import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'

import DashboardPage from './DashboardPage'

import { role } from '../../redux/profile/selectors'
import { isPastClient, isDueDiligenceClient } from '../../redux/profile/selectors'

const selector = createStructuredSelector({
  role,
  isPastClient,
  isDueDiligenceClient,
})

export { DashboardPage }

export default connect(selector)(DashboardPage)
