import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'

import RiskRatingTable from './RiskRatingTable'
import { riskRatingDetails } from '../../redux/clientInfo/selectors'

import { listRiskRatingDetails, exportRiskRatingDetails } from '../../redux/clientInfo/actions'

const selector = createStructuredSelector({
  riskRatingDetails,
})
const actions = {
  listRiskRatingDetails,
  exportRiskRatingDetails,
}

export default connect(selector, actions)(RiskRatingTable)
