import { Action } from 'redux'
import { History } from 'history'
import { takeEvery } from 'redux-saga/effects'
import { generatePath } from 'react-router-dom'

import { ROUTES } from '../../constants/routes'
import { START_FAILURE } from './actions'
import { IResponse } from '@common/interfaces/request'

export function* clientIntakeRedirect(history: History) {
  yield takeEvery(START_FAILURE, function* (action: IResponse & Action) {
    if (action.error.code === 'PERMANENT_REDIRECT') {
      history.push(
        generatePath(ROUTES.CLIENT_INTAKE, { salesforceId: action.error.data.salesforceId }),
      )
    }
    yield
  })
}
