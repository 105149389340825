import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useParams } from 'react-router'
import { FormApi } from 'final-form'
import { Form, FormRenderProps, Field } from 'react-final-form'
import { makeValidate } from 'mui-rff'
import * as Yup from 'yup'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'

import styles from './EntitySettingsGeneralPage.module.scss'

import TextField from '../../components/Common/TextField'
import InputLabel from '../../components/Common/InputLabel'
import Button from '../../components/Common/Button'
import {
  ENTITY_SUB_TYPE_OPTIONS,
  IEntityInfo,
  IEntityMergeConflicts,
} from '@common/interfaces/entityInfo'
import { useLoadInfo } from '../../hooks/useLoadInfo'
import EntitySettingsHeader from '../../components/EntitySettingsHeader'
import Card from '../../components/Common/Card'
import SelectField from '../../components/Common/SelectField'
import { UploadFile } from '../../components/Common/UploadFile'
import { DOMAIN_REGEXP } from '../../constants/common'
import EntityMergeConflictModal from '../../components/EntityMergeConflictModal'
import CreatableSelectField, { IOptionType } from '../../components/Common/CreatableSelectField'
import WarningModal from '../../components/WarningModal'

const schema = Yup.object().shape({
  name: Yup.object().nullable().required('Name is required'),
  website: Yup.string().nullable(),
})
const validate = makeValidate(schema)

const OPTIONS_WITH_EMPTY = [{ value: '', label: '' }, ...ENTITY_SUB_TYPE_OPTIONS]

interface IFormProps extends FormRenderProps<any> {
  formRef: React.MutableRefObject<FormApi<any, Partial<any>>>
  isSaving: boolean
  iconUrl: string | null
  setIconUrl: (image: string | null) => void
  loadEntityNames: (inputValue: string) => Promise<IOptionType[]>
}

const EntitySettingsGeneralForm = ({
  formRef,
  isSaving,
  iconUrl,
  setIconUrl,
  loadEntityNames,
  form,
  values,
  dirty,
  invalid,
  handleSubmit,
}: IFormProps) => {
  formRef.current = form

  const [isImageManageModalOpen, setIsImageManageModalOpen] = useState(false)

  const handleToggleImageManageModal = useCallback(() => {
    setIsImageManageModalOpen((isOpen) => !isOpen)
  }, [])

  const handleUploadFile = useCallback(
    (loadedFiles: File[]) => {
      form.change('files', loadedFiles)
      const [image] = loadedFiles
      if (image) {
        const reader = new FileReader()
        reader.onload = () => {
          setIconUrl(reader.result as string)
        }
        reader.readAsDataURL(image)
      }
    },
    [form, setIconUrl],
  )

  const handleDeleteFile = useCallback(() => {
    form.change('files', [])
    setIconUrl(null)
  }, [form, setIconUrl])

  const handleAddEntity = useCallback(
    (data) => {
      form.change('name', { value: data, label: data })
    },
    [form],
  )

  const handleSelectEntity = useCallback(
    async (event, newValue: any) => {
      form.change('name', { value: newValue.value, label: newValue.value })
      form.change('mergeEntityId', newValue.id)
    },
    [form],
  )

  return (
    <form onSubmit={handleSubmit}>
      <Card
        noHeaderMargin
        withBorder={false}
        title={
          <Box display="flex" justifyContent="flex-end" alignItems="center">
            <Button
              type="submit"
              small={false}
              onClick={handleSubmit}
              disabled={invalid || !dirty}
              isLoading={isSaving}
            >
              Save
            </Button>
          </Box>
        }
      >
        <Box mt={1} display="flex" flexDirection="column" width="100%">
          <Box mb={2} display="flex" justifyContent="space-between" alignItems="center">
            {iconUrl && (
              <Box className={styles.avatarWrapper}>
                <img className={styles.avatarImg} src={iconUrl} alt="logo" />
              </Box>
            )}
            <Box className={styles.imageUploadWrapper}>
              <UploadFile
                title="Profile Picture"
                size="lg"
                onDropAccepted={handleUploadFile}
                files={values.files}
                onDelete={handleDeleteFile}
                acceptedFileTypes={['image']}
                maxFiles={1}
                isModalOpen={isImageManageModalOpen}
                handleToggleModal={handleToggleImageManageModal}
              />
              <Field name="files" type="hidden" render={() => null} />
            </Box>
          </Box>
          <Box mb={2}>
            <InputLabel htmlFor="name">Name</InputLabel>

            <CreatableSelectField
              label=""
              height="large"
              name="name"
              placeholder="Entity"
              onAddValue={handleAddEntity}
              defaultValue={null}
              options={[]}
              isAsync
              loadOptions={loadEntityNames}
              onChangeCustom={handleSelectEntity}
              getOptionValue={(option) => option.value}
            />
          </Box>
          <Box mb={2}>
            <InputLabel htmlFor="type">Sub-type</InputLabel>
            <SelectField
              id="type"
              name="type"
              options={OPTIONS_WITH_EMPTY}
              defaultValue=""
              selectSize="large"
              optionClassName={styles.optionClassName}
            />
          </Box>
          <Box mb={2}>
            <InputLabel htmlFor="name">Website</InputLabel>
            <TextField name="website" type="text" placeholder="Website" size="large" />
          </Box>
          <Box mb={2}>
            <InputLabel htmlFor="description">Description</InputLabel>
            <TextField
              name="description"
              type="text"
              placeholder="Description"
              size="large"
              multiline
              rows={3}
            />
          </Box>
        </Box>
      </Card>
    </form>
  )
}

interface IProps {
  entity: IEntityInfo
  entityMergeConflicts?: IEntityMergeConflicts[]
  getEntityInfo: (id: string) => void
  updateEntityInfo: (id: string, data: FormData) => void
  listEntityInfo: (data?: object) => Promise<{ data: IEntityInfo[] }>
}

const EntitySettingsGeneralPage = ({
  entity,
  entityMergeConflicts,
  getEntityInfo,
  updateEntityInfo,
  listEntityInfo,
}: IProps) => {
  const { id } = useParams<{ id: string }>()
  const formRef: React.MutableRefObject<FormApi<any, Partial<any>>> = useRef(null)
  const [isSaving, setIsSaving] = useState(false)
  const [iconUrl, setIconUrl] = useState<string | null>(null)
  const [isMergeConfirmed, setIsMergeConfirmed] = useState(false)
  const [isMergeConfirmModalShown, setIsMergeConfirmModalShown] = useState(false)
  const [isConfirmedWithConflict, setIsConfirmedWithConflict] = useState(false)
  const [isConfirmWithConflictModalShown, setIsConfirmWithConflictModalShown] = useState(false)
  const [mergeEntity, setMergeEntity] = useState({
    id: entity?.id,
    newName: entity?.name,
  })

  useLoadInfo({ id, info: entity, show: getEntityInfo })

  const handleUpdateEntityInfo = useCallback(
    async (data: any) => {
      const formData = new FormData()

      if (data.mergeEntityId && data.mergeEntityId !== id && !isMergeConfirmed) {
        setMergeEntity({ id: data.mergeEntityId, newName: data.name?.value })
        setIsMergeConfirmModalShown(true)
        return
      }

      formData.append('newName', data.name?.value)
      formData.append('type', data.type || '')
      formData.append('website', data.website ? data.website.match(DOMAIN_REGEXP)[0] : '')
      formData.append('description', data.description || '')
      formData.append('isConfirmedWithConflict', isConfirmedWithConflict.toString())

      if (data.files?.length) {
        formData.append('files[]', data.files[0], data.files[0].name)
      }

      setIsSaving(true)
      await updateEntityInfo(id, formData)
      setIsSaving(false)
    },
    [id, updateEntityInfo, isConfirmedWithConflict, isMergeConfirmed],
  )

  const handleSetIconUrl = useCallback(
    (image) => {
      setIconUrl(image ? image : entity?.iconUrl)
    },
    [entity],
  )

  useEffect(() => {
    if (entity) {
      setIconUrl(entity.iconUrl)
    }
  }, [entity])

  const initialValues = useMemo(
    () => ({
      name: {
        value: entity?.name,
        label: entity?.name,
      },
      type: entity?.type,
      website: entity?.website,
      description: entity?.description,
      files: [],
    }),
    [entity],
  )

  useEffect(() => {
    if (entityMergeConflicts.length > 0) {
      setIsConfirmWithConflictModalShown(true)
    }
  }, [entityMergeConflicts])

  const handleCloseMergeConfirmModal = useCallback(() => {
    setIsMergeConfirmModalShown(false)
  }, [])

  const handleConfirmMerge = useCallback(() => {
    setIsMergeConfirmed(true)
    setTimeout(() => {
      setIsMergeConfirmModalShown(false)
      formRef.current.submit()
    })
  }, [])

  const handleCloseConfirmWithConflictModal = useCallback(() => {
    setIsConfirmWithConflictModalShown(false)
  }, [])

  const handleConfirmWithConflicts = useCallback(() => {
    setIsConfirmedWithConflict(true)
    setTimeout(() => {
      setIsConfirmWithConflictModalShown(false)
      formRef.current.submit()
    })
  }, [])

  const loadEntityNames = useCallback(
    async (inputValue: string) => {
      const res = await listEntityInfo({
        name: inputValue,
      })

      return res.data.map(({ id, name }) => ({
        id,
        value: name,
        label: name,
      }))
    },
    [listEntityInfo],
  )

  return (
    <Box className={styles.wrapper} py={1} pr={2}>
      <EntitySettingsHeader />

      <Box>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Form
              initialValues={initialValues}
              onSubmit={handleUpdateEntityInfo}
              validate={validate}
              render={(formProps) => (
                <EntitySettingsGeneralForm
                  {...formProps}
                  formRef={formRef}
                  isSaving={isSaving}
                  iconUrl={iconUrl}
                  setIconUrl={handleSetIconUrl}
                  loadEntityNames={loadEntityNames}
                />
              )}
            />
          </Grid>
        </Grid>
      </Box>

      {isMergeConfirmModalShown && (
        <WarningModal
          onCancel={handleCloseMergeConfirmModal}
          onConfirm={handleConfirmMerge}
          warningMessage={`Are you sure you want to merge ${entity.name} into ${mergeEntity?.newName}?`}
          isLoading={isSaving}
        />
      )}

      {isConfirmWithConflictModalShown && (
        <EntityMergeConflictModal
          handleCloseConfirmWithConflictModal={handleCloseConfirmWithConflictModal}
          handleConfirmWithConflicts={handleConfirmWithConflicts}
        />
      )}
    </Box>
  )
}

export default EntitySettingsGeneralPage
