import React, { useCallback } from 'react'
import { useHistory } from 'react-router'
import Grid from '@mui/material/Grid'

import styles from '../../components/AuthContainer/AuthContainer.module.scss'

import { ReactComponent as ActionPendingIcon } from '@assets/images/action-pending.svg'
import Card from '../../components/Common/Card'
import Button from '../../components/Common/Button'

const ErrorPage = () => {
  const history = useHistory()
  const handleGoBack = useCallback(() => {
    history.goBack()
  }, [history])

  const handleGoHome = useCallback(() => {
    history.push('/')
  }, [history])

  return (
    <Card
      className={styles.formWrapper}
      classes={{ content: styles.centeredContent }}
      withBorder={false}
    >
      <Grid item xs={12} className={styles.formContainer}>
        <Grid item xs={12} className={styles.infoContainer}>
          <div className={styles.headingCenter}>
            <ActionPendingIcon className={styles.headingErrorIcon} />

            <div className={styles.headingError}>
              Sorry! We're having some technical difficulties.
            </div>

            <div className={styles.headingInfo}>
              Try going back or returning to the homepage.
              <br />
              If the problem persists, contact us.
            </div>

            <Button small={false} className={styles.goBackButton} fullWidth onClick={handleGoBack}>
              Go Back
            </Button>
            <Button
              small={false}
              variant={'outlined'}
              className={styles.goHomeButton}
              fullWidth
              onClick={handleGoHome}
            >
              Homepage
            </Button>
          </div>
        </Grid>
      </Grid>
    </Card>
  )
}

export default ErrorPage
