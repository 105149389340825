import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'

import AnalysisQueueTable from './AnalysisQueueTable'

import { clients } from '../../redux/clientInfo/selectors'
import { data } from '../../redux/ongoingReporting/selectors'
import {
  listOngoingReporting,
  deleteOngoingReporting,
  updateOngoingReportingStatus,
  hideOngoingReporting,
} from '../../redux/ongoingReporting/actions'

import { hideAliases, hideTypeMapping } from '../../redux/generalLedger/actions'

const selector = createStructuredSelector({
  clients,
  fullOngoingReportingData: data,
})
const actions = {
  listOngoingReporting,
  deleteOngoingReporting,
  updateOngoingReportingStatus,
  hideOngoingReporting,
  hideAliases,
  hideTypeMapping,
}

export default connect(selector, actions)(AnalysisQueueTable)
