import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import ClientTermLoanAmortization from './ClientTermLoanAmortization'
import {
  createTermLoanActivity,
  deleteTermLoanActivity,
  updateTermLoanActivity,
} from '../../../redux/clientInfo/actions'
import { loanBalanceStartDate } from '../../../redux/loanBalance/selectors'

const selector = createStructuredSelector({
  loanBalanceStartDate,
})

const actions = {
  createTermLoanActivity,
  deleteTermLoanActivity,
  updateTermLoanActivity,
}

export default connect(selector, actions)(ClientTermLoanAmortization)
