import React, { useState, useCallback, useMemo } from 'react'
import Box from '@mui/material/Box'
import Popover from '@mui/material/Popover'
import TreeView from '@mui/lab/TreeView/TreeView'
import TreeItem from '@mui/lab/TreeItem/TreeItem'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import Tooltip from '@mui/material/Tooltip'
import cn from 'classnames'
import groupBy from 'lodash/groupBy'
import { ReactComponent as ExcelIcon } from '@assets/images/excel-icon.svg'
import styles from './FileSelect.module.scss'
import Button from '../../components/Common/Button'
import DropDownArrow from '../../components/Common/DropDownArrow'
import { IFile } from '@common/interfaces/box'
import { ReactComponent as DeleteIcon } from '@assets/images/close-outline-icon.svg'
import { ReactComponent as AddIcon } from '@assets/images/plus-simple-icon.svg'
import Loader from '../Loader'
import Link from '@mui/material/Link'

interface IProps {
  isLoading?: boolean
  files: IFile[]
  selectedDocuments: { fileId: string; sheetName: string }[]
  isSheetSelectable?: boolean
  updateFiles?: (selectedDocuments: { fileId: string; sheetName: string }[]) => void
  className?: string
  disabled?: boolean
}

const MultipleFileSelect = ({
  isLoading = false,
  files,
  selectedDocuments,
  updateFiles,
  className,
  disabled = false,
}: IProps) => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)
  const [expandedNode, setExpandedNode] = useState([])

  const selectedNodeIds = useMemo(
    () =>
      selectedDocuments
        ? selectedDocuments.map(
            (selectedDocument) => `${selectedDocument.fileId}/${selectedDocument.sheetName}`,
          )
        : null,
    [selectedDocuments],
  )

  const handleNodeToggle = useCallback(
    (event: React.ChangeEvent<{}>, nodeIds: string[]) => {
      setExpandedNode([nodeIds[0]])
    },
    [setExpandedNode],
  )

  const handleOpen = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      setAnchorEl(event.currentTarget)
    },
    [setAnchorEl],
  )

  const handleClose = useCallback(() => {
    setAnchorEl(null)
  }, [setAnchorEl])

  const handleSelect = useCallback(
    async (event, nodeIds, isMultiple = false) => {
      if (!nodeIds?.length || disabled) {
        return
      }

      const [nodeId] = nodeIds

      const filesId = files.map(({ id: fileId }) => fileId)
      if (!filesId.includes(nodeId)) {
        const [fileId, sheetName] = nodeId.split('/')
        if (!isMultiple) {
          updateFiles([{ fileId, sheetName }])
        } else {
          const isSelected = selectedDocuments.find(
            (item) => item.fileId === fileId && item.sheetName === sheetName,
          )
          if (isSelected) {
            updateFiles(
              selectedDocuments.filter(
                (item) => item.fileId !== fileId || item.sheetName !== sheetName,
              ),
            )
          } else {
            updateFiles([...selectedDocuments, { fileId, sheetName }])
          }
        }
        handleClose()
      }
    },
    [files, handleClose, updateFiles, selectedDocuments, disabled],
  )

  const handleClear = useCallback(
    (fileId: string, sheetName: string) => {
      if (disabled) {
        return
      }
      updateFiles(
        selectedDocuments.filter((item) => item.fileId !== fileId || item.sheetName !== sheetName),
      )
    },
    [selectedDocuments, updateFiles, disabled],
  )

  const fileSelectLabel = useMemo(() => {
    if (selectedDocuments.length) {
      return Object.entries(groupBy(selectedDocuments, 'fileId'))
        .map(([fileId, selectedDocuments]) => {
          const file = files.find(({ id }) => id === fileId)
          if (selectedDocuments.length > 1) {
            return `${file?.fileName || file?.name} - ${selectedDocuments.length} sheets selected`
          }
          return `${file?.fileName || file?.name} - ${selectedDocuments[0].sheetName}`
        })
        .join(', ')
    }
    return 'Select file and worksheet'
  }, [selectedDocuments, files])
  return (
    <Box display="flex" className={cn(styles.wrapper, className)}>
      {isLoading && <Loader />}

      <Button
        type="button"
        color="primary"
        size="small"
        className={cn(styles.title, styles.multiSelectTitle, {
          [styles.open]: Boolean(anchorEl),
        })}
        onClick={handleOpen}
        disabled={disabled}
      >
        <Tooltip title={fileSelectLabel} placement="top">
          <span>{fileSelectLabel}</span>
        </Tooltip>
        <DropDownArrow className={cn(styles.titleIcon, { [styles.rotate]: !!anchorEl })} />
      </Button>

      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        classes={{ paper: styles.popover }}
      >
        <TreeView
          defaultCollapseIcon={<ExpandMoreIcon />}
          defaultExpandIcon={<ChevronRightIcon />}
          multiSelect
          onNodeSelect={handleSelect}
          selected={selectedNodeIds}
          className={styles.filesList}
          expanded={expandedNode}
          onNodeToggle={handleNodeToggle}
        >
          {files.map(
            ({ id, fileName, name, link, sheetNames }) =>
              id && (
                <TreeItem
                  key={id}
                  nodeId={id}
                  classes={{
                    content: styles.content,
                    label: styles.label,
                    selected: styles.selected,
                    group: styles.group,
                  }}
                  label={
                    <Box
                      display="flex"
                      alignItems="center"
                      justifyContent="space-between"
                      width="100%"
                      pr={2.5}
                    >
                      <Box className={styles.fileNameWrapper}>
                        <Link href={link} target="_blank">
                          <ExcelIcon className={styles.fileNameIcon} />
                        </Link>
                        <span className={styles.fileName}>{fileName || name}</span>
                      </Box>

                      <DropDownArrow
                        className={cn(styles.titleIcon, styles.multiSelectDropDownArrow)}
                      />
                    </Box>
                  }
                >
                  {sheetNames &&
                    sheetNames.map((sheetName) => (
                      <TreeItem
                        key={sheetName}
                        nodeId={`${id}/${sheetName}`}
                        label={
                          <>
                            {sheetName}
                            {selectedNodeIds.includes(`${id}/${sheetName}`) ? (
                              <div className={cn(styles.icon, styles.clearIcon)}>
                                <DeleteIcon
                                  onClick={(event) => {
                                    event.stopPropagation()
                                    handleClear(id, sheetName)
                                  }}
                                />
                              </div>
                            ) : (
                              <div className={cn(styles.icon, styles.addIcon)}>
                                <AddIcon
                                  onClick={(event) => {
                                    event.stopPropagation()
                                    handleSelect(event, [`${id}/${sheetName}`], true)
                                  }}
                                />
                              </div>
                            )}
                          </>
                        }
                        classes={{
                          content: styles.content,
                          selected: styles.selected,
                        }}
                      />
                    ))}
                </TreeItem>
              ),
          )}
          {!files.length && (
            <TreeItem
              nodeId={'no-items'}
              classes={{
                content: styles.content,
                label: styles.label,
                selected: styles.selected,
              }}
              label="No items available"
            />
          )}
        </TreeView>
      </Popover>
    </Box>
  )
}

export default MultipleFileSelect
