import { Box, Grid } from '@mui/material'
import React, { useCallback, useMemo, useState } from 'react'
import LoanCommitteeReportHeader from '../../components/LoanCommitteeReportHeader'
import { DUE_DILIGENCE_ROUTES_MAP, IDueDiligence } from '@common/interfaces/dueDiligence'
import { OngoingReportingType, WorkflowPage } from '@common/interfaces/bbc'
import { generatePath } from 'react-router-dom'
import { useParams } from 'react-router-dom'
import { ROUTES } from '../../constants/routes'
import SalesBySKUVisualizationTable from '../../components/SalesBySKUVisualizationTable'
import BBCInventoryDistributionTable from '../../components/BBCInventoryDistributionTable'

interface IProps {
  dueDiligenceInfo: IDueDiligence
}

const LoanCommitteeReportInventoryPage = ({ dueDiligenceInfo }: IProps) => {
  const { id } = useParams<{ id: string }>()
  const [isInventoryDistributionInitialized, setIsInventoryDistributionInitialized] =
    useState(false)

  const { salesBySkuLink, inventoryDistributionLink } = useMemo(
    () => ({
      salesBySkuLink: generatePath(ROUTES.DUE_DILIGENCE_PROCESS_DOCUMENTS_LIST, {
        id,
        type: DUE_DILIGENCE_ROUTES_MAP[OngoingReportingType.SalesBySKU],
      }),
      inventoryDistributionLink: generatePath(ROUTES.DUE_DILIGENCE_PROCESS_DOCUMENTS_LIST, {
        id,
        type: DUE_DILIGENCE_ROUTES_MAP[OngoingReportingType.Inventory],
      }),
    }),
    [id],
  )

  const handleInventoryDistributionInitialize = useCallback(() => {
    setIsInventoryDistributionInitialized(true)
  }, [])

  return (
    <Box py={1} pr={2}>
      <LoanCommitteeReportHeader />
      <Grid container spacing={2} mt={1}>
        <Grid item xs={12}>
          <BBCInventoryDistributionTable
            aliases={dueDiligenceInfo?.inventoryIneligibilityFieldAliases}
            type={WorkflowPage.dueDilligence}
            editLink={inventoryDistributionLink}
            onInitialize={handleInventoryDistributionInitialize}
          />
        </Grid>
        <Grid item xs={12}>
          {isInventoryDistributionInitialized && (
            <SalesBySKUVisualizationTable
              type={WorkflowPage.dueDilligence}
              currentId={dueDiligenceInfo?.id}
              editLink={salesBySkuLink}
            />
          )}
        </Grid>
      </Grid>
    </Box>
  )
}

export default LoanCommitteeReportInventoryPage
