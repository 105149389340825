import { combineReducers } from 'redux'
import * as actions from './actions'
import { INotification, INotificationData } from '@common/interfaces/notification'
import { UPDATE_NOTIFICATIONS } from '@common/constants/webSockets'
import { loadingArrayReducer } from '../../helpers/helpers'

export interface INotificationState {
  loadingArray: string[]
  lastNotifications: INotificationData
  notifications: INotificationData
}

export default combineReducers<INotificationState>({
  loadingArray: loadingArrayReducer([actions.prefix]),
  lastNotifications: (state = null, action) => {
    switch (action.type) {
      case actions.LIST_LAST_NOTIFICATIONS_SUCCESS:
      case UPDATE_NOTIFICATIONS:
        return action.data
      default:
        return state
    }
  },
  notifications: (state = null, action) => {
    switch (action.type) {
      case actions.LIST_NOTIFICATIONS_SUCCESS:
        if (!action.loadMore) {
          return action.data
        } else {
          return {
            ...state,
            data: [...state.data, ...action.data.data],
          }
        }
      case UPDATE_NOTIFICATIONS:
        if (!state) {
          return state
        }
        return {
          ...state,
          data: state.data.map((notification) =>
            action.data.data.find(({ id }: INotification) => id === notification.id) &&
            !notification.isRead
              ? {
                  ...notification,
                  isRead: true,
                }
              : notification,
          ),
          totalUnread: action.data.totalUnread,
        }
      default:
        return state
    }
  },
})
