import React, { useState, useCallback, useMemo } from 'react'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'

import styles from './ProspectAnalysisQueueTable.module.scss'

import WarningModal from '../WarningModal'
import { MenuIcon } from '../Common/Icons'

interface IProps {
  handleManage: () => void
  handleDelete: () => void
}

const ProspectAnalysisQueueUncategorizedActions = ({ handleManage, handleDelete }: IProps) => {
  const [anchorEl, setAnchorEl] = useState(null)
  const isActionsMenuOpen = useMemo(() => Boolean(anchorEl), [anchorEl])
  const [isDeleteModalShown, setIsDeleteModalShown] = useState(false)
  const [isDeleting, setIsDeleting] = useState(false)

  const handleClickMenu = useCallback((event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }, [])

  const handleCloseMenu = useCallback(() => {
    setAnchorEl(null)
  }, [])

  const onManage = useCallback(() => {
    handleCloseMenu()
    handleManage()
  }, [handleCloseMenu, handleManage])

  const onDelete = useCallback(() => {
    handleCloseMenu()
    setIsDeleteModalShown(true)
  }, [handleCloseMenu])

  const handleDeleteConfirm = useCallback(async () => {
    setIsDeleting(true)
    await handleDelete()
    setIsDeleting(false)
    setIsDeleteModalShown(false)
  }, [handleDelete])

  const handleDeleteCancel = useCallback(async () => {
    setIsDeleteModalShown(false)
  }, [])

  return (
    <>
      <MenuIcon isActive={isActionsMenuOpen} onClick={handleClickMenu} size="small" />
      <Menu
        classes={{ paper: styles.iconMenu }}
        open={isActionsMenuOpen}
        onClose={handleCloseMenu}
        anchorEl={anchorEl}
      >
        <MenuItem classes={{ root: styles.iconMenuItem }} onClick={onManage}>
          Select reports
        </MenuItem>
        <MenuItem classes={{ root: styles.iconMenuItem }} onClick={onDelete}>
          Delete
        </MenuItem>
      </Menu>

      {isDeleteModalShown && (
        <WarningModal
          warningMessage="Deleting this files will be permanent."
          onConfirm={handleDeleteConfirm}
          onCancel={handleDeleteCancel}
          confirmText="Yes, proceed"
          cancelText="Cancel"
          disabled={isDeleting}
        />
      )}
    </>
  )
}

export default ProspectAnalysisQueueUncategorizedActions
