import React, { useMemo } from 'react'
import { generatePath, Link } from 'react-router-dom'
import Box from '@mui/material/Box'
import MuiAvatar from '@mui/material/Avatar'
import LinkButton from '@mui/material/Link'
import cn from 'classnames'

import styles from './GlobalSearchPage.module.scss'
import genericSs from '@styles/generic.module.scss'

import { ClientInfoStatus, IClientInfo } from '@common/interfaces/client'
import TableRow from '../../components/Common/TableRow'
import TableCell from '../../components/Common/TableCell'
import { ROUTES } from '../../constants/routes'
import { formatMaxNumber, formatPrice } from '../../helpers/helpers'
import { replaceJSX } from './GlobalSearchPage'
import { SearchType } from '@common/interfaces/search'

const GlobalSearchPageTableRowClient = ({
  clientInfo,
  searchString,
  searchType,
}: {
  clientInfo: IClientInfo
  searchString: string
  searchType: SearchType
}) => {
  const link = useMemo(() => {
    if (!clientInfo?.id) {
      return null
    }

    if (searchType === SearchType.Client) {
      return generatePath(ROUTES.CLIENT_PAGE, { id: clientInfo.id })
    } else if (searchType === SearchType.DueDiligence) {
      return generatePath(ROUTES.DUE_DILIGENCE, { id: clientInfo.id })
    } else if (searchType === SearchType.Prospect && clientInfo.firstOPSReportingId) {
      return generatePath(ROUTES.PROSPECT_PAGE, { id: clientInfo.firstOPSReportingId })
    }

    return null
  }, [clientInfo, searchType])

  return (
    <TableRow>
      <TableCell className={genericSs.tableTextLeft}>
        <Box display="flex" alignItems="center" gap={2}>
          {clientInfo.iconUrl && (
            <MuiAvatar
              src={clientInfo.iconUrl}
              alt={clientInfo.clientName}
              className={styles.clientAvatar}
            />
          )}
          <LinkButton component={Link} to={link}>
            {replaceJSX(clientInfo.clientName, searchString)}
          </LinkButton>
        </Box>
      </TableCell>
      {searchType !== SearchType.Client && (
        <TableCell className={genericSs.tableTextLeft}>
          <LinkButton component={Link} to={generatePath(ROUTES.CLIENT_PAGE, { id: clientInfo.id })}>
            {replaceJSX(clientInfo.clientName, searchString)}
          </LinkButton>
        </TableCell>
      )}
      <TableCell className={genericSs.tableTextRight}>
        <span className={genericSs.pricePrefix}>$</span>
        {replaceJSX(formatPrice(clientInfo.currentAmount), searchString)}
      </TableCell>
      <TableCell className={genericSs.tableTextRight}>
        {replaceJSX(clientInfo.risk ? clientInfo.risk.toFixed(2) : '-', searchString)}
      </TableCell>
      <TableCell className={genericSs.tableTextRight}>
        {replaceJSX(formatMaxNumber(clientInfo.lineTurn, 50), searchString)}
      </TableCell>
      <TableCell className={genericSs.tableTextLeft}>
        <span
          className={cn(genericSs.statusCard, {
            [genericSs.greenTag]: ![
              ClientInfoStatus.Past,
              ClientInfoStatus.PastArchived,
              ClientInfoStatus.Archived,
            ].includes(clientInfo.clientStatus),
            [genericSs.grayCard]: [
              ClientInfoStatus.Past,
              ClientInfoStatus.PastArchived,
              ClientInfoStatus.Archived,
            ].includes(clientInfo.clientStatus),
          })}
        >
          {replaceJSX(clientInfo.clientStatus, searchString)}
        </span>
      </TableCell>
    </TableRow>
  )
}

export default GlobalSearchPageTableRowClient
