import { skuVisualizationsFilters } from './../../redux/ongoingReporting/selectors'
import { listSalesBySkuFilters } from './../../redux/ongoingReporting/actions'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'

import {
  listSalesBySkuVisualization,
  exportSalesBySkuVisualization,
  hideSalesData,
} from '../../redux/ongoingReporting/actions'
import { skuVisualization } from '../../redux/ongoingReporting/selectors'
import SalesBySKUVisualizationTable from './SalesBySKUVisualizationTable'

const selector = createStructuredSelector({
  skuVisualization,
  skuVisualizationsFilters,
})

const actions = {
  listSalesBySkuVisualization,
  listSalesBySkuFilters,
  exportSalesBySkuVisualization,
  hideSalesData,
}

export default connect(selector, actions)(SalesBySKUVisualizationTable)
