import React from 'react'
import cn from 'classnames'

import styles from './BBCEditorModal.module.scss'

import { IBorrowingBase } from '@common/interfaces/bbc'
import Modal from '../Common/Modal'
import { formatDate } from '../../helpers/helpers'
import RichTextEditor from '../Common/RichTextEditor'

interface IProps {
  isShown: boolean
  onCancel?: () => void
  className?: string
  title: string
  Icon: any
  bbc: IBorrowingBase
  value: string
  handleChange?: (data: string) => void
  readOnly?: boolean
}

const BBCEditorModal = ({
  isShown,
  onCancel,
  className,
  title,
  Icon,
  bbc,
  value,
  handleChange,
  readOnly,
}: IProps) => {
  if (!isShown) {
    return null
  }

  return (
    <Modal
      open
      onCancel={onCancel}
      classes={{ root: cn(styles.modalRoot, className), body: styles.modalBody }}
    >
      <div className={styles.modalTitle}>
        <h2>
          {bbc.clientInfo?.clientName || ''} BBC
          <span>{formatDate(bbc.recordDate)}</span>
        </h2>

        <div>
          <Icon className={styles.modalTitleIcon} />
          <span>{title}</span>
        </div>
      </div>

      <RichTextEditor
        className={styles.modalContent}
        value={value}
        handleChange={handleChange}
        readOnly={readOnly}
      />
    </Modal>
  )
}

export default BBCEditorModal
