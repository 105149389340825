import React, { useCallback, useEffect, useState } from 'react'
import styles from './UpdateBrandImageModal.module.scss'
import { INewsfeed } from '@common/interfaces/newsfeed'
import Button from '../Common/Button'
import { Box } from '@mui/material'
import Modal from '../Common/Modal'
import { UploadFile } from '../Common/UploadFile'

interface IProps {
  newsItem: INewsfeed
  updateEntityInfo: (id: string, data: FormData) => void
  updateClient: (id: string, data: FormData) => void
  updateBrand: (id: string, data: FormData) => void
  setIsEditProfilePicModalOpen: (isOpen: boolean) => void
  showNewsfeed: (id: string, params?: object) => void
}

const UpdateBrandImageModal = ({
  newsItem,
  updateEntityInfo,
  updateClient,
  setIsEditProfilePicModalOpen,
  showNewsfeed,
  updateBrand,
}: IProps) => {
  const [savedProfilePic, setSavedProfilePic] = useState<string | null>(null)
  const [isSaving, setIsSaving] = useState(false)
  const [isImageManageModalOpen, setIsImageManageModalOpen] = useState(false)

  const [loadedFile, setLoadedFile] = useState<File[]>([])

  const [iconUrl, setIconUrl] = useState<string | null>(null)

  const handleSetIconUrl = useCallback((image) => {
    setIconUrl(image)
  }, [])

  useEffect(() => {
    setSavedProfilePic(null)
    setIsSaving(false)
  }, [])

  useEffect(() => {
    setIconUrl(savedProfilePic || newsItem?.brandFromSalesforce?.brandIcon)
  }, [savedProfilePic, newsItem?.brandFromSalesforce?.brandIcon])

  const closeEditProfilePicModal = useCallback(() => {
    setIsEditProfilePicModalOpen(false)
    setIsSaving(false)
    setLoadedFile([])
    setIconUrl(savedProfilePic || newsItem?.brandFromSalesforce?.brandIcon)
  }, [savedProfilePic, newsItem?.brandFromSalesforce?.brandIcon, setIsEditProfilePicModalOpen])

  const handleUpdateClientInfo = useCallback(
    async (file: any) => {
      if (newsItem?.brandFromSalesforce?.clientInfo?.id) {
        const formData = new FormData()
        formData.append('files[profilePicture]', file[0], file[0].name)
        await updateClient(newsItem?.brandFromSalesforce?.clientInfo?.id, formData)
      } else if (newsItem?.brandFromSalesforce?.entityInfo?.id) {
        const formData = new FormData()
        formData.append('files[]', file[0], file[0].name)
        formData.append('newName', newsItem.brandFromSalesforce.entityInfo.name)
        await updateEntityInfo(newsItem?.brandFromSalesforce?.entityInfo?.id, formData)
      } else if (newsItem?.brandFromSalesforce?.id) {
        const formData = new FormData()
        formData.append('files[brandLogo]', file[0], file[0].name)
        await updateBrand(newsItem?.brandFromSalesforce?.id, formData)
      }
    },
    [newsItem, updateClient, updateEntityInfo, updateBrand],
  )

  const handleSaveProfilePic = useCallback(async () => {
    setIsSaving(true)
    await handleUpdateClientInfo(loadedFile)
    await showNewsfeed(newsItem.id)
    setSavedProfilePic(iconUrl)
    setIconUrl(iconUrl)
    setIsEditProfilePicModalOpen(false)
    setLoadedFile([])
    setIsSaving(false)
  }, [
    handleUpdateClientInfo,
    loadedFile,
    iconUrl,
    setLoadedFile,
    setIsEditProfilePicModalOpen,
    showNewsfeed,
    newsItem?.id,
  ])

  const handleUploadFile = useCallback(
    async (loadedFiles: File[]) => {
      setLoadedFile(loadedFiles)
      const [image] = loadedFiles

      if (image) {
        const reader = new FileReader()
        reader.onload = () => {
          handleSetIconUrl(reader.result as string)
        }
        reader.readAsDataURL(image)
      }
    },
    [handleSetIconUrl],
  )

  const handleDeleteFile = useCallback(async () => {
    setLoadedFile([])
    handleSetIconUrl(null)
  }, [handleSetIconUrl])

  const handleToggleImageManageModal = useCallback(() => {
    setIsImageManageModalOpen((isOpen) => !isOpen)
  }, [])

  return (
    <Modal
      open
      onCancel={closeEditProfilePicModal}
      classes={{ root: styles.modalRoot, title: styles.modalTitle, footer: styles.modalFooter }}
      title="Upload Image"
      size="small"
      footer={[
        <Button
          color="primary"
          variant="contained"
          small={false}
          onClick={handleSaveProfilePic}
          isLoading={isSaving}
          key="submit"
          className={styles.modalFooter}
          disabled={!loadedFile.length}
        >
          Update
        </Button>,
      ]}
    >
      {iconUrl && (
        <Box className={styles.avatarWrapper}>
          <img className={styles.avatarImg} src={iconUrl} alt="logo" />
        </Box>
      )}
      <Box className={styles.imageUploadWrapper}>
        <UploadFile
          title="Brand Logo"
          size="lg"
          onDropAccepted={handleUploadFile}
          files={loadedFile}
          onDelete={handleDeleteFile}
          acceptedFileTypes={['image']}
          maxFiles={1}
          isModalOpen={isImageManageModalOpen}
          handleToggleModal={handleToggleImageManageModal}
        />
      </Box>
    </Modal>
  )
}
export default UpdateBrandImageModal
