import React, { useCallback, useEffect, useMemo } from 'react'
import { useParams } from 'react-router'
import { generatePath, Link } from 'react-router-dom'
import Grid from '@mui/material/Grid'
import Box from '@mui/material/Box'
import cn from 'classnames'
import LinkButton from '@mui/material/Link'
import genericSs from '@styles/generic.module.scss'
import styles from './DueDiligenceProcessDocumentsPage.module.scss'
import {
  DUE_DILIGENCE_DOCUMENTS_TITLE_MAP,
  DUE_DILIGENCE_ROUTES_MAP,
  DueDiligenceReportingStatus,
  IDueDiligence,
  IDueDiligenceReporting,
} from '@common/interfaces/dueDiligence'
import { ROUTES } from '../../constants/routes'
import TableContainer from '../../components/Common/TableContainer'
import Table from '../../components/Common/Table'
import TableHead from '../../components/Common/TableHead'
import TableBody from '../../components/Common/TableBody'
import TableRow from '../../components/Common/TableRow'
import TableCell from '../../components/Common/TableCell'
import { BoxLink } from '../../components/Common/Icons'
import TableFiltersRow from '../../components/Common/TableFiltersRow'
import SelectField from '../../components/Common/SelectField'
import { OngoingReportingType } from '@common/interfaces/bbc'
import { ILoadingData } from '../../redux/types'
import SaveState from '../../components/Common/SaveState'
import TableLoader from '../../components/Common/TableLoader'
import DueDiligenceProcessDocumentsPageLoader from './DueDiligenceProcessDocumentsPageLoader'
import { formatDateRange, formatDate, debounceEventHandler } from '../../helpers/helpers'
import { ClientInfoStatus } from '@common/interfaces/client'
import DueDiligencePageHeader from '../../components/DueDiligencePageHeader'
import { useLoadInfo } from '../../hooks/useLoadInfo'
import { DUE_DILIGENCE_LIST_FILTERS_CONFIG } from '@common/constants/filters'
import useTable from '../../hooks/useTable'
import { buildFiltersDefaults } from '../../helpers/filters'

interface IProps {
  isLoadingInfo: boolean
  dueDiligenceInfo: IDueDiligence
  dueDiligenceReportingData: ILoadingData<{ data: IDueDiligenceReporting[] }>
  showReporting: (id: string, params: object) => void
  updateReporting: (id: string, type: OngoingReportingType, params: object) => void
  show: (id: string) => void
}

const filtersDefaults = buildFiltersDefaults(DUE_DILIGENCE_LIST_FILTERS_CONFIG)
const sortDefault = { field: 'status', direction: 'DESC' } as const

const STEP_STATUS_OPTIONS = [
  {
    value: DueDiligenceReportingStatus.NotStarted,
    label: 'Not started',
  },
  {
    value: DueDiligenceReportingStatus.InProgress,
    label: 'In progress',
  },
  {
    value: DueDiligenceReportingStatus.Completed,
    label: 'Completed',
  },
]

const DueDiligenceReportingRow = ({
  id,
  reporting,
  updateReporting,
  readOnly,
}: {
  id: string
  reporting: IDueDiligenceReporting
  updateReporting: (type: OngoingReportingType, status: DueDiligenceReportingStatus) => void
  readOnly: boolean
}) => {
  const { type, status, boxLink, createdAt } = reporting

  const handleChangeStatus = useCallback(
    ({ target: { value } }) => {
      updateReporting(reporting.type, value)
    },
    [reporting, updateReporting],
  )
  const monthsProcessed = useMemo(() => {
    const flows = reporting.dueDiligenceReportingFlows?.filter(({ isCompleted }) => isCompleted)

    if (!flows?.length) {
      return '-'
    }

    if (
      [OngoingReportingType.AR, OngoingReportingType.AP, OngoingReportingType.Inventory].includes(
        reporting.type,
      )
    ) {
      return (
        formatDateRange(
          flows
            .filter(({ data }) => data?.recordDate)
            .map(({ data }) => ({
              startDate: data.recordDate,
              endDate: data.recordDate,
            })) || [],
        ) || '-'
      )
    }

    if (
      [
        OngoingReportingType.IncomeStatement,
        OngoingReportingType.BalanceSheet,
        OngoingReportingType.IncomeStatementProjections,
        OngoingReportingType.BalanceSheetProjections,
      ].includes(reporting.type)
    ) {
      return (
        formatDateRange(
          flows
            .filter(({ data }) => data?.startDate && data?.endDate)
            .map(({ data }) => ({
              startDate: data.startDate,
              endDate: data.endDate,
            })) || [],
        ) || '-'
      )
    }

    if (
      [
        OngoingReportingType.ARGeneralLedger,
        OngoingReportingType.SalesBySKU,
        OngoingReportingType.BankTransactions,
      ].includes(reporting.type)
    ) {
      return (
        formatDateRange(
          flows
            .filter(({ data }) => data?.recordDates?.length)
            .map(({ data }) =>
              data?.recordDates.map((recordDate) => ({
                startDate: recordDate,
                endDate: recordDate,
              })),
            )
            .flat() || [],
        ) || '-'
      )
    }

    return '-'
  }, [reporting])

  return (
    <TableRow key={reporting.id}>
      <TableCell className={styles.documentTableItemTitle}>
        {DUE_DILIGENCE_ROUTES_MAP[type] && (
          <LinkButton
            component={Link}
            to={generatePath(ROUTES.DUE_DILIGENCE_PROCESS_DOCUMENTS_LIST, {
              id,
              type: DUE_DILIGENCE_ROUTES_MAP[type],
            })}
          >
            {DUE_DILIGENCE_DOCUMENTS_TITLE_MAP[type]}
          </LinkButton>
        )}
      </TableCell>
      <TableCell className={genericSs.tableTextLeft}>
        <BoxLink
          link={boxLink}
          title={`${DUE_DILIGENCE_DOCUMENTS_TITLE_MAP[type]} folder`}
          size="small"
        />
      </TableCell>
      <TableCell className={genericSs.tableTextRight}>{formatDate(createdAt)}</TableCell>
      <TableCell className={genericSs.tableTextLeft}>{monthsProcessed}</TableCell>
      <TableCell className={genericSs.tableTextLeft}>
        <SelectField
          className={cn(styles.documentTableItemStatus, {
            [styles.documentTableItemStatusNotStarted]:
              status === DueDiligenceReportingStatus.NotStarted,
            [styles.documentTableItemStatusInProgress]:
              status === DueDiligenceReportingStatus.InProgress,
            [styles.documentTableItemStatusCompleted]:
              status === DueDiligenceReportingStatus.Completed,
          })}
          optionClassName={styles.diligenceProgressStepItemStatusOption}
          name="status"
          useFinalForm={false}
          options={STEP_STATUS_OPTIONS}
          onChange={handleChangeStatus}
          value={status}
          disabled={readOnly}
        />
      </TableCell>
    </TableRow>
  )
}

const DueDiligenceProcessDocumentsPage = ({
  isLoadingInfo,
  dueDiligenceInfo,
  dueDiligenceReportingData,
  showReporting,
  updateReporting,
  show,
}: IProps) => {
  const { id } = useParams<{ id: string }>()

  const { isLoading, isSaving, isSaved, dueDiligenceReporting } = useMemo(
    () => ({
      isLoading: dueDiligenceReportingData.isLoading,
      isSaving: dueDiligenceReportingData.isSaving,
      isSaved: dueDiligenceReportingData.isSaved,
      dueDiligenceReporting: dueDiligenceReportingData.data?.data || [],
    }),
    [dueDiligenceReportingData],
  )

  const { handleOrderChange, orderBy } = useTable({
    tableId: 'ddDocuments',
    filtersDefaults,
    sortDefault,
  })

  const debounceShowReporting = useMemo(
    () =>
      debounceEventHandler((data: any) => {
        showReporting(id, { ...data })
      }, 500),
    [id, showReporting],
  )

  useEffect(() => {
    debounceShowReporting({
      orderBy: orderBy.field,
      orderDirection: orderBy.direction,
    })
  }, [showReporting, orderBy, debounceShowReporting])

  const readOnly = useMemo(
    () => dueDiligenceInfo?.clientStatus !== ClientInfoStatus.DueDiligence,
    [dueDiligenceInfo],
  )

  const { isLoading: isDiligenceLoading } = useLoadInfo({ id, info: dueDiligenceInfo, show })

  const handleUpdateReporting = useCallback(
    (type: OngoingReportingType, status: DueDiligenceReportingStatus) => {
      updateReporting(id, type, { status })
    },
    [updateReporting, id],
  )

  return (
    <div>
      <DueDiligencePageHeader isLoading={isDiligenceLoading} />
      <Grid container py={3} pr={2} alignItems="flex-start" justifyContent="center" rowSpacing={2}>
        {isLoadingInfo ? (
          <DueDiligenceProcessDocumentsPageLoader />
        ) : (
          <Grid
            container
            item
            xs={12}
            alignItems="flex-start"
            justifyContent="flex-start"
            className={styles.tableContainer}
            ml={3}
          >
            <TableContainer className={styles.documentTable}>
              <Table>
                <TableHead>
                  <TableFiltersRow
                    filters={DUE_DILIGENCE_LIST_FILTERS_CONFIG}
                    orderBy={orderBy}
                    handleOrderChange={handleOrderChange}
                  ></TableFiltersRow>
                </TableHead>
                <TableBody>
                  {isLoading ? (
                    <TableLoader columnsCount={5} rowsCount={10} height={36} />
                  ) : (
                    dueDiligenceReporting.map((reporting) => (
                      <DueDiligenceReportingRow
                        id={id}
                        key={reporting.id}
                        reporting={reporting}
                        updateReporting={handleUpdateReporting}
                        readOnly={readOnly}
                      />
                    ))
                  )}
                </TableBody>
              </Table>
              <Box display="flex" alignItems="center" justifyContent="flex-end">
                <SaveState isSaving={isSaving} isSaved={isSaved} />
              </Box>
            </TableContainer>
          </Grid>
        )}
      </Grid>
    </div>
  )
}

export default DueDiligenceProcessDocumentsPage
