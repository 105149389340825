import { combineReducers } from 'redux'
import * as actions from './actions'
import { IActivityData } from '@common/interfaces/activity'
import { ILoadingData } from '../types'
import { createLoadingDataReducer } from '../../helpers/redux'
import { CREATE_ACTIVITY, UPDATE_ACTIVITY, DELETE_ACTIVITY } from '@common/constants/webSockets'

export interface IActivityState {
  activities: ILoadingData<IActivityData>
}

export default combineReducers<IActivityState>({
  activities: createLoadingDataReducer<IActivityData>(
    [actions.LIST_ACTIVITY_REQUEST],
    [
      actions.CREATE_ACTIVITY_REQUEST,
      actions.UPDATE_ACTIVITY_REQUEST,
      actions.DELETE_ACTIVITY_REQUEST,
      CREATE_ACTIVITY,
      UPDATE_ACTIVITY,
      DELETE_ACTIVITY,
    ],
    true,
  ),
})
