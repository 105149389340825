import React from 'react'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import Skeleton from '@mui/material/Skeleton'

import Card from '../../components/Common/Card'

const SubmitReportingSectionItem = () => {
  return (
    <Grid item xs={12}>
      <Card noHeaderMargin>
        <Skeleton height={53} width={400} />
      </Card>
    </Grid>
  )
}

const SubmitReportingSection = () => {
  return (
    <Grid item xs={6}>
      <Grid item container xs={12} spacing={3} mb={2.5}>
        <Grid item xs={12}>
          <Skeleton width={200} height={36} />
        </Grid>
      </Grid>
      <Grid item container xs={12} spacing={3}>
        <SubmitReportingSectionItem />
        <SubmitReportingSectionItem />
        <SubmitReportingSectionItem />
        <SubmitReportingSectionItem />
        <SubmitReportingSectionItem />
        <SubmitReportingSectionItem />
      </Grid>
    </Grid>
  )
}
const SubmitReportingPageLoader = () => {
  return (
    <Grid container xs={12} spacing={3} mt={4.5}>
      <SubmitReportingSection />
      <Grid item xs={12}>
        <Box display="flex" justifyContent="flex-end">
          <Skeleton height={80} width={120} />
        </Box>
      </Grid>
    </Grid>
  )
}

export default SubmitReportingPageLoader
