import React, { useMemo } from 'react'
import { Route, Switch, Redirect } from 'react-router-dom'
import { matchPath } from 'react-router'
import Box from '@mui/material/Box'
import cn from 'classnames'

import styles from './AuthContainer.module.scss'

import LoginPage from '../../pages/LoginPage'
import ResetPasswordPage from '../../pages/ResetPasswordPage'
import PasswordRecoveryPage from '../../pages/PasswordRecoveryPage'
import ConfirmEmail from '../../pages/ConfirmEmail'
import PasswordSetUpPage from '../../pages/PasswordSetUpPage'
import ClientIntakePage from '../../pages/ClientIntakePage'
import { ROUTES } from '../../constants/routes'
import Loader from '../Loader'
import LoginBanner from '../../assets/images/login-banner.png'
import DwightLogo from '../../assets/images/dwight-text.svg'
import ErrorPage from '../../pages/ErrorPage'

interface IProps {
  isLoading: boolean
  isAuthenticated: boolean
  pathname: string
}

const AuthContainer = ({ isLoading, isAuthenticated, pathname }: IProps) => {
  const route = window.localStorage.getItem('return_url') || ROUTES.HOMEPAGE
  const isSetupContainer = useMemo(
    () => !matchPath(pathname, { path: [ROUTES.LOGIN, ROUTES.PASSWORD_SETUP] }),
    [pathname],
  )

  return (
    <Box
      className={cn({
        [styles.container]: !isSetupContainer,
        [styles.setupContainer]: isSetupContainer,
      })}
    >
      {isAuthenticated && !isLoading && <Redirect to={route} />}
      {isLoading && <Loader />}
      {!isAuthenticated && !isLoading && (
        <>
          {isSetupContainer ? (
            <div className={styles.topLogoContainer}>
              <img className={styles.dwightLogo} src={DwightLogo} alt="Dwight Funding" />
            </div>
          ) : (
            <div className={styles.logoContainer}>
              <img src={LoginBanner} alt="Banner" />
              <div className={styles.mottoWrapper}>
                <div className={styles.motto}>
                  <div>More than a lender,</div>
                  <div>we're a partner.</div>
                  <div className={styles.mottoInfo}>
                    We care about your success. We'll provide you with the expert insight and
                    network to supercharge your company when you need it most.
                  </div>
                </div>
              </div>
            </div>
          )}
          <Switch>
            <Route path={ROUTES.LOGIN} component={LoginPage} exact />
            <Route path={ROUTES.RESET_PASSWORD} component={ResetPasswordPage} exact />
            <Route path={ROUTES.PASSWORD_RECOVERY} component={PasswordRecoveryPage} exact />
            <Route path={ROUTES.CONFIRM_EMAIL} component={ConfirmEmail} />
            <Route path={ROUTES.PASSWORD_SETUP} component={PasswordSetUpPage} />
            <Route path={ROUTES.CLIENT_INTAKE} component={ClientIntakePage} />
            <Route path={ROUTES.GLOBAL_ERROR} component={ErrorPage} />
          </Switch>
          <a
            className={isSetupContainer ? styles.resetFooterLink : styles.footerLink}
            href="https://dwightfunding.com/privacy-policy/"
          >
            Privacy Policy
          </a>
          <div className={styles.copyright}>Copyright {new Date().getFullYear()}</div>
        </>
      )}
    </Box>
  )
}

export default AuthContainer
