import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import ProspectReportingReceivablesPage from './ProspectReportingReceivablesPage'

import { reporting, arSummary, date } from '../../redux/prospect/selectors'
import {
  show,
  startReporting,
  updateReportingFlowAdditionalData,
  listARSummary,
  updateARSummary,
  updateARDate,
  getARDate,
} from '../../redux/prospect/actions'

const selector = createStructuredSelector({
  reporting,
  arSummaryData: arSummary,
  date,
})
const actions = {
  listARSummary,
  updateARSummary,
  startReporting,
  updateDate: updateARDate,
  updateReportingFlowAdditionalData,
  getDate: getARDate,
  show,
}

export { ProspectReportingReceivablesPage }

export default connect(selector, actions)(ProspectReportingReceivablesPage)
