import React from 'react'
import cn from 'classnames'
import { ButtonProps } from '@mui/material/Button'

import Button from '../../Common/Button'
import { ReactComponent as PlusIcon } from '../../../assets/images/plus-simple-icon.svg'
import styles from './AddButton.module.scss'

interface IProps extends ButtonProps {
  children?: React.ReactNode
}

const AddButton = ({ children, disabled, size, ...restProps }: IProps) => {
  return (
    <Button
      color="primary"
      size="small"
      classes={{ root: styles.label }}
      className={cn(styles.root, { [styles.disabled]: disabled, [styles.big]: size === 'large' })}
      disabled={disabled}
      {...restProps}
    >
      <PlusIcon width="16" height="16" className={styles.icon} />
    </Button>
  )
}

export default AddButton
