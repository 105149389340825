import { connect } from 'react-redux'

import CollectionAggregationRow from './CollectionAggregationRow'

import { listAggregationRow } from '../../redux/collections/actions'

const actions = {
  listAggregationRow,
}

export default connect(null, actions)(CollectionAggregationRow)
