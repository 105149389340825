import { combineReducers } from 'redux'

import * as actions from './actions'
import { ILoadingData } from '../types'
import { createLoadingDataReducer } from '../../helpers/redux'
import {
  IBrandOptions,
  IClientNewsfeedData,
  INewsfeedData,
  INewsfeedItemData,
} from '@common/interfaces/newsfeed'

export interface INewsfeedState {
  clientNewsfeedData: ILoadingData<IClientNewsfeedData>
  brandOptions: ILoadingData<IBrandOptions>
  newsfeedData: ILoadingData<INewsfeedData>
  newsfeedItem: ILoadingData<INewsfeedItemData>
}

export default combineReducers<INewsfeedState>({
  newsfeedData: createLoadingDataReducer<INewsfeedData>(
    [actions.LIST_NEWSFEED_REQUEST],
    [actions.UPDATE_NEWSFEED_REQUEST, actions.DELETE_NEWSFEED_REQUEST],
    true,
  ),
  clientNewsfeedData: createLoadingDataReducer<IClientNewsfeedData>([
    actions.LIST_CLIENT_NEWSFEED_REQUEST,
  ]),

  brandOptions: createLoadingDataReducer<IBrandOptions>([actions.LIST_BRAND_OPTIONS_REQUEST]),

  newsfeedItem: createLoadingDataReducer<INewsfeedItemData>([actions.SHOW_NEWSFEED_REQUEST]),
})
