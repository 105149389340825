import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'

import RiskRatingVisualization from './RiskRatingVisualization'

import { listRiskRating } from '../../../redux/clientInfo/actions'
import { riskRatings } from '../../../redux/clientInfo/selectors'

const selector = createStructuredSelector({
  riskRatings,
})

const actions = {
  listRiskRating,
}

export default connect(selector, actions)(RiskRatingVisualization)
