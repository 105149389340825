import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'

import ProspectReportingSummaryInventory from './ProspectReportingSummaryInventory'

import { inventorySummaryInfo } from '../../redux/prospect/selectors'
import { showInventorySummaryInfo } from '../../redux/prospect/actions'

const selector = createStructuredSelector({
  inventorySummaryInfo,
})

const actions = {
  showInventorySummaryInfo,
}

export default connect(selector, actions)(ProspectReportingSummaryInventory)
