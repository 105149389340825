import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'

import ProspectSetupPage from './ProspectSetupPage'

import { isLoading } from '../../redux/prospect/selectors'
import { createProspect } from '../../redux/prospect/actions'

const selector = createStructuredSelector({
  isLoading,
})
const actions = {
  createProspect,
}

export { ProspectSetupPage }

export default connect(selector, actions)(ProspectSetupPage)
