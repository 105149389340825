import React, { useCallback, useMemo } from 'react'
import { generatePath, useHistory, useParams, matchPath } from 'react-router'
import queryString from 'query-string'

import Tabs from '../Tabs'

interface IProps {
  tabs: { [key: string]: string | string[] }
  menu?: { [key: string]: Array<{ label: string; route: string }> }
  actionsRequired?: { [key: string]: boolean | boolean[] }
  isQueryString?: boolean
}

const TabsWithRoutesMenu = ({ tabs, menu, actionsRequired, isQueryString = true }: IProps) => {
  const history = useHistory()
  const { id } = useParams<{ id: string }>()

  const tabsList = useMemo(() => Object.keys(tabs), [tabs])

  const selectedTab = useMemo(() => {
    if (!tabsList.length) return null

    const searchParams = queryString.parse(history.location.search)
    const currentTabFromQuery = searchParams.tab as string

    const exactPath = isQueryString
      ? `${history.location.pathname}${history.location.search}`
      : history.location.pathname

    if (isQueryString && currentTabFromQuery && tabs[currentTabFromQuery]) {
      return currentTabFromQuery
    }

    const currentTab = tabsList.find((tab) => {
      if (Array.isArray(tabs[tab])) {
        return (tabs[tab] as string[]).some((route) => {
          const match = matchPath(exactPath, { path: route, exact: true })
          return match?.isExact
        })
      }
      const match = matchPath(exactPath, { path: tabs[tab], exact: true })
      return !!match
    })

    return currentTab || tabsList[0]
  }, [tabs, tabsList, history.location.pathname, history.location.search, isQueryString])

  const onTabChange = useCallback(
    (tab) => {
      if (Array.isArray(tabs[tab])) {
        return null
      } else {
        history.push(generatePath(tabs[tab] as string, { id }))
      }
    },
    [tabs, history, id],
  )

  const handleClickMenuItem = useCallback(
    (tab: string, menuItem: string) => {
      const selectedMenu = menu[tab].filter(
        (item: { label: string; route: string }) => item.label === menuItem,
      )[0]
      if (selectedMenu) {
        history.push(generatePath(selectedMenu.route, { id }))
      }
    },
    [history, id, menu],
  )

  return (
    <Tabs
      tabs={tabsList}
      selected={selectedTab}
      handleChange={onTabChange}
      menu={menu}
      handleClickMenuItem={handleClickMenuItem}
      actionsRequired={actionsRequired}
    />
  )
}

export default TabsWithRoutesMenu
