import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'

import BBCEditModal from './BBCEditModal'

import { bbc } from '../../redux/bbc/selectors'
import { updateBBC } from '../../redux/bbc/actions'

const selector = createStructuredSelector({
  bbc,
})

const actions = {
  updateBBC,
}

export default connect(selector, actions)(BBCEditModal)
