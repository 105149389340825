import { LOAN_TYPES } from '../constants/client'
import { clientTermsInfo, IClientInfo } from '../interfaces/client'
import {
  DUE_DILIGENCE_TABLE_ITEM_FIELD_LABELS,
  DUE_DILIGENCE_TABLE_ITEM_REQUIRED_FIELDS,
  DueDiligenceTableItemType,
  IDueDiligenceContact,
  IDueDiligenceExecutive,
  IDueDiligenceInventoryLocation,
} from '../interfaces/dueDiligence'

export const textToTitleCase = (text: string) =>
  text
    .toLowerCase()
    .split(' ')
    .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
    .join(' ')

export const formatCamelCaseToRegular = (value: string) => {
  return value.replace(/([A-Z])/g, ' $1').replace(/^./, (str) => {
    return str.toUpperCase()
  })
}

export const getClientTermTitle = (termName: string) => {
  const camelCaseTerm = snakeToCamelCase(termName)
  const termInfo = clientTermsInfo.find((term) => term.value === camelCaseTerm)
  return termInfo?.label || formatCamelCaseToRegular(camelCaseTerm)
}

export const isEmpty = (value: any) => !value && value !== 0

export const urlBase64ToUint8Array = (base64String: string): Uint8Array => {
  const padding = '='.repeat((4 - (base64String.length % 4)) % 4)
  const base64 = (base64String + padding).replace(/\-/g, '+').replace(/_/g, '/')

  const rawData = window.atob(base64)
  const outputArray = new Uint8Array(rawData.length)

  for (let i = 0; i < rawData.length; ++i) {
    outputArray[i] = rawData.charCodeAt(i)
  }
  return outputArray
}

export const camelToSnakeCase = (str: string) =>
  str.replace(/[A-Z]/g, (letter) => `_${letter.toLowerCase()}`)

export const snakeToCamelCase = (str: string) =>
  str.toLowerCase().replace(/[-_][a-z]/g, (group) => group.slice(-1).toUpperCase())

export const snakeToTitleCase = (str: string) =>
  str
    .split('_')
    .map((word) => textToTitleCase(word))
    .join(' ')

export const escapeLikeString = (raw: string, escapeChar = '\\'): string =>
  raw.replace(/[\\%_]/g, (match) => escapeChar + match)

export const addHttp = (url: string) => {
  if (!/^(?:f|ht)tps?:\/\//.test(url)) {
    return `https://${url}`
  }
  return url
}

export const getDueDiligenceTableItemMissingFields = (
  type: DueDiligenceTableItemType,
  item: any,
) => {
  const missingFields: string[] = []
  const requiredFields: string[] = DUE_DILIGENCE_TABLE_ITEM_REQUIRED_FIELDS[type] || []

  requiredFields.forEach((field) => {
    if (!item[field]) {
      missingFields.push(DUE_DILIGENCE_TABLE_ITEM_FIELD_LABELS[field])
    }
  })

  switch (type) {
    case DueDiligenceTableItemType.Contact:
      {
        const contact = item as IDueDiligenceContact

        if (
          contact.isResponsibleRequestingFunding === null ||
          contact.isAuthorizedSignature === null
        ) {
          missingFields.push('Funding authorization')
        }
      }
      break
    case DueDiligenceTableItemType.Executive:
      {
        const executive = item as IDueDiligenceExecutive
        if (
          executive.isConvictedFelony === null ||
          executive.isDefaultedFinancialObligation === null ||
          executive.isPersonalBankruptcy === null
        ) {
          missingFields.push('Personal history')
        }

        if (
          (executive.isConvictedFelony && !executive.isConvictedFelonyComment) ||
          (executive.isDefaultedFinancialObligation &&
            !executive.isDefaultedFinancialObligationComment) ||
          (executive.isPersonalBankruptcy && !executive.isPersonalBankruptcyComment)
        ) {
          missingFields.push('Personal history detail')
        }
      }
      break
    case DueDiligenceTableItemType.InventoryLocation:
      {
        const inventoryLocation = item as IDueDiligenceInventoryLocation
        if (
          ![
            inventoryLocation.street,
            inventoryLocation.city,
            inventoryLocation.state,
            inventoryLocation.postalCode,
            inventoryLocation.country,
          ].filter(Boolean).length
        ) {
          missingFields.push('Address')
        }
      }
      break
  }

  return missingFields
}

export const sleep = async (ms: number) => new Promise((resolve) => setTimeout(resolve, ms))

export const retryPromise = async (promise: any, retries: number): Promise<any> => {
  try {
    const data = await promise
    return data
  } catch (e) {
    if (retries === 1) {
      return Promise.reject(e)
    }

    return retryPromise(promise, retries - 1)
  }
}

export const humanReadableFileSize = (
  sizeInBytes: number,
  precise: boolean = false,
): [string, string] => {
  if (sizeInBytes === 0) return ['0', 'B']

  const exp = Math.floor(Math.log(sizeInBytes) / Math.log(1024))
  return [
    (sizeInBytes / Math.pow(1024, exp)).toFixed(precise ? 2 : 0),
    ['B', 'KB', 'MB', 'GB', 'TB'][exp],
  ]
}

export const processPromisesBatch = async (
  items: Array<any>,
  limit: number,
  fn: (item: any) => Promise<any>,
  timeout?: number,
): Promise<any> => {
  let results: any[] = []
  for (let start = 0; start < items.length; start += limit) {
    const end = start + limit > items.length ? items.length : start + limit

    const slicedResults = await Promise.all(items.slice(start, end).map(fn))

    timeout && (await sleep(timeout))

    results = [...results, ...slicedResults]
  }

  return results
}

export const isDefined = <T>(value: T | null | undefined): value is NonNullable<T> => {
  return value !== null && value !== undefined
}

export const htmlToText = (html: string) => {
  return html.replace(/<[^>]*>?/gm, '').replace(/&nbsp;/g, ' ')
}

export const rejectWithTimeLimit = async (timoutMs: number, task: any, onReject?: () => void) => {
  let timeout
  const timeoutPromise = new Promise((resolve, reject) => {
    timeout = setTimeout(() => {
      onReject && onReject()
      reject('ERR_TIMEOUT')
    }, timoutMs)
  })

  const response = await Promise.race([task, timeoutPromise])

  if (timeout) {
    clearTimeout(timeout)
  }

  return response
}

export const checkIsLoanStructureComplete = (clientInfo: Partial<IClientInfo>) => {
  if (!clientInfo) {
    return false
  }

  let isARComplete = true
  let isInventoryComplete = true
  if (
    clientInfo.loanType !== LOAN_TYPES.inventory &&
    (isEmpty(clientInfo.arAdvance) ||
      isEmpty(clientInfo.pastInvoiceThreshold) ||
      isEmpty(clientInfo.crossAge))
  ) {
    isARComplete = false
  }
  if (
    clientInfo.loanType !== LOAN_TYPES.ar &&
    isEmpty(clientInfo.inventoryAdvanceRateCost) &&
    (isEmpty(clientInfo.nolv) || isEmpty(clientInfo.inventoryAdvanceRateNolv))
  ) {
    isInventoryComplete = false
  }

  return isARComplete && isInventoryComplete
}
