import { useEffect, useMemo } from 'react'
import { useDispatch } from 'react-redux'

import { trackVisualizations } from '../redux/visualizationsTracking/actions'
import { EVENTS } from '@common/constants/tracking'
import { debounceEventHandler } from '../helpers/helpers'

interface IProps {
  category: string
  params: object
  currentTab: string
}

const useTrackVisualizationsTabSelection = ({ category, params, currentTab }: IProps) => {
  const dispatch = useDispatch()

  const trackTabChange = useMemo(
    () =>
      debounceEventHandler((currentTab: string) => {
        if (currentTab) {
          dispatch(
            trackVisualizations(EVENTS.tab, category, {
              ...(params || {}),
              data: {
                tab: currentTab,
              },
            }),
          )
        }
      }, 500),
    [category, dispatch, params],
  )

  useEffect(() => {
    trackTabChange(currentTab)
  }, [trackTabChange, currentTab])

  return {}
}

export default useTrackVisualizationsTabSelection
