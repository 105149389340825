import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'

import ClientCashFlow from './ClientCashFlow'

import { cashFlowInfo } from '../../../redux/clientInfo/selectors'
import { showCashFlowInfo } from '../../../redux/clientInfo/actions'

const selector = createStructuredSelector({
  cashFlowInfo,
})

const actions = {
  showCashFlowInfo,
}

export default connect(selector, actions)(ClientCashFlow)
