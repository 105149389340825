import React, { useMemo } from 'react'
import { formatPercent, formatPrice } from '../../helpers/helpers'
import { IEntityInfo } from '@common/interfaces/entityInfo'
import Grid from '@mui/material/Grid'
import styles from './EntityDashboardHeader.module.scss'
import { ReactComponent as MetricSummary } from '../../assets/images/chart-line.svg'

interface IProps {
  entityInfo: IEntityInfo
  currentTab: string
}

const EntityDashboardSummaryMetrics = ({ entityInfo, currentTab }: IProps) => {
  const customer = useMemo(() => {
    return currentTab === 'Customer'
  }, [currentTab])

  const currentReport = useMemo(() => {
    return customer ? 'AR' : 'AP'
  }, [customer])
  const isAR = useMemo(() => currentReport === 'AR', [currentReport])

  const { totalAmount, totalNearAged, totalAged } = useMemo(() => {
    let totalAmount = 0
    let totalNearAged = 0
    let totalAged = 0
    if (isAR) {
      totalAmount = entityInfo?.totalAr
      totalNearAged = entityInfo?.ar61To90Days
      totalAged = entityInfo?.ar91PlusDays
    } else {
      totalAmount = entityInfo?.totalAp
      totalNearAged = entityInfo?.ap61To90Days
      totalAged = entityInfo?.totalAp91Plus
    }
    return { totalAmount, totalNearAged, totalAged }
  }, [isAR, entityInfo])

  return (
    <Grid item className={styles.headerContainer} xs={6} lg={6}>
      <div className={styles.dashboardBlock}>
        <div className={styles.dashboardBlockTitle}>
          <MetricSummary className={styles.dashboardBlockContentIcon} />
          Summary Metrics
        </div>
        <Grid container spacing={6} className={styles.gridContainer}>
          <Grid item xs={12} lg={12} className={styles.blockContainer}>
            <div className={styles.gridContainerWrapper}>
              {isAR && (
                <div className={styles.dashboardBlockContent}>
                  <div className={styles.dashboardBlockContentLabel}>Total Exposure</div>
                  <div className={styles.dashboardBlockContentValueNumbers}>
                    {'$' + formatPrice(entityInfo?.totalExposure)}
                  </div>
                </div>
              )}
              <div className={styles.dashboardBlockContent}>
                <div className={styles.dashboardBlockContentLabel}>Total {currentReport}</div>
                <div className={styles.dashboardBlockContentValue}>
                  {'$' + formatPrice(totalAmount)}
                </div>
              </div>
              <div className={styles.dashboardBlockContent}>
                <div className={styles.dashboardBlockContentLabel}>61-90 Days {currentReport}</div>
                <div className={styles.dashboardBlockContentValue}>
                  {'$' + formatPrice(totalNearAged)}
                </div>
              </div>
              <div className={styles.dashboardBlockContent}>
                <div className={styles.dashboardBlockContentLabel}>91+ Days {currentReport}</div>
                <div className={styles.dashboardBlockContentValue}>
                  {'$' + formatPrice(totalAged)}
                </div>
              </div>
            </div>
          </Grid>

          <Grid item xs={12} lg={12} className={styles.blockContainerNotInitial}>
            <div className={styles.gridContainerWrapperMetrics}>
              <div className={styles.dashboardBlockContent}>
                <div className={styles.dashboardBlockContentLabel}>Collections (Last 90 Days)</div>
                <div className={styles.dashboardBlockContentValueNumbers}>
                  {'$' + formatPrice(entityInfo?.totalCollections)}
                </div>
              </div>

              {customer && (
                <div className={styles.dashboardBlockContent}>
                  <div className={styles.dashboardBlockContentLabel}>Avg. DSO (Last 90 Days)</div>
                  <div className={styles.dashboardBlockContentValueNumbers}>
                    {entityInfo?.dso ? entityInfo?.dso.toFixed(2) : '-'}
                  </div>
                </div>
              )}
              {!customer && (
                <div className={styles.dashboardBlockContent}>
                  <div className={styles.dashboardBlockContentLabel}>New Bills (Last 90 Days)</div>
                  <div className={styles.dashboardBlockContentValueNumbers}>
                    {formatPrice(entityInfo?.newBills)}
                  </div>
                </div>
              )}
            </div>
          </Grid>

          {customer && (
            <Grid item xs={12} lg={12} className={styles.blockContainerNotInitial}>
              <div className={styles.gridContainerWrapperMetricsLastRow}>
                <div className={styles.dashboardBlockContent}>
                  <div className={styles.dashboardBlockContentLabel}>
                    New Invoices (Last 90 days)
                  </div>
                  <div className={styles.dashboardBlockContentValueNumbers}>
                    {'$' + formatPrice(entityInfo?.newInvoices)}
                  </div>
                </div>
                <div className={styles.dashboardBlockContent}>
                  <div className={styles.dashboardBlockContentLabel}>Dilution % (Last 90 Days)</div>
                  <div className={styles.dashboardBlockContentValueNumbers}>
                    {formatPercent(entityInfo?.dilution)}
                  </div>
                </div>
              </div>
            </Grid>
          )}
        </Grid>
      </div>
    </Grid>
  )
}

export default EntityDashboardSummaryMetrics
