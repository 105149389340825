import React, { useMemo } from 'react'
import Avatar from '@mui/material/Avatar'

import styles from './DueDiligenceClientDashboard.module.scss'
import { ReactComponent as MailIcon } from '../../assets/images/mail-icon.svg'
import { ReactComponent as CallIcon } from '../../assets/images/call-icon.svg'

import { IUser } from '@common/interfaces/user'

interface IProps {
  manager: IUser
}

const DueDiligenceQuestionsManager = ({ manager }: IProps) => {
  return (
    <div className={styles.diligenceQuestionsManagerItem} key={manager.id}>
      <Avatar
        alt={`${manager.firstName} ${manager.lastName}`}
        variant="circular"
        src={manager.avatar}
        className={styles.diligenceQuestionsManagerItemAvatar}
      >
        {manager.firstName[0]}
        {manager.lastName[0]}
      </Avatar>
      <div className={styles.diligenceQuestionsManagerItemContacts}>
        <div className={styles.diligenceQuestionsManagerItemContactsName}>{manager.firstName}</div>
        <div className={styles.diligenceQuestionsManagerItemContactsData}>
          <MailIcon />
          <a
            target="_blank"
            rel="noopener noreferrer"
            href={`mailto:${manager.email}`}
            className={styles.contactLink}
          >
            {manager.email}
          </a>
        </div>
        {manager.phone && (
          <div className={styles.diligenceQuestionsManagerItemContactsData}>
            <CallIcon />
            <a
              target="_blank"
              rel="noopener noreferrer"
              href={`tel:${manager.phone}`}
              className={styles.contactLink}
            >
              {manager.phone}
            </a>
          </div>
        )}
      </div>
    </div>
  )
}
const DueDiligenceClientDashboardQuestions = ({ managers }: { managers: IUser[] }) => {
  const mangersNames = useMemo(
    () => managers.map(({ firstName }) => firstName).join(' & '),
    [managers],
  )

  if (!managers.length) {
    return null
  }

  return (
    <div className={styles.diligenceQuestions}>
      <div className={styles.diligenceQuestionsText}>
        <div className={styles.diligenceQuestionsTextTitle}>Question ?</div>
        <div className={styles.diligenceQuestionsTextDescription}>
          For any question or inquiries please reach out to {mangersNames}
        </div>
      </div>
      <div className={styles.diligenceQuestionsManagers}>
        {managers.map((manager) => (
          <DueDiligenceQuestionsManager key={manager.id} manager={manager} />
        ))}
      </div>
    </div>
  )
}

export default DueDiligenceClientDashboardQuestions
