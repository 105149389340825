import React, { useMemo } from 'react'
import { ClientERPSystem, IClientInfo } from '@common/interfaces/client'
import Box from '@mui/material/Box'
import styles from './Integrations.module.scss'
import Grid from '@mui/material/Grid'
import { ReactComponent as QuickbooksIcon } from '@assets/images/quickbooks-icon.svg'
import Integration from './Integration'

const integrations = [
  {
    name: ClientERPSystem.QuickbooksOnline,
    description: 'ERP',
    icon: <QuickbooksIcon />,
    isConnected: false,
    manageLink: '',
    connectLink: '',
  },
]
interface IProps {
  clientInfo: IClientInfo
}

const Integrations = ({ clientInfo }: IProps) => {
  const newIntegrations = useMemo(() => {
    return integrations.map((integration) => {
      if (integration.name === ClientERPSystem.QuickbooksOnline) {
        return {
          ...integration,
          isConnected:
            clientInfo?.isErpConnected && clientInfo?.erpName === ClientERPSystem.QuickbooksOnline,
          manageLink: clientInfo?.codatLink,
          connectLink: clientInfo?.codatLink,
        }
      }
      if (integration.name === ClientERPSystem.Netsuite) {
        return {
          ...integration,
          isConnected:
            clientInfo?.isErpConnected && clientInfo?.erpName === ClientERPSystem.Netsuite,
          manageLink: clientInfo?.codatLink,
          connectLink: clientInfo?.codatLink,
        }
      }
      return integration
    })
  }, [clientInfo])

  return (
    <Box className={styles.wrapper}>
      <h3 className={styles.heading}>App integrations</h3>
      <p className={styles.description}>Authorize Dwight Funding to pull reports on your behalf.</p>
      <Grid container alignItems="center" justifyContent={'space-between'}>
        {newIntegrations.map((integration) => (
          <Integration key={integration.name} integration={integration} />
        ))}
      </Grid>
    </Box>
  )
}

export default Integrations
