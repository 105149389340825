import React from 'react'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import Button from '../Common/Button'
import { ReactComponent as EmptyIcon } from '../../assets/images/empty-page-icon.svg'
import { OngoingReportingType, ONGOING_REPORTING_TYPE_LABEL } from '@common/interfaces/bbc'
interface IProps {
  reportType: OngoingReportingType
  openFileSelectModal: () => void
}

const OPSWaivedDocument = ({ reportType, openFileSelectModal }: IProps) => {
  return (
    <Box display="flex" justifyContent="center" alignItems="center" height="60vh">
      <Grid container spacing={2} justifyContent={'center'}>
        <Grid container item xs={12} justifyContent={'center'}>
          <EmptyIcon />
        </Grid>
        <Grid container item xs={12} justifyContent={'center'}>
          <h3>{ONGOING_REPORTING_TYPE_LABEL[reportType]} is marked as waived</h3>
        </Grid>
        <Grid container item xs={12} justifyContent={'center'}>
          <Button
            type="button"
            color="primary"
            variant="contained"
            small={false}
            onClick={openFileSelectModal}
          >
            Select file
          </Button>
        </Grid>
      </Grid>
    </Box>
  )
}

export default OPSWaivedDocument
