import React, { useState, useEffect, useCallback, useMemo, useRef } from 'react'
import { Form, FormRenderProps } from 'react-final-form'
import cn from 'classnames'
import InfiniteScroll from 'react-infinite-scroll-component'
import { FieldArray } from 'react-final-form-arrays'
import arrayMutators from 'final-form-arrays'
import Box from '@mui/material/Box'
import IconButton from '@mui/material/IconButton'
import moment from 'moment'
import { makeValidate } from 'mui-rff'
import * as Yup from 'yup'

import styles from './DeletedCheckTable.module.scss'
import genericSs from '@styles/generic.module.scss'

import ActiveToolbar from '../ActiveToolbar'
import {
  debounceEventHandler,
  formatPrice,
  dateToString,
  formatDate,
  formatter,
} from '../../helpers/helpers'
import TableContainer from '../Common/TableContainer'
import Table from '../Common/Table'
import TableHead from '../Common/TableHead'
import TableBody from '../Common/TableBody'
import TableRow from '../Common/TableRow'
import TableCell from '../Common/TableCell'
import TableFiltersRow from '../Common/TableFiltersRow'
import { IDeletedItems } from '@common/interfaces/client'
import Button from '../Common/Button'
import { ReactComponent as CheckIcon } from '../../assets/images/outline-check-rectangle.svg'
import { ReactComponent as ArrowRightIcon } from '../../assets/images/outline-arrow-right.svg'
import { ReactComponent as DeleteIcon } from '../../assets/images/delete-icon.svg'
import Tooltip from '@mui/material/Tooltip'
import { DATE_FORMAT } from '../../constants/common'
import KeyboardDatePicker from '../Common/KeyboardDatePicker'
import { buildFiltersDefaults, buildFiltersValidateSchema } from '../../helpers/filters'
import { DELETED_COLLECTIONS_LIST_FILTERS_CONFIG, PER_PAGE } from '@common/constants/filters'
import FilterContainer from '../Filters/FilterContainer'
import { FormApi } from 'final-form'
import { OnChange } from 'react-final-form-listeners'
import { ILoadingData } from '../../redux/types'
import TableLoader from '../../components/Common/TableLoader'
import Modal from '../Common/Modal'
import InputLabel from '../Common/InputLabel'
import ModalButton from '../Common/Button/Button'
import TextField from '../Common/TextField'
import CurrencyField from '../Common/CurrencyField'
import WarningModal from '../WarningModal'
import AddButton from '../Client/AddButton'
import useTable from '../../hooks/useTable'

const filtersValidate = buildFiltersValidateSchema(DELETED_COLLECTIONS_LIST_FILTERS_CONFIG)
const filtersDefaults = buildFiltersDefaults(DELETED_COLLECTIONS_LIST_FILTERS_CONFIG, {
  status: ['notsent'],
})
const mutators = {
  ...arrayMutators,
}
const today = moment().startOf('day').toDate()

const DeletedCheckRow = ({
  index,
  item,
  values,
  isActiveRow,
  isCurrentActiveRow,
  isActionsAvailable,
  onSelectRow,
  onSentDeleted,
  onDeleteDeleted,
  loanBalanceStartDate,
  setDate,
}: {
  index: number
  item: any
  values: any
  isActiveRow: boolean
  isCurrentActiveRow: boolean
  isActionsAvailable: boolean
  onSelectRow: (event: any, index: number) => void
  setDate: (index: number, date: any) => void
  onSentDeleted: (values: any) => void
  onDeleteDeleted: (values: any) => void
  loanBalanceStartDate: string
}) => {
  const [itemDate, setItemDate] = useState(today)

  const handleSelectRow = useCallback((event) => onSelectRow(event, index), [index, onSelectRow])
  const handleSentDeleted = useCallback(() => {
    onSentDeleted({ itemDate, ...values })
  }, [onSentDeleted, values, itemDate])
  const handleDeleteDeleted = useCallback(() => {
    onDeleteDeleted(values)
  }, [onDeleteDeleted, values])
  const handleChangeDate = useCallback(
    (index, value) => {
      setDate(index, value)
      setItemDate(value)
    },
    [setDate],
  )

  if (!item) {
    return null
  }

  return (
    <TableRow
      data-index={index}
      className={cn('activableRow', {
        activeRow: isActiveRow,
        currentActiveRow: isCurrentActiveRow,
      })}
      onClick={handleSelectRow}
    >
      <TableCell className={genericSs.tableTextLeft}>
        <Tooltip title={item.description} placement="top" disableTouchListener>
          <span>{item.description}</span>
        </Tooltip>
      </TableCell>
      <TableCell className={genericSs.tableTextRight}>
        <span className={genericSs.pricePrefix}>$</span>
        {formatPrice(item.credit)}
      </TableCell>
      <TableCell className={genericSs.tableTextRight}>
        <span className={genericSs.pricePrefix}>$</span>
        {formatPrice(item.debit)}
      </TableCell>
      <TableCell className={genericSs.tableTextRight}>
        {item.originalRecordDate ? formatDate(item.originalRecordDate) : ''}
      </TableCell>
      <TableCell className={genericSs.tableTextRight}>
        {item.isSent ? (
          formatDate(item.recordDate)
        ) : (
          <div>
            <KeyboardDatePicker
              name={`deletedItems[${index}].recordDate`}
              inputFormat={DATE_FORMAT}
              maxDate={today}
              minDate={moment(loanBalanceStartDate || undefined).toDate()}
            />
            <OnChange name={`deletedItems[${index}].recordDate`}>
              {(value) => handleChangeDate(index, value)}
            </OnChange>
          </div>
        )}
      </TableCell>
      <TableCell className={genericSs.tableTextLeft}>
        {item.isSent ? (
          <Button
            startIcon={<CheckIcon />}
            small
            variant="outlined"
            className={styles.deletedTableSentButton}
          >
            Returned
          </Button>
        ) : (
          isActionsAvailable && (
            <Button
              startIcon={<ArrowRightIcon />}
              small
              color="primary"
              variant="outlined"
              onClick={handleSentDeleted}
              className={styles.sendButton}
            >
              Return
            </Button>
          )
        )}
        {isActionsAvailable && !item.isSent && (
          <IconButton
            onClick={handleDeleteDeleted}
            color="primary"
            disableFocusRipple
            disableRipple
            disableTouchRipple
          >
            <DeleteIcon />
          </IconButton>
        )}
      </TableCell>
    </TableRow>
  )
}

const DeletedCheckTableForm = ({
  form,
  values,
  formRef,
  loadMore,
  deletedItems,
  activeItems,
  activeItem,
  handleSelectRow,
  handleSentDeleted,
  handleDeleteDeleted,
  loanBalanceStartDate,
  isLoading,
  setDate,
}: FormRenderProps<any> & {
  formRef: React.MutableRefObject<FormApi<any, Partial<any>>>
  loadMore: () => void
  deletedItems: IDeletedItems
  activeItems: number[]
  activeItem: number
  handleSelectRow: (event: any, index: number) => void
  handleSentDeleted: (item: any) => void
  handleDeleteDeleted: (item: any) => void
  loanBalanceStartDate: string
  isLoading: boolean
  setDate: (index: number, date: any) => void
}) => {
  formRef.current = form

  return (
    <TableBody id="scrollableTableDeleted">
      {isLoading ? (
        <TableLoader columnsCount={6} height={26} />
      ) : (
        <InfiniteScroll
          dataLength={deletedItems?.data.length}
          next={loadMore}
          hasMore={deletedItems?.data.length < deletedItems?.totals.totalItems}
          loader={
            <TableRow>
              <TableCell colSpan={6}>Loading...</TableCell>
            </TableRow>
          }
          scrollableTarget="scrollableTableDeleted"
        >
          <FieldArray name="deletedItems">
            {({ fields }) =>
              fields.map((name, index) => (
                <DeletedCheckRow
                  key={deletedItems.data[index]?.id}
                  index={index}
                  item={deletedItems.data[index]}
                  values={values.deletedItems[index]}
                  isActiveRow={activeItems.includes(index)}
                  isCurrentActiveRow={activeItem === index}
                  isActionsAvailable={activeItems.length <= 1}
                  onSelectRow={handleSelectRow}
                  onSentDeleted={handleSentDeleted}
                  onDeleteDeleted={handleDeleteDeleted}
                  loanBalanceStartDate={loanBalanceStartDate}
                  setDate={setDate}
                />
              ))
            }
          </FieldArray>
        </InfiniteScroll>
      )}
    </TableBody>
  )
}

interface IProps {
  deletedItems: ILoadingData<IDeletedItems>
  listDeletedItems: (params?: { page?: number; perPage?: number }) => void
  sentDeletedItems: (data: object) => Promise<void>
  createDeletedItem: (data: object) => Promise<any>
  deleteDeletedItems: (data: object) => Promise<void>
  loanBalanceStartDate: string
}

const DeletedCheckTable = ({
  listDeletedItems,
  deletedItems,
  sentDeletedItems,
  createDeletedItem,
  deleteDeletedItems,
  loanBalanceStartDate,
}: IProps) => {
  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false)
  const [isCreating, setIsCreating] = useState(false)
  const { isLoading, deletedItemsData } = useMemo(
    () => ({
      isLoading: deletedItems.isLoading,
      deletedItemsData: deletedItems?.data,
    }),
    [deletedItems],
  )

  const wrapperRef = useRef(null)
  const formRef = useRef(null)

  const {
    filters,
    handleFiltersChange,
    handleOrderChange,
    orderBy,
    activeItem,
    activeItems,
    setActiveItem,
    setActiveItems,
    resetActiveItems,
    handleSelectRow,
    quickFilter,
    handleQuickFilterChange,
  } = useTable({
    tableId: 'nonClientRelatedCash',
    filtersDefaults,
    sortDefault: {
      field: 'record_date',
      direction: 'DESC',
    },
    quickFilterDefault: 'Unreturned',
  })
  const itemsCount = deletedItemsData?.data.length
  const [isReturnButtonLoading, setIsReturnButtonLoading] = useState(false)
  const [isDeleting, setIsDeleting] = useState(false)
  const [isDeleteModalShow, setIsDeleteModalShow] = useState(false)
  const [isReturnModalShow, setIsReturnModalShow] = useState(false)
  const [selectedItems, setSelectedItems] = useState([])

  const fetchDeletedItemsList = useCallback(
    (data: any) => {
      const params = {
        ...data,
        filters: {
          ...data.filters,
        },
      }
      if (
        params?.filters.originalRecordDateFrom &&
        typeof params.filters.originalRecordDateFrom !== 'string'
      ) {
        params.filters.originalRecordDateFrom = dateToString(params.filters.originalRecordDateFrom)
      }
      if (
        params?.filters.originalRecordDateTo &&
        typeof params.filters.originalRecordDateTo !== 'string'
      ) {
        params.filters.originalRecordDateTo = dateToString(params.filters.originalRecordDateTo)
      }
      if (params?.filters.recordDateFrom && typeof params.filters.recordDateFrom !== 'string') {
        params.filters.recordDateFrom = dateToString(params.filters.recordDateFrom)
      }
      if (params?.filters.recordDateTo && typeof params.filters.recordDateTo !== 'string') {
        params.filters.recordDateTo = dateToString(params.filters.recordDateTo)
      }
      listDeletedItems(params)
    },
    [listDeletedItems],
  )

  const debounceListDeletedItems = useMemo(
    () => debounceEventHandler(fetchDeletedItemsList, 500),
    [fetchDeletedItemsList],
  )

  useEffect(() => {
    debounceListDeletedItems({
      page: 0,
      perPage: PER_PAGE,
      filters,
      orderBy: orderBy.field,
      orderDirection: orderBy.direction,
    })
  }, [filters, orderBy, debounceListDeletedItems])

  const refetchDeletedItemsList = useCallback(
    (withNew = false) => {
      fetchDeletedItemsList({
        page: 0,
        perPage: withNew ? itemsCount + 1 : itemsCount,
        filters,
        orderBy: orderBy.field,
        orderDirection: orderBy.direction,
      })
    },
    [itemsCount, filters, orderBy, fetchDeletedItemsList],
  )

  const handleSentDeleted = useCallback((item: any) => {
    setSelectedItems([
      {
        id: item.id,
        recordDate: item.itemDate,
      },
    ])
    setIsReturnModalShow(true)
  }, [])

  const handleDeleteDeleted = useCallback((item: any) => {
    setSelectedItems([
      {
        id: item.id,
      },
    ])
    setIsDeleteModalShow(true)
  }, [])

  const handleDelete = useCallback(() => {
    setSelectedItems(
      deletedItemsData?.data
        .filter((_, index) => activeItems.includes(index))
        .map((item) => ({
          id: item.id,
        })),
    )
    setIsDeleteModalShow(true)
  }, [deletedItemsData, activeItems])

  const handleDeleteDeletedConfirm = useCallback(async () => {
    setIsDeleting(true)
    await deleteDeletedItems({
      data: selectedItems,
    })
    setSelectedItems([])
    setIsDeleteModalShow(false)
    setIsDeleting(false)
    refetchDeletedItemsList()
  }, [selectedItems, deleteDeletedItems, refetchDeletedItemsList])

  const handleDeleteDeletedCancel = useCallback(() => {
    setSelectedItems([])
    setIsDeleteModalShow(false)
  }, [])

  const handleDateChangeActive = useCallback(
    (index, value) => {
      if (activeItems.length > 1 && activeItems.includes(index)) {
        formRef.current.batch(() => {
          deletedItemsData.data.forEach((item, index) => {
            if (activeItems.includes(index)) {
              formRef.current.change(`deletedItems[${index}].recordDate`, value)
            }
          })
        })
      } else {
        formRef.current.change(`deletedItems[${index}].recordDate`, value)
      }
    },
    [formRef, activeItems, deletedItemsData],
  )

  const handleSentActiveDeleted = useCallback(
    (values: { deletedItems: any[] }) => {
      setSelectedItems(
        values.deletedItems
          .filter((_, index) => activeItems.includes(index))
          .filter(({ isSent }) => !isSent)
          .map((item) => ({
            id: item.id,
            recordDate: dateToString(item.recordDate),
          })),
      )
      setIsReturnModalShow(true)
    },
    [activeItems],
  )

  const handleSentDeletedConfirm = useCallback(async () => {
    setIsReturnButtonLoading(true)
    await sentDeletedItems({
      data: selectedItems,
    })
    setSelectedItems([])
    resetActiveItems()
    setIsReturnModalShow(false)
    setIsReturnButtonLoading(false)
    refetchDeletedItemsList()
  }, [selectedItems, sentDeletedItems, refetchDeletedItemsList, resetActiveItems])

  const handleSentDeletedCancel = useCallback(() => {
    setSelectedItems([])
    setIsReturnModalShow(false)
  }, [])

  const totalRow = useMemo(
    () =>
      deletedItemsData?.data
        .filter((_, index) => activeItems.includes(index))
        .reduce(
          (result, row) => {
            result.credit += row.credit || 0
            result.debit += row.debit || 0

            if (!row.isSent) {
              result.hasNotSent = true
            }

            return result
          },
          {
            credit: 0,
            debit: 0,
            hasNotSent: false,
          },
        ),
    [deletedItemsData, activeItems],
  )

  const loadMore = useCallback(() => {
    fetchDeletedItemsList({
      loadMore: true,
      page: Math.ceil(deletedItemsData?.data.length / PER_PAGE),
      perPage: PER_PAGE,
      orderBy: orderBy.field,
      orderDirection: orderBy.direction,
      filters,
    })
  }, [deletedItemsData, orderBy, filters, fetchDeletedItemsList])

  const initialValues = useMemo(
    () => ({
      deletedItems: deletedItemsData?.data.map((item) => ({
        ...item,
        recordDate: today,
      })),
    }),
    [deletedItemsData],
  )

  const handleReturn = useCallback(async () => {
    await formRef.current?.submit()
  }, [])

  const handleCreate = useCallback(() => {
    setIsCreateModalOpen(true)
  }, [])

  const handleCreateCancel = useCallback(() => {
    setIsCreateModalOpen(false)
  }, [])

  const handleCreateConfirm = useCallback(
    async (data) => {
      setIsCreating(true)
      const result = await createDeletedItem({
        ...data,
        recordDate: dateToString(data.recordDate),
      })
      if (!result?.error) {
        await refetchDeletedItemsList(true)
        setIsCreateModalOpen(false)
      }
      setIsCreating(false)
    },
    [createDeletedItem, refetchDeletedItemsList],
  )

  const validate = useMemo(() => {
    const minDate = moment(loanBalanceStartDate || undefined).toDate()
    const schema = Yup.object().shape({
      description: Yup.string().required('Required'),
      amount: Yup.number().typeError('Invalid number').required('Required'),
      recordDate: Yup.date()
        .typeError('Please type date in MM/DD/YY format')
        .required('Required')
        .min(minDate, 'Cannot create non-client-related cash for a locked month'),
    })

    return makeValidate(schema)
  }, [loanBalanceStartDate])

  return (
    <div>
      <TableContainer
        className={styles.table}
        onActiveRowsChange={setActiveItems}
        onActiveRowChange={setActiveItem}
      >
        <Form
          validate={filtersValidate}
          onSubmit={handleFiltersChange}
          initialValues={filters}
          mutators={{
            setFieldData: ([field, value], state, { changeValue }) => {
              changeValue(state, field, () => value)
            },
          }}
          render={({ values, handleSubmit, form: { mutators: filtersMutators } }) => (
            <FilterContainer
              filters={DELETED_COLLECTIONS_LIST_FILTERS_CONFIG}
              handleSubmit={handleSubmit}
              mutators={filtersMutators}
              values={values}
              appliedFilters={filters}
              appliedQuickFilter={quickFilter}
              handleAppliedQuickFilterChange={handleQuickFilterChange}
              actions={
                <Box display="flex" alignItems="center" gap={1}>
                  <AddButton variant="outlined" onClick={handleCreate} />
                  {activeItems.length > 1 && totalRow?.hasNotSent && (
                    <Box display="flex" alignItems="center" gap={1}>
                      <Button
                        startIcon={<ArrowRightIcon />}
                        className={styles.sendButtonAll}
                        color="primary"
                        variant="contained"
                        onClick={handleReturn}
                        small={false}
                      >
                        Return
                      </Button>
                    </Box>
                  )}
                  {activeItems.length > 1 && (
                    <Box display="flex" alignItems="center" gap={1}>
                      <Button
                        className={styles.sendButtonAll}
                        color="primary"
                        variant="contained"
                        onClick={handleDelete}
                        small={false}
                        isLoading={isDeleting}
                      >
                        Delete
                      </Button>
                    </Box>
                  )}
                </Box>
              }
            />
          )}
        />
        <Table>
          <TableHead>
            <TableFiltersRow
              filters={DELETED_COLLECTIONS_LIST_FILTERS_CONFIG}
              orderBy={orderBy}
              handleOrderChange={handleOrderChange}
            />
          </TableHead>
          <Form
            onSubmit={handleSentActiveDeleted}
            initialValues={initialValues}
            mutators={mutators}
            render={(formProps) => (
              <DeletedCheckTableForm
                {...formProps}
                formRef={formRef}
                loadMore={loadMore}
                deletedItems={deletedItemsData}
                activeItems={activeItems}
                activeItem={activeItem}
                handleSelectRow={handleSelectRow}
                handleSentDeleted={handleSentDeleted}
                handleDeleteDeleted={handleDeleteDeleted}
                loanBalanceStartDate={loanBalanceStartDate}
                isLoading={isLoading}
                setDate={handleDateChangeActive}
              />
            )}
          />
        </Table>
      </TableContainer>

      <ActiveToolbar
        activeItems={activeItems}
        className={styles.activeToolbar}
        containerRef={wrapperRef}
        resetActiveItems={resetActiveItems}
      >
        <div className={genericSs.tableTextRight}>{formatter.format(totalRow?.credit)}</div>
        <div className={genericSs.tableTextRight}>{formatter.format(totalRow?.debit)}</div>
      </ActiveToolbar>

      {isCreateModalOpen && (
        <Modal
          classes={{
            root: styles.modal,
          }}
          open
          onCancel={handleCreateCancel}
          title="Create non-client related cash"
        >
          <Form
            onSubmit={handleCreateConfirm}
            validate={validate}
            render={({
              invalid,
              pristine,
              handleSubmit,
            }: {
              values: any
              invalid: boolean
              pristine: boolean
              handleSubmit: any
            }) => (
              <form className={genericSs.textLeft} onSubmit={handleSubmit}>
                <Box mt={2}>
                  <InputLabel htmlFor="description">Description</InputLabel>
                  <TextField name="description" multiline fullWidth />
                </Box>

                <Box mt={2}>
                  <InputLabel htmlFor="amount">Amount</InputLabel>
                  <CurrencyField name="amount" />
                </Box>

                <Box mt={2}>
                  <InputLabel htmlFor="recordDate">Record Date</InputLabel>
                  <KeyboardDatePicker
                    name="recordDate"
                    inputFormat={DATE_FORMAT}
                    className={styles.dateField}
                    minDate={moment(loanBalanceStartDate || undefined).toDate()}
                  />
                </Box>

                <Box display="flex" alignItems="center" justifyContent="center" mt={2}>
                  <ModalButton
                    isLoading={isCreating}
                    color="primary"
                    variant="contained"
                    onClick={handleSubmit}
                    disabled={invalid || pristine}
                    fullWidth
                    small={false}
                  >
                    Create
                  </ModalButton>
                </Box>
                <Box display="flex" alignItems="center" justifyContent="center" mt={1}>
                  <ModalButton
                    color="primary"
                    variant="outlined"
                    onClick={handleCreateCancel}
                    secondary
                    fullWidth
                    small={false}
                  >
                    Cancel
                  </ModalButton>
                </Box>
              </form>
            )}
          />
        </Modal>
      )}

      {isDeleteModalShow && (
        <WarningModal
          warningMessage="Deleting this record will be permanent."
          onConfirm={handleDeleteDeletedConfirm}
          onCancel={handleDeleteDeletedCancel}
          confirmText="Yes, proceed"
          cancelText="Cancel"
          isLoading={isDeleting}
        />
      )}

      {isReturnModalShow && (
        <WarningModal
          warningMessage="Are you sure you want to return item(s)? This cannot be undone."
          onConfirm={handleSentDeletedConfirm}
          onCancel={handleSentDeletedCancel}
          confirmText="Yes, proceed"
          cancelText="Cancel"
          isLoading={isReturnButtonLoading}
        />
      )}
    </div>
  )
}

export default DeletedCheckTable
