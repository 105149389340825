import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'

import EntityAccountMapping from './EntityAccountMapping'

import {
  listEntityAccounts,
  hideEntityAccounts,
  updateEntityAccounts,
  listEntityInfo,
  addEntityInfo,
} from '../../redux/entityInfo/actions'
import { entityAccounts } from '../../redux/entityInfo/selectors'

const selector = createStructuredSelector({
  entityAccounts,
})

const actions = {
  listEntityAccounts,
  hideEntityAccounts,
  updateEntityAccounts,
  listEntityInfo,
  addEntityInfo,
}

export default connect(selector, actions)(EntityAccountMapping)
