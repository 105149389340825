import React, { useCallback, useMemo } from 'react'
import MuiTableRow, { TableRowProps } from '@mui/material/TableRow'
import cn from 'classnames'

import styles from './TableRow.module.scss'

interface IProps extends TableRowProps {
  isEditable?: boolean
  isFiltersRow?: boolean
  isNewFiltersRow?: boolean
  isActiveRow?: boolean
  isCurrentActiveRow?: boolean
  index?: number
  onSelectRow?: (event: any, index: number) => void
}

const TableRow = React.memo(
  ({
    isEditable,
    isFiltersRow,
    isNewFiltersRow,
    isActiveRow,
    isCurrentActiveRow,
    index,
    onSelectRow,
    ...props
  }: IProps) => {
    const handleSelectRow = useCallback(
      (event) => {
        if (onSelectRow) {
          onSelectRow(event, index)
        }
      },
      [index, onSelectRow],
    )

    const classNames = useMemo(
      () =>
        cn(styles.root, {
          [styles.editable]: isEditable,
          [styles.filters]: isFiltersRow,
          [styles.newFilters]: isNewFiltersRow,
          activeRow: isActiveRow,
          currentActiveRow: isCurrentActiveRow,
        }),
      [isEditable, isFiltersRow, isNewFiltersRow, isActiveRow, isCurrentActiveRow],
    )

    return (
      <MuiTableRow
        classes={{
          root: classNames,
        }}
        onClick={onSelectRow ? handleSelectRow : undefined}
        {...props}
      />
    )
  },
)

export default TableRow
