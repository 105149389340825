import React, { useMemo } from 'react'
import { useParams } from 'react-router'
import Grid from '@mui/material/Grid'
import { IDueDiligence } from '@common/interfaces/dueDiligence'
import { ClientEntityRelationshipType } from '@common/interfaces/integration'
import { ClientERPSystem } from '@common/interfaces/client'
import { useLoadInfo } from '../../hooks/useLoadInfo'
import DueDiligenceApplicationTableInventoryLocations from '../../components/DueDiligenceApplicationTableInventoryLocations'
import DueDiligenceApplicationTableBankAccounts from '../../components/DueDiligenceApplicationTableBankAccounts'
import Card from '../../components/Common/Card'
import DueDiligencePageHeader from '../../components/DueDiligencePageHeader'
import { DueDiligenceInfoApplicationData } from '../DueDiligenceCompanyBackgroundPage/DueDiligenceCompanyBackgroundPage'

interface IProps {
  dueDiligenceInfo: IDueDiligence
  show: (id: string) => void
}

const DueDiligenceClientFinancialsPage = ({ dueDiligenceInfo, show }: IProps) => {
  const { id } = useParams<{ id: string }>()
  const { isLoading } = useLoadInfo({ id, info: dueDiligenceInfo, show })
  const data = useMemo(() => {
    if (!dueDiligenceInfo) {
      return []
    }

    const distributionServices3PL = dueDiligenceInfo.clientEntityRelationship?.find(
      ({ type }) => type === ClientEntityRelationshipType.DistributionServices3PL,
    )

    return [
      [
        {
          label: 'What 3PL’s do you use?',
          value: distributionServices3PL
            ? distributionServices3PL.salesforceAccount?.name ||
              distributionServices3PL.newSalesforceAccount
            : '',
        },
      ],
      [
        {
          label: 'What ERP’s and/or Inventory management systems do you use?',
          values: [
            ...(dueDiligenceInfo.dueDiligenceClientInfo?.erpSystem || []).filter(
              (system) => system !== ClientERPSystem.Other,
            ),
            ...(dueDiligenceInfo.dueDiligenceClientInfo?.erpSystemOther
              ?.split(',')
              .map((system) => system.trim()) || []),
          ].filter(Boolean),
          size: 12,
        },
      ],
    ]
  }, [dueDiligenceInfo])

  return (
    <div>
      <DueDiligencePageHeader isLoading={isLoading} />
      <Grid container item xs={12}>
        <Grid item xs={12} mb={2} px={2}>
          <Card title="Vendors">
            {data.map((data, index) => (
              <DueDiligenceInfoApplicationData key={index} data={data} />
            ))}
          </Card>
        </Grid>
        <Grid item xs={12} mb={2} px={2}>
          <Card title="Inventory locations">
            <DueDiligenceApplicationTableInventoryLocations />
          </Card>
        </Grid>
        <Grid item xs={12} px={2}>
          <Card title="Bank accounts">
            <DueDiligenceApplicationTableBankAccounts />
          </Card>
        </Grid>
      </Grid>
    </div>
  )
}

export default DueDiligenceClientFinancialsPage
