import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'

import Integrations from './Integrations'
import { clientInfo } from '../../redux/profile/selectors'

const selector = createStructuredSelector({
  clientInfo,
})

export default connect(selector, null)(Integrations)
