import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'

import ClientFinancials from './ClientFinancials'

import { clientInfo, financialsReports } from '../../../redux/clientInfo/selectors'
import { listFinancialsReports, hideFinancialsReports } from '../../../redux/clientInfo/actions'

const selector = createStructuredSelector({
  clientInfo,
  financialsReports,
})

const actions = {
  listFinancialsReports,
  hideFinancialsReports,
}

export default connect(selector, actions)(ClientFinancials)
