import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'

import ClientAmendments from './ClientAmendments'
import { clientInfo, amendmentsData } from '../../../redux/clientInfo/selectors'
import {
  listAmendments,
  createAmendment,
  updateAmendment,
  deleteAmendment,
  createOveradvancePayment,
  listOveradvancePayments,
  deleteOveradvancePayment,
} from '../../../redux/clientInfo/actions'
import { loanBalanceStartDate } from '../../../redux/loanBalance/selectors'

const selector = createStructuredSelector({
  clientInfo,
  amendmentsData,
  loanBalanceStartDate,
})

const actions = {
  listAmendments,
  createAmendment,
  updateAmendment,
  deleteAmendment,
  createOveradvancePayment,
  listOveradvancePayments,
  deleteOveradvancePayment,
}

export default connect(selector, actions)(ClientAmendments)
