import React, { useEffect, useMemo } from 'react'
import cn from 'classnames'
import { Link, generatePath } from 'react-router-dom'
import styles from './ParticipantsSummaryDetailTable.module.scss'
import genericSs from '@styles/generic.module.scss'
import { formatPercent, formatPrice } from '../../helpers/helpers'
import TableFiltersRow from '../Common/TableFiltersRow'
import { IFilter } from '@common/constants/filters'
import useTable from '../../hooks/useTable'
import TableContainer from '../Common/TableContainer'
import Table from '../Common/Table'
import TableHead from '../Common/TableHead'
import TableBody from '../Common/TableBody'
import TableRow from '../Common/TableRow'
import TableCell from '../Common/TableCell'
import { IParticipation, IParticipationData } from '@common/interfaces/participant'
import { ILoadingData } from '../../redux/types'
import LinkButton from '@mui/material/Link'
import { ROUTES } from '../../constants/routes'
import { PARTICIPANTS_DETAIL_TABLE_FILTERS_CONFIG } from '@common/constants/filters'
import { buildFiltersDefaults } from '../../helpers/filters'
import TableLoader from '../Common/TableLoader'

const filtersDefaults = buildFiltersDefaults(PARTICIPANTS_DETAIL_TABLE_FILTERS_CONFIG as IFilter[])

interface IRowProps {
  rowIndex: number
  item?: IParticipation
  headers?: string[]
}

const ParticipantsSummaryDetailRow = ({ rowIndex, item }: IRowProps) => {
  return (
    <TableRow data-index={rowIndex}>
      <TableCell className={cn(genericSs.tableTextLeft)}>
        {item.clientId ? (
          <LinkButton
            component={Link}
            to={generatePath(ROUTES.CLIENT_PAGE, {
              id: item.clientId,
            })}
          >
            {item.clientName}
          </LinkButton>
        ) : (
          item.clientName
        )}
      </TableCell>

      <TableCell className={cn(genericSs.tableTextRight)}>
        <div className={styles.amountHolder}>
          <span>$</span>
          {formatPrice(item.nfe)}
        </div>
      </TableCell>

      <TableCell className={cn(genericSs.tableTextRight)}>
        <div className={styles.amountHolder}>
          <span>$</span>
          {formatPrice(item.loanBalance)}
        </div>
      </TableCell>

      <TableCell className={cn(genericSs.tableTextRight)}>
        <div className={styles.amountHolder}>{formatPercent(item.loanExposure)}</div>
      </TableCell>
    </TableRow>
  )
}

interface IProps {
  listAllParticipations: (data: object) => void
  participant: string
  participations: ILoadingData<IParticipationData>
}

const ParticipantsSummaryDetailTable = ({
  listAllParticipations,
  participations,
  participant,
}: IProps) => {
  const { isLoading, data: participationsData } = useMemo(() => {
    return {
      isLoading: participations?.isLoading,
      data: participations?.data?.data,
    }
  }, [participations])

  const { filters, handleOrderChange, orderBy } = useTable({
    tableId: 'participantsSummaryDetail',
    filtersDefaults,
    sortDefault: {
      field: 'client_name',
      direction: 'DESC',
    },
  })

  useEffect(() => {
    listAllParticipations({
      participant,
      filters,
      orderBy: orderBy.field,
      orderDirection: orderBy.direction,
    })
  }, [listAllParticipations, orderBy, filters, participant])

  return (
    <TableContainer className={cn(styles.table)} id={'capTableSummmary'}>
      <Table>
        <TableHead className={cn(styles.tableHead)}>
          <TableFiltersRow
            filters={PARTICIPANTS_DETAIL_TABLE_FILTERS_CONFIG as IFilter[]}
            orderBy={orderBy}
            handleOrderChange={handleOrderChange}
          />
        </TableHead>
        <TableBody id="scrollableTable">
          {isLoading ? (
            <TableLoader
              columnsCount={PARTICIPANTS_DETAIL_TABLE_FILTERS_CONFIG.length}
              height={26}
            />
          ) : (
            participationsData &&
            participationsData.map((item: IParticipation, rowIndex: number) => {
              return <ParticipantsSummaryDetailRow key={item.id} rowIndex={rowIndex} item={item} />
            })
          )}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export default ParticipantsSummaryDetailTable
