import React, { useCallback, useMemo } from 'react'
import { diff as deepDiff } from 'deep-object-diff'
import pick from 'lodash/pick'

import styles from './BBCLogsItem.module.scss'

import {
  BBC_LOG_FIELDS_TO_COMPARE,
  BBCLogAction,
  BBCLogType,
  IBBCLog,
  IBBCLogCustomRule,
} from '@common/interfaces/bbcLog'
import BBCLogsItemContentUsers from './BBCLogsItemContentUsers'

const BBCLogsItemContentCustomRuleSingle = ({
  bbcLog,
  rule,
}: {
  bbcLog: IBBCLog
  rule: IBBCLogCustomRule
}) => {
  const renderChanges = useCallback(() => {
    const changedFields = Object.keys(
      pick(
        deepDiff(rule.oldRule || {}, rule.newRule || {}),
        BBC_LOG_FIELDS_TO_COMPARE[BBCLogType.CustomRule],
      ),
    )

    const renderValue = (field: string, value: any) => {
      if (!value) {
        return '-'
      }

      if (field === 'eligibility') {
        return value?.toLowerCase()
      }

      if (Array.isArray(value)) {
        return value.length
          ? `${value.slice(0, 3).join(', ')}${
              value.length > 3 ? ` (and ${value.length - 3} more)` : ''
            }`
          : '-'
      }

      return value
    }

    return (
      <>
        {changedFields.map((field, index) => (
          <React.Fragment key={field}>
            {field} from {renderValue(field, rule.oldRule?.[field])} to&nbsp;
            <b>{renderValue(field, rule.newRule?.[field])}</b>
            {index !== changedFields.length - 1 ? ', ' : ''}
          </React.Fragment>
        ))}
      </>
    )
  }, [rule])

  switch (rule.action) {
    case BBCLogAction.add:
      return (
        <div className={styles.itemData}>
          <BBCLogsItemContentUsers bbcLog={bbcLog} />
          &nbsp;
          <b>added</b> a new&nbsp;
          <b>{rule.newRule?.label}</b>&nbsp;rule
        </div>
      )
    case BBCLogAction.delete:
      return (
        <div className={styles.itemData}>
          <BBCLogsItemContentUsers bbcLog={bbcLog} />
          &nbsp;
          <b>deleted</b> {rule.oldRule?.label}
        </div>
      )
    case BBCLogAction.update:
      return (
        <div className={styles.itemData}>
          <BBCLogsItemContentUsers bbcLog={bbcLog} />
          &nbsp;
          <b>updated '{rule.newRule?.label}'</b>
          &nbsp; rule&nbsp;
          {renderChanges()}
        </div>
      )
    default:
      return null
  }
}

const BBCLogsItemContentCustomRuleMultiple = ({ bbcLog }: { bbcLog: IBBCLog }) => {
  const actions = useMemo(
    () =>
      bbcLog.customRules.reduce((result, { action }) => {
        if (!result[action]) {
          result[action] = 0
        }

        result[action]++

        return result
      }, {}),
    [bbcLog],
  )

  return (
    <div className={styles.itemData}>
      <BBCLogsItemContentUsers bbcLog={bbcLog} />
      &nbsp;
      {Object.keys(actions).map((action, index) => (
        <React.Fragment key={action}>
          <b>{action}</b>&nbsp;
          {action === BBCLogAction.update && ' eligibility setting for '}
          <b>{actions[action]}</b>
          {action === BBCLogAction.add && ' new'} rule{actions[action] > 1 ? 's' : ''}
          {index === Object.keys(actions).length - 2
            ? ' and '
            : index !== Object.keys(actions).length - 1
            ? ', '
            : ''}
        </React.Fragment>
      ))}
    </div>
  )
}

const BBCLogsItemContentCustomRule = ({ bbcLog }: { bbcLog: IBBCLog }) => {
  if (bbcLog.customRules.length > 1) {
    return <BBCLogsItemContentCustomRuleMultiple bbcLog={bbcLog} />
  }

  return <BBCLogsItemContentCustomRuleSingle bbcLog={bbcLog} rule={bbcLog.customRules[0]} />
}

export default BBCLogsItemContentCustomRule
