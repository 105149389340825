import React from 'react'
import { Link as RouterLink } from 'react-router-dom'
import Link from '@mui/material/Link'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemText from '@mui/material/ListItemText'
import ListItemIcon from '@mui/material/ListItemIcon'
import cn from 'classnames'

import styles from './ClientSettingsMenu.module.scss'
import genericSs from '@styles/generic.module.scss'

import { ROUTES } from '../../constants/routes'
import { ReactComponent as ProfileDetailsIcon } from '@assets/images/profile-details-icon.svg'
import { ReactComponent as SecurityIcon } from '@assets/images/security-icon.svg'
import { ReactComponent as IntegrationsIcon } from '@assets/images/integrations-icon.svg'
import { ReactComponent as NotificationIcon } from '@assets/images/notification-settings-icon.svg'
import { IUser } from '@common/interfaces/user'
import { UserRole } from '@common/interfaces/user'

const settingsConfig = [
  {
    name: 'Profile details',
    path: ROUTES.SETTINGS_PROFILE,
    subtitle: 'Update your personal details',
    icon: <ProfileDetailsIcon />,
  },
  {
    name: 'Security',
    path: ROUTES.SETTINGS_SECURITY,
    subtitle: 'Change and update password',
    icon: <SecurityIcon />,
  },
  {
    name: 'App integrations',
    path: ROUTES.CLIENT_SETTINGS_INTEGRATIONS,
    subtitle: 'Manage integrated applications',
    icon: <IntegrationsIcon />,
  },
  {
    name: 'Notification settings',
    path: ROUTES.CLIENT_SETTINGS_NOTIFICATIONS,
    subtitle: 'Manage all your notifications',
    icon: <NotificationIcon />,
  },
]

interface IProps {
  currentTab: string
  user: IUser
}
const ClientSettingsMenu = ({ currentTab, user }: IProps) => {
  const currentSettingsConfig = settingsConfig.filter((item) => {
    if (user.role !== UserRole.CLIENT_USER && item.name === 'App integrations') {
      return false
    }
    if (
      [UserRole.CLIENT_USER, UserRole.PARTICIPANT_USER].includes(user.role) &&
      !user.isProspectUser &&
      item.name === 'Notification settings'
    ) {
      return false
    }
    return true
  })

  return (
    <div className={styles.container}>
      <span className={cn(genericSs.header, styles.header)}>Account settings</span>
      <List>
        {currentSettingsConfig.map((item) => {
          const isActive = currentTab === item.name
          return (
            <Link component={RouterLink} to={item.path}>
              <ListItem
                key={item.name}
                className={cn(styles.listItem, {
                  [styles.active]: isActive,
                })}
              >
                <ListItemIcon className={styles.icon}>{item.icon}</ListItemIcon>
                <ListItemText
                  classes={{
                    primary: styles.primary,
                    secondary: styles.secondary,
                  }}
                  primary={item.name}
                  secondary={item.subtitle}
                />
              </ListItem>
            </Link>
          )
        })}
      </List>
    </div>
  )
}

export default ClientSettingsMenu
