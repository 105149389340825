import { IBorrowingBase } from './bbc'
import { IClientInfo } from './client'
import { IParticipationWire } from './participant'
import { IPassThrough } from './passThrough'

export enum TreasuryAmountTypes {
  Funding = 'Funding',
  Passthrough = 'Passthrough',
  Participation = 'Participation',
  Other = 'Other',
}

export interface ITreasuryDetails {
  id: string
  clientInfo: IClientInfo
  recordDate: string
  type: TreasuryAmountTypes
  amount: number
  isPrediction: boolean
  isDisplayed: boolean
  participant: string
  participationWireType: string
  borrowingBase: IBorrowingBase
  participationWires: IParticipationWire
  passThrough: IPassThrough
  description: string
  isChecked: boolean
}

export interface ITreasuryData {
  data: {
    id: string
    recordDate: string
    startingCash: number
    buffer: number
    finalDraw: number
    fundingWires: ITreasuryDetails[]
    passthroughWires: ITreasuryDetails[]
    participationWires: ITreasuryDetails[]
    otherWires: ITreasuryDetails[]
    clientList: { value: string; label: string }[]
  }
}
