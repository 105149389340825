// import uuid from 'uuid'
export const prefix = 'ui'

export const PUT_NOTIFICATION = `${prefix}/PUT_NOTIFICATION`
export const REMOVE_NOTIFICATION = `${prefix}/REMOVE_NOTIFICATION`

export const putNotification = (notification: object) => ({
  type: PUT_NOTIFICATION,
  payload: {
    ...notification,
    isOpen: true,
    // _id: uuid(),
  },
})

export const removeNotification = () => ({
  type: REMOVE_NOTIFICATION,
  payload: {},
})

export const CHANGE_PAGE_TITLE = `${prefix}/CHANGE_PAGE_TITLE`

export const changePageTitle = (title: string) => ({
  type: CHANGE_PAGE_TITLE,
  payload: {
    title,
  },
})
