import Base from './base'

export default class Bdo extends Base {
  addCreditor(data: object) {
    return this.apiClient.post('bdo/creditors', data)
  }

  addDebtor(data: object) {
    return this.apiClient.post('bdo/debtors', data)
  }

  listCreditors() {
    return this.apiClient.get('bdo/creditors')
  }

  listDebtors() {
    return this.apiClient.get('bdo/debtors')
  }
}
