import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'

import OngoingReportingSalesBySKUPage from './OngoingReportingSalesBySKUPage'

import { isLoading, ongoingReporting } from '../../redux/ongoingReporting/selectors'
import {
  showOngoingReporting,
  updateOngoingReporting,
  submitOngoingReporting,
  updateOngoingReportingStatus,
} from '../../redux/ongoingReporting/actions'
import { deleteFile } from '../../redux/file/actions'
import { downloadTemplate } from '../../redux/bbc/actions'

const selector = createStructuredSelector({
  isLoading,
  data: ongoingReporting,
})

const actions = {
  showOngoingReporting,
  updateOngoingReporting,
  submitOngoingReporting,
  deleteFile,
  downloadTemplate,
  updateOngoingReportingStatus,
}

export default connect(selector, actions)(OngoingReportingSalesBySKUPage)
