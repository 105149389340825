import { IClientInfo } from '@common/interfaces/client'
import { prefix } from './actions'
import { IRootState } from '../reducer'
import { createSelector, createSelectorCreator, defaultMemoize } from 'reselect'

export const isLoading = (state: IRootState) => state[prefix].loadingArray.length > 0

export const clients = (state: IRootState) => state[prefix].clients

export const clientInfo = (state: IRootState) => state[prefix].clientInfo

const createItemsMonitorSelector = createSelectorCreator(
  defaultMemoize,
  (a: any, b: any) => a?.id === b?.id,
)
export const clientInfoItemsMonitor = createItemsMonitorSelector(
  clientInfo,
  (client: IClientInfo) => ({ id: client?.id, itemsToMonitor: client?.itemsToMonitor }),
)

export const documents = (state: IRootState) => state[prefix].documents

export const lsaDocumentLink = createSelector(documents, (docs) => docs?.lsa?.[0]?.link)

export const RMUsers = (state: IRootState) => state[prefix].RMUsers

export const managers = (state: IRootState) => state[prefix].managers

export const aggregation = (state: IRootState) => state[prefix].aggregation

export const fundingRequest = (state: IRootState) => state[prefix].fundingRequest

export const clientsAggregation = (state: IRootState) => state[prefix].clientsAggregation

export const clientId = createSelector(clientInfo, (client) => client?.id)

export const overadvances = (state: IRootState) => state[prefix].overadvances

export const complianceData = (state: IRootState) => state[prefix].complianceData

export const termLoans = (state: IRootState) => state[prefix].termLoans

export const inventoryEligibilityData = (state: IRootState) =>
  state[prefix].inventoryEligibilityData

export const eligibleFields = (state: IRootState) => state[prefix].fields

export const customRules = (state: IRootState) => state[prefix].customRules

export const customRulesLabels = (state: IRootState) => state[prefix].customRulesLabels

export const customRulesValues = (state: IRootState) => state[prefix].customRulesValues

export const amendmentsData = (state: IRootState) => state[prefix].amendmentsData

export const arEligibilityData = (state: IRootState) => state[prefix].arEligibilityData

export const apEligibilityData = (state: IRootState) => state[prefix].apEligibilityData

export const financialsReports = (state: IRootState) => state[prefix].financialsReports

export const riskRatings = (state: IRootState) => state[prefix].riskRatings

export const riskRatingDetails = (state: IRootState) => state[prefix].riskRatingDetails

export const inventoryHealth = (state: IRootState) => state[prefix].inventoryHealth

export const cashFlowInfo = (state: IRootState) => state[prefix].cashFlowInfo

export const customers = (state: IRootState) => state[prefix].customers

export const refreshCount = (state: IRootState) => state[prefix].refreshCount

export const accountActivityGraph = (state: IRootState) => state[prefix].accountActivityGraph

export const banners = (state: IRootState) => state[prefix].banners

export const loanLedger = (state: IRootState) => state[prefix].loanLedger

export const stressCase = (state: IRootState) => state[prefix].stressCase

export const collectionsReconciliation = (state: IRootState) =>
  state[prefix].collectionsReconciliation

export const collectionsReconciliationOptions = (state: IRootState) =>
  state[prefix].collectionsReconciliationOptions

export const salesChannelDistribution = (state: IRootState) =>
  state[prefix].salesChannelDistribution

export const salesChannelDistributionOptions = (state: IRootState) =>
  state[prefix].salesChannelDistributionOptions
