import { IApi } from '../../api'
import { CommonPromiseAction } from '../types'
import { createRequestTypes } from '../../helpers/helpers'

export const prefix = 'fee'

export const CREATE_FEE = createRequestTypes(prefix, 'CREATE_FEE')

export const [CREATE_FEE_REQUEST, CREATE_FEE_SUCCESS, CREATE_FEE_FAILURE] = CREATE_FEE

export const createFee: CommonPromiseAction = (data: object) => ({
  type: CREATE_FEE,
  promise: (api: IApi) => api.fee.create(data),
})

const UPDATE_FEE = createRequestTypes(prefix, 'UPDATE_FEE')

export const [UPDATE_FEE_REQUEST, UPDATE_FEE_SUCCESS, UPDATE_FEE_FAILURE] = UPDATE_FEE

export const updateFee: CommonPromiseAction = (id: string, data: object) => ({
  type: UPDATE_FEE,
  promise: (api: IApi) => api.fee.update(id, data),
})

const RESTORE_FEE = createRequestTypes(prefix, 'RESTORE_FEE')

export const [RESTORE_FEE_REQUEST, RESTORE_FEE_SUCCESS, RESTORE_FEE_FAILURE] = RESTORE_FEE

export const restoreFee: CommonPromiseAction = (id: string) => ({
  type: RESTORE_FEE,
  promise: (api: IApi) => api.fee.restore(id),
})

const DELETE_FEE = createRequestTypes(prefix, 'DELETE_FEE')

export const [DELETE_FEE_REQUEST, DELETE_FEE_SUCCESS, DELETE_FEE_FAILURE] = DELETE_FEE

export const deleteFee: CommonPromiseAction = (id: string) => ({
  type: DELETE_FEE,
  promise: (api: IApi) => api.fee.delete(id),
})
