import React from 'react'
import CircularProgress from '@mui/material/CircularProgress'
import { Route, Redirect } from 'react-router-dom'
import { ROUTES } from '../../constants/routes'
import { ConnectedComponent } from 'react-redux'
import MainLayout from '../../pages/MainLayout'

interface IProps {
  isInitialized: boolean
  isAuthenticated: boolean
  component: ConnectedComponent<any, any>
}

const PrivateRoute = ({
  component: Component,
  isAuthenticated,
  isInitialized,
  ...rest
}: IProps) => {
  return (
    <Route
      {...rest}
      render={(props) => {
        if (!isInitialized) {
          return (
            <MainLayout>
              <CircularProgress
                size="large"
                style={{
                  position: 'absolute',
                  top: '50%',
                }}
              />
            </MainLayout>
          )
        }
        window.localStorage.setItem('return_url', props.location.pathname + props.location.search)
        return isAuthenticated ? (
          <MainLayout>
            <Component {...props} />
          </MainLayout>
        ) : (
          <Redirect
            to={{
              pathname: ROUTES.LOGIN,
              state: { from: props.location },
            }}
          />
        )
      }}
    />
  )
}

export default PrivateRoute
