import React, { MutableRefObject, ReactNode, useEffect, useState, useCallback, useRef } from 'react'
import ReactDOM from 'react-dom'
import cn from 'classnames'

import styles from './ActiveToolbar.module.scss'

interface IProps extends React.HTMLAttributes<HTMLDivElement> {
  activeItems: any[]
  children?: ReactNode
  className?: string
  minLength?: number
  isFullscreen?: boolean
  containerRef?: MutableRefObject<any>
  resetActiveItems?: () => void
  coupledTables?: string[]
}

const ActiveToolbar = ({
  activeItems,
  children,
  className,
  minLength = 2,
  isFullscreen = false,
  containerRef,
  resetActiveItems,
  coupledTables,
  ...props
}: IProps) => {
  const [toolbarId, setToolbarId] = useState('activeToolbarsWrapper')
  const wrapper = document.getElementById(toolbarId)
  const ref = useRef(null)

  useEffect(() => {
    if (ref.current && coupledTables?.length > 0) {
      const firstTable = document.getElementById(coupledTables[0])
      if (firstTable) {
        ref.current.scrollLeft = firstTable.scrollLeft
      }
    }
  }, [ref?.current?.scrollWidth, coupledTables])

  const handleScroll = useCallback(() => {
    if (ref.current && coupledTables) {
      const scrollLeft = ref.current.scrollLeft
      coupledTables.forEach((tableId) => {
        const coupledTable = document.getElementById(tableId)
        if (coupledTable) {
          coupledTable.scrollLeft = scrollLeft
        }
      })
    }
  }, [ref, coupledTables])

  useEffect(() => {
    setToolbarId(isFullscreen ? 'activeToolbarsFullscreenWrapper' : 'activeToolbarsWrapper')
  }, [isFullscreen])

  if (!wrapper || activeItems.length < minLength) {
    return null
  }

  return ReactDOM.createPortal(
    <div
      className={cn(styles.activeToolbar, className, {
        [styles.fullscreen]: isFullscreen,
      })}
      ref={ref}
      onScroll={handleScroll}
      {...props}
    >
      <div className={styles.activeToolbarItemsCount}>{activeItems.length} items selected</div>
      <>{children}</>
    </div>,
    wrapper,
  )
}

export default ActiveToolbar
