import React, { useMemo } from 'react'
import cn from 'classnames'
import Tooltip from '@mui/material/Tooltip'
import styles from './OngoingReportingReconciliation.module.scss'
import genericSs from '@styles/generic.module.scss'

import { ReactComponent as Info } from '@assets/images/reconciliation-info-icon.svg'

import { formatPriceNoDecimal } from '../../helpers/helpers'
import TableContainer from '../Common/TableContainer'
import Table from '../Common/Table'
import TableHead from '../Common/TableHead'
import TableBody from '../Common/TableBody'
import TableRow from '../Common/TableRow'
import TableCell from '../Common/TableCell'
import {
  CLIENT_FINANCIALS_FIELDS_MAPPING,
  IOngoingReportingRawMappingData,
} from '@common/interfaces/bbc'
import Card from '../Common/Card'
import Grid from '@mui/material/Grid'

interface IProps {
  statementType: string
  dataConsolidated: IOngoingReportingRawMappingData
  data: { field: string; amounts: number[]; instructions: string }[]
}

const OngoingReportingReconciliation = ({ statementType, dataConsolidated, data }: IProps) => {
  const missedColumns = useMemo(
    () =>
      Array.from(Array(Math.max(6 - (dataConsolidated?.dictionary.headers.length || 0), 0)).keys()),
    [dataConsolidated],
  )

  return (
    <Card title="Reconciliation" className={styles.reconciliation}>
      <TableContainer
        className={styles.table}
        scrollLeft
        coupledTables={['ongoingReportingSummary', 'ongoingReportingReconciliation']}
        id="ongoingReportingReconciliation"
      >
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>
                <div className={styles.headerStatement}>Statement</div>
              </TableCell>
              {dataConsolidated &&
                dataConsolidated.dictionary.headers.map((header) => (
                  <TableCell key={header} className={cn(genericSs.tableTextRight)}>
                    <div className={styles.header}>{header}</div>
                  </TableCell>
                ))}
              {missedColumns.map((index) => (
                <TableCell key={index} />
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((item) => (
              <TableRow key={item.field}>
                <TableCell className={cn(genericSs.tableTextLeft)}>
                  <Grid container spacing={0} justifyContent="space-evenly" rowSpacing={0}>
                    <Grid item xs={8} className={styles.account}>
                      {CLIENT_FINANCIALS_FIELDS_MAPPING[item.field]}
                    </Grid>
                    <Grid item xs={4} className={styles.infoIconContainer}>
                      <Tooltip title={item.instructions}>
                        <div className={styles.infoIcon}>
                          <Info className={styles.infoIconSize} />
                        </div>
                      </Tooltip>
                    </Grid>
                  </Grid>
                </TableCell>
                {dataConsolidated &&
                  dataConsolidated.dictionary.headers.map((header, index) => (
                    <TableCell
                      key={header}
                      className={cn(genericSs.tableTextRight, {
                        [styles.validCell]: item.amounts[index] === 0,
                        [styles.invalidCell]: item.amounts[index] !== 0,
                      })}
                    >
                      ${formatPriceNoDecimal(item.amounts[index])}
                    </TableCell>
                  ))}
                {missedColumns.map((index) => (
                  <TableCell key={index} />
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Card>
  )
}

export default OngoingReportingReconciliation
