import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'

import Header from './Header'
import { isBlankLayout, pathname } from '../../redux/router/selectors'
import { isDueDiligenceClient, role } from '../../redux/profile/selectors'
import { toggleNotes } from '../../redux/notes/actions'
import { user } from '../../redux/profile/selectors'
import { isSidebarOpen, isAdminLoggedAsClient, clientInfo } from '../../redux/profile/selectors'
import { title } from '../../redux/ui/selectors'
import { logout } from '../../redux/profile/actions'

const selector = createStructuredSelector({
  isBlankLayout,
  role,
  user,
  pathname,
  isSidebarOpen,
  title,
  isDueDiligenceClient,
  isAdminLoggedAsClient,
  clientInfo,
})

const actions = {
  toggleNotes,
  onLogout: logout,
}

export default connect(selector, actions)(Header)
