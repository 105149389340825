import { outstandingParticipationWires } from './../../redux/participant/selectors'
import { listOutstandingWires, createParticipationWire } from './../../redux/participant/actions'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import OutstandingParticipationWires from './OutstandingParticipationWires'
import { isAdminRightsRole } from '../../redux/profile/selectors'
import { loanBalanceStartDate } from '../../redux/loanBalance/selectors'

const selector = createStructuredSelector({
  isAdminRightsRole,
  outstandingParticipationWires,
  loanBalanceStartDate,
})

const actions = {
  listOutstandingWires,
  createParticipationWire,
}

export default connect(selector, actions)(OutstandingParticipationWires)
