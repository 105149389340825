import React, { useEffect } from 'react'
import { Link as RouterLink } from 'react-router-dom'
import Link from '@mui/material/Link'
import { useParams } from 'react-router'
import { ROUTES } from '../../constants/routes'

import Card from '../../components/Common/Card'
import ResetPasswordForm from '../../components/ResetPasswordForm'
import LeftPoint from '../../assets/images/left-point.svg'
import SuccessLogo from '../../assets/images/success-logo.svg'
import cn from 'classnames'
import styles from '../../components/AuthContainer/AuthContainer.module.scss'
import Button from '../../components/Common/Button'
interface IProps {
  isChangedPwd: boolean
  checkPasswordSetup: (hash: string) => void
}

interface ParamTypes {
  hash: string
}

const ResetPasswordPage = ({ isChangedPwd, checkPasswordSetup }: IProps) => {
  const { hash } = useParams<ParamTypes>()

  useEffect(() => {
    if (hash && !isChangedPwd) {
      // checkPasswordSetup(hash)
    }
  }, [checkPasswordSetup, hash, isChangedPwd])

  return (
    <div>
      <div className={styles.backToLogin}>
        <Link component={RouterLink} to={ROUTES.LOGIN}>
          <img className={styles.leftPoint} src={LeftPoint} alt="Back to login" />
          Back to login
        </Link>
      </div>
      <Card noHeaderMargin className={styles.resetFormWrapper}>
        <div
          className={cn(styles.setupFormContainer, {
            [styles.setUpPassword]: !isChangedPwd,
          })}
        >
          {isChangedPwd && <img className={styles.successLogo} src={SuccessLogo} alt="Success" />}
          <div className={styles.heading}>
            {isChangedPwd ? 'Password changed' : 'Set up your new password'}
          </div>
          <div className={styles.headingInfo}>
            {isChangedPwd
              ? 'Your password has been updated successfully. You can now log in using this password'
              : 'Pick a new password to use going forward'}
          </div>
          {hash && !isChangedPwd && <ResetPasswordForm />}
          {isChangedPwd && (
            <Button
              className={styles.resetButton}
              variant="contained"
              color="primary"
              // @ts-ignore
              component={RouterLink}
              to={ROUTES.LOGIN}
            >
              Go to login
            </Button>
          )}
        </div>
      </Card>
    </div>
  )
}

export default ResetPasswordPage
