import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'

import ProspectReportingInventoryPage from './ProspectReportingInventoryPage'

import { reporting } from '../../redux/prospect/selectors'
import { startReporting, show } from '../../redux/prospect/actions'

const selector = createStructuredSelector({
  reporting,
})

const actions = {
  show,
  startReporting,
}

export default connect(selector, actions)(ProspectReportingInventoryPage)
