import React from 'react'

import { ROUTES } from '../../constants/routes'
import TabsWithRoutesMenu from '../Common/TabsWithRoutesMenu'

const TABS_ROUTES = {
  General: ROUTES.ENTITY_SETTINGS_GENERAL,
  Aliases: ROUTES.ENTITY_SETTINGS_ALIAS_MAPPING,
  Collateral: [ROUTES.ENTITY_SETTINGS_RECEIVABLES, ROUTES.ENTITY_SETTINGS_PAYABLES],
  'Related Entities': ROUTES.ENTITY_SETTINGS_RELATED_ENTITIES,
}

const MENU = {
  Collateral: [
    {
      label: 'Receivable',
      route: ROUTES.ENTITY_SETTINGS_RECEIVABLES,
    },
    {
      label: 'Payable',
      route: ROUTES.ENTITY_SETTINGS_PAYABLES,
    },
  ],
}

const EntitySettingsTabs = () => {
  return <TabsWithRoutesMenu tabs={TABS_ROUTES} menu={MENU} isQueryString={false}/>
}

export default EntitySettingsTabs
