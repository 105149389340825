import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'

import DueDiligenceApplicationTableInventoryLocations from './DueDiligenceApplicationTableInventoryLocations'

import { dueDiligenceFinancialsInventoryLocations } from '../../redux/dueDiligence/selectors'
import {
  listFinancialsInventoryLocations,
  hideFinancialsInventoryLocations,
} from '../../redux/dueDiligence/actions'

const selector = createStructuredSelector({
  dueDiligenceFinancialsInventoryLocations,
})
const actions = {
  listFinancialsInventoryLocations,
  hideFinancialsInventoryLocations,
}

export default connect(selector, actions)(DueDiligenceApplicationTableInventoryLocations)
