import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'

import GlobalSearchPage from './GlobalSearchPage'

import { isLoading, searchString, searchResults } from '../../redux/search/selectors'
import { searchByType } from '../../redux/search/actions'

const selector = createStructuredSelector({
  isLoading,
  searchString,
  searchResults,
})
const actions = {
  searchByType,
}

export default connect(selector, actions)(GlobalSearchPage)
