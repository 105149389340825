import React, { useCallback, useEffect, useMemo } from 'react'
import Card from '../Common/Card'
import { ILoadingData } from '../../redux/types'
import { IParticipationPageData, ParticipationStatus } from '@common/interfaces/participant'
import Table from '../Common/Table'
import TableContainer from '../Common/TableContainer'
import { PARTICIPANT_PARTICIPATION_FILTERS_CONFIG, PER_PAGE } from '@common/constants/filters'
import { ROUTES } from '../../constants/routes'
import Link from '@mui/material/Link'
import { Link as RouterLink, generatePath } from 'react-router-dom'
import useTable from '../../hooks/useTable'
import TableFiltersRow from '../Common/TableFiltersRow'
import TableHead from '../Common/TableHead'
import TableBody from '../Common/TableBody'
import TableRow from '../Common/TableRow'
import TableCell from '../Common/TableCell'
import TableLoader from '../Common/TableLoader'
import genericSs from '@styles/generic.module.scss'
import FormattedTableCell from '../Common/FormattedTableCell'
import InfiniteScroll from 'react-infinite-scroll-component'
import FilterContainer from '../Filters/FilterContainer'
import { buildFiltersDefaults, buildFiltersValidateSchema } from '../../helpers/filters'
import { Form } from 'react-final-form'
import { debounceEventHandler } from '../../helpers/helpers'
import cn from 'classnames'
import styles from './Participations.module.scss'
const filtersValidate = buildFiltersValidateSchema(PARTICIPANT_PARTICIPATION_FILTERS_CONFIG)
const filtersDefaults = buildFiltersDefaults(PARTICIPANT_PARTICIPATION_FILTERS_CONFIG)

interface IProps {
  participantId: string
  participantParticipations: ILoadingData<IParticipationPageData>
  listParticipantParticipations: (id: string, data: object) => void
}

const Participations = ({
  participantId,
  participantParticipations,
  listParticipantParticipations,
}: IProps) => {
  const { participationsList, isParticipationLoading, totalCount } = useMemo(
    () => ({
      participationsList: participantParticipations?.data?.data,
      isParticipationLoading: participantParticipations?.isLoading,
      totalCount: participantParticipations?.data?.totalCount,
    }),
    [participantParticipations],
  )

  const { orderBy, handleOrderChange, filters, handleFiltersChange } = useTable({
    tableId: 'participation',
    filtersDefaults,
    sortDefault: {
      field: 'client_name',
      direction: 'ASC',
    },
  })

  const fetchParticipations = useCallback(
    (data) =>
      listParticipantParticipations(participantId, {
        ...data,
        perPage: PER_PAGE,
      }),
    [listParticipantParticipations, participantId],
  )

  const debounceListParticipations = useMemo(
    () => debounceEventHandler(fetchParticipations, 500),
    [fetchParticipations],
  )

  useEffect(() => {
    debounceListParticipations({
      filters,
      orderBy: orderBy.field,
      orderDirection: orderBy.direction,
    })
  }, [debounceListParticipations, filters, orderBy])

  const loadMore = useCallback(() => {
    debounceListParticipations({
      loadMore: true,
      page: Math.ceil(participationsList?.length / PER_PAGE),
      orderBy: orderBy.field,
      orderDirection: orderBy.direction,
      filters,
    })
  }, [debounceListParticipations, participationsList, orderBy, filters])

  return (
    <Card noHeaderMargin>
      <TableContainer>
        <Form
          validate={filtersValidate}
          onSubmit={handleFiltersChange}
          initialValues={filters}
          mutators={{
            setFieldData: ([field, value], state, { changeValue }) => {
              changeValue(state, field, () => value)
            },
          }}
          render={({ values, handleSubmit, form: { mutators } }) => (
            <FilterContainer
              filters={PARTICIPANT_PARTICIPATION_FILTERS_CONFIG}
              handleSubmit={handleSubmit}
              mutators={mutators}
              values={values}
              title="Clients"
              appliedFilters={filters}
            />
          )}
        />
        <Table>
          <TableHead>
            <TableFiltersRow
              filters={PARTICIPANT_PARTICIPATION_FILTERS_CONFIG}
              orderBy={orderBy}
              handleOrderChange={handleOrderChange}
            />
          </TableHead>
          <TableBody id="scrollableTable">
            {isParticipationLoading ? (
              <TableLoader
                columnsCount={PARTICIPANT_PARTICIPATION_FILTERS_CONFIG.length}
                height={26}
              />
            ) : (
              participationsList &&
              participationsList.length > 0 && (
                <InfiniteScroll
                  dataLength={participationsList?.length}
                  next={loadMore}
                  hasMore={participationsList?.length < totalCount}
                  loader={<TableLoader columnsCount={7} height={36} rowsCount={1} />}
                  scrollableTarget="scrollableTableReporting"
                >
                  {participationsList?.map((item, index) => (
                    <TableRow key={item.id}>
                      <TableCell className={genericSs.tableTextLeft}>
                        <Link
                          component={RouterLink}
                          to={generatePath(ROUTES.CLIENT_PAGE, {
                            id: item.clientInfo?.id,
                          })}
                        >
                          {item.clientName}
                        </Link>
                        {item.status !== ParticipationStatus.Active && (
                          <span
                            className={cn(styles.statusTag, {
                              [genericSs.grayCard]: item.status === ParticipationStatus.Terminated,
                              [genericSs.yellowTag]: item.status === ParticipationStatus.Pending,
                            })}
                          >
                            {item.status}
                          </span>
                        )}
                      </TableCell>
                      <FormattedTableCell
                        value={item.participationLoanExposure?.loanBalance}
                        fieldType="amount"
                      />
                      <FormattedTableCell
                        value={item.participationLoanExposure?.participantExposureAmount}
                        fieldType="amount"
                      />
                      <FormattedTableCell
                        value={item.participationLoanExposure?.participantExposurePercentage}
                        fieldType="percent"
                      />
                      <FormattedTableCell value={item.loanExposure} fieldType="percent" />

                      <FormattedTableCell value={item.interestOverride} fieldType="percent" />
                      <FormattedTableCell value={item.contractDate} fieldType="date" />
                    </TableRow>
                  ))}
                </InfiniteScroll>
              )
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Card>
  )
}

export default Participations
