import { combineReducers } from 'redux'
import * as actions from './actions'
import {
  IOngoingReporting,
  IOngoingReportingData,
  IOngoingReportingRawMapping,
  IOngoingReportingRawMappingData,
  IOngoingReportingSummaryData,
  IOngoingReportingSummaryHeaders,
  IOngoingReportingUpdatesItem,
  IInventoryMappingFieldsData,
  ISalesBySKUVisualizationData,
  OngoingReportingType,
  ISalesBySKUVisualizationFilters,
  ISalesDistributionAnalysisData,
  IOngoingReportingSummaryHeadersData,
} from '@common/interfaces/bbc'
import { createLoadingDataReducer } from '../../helpers/redux'
import { loadingArrayReducer } from '../../helpers/helpers'
import { ILoadingData } from '../types'
import { LIST_CLIENT_FINANCIALS_SUMMARY_SUCCESS } from '../clientInfo/actions'
import { PROCESS_CAPTABLE_SUCCESS } from '../capTable/actions'

export interface IOngoingReportingState {
  loadingArray: string[]
  data: ILoadingData<IOngoingReportingData>
  ongoingReporting: IOngoingReporting
  dataConsolidated: IOngoingReportingRawMappingData
  dataSummary: Partial<Record<OngoingReportingType, IOngoingReportingSummaryData>>
  dataSummaryHeaders: IOngoingReportingSummaryHeaders
  dataSummaryHeadersColors: IOngoingReportingSummaryHeaders
  dataSummaryHeadersData: IOngoingReportingSummaryHeadersData
  dataSummaryGrowth: Partial<Record<OngoingReportingType, IOngoingReportingSummaryData>>
  reportingUpdatesList: IOngoingReportingUpdatesItem[]
  skuDetails: ILoadingData<IInventoryMappingFieldsData>
  skuVisualization: ILoadingData<ISalesBySKUVisualizationData>
  skuVisualizationsFilters: ILoadingData<ISalesBySKUVisualizationFilters>
  salesByDistributionGraph: ILoadingData<ISalesDistributionAnalysisData>
}

export default combineReducers<IOngoingReportingState>({
  loadingArray: loadingArrayReducer(
    [actions.prefix],
    [actions.LIST_ONGOING_REPORTING_REQUEST, actions.LIST_INVENTORY_MAPPING_REQUEST],
  ),
  data: createLoadingDataReducer<IOngoingReportingData>(
    [actions.LIST_ONGOING_REPORTING_SUCCESS],
    [actions.UPDATE_ONGOING_REPORTING_STATUS_SUCCESS],
  ),
  skuDetails: createLoadingDataReducer<IInventoryMappingFieldsData>(
    [actions.LIST_INVENTORY_MAPPING_REQUEST],
    [actions.UPDATE_INVENTORY_MAPPING_REQUEST],
  ),
  skuVisualization: createLoadingDataReducer<ISalesBySKUVisualizationData>(
    [actions.LIST_SALES_BY_SKU_VISUALIZATION_REQUEST],
    [actions.EXPORT_SALES_BY_SKU_VISUALIZATION_REQUEST],
    false,
    [actions.HIDE_SALES_DATA],
  ),
  skuVisualizationsFilters: createLoadingDataReducer<ISalesBySKUVisualizationFilters>(
    [actions.LIST_SALES_BY_SKU_FILTERS_REQUEST],
    [],
    false,
    [actions.HIDE_SALES_DATA],
  ),
  ongoingReporting(state: IOngoingReporting = null, action) {
    switch (action.type) {
      case PROCESS_CAPTABLE_SUCCESS:
      case actions.SHOW_ONGOING_REPORTING_SUCCESS:
      case actions.SUBMIT_ONGOING_REPORTING_SUCCESS:
        return action.data
      case actions.UPDATE_ONGOING_REPORTING_STATUS_SUCCESS:
        if (!state) {
          return action.data
        }
        return {
          ...state,
          ...action.data,
        }
      case actions.HIDE_ONGOING_REPORTING:
        return null
      default:
        return state
    }
  },
  dataConsolidated(state: IOngoingReportingRawMappingData = null, action) {
    switch (action.type) {
      case actions.UPDATE_CONSOLIDATED_ONGOING_REPORTING_SUCCESS:
        return {
          ...state,
          data: state.data.map((item) =>
            action.data.find(({ id }: IOngoingReportingRawMapping) => id === item.id)
              ? {
                  ...item,
                  ...(action.data.find(({ id }: IOngoingReportingRawMapping) => id === item.id) ||
                    {}),
                }
              : item,
          ),
        }
      case actions.LIST_CONSOLIDATED_ONGOING_REPORTING_SUCCESS:
        if (!action.loadMore) {
          return action.data
        } else {
          return {
            ...state,
            data: [...state.data, ...action.data.data],
          }
        }
      default:
        return state
    }
  },
  dataSummary(
    state: Partial<Record<OngoingReportingType, IOngoingReportingSummaryData>> = {},
    action,
  ) {
    switch (action.type) {
      case actions.LIST_SUMMARY_ONGOING_REPORTING_SUCCESS:
      case LIST_CLIENT_FINANCIALS_SUMMARY_SUCCESS:
        return {
          ...state,
          [action.data.statementType]: action.data.data,
        }
      default:
        return state
    }
  },
  dataSummaryHeaders(state: IOngoingReportingSummaryHeaders = null, action) {
    switch (action.type) {
      case actions.LIST_SUMMARY_ONGOING_REPORTING_SUCCESS:
      case LIST_CLIENT_FINANCIALS_SUMMARY_SUCCESS:
        return {
          ...state,
          [action.data.statementType]: action.data.headers,
        }
      default:
        return state
    }
  },
  dataSummaryHeadersColors(state: IOngoingReportingSummaryHeaders = null, action) {
    switch (action.type) {
      case actions.LIST_SUMMARY_ONGOING_REPORTING_SUCCESS:
      case LIST_CLIENT_FINANCIALS_SUMMARY_SUCCESS:
        return {
          ...state,
          [action.data.statementType]: action.data.headersColors,
        }
      default:
        return state
    }
  },
  dataSummaryHeadersData(state: IOngoingReportingSummaryHeadersData = null, action) {
    switch (action.type) {
      case actions.LIST_SUMMARY_ONGOING_REPORTING_SUCCESS:
      case LIST_CLIENT_FINANCIALS_SUMMARY_SUCCESS:
        return {
          ...state,
          [action.data.statementType]: action.data.headersData,
        }
      default:
        return state
    }
  },
  dataSummaryGrowth(
    state: Partial<Record<OngoingReportingType, IOngoingReportingSummaryData>> = {},
    action,
  ) {
    switch (action.type) {
      case actions.LIST_SUMMARY_ONGOING_REPORTING_SUCCESS:
      case LIST_CLIENT_FINANCIALS_SUMMARY_SUCCESS:
        return {
          ...state,
          [action.data.statementType]: action.data.growth,
        }
      default:
        return state
    }
  },
  reportingUpdatesList(state: any[] = [], action) {
    switch (action.type) {
      case actions.SUBMIT_ONGOING_REPORTING_REQUEST:
        return []
      case actions.SUBMIT_ONGOING_REPORTING_FAILURE:
        if (action.error.code === 'CUSTOM_ERROR') {
          return action.error.data
        }
        return state
      default:
        return state
    }
  },
  salesByDistributionGraph: createLoadingDataReducer<ISalesDistributionAnalysisData>(
    [actions.LIST_SALES_BY_DISTRIBUTION_GRAPH_REQUEST],
    [],
    false,
    [actions.HIDE_SALES_DATA],
  ),
})
