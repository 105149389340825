import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'

import EntityActivityTable from './EntityActivityTable'

import { getEntityActivity } from '../../redux/entityInfo/actions'
import { entityActivity } from '../../redux/entityInfo/selectors'

const selector = createStructuredSelector({
  entityActivityData: entityActivity,
})
const actions = {
  getEntityActivity,
}

export default connect(selector, actions)(EntityActivityTable)
