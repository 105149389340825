import React, { useEffect, useCallback, useState } from 'react'
import { Link, generatePath } from 'react-router-dom'
import Tooltip from '@mui/material/Tooltip'
import Box from '@mui/material/Box'
import cn from 'classnames'
import LinkButton from '@mui/material/Link'
import { ROUTES } from '../../constants/routes'

import styles from './CollectionAggregationRow.module.scss'
import genericSs from '@styles/generic.module.scss'

import { IAggregationDataRow } from '@common/interfaces/collection'
import TableRow from '../Common/TableRow'
import TableCell from '../Common/TableCell'
import { formatDateTime, formatPrice, formatDate } from '../../helpers/helpers'
import TableLoader from '../../components/Common/TableLoader'
import { MenuIcon } from '../Common/Icons'

interface IProps {
  clientName: string
  recordDate: string
  data: IAggregationDataRow[]
  listAggregationRow?: (clientName: string, recordDate: string, filters?: object) => void
  handleMenuClick?: (item: IAggregationDataRow, event: React.MouseEvent<HTMLElement>) => void
  activeItems: number[]
  activeItem: number
  parentIndex: number
  handleSelectRow: any
}

const CollectionAggregationRow = ({
  clientName,
  recordDate,
  data,
  listAggregationRow,
  handleMenuClick,
  activeItems,
  activeItem,
  parentIndex,
  handleSelectRow,
}: IProps) => {
  const [isLoading, setIsLoading] = useState(false)
  const refetchAggregationRow = useCallback(async () => {
    setIsLoading(true)
    await listAggregationRow(clientName, recordDate)
    setIsLoading(false)
  }, [clientName, recordDate, listAggregationRow])

  useEffect(() => {
    if (!data) {
      refetchAggregationRow()
    }
  }, [data, refetchAggregationRow])

  if (!data && !isLoading) {
    return null
  }

  return (
    <>
      {isLoading ? (
        <TableLoader columnsCount={9} rowsCount={1} />
      ) : (
        data.map((item, index) => {
          const rowIndex = +(parentIndex + index.toString().padStart(4, '0'))
          return (
            <TableRow
              key={item.id}
              data-index={rowIndex}
              className={cn(styles.collectionAggregationRow, 'activableRow', {
                activeRow: activeItems.includes(rowIndex),
                currentActiveRow: activeItem === rowIndex,
              })}
              onClick={(event) => handleSelectRow(event, rowIndex)}
            >
              <TableCell className={genericSs.tableTextLeft} />
              <TableCell className={genericSs.tableTextLeft} />
              <TableCell className={genericSs.tableTextLeft}>
                {item?.entityId ? (
                  <LinkButton
                    component={Link}
                    to={generatePath(ROUTES.ENTITY_PAGE, {
                      id: item.entityId,
                    })}
                  >
                    {item.debtor}
                  </LinkButton>
                ) : (
                  <span>{item.debtor}</span>
                )}
              </TableCell>
              <TableCell className={genericSs.tableTextLeft}>
                {item.isCheck ? (
                  <>
                    <span className={genericSs.pricePrefix}>$</span>
                    {formatPrice(item.checkPaymentAmount)}
                  </>
                ) : (
                  ''
                )}
              </TableCell>
              <TableCell className={genericSs.tableTextLeft}>
                <span className={genericSs.colorSecondary}>
                  {item.isCheck ? (
                    <LinkButton
                      href={item.boxLink}
                      target="_blank"
                      rel="noopener noreferrer"
                      onClick={() => window.open(item.boxLink, '_blank')}
                    >
                      {item.checkNumber}
                    </LinkButton>
                  ) : (
                    ''
                  )}
                </span>
              </TableCell>
              <TableCell className={genericSs.tableTextRight}>
                {item.isWire ? (
                  <>
                    <span className={genericSs.pricePrefix}>$</span>
                    {formatPrice(item.wirePaymentAmount)}
                  </>
                ) : (
                  ''
                )}
              </TableCell>
              <TableCell className={genericSs.tableTextRight}>
                <span className={genericSs.pricePrefix}>$</span>
                {formatPrice(item.isWire ? item.wirePaymentAmount : item.checkPaymentAmount)}
              </TableCell>
              <TableCell className={genericSs.tableTextLeft}>
                <Tooltip title={formatDateTime(item.processedAt)} placement="top">
                  <span>{formatDate(item.recordDate)}</span>
                </Tooltip>
              </TableCell>
              <TableCell className={genericSs.tableTextLeft}>
                {item.isEditable && (
                  <Box display="inline-box" ml={1}>
                    <MenuIcon
                      onClick={(event: React.MouseEvent<HTMLElement>) =>
                        handleMenuClick(item, event)
                      }
                      size="small"
                    />
                  </Box>
                )}
              </TableCell>
            </TableRow>
          )
        })
      )}
    </>
  )
}

export default CollectionAggregationRow
