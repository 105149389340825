import {
  updateParticipationWire,
  deleteParticipationWire,
  exportParticipationActivity,
} from './../../../../redux/participant/actions'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import ParticipationActivityMenu from './ParticipationActivityMenu'
import { loanBalanceStartDate } from '../../../../redux/loanBalance/selectors'

const selector = createStructuredSelector({
  loanBalanceStartDate,
})

const actions = {
  updateParticipationWire,
  deleteParticipationWire,
  exportParticipationActivity,
}

export default connect(selector, actions)(ParticipationActivityMenu)
