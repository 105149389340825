import React, { useEffect, useCallback, useState, useMemo } from 'react'
import { useParams } from 'react-router'
import { Form } from 'react-final-form'
import arrayMutators from 'final-form-arrays'
import setFieldData from 'final-form-set-field-data'

import { debounceEventHandler } from '../../helpers/helpers'
import { ENTITY_ACCOUNTS_LIST_FILTERS_CONFIG, PER_PAGE } from '@common/constants/filters'
import { buildFiltersDefaults } from '../../helpers/filters'
import { ILoadingData } from '../../redux/types'
import { IEntityAccountsData } from '@common/interfaces/entityInfo'
import useTable from '../../hooks/useTable'
import EntityAccountMappingForm from './EntityAccountMappingForm'
import { ICheckAccount } from '@common/interfaces/collection'

const mutators = {
  ...arrayMutators,
  setFieldData,
}

interface IProps {
  entityAccounts: ILoadingData<IEntityAccountsData>
  listEntityAccounts: (id: string, data: object) => Promise<any>
  hideEntityAccounts: () => void
  updateEntityAccounts: (id: string, data: object) => Promise<any>
  listEntityInfo: (data: object) => Promise<{ data: any }>
  addEntityInfo: (data: object) => Promise<any>
}

const filtersDefaults = buildFiltersDefaults(ENTITY_ACCOUNTS_LIST_FILTERS_CONFIG)

const EntityAccountMapping = ({
  entityAccounts,
  listEntityAccounts,
  hideEntityAccounts,
  updateEntityAccounts,
  listEntityInfo,
  addEntityInfo,
}: IProps) => {
  const { id } = useParams<{ id: string }>()
  const [isSaving, setIsSaving] = useState(false)
  const [isSaved, setIsSaved] = useState(false)

  const { isLoading, accounts, itemsCount, totalItems } = useMemo(
    () => ({
      isLoading: entityAccounts.isLoading,
      accounts: entityAccounts?.data?.data || [],
      itemsCount: entityAccounts?.data?.data?.length,
      totalItems: entityAccounts?.data?.totals?.totalItems,
    }),
    [entityAccounts],
  )

  const {
    filters,
    handleFiltersChange,
    handleOrderChange,
    orderBy,
    activeItem,
    activeItems,
    setActiveItem,
    setActiveItems,
    handleSelectRow,
    resetActiveItems,
  } = useTable({
    tableId: 'entityAccountMapping',
    filtersDefaults,
    sortDefault: {
      field: 'accountNumber',
      direction: 'ASC',
    },
  })

  useEffect(() => {
    return () => {
      hideEntityAccounts()
    }
  }, [hideEntityAccounts])

  const handleListEntityAccounts = useCallback(
    (data: object) => id && listEntityAccounts(id, data),
    [id, listEntityAccounts],
  )
  const debounceHandleListEntityAccounts = useMemo(
    () => debounceEventHandler(handleListEntityAccounts, 500),
    [handleListEntityAccounts],
  )

  useEffect(() => {
    debounceHandleListEntityAccounts({
      page: 0,
      perPage: PER_PAGE,
      filters,
      orderBy: orderBy.field,
      orderDirection: orderBy.direction,
    })
  }, [filters, orderBy, debounceHandleListEntityAccounts])

  const loadMore = useCallback(
    () =>
      handleListEntityAccounts({
        loadMore: true,
        page: Math.ceil(itemsCount / PER_PAGE),
        perPage: Number(PER_PAGE),
        filters,
        orderBy: orderBy.field,
        orderDirection: orderBy.direction,
      }),
    [filters, orderBy, handleListEntityAccounts, itemsCount],
  )

  const refetchEntityAccountsList = useCallback(
    () =>
      handleListEntityAccounts({
        page: 0,
        perPage: itemsCount,
        filters,
        orderBy: orderBy.field,
        orderDirection: orderBy.direction,
        skipLoader: true,
      }),
    [filters, orderBy, handleListEntityAccounts, itemsCount],
  )

  const loadEntitiesOptions = useCallback(
    async (inputValue: string) => {
      const res = await listEntityInfo({
        name: inputValue,
      })
      return res.data.map((item: any) => ({
        id: item.id,
        value: item.name,
        label: item.name,
      }))
    },
    [listEntityInfo],
  )

  const handleAddEntityOption = useCallback(
    async (mapping: string | string[]) => addEntityInfo({ name: mapping, type: 'debtor' }),
    [addEntityInfo],
  )

  const initialValues = useMemo(
    () => ({
      accounts: (accounts || []).map((checkAccount) => ({
        id: checkAccount.id,
        accountNumber: checkAccount.accountNumber,
        lastSeenDate: checkAccount.lastSeenDate,
        lastMappedDate: checkAccount.lastMappedDate,
        linkedName: checkAccount.linkedName,
      })),
    }),
    [accounts],
  )

  const handleSubmit = useCallback(
    async (values) => {
      setIsSaving(true)
      setIsSaved(false)
      const accountsToDelete: string[] = []
      const accountsToUpdate: { [key: string]: string } = {}
      const accounts: ICheckAccount[] = values.accounts

      accounts.forEach((value, index) => {
        if (!value.linkedName && Boolean(initialValues.accounts[index].linkedName)) {
          accountsToDelete.push(value.accountNumber)
        } else if (
          Boolean(value.linkedName) &&
          value.linkedName !== initialValues.accounts[index].linkedName
        ) {
          accountsToUpdate[value.accountNumber] = value.linkedName
        }
      })

      let result
      if (accountsToDelete.length) {
        result = await updateEntityAccounts(id, {
          accountNumber: accountsToDelete,
          linkedName: null,
        })
        if (!!result?.error) {
          setIsSaving(false)
          return
        }
      }
      if (Object.keys(accountsToUpdate).length) {
        const accountNumbers = Object.keys(accountsToUpdate)
        const linkedNames = Object.values(accountsToUpdate)
        result = await handleAddEntityOption([...new Set(linkedNames)])
        if (!!result?.error) {
          setIsSaving(false)
          return
        }
        result = await updateEntityAccounts(id, {
          accountNumber: accountNumbers,
          linkedName: linkedNames,
        })
        if (!!result?.error) {
          setIsSaving(false)
          return
        }
      }
      resetActiveItems()
      await refetchEntityAccountsList()

      setIsSaved(true)
      setIsSaving(false)
    },
    [
      initialValues,
      id,
      updateEntityAccounts,
      handleAddEntityOption,
      resetActiveItems,
      refetchEntityAccountsList,
    ],
  )

  return (
    <Form
      onSubmit={handleSubmit}
      initialValues={initialValues}
      mutators={mutators}
      render={(formProps) => (
        <EntityAccountMappingForm
          {...formProps}
          isLoading={isLoading}
          isSaving={isSaving}
          isSaved={isSaved}
          filters={filters}
          onFiltersChange={handleFiltersChange}
          orderBy={orderBy}
          onOrderChange={handleOrderChange}
          itemsCount={itemsCount}
          totalItems={totalItems}
          loadMore={loadMore}
          loadEntitiesOptions={loadEntitiesOptions}
          handleAddEntityOption={handleAddEntityOption}
          activeItem={activeItem}
          activeItems={activeItems}
          setActiveItem={setActiveItem}
          setActiveItems={setActiveItems}
          handleSelectRow={handleSelectRow}
        />
      )}
    />
  )
}

export default EntityAccountMapping
