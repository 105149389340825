import React, { useCallback, useMemo, useEffect } from 'react'
import { IFlags, IBBCFlagDetailsData, IBBCFlagDetails } from '@common/interfaces/bbc'
import Table from '../../Common/Table'
import TableHead from '../../Common/TableHead'
import TableRow from '../../Common/TableRow'
import TableContainer from '../../Common/TableContainer'
import TableFiltersRow from '../../Common/TableFiltersRow'
import TableBody from '../../Common/TableBody'
import TableLoader from '../../Common/TableLoader'
import InfiniteScroll from 'react-infinite-scroll-component'
import styles from './../FlagContainer.module.scss'
import { buildFiltersValidateSchema } from '../../../helpers/filters'
import { textToTitleCase } from '@common/helpers/helpers'
import { Form } from 'react-final-form'
import cn from 'classnames'
import FilterContainer from '../../Filters/FilterContainer'
import { DETAILED_BBC_FLAGS_MAPPING } from '@common/interfaces/bbc'
import { BBC_DETAIL_LIST_FILTERS_CONFIG, IFilter, PER_PAGE } from '@common/constants/filters'
import { DETAILED_FLAG_FILTER_MAPPING } from '../constants'
import FlagDetailTable from '../FlagDetailTable'
import FlagTitle from '../FlagTitle'
import Note from '../../Notes/Note'
import { boldFlagDescription } from '../Flag/Flag'
import useTable from '../../../hooks/useTable'
import { buildFiltersDefaults } from '../../../helpers/filters'
import { ILoadingData } from 'src/redux/types'
interface IPropsFlagDetailSidePanel {
  currentFlag: IFlags | null
  closeExpandModal: () => void
  getFlagDetails: (id: string, params: object) => void
  getFlagDetailsDuplicateInvoices: (id: string, params: object) => void
  getFlagDetailPaidPriorityInvoices: (id: string, params: object) => void
  getFlagDetailsPendingCashDominion: (id: string, params: object) => void
  flagDetailsData: ILoadingData<IBBCFlagDetailsData>
}

const FlagDetailSidePanel = ({
  flagDetailsData,
  currentFlag,
  getFlagDetails,
  getFlagDetailsDuplicateInvoices,
  getFlagDetailPaidPriorityInvoices,
  getFlagDetailsPendingCashDominion,
  closeExpandModal,
}: IPropsFlagDetailSidePanel) => {
  const { isLoading, data: flagDetails } = useMemo(() => flagDetailsData, [flagDetailsData])

  const hasTable = useMemo(() => {
    return DETAILED_BBC_FLAGS_MAPPING[currentFlag?.flagName]?.sourceData ?? false
  }, [currentFlag])

  const filteredDetailedFlags = useMemo(() => {
    if (flagDetails?.data.length > 0) {
      return flagDetails?.data.filter((flag) => flag.flagLod === 'Detail')
    }
    return null
  }, [flagDetails])

  const [filtersDefaults, orderDefaults] = useMemo(() => {
    if (currentFlag?.flagName === null) {
      return [null, null]
    }
    const filterDefaults = DETAILED_FLAG_FILTER_MAPPING[currentFlag?.flagName]?.filters
      ? buildFiltersDefaults(DETAILED_FLAG_FILTER_MAPPING[currentFlag?.flagName].filters)
      : buildFiltersDefaults(BBC_DETAIL_LIST_FILTERS_CONFIG)

    const orderDefaults = {
      field:
        DETAILED_FLAG_FILTER_MAPPING[currentFlag?.flagName]?.defaultFilter ||
        'impact_to_availability',
      direction: 'DESC' as const,
    }

    return [filterDefaults, orderDefaults]
  }, [currentFlag?.flagName])

  const {
    filters,
    handleFiltersChange,
    handleOrderChange,
    orderBy,
    activeItem,
    activeItems,
    setActiveItem,
    setActiveItems,
    handleSelectRow,
  } = useTable({
    tableId: 'flagDetails',
    filtersDefaults,
    sortDefault: orderDefaults,
  })

  useEffect(() => {
    if (currentFlag?.flagName !== null) {
      handleFiltersChange(filtersDefaults)
      handleOrderChange(orderDefaults.field, orderDefaults.direction)
    }
  }, [
    currentFlag?.flagName,
    filtersDefaults,
    orderDefaults,
    handleFiltersChange,
    handleOrderChange,
  ])

  const handleListFlagDetail = useCallback(
    (data: object) => {
      if (currentFlag?.flagName === 'duplicate_ar_invoices') {
        getFlagDetailsDuplicateInvoices(currentFlag?.id, {
          ...data,
        })
      } else if (currentFlag?.flagName === 'priority_invoice_paid') {
        getFlagDetailPaidPriorityInvoices(currentFlag?.id, {
          ...data,
        })
      } else if (currentFlag?.flagName === 'pending_cash_dominion') {
        getFlagDetailsPendingCashDominion(currentFlag?.id, {
          ...data,
        })
      } else if (DETAILED_BBC_FLAGS_MAPPING[currentFlag?.flagName]?.sourceData) {
        getFlagDetails(currentFlag?.id, {
          ...data,
        })
      }
    },
    [
      getFlagDetails,
      currentFlag,
      getFlagDetailsDuplicateInvoices,
      getFlagDetailPaidPriorityInvoices,
      getFlagDetailsPendingCashDominion,
    ],
  )

  const filtersConfig = useMemo(
    () =>
      DETAILED_FLAG_FILTER_MAPPING[currentFlag?.flagName]?.filters ||
      BBC_DETAIL_LIST_FILTERS_CONFIG.map((filterConfig) => ({
        ...filterConfig,
        title: ['oldValue', 'newValue'].includes(filterConfig.field)
          ? `${textToTitleCase(filterConfig.field.slice(0, 3))} ${
              DETAILED_BBC_FLAGS_MAPPING[currentFlag?.flagName]?.label
            }`
          : filterConfig.title,
        type: ['oldValue', 'newValue'].includes(filterConfig.field)
          ? DETAILED_BBC_FLAGS_MAPPING[currentFlag?.flagName]?.type
          : filterConfig.type,
      })),
    [currentFlag],
  )

  const validOrderBy = useMemo(() => {
    return filtersConfig.find(
      (filter: IFilter) => filter.field === orderBy.field || filter.dbField === orderBy.field,
    )
  }, [filtersConfig, orderBy])

  useEffect(() => {
    if (currentFlag && filters && validOrderBy) {
      handleListFlagDetail({
        flagName: currentFlag.flagName,
        flagType: currentFlag.flagType,
        page: 0,
        perPage: PER_PAGE,
        filters,
        orderBy: orderBy.field,
        orderDirection: orderBy.direction,
      })
    }
  }, [currentFlag, filters, orderBy, handleListFlagDetail, validOrderBy])
  const filtersValidate = useMemo(() => buildFiltersValidateSchema(filtersConfig), [filtersConfig])

  const itemsCount = useMemo(() => filteredDetailedFlags?.length, [filteredDetailedFlags])
  const totalCount = useMemo(() => (flagDetails ? flagDetails.totalItems : 0), [flagDetails])

  const loadMore = useCallback(() => {
    handleListFlagDetail({
      flagName: currentFlag.flagName,
      flagType: currentFlag.flagType,
      loadMore: true,
      page: Math.ceil(itemsCount / PER_PAGE),
      perPage: Number(PER_PAGE),
      filters,
      orderBy: orderBy.field,
      orderDirection: orderBy.direction,
    })
  }, [filters, orderBy, handleListFlagDetail, itemsCount, currentFlag])

  const { flagTitle, flagDescription } = useMemo(() => {
    const flagTitle = currentFlag?.message?.split(':')[0]
    const flagDescription =
      currentFlag?.message?.split(':')[currentFlag?.message?.split(':').length - 1]
    if (!flagDescription) {
      return { flagTitle, flagDescription: [] }
    }

    const parts = boldFlagDescription(flagDescription)
    return { flagTitle, flagDescription: parts }
  }, [currentFlag])

  return (
    <div className={styles.expandedContainer}>
      <FlagTitle flag={currentFlag} flagTitle={flagTitle} isSidePanel />

      <div className={styles.expandedContainerContent}>
        <div className={styles.flagDescription}>{flagDescription}</div>

        {hasTable && (
          <div className={styles.tableWrapper}>
            <TableContainer
              className={styles.table}
              isActivable
              onActiveRowsChange={setActiveItems}
              onActiveRowChange={setActiveItem}
            >
              <Form
                validate={filtersValidate}
                onSubmit={handleFiltersChange}
                initialValues={filters}
                mutators={{
                  setFieldData: ([field, value], state, { changeValue }) => {
                    changeValue(state, field, () => value)
                  },
                }}
                render={({ values: filterValues, handleSubmit, form: { mutators } }) => (
                  <FilterContainer
                    filters={filtersConfig}
                    handleSubmit={handleSubmit}
                    mutators={mutators}
                    values={filterValues}
                    appliedFilters={filters}
                  />
                )}
              />
              <Table>
                <TableHead>
                  <TableFiltersRow
                    filters={filtersConfig}
                    orderBy={orderBy}
                    handleOrderChange={handleOrderChange}
                  />
                </TableHead>
                <TableBody id="scrollableTableDetails" className={styles.detailsBody}>
                  {isLoading ? (
                    <TableLoader columnsCount={filtersConfig.length} rowsCount={16} />
                  ) : (
                    filteredDetailedFlags && (
                      <InfiniteScroll
                        dataLength={itemsCount}
                        next={loadMore}
                        hasMore={itemsCount < totalCount}
                        loader={<TableLoader columnsCount={filtersConfig.length} rowsCount={1} />}
                        scrollableTarget="scrollableTableDetails"
                      >
                        {filteredDetailedFlags?.map((flag: IBBCFlagDetails, index: any) => {
                          return (
                            <TableRow
                              id={`flag-detail-${index}`}
                              key={flag.id}
                              data-index={index}
                              className={cn('activableRow', {
                                activeRow: activeItems.includes(index),
                                currentActiveRow: activeItem === index,
                              })}
                              onClick={(event) => handleSelectRow(event, index)}
                            >
                              <FlagDetailTable
                                flag={flag}
                                currentFlag={currentFlag}
                                large={!DETAILED_FLAG_FILTER_MAPPING[currentFlag?.flagName]?.small}
                              />
                            </TableRow>
                          )
                        })}
                      </InfiniteScroll>
                    )
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        )}
        <div className={styles.notesRepliesContainerList}>
          <div className={styles.noteExpandedContainer}>
            <Note
              note={currentFlag?.notes}
              isExpandModal
              isFlag
              hasTable={hasTable}
              handleCloseExpandModal={closeExpandModal}
              commentsContainerListClassName={styles.commentsContainer}
              addCommentClassName={styles.stickyAddComment}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default FlagDetailSidePanel
