import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'

import { notification } from '../../../redux/ui/selectors'
import { putNotification, removeNotification } from '../../../redux/ui/actions'
import Snackbar from './Snackbar'

const selector = createStructuredSelector({
  notification,
})

const actions = { onClose: removeNotification, onCloseOneMessage: putNotification }

export default connect(selector, actions)(Snackbar)
