import React from 'react'
import MuiIconButton, { IconButtonProps } from '@mui/material/IconButton'

import styles from './IconButton.module.scss'

const IconButton = (props: IconButtonProps) => {
  return <MuiIconButton classes={{ root: styles.root }} {...props} />
}

export default IconButton
