import { Action } from 'redux'
import { takeEvery, put, select } from 'redux-saga/effects'
import { matchPath } from 'react-router-dom'
import { ROUTES } from '../../constants/routes'
import {
  SUBMIT_BBC_FILES_SUCCESS,
  UPDATE_EXTRA_RESERVES_SUCCESS,
  CALCULATE_CLIENT_COLLATERAL_SUCCESS,
  EXPORT_BBC_SUCCESS,
  DOWNLOAD_TEMPLATE_SUCCESS,
  CREATE_BBC_RESERVE_SUCCESS,
  UPDATE_BBC_RESERVE_SUCCESS,
  DELETE_BBC_RESERVE_SUCCESS,
  CREATE_INVENTORY_INELIGIBLE_CUSTOM_RULES_SUCCESS,
  UPDATE_INVENTORY_INELIGIBLE_CUSTOM_RULES_SUCCESS,
  DELETE_INVENTORY_INELIGIBLE_CUSTOM_RULES_SUCCESS,
  EXPORT_BBC_AP_ROLLFORWARD_SUCCESS,
  EXPORT_BBC_AP_ROLLFORWARD_FAILURE,
  show,
  getBBCSummaryStats,
  calculateClientCollateral,
  listCustomRules,
  listCustomRulesLabels,
  CREATE_INVENTORY_INELIGIBLE_CUSTOM_RULES_LABELS_SUCCESS,
  listARIneligibleStats,
  listInventoryIneligibleStatsSummary,
  EXPORT_BBC_AR_ROLLFORWARD_FAILURE,
  EXPORT_BBC_AR_ROLLFORWARD_SUCCESS,
  UPDATE_BBC_SUCCESS,
  GET_BBC_AR_ROLLFORWARD_STATS_SUCCESS,
  getBBCFiles,
  DOWNLOAD_TEMPLATE_FAILURE,
  EXPORT_BBC_FAILURE,
} from './actions'
import { IResponse } from '@common/interfaces/request'
import { download } from '../../helpers/helpers'
import { bbc } from './selectors'
import { pathname } from '../router/selectors'
import { listCustomRules as listCustomEligibleRules } from '../clientInfo/actions'
import { clientInfo as client } from '../clientInfo/selectors'
import { calculateBBCFlags } from '../flag/actions'
import { UserRole } from '@common/interfaces/user'
import { role } from '../profile/selectors'
import { FundingRequestStatus } from '@common/interfaces/bbc'

export function* bbcUpdateSuccess() {
  yield takeEvery([UPDATE_BBC_SUCCESS], function* (data: IResponse & Action) {
    yield put(show(data.data.id))
  })
}

export function* bbcFlagUpdate() {
  yield takeEvery([GET_BBC_AR_ROLLFORWARD_STATS_SUCCESS], function* () {
    const userRole: UserRole = yield select(role)
    if (
      userRole !== UserRole.PARTICIPANT_USER &&
      matchPath(window.location.pathname, { path: ROUTES.BBC_SUMMARY })
    ) {
      const { id } = yield select(bbc)
      const { status } = yield select(bbc)
      if (![FundingRequestStatus.Completed, FundingRequestStatus.Sent].includes(status)) {
        yield put(calculateBBCFlags({ id }))
      }
    }
  })
}

export function* bbcFilesSubmitSuccess() {
  yield takeEvery([SUBMIT_BBC_FILES_SUCCESS], function* (data: IResponse & Action) {
    yield put(show(data.data.id))
    yield put(getBBCFiles(data.data.id))
  })
}

export function* refetchBBCReserves() {
  yield takeEvery(
    [CREATE_BBC_RESERVE_SUCCESS, UPDATE_BBC_RESERVE_SUCCESS, DELETE_BBC_RESERVE_SUCCESS],
    function* () {
      const { id }: { id: string } = yield select(bbc)

      yield put(calculateClientCollateral(id))
    },
  )
}

export function* recalculateClientCollateral() {
  yield takeEvery([UPDATE_EXTRA_RESERVES_SUCCESS], function* (data: IResponse & Action) {
    yield put(calculateClientCollateral(data.data.id))
  })
}

export function* calculateClientCollateralSuccess() {
  yield takeEvery([CALCULATE_CLIENT_COLLATERAL_SUCCESS], function* (data: IResponse & Action) {
    yield put(show(data.data.id))
    yield put(getBBCSummaryStats(data.data.id))

    const path: string = yield select(pathname)

    if (matchPath(path, { path: ROUTES.BBC_SUMMARY_RECEIVABLES })) {
      yield put(listARIneligibleStats(data.data.id))
    }
    if (matchPath(path, { path: ROUTES.BBC_SUMMARY_INVENTORY })) {
      yield put(listInventoryIneligibleStatsSummary(data.data.id))
    }
  })
}

export function* downloadBBCExport() {
  yield takeEvery(EXPORT_BBC_SUCCESS, function* (action: IResponse & Action) {
    try {
      yield download(action.data, action.data.name)
    } catch (error) {
      yield put({ type: EXPORT_BBC_FAILURE, error })
    }
  })
}

export function* downloadTemplate() {
  yield takeEvery([DOWNLOAD_TEMPLATE_SUCCESS], function* (action: IResponse & Action) {
    try {
      yield download(action.data, action.data.name)
    } catch (error) {
      yield put({ type: DOWNLOAD_TEMPLATE_FAILURE, error })
    }
  })
}

export function* refetchCustomRules() {
  yield takeEvery(
    [
      CREATE_INVENTORY_INELIGIBLE_CUSTOM_RULES_SUCCESS,
      UPDATE_INVENTORY_INELIGIBLE_CUSTOM_RULES_SUCCESS,
      DELETE_INVENTORY_INELIGIBLE_CUSTOM_RULES_SUCCESS,
    ],
    function* () {
      const path: string = yield select(pathname)

      if (matchPath(path, ROUTES.CLIENT_SETUP)) {
        const { id: clientId } = yield select(client)
        yield put(listCustomEligibleRules(clientId))
      } else if (matchPath(path, ROUTES.BBC_COLLATERAL_ELIGIBILITY_INVENTORY)) {
        const { id } = yield select(bbc)
        yield put(listCustomRules({ borrowingBaseId: id, skipLoader: true }))
      }
    },
  )
}

export function* refetchCustomRulesLabels() {
  yield takeEvery([CREATE_INVENTORY_INELIGIBLE_CUSTOM_RULES_LABELS_SUCCESS], function* () {
    yield put(listCustomRulesLabels())
  })
}

export function* exportARRollforward() {
  yield takeEvery(EXPORT_BBC_AR_ROLLFORWARD_SUCCESS, function* (action: IResponse & Action) {
    try {
      const fileName = action.data.name
      yield download(action.data, fileName)
    } catch (error) {
      yield put({ type: EXPORT_BBC_AR_ROLLFORWARD_FAILURE, error })
    }
  })
}

export function* exportAPRollforward() {
  yield takeEvery(EXPORT_BBC_AP_ROLLFORWARD_SUCCESS, function* (action: IResponse & Action) {
    try {
      const fileName = action.data.name
      yield download(action.data, fileName)
    } catch (error) {
      yield put({ type: EXPORT_BBC_AP_ROLLFORWARD_FAILURE, error })
    }
  })
}
