import { IApi } from '../../api'
import { CommonPromiseAction } from '../types'

export const prefix = 'auditLog'

export const LIST_AUDIT_LOG_REQUEST = `${prefix}/LIST_AUDIT_LOG_REQUEST`
export const LIST_AUDIT_LOG_SUCCESS = `${prefix}/LIST_AUDIT_LOG_SUCCESS`
export const LIST_AUDIT_LOG_FAILURE = `${prefix}/LIST_AUDIT_LOG_FAILURE`

export const listAuditLog: CommonPromiseAction = (data: object) => ({
  type: [LIST_AUDIT_LOG_REQUEST, LIST_AUDIT_LOG_SUCCESS, LIST_AUDIT_LOG_FAILURE],
  promise: (api: IApi) => api.auditLog.list(data),
  // @ts-ignore
  loadMore: data.loadMore,
})

export const EXPORT_AUDIT_LOG_REQUEST = `${prefix}/EXPORT_AUDIT_LOG_REQUEST`
export const EXPORT_AUDIT_LOG_SUCCESS = `${prefix}/EXPORT_AUDIT_LOG_SUCCESS`
export const EXPORT_AUDIT_LOG_FAILURE = `${prefix}/EXPORT_AUDIT_LOG_FAILURE`

export const exportAuditLog: CommonPromiseAction = (params: object) => ({
  type: EXPORT_AUDIT_LOG_REQUEST,
  params,
})

export const GET_AUDIT_LOG_DICTIONARY_REQUEST = `${prefix}/GET_AUDIT_LOG_DICTIONARY_REQUEST`
export const GET_AUDIT_LOG_DICTIONARY_SUCCESS = `${prefix}/GET_AUDIT_LOG_DICTIONARY_SUCCESS`
export const GET_AUDIT_LOG_DICTIONARY_FAILURE = `${prefix}/GET_AUDIT_LOG_DICTIONARY_FAILURE`

export const getAuditLogDictionary: CommonPromiseAction = () => ({
  type: [
    GET_AUDIT_LOG_DICTIONARY_REQUEST,
    GET_AUDIT_LOG_DICTIONARY_SUCCESS,
    GET_AUDIT_LOG_DICTIONARY_FAILURE,
  ],
  promise: (api: IApi) => api.auditLog.getDictionary(),
})
