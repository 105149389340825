import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'

import ProspectSummaryPage from './ProspectSummaryPage'

import { reporting } from '../../redux/prospect/selectors'
import { show, startReporting } from '../../redux/prospect/actions'

const selector = createStructuredSelector({
  reporting,
})
const actions = {
  show,
  startReporting,
}

export { ProspectSummaryPage }

export default connect(selector, actions)(ProspectSummaryPage)
