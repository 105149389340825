import React, { useMemo, useCallback, useState, useEffect } from 'react'
import { useParams, useHistory, useLocation } from 'react-router'
import { generatePath } from 'react-router-dom'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import moment from 'moment'
import queryString from 'query-string'
import pick from 'lodash/pick'
import cn from 'classnames'

import {
  CLIENT_FINANCIALS_FIELDS_MAPPING,
  IOngoingReporting,
  IOngoingReportingRawMappingData,
  IOngoingReportingSummaryData,
  IOngoingReportingUpdatesItem,
  ONGOING_REPORTING_TYPE_LABEL,
  OngoingReportingStatus,
  OngoingReportingType,
  ReportingFlow,
  ReportingPeriods,
} from '@common/interfaces/bbc'
import Button from '../../components/Common/Button'
import OngoingReportingStepper from '../../components/OngoingReportingStepper'
import OngoingReportingFiles from '../../components/OngoingReportingFiles'
import OngoingReportingMapping from '../../components/OngoingReportingMapping'
import OngoingReportingSummary from '../../components/OngoingReportingSummary'
import OngoingReportingReconciliation from '../../components/OngoingReportingReconciliation'
import Modal from '../../components/Common/Modal'
import TableContainer from '../../components/Common/TableContainer'
import Table from '../../components/Common/Table'
import TableHead from '../../components/Common/TableHead'
import TableBody from '../../components/Common/TableBody'
import TableRow from '../../components/Common/TableRow'
import TableCell from '../../components/Common/TableCell'
import { formatDateMonthYear, formatter } from '../../helpers/helpers'
import { getReconciliation } from '../../helpers/financials'
import { REPORTING_DOCUMENT_TYPES } from '@common/constants/client'
import { historyChangeEventHandler, visibilityChangeEventHandler } from '../../helpers/helpers'
import Card from '../../components/Common/Card/Card'
import { useSetPageTitle } from '../../hooks/useSetPageTitle'
import ReactDOM from 'react-dom'
import Breadcrumbs from '../../components/Common/Breadcrumbs'
import { ReactComponent as HomeIcon } from '@assets/images/home-icon.svg'
import { ROUTES } from '../../constants/routes'
import FinancialEditDatesModal from '../../components/FinancialEditDatesModal'
import styles from '../ProspectReportingFinancialsPage/ProspectReportingFinancialsPage.module.scss'
import Pagination from '../../components/Common/Pagination'
import useOngoingReportingFileSheet from '../../hooks/useOngoingReportingFileSheet'

interface IProps {
  statementType: OngoingReportingType | null
  data: IOngoingReporting
  dataConsolidated: IOngoingReportingRawMappingData
  dataSummary: Partial<Record<OngoingReportingType, IOngoingReportingSummaryData>>
  reportingUpdatesList: IOngoingReportingUpdatesItem[]
  showOngoingReporting: (id: string) => void
  updateOngoingReporting: (id: string, data: object) => any
  submitOngoingReporting: (id: string, data: object) => void
  trackActivity: (data: object, beacon?: boolean) => void
  isSidebarOpen: boolean
  isNotesShown: boolean
}

const OngoingReportingPage = ({
  statementType,
  data,
  dataConsolidated,
  dataSummary,
  reportingUpdatesList,
  showOngoingReporting,
  updateOngoingReporting,
  submitOngoingReporting,
  trackActivity,
  isSidebarOpen,
  isNotesShown,
}: IProps) => {
  const { id } = useParams<{ id: string }>()
  const [isButtonLoading, setIsButtonLoading] = useState(false)
  const [isConfirmModalShown, setIsConfirmModalShown] = useState(false)
  const [isMappingModalShown, setIsMappingModalShown] = useState(false)
  const [isModalShown, setIsModalShown] = useState(false)
  const [refreshCounter, setRefreshCounter] = useState(1)
  const [isModalMultiSelected, setIsModalMultiSelected] = useState(false)

  const [currentReportingPeriod, setCurrentReportingPeriod] = useState<ReportingPeriods>(
    ReportingPeriods.Monthly,
  )
  const [isFilesSaving, setIsFilesSaving] = useState(false)

  const history = useHistory()
  const { pathname, search } = useLocation()
  const qs = useMemo(() => queryString.parse(search), [search])

  const [isFileSelectShown, setIsFileSelectShown] = useState(false)
  const handleOpenSelectFile = useCallback(() => {
    setIsFileSelectShown(true)
  }, [setIsFileSelectShown])

  const handleCloseSelectFile = useCallback(() => {
    setIsFileSelectShown(false)
  }, [setIsFileSelectShown])

  const handleSelectFiles = useCallback(
    async (fileSheets: { fileId: string; sheetName: string }[]) => {
      const result = await updateOngoingReporting(id, {
        fileSheets: [
          ...data.fileSheets.filter(({ type }) => type !== statementType),
          ...fileSheets.map((item) => ({
            ...item,
            type: statementType,
          })),
        ],
        refreshReport: statementType,
      })
      if (result?.error?.code === 'FORMAT_ERROR') {
        showOngoingReporting(id)
      }
      setRefreshCounter((counter) => counter + 1)
      return result
    },
    [id, data, statementType, updateOngoingReporting, showOngoingReporting, setRefreshCounter],
  )
  const [startTime, setStartTime] = useState(moment().toISOString())
  const logActivity = useCallback(
    (beacon: boolean = false) => {
      if (id && statementType) {
        const endTime = moment().toISOString()
        const data = {
          ongoingReportingId: id,
          startedAt: startTime,
          finishedAt: endTime,
          workflow: REPORTING_DOCUMENT_TYPES.financials,
          step: statementType,
        }
        trackActivity(data, beacon)
        setStartTime(endTime)
      }
    },
    [id, startTime, trackActivity, statementType],
  )
  useEffect(() => {
    const unlisten = historyChangeEventHandler(logActivity, history, pathname)
    return unlisten
  }, [history, pathname, logActivity])

  useEffect(() => {
    const unlisten = visibilityChangeEventHandler(logActivity)
    return unlisten
  }, [logActivity])

  const handleSubmitOngoingReporting = useCallback(async () => {
    setIsButtonLoading(true)
    await logActivity()
    await submitOngoingReporting(id, { statementType })
    setIsButtonLoading(false)
  }, [id, submitOngoingReporting, statementType, logActivity])

  const dataReconciliation = useMemo(() => {
    return getReconciliation(statementType, dataSummary, dataConsolidated)
  }, [statementType, dataSummary, dataConsolidated])

  useEffect(() => {
    if (reportingUpdatesList.length > 0) {
      setIsConfirmModalShown(true)
    }
  }, [reportingUpdatesList])

  const handleCancel = useCallback(() => {
    setIsConfirmModalShown(false)
  }, [])

  const handleConfirm = useCallback(async () => {
    setIsConfirmModalShown(false)

    submitOngoingReporting(id, { statementType, isConfirmed: true })
  }, [id, statementType, submitOngoingReporting])

  const currentClientName = useMemo(() => {
    if (data?.id === id) {
      return data?.clientName
    }
    return ''
  }, [data, id])

  const breadcrumbs = useMemo(() => {
    return currentClientName
      ? [
          {
            link: ROUTES.HOMEPAGE,
            Icon: HomeIcon,
          },
          {
            link: ROUTES.ANALYSIS_QUEUE,
            title: 'Analysis queue',
          },
          {
            title: currentClientName,
            link: data?.clientInfo?.id
              ? generatePath(ROUTES.CLIENT_PAGE, { id: data?.clientInfo?.id })
              : '',
          },
          {
            link: generatePath(ROUTES.ONGOING_REPORTING, { id }),
            title: `Financial analysis`,
          },
        ]
      : [
          {
            link: ROUTES.HOMEPAGE,
            Icon: HomeIcon,
          },
          {
            link: ROUTES.ANALYSIS_QUEUE,
            title: 'Analysis queue',
          },
          {
            link: generatePath(ROUTES.ONGOING_REPORTING, { id }),
            title: `Financial analysis`,
          },
        ]
  }, [id, currentClientName, data?.clientInfo?.id])

  const [wrapper, setWrapper] = useState<HTMLElement | null>(null)

  useEffect(() => {
    setTimeout(() => {
      isMappingModalShown && setWrapper(document.getElementById('secondModalOpen'))
    }, 1000)
  }, [isMappingModalShown])

  useSetPageTitle(`${currentClientName} Financial Analysis`)

  const isComplete = useMemo(() => {
    return [OngoingReportingStatus.Verified, OngoingReportingStatus.Archived].includes(data?.status)
  }, [data])

  const { fileSheets } = useOngoingReportingFileSheet({
    reporting: data,
    type: statementType,
  })

  const currentFileSheet = useMemo(
    () =>
      fileSheets?.length
        ? fileSheets?.find(
            ({ fileId, sheetName }) => fileId === qs.fileId && sheetName === qs.sheetName,
          ) || fileSheets[0]
        : null,
    [fileSheets, qs],
  )

  const currentFileSheetIndex = useMemo(() => {
    if (!fileSheets?.length) {
      return 0
    }
    const index = fileSheets?.findIndex(
      ({ fileId, sheetName }) => fileId === qs.fileId && sheetName === qs.sheetName,
    )

    if (index === -1) {
      return 0
    }

    return index
  }, [fileSheets, qs])

  const handleChangeCurrentFile = useCallback(
    (event: React.ChangeEvent<unknown>, page: number) => {
      const nextFile = fileSheets[page - 1]
      if (nextFile) {
        history.push({
          pathname,
          search: queryString.stringify({
            ...qs,
            fileId: nextFile.fileId,
            sheetName: nextFile.sheetName,
          }),
        })
      }
    },
    [history, fileSheets, pathname, qs],
  )

  const currentSelectedFile = useMemo(
    () => (currentFileSheet ? data?.files?.find(({ id }) => id === currentFileSheet.fileId) : null),
    [data?.files, currentFileSheet],
  )

  const handleChangeDates = useCallback(
    async (dates: any) => {
      await updateOngoingReporting(id, {
        financialDates: {
          ...data.financialDates,
          [statementType]: dates,
        },
      })
      setRefreshCounter((counter) => counter + 1)
    },
    [id, data, statementType, updateOngoingReporting],
  )
  const currentReportDates = useMemo(
    () =>
      statementType
        ? {
            startDate:
              data?.financialDates?.[statementType]?.startDate ||
              dataConsolidated?.data?.[0]?.values?.[0]?.period?.split('T')?.[0],
            endDate:
              data?.financialDates?.[statementType]?.endDate ||
              dataConsolidated?.data?.[0]?.values?.[
                dataConsolidated?.data?.[0]?.values?.length - 1
              ]?.period?.split('T')?.[0],
          }
        : null,
    [statementType, data, dataConsolidated],
  )
  const currentReportFlowTitle = useMemo(
    () =>
      currentReportDates?.startDate
        ? `from ${formatDateMonthYear(currentReportDates.startDate)} to ${formatDateMonthYear(
            currentReportDates.endDate,
          )}`
        : '',
    [currentReportDates],
  )

  const ongoingReportingData = useMemo(
    () => ({
      ...pick(currentFileSheet || {}, ['fileId', 'sheetName']),
      ongoingReportingId: data?.id,
    }),
    [currentFileSheet, data?.id],
  )

  return (
    <Box>
      <Breadcrumbs breadcrumbs={breadcrumbs} />

      {statementType && (
        <Box mt={1.5} ml={3.75}>
          <h1>
            <Box display="flex" alignItems="center" gap={2} lineHeight="36px">
              Map {ONGOING_REPORTING_TYPE_LABEL[statementType]} {currentReportFlowTitle}
              {fileSheets.length > 0 && (
                <FinancialEditDatesModal
                  startDate={currentReportDates?.startDate}
                  endDate={currentReportDates?.endDate}
                  handleSave={handleChangeDates}
                />
              )}
            </Box>
          </h1>
        </Box>
      )}
      <Card
        withBorder={false}
        title={
          <Box display="flex" justifyContent="space-between" alignItems="center">
            <OngoingReportingStepper handleSubmitOngoingReporting={handleSubmitOngoingReporting} />
            <Button
              type="submit"
              color="primary"
              variant="contained"
              size="small"
              onClick={handleSubmitOngoingReporting}
              isLoading={isButtonLoading}
            >
              {!statementType && isComplete ? 'Update' : !statementType ? 'Submit' : 'Continue'}
            </Button>
          </Box>
        }
      >
        {data && (
          <Grid container spacing={3}>
            {statementType && (
              <Grid item xs={12}>
                <OngoingReportingFiles
                  handleSelectFiles={handleSelectFiles}
                  fileSheets={fileSheets}
                  files={data.files}
                  handleCloseModal={handleCloseSelectFile}
                  handleOpenModal={handleOpenSelectFile}
                  isOpen={isFileSelectShown}
                  setRefreshCounter={setRefreshCounter}
                  setIsFilesSaving={setIsFilesSaving}
                />
              </Grid>
            )}
            {statementType && (
              <Grid item xs={12}>
                <OngoingReportingMapping
                  fileSheets={fileSheets}
                  statementType={statementType}
                  refreshCounter={refreshCounter}
                  isMappingModalShown={isMappingModalShown}
                  setIsMappingModalShown={setIsMappingModalShown}
                  reportingFlow={ReportingFlow.OngoingReporting}
                  currentReportingPeriod={currentReportingPeriod}
                  setCurrentReportingPeriod={setCurrentReportingPeriod}
                  setRefreshCounter={setRefreshCounter}
                  additionalParams={ongoingReportingData}
                  setIsModalMultiSelected={setIsModalMultiSelected}
                />
              </Grid>
            )}
            {statementType && (
              <Grid item xs={12}>
                <OngoingReportingSummary
                  fileSheets={fileSheets}
                  statementType={statementType}
                  refreshCounter={refreshCounter}
                  setIsMappingModalShown={setIsMappingModalShown}
                  handleOpenSelectFile={handleOpenSelectFile}
                  isModalShown={isModalShown}
                  setIsModalShown={setIsModalShown}
                  selectedFile={currentSelectedFile}
                  reportingFlow={ReportingFlow.OngoingReporting}
                  currentReportingPeriod={currentReportingPeriod}
                  setCurrentReportingPeriod={setCurrentReportingPeriod}
                  isLoading={isFilesSaving}
                  additionalParams={ongoingReportingData}
                />
              </Grid>
            )}
            {!statementType && (
              <Grid item xs={12}>
                <OngoingReportingSummary
                  statementType={OngoingReportingType.IncomeStatement}
                  reportingFlow={ReportingFlow.OngoingReporting}
                  currentReportingPeriod={currentReportingPeriod}
                  setCurrentReportingPeriod={setCurrentReportingPeriod}
                />
              </Grid>
            )}
            {!statementType && (
              <Grid item xs={12}>
                <OngoingReportingSummary
                  statementType={OngoingReportingType.BalanceSheet}
                  reportingFlow={ReportingFlow.OngoingReporting}
                  currentReportingPeriod={currentReportingPeriod}
                  setCurrentReportingPeriod={setCurrentReportingPeriod}
                />
              </Grid>
            )}
            {isMappingModalShown && wrapper ? (
              ReactDOM.createPortal(
                <Grid item xs={12}>
                  <OngoingReportingReconciliation
                    statementType={statementType}
                    data={dataReconciliation}
                  />
                </Grid>,
                wrapper,
              )
            ) : (
              <Grid item xs={12}>
                <OngoingReportingReconciliation
                  statementType={statementType}
                  data={dataReconciliation}
                />
              </Grid>
            )}
          </Grid>
        )}
        {isConfirmModalShown && (
          <Modal
            open={isConfirmModalShown}
            onCancel={handleCancel}
            title="You are about to modify the following records. Are you sure?"
            footer={[
              <Button
                key="cancel"
                color="primary"
                variant="outlined"
                onClick={handleCancel}
                secondary
              >
                Discard
              </Button>,
              <Button key="submit" color="primary" variant="contained" onClick={handleConfirm}>
                Confirm
              </Button>,
            ]}
          >
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Category</TableCell>
                    <TableCell>Date</TableCell>
                    <TableCell>Old Value</TableCell>
                    <TableCell>New Value</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {reportingUpdatesList.map((item) => (
                    <TableRow key={`${item.field}-${item.recordDate}`}>
                      <TableCell>{CLIENT_FINANCIALS_FIELDS_MAPPING[item.field]}</TableCell>
                      <TableCell>{item.recordDate}</TableCell>
                      <TableCell>{formatter.format(item.oldValue)}</TableCell>
                      <TableCell>{formatter.format(item.newValue)}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Modal>
        )}
      </Card>

      {fileSheets?.length > 1 && !isModalMultiSelected && statementType && (
        <Box
          className={cn(styles.navigationBar, {
            [styles.navigationBarWithSidebar]: isSidebarOpen && !isModalMultiSelected,
            [styles.navigationBarWithNotes]: isNotesShown,
            [styles.navigationBarModal]: isMappingModalShown,
          })}
          display="flex"
          justifyContent="space-between"
          alignItems="center"
        >
          <span className={styles.navigationBarLabel}>
            {currentFileSheet.file?.fileName} - {currentFileSheet.sheetName}
          </span>
          <Box>
            <Pagination
              count={fileSheets.length}
              page={currentFileSheetIndex + 1}
              onChange={handleChangeCurrentFile}
              variant="outlined"
              shape="rounded"
              color="primary"
            />
          </Box>
        </Box>
      )}
    </Box>
  )
}

export default OngoingReportingPage
