import React, { Dispatch, SetStateAction, useCallback, useEffect, useMemo, useState } from 'react'
import { useParams } from 'react-router'
import { generatePath, matchPath, useHistory, Link as RouterLink } from 'react-router-dom'
import Grid from '@mui/material/Grid'
import Skeleton from '@mui/material/Skeleton'
import Box from '@mui/material/Box'
import Link from '@mui/material/Link'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import Tooltip from '@mui/material/Tooltip'
import sortBy from 'lodash/orderBy'
import { usePermissions } from '../../helpers/permissionContext'

import styles from './ProspectDashboardHeader.module.scss'

import { ReactComponent as DownChevron } from '@assets/images/down-chevron.svg'
import {
  IOPSReporting,
  OPSReportingStatus,
  ProspectRefreshType,
} from '@common/interfaces/prospects'
import Card from '../../components/Common/Card'
import { SalesforceLink, BoxLink } from '../Common/Icons'
import OPSTabs from '../../components/OPSTabs'
import { textToTitleCase } from '@common/helpers/helpers'
import Breadcrumbs from '../../components/Common/Breadcrumbs'
import { ReactComponent as HomeIcon } from '@assets/images/home-icon.svg'
import { ROUTES } from '../../constants/routes'
import { useLoadInfo } from '../../hooks/useLoadInfo'
import { ClientInfoStatus } from '@common/interfaces/client'
import SelectField from '../Common/SelectField'
import Button from '../../components/Common/Button'
import { formatDateTime } from '../../helpers/helpers'

interface IProps {
  reporting: IOPSReporting
  show: (id: string) => void
  pullReports: (id: string, params?: object) => Promise<void>
  pathname: string
  refreshCount: number
  setRefreshCounter?: Dispatch<SetStateAction<number>>
}

const ProspectDashboardHeader = ({
  reporting,
  show,
  pullReports,
  pathname,
  refreshCount,
  setRefreshCounter,
}: IProps) => {
  const { id } = useParams<{ id?: string }>()
  const history = useHistory()

  const { id: opsReportingId } = reporting || {}
  useLoadInfo({ id, info: reporting, show })

  useEffect(() => {
    setRefreshCounter && setRefreshCounter((counter) => counter + 1)
  }, [refreshCount, setRefreshCounter])

  const [anchorElRefresh, setAnchorElRefresh] = useState(null)
  const isRefreshMenuOpen = useMemo(() => Boolean(anchorElRefresh), [anchorElRefresh])

  const handleClickRefreshMenu = useCallback((event: React.MouseEvent<HTMLElement>) => {
    setAnchorElRefresh(event.currentTarget)
  }, [])

  const handleCloseRefreshMenu = useCallback(() => {
    setAnchorElRefresh(null)
  }, [])

  const handlePullCodatReports = useCallback(async () => {
    handleCloseRefreshMenu()
    if (reporting.refreshedAt) {
      return
    }
    await pullReports(opsReportingId, { type: ProspectRefreshType.Codat })
  }, [handleCloseRefreshMenu, opsReportingId, pullReports, reporting])

  const handlePullBoxReports = useCallback(async () => {
    handleCloseRefreshMenu()
    if (reporting.refreshedAt) {
      return
    }
    await pullReports(opsReportingId, { type: ProspectRefreshType.Box })
  }, [handleCloseRefreshMenu, opsReportingId, pullReports, reporting])

  const { isUW } = usePermissions()

  const readOnly = useMemo(
    () =>
      ![ClientInfoStatus.Prospect, ClientInfoStatus.Archived].includes(
        reporting?.clientInfo?.clientStatus,
      ) ||
      reporting?.status === OPSReportingStatus.Archived ||
      isUW,
    [reporting, isUW],
  )

  const title = useMemo(() => {
    if (matchPath(pathname, generatePath(ROUTES.PROSPECT_SUMMARY_PAGE, { id }))) {
      return 'View OPS'
    }
    return 'Process Documents'
  }, [pathname, id])

  const breadcrumbs = useMemo(
    () => [
      {
        link: ROUTES.PROSPECTS,
        Icon: HomeIcon,
      },
      {
        link: generatePath(ROUTES.PROSPECT_PAGE, { id }),
        title: reporting?.clientName,
      },
      {
        title,
        link: generatePath(ROUTES.PROSPECT_REPORTING_RECEIVABLES, { id }),
      },
    ],
    [reporting, id, title],
  )

  const opsReportingVersions = useMemo(
    () =>
      sortBy(reporting?.clientInfo?.opsReportings, 'createdAt', 'desc').map((opsReporting) => ({
        value: opsReporting.id,
        label: opsReporting.salesforceProspectInfo?.name || opsReporting.clientName,
      })),
    [reporting],
  )

  const goToVersion = useCallback(
    ({ target: { value } }) => {
      history.push(pathname.replace(id, value))
    },
    [pathname, id, history],
  )

  if (!reporting) {
    return (
      <Grid item xs={12}>
        <Breadcrumbs breadcrumbs={breadcrumbs} isLoading />
        <Card
          className={styles.card}
          withBorder={false}
          title={
            <Box display="flex" justifyContent="space-between" alignItems="center">
              <Skeleton width={600} height={80} />

              <Box
                className={styles.icons}
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                gap={1}
              >
                <Skeleton width={36} height={60} />
                <Skeleton width={36} height={60} />
                <Skeleton width={36} height={60} />
              </Box>
            </Box>
          }
        />
      </Grid>
    )
  }

  return (
    <Grid item xs={12}>
      <Breadcrumbs breadcrumbs={breadcrumbs} />
      <Card
        className={styles.card}
        withBorder={false}
        title={
          <Box display="flex" justifyContent="space-between" alignItems="center">
            <OPSTabs
              prospectInfo={reporting.clientInfo}
              opsReportingFlows={reporting.opsReportingFlows}
            />
            <Box
              className={styles.icons}
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              {opsReportingVersions.length > 1 && (
                <SelectField
                  label="Version"
                  variant="outlined"
                  className={styles.selectField}
                  useFinalForm={false}
                  name="compareDate"
                  options={opsReportingVersions}
                  onChange={goToVersion}
                  value={reporting?.id}
                  defaultValue=""
                  withTopLabel
                />
              )}
              <Link
                className={styles.linkButton}
                component={RouterLink}
                to={generatePath(ROUTES.PROSPECT_SUMMARY_PAGE, { id })}
              >
                View Summary
              </Link>
              {!readOnly && (reporting.clientInfo?.accountId || reporting.clientInfo?.leadId) && (
                <Tooltip
                  title={
                    reporting.refreshedAt
                      ? `Requested at ${formatDateTime(reporting.refreshedAt)}`
                      : ''
                  }
                  placement="top"
                >
                  <div>
                    <Button
                      variant="outlined"
                      onClick={handleClickRefreshMenu}
                      disabled={!!reporting.refreshedAt}
                    >
                      Refresh
                      <DownChevron className={styles.downChevron} />
                    </Button>
                    <Menu
                      open={isRefreshMenuOpen}
                      anchorEl={anchorElRefresh}
                      onClose={handleCloseRefreshMenu}
                    >
                      {reporting?.clientInfo?.isErpConnected && (
                        <MenuItem
                          classes={{ root: styles.actionsMenuItem }}
                          onClick={handlePullCodatReports}
                        >
                          Codat
                        </MenuItem>
                      )}
                      <MenuItem
                        classes={{ root: styles.actionsMenuItem }}
                        onClick={handlePullBoxReports}
                      >
                        Box
                      </MenuItem>
                    </Menu>
                  </div>
                </Tooltip>
              )}
              {reporting.salesforceProspectInfo?.salesforceLink && (
                <SalesforceLink
                  link={reporting.salesforceProspectInfo?.salesforceLink}
                  title={textToTitleCase(reporting.salesforceProspectInfo?.salesforceType || '')}
                />
              )}
              {reporting.salesforceProspectInfo?.opsBoxLink && (
                <BoxLink
                  link={reporting.salesforceProspectInfo?.opsBoxLink}
                  title="Prospect folder"
                />
              )}
            </Box>
          </Box>
        }
      ></Card>
    </Grid>
  )
}

export default ProspectDashboardHeader
