import React, { useState, useCallback, useMemo, useRef, useEffect } from 'react'
import { Form, FormRenderProps } from 'react-final-form'
import { FormApi } from 'final-form'
import { makeValidate } from 'mui-rff'
import * as Yup from 'yup'
import Box from '@mui/material/Box'
import InputAdornment from '@mui/material/InputAdornment'
import IconButton from '@mui/material/IconButton'
import cn from 'classnames'
import moment from 'moment'

import styles from './DueDiligenceTable.module.scss'

import { ReactComponent as Visibility } from '@assets/images/text-visibility-on.svg'
import { ReactComponent as VisibilityOff } from '@assets/images/text-visibility-off.svg'
import KeyboardDatePicker from '../Common/KeyboardDatePicker'
import {
  DATE_FORMAT,
  PHONE_VALIDATE_REGEXP,
  SOCIAL_SECURITY_VALIDATE_REGEXP,
} from '../../constants/common'
import InputLabel from '../Common/InputLabel'
import {
  DueDiligenceTableItemType,
  IDueDiligenceBankAccount,
  IDueDiligenceBoardMember,
  IDueDiligenceContact,
  IDueDiligenceExecutive,
  IDueDiligenceInventoryLocation,
  IDueDiligenceReference,
  IDueDiligenceTableItem,
} from '@common/interfaces/dueDiligence'
import TextField from '../Common/TextField'
import AutocompletePlace from '../Common/AutocompletePlace'
import SelectField from '../Common/SelectField'
import { SocialSecurityNumberMaskInput, PhoneMaskInput } from '../Common/MaskInput'
import BooleanRadios from '../Common/BooleanRadios'
import Modal from '../Common/Modal'
import Button from '../Common/Button'
import CurrencyField from '../Common/CurrencyField'
import CreatableSelectField, { IOptionType } from '../Common/CreatableSelectField'
import { IBank } from '@common/interfaces/bank'

const contactSchema = Yup.object().shape({
  firstName: Yup.string().nullable(true),
  lastName: Yup.string().nullable(true),
  email: Yup.string().nullable(true).email(),
  phone: Yup.string().nullable(true).matches(PHONE_VALIDATE_REGEXP, 'Not a valid phone number'),
  isResponsibleRequestingFunding: Yup.string().nullable(true),
  isAuthorizedSignature: Yup.string().nullable(true),
})
const validateContact = makeValidate(contactSchema)

const executiveSchema = Yup.object().shape({
  firstName: Yup.string().nullable(true),
  lastName: Yup.string().nullable(true),
  title: Yup.string().nullable(true),
  email: Yup.string().nullable(true).email(),
  socialSecurityNumber: Yup.string()
    .nullable(true)
    .matches(SOCIAL_SECURITY_VALIDATE_REGEXP, 'Not a valid social security number'),
  phone: Yup.string().nullable(true).matches(PHONE_VALIDATE_REGEXP, 'Not a valid phone number'),
  birthday: Yup.date()
    .typeError('Please type date in MM/DD/YY format')
    .nullable(true)
    .max(moment().toDate(), 'Date of birth cannot be in the future'),
  address: Yup.mixed().nullable(true),
  isConvictedFelony: Yup.string().nullable(true),
  isConvictedFelonyComment: Yup.string().nullable(true),
  isDefaultedFinancialObligation: Yup.string().nullable(true),
  isDefaultedFinancialObligationComment: Yup.string().nullable(true),
  isPersonalBankruptcy: Yup.string().nullable(true),
  isPersonalBankruptcyComment: Yup.string().nullable(true),
})
const validateExecutive = makeValidate(executiveSchema)

const boardMemberSchema = Yup.object().shape({
  firstName: Yup.string().nullable(true),
  lastName: Yup.string().nullable(true),
  email: Yup.string().nullable(true).email(),
  company: Yup.string().nullable(true),
  relationshipToCompany: Yup.mixed().nullable(true),
})
const validateBoardMember = makeValidate(boardMemberSchema)

const referenceSchema = Yup.object().shape({
  firstName: Yup.string().nullable(true),
  lastName: Yup.string().nullable(true),
  email: Yup.string().nullable(true).email(),
  phone: Yup.string().nullable(true).matches(PHONE_VALIDATE_REGEXP, 'Not a valid phone number'),
  businessName: Yup.string().nullable(true),
  relationshipToCompany: Yup.mixed().nullable(true),
})
const validateReference = makeValidate(referenceSchema)

const inventoryLocationSchema = Yup.object().shape({
  name: Yup.string().nullable(true),
  type: Yup.string().nullable(true),
  landlord: Yup.string().nullable(true),
  address: Yup.mixed().nullable(true),
  finishedGoodsAmount: Yup.number(),
  totalInventoryAmount: Yup.number(),
})
const validateInventoryLocation = makeValidate(inventoryLocationSchema)

const bankAccountSchema = Yup.object().shape({
  bankName: Yup.object().nullable().required('Bank name is required'),
  bankAccountNumber: Yup.string().required('Bank account # is required'),
  abaRoutingNumber: Yup.string().required('Wire Routing # is required'),
  purpose: Yup.string().nullable().max(500),
})
const validateBankAccount = makeValidate(bankAccountSchema)

const SCHEMA_BY_TYPE = {
  [DueDiligenceTableItemType.Contact]: contactSchema,
  [DueDiligenceTableItemType.Executive]: executiveSchema,
  [DueDiligenceTableItemType.BoardMember]: boardMemberSchema,
  [DueDiligenceTableItemType.Reference]: referenceSchema,
  [DueDiligenceTableItemType.InventoryLocation]: inventoryLocationSchema,
}

const getIncompleteFields = (
  type: DueDiligenceTableItemType,
  values: any,
  isNew: boolean = false,
): string[] => {
  if (isNew) {
    return []
  }
  if (!SCHEMA_BY_TYPE[type]) {
    return []
  }

  return Object.keys(SCHEMA_BY_TYPE[type]?.fields).filter((field) => !values[field])
}

const RELATIONSHIP_OPTIONS = [
  { value: 'Executive', label: 'Executive' },
  { value: 'Investor', label: 'Investor' },
  { value: 'Independent', label: 'Independent' },
]
const today = new Date()

const mutators = {
  setFieldData: ([field, value]: any, state: any, { changeValue }: any) => {
    changeValue(state, field, () => value)
  },
}

const PLACE_PREDICTIONS_OPTIONS = {
  types: ['street_address', 'premise'],
}

const DueDiligenceSaveContactFormRender = ({
  form,
  values,
  pristine,
  submitting,
  formRef,
  setIsFormInvalid,
  isNew,
}: FormRenderProps<any> & {
  formRef: React.MutableRefObject<FormApi<any, Partial<any>>>
  setIsFormInvalid: (state: boolean) => void
  isNew: boolean
}) => {
  formRef.current = form

  useEffect(() => {
    setIsFormInvalid(submitting || pristine)
  }, [pristine, submitting, setIsFormInvalid])

  const phoneMaskProps = useMemo(() => ({ inputComponent: PhoneMaskInput() }), [])

  const incompleteFields = useMemo(
    () => getIncompleteFields(DueDiligenceTableItemType.Contact, values, isNew),
    [values, isNew],
  )

  return (
    <form className={styles.formWrapper}>
      <div className={styles.formContent}>
        <Box>
          <InputLabel className={styles.inputLabel}>First name</InputLabel>
          <TextField
            name="firstName"
            placeholder="E.g., John"
            size="large"
            className={cn(styles.inputField, {
              [styles.incomplete]: incompleteFields.includes('firstName'),
            })}
          />
        </Box>

        <Box>
          <InputLabel className={styles.inputLabel}>Last name</InputLabel>
          <TextField
            name="lastName"
            placeholder="E.g., Smith"
            size="large"
            className={cn(styles.inputField, {
              [styles.incomplete]: incompleteFields.includes('lastName'),
            })}
          />
        </Box>

        <Box>
          <InputLabel className={styles.inputLabel}>Phone number</InputLabel>
          <TextField
            name="phone"
            InputProps={phoneMaskProps}
            placeholder="E.g., (123) 456-7890"
            size="large"
            className={cn(styles.inputField, {
              [styles.incomplete]: incompleteFields.includes('phone'),
            })}
          />
        </Box>

        <Box>
          <InputLabel className={styles.inputLabel}>Email</InputLabel>
          <TextField
            name="email"
            type="email"
            placeholder="E.g., johnsmith@email.com"
            size="large"
            className={cn(styles.inputField, {
              [styles.incomplete]: incompleteFields.includes('email'),
            })}
          />
        </Box>

        <Box className={styles.fullWidthRow}>
          <InputLabel
            className={cn(styles.inputLabel, {
              [styles.incomplete]: incompleteFields.includes('isResponsibleRequestingFunding'),
            })}
          >
            Is this person responsible for requesting funding?
          </InputLabel>

          <Box display="flex" justifyContent="flex-start">
            <BooleanRadios name="isResponsibleRequestingFunding" />
          </Box>
        </Box>

        <Box className={styles.fullWidthRow}>
          <InputLabel
            className={cn(styles.inputLabel, {
              [styles.incomplete]: incompleteFields.includes('isAuthorizedSignature'),
            })}
          >
            Is this person authorized to sign for funding?
          </InputLabel>

          <Box display="flex" justifyContent="flex-start">
            <BooleanRadios name="isAuthorizedSignature" />
          </Box>
        </Box>
      </div>
    </form>
  )
}

const DueDiligenceSaveExecutiveFormRender = ({
  form,
  values,
  pristine,
  submitting,
  formRef,
  setIsFormInvalid,
  modified,
  isNew,
}: FormRenderProps<any> & {
  formRef: React.MutableRefObject<FormApi<any, Partial<any>>>
  setIsFormInvalid: (state: boolean) => void
  isNew: boolean
}) => {
  formRef.current = form

  const isAddressInvalid = useMemo(() => {
    if (!values) {
      return false
    }
    if (!values.address || !values.homeAddress) {
      return false
    }

    return (
      !values.homeAddress?.state ||
      !values.homeAddress?.city ||
      !values.homeAddress?.street ||
      !values.homeAddress?.postalCode
    )
  }, [values])
  const addressError = useMemo(
    () =>
      (modified['address'] || !isNew) && isAddressInvalid
        ? 'Please include all address fields: Street, City, State, and Zip'
        : '',
    [modified, isNew, isAddressInvalid],
  )

  useEffect(() => {
    setIsFormInvalid(submitting || pristine)
  }, [pristine, submitting, setIsFormInvalid])

  const [socialSecurityNumberVisibility, setSocialSecurityNumberVisibility] = useState(false)

  const handleSelect = useCallback(
    (address: any) => form.mutators.setFieldData('homeAddress', address),
    [form],
  )
  const toggleSocialSecurityNumberVisibility = useCallback(() => {
    setSocialSecurityNumberVisibility((prev) => !prev)
  }, [])

  const phoneMaskProps = useMemo(() => ({ inputComponent: PhoneMaskInput() }), [])
  const socialSecurityNumberMaskProps = useMemo(
    () => ({
      autoComplete: 'one-time-code',
      endAdornment: (
        <InputAdornment position="end">
          <IconButton
            aria-label="toggle visibility"
            className={styles.iconButtonVisibility}
            disableRipple
            onClick={toggleSocialSecurityNumberVisibility}
          >
            {socialSecurityNumberVisibility ? <Visibility /> : <VisibilityOff />}
          </IconButton>
        </InputAdornment>
      ),
      ...(socialSecurityNumberVisibility
        ? { inputComponent: SocialSecurityNumberMaskInput() }
        : {}),
    }),
    [socialSecurityNumberVisibility, toggleSocialSecurityNumberVisibility],
  )

  const incompleteFields = useMemo(
    () => getIncompleteFields(DueDiligenceTableItemType.Executive, values, isNew),
    [values, isNew],
  )

  return (
    <form className={styles.formWrapper}>
      <div className={cn(styles.formContent, styles.formContentTwoColumn)}>
        <Box>
          <InputLabel className={styles.inputLabel}>First name</InputLabel>
          <TextField
            name="firstName"
            placeholder="E.g., John"
            size="large"
            className={cn(styles.inputField, {
              [styles.incomplete]: incompleteFields.includes('firstName'),
            })}
          />
        </Box>

        <Box>
          <InputLabel className={styles.inputLabel}>Last name</InputLabel>
          <TextField
            name="lastName"
            placeholder="E.g., Smith"
            size="large"
            className={cn(styles.inputField, {
              [styles.incomplete]: incompleteFields.includes('lastName'),
            })}
          />
        </Box>

        <Box>
          <InputLabel className={styles.inputLabel}>Title</InputLabel>
          <TextField
            name="title"
            placeholder="E.g., CEO"
            size="large"
            className={cn(styles.inputField, {
              [styles.incomplete]: incompleteFields.includes('title'),
            })}
          />
        </Box>

        <Box>
          <InputLabel className={styles.inputLabel}>Email</InputLabel>
          <TextField
            name="email"
            type="email"
            placeholder="E.g., johnsmith@email.com"
            size="large"
            className={cn(styles.inputField, {
              [styles.incomplete]: incompleteFields.includes('email'),
            })}
          />
        </Box>

        <Box>
          <InputLabel className={styles.inputLabel}>Phone number</InputLabel>
          <TextField
            name="phone"
            InputProps={phoneMaskProps}
            placeholder="E.g., (123) 456-7890"
            size="large"
            className={cn(styles.inputField, {
              [styles.incomplete]: incompleteFields.includes('phone'),
            })}
          />
        </Box>

        <Box>
          <InputLabel className={styles.inputLabel}>Social security number</InputLabel>
          <TextField
            name="socialSecurityNumber"
            type={
              socialSecurityNumberVisibility || !values?.socialSecurityNumber ? 'text' : 'password'
            }
            InputProps={socialSecurityNumberMaskProps}
            placeholder="E.g., 990-34-2833"
            size="large"
            className={cn(styles.inputField, {
              [styles.incomplete]: incompleteFields.includes('socialSecurityNumber'),
            })}
          />
        </Box>

        <Box>
          <InputLabel className={styles.inputLabel}>Date of birth</InputLabel>
          <KeyboardDatePicker
            className={cn(styles.datePickerField, {
              [styles.incomplete]: incompleteFields.includes('birthday'),
            })}
            name="birthday"
            inputFormat={DATE_FORMAT}
            maxDate={today}
            placeholder="E.g., 10/10/2023"
          />
        </Box>

        <Box>
          <InputLabel className={styles.inputLabel}>
            Home address (Street, City, State, Zip)
          </InputLabel>
          <AutocompletePlace
            label=""
            name="address"
            placeholder="E.g., New York"
            helperText={addressError}
            options={[]}
            className={cn(styles.autocompleteField, {
              [styles.incomplete]: incompleteFields.includes('address') || isAddressInvalid,
            })}
            onSelect={handleSelect}
            placePredictionsOptions={PLACE_PREDICTIONS_OPTIONS}
          />
        </Box>

        <Box className={styles.fullWidthRow}>
          <InputLabel
            className={cn(styles.inputLabel, {
              [styles.incomplete]: incompleteFields.includes('isConvictedFelony'),
            })}
          >
            Has this person ever been convicted of any felony?
          </InputLabel>

          <Box display="flex" justifyContent="flex-start">
            <BooleanRadios name="isConvictedFelony" />
          </Box>
        </Box>

        {values?.isConvictedFelony === 'true' && (
          <Box className={styles.fullWidthRow}>
            <TextField
              name="isConvictedFelonyComment"
              size="large"
              className={cn(styles.inputField, {
                [styles.incomplete]: incompleteFields.includes('isConvictedFelonyComment'),
              })}
              rows={4}
              multiline
            />
          </Box>
        )}

        <Box className={styles.fullWidthRow}>
          <InputLabel
            className={cn(styles.inputLabel, {
              [styles.incomplete]: incompleteFields.includes('isDefaultedFinancialObligation'),
            })}
          >
            Has this person ever defaulted on any financial obligation?
          </InputLabel>

          <Box display="flex" justifyContent="flex-start">
            <BooleanRadios name="isDefaultedFinancialObligation" />
          </Box>
        </Box>

        {values?.isDefaultedFinancialObligation === 'true' && (
          <Box className={styles.fullWidthRow}>
            <TextField
              name="isDefaultedFinancialObligationComment"
              size="large"
              className={cn(styles.inputField, {
                [styles.incomplete]: incompleteFields.includes(
                  'isDefaultedFinancialObligationComment',
                ),
              })}
              rows={4}
              multiline
            />
          </Box>
        )}

        <Box className={styles.fullWidthRow}>
          <InputLabel
            className={cn(styles.inputLabel, {
              [styles.incomplete]: incompleteFields.includes('isPersonalBankruptcy'),
            })}
          >
            Has this person ever filed for personal bankruptcy?
          </InputLabel>

          <Box display="flex" justifyContent="flex-start">
            <BooleanRadios name="isPersonalBankruptcy" />
          </Box>
        </Box>

        {values?.isPersonalBankruptcy === 'true' && (
          <Box className={styles.fullWidthRow}>
            <TextField
              name="isPersonalBankruptcyComment"
              size="large"
              className={cn(styles.inputField, {
                [styles.incomplete]: incompleteFields.includes('isPersonalBankruptcyComment'),
              })}
              rows={4}
              multiline
            />
          </Box>
        )}
      </div>
    </form>
  )
}

const DueDiligenceSaveBoardMemberFormRender = ({
  form,
  values,
  pristine,
  submitting,
  formRef,
  setIsFormInvalid,
  isNew,
}: FormRenderProps<any> & {
  formRef: React.MutableRefObject<FormApi<any, Partial<any>>>
  setIsFormInvalid: (state: boolean) => void
  isNew: boolean
}) => {
  formRef.current = form

  useEffect(() => {
    setIsFormInvalid(submitting || pristine)
  }, [pristine, submitting, setIsFormInvalid])

  const incompleteFields = useMemo(
    () => getIncompleteFields(DueDiligenceTableItemType.BoardMember, values, isNew),
    [values, isNew],
  )

  return (
    <form className={styles.formWrapper}>
      <div className={styles.formContent}>
        <Box>
          <InputLabel className={styles.inputLabel}>First name</InputLabel>
          <TextField
            name="firstName"
            placeholder="E.g., John"
            size="large"
            className={cn(styles.inputField, {
              [styles.incomplete]: incompleteFields.includes('firstName'),
            })}
          />
        </Box>

        <Box>
          <InputLabel className={styles.inputLabel}>Last name</InputLabel>
          <TextField
            name="lastName"
            placeholder="E.g., Smith"
            size="large"
            className={cn(styles.inputField, {
              [styles.incomplete]: incompleteFields.includes('lastName'),
            })}
          />
        </Box>

        <Box>
          <InputLabel className={styles.inputLabel}>Email</InputLabel>
          <TextField
            name="email"
            type="email"
            placeholder="E.g., johnsmith@email.com"
            size="large"
            className={cn(styles.inputField, {
              [styles.incomplete]: incompleteFields.includes('email'),
            })}
          />
        </Box>

        <Box>
          <InputLabel className={styles.inputLabel}>Company</InputLabel>
          <TextField
            name="company"
            placeholder="E.g., John’s Apples"
            size="large"
            className={cn(styles.inputField, {
              [styles.incomplete]: incompleteFields.includes('company'),
            })}
          />
        </Box>

        <Box>
          <InputLabel className={styles.inputLabel}>Relationship to company</InputLabel>
          <SelectField
            name="relationshipToCompany"
            selectSize="large"
            border
            options={RELATIONSHIP_OPTIONS}
            placeholder="Select option"
            className={cn(styles.selectField, {
              [styles.incomplete]: incompleteFields.includes('relationshipToCompany'),
            })}
          />
        </Box>
      </div>
    </form>
  )
}

const DueDiligenceSaveReferenceFormRender = ({
  form,
  values,
  pristine,
  submitting,
  formRef,
  setIsFormInvalid,
  isNew,
}: FormRenderProps<any> & {
  formRef: React.MutableRefObject<FormApi<any, Partial<any>>>
  setIsFormInvalid: (state: boolean) => void
  isNew: boolean
}) => {
  formRef.current = form

  useEffect(() => {
    setIsFormInvalid(submitting || pristine)
  }, [pristine, submitting, setIsFormInvalid])

  const phoneMaskProps = useMemo(() => ({ inputComponent: PhoneMaskInput() }), [])

  const incompleteFields = useMemo(
    () => getIncompleteFields(DueDiligenceTableItemType.Reference, values, isNew),
    [values, isNew],
  )

  return (
    <form className={styles.formWrapper}>
      <div className={styles.formContent}>
        <Box>
          <InputLabel className={styles.inputLabel}>First name</InputLabel>
          <TextField
            name="firstName"
            placeholder="E.g., John"
            size="large"
            className={cn(styles.inputField, {
              [styles.incomplete]: incompleteFields.includes('firstName'),
            })}
          />
        </Box>

        <Box>
          <InputLabel className={styles.inputLabel}>Last name</InputLabel>
          <TextField
            name="lastName"
            placeholder="E.g., Smith"
            size="large"
            className={cn(styles.inputField, {
              [styles.incomplete]: incompleteFields.includes('lastName'),
            })}
          />
        </Box>

        <Box>
          <InputLabel className={styles.inputLabel}>Phone number</InputLabel>
          <TextField
            name="phone"
            InputProps={phoneMaskProps}
            placeholder="E.g., (123) 456-7890"
            size="large"
            className={cn(styles.inputField, {
              [styles.incomplete]: incompleteFields.includes('phone'),
            })}
          />
        </Box>

        <Box>
          <InputLabel className={styles.inputLabel}>Email</InputLabel>
          <TextField
            name="email"
            type="email"
            placeholder="E.g., johnsmith@email.com"
            size="large"
            className={cn(styles.inputField, {
              [styles.incomplete]: incompleteFields.includes('email'),
            })}
          />
        </Box>

        <Box>
          <InputLabel className={styles.inputLabel}>Business name</InputLabel>
          <TextField
            name="businessName"
            placeholder="E.g., John’s Apples"
            size="large"
            className={cn(styles.inputField, {
              [styles.incomplete]: incompleteFields.includes('businessName'),
            })}
          />
        </Box>

        <Box>
          <InputLabel className={styles.inputLabel}>Relationship to company</InputLabel>
          <SelectField
            name="relationshipToCompany"
            selectSize="large"
            border
            options={RELATIONSHIP_OPTIONS}
            placeholder="Select option"
            className={cn(styles.selectField, {
              [styles.incomplete]: incompleteFields.includes('relationshipToCompany'),
            })}
          />
        </Box>
      </div>
    </form>
  )
}

const DueDiligenceSaveInventoryLocationFormRender = ({
  form,
  values,
  pristine,
  submitting,
  formRef,
  setIsFormInvalid,
  isNew,
}: FormRenderProps<any> & {
  formRef: React.MutableRefObject<FormApi<any, Partial<any>>>
  setIsFormInvalid: (state: boolean) => void
  isNew: boolean
}) => {
  formRef.current = form

  useEffect(() => {
    setIsFormInvalid(submitting || pristine)
  }, [pristine, submitting, setIsFormInvalid])

  const handleSelect = useCallback(
    (address: any) => form.mutators.setFieldData('businessAddress', address),
    [form],
  )

  const incompleteFields = useMemo(
    () => getIncompleteFields(DueDiligenceTableItemType.InventoryLocation, values, isNew),
    [values, isNew],
  )

  return (
    <form className={styles.formWrapper}>
      <div className={cn(styles.formContent, styles.formContentTwoColumn)}>
        <Box>
          <InputLabel className={styles.inputLabel}>Name</InputLabel>
          <TextField
            name="name"
            placeholder="Enter inventory name"
            size="large"
            className={cn(styles.inputField, {
              [styles.incomplete]: incompleteFields.includes('name'),
            })}
          />
        </Box>

        <Box>
          <InputLabel className={styles.inputLabel}>Location type</InputLabel>
          <TextField
            name="type"
            placeholder="Enter location type"
            size="large"
            className={cn(styles.inputField, {
              [styles.incomplete]: incompleteFields.includes('type'),
            })}
          />
        </Box>

        <Box>
          <InputLabel className={styles.inputLabel}>Landlord</InputLabel>
          <TextField
            name="landlord"
            placeholder="Tell us your inventory location landloard"
            size="large"
            className={cn(styles.inputField, {
              [styles.incomplete]: incompleteFields.includes('landlord'),
            })}
          />
        </Box>

        <Box>
          <InputLabel className={styles.inputLabel}>Inventory location address</InputLabel>
          <AutocompletePlace
            label=""
            name="address"
            placeholder="Select address"
            options={[]}
            onSelect={handleSelect}
            className={cn(styles.autocompleteField, {
              [styles.incomplete]: incompleteFields.includes('address'),
            })}
          />
        </Box>

        <Box>
          <InputLabel className={styles.inputLabel}>Finished goods value</InputLabel>
          <CurrencyField
            name="finishedGoodsAmount"
            placeholder="Enter finished goods value"
            size="large"
            className={cn(styles.inputField, {
              [styles.incomplete]: incompleteFields.includes('finishedGoodsAmount'),
            })}
          />
        </Box>

        <Box>
          <InputLabel className={styles.inputLabel}>Total inventory value</InputLabel>
          <CurrencyField
            name="totalInventoryAmount"
            placeholder="Enter inventory value"
            size="large"
            className={cn(styles.inputField, {
              [styles.incomplete]: incompleteFields.includes('totalInventoryAmount'),
            })}
          />
        </Box>
      </div>
    </form>
  )
}

const DueDiligenceSaveBankAccountFormRender = ({
  form,
  invalid,
  pristine,
  submitting,
  formRef,
  setIsFormInvalid,
  banksOptions,
  handleAddBank,
}: FormRenderProps<any> & {
  formRef: React.MutableRefObject<FormApi<any, Partial<any>>>
  setIsFormInvalid: (state: boolean) => void
  banksOptions: IOptionType[]
  handleAddBank: (name: string) => void
}) => {
  formRef.current = form

  useEffect(() => {
    setIsFormInvalid(submitting || pristine || invalid)
  }, [invalid, pristine, submitting, setIsFormInvalid])

  return (
    <form className={styles.formWrapper}>
      <div className={cn(styles.formContent, styles.formContentTwoColumn)}>
        <Box>
          <InputLabel className={styles.inputLabel}>Bank name</InputLabel>
          <CreatableSelectField
            name="bankName"
            className={styles.creatableSelectField}
            onAddValue={handleAddBank}
            height="large"
            placeholder="Enter bank account name"
            options={banksOptions}
          />
        </Box>

        <Box>
          <InputLabel className={styles.inputLabel}>Bank account #</InputLabel>
          <TextField
            name="bankAccountNumber"
            placeholder="Enter bank account #"
            size="large"
            className={styles.inputField}
          />
        </Box>

        <Box>
          <InputLabel className={styles.inputLabel}>Wire Routing #</InputLabel>
          <TextField
            name="abaRoutingNumber"
            placeholder="Enter Wire Routing #"
            size="large"
            className={styles.inputField}
          />
        </Box>

        <Box className={styles.fullWidthRow}>
          <InputLabel className={styles.inputLabel}>Purpose</InputLabel>
          <TextField
            name="purpose"
            placeholder="E.g. Funding, Operating"
            size="large"
            className={styles.inputField}
            rows={4}
            multiline
          />
        </Box>
      </div>
    </form>
  )
}

const LABEL = {
  [DueDiligenceTableItemType.Contact]: 'contact',
  [DueDiligenceTableItemType.Executive]: 'executive',
  [DueDiligenceTableItemType.BoardMember]: 'board member',
  [DueDiligenceTableItemType.Reference]: 'reference',
  [DueDiligenceTableItemType.InventoryLocation]: 'inventory location',
  [DueDiligenceTableItemType.BankAccount]: 'bank account',
}

interface IProps {
  type: DueDiligenceTableItemType
  item?: IDueDiligenceTableItem
  banks?: IBank[]
  handleAddBank?: (name: string) => void
  handleSaveItem: (data: Partial<IDueDiligenceTableItem>) => Promise<any>
  onCloseModal: () => void
}

const DueDiligenceSaveItemModal = ({
  type,
  item,
  banks,
  handleAddBank,
  handleSaveItem,
  onCloseModal,
}: IProps) => {
  const formRef: React.MutableRefObject<FormApi<any, Partial<any>>> = useRef(null)
  const isSavingAnother = useRef(false)
  const [isSaving, setIsSaving] = useState(false)
  const [isFormInvalid, setIsFormInvalid] = useState(false)

  const isNew = useMemo(() => !item, [item])

  const onSave = useCallback(() => {
    isSavingAnother.current = false
    formRef.current?.submit()
  }, [])

  const onSaveAnother = useCallback(() => {
    isSavingAnother.current = true
    formRef.current?.submit()
  }, [])

  const handleSave = useCallback(
    async (data) => {
      const shouldCloseModal = !isSavingAnother.current
      setIsSaving(true)
      const result = await handleSaveItem(data)
      setIsSaving(false)
      isSavingAnother.current = false
      if (!result.error) {
        formRef.current?.restart()
        if (shouldCloseModal) {
          onCloseModal()
        }
      }
    },
    [handleSaveItem, onCloseModal],
  )

  const initialValues = useMemo(() => {
    if (!item) {
      return undefined
    }

    if (type === DueDiligenceTableItemType.Contact) {
      const contact = item as IDueDiligenceContact

      return {
        firstName: contact.firstName || '',
        lastName: contact.lastName || '',
        email: contact.email || '',
        phone: contact.phone || null,
        isResponsibleRequestingFunding: (typeof contact.isResponsibleRequestingFunding === 'boolean'
          ? contact.isResponsibleRequestingFunding
          : ''
        ).toString(),
        isAuthorizedSignature: (typeof contact.isAuthorizedSignature === 'boolean'
          ? contact.isAuthorizedSignature
          : ''
        ).toString(),
      }
    }

    if (type === DueDiligenceTableItemType.Executive) {
      const executive = item as IDueDiligenceExecutive

      return {
        firstName: executive.firstName || '',
        lastName: executive.lastName || '',
        title: executive.title || '',
        email: executive.email || '',
        phone: executive.phone || null,
        socialSecurityNumber: executive.socialSecurityNumber || null,
        birthday: executive.birthday || null,
        address: executive.country
          ? {
              label: `${executive.street}, ${executive.city}, ${executive.state} ${executive.postalCode}, ${executive.country}`,
            }
          : null,
        homeAddress: {
          country: executive.country,
          state: executive.state,
          city: executive.city,
          street: executive.street,
          postalCode: executive.postalCode,
        },
        isConvictedFelony: (typeof executive.isConvictedFelony === 'boolean'
          ? executive.isConvictedFelony
          : ''
        ).toString(),
        isConvictedFelonyComment: executive.isConvictedFelonyComment || null,
        isDefaultedFinancialObligation: (typeof executive.isDefaultedFinancialObligation ===
        'boolean'
          ? executive.isDefaultedFinancialObligation
          : ''
        ).toString(),
        isDefaultedFinancialObligationComment:
          executive.isDefaultedFinancialObligationComment || null,
        isPersonalBankruptcy: (typeof executive.isPersonalBankruptcy === 'boolean'
          ? executive.isPersonalBankruptcy
          : ''
        ).toString(),
        isPersonalBankruptcyComment: executive.isPersonalBankruptcyComment || null,
      }
    }

    if (type === DueDiligenceTableItemType.BoardMember) {
      const boardMember = item as IDueDiligenceBoardMember

      return {
        email: boardMember.email || '',
        firstName: boardMember.firstName || '',
        lastName: boardMember.lastName || '',
        company: boardMember.company || '',
        relationshipToCompany: boardMember.relationshipToCompany || '',
      }
    }

    if (type === DueDiligenceTableItemType.Reference) {
      const reference = item as IDueDiligenceReference

      return {
        firstName: reference.firstName || '',
        lastName: reference.lastName || '',
        email: reference.email || '',
        phone: reference.phone || null,
        businessName: reference.businessName || null,
        relationshipToCompany: reference.relationshipToCompany || '',
      }
    }

    if (type === DueDiligenceTableItemType.InventoryLocation) {
      const inventoryLocation = item as IDueDiligenceInventoryLocation

      return {
        name: inventoryLocation.name || '',
        type: inventoryLocation.type || '',
        landlord: inventoryLocation.landlord || '',
        address: inventoryLocation.country
          ? {
              label: `${inventoryLocation.street}, ${inventoryLocation.city}, ${inventoryLocation.state} ${inventoryLocation.postalCode}, ${inventoryLocation.country}`,
            }
          : null,
        businessAddress: {
          country: inventoryLocation.country,
          state: inventoryLocation.state,
          city: inventoryLocation.city,
          street: inventoryLocation.street,
          postalCode: inventoryLocation.postalCode,
        },
        finishedGoodsAmount: inventoryLocation.finishedGoodsAmount || 0,
        totalInventoryAmount: inventoryLocation.totalInventoryAmount || 0,
      }
    }

    if (type === DueDiligenceTableItemType.BankAccount) {
      const bankAccount = item as IDueDiligenceBankAccount

      return {
        bankName: {
          value: bankAccount.bankName || '',
          label: bankAccount.bankName || '',
        },
        bankAccountNumber: bankAccount.bankAccountNumber || '',
        abaRoutingNumber: bankAccount.abaRoutingNumber || '',
        purpose: bankAccount.purpose || '',
      }
    }

    return null
  }, [item, type])

  const title = useMemo(() => `${isNew ? 'Add' : 'Edit'} ${LABEL[type]}`, [type, isNew])
  const button = useMemo(() => `Save ${LABEL[type]}`, [type])
  const banksOptions = useMemo(
    () => (banks || []).map(({ name }) => ({ value: name, label: name })),
    [banks],
  )

  return (
    <Modal
      title={title}
      open
      disableEnforceFocus
      onCancel={onCloseModal}
      classes={{ body: cn(styles.modalBody, { [styles.addNewModalBody]: isNew }) }}
    >
      {type === DueDiligenceTableItemType.Contact && (
        <Form
          initialValues={initialValues}
          onSubmit={handleSave}
          validate={validateContact}
          render={(props) => (
            <DueDiligenceSaveContactFormRender
              formRef={formRef}
              setIsFormInvalid={setIsFormInvalid}
              isNew={isNew}
              {...props}
            />
          )}
        />
      )}

      {type === DueDiligenceTableItemType.Executive && (
        <Form
          initialValues={initialValues}
          onSubmit={handleSave}
          validate={validateExecutive}
          mutators={mutators}
          render={(props) => (
            <DueDiligenceSaveExecutiveFormRender
              formRef={formRef}
              setIsFormInvalid={setIsFormInvalid}
              isNew={isNew}
              {...props}
            />
          )}
        />
      )}

      {type === DueDiligenceTableItemType.BoardMember && (
        <Form
          initialValues={initialValues}
          onSubmit={handleSave}
          validate={validateBoardMember}
          render={(props) => (
            <DueDiligenceSaveBoardMemberFormRender
              formRef={formRef}
              setIsFormInvalid={setIsFormInvalid}
              isNew={isNew}
              {...props}
            />
          )}
        />
      )}

      {type === DueDiligenceTableItemType.Reference && (
        <Form
          initialValues={initialValues}
          onSubmit={handleSave}
          validate={validateReference}
          render={(props) => (
            <DueDiligenceSaveReferenceFormRender
              formRef={formRef}
              setIsFormInvalid={setIsFormInvalid}
              isNew={isNew}
              {...props}
            />
          )}
        />
      )}

      {type === DueDiligenceTableItemType.InventoryLocation && (
        <Form
          initialValues={initialValues}
          onSubmit={handleSave}
          validate={validateInventoryLocation}
          mutators={mutators}
          render={(props) => (
            <DueDiligenceSaveInventoryLocationFormRender
              formRef={formRef}
              setIsFormInvalid={setIsFormInvalid}
              isNew={isNew}
              {...props}
            />
          )}
        />
      )}

      {type === DueDiligenceTableItemType.BankAccount && (
        <Form
          initialValues={initialValues}
          onSubmit={handleSave}
          validate={validateBankAccount}
          render={(props) => (
            <DueDiligenceSaveBankAccountFormRender
              formRef={formRef}
              setIsFormInvalid={setIsFormInvalid}
              banksOptions={banksOptions}
              handleAddBank={handleAddBank}
              {...props}
            />
          )}
        />
      )}

      <div className={styles.modalButtonsWrapper}>
        {isNew && (
          <Button
            small={false}
            variant="outlined"
            disabled={isFormInvalid || isSaving}
            isLoading={isSaving && isSavingAnother.current}
            onClick={onSaveAnother}
          >
            Save & add another
          </Button>
        )}

        <Button
          small={false}
          variant="contained"
          disabled={isFormInvalid || isSaving}
          isLoading={isSaving && !isSavingAnother.current}
          onClick={onSave}
        >
          {button}
        </Button>
      </div>
    </Modal>
  )
}

export default DueDiligenceSaveItemModal
