import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'

import DueDiligenceHeaderMenu from './DueDiligenceHeaderMenu'

import { dueDiligenceInfo, currentProcessDocumentsLink } from '../../redux/dueDiligence/selectors'
import { show, update, runBBC } from '../../redux/dueDiligence/actions'
import { isAdminRightsRole } from '../../redux/profile/selectors'
import { loginAsClient } from '../../redux/profile/actions'

const selector = createStructuredSelector({
  dueDiligenceInfo,
  isAdminRightsRole,
  currentProcessDocumentsLink,
})
const actions = {
  show,
  update,
  runBBC,
  loginAsClient,
}

export default connect(selector, actions)(DueDiligenceHeaderMenu)
