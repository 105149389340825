import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'

import InventoryHealthVisualization from './InventoryHealthVisualization'

import { listInventoryHealth } from '../../../redux/clientInfo/actions'
import { inventoryHealth } from '../../../redux/clientInfo/selectors'

const selector = createStructuredSelector({
  inventoryHealth,
})

const actions = {
  listInventoryHealth,
}

export default connect(selector, actions)(InventoryHealthVisualization)
