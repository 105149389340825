import React, { useState, useCallback, useMemo, useEffect, useRef } from 'react'
import { FormApi } from 'final-form'

import {
  DueDiligenceDocumentRequestStep,
  IDueDiligence,
  IDueDiligenceCompanyInfo,
  IDueDiligenceDocumentRequest,
} from '@common/interfaces/dueDiligence'
import { ILoadingData } from '../../redux/types'
import DueDiligenceFullscreenModal from '../DueDiligenceFullscreenModal'
import DueDiligenceTeamContacts from '../DueDiligenceTeamContacts'
import DueDiligenceTeamAdvisors from '../DueDiligenceTeamAdvisors'
import DueDiligenceTeamExecutives from '../DueDiligenceTeamExecutives'
import DueDiligenceTeamBoardMembers from '../DueDiligenceTeamBoardMembers'
import DueDiligenceTeamReferences from '../DueDiligenceTeamReferences'
import DueDiligenceClientStepFiles from '../DueDiligenceClientStepFiles'
import { ClientEntityRelationshipType } from '@common/interfaces/integration'
import {
  isEntityRelationshipCompleted,
  isEntityRelationshipInProgress,
} from '@common/constants/prospects'

const CONTACTS_STEP = 0
const ADVISORS_STEP = 1
const EXECUTIVES_STEP = 2
const BOARD_MEMBERS_STEP = 3
const REFERENCES_STEP = 4
const DOCUMENTATION_STEP = 5

interface IProps {
  dueDiligenceInfo: IDueDiligence
  dueDiligenceCompanyInfo: IDueDiligenceCompanyInfo
  dueDiligenceDocumentRequests: ILoadingData<{ data: IDueDiligenceDocumentRequest[] }>
  updateTeamAdvisorsInfo: (data: object) => Promise<void>
  onCloseModal: () => void
  startStep?: string
}

const DueDiligenceTeamModal = ({
  dueDiligenceInfo,
  dueDiligenceCompanyInfo,
  dueDiligenceDocumentRequests,
  updateTeamAdvisorsInfo,
  onCloseModal,
  startStep,
}: IProps) => {
  const filesNavigationRef: React.MutableRefObject<any> = useRef(null)
  const advisorsFormRef: React.MutableRefObject<FormApi<any, Partial<any>>> = useRef(null)
  const [step, setStep] = useState(startStep ? parseInt(startStep) : CONTACTS_STEP)
  const [progress, setProgress] = useState(dueDiligenceCompanyInfo.teamProgress)
  const [isLoading, setIsLoading] = useState(false)
  const [isAdvisorsFormInvalid, setIsAdvisorsFormInvalid] = useState(false)

  useEffect(() => {
    setProgress(dueDiligenceCompanyInfo.teamProgress)
  }, [dueDiligenceCompanyInfo])

  const handleCloseModal = useCallback(async () => {
    if (step === ADVISORS_STEP) {
      await advisorsFormRef.current.submit()
    }

    onCloseModal()
  }, [onCloseModal, step])

  const onBack = useCallback(async () => {
    if (step === ADVISORS_STEP) {
      await advisorsFormRef.current.submit()
    }
    if (step === DOCUMENTATION_STEP) {
      filesNavigationRef.current(() => {
        setStep(REFERENCES_STEP)
      }, 'back')
      return
    }

    setStep((step) => step - 1)
  }, [step])

  const onNext = useCallback(async () => {
    if (step === DOCUMENTATION_STEP) {
      filesNavigationRef.current(handleCloseModal)
      return
    }

    if (step === ADVISORS_STEP) {
      await advisorsFormRef.current.submit()
    }

    setStep((step) => step + 1)
  }, [step, handleCloseModal])

  const handleSetStep = useCallback(
    async (nextStep) => {
      if (nextStep === step) {
        return
      }

      if (step === ADVISORS_STEP) {
        await advisorsFormRef.current.submit()
      }

      setStep(nextStep)
    },
    [step],
  )

  const steps = useMemo(() => {
    const documentRequests =
      dueDiligenceDocumentRequests?.data?.data?.filter(
        ({ type }) => type.step === DueDiligenceDocumentRequestStep.Team,
      ) || []

    const lawFirm = dueDiligenceCompanyInfo.clientEntityRelationship.find(
      ({ type }) => type === ClientEntityRelationshipType.LawFirm,
    )
    const outsourcedCFO = dueDiligenceCompanyInfo.clientEntityRelationship.find(
      ({ type }) => type === ClientEntityRelationshipType.OutsourcedCFO,
    )
    const accountingFirm = dueDiligenceCompanyInfo.clientEntityRelationship.find(
      ({ type }) => type === ClientEntityRelationshipType.AccountingFirm,
    )

    return [
      {
        title: `${dueDiligenceInfo.clientName} Team`,
        isActive: dueDiligenceCompanyInfo.hasContacts,
        isCompleted: dueDiligenceCompanyInfo.isAllContactsValid,
      },
      {
        title: 'Advisors',
        isActive:
          dueDiligenceCompanyInfo.boardMembersCount !== null ||
          dueDiligenceCompanyInfo.executivesCount !== null ||
          isEntityRelationshipInProgress(lawFirm) ||
          dueDiligenceCompanyInfo.isOutsourcedCFO ||
          isEntityRelationshipInProgress(outsourcedCFO) ||
          isEntityRelationshipInProgress(accountingFirm),
        isCompleted:
          dueDiligenceCompanyInfo.boardMembersCount !== null &&
          dueDiligenceCompanyInfo.executivesCount !== null &&
          isEntityRelationshipCompleted(lawFirm) &&
          (dueDiligenceCompanyInfo.isOutsourcedCFO === false ||
            isEntityRelationshipCompleted(outsourcedCFO)) &&
          isEntityRelationshipCompleted(accountingFirm),
      },
      {
        title: 'Executives',
        isActive: dueDiligenceCompanyInfo.hasExecutives,
        isCompleted: dueDiligenceCompanyInfo.isAllExecutivesValid,
      },
      {
        title: 'Board members',
        isActive: dueDiligenceCompanyInfo.hasBoardMembers,
        isCompleted: dueDiligenceCompanyInfo.isAllBoardMembersValid,
      },
      {
        title: 'References',
        isActive: dueDiligenceCompanyInfo.hasReferences,
        isCompleted: dueDiligenceCompanyInfo.isAllReferencesValid,
      },
      {
        title: 'Documentation',
        isActive:
          documentRequests.length > 0 && documentRequests.some(({ files }) => files.length > 0),
        isCompleted:
          documentRequests.length > 0 && documentRequests.every(({ files }) => files.length > 0),
      },
    ]
  }, [dueDiligenceInfo, dueDiligenceCompanyInfo, dueDiligenceDocumentRequests])

  const header = useMemo(() => {
    switch (step) {
      case CONTACTS_STEP:
        return `Let us know who our contact(s) are at ${dueDiligenceInfo.clientName}`
      case ADVISORS_STEP:
        return 'Who else supports the business?'
      case EXECUTIVES_STEP:
        return 'Tell us about your leadership team'
      case BOARD_MEMBERS_STEP:
        return 'Share some information on your board'
      case REFERENCES_STEP:
        return 'Provide a few references'
      case DOCUMENTATION_STEP:
        return 'Upload docs to help us with diligence'
      default:
        return ''
    }
  }, [step, dueDiligenceInfo])

  const handleAdvisorsFormChange = useCallback(
    (values: any) => {
      const {
        executivesCount,
        boardMembersCount,
        lawFirm,
        lawFirmContactFullName,
        lawFirmContactPhone,
        lawFirmContactEmail,
        outsourcedCFO,
        outsourcedCFOContactFullName,
        outsourcedCFOContactPhone,
        outsourcedCFOContactEmail,
        isOutsourcedCFO,
        accountingFirm,
        accountingFirmContactFullName,
        accountingFirmContactPhone,
        accountingFirmContactEmail,
      } = values

      const documentRequests =
        dueDiligenceDocumentRequests?.data?.data?.filter(
          ({ type }) => type.step === DueDiligenceDocumentRequestStep.Team,
        ) || []
      const completedDocumentRequestCount = documentRequests.filter(
        ({ files }) => files.length > 0,
      ).length
      const documentRequestCount = documentRequests.length
      const advisorProgress =
        ((executivesCount ? 1 : 0) +
          (boardMembersCount ? 1 : 0) +
          (isEntityRelationshipCompleted({
            newSalesforceAccount: lawFirm,
            fullName: lawFirmContactFullName,
            phone: lawFirmContactPhone,
            email: lawFirmContactEmail,
          })
            ? 1
            : 0) +
          (isOutsourcedCFO === 'false' ||
          isEntityRelationshipCompleted({
            newSalesforceAccount: outsourcedCFO,
            fullName: outsourcedCFOContactFullName,
            phone: outsourcedCFOContactPhone,
            email: outsourcedCFOContactEmail,
          })
            ? 1
            : 0) +
          (isEntityRelationshipCompleted({
            newSalesforceAccount: accountingFirm,
            fullName: accountingFirmContactFullName,
            phone: accountingFirmContactPhone,
            email: accountingFirmContactEmail,
          })
            ? 1
            : 0)) /
        5

      setProgress(
        Math.round(
          ((completedDocumentRequestCount +
            (dueDiligenceCompanyInfo.hasContacts ? 1 : 0) +
            (dueDiligenceCompanyInfo.hasExecutives ? 1 : 0) +
            (dueDiligenceCompanyInfo.hasBoardMembers ? 1 : 0) +
            (dueDiligenceCompanyInfo.hasReferences ? 1 : 0) +
            advisorProgress) /
            (documentRequestCount + 5)) *
            100,
        ),
      )
    },
    [dueDiligenceCompanyInfo, dueDiligenceDocumentRequests],
  )

  const handleAdvisorsFormSave = useCallback(
    async (data) => {
      const {
        lawFirm,
        lawFirmContactFullName,
        lawFirmContactPhone,
        lawFirmContactEmail,
        outsourcedCFO,
        outsourcedCFOContactFullName,
        outsourcedCFOContactPhone,
        outsourcedCFOContactEmail,
        isOutsourcedCFO,
        accountingFirm,
        accountingFirmContactFullName,
        accountingFirmContactPhone,
        accountingFirmContactEmail,
        ...rest
      } = data

      setIsLoading(true)
      await updateTeamAdvisorsInfo({
        clientEntityRelationship: [
          lawFirm || lawFirmContactFullName || lawFirmContactPhone || lawFirmContactEmail
            ? {
                type: ClientEntityRelationshipType.LawFirm,
                salesforceAccount: lawFirm?.label || lawFirm,
                fullName: lawFirmContactFullName,
                phone: lawFirmContactPhone,
                email: lawFirmContactEmail,
              }
            : null,
          isOutsourcedCFO === 'true' &&
          (outsourcedCFO ||
            outsourcedCFOContactFullName ||
            outsourcedCFOContactPhone ||
            outsourcedCFOContactEmail)
            ? {
                type: ClientEntityRelationshipType.OutsourcedCFO,
                salesforceAccount: outsourcedCFO?.label || outsourcedCFO,
                fullName: outsourcedCFOContactFullName,
                phone: outsourcedCFOContactPhone,
                email: outsourcedCFOContactEmail,
              }
            : null,
          accountingFirm ||
          accountingFirmContactFullName ||
          accountingFirmContactPhone ||
          accountingFirmContactEmail
            ? {
                type: ClientEntityRelationshipType.AccountingFirm,
                salesforceAccount: accountingFirm?.label || accountingFirm,
                fullName: accountingFirmContactFullName,
                phone: accountingFirmContactPhone,
                email: accountingFirmContactEmail,
              }
            : null,
        ].filter(Boolean),
        isOutsourcedCFO,
        ...rest,
      })
      setIsLoading(false)

      return
    },
    [updateTeamAdvisorsInfo],
  )

  return (
    <DueDiligenceFullscreenModal
      step={step}
      setStep={handleSetStep}
      steps={steps}
      progress={progress}
      header={header}
      onCloseModal={handleCloseModal}
      onNext={onNext}
      onBack={onBack}
      isDisabled={step === ADVISORS_STEP && isAdvisorsFormInvalid}
      isLoading={isLoading}
      isSaving={dueDiligenceDocumentRequests.isSaving}
      isSaved={dueDiligenceDocumentRequests.isSaved}
    >
      {step === CONTACTS_STEP && <DueDiligenceTeamContacts />}
      {step === ADVISORS_STEP && (
        <DueDiligenceTeamAdvisors
          formRef={advisorsFormRef}
          onSubmit={handleAdvisorsFormSave}
          onChange={handleAdvisorsFormChange}
          setIsFormInvalid={setIsAdvisorsFormInvalid}
        />
      )}
      {step === EXECUTIVES_STEP && <DueDiligenceTeamExecutives />}
      {step === BOARD_MEMBERS_STEP && <DueDiligenceTeamBoardMembers />}
      {step === REFERENCES_STEP && <DueDiligenceTeamReferences />}
      {step === DOCUMENTATION_STEP && (
        <DueDiligenceClientStepFiles
          step={DueDiligenceDocumentRequestStep.Team}
          navigationRef={filesNavigationRef}
        />
      )}
    </DueDiligenceFullscreenModal>
  )
}

export default DueDiligenceTeamModal
