import { Action } from 'redux'

import { ILoadingData } from '../redux/types'
import { CREATE_ACTIVITY } from '@common/constants/webSockets'
import { CREATE_PARTICIPATION_WIRE_SUCCESS } from '../redux/participant/actions'

const ADD_TO_TOP_ACTIONS = [CREATE_ACTIVITY, CREATE_PARTICIPATION_WIRE_SUCCESS]

const buildActionTypesRegExp = (actionTypes?: string[]): RegExp | null => {
  if (!actionTypes?.length) {
    return null
  }

  return new RegExp(
    `^(?:${[
      ...new Set(
        actionTypes
          .map((item) => item.replace(/_(request|success|failure)/gi, ''))
          .map((type) => [`${type}_REQUEST`, `${type}_SUCCESS`, `${type}_FAILURE`])
          .flat(),
      ),
    ].join('|')})`,
    'i',
  )
}

const updateNestedData = (
  data: any[],
  action: { [key: string]: string } & { data: any[] },
  keys: string[],
  loadMore: boolean,
): any => {
  const [currentKey, ...remainingKeys] = keys

  return data.map((item) => {
    if (item[currentKey] === action[currentKey]) {
      if (remainingKeys.length === 0) {
        return {
          ...item,
          rows: loadMore ? [...item.rows, ...action.data] : action.data,
        }
      }
      return {
        ...item,
        rows: updateNestedData(item.rows, action, remainingKeys, loadMore),
      }
    }
    return item
  })
}

export const createLoadingDataReducer = <T>(
  loadingActionTypes: string[],
  savingActionTypes?: string[],
  withSaveActionLiveEdit?: boolean,
  resetActionTypes?: string[],
  withMergeOnUpdate?: boolean,
  customLiveEdit?: (state: ILoadingData<T>, action: Action) => ILoadingData<T>,
) => {
  const initialState: ILoadingData<T> = {
    isLoading: true,
    isSaving: false,
    isSaved: false,
    data: null,
  }
  const loadingRegExp = buildActionTypesRegExp(loadingActionTypes)
  const savingRegExp = buildActionTypesRegExp(savingActionTypes)

  return (
    state = initialState,
    action: Action & {
      data: any
      params: {
        loadMore?: boolean
        skipLoader?: boolean
        nestedRows?: { keys: string[] }
        customLiveEdit?: (state: ILoadingData<T>, action: Action) => ILoadingData<T>
      }
    },
  ) => {
    if (loadingRegExp.test(action.type)) {
      if (action.type.endsWith('_REQUEST')) {
        return {
          ...state,
          isLoading:
            !action.params?.loadMore && !action.params?.skipLoader && !action.params?.nestedRows
              ? true
              : state.isLoading,
        }
      }
      if (action.type.endsWith('_FAILURE')) {
        return {
          ...state,
          isLoading: false,
        }
      }
      if (action.type.endsWith('_SUCCESS')) {
        if (action.params?.nestedRows) {
          const { keys } = action.params.nestedRows
          return {
            ...state,
            data: {
              ...state.data,
              data: updateNestedData(
                // @ts-ignore
                state.data.data,
                action.data,
                keys,
                action.params.loadMore,
              ),
            },
          }
        }
        return {
          ...state,
          isLoading: false,
          data: {
            ...action.data,
            data: action.params?.loadMore
              ? // @ts-ignore
                [...(state.data.data || []), ...action.data.data]
              : action.data.data,
          },
        }
      }
    }
    if (savingRegExp && savingRegExp.test(action.type)) {
      if (action.type.endsWith('_REQUEST')) {
        return {
          ...state,
          isSaving: true,
          isSaved: false,
        }
      }
      if (action.type.endsWith('_FAILURE') || action.type.endsWith('_SUCCESS')) {
        const baseState = {
          ...state,
          isSaving: false,
          isSaved: action.type.endsWith('_SUCCESS'),
        }
        if (withSaveActionLiveEdit && action.type.endsWith('_SUCCESS')) {
          if (action.params?.customLiveEdit) {
            return action.params.customLiveEdit(baseState, action)
          }
          if (customLiveEdit) {
            return customLiveEdit(baseState, action)
          }
          const mainAction = action.type.split('/')[1]

          if (mainAction.startsWith('DELETE') || mainAction.startsWith('REMOVE')) {
            return {
              ...baseState,
              data: {
                ...state.data,
                // @ts-ignore
                data: state?.data?.data?.filter(
                  (item: any) =>
                    item.id !== action.data.id && !(action.data.ids || []).includes(item.id),
                ),
              },
            }
          }
          if (
            mainAction.startsWith('UPDATE') ||
            mainAction.startsWith('RESTORE') ||
            mainAction.startsWith('NOTIFY') ||
            mainAction.startsWith('INVITE') ||
            mainAction.startsWith('ADD_OR_REMOVE')
          ) {
            if (action.data.items) {
              return {
                ...baseState,
                data: {
                  ...state.data,
                  // @ts-ignore
                  data: state?.data?.data?.map((item) => {
                    const updatedItem = action.data.items.find(({ id }: any) => id === item.id)

                    return updatedItem
                      ? withMergeOnUpdate
                        ? { ...item, ...updatedItem }
                        : updatedItem
                      : item
                  }),
                },
              }
            }

            return {
              ...baseState,
              data: {
                ...state.data,
                // @ts-ignore
                data: state?.data?.data?.map((item) =>
                  item.id === action.data.id
                    ? withMergeOnUpdate
                      ? { ...item, ...action.data }
                      : action.data
                    : item,
                ),
              },
            }
          }
          if (mainAction.startsWith('CREATE') || mainAction.startsWith('ADD')) {
            // @ts-ignore
            if (!state?.data?.data) {
              return state
            }
            // @ts-ignore
            if (state?.data?.data?.find((item) => item.id === action.data.id)) {
              return state
            }
            if (ADD_TO_TOP_ACTIONS.includes(action.type)) {
              return {
                ...baseState,
                data: {
                  ...state.data,
                  // @ts-ignore
                  data: [action.data, ...state.data.data],
                },
              }
            }
            return {
              ...baseState,
              data: {
                ...state.data,
                // @ts-ignore
                data: [...state.data.data, action.data],
              },
            }
          }
        }
        return baseState
      }
    }
    if (resetActionTypes && resetActionTypes.includes(action.type)) {
      return initialState
    }

    return state
  }
}
