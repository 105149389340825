import React from 'react'
import { Link as RouterLink } from 'react-router-dom'
import Link from '@mui/material/Link'
import MUIBreadcrumbs from '@mui/material/Breadcrumbs'

import styles from './Breadcrumbs.module.scss'

import { ReactComponent as NextIcon } from '@assets/images/next-icon.svg'
import Skeleton from '@mui/material/Skeleton'
import cn from 'classnames'

interface IBreadcrumb {
  title?: string
  link: string
  Icon?: React.FC
}

interface IProps {
  breadcrumbs: IBreadcrumb[]
  isLoading?: boolean
  noMarginLeft?: boolean
}

const Breadcrumbs = ({ breadcrumbs, isLoading, noMarginLeft }: IProps) => {
  return (
    <MUIBreadcrumbs
      separator={<NextIcon className={styles.navigateNext} />}
      className={cn(styles.breadcrumbs, {
        [styles.noMarginLeft]: noMarginLeft,
      })}
    >
      {breadcrumbs.map(({ title, link, Icon }) => {
        if (isLoading) {
          return <Skeleton key={link} width={Icon && !title ? 12 : 75} height={30} />
        }
        return (
          <Link key={link} component={RouterLink} to={link} className={styles.breadcrumbLink}>
            {Icon && <Icon />}
            {title}
          </Link>
        )
      })}
    </MUIBreadcrumbs>
  )
}

export default Breadcrumbs
