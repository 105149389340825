import React, { useState, useCallback, useMemo } from 'react'
import Link from '@mui/material/Link'
import { Link as RouterLink } from 'react-router-dom'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'

import styles from './AnalysisQueueTable.module.scss'

import WarningModal from '../WarningModal'
import { IOngoingReporting, OngoingReportingStatus } from '@common/interfaces/bbc'
import { REPORTING_DOCUMENT_TYPES } from '@common/constants/client'
import { buildFiltersDefaults } from '../../helpers/filters'
import { ONGOING_REPORTING_LIST_FILTERS_CONFIG } from '@common/constants/filters'
import { MenuIcon } from '../Common/Icons'

export const filtersDefaults = buildFiltersDefaults(ONGOING_REPORTING_LIST_FILTERS_CONFIG, {
  statuses: [
    OngoingReportingStatus.NotStarted,
    OngoingReportingStatus.Uploaded,
    OngoingReportingStatus.Process,
    OngoingReportingStatus.InReview,
    OngoingReportingStatus.Verified,
  ],
})

const ACTION_LABEL = {
  [OngoingReportingStatus.NotStarted]: 'Process',
  [OngoingReportingStatus.Process]: 'Resume',
  [OngoingReportingStatus.InReview]: 'Review',
  [OngoingReportingStatus.Verified]: 'View',
  [OngoingReportingStatus.Uploaded]: 'View',
  [OngoingReportingStatus.Archived]: 'View',
}

interface IProps {
  item: IOngoingReporting
  itemLink: string
  handleClick: (item: IOngoingReporting) => void

  deleteOngoingReporting: (id: string) => Promise<void>
  updateOngoingReportingStatus: (data: object) => Promise<void>

  isLoading: boolean
  refetchOngoingReportingList: () => void
}

const OngoingReportingActions = ({
  item,
  itemLink,
  deleteOngoingReporting,
  updateOngoingReportingStatus,
  refetchOngoingReportingList,
  handleClick,
  isLoading,
}: IProps) => {
  const [isDeleteModalShown, setIsDeleteModalShown] = useState(false)
  const [isArchiveModalShown, setIsArchiveModalShown] = useState(false)
  const [selectedReport, setSelectedReport] = useState(null)

  const [anchorEl, setAnchorEl] = useState(null)
  const [menuOpen, setMenuOpen] = useState(false)

  const handleClickMenu = useCallback((event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
    setMenuOpen(true)
  }, [])

  const handleCloseMenu = useCallback(() => {
    setAnchorEl(null)
    setMenuOpen(false)
  }, [])

  const handleArchiveOngoingReportingConfirm = useCallback(async () => {
    await updateOngoingReportingStatus({
      id: selectedReport,
      status: OngoingReportingStatus.Archived,
    })
    setSelectedReport(null)
    setIsArchiveModalShown(false)
    refetchOngoingReportingList()
  }, [selectedReport, updateOngoingReportingStatus, refetchOngoingReportingList])

  const handleArchiveOngoingReporting = useCallback(
    async ({ id }) => {
      setSelectedReport(id)
      setIsArchiveModalShown(true)
      handleCloseMenu()
    },
    [handleCloseMenu],
  )

  const handleDeleteOngoingReporting = useCallback(
    async (id: string) => {
      setSelectedReport(id)
      setIsDeleteModalShown(true)
      handleCloseMenu()
    },
    [handleCloseMenu],
  )

  const handleDeleteOngoingReportingCancel = useCallback(() => {
    setSelectedReport(null)
    setIsDeleteModalShown(false)
    setIsArchiveModalShown(false)
  }, [])

  const handleDeleteOngoingReportingConfirm = useCallback(async () => {
    await deleteOngoingReporting(selectedReport)
    setSelectedReport(null)
    setIsDeleteModalShown(false)
    refetchOngoingReportingList()
  }, [selectedReport, deleteOngoingReporting, refetchOngoingReportingList])

  const isArchived = useMemo(() => item.status === OngoingReportingStatus.Archived, [item])

  return (
    <>
      <MenuIcon onClick={handleClickMenu} isActive={menuOpen} size="small" />
      <Menu
        classes={{ paper: styles.iconMenu }}
        open={menuOpen}
        onClose={handleCloseMenu}
        anchorEl={anchorEl}
      >
        <MenuItem disabled={!item.preProcessed} classes={{ root: styles.iconMenuItem }}>
          {[
            REPORTING_DOCUMENT_TYPES.financials,
            REPORTING_DOCUMENT_TYPES.financialProjections,
            REPORTING_DOCUMENT_TYPES.salesBySKU,
            REPORTING_DOCUMENT_TYPES.arGeneralLedger,
            REPORTING_DOCUMENT_TYPES.bankTransactions,
            REPORTING_DOCUMENT_TYPES.capTable,
          ].includes(item.type) ? (
            ACTION_LABEL[item.status] && (
              <Link
                component={RouterLink}
                to={itemLink}
                className={styles.linkBtn}
                onClick={() => handleClick(item)}
              >
                {ACTION_LABEL[item.status]}
              </Link>
            )
          ) : (
            <Link target="_blank" className={styles.linkBtn} href={item.boxLink}>
              View
            </Link>
          )}
        </MenuItem>
        {!isArchived && (
          <MenuItem
            classes={{ root: styles.iconMenuItem }}
            onClick={() => {
              handleArchiveOngoingReporting({
                id: item.id,
              })
            }}
          >
            Archive
          </MenuItem>
        )}
        <MenuItem
          classes={{ root: styles.iconMenuItem }}
          onClick={() => handleDeleteOngoingReporting(item.id)}
        >
          Delete
        </MenuItem>
      </Menu>

      {isDeleteModalShown && selectedReport && (
        <WarningModal
          warningMessage="Deleting this record will be permanent."
          onConfirm={handleDeleteOngoingReportingConfirm}
          onCancel={handleDeleteOngoingReportingCancel}
          confirmText="Yes, proceed"
          cancelText="Cancel"
          disabled={isLoading}
        />
      )}
      {isArchiveModalShown && selectedReport && (
        <WarningModal
          warningMessage="Archive this record?"
          onConfirm={handleArchiveOngoingReportingConfirm}
          onCancel={handleDeleteOngoingReportingCancel}
          confirmText="Yes, proceed"
          cancelText="Cancel"
          disabled={isLoading}
        />
      )}
    </>
  )
}

export default OngoingReportingActions
