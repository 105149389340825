import { LOAN_TYPES } from '@common/constants/client'
import { ReserveKind } from '@common/interfaces/bbc'

export const FIELDS_LABELS = {
  over_90DaysAndCreditsPastDue: 'Past Due & Credits',
  crossAge: 'Cross Age',
  ineligibleDebtors: 'Ineligible Customers',
  contras: 'Contras',
  concentration: 'Concentration',
  creditLimits: 'Credit Limits',
  arOtherMiscReserves: 'Other',
  ineligibleSkus: 'Ineligible SKUs and Season',
  ineligibleLocationsAndInTransit: 'Ineligible Locations and In-Transit',
  slowAgedOrExpired: 'Slow Moving, Aged, Near/Past Expiration',
  invOtherMiscReserves: 'Other Misc. Reserves',
  percent: 'Percentage',
  absolute: 'Absolute',
  totalGrossAr: 'Gross AR',
  eligibleReceivables: 'Eligible AR',
  totalGrossInventory: 'Gross Inventory',
  eligibleInventoryCost: 'Eligible Inventory',
}

const CATEGORIES_LIST = {
  [LOAN_TYPES.ar]: [
    'over_90DaysAndCreditsPastDue',
    'crossAge',
    'ineligibleDebtors',
    'contras',
    'concentration',
    'creditLimits',
    'arOtherMiscReserves',
  ],
  [LOAN_TYPES.inventory]: [
    'ineligibleSkus',
    'ineligibleLocationsAndInTransit',
    'slowAgedOrExpired',
    'invOtherMiscReserves',
  ],
}

export const CATEGORIES_OPTIONS = {
  [LOAN_TYPES.ar]: CATEGORIES_LIST[LOAN_TYPES.ar].map((field) => ({
    value: field,
    label: FIELDS_LABELS[field],
  })),
  [LOAN_TYPES.inventory]: CATEGORIES_LIST[LOAN_TYPES.inventory].map((field) => ({
    value: field,
    label: FIELDS_LABELS[field],
  })),
}

export const FIELDS_OPTIONS = {
  [LOAN_TYPES.ar]: [
    {
      value: 'totalGrossAr',
      label: 'Gross AR',
    },
    {
      value: 'eligibleReceivables',
      label: 'Eligible AR',
    },
  ],
  [LOAN_TYPES.inventory]: [
    {
      value: 'totalGrossInventory',
      label: 'Gross inventory',
    },
    {
      value: 'eligibleInventoryCost',
      label: 'Eligible inventory',
    },
  ],
}

export const TYPES_OPTIONS = [
  {
    value: ReserveKind.Absolute,
    label: FIELDS_LABELS[ReserveKind.Absolute],
  },
  {
    value: ReserveKind.Percent,
    label: FIELDS_LABELS[ReserveKind.Percent],
  },
]
