import { connect } from 'react-redux'

import ManageTermsStatusSelect from './ManageTermsStatusSelect'
import { updateProspectTerms } from '../../../redux/prospect/actions'

const actions = {
  updateProspectTerms,
}

export default connect(null, actions)(ManageTermsStatusSelect)
