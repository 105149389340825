import React, { useState, useCallback } from 'react'
import { useParams } from 'react-router'
import { Form } from 'react-final-form'
import { makeValidate } from 'mui-rff'
import * as Yup from 'yup'
import Box from '@mui/material/Box'
import InputAdornment from '@mui/material/InputAdornment'
import IconButton from '@mui/material/IconButton'
import Visibility from '../../assets/images/text-visibility-on.svg'
import VisibilityOff from '../../assets/images/text-visibility-off.svg'

import TextField from '../Common/TextField'
import InputLabel from '../Common/InputLabel'
import Button from '../Common/Button'
import StrongPasswordCheck from '../StrongPasswordCheck'

import styles from './ResetPasswordForm.module.scss'
import authContainerStyles from '../AuthContainer/AuthContainer.module.scss'
import genericSs from '@styles/generic.module.scss'

const schema = Yup.object().shape({
  password: Yup.string()
    .required('Password is required')
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[^A-Za-z0-9]).{8,}/,
      'Password is not strong enough',
    ),
  confirmPassword: Yup.string()
    .required('Password confirmation is required')
    .oneOf([Yup.ref('password'), null], "Passwords doesn't match"),
})
const validate = makeValidate(schema)

interface IFormFields {
  password: string
  confirmPassword: string
}

interface ParamTypes {
  hash: string
}

interface IProps {
  onResetPassword: (data: IFormFields & { hash: string }) => void
}

const ResetPasswordForm = ({ onResetPassword }: IProps) => {
  const { hash } = useParams<ParamTypes>()

  const handleResetPassword = useCallback(
    (data: any) => {
      onResetPassword({ ...data, hash })
    },
    [onResetPassword, hash],
  )

  const [passwordVisibility, setPasswordVisibility] = useState(false)
  const handleClickShowPassword = useCallback(() => {
    setPasswordVisibility((visibility) => !visibility)
  }, [])

  const [confirmPasswordVisibility, setConfirmPasswordVisibility] = useState(false)
  const handleClickShowConfirmPassword = useCallback(() => {
    setConfirmPasswordVisibility((visibility) => !visibility)
  }, [])

  return (
    <div className={styles.resetClass}>
      <Form
        validate={validate}
        onSubmit={handleResetPassword}
        render={(props) => (
          <form onSubmit={props.handleSubmit}>
            <Box display="flex" flexDirection="column" width="100%">
              <Box mb={2}>
                <InputLabel className={authContainerStyles.emailInput} htmlFor="password">
                  New Password
                </InputLabel>
                <TextField
                  name="password"
                  type={passwordVisibility ? 'text' : 'password'}
                  placeholder="Enter your new password"
                  className={genericSs.passwordInput}
                  fullWidth
                  showInputAdornmentBorder={false}
                  InputProps={{
                    autoComplete: 'off',
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          className={styles.iconButtonVisibility}
                          disableRipple
                          onClick={handleClickShowPassword}
                        >
                          {passwordVisibility ? (
                            <img src={Visibility} alt="toggle password visibility" />
                          ) : (
                            <img src={VisibilityOff} alt="toggle password visibility" />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </Box>
              <Box mb={2}>
                <InputLabel className={authContainerStyles.emailInput} htmlFor="password">
                  Confirm password
                </InputLabel>
                <TextField
                  name="confirmPassword"
                  type={confirmPasswordVisibility ? 'text' : 'password'}
                  placeholder="Confirm your new password"
                  className={genericSs.passwordInput}
                  fullWidth
                  showInputAdornmentBorder={false}
                  InputProps={{
                    autoComplete: 'off',
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          className={styles.iconButtonVisibility}
                          disableRipple
                          onClick={handleClickShowConfirmPassword}
                        >
                          {confirmPasswordVisibility ? (
                            <img src={Visibility} alt="toggle password visibility" />
                          ) : (
                            <img src={VisibilityOff} alt="toggle password visibility" />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </Box>
              <StrongPasswordCheck password={props.values.password} />
              <Box>
                <Button
                  type="submit"
                  disabled={props.submitting || props.pristine || props.invalid}
                  className={authContainerStyles.resetButton}
                >
                  Submit
                </Button>
              </Box>
            </Box>
          </form>
        )}
      />
    </div>
  )
}

export default ResetPasswordForm
