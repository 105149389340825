import { trackActivity } from './../../redux/activity/actions'
import { connect } from 'react-redux'
import { createStructuredSelector, createSelector } from 'reselect'

import BBCMappingModal from './BBCMappingModal'

import { bbc } from '../../redux/bbc/selectors'
import { OngoingReportingType } from '@common/interfaces/bbc'

const selector = createStructuredSelector({
  bbc,
  arFile: createSelector(bbc, (bbcInfo) => {
    const fileSheets = (bbcInfo?.fileSheets || []).filter(
      ({ type }) => type === OngoingReportingType.AR,
    )
    const selectedFileSheet = fileSheets[0]

    if (!selectedFileSheet) {
      return null
    }

    const files = bbcInfo?.files || []

    return files.find(({ id }) => id === selectedFileSheet.fileId)
  }),
  apFile: createSelector(bbc, (bbcInfo) => {
    const fileSheets = (bbcInfo?.fileSheets || []).filter(
      ({ type }) => type === OngoingReportingType.AP,
    )
    const selectedFileSheet = fileSheets[0]

    if (!selectedFileSheet) {
      return null
    }

    const files = bbcInfo?.files || []

    return files.find(({ id }) => id === selectedFileSheet.fileId)
  }),
})
const actions = {
  trackActivity,
}

export { BBCMappingModal }

export default connect(selector, actions)(BBCMappingModal)
