import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'

import ResetPasswordPage from './ResetPasswordPage'

import { isChangedPwd } from '../../redux/profile/selectors'
import { checkPasswordSetup } from '../../redux/profile/actions'

const selector = createStructuredSelector({
  isChangedPwd,
})

const actions = {
  checkPasswordSetup,
}

export default connect(selector, actions)(ResetPasswordPage)
