import { ActionPattern } from '@redux-saga/types'
import { IApi } from '../../api'
import { CommonPromiseAction } from '../types'
import { createRequestTypes } from '../../helpers/helpers'

export const prefix = 'box'

export const LIST_FOLDERS_REQUEST = `${prefix}/LIST_FOLDERS_REQUEST`
export const LIST_FOLDERS_SUCCESS = `${prefix}/LIST_FOLDERS_SUCCESS`
export const LIST_FOLDERS_FAILURE = `${prefix}/LIST_FOLDERS_FAILURE` as ActionPattern

export const listFolders: CommonPromiseAction = () => ({
  type: [LIST_FOLDERS_REQUEST, LIST_FOLDERS_SUCCESS, LIST_FOLDERS_FAILURE],
  promise: (api: IApi) => api.box.listFolders(),
})

export const SET_BOX_VIEWER_LINK = `${prefix}/SET_BOX_VIEWER_LINK`

export const setBoxViewerLink = (link: string) => ({
  type: SET_BOX_VIEWER_LINK,
  data: link,
})

export const GET_EMBED_LINK = createRequestTypes(prefix, `GET_EMBED_LINK`)

export const [GET_EMBED_LINK_REQUEST, GET_EMBED_LINK_SUCCESS, GET_EMBED_LINK_FAILURE] =
  GET_EMBED_LINK

export const getEmbedLink = (fileId: string) => ({
  type: GET_EMBED_LINK,
  promise: (api: IApi) => api.box.getEmbedLink(fileId),
})
