import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'

import DilutionOverTime from './DilutionOverTime'

import { dilutionDropdowns, dilutionOverTime } from '../../redux/generalLedger/selectors'
import { listDilutionOverTime, hideDilutionOverTime } from '../../redux/generalLedger/actions'
import { debtorIneligibleCategories } from '../../redux/options/selectors'

const selector = createStructuredSelector({
  dilutionOverTime,
  debtorIneligibleCategories,
  dilutionDropdowns,
})
const actions = {
  listDilutionOverTime,
  hideDilutionOverTime,
}

export default connect(selector, actions)(DilutionOverTime)
