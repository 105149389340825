import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'

import CustomersSummaryTable from './CustomersSummaryTable'

import { customersAggregation } from '../../redux/entityInfo/selectors'
import { listCustomers, exportCustomers, listEntityInfo } from '../../redux/entityInfo/actions'

const selector = createStructuredSelector({
  fullCustomersAggregation: customersAggregation,
})
const actions = {
  listCustomers,
  exportCustomers,
  listEntityInfo,
}

export default connect(selector, actions)(CustomersSummaryTable)
