import Base from './base'

export default class AuditLog extends Base {
  list(data: object) {
    return this.apiClient.get('audit-log', data)
  }

  getDictionary() {
    return this.apiClient.get('audit-log/dictionary')
  }
}
