import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'

import ClientDashboard from './ClientDashboard'

import { isLoading } from '../../redux/profile/selectors'

const selector = createStructuredSelector({
  isLoading,
})

export default connect(selector, null)(ClientDashboard)
