import ClientSettingsMenu from './ClientSettingsMenu'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import { user } from '../../redux/profile/selectors'

const selector = createStructuredSelector({
  user,
})

export default connect(selector, null)(ClientSettingsMenu)
