import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'

import ClientAccountActivityCreateModal from './ClientAccountActivityCreateModal'

import {
  listClientParticipations,
  createParticipationWire,
} from '../../../redux/participant/actions'
import { clientParticipations } from '../../../redux/participant/selectors'
import { clientInfo, termLoans } from '../../../redux/clientInfo/selectors'
import { createFunding, createTermLoanActivity } from '../../../redux/clientInfo/actions'
import { addEntityInfo, listEntityInfo } from '../../../redux/entityInfo/actions'
import { createFee } from '../../../redux/fee/actions'
import { createAdjustment } from '../../../redux/adjustment/actions'
import { createWire, createCheck } from '../../../redux/collections/actions'
import { loanBalanceStartDate } from '../../../redux/loanBalance/selectors'

const selector = createStructuredSelector({
  loanBalanceStartDate,
  clientInfo,
  termLoans,
  clientParticipations,
})

const actions = {
  createFee,
  createFunding,
  createAdjustment,
  createWire,
  createCheck,
  addEntityInfo,
  listEntityInfo,
  listClientParticipations,
  createParticipationWire,
  createTermLoanPayment: createTermLoanActivity,
}

export default connect(selector, actions)(ClientAccountActivityCreateModal)
