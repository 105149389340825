import { combineReducers } from 'redux'

import { IDebtorIneligibleCategories } from '@common/interfaces/bbc'
import * as actions from './actions'
import { ILoadingData } from '../types'
import { createLoadingDataReducer } from '../../helpers/redux'

export interface IOptionsState {
  debtorIneligibleCategories: ILoadingData<IDebtorIneligibleCategories>
}

export default combineReducers<IOptionsState>({
  debtorIneligibleCategories: createLoadingDataReducer<IDebtorIneligibleCategories>([
    actions.GET_DEBTOR_INELIGIBLE_CATEGORIES_REQUEST,
  ]),
})
