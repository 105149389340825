import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import BankTransactionsPage from './BankTransactionsPage'
import { bankTransactions } from '../../redux/bankTransactions/selectors'

import {
  showOngoingReporting,
  updateOngoingReportingStatus,
} from '../../redux/ongoingReporting/actions'
import { ongoingReporting } from '../../redux/ongoingReporting/selectors'
import { trackActivity } from '../../redux/activity/actions'

import { balances } from '../../redux/bankAccount/selectors'
import { listBankAccountBalances } from '../../redux/bankAccount/actions'
import { calculateBankTransactionFlags } from '../../redux/flag/actions'

const selector = createStructuredSelector({
  bankTransactions,
  ongoingReporting,
  balances,
})

const actions = {
  updateOngoingReportingStatus,
  showOngoingReporting,
  listBankAccountBalances,
  trackActivity,
  calculateBankTransactionFlags,
}

export { BankTransactionsPage }

export default connect(selector, actions)(BankTransactionsPage)
