import React, { ReactNode, useMemo } from 'react'
import TableCell from '../TableCell'
import genericSs from '@styles/generic.module.scss'
import { formatDate, formatNumber, formatPercent, formatPrice } from '../../../helpers/helpers'
import { FieldType } from '@common/constants/filters'
import cn from 'classnames'
import { Tooltip } from '@mui/material'
import ChangedValue from '../../../components/ChangedValue'

interface IProps {
  value: any
  fieldType: FieldType
  nullCondition?: boolean
  tooltip?: string | ReactNode
  isChip?: boolean
  showZeroChip?: boolean
}

const getCellValue = (value: any, fieldType: FieldType, nullCondition?: boolean) => {
  if (nullCondition) {
    return '-'
  } else if (fieldType === 'percent') {
    return formatPercent(value)
  } else if (fieldType === 'date') {
    return formatDate(value)
  } else if (fieldType === 'amount') {
    return formatPrice(value)
  } else if (fieldType === 'number') {
    return formatNumber(value)
  } else if (fieldType === 'text') {
    return value
  } else {
    return null
  }
}

const CellValue = ({
  formattedValue,
  fieldType,
}: {
  formattedValue: string | number
  fieldType: FieldType
}) => {
  return (
    <>
      {fieldType === 'amount' && <span className={genericSs.pricePrefix}>$</span>}
      {formattedValue}
    </>
  )
}

const FormattedTableCell = ({
  value,
  fieldType,
  nullCondition,
  tooltip,
  isChip,
  showZeroChip,
}: IProps) => {
  const formattedValue = useMemo(
    () => getCellValue(value, fieldType, nullCondition),
    [value, fieldType, nullCondition],
  )

  return (
    <TableCell
      className={cn({
        [genericSs.tableTextLeft]: fieldType === 'text',
        [genericSs.tableTextRight]: fieldType !== 'text',
        [genericSs.tableTextCenter]: isChip,
      })}
    >
      {tooltip ? (
        <Tooltip title={tooltip} placement="top">
          <div className={genericSs.tableText}>
            <CellValue formattedValue={formattedValue} fieldType={fieldType} />
          </div>
        </Tooltip>
      ) : isChip ? (
        <ChangedValue
          isChip
          changedValue={value}
          type={fieldType === 'amount' ? 'currency' : fieldType === 'percent' ? 'percent' : null}
          showZero={showZeroChip}
        />
      ) : (
        <CellValue formattedValue={formattedValue} fieldType={fieldType} />
      )}
    </TableCell>
  )
}

export default FormattedTableCell
