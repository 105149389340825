import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'

import NotificationPane from './NotificationPane'

import { isLoading, lastNotifications } from '../../redux/notification/selectors'
import { markAsRead } from '../../redux/notification/actions'

const selector = createStructuredSelector({
  isLoading,
  notifications: lastNotifications,
})

const actions = {
  markAsRead,
}

export default connect(selector, actions)(NotificationPane)
