import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'

import DueDiligencePageHeader from './DueDiligencePageHeader'

import { dueDiligenceInfo, dueDiligenceProgress } from '../../redux/dueDiligence/selectors'
import { show, hide, update, refreshAutoReporting } from '../../redux/dueDiligence/actions'
import { updateClient } from '../../redux/clientInfo/actions'

const selector = createStructuredSelector({
  dueDiligenceInfo,
  dueDiligenceProgress,
})
const actions = {
  show,
  hide,
  update,
  refreshAutoReporting,
  updateClient,
}

export default connect(selector, actions)(DueDiligencePageHeader)
