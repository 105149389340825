import { connect } from 'react-redux'
import ParticipantGeneralSettings from './ParticipantGeneralSettings'
import { participantInfo } from './../../redux/participant/selectors'
import { updateParticipant } from './../../redux/participant/actions'
import { createStructuredSelector } from 'reselect'

const selector = createStructuredSelector({
  participantInfo,
})

const actions = {
  updateParticipant,
}

export default connect(selector, actions)(ParticipantGeneralSettings)
