import { createRequestTypes } from '../../helpers/helpers'
import { IApi } from '../../api'
import { CommonPromiseAction } from '../types'

export const prefix = 'file'

const UPLOAD = createRequestTypes(prefix, 'UPLOAD')
export const [UPLOAD_REQUEST, UPLOAD_SUCCESS, UPLOAD_FAILURE] = UPLOAD

export const uploadFile: CommonPromiseAction = (data: FormData) => ({
  type: UPLOAD,
  promise: (api: IApi) => api.file.upload(data),
})

const UPDATE = createRequestTypes(prefix, 'UPDATE')
export const [UPDATE_REQUEST, UPDATE_SUCCESS, UPDATE_FAILURE] = UPDATE

export const updateFile: CommonPromiseAction = (id: string, data: object) => ({
  type: UPDATE,
  promise: (api: IApi) => api.file.update(id, data),
})

const DELETE = createRequestTypes(prefix, 'DELETE')

export const [DELETE_REQUEST, DELETE_SUCCESS, DELETE_FAILURE] = DELETE

export const deleteFile: CommonPromiseAction = (id: string | string[]) => ({
  type: DELETE,
  promise: (api: IApi) => api.file.delete(id),
})

const LIST_HEADER_MAPPING = createRequestTypes(prefix, 'LIST_HEADER_MAPPING')

export const [
  LIST_HEADER_MAPPING_REQUEST,
  LIST_HEADER_MAPPING_SUCCESS,
  LIST_HEADER_MAPPING_FAILURE,
] = LIST_HEADER_MAPPING

export const listHeaderMapping: CommonPromiseAction = (params: object) => ({
  type: LIST_HEADER_MAPPING,
  promise: (api: IApi) => api.file.listHeaderMapping(params),
})

const UPDATE_HEADER_MAPPING = createRequestTypes(prefix, 'UPDATE_HEADER_MAPPING')

export const [
  UPDATE_HEADER_MAPPING_REQUEST,
  UPDATE_HEADER_MAPPING_SUCCESS,
  UPDATE_HEADER_MAPPING_FAILURE,
] = UPDATE_HEADER_MAPPING

export const updateHeaderMapping: CommonPromiseAction = (id: string, data: object) => ({
  type: UPDATE_HEADER_MAPPING,
  promise: (api: IApi) => api.file.updateHeaderMapping(id, data),
})

const CREATE_HEADER_MAPPING = createRequestTypes(prefix, 'CREATE_HEADER_MAPPING')

export const [
  CREATE_HEADER_MAPPING_REQUEST,
  CREATE_HEADER_MAPPING_SUCCESS,
  CREATE_HEADER_MAPPING_FAILURE,
] = CREATE_HEADER_MAPPING

export const createHeaderMapping: CommonPromiseAction = (data: object) => ({
  type: CREATE_HEADER_MAPPING,
  promise: (api: IApi) => api.file.createHeaderMapping(data),
})
