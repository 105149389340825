import { connect } from 'react-redux'

import InvestmentDetailRow from './InvestmentDetailRow'

import { getInvestmentDetailRow } from '../../redux/entityInfo/actions'

const actions = {
  getInvestmentDetailRow,
}

export default connect(null, actions)(InvestmentDetailRow)
