import React from 'react'
import { useParams } from 'react-router'
import Box from '@mui/material/Box'
import BasicInformation from '../../components/Client/BasicInformation'
import Documents from '../../components/Client/Documents'
import ClientSetupHeader from '../../components/ClientSetupHeader'
import Grid from '@mui/material/Grid'
import ClientProfilePictureUpload from '../../components/ClientProfilePictureUpload'

const ClientSetupDocumentsPage = () => {
  const { id: clientId } = useParams<{ id?: string }>()

  return (
    <Box py={1} pr={2}>
      <ClientSetupHeader />
      <Box>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <ClientProfilePictureUpload />
          </Grid>
          <Grid item xs={12} lg={6}>
            <BasicInformation />
          </Grid>
          <Grid item xs={12}>
            <Documents clientId={clientId} />
          </Grid>
        </Grid>
      </Box>
    </Box>
  )
}

export default ClientSetupDocumentsPage
