import { ROUTES } from '../../constants/routes'

export const titles = {
  [ROUTES.SETTINGS]: 'Settings',
  [ROUTES.CLIENT_SETTINGS_INTEGRATIONS]: 'Settings',
  [ROUTES.CLIENT_SETTINGS_NOTIFICATIONS]: 'Settings',
  [ROUTES.SETTINGS_PROFILE]: 'Settings',
  [ROUTES.SETTINGS_SECURITY]: 'Settings',
  [ROUTES.HOMEPAGE]: 'Dashboard',
  [ROUTES.SUBMIT_REPORTING]: 'Reporting',
  [ROUTES.ACCOUNT_ACTIVITY]: 'Activity',
  [ROUTES.NOTIFICATIONS]: 'Notifications',
  [ROUTES.CLIENT_VISUALIZATIONS]: 'Analytics',
  [ROUTES.MARKET_NEWS]: 'Market News',
  [ROUTES.CLIENT_SUBMIT_REPORTING_PAGE]: 'Ongoing Reporting',
}
