import React, { useMemo } from 'react'

import { IBBCLog } from '@common/interfaces/bbcLog'

const BBCLogsItemContentUsers = ({ bbcLog }: { bbcLog: IBBCLog }) => {
  const users = useMemo(
    () => (bbcLog.users || []).map(({ user }) => user).filter(Boolean),
    [bbcLog],
  )

  return (
    <React.Fragment>
      {users.map((user, index) => (
        <React.Fragment key={user.id}>
          {user.firstName}
          {index === Object.keys(bbcLog.users).length - 2
            ? ' and '
            : index !== Object.keys(bbcLog.users).length - 1
            ? ', '
            : ''}
        </React.Fragment>
      ))}
    </React.Fragment>
  )
}

export default BBCLogsItemContentUsers
