import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'

import NotesContainer from './NotesContainer'

import { isNotesShown, notes, isLoading, summary } from '../../redux/notes/selectors'
import { isBDO } from '../../redux/profile/selectors'

import { listNotes, hideNotes, summarizeNotes } from '../../redux/notes/actions'

const selector = createStructuredSelector({
  isNotesShown,
  notesData: notes,
  isLoading,
  summary,
  isBDO,
})

const actions = {
  listNotes,
  hideNotes,
  summarizeNotes,
}

export default connect(selector, actions)(NotesContainer)
