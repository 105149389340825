import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'

import DueDiligenceCapTableMappingPage from './DueDiligenceCapTableMappingPage'

import { dueDiligenceInfo } from '../../redux/dueDiligence/selectors'
import { capTableMapping } from '../../redux/capTable/selectors'
import { listMapping } from '../../redux/capTable/actions'
import { show } from '../../redux/dueDiligence/actions'

//import {  } from '../../redux/dueDiligence/actions'
// import {  } from '../../redux/entityInfo/actions'
import { updateAlias } from '../../redux/arapmapping/actions'

const selector = createStructuredSelector({
  dueDiligenceInfo,
  capTableMapping,
})
const actions = {
  updateAlias,
  listMapping,
  show,
}

export default connect(selector, actions)(DueDiligenceCapTableMappingPage)
