import React, { useCallback, useEffect, useMemo, useState } from 'react'
import InfiniteScroll from 'react-infinite-scroll-component'
import { Form } from 'react-final-form'

import styles from './GlobalSearchPage.module.scss'

import { ISearchResult, SearchType } from '@common/interfaces/search'
import { IFilter, PER_PAGE } from '@common/constants/filters'
import { INoteMapping } from '@common/interfaces/notes'
import TableContainer from '../../components/Common/TableContainer'
import Table from '../../components/Common/Table'
import TableHead from '../../components/Common/TableHead'
import TableBody from '../../components/Common/TableBody'
import TableLoader from '../../components/Common/TableLoader'
import GlobalSearchPageTableRow from './GlobalSearchPageTableRow'
import GlobalSearchPageNote from './GlobalSearchPageNote'
import { GLOBAL_SEARCH_TYPE_COLUMNS } from './GlobalSearchPage'
import TableFiltersRow from '../../components/Common/TableFiltersRow'
import FilterContainer from '../../components/Filters/FilterContainer'
import { debounceEventHandler } from '../../helpers/helpers'

const GlobalSearchPageFullscreenSection = ({
  type,
  searchString,
  search,
}: {
  type: SearchType
  searchString: string
  search: (data: object) => Promise<{ data: ISearchResult[] }>
}) => {
  const [isLoading, setIsLoading] = useState(true)
  const [searchResult, setSearchResult] = useState<ISearchResult>(null)
  const columns: IFilter[] = useMemo(() => GLOBAL_SEARCH_TYPE_COLUMNS[type] || [], [type])

  const [filters, setFilters] = useState({ fullSearch: '' })
  const [orderBy, setOrderBy] = useState({
    field: null,
    direction: 'ASC',
  })

  const fetchSearchResults = useCallback(
    async (data: any) => {
      const res = await search(data)
      const searchResult = res.data[0]
      setSearchResult(searchResult)
      setIsLoading(false)
    },
    [search],
  )

  const debounceFetchSearchResults = useMemo(
    () => debounceEventHandler(fetchSearchResults, 500),
    [fetchSearchResults],
  )

  useEffect(() => {
    if (type) {
      debounceFetchSearchResults({
        type,
        page: 0,
        perPage: PER_PAGE,
        search: searchString,
        filters: {
          fullSearch: filters?.fullSearch,
        },
        orderBy: orderBy.field,
        orderDirection: orderBy.direction,
      })
    }
  }, [debounceFetchSearchResults, type, searchString, filters, orderBy])

  const loadMore = useCallback(async () => {
    const res = await search({
      type,
      page: Math.ceil(searchResult?.items.length / PER_PAGE),
      perPage: PER_PAGE,
      search: searchString,
      filters: {
        fullSearch: filters?.fullSearch,
      },
      orderBy: orderBy.field,
      orderDirection: orderBy.direction,
    })
    const result = res.data[0]
    setSearchResult((searchResult) => ({
      ...searchResult,
      items: [...searchResult.items, ...result.items],
    }))
  }, [search, type, searchString, searchResult, filters, orderBy])

  const handleOrderChange = useCallback((field: string) => {
    setOrderBy((order) => ({
      field,
      direction: order.field === field ? (order.direction === 'DESC' ? 'ASC' : 'DESC') : 'ASC',
    }))
  }, [])

  const handleFiltersChange = useCallback((data: any) => {
    setFilters(data)
  }, [])

  const searchStringWithAdditionalSearch = useMemo(
    () => [searchString, filters?.fullSearch].filter(Boolean).join(' '),
    [searchString, filters],
  )

  if (type === SearchType.Note) {
    return (
      <div>
        <Form
          onSubmit={handleFiltersChange}
          initialValues={filters}
          mutators={{
            setFieldData: ([field, value], state, { changeValue }) => {
              changeValue(state, field, () => value)
            },
          }}
          render={({ values, handleSubmit, form: { mutators } }) => (
            <div className={styles.filtersHeader}>
              <FilterContainer
                filters={columns}
                handleSubmit={handleSubmit}
                mutators={mutators}
                values={values}
                appliedFilters={filters}
              />
            </div>
          )}
        />
        <div className={styles.notesWrapper} id="scrollableNotes">
          {searchResult?.items?.length > 0 && (
            <InfiniteScroll
              dataLength={searchResult.items.length}
              next={loadMore}
              hasMore={searchResult.items.length < searchResult.total}
              loader="Loading..."
              scrollableTarget="scrollableNotes"
              className={styles.notesContainer}
            >
              {searchResult.items.map((item) => (
                <GlobalSearchPageNote
                  key={item.id}
                  note={item.item as INoteMapping}
                  searchString={searchStringWithAdditionalSearch}
                />
              ))}
            </InfiniteScroll>
          )}
        </div>
      </div>
    )
  }

  return (
    <TableContainer className={styles.table}>
      <Form
        onSubmit={handleFiltersChange}
        initialValues={filters}
        mutators={{
          setFieldData: ([field, value], state, { changeValue }) => {
            changeValue(state, field, () => value)
          },
        }}
        render={({ values, handleSubmit, form: { mutators } }) => (
          <div className={styles.filtersHeader}>
            <FilterContainer
              filters={columns}
              handleSubmit={handleSubmit}
              mutators={mutators}
              values={values}
              appliedFilters={filters}
            />
          </div>
        )}
      />
      <Table>
        <TableHead>
          <TableFiltersRow
            filters={columns}
            orderBy={orderBy}
            handleOrderChange={handleOrderChange}
          />
        </TableHead>
        <TableBody id="scrollableTable">
          {isLoading ? (
            <TableLoader columnsCount={columns.length} rowsCount={3} />
          ) : (
            searchResult?.items?.length > 0 && (
              <InfiniteScroll
                dataLength={searchResult.items.length}
                next={loadMore}
                hasMore={searchResult.items.length < searchResult.total}
                loader={<TableLoader columnsCount={columns.length} rowsCount={1} />}
                scrollableTarget="scrollableTable"
              >
                {searchResult.items.map((item) => (
                  <GlobalSearchPageTableRow
                    key={item.id}
                    item={item}
                    searchString={searchStringWithAdditionalSearch}
                  />
                ))}
              </InfiniteScroll>
            )
          )}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export default GlobalSearchPageFullscreenSection
