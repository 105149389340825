import React, { useCallback, useState, useEffect, useRef, useMemo } from 'react'
import Tooltip from '@mui/material/Tooltip'
import { ErrorCode, FileRejection, useDropzone } from 'react-dropzone'
import cn from 'classnames'

import styles from './DueDiligenceDocumentUpload.module.scss'
import genericSs from '@styles/generic.module.scss'
import { ReactComponent as WarningIcon } from '@assets/images/warning-icon-outline.svg'
import {
  DUE_DILIGENCE_DOCUMENTS_TYPES_WITH_TEMPLATES,
  DUE_DILIGENCE_DOCUMENTS_TYPES_WITHOUT_UPLOAD,
  IDueDiligenceDocumentRequest,
} from '@common/interfaces/dueDiligence'
import WarningModal from '../WarningModal'
import UploadFileManagement from '../Common/UploadFileManagement'
import { DEFAULT_MAX_FILE_SIZE } from '../../constants/common'
import { IFile } from '@common/interfaces/box'
import Modal from '../Common/Modal'
import { humanReadableFileSize } from '@common/helpers/helpers'

interface IProps {
  readOnly?: boolean
  documentRequest: IDueDiligenceDocumentRequest
  handleUploadFiles: (files: File[]) => void
  handleDeleteFiles: (ids: string[]) => Promise<any>
  handleViewSample: (id: string) => void
  handleClose: () => void
  putNotification: (notification: object) => void
}

const DueDiligenceDocumentUpload = ({
  readOnly = false,
  documentRequest,
  handleUploadFiles,
  handleDeleteFiles,
  handleViewSample,
  handleClose,
  putNotification,
}: IProps) => {
  const documentsWrapperRef = useRef(null)

  const [isDocumentsExpanded, setIsDocumentsExpanded] = useState(false)

  const [filesForDelete, setFilesForDelete] = useState<IFile[]>([])
  const [isDeleting, setIsDeleting] = useState(false)

  const scrollSectionsToBottom = useCallback(() => {
    setTimeout(() => {
      if (documentsWrapperRef.current) {
        documentsWrapperRef.current.scrollTop = documentsWrapperRef.current.scrollHeight
      }
    }, 400)
  }, [])

  useEffect(() => {
    if (isDocumentsExpanded) {
      scrollSectionsToBottom()
    }
  }, [isDocumentsExpanded, scrollSectionsToBottom])

  useEffect(() => {
    scrollSectionsToBottom()
  }, [documentRequest?.files, scrollSectionsToBottom])

  useEffect(() => {
    setTimeout(() => {
      scrollSectionsToBottom()
    }, 400)
  }, [scrollSectionsToBottom])

  const modifiedOnUpload = useCallback(
    async (files: File[]) => {
      await handleUploadFiles(files)
      setIsDocumentsExpanded(true)
    },
    [handleUploadFiles],
  )

  const handleFileRejection = useCallback(
    (fileRejection: FileRejection[]) => {
      const firstError = fileRejection?.[0]?.errors?.[0]
      if (firstError) {
        putNotification({
          code: firstError.code,
          message:
            firstError.code === ErrorCode.FileTooLarge
              ? `File is too large, the maximum file size is ${humanReadableFileSize(
                  DEFAULT_MAX_FILE_SIZE,
                ).join(' ')}`
              : firstError.message,
          type: 'error',
        })
      }
    },
    [putNotification],
  )

  const { getRootProps, getInputProps, open, isDragAccept, isDragReject } = useDropzone({
    noClick: true,
    maxSize: DEFAULT_MAX_FILE_SIZE,
    maxFiles: 99,
    multiple: true,
    noDragEventsBubbling: true,
    onDropAccepted: modifiedOnUpload,
    onDropRejected: handleFileRejection,
  })

  const onDeleteFiles = useCallback(
    (indexes: number[]) => {
      const files = documentRequest?.files || []
      setFilesForDelete(
        indexes
          .sort((a, b) => b - a)
          .map((number, index) => files[number === undefined ? index : number]),
      )
    },
    [documentRequest],
  )

  const handleDeleteFilesConfirm = useCallback(async () => {
    setIsDeleting(true)
    await handleDeleteFiles(filesForDelete.map((file) => file.id))
    setFilesForDelete([])
    setIsDeleting(false)
  }, [handleDeleteFiles, filesForDelete])

  const handleDeleteFilesCancel = useCallback(async () => {
    setFilesForDelete([])
  }, [])

  const handleViewSampleClick = useCallback(() => {
    if (DUE_DILIGENCE_DOCUMENTS_TYPES_WITH_TEMPLATES.includes(documentRequest?.type?.type)) {
      handleViewSample(documentRequest.id)
    }
  }, [handleViewSample, documentRequest])

  const isCustom = useMemo(
    () => DUE_DILIGENCE_DOCUMENTS_TYPES_WITHOUT_UPLOAD.includes(documentRequest?.type?.type),
    [documentRequest],
  )

  return (
    <Modal
      open
      size="small"
      onCancel={handleClose}
      title="Upload file"
      classes={{
        root: styles.editModal,
        footer: styles.editModalFooter,
        title: styles.editModalTitle,
      }}
    >
      {documentRequest && (
        <>
          {!isCustom && (
            <>
              {documentRequest.type.helperText && (
                <div className={genericSs.warningMessage}>{documentRequest.type.helperText}</div>
              )}
              {documentRequest.type.fileType && (
                <div className={genericSs.warningMessage}>
                  {documentRequest.type.fileType} format is required for this document
                </div>
              )}

              {!readOnly && (
                <UploadFileManagement
                  className={styles.uploadSection}
                  files={documentRequest?.files}
                  onDelete={onDeleteFiles}
                  isDragAccept={isDragAccept}
                  isDragReject={isDragReject}
                  getRootProps={getRootProps}
                  getInputProps={getInputProps}
                  open={open}
                  handleDelete={onDeleteFiles}
                  dropzoneText="Drop files here or "
                  showFiles={false}
                />
              )}
              {!readOnly && (
                <div className={styles.uploadHeader}>
                  {DUE_DILIGENCE_DOCUMENTS_TYPES_WITH_TEMPLATES.includes(
                    documentRequest.type.type,
                  ) && (
                    <Tooltip placement="top" title="View Sample">
                      <div onClick={handleViewSampleClick} className={styles.templateLink}>
                        View Sample
                      </div>
                    </Tooltip>
                  )}
                </div>
              )}
            </>
          )}

          <div className={styles.documentsAndCommentsSection}>
            {!isCustom && (
              <>
                <div
                  ref={documentsWrapperRef}
                  className={cn(styles.documentsSection, {
                    [styles.documentsSectionExpanded]: isDocumentsExpanded,
                  })}
                >
                  {documentRequest?.files.length > 0 ? (
                    <UploadFileManagement
                      files={documentRequest?.files}
                      onDelete={onDeleteFiles}
                      isDragAccept={isDragAccept}
                      isDragReject={isDragReject}
                      getRootProps={getRootProps}
                      getInputProps={getInputProps}
                      open={open}
                      handleDelete={onDeleteFiles}
                      dropzoneText="Drop files here or "
                      showDropzone={false}
                      readOnly={readOnly}
                    />
                  ) : (
                    <div className={styles.commentsEmpty}>
                      <WarningIcon />
                      There are no documents uploaded
                    </div>
                  )}
                </div>
              </>
            )}
          </div>

          {filesForDelete.length > 0 && (
            <WarningModal
              warningMessage={
                filesForDelete.length > 1
                  ? 'Deleting these files will be permanent.'
                  : 'Deleting this file will be permanent.'
              }
              onConfirm={handleDeleteFilesConfirm}
              onCancel={handleDeleteFilesCancel}
              confirmText="Yes, proceed"
              cancelText="Cancel"
              isLoading={isDeleting}
              disabled={isDeleting}
            />
          )}
        </>
      )}
    </Modal>
  )
}

export default DueDiligenceDocumentUpload
