import { inventoryDistributionCategories } from './../../redux/bbc/selectors'
import { listInventoryDistributionAnalysisCategories } from './../../redux/bbc/actions'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'

import BBCInventoryDistributionTable from './BBCInventoryDistributionTable'

import { listInventoryDistributionAnalysis, getBBCSummaryStats } from '../../redux/bbc/actions'
import { isLoading as isLoadingBBC, inventoryDistributionData } from '../../redux/bbc/selectors'

const selector = createStructuredSelector({
  isLoadingBBC,
  inventoryDistributionData,
  inventoryDistributionCategories,
})

const actions = {
  listInventoryDistributionAnalysis,
  listInventoryDistributionAnalysisCategories,
  getBBCSummaryStats,
}

export default connect(selector, actions)(BBCInventoryDistributionTable)
