import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import FundingWiresTable from './FundingWiresTable'
import { fundingWiresData } from '../../redux/outgoingWire/selectors'
import { clients } from '../../redux/clientInfo/selectors'
import { isAdminRightsRole } from '../../redux/profile/selectors'
import { list as listClients } from '../../redux/clientInfo/actions'
import { listOutgoingWire, sentOutgoingWires } from '../../redux/outgoingWire/actions'
import { loanBalanceStartDate } from '../../redux/loanBalance/selectors'
import { putNotification } from '../../redux/ui/actions'
import { updateBBC } from '../../redux/bbc/actions'
import { updateActivity } from '../../redux/activity/actions'

const selector = createStructuredSelector({
  isAdminRightsRole,
  fundingWiresData,
  clients,
  loanBalanceStartDate,
})

const actions = {
  listClients,
  listOutgoingWire,
  sentOutgoingWires,
  putNotification,
  updateBBC,
  updateActivity,
}

export default connect(selector, actions)(FundingWiresTable)
