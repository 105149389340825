import React from 'react'
import Box from '@mui/material/Box'
import { useSetPageTitle } from '../../hooks/useSetPageTitle'
import ParticipantsTable from '../../components/ParticipantsTable'

const ParticipantManagementPage = () => {
  useSetPageTitle('Participants')

  return (
    <Box py={1}>
      <ParticipantsTable />
    </Box>
  )
}

export default ParticipantManagementPage
