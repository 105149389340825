import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'

import DueDiligenceApplicationPage from './DueDiligenceClientFinancialsPage'

import { dueDiligenceInfo } from '../../redux/dueDiligence/selectors'
import { show } from '../../redux/dueDiligence/actions'

const selector = createStructuredSelector({
  dueDiligenceInfo,
})
const actions = {
  show,
}

export { DueDiligenceApplicationPage }

export default connect(selector, actions)(DueDiligenceApplicationPage)
