import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'

import DueDiligenceTeamExecutives from './DueDiligenceTeamExecutives'

import { dueDiligenceTeamExecutives } from '../../redux/dueDiligence/selectors'
import {
  listTeamExecutives,
  hideTeamExecutives,
  addTeamExecutive,
  updateTeamExecutive,
  deleteTeamExecutive,
} from '../../redux/dueDiligence/actions'

const selector = createStructuredSelector({
  dueDiligenceTeamExecutives,
})
const actions = {
  listTeamExecutives,
  hideTeamExecutives,
  addTeamExecutive,
  updateTeamExecutive,
  deleteTeamExecutive,
}

export default connect(selector, actions)(DueDiligenceTeamExecutives)
