import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'

import InvestmentSummaryTable from './InvestmentSummaryTable'
import { listInvestorSummary } from '../../redux/entityInfo/actions'
import { investmentSummary } from '../../redux/entityInfo/selectors'
import { clientInfo } from '../../redux/clientInfo/selectors'

const selector = createStructuredSelector({
  clientInfo,
  investmentSummary,
})
const actions = {
  listInvestorSummary,
}

export { InvestmentSummaryTable }

export default connect(selector, actions)(InvestmentSummaryTable)
