import React, { useCallback, useState } from 'react'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'

import styles from './ClientAccountActivity.module.scss'
import genericSs from '@styles/generic.module.scss'

import { IAccountActivity } from '@common/interfaces/client'
import TableCell from '../../Common/TableCell'
import { MenuIcon } from '../../Common/Icons'

interface IProps {
  item: IAccountActivity
  onEdit: (item: IAccountActivity) => void
  onDelete: (item: IAccountActivity) => void
  onRestore: (item: IAccountActivity) => void
}

const ClientAccountActivityAction = ({ item, onEdit, onDelete, onRestore }: IProps) => {
  const [anchorEl, setAnchorEl] = useState(null)
  const [menuOpen, setMenuOpen] = useState(false)

  const handleClickMenu = useCallback((event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    event.preventDefault()
    event.stopPropagation()
    setAnchorEl(event.currentTarget)
    setMenuOpen(true)
  }, [])

  const handleCloseMenu = useCallback(() => {
    setAnchorEl(null)
    setMenuOpen(false)
  }, [])

  const handleEdit = useCallback(() => {
    onEdit(item)
    handleCloseMenu()
  }, [item, onEdit, handleCloseMenu])

  const handleDelete = useCallback(() => {
    onDelete(item)
    handleCloseMenu()
  }, [item, onDelete, handleCloseMenu])

  const handleRestore = useCallback(() => {
    onRestore(item)
    handleCloseMenu()
  }, [item, onRestore, handleCloseMenu])

  if (!item.isEditable && !item.isDeletable) {
    return <TableCell />
  }

  return (
    <TableCell className={genericSs.tableTextRight}>
      <MenuIcon onClick={handleClickMenu} isActive={menuOpen} size="small" />
      <Menu
        classes={{ paper: styles.iconMenu }}
        open={menuOpen}
        onClose={handleCloseMenu}
        anchorEl={anchorEl}
      >
        {item.isEditable && (
          <MenuItem classes={{ root: styles.iconMenuItem }} onClick={handleEdit}>
            Edit
          </MenuItem>
        )}
        {item.isDeletable && !item.isDeleted && (
          <MenuItem classes={{ root: styles.iconMenuItem }} onClick={handleDelete}>
            Delete
          </MenuItem>
        )}
        {item.isDeletable && item.isDeleted && (
          <MenuItem classes={{ root: styles.iconMenuItem }} onClick={handleRestore}>
            Restore
          </MenuItem>
        )}
      </Menu>
    </TableCell>
  )
}

export default ClientAccountActivityAction
