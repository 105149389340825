import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'

import ClientTermLoans from './ClientTermLoans'

import {
  listTermLoans,
  createTermLoan,
  updateTermLoan,
  deleteTermLoan,
  listAmortizationSchedule,
} from '../../redux/clientInfo/actions'
import { putNotification } from '../../redux/ui/actions'
import { clientInfo, termLoans, isLoading } from '../../redux/clientInfo/selectors'
import { isAdminRightsRole } from '../../redux/profile/selectors'
import { loanBalanceStartDate } from '../../redux/loanBalance/selectors'

const selector = createStructuredSelector({
  clientInfo,
  termLoans,
  isAdminRightsRole,
  loanBalanceStartDate,
  isLoading,
})

const actions = {
  listTermLoans,
  createTermLoan,
  putNotification,
  updateTermLoan,
  deleteTermLoan,
  listAmortizationSchedule,
}

export default connect(selector, actions)(ClientTermLoans)
