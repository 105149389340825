import { combineReducers } from 'redux'
import * as actions from './actions'
import {
  IAccount,
  IAccountSubIndustry,
  IAccountIndustry,
  IAccountSalesChannel,
  IInvestor,
} from '@common/interfaces/salesforce'

interface IAccountDependenciesState {
  industries?: IAccountIndustry[]
  subIndustries?: IAccountSubIndustry[]
  salesChannels?: IAccountSalesChannel[]
}

const initialAccountDependenciesState: IAccountDependenciesState = {
  industries: [] as IAccountIndustry[],
  subIndustries: [] as IAccountSubIndustry[],
  salesChannels: [] as IAccountSalesChannel[],
}

export interface ISalesforceState {
  loadingArray: string[]
  accounts: IAccount[]
  account: IAccount
  investors: IInvestor[]
  accountDependencies: {
    industries?: IAccountIndustry[]
    subIndustries?: IAccountSubIndustry[]
    salesChannels?: IAccountSalesChannel[]
  }
}

export default combineReducers<ISalesforceState>({
  loadingArray(state: string[] = [], action) {
    switch (action.type) {
      case actions.LIST_ACCOUNTS_REQUEST:
      case actions.SHOW_ACCOUNT_REQUEST:
      case actions.UPDATE_ACCOUNT_REQUEST:
        return [...state, action.type]
      case actions.LIST_ACCOUNTS_SUCCESS:
      case actions.LIST_ACCOUNTS_FAILURE:
      case actions.LIST_INVESTORS_SUCCESS:
      case actions.LIST_INVESTORS_FAILURE:
      case actions.SHOW_ACCOUNT_SUCCESS:
      case actions.SHOW_ACCOUNT_FAILURE:
      case actions.UPDATE_ACCOUNT_SUCCESS:
      case actions.UPDATE_ACCOUNT_FAILURE:
        return state.filter(
          (item) =>
            item !== action.type.replace('SUCCESS', 'REQUEST').replace('FAILURE', 'REQUEST'),
        )
      default:
        return state
    }
  },
  accounts(state: IAccount[] = [], action) {
    switch (action.type) {
      case actions.LIST_ACCOUNTS_SUCCESS:
        return action.data
      default:
        return state
    }
  },
  account(state: IAccount = null, action) {
    switch (action.type) {
      case actions.SHOW_ACCOUNT_SUCCESS:
      case actions.UPDATE_ACCOUNT_SUCCESS:
        return action.data
      case actions.HIDE_ACCOUNT:
        return null
      default:
        return state
    }
  },
  accountDependencies(state: IAccountDependenciesState = initialAccountDependenciesState, action) {
    switch (action.type) {
      case actions.DESCRIBE_ACCOUNTS_SUCCESS:
        return action.data
      default:
        return state
    }
  },
  investors(state: IInvestor[] = [], action) {
    switch (action.type) {
      case actions.LIST_INVESTORS_SUCCESS:
        return [...action.data, { id: 'Individual', name: 'Individual' }]
      default:
        return state
    }
  },
})
