import React from 'react'
import cn from 'classnames'

import styles from './AppIntegrations.module.scss'

import Button from '../../Common/Button'
import { ReactComponent as AppIntegrationsImage } from '../../../assets/images/app-integrations.svg'

interface IProps {
  handleGoBack: (data: any) => void
  handleAppIntegrations: () => void
  handleSkipAppIntegrations: () => void
}

const AppIntegrations = ({
  handleGoBack,
  handleAppIntegrations,
  handleSkipAppIntegrations,
}: IProps) => {
  return (
    <div className={styles.formWrapper}>
      <div className={styles.formContent}>
        <AppIntegrationsImage />

        <Button
          className={cn(styles.button, styles.integrateButton)}
          variant="contained"
          onClick={handleAppIntegrations}
        >
          Connect to auto-reporting
        </Button>
      </div>

      <div className={styles.buttonDivider} />

      <div className={styles.buttonWrapper}>
        <Button className={styles.button} variant="outlined" onClick={handleGoBack}>
          Back
        </Button>

        <Button
          className={cn(styles.button, styles.skipButton)}
          onClick={handleSkipAppIntegrations}
        >
          Skip
        </Button>
      </div>
    </div>
  )
}

export default AppIntegrations
