import React, { useMemo } from 'react'
import Box from '@mui/material/Box'

import PasswordMeter from '../PasswordMeter'
import cn from 'classnames'

import styles from './StrongPasswordCheck.module.scss'

import { ReactComponent as CheckIcon } from '../../assets/images/check-mark-icon.svg'

interface IProps {
  password?: string
}

const RULES = [
  {
    id: 'atLeast8Characters',
    label: 'At least 8 characters',
    check: (password: any) => password && password.length >= 8,
  },
  {
    id: 'atLeastSmallLetter',
    label: 'At least 1 lowercase letter',
    check: (password: any) => password && !!password.match(/[a-z]/),
  },
  {
    id: 'atLeastCapitalLetter',
    label: 'At least 1 uppercase letter',
    check: (password: any) => password && !!password.match(/[A-Z]/),
  },
  {
    id: 'atLeastNumber',
    label: 'At least 1 number',
    check: (password: any) => password && !!password.match(/\d/),
  },
  {
    id: 'atLeastSymbol',
    label: 'At least 1 special character (e.g., ! @ # ?)',
    check: (password: any) => password && !!password.match(/[^A-Za-z0-9]/),
  },
]

const StrongPasswordCheck = ({ password }: IProps) => {
  const rules = useMemo(
    () =>
      RULES.map(({ id, check, label }) => ({
        id,
        label,
        isValid: check(password),
      })),
    [password],
  )
  const percent = useMemo(
    () => (rules.reduce((sum, { isValid }) => sum + (isValid ? 1 : 0), 0) / rules.length) * 100,
    [rules],
  )

  return (
    <Box className={styles.wrapper}>
      <PasswordMeter percent={percent} />

      <ul className={styles.list}>
        {rules.map(({ id, label, isValid }) => (
          <li key={id} className={cn({ [styles.isValid]: isValid })}>
            <div className={styles.checkSpot}>
              <CheckIcon className={styles.checkIcon} />
            </div>
            {label}
          </li>
        ))}
      </ul>
    </Box>
  )
}

export default StrongPasswordCheck
