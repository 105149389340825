import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'

import DueDiligenceFinancialsInventoryLocations from './DueDiligenceFinancialsInventoryLocations'

import { dueDiligenceFinancialsInventoryLocations } from '../../redux/dueDiligence/selectors'
import {
  listFinancialsInventoryLocations,
  hideFinancialsInventoryLocations,
  addFinancialsInventoryLocation,
  updateFinancialsInventoryLocation,
  deleteFinancialsInventoryLocation,
} from '../../redux/dueDiligence/actions'

const selector = createStructuredSelector({
  dueDiligenceFinancialsInventoryLocations,
})
const actions = {
  listFinancialsInventoryLocations,
  hideFinancialsInventoryLocations,
  addFinancialsInventoryLocation,
  updateFinancialsInventoryLocation,
  deleteFinancialsInventoryLocation,
}

export default connect(selector, actions)(DueDiligenceFinancialsInventoryLocations)
