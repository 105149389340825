import React, { useCallback } from 'react'

import {
  DueDiligenceTableItemType,
  IDueDiligenceInventoryLocation,
} from '@common/interfaces/dueDiligence'
import DueDiligenceTable from '../DueDiligenceTable'
import { ILoadingData } from '../../redux/types'

interface IProps {
  dueDiligenceFinancialsInventoryLocations: ILoadingData<{ data: IDueDiligenceInventoryLocation[] }>
  listFinancialsInventoryLocations: (params: object) => void
  hideFinancialsInventoryLocations: () => void
  addFinancialsInventoryLocation: (data: object) => Promise<void>
  updateFinancialsInventoryLocation: (id: string, data: object) => Promise<void>
  deleteFinancialsInventoryLocation: (id: string) => Promise<void>
}

const DueDiligenceFinancialsInventoryLocations = ({
  dueDiligenceFinancialsInventoryLocations,
  listFinancialsInventoryLocations,
  hideFinancialsInventoryLocations,
  addFinancialsInventoryLocation,
  updateFinancialsInventoryLocation,
  deleteFinancialsInventoryLocation,
}: IProps) => {
  const handleAddInventoryLocation = useCallback(
    async (data: any) => {
      const { address, businessAddress, ...rest } = data

      return addFinancialsInventoryLocation({
        country: businessAddress?.country || '',
        state: businessAddress?.state || '',
        city: businessAddress?.city || '',
        postalCode: businessAddress?.postalCode || '',
        street: businessAddress?.street || '',
        ...rest,
      })
    },
    [addFinancialsInventoryLocation],
  )
  const handleUpdateInventoryLocation = useCallback(
    async (id: string, data: any) => {
      const {
        name,
        type,
        landlord,
        address,
        businessAddress,
        finishedGoodsAmount,
        totalInventoryAmount,
        ...rest
      } = data

      return updateFinancialsInventoryLocation(id, {
        name: name || '',
        type: type || '',
        landlord: landlord || '',
        country: businessAddress?.country || '',
        state: businessAddress?.state || '',
        city: businessAddress?.city || '',
        postalCode: businessAddress?.postalCode || '',
        street: businessAddress?.street || '',
        finishedGoodsAmount: finishedGoodsAmount || 0,
        totalInventoryAmount: totalInventoryAmount || 0,
        ...rest,
      })
    },
    [updateFinancialsInventoryLocation],
  )

  return (
    <DueDiligenceTable
      type={DueDiligenceTableItemType.InventoryLocation}
      data={dueDiligenceFinancialsInventoryLocations}
      handleList={listFinancialsInventoryLocations}
      handleHide={hideFinancialsInventoryLocations}
      handleAdd={handleAddInventoryLocation}
      handleUpdate={handleUpdateInventoryLocation}
      handleDelete={deleteFinancialsInventoryLocation}
    />
  )
}

export default DueDiligenceFinancialsInventoryLocations
