import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import NotesFullScreenModal from './NotesFullScreenModal'
import { notes, isLoading } from '../../../redux/notes/selectors'
import { addNote } from '../../../redux/notes/actions'
import { listNotes, exportNotes, updateNote } from '../../../redux/notes/actions'

const selector = createStructuredSelector({
  notesData: notes,
  isLoading,
})

const actions = {
  listNotes,
  addNote,
  exportNotes,
  updateNote,
}

export default connect(selector, actions)(NotesFullScreenModal)
