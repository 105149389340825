import React, { useEffect, useMemo, useState } from 'react'
import Skeleton from '@mui/material/Skeleton'
import { Chart } from 'react-chartjs-2'
import cn from 'classnames'

import styles from './BankTransactions.module.scss'

import { ReportingPeriods } from '@common/interfaces/bbc'
import { formatPrice } from '../../helpers/helpers'
import {
  BORDER_RADIUS,
  COLORS,
  FONT_SIZE,
  GRAPH_COLOR_OTHER,
  TOOLTIP,
} from '../../constants/graphs'
import {
  BANK_TRANSACTIONS_GRAPH_OPTION,
  BankTransactionsGraphOption,
  CATEGORIES_LABELS,
  IBankTransactionGraph,
} from '@common/interfaces/bankTransactions'

interface IProps {
  isModalShown: boolean
  bankTransactionsGraphHeader: string[]
  bankTransactionsGraphData: IBankTransactionGraph[]
  bankTransactionsGraphOption: BankTransactionsGraphOption
  option: BankTransactionsGraphOption
  reportingPeriod: ReportingPeriods
}

const BankTransactionsGraph = ({
  isModalShown,
  bankTransactionsGraphHeader,
  bankTransactionsGraphData,
  bankTransactionsGraphOption,
  option,
  reportingPeriod,
}: IProps) => {
  const [isInitialLoading, setIsInitialLoading] = useState(true)

  useEffect(() => {
    if (bankTransactionsGraphData) {
      setIsInitialLoading(false)
    }
  }, [bankTransactionsGraphData])

  const chartData = useMemo(() => {
    if (bankTransactionsGraphData && bankTransactionsGraphOption === option) {
      return {
        labels: bankTransactionsGraphHeader.map((header: string) =>
          reportingPeriod === ReportingPeriods.Monthly
            ? header.replace(/20\d{2}/g, (year) => year.slice(2))
            : header,
        ),
        datasets: bankTransactionsGraphData.map((item, index) => ({
          label:
            option === BANK_TRANSACTIONS_GRAPH_OPTION.Category && CATEGORIES_LABELS[item.category]
              ? CATEGORIES_LABELS[item.category]
              : item[option],
          data: item.amounts,
          backgroundColor: COLORS[index] || GRAPH_COLOR_OTHER,
          borderRadius: BORDER_RADIUS,
        })),
      }
    } else {
      return null
    }
  }, [
    bankTransactionsGraphHeader,
    bankTransactionsGraphData,
    bankTransactionsGraphOption,
    option,
    reportingPeriod,
  ])

  const chartOptions = useMemo(() => {
    return {
      maintainAspectRatio: false,
      scales: {
        x: {
          stacked: true,
          grid: {
            display: false,
          },
          ticks: {
            font: {
              size: FONT_SIZE,
            },
          },
        },
        y: {
          stacked: true,
          grid: {
            display: false,
          },
          ticks: {
            font: {
              size: FONT_SIZE,
            },
            callback: (value: any) => {
              return formatPrice(value) ? `$${formatPrice(value)}` : `$0`
            },
          },
        },
      },
      plugins: {
        legend: {
          position: 'top' as const,
          labels: {
            font: {
              size: FONT_SIZE,
              weight: '500',
            },
            color: 'black',
            boxWidth: 20,
            boxHeight: 20,
            sort: (a: any, b: any) => {
              return a.datasetIndex - b.datasetIndex
            },
          },
        },
        tooltip: {
          ...TOOLTIP,
          itemSort: (a: any, b: any) => {
            return a.datasetIndex - b.datasetIndex
          },
          callbacks: {
            label: (tooltipItem: any) => {
              const datasetIndex = tooltipItem.datasetIndex
              const dataIndex = tooltipItem.dataIndex
              const originalValue = chartData.datasets[datasetIndex].data[dataIndex]
              const title = chartData.datasets[datasetIndex].label

              return `${title}: $${formatPrice(originalValue || 0)}`
            },
          },
        },
      },
    }
  }, [chartData])

  return (
    <div
      className={cn(styles.chartContainer, {
        [styles.fullScreen]: isModalShown,
      })}
    >
      {isInitialLoading ? (
        <Skeleton variant="rectangular" width="100%" height="100%" />
      ) : (
        chartData && <Chart type="bar" data={chartData} options={chartOptions} />
      )}
    </div>
  )
}

export default BankTransactionsGraph
