import React from 'react'
import Box from '@mui/material/Box'

import styles from './BlankPage.module.scss'

const BlankPage = () => {
  return (
    <Box
      p={3}
      className={styles.container}
      display="flex"
      flexDirection="column"
      overflow="hidden"
    />
  )
}

export default BlankPage
