import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'

import DueDiligenceClientDocumentRequestsPage from './DueDiligenceClientDocumentRequestsPage'

import { dueDiligenceInfo, dueDiligenceDocumentRequests } from '../../redux/dueDiligence/selectors'
import {
  showDocumentRequests,
  uploadDocumentRequestsFiles,
  getDocumentRequestsSharedLink,
  createDocumentRequestComment,
  updateDocumentRequestComment,
  deleteDocumentRequestComment,
} from '../../redux/dueDiligence/actions'
import { deleteFile } from '../../redux/file/actions'

const selector = createStructuredSelector({
  dueDiligenceInfo,
  dueDiligenceDocumentRequests,
})

const actions = {
  showDocumentRequests,
  uploadDocumentRequestsFiles,
  getDocumentRequestsSharedLink,
  deleteFile,
  createDocumentRequestComment,
  updateDocumentRequestComment,
  deleteDocumentRequestComment,
}

export default connect(selector, actions)(DueDiligenceClientDocumentRequestsPage)
