import { connect } from 'react-redux'

import EntitySummaryDetailTable from './EntityReportSummaryDetailsTable'

import { getEntityArDetails, getEntityApDetails } from '../../redux/entityInfo/actions'

const actions = {
  getEntityArDetails,
  getEntityApDetails,
}

export default connect(null, actions)(EntitySummaryDetailTable)
