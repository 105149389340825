import Base from './base'

export default class AuditLog extends Base {
  show(id: string) {
    return this.apiClient.get(`participant/${id}`)
  }
  list(data?: object) {
    return this.apiClient.get('participants/list', data)
  }
  create(data: object) {
    return this.apiClient.post('participants', data)
  }
  listClientParticipations(id: string, data: object) {
    return this.apiClient.get(`client/${id}/participations`, data)
  }

  listAllParticipations(data: object) {
    return this.apiClient.get(`participants/all-participations`, data)
  }

  listParticipantParticipations(id: string, data: object) {
    return this.apiClient.get(`participants/${id}/participations`, data)
  }
  createParticipation(id: string, data: object) {
    return this.apiClient.post(`client/${id}/participations`, data)
  }
  updateParticipation(id: string, participationId: string, data: object) {
    return this.apiClient.put(`client/${id}/participations/${participationId}`, data)
  }
  updateParticipant(id: string, data: object) {
    return this.apiClient.put(`participants/${id}`, data)
  }
  terminateParticipation(id: string, participationId: string, data: object) {
    return this.apiClient.delete(`client/${id}/participations/${participationId}`, data)
  }
  createParticipationWire(id: string, participationId: string, data: object) {
    return this.apiClient.post(`client/${id}/participation/${participationId}/wire`, data)
  }
  updateParticipationWire(
    id: string,
    participationId: string,
    participationWireId: string,
    data: object,
  ) {
    return this.apiClient.put(
      `client/${id}/participation/${participationId}/wire/${participationWireId}`,
      data,
    )
  }
  deleteParticipationWire(
    id: string,
    participationId: string,
    participationWireId: string,
    data: object,
  ) {
    return this.apiClient.delete(
      `client/${id}/participation/${participationId}/wire/${participationWireId}`,
      data,
    )
  }
  listOutstandingWires(data: object) {
    return this.apiClient.get(`participations/outstanding-wires`, data)
  }
  exportParticipationActivity(id: string, params: object) {
    return this.apiClient.post(`client/${id}/participation-activity`, params, { isFile: true })
  }
}
