import { prefix } from './actions'
import { IRootState } from '../reducer'

export const isLoading = (state: IRootState) => state[prefix].loadingArray.length > 0

export const reporting = (state: IRootState) => state[prefix].reporting

export const prospectsAggregation = (state: IRootState) => state[prefix].prospectsAggregation

export const prospectReporting = (state: IRootState) => state[prefix].prospectReporting

export const prospectTerms = (state: IRootState) => state[prefix].prospectTerms

export const inventorySummaryInfo = (state: IRootState) => state[prefix].inventorySummaryInfo

export const cashFlowSummaryInfo = (state: IRootState) => state[prefix].cashFlowSummaryInfo

export const termStructureSummaryInfo = (state: IRootState) =>
  state[prefix].termStructureSummaryInfo

export const arSummary = (state: IRootState) => state[prefix].arSummary

export const apSummary = (state: IRootState) => state[prefix].apSummary

export const date = (state: IRootState) => state[prefix].date

export const refreshCount = (state: IRootState) => state[prefix].refreshCount
