import React, { useEffect } from 'react'
import LinearProgress, { LinearProgressProps } from '@mui/material/LinearProgress'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import cn from 'classnames'

import styles from './LinearProgressBar.module.scss'

const LinearProgressWithLabel = (
  props: LinearProgressProps & { value: number; isFullWidth?: boolean; isWithLabel?: boolean },
) => {
  return (
    <Box className={styles.container}>
      {props.isWithLabel && <Box sx={{ width: '100%', mr: 1 }}>Upload in progress</Box>}
      <Box
        className={cn(styles.progressBar, { [styles.progressBarFullWidth]: props.isFullWidth })}
        sx={{ display: 'flex', alignItems: 'center' }}
      >
        <Box sx={{ width: '100%', mr: 1 }}>
          <LinearProgress variant="determinate" {...props} />
        </Box>
        <Box sx={{ minWidth: 35 }}>
          <Typography variant="body2" color="black">{`${Math.round(props.value)}%`}</Typography>
        </Box>
      </Box>
    </Box>
  )
}

const LinearProgressBar = ({
  isFullWidth = false,
  isWithLabel = true,
}: {
  isFullWidth?: boolean
  isWithLabel?: boolean
}) => {
  const [progress, setProgress] = React.useState(10)

  useEffect(() => {
    const timer = setInterval(() => {
      const randomIncrement = Math.floor(Math.random() * 10) + 1
      setProgress((prevProgress) =>
        prevProgress + randomIncrement >= 100 ? 99 : prevProgress + randomIncrement,
      )
    }, 300)
    return () => {
      clearInterval(timer)
    }
  }, [])

  return (
    <Box sx={{ width: '100%' }}>
      <LinearProgressWithLabel
        value={progress}
        isFullWidth={isFullWidth}
        isWithLabel={isWithLabel}
      />
    </Box>
  )
}

export default LinearProgressBar
