import { IApi } from '../../api'
import { LAST_NOTIFICATIONS_COUNT } from '@common/constants/client'
import { CommonPromiseAction } from '../types'

export const prefix = 'notification'

export const LIST_NOTIFICATIONS_REQUEST = `${prefix}/LIST_NOTIFICATIONS_REQUEST`
export const LIST_NOTIFICATIONS_SUCCESS = `${prefix}/LIST_NOTIFICATIONS_SUCCESS`
export const LIST_NOTIFICATIONS_FAILURE = `${prefix}/LIST_NOTIFICATIONS_FAILURE`

export const list: CommonPromiseAction = (params: object) => ({
  type: [LIST_NOTIFICATIONS_REQUEST, LIST_NOTIFICATIONS_SUCCESS, LIST_NOTIFICATIONS_FAILURE],
  promise: (api: IApi) => api.notification.list(params),
  // @ts-ignore
  loadMore: params.loadMore,
})

export const LIST_LAST_NOTIFICATIONS_REQUEST = `${prefix}/LIST_LAST_NOTIFICATIONS_REQUEST`
export const LIST_LAST_NOTIFICATIONS_SUCCESS = `${prefix}/LIST_LAST_NOTIFICATIONS_SUCCESS`
export const LIST_LAST_NOTIFICATIONS_FAILURE = `${prefix}/LIST_LAST_NOTIFICATIONS_FAILURE`

export const listLastNotifications: CommonPromiseAction = () => ({
  type: [
    LIST_LAST_NOTIFICATIONS_REQUEST,
    LIST_LAST_NOTIFICATIONS_SUCCESS,
    LIST_LAST_NOTIFICATIONS_FAILURE,
  ],
  promise: (api: IApi) =>
    api.notification.list({
      perPage: LAST_NOTIFICATIONS_COUNT,
      page: 0,
    }),
})

export const MARK_NOTIFICATIONS_AS_READ_REQUEST = `${prefix}/MARK_NOTIFICATIONS_AS_READ_REQUEST`
export const MARK_NOTIFICATIONS_AS_READ_SUCCESS = `${prefix}/MARK_NOTIFICATIONS_AS_READ_SUCCESS`
export const MARK_NOTIFICATIONS_AS_READ_FAILURE = `${prefix}/MARK_NOTIFICATIONS_AS_READ_FAILURE`

export const markAsRead: CommonPromiseAction = (params: {
  notificationIds: string[]
  all?: boolean
}) => ({
  type: [
    MARK_NOTIFICATIONS_AS_READ_REQUEST,
    MARK_NOTIFICATIONS_AS_READ_SUCCESS,
    MARK_NOTIFICATIONS_AS_READ_FAILURE,
  ],
  promise: (api: IApi) => api.notification.markAsRead(params),
})
