import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import cn from 'classnames'
import { Link, generatePath } from 'react-router-dom'
import styles from './ParticipantsTable.module.scss'
import genericSs from '@styles/generic.module.scss'
import { debounceEventHandler, formatPrice } from '../../helpers/helpers'
import TableFiltersRow from '../Common/TableFiltersRow'
import { IFilter, PER_PAGE } from '@common/constants/filters'
import { Form } from 'react-final-form'
import useTable from '../../hooks/useTable'
import FilterContainer from '../Filters/FilterContainer'
import TableContainer from '../Common/TableContainer'
import Table from '../Common/Table'
import TableHead from '../Common/TableHead'
import TableBody from '../Common/TableBody'
import TableRow from '../Common/TableRow'
import TableCell from '../Common/TableCell'
import Card from '../Common/Card'
import { IParticipant, IParticipantData } from '@common/interfaces/participant'
import { ILoadingData } from '../../redux/types'
import LinkButton from '@mui/material/Link'
import { ROUTES } from '../../constants/routes'
import { PARTICIPANTS_TABLE_FILTERS_CONFIG } from '@common/constants/filters'
import { buildFiltersDefaults, buildFiltersValidateSchema } from '../../helpers/filters'
import TableLoader from '../Common/TableLoader'
import { ExpandDetailIcon } from '../Common/Icons'
import ParticipantsSummaryDetailTable from '../ParticipantsSummaryDetailTable'
import InfiniteScroll from 'react-infinite-scroll-component'

const filtersValidate = buildFiltersValidateSchema(PARTICIPANTS_TABLE_FILTERS_CONFIG as IFilter[])
const filtersDefaults = buildFiltersDefaults(PARTICIPANTS_TABLE_FILTERS_CONFIG as IFilter[])

interface IRowProps {
  rowIndex: number
  item?: IParticipant
  headers?: string[]
  handleExpand: (label: string) => void
  isExpanded: boolean
}

const ParticipantsSummaryRow = ({ rowIndex, item, handleExpand, isExpanded }: IRowProps) => {
  return (
    <TableRow className={cn('activableRow')} data-index={rowIndex}>
      <TableCell className={genericSs.tableTextCenter}>
        <ExpandDetailIcon onClick={() => handleExpand(item.participant)} isExpanded={isExpanded} />
      </TableCell>
      <TableCell className={cn(genericSs.tableTextLeft)}>
        {item.id ? (
          <LinkButton
            component={Link}
            to={generatePath(ROUTES.PARTICIPANTS_PAGE, {
              id: item.id,
            })}
          >
            {item.participant}
          </LinkButton>
        ) : (
          item.participant
        )}
      </TableCell>

      <TableCell className={cn(genericSs.tableTextRight)}>
        <div className={styles.amountHolder}>
          <span>$</span>
          {formatPrice(item.totalLoanExposure)}
        </div>
      </TableCell>

      <TableCell className={cn(genericSs.tableTextRight)}>
        <div className={styles.amountHolder}>{item.totalClients}</div>
      </TableCell>
    </TableRow>
  )
}

interface IProps {
  listParticipants: (data: object) => void
  participantsData: ILoadingData<IParticipantData>
}

const ParticipantsTable = ({ listParticipants, participantsData }: IProps) => {
  const wrapperRef = useRef(null)
  const [expanded, setExpanded] = useState([])

  const {
    isLoading,
    data: participants,
    totalCount,
  } = useMemo(() => {
    return {
      isLoading: participantsData?.isLoading,
      data: participantsData?.data?.data,
      totalCount: participantsData?.data?.totalCount,
    }
  }, [participantsData])

  const { filters, handleFiltersChange, handleOrderChange, orderBy } = useTable({
    tableId: 'participant',
    filtersDefaults,
    sortDefault: {
      field: 'participant',
      direction: 'DESC',
    },
  })

  useEffect(() => {
    listParticipants({ filters, orderBy: orderBy.field, orderDirection: orderBy.direction })
  }, [listParticipants, orderBy, filters])

  const handleExpand = useCallback((label: string) => {
    setExpanded((values) =>
      values.includes(label) ? values.filter((item) => item !== label) : [...values, label],
    )
  }, [])

  const debounceListParticipants = useMemo(
    () => debounceEventHandler((data: any) => listParticipants({ ...data }), 500),
    [listParticipants],
  )

  const loadMore = useCallback(() => {
    debounceListParticipants({
      loadMore: true,
      orderBy: orderBy.field,
      orderDirection: orderBy.direction,
      filters,
      page: Math.ceil(participants?.length / PER_PAGE),
    })
  }, [orderBy, filters, participants?.length, debounceListParticipants])

  return (
    <Card ref={wrapperRef} withBorder={false}>
      <TableContainer className={cn(styles.table)} id={'capTableSummmary'}>
        <Form
          validate={filtersValidate}
          onSubmit={handleFiltersChange}
          initialValues={filters}
          mutators={{
            setFieldData: ([field, value], state, { changeValue }) => {
              changeValue(state, field, () => value)
            },
          }}
          render={({ values, handleSubmit, form: { mutators } }) => (
            <FilterContainer
              filters={PARTICIPANTS_TABLE_FILTERS_CONFIG as IFilter[]}
              handleSubmit={handleSubmit}
              mutators={mutators}
              values={values}
              appliedFilters={filters}
              withFilters={true}
            />
          )}
        />

        <Table>
          <TableHead className={cn(styles.tableHead)}>
            <TableFiltersRow
              filters={PARTICIPANTS_TABLE_FILTERS_CONFIG as IFilter[]}
              orderBy={orderBy}
              handleOrderChange={handleOrderChange}
            />
          </TableHead>
          <TableBody id="scrollableTable">
            {isLoading ? (
              <TableLoader columnsCount={PARTICIPANTS_TABLE_FILTERS_CONFIG.length} height={26} />
            ) : (
              participants && (
                <InfiniteScroll
                  dataLength={participants?.length}
                  next={loadMore}
                  hasMore={participants?.length < totalCount}
                  loader={<TableLoader columnsCount={10} rowsCount={1} />}
                  scrollableTarget="scrollableTable"
                >
                  {participants.map((item: IParticipant, rowIndex: number) => {
                    const isExpanded = expanded.includes(item.participant)

                    return (
                      <>
                        <ParticipantsSummaryRow
                          key={item.id}
                          rowIndex={rowIndex}
                          item={item}
                          handleExpand={handleExpand}
                          isExpanded={isExpanded}
                        />
                        {isExpanded && (
                          <TableRow>
                            <TableCell className={genericSs.nestedRowColumn} colSpan={3}>
                              <ParticipantsSummaryDetailTable participant={item.participant} />
                            </TableCell>
                          </TableRow>
                        )}
                      </>
                    )
                  })}
                </InfiniteScroll>
              )
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Card>
  )
}

export default ParticipantsTable
