import React, { useCallback, useMemo, useRef, useState } from 'react'
import { generatePath, Link as RouterLink } from 'react-router-dom'
import Link from '@mui/material/Link'
import Tooltip from '@mui/material/Tooltip'
import cn from 'classnames'

import styles from './BBCLogsItem.module.scss'

import { ReactComponent as ReplyIcon } from '@assets/images/reply-icon.svg'
import { BBCLogType, IBBCLog } from '@common/interfaces/bbcLog'
import {
  formatDate,
  formatDateCalendarNoTime,
  formatDateTime,
  formatPriceNoDecimal,
  handleStopPropagation,
} from '../../../helpers/helpers'
import BBCLogsItemContentAREligibility from './BBCLogsItemContentAREligibility'
import BBCLogsItemContentCustomRule from './BBCLogsItemContentCustomRule'
import BBCLogsItemContentInventoryEligibility from './BBCLogsItemContentInventoryEligibility'
import BBCLogsItemContentInventoryMapping from './BBCLogsItemContentInventoryMapping'
import BBCLogsItemContentReserve from './BBCLogsItemContentReserve'
import BBCLogsItemContentAPEligibility from './BBCLogsItemContentAPEligibility'
import { ROUTES } from '../../../constants/routes'
import Note from '../../Notes/Note'
import AddComment from '../../Notes/AddComment'

interface IProps {
  bbcLog: IBBCLog
  isExpanded: boolean
  pushToNotes: (data: any) => void
  showDetails: (bbcLog: IBBCLog) => void
}

const COMPONENT_MAP = {
  [BBCLogType.AREligibility]: BBCLogsItemContentAREligibility,
  [BBCLogType.CustomRule]: BBCLogsItemContentCustomRule,
  [BBCLogType.InventoryEligibility]: BBCLogsItemContentInventoryEligibility,
  [BBCLogType.InventoryMapping]: BBCLogsItemContentInventoryMapping,
  [BBCLogType.Reserve]: BBCLogsItemContentReserve,
  [BBCLogType.APEligibility]: BBCLogsItemContentAPEligibility,
}

export const BBCLogsItemContent = ({ bbcLog }: { bbcLog: IBBCLog }) => {
  if (!COMPONENT_MAP[bbcLog.type]) {
    return null
  }
  const Component = COMPONENT_MAP[bbcLog.type]

  return <Component bbcLog={bbcLog} />
}

const BBCLogsItem = ({ bbcLog, isExpanded, pushToNotes, showDetails }: IProps) => {
  const itemRef = useRef(null)

  const hasNote = useMemo(() => bbcLog.notes.length > 0, [bbcLog])
  const [isReplyShown, setIsReplyShown] = useState(false)
  const [isRepliesExpanded, setIsRepliesExpanded] = useState(false)

  const handleCreateNote = useCallback(async () => {
    if (!hasNote && itemRef.current) {
      await pushToNotes({
        id: bbcLog.id,
        text: itemRef.current.querySelector('[class*="itemData"]').textContent,
      })
    }
  }, [bbcLog, hasNote, pushToNotes])

  const handleViewDetails = useCallback(
    (event: React.MouseEvent) => {
      event.stopPropagation()
      setIsReplyShown(false)
      handleCreateNote()
      showDetails(bbcLog)
    },
    [bbcLog, handleCreateNote, showDetails],
  )

  const bbcLink = useMemo(
    () =>
      bbcLog.borrowingBaseId
        ? generatePath(ROUTES.BBC_SUMMARY, { id: bbcLog.borrowingBaseId })
        : null,
    [bbcLog],
  )

  const toggleReplyVisibility = useCallback(
    async (event?: React.MouseEvent) => {
      event?.stopPropagation()
      handleCreateNote()
      setIsReplyShown((isShown) => !isShown)
    },
    [handleCreateNote],
  )

  const toggleExpandReplies = useCallback((event: React.MouseEvent) => {
    event.stopPropagation()
    setIsRepliesExpanded((isExpanded) => !isExpanded)
  }, [])

  const expandRepliesText = useMemo(
    () => (isRepliesExpanded ? 'Hide all' : 'See all replies'),
    [isRepliesExpanded],
  )

  return (
    <div
      ref={itemRef}
      className={cn(styles.item, { [styles.itemExpanded]: isExpanded })}
      onClick={handleViewDetails}
    >
      <div className={styles.itemHeading}>
        <div className={styles.itemTags}>
          {bbcLog.borrowingBase && (
            <Link
              component={RouterLink}
              to={bbcLink}
              className={styles.itemTag}
              onClick={handleStopPropagation}
            >
              {bbcLog.borrowingBase.isTest ? 'Test ' : ''}BBC&nbsp;
              {formatDate(bbcLog.borrowingBase.recordDate)}
            </Link>
          )}
        </div>

        <div className={styles.itemActions}>
          {!isExpanded && (
            <Tooltip title="Reply">
              <ReplyIcon onClick={toggleReplyVisibility} />
            </Tooltip>
          )}
        </div>
      </div>

      <div className={styles.itemTitle}>
        {bbcLog.type}

        {bbcLog.impactToAvailability !== 0 && (
          <span
            className={cn(styles.itemImpactToAvailability, {
              [styles.itemImpactToAvailabilityPositive]: bbcLog.impactToAvailability > 0,
              [styles.itemImpactToAvailabilityNegative]: bbcLog.impactToAvailability < 0,
            })}
          >
            ${formatPriceNoDecimal(Math.abs(bbcLog.impactToAvailability))}
          </span>
        )}
      </div>

      <BBCLogsItemContent bbcLog={bbcLog} />

      <div className={styles.itemBottom}>
        <Tooltip title={formatDateTime(bbcLog.createdAt)}>
          <span>{formatDateCalendarNoTime(bbcLog.createdAt)}</span>
        </Tooltip>
      </div>

      {bbcLog.notes?.[0]?.children?.length > 1 && (
        <div className={styles.itemExpandRepliesContainer}>
          <Link className={styles.itemExpandRepliesText} onClick={toggleExpandReplies}>
            {expandRepliesText}
          </Link>
        </div>
      )}

      {bbcLog.notes?.[0]?.children?.length > 0 && (
        <div className={styles.itemReplies}>
          {bbcLog.notes?.[0]?.children?.map((note, index) =>
            index === bbcLog.notes?.[0]?.children?.length - 1 || isRepliesExpanded ? (
              <Note note={note} isChild />
            ) : null,
          )}
        </div>
      )}

      {!isExpanded && isReplyShown && bbcLog.notes?.[0] && (
        <AddComment parent={bbcLog.notes?.[0]} hideReply={toggleReplyVisibility} />
      )}
    </div>
  )
}

export default BBCLogsItem
