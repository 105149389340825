import React, { useEffect, useCallback, useMemo, useState, useRef } from 'react'
import { useHistory } from 'react-router'
import { useParams } from 'react-router'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import styles from './OngoingReportingARGeneralLedgerPage.module.scss'
import { IOngoingReporting, OngoingReportingStatus, ReportingFlow } from '@common/interfaces/bbc'
import Button from '../../components/Common/Button'
import { ROUTES } from '../../constants/routes'
import OngoingReportingFiles from '../../components/OngoingReportingFiles'
import { REPORTING_DOCUMENT_TYPES } from '@common/constants/client'
import ClientAliasMapping from '../../components/ClientAliasMapping'
import { IEntityInfo } from '@common/interfaces/entityInfo'
import Modal from '../../components/Common/Modal'
import TabContext from '@mui/lab/TabContext'
import TabPanel from '@mui/lab/TabPanel'
import TypeMapping from '../../components/TypeMapping'
import { IClientGeneralLedgerMapping } from '@common/interfaces/clientGeneralLedger'
import { FormApi } from 'final-form'
import { ILoadingData } from '../../redux/types'
import { EditMapping, EditSourceData, ExternalLink } from '../../components/Common/Icons'
import Card from '../../components/Common/Card'
import { useSetPageTitle } from '../../hooks/useSetPageTitle'
import Loader from './OngoingReportingARGeneralLedgerPageLoader'
import Breadcrumbs from '../../components/Common/Breadcrumbs'
import { ReactComponent as HomeIcon } from '@assets/images/home-icon.svg'
import { generatePath } from 'react-router-dom'
import Tabs from '../../components/Common/Tabs'
import DilutionByCustomer from '../../components/DilutionByCustomer'
import DilutionOverTime from '../../components/DilutionOverTime'
import { WorkflowTypes } from '@common/interfaces/notes'
import useOngoingReportingFileSheet from '../../hooks/useOngoingReportingFileSheet'

const tabOptions = ['Customers', 'Transaction types']

interface IProps {
  data: IOngoingReporting
  showOngoingReporting: (id: string) => void
  updateGeneralLedger: (id: string, data: any) => Promise<any>
  entities: IEntityInfo[]
  listTypeMapping?: (id: string) => void
  typeMapping: ILoadingData<{ data: IClientGeneralLedgerMapping[] }>
  updateOngoingReportingStatus: (data: object) => Promise<void>
  getDebtorIneligibleCategories: (data: object) => void
  listDilutionDropdowns: (id: string, params: object) => void
}

const OngoingReportingARGeneralLedgerPage = ({
  data,
  updateGeneralLedger,
  showOngoingReporting,
  listTypeMapping,
  typeMapping,
  updateOngoingReportingStatus,
  getDebtorIneligibleCategories,
  listDilutionDropdowns,
}: IProps) => {
  const { id } = useParams<{ id: string }>()
  const formRef: React.MutableRefObject<FormApi<any, Partial<any>>> = useRef(null)
  const [refreshCounter, setRefreshCounter] = useState(0)

  const isDisabled = useMemo(
    () => [OngoingReportingStatus.Verified, OngoingReportingStatus.Archived].includes(data?.status),
    [data],
  )

  const currentClientName = useMemo(() => {
    if (id === data?.id) {
      return data?.clientInfo?.clientName
    }
  }, [data, id])

  useEffect(() => {
    if (data?.clientInfo) {
      getDebtorIneligibleCategories({
        id: data?.clientInfo.id,
        workflow: WorkflowTypes.clientPage,
      })
    }
  }, [data, getDebtorIneligibleCategories])

  useEffect(
    () => id && listDilutionDropdowns(id, { reportingFlow: ReportingFlow.OngoingReporting }),
    [id, listDilutionDropdowns, refreshCounter],
  )

  const breadcrumbs = useMemo(() => {
    return [
      {
        link: ROUTES.HOMEPAGE,
        Icon: HomeIcon,
      },
      {
        link: ROUTES.ANALYSIS_QUEUE,
        title: 'Analysis queue',
      },
      {
        title: currentClientName,
        link: data?.clientInfo?.id
          ? generatePath(ROUTES.CLIENT_PAGE, { id: data?.clientInfo?.id })
          : '',
      },
      {
        link: generatePath(ROUTES.ONGOING_REPORTING_AR_GENERAL_LEDGER, { id }),
        title: `Dilution analysis`,
      },
    ]
  }, [id, currentClientName, data?.clientInfo?.id])

  const [currentTab, setCurrentTab] = useState(tabOptions[0])

  const handleChangeTab = async (newValue: string) => {
    setCurrentTab(newValue)
  }

  useEffect(() => {
    if (!data || data?.id !== id) {
      showOngoingReporting(id)
    }
  }, [showOngoingReporting, data, id])

  const handleSelectFiles = useCallback(
    async (fileSheets: { fileId: string; sheetName: string }[]) => {
      const result = await updateGeneralLedger(id, {
        fileSheets: fileSheets.map((item) => ({
          ...item,
          type: REPORTING_DOCUMENT_TYPES.arGeneralLedger,
        })),
      })
      if (result?.error?.code === 'FORMAT_ERROR') {
        showOngoingReporting(id)
      }
      if (id && id === data?.id) {
        await listTypeMapping(id)
      }
      return result
    },
    [id, data, updateGeneralLedger, listTypeMapping, showOngoingReporting],
  )

  const { fileSheets, currentSelectedFile } = useOngoingReportingFileSheet({
    reporting: data,
    type: REPORTING_DOCUMENT_TYPES.arGeneralLedger,
  })

  const history = useHistory()

  const unMappedAliasesCount = useMemo(
    () => (data?.unMappedDebtorsCount ? Number(data.unMappedDebtorsCount) : 0),
    [data],
  )
  const unMappedTransactionTypesCount = useMemo(
    () => (data?.unMappedTypeCount ? Number(data.unMappedTypeCount) : 0),
    [data],
  )

  const unmappedTotalCount = useMemo(
    () => (data ? unMappedAliasesCount + unMappedTransactionTypesCount : 0),
    [data, unMappedAliasesCount, unMappedTransactionTypesCount],
  )

  const [isModalOpen, setIsModalOpen] = useState(
    unmappedTotalCount > 0 && typeMapping?.data?.data?.length > 0,
  )

  useEffect(() => {
    if (unmappedTotalCount > 0) {
      setIsModalOpen(true)
    }
  }, [unmappedTotalCount])

  const handleCloseModal = useCallback(async () => {
    setRefreshCounter((refreshCounter) => refreshCounter + 1)
    setIsModalOpen(false)
  }, [])
  const status = useMemo(() => data?.status, [data])

  useEffect(() => {
    if (data?.id === id && status === OngoingReportingStatus.NotStarted) {
      updateOngoingReportingStatus({ id, status: OngoingReportingStatus.Process })
    }
  }, [data, id, status, updateOngoingReportingStatus])

  useEffect(() => {
    if (unmappedTotalCount === 0 && data?.id === id && status === OngoingReportingStatus.Process) {
      updateOngoingReportingStatus({ id, status: OngoingReportingStatus.InReview })
      setIsModalOpen(false)
    }
  }, [unmappedTotalCount, typeMapping, data, id, updateOngoingReportingStatus, status])

  const handleClick = useCallback(() => {
    if (data?.id === id && status === OngoingReportingStatus.InReview) {
      updateOngoingReportingStatus({ id, status: OngoingReportingStatus.Verified })
    }
    history.push(ROUTES.ANALYSIS_QUEUE)
  }, [history, id, data, updateOngoingReportingStatus, status])

  useSetPageTitle(currentClientName ? `${currentClientName} Dilution Analysis` : '')

  const [isFileSelectShown, setIsFileSelectShown] = useState(false)
  const handleOpenSelectFile = useCallback(() => {
    setIsFileSelectShown(true)
  }, [setIsFileSelectShown])

  const handleCloseSelectFile = useCallback(() => {
    setIsFileSelectShown(false)
  }, [setIsFileSelectShown])

  const toggleIsFileSelectShown = useCallback(() => setIsFileSelectShown((isShown) => !isShown), [])

  const toggleIsModalOpen = useCallback(() => setIsModalOpen((isOpen) => !isOpen), [])

  if (!data || data?.id !== id) {
    return <Loader />
  }

  return (
    <Box py={1} pr={2}>
      <Breadcrumbs breadcrumbs={breadcrumbs} isLoading={!currentClientName} />

      <Card
        withBorder={false}
        title={
          <Box display="flex" justifyContent="flex-end" alignItems="center">
            <Box display="flex" justifyContent="space-between" alignItems="center" gap={2}>
              <ExternalLink link={currentSelectedFile?.link} />
              <EditSourceData
                action={toggleIsFileSelectShown}
                error={!!fileSheets?.filter(({ file }) => file && file.error).length}
              />

              <EditMapping action={toggleIsModalOpen} mappingRequired={unmappedTotalCount > 0} />

              <Button
                type="submit"
                color="primary"
                variant="contained"
                size="small"
                disabled={unmappedTotalCount !== 0}
                onClick={handleClick}
              >
                {[OngoingReportingStatus.Archived, OngoingReportingStatus.Verified].includes(status)
                  ? 'Back to Analysis queue'
                  : 'Submit'}
              </Button>
            </Box>
          </Box>
        }
      >
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <OngoingReportingFiles
              handleSelectFiles={handleSelectFiles}
              fileSheets={fileSheets}
              files={data?.files}
              isDisabled={isDisabled}
              handleCloseModal={handleCloseSelectFile}
              handleOpenModal={handleOpenSelectFile}
              isOpen={isFileSelectShown}
              setRefreshCounter={setRefreshCounter}
            />
            {unmappedTotalCount === 0 && currentSelectedFile?.fileId && (
              <>
                <Grid item xs={12}>
                  <DilutionByCustomer
                    workflow={WorkflowTypes.ongoingReporting}
                    refreshCounter={refreshCounter}
                  />
                </Grid>
                <Grid item xs={12} mt={2}>
                  <DilutionOverTime
                    reportingFlow={ReportingFlow.OngoingReporting}
                    refreshCounter={refreshCounter}
                  />
                </Grid>
              </>
            )}
            {isModalOpen && (
              <div className={styles.modal}>
                <Modal
                  classes={{
                    root: styles.modal,
                  }}
                  open
                  onCancel={handleCloseModal}
                  toolbar
                  disableEnforceFocus
                  isTable={true}
                >
                  <br />
                  <br />
                  <TabContext value={currentTab}>
                    <div className={styles.tabContainer}>
                      <Tabs
                        tabs={tabOptions}
                        selected={currentTab}
                        handleChange={handleChangeTab}
                      />
                    </div>
                    <TabPanel value={tabOptions[0]}>
                      <ClientAliasMapping
                        fileId={currentSelectedFile.id}
                        table="client_general_ledger"
                        clientName={currentClientName}
                        formRef={formRef}
                        tableClassName={styles.aliasesTable}
                      />
                    </TabPanel>
                    <TabPanel value={tabOptions[1]}>
                      <TypeMapping
                        id={id}
                        typeMappingData={typeMapping}
                        listTypeMapping={listTypeMapping}
                        tableClassName={styles.typesTable}
                        reportingFlow={ReportingFlow.OngoingReporting}
                      />
                    </TabPanel>
                  </TabContext>
                </Modal>
              </div>
            )}
          </Grid>
        </Grid>
      </Card>
    </Box>
  )
}

export default OngoingReportingARGeneralLedgerPage
