import { connect } from 'react-redux'
import LoanCommitteeReportCapTablePage from './LoanCommitteeReportCapTablePage'
import { dueDiligenceInfo } from '../../redux/dueDiligence/selectors'
import { createStructuredSelector } from 'reselect'

const selectors = createStructuredSelector({
  dueDiligenceInfo,
})

export default connect(selectors, null)(LoanCommitteeReportCapTablePage)
