import { connect } from 'react-redux'
import NoteEditor from './NoteEditor'
import { listSuggestions } from '../../../redux/notes/actions'
import { putNotification } from '../../../redux/ui/actions'
import { role } from '../../../redux/profile/selectors'

import { createStructuredSelector } from 'reselect'

const selector = createStructuredSelector({
  role,
})

const actions = {
  listSuggestions,
  putNotification,
}

export default connect(selector, actions)(NoteEditor)
