import React, { useEffect } from 'react'
import { useParams } from 'react-router'

import styles from './ConfirmEmail.module.scss'

interface IProps {
  onConfirm: (hash: string) => void
  isConfirmed: boolean
}

const ConfirmEmail = ({ onConfirm, isConfirmed }: IProps) => {
  const { hash } = useParams<{ hash: string }>()

  useEffect(() => {
    onConfirm(hash)
  }, [onConfirm, hash])

  return (
    <div className={styles.confirmEmailWrap}>
      <div>
        {isConfirmed ? (
          <h3>Your email is verified. Now you can login to system.</h3>
        ) : (
          <h3>Email is not verified.</h3>
        )}
      </div>
    </div>
  )
}

export default ConfirmEmail
