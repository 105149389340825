import { prefix } from './actions'
import { IRootState } from '../reducer'

export const isLoading = (state: IRootState) => state[prefix].loadingArray.length > 0

export const customersAggregation = (state: IRootState) => state[prefix].customersAggregation

export const vendorsAggregation = (state: IRootState) => state[prefix].vendorsAggregation

export const entities = (state: IRootState) => state[prefix].entities

export const entity = (state: IRootState) => state[prefix].entity

export const entityArSummary = (state: IRootState) => state[prefix].entityArSummary

export const entityApSummary = (state: IRootState) => state[prefix].entityApSummary

export const entityDilution = (state: IRootState) => state[prefix].entityDilution

export const entityActivity = (state: IRootState) => state[prefix].entityActivity

export const aliases = (state: IRootState) => state[prefix].aliases

export const entityMergeConflicts = (state: IRootState) => state[prefix].entityMergeConflicts

export const entityAR = (state: IRootState) => state[prefix].entityAR

export const entityAp = (state: IRootState) => state[prefix].entityAp

export const investorAggregation = (state: IRootState) => state[prefix].investorAggregation

export const investmentSummary = (state: IRootState) => state[prefix].investmentSummary

export const entityAccounts = (state: IRootState) => state[prefix].entityAccounts
