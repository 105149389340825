import { connect } from 'react-redux'

import BlankPage from './BlankPage'

const selector: any = null

const actions = {}

export { BlankPage }

export default connect(selector, actions)(BlankPage)
