import React, { useCallback, useState, useEffect, useRef, useMemo } from 'react'
import Tooltip from '@mui/material/Tooltip'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import cn from 'classnames'
import CommentsTextEditor from './CommentsTextEditor'

import styles from './DueDiligenceCommentsModal.module.scss'
import { ReactComponent as WarningIcon } from '@assets/images/warning-icon-outline.svg'
import {
  IDueDiligenceDocumentRequest,
  IDueDiligenceDocumentRequestComment,
} from '@common/interfaces/dueDiligence'
import DueDiligenceSideContainer from '../DueDiligenceSideContainer'
import WarningModal from '../WarningModal'
import { formatDateCalendar, formatDateTime } from '../../helpers/helpers'
import Avatar from '../Common/Avatar'
import { MenuIcon } from '../Common/Icons'

export const DueDiligenceDocumentRequestComment = ({
  comment,
  isOwn,
  isSaving,
  isEditable,
  onEdit,
  onDelete,
}: {
  comment: IDueDiligenceDocumentRequestComment
  isOwn: boolean
  isSaving: boolean
  isEditable: boolean
  onEdit: (comment: IDueDiligenceDocumentRequestComment) => Promise<any>
  onDelete: (comment: IDueDiligenceDocumentRequestComment) => void
}) => {
  const [isEditing, setIsEditing] = useState(false)
  const [anchorEl, setAnchorEl] = useState(null)
  const [actionsMenuOpen, setActionsMenuOpen] = useState(false)

  const commentText = useMemo(() => comment?.comment, [comment])

  const handleClickMenu = useCallback((event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
    setActionsMenuOpen(true)
  }, [])

  const handleCloseMenu = useCallback(() => {
    setActionsMenuOpen(false)
    setAnchorEl(null)
  }, [])

  const handleEdit = useCallback(() => {
    setIsEditing(true)
    handleCloseMenu()
  }, [handleCloseMenu])

  const handleSave = useCallback(async () => {
    const result = await onEdit({ ...comment, comment: commentText })
    if (!result?.error) {
      setIsEditing(false)
    }
  }, [comment, onEdit, commentText])

  const handleDelete = useCallback(() => {
    onDelete(comment)
    handleCloseMenu()
  }, [comment, onDelete, handleCloseMenu])

  return (
    <div className={styles.comment}>
      <div className={styles.commentUserAndActions}>
        <div className={styles.commentUser}>
          <Avatar user={comment.user} />
          {comment.user?.firstName} {comment.user?.lastName}
        </div>
        <div className={styles.dateContainer}>
          <div className={styles.commentDate}>
            <Tooltip title={formatDateTime(comment.createdAt)}>
              <span>{formatDateCalendar(comment.createdAt)}</span>
            </Tooltip>
          </div>

          {isEditable && isOwn && (
            <MenuIcon isActive={actionsMenuOpen} onClick={handleClickMenu} size="small" />
          )}

          <Menu open={actionsMenuOpen} anchorEl={anchorEl} onClose={handleCloseMenu}>
            <MenuItem onClick={handleEdit}>Edit</MenuItem>
            <MenuItem onClick={handleDelete}>Delete</MenuItem>
          </Menu>
        </div>
      </div>

      <div className={styles.commentText}>
        <CommentsTextEditor
          value={commentText || ''}
          className={styles.textEditor}
          readOnly={!isEditing}
          isUpdate
          handleUpdate={handleSave}
        />
      </div>
    </div>
  )
}

export const DueDiligenceDocumentRequestAddComment = ({
  handleAddComment,
}: {
  handleAddComment: (commentText: string) => Promise<any>
}) => {
  const [commentText, setCommentText] = useState('')

  const onAddComment = useCallback(
    async (comment: any) => {
      const result = await handleAddComment(comment)
      if (!result?.error) {
        setCommentText('')
      }
    },
    [handleAddComment],
  )

  return (
    <div className={styles.addCommentWrapper}>
      <CommentsTextEditor
        value={commentText || ''}
        className={styles.textEditor}
        onAddComment={onAddComment}
      />
    </div>
  )
}

interface IProps {
  isSaving: boolean
  isUW?: boolean
  readOnly?: boolean
  documentRequest: IDueDiligenceDocumentRequest
  currentUserId?: string
  handleSaveComment?: (data: Partial<any>) => Promise<any>
  handleDeleteComment?: (id: string) => Promise<any>
  handleClose: () => void
}

const DueDiligenceCommentsModal = ({
  isSaving,
  isUW = false,
  readOnly = false,
  documentRequest,
  currentUserId,
  handleSaveComment,
  handleDeleteComment,
  handleClose,
}: IProps) => {
  const documentsWrapperRef = useRef(null)
  const commentsWrapperRef = useRef(null)

  const [commentForDelete, setCommentForDelete] =
    useState<IDueDiligenceDocumentRequestComment>(null)

  const scrollSectionsToBottom = useCallback(() => {
    setTimeout(() => {
      if (commentsWrapperRef.current) {
        commentsWrapperRef.current.scrollTop = commentsWrapperRef.current.scrollHeight
      }
    }, 400)
    setTimeout(() => {
      if (documentsWrapperRef.current) {
        documentsWrapperRef.current.scrollTop = documentsWrapperRef.current.scrollHeight
      }
    }, 400)
  }, [])

  useEffect(() => {
    scrollSectionsToBottom()
  }, [documentRequest?.files, documentRequest?.comments, scrollSectionsToBottom])

  useEffect(() => {
    setTimeout(() => {
      scrollSectionsToBottom()
    }, 400)
  }, [scrollSectionsToBottom])

  const handleAddComment = useCallback(
    async (commentText: string) => {
      const result = await handleSaveComment({ comment: commentText })
      return result
    },
    [handleSaveComment],
  )

  const handleEditComment = useCallback(
    async (comment: IDueDiligenceDocumentRequestComment) => handleSaveComment(comment),
    [handleSaveComment],
  )

  const handleDeleteCommentItem = useCallback((comment: IDueDiligenceDocumentRequestComment) => {
    setCommentForDelete(comment)
  }, [])

  const handleDeleteCommentConfirm = useCallback(async () => {
    await handleDeleteComment(commentForDelete.id)
    setCommentForDelete(null)
  }, [commentForDelete, handleDeleteComment])

  const handleDeleteCommentCancel = useCallback(() => {
    setCommentForDelete(null)
  }, [])

  const handleCancel = useCallback(() => {
    handleClose()
  }, [handleClose])

  return (
    <DueDiligenceSideContainer
      isUW
      open
      onCancel={handleCancel}
      title={documentRequest?.type?.name}
    >
      <div className={styles.documentsAndCommentsSection}>
        <div
          ref={commentsWrapperRef}
          className={cn(styles.commentsWrapper, styles.commentsWrapperExpanded)}
        >
          {documentRequest?.comments?.length > 0 ? (
            documentRequest?.comments?.map((comment) => (
              <DueDiligenceDocumentRequestComment
                key={comment.id}
                comment={comment}
                isOwn={comment.userId === currentUserId}
                isSaving={isSaving}
                isEditable={isUW && !readOnly}
                onEdit={handleEditComment}
                onDelete={handleDeleteCommentItem}
              />
            ))
          ) : (
            <div className={styles.commentsEmpty}>
              <WarningIcon />
              No comments
            </div>
          )}
        </div>
      </div>

      {isUW && !readOnly && (
        <DueDiligenceDocumentRequestAddComment handleAddComment={handleAddComment} />
      )}

      {commentForDelete && (
        <WarningModal
          warningMessage="Deleting this comment will be permanent."
          onConfirm={handleDeleteCommentConfirm}
          onCancel={handleDeleteCommentCancel}
          confirmText="Yes, proceed"
          cancelText="Cancel"
          isLoading={isSaving}
          disabled={isSaving}
        />
      )}
    </DueDiligenceSideContainer>
  )
}

export default DueDiligenceCommentsModal
