import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'

import ParticipantsTable from './ParticipantsTable'
import { listParticipants } from '../../redux/participant/actions'
import { participants } from '../../redux/participant/selectors'

const selector = createStructuredSelector({
  participantsData: participants,
})
const actions = {
  listParticipants,
}

export { ParticipantsTable }

export default connect(selector, actions)(ParticipantsTable)
