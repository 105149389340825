import React, { useMemo } from 'react'
import { INewsfeed, NewsTags } from '@common/interfaces/newsfeed'
import TaggingField from '../Common/TaggingField'
import styles from './MarketNewsDetails.module.scss'
import { createFilterOptions } from '@mui/base'
import { IOptionType } from '@common/interfaces/entityInfo'
import LinkedAccountTooltip from './LinkedAccountTooltip'

interface IProps {
  handleAddNewOtherAccount: (newValue: object, isFundraise: boolean) => void
  newsItem: INewsfeed
  handleDeleteLinkedAccount: (value: string) => void
  loadBrandOptions: (inputValue: string) => Promise<any>
  isFundraise?: boolean
}

const LinkedAccountInput = ({
  handleAddNewOtherAccount,
  newsItem,
  handleDeleteLinkedAccount,
  loadBrandOptions,
  isFundraise,
}: IProps) => {
  const filter = createFilterOptions<IOptionType>()

  const initialLinkedAccountsValues = useMemo(() => {
    const linkedAccountOptions = (
      newsItem?.linkedAccounts?.map((linkedAccount) => ({
        value: linkedAccount?.salesforceId || null,
        label: linkedAccount?.brandInfo?.brandName || '',
        type: linkedAccount?.type,
      })) || []
    ).filter((linkedAccount) => !isFundraise || linkedAccount.type === NewsTags.Fundraise)
    return linkedAccountOptions
  }, [newsItem?.linkedAccounts, isFundraise])

  return (
    <TaggingField
      className={styles.taggingField}
      limtDrowdown
      options={[]}
      placeholder={initialLinkedAccountsValues?.length > 0 ? '' : 'Add account'}
      renderTags={(tagValue) => {
        return tagValue.map((option, index) => (
          <LinkedAccountTooltip
            key={option.label}
            option={option}
            handleDeleteLinkedAccount={handleDeleteLinkedAccount}
            isFundraise={isFundraise}
          />
        ))
      }}
      isAsync
      loadOptions={loadBrandOptions}
      limitTags={4}
      onChange={(event, newValue) => {
        handleAddNewOtherAccount(newValue, isFundraise)
      }}
      name={`referralOptionValue`}
      // @ts-ignore
      value={initialLinkedAccountsValues || []}
      filterOptions={(currentOptions, params) => {
        // @ts-ignore
        const filtered = filter(currentOptions, params)
        if (
          params.inputValue !== '' &&
          filtered.findIndex((option) => option.value === params.inputValue) === -1
        ) {
          filtered.push({
            value: params.inputValue,
            label: `Add "${params.inputValue}"`,
            isNew: true,
          })
        }

        return filtered
      }}
      getOptionValue={(option) => option?.value}
      getOptionLabel={(option) => option?.label}
    />
  )
}

export default LinkedAccountInput
