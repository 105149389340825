import { Action } from 'redux'
import { takeEvery, put } from 'redux-saga/effects'
import { ExportToCsv } from 'export-to-csv-fix-source-map'

import { IApi } from '../../api'
import {
  EXPORT_AUDIT_LOG_REQUEST,
  EXPORT_AUDIT_LOG_SUCCESS,
  EXPORT_AUDIT_LOG_FAILURE,
} from './actions'
import { IAuditLogData } from '@common/interfaces/auditLog'
import { formatDateTime } from '../../helpers/helpers'

export function* exportAuditLog(api: IApi) {
  yield takeEvery(EXPORT_AUDIT_LOG_REQUEST, function* (action: Action) {
    try {
      // @ts-ignore
      const { params } = action
      const { data }: IAuditLogData = yield api.auditLog.list(params)
      const title = 'Audit_Log'
      const csvExporter = new ExportToCsv({
        showLabels: true,
        filename: title,
        title,
        headers: [
          'Client Name',
          'Activity',
          'User',
          'Table',
          'Column',
          'Old Value',
          'New Value',
          'Date',
        ],
      })
      csvExporter.generateCsv(
        data.map(
          ({ clientName, activity, user, table, column, oldValue, newValue, createdAt }: any) => [
            clientName,
            activity,
            user ? `${user.firstName} ${user.lastName}` : 'System',
            table,
            column,
            oldValue,
            newValue,
            formatDateTime(createdAt),
          ],
        ),
      )

      yield put({ type: EXPORT_AUDIT_LOG_SUCCESS })
    } catch (error) {
      yield put({ type: EXPORT_AUDIT_LOG_FAILURE, error })
    }
  })
}
