import React, { useEffect, useCallback, useState, useMemo } from 'react'
import { Field, Form } from 'react-final-form'
import Box from '@mui/material/Box'
import { OnChange } from 'react-final-form-listeners'
import Tooltip from '@mui/material/Tooltip'
import Loader from '../../Loader'
import Grid from '@mui/material/Grid'
import cn from 'classnames'
import TableLoader from '../../Common/TableLoader'
import { debounceEventHandler } from '../../../helpers/helpers'
import styles from './UserManagement.module.scss'
import genericSs from '@styles/generic.module.scss'
import { IClientInfo } from '@common/interfaces/client'
import Card from '../../Common/Card'
import Checkbox from '../../Common/Checkbox'
import arrayMutators from 'final-form-arrays'
import Button from '../../Common/Button'
import TableContainer from '../../Common/TableContainer'
import Table from '../../Common/Table'
import TableHead from '../../Common/TableHead'
import TableRow from '../../Common/TableRow'
import TableCell from '../../Common/TableCell'
import TableBody from '../../Common/TableBody'
import { IUser, UserRole, UserStatus } from '@common/interfaces/user'
import MenuItem from '@mui/material/MenuItem'
import Menu from '@mui/material/Menu'
import TableFiltersRow from '../../Common/TableFiltersRow'
import {
  USER_MANAGEMENT_FILTERS_CONFIG,
  USER_MANAGEMENT_CLIENT_FILTERS_CONFIG,
  USER_MANAGEMENT_PARTICIPANT_FILTERS_CONFIG,
} from '@common/constants/filters'
import FilterContainer from '../../Filters/FilterContainer'
import { buildFiltersDefaults, buildFiltersValidateSchema } from '../../../helpers/filters'
import WarningModal from '../../../components/WarningModal'
import AddEditUser from './AddEditUser'
import AddButton from '../AddButton'
import { ILoadingData } from '../../../redux/types'
import SaveState from '../../Common/SaveState'
import { MenuIcon } from '../../Common/Icons'

const mutators = {
  ...arrayMutators,
}

interface IProps {
  clientId?: string
  participantId?: string
  participantClients?: string[]
  users: ILoadingData<{ data: IUser[] }>
  clientInfo: IClientInfo
  listUsers: (params: object) => void
  createUser: (data: Partial<IUser> & { clientId: string }) => void
  updateUser: (id: string, data: Partial<IUser & { clientId?: string }>) => void
  deleteUser: (id: string) => void
  inviteUser: (id: string) => void
}

const UserManagement = ({
  clientId,
  clientInfo,
  participantId,
  participantClients,
  users,
  listUsers,
  createUser,
  updateUser,
  deleteUser,
  inviteUser,
}: IProps) => {
  const filtersConfig = useMemo(
    () => [
      ...USER_MANAGEMENT_FILTERS_CONFIG,
      ...(participantId
        ? USER_MANAGEMENT_PARTICIPANT_FILTERS_CONFIG
        : USER_MANAGEMENT_CLIENT_FILTERS_CONFIG),
    ],
    [participantId],
  )

  const filtersDefaults = buildFiltersDefaults(filtersConfig)
  const filtersValidate = buildFiltersValidateSchema(filtersConfig)
  const [isEditModalShown, setIsEditModalShown] = useState(false)
  const [isAddModalShown, setIsAddModalShown] = useState(false)
  const [filters, setFilters] = useState(filtersDefaults)
  const [selectedUser, setSelectedUser] = useState<IUser | null>(null)
  const [isButtonLoading, setIsButtonLoading] = useState(false)
  const [isDeleteModalShow, setIsDeleteModalShow] = useState(false)

  const [orderBy, setOrderBy] = useState({
    field: 'email',
    direction: 'ASC',
  })
  const [anchorEl, setAnchorEl] = useState(null)
  const [actionsMenuOpen, setActionsMenuOpen] = useState(false)

  const { isLoading, usersData, isSaving, isSaved } = useMemo(
    () => ({
      isLoading: users.isLoading,
      isSaving: users.isSaving,
      isSaved: users.isSaved,
      usersData: users?.data?.data,
    }),
    [users],
  )

  const handleClickMenu = useCallback((user, event: React.MouseEvent<HTMLElement>) => {
    setSelectedUser(user)
    setAnchorEl(event.currentTarget)
    setActionsMenuOpen(true)
  }, [])
  const handleCloseMenu = useCallback(() => {
    setSelectedUser(null)
    setAnchorEl(null)
    setActionsMenuOpen(false)
  }, [])

  const handleDeleteUserConfirm = useCallback(async () => {
    setIsButtonLoading(true)
    await deleteUser(selectedUser.id)
    setIsButtonLoading(false)
    setIsDeleteModalShow(false)
    handleCloseMenu()
  }, [deleteUser, selectedUser, handleCloseMenu])

  const handleDeleteUserCancel = useCallback(async () => {
    setIsDeleteModalShow(false)
    handleCloseMenu()
  }, [handleCloseMenu])

  const handleDeleteUser = useCallback(() => {
    setIsDeleteModalShow(true)
  }, [])

  const handleFiltersChange = useCallback((data: any) => {
    setFilters(data)
  }, [])

  const debounceListSummary = useMemo(
    () =>
      debounceEventHandler((data: any) => {
        clientId
          ? listUsers({
              clientId,
              role: UserRole.CLIENT_USER,
              accountId: clientInfo?.accountId,
              ...data,
            })
          : listUsers({
              participantId,
              role: UserRole.PARTICIPANT_USER,
              ...data,
            })
      }, 500),
    [clientId, participantId, clientInfo, listUsers],
  )

  useEffect(() => {
    debounceListSummary({
      filters,
      orderBy: orderBy.field,
      orderDirection: orderBy.direction,
    })
  }, [filters, orderBy, debounceListSummary])

  const handleOrderChange = useCallback((field: string) => {
    setOrderBy((order) => ({
      field,
      direction: order.field === field ? (order.direction === 'DESC' ? 'ASC' : 'DESC') : 'ASC',
    }))
  }, [])

  const handleCreateUser = useCallback(
    async (data: any) => {
      setIsButtonLoading(true)
      await createUser({
        ...data,
        clientId,
        participantId,
        role: participantId ? UserRole.PARTICIPANT_USER : UserRole.CLIENT_USER,
      })
      setIsButtonLoading(false)
      setIsAddModalShown(false)
    },
    [createUser, clientId, participantId],
  )

  const handleUpdateUser = useCallback(
    async ({ id, ...data }: any) => {
      setIsButtonLoading(true)
      await updateUser(id, { ...data, clientId, participantId })
      setIsButtonLoading(false)
      setIsEditModalShown(false)
    },
    [updateUser, clientId, participantId],
  )

  return (
    <Card className={styles.card} noHeaderMargin>
      {isLoading && <Loader />}
      <Grid container>
        <Box flex={1}>
          <Card noPadding withBorder={false} noHeaderMargin>
            <Card noPadding withBorder={false} noHeaderMargin>
              <AddEditUser
                isEditModalShown={isAddModalShown}
                setIsEditModalShown={setIsAddModalShown}
                handleCreateAddUser={handleCreateUser}
                isButtonLoading={isButtonLoading}
                isParticipant={!!participantId}
                participantCompanies={participantClients}
              />
            </Card>
            <Box>
              <Card withBorder={false} noPadding noHeaderMargin>
                <TableContainer
                  className={cn(participantId ? styles.participantsTable : styles.table)}
                >
                  <Form
                    onSubmit={handleFiltersChange}
                    validate={filtersValidate}
                    initialValues={filters}
                    mutators={{
                      setFieldData: ([field, value], state, { changeValue }) => {
                        changeValue(state, field, () => value)
                      },
                    }}
                    render={({ values, handleSubmit, form: { mutators } }) => (
                      <div className={styles.filtersHeader}>
                        <FilterContainer
                          filters={filtersConfig}
                          handleSubmit={handleSubmit}
                          mutators={mutators}
                          values={values}
                          appliedFilters={filters}
                          title={`User Management`}
                          actions={
                            <AddButton
                              variant="outlined"
                              disabled={(!clientId && !participantId) || isLoading}
                              onClick={() => setIsAddModalShown(true)}
                            ></AddButton>
                          }
                        />
                      </div>
                    )}
                  />
                  <Table className={styles.tableContainerData}>
                    <TableHead>
                      <TableFiltersRow
                        filters={filtersConfig}
                        orderBy={orderBy}
                        handleOrderChange={handleOrderChange}
                      />
                    </TableHead>
                    <TableBody>
                      {isLoading && <TableLoader columnsCount={filtersDefaults.length} />}
                      {usersData?.length > 0 &&
                        usersData.map((user) => (
                          <Form
                            key={user.id}
                            initialValues={user}
                            onSubmit={handleUpdateUser}
                            mutators={mutators}
                            render={({ dirty, handleSubmit }) => (
                              <TableRow key={user.id}>
                                <TableCell className={genericSs.tableTextLeft}>
                                  {user.firstName}
                                </TableCell>
                                <TableCell className={genericSs.tableTextLeft}>
                                  {user.lastName}
                                </TableCell>
                                <TableCell className={genericSs.tableTextLeft}>
                                  <Tooltip title={user.email} arrow>
                                    <span>{user.email}</span>
                                  </Tooltip>
                                </TableCell>
                                <TableCell className={genericSs.tableTextLeft}>
                                  {user.status === UserStatus.Active ? (
                                    <span className={styles.active}>Active</span>
                                  ) : user.isInvited ? (
                                    <span className={styles.invitationSpan}>Invitation sent</span>
                                  ) : (
                                    <Button
                                      variant="text"
                                      color="primary"
                                      onClick={() => inviteUser(user.id)}
                                      className={genericSs.inlineButton}
                                    >
                                      Send invite
                                    </Button>
                                  )}
                                </TableCell>
                                {participantId ? (
                                  <TableCell className={genericSs.tableTextLeft}>
                                    {user?.participantInfoUser?.participations
                                      ?.map(({ clientName }) => clientName)
                                      ?.join(', ')}
                                  </TableCell>
                                ) : (
                                  <>
                                    <TableCell
                                      className={cn(genericSs.tableTextLeft, {
                                        [styles.authorized]: user.isAuthorizedSignature,
                                        [styles.notAuthorized]: !user.isAuthorizedSignature,
                                      })}
                                    >
                                      {user.isAuthorizedSignature ? 'Authorized' : 'Not authorized'}
                                    </TableCell>
                                    <TableCell className={genericSs.tableTextLeft}>
                                      <Field
                                        name="isReportingReminderReceiver"
                                        type="checkbox"
                                        component={Checkbox}
                                        disabled={user.status !== UserStatus.Active}
                                      />
                                    </TableCell>
                                    <TableCell className={genericSs.tableTextLeft}>
                                      <Field name="isViewer" type="checkbox" component={Checkbox} />
                                    </TableCell>
                                  </>
                                )}
                                <TableCell className={genericSs.tableTextRight}>
                                  <Box display="inline-box" ml={1}>
                                    <MenuIcon
                                      onClick={(event) => handleClickMenu(user, event)}
                                      size="small"
                                    />
                                  </Box>
                                </TableCell>
                                <OnChange name="isReportingReminderReceiver">
                                  {() => dirty && handleSubmit()}
                                </OnChange>
                                <OnChange name="isViewer">{() => dirty && handleSubmit()}</OnChange>
                              </TableRow>
                            )}
                          />
                        ))}
                    </TableBody>
                    <Menu
                      open={actionsMenuOpen}
                      anchorEl={anchorEl}
                      onClose={handleCloseMenu}
                      className={styles.actionsMenu}
                    >
                      {!participantId && (
                        <MenuItem
                          onClick={() => {
                            handleUpdateUser({
                              ...selectedUser,
                              id: selectedUser.id,
                              isAuthorizedSignature: !selectedUser.isAuthorizedSignature,
                            })

                            handleCloseMenu()
                          }}
                        >
                          {!selectedUser?.isAuthorizedSignature
                            ? 'Authorize signor'
                            : 'Deauthorize signor'}
                        </MenuItem>
                      )}
                      {selectedUser?.status !== UserStatus.Active && selectedUser?.isInvited && (
                        <MenuItem
                          onClick={() => {
                            inviteUser(selectedUser.id)
                            handleCloseMenu()
                          }}
                        >
                          Resend invitation
                        </MenuItem>
                      )}
                      <MenuItem
                        onClick={() => {
                          setIsEditModalShown(true)
                        }}
                      >
                        Edit
                      </MenuItem>
                      <MenuItem onClick={handleDeleteUser}>Delete</MenuItem>
                    </Menu>
                    <AddEditUser
                      isEditModalShown={isEditModalShown}
                      setIsEditModalShown={setIsEditModalShown}
                      handleCreateAddUser={handleUpdateUser}
                      user={selectedUser}
                      handleCloseMenu={handleCloseMenu}
                      isButtonLoading={isButtonLoading}
                      isParticipant={!!participantId}
                      participantCompanies={participantClients}
                    />
                  </Table>
                  <Grid container justifyContent={'flex-end'}>
                    <SaveState isSaving={isSaving} isSaved={isSaved} />
                  </Grid>
                </TableContainer>
              </Card>
            </Box>
          </Card>
        </Box>
      </Grid>
      {isDeleteModalShow && (
        <WarningModal
          warningMessage="Deleting this record will be permanent."
          onConfirm={handleDeleteUserConfirm}
          onCancel={handleDeleteUserCancel}
          confirmText="Yes, proceed"
          cancelText="Cancel"
          isLoading={isButtonLoading}
        />
      )}
    </Card>
  )
}

export default UserManagement
