import React, { useState } from 'react'

import styles from './MarketNewsPage.module.scss'

import Card from '../../components/Common/Card'
import MarketNewsTable from '../../components/MarketNewsTable'
import MarketNewsDetails from '../../components/MarketNewsDetails'
import { ClientNewsIndustries, INewsfeed } from '@common/interfaces/newsfeed'
import { IClientInfo } from '@common/interfaces/client'

const MarketNewsPage = () => {
  const [currentNewsItem, setCurrentNewsItem] = useState<INewsfeed | null>(null)
  const [clientIndustries, setClientIndustries] = useState<
    Record<ClientNewsIndustries, IClientInfo[]>
  >(
    Object.fromEntries(Object.values(ClientNewsIndustries).map((key) => [key, []])) as Record<
      ClientNewsIndustries,
      IClientInfo[]
    >,
  )

  return (
    <div>
      <Card
        noHeaderMargin
        noPadding
        className={styles.cardContainer}
        classes={{
          root: styles.card,
        }}
      >
        <MarketNewsTable
          setCurrentNewsItem={setCurrentNewsItem}
          currentNewsItem={currentNewsItem}
          setClientIndustries={setClientIndustries}
        />

        {currentNewsItem && (
          <MarketNewsDetails
            newsItemId={currentNewsItem.id}
            setCurrentNewsItem={setCurrentNewsItem}
            clientIndustries={clientIndustries}
          />
        )}
      </Card>
    </div>
  )
}

export default MarketNewsPage
