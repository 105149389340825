import { toggleSidebar } from '../../redux/profile/actions'
import { isClientUser, isDueDiligenceClient, isSidebarOpen } from '../../redux/profile/selectors'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import { withRouter, RouteComponentProps } from 'react-router'
import SideMenu from './SideMenu'
import { pathname } from '../../redux/router/selectors'
import { role } from '../../redux/profile/selectors'
import { logout } from '../../redux/profile/actions'
import { isPastClient, isAdminLoggedAsClient } from '../../redux/profile/selectors'

const selector = createStructuredSelector({
  pathname,
  role,
  isSidebarOpen,
  isPastClient,
  isDueDiligenceClient,
  isAdminLoggedAsClient,
  isClientUser,
})

const actions = {
  onLogout: logout,
  toggleSidebar,
}

export default withRouter<RouteComponentProps, any>(connect(selector, actions)(SideMenu))
