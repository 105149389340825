import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'

import DueDiligenceProcessDocumentsBankTransactionsPage from './DueDiligenceProcessDocumentsBankTransactionsPage'

import { dueDiligenceInfo, dueDiligenceReporting } from '../../redux/dueDiligence/selectors'
import { show, showReporting } from '../../redux/dueDiligence/actions'

const selector = createStructuredSelector({
  dueDiligenceInfo,
  dueDiligenceReportingData: dueDiligenceReporting,
})
const actions = {
  show,
  showReporting,
}

export default connect(selector, actions)(DueDiligenceProcessDocumentsBankTransactionsPage)
