import React, { useEffect, useMemo } from 'react'
import { useLocation, matchPath, useHistory } from 'react-router'
import Box from '@mui/material/Box'

import styles from './ClientSettingsPage.module.scss'

import { ROUTES } from '../../constants/routes'
import ProfileForm from '../../components/ProfileForm'
import UpdatePasswordForm from '../../components/UpdatePasswordForm'
import Integrations from '../../components/Integrations'
import NotificationSettings from '../../components/NotificationSettings'

const ClientSettingsPage = ({ isAdminLoggedAsClient }: { isAdminLoggedAsClient: boolean }) => {
  const { pathname } = useLocation()
  const history = useHistory()

  const currentTab = useMemo(
    () =>
      matchPath(pathname, ROUTES.SETTINGS_SECURITY)
        ? 'Security'
        : matchPath(pathname, ROUTES.CLIENT_SETTINGS_INTEGRATIONS)
        ? 'App integrations'
        : matchPath(pathname, ROUTES.CLIENT_SETTINGS_NOTIFICATIONS)
        ? 'Notification settings'
        : 'Profile details',
    [pathname],
  )

  useEffect(() => {
    if (isAdminLoggedAsClient) {
      history.replace(ROUTES.HOMEPAGE)
    }
  }, [isAdminLoggedAsClient, history])

  return (
    <Box display="flex" height="93vh">
      <Box flex={1} mt={10} className={styles.settingsDetails}>
        {currentTab === 'Profile details' ? (
          <ProfileForm />
        ) : currentTab === 'Security' ? (
          <UpdatePasswordForm />
        ) : currentTab === 'App integrations' ? (
          <Integrations />
        ) : currentTab === 'Notification settings' ? (
          <NotificationSettings />
        ) : null}
      </Box>
    </Box>
  )
}

export default ClientSettingsPage
