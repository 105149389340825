import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'

import BBCNotes from './BBCNotes'

import { show as showBBC } from '../../redux/bbc/actions'
import { bbc } from '../../redux/bbc/selectors'
import { updateClientItemsToMonitor } from '../../redux/clientInfo/actions'

const selector = createStructuredSelector({
  bbc,
})

const actions = {
  showBBC,
  updateClientItemsToMonitor,
}

export default connect(selector, actions)(BBCNotes)
