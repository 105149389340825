import React from 'react'

import { DueDiligenceTableItemType, IDueDiligenceContact } from '@common/interfaces/dueDiligence'
import DueDiligenceApplicationTable from '../DueDiligenceApplicationTable'
import { ILoadingData } from '../../redux/types'

interface IProps {
  dueDiligenceTeamContacts: ILoadingData<{ data: IDueDiligenceContact[] }>
  listTeamContacts: (params: object) => void
  hideTeamContacts: () => void
}

const DueDiligenceApplicationTableContacts = ({
  dueDiligenceTeamContacts,
  listTeamContacts,
  hideTeamContacts,
}: IProps) => {
  return (
    <DueDiligenceApplicationTable
      type={DueDiligenceTableItemType.Contact}
      data={dueDiligenceTeamContacts}
      handleList={listTeamContacts}
      handleHide={hideTeamContacts}
    />
  )
}

export default DueDiligenceApplicationTableContacts
