import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'

import AuditPage from './AuditPage'
import { isLoading, data, dictionary } from '../../redux/auditLog/selectors'
import { list as users } from '../../redux/user/selectors'
import { listAuditLog, exportAuditLog, getAuditLogDictionary } from '../../redux/auditLog/actions'
import { listUsers } from '../../redux/user/actions'

const selector = createStructuredSelector({
  isLoading,
  data,
  dictionary,
  users,
})

const actions = {
  listAuditLog,
  exportAuditLog,
  listUsers,
  getAuditLogDictionary,
}

export default connect(selector, actions)(AuditPage)
