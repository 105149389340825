import React from 'react'

import {
  DueDiligenceTableItemType,
  IDueDiligenceBoardMember,
} from '@common/interfaces/dueDiligence'
import DueDiligenceApplicationTable from '../DueDiligenceApplicationTable'
import { ILoadingData } from '../../redux/types'

interface IProps {
  dueDiligenceTeamBoardMembers: ILoadingData<{ data: IDueDiligenceBoardMember[] }>
  listTeamBoardMembers: (params: object) => void
  hideTeamBoardMembers: () => void
}

const DueDiligenceApplicationTableBoardMembers = ({
  dueDiligenceTeamBoardMembers,
  listTeamBoardMembers,
  hideTeamBoardMembers,
}: IProps) => {
  return (
    <DueDiligenceApplicationTable
      type={DueDiligenceTableItemType.BoardMember}
      data={dueDiligenceTeamBoardMembers}
      handleList={listTeamBoardMembers}
      handleHide={hideTeamBoardMembers}
    />
  )
}

export default DueDiligenceApplicationTableBoardMembers
