import { ISearchResult, SearchType } from '@common/interfaces/search'
import { ClientInfoStatus } from '@common/interfaces/client'

const CLIENT_STATUS_TO_SECTION_MAP = {
  [ClientInfoStatus.DueDiligence]: SearchType.DueDiligence,
  [ClientInfoStatus.Prospect]: SearchType.Prospect,
  [ClientInfoStatus.Archived]: SearchType.Prospect,
  [ClientInfoStatus.Current]: SearchType.Client,
  [ClientInfoStatus.Past]: SearchType.Client,
}

const SECTION_KEYWORDS = {
  [SearchType.Collection]: ['collection', 'wire', 'check'],
  [SearchType.Fee]: ['fee', 'interest', 'minimum'],
  [SearchType.OngoingReporting]: ['financials', 'sales', 'bank', 'financial', 'general', 'ledger'],
  [SearchType.User]: ['user', 'email', 'contact'],
  [SearchType.Entity]: ['entity', 'debtor', 'customer', 'vendor'],
  [SearchType.Funding]: ['bbc', 'funding', 'borrowing'],
  [SearchType.Note]: ['note'],
}

const reorderResultsBasedOnSection = (results: ISearchResult[], section: string) => {
  const sectionResults = results.filter((result) => result.type === section)
  const otherResults = results.filter((result) => result.type !== section)
  return [...sectionResults, ...otherResults]
}

const pushSectionsToBottom = (results: ISearchResult[], sections: string[]) => {
  const sectionResults = results.filter((result) => sections.includes(result.type))
  const otherResults = results.filter((result) => !sections.includes(result.type))
  return [...otherResults, ...sectionResults]
}

const getSectionFromSearchTerm = (searchTerm: string) => {
  const searchTermArray = searchTerm.toLowerCase().split(' ')
  const section = Object.keys(SECTION_KEYWORDS).find((section) =>
    SECTION_KEYWORDS[section].some((keyword: string) => searchTermArray.includes(keyword)),
  )
  return section
}

const seperateClientStatusResults = (results: ISearchResult[]) => {
  const diligenceMaxScore = results.find(
    (result) => result.type === SearchType.DueDiligence,
  )?.maxScore
  const clientInfoMaxScore = results.find((result) => result.type === SearchType.Client)?.maxScore
  const prospectMaxScore = results.find((result) => result.type === SearchType.Prospect)?.maxScore

  const firstSection = results.find(
    (result) =>
      result.maxScore === Math.max(diligenceMaxScore, clientInfoMaxScore, prospectMaxScore),
  )

  const firstResultStatus = firstSection?.items?.[0]?.status

  const section = CLIENT_STATUS_TO_SECTION_MAP[firstResultStatus]
  const otherSections = Object.values(CLIENT_STATUS_TO_SECTION_MAP).filter(
    (otherSection) => otherSection !== section,
  )

  if (!section) {
    return results
  }

  let reorderedResults = reorderResultsBasedOnSection(results, section)
  reorderedResults = pushSectionsToBottom(reorderedResults, otherSections)
  return reorderedResults
}

const organizeResultsBySectionBasedOnSearchTerm = (
  results: ISearchResult[],
  searchTerm: string,
) => {
  const section = getSectionFromSearchTerm(searchTerm)
  if (!section) {
    return results
  }
  return reorderResultsBasedOnSection(results, section)
}

export { seperateClientStatusResults, organizeResultsBySectionBasedOnSearchTerm }
