import React, { useState, useCallback, useMemo, useEffect } from 'react'
import Tooltip from '@mui/material/Tooltip'
import Grid from '@mui/material/Grid'
import cn from 'classnames'

import styles from './DueDiligenceApplicationTable.module.scss'
import genericSs from '@styles/generic.module.scss'

import {
  DueDiligenceTableItemType,
  IDueDiligenceBankAccount,
  IDueDiligenceBoardMember,
  IDueDiligenceContact,
  IDueDiligenceExecutive,
  IDueDiligenceInventoryLocation,
  IDueDiligenceReference,
  IDueDiligenceTableItem,
} from '@common/interfaces/dueDiligence'
import TableContainer from '../Common/TableContainer'
import Table from '../Common/Table'
import TableRow from '../Common/TableRow'
import TableHead from '../Common/TableHead'
import TableBody from '../Common/TableBody'
import TableCell from '../Common/TableCell'
import TableFiltersRow from '../Common/TableFiltersRow'
import { ILoadingData } from '../../redux/types'
import {
  DUE_DILIGENCE_FINANCIALS_BANK_ACCOUNTS_LIST_FILTERS_CONFIG,
  DUE_DILIGENCE_FINANCIALS_INVENTORY_LOCATIONS_APPLICATION_LIST_FILTERS_CONFIG,
  DUE_DILIGENCE_TEAM_BOARD_MEMBERS_LIST_FILTERS_CONFIG,
  DUE_DILIGENCE_TEAM_CONTACTS_LIST_FILTERS_CONFIG,
  DUE_DILIGENCE_TEAM_EXECUTIVES_APPLICATION_LIST_FILTERS_CONFIG,
  DUE_DILIGENCE_TEAM_REFERENCES_LIST_FILTERS_CONFIG,
} from '@common/constants/filters'
import TableLoader from '../Common/TableLoader'
import { debounceEventHandler, formatPrice, formatTextDate } from '../../helpers/helpers'
import { useParams } from 'react-router'
import { ExpandDetailIcon } from '../Common/Icons'
import { DueDiligenceInfoApplicationDataItem } from '../../pages/DueDiligenceCompanyBackgroundPage/DueDiligenceCompanyBackgroundPage'

const LABEL = {
  [DueDiligenceTableItemType.Contact]: 'contact',
  [DueDiligenceTableItemType.Executive]: 'executive',
  [DueDiligenceTableItemType.BoardMember]: 'board member',
  [DueDiligenceTableItemType.Reference]: 'reference',
  [DueDiligenceTableItemType.InventoryLocation]: 'inventory location',
  [DueDiligenceTableItemType.BankAccount]: 'bank account',
}

const FILTERS_CONFIG = {
  [DueDiligenceTableItemType.Contact]: DUE_DILIGENCE_TEAM_CONTACTS_LIST_FILTERS_CONFIG,
  [DueDiligenceTableItemType.Executive]:
    DUE_DILIGENCE_TEAM_EXECUTIVES_APPLICATION_LIST_FILTERS_CONFIG,
  [DueDiligenceTableItemType.BoardMember]: DUE_DILIGENCE_TEAM_BOARD_MEMBERS_LIST_FILTERS_CONFIG,
  [DueDiligenceTableItemType.Reference]: DUE_DILIGENCE_TEAM_REFERENCES_LIST_FILTERS_CONFIG,
  [DueDiligenceTableItemType.InventoryLocation]:
    DUE_DILIGENCE_FINANCIALS_INVENTORY_LOCATIONS_APPLICATION_LIST_FILTERS_CONFIG,
  [DueDiligenceTableItemType.BankAccount]:
    DUE_DILIGENCE_FINANCIALS_BANK_ACCOUNTS_LIST_FILTERS_CONFIG,
}

export const DueDiligenceApplicationTableDataBooleanItem = ({
  label,
  value,
  comment,
}: {
  label: string
  value: boolean
  comment?: string
}) => {
  return (
    <Grid container mx={2} pt={2} rowGap={2}>
      <Grid item xs={12} display="flex" alignItems="center" justifyContent="space-between">
        <div className={styles.booleanLabel}>{label}</div>

        <div className={styles.booleanValue}>{value ? 'Yes' : 'No'}</div>
      </Grid>
      {value && (
        <Grid item xs={12} display="flex" className={styles.booleanComment}>
          {comment || '-'}
        </Grid>
      )}
    </Grid>
  )
}

const DueDiligenceApplicationTableTeamContactRow = ({
  contact,
}: {
  contact: IDueDiligenceContact
}) => {
  return (
    <TableRow>
      <TableCell className={genericSs.tableTextLeft}>
        {contact.firstName} {contact.lastName}
      </TableCell>
      <TableCell className={genericSs.tableTextLeft}>{contact.phone}</TableCell>
      <TableCell className={genericSs.tableTextLeft}>{contact.email}</TableCell>
    </TableRow>
  )
}

const DueDiligenceApplicationTableTeamExecutiveRow = ({
  executive,
}: {
  executive: IDueDiligenceExecutive
}) => {
  const [isExpanded, setIsExpanded] = useState(false)
  const toggleExpand = useCallback(() => {
    setIsExpanded((expanded) => !expanded)
  }, [])

  return (
    <>
      <TableRow>
        <TableCell className={genericSs.tableTextCenter}>
          <ExpandDetailIcon onClick={toggleExpand} isExpanded={isExpanded} />
        </TableCell>
        <TableCell className={genericSs.tableTextLeft}>
          {executive.firstName} {executive.lastName}
        </TableCell>
        <TableCell className={genericSs.tableTextLeft}>{executive.title}</TableCell>
        <TableCell className={genericSs.tableTextLeft}>{executive.phone}</TableCell>
        <TableCell className={genericSs.tableTextLeft}>{executive.email}</TableCell>
      </TableRow>
      {isExpanded && (
        <TableRow>
          <TableCell
            className={cn(
              genericSs.nestedRowColumn,
              genericSs.tableTextLeft,
              styles.nestedRowColumn,
            )}
            colSpan={5}
          >
            <Grid container mx={2} py={2}>
              <DueDiligenceInfoApplicationDataItem
                label="Social security number"
                value={
                  executive.socialSecurityNumber
                    ? executive.socialSecurityNumber.replace(/(\d{3})(\d{2})(.*)/, '$1-$2-$3')
                    : ''
                }
                size={3}
                hidden={!!executive.socialSecurityNumber}
              />
              <DueDiligenceInfoApplicationDataItem
                label="Date of birth"
                value={executive.birthday ? formatTextDate(executive.birthday) : '-'}
                size={3}
              />
              <DueDiligenceInfoApplicationDataItem
                label="Home address"
                value={[
                  executive.street,
                  executive.city,
                  executive.state,
                  executive.postalCode,
                  executive.country,
                ]
                  .filter(Boolean)
                  .join(', ')}
                size={6}
              />
            </Grid>
            <DueDiligenceApplicationTableDataBooleanItem
              label="Has this individual ever been convicted of a felony?"
              value={executive.isConvictedFelony}
              comment={executive.isConvictedFelonyComment}
            />
            <DueDiligenceApplicationTableDataBooleanItem
              label="Has this person ever defaulted on any financial obligation?"
              value={executive.isDefaultedFinancialObligation}
              comment={executive.isDefaultedFinancialObligationComment}
            />
            <DueDiligenceApplicationTableDataBooleanItem
              label="Has this person ever filed for personal bankruptcy?"
              value={executive.isPersonalBankruptcy}
              comment={executive.isPersonalBankruptcyComment}
            />
          </TableCell>
        </TableRow>
      )}
    </>
  )
}

const DueDiligenceApplicationTableTeamBoardMemberRow = ({
  boardMember,
}: {
  boardMember: IDueDiligenceBoardMember
}) => {
  return (
    <TableRow>
      <TableCell className={genericSs.tableTextLeft}>
        {boardMember.firstName} {boardMember.lastName}
      </TableCell>
      <TableCell className={genericSs.tableTextLeft}>{boardMember.email}</TableCell>
      <TableCell className={genericSs.tableTextLeft}>{boardMember.company}</TableCell>
      <TableCell className={genericSs.tableTextLeft}>{boardMember.relationshipToCompany}</TableCell>
    </TableRow>
  )
}

const DueDiligenceApplicationTableTeamReferenceRow = ({
  reference,
}: {
  reference: IDueDiligenceReference
}) => {
  return (
    <TableRow>
      <TableCell className={genericSs.tableTextLeft}>
        {reference.firstName} {reference.lastName}
      </TableCell>
      <TableCell className={genericSs.tableTextLeft}>{reference.businessName}</TableCell>
      <TableCell className={genericSs.tableTextLeft}>{reference.email}</TableCell>
      <TableCell className={genericSs.tableTextLeft}>{reference.phone}</TableCell>
      <TableCell className={genericSs.tableTextLeft}>{reference.relationshipToCompany}</TableCell>
    </TableRow>
  )
}

const DueDiligenceApplicationTableFinancialsInventoryLocationRow = ({
  inventoryLocation,
}: {
  inventoryLocation: IDueDiligenceInventoryLocation
}) => {
  const [isExpanded, setIsExpanded] = useState(false)
  const toggleExpand = useCallback(() => {
    setIsExpanded((expanded) => !expanded)
  }, [])

  return (
    <>
      <TableRow>
        <TableCell className={genericSs.tableTextCenter}>
          <ExpandDetailIcon onClick={toggleExpand} isExpanded={isExpanded} />
        </TableCell>
        <TableCell className={genericSs.tableTextLeft}>{inventoryLocation.name}</TableCell>
        <TableCell className={genericSs.tableTextLeft}>{inventoryLocation.type}</TableCell>
        <TableCell className={genericSs.tableTextLeft}>
          <span className={genericSs.pricePrefix}>$</span>
          {formatPrice(inventoryLocation.totalInventoryAmount)}
        </TableCell>
        <TableCell className={genericSs.tableTextLeft}>
          <span className={genericSs.pricePrefix}>$</span>
          {formatPrice(inventoryLocation.finishedGoodsAmount)}
        </TableCell>
      </TableRow>
      {isExpanded && (
        <TableRow>
          <TableCell
            className={cn(
              genericSs.nestedRowColumn,
              genericSs.tableTextLeft,
              styles.nestedRowColumn,
            )}
            colSpan={5}
          >
            <Grid container mx={2} py={2}>
              <DueDiligenceInfoApplicationDataItem
                label="Mailing address"
                value={[
                  inventoryLocation.street,
                  inventoryLocation.city,
                  inventoryLocation.state,
                  inventoryLocation.postalCode,
                  inventoryLocation.country,
                ]
                  .filter(Boolean)
                  .join(', ')}
                size={4}
              />
              <DueDiligenceInfoApplicationDataItem
                label="Landlord"
                value={inventoryLocation.landlord}
                size={4}
              />
            </Grid>
          </TableCell>
        </TableRow>
      )}
    </>
  )
}

const DueDiligenceApplicationTableFinancialsBankAccountRow = ({
  bankAccount,
}: {
  bankAccount: IDueDiligenceBankAccount
}) => {
  const [isExpanded, setIsExpanded] = useState(false)
  const toggleExpand = useCallback(() => {
    setIsExpanded((expanded) => !expanded)
  }, [])

  return (
    <>
      <TableRow>
        <TableCell className={genericSs.tableTextCenter}>
          <ExpandDetailIcon onClick={toggleExpand} isExpanded={isExpanded} />
        </TableCell>
        <TableCell className={genericSs.tableTextLeft}>{bankAccount.bankName}</TableCell>
        <TableCell className={genericSs.tableTextLeft}>
          <Tooltip
            title={bankAccount.bankAccountNumber}
            arrow
            placement="top"
            classes={{
              tooltip: styles.bankAccountNumberTooltip,
              arrow: styles.bankAccountNumberTooltipArrow,
            }}
            leaveDelay={1000}
          >
            <span>{`XXXXX${bankAccount.bankAccountNumber.slice(-4)}`}</span>
          </Tooltip>
        </TableCell>
        <TableCell className={genericSs.tableTextLeft}>
          <Tooltip
            title={bankAccount.purpose}
            arrow
            placement="top-start"
            classes={{
              tooltip: styles.bankAccountNumberTooltip,
              arrow: styles.bankAccountNumberTooltipArrow,
            }}
            leaveDelay={1000}
          >
            <span>{bankAccount.purpose}</span>
          </Tooltip>
        </TableCell>
      </TableRow>
      {isExpanded && (
        <TableRow>
          <TableCell
            className={cn(
              genericSs.nestedRowColumn,
              genericSs.tableTextLeft,
              styles.nestedRowColumn,
            )}
            colSpan={5}
          >
            <Grid container mx={2} py={2}>
              <DueDiligenceInfoApplicationDataItem
                label="Wire Routing #"
                value={bankAccount.abaRoutingNumber}
                size={3}
              />
            </Grid>
          </TableCell>
        </TableRow>
      )}
    </>
  )
}

const DueDiligenceApplicationTableRow = ({
  type,
  item,
}: {
  type: DueDiligenceTableItemType
  item: IDueDiligenceTableItem
}) => {
  if (type === DueDiligenceTableItemType.Contact) {
    return <DueDiligenceApplicationTableTeamContactRow contact={item as IDueDiligenceContact} />
  }

  if (type === DueDiligenceTableItemType.Executive) {
    return (
      <DueDiligenceApplicationTableTeamExecutiveRow executive={item as IDueDiligenceExecutive} />
    )
  }

  if (type === DueDiligenceTableItemType.BoardMember) {
    return (
      <DueDiligenceApplicationTableTeamBoardMemberRow
        boardMember={item as IDueDiligenceBoardMember}
      />
    )
  }

  if (type === DueDiligenceTableItemType.Reference) {
    return (
      <DueDiligenceApplicationTableTeamReferenceRow reference={item as IDueDiligenceReference} />
    )
  }

  if (type === DueDiligenceTableItemType.InventoryLocation) {
    return (
      <DueDiligenceApplicationTableFinancialsInventoryLocationRow
        inventoryLocation={item as IDueDiligenceInventoryLocation}
      />
    )
  }

  if (type === DueDiligenceTableItemType.BankAccount) {
    return (
      <DueDiligenceApplicationTableFinancialsBankAccountRow
        bankAccount={item as IDueDiligenceBankAccount}
      />
    )
  }

  return null
}

interface IProps {
  type: DueDiligenceTableItemType
  data: ILoadingData<{
    data: IDueDiligenceTableItem[]
  }>
  handleList: (params: object, id: string) => void
  handleHide: () => void
}

const DueDiligenceApplicationTable = ({ type, data, handleList, handleHide }: IProps) => {
  const { id } = useParams<{ id: string }>()

  const [orderBy, setOrderBy] = useState({
    field: null,
    direction: 'ASC',
  })

  const handleOrderChange = useCallback((field: string) => {
    setOrderBy((order) => ({
      field,
      direction: order.field === field ? (order.direction === 'DESC' ? 'ASC' : 'DESC') : 'ASC',
    }))
  }, [])

  const debounceListItems = useMemo(
    () =>
      debounceEventHandler((params: any) => {
        if (id) {
          handleList(params, id)
        }
      }, 500),
    [handleList, id],
  )

  useEffect(() => {
    debounceListItems({
      orderBy: orderBy.field,
      orderDirection: orderBy.direction,
    })
  }, [orderBy, debounceListItems])

  useEffect(() => {
    return () => {
      handleHide()
    }
  }, [handleHide])

  const filtersConfig = useMemo(
    () =>
      FILTERS_CONFIG[type].filter(({ field }) => !['abaRoutingNumber', 'action'].includes(field)),
    [type],
  )

  return (
    <TableContainer
      className={cn(styles.tableContainer, {
        [styles.tableContainerContacts]: type === DueDiligenceTableItemType.Contact,
        [styles.tableContainerExecutives]: type === DueDiligenceTableItemType.Executive,
        [styles.tableContainerBoardMembers]: type === DueDiligenceTableItemType.BoardMember,
        [styles.tableContainerReferences]: type === DueDiligenceTableItemType.Reference,
        [styles.tableContainerInventoryLocations]:
          type === DueDiligenceTableItemType.InventoryLocation,
        [styles.tableContainerBankAccounts]: type === DueDiligenceTableItemType.BankAccount,
      })}
    >
      <Table>
        <TableHead>
          <TableFiltersRow
            filters={filtersConfig}
            orderBy={orderBy}
            handleOrderChange={handleOrderChange}
            isChildrenAtStart
          >
            {[
              DueDiligenceTableItemType.Executive,
              DueDiligenceTableItemType.InventoryLocation,
              DueDiligenceTableItemType.BankAccount,
            ].includes(type) && <TableCell />}
          </TableFiltersRow>
        </TableHead>
        <TableBody>
          {data.isLoading ? (
            <TableLoader columnsCount={filtersConfig.length} rowsCount={3} height={38} />
          ) : !data.data?.data?.length ? (
            <TableRow className={styles.emptyRow}>
              <TableCell colSpan={filtersConfig.length}>
                <div className={styles.emptyRowContainer}>
                  <div className={styles.emptyRowText}>There are no {LABEL[type]}s yet</div>
                </div>
              </TableCell>
            </TableRow>
          ) : null}
          {data.data?.data.map((item) => (
            <DueDiligenceApplicationTableRow key={item.id} type={type} item={item} />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export default DueDiligenceApplicationTable
