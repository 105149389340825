import React, { useEffect } from 'react'
import { useParams, generatePath, useHistory, matchPath } from 'react-router'
import Box from '@mui/material/Box'

import { IClientInfo } from '@common/interfaces/client'
import { ROUTES } from '../../constants/routes'
import ClientSetupHeader from '../../components/ClientSetupHeader'

interface IProps {
  clientInfo: IClientInfo
  pathname: string
}

const ClientSetupPage = ({ pathname }: IProps) => {
  const { id: clientId } = useParams<{ id?: string }>()
  const history = useHistory()

  useEffect(() => {
    if (
      matchPath(pathname, {
        path: ROUTES.CLIENT_SETUP,
        exact: true,
      })
    ) {
      history.replace(
        generatePath(ROUTES.CLIENT_SETUP_LOAN_STRUCTURE_ASSET_BASED, { id: clientId }),
      )
    }
  }, [history, pathname, clientId])

  return (
    <Box py={1} pr={2}>
      <ClientSetupHeader />
    </Box>
  )
}

export default ClientSetupPage
