import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'

import PortfolioManagerDashboard from './PortfolioManagerDashboard'

import { clients, clientsAggregation } from '../../redux/clientInfo/selectors'
import { userId, role } from '../../redux/profile/selectors'
import {
  list as listClients,
  listClientsAggregation,
  exportPortfolioReview,
} from '../../redux/clientInfo/actions'

const selector = createStructuredSelector({
  userId,
  role,
  clients,
  fullClientsAggregation: clientsAggregation,
})
const actions = {
  listClients,
  listClientsAggregation,
  exportPortfolioReview,
}

export default connect(selector, actions)(PortfolioManagerDashboard)
