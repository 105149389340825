import React, { useCallback, useEffect, useMemo, useState } from 'react'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'

import styles from './GlobalSearchPage.module.scss'
import genericSs from '@styles/generic.module.scss'

import {
  GLOBAL_SEARCH_TYPE_TITLES,
  ISearchResult,
  SEARCH_LIMIT,
  SearchType,
} from '@common/interfaces/search'
import { ExpandDetailIcon } from '../../components/Common/Icons'
import TableContainer from '../../components/Common/TableContainer'
import Table from '../../components/Common/Table'
import TableHead from '../../components/Common/TableHead'
import TableRow from '../../components/Common/TableRow'
import TableCell from '../../components/Common/TableCell'
import TableBody from '../../components/Common/TableBody'
import TableLoader from '../../components/Common/TableLoader'
import GlobalSearchPageTableRow from './GlobalSearchPageTableRow'
import { GLOBAL_SEARCH_TYPE_COLUMNS } from './GlobalSearchPage'
import GlobalSearchPageNotes from './GlobalSearchPageNotes'
import { IFilter } from '@common/constants/filters'
import cn from 'classnames'

const GlobalSearchPageSection = ({
  isLoading,
  searchResult,
  searchString,
  setViewMoreResult,
}: {
  isLoading: boolean
  searchResult: ISearchResult
  searchString: string
  setViewMoreResult: (type: SearchType) => void
}) => {
  const [isExpanded, setIsExpanded] = useState(searchResult.items.length > 0)

  const toggleIsExpanded = useCallback(() => {
    setIsExpanded((isExpanded) => !isExpanded)
  }, [])

  const columns: IFilter[] = useMemo(
    () => GLOBAL_SEARCH_TYPE_COLUMNS[searchResult.type] || [],
    [searchResult],
  )

  const handleViewMoreResults = useCallback(() => {
    setViewMoreResult(searchResult.type)
  }, [setViewMoreResult, searchResult])

  useEffect(() => {
    setIsExpanded(searchResult.items.length > 0)
  }, [searchResult.items.length])

  return (
    <Grid item xs={12} className={styles.section}>
      <div className={styles.sectionTitle}>
        {GLOBAL_SEARCH_TYPE_TITLES[searchResult.type]}
        <ExpandDetailIcon onClick={toggleIsExpanded} isExpanded={isExpanded} />
      </div>
      {isExpanded && (
        <div className={styles.sectionTable}>
          {searchResult.type === SearchType.Note ? (
            <GlobalSearchPageNotes items={searchResult.items} searchString={searchString} />
          ) : (
            <TableContainer className={styles.table}>
              <Table>
                <TableHead>
                  <TableRow>
                    {columns.map((column) => (
                      <TableCell
                        key={column.field}
                        className={cn({
                          [genericSs.tableTextLeft]: ['text'].includes(column.type),
                          [genericSs.tableTextRight]: [
                            'number',
                            'percent',
                            'amount',
                            'date',
                          ].includes(column.type),
                        })}
                      >
                        {column.title}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {isLoading ? (
                    <TableLoader columnsCount={columns.length} rowsCount={3} />
                  ) : searchResult.items.length > 0 ? (
                    searchResult.items.map((item) => (
                      <GlobalSearchPageTableRow
                        key={item.id}
                        item={item}
                        searchString={searchString}
                      />
                    ))
                  ) : (
                    <TableRow>
                      <TableCell colSpan={columns.length}>No results</TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          )}
          {searchResult.total > SEARCH_LIMIT && (
            <Box
              className={styles.viewMoreSection}
              display="flex"
              alignItems="center"
              justifyContent="space-between"
            >
              <span>1 - 3 of {searchResult.total}</span>
              <div className={styles.viewMoreLink} onClick={handleViewMoreResults}>
                View More Results
              </div>
            </Box>
          )}
        </div>
      )}
    </Grid>
  )
}

export default GlobalSearchPageSection
