import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'

import SalesChannelDistribution from './SalesChannelDistribution'

import {
  salesChannelDistribution,
  salesChannelDistributionOptions,
} from '../../redux/clientInfo/selectors'
import {
  listSalesChannelDistribution,
  listSalesChannelDistributionOptions,
  hideSalesChannelDistribution,
} from '../../redux/clientInfo/actions'

const selector = createStructuredSelector({
  salesChannelDistribution,
  salesChannelDistributionOptions,
})

const actions = {
  listSalesChannelDistribution,
  listSalesChannelDistributionOptions,
  hideSalesChannelDistribution,
}

export default connect(selector, actions)(SalesChannelDistribution)
