import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'

import DueDiligenceClientDashboard from './DueDiligenceClientDashboard'

import {
  isLoading,
  dueDiligenceInfo,
  dueDiligenceCompanyInfo,
} from '../../redux/dueDiligence/selectors'

const selector = createStructuredSelector({
  isLoading,
  dueDiligenceInfo,
  dueDiligenceCompanyInfo,
})

export default connect(selector)(DueDiligenceClientDashboard)
