import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'

import ClientAccountActivityEditModal from './ClientAccountActivityEditModal'

import { clientInfo } from '../../../redux/clientInfo/selectors'
import { updateFunding, updatePassthroughs } from '../../../redux/clientInfo/actions'
import { updateFee } from '../../../redux/fee/actions'
import { updateAdjustment } from '../../../redux/adjustment/actions'
import { loanBalanceStartDate } from '../../../redux/loanBalance/selectors'

const selector = createStructuredSelector({
  loanBalanceStartDate,
  clientInfo,
})

const actions = {
  updateFee,
  updateAdjustment,
  updateFunding,
  updatePassthroughs,
}

export default connect(selector, actions)(ClientAccountActivityEditModal)
