import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import RightSidebar from './RightSidebar'
import { isNotesShown } from '../../redux/notes/selectors'
import { reporting } from '../../redux/prospect/selectors'
import { bbc } from '../../redux/bbc/selectors'
import { ongoingReporting } from '../../redux/ongoingReporting/selectors'
import { entity } from '../../redux/entityInfo/selectors'
import { clientInfo } from '../../redux/clientInfo/selectors'
import { newFlagsCount } from '../../redux/flag/selectors'
import { getNewFlagsCount } from '../../redux/flag/actions'

const selector = createStructuredSelector({
  isNotesShown,
  reporting,
  bbc,
  entityInfo: entity,
  clientInfo,
  ongoingReporting,
  newFlagsCount,
})

const actions = {
  getNewFlagsCount,
}

export default connect(selector, actions)(RightSidebar)
