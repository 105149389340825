import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'

import DueDiligenceCompanyBackgroundModal from './DueDiligenceCompanyBackgroundModal'

import {
  dueDiligenceCompanyInfo,
  dueDiligenceDocumentRequests,
} from '../../redux/dueDiligence/selectors'
import { updateCompanyInfo } from '../../redux/dueDiligence/actions'

const selector = createStructuredSelector({
  dueDiligenceCompanyInfo,
  dueDiligenceDocumentRequests,
})
const actions = {
  updateCompanyInfo,
}

export default connect(selector, actions)(DueDiligenceCompanyBackgroundModal)
