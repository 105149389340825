import { IChecksSummaryInfo } from './../../../../common/interfaces/collection'
import { combineReducers } from 'redux'
import * as actions from './actions'
import {
  ICheckAccount,
  IWireTotals,
  IAggregationData,
  INegativeBalanceWiresData,
  IWireDataAggregation,
  ICheckData,
} from '@common/interfaces/collection'
import { IFile } from '@common/interfaces/box'
import { IDeletedItems } from '@common/interfaces/client'
import { ILoadingData } from '../../redux/types'
import { createLoadingDataReducer } from '../../helpers/redux'
import { loadingArrayReducer } from '../../helpers/helpers'

export interface ICollectionState {
  loadingArray: string[]
  checksFiles: IFile[]
  checks: ILoadingData<ICheckData>
  checksSummaryInfo: IChecksSummaryInfo
  checkAccounts: ICheckAccount[]
  wiresFiles: IFile[]
  wiresData: ILoadingData<IWireDataAggregation>
  wiresDataNegative: ILoadingData<IWireDataAggregation>
  wiresDataDeleted: ILoadingData<IWireDataAggregation>
  wiresDataTotals: ILoadingData<{ data: IWireTotals }>
  collectionStatus: string
  aggregation: IAggregationData
  deletedItems: ILoadingData<IDeletedItems>
  negativeItems: INegativeBalanceWiresData
}

export default combineReducers<ICollectionState>({
  loadingArray: loadingArrayReducer(
    [actions.prefix],
    [
      actions.LIST_MID_DAY_WIRES_NEW_REQUEST,
      actions.LIST_NEW_WIRES_REQUEST,
      actions.LIST_NEGATIVE_WIRES_REQUEST,
      actions.LIST_MID_DAY_WIRES_NEGATIVE_REQUEST,
      actions.LIST_DELETED_WIRES_REQUEST,
      actions.LIST_MID_DAY_WIRES_DELETED_REQUEST,
      actions.LIST_MID_DAY_WIRES_TOTALS_REQUEST,
      actions.LIST_AGGREGATION_ROW_REQUEST,
      actions.EXPORT_AGGREGATION_REQUEST,
      actions.DELETE_AGGREGATION_ROW_REQUEST,
      actions.UPDATE_AGGREGATION_ROW_REQUEST,
      actions.SENT_DELETED_ITEM_REQUEST,
      actions.CREATE_DELETED_ITEM_REQUEST,
    ],
  ),
  checksFiles(state: IFile[] = [], action) {
    switch (action.type) {
      case actions.LIST_CHECKS_SUCCESS:
      case actions.UPLOAD_CHECKS_SUCCESS:
        return action.data.files
      case actions.SAVE_CHECKS_SUCCESS:
        return []
      default:
        return state
    }
  },
  checks: createLoadingDataReducer<ICheckData>(
    [actions.LIST_CHECKS_REQUEST],
    [actions.UPDATE_CHECK_REQUEST, actions.DELETE_CHECK_REQUEST, actions.UPDATE_CHECKS_REQUEST],
    true,
  ),
  checksSummaryInfo(state: IChecksSummaryInfo = null, action) {
    switch (action.type) {
      case actions.GET_CHECKS_SUMMARY_INFO_SUCCESS:
        return action.data
      case actions.SAVE_CHECKS_SUCCESS:
        return []
      default:
        return state
    }
  },
  checkAccounts(state: ICheckAccount[] = [], action) {
    switch (action.type) {
      case actions.LIST_CHECK_ACCOUNTS_SUCCESS:
        return action.data
      default:
        return state
    }
  },
  wiresFiles(state: IFile[] = [], action) {
    switch (action.type) {
      case actions.LIST_WIRE_FILES_AND_STATUS_SUCCESS:
        return action.data.files
      case actions.SAVE_WIRES_SUCCESS:
      case actions.SAVE_MID_DAY_WIRES_SUCCESS:
        return []
      default:
        return state
    }
  },
  wiresData: createLoadingDataReducer<IWireDataAggregation>(
    [actions.LIST_MID_DAY_WIRES_NEW_REQUEST, actions.LIST_NEW_WIRES_REQUEST],
    [
      actions.UPDATE_WIRE_REQUEST,
      actions.DELETE_WIRE_REQUEST,
      actions.UPDATE_WIRES_REQUEST,
      actions.UPDATE_MID_DAY_WIRES_REQUEST,
      actions.DELETE_MID_DAY_WIRE_REQUEST,
      actions.UPDATE_MID_DAY_WIRE_REQUEST,
    ],
    true,
  ),
  wiresDataNegative: createLoadingDataReducer<IWireDataAggregation>(
    [actions.LIST_NEGATIVE_WIRES_REQUEST, actions.LIST_MID_DAY_WIRES_NEGATIVE_REQUEST],
    [
      actions.UPDATE_WIRE_REQUEST,
      actions.UPDATE_WIRES_REQUEST,
      actions.RESTORE_WIRE_SUCCESS,
      actions.UPDATE_MID_DAY_WIRES_REQUEST,
      actions.RESTORE_MID_DAY_WIRE_REQUEST,
      actions.UPDATE_MID_DAY_WIRE_REQUEST,
    ],
    true,
  ),
  wiresDataDeleted: createLoadingDataReducer<IWireDataAggregation>(
    [actions.LIST_DELETED_WIRES_REQUEST, actions.LIST_MID_DAY_WIRES_DELETED_REQUEST],
    [
      actions.UPDATE_WIRE_REQUEST,
      actions.UPDATE_WIRES_REQUEST,
      actions.RESTORE_WIRE_SUCCESS,
      actions.UPDATE_MID_DAY_WIRES_REQUEST,
      actions.RESTORE_MID_DAY_WIRE_REQUEST,
      actions.UPDATE_MID_DAY_WIRE_REQUEST,
    ],
    true,
  ),
  wiresDataTotals: createLoadingDataReducer<{ data: IWireTotals }>([
    actions.LIST_MID_DAY_WIRES_TOTALS_REQUEST,
  ]),
  collectionStatus(state: string = null, action) {
    switch (action.type) {
      case actions.LIST_CHECKS_SUCCESS:
      case actions.LIST_WIRE_FILES_AND_STATUS_SUCCESS:
        return action.data.status
      default:
        return state
    }
  },
  aggregation(state: IAggregationData = null, action) {
    switch (action.type) {
      case actions.LIST_AGGREGATION_SUCCESS:
        if (!action.loadMore) {
          return action.data
        } else {
          return {
            ...state,
            data: [...state.data, ...action.data.data],
          }
        }
      case actions.LIST_AGGREGATION_ROW_SUCCESS:
        return {
          data: state.data.map((item) =>
            item.clientName === action.params.clientName &&
            item.recordDate === action.params.recordDate
              ? {
                  ...item,
                  items: action.data,
                }
              : item,
          ),
          totals: state.totals,
        }
      default:
        return state
    }
  },
  deletedItems: createLoadingDataReducer<IDeletedItems>(
    [actions.LIST_DELETED_ITEMS_REQUEST],
    [actions.SENT_DELETED_ITEM_SUCCESS],
    true,
  ),
  negativeItems(state: INegativeBalanceWiresData = null, action) {
    switch (action.type) {
      case actions.LIST_NEGATIVE_ITEMS_SUCCESS:
        if (!action.loadMore) {
          return action.data
        } else {
          return {
            ...state,
            data: [...state.data, ...action.data.data],
          }
        }
      default:
        return state
    }
  },
})
