import React from 'react'

import styles from './ExportButton.module.scss'

import { ReactComponent as ExportIcon } from '@assets/images/download-alt-icon.svg'
import Button from '../../Common/Button'

interface IProps {
  isLoading: boolean
  disabled?: boolean
  handleExport: () => any
}

const ExportButton = ({ isLoading, disabled, handleExport }: IProps) => {
  return (
    <Button
      className={styles.exportButton}
      variant="outlined"
      color="primary"
      onClick={handleExport}
      disabled={isLoading || disabled}
      isLoading={isLoading}
    >
      <ExportIcon />
    </Button>
  )
}

export default ExportButton
