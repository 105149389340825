import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'

import ProspectManageTermsPage from './ProspectManageTermsPage'
import { reporting, prospectTerms } from '../../redux/prospect/selectors'
import { listProspectTerms } from '../../redux/prospect/actions'

const selector = createStructuredSelector({
  reporting,
  prospectTerms,
})
const actions = {
  listProspectTerms,
}

export default connect(selector, actions)(ProspectManageTermsPage)
