import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'

import ProspectReportingSummaryCashFlow from './ProspectReportingSummaryCashFlow'

import { cashFlowSummaryInfo } from '../../redux/prospect/selectors'
import { showCashFlowSummaryInfo } from '../../redux/prospect/actions'

const selector = createStructuredSelector({
  cashFlowSummaryInfo,
})

const actions = {
  showCashFlowSummaryInfo,
}

export default connect(selector, actions)(ProspectReportingSummaryCashFlow)
