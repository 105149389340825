import React from 'react'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'

import ClientSetupHeader from '../../components/ClientSetupHeader'
import BBCArIneligibilityTable from '../../components/BBCArIneligibilityTable'
import ReservesTable from '../../components/ReservesTable'
import { CollateralTypes } from '@common/constants/client'

const ClientSetupCollateralReceivablePage = () => {
  return (
    <Box py={1} pr={2}>
      <ClientSetupHeader />

      <Box>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <BBCArIneligibilityTable isEligibilityTable />
          </Grid>
          <Grid item xs={12}>
            <ReservesTable isClientPage type={CollateralTypes.ar} />
          </Grid>
        </Grid>
      </Box>
    </Box>
  )
}

export default ClientSetupCollateralReceivablePage
