import React, { useCallback, useState } from 'react'
import { useParams } from 'react-router'
import TableCell from '../Common/TableCell'
import Button from '../../components/Common/Button'
import styles from './ReservesTable.module.scss'
import genericSs from '@styles/generic.module.scss'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import Modal from '../Common/Modal'
import { IExtraReserve } from '@common/interfaces/bbc'
import AdjustmentsModal from './AdjustmentsModal'
import { CollateralTypes } from '@common/constants/client'
import { MenuIcon } from '../Common/Icons'

const ExtraReserveActions = ({
  item,
  isHistorical,
  isClientPage,
  updateExtraReserves,
  updateClientExtraReserves,
  refetchReservesList,
  isSaving = false,
}: {
  item: IExtraReserve
  isHistorical: boolean
  isClientPage?: boolean
  updateExtraReserves: (id: string, data: object) => Promise<void>
  updateClientExtraReserves: (id: string, data: object) => Promise<void>
  refetchReservesList: (skipLoader?: boolean) => void
  isSaving?: boolean
}) => {
  const { id } = useParams<{ id: string }>()
  const [isDeleteExtraModalShown, setIsDeleteExtraModalShown] = useState(false)
  const [isEditExtraModalShown, setIsEditExtraModalShown] = useState(false)
  const [anchorEl, setAnchorEl] = useState(null)
  const [menuOpen, setMenuOpen] = useState(false)

  const handleClickMenu = useCallback((event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
    setMenuOpen(true)
  }, [])

  const handleCloseMenu = useCallback(() => {
    setAnchorEl(null)
    setMenuOpen(false)
  }, [])

  const handleDeleteCancel = useCallback(() => {
    setIsDeleteExtraModalShown(false)
  }, [setIsDeleteExtraModalShown])

  const handleDeleteExtraReserve = useCallback(() => {
    const deleteExtraReserve = isClientPage ? updateClientExtraReserves : updateExtraReserves

    deleteExtraReserve(id, {
      creditor: item.creditor,
      priorityPayable: null,
    }).then(() => {
      setIsDeleteExtraModalShown(false)
      refetchReservesList(true)
      handleCloseMenu()
    })
  }, [
    id,
    isClientPage,
    item,
    setIsDeleteExtraModalShown,
    updateClientExtraReserves,
    updateExtraReserves,
    handleCloseMenu,
    refetchReservesList,
  ])

  const handleUpdateExtraReserve = useCallback(
    (values) => {
      const { creditor, priorityPayable, notes } = values
      if (!creditor || !priorityPayable) {
        return
      }

      const update = isClientPage ? updateClientExtraReserves : updateExtraReserves

      update(id, {
        creditor,
        priorityPayable,
        notes,
      }).then(() => {
        refetchReservesList(true)
        setIsEditExtraModalShown(false)
        handleCloseMenu()
      })
    },
    [
      id,
      isClientPage,
      updateExtraReserves,
      updateClientExtraReserves,
      refetchReservesList,
      handleCloseMenu,
    ],
  )

  return (
    <>
      <TableCell className={genericSs.tableTextRight}>
        <MenuIcon isActive={menuOpen} onClick={handleClickMenu} size="small" />
        <Menu
          classes={{ paper: styles.iconMenu }}
          open={menuOpen}
          onClose={handleCloseMenu}
          anchorEl={anchorEl}
        >
          {!isHistorical && (
            <MenuItem
              classes={{ root: styles.iconMenuItem }}
              onClick={() => {
                setIsEditExtraModalShown(true)
              }}
            >
              Edit
            </MenuItem>
          )}

          {!isHistorical && (
            <MenuItem
              classes={{ root: styles.iconMenuItem }}
              onClick={() => {
                setIsDeleteExtraModalShown(true)
              }}
            >
              Delete
            </MenuItem>
          )}
        </Menu>
      </TableCell>
      {isDeleteExtraModalShown && (
        <Modal
          size="small"
          open={isDeleteExtraModalShown}
          onCancel={handleDeleteCancel}
          title="Delete Reserve?"
          footer={[
            <Button key="cancel" color="primary" variant="contained" onClick={handleDeleteCancel}>
              Cancel
            </Button>,
            <Button
              isLoading={isSaving}
              key="submit"
              color="primary"
              variant="contained"
              onClick={handleDeleteExtraReserve}
            >
              Delete
            </Button>,
          ]}
        >
          Reserve will be deleted. Are you sure?
        </Modal>
      )}
      {isEditExtraModalShown && (
        <AdjustmentsModal
          type={CollateralTypes.ap}
          adjustmentType="edit"
          item={item}
          handleSubmit={handleUpdateExtraReserve}
          isModalShowing={isEditExtraModalShown}
          setIsModalShowing={setIsEditExtraModalShown}
          isSaving={isSaving}
        />
      )}
    </>
  )
}

export default ExtraReserveActions
