import React from 'react'
import { Radios as MuiRadios, RadiosProps } from 'mui-rff'

import { ReactComponent as CheckedRadio } from '../../../assets/images/radio-checked-icon.svg'
import { ReactComponent as UncheckedRadio } from '../../../assets/images/radio-unchecked-icon.svg'

import styles from './Radios.module.scss'

const Radios = ({
  hideDisabled = false,
  ...props
}: {
  hideDisabled?: boolean
} & RadiosProps & { className?: string }) => {
  return (
    <MuiRadios
      {...props}
      classes={{ root: styles.root }}
      icon={<UncheckedRadio className={styles.icon} />}
      checkedIcon={<CheckedRadio className={styles.icon} />}
      radioGroupProps={{
        row: true,
        classes: { root: props.className },
      }}
      formControlLabelProps={{
        classes: {
          root: styles.label,
          disabled: hideDisabled ? styles.disabled : '',
        },
      }}
    />
  )
}

export default Radios
