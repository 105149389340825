import React, { useEffect, useMemo } from 'react'

import styles from './ClientNewsfeedContainer.module.scss'

import { IClientNewsfeedData } from '@common/interfaces/newsfeed'
import { ILoadingData } from '../../redux/types'
import ClientNewsfeedItem from './ClientNewsfeedItem'
import ClientNewsfeedItemLoader from './ClientNewsfeedItemLoader'
import Card from '../../components/Common/Card'
import { ReactComponent as NoNews } from '@assets/images/no-news.svg'

interface IProps {
  clientNewsfeedData: ILoadingData<IClientNewsfeedData>
  listClientNewsfeed: () => void
}

const ClientNewsFeedContainer = ({ clientNewsfeedData, listClientNewsfeed }: IProps) => {
  const { data, isLoading } = useMemo(
    () => ({
      data: clientNewsfeedData?.data?.data,
      isLoading: clientNewsfeedData?.isLoading,
    }),
    [clientNewsfeedData],
  )

  useEffect(() => {
    listClientNewsfeed()
  }, [listClientNewsfeed])

  if (isLoading) {
    return (
      <div className={styles.container}>
        <ClientNewsfeedItemLoader />
        <ClientNewsfeedItemLoader />
        <ClientNewsfeedItemLoader />
      </div>
    )
  }

  return (
    <Card title="Industry News" className={styles.title}>
      {data?.length > 0 ? (
        data?.map((item) => <ClientNewsfeedItem key={item.id} item={item} />)
      ) : (
        <div className={styles.noNews}>
          <NoNews />
          <p>Check back later for industry news!</p>
        </div>
      )}
    </Card>
  )
}

export default ClientNewsFeedContainer
