import { combineReducers } from 'redux'

import * as actions from './actions'
import { ICapTable, ICapTableMappingData, ICapTableSummaryData } from '@common/interfaces/capTable'
import { createLoadingDataReducer } from '../../helpers/redux'
import { ILoadingData } from '../types'

export interface ICapTableState {
  loadingArray: string[]
  capTable: ICapTable[]
  capTableMapping: ILoadingData<ICapTableMappingData>
  capTableSummary: ILoadingData<ICapTableSummaryData>
}

export default combineReducers<ICapTableState>({
  loadingArray(state: string[] = [], action) {
    switch (action.type) {
      case actions.PROCESS_CAPTABLE_REQUEST:
      case actions.LIST_CAPTABLE_REQUEST:
      case actions.UPDATE_CAPTABLE_REQUEST:
      case actions.UPLOAD_CAPTABLE_REQUEST:
        return [...state, action.type]
      case actions.PROCESS_CAPTABLE_SUCCESS:
      case actions.PROCESS_CAPTABLE_FAILURE:
      case actions.LIST_CAPTABLE_SUCCESS:
      case actions.LIST_CAPTABLE_FAILURE:
      case actions.UPDATE_CAPTABLE_SUCCESS:
      case actions.UPDATE_CAPTABLE_FAILURE:
      case actions.UPLOAD_CAPTABLE_SUCCESS:
      case actions.UPLOAD_CAPTABLE_FAILURE:
        return state.filter(
          (item) =>
            item !== action.type.replace('SUCCESS', 'REQUEST').replace('FAILURE', 'REQUEST'),
        )
      default:
        return state
    }
  },
  capTable(state: ICapTable[] = [], action) {
    switch (action.type) {
      case actions.LIST_CAPTABLE_SUCCESS:
      case actions.PROCESS_CAPTABLE_SUCCESS:
        return action.data
      case actions.LIST_CAPTABLE_FAILURE:
      case actions.PROCESS_CAPTABLE_FAILURE:
        return []
      default:
        return state
    }
  },
  capTableMapping: createLoadingDataReducer<ICapTableMappingData>(
    [actions.LIST_MAPPING_REQUEST],
    [actions.UPDATE_CAP_TABLE_DETAILS_REQUEST],
    true,
    [],
    true,
  ),

  capTableSummary: createLoadingDataReducer<ICapTableSummaryData>([actions.LIST_CAPTABLE_REQUEST]),
})
