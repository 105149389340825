import { useCallback, useEffect } from 'react'

export function useBeforeUnload(
  isDirty: boolean = false,
  message: string = 'You have unsaved changes. Are you sure you want to leave?',
): void {
  const handleBeforeUnload = useCallback(
    (event: Event) => {
      if (isDirty) {
        event.preventDefault()
        const returnValue = message
        // @ts-ignore
        event.returnValue = returnValue
        return returnValue
      } else {
        return undefined
      }
    },
    [isDirty, message],
  )

  useEffect(() => {
    window.addEventListener('beforeunload', handleBeforeUnload, { capture: true })

    return () => {
      return window.removeEventListener('beforeunload', handleBeforeUnload, { capture: true })
    }
  }, [handleBeforeUnload, isDirty])
}
