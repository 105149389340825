import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'

import CollectionsReconciliation from './CollectionsReconciliation'

import {
  collectionsReconciliation,
  collectionsReconciliationOptions,
} from '../../redux/clientInfo/selectors'
import {
  listCollectionsReconciliation,
  listCollectionsReconciliationOptions,
  hideCollectionsReconciliation,
} from '../../redux/clientInfo/actions'

const selector = createStructuredSelector({
  collectionsReconciliation,
  collectionsReconciliationOptions,
})

const actions = {
  listCollectionsReconciliation,
  listCollectionsReconciliationOptions,
  hideCollectionsReconciliation,
}

export default connect(selector, actions)(CollectionsReconciliation)
