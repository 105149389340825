import Base from './base'

export default class Bank extends Base {
  create(data: { name: string }) {
    return this.apiClient.post('banks', data)
  }

  list() {
    return this.apiClient.get('banks')
  }
}
