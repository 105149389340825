import React, { useState, useCallback, useMemo, SetStateAction, Dispatch } from 'react'

import genericSs from '@styles/generic.module.scss'
import styles from './ClientTermLoanAmortization.module.scss'

import { formatDate, formatPrice } from '../../../helpers/helpers'
import { ITermLoan } from '@common/interfaces/termLoan'
import Card from '../../Common/Card'
import Table from '../../Common/Table'
import TableHead from '../../Common/TableHead'
import TableRow from '../../Common/TableRow'
import TableCell from '../../Common/TableCell'
import TableContainer from '../../Common/TableContainer'
import TableBody from '../../Common/TableBody'
import AddButton from '../../../components/Client/AddButton'
import Modal from '../../../components/Common/Modal'
import { Form } from 'react-final-form'
import { Box } from '@mui/material'
import Button from '../../../components/Common/Button'
import FormField from '../../../components/Common/FormField'
import { TermLoanActivityType } from '@common/interfaces/loanServicing'
import moment from 'moment'
import { useParams } from 'react-router'
import ClientTermLoanAmortizationAction from './ClientTermLoanAmortizationAction'
import WarningModal from '../../../components/WarningModal'
import { ITermLoanActivity } from '@common/interfaces/termLoan'
import { makeValidate } from 'mui-rff'
import * as Yup from 'yup'
import { TermLoanStatus } from '@common/interfaces/client'
import TableLoader from '../../../components/Common/TableLoader'

const ACTIVITY_TYPE_OPTIONS = [
  {
    value: TermLoanActivityType.ManualPayment,
    label: 'Principal Payment',
  },
  {
    value: TermLoanActivityType.Funding,
    label: 'Funding',
  },
  {
    value: TermLoanActivityType.Miscellaneous,
    label: 'Miscellaneous Fee',
  },
]

const CreateActivityModal = ({
  maxPrincipalPayment,
  termLoanId,
  open,
  onClose,
  createTermLoanActivity,
  minDate,
  setRefreshCounter,
}: {
  maxPrincipalPayment: number
  minDate: Date
  open: boolean
  termLoanId: string
  onClose: () => void
  createTermLoanActivity: (id: string, termLoanId: string, data: object) => void
  setRefreshCounter: Dispatch<SetStateAction<number>>
}) => {
  const { id: clientId }: { id: string } = useParams()
  const [isLoading, setIsLoading] = useState(false)
  const initialValues = useMemo(
    () => ({
      recordDate: '',
      amount: '',
      type: TermLoanActivityType.ManualPayment,
      termLoanId,
      clientId,
    }),
    [clientId, termLoanId],
  )

  const validate = useMemo(
    () =>
      makeValidate(
        Yup.object({
          type: Yup.string()
            .required('Required')
            .oneOf(ACTIVITY_TYPE_OPTIONS.map((option) => option.value)),
          recordDate: Yup.date()
            .typeError('Please type date in MM/DD/YY format')
            .min(minDate, 'Date cannot be from a closed month'),
          amount: Yup.number()
            .when('type', {
              is: TermLoanActivityType.ManualPayment,
              then: Yup.number().max(
                maxPrincipalPayment,
                `Amount must be less than or equal to $${formatPrice(maxPrincipalPayment)}`,
              ),
            })

            .required('Required')
            .min(0, 'Amount must be greater than 0'),
        }),
      ),
    [minDate, maxPrincipalPayment],
  )

  const onAddActivity = useCallback(
    async (data) => {
      setIsLoading(true)
      await createTermLoanActivity(clientId, termLoanId, data)
      setIsLoading(false)
      onClose()
      setRefreshCounter((counter: number) => counter + 1)
    },
    [createTermLoanActivity, onClose, setRefreshCounter, clientId, termLoanId],
  )

  return (
    <Modal title="Add Activity" onCancel={onClose} open={open}>
      <Form
        initialValues={initialValues}
        onSubmit={onAddActivity}
        validate={validate}
        render={({ handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            <Box display="flex" flexDirection="column" gap={0}>
              <Box flex={1}>
                <FormField name="type" label="Type" type="select" options={ACTIVITY_TYPE_OPTIONS} />
              </Box>
              <Box flex={1}>
                <FormField name="recordDate" label="Date" type="date" minDate={minDate} />
              </Box>
              <Box flex={1}>
                <FormField name="amount" label="Amount" type="currency" />
              </Box>
              <Box flex={1} mt={3}>
                <Button
                  fullWidth
                  type="submit"
                  small={false}
                  disabled={isLoading}
                  isLoading={isLoading}
                >
                  Add
                </Button>
              </Box>
            </Box>
          </form>
        )}
      />
    </Modal>
  )
}

const EditActivityModal = ({
  minDate,
  maxPrincipalPayment,
  activity,
  open,
  termLoanId,
  onClose,
  updateTermLoanActivity,
  setRefreshCounter,
}: {
  maxPrincipalPayment: number
  minDate: Date
  open: boolean
  termLoanId: string
  activity: ITermLoanActivity
  onClose: () => void
  updateTermLoanActivity: (id: string, termLoanId: string, activityId: string, data: object) => void
  setRefreshCounter: Dispatch<SetStateAction<number>>
}) => {
  const { id: clientId }: { id: string } = useParams()
  const [isLoading, setIsLoading] = useState(false)
  const initialValues = useMemo(
    () =>
      activity && {
        type: activity.type,
        recordDate: activity.recordDate,
        amount: activity.amount,
        activityId: activity.id,
      },
    [activity],
  )

  const validate = useMemo(
    () =>
      makeValidate(
        Yup.object({
          type: Yup.string()
            .required('Required')
            .oneOf(ACTIVITY_TYPE_OPTIONS.map((option) => option.value)),
          recordDate: Yup.date()
            .typeError('Please type date in MM/DD/YY format')
            .min(minDate, 'Date cannot be from a closed month'),
          amount: Yup.number()
            .when('type', {
              is: TermLoanActivityType.ManualPayment,
              then: Yup.number().max(
                maxPrincipalPayment,
                `Amount must be less than or equal to $${formatPrice(maxPrincipalPayment)}`,
              ),
            })

            .required('Required')
            .min(0, 'Amount must be greater than 0'),
        }),
      ),
    [minDate, maxPrincipalPayment],
  )

  const onEditActivity = useCallback(
    async (data) => {
      setIsLoading(true)
      await updateTermLoanActivity(clientId, termLoanId, activity.id, data)
      setIsLoading(false)
      onClose()
      setRefreshCounter((counter: number) => counter + 1)
    },
    [updateTermLoanActivity, onClose, setRefreshCounter, clientId, termLoanId, activity],
  )

  return (
    <Modal title="Edit Activity" onCancel={onClose} open={open}>
      <Form
        initialValues={initialValues}
        validate={validate}
        onSubmit={onEditActivity}
        render={({ handleSubmit, dirty }) => (
          <form onSubmit={handleSubmit}>
            <Box display="flex" flexDirection="column" gap={0}>
              <Box flex={1}>
                <FormField name="type" label="Type" type="select" options={ACTIVITY_TYPE_OPTIONS} />
              </Box>
              <Box flex={1}>
                <FormField name="recordDate" label="Date" type="date" minDate={minDate} />
              </Box>
              <Box flex={1}>
                <FormField name="amount" label="Amount" type="currency" />
              </Box>
              <Box flex={1} mt={3}>
                <Button
                  fullWidth
                  type="submit"
                  small={false}
                  disabled={isLoading || !dirty}
                  isLoading={isLoading}
                >
                  Save
                </Button>
              </Box>
            </Box>
          </form>
        )}
      />
    </Modal>
  )
}

interface IProps {
  termLoan: ITermLoan
  createTermLoanActivity: (id: string, termLoanId: string, data: object) => void
  loanBalanceStartDate: string
  setRefreshCounter: Dispatch<SetStateAction<number>>
  deleteTermLoanActivity: (id: string, termLoanId: string, activityId: string) => void
  updateTermLoanActivity: (id: string, termLoanId: string, activityId: string, data: object) => void
  inModal?: boolean
  isLoading?: boolean
}

const ClientTermLoanAmortization = ({
  termLoan,
  createTermLoanActivity,
  deleteTermLoanActivity,
  updateTermLoanActivity,
  loanBalanceStartDate,
  setRefreshCounter,
  inModal = false,
  isLoading,
}: IProps) => {
  const [isCreateModalOpen, setisCreateModalOpen] = useState(false)
  const [editActivity, setEditActivity] = useState<ITermLoanActivity | null>(null)
  const [deleteModalId, setDeleteModalId] = useState<string | null>(null)
  const [isDeleting, setIsDeleting] = useState(false)
  const { id: clientId }: { id: string } = useParams()

  const handleOpenCreateModal = useCallback(() => {
    setisCreateModalOpen(true)
  }, [])

  const onDelete = useCallback(
    (id: string) => {
      setDeleteModalId(id)
    },
    [setDeleteModalId],
  )

  const handleDeleteConfirm = useCallback(async () => {
    setIsDeleting(true)
    await deleteTermLoanActivity(clientId, termLoan.id, deleteModalId)
    setIsDeleting(false)
    setDeleteModalId(null)
    setRefreshCounter((counter: number) => counter + 1)
  }, [deleteTermLoanActivity, deleteModalId, setRefreshCounter, clientId, termLoan])

  const handleDeleteCancel = useCallback(() => {
    setDeleteModalId(null)
  }, [setDeleteModalId])

  const onEdit = useCallback(
    (activity: ITermLoanActivity) => {
      setEditActivity(activity)
    },
    [setEditActivity],
  )

  const amortizationSchedule = useMemo(() => termLoan?.amortizationSchedule, [termLoan])

  const maxPrincipalPayment = useMemo(() => termLoan.loanBalance || termLoan.loanAmount, [termLoan])

  const minDate = useMemo(
    () =>
      moment
        .max([moment(loanBalanceStartDate || undefined), moment(termLoan.closingDate)])
        .toDate(),
    [loanBalanceStartDate, termLoan.closingDate],
  )

  return (
    <Card
      title={!inModal && 'Amortization Schedule'}
      withBorder={false}
      noPadding
      renderHeaderRightColumn={() => (
        <AddButton
          variant="outlined"
          onClick={handleOpenCreateModal}
          disabled={termLoan.status === TermLoanStatus.Terminated}
        ></AddButton>
      )}
    >
      <TableContainer className={styles.table}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell className={genericSs.tableTextLeft}>Date</TableCell>
              <TableCell className={genericSs.tableTextRight}>Fundings</TableCell>
              <TableCell className={genericSs.tableTextRight}>Payments</TableCell>
              <TableCell className={genericSs.tableTextRight}>Fees</TableCell>
              <TableCell className={genericSs.tableTextRight}>Interest</TableCell>
              <TableCell className={genericSs.tableTextRight}>Balance</TableCell>
              <TableCell className={genericSs.tableTextRight}>Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody id="scrollableTable">
            {isLoading ? (
              <TableLoader columnsCount={5} rowsCount={20} />
            ) : (
              amortizationSchedule?.map((termLoanBalance, index) => {
                return (
                  <TableRow key={index}>
                    <TableCell className={genericSs.tableTextLeft}>
                      {formatDate(termLoanBalance.recordDate)}
                    </TableCell>
                    <TableCell className={genericSs.tableTextRight}>
                      ${formatPrice(termLoanBalance.fundings)}
                    </TableCell>
                    <TableCell className={genericSs.tableTextRight}>
                      ${formatPrice(termLoanBalance.payments)}
                    </TableCell>
                    <TableCell className={genericSs.tableTextRight}>
                      ${formatPrice(termLoanBalance.fees)}
                    </TableCell>
                    <TableCell className={genericSs.tableTextRight}>
                      ${formatPrice(termLoanBalance.interest)}
                    </TableCell>
                    <TableCell className={genericSs.tableTextRight}>
                      ${formatPrice(termLoanBalance.loanBalance)}
                    </TableCell>
                    <TableCell className={genericSs.tableTextRight}>
                      {termLoanBalance.activity && (
                        <ClientTermLoanAmortizationAction
                          item={termLoanBalance.activity}
                          onEdit={onEdit}
                          onDelete={onDelete}
                        />
                      )}
                    </TableCell>
                  </TableRow>
                )
              })
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <CreateActivityModal
        minDate={minDate}
        maxPrincipalPayment={maxPrincipalPayment}
        termLoanId={termLoan.id}
        open={Boolean(isCreateModalOpen)}
        onClose={() => setisCreateModalOpen(null)}
        createTermLoanActivity={createTermLoanActivity}
        setRefreshCounter={setRefreshCounter}
      />
      <EditActivityModal
        minDate={minDate}
        maxPrincipalPayment={maxPrincipalPayment}
        activity={editActivity}
        open={Boolean(editActivity)}
        onClose={() => setEditActivity(null)}
        updateTermLoanActivity={updateTermLoanActivity}
        termLoanId={termLoan.id}
        setRefreshCounter={setRefreshCounter}
      />
      {deleteModalId && (
        <WarningModal
          onCancel={handleDeleteCancel}
          onConfirm={handleDeleteConfirm}
          title="Delete Activity"
          warningMessage="Are you sure you want to delete this payment?"
          isLoading={isDeleting}
        />
      )}
    </Card>
  )
}

export default ClientTermLoanAmortization
