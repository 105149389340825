import React, { memo } from 'react'
import TableRow from '../Common/TableRow'
import TableCell from '../Common/TableCell'
import TextField from '../Common/TextField'
import { FinancialMetric, MetricValue } from '@common/interfaces/stressCase'
import genericSs from '@styles/generic.module.scss'
import { formatValue } from '../../helpers/helpers'
import InputAdornment from '@mui/material/InputAdornment'
import { Form, FormSpy } from 'react-final-form'
interface MetricRowProps {
  metric: FinancialMetric
  timeline: Array<{ year: string; label: string }>
  getValue: (yearId: string, metricId: string) => MetricValue | undefined
  onUpdateAssumption: (assumptionId: string, metricId: string, value: number) => void
}

const MetricRow: React.FC<MetricRowProps> = ({
  metric,
  timeline,
  getValue,
  onUpdateAssumption,
}) => {
  return (
    <TableRow>
      <TableCell className={genericSs.tableTextLeft}>
        <div>{metric.label}</div>
      </TableCell>
      {timeline.map((year) => {
        const value = getValue(year.year, metric.id)
        return (
          <TableCell key={`${metric.id}-${year.year}`} className={genericSs.tableTextRight}>
            {value?.isEditable ? (
              <Form
                onSubmit={(values) => {
                  const newValue = values[`${metric.id}-${year.year}`]
                  onUpdateAssumption(value.assumptionId, metric.id, parseFloat(newValue))
                }}
                initialValues={{
                  [`${metric.id}-${year.year}`]: value.value ? value.value * 100 : undefined,
                }}
                render={({ handleSubmit }) => (
                  <>
                    <FormSpy
                      subscription={{ dirty: true, values: true }}
                      onChange={(props) => {
                        if (props.dirty) {
                          handleSubmit(props.values)
                        }
                      }}
                    />
                    <TextField
                      type={'percent'}
                      name={`${metric.id}-${year.year}`}
                      InputProps={{
                        endAdornment: <InputAdornment position="end">%</InputAdornment>,
                        type: 'number',
                      }}
                    />
                  </>
                )}
              />
            ) : (
              value && (
                <div className={genericSs.bold}>
                  {formatValue({ value: value.value, type: value.type || metric.type })}
                </div>
              )
            )}
          </TableCell>
        )
      })}
    </TableRow>
  )
}

export default memo(MetricRow)
