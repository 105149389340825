import React, { useEffect, useCallback, useState, useMemo } from 'react'
import { OnChange } from 'react-final-form-listeners'
import { Form } from 'react-final-form'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import * as Yup from 'yup'
import { makeValidate } from 'mui-rff'
import cn from 'classnames'

import styles from './BBCFileInventoryHeaderMapping.module.scss'
import genericSs from '@styles/generic.module.scss'

import Card from '../Common/Card'
import TableContainer from '../Common/TableContainer/TableContainer'
import Table from '../Common/Table/Table'
import TableHead from '../Common/TableHead/TableHead'
import TableRow from '../Common/TableRow/TableRow'
import TableCell from '../Common/TableCell/TableCell'
import TableBody from '../Common/TableBody/TableBody'
import Modal from '../Common/Modal'
import Button from '../Common/Button'
import AddButton from '../Client/AddButton'
import { IFileHeaderData } from '@common/interfaces/file'
import {
  INVENTORY_INELIGIBILITY_FIELDS,
  OngoingReportingType,
  WorkflowPage,
} from '@common/interfaces/bbc'
import { ILoadingData } from '../../redux/types'
import FormField from '../Common/FormField'
import SaveState from '../Common/SaveState'
import { textToTitleCase } from '@common/helpers/helpers'
import { IFile } from '@common/interfaces/box'
import SelectedFiles from '../SelectedFiles'
import Pagination from '../Common/Pagination'
import TableLoader from '../Common/TableLoader'

const options = [
  {
    value: 'notApplicable',
    label: 'N/A',
  },
  {
    value: 'description',
    label: 'Description',
  },
  ...Object.keys(INVENTORY_INELIGIBILITY_FIELDS).map((key) => ({
    value: key,
    label: INVENTORY_INELIGIBILITY_FIELDS[key].reason,
  })),
]

type SelectedDocument = IFile & {
  fileSheetId?: string
  headerRow?: string[]
  sampleData?: { [key: string]: string }
}

interface IProps {
  id: string
  selectedDocuments: SelectedDocument[]
  listHeaderMapping: (params: object) => void
  headerMappings: ILoadingData<IFileHeaderData>
  updateHeaderMapping: (id: string, data: object) => void
  createHeaderMapping: (data: object) => void
  handleSelectMultipleFiles: (
    selectedDocuments: { id?: string; fileId: string; sheetName: string }[],
    isReselect?: boolean,
  ) => void
  fileReselecting: { id?: string; fileId: string; sheetName: string }
  defaultFileSheetId?: string
}

const BBCFileInventoryHeaderMapping = ({
  id,
  selectedDocuments,
  headerMappings,
  listHeaderMapping,
  updateHeaderMapping,
  createHeaderMapping,
  handleSelectMultipleFiles,
  fileReselecting,
  defaultFileSheetId,
}: IProps) => {
  const [isCreateModalShown, setIsCreateModalShown] = useState(false)
  const [selectedDocument, setSelectedDocument] = useState<SelectedDocument | null>(null)
  const [refreshCounter, setRefreshCounter] = useState(0)

  useEffect(() => {
    if (!selectedDocument && selectedDocuments?.length > 0) {
      if (defaultFileSheetId) {
        const selectedDocument = selectedDocuments.find(
          ({ fileSheetId }) => fileSheetId === defaultFileSheetId,
        )
        if (selectedDocument) {
          setSelectedDocument(selectedDocument)
          return
        }
      }

      setSelectedDocument(selectedDocuments[0])
    }
  }, [selectedDocument, selectedDocuments, defaultFileSheetId])

  useEffect(() => {
    setSelectedDocument((selectedDocument) =>
      selectedDocument
        ? selectedDocuments.find(
            ({ id, sheetName }) =>
              id === selectedDocument.id && sheetName === selectedDocument.sheetName,
          )
        : null,
    )
  }, [selectedDocuments])

  useEffect(() => {
    id &&
      listHeaderMapping({
        id,
        fileType: OngoingReportingType.Inventory,
        workflow: WorkflowPage.borrowingbase,
      })
  }, [id, listHeaderMapping, refreshCounter])

  const handleCreateHeaderMapping = useCallback(
    async (values) => {
      const data = {
        ...values,
        fileType: OngoingReportingType.Inventory,
        workflow: WorkflowPage.borrowingbase,
        id,
      }
      await createHeaderMapping(data)
      setIsCreateModalShown(false)
    },
    [createHeaderMapping, id],
  )

  const handleUpdateHeaderMapping = useCallback(
    (values) => {
      updateHeaderMapping(values.id, {
        ...values,
        fileType: OngoingReportingType.Inventory,
      })
    },
    [updateHeaderMapping],
  )

  const { headers, isLoading, isSaving, isSaved } = useMemo(
    () => ({
      headers: headerMappings?.data?.data || [],
      isLoading: headerMappings?.isLoading,
      isSaving: headerMappings?.isSaving,
      isSaved: headerMappings?.isSaved,
    }),
    [headerMappings],
  )

  const currentHeaders = useMemo(() => {
    if (!headers || !selectedDocument) {
      return headers
    }

    return headers
      .filter(
        ({ borrowingBaseId, fileHeaderName }) =>
          (!selectedDocument.headerRow?.length && !borrowingBaseId) ||
          (selectedDocument.headerRow || []).includes(fileHeaderName),
      )
      .map((item) => ({
        ...item,
        sampleData: selectedDocument.sampleData?.[item.fileHeaderName] || null,
      }))
  }, [headers, selectedDocument])

  const initialValues = useMemo(() => {
    return currentHeaders?.reduce((acc, header) => {
      acc[header.id] = {
        id: header.id,
        mappedColumnName: header.mappedColumnName,
      }
      return acc
    }, {})
  }, [currentHeaders])

  const existingHeaders = useMemo(
    () => currentHeaders?.map((header) => header.fileHeaderName),
    [currentHeaders],
  )

  const mappedColumnNames = useMemo(
    () => currentHeaders?.map((header) => header.mappedColumnName),
    [currentHeaders],
  )

  const validate = useMemo(() => {
    return makeValidate(
      Yup.object().shape({
        fileHeaderName: Yup.string()
          .required('Required')
          .trim()
          .lowercase()
          .notOneOf(existingHeaders, 'Header already exists'),
        mappedColumnName: Yup.string().required('Required'),
      }),
    )
  }, [existingHeaders])

  const handleOpenCreateModal = useCallback(() => {
    setIsCreateModalShown(true)
  }, [])

  const handleCloseCreateModal = useCallback(() => {
    setIsCreateModalShown(false)
  }, [])

  const updatedOptions = useMemo(() => {
    return options.map((option) => {
      if (mappedColumnNames.includes(option.value) && option.value !== 'notApplicable') {
        return {
          ...option,
          disabled: true,
        }
      }
      return option
    })
  }, [mappedColumnNames])

  const currentSelectedDocumentIndex = useMemo(() => {
    if (!selectedDocuments?.length || !selectedDocument) {
      return 0
    }
    const index = selectedDocuments?.findIndex(
      ({ fileSheetId }) => selectedDocument.fileSheetId === fileSheetId,
    )

    if (index === -1) {
      return 0
    }

    return index
  }, [selectedDocuments, selectedDocument])

  const handleChangeCurrentSelectedDocument = useCallback(
    (event: React.ChangeEvent<unknown>, page: number) => {
      const nextSelectedDocument = selectedDocuments[page - 1]
      if (nextSelectedDocument) {
        setSelectedDocument(nextSelectedDocument)
      }
    },
    [selectedDocuments],
  )

  const handleSelectFiles = useCallback(
    async (
      documents: {
        id?: string
        fileId: string
        sheetName: string
        fileSheetId?: string
        isReselect?: boolean
      }[],
      isReselect?: boolean,
    ) => {
      if (!selectedDocument) {
        return
      }

      if (!isReselect) {
        await handleSelectMultipleFiles(
          selectedDocuments
            .filter(
              ({ id: fileId, sheetName: fileSheetName }) =>
                fileId !== selectedDocument.fileId && fileSheetName !== selectedDocument.sheetName,
            )
            .map(({ id: fileId, sheetName: fileSheetName, fileSheetId }) => ({
              id: fileSheetId,
              fileId,
              sheetName: fileSheetName,
              isReselect: false,
            })),
        )
        setRefreshCounter((refreshCounter) => refreshCounter + 1)
        return
      }

      const [currentSelectedDocument] = documents

      await handleSelectMultipleFiles(
        selectedDocuments.map(({ id: fileId, sheetName: fileSheetName, fileSheetId }) =>
          fileId === currentSelectedDocument.fileId &&
          fileSheetName === currentSelectedDocument.sheetName
            ? currentSelectedDocument
            : {
                id: fileSheetId,
                fileId,
                sheetName: fileSheetName,
                isReselect: false,
              },
        ),
        isReselect,
      )
      setRefreshCounter((refreshCounter) => refreshCounter + 1)
    },
    [selectedDocument, selectedDocuments, handleSelectMultipleFiles],
  )

  const onSave = useCallback(() => {
    if (selectedDocument) {
      handleSelectFiles(
        [
          {
            fileId: selectedDocument.id,
            id: selectedDocument.fileSheetId,
            sheetName: selectedDocument.sheetName,
            isReselect: true,
          },
        ],
        true,
      )
    }
  }, [selectedDocument, handleSelectFiles])

  return (
    <>
      <SelectedFiles
        selectedDocuments={selectedDocument ? [selectedDocument] : []}
        handleSelectMultipleFiles={handleSelectFiles}
        fileReselecting={fileReselecting}
      />
      <Card
        noHeaderMargin
        noPadding
        withBorder={false}
        title={
          <Box display="flex" justifyContent="flex-end" alignItems="center">
            <AddButton variant="outlined" disabled={!id} onClick={handleOpenCreateModal} />
          </Box>
        }
      >
        <Box flex={1}>
          <Card withBorder={false} noPadding noHeaderMargin>
            <Form
              onSubmit={handleCreateHeaderMapping}
              validate={validate}
              render={({ dirty, submitting, invalid, form, handleSubmit }) => (
                <form>
                  <Modal
                    open={isCreateModalShown}
                    onCancel={handleCloseCreateModal}
                    size="small"
                    title="Add Column Mapping"
                    footer={[
                      <Button
                        key="submit"
                        color="primary"
                        small={false}
                        variant="contained"
                        disabled={!dirty || invalid || submitting}
                        onClick={async () => {
                          await handleSubmit()
                          form.reset()
                        }}
                        fullWidth
                      >
                        Add
                      </Button>,
                    ]}
                  >
                    <Box>
                      <FormField
                        name="fileHeaderName"
                        label="Column Name"
                        type="text"
                        labelSize="small"
                      />
                      <FormField
                        name="mappedColumnName"
                        label="Mapped Column Name"
                        options={options}
                        type="select"
                      />
                    </Box>
                  </Modal>
                </form>
              )}
            />
          </Card>

          <Card withBorder={false} noPadding noHeaderMargin>
            <TableContainer
              className={cn(styles.table, {
                [styles.tableWithMinHeight]: currentHeaders?.length,
              })}
            >
              {(isLoading || currentHeaders?.length > 0) && (
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell className={genericSs.tableTextLeft}>File Column</TableCell>
                      <TableCell className={genericSs.tableTextLeft}>Mapped Column</TableCell>
                      <TableCell className={genericSs.tableTextLeft}>Sample Data</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {isLoading ? (
                      <TableLoader columnsCount={3} />
                    ) : (
                      currentHeaders.map((header) => (
                        <Form
                          key={header.id}
                          initialValues={initialValues[header.id]}
                          onSubmit={handleUpdateHeaderMapping}
                          render={({ dirty, handleSubmit }) => (
                            <TableRow>
                              <TableCell className={genericSs.tableTextLeft}>
                                {textToTitleCase(header.fileHeaderName)}
                              </TableCell>
                              <TableCell className={genericSs.tableTextLeft}>
                                <FormField
                                  name="mappedColumnName"
                                  label=""
                                  options={updatedOptions}
                                  type="select"
                                  size="small"
                                />
                              </TableCell>
                              <TableCell className={genericSs.tableTextLeft}>
                                {header.sampleData}
                              </TableCell>

                              <OnChange name="mappedColumnName">
                                {() => dirty && handleSubmit()}
                              </OnChange>
                            </TableRow>
                          )}
                        />
                      ))
                    )}
                  </TableBody>
                </Table>
              )}

              {selectedDocuments.length > 1 && (
                <div className={styles.paginationWrapper}>
                  <Pagination
                    count={selectedDocuments.length}
                    page={currentSelectedDocumentIndex + 1}
                    onChange={handleChangeCurrentSelectedDocument}
                    variant="outlined"
                    shape="rounded"
                    color="primary"
                    size="small"
                  />
                </div>
              )}

              {currentHeaders?.length > 0 && (
                <Grid container justifyContent={'space-between'} alignItems={'center'}>
                  <SaveState isSaving={isSaving} isSaved={isSaved} />
                  <Button onClick={onSave} className={styles.saveButton}>
                    Save
                  </Button>
                </Grid>
              )}
            </TableContainer>
          </Card>
        </Box>
      </Card>
    </>
  )
}

export default BBCFileInventoryHeaderMapping
