import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import BBCFileInventoryHeaderMapping from './BBCFileInventoryHeaderMapping'
import {
  createHeaderMapping,
  listHeaderMapping,
  updateHeaderMapping,
} from '../../redux/file/actions'
import { headerMappings } from '../../redux/file/selectors'

const selector = createStructuredSelector({
  headerMappings,
})

const actions = {
  listHeaderMapping,
  updateHeaderMapping,
  createHeaderMapping,
}

export default connect(selector, actions)(BBCFileInventoryHeaderMapping)
