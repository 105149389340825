import Base from './base'

export default class Activity extends Base {
  list(data: object) {
    return this.apiClient.get('activities', data)
  }

  create(data: object) {
    return this.apiClient.post('activities', data)
  }

  update(id: string, data: object) {
    return this.apiClient.put(`activities/${id}`, data)
  }

  delete(id: string) {
    return this.apiClient.delete(`activities/${id}`)
  }

  track(data: object, beacon?: boolean) {
    if (beacon) {
      return this.apiClient.beacon('activities/track', data)
    }
    return this.apiClient.post('activities/track', data)
  }
}
