import { IParticipant } from './participant'

export enum NotificationSettingType {
  AutoReportingConnected = 'AutoReportingConnected',
  Notes = 'Notes',
  FeesAndAdjustments = 'FeesAndAdjustments',
  Collections = 'Collections',
  PassThrough = 'PassThroughs',
  Funding = 'Funding',
  Activities = 'Activities',
  SubmitReporting = 'SubmitReporting',
  ClientIntake = 'ClientIntake',
  ClientApplication = 'ClientApplication',
  ClientSettings = 'ClientSettings',
}

export interface IUserNotificationSettings {
  email: NotificationSettingType[]
  push: NotificationSettingType[]
}

export interface IUser {
  id: string
  firstName: string
  lastName: string
  email: string
  status: UserStatus
  role: UserRole
  phone: string
  calendlyURL: string
  avatar: string
  isInvited: boolean
  isProspectUser: boolean
  isAdminLoggedAsClient: boolean
  isNotified: boolean
  isPrimary: boolean
  isViewer: boolean
  isAuthorizedSignature: boolean
  isReportingReminderReceiver: boolean
  clientInfoUsers?: any[]
  salesforceId?: string
  password?: string
  confirmPassword?: string
  notificationSettings: IUserNotificationSettings
  clientName?: string
  clientId?: string
  clientIconUrl?: string
  participantInfoUser: IParticipant
}

export enum UserStatus {
  New = 'NEW',
  Active = 'ACTIVE',
}

export enum UserRole {
  ADMIN = 'ADMIN',
  PORTFOLIO_ADMIN = 'PORTFOLIO_ADMIN',
  PORTFOLIO_USER = 'PORTFOLIO_USER',
  CLIENT_USER = 'CLIENT_USER',
  PARTICIPANT_USER = 'PARTICIPANT_USER',
  BDO = 'BUSINESS_DEVELOPMENT_USER',
  UW_USER = 'UW_USER',
}

export enum AdminUserNames {
  BD_ADMIN = 'Ben Brachot',
}

export const USER_ROLE_LABEL = {
  [UserRole.ADMIN]: 'System Admin',
  [UserRole.PORTFOLIO_ADMIN]: 'Relationship Manager',
  [UserRole.PORTFOLIO_USER]: 'Ops User',
  [UserRole.CLIENT_USER]: 'Client',
  [UserRole.BDO]: 'Business Development User',
  [UserRole.UW_USER]: 'Under Writer User',
}

export const USER_ROLE_LABEL_SHORT = {
  [UserRole.ADMIN]: 'Admin',
  [UserRole.PORTFOLIO_ADMIN]: 'RM',
  [UserRole.PORTFOLIO_USER]: 'Ops',
  [UserRole.CLIENT_USER]: 'Client',
  [UserRole.BDO]: 'BDO',
  [UserRole.UW_USER]: 'UW',
}

export const userRoleGroups = {
  ADMINS: [UserRole.ADMIN],
  UW_USERS: [UserRole.UW_USER, UserRole.ADMIN],
  PORTFOLIO_ADMINS: [UserRole.PORTFOLIO_ADMIN, UserRole.ADMIN, UserRole.UW_USER],
  PORTFOLIO: [UserRole.PORTFOLIO_ADMIN, UserRole.PORTFOLIO_USER, UserRole.ADMIN, UserRole.UW_USER],
  PORTFOLIO_AND_PARTICIPANT: [
    UserRole.PORTFOLIO_ADMIN,
    UserRole.PORTFOLIO_USER,
    UserRole.ADMIN,
    UserRole.UW_USER,
    UserRole.PARTICIPANT_USER,
  ],
  CLIENTS: [UserRole.CLIENT_USER],
  BDOS: [UserRole.BDO, UserRole.ADMIN],
  BDOS_AND_UW: [UserRole.BDO, UserRole.UW_USER, UserRole.ADMIN],
  INTERNAL: [
    UserRole.PORTFOLIO_ADMIN,
    UserRole.PORTFOLIO_USER,
    UserRole.ADMIN,
    UserRole.UW_USER,
    UserRole.BDO,
  ],
  DD: [UserRole.ADMIN, UserRole.UW_USER, UserRole.CLIENT_USER],
  DD_AND_PARTICIPANT: [
    UserRole.ADMIN,
    UserRole.UW_USER,
    UserRole.CLIENT_USER,
    UserRole.PARTICIPANT_USER,
  ],
  ALL: [
    UserRole.ADMIN,
    UserRole.PORTFOLIO_ADMIN,
    UserRole.PORTFOLIO_USER,
    UserRole.CLIENT_USER,
    UserRole.BDO,
    UserRole.UW_USER,
    UserRole.PARTICIPANT_USER,
  ],
  INTERNAL_AND_CLIENTS: [
    UserRole.ADMIN,
    UserRole.PORTFOLIO_ADMIN,
    UserRole.PORTFOLIO_USER,
    UserRole.CLIENT_USER,
    UserRole.BDO,
    UserRole.UW_USER,
  ],
  INTERNAL_AND_PARTICIPANTS: [
    UserRole.ADMIN,
    UserRole.PORTFOLIO_ADMIN,
    UserRole.PORTFOLIO_USER,
    UserRole.PARTICIPANT_USER,
    UserRole.BDO,
    UserRole.UW_USER,
  ],
  ALL_EXCEPT_BDO: [
    UserRole.ADMIN,
    UserRole.PORTFOLIO_ADMIN,
    UserRole.PORTFOLIO_USER,
    UserRole.CLIENT_USER,
    UserRole.UW_USER,
    UserRole.PARTICIPANT_USER,
  ],
}

export const NOTIFICATION_SETTING_LABEL = {
  [NotificationSettingType.AutoReportingConnected]: 'Auto reporting',
  [NotificationSettingType.Notes]: 'Notes',
  [NotificationSettingType.FeesAndAdjustments]: 'Fees and Adjustments',
  [NotificationSettingType.Collections]: 'Collections',
  [NotificationSettingType.PassThrough]: 'Pass-throughs',
  [NotificationSettingType.Funding]: 'Fundings',
  [NotificationSettingType.Activities]: 'Activities',
  [NotificationSettingType.SubmitReporting]: 'Ongoing reporting',
  [NotificationSettingType.ClientIntake]: 'Client intake',
  [NotificationSettingType.ClientApplication]: 'Client Application',
  [NotificationSettingType.ClientSettings]: 'Client Settings',
}

export const USER_ROLE_NOTIFICATION_SETTINGS = {
  [UserRole.ADMIN]: [
    NotificationSettingType.AutoReportingConnected,
    NotificationSettingType.Notes,
    NotificationSettingType.FeesAndAdjustments,
    NotificationSettingType.ClientSettings,
    NotificationSettingType.Collections,
    NotificationSettingType.PassThrough,
    NotificationSettingType.Funding,
    NotificationSettingType.Activities,
    NotificationSettingType.SubmitReporting,
    NotificationSettingType.ClientIntake,
    NotificationSettingType.ClientApplication,
  ],
  [UserRole.PORTFOLIO_ADMIN]: [
    NotificationSettingType.AutoReportingConnected,
    NotificationSettingType.Notes,
    NotificationSettingType.FeesAndAdjustments,
    NotificationSettingType.ClientSettings,
    NotificationSettingType.Collections,
    NotificationSettingType.PassThrough,
    NotificationSettingType.Funding,
    NotificationSettingType.Activities,
    NotificationSettingType.SubmitReporting,
    NotificationSettingType.ClientSettings,
  ],
  [UserRole.PORTFOLIO_USER]: [
    NotificationSettingType.AutoReportingConnected,
    NotificationSettingType.Notes,
    NotificationSettingType.FeesAndAdjustments,
    NotificationSettingType.ClientSettings,
    NotificationSettingType.Collections,
    NotificationSettingType.PassThrough,
    NotificationSettingType.Funding,
    NotificationSettingType.Activities,
    NotificationSettingType.SubmitReporting,
  ],
  [UserRole.BDO]: [
    NotificationSettingType.AutoReportingConnected,
    NotificationSettingType.Notes,
    NotificationSettingType.ClientIntake,
  ],
  [UserRole.UW_USER]: [
    NotificationSettingType.AutoReportingConnected,
    NotificationSettingType.Notes,
    NotificationSettingType.ClientApplication,
  ],
  [UserRole.CLIENT_USER]: [NotificationSettingType.ClientApplication],
}
