import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'

import Participations from './Participations'

import { participantParticipations } from '../../redux/participant/selectors'
import { listParticipantParticipations } from '../../redux/participant/actions'

const selector = createStructuredSelector({
  participantParticipations,
})

const actions = {
  listParticipantParticipations,
}

export default connect(selector, actions)(Participations)
