import React, { useCallback, useMemo } from 'react'
import { Select as MuiSelect, SelectProps } from 'mui-rff'
import { default as MuiCoreSelect } from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import cn from 'classnames'

import styles from './SelectField.module.scss'

import { ReactComponent as ArrowDropDown } from '../../../assets/images/filter-down-icon.svg'

const MenuProps = {
  // add size
  PaperProps: {
    style: {
      maxHeight: '40vh',
    },
  },

  transformOrigin: {
    vertical: 'top' as const,
    horizontal: 'left' as const,
  },
  anchorOrigin: {
    vertical: 'bottom' as const,
    horizontal: 'left' as const,
  },

  getContentAnchorEl: null as null,
  classes: { paper: styles.menuPaper, list: styles.menuList },
  style: { zIndex: 1501 },
}

interface IOption {
  value: string
  label: string
  disabled?: boolean
}

interface IProps extends SelectProps {
  useFinalForm?: boolean
  options: IOption[]
  placeholder?: string
  align?: string
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void
  className?: string
  isFilterMenu?: boolean
  handleSubmit: (values: any) => void
  appliedFilters?: any
  values?: any
  withTopLabel?: boolean
}

const SelectField = ({
  useFinalForm = true,
  options,
  placeholder,
  onChange,
  align,
  className,
  handleSubmit,
  isFilterMenu = false,
  appliedFilters,
  values,
  withTopLabel,
  label,
  name,
  startAdornment,
  ...props
}: IProps) => {
  const Component = useFinalForm ? MuiSelect : MuiCoreSelect
  const extraProps: any = {}
  if (!useFinalForm) {
    extraProps.onChange = onChange
  }

  const isFilterMenuClass = isFilterMenu ? styles.filterMenu : ''
  const isFilterMenuItemClass = isFilterMenu ? styles.filterMenuItem : ''
  const [open, setOpen] = React.useState(false)

  const handleClose = useCallback(() => {
    setOpen(false)
    handleSubmit(values)
  }, [handleSubmit, values])

  const handleOpen = useCallback(() => {
    setOpen(true)
  }, [])

  const handleRenderValue = useCallback(
    (selected: string[] | string) => {
      if (Array.isArray(selected) && selected.length > 0) {
        return selected
          .map((item) => options.find(({ value }) => value === item)?.label || item)
          .join(', ')
      } else if (typeof selected === 'string' && selected) {
        return options.find(({ value }) => value === selected)?.label || selected
      } else {
        return <span className={styles.placeholder}> {open ? '' : placeholder}</span>
      }
    },
    [options, placeholder, open],
  )

  const isSelected = useMemo(() => values?.[name]?.length > 0, [values, name])

  const isTopLabelShown = useMemo(() => {
    return (withTopLabel && isSelected) || open
  }, [withTopLabel, isSelected, open])

  return (
    <div className={cn(styles.filterBox, { [styles.filterBoxBorder]: !isTopLabelShown })}>
      <Component
        {...props}
        {...extraProps}
        displayEmpty
        disableCloseOnSelect
        className={cn('commonFilter', styles.root, className, isFilterMenuClass, {
          [styles.withTopLabel]: isTopLabelShown,
          [styles.borderSelected]: isSelected,
        })}
        disableUnderline={true}
        variant={isTopLabelShown ? 'outlined' : 'standard'}
        IconComponent={ArrowDropDown}
        MenuProps={MenuProps}
        renderValue={handleRenderValue}
        classes={{
          input: styles.input,
          select: styles.focused,
        }}
        open={open}
        onClose={handleClose}
        onOpen={handleOpen}
        onBlur={handleSubmit}
        label={label}
        name={name}
        startAdornment={isSelected || open ? null : startAdornment}
      >
        {options.map(({ value, label, disabled }) => (
          <MenuItem
            key={value}
            value={value}
            disabled={disabled}
            classes={{ selected: styles.selected }}
            className={cn('commonFilter', isFilterMenuItemClass)}
          >
            {label}
          </MenuItem>
        ))}
      </Component>
    </div>
  )
}

export default SelectField
