import React from 'react'
import Skeleton from '@mui/material/Skeleton'
import Card from '../../components/Common//Card'
import Grid from '@mui/material/Grid'
const RequestFundingPageLoader = () => {
  return (
    <>
      <Grid container xs={12} spacing={3}>
        <Grid item xs={3}>
          <Skeleton height={200} />
        </Grid>
        <Grid item xs={3}>
          <Skeleton height={200} />
        </Grid>
        <Grid item xs={3}>
          <Skeleton height={200} />
        </Grid>
        <Grid item xs={3}>
          <Skeleton height={200} />
        </Grid>
      </Grid>

      <Grid container xs={12} spacing={3}>
        <Grid item xs={9}>
          <Card>
            <Grid container xs={12} spacing={3}>
              <Grid item xs={6}>
                <Skeleton width={120} height={24} />
              </Grid>
              <Grid item xs={6}>
                <Skeleton width={120} height={24} />
              </Grid>
            </Grid>
            <br />
            <br />
            <Grid container xs={12} spacing={3}>
              <Grid item xs={6}>
                <Skeleton height={24} width={250} />
                <br />
                <Skeleton height={24} width={250} />
                <br />
                <Skeleton height={24} width={350} />
                <br />
                <br />
                <br />
                <Skeleton height={48} width={100} />
              </Grid>
              <Grid item xs={6}>
                <Skeleton height={80} />
                <Skeleton height={80} />
                <Skeleton height={80} />
              </Grid>
            </Grid>
          </Card>
        </Grid>
        <Grid container item xs={3}>
          <Card>
            <Grid container item xs={12} justifyContent={'center'} rowGap={2}>
              <Grid item container xs={12} justifyContent={'center'}>
                <Skeleton width={225} height={24} />
              </Grid>
              <Grid item container xs={12} justifyContent={'center'}>
                <Skeleton variant="circular" width={80} height={80} />
              </Grid>
              <Grid item container xs={12} justifyContent={'center'}>
                <Skeleton width={50} height={24} />
              </Grid>
              <Grid item container xs={12} justifyContent={'center'}>
                <Skeleton width={100} height={24} />
              </Grid>
              <Grid item container xs={12}>
                <Skeleton width={150} height={24} />
              </Grid>
              <Grid item container xs={12}>
                <Skeleton width={120} height={24} />
              </Grid>
              <Grid item container xs={12}>
                <Skeleton width={120} height={24} />
              </Grid>
            </Grid>
          </Card>
        </Grid>
      </Grid>
    </>
  )
}

export default RequestFundingPageLoader
