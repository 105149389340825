import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'

import ClientBanners from './ClientBanners'
import { banners } from '../../redux/clientInfo/selectors'
import { closeBanner } from '../../redux/clientInfo/actions'

const selector = createStructuredSelector({
  banners,
})

const actions = {
  closeBanner,
}

export default connect(selector, actions)(ClientBanners)
