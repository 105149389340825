import { IDueDiligence } from '@common/interfaces/dueDiligence'
import { Grid, Skeleton } from '@mui/material'
import React, { useMemo } from 'react'
import styles from '../LoanCommitteeReportOverviewPage.module.scss'
import { formatDateYear } from '../../../helpers/helpers'
import { stringAvatar } from '../../../components/Notes/helpers/helpers'

interface IProps {
  dueDiligenceInfo: IDueDiligence
  isLoading: boolean
}

const HeaderLoader = ({
  isLoading,
  value,
  nullValue,
  formatValue,
}: {
  isLoading: boolean
  value: string
  nullValue: string
  formatValue?: (value: string) => string
}) => {
  const valueToShow = useMemo(() => {
    if (value) {
      return formatValue ? formatValue(value) : value
    }
    return nullValue
  }, [value, nullValue, formatValue])

  return isLoading ? <Skeleton width={120} /> : <>{valueToShow}</>
}

const CompanySummary = ({ dueDiligenceInfo, isLoading }: IProps) => {
  const initials = useMemo(() => {
    const name = dueDiligenceInfo?.clientName || ''
    return name.split(' ').length > 1 ? `${name.split(' ')[0][0]}${name.split(' ')[1][0]}` : name[0]
  }, [dueDiligenceInfo])

  return (
    <div className={styles.headerContainer}>
      <div className={styles.avatarGridContainer}>
        {isLoading ? (
          <Skeleton width={140} height={140} />
        ) : (
          dueDiligenceInfo && (
            <div className={styles.avatar} {...stringAvatar(dueDiligenceInfo?.clientName)}>
              {dueDiligenceInfo?.iconUrl ? (
                <div className={styles.avatarContainer}>
                  <img className={styles.avatarImg} src={dueDiligenceInfo?.iconUrl} alt="logo" />
                </div>
              ) : (
                <div className={styles.avatarText}>{initials}</div>
              )}
            </div>
          )
        )}
      </div>
      <div className={styles.contentContainer}>
        <Grid container spacing={1}>
          <Grid container item xs={12} spacing={0}>
            <Grid item xs={12 / 7}>
              <div className={styles.headerTitle}>Industry</div>
              <div className={styles.headerValue}>
                <HeaderLoader
                  isLoading={isLoading}
                  value={dueDiligenceInfo?.industry}
                  nullValue="-"
                />
              </div>
            </Grid>
            <Grid item xs={12 / 7}>
              <div className={styles.headerTitle}>Year Founded</div>
              <div className={styles.headerValue}>
                <HeaderLoader
                  isLoading={isLoading}
                  value={dueDiligenceInfo?.dueDiligenceClientInfo?.establishedDate}
                  nullValue="-"
                  formatValue={formatDateYear}
                />
              </div>
            </Grid>
            <Grid item xs={12 / 7}>
              <div className={styles.headerTitle}>Headquarters</div>
              <div className={styles.headerValue}>
                <HeaderLoader
                  isLoading={isLoading}
                  value={dueDiligenceInfo?.dueDiligenceClientInfo?.billingState}
                  nullValue="-"
                />
              </div>
            </Grid>
            <Grid item xs={12 / 7}>
              <div className={styles.headerTitle}>Employees</div>
              <div className={styles.headerValue}>
                <HeaderLoader
                  isLoading={isLoading}
                  value={dueDiligenceInfo?.dueDiligenceClientInfo?.employeeCount?.toString()}
                  nullValue="-"
                />
              </div>
            </Grid>
            <Grid item xs={12 / 7}>
              <div className={styles.headerTitle}>BDO</div>
              <div className={styles.headerValue}>
                {dueDiligenceInfo?.salesforceProspectInfo?.user?.firstName || '-'}
              </div>
            </Grid>
            <Grid item xs={12 / 7}>
              <div className={styles.headerTitle}>Contact</div>
              <div className={styles.headerValue}>
                {dueDiligenceInfo?.primaryContact ? (
                  <a
                    href={dueDiligenceInfo?.primaryContact?.salesforceLink}
                    target="_blank"
                    rel="noreferrer"
                    className={styles.link}
                  >
                    {dueDiligenceInfo?.primaryContact?.name}
                  </a>
                ) : (
                  '-'
                )}
              </div>
            </Grid>
          </Grid>
        </Grid>
      </div>
    </div>
  )
}

export default CompanySummary
