import React, { useMemo } from 'react'
import OutstandingParticipationWires from '../../components/OutstandingParticipationWires'
import ParticipantDashboardHeader from '../../components/ParticipantDashboardHeader'
import Grid from '@mui/material/Grid'
import { useLoadInfo } from '../../hooks/useLoadInfo'
import { useSetPageTitle } from '../../hooks/useSetPageTitle'
import { IParticipant } from '@common/interfaces/participant'
import { ILoadingData } from '../../redux/types'
import { useParams } from 'react-router'
import Participations from '../../components/ParticipantParticipations'

interface IProps {
  participantInfo: ILoadingData<IParticipant>
  showParticipant: (id: string) => void
}

const ParticipantPage = ({ participantInfo, showParticipant }: IProps) => {
  const { id } = useParams<{ id: string }>()

  const { data: participantInfoData } = useMemo(() => participantInfo, [participantInfo])

  useLoadInfo({ id, info: participantInfoData, show: showParticipant })

  useSetPageTitle(participantInfoData?.participant || '')

  if (!participantInfoData) {
    return null
  }

  return (
    <Grid container py={3} pr={2}>
      <ParticipantDashboardHeader />
      <Participations participantId={participantInfoData?.id} />
      <OutstandingParticipationWires participantId={participantInfoData?.id} />
    </Grid>
  )
}

export default ParticipantPage
