import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'

import CapTablePage from './CapTablePage'

import { ongoingReporting, isLoading } from '../../redux/ongoingReporting/selectors'
import { capTableMapping } from '../../redux/capTable/selectors'
import { show as showClient } from '../../redux/clientInfo/actions'

import {
  showOngoingReporting,
  updateOngoingReportingStatus,
} from '../../redux/ongoingReporting/actions'

import { processCapTable, listCapTable, listMapping } from '../../redux/capTable/actions'

const selector = createStructuredSelector({
  ongoingReporting,
  isLoading,
  capTableMapping,
})

const actions = {
  showOngoingReporting,
  listCapTable,
  processCapTable,
  listMapping,
  showClient,
  updateOngoingReportingStatus,
}

export { CapTablePage }

export default connect(selector, actions)(CapTablePage)
