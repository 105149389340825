import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'

import DueDiligenceProcessDocumentsListPage from './DueDiligenceProcessDocumentsListPage'

import {
  isLoading,
  dueDiligenceInfo,
  dueDiligenceReportingFlows,
  refreshCount,
} from '../../redux/dueDiligence/selectors'
import {
  show,
  showDocuments,
  hideDocuments,
  processDocuments,
  uploadDocuments,
  updateDocument,
  unprocessDocument,
  refreshAutoReporting,
  deleteDocument,
  createDocument,
} from '../../redux/dueDiligence/actions'
import { list as banksAccountData } from '../../redux/bankAccount/selectors'
import { listBankAccounts, createBankAccount } from '../../redux/bankAccount/actions'
import { list as banks } from '../../redux/bank/selectors'
import { createBank, listBanks } from '../../redux/bank/actions'
import { downloadTemplate } from '../../redux/bbc/actions'
import { putNotification } from '../../redux/ui/actions'

const selector = createStructuredSelector({
  isLoadingInfo: isLoading,
  dueDiligenceInfo,
  dueDiligenceReportingFlowsData: dueDiligenceReportingFlows,
  banks,
  banksAccountData,
  refreshCount,
})
const actions = {
  show,
  showDocuments,
  hideDocuments,
  processDocuments,
  uploadDocuments,
  updateDocument,
  unprocessDocument,
  listBankAccounts,
  createBankAccount,
  listBanks,
  createBank,
  downloadTemplate,
  putNotification,
  refreshAutoReporting,
  deleteDocument,
  createDocument,
}

export default connect(selector, actions)(DueDiligenceProcessDocumentsListPage)
