import React from 'react'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import ClientAccountActivity from '../Client/ClientAccountActivity'

const ClientDashboard = () => {
  return (
    <Box py={1} pr={2}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <ClientAccountActivity />
        </Grid>
      </Grid>
    </Box>
  )
}

export default ClientDashboard
