import React, { useCallback, useMemo } from 'react'
import { DatePicker, DatePickerProps } from '@mui/x-date-pickers/DatePicker'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import TextFieldCustom from '../TextField'
import { TextField } from '@mui/material'

import { Field, FieldProps, FieldRenderProps } from 'react-final-form'
import { showErrorOnChange, ShowErrorFunc } from 'mui-rff'
import cn from 'classnames'
import { parseISO } from 'date-fns'
import moment from 'moment'

import styles from './KeyboardDatePicker.module.scss'

import { ReactComponent as CalendarIcon } from '../../../assets/images/calendar-icon.svg'
import { Skeleton } from '@mui/material'

interface KeyboardDatePickerProps extends Partial<Omit<DatePickerProps<any, any>, 'onChange'>> {
  name: string
  placeholder?: string
  fieldProps?: Partial<FieldProps<any, any>>
  showError?: ShowErrorFunc
  useFinalForm?: boolean
  value?: any
  size?: 'small' | 'medium' | 'large'
  onChange?: (event: any) => void
  onBlur?: () => void
  withTopLabel?: boolean
  isLoading?: boolean
  calendarSelectOnly?: boolean
}

interface DatePickerWrapperProps extends FieldRenderProps<DatePickerProps<any, any>, HTMLElement> {
  calendarSelectOnly?: boolean
}

const KeyboardDatePickerWrapper = (props: DatePickerWrapperProps) => {
  const {
    className,
    placeholder = '',
    input: { name, onChange, value, ...restInput },
    meta = {},
    showError = showErrorOnChange,
    onBlur,
    withTopLabel,
    label,
    calendarSelectOnly = false,
    ...rest
  } = props

  const { error, submitError } = meta
  const isError = showError({ meta })
  const customOnChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      if (event.target.value && onChange) {
        if (new RegExp(`\\d{4}-\\d{2}-\\d{2}`).test(event.target.value)) {
          onChange(moment(event.target.value, 'YYYY-MM-DD').toDate())
          return
        }
      }
    },
    [onChange],
  )

  const handleInputClick = useCallback(
    (e: React.MouseEvent<HTMLDivElement>) => {
      if (!calendarSelectOnly) return

      const pickerButton = e.currentTarget.querySelector('.MuiIconButton-root') as HTMLElement
      pickerButton?.click()
      e.preventDefault()
    },
    [calendarSelectOnly],
  )

  const TextFieldComponent = useMemo(() => {
    return !calendarSelectOnly ? TextField : TextFieldCustom
  }, [calendarSelectOnly])

  const { helperText, ...lessRest } = rest
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} className={cn('commonFilter')}>
      <DatePicker
        className={cn(className, {
          [styles.calendarSelectOnly]: calendarSelectOnly,
        })}
        components={{
          OpenPickerIcon: CalendarIcon,
        }}
        renderInput={({ InputProps, ...renderProps }) => {
          const { endAdornment } = InputProps || {}

          return (
            <TextFieldComponent
              name={name}
              {...renderProps}
              {...restInput}
              error={isError}
              InputProps={{
                ...InputProps,
                classes: {
                  error: styles.inputError,
                },
                readOnly: calendarSelectOnly,
                endAdornment: endAdornment,
                onClick: handleInputClick,
              }}
              inputProps={{
                ...renderProps.inputProps,
                placeholder: value ? undefined : placeholder,
                tabIndex: calendarSelectOnly ? -1 : 0,
              }}
              helperText={isError ? error || submitError : helperText}
              variant={withTopLabel ? 'outlined' : 'standard'}
              onBlur={onBlur}
              label={withTopLabel ? label : undefined}
              onChange={customOnChange}
            />
          )
        }}
        onChange={onChange}
        value={
          !value || (value as any) === ''
            ? null
            : typeof value === 'string'
            ? parseISO(value as any)
            : value
        }
        InputAdornmentProps={{
          position: 'start',
        }}
        {...lessRest}
      />
    </LocalizationProvider>
  )
}

const KeyboardDatePicker = (props: KeyboardDatePickerProps) => {
  const {
    className = '',
    name,
    fieldProps,
    useFinalForm = true,
    onChange,
    value,
    size = 'small',
    withTopLabel,
    isLoading,
    ...rest
  } = props

  if (isLoading) {
    return <Skeleton variant="rectangular" width="150px" height="36px" />
  }

  if (!useFinalForm) {
    return (
      <KeyboardDatePickerWrapper
        {...rest}
        // @ts-ignore
        input={{
          name,
          onChange,
          value,
        }}
        className={cn(className, {
          [styles.big]: size === 'large',
          [styles.root]: size === 'small',
          [styles.withTopLabel]: withTopLabel,
        })}
        withTopLabel={withTopLabel}
      />
    )
  }

  return (
    <Field
      name={name}
      render={({ input, ...fieldRenderProps }) => {
        const handleOpen = () => input.onFocus()
        const handleClose = () => input.onBlur()

        return (
          <KeyboardDatePickerWrapper
            {...fieldRenderProps}
            {...rest}
            input={input}
            className={cn(className, {
              [styles.big]: size === 'large',
              [styles.root]: size === 'small',
              [styles.withTopLabel]: withTopLabel,
            })}
            onOpen={handleOpen}
            onClose={handleClose}
            withTopLabel={withTopLabel}
          />
        )
      }}
      {...fieldProps}
    />
  )
}

export default KeyboardDatePicker
