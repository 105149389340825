import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'

import ProspectAnalysisQueuePage from './ProspectAnalysisQueuePage'

import { reporting, refreshCount } from '../../redux/prospect/selectors'
import { startReporting, updateReporting } from '../../redux/prospect/actions'
import { putNotification } from '../../redux/ui/actions'
import { deleteFile } from '../../redux/file/actions'

const selector = createStructuredSelector({
  reporting,
  refreshCount,
})

const actions = {
  startReporting,
  putNotification,
  updateReporting,
  deleteFile,
}

export default connect(selector, actions)(ProspectAnalysisQueuePage)
