import { IApi } from '../../api'
import { CommonPromiseAction } from '../types'

export const prefix = 'visualizationsTracking'

export const TRACK_VISUALIZATIONS_REQUEST = `${prefix}/TRACK_VISUALIZATIONS_REQUEST`
export const TRACK_VISUALIZATIONS_SUCCESS = `${prefix}/TRACK_VISUALIZATIONS_SUCCESS`
export const TRACK_VISUALIZATIONS_FAILURE = `${prefix}/TRACK_VISUALIZATIONS_FAILURE`

export const trackVisualizations: CommonPromiseAction = (
  event: string,
  category: string,
  data: object,
) => ({
  type: [TRACK_VISUALIZATIONS_REQUEST, TRACK_VISUALIZATIONS_SUCCESS, TRACK_VISUALIZATIONS_FAILURE],
  promise: (api: IApi) =>
    api.visualizationsTracking.track({
      event,
      category,
      ...(data || {}),
    }),
})
