import { connect } from 'react-redux'
import { createStructuredSelector, createSelector } from 'reselect'

import RelationshipManager from './RelationshipManager'

import { isLoading, RMUsers, managers } from '../../../redux/clientInfo/selectors'
import {
  listRelationshipManagers,
  listClientManagers,
  addManager,
  removeManager,
  notifyManager,
  updateManager,
} from '../../../redux/clientInfo/actions'

const primaryManagerId = createSelector(
  managers,
  (users) => users?.data?.data.find(({ isPrimary }) => Boolean(isPrimary))?.id,
)

const selector = createStructuredSelector({
  isLoading,
  relationshipManagers: RMUsers,
  managers,
  primaryManagerId,
})

const actions = {
  listRelationshipManagers,
  listClientManagers,
  addManager,
  removeManager,
  notifyManager,
  updateManager,
}

export default connect(selector, actions)(RelationshipManager)
