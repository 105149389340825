import React from 'react'
import Grid from '@mui/material/Grid'
import Skeleton from '@mui/material/Skeleton'
import Card from '../../components/Common/Card'

const DueDiligenceCompanyBackgroundPageSkeleton = () => {
  // Helper function to create skeleton rows
  const createSkeletonRow = (count: number) => {
    return Array(count)
      .fill(null)
      .map((_, index) => (
        <Grid item xs={4} key={index}>
          <Skeleton variant="text" width="40%" height={20} sx={{ mb: 1 }} />
          <Skeleton variant="text" width="100%" height={24} />
        </Grid>
      ))
  }

  return (
    <div>
      <Grid container item xs={12}>
        <Grid item xs={12} mb={2} px={2}>
          <Card title="Background">
            <Grid container mx={2} py={2}>
              {createSkeletonRow(3)}
            </Grid>
            <Grid container mx={2} py={2}>
              {createSkeletonRow(3)}
            </Grid>
            <Grid container mx={2} py={2}>
              {createSkeletonRow(2)}
            </Grid>

            {/* Boolean items skeleton */}
            <Grid container mx={2} pt={2}>
              {Array(3)
                .fill(null)
                .map((_, index) => (
                  <Grid item xs={12} key={index} mb={3}>
                    <Grid container alignItems="center" justifyContent="space-between">
                      <Skeleton variant="text" width="60%" height={24} />
                      <Skeleton variant="text" width="15%" height={24} />
                    </Grid>
                    <Skeleton variant="text" width="100%" height={20} sx={{ mt: 1 }} />
                  </Grid>
                ))}
            </Grid>
          </Card>
        </Grid>
      </Grid>
    </div>
  )
}

export default DueDiligenceCompanyBackgroundPageSkeleton
