import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'

import BBCArIneligibilityTable from './BBCArIneligibilityTable'

import { bbc, arIneligibilityData } from '../../redux/bbc/selectors'
import { listArIneligibility, updateArIneligibility } from '../../redux/bbc/actions'
import { listArEligibility } from '../../redux/clientInfo/actions'
import { arEligibilityData } from '../../redux/clientInfo/selectors'

const selector = createStructuredSelector({
  bbc,
  arIneligibilityData,
  arEligibilityData,
})

const actions = {
  listArIneligibility,
  updateArIneligibility,
  listArEligibility,
}

export default connect(selector, actions)(BBCArIneligibilityTable)
