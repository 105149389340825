import React from 'react'
import Tooltip from '@mui/material/Tooltip'

export const maxOutAt99WithTooltip = (value: number) => {
  if (value > 99) {
    return (
      <Tooltip title={value} placement="top">
        <span>99+</span>
      </Tooltip>
    )
  }
  return value
}
