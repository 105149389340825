import React from 'react'
import { generatePath, Link } from 'react-router-dom'
import Box from '@mui/material/Box'
import MuiAvatar from '@mui/material/Avatar'
import LinkButton from '@mui/material/Link'

import styles from './GlobalSearchPage.module.scss'
import genericSs from '@styles/generic.module.scss'

import { IFee } from '@common/interfaces/loanServicing'
import TableRow from '../../components/Common/TableRow'
import TableCell from '../../components/Common/TableCell'
import { ROUTES } from '../../constants/routes'
import { formatDate, formatPrice } from '../../helpers/helpers'
import { replaceJSX } from './GlobalSearchPage'

const GlobalSearchPageTableRowFee = ({
  fee,
  searchString,
}: {
  fee: IFee
  searchString: string
}) => {
  return (
    <TableRow>
      <TableCell className={genericSs.tableTextLeft}>
        <Box display="flex" alignItems="center" gap={2}>
          {fee.clientIconUrl && (
            <MuiAvatar
              src={fee.clientIconUrl}
              alt={fee.clientName}
              className={styles.clientAvatar}
            />
          )}
          <LinkButton component={Link} to={generatePath(ROUTES.CLIENT_PAGE, { id: fee.clientId })}>
            {replaceJSX(fee.clientName, searchString)}
          </LinkButton>
        </Box>
      </TableCell>
      <TableCell className={genericSs.tableTextRight}>
        {replaceJSX(formatDate(fee.recordDate), searchString)}
      </TableCell>
      <TableCell className={genericSs.tableTextRight}>
        <span className={genericSs.pricePrefix}>$</span>
        {replaceJSX(formatPrice(fee.amount), searchString)}
      </TableCell>
      <TableCell className={genericSs.tableTextLeft}>
        {replaceJSX(fee.type, searchString)}
      </TableCell>
      <TableCell className={genericSs.tableTextLeft}>
        {replaceJSX(fee.title || '-', searchString)}
      </TableCell>
    </TableRow>
  )
}

export default GlobalSearchPageTableRowFee
