import React from 'react'
import Box from '@mui/material/Box'
import InsuranceInformation from '../../components/Client/InsuranceInformation'

import ClientSetupHeader from '../../components/ClientSetupHeader'
import RequiredReporting from '../../components/Client/RequiredReporting'

const ClientSetupReportingPage = () => {
  return (
    <Box py={1} pr={2}>
      <ClientSetupHeader />

      <Box>
        <RequiredReporting />
        <br />
        <InsuranceInformation />
      </Box>
    </Box>
  )
}

export default ClientSetupReportingPage
