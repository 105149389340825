import { combineReducers } from 'redux'
import * as actions from './actions'
import { AlertColor } from '@mui/material/Alert'
import { SnackbarOrigin } from '@mui/material/Snackbar'

export interface IUiState {
  notification: INotificationState
  title: string
}

interface INotificationState {
  isOpen: boolean
  type: AlertColor
  code: string
  message: string
  messagesList: string[]
  anchorOrigin: SnackbarOrigin
  autoHideDuration: number
}

const notificationInitialState = {
  isOpen: false,
  type: 'success' as AlertColor,
  code: '',
  message: '',
  messagesList: [] as any,
  anchorOrigin: {
    vertical: 'top',
    horizontal: 'right',
  } as SnackbarOrigin,
  autoHideDuration: 7000,
}

export default combineReducers<IUiState>({
  notification(state: INotificationState = notificationInitialState, action) {
    switch (action.type) {
      case actions.PUT_NOTIFICATION:
        return { ...state, ...action.payload }
      case actions.REMOVE_NOTIFICATION:
        return notificationInitialState
      default:
        return state
    }
  },
  title(state = '', action) {
    switch (action.type) {
      case actions.CHANGE_PAGE_TITLE:
        return action.payload.title
      default:
        return state
    }
  },
})
