import ProspectDashboardHeader from './ProspectDashboardHeader'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'

import { reporting, refreshCount } from '../../redux/prospect/selectors'
import { show, pullReports } from '../../redux/prospect/actions'
import { pathname } from '../../redux/router/selectors'

const selector = createStructuredSelector({
  reporting,
  pathname,
  refreshCount,
})
const actions = {
  show,
  pullReports,
}

export default connect(selector, actions)(ProspectDashboardHeader)
