import React from 'react'
import MuiSelect, { SelectProps } from '@mui/material/Select'

import styles from './Select.module.scss'

const ExtraMenuProps = {
  anchorOrigin: {
    vertical: 'bottom' as const,
    horizontal: 'left' as const,
  },
  transformOrigin: {
    vertical: 'top' as const,
    horizontal: 'left' as const,
  },
  getContentAnchorEl: null as null,
}

const Select = ({ MenuProps, ...props }: SelectProps) => (
  <MuiSelect
    {...props}
    MenuProps={{ ...MenuProps, ...ExtraMenuProps }}
    classes={{ select: styles.select }}
    disableUnderline
    variant="standard"
  />
)

export default Select
