import React from 'react'
import { IField } from '@common/interfaces/client'
import FormField from '../Common/FormField'
import { ClientTerms, OtherClientTerms } from '@common/interfaces/client'
import { formatDate, formatPrice, formatPercent, formatAmount } from '../../helpers/helpers'

export const getFieldValue = (
  term: any,
  name: string,
  type: string,
  scale: number = 100,
  options?: { value: string; label: string }[],
) => {
  const formattedValue = term?.[name]?.toString()
  if (formattedValue === undefined) return '-'
  switch (type) {
    case 'date':
      return formatDate(formattedValue)
    case 'currency':
      return `$${formatPrice(formattedValue)}`
    case 'percent':
      return formatPercent(+formattedValue, 2, scale)
    case 'number':
      return (+formattedValue).toFixed(2)
    case 'select':
      return options?.find((option) => option.value === formattedValue)?.label
    case 'amount':
      return `$${formatAmount(formattedValue)}`
    default:
      return formattedValue
  }
}

interface IClientABLField extends IField {
  name: keyof ClientTerms | keyof OtherClientTerms
}

export const ClientABLField = ({
  label,
  name,
  type,
  disabled,
  options,
  placeholder,
  ...props
}: IClientABLField) => {
  return (
    <FormField
      label={label}
      name={name as string}
      type={type}
      disabled={disabled}
      options={options}
      placeholder={placeholder}
      {...props}
    />
  )
}

export interface IClientOveradvanceField extends IField {}

export const ClientOveradvanceField = ({
  label,
  name,
  type,
  disabled,
  options,
  ...props
}: IClientOveradvanceField) => {
  return (
    <FormField
      label={label}
      name={name as string}
      type={type}
      disabled={disabled}
      options={options}
      {...props}
    />
  )
}

export interface IClientTermLoanField extends IField {}

export const ClientTermLoanField = ({
  label,
  name,
  type,
  disabled,
  options,
  ...props
}: IClientTermLoanField) => {
  return (
    <FormField
      label={label}
      name={name as string}
      type={type}
      disabled={disabled}
      options={options}
      {...props}
    />
  )
}
