import React, { useCallback, useEffect, useState } from 'react'
import { useHistory, useLocation } from 'react-router'
import queryString from 'query-string'
import Grid from '@mui/material/Grid'

import styles from './DueDiligenceClientDashboard.module.scss'

import {
  DueDiligenceDocumentRequestStep,
  IDueDiligence,
  IDueDiligenceCompanyInfo,
} from '@common/interfaces/dueDiligence'
import { formatTextDate } from '../../helpers/helpers'
import DueDiligenceClientDashboardLoader from './DueDiligenceClientDashboardLoader'
import DueDiligenceClientDashboardQuestions from './DueDiligenceClientDashboardQuestions'
import DueDiligenceClientDashboardProgressCard from './DueDiligenceClientDashboardProgressCard'
import DueDiligenceClientDashboardProgress from './DueDiligenceClientDashboardProgress'
import DueDiligenceCompanyBackgroundModal from '../DueDiligenceCompanyBackgroundModal'
import DueDiligenceTeamModal from '../DueDiligenceTeamModal'
import DueDiligenceFinancialsModal from '../DueDiligenceFinancialsModal'

interface IProps {
  isLoading: boolean
  dueDiligenceInfo: IDueDiligence
  dueDiligenceCompanyInfo: IDueDiligenceCompanyInfo
}

const DueDiligenceClientDashboard = ({
  isLoading,
  dueDiligenceInfo,
  dueDiligenceCompanyInfo,
}: IProps) => {
  const history = useHistory()
  const { search }: { search: string } = useLocation()
  const { modal, step } = queryString.parse(search) as { modal: string; step: string }

  const [isCompanyBackgroundModalShown, setIsCompanyBackgroundModalShown] = useState(false)
  const [isTeamModalShown, setIsTeamModalShown] = useState(false)
  const [isFinancialsModalShown, setIsFinancialsModalShown] = useState(false)

  const handleOpenCompanyBackgroundModal = useCallback(() => {
    history.replace({
      search: queryString.stringify({
        modal: DueDiligenceDocumentRequestStep.CompanyBackground,
      }),
    })
    setIsCompanyBackgroundModalShown(true)
  }, [history])

  const handleCloseCompanyBackgroundModal = useCallback(() => {
    history.replace({
      search: null,
    })
    setIsCompanyBackgroundModalShown(false)
  }, [history])

  const handleOpenTeamModal = useCallback(() => {
    history.replace({
      search: queryString.stringify({
        modal: DueDiligenceDocumentRequestStep.Team,
      }),
    })
    setIsTeamModalShown(true)
  }, [history])

  const handleCloseTeamModal = useCallback(() => {
    history.replace({
      search: null,
    })
    setIsTeamModalShown(false)
  }, [history])

  const handleOpenFinancialsModal = useCallback(() => {
    history.replace({
      search: queryString.stringify({
        modal: DueDiligenceDocumentRequestStep.Financials,
      }),
    })
    setIsFinancialsModalShown(true)
  }, [history])

  const handleCloseFinancialsModal = useCallback(() => {
    history.replace({
      search: null,
    })
    setIsFinancialsModalShown(false)
  }, [history])

  useEffect(() => {
    if (
      modal === DueDiligenceDocumentRequestStep.CompanyBackground &&
      !isCompanyBackgroundModalShown
    ) {
      setIsCompanyBackgroundModalShown(true)
    }
    if (modal === DueDiligenceDocumentRequestStep.Team && !isTeamModalShown) {
      setIsTeamModalShown(true)
    }
    if (modal === DueDiligenceDocumentRequestStep.Financials && !isFinancialsModalShown) {
      setIsFinancialsModalShown(true)
    }
  }, [modal, isCompanyBackgroundModalShown, isTeamModalShown, isFinancialsModalShown])

  if (!dueDiligenceInfo || !dueDiligenceCompanyInfo || isLoading) {
    return <DueDiligenceClientDashboardLoader />
  }

  return (
    <Grid container py={3} pr={2} alignItems="flex-start" justifyContent="center" rowSpacing={4}>
      <Grid container item xs={12} alignItems="center" justifyContent="center" gap={3} mt={4}>
        <div className={styles.diligenceProgress}>
          <div className={styles.diligenceProgressTitle}>Diligence Progress</div>

          <div className={styles.diligenceProgressSteps}>
            <DueDiligenceClientDashboardProgress dueDiligenceInfo={dueDiligenceInfo} />
          </div>

          <div className={styles.diligenceProgressDateContainer}>
            <div className={styles.diligenceProgressDateTextContainer}>
              <div className={styles.diligenceProgressDateLabel}>Target funding date</div>
              <div className={styles.diligenceProgressDate}>
                {dueDiligenceInfo?.salesforceDDInfo?.closeDate
                  ? formatTextDate(dueDiligenceInfo?.salesforceDDInfo?.closeDate)
                  : ''}
              </div>
            </div>
          </div>
        </div>

        <div className={styles.diligenceContainer}>
          <div className={styles.diligenceProgressCards}>
            <DueDiligenceClientDashboardProgressCard
              progress={dueDiligenceCompanyInfo.companyBackgroundProgress}
              title="Company Background"
              onClick={handleOpenCompanyBackgroundModal}
            />
            <DueDiligenceClientDashboardProgressCard
              progress={dueDiligenceCompanyInfo.teamProgress}
              title={`${dueDiligenceInfo.clientName} Team`}
              onClick={handleOpenTeamModal}
            />
            <DueDiligenceClientDashboardProgressCard
              progress={dueDiligenceCompanyInfo.financialCollateralProgress}
              title="Financial & Collateral"
              onClick={handleOpenFinancialsModal}
            />
          </div>

          <DueDiligenceClientDashboardQuestions managers={dueDiligenceInfo.managers} />
        </div>
      </Grid>

      {isCompanyBackgroundModalShown && (
        <DueDiligenceCompanyBackgroundModal
          startStep={step}
          onCloseModal={handleCloseCompanyBackgroundModal}
        />
      )}

      {isTeamModalShown && (
        <DueDiligenceTeamModal startStep={step} onCloseModal={handleCloseTeamModal} />
      )}

      {isFinancialsModalShown && (
        <DueDiligenceFinancialsModal startStep={step} onCloseModal={handleCloseFinancialsModal} />
      )}
    </Grid>
  )
}

export default DueDiligenceClientDashboard
