import {
  BBC_DETAIL_DUPLICATE_INVOICES_FILTERS_CONFIG,
  BBC_DETAIL_CONFIRMED_PRIORITY_INVOICE_FILTERS_CONFIG,
  BBC_DETAIL_ALTERED_DATE_FILTERS_CONFIG,
  BANK_TRANSACTIONS_PENDING_CASH_DOMINION_FILTERS_CONFIG,
} from '@common/constants/filters'

export const DETAILED_FLAG_FILTER_MAPPING = {
  duplicate_ar_invoices: {
    filters: BBC_DETAIL_DUPLICATE_INVOICES_FILTERS_CONFIG,
    defaultFilter: 'invoice',
    small: true,
  },
  priority_invoice_paid: {
    filters: BBC_DETAIL_CONFIRMED_PRIORITY_INVOICE_FILTERS_CONFIG,
    defaultFilter: 'invoice',
    small: true,
  },
  pending_cash_dominion: {
    filters: BANK_TRANSACTIONS_PENDING_CASH_DOMINION_FILTERS_CONFIG,
    defaultFilter: 'description',
    small: true,
  },
  altered_date_flag: {
    filters: BBC_DETAIL_ALTERED_DATE_FILTERS_CONFIG,
    defaultFilter: 'impact_to_availability',
  },
}
