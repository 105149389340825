import React, { useCallback } from 'react'
import Link from '@mui/material/Link'

interface IProps {
  href: string
  children: React.ReactNode
  className?: string
  color?: string
  trackLinkClick: (params: object) => void
  newsfeedHeadlineId?: string
}

const ExternalLink = ({
  href,
  children,
  className,
  color,
  trackLinkClick,
  newsfeedHeadlineId,
  ...props
}: IProps) => {
  const handleClick = useCallback(
    (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
      trackLinkClick({ link: href, newsfeedHeadlineId })
    },
    [trackLinkClick, href, newsfeedHeadlineId],
  )

  return (
    <Link
      href={href}
      onClick={handleClick}
      className={className}
      target="_blank"
      rel="noopener noreferrer"
      {...props}
    >
      {children}
    </Link>
  )
}

export default ExternalLink
