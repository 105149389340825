import { ActionPattern } from '@redux-saga/types'
import { IApi } from '../../api'
import { IBankAccount } from '@common/interfaces/bankAccount'
import { CommonPromiseAction } from '../types'

export const prefix = 'bankAccount'

export const CREATE_REQUEST = `${prefix}/CREATE_REQUEST`
export const CREATE_SUCCESS = `${prefix}/CREATE_SUCCESS`
export const CREATE_FAILURE = `${prefix}/CREATE_FAILURE`

export const createBankAccount: CommonPromiseAction = (
  data: Partial<IBankAccount> & { clientId: string },
) => ({
  type: [CREATE_REQUEST, CREATE_SUCCESS, CREATE_FAILURE],
  promise: (api: IApi) => api.bankAccount.create(data),
})

export const UPDATE_REQUEST = `${prefix}/UPDATE_REQUEST`
export const UPDATE_SUCCESS = `${prefix}/UPDATE_SUCCESS`
export const UPDATE_FAILURE = `${prefix}/UPDATE_FAILURE`

export const updateBankAccount: CommonPromiseAction = (
  id: string,
  data: Partial<IBankAccount>,
) => ({
  type: [UPDATE_REQUEST, UPDATE_SUCCESS, UPDATE_FAILURE],
  promise: (api: IApi) => api.bankAccount.update(id, data),
})

export const DELETE_REQUEST = `${prefix}/DELETE_REQUEST`
export const DELETE_SUCCESS = `${prefix}/DELETE_SUCCESS`
export const DELETE_FAILURE = `${prefix}/DELETE_FAILURE`

export const deleteBankAccount: CommonPromiseAction = (id: string) => ({
  type: [DELETE_REQUEST, DELETE_SUCCESS, DELETE_FAILURE],
  promise: (api: IApi) => api.bankAccount.delete(id),
})

export const LIST_REQUEST = `${prefix}/LIST_REQUEST`
export const LIST_SUCCESS = `${prefix}/LIST_SUCCESS`
export const LIST_FAILURE = `${prefix}/LIST_FAILURE` as ActionPattern

export const listBankAccounts: CommonPromiseAction = (
  clientId: string,
  data: object,
  withBalances: boolean = false,
) => ({
  type: [LIST_REQUEST, LIST_SUCCESS, LIST_FAILURE],
  promise: (api: IApi) => api.bankAccount.list({ clientId, withBalances, ...data }),
})

export const LIST_BALANCES_REQUEST = `${prefix}/LIST_BALANCES_REQUEST`
export const LIST_BALANCES_SUCCESS = `${prefix}/LIST_BALANCES_SUCCESS`
export const LIST_BALANCES_FAILURE = `${prefix}/LIST_BALANCES_FAILURE`

export const listBankAccountBalances: CommonPromiseAction = (ongoingReportingId: string) => ({
  type: [LIST_BALANCES_REQUEST, LIST_BALANCES_SUCCESS, LIST_BALANCES_FAILURE],
  promise: (api: IApi) => api.bankAccount.listBalances(ongoingReportingId),
})
