import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'

import Participations from './Participations'

import { loanBalanceStartDate } from '../../../redux/loanBalance/selectors'
import { participants, clientParticipations } from '../../../redux/participant/selectors'
import {
  listParticipants,
  listClientParticipations,
  createParticipation,
  terminateParticipation,
  updateParticipation,
} from '../../../redux/participant/actions'

const selector = createStructuredSelector({
  loanBalanceStartDate,
  participants,
  clientParticipations,
})

const actions = {
  listParticipants,
  listClientParticipations,
  createParticipation,
  terminateParticipation,
  updateParticipation,
}

export default connect(selector, actions)(Participations)
