import React, { useCallback, useState } from 'react'
import { generatePath } from 'react-router-dom'
import { useHistory } from 'react-router'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import { CircularProgress } from '@mui/material'

import styles from './ManageTermsActionMenu.module.scss'

import { IOPSReporting, IProspectTerms } from '@common/interfaces/prospects'
import { ROUTES } from '../../../constants/routes'
import WarningModal from '../../../components/WarningModal'
import { MenuIcon } from '../../../components/Common/Icons'

interface IProps {
  reporting: IOPSReporting
  term: IProspectTerms
  id: string
  createProspectTerms: (id: string, term: IProspectTerms) => void
  deleteProspectTerms: (id: string, prospectTermsId: string) => void
  generateTermSheet: (id: string, prospectTermsId: string) => Promise<void>
  userId: string
}

const ManageTermsActionMenu = ({
  reporting,
  term,
  id,
  createProspectTerms,
  deleteProspectTerms,
  generateTermSheet,
  userId,
}: IProps) => {
  const history = useHistory()

  const [anchorEl, setAnchorEl] = useState(null)
  const [menuOpen, setMenuOpen] = useState(false)
  const [isDeleteWarningOpen, setIsDeleteWarningOpen] = useState(false)
  const [isCloneLoading, setIsCloneLoading] = useState(false)
  const [isGenerateTermSheetLoading, setIsGenerateTermSheetLoading] = useState(false)

  const handleClickMenu = useCallback((event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
    setMenuOpen(true)
  }, [])

  const handleCloseMenu = useCallback(() => {
    setAnchorEl(null)
    setMenuOpen(false)
  }, [])

  const handleEdit = useCallback(() => {
    history.push(generatePath(ROUTES.PROSPECT_EDIT_TERMS_PAGE, { id, termId: term.id }))
    handleCloseMenu()
  }, [handleCloseMenu, id, history, term])

  const handleDeleteConfrim = useCallback(() => {
    deleteProspectTerms(id, term.id)
    handleCloseMenu()
  }, [handleCloseMenu, id, deleteProspectTerms, term])

  const handleDeleteCancel = useCallback(() => {
    setIsDeleteWarningOpen(false)
  }, [])

  const handleDelete = useCallback(() => {
    setIsDeleteWarningOpen(true)
    handleCloseMenu()
  }, [handleCloseMenu])

  const handleClone = useCallback(async () => {
    setIsCloneLoading(true)
    await createProspectTerms(reporting.id, term)
    setIsCloneLoading(false)
    handleCloseMenu()
  }, [reporting, createProspectTerms, term, handleCloseMenu])

  const handleGenerateTermSheet = useCallback(async () => {
    setIsGenerateTermSheetLoading(true)
    await generateTermSheet(id, term.id)
    setIsGenerateTermSheetLoading(false)
    handleCloseMenu()
  }, [id, generateTermSheet, term, handleCloseMenu])

  return (
    <div className={styles.wrapper}>
      <MenuIcon isActive={menuOpen} onClick={handleClickMenu} size="small" />
      <Menu open={menuOpen} onClose={handleCloseMenu} anchorEl={anchorEl}>
        {term.user?.id === userId && <MenuItem onClick={handleEdit}>Edit</MenuItem>}
        <MenuItem onClick={handleClone}>
          {isCloneLoading ? <CircularProgress size={20} /> : 'Clone structure'}
        </MenuItem>
        <MenuItem onClick={handleDelete}>Delete</MenuItem>
        <MenuItem onClick={handleGenerateTermSheet} className={styles.generateTermSheet}>
          {isGenerateTermSheetLoading ? <CircularProgress size={20} /> : 'Generate Term Sheet'}
        </MenuItem>
      </Menu>
      {isDeleteWarningOpen && (
        <WarningModal
          warningMessage="Are you sure you want to delete these terms?"
          onConfirm={handleDeleteConfrim}
          onCancel={handleDeleteCancel}
          confirmText="Delete"
          cancelText="Cancel"
        />
      )}
    </div>
  )
}

export default ManageTermsActionMenu
