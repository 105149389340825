import React, { ReactNode } from 'react'
import { ROUTES } from '../../constants/routes'
import { ReactComponent as ClientIcon } from '../../assets/images/people-icon.svg'
import { ReactComponent as ClientIconActive } from '../../assets/images/people-icon-active.svg'
import { ReactComponent as AuditLogIcon } from '../../assets/images/audit-log-icon.svg'
import { ReactComponent as AuditLogIconActive } from '../../assets/images/audit-log-icon-active.svg'
import { ReactComponent as CollectionsIcon } from '../../assets/images/collections-icon.svg'
import { ReactComponent as ActivityQueueIcon } from '../../assets/images/activity-menu-icon.svg'
import { ReactComponent as TransferIcon } from '../../assets/images/transfer-icon.svg'
import { ReactComponent as DashboardIcon } from '../../assets/images/dashboard-icon.svg'
import { ReactComponent as ClientActivityIcon } from '../../assets/images/activity-icon.svg'
import { ReactComponent as ClientReportingIcon } from '../../assets/images/reporting-icon.svg'
import { ReactComponent as ClientSettings } from '../../assets/images/settings-icon2.svg'
import { ReactComponent as EntityIcon } from '../../assets/images/briefcase-icon.svg'
import { ReactComponent as UserManagementIcon } from '../../assets/images/user-management-icon.svg'
import { ReactComponent as UserManagementIconActive } from '../../assets/images/user-management-icon-active.svg'
import { ReactComponent as ExportsIcon } from '../../assets/images/download-alt-icon.svg'
import { ReactComponent as ExportsIconNotActive } from '../../assets/images/download-alt-icon-not-active.svg'
import { ReactComponent as ParticipantsIcon } from '../../assets/images/pie-chart-icon.svg'
import { ReactComponent as ParticipantsIconActive } from '../../assets/images/pie-chart-icon-active.svg'
import { ReactComponent as ProspectsIcon } from '../../assets/images/prospects-icon.svg'
import { ReactComponent as UnderwritingIcon } from '../../assets/images/underwriting-icon.svg'
import { ReactComponent as BarChartIcon } from '../../assets/images/bar-chart-icon.svg'
import styles from './SideMenu.module.scss'
import { UserRole } from '@common/interfaces/user'

export interface IMenuData {
  label: string
  icon: ReactNode
  link: string
  key: string
  activeIcon?: ReactNode
  subItems?: ISubMenuData[]
  divider?: boolean
}

export interface ISubMenuData {
  label: string
  link: string
  icon: ReactNode
}

const SIDE_MENU_ADMIN: IMenuData[] = [
  {
    label: 'Clients',
    icon: <ClientIcon />,
    link: ROUTES.HOMEPAGE,
    key: ROUTES.HOMEPAGE,
    activeIcon: <ClientIconActive />,
  },
  {
    label: 'Collections',
    link: ROUTES.COLLECTIONS_DASHBOARD,
    key: ROUTES.COLLECTIONS_DASHBOARD,
    icon: <CollectionsIcon />,
  },
  {
    label: 'Operations',
    icon: <ActivityQueueIcon />,
    key: ROUTES.ACTIVITY_QUEUE,
    link: ROUTES.ACTIVITY_QUEUE,
  },
  {
    label: 'Wires',
    icon: <TransferIcon />,
    key: ROUTES.OUTGOING_WIRE,
    link: ROUTES.OUTGOING_WIRE,
  },
  {
    label: 'Exports',
    icon: <ExportsIconNotActive />,
    key: ROUTES.EXPORTS_PAGE,
    link: ROUTES.EXPORTS_PAGE,
    activeIcon: <ExportsIcon className={styles.exportIcon} />,
  },
  {
    divider: true,
    label: 'Entities',
    icon: <EntityIcon />,
    key: ROUTES.ENTITY_MANAGEMENT,
    link: ROUTES.ENTITY_MANAGEMENT,
  },
  {
    label: 'Participants',
    icon: <ParticipantsIcon />,
    key: ROUTES.PARTICIPANTS_MANAGEMENT,
    link: ROUTES.PARTICIPANTS_MANAGEMENT,
    activeIcon: <ParticipantsIconActive />,
  },
  {
    label: 'Prospects',
    icon: <ProspectsIcon />,
    key: ROUTES.PROSPECTS,
    link: ROUTES.PROSPECTS,
  },
  {
    label: 'Diligence',
    icon: <UnderwritingIcon />,
    key: ROUTES.DUE_DILIGENCE_HOME,
    link: ROUTES.DUE_DILIGENCE_HOME,
  },
  {
    divider: true,
    label: 'Users',
    icon: <UserManagementIcon />,
    key: ROUTES.USER_MANAGEMENT,
    link: ROUTES.USER_MANAGEMENT,
    activeIcon: <UserManagementIconActive />,
  },
  {
    label: 'Audit Log',
    icon: <AuditLogIcon />,
    key: ROUTES.AUDIT,
    link: ROUTES.AUDIT,
    activeIcon: <AuditLogIconActive />,
  },
]
export const SIDE_MENU_PORTFOLIO_USER: IMenuData[] = [
  {
    label: 'Dashboard',
    icon: <DashboardIcon />,
    link: ROUTES.HOMEPAGE,
    key: ROUTES.HOMEPAGE,
  },
  {
    label: 'Collections',
    link: ROUTES.COLLECTIONS_DASHBOARD,
    key: ROUTES.COLLECTIONS_DASHBOARD,
    icon: <CollectionsIcon />,
  },
  {
    label: 'Operations',
    icon: <ActivityQueueIcon />,
    key: ROUTES.ACTIVITY_QUEUE,
    link: ROUTES.ACTIVITY_QUEUE,
  },
  {
    label: 'Wires',
    icon: <TransferIcon />,
    key: ROUTES.OUTGOING_WIRE,
    link: ROUTES.OUTGOING_WIRE,
  },
  {
    label: 'Entities',
    icon: <EntityIcon />,
    key: ROUTES.ENTITY_MANAGEMENT,
    link: ROUTES.ENTITY_MANAGEMENT,
  },
  {
    label: 'Participants',
    icon: <ParticipantsIcon />,
    key: ROUTES.PARTICIPANTS_MANAGEMENT,
    link: ROUTES.PARTICIPANTS_MANAGEMENT,
    activeIcon: <ParticipantsIconActive />,
  },
]

export const SIDE_MENU_UW_USER: IMenuData[] = [
  {
    label: 'Dashboard',
    icon: <DashboardIcon />,
    link: ROUTES.HOMEPAGE,
    key: ROUTES.HOMEPAGE,
  },
  {
    label: 'Operations',
    icon: <ActivityQueueIcon />,
    key: ROUTES.ACTIVITY_QUEUE,
    link: ROUTES.ACTIVITY_QUEUE,
  },
  {
    label: 'Collections',
    link: ROUTES.COLLECTIONS_DASHBOARD,
    key: ROUTES.COLLECTIONS_DASHBOARD,
    icon: <CollectionsIcon />,
  },
  {
    label: 'Clients',
    icon: <ClientIcon />,
    key: ROUTES.CLIENT_MANAGEMENT,
    link: ROUTES.CLIENT_MANAGEMENT,
    activeIcon: <ClientIconActive />,
  },
  {
    label: 'Prospects',
    icon: <ProspectsIcon />,
    key: ROUTES.PROSPECTS,
    link: ROUTES.PROSPECTS,
  },
  {
    label: 'Entities',
    icon: <EntityIcon />,
    key: ROUTES.ENTITY_MANAGEMENT,
    link: ROUTES.ENTITY_MANAGEMENT,
  },
]

export const SIDE_MENU_CLIENT_USER: IMenuData[] = [
  {
    label: 'Dashboard',
    icon: <DashboardIcon />,
    key: ROUTES.HOMEPAGE,
    link: ROUTES.HOMEPAGE,
  },
  {
    label: 'Activity',
    icon: <ClientActivityIcon className={styles.activityIcon} />,
    link: ROUTES.ACCOUNT_ACTIVITY,
    key: ROUTES.ACCOUNT_ACTIVITY,
  },
  {
    label: 'Reporting',
    icon: <ClientReportingIcon />,
    key: ROUTES.SUBMIT_REPORTING,
    link: ROUTES.SUBMIT_REPORTING,
  },
  {
    label: 'Analytics',
    icon: <BarChartIcon />,
    key: ROUTES.CLIENT_VISUALIZATIONS,
    link: ROUTES.CLIENT_VISUALIZATIONS,
  },
  {
    label: 'Settings',
    icon: <ClientSettings />,
    key: ROUTES.SETTINGS,
    link: ROUTES.SETTINGS,
  },
]

export const SIDE_MENU_PAST_CLIENT_USER: IMenuData[] = [
  {
    label: 'Activity',
    icon: <ClientActivityIcon className={styles.activityIcon} />,
    link: ROUTES.HOMEPAGE,
    key: ROUTES.HOMEPAGE,
  },
  {
    label: 'Settings',
    icon: <ClientSettings />,
    key: ROUTES.SETTINGS,
    link: ROUTES.SETTINGS,
  },
]

export const SIDE_MENU_BDO_USER: IMenuData[] = [
  {
    label: 'Dashboard',
    icon: <DashboardIcon />,
    key: ROUTES.HOMEPAGE,
    link: ROUTES.HOMEPAGE,
  },
]

export const SIDE_MENU_PARTICIPANTS_USER: IMenuData[] = [
  {
    label: 'Clients',
    icon: <ClientIcon />,
    link: ROUTES.HOMEPAGE,
    key: ROUTES.HOMEPAGE,
    activeIcon: <ClientIconActive />,
  },
  {
    label: 'Wires',
    icon: <TransferIcon />,
    key: ROUTES.OUTGOING_WIRE,
    link: ROUTES.OUTGOING_WIRE,
  },
]

const SIDE_MENU = {
  [UserRole.ADMIN]: SIDE_MENU_ADMIN,
  [UserRole.PORTFOLIO_ADMIN]: SIDE_MENU_PORTFOLIO_USER,
  [UserRole.PORTFOLIO_USER]: SIDE_MENU_PORTFOLIO_USER,
  [UserRole.UW_USER]: SIDE_MENU_UW_USER,
  [UserRole.CLIENT_USER]: [],
  [UserRole.BDO]: SIDE_MENU_BDO_USER,
  [UserRole.PARTICIPANT_USER]: SIDE_MENU_PARTICIPANTS_USER,
} as const

export { SIDE_MENU }
