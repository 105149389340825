import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'

import ReservesTable from './ReservesTable'

import {
  listBBCReserves,
  listClientReserves,
  createBBCReserve,
  createClientReserve,
  updateBBCReserve,
  updateClientReserve,
  deleteBBCReserve,
  deleteClientReserve,
  listExtraReserves,
  listClientExtraReserves,
  updateExtraReserves,
  updateClientExtraReserves,
} from '../../redux/bbc/actions'
import { bbc, reserves, apReserves, isLoading as isLoadingBBC } from '../../redux/bbc/selectors'
import { putNotification } from '../../redux/ui/actions'

const selector = createStructuredSelector({
  isLoadingBBC,
  bbc,
  reservesData: reserves,
  apReservesData: apReserves,
})

const actions = {
  listBBCReserves,
  listClientReserves,
  createBBCReserve,
  createClientReserve,
  updateBBCReserve,
  updateClientReserve,
  deleteBBCReserve,
  deleteClientReserve,
  putNotification,
  listExtraReserves,
  listClientExtraReserves,
  updateExtraReserves,
  updateClientExtraReserves,
}

export default connect(selector, actions)(ReservesTable)
