import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'

import BBCIneligibleInventoryTable from './BBCIneligibleInventoryTable'

import {
  clientCollaterals,
  inventoryIneligibleStats,
  isLoading as isLoadingBBC,
} from '../../redux/bbc/selectors'
import { listInventoryIneligibleStatsSummary } from '../../redux/bbc/actions'

const selector = createStructuredSelector({
  isLoadingBBC,
  clientCollaterals,
  inventoryIneligibleStatsData: inventoryIneligibleStats,
})

const actions = {
  listInventoryIneligibleStatsSummary,
}

export default connect(selector, actions)(BBCIneligibleInventoryTable)
