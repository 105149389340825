import React from 'react'
import cn from 'classnames'

import { ReactComponent as ArrowDropDown } from '../../../assets/images/drop-down-icon.svg'

import styles from './DropDownArrow.module.scss'

interface IProps {
  className?: string
}

const DropDownArrow = ({ className, ...props }: IProps) => (
  <div className={cn(styles.icon, className)}>
    <ArrowDropDown {...props} />
  </div>
)

export default DropDownArrow
