import { connect } from 'react-redux'

import ProspectARDetailRow from './ProspectReportDetailRow'

import { listAPDetail, listARDetail } from '../../redux/prospect/actions'

const actions = {
  listARDetail,
  listAPDetail,
}

export default connect(null, actions)(ProspectARDetailRow)
