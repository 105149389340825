import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import ClientTermLoans from './ClientTermLoans'
import { listAmortizationSchedule, listTermLoans } from '../../../redux/clientInfo/actions'
import { clientInfo, termLoans } from '../../../redux/clientInfo/selectors'

const selector = createStructuredSelector({
  clientInfo,
  termLoans,
})

const actions = {
  listTermLoans,
  listAmortizationSchedule,
}

export default connect(selector, actions)(ClientTermLoans)
