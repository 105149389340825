import React, { useEffect, useCallback, useState } from 'react'
import { OnChange } from 'react-final-form-listeners'
import { Form } from 'react-final-form'
import Box from '@mui/material/Box'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import { makeValidate } from 'mui-rff'
import * as Yup from 'yup'
import mapValues from 'lodash/mapValues'
import cn from 'classnames'
import isEqual from 'lodash/isEqual'

import styles from './RequiredReporting.module.scss'
import genericSs from '@styles/generic.module.scss'

import { REPORTING_DOCUMENT_TYPES, ReportingDocumentStatuses } from '@common/constants/client'
import Loader from '../../Loader'
import Card from '../../Common/Card'
import TableContainer from '../../Common/TableContainer/TableContainer'
import Table from '../../Common/Table/Table'
import TableHead from '../../Common/TableHead/TableHead'
import TableRow from '../../Common/TableRow/TableRow'
import TableCell from '../../Common/TableCell/TableCell'
import TableBody from '../../Common/TableBody/TableBody'
import IconButton from '../../Common/IconButton/IconButton'
import SelectField from '../../Common/SelectField'
import SelectWithCheckboxesField from '../../Common/SelectWithCheckboxesField'
import { IFrequency } from '@common/interfaces/frequency'
import { IRequiredReport } from '@common/interfaces/requiredReport'
import { FREQUENCY_TYPES } from '@common/constants/client'
import { ReactComponent as DeleteIcon } from '../../../assets/images/delete-icon.svg'
import AddButton from '../AddButton'
import Modal from '../../Common/Modal'
import Button from '../../Common/Button'
import InputLabel from '../../Common/InputLabel'
import TableFields from '../../TableFields'
import TextField from '../../Common/TextField'
import { MenuIcon } from '../../Common/Icons'
import FileHeaderMapping from '../../FileHeaderMapping'
import { dateTimeToString, formatDate } from '../../../helpers/helpers'
import { DATE_FORMAT } from '../../../constants/common'
import KeyboardDatePicker from '../../Common/KeyboardDatePicker'

interface IProps {
  isLoading: boolean
  clientId: string
  frequencies: IFrequency[]
  requiredReports: IRequiredReport[]
  listFrequencies: () => void
  listReportingDocuments: () => void
  listRequiredReports: (clientId: string) => void
  createReportingDocument: (data: { name: string }) => void
  createRequiredReport: (data: any) => void
  updateRequiredReport: (id: string, data: any) => void
  deleteRequiredReport: (id: string) => void
}

const normalizeFrequency = (frequency: { [key: string]: string }) =>
  Object.entries(frequency).reduce(
    (acc: string[], [key, value]) => (value ? [...acc, key] : acc),
    [],
  )

const normalizeDocumentName = (documentName: string, reportingName: string) =>
  reportingName ? reportingName : documentName

const getInitialValues = (report: IRequiredReport) => ({
  ...report,
  frequency:
    Array.isArray(report.frequency) &&
    report.frequency.reduce(
      (acc, item: string) => ({
        ...acc,
        [item]: true,
      }),
      {},
    ),
})

const frequencyInitialValue = {
  [FREQUENCY_TYPES.monthly]: true,
}

const schema = Yup.object().shape({
  documentName: Yup.string().required('Required'),
  reportingName: Yup.string().when('documentName', {
    is: 'Custom',
    then: Yup.string().required('Required'),
    otherwise: Yup.string().nullable(),
  }),
  frequency: Yup.lazy((obj) =>
    Yup.object(mapValues(obj, () => Yup.boolean())).test(
      'oneOf',
      'At least one frequency should be selected',
      (values) => Object.values(values).some((value) => Boolean(value)),
    ),
  ),
})

const schemaForExists = Yup.object().shape({
  frequency: Yup.lazy((obj) =>
    Yup.object(mapValues(obj, () => Yup.boolean())).test(
      'oneOf',
      'At least one frequency should be selected',
      (values) => Object.values(values).some((value) => Boolean(value)),
    ),
  ),
})

const reportingDocumentOptions = [
  ...Object.values(REPORTING_DOCUMENT_TYPES).map((value) => ({
    value: value,
    label: value,
  })),
  { value: 'Custom', label: 'Custom' },
].sort((a, b) => a.label.localeCompare(b.label))

const validate = makeValidate(schema)
const validateForExists = makeValidate(schemaForExists)

const RequiredReporting = ({
  isLoading,
  clientId,
  frequencies,
  requiredReports,
  listFrequencies,
  listReportingDocuments,
  listRequiredReports,
  createReportingDocument,
  createRequiredReport,
  updateRequiredReport,
  deleteRequiredReport,
}: IProps) => {
  const [isEditModalShown, setIsEditModalShown] = useState(false)
  const [anchorEl, setAnchorEl] = useState(null)
  const [documentMenuOpen, setDocumentMenuOpen] = useState('')
  const [selectedHeaderMapping, setSelectedHeaderMapping] = useState('')

  useEffect(() => {
    listFrequencies()
  }, [listFrequencies])

  useEffect(() => {
    listReportingDocuments()
  }, [listReportingDocuments])

  useEffect(() => {
    clientId && listRequiredReports(clientId)
  }, [clientId, listRequiredReports])

  const handleCreateReport = useCallback(
    async ({ documentName, frequency, reportingName }: any) => {
      if (reportingName) {
        await createReportingDocument({ name: reportingName })
      }
      createRequiredReport({
        clientId,
        documentName: normalizeDocumentName(documentName, reportingName),
        frequency: normalizeFrequency(frequency),
      })
    },
    [createRequiredReport, createReportingDocument, clientId],
  )

  const handleUpdateReport = useCallback(
    async ({ id, frequency }: any) => {
      if (frequency[FREQUENCY_TYPES.adHoc]) {
        frequency = {
          [FREQUENCY_TYPES.adHoc]: true,
        }
      }

      await updateRequiredReport(id, {
        frequency: normalizeFrequency(frequency),
      })
    },
    [updateRequiredReport],
  )

  const handleUpdateReportDueDate = useCallback(
    async (id, dueDate) => {
      if (dueDate && typeof dueDate === 'string') {
        return
      }
      await updateRequiredReport(id, {
        dueDate: dueDate ? dateTimeToString(dueDate) : null,
      })
    },
    [updateRequiredReport],
  )

  const renderFrequencyField = (
    isConsistentFrequency: boolean = false,
    fromEditModal?: boolean,
    currentFrequency?: any,
  ) => (
    <SelectWithCheckboxesField
      name="frequency"
      placeholder="Choose frequency"
      options={frequencies.map(({ name }) => ({
        value: name,
        label: name,
        disabled:
          (name === FREQUENCY_TYPES.atFunding && isConsistentFrequency) ||
          (name !== FREQUENCY_TYPES.adHoc && currentFrequency?.[FREQUENCY_TYPES.adHoc]),
      }))}
      className={styles.frequencyContainer}
      style={{ width: '100%', textAlign: 'left', display: fromEditModal && 'flex', padding: 0 }}
    />
  )

  const handleOpenHeaderMapping = useCallback((documentName: string) => {
    setSelectedHeaderMapping(documentName?.replace(/\s/g, ''))
  }, [])

  const handleCloseHeaderMapping = useCallback(() => {
    setSelectedHeaderMapping('')
  }, [])

  const handleCloseMenu = useCallback(() => {
    setDocumentMenuOpen('')
    setAnchorEl(null)
  }, [])

  const handleFrequencyChange = useCallback((form, value) => {
    if (value[FREQUENCY_TYPES.adHoc] && Object.keys(value).length > 1) {
      form.change('frequency', {
        [FREQUENCY_TYPES.adHoc]: true,
      })
    }
  }, [])

  return (
    <div className={styles.reportingContainer}>
      {isLoading && <Loader />}
      <div className={styles.headerContainer}></div>

      <Card
        title={
          <Box display="flex" justifyContent="space-between" alignItems="center">
            Reporting
            <AddButton
              variant="outlined"
              disabled={!clientId}
              onClick={() => setIsEditModalShown(true)}
            ></AddButton>
          </Box>
        }
        className={styles.tableContainer}
      >
        <Box flex={1}>
          <Card withBorder={false} className={styles.card} noPadding noHeaderMargin>
            <Card noPadding withBorder={false} noHeaderMargin>
              <Form
                onSubmit={handleCreateReport}
                validate={validate}
                keepDirtyOnReinitialize
                initialValues={{
                  frequency: frequencyInitialValue,
                }}
                render={({ dirty, submitting, invalid, form, handleSubmit, values }) => (
                  <form>
                    <Modal
                      open={isEditModalShown}
                      onCancel={() => setIsEditModalShown(false)}
                      size="small"
                      title="Add Reporting"
                      classes={{
                        footer: styles.editModalFooter,
                        title: styles.editModalTitle,
                      }}
                      footer={[
                        <Button
                          key="submit"
                          color="primary"
                          small={false}
                          variant="contained"
                          disabled={!dirty || invalid || submitting}
                          className={styles.submitButton}
                          onClick={(data) => {
                            handleSubmit(data).then(() => {
                              form.change('documentName', null)
                              form.resetFieldState('documentName')
                              form.reset()
                              form.change('frequency', frequencyInitialValue)
                              setIsEditModalShown(false)
                            })
                          }}
                        >
                          Add reporting
                        </Button>,
                      ]}
                    >
                      <Box className={styles.fieldsContainer}>
                        <TableFields
                          classes={{ root: styles.editFields }}
                          rows={[
                            [
                              <InputLabel
                                key="documentNameLabel"
                                htmlFor="documentName"
                                size="normal"
                                position="horizontal"
                              >
                                Reporting document
                              </InputLabel>,
                              <SelectField
                                key="documentNameInput"
                                name="documentName"
                                placeholder="Select any reporting document"
                                options={reportingDocumentOptions}
                              />,
                            ],
                            String(form?.getState().values?.documentName) === 'Custom'
                              ? [
                                  <InputLabel
                                    key="reportingNameLabel"
                                    htmlFor="reportingName"
                                    size="normal"
                                    position="horizontal"
                                  >
                                    Enter reporting name
                                  </InputLabel>,
                                  <TextField
                                    key="reportingNameInput"
                                    name="reportingName"
                                    style={{ width: '100%' }}
                                  />,
                                ]
                              : [],
                            [
                              <InputLabel key="frequencyLabel" size="normal" position="horizontal">
                                Choose frequency
                              </InputLabel>,
                              renderFrequencyField(false, true, values.frequency),
                            ],
                          ]}
                        />
                      </Box>
                      <OnChange name="frequency">
                        {(value) => {
                          handleFrequencyChange(form, value)
                        }}
                      </OnChange>
                    </Modal>
                  </form>
                )}
              />
            </Card>

            {requiredReports.length > 0 && (
              <Box>
                <Card withBorder={false} noPadding noHeaderMargin>
                  <TableContainer className={styles.table}>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell className={genericSs.tableTextLeft}>
                            Reporting Document
                          </TableCell>
                          <TableCell className={genericSs.tableTextLeft}>Frequency</TableCell>
                          <TableCell className={genericSs.tableTextRight}>
                            Processed Through
                          </TableCell>
                          <TableCell className={genericSs.tableTextRight}>Next Due Date</TableCell>
                          <TableCell className={genericSs.tableTextLeft}>Action</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {requiredReports.map((report) => (
                          <Form
                            key={report.id}
                            initialValues={getInitialValues(report)}
                            validate={validateForExists}
                            onSubmit={handleUpdateReport}
                            render={({ dirty, handleSubmit, values, form, errors }) => (
                              <TableRow>
                                <TableCell className={genericSs.tableTextLeft}>
                                  {report.documentName}
                                </TableCell>
                                <TableCell className={genericSs.tableTextLeft}>
                                  {renderFrequencyField(
                                    !report.isConsistentFrequency,
                                    false,
                                    values.frequency,
                                  )}
                                </TableCell>
                                <TableCell className={genericSs.tableTextRight}>
                                  {report.reportingThoughDate ? (
                                    <div
                                      className={cn({
                                        [styles.red]:
                                          report.status === ReportingDocumentStatuses.Overdue,
                                        [styles.yellow]:
                                          report.status === ReportingDocumentStatuses.Due,
                                      })}
                                    >
                                      {formatDate(report.reportingThoughDate)}
                                    </div>
                                  ) : null}
                                </TableCell>
                                <TableCell className={genericSs.tableTextRight}>
                                  {report.frequency.includes(FREQUENCY_TYPES.adHoc) ? (
                                    <KeyboardDatePicker name="dueDate" inputFormat={DATE_FORMAT} />
                                  ) : report.nextDueDate ? (
                                    <div
                                      className={cn({
                                        [styles.red]:
                                          report.status === ReportingDocumentStatuses.Overdue,
                                        [styles.yellow]:
                                          report.status === ReportingDocumentStatuses.Due,
                                      })}
                                    >
                                      {formatDate(report.nextDueDate)}
                                    </div>
                                  ) : null}
                                </TableCell>
                                <TableCell className={genericSs.tableTextLeft}>
                                  <Box display="inline-box" ml={1}>
                                    {report.documentName === 'Inventory Detail' && (
                                      <>
                                        <MenuIcon
                                          onClick={(event) => {
                                            setAnchorEl(event.currentTarget)
                                            setDocumentMenuOpen(report.documentName)
                                          }}
                                        />
                                        <Menu
                                          open={documentMenuOpen === report.documentName}
                                          onClose={handleCloseMenu}
                                          anchorEl={anchorEl}
                                        >
                                          <MenuItem
                                            onClick={() => {
                                              handleOpenHeaderMapping(report.documentName)
                                              handleCloseMenu()
                                            }}
                                          >
                                            Column Mapping
                                          </MenuItem>
                                        </Menu>
                                        {selectedHeaderMapping && (
                                          <FileHeaderMapping
                                            fileType={selectedHeaderMapping}
                                            id={clientId}
                                            onClose={handleCloseHeaderMapping}
                                          />
                                        )}
                                      </>
                                    )}

                                    {report.canBeDeleted && (
                                      <IconButton onClick={() => deleteRequiredReport(report.id)}>
                                        <DeleteIcon className={styles.iconRoot} />
                                      </IconButton>
                                    )}
                                  </Box>
                                </TableCell>

                                <OnChange name="frequency">
                                  {(value, prevValue) => {
                                    dirty && setTimeout(() => handleSubmit(), 0)
                                  }}
                                </OnChange>
                                <OnChange name="dueDate">
                                  {(value, prevValue) => {
                                    dirty &&
                                      !isEqual(value, prevValue) &&
                                      handleUpdateReportDueDate(report.id, value)
                                  }}
                                </OnChange>
                              </TableRow>
                            )}
                          />
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Card>
              </Box>
            )}
          </Card>
        </Box>
      </Card>
    </div>
  )
}

export default RequiredReporting
