import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'

import OngoingReportingStepper from './OngoingReportingStepper'

import { showOngoingReporting } from '../../redux/ongoingReporting/actions'
import { ongoingReporting } from '../../redux/ongoingReporting/selectors'
import { pathname } from '../../redux/router/selectors'

const selector = createStructuredSelector({
  data: ongoingReporting,
  pathname,
})

const actions = {
  showOngoingReporting,
}

export default connect(selector, actions)(OngoingReportingStepper)
