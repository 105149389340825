import { combineReducers } from 'redux'
import * as actions from './actions'
import { IBankAccount, IBankBalance } from '@common/interfaces/bankAccount'
import { ILoadingData } from '../types'
import { createLoadingDataReducer } from '../../helpers/redux'

export interface IBankAccountState {
  loadingArray: string[]
  list: ILoadingData<{ data: IBankAccount[] }>
  balances: IBankBalance[]
}

export default combineReducers<IBankAccountState>({
  loadingArray(state: string[] = [], action) {
    switch (action.type) {
      case actions.CREATE_REQUEST:
      case actions.LIST_REQUEST:
      case actions.DELETE_REQUEST:
      case actions.UPDATE_REQUEST:
      case actions.LIST_BALANCES_REQUEST:
        return [...state, action.type]
      case actions.CREATE_SUCCESS:
      case actions.CREATE_FAILURE:
      case actions.LIST_SUCCESS:
      case actions.LIST_FAILURE:
      case actions.DELETE_SUCCESS:
      case actions.DELETE_FAILURE:
      case actions.UPDATE_SUCCESS:
      case actions.UPDATE_FAILURE:
      case actions.LIST_BALANCES_SUCCESS:
      case actions.LIST_BALANCES_FAILURE:
        return state.filter(
          (item) =>
            item !== action.type.replace('SUCCESS', 'REQUEST').replace('FAILURE', 'REQUEST'),
        )
      default:
        return state
    }
  },
  list: createLoadingDataReducer<{ data: IBankAccount[] }>(
    [actions.LIST_REQUEST],
    [actions.CREATE_REQUEST, actions.UPDATE_REQUEST, actions.DELETE_REQUEST],
    true,
  ),
  balances(state: IBankBalance[] = [], action) {
    switch (action.type) {
      case actions.LIST_BALANCES_SUCCESS:
        return action.data
      default:
        return state
    }
  },
})
