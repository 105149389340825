import Base from './base'

export default class Notification extends Base {
  list(params: object) {
    return this.apiClient.get('notifications', params)
  }

  markAsRead(params: object) {
    return this.apiClient.post('notifications/mark-as-read', params)
  }
}
