import React, { useCallback, useMemo, useEffect } from 'react'
import useTable from '../../hooks/useTable'
import { Form } from 'react-final-form'
import InfiniteScroll from 'react-infinite-scroll-component'
import Box from '@mui/material/Box'
import { IInvestorAggregation } from '@common/interfaces/entityInfo'
import { INVESTOR_SUMMARY_LIST_FILTERS_CONFIG, PER_PAGE } from '@common/constants/filters'
import { buildFiltersDefaults } from '../../helpers/filters'
import { debounceEventHandler, formatAmount, formatPercent } from '../../helpers/helpers'
import styles from './InvestorSummaryTable.module.scss'
import genericSs from '@styles/generic.module.scss'
import TableContainer from '../Common/TableContainer'
import Table from '../Common/Table'
import TableHead from '../Common/TableHead'
import TableBody from '../Common/TableBody'
import TableRow from '../Common/TableRow'
import TableCell from '../Common/TableCell'
import TableFiltersRow from '../Common/TableFiltersRow'
import FilterContainer from '../Filters/FilterContainer'
import { ILoadingData } from '../../redux/types'
import TableLoader from '../Common/TableLoader/TableLoader'
import { generatePath, Link as RouterLink } from 'react-router-dom'
import Link from '@mui/material/Link'
import { ROUTES } from '../../constants/routes'

const filtersDefaults = buildFiltersDefaults(INVESTOR_SUMMARY_LIST_FILTERS_CONFIG)

const formMutators = {
  setFieldData: ([field, value]: any, state: any, { changeValue }: any) => {
    changeValue(state, field, () => value)
  },
}

interface IProps {
  investorAggregation: ILoadingData<IInvestorAggregation>
  listInvestors: (params: object) => void
  exportInvestors: (params: object) => void
}

const InvestorSummaryTable = ({ investorAggregation, listInvestors, exportInvestors }: IProps) => {
  const { filters, handleFiltersChange, handleOrderChange, orderBy } = useTable({
    tableId: 'investors',
    filtersDefaults,
    sortDefault: {
      field: 'AVG(sub."dilutedSharesPercentage")',
      direction: 'DESC',
    },
  })

  const {
    isLoading,
    data: investorData,
    totals,
  } = useMemo(() => {
    return {
      isLoading: investorAggregation?.isLoading,
      data: investorAggregation?.data?.data,
      totals: investorAggregation?.data?.totals,
    }
  }, [investorAggregation])

  const fetchInvestors = useCallback(
    (params: any) => {
      listInvestors(params)
    },
    [listInvestors],
  )

  const debounceListInvestors = useMemo(
    () => debounceEventHandler(fetchInvestors, 500),
    [fetchInvestors],
  )

  useEffect(() => {
    debounceListInvestors({
      page: 0,
      perPage: PER_PAGE,
      orderBy: orderBy.field,
      orderDirection: orderBy.direction,
      filters,
    })
  }, [orderBy, filters, debounceListInvestors])

  const loadMore = useCallback(() => {
    fetchInvestors({
      loadMore: true,
      page: Math.ceil(investorData?.length / PER_PAGE),
      perPage: PER_PAGE,
      orderBy: orderBy.field,
      orderDirection: orderBy.direction,
      filters,
    })
  }, [investorData, orderBy, filters, fetchInvestors])

  const handleExportAggregation = useCallback(async () => {
    await exportInvestors({
      filters,
      orderBy: orderBy.field,
      orderDirection: orderBy.direction,
      page: 0,
      perPage: PER_PAGE,
      skipLoader: true,
      isExport: true,
    })
  }, [orderBy, filters, exportInvestors])

  return (
    <Box py={1} pr={2}>
      <TableContainer className={styles.table}>
        <Form
          onSubmit={handleFiltersChange}
          initialValues={filters}
          mutators={formMutators}
          render={({ values, handleSubmit, form: { mutators } }) => (
            <FilterContainer
              filters={INVESTOR_SUMMARY_LIST_FILTERS_CONFIG}
              handleSubmit={handleSubmit}
              mutators={mutators}
              values={values}
              title={''}
              appliedFilters={filters}
              handleExportAggregation={handleExportAggregation}
            />
          )}
        />
        <Table>
          <TableHead>
            <TableFiltersRow
              filters={INVESTOR_SUMMARY_LIST_FILTERS_CONFIG}
              orderBy={orderBy}
              handleOrderChange={handleOrderChange}
            />
          </TableHead>
          <TableBody id="scrollableTableReporting">
            {isLoading ? (
              <TableLoader columnsCount={INVESTOR_SUMMARY_LIST_FILTERS_CONFIG.length} height={24} />
            ) : (
              investorData &&
              investorData.length > 0 &&
              investorData[0]?.entityId && (
                <InfiniteScroll
                  dataLength={investorData.length}
                  next={loadMore}
                  hasMore={investorData.length < totals?.totalItems}
                  loader={
                    <TableLoader columnsCount={INVESTOR_SUMMARY_LIST_FILTERS_CONFIG.length} />
                  }
                  scrollableTarget="scrollableTableReporting"
                >
                  {investorData.map((item) => (
                    <TableRow key={item?.id}>
                      <TableCell className={genericSs.tableTextLeft}>
                        {item.entityId ? (
                          <Link
                            component={RouterLink}
                            to={generatePath(ROUTES.ENTITY_PAGE + '\\?tab=Investor', {
                              id: item.entityId,
                            })}
                          >
                            {item.investor}
                          </Link>
                        ) : (
                          item.investor
                        )}
                      </TableCell>

                      <TableCell className={genericSs.tableTextRight}>
                        {item.totalInvestment && Math.ceil(item.totalInvestment) !== 0
                          ? `$${formatAmount(item.totalInvestment)}`
                          : '$-'}
                      </TableCell>
                      <TableCell className={genericSs.tableTextRight}>
                        {item.totalInvestment && Math.ceil(item.averageInvestment) !== 0
                          ? `$${formatAmount(item.averageInvestment)}`
                          : '$-'}
                      </TableCell>
                      <TableCell className={genericSs.tableTextRight}>
                        {item.avgOutstandingSharesPercentage
                          ? formatPercent(item.avgOutstandingSharesPercentage)
                          : '-'}
                      </TableCell>
                      <TableCell className={genericSs.tableTextRight}>
                        {item.avgDilutedSharesPercentage
                          ? formatPercent(item.avgDilutedSharesPercentage)
                          : '-'}
                      </TableCell>
                      <TableCell className={genericSs.tableTextRight}>
                        {item.totalClients}
                      </TableCell>
                      <TableCell className={genericSs.tableTextRight}>
                        {item.totalBoardSeats}
                      </TableCell>
                    </TableRow>
                  ))}
                </InfiniteScroll>
              )
            )}
          </TableBody>
        </Table>
        <Box display="flex" alignItems="center" justifyContent="space-between">
          {totals?.totalItems > 0 && (
            <div className={genericSs.itemsCount}>
              {investorData?.length} / {totals?.totalItems}
            </div>
          )}
        </Box>
      </TableContainer>
    </Box>
  )
}

export default InvestorSummaryTable
