import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import RequestFundingPage from './RequestFundingPage'
import { clientInfo } from '../../redux/profile/selectors'
const selector = createStructuredSelector({
  clientInfo,
})

const actions = {}

export default connect(selector, actions)(RequestFundingPage)
