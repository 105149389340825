import StressCaseAssumptions from './StressCaseAssumptions'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'

import { stressCase } from '../../redux/clientInfo/selectors'
import {
  listStressCaseAssumptions,
  updateStressCaseAssumption,
} from '../../redux/clientInfo/actions'

const selector = createStructuredSelector({
  stressCase,
})

const actions = {
  listStressCaseAssumptions,
  updateStressCaseAssumption,
}

export default connect(selector, actions)(StressCaseAssumptions)
