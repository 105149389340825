import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'

import EntitySettingsArTable from './EntitySettingsArTable'

import { entityAR } from '../../redux/entityInfo/selectors'

import { listEntityAr, updateEntityAr } from '../../redux/entityInfo/actions'

const selector = createStructuredSelector({
  entityAR,
})

const actions = {
  listEntityAr,
  updateEntityAr,
}

export default connect(selector, actions)(EntitySettingsArTable)
