import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'

import ProspectHeader from './ProspectHeader'

import { reporting } from '../../redux/prospect/selectors'
import { show, hide, update, createNewOps } from '../../redux/prospect/actions'
import { updateClient } from '../../redux/clientInfo/actions'

const selector = createStructuredSelector({
  reporting,
})
const actions = {
  show,
  hide,
  update,
  updateClient,
  createNewOps,
}

export default connect(selector, actions)(ProspectHeader)
