import React, { ReactNode } from 'react'
import MuiModal from '@mui/material/Modal'
import Backdrop from '@mui/material/Backdrop'
import Slide from '@mui/material/Slide'
import cn from 'classnames'
import styles from './DueDiligenceSideContainer.module.scss'

interface IProps {
  title: string
  open?: boolean
  children: ReactNode
  onCancel: () => void
  isUW?: boolean
}

const DueDiligenceSideContainer = ({
  title = '',
  open,
  onCancel,
  children,
  isUW = false,
}: IProps) => {
  return (
    // @ts-ignore
    <MuiModal
      open={open}
      onClose={onCancel}
      disableEnforceFocus
      slots={{ backdrop: Backdrop }}
      slotProps={{
        backdrop: {
          timeout: 500,
        },
      }}
    >
      <Slide direction="left" in timeout={500} mountOnEnter unmountOnExit>
        <div className={styles.modal}>
          <div className={styles.modalHeader}>
            <div className={styles.modalTitle}>{title}</div>
          </div>
          <div className={cn(styles.modalBody, { [styles.modalBodyUW]: isUW })}>{children}</div>
        </div>
      </Slide>
    </MuiModal>
  )
}

export default DueDiligenceSideContainer
