import superagent from 'superagent'
import { prepareRequestParams } from '../helpers/helpers'

export default class ApiClient {
  prefix: string
  token: string | null

  constructor(prefix: string) {
    if (!prefix) {
      throw new Error('[apiPrefix] required')
    }
    this.prefix = prefix
    this.token = window.localStorage.getItem('token') || null
  }

  setToken(token: string) {
    this.token = token
    window.localStorage.setItem('token', token)
  }

  removeToken() {
    this.token = null
    window.localStorage.removeItem('token')
    window.localStorage.removeItem('return_url')
  }

  get(url: string, params: object = {}) {
    return this._request({ url, method: 'get', params })
  }

  post(url: string, body: object = {}, params: object = {}) {
    return this._request({ url, method: 'post', body, params })
  }

  patch(url: string, body: object = {}) {
    return this._request({ url, method: 'patch', body })
  }

  put(url: string, body: object = {}) {
    return this._request({ url, method: 'put', body })
  }

  delete(url: string, body: object = {}) {
    return this._request({ url, method: 'delete', body })
  }

  async beacon(url: string, body: object = {}) {
    const data = await fetch(`${this.prefix}${url}`, {
      keepalive: true,
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${this.token}`,
      },
      body: JSON.stringify(body),
    })
    return data
  }

  _request({
    url,
    method,
    params = {},
    body,
  }: {
    url: string
    method: string
    params?: object
    body?: object
  }) {
    // @ts-ignore
    const req = superagent[method](`${this.prefix}${url}`).timeout({
      response: 5 * 60 * 1000,
      deadline: 5 * 60 * 1000,
    })

    // @ts-ignore
    const { isFile } = params || {}
    if (isFile) {
      req.responseType('blob')
      // @ts-ignore
      delete params.isFile
    }

    if (params) {
      req.query(params)
    }
    if (body) {
      req.send(prepareRequestParams(body))
    }
    if (this.token) {
      req.set('Authorization', `Bearer ${this.token}`)
    }

    return req.then((res: any) => {
      if (isFile) {
        const fileName = res.headers['content-disposition']?.split('filename=')[1].split(';')[0]
        if (fileName) {
          res.body.name = fileName.replaceAll('"', '')
        }
        return res.body
      } else {
        if (!res.body.status) {
          throw res.body.error
        }
        return res.body.data
      }
    })
  }
}
