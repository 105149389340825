import React, { ReactNode, useMemo } from 'react'
import Typography from '@mui/material/Typography'
import Link from '@mui/material/Link'
import Tooltip from '@mui/material/Tooltip'
import cn from 'classnames'

import styles from './Document.module.scss'

import { ReactComponent as DocumentIcon } from '@assets/images/document.svg'
import { humanReadableFileSize } from '@common/helpers/helpers'

interface IProps {
  className?: string
  isSmall?: boolean
  icon?: ReactNode
  action?: ReactNode
  isProvided?: boolean
  name?: string
  size?: number
  link?: string
}

const Document = ({
  className,
  icon = <DocumentIcon />,
  action,
  isProvided,
  name,
  size,
  link,
  isSmall = false,
}: IProps) => {
  const humanReadableSize = useMemo(
    () => humanReadableFileSize(size, !isSmall).join(' '),
    [size, isSmall],
  )

  return (
    <div
      className={cn(styles.container, className, {
        [styles.notProvided]: !isProvided,
        [styles.clickable]: !!link,
        [styles.small]: isSmall,
      })}
    >
      <Link href={link} underline="none" target="_blank" rel="noopener noreferrer">
        {icon && <div className={styles.icon}>{icon}</div>}
        <Typography variant="body1" className={styles.title}>
          {isProvided ? (
            <Tooltip title={name} placement="bottom-start">
              <span>{name}</span>
            </Tooltip>
          ) : (
            'Not Provided'
          )}
        </Typography>
        {isProvided && (
          <Typography variant="body1" className={cn({ [styles.sizeLabel]: isProvided })}>
            <Tooltip title={humanReadableSize} placement="bottom-start">
              <span>{humanReadableSize}</span>
            </Tooltip>
          </Typography>
        )}
      </Link>
      {action && <div className={styles.action}>{action}</div>}
    </div>
  )
}

export default Document
