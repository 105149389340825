import React from 'react'
import Grid from '@mui/material/Grid'
import Card from '../../Common/Card'
import {
  ClientInfoStatus,
  allClientTermsInfo,
  ClientSetupSections,
} from '@common/interfaces/client'
import { ClientABLField } from '../ClientHelpers'
import styles from '../DealStructure/DealStructure.module.scss'

const FIELDS_MAP = allClientTermsInfo.filter((term) => term.section === ClientSetupSections.Fees)

const pastClientFields = ['applyServicingFee', 'servicingFee', 'gracePeriod']

interface IProps {
  clientStatus: ClientInfoStatus
}

const Fees = ({ clientStatus }: IProps) => {
  return (
    <Card
      title="Fees"
      classes={{
        root: styles.card,
      }}
    >
      <Grid container spacing={2} justifyContent={'start'}>
        {FIELDS_MAP.map(({ label, value, type }) => {
          if (
            pastClientFields.includes(value) &&
            ![ClientInfoStatus.Past, ClientInfoStatus.PastArchived].includes(clientStatus)
          )
            return null
          return (
            <Grid item xs={3} xl={2} key={label}>
              <ClientABLField label={label} name={value} type={type} />
            </Grid>
          )
        })}
      </Grid>
    </Card>
  )
}

export default Fees
