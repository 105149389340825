import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'

import DueDiligenceApplicationTableContacts from './DueDiligenceApplicationTableContacts'

import { dueDiligenceTeamContacts } from '../../redux/dueDiligence/selectors'
import { listTeamContacts, hideTeamContacts } from '../../redux/dueDiligence/actions'

const selector = createStructuredSelector({
  dueDiligenceTeamContacts,
})
const actions = {
  listTeamContacts,
  hideTeamContacts,
}

export default connect(selector, actions)(DueDiligenceApplicationTableContacts)
