import React, { useEffect, useCallback, useState, useMemo } from 'react'
import { Form } from 'react-final-form'
import Box from '@mui/material/Box'

import styles from './RelationshipManager.module.scss'
import genericSs from '@styles/generic.module.scss'
import { debounceEventHandler } from '../../../helpers/helpers'
import Card from '../../Common/Card'
import Button from '../../Common/Button/Button'
import TableContainer from '../../Common/TableContainer/TableContainer'
import Table from '../../Common/Table/Table'
import TableHead from '../../Common/TableHead/TableHead'
import TableRow from '../../Common/TableRow/TableRow'
import TableCell from '../../Common/TableCell/TableCell'
import TableBody from '../../Common/TableBody/TableBody'
import { IUser, UserRole } from '@common/interfaces/user'
import Grid from '@mui/material/Grid'
import AddButton from '../AddButton'
import AddRelationshipManager from './AddRelationshipManager'
import arrayMutators from 'final-form-arrays'
import Tooltip from '@mui/material/Tooltip'
import MenuItem from '@mui/material/MenuItem'
import Menu from '@mui/material/Menu'
import TableFiltersRow from '../../Common/TableFiltersRow'
import { RELATIONSHIP_MANAGERS_FILTERS_CONFIG } from '@common/constants/filters'
import FilterContainer from '../../Filters/FilterContainer'
import { buildFiltersDefaults, buildFiltersValidateSchema } from '../../../helpers/filters'
import WarningModal from '../../WarningModal'
import { ILoadingData } from '../../../redux/types'
import TableLoader from '../../Common/TableLoader'
import SaveState from '../../Common/SaveState'
import { MenuIcon } from '../../Common/Icons'

const mutators = {
  ...arrayMutators,
}

const filtersDefaults = buildFiltersDefaults(RELATIONSHIP_MANAGERS_FILTERS_CONFIG)
const filtersValidate = buildFiltersValidateSchema(RELATIONSHIP_MANAGERS_FILTERS_CONFIG)

interface IProps {
  clientId: string
  relationshipManagers: ILoadingData<{ data: IUser[] }>
  managers: ILoadingData<{ data: IUser[] }>
  listRelationshipManagers: (data: object) => void
  listClientManagers: (clientId: string, params?: any) => void
  addManager: ({ clientIds, managerId }: { clientIds: string[]; managerId: string }) => void
  removeManager: ({ clientIds, managerId }: { clientIds: string[]; managerId: string }) => void
  notifyManager: ({ clientId, managerId }: { clientId: string; managerId: string }) => void
  updateManager: ({
    clientId,
    managerId,
    data,
  }: {
    clientId: string
    managerId: string
    data: Partial<IUser>
  }) => void
  isDD: boolean
}

const RelationshipManager = ({
  clientId,
  relationshipManagers,
  managers,
  listRelationshipManagers,
  listClientManagers,
  addManager,
  removeManager,
  notifyManager,
  updateManager,
  isDD,
}: IProps) => {
  const [isEditModalShown, setIsEditModalShown] = useState(false)
  const [filters, setFilters] = useState(filtersDefaults)
  const [isDeleteModalShown, setIsDeleteModalShown] = useState<boolean>(false)
  const [selectedManager, setSelectedManager] = useState(null)
  const [orderBy, setOrderBy] = useState({
    field: 'email',
    direction: 'ASC',
  })
  const [anchorEl, setAnchorEl] = useState(null)
  const [actionsMenuOpen, setActionsMenuOpen] = useState(false)
  const [isButtonLoading, setIsButtonLoading] = useState(false)

  const role = useMemo(() => (isDD ? UserRole.UW_USER : UserRole.PORTFOLIO_ADMIN), [isDD])

  const { relationshipManagersData } = useMemo(
    () => ({
      relationshipManagersData: relationshipManagers?.data?.data,
    }),
    [relationshipManagers],
  )

  const { isLoadingManagers, managersData, isSavingManagers, isSavedManagers } = useMemo(
    () => ({
      isLoadingManagers: managers.isLoading,
      isSavingManagers: managers.isSaving,
      isSavedManagers: managers.isSaved,
      managersData: managers?.data?.data,
    }),
    [managers],
  )

  const filteredRMUsers = useMemo(() => {
    return relationshipManagersData && managersData
      ? relationshipManagersData.filter(
          (user) => managersData.findIndex((manager) => manager.id === user.id) === -1,
        )
      : relationshipManagersData
  }, [relationshipManagersData, managersData])

  const handleClickMenu = useCallback((user, event: React.MouseEvent<HTMLElement>) => {
    setSelectedManager(user)
    setAnchorEl(event.currentTarget)
    setActionsMenuOpen(true)
  }, [])
  const handleCloseMenu = useCallback(() => {
    setSelectedManager(null)
    setAnchorEl(null)
    setActionsMenuOpen(false)
  }, [])

  const handleFiltersChange = useCallback((data: any) => {
    setFilters(data)
  }, [])

  useEffect(() => {
    listRelationshipManagers({ withCurrent: true, role })
  }, [listRelationshipManagers, role])

  const debounceListSummary = useMemo(
    () =>
      debounceEventHandler((data: any) => {
        listClientManagers(clientId, {
          ...data,
          withCurrent: true,
          clientId,
          role,
        })
      }, 500),
    [clientId, listClientManagers, role],
  )

  useEffect(() => {
    debounceListSummary({
      filters,
      orderBy: orderBy.field,
      orderDirection: orderBy.direction,
    })
  }, [filters, orderBy, debounceListSummary])

  const handleOrderChange = useCallback((field: string) => {
    setOrderBy((order) => ({
      field,
      direction: order.field === field ? (order.direction === 'DESC' ? 'ASC' : 'DESC') : 'ASC',
    }))
  }, [])

  const handleAddManager = useCallback(
    async (data: any) => {
      setIsButtonLoading(true)
      await addManager({ clientIds: [clientId], managerId: data.managerId })
      setIsButtonLoading(false)
    },
    [addManager, clientId],
  )

  const closeDeleteModal = useCallback(() => {
    setIsDeleteModalShown(false)
    handleCloseMenu()
  }, [handleCloseMenu])

  const handleRemoveManager = useCallback(
    async (managerId?: string) => {
      setIsButtonLoading(true)
      await removeManager({ clientIds: [clientId], managerId })
      setIsButtonLoading(false)
      closeDeleteModal()
    },
    [removeManager, clientId, closeDeleteModal],
  )

  const handleNotifyManager = useCallback(
    (managerId: string) => notifyManager({ clientId, managerId }),
    [notifyManager, clientId],
  )

  const handleUpdateManager = useCallback(
    ({ id: managerId, ...data }: any) => updateManager({ managerId, clientId, data }),
    [updateManager, clientId],
  )

  return (
    <Card className={styles.card} noHeaderMargin>
      <Grid container>
        <Box flex={1}>
          <Card withBorder={false} noPadding noHeaderMargin>
            <Card withBorder={false} noPadding noHeaderMargin>
              <AddRelationshipManager
                isEditModalShown={isEditModalShown}
                setIsEditModalShown={setIsEditModalShown}
                handleAddManager={handleAddManager}
                relationshipManagers={filteredRMUsers}
                isButtonLoading={isButtonLoading}
              />
            </Card>
            <Box>
              <Card withBorder={false} noPadding noHeaderMargin>
                <TableContainer className={styles.table}>
                  <Form
                    onSubmit={handleFiltersChange}
                    validate={filtersValidate}
                    initialValues={filters}
                    mutators={{
                      setFieldData: ([field, value], state, { changeValue }) => {
                        changeValue(state, field, () => value)
                      },
                    }}
                    render={({ values, handleSubmit, form: { mutators } }) => (
                      <div className={styles.filtersHeader}>
                        <FilterContainer
                          filters={RELATIONSHIP_MANAGERS_FILTERS_CONFIG}
                          handleSubmit={handleSubmit}
                          mutators={mutators}
                          values={values}
                          appliedFilters={filters}
                          title={`Relationship Managers`}
                          actions={
                            <AddButton
                              variant="outlined"
                              disabled={!clientId}
                              onClick={() => setIsEditModalShown(true)}
                            ></AddButton>
                          }
                        />
                      </div>
                    )}
                  />

                  <Table>
                    <TableHead>
                      <TableFiltersRow
                        filters={RELATIONSHIP_MANAGERS_FILTERS_CONFIG}
                        orderBy={orderBy}
                        handleOrderChange={handleOrderChange}
                      />
                    </TableHead>

                    <TableBody>
                      {isLoadingManagers && <TableLoader columnsCount={5} />}
                      {managersData?.length > 0 &&
                        managersData?.map((manager) => (
                          <Form
                            key={manager.id}
                            initialValues={manager}
                            onSubmit={handleUpdateManager}
                            mutators={mutators}
                            render={({ dirty, handleSubmit }) => (
                              <TableRow>
                                <TableCell className={genericSs.tableTextLeft}>
                                  {manager.firstName}
                                </TableCell>
                                <TableCell className={genericSs.tableTextLeft}>
                                  {manager.lastName}
                                </TableCell>
                                <TableCell className={genericSs.tableTextLeft}>
                                  <Tooltip title={manager.email} arrow>
                                    <span>{manager.email}</span>
                                  </Tooltip>
                                </TableCell>
                                <TableCell className={genericSs.tableTextLeft}>
                                  {manager.isNotified ? (
                                    <span className={styles.active}>Notification Sent</span>
                                  ) : (
                                    <Button
                                      variant="text"
                                      color="primary"
                                      onClick={() => handleNotifyManager(manager.id)}
                                      className={styles.sendNotificationButton}
                                    >
                                      Send Notification
                                    </Button>
                                  )}
                                </TableCell>
                                <TableCell className={genericSs.tableTextRight}>
                                  <Box display="inline-box" ml={1}>
                                    <MenuIcon
                                      onClick={(event) => handleClickMenu(manager, event)}
                                      size="small"
                                    />
                                  </Box>
                                </TableCell>
                              </TableRow>
                            )}
                          />
                        ))}
                    </TableBody>

                    <Menu
                      open={actionsMenuOpen}
                      anchorEl={anchorEl}
                      onClose={handleCloseMenu}
                      className={styles.actionsMenu}
                    >
                      <MenuItem
                        onClick={() => {
                          setIsDeleteModalShown(true)
                        }}
                      >
                        Delete
                      </MenuItem>
                    </Menu>
                    {isDeleteModalShown && (
                      <WarningModal
                        onCancel={closeDeleteModal}
                        onConfirm={() => {
                          handleRemoveManager(selectedManager.id)
                        }}
                        warningMessage={`Deleting this record will be permanent`}
                        isLoading={isButtonLoading}
                      />
                    )}
                  </Table>
                  <Box display="flex" alignItems="center" justifyContent="flex-end">
                    <SaveState isSaving={isSavingManagers} isSaved={isSavedManagers} />
                  </Box>
                </TableContainer>
              </Card>
            </Box>
          </Card>
        </Box>
      </Grid>
    </Card>
  )
}

export default RelationshipManager
