import React from 'react'
import { Field, Form } from 'react-final-form'
import Box from '@mui/material/Box'
import styles from './UserManagementPage.module.scss'
import Checkbox from '../../components/Common/Checkbox'
import TextField from '../../components/Common/TextField'
import Button from '../../components/Common/Button'
import { IUser, UserRole } from '@common/interfaces/user'
import Modal from '../../components/Common/Modal'
import InputLabel from '../../components/Common/InputLabel'
import TableFields from '../../components/TableFields'
import SelectField from '../../components/Common/SelectField'
import { makeValidate } from 'mui-rff'
import * as Yup from 'yup'

const roles = [
  UserRole.ADMIN,
  UserRole.PORTFOLIO_ADMIN,
  UserRole.PORTFOLIO_USER,
  UserRole.BDO,
  UserRole.UW_USER,
]

const schema = Yup.object().shape({
  firstName: Yup.string().required('Required'),
  lastName: Yup.string().required('Required'),
  email: Yup.string().email().required('Required'),
  role: Yup.mixed().oneOf(roles).required('Required'),
  phone: Yup.string()
    .typeError('Phone number is invalid')
    .nullable(true)
    .matches(/^\+?1?\s?(\(\s?\d{3}\s?\)|\d{3})\s?-?\d{3}-?\d{4}$/, 'Phone number is invalid'),
  calendlyURL: Yup.string()
    .typeError('Calendly URL is invalid')
    .nullable(true)
    .url('Calendly URL is invalid'),
})

const validate = makeValidate(schema)

interface IProps {
  isEditModalShown: boolean
  setIsEditModalShown: (val: boolean) => void
  handleCreateAddUser: (data: any & { id?: string }) => void
  user?: IUser
  handleCloseMenu?: () => void
  roleOptions: { value: UserRole; label: string }[]
  isButtonLoading: boolean
}

const AddEditUser = ({
  isEditModalShown,
  setIsEditModalShown,
  handleCreateAddUser,
  user,
  handleCloseMenu,
  roleOptions,
  isButtonLoading,
}: IProps) => {
  return (
    <Form
      onSubmit={handleCreateAddUser}
      initialValues={{
        firstName: user?.firstName,
        lastName: user?.lastName,
        email: user?.email,
        role: user?.role,
        phone: user?.phone,
        calendlyURL: user?.calendlyURL,
        id: user?.id,
      }}
      validate={validate}
      render={({ dirty, invalid, form, handleSubmit }) => (
        <form>
          <Modal
            open={isEditModalShown}
            size="small"
            onCancel={() => {
              if (user) {
                handleCloseMenu()
              }
              setIsEditModalShown(false)
            }}
            title={user ? 'Edit user' : 'Add user'}
            classes={{
              root: styles.editModal,
              footer: styles.editModalFooter,
              title: styles.editModalTitle,
            }}
            footer={[
              <Button
                key="submit"
                color="primary"
                variant="contained"
                className={styles.submitModalButton}
                disabled={!dirty || invalid}
                isLoading={isButtonLoading}
                onClick={(data) => {
                  handleSubmit(data).then(() => {
                    form.restart()
                    handleCloseMenu()
                  })
                }}
              >
                {user ? 'Save & update' : 'Add user'}
              </Button>,
            ]}
          >
            <Box className={styles.fieldsContainer}>
              <TableFields
                rows={[
                  [
                    <InputLabel
                      key="firstNameLabel"
                      htmlFor="firstName"
                      size="normal"
                      position="horizontal"
                    >
                      First Name
                    </InputLabel>,
                  ],
                  [<TextField key="firstNameInput" required name="firstName" />],
                  [
                    <InputLabel
                      key="lastNameLabel"
                      htmlFor="lastName"
                      size="normal"
                      position="horizontal"
                    >
                      Last Name
                    </InputLabel>,
                  ],
                  [<TextField key="lastNameInput" required name="lastName" />],
                  [
                    <InputLabel
                      key="emailLabel"
                      htmlFor="email"
                      size="normal"
                      position="horizontal"
                    >
                      Email
                    </InputLabel>,
                  ],
                  [<TextField key="emailInput" required name="email" disabled={!!user} />],
                  [
                    <InputLabel key="roleLabel" htmlFor="role" size="normal" position="horizontal">
                      Role
                    </InputLabel>,
                  ],
                  [
                    <SelectField
                      key="roleField"
                      name="role"
                      options={roleOptions}
                      defaultValue={UserRole.PORTFOLIO_USER}
                    />,
                  ],
                ]}
              />
            </Box>
            {String(form?.getState().values?.role) === UserRole.PORTFOLIO_ADMIN && (
              <Box className={styles.fieldsContainer}>
                <TableFields
                  rows={[
                    [
                      <InputLabel
                        key="phoneLabel"
                        htmlFor="phone"
                        size="normal"
                        position="horizontal"
                      >
                        Phone number
                      </InputLabel>,
                    ],
                    [<TextField key="phoneInput" required name="phone" />],
                    [
                      <InputLabel
                        key="calendlyLabel"
                        htmlFor="phone"
                        size="normal"
                        position="horizontal"
                      >
                        Calendly url
                      </InputLabel>,
                    ],
                    [<TextField key="calendlyInput" required name="calendlyURL" />],
                  ]}
                />
              </Box>
            )}
            <Box className={styles.checkboxContainer}>
              <TableFields
                rows={[
                  user
                    ? []
                    : [
                        <Field
                          key="sendInviteInput"
                          name="sendInvite"
                          type="checkbox"
                          // only allow sending invite if user is from dwightfund
                          disabled={!form?.getState().values?.email?.endsWith('@dwightfund.com')}
                          component={Checkbox}
                        />,
                        <InputLabel
                          key="sendInviteLabel"
                          htmlFor="sendInvite"
                          size="normal"
                          position="horizontal"
                        >
                          Send invite
                        </InputLabel>,
                      ],
                ]}
              />
            </Box>
          </Modal>
        </form>
      )}
    />
  )
}

export default AddEditUser
