import React, { useEffect } from 'react'
import { useParams, generatePath, useHistory, matchPath } from 'react-router'
import Box from '@mui/material/Box'
import { ROUTES } from '../../constants/routes'
import EntitySettingsHeader from '../../components/EntitySettingsHeader'

interface IProps {
  pathname: string
  getEntityInfo: (id: string) => void
}

const EntitySettingsPage = ({ pathname, getEntityInfo }: IProps) => {
  const { id: entityId } = useParams<{ id?: string }>()
  const history = useHistory()

  useEffect(() => {
    if (entityId) {
      getEntityInfo(entityId)
    }
  }, [entityId, getEntityInfo])

  useEffect(() => {
    if (
      matchPath(pathname, {
        path: ROUTES.ENTITY_SETTINGS,
        exact: true,
      })
    ) {
      history.replace(generatePath(ROUTES.ENTITY_SETTINGS_GENERAL, { id: entityId }))
    }
  }, [history, pathname, entityId])

  return (
    <Box py={1} pr={2}>
      <EntitySettingsHeader />
    </Box>
  )
}

export default EntitySettingsPage
