import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'

import BDOHomepageTable from './BDOHomepageTable'

import { prospectsAggregation } from '../../redux/prospect/selectors'
import { listProspects } from '../../redux/prospect/actions'
import { user } from '../../redux/profile/selectors'

const selector = createStructuredSelector({
  prospectsAggregationData: prospectsAggregation,
  user,
})
const actions = {
  listProspects,
}

export default connect(selector, actions)(BDOHomepageTable)
