import Base from './base'

export default class Treasury extends Base {
  listTreasury(params: object) {
    return this.apiClient.get('treasury', params)
  }

  updateTreasury(params: object) {
    return this.apiClient.put(`treasury`, params)
  }

  createTreasury(params: object) {
    return this.apiClient.post(`treasury`, params)
  }
}
