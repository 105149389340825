import React, { useMemo } from 'react'
import styles from './InventoryDistributionGraph.module.scss'
import { Bar } from 'react-chartjs-2'
import {
  IInventoryDistributionAnalysis,
  InventoryFieldKeys,
  NOT_MAPPED,
} from '@common/interfaces/bbc'
import { formatAmount, formatDate } from '../../helpers/helpers'
import Skeleton from '@mui/material/Skeleton'
import { DAYS_LABELS } from '../BBCInventoryDistributionTable/BBCInventoryDistributionTable'
import { Grid } from '@mui/material'
import { BORDER_RADIUS, COLORS, FONT_SIZE, TOOLTIP } from '../../constants/graphs'

const DAYS_SINCE_LANDED_ORDER = [
  '0-90 Days',
  '91-180 Days',
  '181-270 Days',
  '271-360 Days',
  '361+ Days',
]

const DAYS_UNTIL_EXPIRATION_ORDER = [
  '361+ Days',
  '271-360 Days',
  '181-270 Days',
  '91-180 Days',
  '0-90 Days',
]

const DAYS_SINCE_LANDED_COLORS = {
  '0': '#54936D',
  '91': '#66B485',
  '181': '#F5B396',
  '271': '#E48C65',
  '361': '#CC5858',
}

const DAYS_UNTIL_EXPIRATION_COLORS = {
  '361': '#54936D',
  '271': '#66B485',
  '181': '#F5B396',
  '91': '#E48C65',
  '0': '#CC5858',
}

interface IProps {
  borrowingBases: { id: string; recordDate: string }[]
  inventoryDistributionAnaysis: IInventoryDistributionAnalysis[]
  isPercent: boolean
  field: InventoryFieldKeys
  isLoading: boolean
}

const InventoryDistributionGraph = ({
  borrowingBases,
  inventoryDistributionAnaysis,
  isLoading,
  isPercent,
  field,
}: IProps) => {
  const top5Categories = useMemo(() => {
    const sumByDistribution: {
      [key: string]: number
    } = {}
    inventoryDistributionAnaysis?.forEach((item) => {
      const sum = Object.values(item?.values).reduce((acc, value) => {
        return (acc || 0) + (value.value || 0)
      }, 0)
      sumByDistribution[item.distribution] = sum
    })

    const sortedSumByDistribution = Object.entries(sumByDistribution).sort((a, b) => b[1] - a[1])
    const top5 = sortedSumByDistribution.slice(0, 5).map((item) => item[0])
    return top5
  }, [inventoryDistributionAnaysis])

  const cleanedUpData = useMemo(() => {
    return inventoryDistributionAnaysis?.map((item, index) => {
      const data = borrowingBases?.map(({ id }) => {
        const value = item?.values[id]?.value
        const percent = item?.values?.[id]?.percent
        return { value, percent }
      })
      const distribution = item.distribution?.toString() || ''
      const isTop5 = top5Categories?.includes(distribution)
      const top5Index = top5Categories?.findIndex((category) => category === distribution)
      let label
      if (isTop5) {
        label = DAYS_LABELS[distribution] || distribution || NOT_MAPPED
      } else {
        label = 'Other'
      }

      let backgroundColor = COLORS[top5Index]
      if (field === 'daysSinceLanded') {
        backgroundColor = DAYS_SINCE_LANDED_COLORS[distribution]
      } else if (field === 'daysUntilExpiration') {
        backgroundColor = DAYS_UNTIL_EXPIRATION_COLORS[distribution]
      }

      return {
        label,
        data,
        backgroundColor,
        borderRadius: BORDER_RADIUS,
      }
    })
  }, [inventoryDistributionAnaysis, top5Categories, borrowingBases, field])

  const sumDatasets: {
    label: string
    data: {
      value: number
      percent: number
    }[]
    backgroundColor: string
    borderRadius: number
  }[] = useMemo(() => {
    const sumDatasets = cleanedUpData?.reduce((acc, dataset) => {
      const index = acc?.findIndex((item) => item.label === dataset.label)
      if (index !== -1) {
        acc[index].data = acc[index].data.map(
          (
            value: {
              value: number
              percent: number
            },
            index: number,
          ) => {
            const sum = (value.value || 0) + (dataset.data[index].value || 0)
            const percent = (value.percent || 0) + (dataset.data[index].percent || 0)
            return { value: sum, percent }
          },
        )
      } else {
        acc.push(dataset)
      }
      return acc
    }, [])

    return sumDatasets
  }, [cleanedUpData])

  const sortedSum = useMemo(() => {
    const orderList =
      field === 'daysSinceLanded'
        ? DAYS_SINCE_LANDED_ORDER
        : field === 'daysUntilExpiration'
        ? DAYS_UNTIL_EXPIRATION_ORDER
        : top5Categories
    const sorted = sumDatasets?.sort((a, b) => {
      const aIndex = orderList.findIndex((item) => item === a.label)
      const bIndex = orderList.findIndex((item) => item === b.label)
      return aIndex - bIndex
    })
    return sorted
  }, [sumDatasets, field, top5Categories])

  const datasets = useMemo(() => {
    const key = isPercent ? 'percent' : 'value'
    const dataset = sortedSum?.map((item) => {
      return {
        ...item,
        data: item.data.map((value) => value[key]),
      }
    })
    return dataset
  }, [sortedSum, isPercent])

  const chartOptions = useMemo(
    () => ({
      maintainAspectRatio: false,
      scales: {
        x: {
          stacked: true,
          grid: {
            display: false,
          },
          ticks: {
            font: {
              size: FONT_SIZE,
            },
          },
        },
        y: {
          stacked: true,
          grid: {
            display: false,
          },
          ticks: {
            font: {
              size: FONT_SIZE,
            },
            callback: (value: any) => {
              if (isPercent) {
                return value ? `${value}%` : `0%`
              } else {
                return value ? formatAmount(value) : `$0`
              }
            },
          },
        },
      },
      plugins: {
        legend: {
          position: 'top' as const,
          labels: {
            font: {
              size: FONT_SIZE,
              weight: '500',
            },
            color: 'black',
            boxWidth: 20,
            boxHeight: 20,
          },
        },
        tooltip: {
          ...TOOLTIP,
          callbacks: {
            title: (tooltipItems: any) => {
              // Assuming 'recordDate' is the x-axis label
              return `${tooltipItems[0].label}`
            },
            label: (tooltipItem: any) => {
              const datasetIndex = tooltipItem.datasetIndex
              const dataIndex = tooltipItem.dataIndex

              const value = sumDatasets[datasetIndex].data?.[dataIndex]?.value || 0
              const percent = sumDatasets[datasetIndex].data?.[dataIndex]?.percent || 0

              // Format the value and percent
              const formattedValue = formatAmount(value) || '0'
              const formattedPercent = percent.toFixed(2) + '%'

              return [`$${formattedValue}`, formattedPercent]
            },
            footer: (tooltipItem: any) => {
              if (isPercent) {
                return
              }
              return '$' + formatAmount(tooltipItem.reduce((a: any, b: any) => a + b.parsed.y, 0))
            },
          },
        },
      },
    }),
    [isPercent, sumDatasets],
  )
  const labels = useMemo(
    () => borrowingBases?.map(({ recordDate }) => formatDate(recordDate)),
    [borrowingBases],
  )

  const chartData = useMemo(() => {
    if (datasets) {
      return {
        labels,
        datasets,
      }
    } else {
      return null
    }
  }, [labels, datasets])

  return (
    <div className={styles.chartContainer}>
      {isLoading ? (
        <Grid
          container
          spacing={5}
          className={styles.skeletonContainer}
          justifyContent={'start'}
          height={'100%'}
        >
          {Array.from({ length: 6 }, (_, index) => (
            <Grid item xs={2} key={index} height={'100%'}>
              <Skeleton variant="rectangular" width="100%" height="100%" />
            </Grid>
          ))}
        </Grid>
      ) : (
        chartData && <Bar className={styles.chart} data={chartData} options={chartOptions} />
      )}
    </div>
  )
}

export default InventoryDistributionGraph
