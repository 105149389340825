import React from 'react'
import { useParams } from 'react-router'
import Box from '@mui/material/Box'

import ClientSetupHeader from '../../components/ClientSetupHeader'
import ClientOveradvances from '../../components/ClientOveradvances'

const ClientSetupLoanStructureOveradvancePage = () => {
  const { id: clientId } = useParams<{ id?: string }>()

  return (
    <Box py={1} pr={2}>
      <ClientSetupHeader />

      <Box>
        <ClientOveradvances clientId={clientId} />
      </Box>
    </Box>
  )
}

export default ClientSetupLoanStructureOveradvancePage
