import { createRequestTypes } from '../../helpers/helpers'
import { IApi } from '../../api'
import { CommonPromiseAction } from '../types'

export const prefix = 'participant'

const SHOW_PARTICIPANT = createRequestTypes(prefix, 'SHOW_PARTICIPANT')

export const [SHOW_PARTICIPANT_REQUEST, SHOW_PARTICIPANT_SUCCESS, SHOW_PARTICIPANT_FAILURE] =
  SHOW_PARTICIPANT

export const showParticipant: CommonPromiseAction = (id: string) => ({
  type: SHOW_PARTICIPANT,
  promise: (api: IApi) => api.participant.show(id),
})

const UPDATE_PARTICIPANT = createRequestTypes(prefix, 'UPDATE_PARTICIPANT')

export const [UPDATE_PARTICIPANT_REQUEST, UPDATE_PARTICIPANT_SUCCESS, UPDATE_PARTICIPANT_FAILURE] =
  UPDATE_PARTICIPANT

export const updateParticipant: CommonPromiseAction = (id: string, data: object) => ({
  type: UPDATE_PARTICIPANT,
  promise: (api: IApi) => api.participant.updateParticipant(id, data),
})

const LIST_PARTICIPANT = createRequestTypes(prefix, 'LIST_PARTICIPANT')

export const [LIST_PARTICIPANT_REQUEST, LIST_PARTICIPANT_SUCCESS, LIST_PARTICIPANT_FAILURE] =
  LIST_PARTICIPANT

export const listParticipants: CommonPromiseAction = (data?: object) => ({
  type: LIST_PARTICIPANT,
  promise: (api: IApi) => api.participant.list(data),
})

const CREATE_PARTICIPANT = createRequestTypes(prefix, 'CREATE_PARTICIPANT')

export const [CREATE_PARTICIPANT_REQUEST, CREATE_PARTICIPANT_SUCCESS, CREATE_PARTICIPANT_FAILURE] =
  CREATE_PARTICIPANT

// not in use right now can be used to create new participant
export const createParticipant: CommonPromiseAction = (data: object) => ({
  type: CREATE_PARTICIPANT,
  promise: (api: IApi) => api.participant.create(data),
})

const LIST_CLIENT_PARTICIPATION = createRequestTypes(prefix, 'LIST_CLIENT_PARTICIPATION')

export const [
  LIST_CLIENT_PARTICIPATION_REQUEST,
  LIST_CLIENT_PARTICIPATION_SUCCESS,
  LIST_CLIENT_PARTICIPATION_FAILURE,
] = LIST_CLIENT_PARTICIPATION

export const listClientParticipations: CommonPromiseAction = (id: string, data: object) => ({
  type: LIST_CLIENT_PARTICIPATION,
  promise: (api: IApi) => api.participant.listClientParticipations(id, data),
})

const LIST_PARTICIPANT_PARTICIPATION = createRequestTypes(prefix, 'LIST_PARTICIPANT_PARTICIPATION')

export const [
  LIST_PARTICIPANT_PARTICIPATION_REQUEST,
  LIST_PARTICIPANT_PARTICIPATION_SUCCESS,
  LIST_PARTICIPANT_PARTICIPATION_FAILURE,
] = LIST_PARTICIPANT_PARTICIPATION

export const listParticipantParticipations: CommonPromiseAction = (id: string, data: object) => ({
  type: LIST_PARTICIPANT_PARTICIPATION,
  promise: (api: IApi) => api.participant.listParticipantParticipations(id, data),
})

const LIST_ALL_PARTICIPATION = createRequestTypes(prefix, 'LIST_ALL_PARTICIPATION')

export const [
  LIST_ALL_PARTICIPATION_REQUEST,
  LIST_ALL_PARTICIPATION_SUCCESS,
  LIST_ALL_PARTICIPATION_FAILURE,
] = LIST_ALL_PARTICIPATION

export const listAllParticipations: CommonPromiseAction = (data: object) => ({
  type: LIST_ALL_PARTICIPATION,
  promise: (api: IApi) => api.participant.listAllParticipations(data),
})

const CREATE_PARTICIPATION = createRequestTypes(prefix, 'CREATE_PARTICIPATION')

export const [
  CREATE_PARTICIPATION_REQUEST,
  CREATE_PARTICIPATION_SUCCESS,
  CREATE_PARTICIPATION_FAILURE,
] = CREATE_PARTICIPATION

export const createParticipation: CommonPromiseAction = (id: string, data: object) => ({
  type: CREATE_PARTICIPATION,
  promise: (api: IApi) => api.participant.createParticipation(id, data),
})

const UPDATE_PARTICIPATION = createRequestTypes(prefix, 'UPDATE_PARTICIPATION')

export const [
  UPDATE_PARTICIPATION_REQUEST,
  UPDATE_PARTICIPATION_SUCCESS,
  UPDATE_PARTICIPATION_FAILURE,
] = UPDATE_PARTICIPATION

export const updateParticipation: CommonPromiseAction = (
  id: string,
  participationId: string,
  data: object,
) => ({
  type: UPDATE_PARTICIPATION,
  promise: (api: IApi) => api.participant.updateParticipation(id, participationId, data),
})

const TERMINATE_PARTICIPATION = createRequestTypes(prefix, 'DELETE_PARTICIPATION')

export const [
  TERMINATE_PARTICIPATION_REQUEST,
  TERMINATE_PARTICIPATION_SUCCESS,
  TERMINATE_PARTICIPATION_FAILURE,
] = TERMINATE_PARTICIPATION

export const terminateParticipation: CommonPromiseAction = (
  id: string,
  participationId: string,
  data: object,
) => ({
  type: TERMINATE_PARTICIPATION,
  promise: (api: IApi) => api.participant.terminateParticipation(id, participationId, data),
})

const CREATE_PARTICIPATION_WIRE = createRequestTypes(prefix, 'CREATE_PARTICIPATION_WIRE')

export const [
  CREATE_PARTICIPATION_WIRE_REQUEST,
  CREATE_PARTICIPATION_WIRE_SUCCESS,
  CREATE_PARTICIPATION_WIRE_FAILURE,
] = CREATE_PARTICIPATION_WIRE

export const createParticipationWire: CommonPromiseAction = (
  id: string,
  participationId: string,
  data: object,
) => ({
  type: CREATE_PARTICIPATION_WIRE,
  promise: (api: IApi) => api.participant.createParticipationWire(id, participationId, data),
})

const UPDATE_PARTICIPATION_WIRE = createRequestTypes(prefix, 'UPDATE_PARTICIPATION_WIRE')

export const [
  UPDATE_PARTICIPATION_WIRE_REQUEST,
  UPDATE_PARTICIPATION_WIRE_SUCCESS,
  UPDATE_PARTICIPATION_WIRE_FAILURE,
] = UPDATE_PARTICIPATION_WIRE

export const updateParticipationWire: CommonPromiseAction = (
  id: string,
  participationId: string,
  participationWireId: string,
  data: object,
) => ({
  type: UPDATE_PARTICIPATION_WIRE,
  promise: (api: IApi) =>
    api.participant.updateParticipationWire(id, participationId, participationWireId, data),
})

const DELETE_PARTICIPATION_WIRE = createRequestTypes(prefix, 'DELETE_PARTICIPATION_WIRE')

export const [
  DELETE_PARTICIPATION_WIRE_REQUEST,
  DELETE_PARTICIPATION_WIRE_SUCCESS,
  DELETE_PARTICIPATION_WIRE_FAILURE,
] = DELETE_PARTICIPATION_WIRE

export const deleteParticipationWire: CommonPromiseAction = (
  id: string,
  participationId: string,
  participationWireId: string,
  data: object,
) => ({
  type: DELETE_PARTICIPATION_WIRE,
  promise: (api: IApi) =>
    api.participant.deleteParticipationWire(id, participationId, participationWireId, data),
})

const LIST_OUTSTANDING_WIRES = createRequestTypes(prefix, 'LIST_OUTSTANDING_WIRES')

export const [
  LIST_OUTSTANDING_WIRES_REQUEST,
  LIST_OUTSTANDING_WIRES_SUCCESS,
  LIST_OUTSTANDING_WIRES_FAILURE,
] = LIST_OUTSTANDING_WIRES

export const listOutstandingWires: CommonPromiseAction = (data: object) => ({
  type: LIST_OUTSTANDING_WIRES,
  promise: (api: IApi) => api.participant.listOutstandingWires(data),
})

const EXPORT_PARTICIPATION_ACTIVITY = createRequestTypes(prefix, 'EXPORT_PARTICIPATION_ACTIVITY')

export const [
  EXPORT_PARTICIPATION_ACTIVITY_REQUEST,
  EXPORT_PARTICIPATION_ACTIVITY_SUCCESS,
  EXPORT_PARTICIPATION_ACTIVITY_FAILURE,
] = EXPORT_PARTICIPATION_ACTIVITY

export const exportParticipationActivity: CommonPromiseAction = (id: string, params: object) => ({
  type: EXPORT_PARTICIPATION_ACTIVITY,
  promise: (api: IApi) => api.participant.exportParticipationActivity(id, params),
  params,
})
