import React from 'react'
import LinearProgress from '@mui/material/LinearProgress'

import styles from './PasswordMeter.module.scss'

const PasswordMeter = ({ percent }: { percent?: number }) => {
  return (
    <div>
      <LinearProgress className={styles.progress} variant="determinate" value={percent} />
      {percent >= 40 && percent < 100 && <div className={styles.goodPassword}>Good</div>}
      {percent === 100 && <div className={styles.strongPassword}>Strong</div>}
    </div>
  )
}

export default PasswordMeter
