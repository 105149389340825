import FlagContainer from './FlagContainer'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'

import { flags, isLoading } from '../../redux/flag/selectors'
import { listFlags } from '../../redux/flag/actions'

const selector = createStructuredSelector({
  flags,
  isLoading,
})
const actions = {
  listFlags,
}

export default connect(selector, actions)(FlagContainer)
