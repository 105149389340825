import { loanBalanceStartDate } from './../../redux/loanBalance/selectors'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'

import CollectionsPassThroughsTable from './CollectionsPassThroughsTable'
import { passThroughData } from '../../redux/passThrough/selectors'
import { clients } from '../../redux/clientInfo/selectors'
import { list as listClients } from '../../redux/clientInfo/actions'
import {
  listPassThroughs,
  submitPassThroughs,
  deletePassthrough,
} from '../../redux/passThrough/actions'

const selector = createStructuredSelector({
  clients,
  passThroughData,
  loanBalanceStartDate,
})

const actions = {
  listClients,
  listPassThroughs,
  submitPassThroughs,
  deletePassthrough,
}

export default connect(selector, actions)(CollectionsPassThroughsTable)
