import React, { useMemo } from 'react'
import { useParams } from 'react-router'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'

import ClientSetupHeader from '../../components/ClientSetupHeader'
import RelationshipManager from '../../components/Client/RelationshipManager'
import { ClientInfoStatus, IClientInfo } from '@common/interfaces/client'

const ClientSetupRelationshipManagerPage = ({ clientInfo }: { clientInfo: IClientInfo }) => {
  const { id: clientId } = useParams<{ id?: string }>()

  const isDD = useMemo(
    () => clientInfo?.clientStatus === ClientInfoStatus.DueDiligence,
    [clientInfo],
  )

  return (
    <Box py={1} pr={2}>
      <ClientSetupHeader />
      <Box>
        <Grid container spacing={1}>
          <Grid item xs={12} lg={12}>
            <RelationshipManager clientId={clientId} isDD={isDD} />
          </Grid>
        </Grid>
      </Box>
    </Box>
  )
}

export default ClientSetupRelationshipManagerPage
