import { connect } from 'react-redux'

import DueDiligenceClientDocumentRequestModal from './DueDiligenceClientDocumentRequestModal'

import { putNotification } from '../../redux/ui/actions'
import { userId } from '../../redux/profile/selectors'
import { createStructuredSelector } from 'reselect'

const selector = createStructuredSelector({
  userId,
})

const actions = {
  putNotification,
}

export default connect(selector, actions)(DueDiligenceClientDocumentRequestModal)
