import { connect } from 'react-redux'
import { participantInfo } from './../../redux/participant/selectors'
import { showParticipant } from './../../redux/participant/actions'
import { createStructuredSelector } from 'reselect'
import ProspectPage from './ParticipantSettingsPage'

const selector = createStructuredSelector({
  participantInfo,
})

const actions = {
  showParticipant,
}

export default connect(selector, actions)(ProspectPage)
