import { createRequestTypes } from '../../helpers/helpers'
import { IApi } from '../../api'
import { CommonPromiseAction } from '../types'

export const prefix = 'outgoingWire'

const LIST_OUTGOING_WIRE = createRequestTypes(prefix, 'LIST_OUTGOING_WIRE')

export const [LIST_OUTGOING_WIRE_REQUEST, LIST_OUTGOING_WIRE_SUCCESS, LIST_OUTGOING_WIRE_FAILURE] =
  LIST_OUTGOING_WIRE

export const listOutgoingWire: CommonPromiseAction = (data: object) => ({
  type: LIST_OUTGOING_WIRE,
  promise: (api: IApi) => api.outgoingWire.list(data),
  params: data,
})

const SENT_OUTGOING_WIRE = createRequestTypes(prefix, 'SENT_OUTGOING_WIRE')

export const [SENT_OUTGOING_WIRE_REQUEST, SENT_OUTGOING_WIRE_SUCCESS, SENT_OUTGOING_WIRE_FAILURE] =
  SENT_OUTGOING_WIRE

export const sentOutgoingWires: CommonPromiseAction = (data: object) => ({
  type: SENT_OUTGOING_WIRE,
  promise: (api: IApi) => api.outgoingWire.sent(data),
})

const DELETE_OUTGOING_WIRE = createRequestTypes(prefix, 'DELETE_OUTGOING_WIRE')

export const [
  DELETE_OUTGOING_WIRE_REQUEST,
  DELETE_OUTGOING_WIRE_SUCCESS,
  DELETE_OUTGOING_WIRE_FAILURE,
] = DELETE_OUTGOING_WIRE

export const deleteOutgoingWire: CommonPromiseAction = (id: string) => ({
  type: DELETE_OUTGOING_WIRE,
  promise: (api: IApi) => api.outgoingWire.delete(id),
})
