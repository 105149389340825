import { Action } from 'redux'
import { History } from 'history'
import { takeEvery } from 'redux-saga/effects'
import { ROUTES } from '../../constants/routes'
import { LIST_FAILURE } from './actions'
import { IResponse } from '@common/interfaces/request'

export function* listFailure(history: History) {
  yield takeEvery(LIST_FAILURE, function* (data: IResponse & Action) {
    if (data.error && data.error.code === 'PERMISSION_DENIED') {
      yield history.push(ROUTES.HOMEPAGE)
    }
  })
}
