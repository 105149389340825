import React, { useEffect, useState, useMemo, useCallback } from 'react'
import { Form } from 'react-final-form'
import { OnBlur, OnChange } from 'react-final-form-listeners'
import Box from '@mui/material/Box'
import styles from './BasicInformation.module.scss'
import genericSs from '@styles/generic.module.scss'
import InputLabel from '../../Common/InputLabel'
import SelectField from '../../Common/SelectField'
import TextField from '../../Common/TextField'
import Card from '../../Common/Card'
import Button from '../../Common/Button'
import { IAccount, IAccountIndustry, IAccountSalesChannel } from '@common/interfaces/salesforce'
import { IClientInfo } from '@common/interfaces/client'
import SelectWithCheckboxesField from '../../Common/SelectWithCheckboxesField'

interface IProps {
  clientInfo: IClientInfo
  accounts: IAccount[]
  account: IAccount
  accountIndustries?: IAccountIndustry[]
  accountSalesChannels?: IAccountSalesChannel[]
  listAccounts: () => void
  updateAccount: (id: string, data: object) => void
  describeAccount: () => void
  showAccount: (id: string) => void
  hideAccount: () => void
  updateClient: (id: string, data: object) => void
}

const BasicInformation = ({
  clientInfo,
  accounts,
  account,
  accountIndustries,
  accountSalesChannels,
  listAccounts,
  updateAccount,
  describeAccount,
  showAccount,
  hideAccount,
  updateClient,
}: IProps) => {
  const [selectedAccount, setSelectedAccount] = useState(null)

  useEffect(() => {
    listAccounts()
  }, [listAccounts])

  const { accountId, clientName } = clientInfo || {}

  useEffect(() => {
    if (accountId) {
      showAccount(accountId)
      setSelectedAccount({ value: accountId, label: clientName })
    }

    return hideAccount
  }, [accountId, clientName, showAccount, hideAccount, setSelectedAccount])

  useEffect(() => {
    describeAccount()
  }, [describeAccount])

  const onSubmit = (values: any) => {
    if (values.industry !== account.industry) {
      values.subIndustry = ''
    }

    const { company, salesChannels, ...data } = values
    data.salesChannels = Object.entries(salesChannels)
      .filter(([key]) => key !== 'all')
      .reduce((acc: string[], [key, value]) => (value ? [...acc, key] : acc), [])

    updateAccount(company.value, data)
  }

  const handleCompanyChange = useCallback(
    (data: { value: string; label: string }) => setSelectedAccount(data),
    [],
  )
  const handleNicknameChange = useCallback(
    (nickname: string) => {
      updateClient(clientInfo.id, { nickname: nickname || '' })
    },
    [updateClient, clientInfo],
  )

  const initialValues = useMemo(
    () => ({
      company: selectedAccount,
      industry: account?.industry,
      subIndustry: account?.subIndustry,
      salesChannels:
        account?.salesChannels &&
        Array.isArray(account?.salesChannels) &&
        account?.salesChannels.reduce(
          (acc, item: string) => ({
            ...acc,
            [item]: true,
          }),
          {},
        ),
      description: account?.description,
      nickname: clientInfo?.nickname,
    }),
    [selectedAccount, account, clientInfo],
  )

  if (!clientInfo) {
    return null
  }

  return (
    <Card title="Company Information" className={styles.card}>
      <Form
        initialValues={initialValues}
        onSubmit={onSubmit}
        render={({ values, handleSubmit, dirty }) => (
          <form>
            <Box>
              <Box display="flex" mb={1.5} alignItems="flex-end">
                <Box width="100%">
                  <InputLabel htmlFor="company">Company</InputLabel>
                  <span className={styles.companyLabel}>{clientInfo.clientName}</span>
                </Box>
                {clientInfo && (
                  <Box ml={1}>
                    <InputLabel htmlFor="nickname">Nickname</InputLabel>
                    <TextField name="nickname" />
                  </Box>
                )}
                {clientInfo && (
                  <Box ml={1}>
                    <Button
                      onClick={() => handleNicknameChange(values.nickname)}
                      variant="contained"
                      color="primary"
                    >
                      Apply
                    </Button>
                  </Box>
                )}
              </Box>
              <Box display="flex" mb={1.5}>
                <Box flex={1}>
                  <InputLabel htmlFor="industry">Industry</InputLabel>
                  <SelectField
                    name="industry"
                    options={accountIndustries.map(({ id: industryId, name: industryName }) => ({
                      value: industryId,
                      label: industryName,
                    }))}
                    disabled={!accounts.length}
                  />
                </Box>
                <Box flex={1} ml={1}>
                  <InputLabel htmlFor="subIndustry">Sub-Industry</InputLabel>
                  <SelectField
                    name="subIndustry"
                    options={(
                      accountIndustries.find(({ id }) => id === values.industry)?.list || []
                    ).map(({ id: subIndustryId, name: accountSubIndustryName }) => ({
                      value: subIndustryId,
                      label: accountSubIndustryName,
                    }))}
                    disabled={!accounts.length}
                  />
                </Box>
                <Box flex={1} ml={1}>
                  <InputLabel htmlFor="salesChannels">Sub-Channels</InputLabel>
                  <SelectWithCheckboxesField
                    className={genericSs.fullWidth}
                    name="salesChannels"
                    options={accountSalesChannels?.map(
                      ({ id: salesChanelId, name: salesChanelName }) => ({
                        value: salesChanelId,
                        label: salesChanelName,
                      }),
                    )}
                    disabled={!accounts.length}
                  />
                </Box>
              </Box>
              <Box>
                <InputLabel htmlFor="description">Description</InputLabel>
                <TextField
                  rows={13}
                  name="description"
                  multiline
                  fullWidth
                  className={styles.textArea}
                  disabled={!accounts.length}
                />
              </Box>
            </Box>
            <OnChange name="company">{(value) => dirty && handleCompanyChange(value)}</OnChange>
            <OnChange name="industry">{() => dirty && handleSubmit()}</OnChange>
            <OnChange name="subIndustry">{() => dirty && handleSubmit()}</OnChange>
            <OnChange name="salesChannels">{() => dirty && handleSubmit()}</OnChange>
            <OnBlur name="description">{() => dirty && handleSubmit()}</OnBlur>
          </form>
        )}
      />
    </Card>
  )
}

export default BasicInformation
