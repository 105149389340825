import React, { useMemo } from 'react'
import { generatePath } from 'react-router-dom'
import { useParams } from 'react-router'
import Grid from '@mui/material/Grid'

import styles from './ClientSetupHeader.module.scss'

import { ReactComponent as HomeIcon } from '@assets/images/home-icon.svg'
import { IClientInfo } from '@common/interfaces/client'
import Breadcrumbs from '../Common/Breadcrumbs'
import ClientSetupTabs from '../ClientSetupTabs'
import { ROUTES } from '../../constants/routes'
import Loader from '../Loader'
import { useLoadInfo } from '../../hooks/useLoadInfo'
import { useSetPageTitle } from '../../hooks/useSetPageTitle'

interface IProps {
  clientInfo: IClientInfo
  isAdmin: boolean
  showClient: (id: string) => void
}

const ClientSetupHeader = ({ clientInfo, showClient, isAdmin }: IProps) => {
  const { id } = useParams<{ id?: string }>()

  useLoadInfo({ id, info: clientInfo, show: showClient })
  useSetPageTitle('Client setup')

  const breadcrumbs = useMemo(() => {
    return id
      ? [
          {
            link: ROUTES.CLIENT_MANAGEMENT,
            Icon: HomeIcon,
          },
          {
            title: clientInfo?.clientName,
            link: generatePath(ROUTES.CLIENT_PAGE, { id }),
          },
          {
            title: 'Client setup',
            link: generatePath(ROUTES.CLIENT_SETUP, { id }),
          },
        ]
      : [
          {
            link: ROUTES.CLIENT_MANAGEMENT,
            Icon: HomeIcon,
          },
          {
            title: 'Client setup',
            link: ROUTES.CLIENT_SETUP_CREATE,
          },
        ]
  }, [clientInfo, id])

  if (id && !clientInfo) {
    return <Loader />
  }

  return (
    <Grid item xs={12}>
      <Breadcrumbs breadcrumbs={breadcrumbs} />

      <div className={styles.tabs}>
        <ClientSetupTabs />
      </div>
    </Grid>
  )
}

export default ClientSetupHeader
