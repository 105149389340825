import Base from './base'

export default class Newsfeed extends Base {
  listNewsfeed(params: object) {
    return this.apiClient.get('newsfeed/all', params)
  }

  listClientNewsfeed() {
    return this.apiClient.get('newsfeed/client')
  }

  showNewsfeed(id: string) {
    return this.apiClient.get(`newsfeed/all/${id}`)
  }

  deleteNews(id: string) {
    return this.apiClient.delete(`newsfeed/all/${id}`)
  }

  updateNewsfeed(id: string, params: object) {
    return this.apiClient.put(`newsfeed/all/${id}`, params)
  }

  listBrandOptions(params: object) {
    return this.apiClient.get('newsfeed/brand-options', params)
  }

  updateBrand(id: string, data: object) {
    return this.apiClient.put(`newsfeed/brand/${id}`, data)
  }

  selectNewsletterHeadlines(data: object) {
    return this.apiClient.post('newsfeed/select-newsletter-headlines', data)
  }
}
