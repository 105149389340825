import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'

import ClientSetupTabs from './ClientSetupTabs'

import { isAdminRightsRole } from '../../redux/profile/selectors'

const selector = createStructuredSelector({
  isAdminRightsRole,
})

export default connect(selector)(ClientSetupTabs)
