import { ActionPattern } from '@redux-saga/types'
import { IApi } from '../../api'
import { CommonPromiseAction } from '../types'

export const prefix = 'frequency'

export const LIST_REQUEST = `${prefix}/LIST_REQUEST`
export const LIST_SUCCESS = `${prefix}/LIST_SUCCESS`
export const LIST_FAILURE = `${prefix}/LIST_FAILURE` as ActionPattern

export const listFrequencies: CommonPromiseAction = () => ({
  type: [LIST_REQUEST, LIST_SUCCESS, LIST_FAILURE],
  promise: (api: IApi) => api.frequency.list(),
})
