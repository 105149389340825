import React from 'react'
import Box from '@mui/material/Box'
import Skeleton from '@mui/material/Skeleton'

import styles from './BBCInventoryIneligibleTable.module.scss'
import genericSs from '@styles/generic.module.scss'

import Table from '../Common/Table'
import TableHead from '../Common/TableHead'
import TableContainer from '../Common/TableContainer'
import TableBody from '../Common/TableBody'
import TableFiltersRow from '../Common/TableFiltersRow'
import { BBC_INVENTORY_INELIGIBILITY_SHORT_LIST_FILTERS_CONFIG } from '@common/constants/filters'
import TableLoader from '../Common/TableLoader'
import { voidHandler } from '../../helpers/helpers'

const EMPTY_ORDER_BY: any = {
  field: null,
  direction: 'ASC',
}
const BBCInventoryIneligibleTableLoader = () => {
  return (
    <TableContainer className={styles.table}>
      <Box mt={2} mb={3}>
        <Skeleton height={32} width={90} />
      </Box>
      <Table>
        <TableHead>
          <TableFiltersRow
            filters={BBC_INVENTORY_INELIGIBILITY_SHORT_LIST_FILTERS_CONFIG}
            orderBy={EMPTY_ORDER_BY}
            handleOrderChange={voidHandler}
          />
        </TableHead>
        <TableBody>
          <TableLoader columnsCount={2} height={36} />
        </TableBody>
      </Table>
      <Box display="flex" alignItems="center" justifyContent="flex-end">
        <div className={genericSs.savingContainer} />
      </Box>
    </TableContainer>
  )
}

export default BBCInventoryIneligibleTableLoader
