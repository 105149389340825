import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'

import AuthContainer from './AuthContainer'

import { isAuthenticated, isLoading } from '../../redux/profile/selectors'
import { pathname } from '../../redux/router/selectors'

const selector = createStructuredSelector({
  isAuthenticated,
  isLoading,
  pathname,
})

const actions = {}

export default connect(selector, actions)(AuthContainer)
