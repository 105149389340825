import Base from './base'

export default class Box extends Base {
  listFolders() {
    return this.apiClient.get('box/folders')
  }
  getEmbedLink(fileId: string) {
    return this.apiClient.get(`box/embed-link/${fileId}`)
  }
}
