import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import { getEntityInfo } from '../../redux/entityInfo/actions'

import { pathname } from '../../redux/router/selectors'
import EntitySettingsPage from './EntitySettingsPage'

const selector = createStructuredSelector({
  pathname,
})
const actions = {
  getEntityInfo,
}

export default connect(selector, actions)(EntitySettingsPage)
