import React, { useMemo } from 'react'
import styles from './DashboardManager.module.scss'
import { IClientInfo } from '@common/interfaces/client'
import { ReactComponent as MailIcon } from '../../assets/images/mail-icon.svg'
import { ReactComponent as CallIcon } from '../../assets/images/call-icon.svg'
import { ReactComponent as CalendarIcon } from '../../assets/images/calendar-icon.svg'
import Avatar from '@mui/material/Avatar'
interface IProps {
  clientInfo?: IClientInfo
}

const DashboardManager = ({ clientInfo }: IProps) => {
  const { firstName, lastName, avatar, email, phone, calendlyURL } = useMemo(
    () => ({ ...clientInfo?.primaryManager }),
    [clientInfo?.primaryManager],
  )

  return (
    <div className={styles.dashboardManager}>
      <div>
        <div className={styles.questionsTitle}>Questions?</div>
        <div className={styles.questionsText}>
          For any questions please reach out to {firstName}.
        </div>
      </div>
      <div className={styles.dashboardManagerBlockDescription}>
        <div className={styles.dashboardManagerBlockAvatarContainer}>
          <Avatar
            alt={`${firstName} ${lastName}`}
            variant="rounded"
            src={avatar}
            className={styles.dashboardManagerBlockAvatar}
          />
        </div>
        <div className={styles.nameEmailContainer}>
          <div className={styles.relationshipManagerName}> {firstName}</div>
          <div className={styles.dashboardManagerBlockBottom}>
            {email && (
              <div className={styles.dashboardManagerBlockContactMeeting}>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href={`mailto:${email}`}
                  className={styles.contactLink}
                >
                  <MailIcon className={styles.contactIcon} />

                  <div>{email}</div>
                </a>
              </div>
            )}
            {phone && (
              <div className={styles.dashboardManagerBlockContactMeeting}>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href={`tel:${phone}`}
                  className={styles.contactLink}
                >
                  <CallIcon className={styles.phoneIcon} />

                  <div> {phone} </div>
                </a>
              </div>
            )}

            {calendlyURL && (
              <div className={styles.dashboardManagerBlockContactMeeting}>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href={calendlyURL}
                  className={styles.contactLink}
                >
                  <CalendarIcon className={styles.contactIcon} />

                  <div className={styles.scheduleMeeting}>Schedule</div>
                </a>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default DashboardManager
