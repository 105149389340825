import { combineReducers } from 'redux'
import * as actions from './actions'
import { ICreditorInfo, IDebtorInfo } from '@common/interfaces/entityInfo'

export interface IBDOState {
  loadingArray: string[]
  creditors: ICreditorInfo[]
  debtors: IDebtorInfo[]
}

export default combineReducers<IBDOState>({
  loadingArray(state: string[] = [], action) {
    switch (action.type) {
      case actions.ADD_DEBTOR_REQUEST:
      case actions.ADD_CREDITOR_REQUEST:
      case actions.LIST_CREDITORS_REQUEST:
      case actions.LIST_DEBTORS_REQUEST:
        return [...state, action.type]
      case actions.ADD_CREDITOR_SUCCESS:
      case actions.ADD_CREDITOR_FAILURE:
      case actions.ADD_DEBTOR_SUCCESS:
      case actions.ADD_DEBTOR_FAILURE:
      case actions.LIST_CREDITORS_SUCCESS:
      case actions.LIST_CREDITORS_FAILURE:
      case actions.LIST_DEBTORS_SUCCESS:
      case actions.LIST_DEBTORS_FAILURE:
        return state.filter(
          (item) =>
            item !== action.type.replace('SUCCESS', 'REQUEST').replace('FAILURE', 'REQUEST'),
        )
      default:
        return state
    }
  },
  creditors(state: ICreditorInfo[] = [], action) {
    switch (action.type) {
      case actions.LIST_CREDITORS_SUCCESS:
        return action.data
      default:
        return state
    }
  },
  debtors(state: IDebtorInfo[] = [], action) {
    switch (action.type) {
      case actions.LIST_DEBTORS_SUCCESS:
        return action.data
      default:
        return state
    }
  },
})
