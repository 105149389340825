import React, { useCallback, useState } from 'react'
import { useParams } from 'react-router'

import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'

import styles from './ReservesTable.module.scss'
import genericSs from '@styles/generic.module.scss'
import Modal from '../Common/Modal'

import TableCell from '../Common/TableCell'
import Button from '../../components/Common/Button'
import AdjustmentsModal from './AdjustmentsModal'
import { ReserveKind } from '@common/interfaces/bbc'
import { MenuIcon } from '../Common/Icons'

const ReserveActions = ({
  item,
  isHistorical,
  isClientPage,
  deleteBBCReserve,
  deleteClientReserve,
  updateBBCReserve,
  updateClientReserve,
  type,
  putNotification,
  refetchReservesList,
  isSaving = false,
}: {
  item: any
  isHistorical: boolean
  isClientPage?: boolean
  updateBBCReserve: (id: string, itemId: string, data: object) => Promise<void>
  updateClientReserve: (id: string, itemId: string, data: object) => Promise<void>
  deleteBBCReserve: (id: string, itemId: string) => Promise<void>
  deleteClientReserve: (id: string, itemId: string) => Promise<void>
  putNotification: (notification: object) => void
  refetchReservesList: (skipLoader?: boolean) => void
  type: any
  isSaving?: boolean
}) => {
  const { id } = useParams<{ id: string }>()
  const [isDeleteModalShown, setIsDeleteModalShown] = useState(false)
  const [isEditModalShown, setIsEditModalShown] = useState(false)

  const [anchorEl, setAnchorEl] = useState(null)
  const [menuOpen, setMenuOpen] = useState(false)

  const handleClickMenu = useCallback((event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
    setMenuOpen(true)
  }, [])

  const handleCloseMenu = useCallback(() => {
    setAnchorEl(null)
    setMenuOpen(false)
  }, [])

  const handleDeleteConfirm = useCallback(() => {
    const deleteReserve = isClientPage ? deleteClientReserve : deleteBBCReserve
    deleteReserve(id, item?.id).then(() => {
      refetchReservesList(true)
      setIsDeleteModalShown(false)
      handleCloseMenu()
    })
  }, [
    id,
    isClientPage,
    deleteBBCReserve,
    deleteClientReserve,
    item,
    setIsDeleteModalShown,
    refetchReservesList,
    handleCloseMenu,
  ])

  const handleDeleteCancel = useCallback(() => {
    setIsDeleteModalShown(false)
  }, [setIsDeleteModalShown])

  const handleUpdateReserve = useCallback(
    (values) => {
      const { kind, percent, field, amount, category, reason } = values
      if (
        (kind === ReserveKind.Absolute && !amount) ||
        (kind === ReserveKind.Percent && (!percent || !field)) ||
        !category ||
        !reason
      ) {
        putNotification({ code: 'FORMAT_ERROR', message: 'Please fill all data', type: 'error' })
        return
      }

      const updateReserve = isClientPage ? updateClientReserve : updateBBCReserve

      updateReserve(id, item?.id, {
        kind,
        amount: amount || 0,
        percent: kind === ReserveKind.Percent ? percent : 0,
        field: kind === ReserveKind.Percent ? field : null,
        type,
        category,
        reason,
      }).then(() => {
        refetchReservesList(true)
        setIsEditModalShown(false)
        handleCloseMenu()
      })
    },
    [
      id,
      isClientPage,
      updateBBCReserve,
      updateClientReserve,
      putNotification,
      type,
      setIsEditModalShown,
      item,
      refetchReservesList,
      handleCloseMenu,
    ],
  )

  return (
    <>
      <TableCell className={genericSs.tableTextRight}>
        <MenuIcon isActive={menuOpen} onClick={handleClickMenu} size="small" />
        <Menu
          classes={{ paper: styles.iconMenu }}
          open={menuOpen}
          onClose={handleCloseMenu}
          anchorEl={anchorEl}
        >
          {!isHistorical && (
            <MenuItem
              classes={{ root: styles.iconMenuItem }}
              onClick={() => {
                setIsEditModalShown(true)
              }}
            >
              Edit
            </MenuItem>
          )}

          {!isHistorical && (
            <MenuItem
              classes={{ root: styles.iconMenuItem }}
              onClick={() => {
                setIsDeleteModalShown(true)
              }}
            >
              Delete
            </MenuItem>
          )}
        </Menu>
      </TableCell>
      {isDeleteModalShown && (
        <Modal
          size="small"
          open={isDeleteModalShown}
          onCancel={handleDeleteCancel}
          title="Delete Reserve?"
          footer={[
            <Button key="cancel" color="primary" variant="contained" onClick={handleDeleteCancel}>
              Cancel
            </Button>,
            <Button
              isLoading={isSaving}
              key="submit"
              color="primary"
              variant="contained"
              onClick={handleDeleteConfirm}
            >
              Delete
            </Button>,
          ]}
        >
          Reserve will be deleted. Are you sure?
        </Modal>
      )}
      {isEditModalShown && (
        <AdjustmentsModal
          type={type}
          adjustmentType="edit"
          item={item}
          handleSubmit={handleUpdateReserve}
          isModalShowing={isEditModalShown}
          setIsModalShowing={setIsEditModalShown}
          isSaving={isSaving}
        />
      )}
    </>
  )
}

export default ReserveActions
