import Base from './base'
import { IUser } from '@common/interfaces/user'

export default class Auth extends Base {
  profile() {
    return this.apiClient.get('users/me')
  }

  login(email: string, password: string) {
    return this.apiClient.post('auth/login', { email, password })
  }

  confirm(hash: string) {
    return this.apiClient.post(`actions/${hash}`)
  }

  resetPassword(email: string) {
    return this.apiClient.post('users/reset-password', { email })
  }

  setPassword(hash: string, password: string, confirmPassword: string) {
    return this.apiClient.post(`actions/${hash}`, {
      data: { password, confirmPassword },
    })
  }

  updatePassword(password: string, newPassword: string) {
    return this.apiClient.post('users/update-password', {
      data: { password, newPassword },
    })
  }

  updateProfile(data: object | FormData) {
    return this.apiClient.put('users', data)
  }

  updateNotificationSettings(data: Partial<IUser>) {
    return this.apiClient.post('users/notification-settings', data)
  }

  processInvitation(hash: string) {
    return this.apiClient.post(`actions/${hash}`)
  }

  checkPasswordSetup(hash: string) {
    return this.apiClient.get(`auth/setup-password/${hash}`)
  }

  setupPassword(
    hash: string,
    {
      firstName,
      lastName,
      email,
      phone,
      password,
      confirmPassword,
    }: {
      firstName: string
      lastName: string
      email: string
      phone: string
      password: string
      confirmPassword: string
    },
  ) {
    return this.apiClient.post(`auth/setup-password/${hash}`, {
      firstName,
      lastName,
      email,
      phone,
      password,
      confirmPassword,
    })
  }

  async logout(token: string) {
    if (token) {
      return this.apiClient.post('auth/logout', { token })
    }
    return false
  }

  loginAsClient(clientId: string) {
    return this.apiClient.post('auth/login-as-client', { clientId })
  }

  logoutAsClient() {
    return this.apiClient.post('auth/logout-as-client')
  }
}
