import React, { useCallback, useMemo, useState, useEffect } from 'react'
import { useParams } from 'react-router'
import { generatePath } from 'react-router-dom'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import Skeleton from '@mui/material/Skeleton'
import cn from 'classnames'
import moment from 'moment'

import styles from './DueDiligencePageHeader.module.scss'

import KeyboardDatePicker from '../Common/KeyboardDatePicker'
import { ReactComponent as HomeIcon } from '@assets/images/home-icon.svg'
import { stringAvatar } from '../Notes/helpers/helpers'
import {
  DueDiligenceHeaderMenuPages,
  IDueDiligence,
  IDueDiligenceProgress,
} from '@common/interfaces/dueDiligence'
import TabsWithRoutesMenu from '../Common/TabsWithRoutesMenu'
import { ROUTES } from '../../constants/routes'
import Breadcrumbs from '../Common/Breadcrumbs'
import Button from '../Common/Button'
import Modal from '../Common/Modal'
import {
  DueDiligenceProgressStatus,
  DueDiligenceProgressStep,
  DUE_DILIGENCE_STEP_STATUS_OPTIONS,
} from '@common/interfaces/dueDiligence'
import { useSetPageTitle } from '../../hooks/useSetPageTitle'
import { ClientInfoStatus } from '@common/interfaces/client'
import { LOAN_TYPES } from '@common/constants/client'
import { formatPrice, formatPercent } from '../../helpers/helpers'
import SelectField from '../Common/SelectField'
import { useLoadInfo } from '../../hooks/useLoadInfo'
import { ReactComponent as EditIcon } from '@assets/images/pencil-icon.svg'
import { UploadFile } from '../Common/UploadFile'
import DueDiligenceHeaderMenu from '../DueDiligenceHeaderMenu'

interface IProps {
  dueDiligenceInfo: IDueDiligence
  show: (id: string) => void
  update: (id: string, data: object) => void
  isLoading: boolean
  updateClient: (id: string, data: FormData) => void
  dueDiligenceProgress: IDueDiligenceProgress
}

const TABS = {
  Application: [
    ROUTES.DUE_DILIGENCE,
    ROUTES.DUE_DILIGENCE_TEAM_PAGE,
    ROUTES.DUE_DILIGENCE_CLIENT_FINANCIALS_PAGE,
  ],
  'Validate documents': ROUTES.DUE_DILIGENCE_VALIDATE_DOCUMENTS_PAGE,
  'Analysis queue': ROUTES.DUE_DILIGENCE_ANALYSIS_QUEUE,
}

const MENU = {
  Application: [
    {
      label: 'Company Background',
      route: ROUTES.DUE_DILIGENCE,
    },
    {
      label: 'The team',
      route: ROUTES.DUE_DILIGENCE_TEAM_PAGE,
    },
    {
      label: 'Financials & collateral',
      route: ROUTES.DUE_DILIGENCE_CLIENT_FINANCIALS_PAGE,
    },
  ],
}

const HeaderLoader = ({ isLoading, value }: { isLoading: boolean; value: string }) => {
  return isLoading ? <Skeleton width={120} /> : <>{value || '-'}</>
}

const DueDiligenceProgressStepItem = ({
  step,
  status,
  onChange,
  readOnly,
  isLoading,
}: {
  step: DueDiligenceProgressStep
  status: DueDiligenceProgressStatus
  onChange: (step: DueDiligenceProgressStep, status: DueDiligenceProgressStatus) => void
  readOnly: boolean
  isLoading: boolean
}) => {
  const handleChange = useCallback(
    ({ target: { value } }) => {
      if (readOnly) {
        return
      }
      onChange(step, value as DueDiligenceProgressStatus)
    },
    [onChange, step, readOnly],
  )

  return (
    <>
      <div className={styles.headerTitle}>{step}</div>
      <div className={styles.headerValue}>
        {isLoading ? (
          <Skeleton width={100} height={20} />
        ) : (
          <SelectField
            className={cn(styles.progressSelect, {
              [styles.notStarted]: [
                DueDiligenceProgressStatus.NotStarted,
                DueDiligenceProgressStatus.NotApplicable,
              ].includes(status),
              [styles.inProgress]: status === DueDiligenceProgressStatus.InProgress,
              [styles.complete]: status === DueDiligenceProgressStatus.Completed,
            })}
            name="status"
            useFinalForm={false}
            options={DUE_DILIGENCE_STEP_STATUS_OPTIONS}
            onChange={handleChange}
            value={status}
            disabled={readOnly}
          />
        )}
      </div>
    </>
  )
}

const DueDiligencePageHeader = ({
  dueDiligenceInfo,
  show,
  update,
  isLoading,
  updateClient,
  dueDiligenceProgress,
}: IProps) => {
  const { id } = useParams<{ id: string }>()
  const [isEditProfilePicModalOpen, setIsEditProfilePicModalOpen] = useState(false)
  const [iconUrl, setIconUrl] = useState<string | null>(null)
  const [loadedFile, setLoadedFile] = useState<File[]>([])
  const [isImageManageModalOpen, setIsImageManageModalOpen] = useState(false)
  const [savedProfilePic, setSavedProfilePic] = useState<string | null>(null)

  useSetPageTitle(dueDiligenceInfo?.clientName || '')

  const [isSaving, setIsSaving] = useState(false)

  const initials = useMemo(() => {
    const name = dueDiligenceInfo?.clientName || ''
    return name.split(' ').length > 1 ? `${name.split(' ')[0][0]}${name.split(' ')[1][0]}` : name[0]
  }, [dueDiligenceInfo])

  useLoadInfo({ id, info: dueDiligenceInfo, show })

  const readOnly = useMemo(
    () => dueDiligenceInfo?.clientStatus !== ClientInfoStatus.DueDiligence,
    [dueDiligenceInfo],
  )

  const handleCloseDateChange = useCallback(
    (value) => {
      if (readOnly) {
        return
      }
      if (!isNaN(value?.getTime()) || value === null) {
        update(id, {
          closeDate: moment(value).format('YYYY-MM-DD'),
        })
      }
    },
    [update, id, readOnly],
  )

  const handleProgressStatusChange = useCallback(
    (step: DueDiligenceProgressStep, status: DueDiligenceProgressStatus) => {
      if (readOnly) {
        return
      }
      update(id, {
        progress: {
          ...dueDiligenceInfo?.ddInfo.progress,
          [step]: status,
        },
      })
    },
    [dueDiligenceInfo, update, id, readOnly],
  )

  const breadcrumbs = useMemo(() => {
    return [
      {
        link: ROUTES.DUE_DILIGENCE_HOME,
        Icon: HomeIcon,
      },
      {
        link: generatePath(ROUTES.DUE_DILIGENCE, { id }),
        title: dueDiligenceInfo?.clientName,
      },
    ]
  }, [dueDiligenceInfo, id])

  useEffect(() => {
    setIconUrl(savedProfilePic || dueDiligenceInfo?.iconUrl)
  }, [savedProfilePic, dueDiligenceInfo?.iconUrl])

  const openEditProfilePicModal = useCallback(() => {
    setIsEditProfilePicModalOpen(true)
  }, [])

  const closeEditProfilePicModal = useCallback(() => {
    setIsEditProfilePicModalOpen(false)
    setLoadedFile([])
    setIconUrl(savedProfilePic || dueDiligenceInfo?.iconUrl)
  }, [savedProfilePic, dueDiligenceInfo?.iconUrl])

  const handleSetIconUrl = useCallback((image) => {
    setIconUrl(image)
  }, [])

  const handleUpdateClientInfo = useCallback(
    async (file: any) => {
      if (id) {
        const formData = new FormData()
        formData.append('files[profilePicture]', file[0], file[0].name)
        await updateClient(id, formData)
        await show(id)
      }
    },
    [id, updateClient, show],
  )

  const handleUploadFile = useCallback(
    async (loadedFiles: File[]) => {
      setLoadedFile(loadedFiles)
      const [image] = loadedFiles

      if (image) {
        const reader = new FileReader()
        reader.onload = () => {
          handleSetIconUrl(reader.result as string)
        }
        reader.readAsDataURL(image)
      }
    },
    [handleSetIconUrl],
  )

  const handleSaveProfilePic = useCallback(async () => {
    setIsSaving(true)
    await handleUpdateClientInfo(loadedFile)
    setSavedProfilePic(iconUrl)
    setIconUrl(iconUrl)
    setIsEditProfilePicModalOpen(false)
    setLoadedFile([])
    setIsSaving(false)
  }, [handleUpdateClientInfo, loadedFile, iconUrl, setLoadedFile, setIsEditProfilePicModalOpen])

  const handleDeleteFile = useCallback(async () => {
    setLoadedFile([])
    handleSetIconUrl(null)
  }, [handleSetIconUrl])

  const handleToggleImageManageModal = useCallback(() => {
    setIsImageManageModalOpen((isOpen) => !isOpen)
  }, [])

  return (
    <Grid data-cy="dd-page-header" container py={3} pr={2} sx={{ width: '100%' }}>
      <Grid item container xs={12} justifyContent="space-between">
        <Grid item container xs={6} justifyContent="flex-start" alignItems="center">
          <Breadcrumbs breadcrumbs={breadcrumbs} isLoading={isLoading} />
        </Grid>
        <Grid container item xs={6} justifyContent="flex-end" gap={1.5}>
          <DueDiligenceHeaderMenu page={DueDiligenceHeaderMenuPages.DiligenceProfile} />
        </Grid>
      </Grid>
      <div className={styles.headerContainer}>
        <div className={styles.avatarGridContainer}>
          {isLoading ? (
            <Skeleton width={140} height={140} />
          ) : (
            dueDiligenceInfo && (
              <div className={styles.avatar} {...stringAvatar(dueDiligenceInfo?.clientName)}>
                {savedProfilePic || dueDiligenceInfo?.iconUrl ? (
                  <div className={styles.avatarContainer}>
                    <img
                      className={styles.avatarImg}
                      src={savedProfilePic || dueDiligenceInfo?.iconUrl}
                      alt="logo"
                    />
                  </div>
                ) : (
                  <div className={styles.avatarText}>{initials}</div>
                )}
                <EditIcon className={styles.editIcon} onClick={openEditProfilePicModal} />
              </div>
            )
          )}
        </div>
        <div className={styles.contentContainer}>
          <Grid container spacing={1}>
            <Grid container item xs={12} spacing={0}>
              <Grid item xs={12 / 7}>
                <div className={styles.headerTitle}>Risk rating</div>
                <div className={styles.headerValue}>
                  <HeaderLoader
                    isLoading={isLoading}
                    value={
                      dueDiligenceInfo?.riskRating ? dueDiligenceInfo?.riskRating.toFixed(2) : '-'
                    }
                  />
                </div>
              </Grid>
              <Grid item xs={12 / 7}>
                <div className={styles.headerTitle}>Max line amount</div>
                <div className={styles.headerValue}>
                  <HeaderLoader
                    isLoading={isLoading}
                    value={
                      dueDiligenceInfo?.maxLineAmount
                        ? `$ ${formatPrice(dueDiligenceInfo?.maxLineAmount)}`
                        : '-'
                    }
                  />
                </div>
              </Grid>
              {dueDiligenceInfo?.loanType !== LOAN_TYPES.inventory && (
                <Grid item xs={12 / 7}>
                  <div className={styles.headerTitle}>AR availability</div>
                  <div className={styles.headerValue}>
                    <HeaderLoader
                      isLoading={isLoading}
                      value={
                        dueDiligenceInfo?.availableAR
                          ? ` $ ${formatPrice(dueDiligenceInfo?.availableAR)}`
                          : '-'
                      }
                    />
                  </div>
                </Grid>
              )}
              {dueDiligenceInfo?.loanType !== LOAN_TYPES.ar && (
                <Grid item xs={12 / 7}>
                  <div className={styles.headerTitle}>Inv. availability</div>
                  <div className={styles.headerValue}>
                    <HeaderLoader
                      isLoading={isLoading}
                      value={
                        dueDiligenceInfo?.availableInventory
                          ? ` $ ${formatPrice(dueDiligenceInfo?.availableInventory)}`
                          : '-'
                      }
                    />
                  </div>
                </Grid>
              )}
              {dueDiligenceInfo?.loanType !== LOAN_TYPES.inventory && (
                <Grid item xs={12 / 7}>
                  <div className={styles.headerTitle}>AR advanced rate</div>
                  <div className={styles.headerValue}>
                    <HeaderLoader
                      isLoading={isLoading}
                      value={formatPercent(dueDiligenceInfo?.arAdvance, 2, 100)}
                    />
                  </div>
                </Grid>
              )}
              {dueDiligenceInfo?.loanType !== LOAN_TYPES.ar && (
                <Grid item xs={12 / 7}>
                  <div className={styles.headerTitle}>Inv. advanced rate</div>
                  <div className={styles.headerValue}>
                    <HeaderLoader
                      isLoading={isLoading}
                      value={formatPercent(dueDiligenceInfo?.inventoryAdvanceRateCost, 2, 100)}
                    />
                  </div>
                </Grid>
              )}

              <Grid item xs={12 / 7}>
                <div className={styles.headerTitle}>Target funding date</div>
                <div className={styles.headerValue}>
                  <KeyboardDatePicker
                    useFinalForm={false}
                    name="closeDate"
                    value={dueDiligenceInfo?.salesforceDDInfo?.closeDate}
                    onChange={handleCloseDateChange}
                    disabled={readOnly}
                  />
                </div>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <div className={styles.infoDivider}></div>
            </Grid>
            <Grid container item>
              {Object.entries(dueDiligenceProgress || {}).map(([step, status]) => (
                <Grid item xs={12 / 7} key={step}>
                  <DueDiligenceProgressStepItem
                    step={step as DueDiligenceProgressStep}
                    status={status as DueDiligenceProgressStatus}
                    onChange={handleProgressStatusChange}
                    readOnly={readOnly}
                    isLoading={isLoading}
                  />
                </Grid>
              ))}
            </Grid>
          </Grid>
        </div>
      </div>
      <Grid item xs={12} className={styles.tabsContainer}>
        <TabsWithRoutesMenu tabs={TABS} menu={MENU} isQueryString={false} />
      </Grid>

      {isEditProfilePicModalOpen && (
        <Modal
          open={isEditProfilePicModalOpen}
          onCancel={closeEditProfilePicModal}
          classes={{ root: styles.modalRoot, title: styles.modalTitle, footer: styles.modalFooter }}
          title="Upload Image"
          size="small"
          footer={[
            <Button
              color="primary"
              variant="contained"
              small={false}
              onClick={handleSaveProfilePic}
              isLoading={isSaving}
              key="submit"
              className={styles.modalFooter}
              disabled={!loadedFile.length}
            >
              Update
            </Button>,
          ]}
        >
          {iconUrl && (
            <Box className={styles.avatarWrapper}>
              <img className={styles.avatarImg} src={iconUrl} alt="logo" />
            </Box>
          )}
          <Box className={styles.imageUploadWrapper}>
            <UploadFile
              title="Profile Picture"
              size="lg"
              onDropAccepted={handleUploadFile}
              files={loadedFile}
              onDelete={handleDeleteFile}
              acceptedFileTypes={['image']}
              maxFiles={1}
              isModalOpen={isImageManageModalOpen}
              handleToggleModal={handleToggleImageManageModal}
            />
          </Box>
        </Modal>
      )}
    </Grid>
  )
}

export default DueDiligencePageHeader
