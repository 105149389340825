import Base from './base'

export default class OngoingReporting extends Base {
  list(data: object) {
    return this.apiClient.get('ongoing-reporting', data)
  }

  show(id: string) {
    return this.apiClient.get(`ongoing-reporting/${id}`)
  }

  update(id: string, data: object | FormData) {
    return this.apiClient.put(`ongoing-reporting/${id}`, data)
  }

  submit(id: string, data: object) {
    return this.apiClient.post(`ongoing-reporting/${id}`, data)
  }

  delete(id: string) {
    return this.apiClient.delete(`ongoing-reporting/${id}`)
  }

  listConsolidated(id: string, data: object) {
    return this.apiClient.get(`ongoing-reporting/${id}/consolidated`, data)
  }

  calculateOngoingReportingFlags(id: string) {
    return this.apiClient.get(`ongoing-reporting/${id}/flags-calculate`)
  }

  updateConsolidated(id: string, data: object) {
    return this.apiClient.put(`ongoing-reporting/${id}/consolidated`, data)
  }

  listSummary(id: string, data: object) {
    return this.apiClient.get(`ongoing-reporting/${id}/summary`, data)
  }

  listInventoryMapping(id: string, data: object) {
    return this.apiClient.get(`ongoing-reporting/${id}/inventory-mapping`, data)
  }

  listSalesBySkuVisualization(id: string, data: object) {
    return this.apiClient.get(`ongoing-reporting/${id}/sales-by-sku-visualization`, data)
  }

  listSalesBySkuFilters(id: string, data: object) {
    return this.apiClient.get(`ongoing-reporting/${id}/sales-by-sku-filters`, data)
  }

  listSalesByDistributionGraph(id: string, data: object) {
    return this.apiClient.get(`ongoing-reporting/${id}/sales-by-distribution-graph`, data)
  }

  updateInventoryMapping(id: string, itemId: string, data: object) {
    return this.apiClient.put(`ongoing-reporting/${id}/inventory-mapping/${itemId}`, data)
  }

  updateReportingStatus(data: object) {
    return this.apiClient.put('ongoing-reporting', data)
  }
}
