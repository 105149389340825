import { IRootState } from '../reducer'
import { prefix } from './actions'

export const participants = (state: IRootState) => state[prefix].participants

export const clientParticipations = (state: IRootState) => state[prefix].clientParticipations

export const allParticipations = (state: IRootState) => state[prefix].allParticipations

export const outstandingParticipationWires = (state: IRootState) =>
  state[prefix].outstandingParticipationWires

export const refreshCount = (state: IRootState) => state[prefix].refreshCount

export const participantInfo = (state: IRootState) => state[prefix].participantInfo

export const participantParticipations = (state: IRootState) =>
  state[prefix].participantParticipations
