import React, { useEffect, useCallback, useState, useMemo } from 'react'
import CollectionsWiresTable from './CollectionsWiresTable'
import styles from './CollectionsWiresMapping.module.scss'
import Modal from '../Common/Modal'
import { IWireData, IWireDataAggregation, WireDataStatus } from '@common/interfaces/collection'
import Grid from '@mui/material/Grid'
import Card from '../Common/Card'
import Button from '../Common/Button'
import { ClientInfoStatus, IClientInfo } from '@common/interfaces/client'
import { IEntityInfo } from '@common/interfaces/entityInfo'
import WarningModal from '../WarningModal'
import { ILoadingData } from '../../redux/types'

interface IProps {
  wiresData: ILoadingData<IWireDataAggregation>
  wiresDataNegative: ILoadingData<IWireDataAggregation>
  wiresDataDeleted: ILoadingData<IWireDataAggregation>
  handleListWires: (params: object, type?: WireDataStatus) => void
  updateWire: (id: string, data: object) => void
  updateWires: (data: object) => void
  restoreWire: (id: string, data?: object) => void
  deleteWire: (id: string, data: object) => void
  clients: ILoadingData<{ data: IClientInfo[] }>
  listEntityInfo: (data: object) => Promise<{ data: IEntityInfo[] }>
  addEntityInfo: (data: object) => void
  listClients: (params: object) => void
}

const CollectionsWiresMapping = ({
  wiresData,
  wiresDataNegative,
  wiresDataDeleted,
  updateWire,
  updateWires,
  restoreWire,
  deleteWire,
  clients,
  listEntityInfo,
  addEntityInfo,
  handleListWires,
  listClients,
}: IProps) => {
  const [isConfirmModalShown, setIsConfirmModalShown] = useState(false)
  const [isDeleteModalShown, setIsDeleteModalShown] = useState(false)
  const [isPermanent, setIsPermanent] = useState(false)
  const [selectedWire, setSelectedWire] = useState(null)
  const [isSavingButton, setIsSavingButton] = useState(false)

  const { isWiresLoading, wiresDataLoaded, isWiresSaving, isWiresSaved } = useMemo(
    () => ({
      isWiresLoading: wiresData.isLoading,
      wiresDataLoaded: wiresData?.data,
      isWiresSaving: wiresData?.isSaving,
      isWiresSaved: wiresData?.isSaved,
    }),
    [wiresData],
  )

  const {
    isNegativeWiresLoading,
    wiresDataNegativeLoaded,
    isNegativeWiresSaving,
    isNegativeWiresSaved,
  } = useMemo(
    () => ({
      isNegativeWiresLoading: wiresDataNegative.isLoading,
      wiresDataNegativeLoaded: wiresDataNegative?.data,
      isNegativeWiresSaving: wiresDataNegative?.isSaving,
      isNegativeWiresSaved: wiresDataNegative?.isSaved,
    }),
    [wiresDataNegative],
  )

  const {
    isDeletedWiresLoading,
    wiresDataDeletedLoaded,
    isDeletedWiresSaving,
    isDeletedWiresSaved,
  } = useMemo(
    () => ({
      isDeletedWiresLoading: wiresDataDeleted.isLoading,
      wiresDataDeletedLoaded: wiresDataDeleted?.data,
      isDeletedWiresSaving: wiresDataDeleted?.isSaving,
      isDeletedWiresSaved: wiresDataDeleted?.isSaved,
    }),
    [wiresDataDeleted],
  )

  useEffect(() => {
    wiresDataLoaded &&
      listClients({
        statuses: [ClientInfoStatus.Past, ClientInfoStatus.Current],
        withBankAccounts: true,
      })
  }, [listClients, wiresDataLoaded])

  const mappingProgress = useMemo(
    () =>
      ((wiresDataLoaded?.mappedCount + wiresDataNegativeLoaded?.mappedCount) * 100) /
      (wiresDataLoaded?.totalCount + wiresDataNegativeLoaded?.totalCount),
    [wiresDataLoaded, wiresDataNegativeLoaded],
  )

  const handleAddDebtor = useCallback(
    async (debtor: string, clientName: string) =>
      addEntityInfo({ name: debtor, type: 'debtor', clientName: clientName }),
    [addEntityInfo],
  )

  const handleArchive = useCallback((wire: IWireData) => {
    setIsPermanent(false)
    setIsDeleteModalShown(true)
    setSelectedWire(wire)
  }, [])

  const handleDelete = useCallback((wire: IWireData) => {
    setIsPermanent(true)
    setIsDeleteModalShown(true)
    setSelectedWire(wire)
  }, [])

  const handleRestore = useCallback(
    (wire: IWireData) => {
      restoreWire(wire.id, { skipLoader: true })
    },
    [restoreWire],
  )

  const handleMarkAsNegative = useCallback(
    (wire: IWireData) => {
      updateWires({
        ids: [wire.id],
        status: WireDataStatus.Negative,
        skipLoader: true,
      })
    },
    [updateWires],
  )
  const handleCancelUpdate = useCallback(() => {
    setIsConfirmModalShown(false)
    setSelectedWire(null)
  }, [])

  const handleConfirmUpdate = useCallback(() => {
    const { id, client } = selectedWire
    updateWire(id, { clientName: client })
    setIsConfirmModalShown(false)
    setSelectedWire(null)
  }, [selectedWire, updateWire])

  const handleDeleteConfirm = useCallback(async () => {
    setIsSavingButton(true)
    await deleteWire(selectedWire.id, { isPermanent })
    setIsDeleteModalShown(false)
    setSelectedWire(null)
    setIsSavingButton(false)
  }, [selectedWire, deleteWire, isPermanent])

  const handleDeleteCancel = useCallback(() => {
    setIsDeleteModalShown(false)
    setSelectedWire(null)
  }, [])

  return (
    <Grid item xs={12}>
      <Card noHeaderMargin className={styles.cardContainer}>
        <CollectionsWiresTable
          type={WireDataStatus.New}
          clients={clients}
          wiresData={wiresDataLoaded}
          isTableLoading={isWiresLoading}
          addEntityInfo={handleAddDebtor}
          updateWire={updateWire}
          updateWires={updateWires}
          listWires={handleListWires}
          archiveWire={handleArchive}
          deleteWire={handleDelete}
          restoreWire={handleRestore}
          markNegativeWire={handleMarkAsNegative}
          listDebtors={listEntityInfo}
          mappingProgress={mappingProgress}
          isTableSaving={isWiresSaving}
          isTableSaved={isWiresSaved}
        />
      </Card>
      <Card noHeaderMargin className={styles.cardContainer}>
        <CollectionsWiresTable
          type={WireDataStatus.Negative}
          clients={clients}
          wiresData={wiresDataNegativeLoaded}
          isTableLoading={isNegativeWiresLoading}
          addEntityInfo={handleAddDebtor}
          updateWire={updateWire}
          updateWires={updateWires}
          listWires={handleListWires}
          archiveWire={handleArchive}
          deleteWire={handleDelete}
          restoreWire={handleRestore}
          markNegativeWire={handleMarkAsNegative}
          listDebtors={listEntityInfo}
          isTableSaving={isNegativeWiresSaving}
          isTableSaved={isNegativeWiresSaved}
        />
      </Card>
      <Card noHeaderMargin className={styles.cardContainer}>
        <CollectionsWiresTable
          type={WireDataStatus.Deleted}
          clients={clients}
          wiresData={wiresDataDeletedLoaded}
          isTableLoading={isDeletedWiresLoading}
          addEntityInfo={handleAddDebtor}
          updateWire={updateWire}
          updateWires={updateWires}
          listWires={handleListWires}
          archiveWire={handleArchive}
          deleteWire={handleDelete}
          restoreWire={handleRestore}
          markNegativeWire={handleMarkAsNegative}
          listDebtors={listEntityInfo}
          isTableSaving={isDeletedWiresSaving}
          isTableSaved={isDeletedWiresSaved}
        />
      </Card>
      {isConfirmModalShown && selectedWire && (
        <Modal
          open={isConfirmModalShown}
          onCancel={() => setIsConfirmModalShown(false)}
          title="Save changes?"
          footer={[
            <Button
              key="cancel"
              color="primary"
              variant="outlined"
              onClick={handleCancelUpdate}
              secondary
            >
              Cancel
            </Button>,
            <Button key="submit" color="primary" variant="contained" onClick={handleConfirmUpdate}>
              Save
            </Button>,
          ]}
        >
          This action will map and lock the Account Number to this Client. The Account Number can
          only be changed on the Client Setup page
        </Modal>
      )}

      {isDeleteModalShown && selectedWire && (
        <WarningModal
          warningMessage={
            'Wires will be ' +
            (isPermanent ? 'deleted permanently' : 'marked as non client related cash') +
            '. Are you sure?'
          }
          onConfirm={handleDeleteConfirm}
          onCancel={handleDeleteCancel}
          confirmText="Confirm"
          cancelText="Cancel"
          isLoading={isSavingButton}
        />
      )}
    </Grid>
  )
}

export default CollectionsWiresMapping
