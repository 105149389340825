import React, { useMemo } from 'react'
import { useHistory, matchPath } from 'react-router'

import styles from './OPSTabs.module.scss'

import { ROUTES } from '../../constants/routes'
import TabsWithRoutesMenu from '../Common/TabsWithRoutesMenu'
import { useSetPageTitle } from '../../hooks/useSetPageTitle'
import { IOPSReportingFlow, IProspect, OPSReportingFlowStatus } from '@common/interfaces/prospects'
import { OngoingReportingType } from '@common/interfaces/bbc'
import { ClientInfoStatus } from '@common/interfaces/client'

const TABS_ROUTES = {
  Receivables: ROUTES.PROSPECT_REPORTING_RECEIVABLES,
  Payables: ROUTES.PROSPECT_REPORTING_PAYABLES,
  Inventory: ROUTES.PROSPECT_REPORTING_INVENTORY,
  Financials: [ROUTES.PROSPECT_REPORTING_BALANCE_SHEET, ROUTES.PROSPECT_REPORTING_INCOME_STATEMENT],
  Projections: [
    ROUTES.PROSPECT_REPORTING_BALANCE_SHEET_PROJECTIONS,
    ROUTES.PROSPECT_REPORTING_INCOME_STATEMENT_PROJECTIONS,
  ],
}

const MENU = {
  Financials: [
    {
      label: 'Income Statement',
      route: ROUTES.PROSPECT_REPORTING_INCOME_STATEMENT,
    },
    {
      label: 'Balance Sheet',
      route: ROUTES.PROSPECT_REPORTING_BALANCE_SHEET,
    },
  ],
  Projections: [
    {
      label: 'Income Statement',
      route: ROUTES.PROSPECT_REPORTING_INCOME_STATEMENT_PROJECTIONS,
    },
    {
      label: 'Balance Sheet',
      route: ROUTES.PROSPECT_REPORTING_BALANCE_SHEET_PROJECTIONS,
    },
  ],
}
const OPSTabs = ({
  prospectInfo,
  opsReportingFlows,
}: {
  prospectInfo: IProspect
  opsReportingFlows: IOPSReportingFlow[]
}) => {
  const history = useHistory()

  useSetPageTitle(
    matchPath(history.location.pathname, {
      path: ROUTES.PROSPECT_SUMMARY_PAGE,
      exact: true,
    })
      ? 'OPS'
      : 'Process Documents',
  )

  const actionsRequired = useMemo(() => {
    if (
      ![ClientInfoStatus.Prospect, ClientInfoStatus.Archived].includes(prospectInfo?.clientStatus)
    ) {
      return null
    }

    const opsReportingFlowsActionRequired = opsReportingFlows?.reduce(
      (result, { type, status }) => ({
        ...result,
        [type]:
          status !== OPSReportingFlowStatus.Complete && status !== OPSReportingFlowStatus.Waived,
      }),
      {},
    )

    return {
      Receivables: opsReportingFlowsActionRequired[OngoingReportingType.AR],
      Payables: opsReportingFlowsActionRequired[OngoingReportingType.AP],
      Inventory: opsReportingFlowsActionRequired[OngoingReportingType.Inventory],
      Financials: [
        opsReportingFlowsActionRequired[OngoingReportingType.IncomeStatement],
        opsReportingFlowsActionRequired[OngoingReportingType.BalanceSheet],
      ],
      Projections: [
        opsReportingFlowsActionRequired[OngoingReportingType.IncomeStatementProjections],
        opsReportingFlowsActionRequired[OngoingReportingType.BalanceSheetProjections],
      ],
    }
  }, [opsReportingFlows, prospectInfo])

  return (
    <div className={styles.tabs}>
      <TabsWithRoutesMenu
        tabs={TABS_ROUTES}
        menu={MENU}
        actionsRequired={actionsRequired}
        isQueryString={false}
      />
    </div>
  )
}

export default OPSTabs
