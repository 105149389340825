import React from 'react'
import LinearProgress from '@mui/material/LinearProgress'
import cn from 'classnames'

import styles from './Loader.module.scss'

const Loader = ({ top = null, isSidebarOpen }: { top?: number; isSidebarOpen: boolean }) => {
  const topStyle = top === null ? {} : { top }

  return (
    <div className={styles.loaderWrapper}>
      <LinearProgress
        style={topStyle}
        className={cn({ [styles.reduced]: isSidebarOpen })}
        classes={{ root: styles.loader }}
      />
    </div>
  )
}

export default Loader
