import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'

import ParticipantsSummaryDetailTable from './ParticipantsSummaryDetailTable'
import { listAllParticipations } from '../../redux/participant/actions'
import { allParticipations } from '../../redux/participant/selectors'

const selector = createStructuredSelector({
  participations: allParticipations,
})
const actions = {
  listAllParticipations,
}

export { ParticipantsSummaryDetailTable }

export default connect(selector, actions)(ParticipantsSummaryDetailTable)
