import { IField } from '../interfaces/client'
import { LOAN_TYPES } from './client'

export const LINE_UTILIZATION_FIELDS_CONFIG: IField[] = [
  {
    label: 'Max line amount',
    name: 'maxLineAmount',
    type: 'currency',
  },
  {
    label: 'Minimum',
    name: 'minimumLineAmount',
    type: 'currency',
  },
  {
    label: 'Unused line fee',
    name: 'unusedLineFee',
    type: 'percent',
  },
]
export const INTEREST_RATE_FIELDS_CONFIG: IField[] = [
  {
    label: 'Rate',
    name: 'rate',
    type: 'percent',
    disabled: true,
  },
  {
    label: 'Floor',
    name: 'interestRateBase',
    type: 'percent',
  },
  {
    label: 'Margin above prime',
    name: 'interestRateAbovePrime',
    type: 'percent',
  },
  {
    label: 'Min interest waived until',
    name: 'minimumInterestEffectiveDate',
    type: 'date',
  },
]

export const COLLATERAL_FIELDS_CONFIG: IField[] = [
  {
    label: 'Deal type',
    name: 'loanType',
    type: 'select',
    options: Object.values(LOAN_TYPES).map((value) => ({
      value,
      label: value,
    })),
  },
  {
    label: 'AR advance rate',
    name: 'arAdvance',
    type: 'percent',
  },
  {
    label: 'Inv. advance rate (cost)',
    name: 'inventoryAdvanceRateCost',
    type: 'percent',
  },
  {
    label: 'Inv. advance rate (NOLV)',
    name: 'inventoryAdvanceRateNolv',
    type: 'percent',
  },
  {
    label: 'Sublimit',
    name: 'sublimit',
    type: 'percent',
  },
]

export const FEE_FIELDS_CONFIG: IField[] = [
  {
    label: 'Facility fee',
    name: 'facilityFee',
    type: 'percent',
  },
  {
    label: 'Termination fee (year 1)',
    name: 'terminationYear1',
    type: 'percent',
  },
  {
    label: 'Termination fee (ongoing)',
    name: 'terminationYear2',
    type: 'percent',
  },
]

const GUARENTEES = ['Corporate', 'Validity & Support']
const COVENANT_TYPES = ['Liquidity', 'Inventory']

export const COVENANTS_FIELDS_CONFIG: IField[] = [
  {
    label: 'Guarantees',
    name: 'guarentees',
    type: 'select',
    options: GUARENTEES.map((value) => ({
      value,
      label: value,
    })),
  },
  {
    label: 'Subordination language',
    name: 'subordination',
    type: 'select',
    options: [
      {
        value: 'true',
        label: 'Yes',
      },
      {
        value: 'false',
        label: 'No',
      },
    ],
  },
  {
    label: 'Covenant type',
    name: 'covenantType',
    type: 'select',
    options: COVENANT_TYPES.map((value) => ({
      value,
      label: value,
    })),
  },
  {
    label: 'Qualified equity round',
    name: 'qualifiedEquityRound',
    type: 'currency',
  },
]
export const OTHER_FIELDS_CONFIG: IField[] = [
  {
    label: 'Effective date',
    name: 'recordDate',
    type: 'date',
  },
  {
    label: 'Expiration date',
    name: 'expirationDate',
    type: 'date',
  },
  {
    label: 'Deposits',
    name: 'deposits',
    type: 'currency',
  },
  {
    label: 'Comments for committee',
    name: 'commentsForCommittee',
    type: 'text',
  },
]

export const All_MANAGE_TERMS_FIELDS_CONFIG = [
  ...LINE_UTILIZATION_FIELDS_CONFIG,
  ...INTEREST_RATE_FIELDS_CONFIG,
  ...COLLATERAL_FIELDS_CONFIG,
  ...FEE_FIELDS_CONFIG,
  ...COVENANTS_FIELDS_CONFIG,
  ...OTHER_FIELDS_CONFIG,
]

export enum PROSPECT_TERMS_STATUSES {
  submission = 'submission',
  approved = 'approved',
  proposed = 'proposed',
  signed = 'signed',
}

export const isEntityRelationshipInProgress = (entityRelationship: any) => {
  if (!entityRelationship) {
    return false
  }
  // Check salesforce relationship (salesforceId or newSalesforceAccount)
  if (entityRelationship.salesforceAccountId || entityRelationship.newSalesforceAccount) {
    return true
  }
  // Check is any of fields completed for relationship contact
  if (entityRelationship.fullName || entityRelationship.phone || entityRelationship.email) {
    return true
  }
  return false
}

export const isEntityRelationshipCompleted = (entityRelationship: any) => {
  if (!entityRelationship) {
    return false
  }
  // Check salesforce relationship (salesforceId or newSalesforceAccount)
  if (!entityRelationship.salesforceAccountId && !entityRelationship.newSalesforceAccount) {
    return false
  }
  // Check is all fields completed for relationship contact
  if (!entityRelationship.fullName || !entityRelationship.phone || !entityRelationship.email) {
    return false
  }
  return true
}
