import { prefix } from './actions'
import { IRootState } from '../reducer'

export const isLoading = (state: IRootState) => state[prefix].loadingArray.length > 0

export const bbc = (state: IRootState) => state[prefix].bbc

export const inventoryIneligibilityData = (state: IRootState) =>
  state[prefix].inventoryIneligibilityData

export const fields = (state: IRootState) => state[prefix].fields

export const clientCollaterals = (state: IRootState) => state[prefix].clientCollaterals

export const previousClientCollaterals = (state: IRootState) =>
  state[prefix].previousClientCollaterals

export const arapChartData = (state: IRootState) => state[prefix].arapChartData

export const arapChartDataDropdownOptions = (state: IRootState) =>
  state[prefix].arapChartDataDropdownOptions

export const arRollforward = (state: IRootState) => state[prefix].arRollforward

export const arRollforwardGraph = (state: IRootState) => state[prefix].arRollforwardGraph

export const apRollforward = (state: IRootState) => state[prefix].apRollforward

export const reserves = (state: IRootState) => state[prefix].reserves

export const customRules = (state: IRootState) => state[prefix].customRules

export const customRulesLabels = (state: IRootState) => state[prefix].customRulesLabels

export const customRulesValues = (state: IRootState) => state[prefix].customRulesValues

export const inventoryIneligibleStats = (state: IRootState) =>
  state[prefix].inventoryIneligibleStats

export const arIneligibleStats = (state: IRootState) => state[prefix].arIneligibleStats

export const arSummaryStats = (state: IRootState) => state[prefix].arSummaryStats

export const apSummaryStats = (state: IRootState) => state[prefix].apSummaryStats

export const apReserves = (state: IRootState) => state[prefix].apReserves

export const arIneligibilityData = (state: IRootState) => state[prefix].arIneligibilityData

export const inventoryDistributionData = (state: IRootState) =>
  state[prefix].inventoryDistributionData

export const inventoryDistributionCategories = (state: IRootState) =>
  state[prefix].inventoryDistributionCategories
