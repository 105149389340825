import React, { useState, useCallback } from 'react'
import { Form } from 'react-final-form'
import { makeValidate } from 'mui-rff'
import * as Yup from 'yup'
import Box from '@mui/material/Box'
import InputAdornment from '@mui/material/InputAdornment'
import IconButton from '@mui/material/IconButton'

import Visibility from '../../assets/images/text-visibility-on.svg'
import VisibilityOff from '../../assets/images/text-visibility-off.svg'

import styles from './UpdatePasswordForm.module.scss'
import genericSs from '@styles/generic.module.scss'

import TextField from '../../components/Common/TextField'
import InputLabel from '../../components/Common/InputLabel'
import Button from '../../components/Common/Button'
import StrongPasswordCheck from '../StrongPasswordCheck'

interface IFormFields {
  password: string
  newPassword: string
}

interface IProps {
  onUpdatePassword: (data: IFormFields) => void
}

const schema = Yup.object().shape({
  password: Yup.string().required('Password is a required field.'),
  newPassword: Yup.string()
    .required('New password is a required field.')
    .matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[^A-Za-z0-9]).{8,}/, 'New password not strong.'),
})
const validate = makeValidate(schema)

const UpdatePasswordForm = ({ onUpdatePassword }: IProps) => {
  const [isLoading, setIsLoading] = useState(false)
  const handleUpdatePassword = useCallback(
    async (data: any) => {
      setIsLoading(true)
      await onUpdatePassword({ ...data })
      setIsLoading(false)
    },
    [onUpdatePassword],
  )

  const [passwordVisibility, setPasswordVisibility] = useState(false)
  const handleClickShowPassword = useCallback(() => {
    setPasswordVisibility((visibility) => !visibility)
  }, [])

  const [confirmPasswordVisibility, setConfirmPasswordVisibility] = useState(false)
  const handleClickShowConfirmPassword = useCallback(() => {
    setConfirmPasswordVisibility((visibility) => !visibility)
  }, [])

  return (
    <Box className={styles.wrapper}>
      <h3 className={styles.heading}>Change Password</h3>
      <Form
        onSubmit={handleUpdatePassword}
        validate={validate}
        render={(props) => (
          <form onSubmit={props.handleSubmit}>
            <Box display="flex" flexDirection="column" width="100%">
              <Box mt={5} mb={2}>
                <InputLabel htmlFor="password">Current Password</InputLabel>
                <TextField
                  name="password"
                  type={passwordVisibility ? 'text' : 'password'}
                  placeholder="Current Password"
                  className={genericSs.passwordInput}
                  fullWidth
                  showInputAdornmentBorder={false}
                  InputProps={{
                    autoComplete: 'off',
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          className={styles.iconButtonVisibility}
                          disableRipple
                          onClick={handleClickShowPassword}
                        >
                          {passwordVisibility ? (
                            <img src={Visibility} alt="toggle password visibility" />
                          ) : (
                            <img src={VisibilityOff} alt="toggle password visibility" />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </Box>
              <Box mb={2}>
                <InputLabel htmlFor="password">New Password</InputLabel>
                <TextField
                  name="newPassword"
                  type={confirmPasswordVisibility ? 'text' : 'password'}
                  placeholder="New Password"
                  className={genericSs.passwordInput}
                  fullWidth
                  showInputAdornmentBorder={false}
                  InputProps={{
                    autoComplete: 'off',
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          className={styles.iconButtonVisibility}
                          disableRipple
                          onClick={handleClickShowConfirmPassword}
                        >
                          {confirmPasswordVisibility ? (
                            <img src={Visibility} alt="toggle password visibility" />
                          ) : (
                            <img src={VisibilityOff} alt="toggle password visibility" />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </Box>
              <StrongPasswordCheck password={props.values.newPassword} />
              <Box>
                <Button
                  type="submit"
                  disabled={props.submitting || props.pristine || props.invalid}
                  color="primary"
                  variant="contained"
                  size="small"
                  className={styles.button}
                  isLoading={isLoading}
                >
                  Save changes
                </Button>
              </Box>
            </Box>
          </form>
        )}
      />
    </Box>
  )
}

export default UpdatePasswordForm
