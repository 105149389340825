import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'

import DilutionByCustomer from './DilutionByCustomer'

import { dilutionByCustomer, dilutionDropdowns } from '../../redux/generalLedger/selectors'
import { listDilutionByCustomer, hideDilutionByCustomer } from '../../redux/generalLedger/actions'
import { debtorIneligibleCategories } from '../../redux/options/selectors'

const selector = createStructuredSelector({
  dilutionByCustomer,
  debtorIneligibleCategories,
  dilutionDropdowns,
})
const actions = {
  listDilutionByCustomer,
  hideDilutionByCustomer,
}

export default connect(selector, actions)(DilutionByCustomer)
