import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'

import DueDiligenceClientStepFiles from './DueDiligenceClientStepFiles'

import { dueDiligenceInfo, dueDiligenceDocumentRequests } from '../../redux/dueDiligence/selectors'
import {
  getCompanyInfo,
  showDocumentRequests,
  uploadDocumentRequestsFiles,
  getDocumentRequestsSharedLink,
} from '../../redux/dueDiligence/actions'
import { deleteFile } from '../../redux/file/actions'
import { putNotification } from '../../redux/ui/actions'

const selector = createStructuredSelector({
  dueDiligenceInfo,
  dueDiligenceDocumentRequests,
})

const actions = {
  getCompanyInfo,
  showDocumentRequests,
  uploadDocumentRequestsFiles,
  getDocumentRequestsSharedLink,
  deleteFile,
  putNotification,
}

export default connect(selector, actions)(DueDiligenceClientStepFiles)
