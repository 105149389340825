import { IApi } from '../../api'
import { CommonPromiseAction } from '../types'

export const prefix = 'search'

export const SEARCH_REQUEST = `${prefix}/SEARCH_REQUEST`
export const SEARCH_SUCCESS = `${prefix}/SEARCH_SUCCESS`
export const SEARCH_FAILURE = `${prefix}/SEARCH_FAILURE`

export const search: CommonPromiseAction = (data: object) => ({
  type: [SEARCH_REQUEST, SEARCH_SUCCESS, SEARCH_FAILURE],
  promise: (api: IApi) => api.search.search(data),
  params: data,
})

export const SEARCH_BY_TYPE_REQUEST = `${prefix}/SEARCH_BY_TYPE_REQUEST`
export const SEARCH_BY_TYPE_SUCCESS = `${prefix}/SEARCH_BY_TYPE_SUCCESS`
export const SEARCH_BY_TYPE_FAILURE = `${prefix}/SEARCH_BY_TYPE_FAILURE`

export const searchByType: CommonPromiseAction = (data: object) => ({
  type: [SEARCH_BY_TYPE_REQUEST, SEARCH_BY_TYPE_SUCCESS, SEARCH_BY_TYPE_FAILURE],
  promise: (api: IApi) => api.search.search(data),
  params: data,
})
