import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'

import BBCAPSummaryTable from './BBCAPSummaryTable'

import {
  bbc,
  apSummaryStats,
  previousClientCollaterals,
  apReserves,
  isLoading as isLoadingBBC,
  arapChartData,
  arapChartDataDropdownOptions,
} from '../../redux/bbc/selectors'
import {
  getBBCAPSummaryStats,
  getPreviousBBCClientCollateral,
  show as showBBC,
  getARAPChartDropdownOptions,
} from '../../redux/bbc/actions'
import { dueDiligenceInfo } from '../../redux/dueDiligence/selectors'

const selector = createStructuredSelector({
  isLoadingBBC,
  bbc,
  apSummaryStatsData: apSummaryStats,
  previousClientCollaterals,
  apReserves,
  arapChartData,
  arapChartDataDropdownOptions,
  dueDiligenceInfo,
})

const actions = {
  getBBCAPSummaryStats,
  showBBC,
  getPreviousBBCClientCollateral,
  getARAPChartDropdownOptions,
}

export default connect(selector, actions)(BBCAPSummaryTable)
