import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'

import {
  updateNewsfeed,
  showNewsfeed,
  deleteNews,
  listBrandOptions,
} from '../../redux/newsfeed/actions'
import { newsfeedItem } from '../../redux/newsfeed/selectors'
import MarketNewsDetails from './MarketNewsDetails'

const actions = {
  updateNewsfeed,
  showNewsfeed,
  listBrandOptions,
  deleteNews,
}

const selector = createStructuredSelector({
  newsfeedItem,
})

export default connect(selector, actions)(MarketNewsDetails)
