import React, { useCallback, useState, useEffect, useMemo } from 'react'
import { useParams } from 'react-router'
import Box from '@mui/material/Box'
import Popover from '@mui/material/Popover'
import MenuItem from '@mui/material/MenuItem'
import InfiniteScroll from 'react-infinite-scroll-component'
import { Form } from 'react-final-form'
import cn from 'classnames'

import styles from './FlagContainer.module.scss'

import { ReactComponent as SortIcon } from '@assets/images/sort-icon.svg'
import Flag from './Flag'
import { BBCLogsItemLoader } from '../BBCLogsContainer/BBCLogsItem'
import { IFlags, IFlagData, FLAG_TYPE_LABELS } from '@common/interfaces/bbc'
import NoteExpandModal from '../Notes/NoteExpandModal'
import Card from '../Common/Card/Card'
import { FLAGS_LIST_FILTERS_CONFIG, FLAGS_SORT_OPTIONS, PER_PAGE } from '@common/constants/filters'
import FlagDetailSidePanel from './FlagDetailSidePanel'
import { WorkflowTypes } from '@common/interfaces/notes'
import { buildFiltersDefaults } from '../../helpers/filters'
import { ReactComponent as EmptyIcon } from '@assets/images/no-notes-icon.svg'
import FilterContainer from '../Filters/FilterContainer'

const filtersDefaults = buildFiltersDefaults(FLAGS_LIST_FILTERS_CONFIG)
interface IProps {
  workflow: WorkflowTypes
  flags: IFlagData
  listFlags: (params: object) => void
  isLoading: boolean
}

const FlagContainer = ({ workflow, flags, listFlags, isLoading }: IProps) => {
  const { id } = useParams<{ id: string }>()

  const [sortAnchorEl, setSortAnchorEl] = useState<SVGSVGElement | null>(null)
  const isSortOpen = useMemo(() => Boolean(sortAnchorEl), [sortAnchorEl])

  const [expandedFlag, setExpandedFlag] = useState<IFlags>(null)
  const [isExpandNewFlagModalOpen, setIsExpandNewFlagModalOpen] = useState(false)

  const [filters, setFilters] = useState(filtersDefaults)
  const [orderBy, setOrderBy] = useState({ field: 'record_date', direction: 'DESC' })

  const { currentFlags, itemsCount, totalItems } = useMemo(
    () => ({
      currentFlags: flags?.data,
      itemsCount: flags?.data?.length ?? 0,
      totalItems: flags?.totalItems ?? 0,
    }),
    [flags],
  )

  const handleExpandFlag = useCallback(
    (flag: IFlags) => {
      if (expandedFlag?.id === flag.id) {
        setExpandedFlag(null)
        setIsExpandNewFlagModalOpen(false)
      } else {
        setExpandedFlag(flag)
      }
    },
    [expandedFlag?.id],
  )

  useEffect(() => {
    setExpandedFlag((flag) =>
      flag ? currentFlags?.find(({ id }) => flag.id === id) || null : flag,
    )
  }, [currentFlags, expandedFlag])

  const closeExpandModal = useCallback(() => {
    setExpandedFlag(null)
    setIsExpandNewFlagModalOpen(false)
  }, [])

  const openSort = useCallback((event: React.MouseEvent<SVGSVGElement>) => {
    setSortAnchorEl((anchorEl: SVGSVGElement) => (anchorEl ? null : event.currentTarget))
  }, [])

  const closeSort = useCallback(() => {
    setSortAnchorEl(null)
  }, [])

  const filtersConfig = useMemo(
    () =>
      workflow === WorkflowTypes.entityPage
        ? []
        : [
            {
              field: 'flagType',
              type: 'list' as const,
              title: 'Flag Type',
              isMultiple: true,
              options: Object.keys(FLAG_TYPE_LABELS).map((value) => ({
                value,
                label: FLAG_TYPE_LABELS[value],
              })),
            },
          ],
    [workflow],
  )

  const handleChangeOrderBy = useCallback(
    (value) => {
      const [field, direction] = value.split(' ')
      setOrderBy((order) => ({
        field,
        direction,
      }))
      setSortAnchorEl(null)
    },
    [setOrderBy],
  )

  useEffect(() => {
    if (workflow && id) {
      listFlags({
        id,
        page: 0,
        perPage: PER_PAGE,
        workflow,
        filters,
        orderBy: orderBy.field,
        orderDirection: orderBy.direction,
      })
    }
  }, [listFlags, id, workflow, filters, orderBy])

  const loadMore = useCallback(() => {
    listFlags({
      id,
      page: Math.ceil(itemsCount / PER_PAGE),
      perPage: PER_PAGE,
      workflow,
      loadMore: true,
      filters,
      orderBy: orderBy.field,
      orderDirection: orderBy.direction,
    })
  }, [listFlags, itemsCount, id, workflow, filters, orderBy])

  const handleKeyChange = useCallback(
    (e) => {
      if (!expandedFlag) {
        return
      }
      if (e.key === 'Escape') {
        setExpandedFlag(null)
      } else if (e.key === 'ArrowDown') {
        const currentIndex = currentFlags.findIndex(({ id }) => id === expandedFlag.id)
        if (currentIndex < currentFlags.length - 1) {
          setExpandedFlag(currentFlags[currentIndex + 1])
        }
      } else if (e.key === 'ArrowUp') {
        const currentIndex = currentFlags.findIndex(({ id }) => id === expandedFlag.id)
        if (currentIndex > 0) {
          setExpandedFlag(currentFlags[currentIndex - 1])
        }
      }
    },
    [setExpandedFlag, expandedFlag, currentFlags],
  )

  const handleFiltersChange = useCallback((data: any) => {
    setFilters(data)
  }, [])

  useEffect(() => {
    window.addEventListener('keydown', handleKeyChange)

    return () => {
      window.removeEventListener('keydown', handleKeyChange)
    }
  }, [handleKeyChange])

  return (
    <Card
      className={styles.flagContainer}
      classes={{
        content: styles.flagCardContainer,
      }}
      withBorder={false}
      noHeaderMargin
    >
      <Box
        display="flex"
        justifyContent="space-between"
        alignContent="center"
        gap={2}
        className={styles.actionsContainer}
      >
        <Box display="flex" flex={1} justifyContent="flex-start" alignContent="center" gap={1}>
          <div
            className={cn(
              styles.actionButtonContainer,
              styles.actionButtonContainerFilterContainer,
            )}
          >
            <Form
              onSubmit={handleFiltersChange}
              initialValues={filters}
              mutators={{
                setFieldData: ([field, value], state, { changeValue }) => {
                  changeValue(state, field, () => value)
                },
              }}
              render={({ values, handleSubmit, form: { mutators } }) => (
                <FilterContainer
                  mutators={mutators}
                  filters={filtersConfig}
                  handleSubmit={handleSubmit}
                  values={values}
                  appliedFilters={filters}
                  withFilterItems={false}
                  filtersSize={12}
                  actionsSize={0}
                  actionsInsideFilters
                  actions={
                    <div className={styles.actionButtonContainer}>
                      <Popover
                        open={isSortOpen}
                        anchorEl={sortAnchorEl}
                        onClose={closeSort}
                        anchorOrigin={{
                          vertical: 'bottom',
                          horizontal: 'left',
                        }}
                        classes={{ paper: styles.sortMenuPopoverPaper }}
                      >
                        <div className={styles.sortMenu}>
                          {FLAGS_SORT_OPTIONS.map((option) => (
                            <MenuItem
                              key={option.value}
                              classes={{
                                root: styles.sortMenuItem,
                                selected: styles.sortMenuItemSelected,
                              }}
                              onClick={() => handleChangeOrderBy(option.value)}
                              selected={`${orderBy.field} ${orderBy.direction}` === option.value}
                            >
                              {option.label}
                            </MenuItem>
                          ))}
                        </div>
                      </Popover>

                      <SortIcon
                        className={cn(styles.button, styles.sortButton, {
                          [styles.buttonActive]: isSortOpen,
                        })}
                        onClick={openSort}
                      />
                    </div>
                  }
                  filterMenuClassName={styles.filterMenuPopoverPaper}
                />
              )}
            />
          </div>
        </Box>
      </Box>
      {isLoading && !currentFlags ? (
        <BBCLogsItemLoader />
      ) : currentFlags?.length === 0 ? (
        <div className={styles.emptyContainer}>
          <EmptyIcon />
          <div className={styles.emptyContainerText}>There are no flags at this time.</div>
        </div>
      ) : (
        <div id="scrollableDiv" className={styles.flagsList}>
          <InfiniteScroll
            className={styles.infiniteScroll}
            dataLength={itemsCount}
            next={loadMore}
            hasMore={itemsCount < totalItems}
            loader={<BBCLogsItemLoader />}
            scrollableTarget="scrollableDiv"
          >
            {currentFlags?.map((flag, index) => (
              <Flag
                flag={flag}
                key={flag.id}
                onClickExpandModal={handleExpandFlag}
                isSelected={expandedFlag?.id === flag.id}
                handleCloseExpandModal={expandedFlag?.id === flag.id ? closeExpandModal : undefined}
                index={index}
              />
            ))}
          </InfiniteScroll>

          {(isExpandNewFlagModalOpen || !!expandedFlag) && (
            <NoteExpandModal bodyClassName={styles.expandModalBody} handleClose={closeExpandModal}>
              <FlagDetailSidePanel currentFlag={expandedFlag} closeExpandModal={closeExpandModal} />
            </NoteExpandModal>
          )}
        </div>
      )}
    </Card>
  )
}

export default FlagContainer
