import { trackActivity } from './../../redux/activity/actions'
import { connect } from 'react-redux'
import { createStructuredSelector, createSelector } from 'reselect'

import CollectionsWiresPage from './CollectionsWiresPage'
import {
  isLoading as isLoadingCollections,
  wiresData,
  wiresDataNegative,
  wiresDataDeleted,
  collectionStatus,
} from '../../redux/collections/selectors'
import {
  uploadWires,
  updateWire,
  updateWires,
  deleteWire,
  restoreWire,
  saveWires,
  listNewWires,
  listNegativeWires,
  listDeletedWires,
  listWireFilesAndStatus,
} from '../../redux/collections/actions'
import { isLoading as isLoadingEntityInfo } from '../../redux/entityInfo/selectors'
import { isLoading as isLoadingFiles } from '../../redux/file/selectors'

const selector = createStructuredSelector({
  isLoading: createSelector(
    isLoadingCollections,
    isLoadingFiles,
    isLoadingEntityInfo,
    (collectionsLoading, loadingFiles, loadingEntityInfo) =>
      collectionsLoading || loadingFiles || loadingEntityInfo,
  ),
  wiresData,
  wiresDataNegative,
  wiresDataDeleted,
  collectionStatus,
})

const actions = {
  uploadWires,
  updateWire,
  updateWires,
  deleteWire,
  restoreWire,
  saveWires,
  listNewWires,
  listNegativeWires,
  listDeletedWires,
  listWireFilesAndStatus,
  trackActivity,
}

export default connect(selector, actions)(CollectionsWiresPage)
