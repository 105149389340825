import { useMemo } from 'react'

import {
  FundingRequestStatus,
  IBorrowingBase,
  IInventoryIneligibilityCustomRule,
  IInventoryIneligibilityField,
} from '@common/interfaces/bbc'
import { ILoadingData } from '../../redux/types'

import InventoryEligibleCustomRuleTable from '../Client/InventoryEligibleCustomRules/InventoryEligibleCustomRuleTable'

interface IProps {
  bbc: IBorrowingBase
  fields: ILoadingData<{ data: IInventoryIneligibilityField[] }>
  customRules: ILoadingData<IInventoryIneligibilityCustomRule[]>
  customRulesLabels: string[]
  customRulesValues: { [key: string]: string[] }
  listCustomRules: (data: { borrowingBaseId: string }) => void
  createCustomRules: (data: object) => void
  updateCustomRule: (id: string, data: object) => void
  deleteCustomRule: (id: string) => void
  listCustomRulesLabels: () => void
  createCustomRulesLabel: (data: { label: string }) => void
  listCustomRulesValues: (data: { clientName: string; field: string }) => void
  putNotification: (notification: object) => void
}

const BBCInventoryIneligibleCustomRules = ({
  bbc,
  fields,
  customRules,
  customRulesValues,
  listCustomRules,
  createCustomRules,
  updateCustomRule,
  deleteCustomRule,
  listCustomRulesLabels,
  createCustomRulesLabel,
  listCustomRulesValues,
}: IProps) => {
  const clientName = useMemo(() => bbc?.clientName, [bbc])
  const borrowingBaseId = useMemo(() => bbc?.id, [bbc])
  const isCompleted = useMemo(
    () => [FundingRequestStatus.Completed, FundingRequestStatus.Sent].includes(bbc?.status),
    [bbc],
  )

  return InventoryEligibleCustomRuleTable({
    borrowingBaseId,
    isCompleted,
    clientName,
    fields,
    customRules,
    customRulesValues,
    listCustomRules,
    createCustomRules,
    updateCustomRule,
    deleteCustomRule,
    listCustomRulesLabels,
    createCustomRulesLabel,
    listCustomRulesValues,
  })
}

export default BBCInventoryIneligibleCustomRules
