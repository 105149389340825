import React, { useCallback, useMemo, useState } from 'react'
import {
  IAmendmentDetail,
  clientTermsInfo,
  IAmendment,
  ClientAmendmentType,
} from '@common/interfaces/client'
import { Form } from 'react-final-form'
import { makeValidate } from 'mui-rff'
import * as Yup from 'yup'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'

import genericSs from '@styles/generic.module.scss'
import styles from './../ClientAmendments.module.scss'

import { dateToString, formatDate, formatPercent, formatPrice } from '../../../../helpers/helpers'
import { snakeToCamelCase, snakeToTitleCase } from '@common/helpers/helpers'
import WarningModal from '../../../WarningModal'
import Modal from '../../../Common/Modal'
import InputLabel from '../../../Common/InputLabel'
import TextField from '../../../Common/TextField'
import Button from '../../../Common/Button'
import KeyboardDatePicker from '../../../Common/KeyboardDatePicker'
import { DATE_FORMAT } from '../../../../constants/common'
import moment from 'moment/moment'
import TableHead from '../../../Common/TableHead'
import TableRow from '../../../Common/TableRow'
import TableCell from '../../../Common/TableCell'
import TableBody from '../../../Common/TableBody'
import Table from '../../../Common/Table'
import { PARTICIPATION_FIELDS } from '@common/interfaces/participant'
import { MenuIcon } from '../../../Common/Icons'

const schema = Yup.object().shape({
  effectiveDate: Yup.date()
    .typeError('Please type date in MM/DD/YY format')
    .test({
      name: 'minDate',
      exclusive: false,
      message: 'Effective Date cannot be today or the past',
      test(value) {
        return (
          !value || !moment(value).startOf('day').isSameOrBefore(moment().startOf('day'), 'day')
        )
      },
    }),
})

const AmendmentEditModal = ({
  item,
  handleSubmit,
  handleCancel,
  loanBalanceStartDate,
}: {
  item: IAmendment
  handleSubmit: (data: any) => void
  handleCancel: () => void
  loanBalanceStartDate: string
}) => {
  const isInFuture = useMemo(() => moment(item.effectiveDate).isAfter(moment(), 'day'), [item])
  const minDate = useMemo(
    () =>
      item.isManual
        ? moment(loanBalanceStartDate || undefined).toDate()
        : moment().add(1, 'day').startOf('day').toDate(),
    [item, loanBalanceStartDate],
  )
  const validate = useMemo(() => {
    const minDate = moment(loanBalanceStartDate || undefined).toDate()

    if (item.isManual) {
      return makeValidate(
        Yup.object().shape({
          summaryOfChanges: Yup.string().required('Required'),
          effectiveDate: Yup.date()
            .typeError('Please type date in MM/DD/YY format')
            .required('Required')
            .min(minDate, 'Cannot set effective date for a locked month'),
          boxLink: Yup.string().nullable(),
        }),
      )
    }

    return isInFuture ? makeValidate(schema) : undefined
  }, [isInFuture, item.isManual, loanBalanceStartDate])

  return (
    <Modal open onCancel={handleCancel} title="Edit Amendment" size="small">
      <Form
        validate={validate}
        initialValues={item}
        onSubmit={handleSubmit}
        render={({ pristine, invalid, submitting, handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            <Box display="flex" flexDirection="column" gap={3}>
              {item.isManual ? (
                <Box flex={1}>
                  <InputLabel htmlFor="boxLink" className={genericSs.textLeft}>
                    Summary of Changes
                  </InputLabel>
                  <TextField
                    className={styles.textFormField}
                    id="summaryOfChanges"
                    name="summaryOfChanges"
                    placeholder="Summary of Changes"
                  />
                </Box>
              ) : (
                <Box flex={1}>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>Field</TableCell>
                        <TableCell>Old Value</TableCell>
                        <TableCell>New Value</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {item.clientAmendmentDetails.map((amendmentDetail) => {
                        const { termName, oldValue, newValue } = formatTerm(
                          amendmentDetail,
                          item.amendmentType,
                        )

                        return (
                          <TableRow key={amendmentDetail.id}>
                            <TableCell>{termName}</TableCell>
                            <TableCell>{oldValue}</TableCell>
                            <TableCell>{newValue}</TableCell>
                          </TableRow>
                        )
                      })}
                    </TableBody>
                  </Table>
                </Box>
              )}

              <Box flex={1}>
                <InputLabel htmlFor="boxLink" className={genericSs.textLeft}>
                  Effective Date
                </InputLabel>
                <KeyboardDatePicker
                  className={styles.textFormField}
                  name="effectiveDate"
                  inputFormat={DATE_FORMAT}
                  minDate={minDate}
                  disabled={!item.isManual && !isInFuture}
                />
              </Box>

              <Box flex={1}>
                <InputLabel htmlFor="boxLink" className={genericSs.textLeft}>
                  Amendment box link
                </InputLabel>
                <TextField
                  className={styles.textFormField}
                  id="boxLink"
                  name="boxLink"
                  placeholder="Amendment box link"
                  InputProps={{
                    type: 'url',
                  }}
                />
              </Box>
            </Box>

            <Box mt={5}>
              <Button
                type="submit"
                fullWidth
                small={false}
                key="submit"
                color="primary"
                variant="contained"
                onClick={handleSubmit}
                disabled={pristine || invalid || submitting}
                isLoading={submitting}
              >
                Confirm
              </Button>
            </Box>
          </form>
        )}
      />
    </Modal>
  )
}

export const AmendmentMenu = ({
  amendment,
  updateAmendment,
  deleteAmendment,
  loanBalanceStartDate,
}: {
  amendment: IAmendment
  updateAmendment: (id: string, values: object) => Promise<any>
  deleteAmendment: (id: string) => Promise<any>
  loanBalanceStartDate: string
}) => {
  const [anchorEl, setAnchorEl] = useState(null)
  const [isMenuOpen, setIsMenuOpen] = useState(false)
  const [isEditModalOpen, setIsEditModalOpen] = useState(false)
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false)

  const handleClickMenu = useCallback((event: React.MouseEvent) => {
    setAnchorEl(event.currentTarget)
    setIsMenuOpen(true)
  }, [])

  const handleCloseMenu = useCallback(() => {
    setAnchorEl(null)
    setIsMenuOpen(false)
  }, [])

  const handleEdit = useCallback(() => {
    handleCloseMenu()
    setIsEditModalOpen(true)
  }, [handleCloseMenu])

  const handleEditConfirm = useCallback(
    async (data: any) => {
      const result = await updateAmendment(amendment.id, {
        boxLink: data.boxLink || '',
        effectiveDate: dateToString(data.effectiveDate),
        summaryOfChanges: amendment.isManual ? data.summaryOfChanges : null,
      })
      !result?.error && setIsEditModalOpen(false)
    },
    [amendment, updateAmendment],
  )

  const handleDelete = useCallback(() => {
    handleCloseMenu()
    setIsDeleteModalOpen(true)
  }, [handleCloseMenu])

  const handleDeleteConfirm = useCallback(() => {
    setIsDeleteModalOpen(false)
    deleteAmendment(amendment.id)
  }, [amendment, deleteAmendment])

  const handleCancel = useCallback(() => {
    setIsDeleteModalOpen(false)
    setIsEditModalOpen(false)
  }, [])

  return (
    <>
      <MenuIcon isActive={isMenuOpen} onClick={handleClickMenu} size="small" />
      <Menu
        classes={{ paper: styles.iconMenu }}
        open={isMenuOpen}
        onClose={handleCloseMenu}
        anchorEl={anchorEl}
      >
        <MenuItem classes={{ root: styles.iconMenuItem }} onClick={handleEdit}>
          Edit
        </MenuItem>
        <MenuItem classes={{ root: styles.iconMenuItem }} onClick={handleDelete}>
          Delete
        </MenuItem>
      </Menu>
      {isEditModalOpen && (
        <AmendmentEditModal
          item={amendment}
          handleSubmit={handleEditConfirm}
          handleCancel={handleCancel}
          loanBalanceStartDate={loanBalanceStartDate}
        />
      )}
      {isDeleteModalOpen && (
        <WarningModal
          warningMessage="Are you sure you want to delete this amendment, this can not be undone."
          onConfirm={handleDeleteConfirm}
          onCancel={handleCancel}
          confirmText="Delete"
          cancelText="Cancel"
        />
      )}
    </>
  )
}

interface IProps {
  amendmentDetails: IAmendmentDetail[]
  amendmentType: ClientAmendmentType
}

const formatTerm = (term: IAmendmentDetail, amendmentType = ClientAmendmentType.Legal) => {
  const termValue = snakeToCamelCase(term.termName)
  let termType
  let termName

  if (amendmentType === ClientAmendmentType.Participation) {
    const termInfo = PARTICIPATION_FIELDS.find((term) => term.value === termValue)
    termType = termInfo?.type
    termName = termInfo?.label || snakeToTitleCase(term.termName)
  } else {
    const termInfo = clientTermsInfo.find((term) => term.value === termValue)
    termName = termInfo?.label || snakeToTitleCase(term.termName)
    termType = termInfo?.type
  }

  let oldValue = term.oldValue
  let newValue = term.newValue

  switch (termType) {
    case 'date':
      oldValue = formatDate(oldValue)
      newValue = formatDate(newValue)
      break
    case 'currency':
      oldValue = `$${formatPrice(oldValue)}`
      newValue = `$${formatPrice(newValue)}`
      break
    case 'percent':
      oldValue = formatPercent(+oldValue)
      newValue = formatPercent(+newValue)
      break
    default:
      break
  }
  if (!term.oldValue) {
    oldValue = '-'
  }
  if (!term.newValue) {
    newValue = '-'
  }
  return { termName, oldValue, newValue }
}

const ClientAmendmentDetails = ({ amendmentDetails, amendmentType }: IProps) => {
  return (
    <Grid container rowSpacing={2}>
      <Grid container item xs={12}>
        <Grid item xs={1} />
        <Grid container item xs={8}>
          <Grid item xs={4}>
            <h3>Term</h3>
          </Grid>
          <Grid item xs={4}>
            <h3> Old value</h3>
          </Grid>
          <Grid item xs={4}>
            <h3>New value</h3>
          </Grid>
        </Grid>
        <Grid item xs={3} />
      </Grid>
      <Grid container item xs={12} rowSpacing={1}>
        {amendmentDetails?.map((amendmentDetail) => {
          const { termName, oldValue, newValue } = formatTerm(amendmentDetail, amendmentType)
          return (
            <>
              <Grid item xs={1} />

              <Grid
                container
                item
                xs={8}
                key={amendmentDetail.id}
                justifyContent="start"
                alignItems="center"
                className={styles.amendmentDetailsRow}
              >
                <Grid item xs={12}>
                  <div className={styles.divider} />
                </Grid>

                <Grid item xs={4}>
                  <span>{termName}</span>
                </Grid>
                <Grid item xs={4}>
                  <span>{oldValue}</span>
                </Grid>
                <Grid item xs={4}>
                  <span>{newValue}</span>
                </Grid>
              </Grid>
              <Grid item xs={3} />
            </>
          )
        })}
      </Grid>
    </Grid>
  )
}

export default ClientAmendmentDetails
