import React, { useCallback } from 'react'
import cn from 'classnames'

import styles from './CollectionsWiresMapping.module.scss'
import genericSs from '@styles/generic.module.scss'

import Table from '../Common/Table'
import TableHead from '../Common/TableHead'
import TableRow from '../Common/TableRow'
import TableCell from '../Common/TableCell'
import TableBody from '../Common/TableBody'
import TableContainer from '../Common/TableContainer'
import { formatPrice, unFormatPrice } from '../../helpers/helpers'
import CurrencyField from '../Common/CurrencyField'
import { IWiresDataReconciliationAccounts } from '@common/interfaces/collection'
import TableLoader from '../../components/Common/TableLoader'

interface IProps {
  accounts: IWiresDataReconciliationAccounts
  setAccounts: any
  wiresDataTotal: number
  allDataLoading: boolean
}

const CollectionsWiresReconciliationTable = ({
  accounts,
  setAccounts,
  wiresDataTotal,
  allDataLoading,
}: IProps) => {
  const handleChangeAccounts = useCallback(
    (name: string, value: any) => {
      setAccounts((data: IWiresDataReconciliationAccounts) => {
        const accountsData = {
          ...data,
          [name]: value,
        }
        return {
          ...accountsData,
          discrepancy:
            Math.round(
              (+(wiresDataTotal || 0) -
                unFormatPrice(accountsData.bank) +
                unFormatPrice(accountsData.operating) +
                unFormatPrice(accountsData.concentration)) *
                100,
            ) / 100,
        }
      })
    },
    [wiresDataTotal, setAccounts],
  )

  const handleChangeAccount = useCallback(
    (event) => {
      handleChangeAccounts(event.target.name, event.target.value)
    },
    [handleChangeAccounts],
  )

  return (
    <TableContainer className={styles.wiresReconciliationTable}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell className={genericSs.tableTextLeft}>Account</TableCell>
            <TableCell className={genericSs.tableTextLeft}>Total deposit</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {allDataLoading ? (
            <TableLoader rowsCount={4} columnsCount={2} />
          ) : (
            <>
              <TableRow>
                <TableCell className={genericSs.tableTextLeft}>Dwight Bank Account</TableCell>
                <TableCell className={genericSs.tableTextLeft}>
                  <CurrencyField
                    name="bank"
                    useFinalForm={false}
                    value={accounts.bank}
                    onChange={handleChangeAccount}
                  />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell className={genericSs.tableTextLeft}>Dwight Operating Account</TableCell>
                <TableCell className={genericSs.tableTextLeft}>
                  <CurrencyField
                    name="operating"
                    useFinalForm={false}
                    value={accounts.operating}
                    onChange={handleChangeAccount}
                  />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell className={genericSs.tableTextLeft}>
                  Sterling Concentration Account
                </TableCell>
                <TableCell className={genericSs.tableTextLeft}>
                  <CurrencyField
                    name="concentration"
                    useFinalForm={false}
                    value={accounts.concentration}
                    onChange={handleChangeAccount}
                  />
                </TableCell>
              </TableRow>
              <TableRow
                className={cn({
                  [styles.validRow]: accounts.discrepancy === 0,
                  [styles.rowInvalid]: accounts.discrepancy !== 0,
                })}
              >
                <TableCell className={genericSs.tableTextLeft}>
                  <b>Discrepancy</b>
                </TableCell>
                <TableCell className={genericSs.tableTextLeft}>
                  <span className={genericSs.pricePrefix}>$</span>
                  {formatPrice(accounts.discrepancy)}
                </TableCell>
              </TableRow>
            </>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export default CollectionsWiresReconciliationTable
