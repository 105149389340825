import { IClientInfo } from './client'

export enum PassThroughStatus {
  Unsent = 'Unsent',
  Pending = 'Pending',
  Sent = 'Sent',
}

export interface IPassThrough {
  id: string
  amount: number
  clientId: string
  clientName: string
  clientStatus: string
  collectionDays?: number
  recordDate: string
  sentDate?: string
  status: PassThroughStatus
  clientInfo?: IClientInfo
  noWireFeeApplied?: boolean
}

export interface IPassThroughData {
  data: IPassThrough[]
  totals: {
    totalItems: number
  }
}
