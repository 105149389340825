import { combineReducers } from 'redux'

import * as actions from './actions'
import { IBBCLog, IBBCLogData } from '@common/interfaces/bbcLog'
import { ILoadingData } from '../types'
import { createLoadingDataReducer } from '../../helpers/redux'
import { UPDATE_BBC_LOG } from '@common/constants/webSockets'
import { ADD_NOTE_SUCCESS, DELETE_NOTE_SUCCESS, UPDATE_NOTE_SUCCESS } from '../notes/actions'

export interface IBBCLogState {
  data: ILoadingData<IBBCLogData>
}

export default combineReducers<IBBCLogState>({
  data: createLoadingDataReducer<IBBCLogData>(
    [actions.LIST_BBC_LOG_REQUEST],
    [UPDATE_BBC_LOG, ADD_NOTE_SUCCESS, DELETE_NOTE_SUCCESS, UPDATE_NOTE_SUCCESS],
    true,
    [actions.HIDE_BBC_LOG],
    null,
    (state: any, action: any) => {
      if (action.type === ADD_NOTE_SUCCESS) {
        if (action.data.parentId) {
          return {
            ...state,
            data: {
              ...state.data,
              data: state?.data?.data?.map((item: any) => ({
                ...item,
                notes: item.notes.map((note: any) =>
                  note.id === action.data.parentId
                    ? {
                        ...note,
                        children: [...(note.children || []), action.data],
                      }
                    : note,
                ),
              })),
            },
          }
        }

        if (action.data.bbcLogId) {
          return {
            ...state,
            data: {
              ...state.data,
              data: state?.data?.data?.map((item: any) => ({
                ...item,
                notes: item.id === action.data.bbcLogId ? [action.data] : item.notes,
              })),
            },
          }
        }

        return state
      }

      if (action.type === UPDATE_NOTE_SUCCESS) {
        if (action.data.parentId) {
          return {
            ...state,
            data: {
              ...state.data,
              data: state?.data?.data?.map((item: any) => ({
                ...item,
                notes: item.notes.map((note: any) =>
                  note.id === action.data.parentId
                    ? {
                        ...note,
                        children: (note.children || []).map((child: any) =>
                          child.id === action.data.id ? action.data : child,
                        ),
                      }
                    : note,
                ),
              })),
            },
          }
        }

        return state
      }

      if (action.type === DELETE_NOTE_SUCCESS) {
        if (action.data.parentId) {
          return {
            ...state,
            data: {
              ...state.data,
              data: state?.data?.data?.map((item: any) => ({
                ...item,
                notes: item.notes.map((note: any) =>
                  note.id === action.data.parentId
                    ? {
                        ...note,
                        children: (note.children || []).filter(
                          (child: any) => child.id !== action.data.id,
                        ),
                      }
                    : note,
                ),
              })),
            },
          }
        }

        return state
      }

      // No BBC Logs tab opened
      if (!state.data || !action.data) {
        return state
      }

      const {
        data: { params },
      } = state
      const { data } = action as { data: IBBCLog }
      const { id, clientName } = data

      if (id && Object.keys(data).length === 1) {
        return {
          ...state,
          data: {
            ...state.data,
            data: state.data?.data?.filter((item: any) => item.id !== id),
          },
        }
      }

      // Current BBC Logs tab for another Client
      if (clientName !== params.clientName) {
        return state
      }

      return {
        ...state,
        data: {
          ...state.data,
          data: !!state.data?.data?.find((item: any) => item.id === id)
            ? state?.data?.data.map((item: any) => (item.id === id ? action.data : item))
            : [action.data, ...state.data.data],
        },
      }
    },
  ),
})
