import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'

import DealStructure from './DealStructure'

import { clientInfo } from '../../../redux/clientInfo/selectors'
import { updateClientTerms } from '../../../redux/clientInfo/actions'
import { loanBalanceStartDate } from '../../../redux/loanBalance/selectors'

const selector = createStructuredSelector({
  clientInfo,
  loanBalanceStartDate,
})

const actions = {
  updateClientTerms,
}

export default connect(selector, actions)(DealStructure)
