import { ILoadingData } from 'src/redux/types'
import React, { useCallback, useEffect, useMemo } from 'react'
import Table from '../Common/Table'
import TableHead from '../Common/TableHead'
import TableBody from '../Common/TableBody'
import TableRow from '../Common/TableRow'
import TableCell from '../Common/TableCell'
import TableContainer from '../Common/TableContainer'
import { StressCaseData, MetricValue, STRESS_CASE_METRICS } from '@common/interfaces/stressCase'
import TableLoader from '../Common/TableLoader'
import { useParams } from 'react-router'
import MetricRow from './MetricRow'
import genericSs from '@styles/generic.module.scss'
import SaveState from '../Common/SaveState'
import { Box } from '@mui/material'
import styles from './StressCaseAssumptions.module.scss'
import { ReportingFlow } from '@common/interfaces/bbc'
interface IProps {
  stressCase: ILoadingData<StressCaseData>
  reportingFlow: ReportingFlow
  updateStressCaseAssumption: (id: string, assumptionId: string, data: object) => void
  listStressCaseAssumptions: (id: string, data: { reportingFlow: ReportingFlow }) => void
}

const StressCaseAssumptions = ({
  stressCase,
  reportingFlow,
  updateStressCaseAssumption,
  listStressCaseAssumptions,
}: IProps) => {
  const { id } = useParams<{ id: string }>()

  useEffect(() => {
    if (id) {
      listStressCaseAssumptions(id, { reportingFlow })
    }
  }, [id, listStressCaseAssumptions, reportingFlow])

  const { data, isLoading, isSaved, isSaving } = useMemo(() => stressCase, [stressCase])

  const getValue = useCallback(
    (yearId: string, metricId: string): MetricValue | undefined => {
      return data?.values.find((value) => value.yearId === yearId && value.metricId === metricId)
    },
    [data],
  )

  const handleUpdateStressCaseAssumption = useCallback(
    async (assumptionId: string, metricId: string, value: number) => {
      updateStressCaseAssumption(id, assumptionId, { [metricId]: value })
    },
    [updateStressCaseAssumption, id],
  )

  return (
    <TableContainer>
      <Table className={styles.stressCaseTable}>
        {isLoading ? (
          <TableLoader columnsCount={6} rowsCount={STRESS_CASE_METRICS.length} />
        ) : (
          <>
            <TableHead>
              <TableRow>
                <TableCell className={genericSs.tableTextLeft}>Metric</TableCell>
                {data?.timeline?.map((year) => (
                  <TableCell key={year.year} className={genericSs.tableTextRight}>
                    {year.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {data &&
                STRESS_CASE_METRICS.map((metric) => (
                  <MetricRow
                    key={metric.id}
                    metric={metric}
                    timeline={data.timeline}
                    getValue={getValue}
                    onUpdateAssumption={handleUpdateStressCaseAssumption}
                  />
                ))}
            </TableBody>
          </>
        )}
      </Table>
      <Box display="flex" alignItems="center" justifyContent="flex-end">
        <SaveState isSaving={isSaving} isSaved={isSaved} />
      </Box>
    </TableContainer>
  )
}

export default StressCaseAssumptions
