import React from 'react'
import Skeleton from '@mui/material/Skeleton'

import styles from './EntityPreview.module.scss'

import { IEntityPreviewInfo } from '@common/interfaces/entityInfo'

interface IProps {
  isLoading: boolean
  entityInfo: IEntityPreviewInfo | null
}

const EntityPreviewClientInfo = ({ isLoading, entityInfo }: IProps) => {
  if (isLoading || !entityInfo) {
    return (
      <>
        <div className={styles.row}>
          <Skeleton height={75} width={75} variant="rectangular" />

          <div className={styles.column}>
            <div className={styles.dataBlock}>
              <Skeleton variant="rounded" height={15} width={60} />
              <Skeleton variant="rounded" height={15} width={40} />
            </div>
            <div className={styles.dataBlock}>
              <Skeleton variant="rounded" height={15} width={110} />
              <Skeleton variant="rounded" height={15} width={40} />
            </div>
            <div className={styles.dataBlock}>
              <Skeleton variant="rounded" height={15} width={60} />
              <Skeleton variant="rounded" height={15} width={40} />
            </div>
            <div className={styles.dataBlock}>
              <Skeleton variant="rounded" height={15} width={120} />
              <Skeleton variant="rounded" height={15} width={40} />
            </div>
          </div>
        </div>
      </>
    )
  }

  return (
    <>
      <div className={styles.row}>
        {entityInfo.iconUrl && (
          <div className={styles.avatarWrapper}>
            <img className={styles.avatarImg} src={entityInfo.iconUrl} alt={entityInfo.name} />
          </div>
        )}
        {entityInfo.debtor && (
          <div className={styles.column}>
            <div className={styles.dataBlock}>
              <div className={styles.dataLabel}>Avg. DSO:</div>
              <div className={styles.dataValue}>
                {entityInfo.dsoOnClient ? entityInfo.dsoOnClient.toFixed(2) : 'N/A'}
              </div>
            </div>
            <div className={styles.dataBlock}>
              <div className={styles.dataLabel}>Portfolio Avg. DSO:</div>
              <div className={styles.dataValue}>
                {entityInfo.dso ? entityInfo.dso.toFixed(2) : 'N/A'}
              </div>
            </div>
            <div className={styles.dataBlock}>
              <div className={styles.dataLabel}>Avg. Terms:</div>
              <div className={styles.dataValue}>
                {entityInfo.averageTermsOnClient
                  ? entityInfo.averageTermsOnClient.toFixed(2)
                  : 'N/A'}
              </div>
            </div>
            <div className={styles.dataBlock}>
              <div className={styles.dataLabel}>Portfolio Avg. Terms:</div>
              <div className={styles.dataValue}>
                {entityInfo.averageTerms ? entityInfo.averageTerms.toFixed(2) : 'N/A'}
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  )
}

export default EntityPreviewClientInfo
