import React from 'react'
import Skeleton from '@mui/material/Skeleton'

import styles from './ClientNewsfeedContainer.module.scss'

const NewsItemLoader = () => {
  return (
    <div className={styles.item}>
      <div className={styles.itemIcon}>
        <Skeleton width={16} height={27} />
      </div>
      <div className={styles.itemData}>
        <div className={styles.itemDescription}>
          <Skeleton height={21} />
          <Skeleton height={21} />
          <Skeleton height={21} width="70%" />
        </div>
        <div className={styles.itemDate}>
          <Skeleton height={16} width={50} />
        </div>
      </div>
    </div>
  )
}

export default NewsItemLoader
