import React, { useState, useCallback, useMemo, useRef, useEffect } from 'react'
import moment from 'moment'
import { Form, FormRenderProps, FormSpy } from 'react-final-form'
import { FormApi } from 'final-form'
import { makeValidate } from 'mui-rff'
import * as Yup from 'yup'
import Box from '@mui/material/Box'
import InputAdornment from '@mui/material/InputAdornment'
import IconButton from '@mui/material/IconButton'

import styles from './DueDiligenceCompanyBackgroundModal.module.scss'

import { ReactComponent as Visibility } from '@assets/images/text-visibility-on.svg'
import { ReactComponent as VisibilityOff } from '@assets/images/text-visibility-off.svg'
import KeyboardDatePicker from '../Common/KeyboardDatePicker'
import {
  DATE_DATABASE_FORMAT,
  FEDERAL_TAX_VALIDATE_REGEXP,
  PHONE_VALIDATE_REGEXP,
  STATES,
  YEAR_FORMAT,
} from '../../constants/common'
import InputLabel from '../Common/InputLabel'
import {
  DueDiligenceDocumentRequestStep,
  IDueDiligenceCompanyInfo,
  IDueDiligenceDocumentRequest,
} from '@common/interfaces/dueDiligence'
import DueDiligenceFullscreenModal from '../DueDiligenceFullscreenModal'
import TextField from '../Common/TextField'
import AutocompletePlace from '../Common/AutocompletePlace'
import SelectField from '../Common/SelectField'
import { FederalTaxIdMaskInput, PhoneMaskInput } from '../Common/MaskInput'
import BooleanRadios from '../Common/BooleanRadios'
import { ILoadingData } from '../../redux/types'
import DueDiligenceClientStepFiles from '../DueDiligenceClientStepFiles'

const schema = Yup.object().shape({
  fullLegalName: Yup.string(),
  employeeCount: Yup.number()
    .nullable(true)
    .typeError('Invalid # of employees')
    .min(0, 'Invalid # of employees'),
  federalTaxId: Yup.string()
    .nullable(true)
    .matches(FEDERAL_TAX_VALIDATE_REGEXP, 'Not a valid federal tax id'),
  phone: Yup.string().nullable(true).matches(PHONE_VALIDATE_REGEXP, 'Not a valid phone number'),
})
const validate = makeValidate(schema)
const STATES_OPTIONS = Object.values(STATES).map((state: string) => ({
  value: state,
  label: state,
}))
const today = new Date()

const mutators = {
  setFieldData: ([field, value]: any, state: any, { changeValue }: any) => {
    changeValue(state, field, () => value)
  },
}

const COMPANY_BACKGROUND_STEP = 0
const DOCUMENTATION_STEP = 1

const DueDiligenceCompanyBackgroundFormRender = ({
  form,
  values,
  invalid,
  submitting,
  formRef,
  onChange,
  setIsFormInvalid,
}: FormRenderProps<any> & {
  formRef: React.MutableRefObject<FormApi<any, Partial<any>>>
  onChange: (values: any) => void
  setIsFormInvalid: (state: boolean) => void
}) => {
  formRef.current = form

  useEffect(() => {
    setIsFormInvalid(submitting || invalid)
  }, [invalid, submitting, setIsFormInvalid])

  const [taxVisibility, setTaxVisibility] = useState(false)

  const handleSelect = useCallback(
    (address: any) => form.mutators.setFieldData('billingAddress', address),
    [form],
  )
  const toggleTaxVisibility = useCallback(() => {
    setTaxVisibility((prev) => !prev)
  }, [])

  const phoneMaskProps = useMemo(() => ({ inputComponent: PhoneMaskInput() }), [])
  const federalTaxIdMaskProps = useMemo(
    () => ({
      autoComplete: 'one-time-code',
      endAdornment: (
        <InputAdornment position="end">
          <IconButton
            aria-label="toggle visibility"
            className={styles.iconButtonVisibility}
            disableRipple
            onClick={toggleTaxVisibility}
          >
            {taxVisibility ? <Visibility /> : <VisibilityOff />}
          </IconButton>
        </InputAdornment>
      ),
      ...(taxVisibility ? { inputComponent: FederalTaxIdMaskInput() } : {}),
    }),
    [taxVisibility, toggleTaxVisibility],
  )

  return (
    <form className={styles.formWrapper}>
      <div className={styles.formContent}>
        <Box>
          <InputLabel className={styles.inputLabel}>Legal company name</InputLabel>
          <TextField
            name="fullLegalName"
            placeholder="E.g., Main Street LLC."
            size="large"
            className={styles.inputField}
          />
        </Box>
        <Box>
          <InputLabel className={styles.inputLabel}># of employees</InputLabel>
          <TextField
            name="employeeCount"
            type="number"
            placeholder="2001"
            size="large"
            className={styles.inputField}
          />
        </Box>
        <Box>
          <InputLabel className={styles.inputLabel}>HQ address</InputLabel>
          <AutocompletePlace
            label=""
            name="address"
            placeholder="E.g., 123 Spring Street, New York, NY..."
            options={[]}
            className={styles.autocompleteField}
            onSelect={handleSelect}
          />
        </Box>
        <Box>
          <InputLabel className={styles.inputLabel}>Year founded</InputLabel>
          <KeyboardDatePicker
            className={styles.datePickerField}
            name="establishedDate"
            inputFormat={YEAR_FORMAT}
            maxDate={today}
            openTo="year"
            views={['year']}
            placeholder="E.g., 2000"
          />
        </Box>
        <Box>
          <InputLabel className={styles.inputLabel}>Company phone number</InputLabel>
          <TextField
            name="phone"
            InputProps={phoneMaskProps}
            placeholder="E.g., (123) 456-7890"
            size="large"
            className={styles.inputField}
          />
        </Box>
        <Box>
          <InputLabel className={styles.inputLabel}>State of formation</InputLabel>
          <SelectField
            border
            selectSize="large"
            name="stateOfFormation"
            options={STATES_OPTIONS}
            placeholder="Select state"
            className={styles.selectField}
          />
        </Box>
        <Box>
          <InputLabel className={styles.inputLabel}>Federal tax ID</InputLabel>
          <TextField
            name="federalTaxId"
            type={values.federalTaxId && !taxVisibility ? 'password' : 'text'}
            InputProps={federalTaxIdMaskProps}
            placeholder="E.g., 12-3456789"
            size="large"
            className={styles.inputField}
          />
        </Box>
        <Box className={styles.booleanFieldRow}>
          <div className={styles.booleanFieldRowLabel}>
            Are you current with all local, state, and federal taxes?
          </div>

          <BooleanRadios name="isWithAllTaxes" />
        </Box>
        {values.isWithAllTaxes === 'false' && (
          <Box className={styles.booleanFieldRow}>
            <TextField
              name="isWithAllTaxesComment"
              size="large"
              className={styles.inputField}
              rows={4}
              multiline
            />
          </Box>
        )}

        <Box className={styles.booleanFieldRow}>
          <div className={styles.booleanFieldRowLabel}>
            Are there any pending judgments or litigations?
          </div>

          <BooleanRadios name="isPendingJudgement" />
        </Box>
        {values.isPendingJudgement === 'true' && (
          <Box className={styles.booleanFieldRow}>
            <TextField
              name="isPendingJudgementComment"
              size="large"
              className={styles.inputField}
              rows={4}
              multiline
            />
          </Box>
        )}

        <Box className={styles.booleanFieldRow}>
          <div className={styles.booleanFieldRowLabel}>
            Are any of your assets pledged as collateral?
          </div>

          <BooleanRadios name="isAssetsPledged" />
        </Box>
        {values.isAssetsPledged === 'true' && (
          <Box className={styles.booleanFieldRow}>
            <TextField
              name="isAssetsPledgedComment"
              size="large"
              className={styles.inputField}
              rows={4}
              multiline
            />
          </Box>
        )}
      </div>
      <FormSpy
        subscription={{ values: true }}
        onChange={(props) => {
          onChange(props.values)
        }}
      />
    </form>
  )
}

interface IProps {
  dueDiligenceCompanyInfo: IDueDiligenceCompanyInfo
  dueDiligenceDocumentRequests: ILoadingData<{ data: IDueDiligenceDocumentRequest[] }>
  updateCompanyInfo: (data: object) => Promise<void>
  onCloseModal: () => void
  startStep?: string
}

const DueDiligenceCompanyBackgroundModal = ({
  dueDiligenceCompanyInfo,
  dueDiligenceDocumentRequests,
  updateCompanyInfo,
  onCloseModal,
  startStep,
}: IProps) => {
  const filesNavigationRef: React.MutableRefObject<any> = useRef(null)
  const formRef: React.MutableRefObject<FormApi<any, Partial<any>>> = useRef(null)
  const [isLoading, setIsLoading] = useState(false)
  const [step, setStep] = useState(startStep ? parseInt(startStep) : COMPANY_BACKGROUND_STEP)
  const [progress, setProgress] = useState(dueDiligenceCompanyInfo.companyBackgroundProgress)
  const [isFormInvalid, setIsFormInvalid] = useState(false)

  useEffect(() => {
    setProgress(dueDiligenceCompanyInfo.companyBackgroundProgress)
  }, [dueDiligenceCompanyInfo])

  const handleFormChange = useCallback(
    (values: any) => {
      const {
        billingAddress,
        isWithAllTaxes,
        isWithAllTaxesComment,
        isPendingJudgement,
        isPendingJudgementComment,
        isAssetsPledged,
        isAssetsPledgedComment,
        ...fields
      } = values
      let completedColumnsCount = Object.values(fields).filter(Boolean).length
      const columnsCount = 10
      if (isWithAllTaxes === 'true' || (isWithAllTaxes === 'false' && isWithAllTaxesComment)) {
        completedColumnsCount += 1
      }
      if (
        isPendingJudgement === 'false' ||
        (isPendingJudgement === 'true' && isPendingJudgementComment)
      ) {
        completedColumnsCount += 1
      }
      if (isAssetsPledged === 'false' || (isAssetsPledged === 'true' && isAssetsPledgedComment)) {
        completedColumnsCount += 1
      }

      const documentRequests =
        dueDiligenceDocumentRequests?.data?.data?.filter(
          ({ type }) => type.step === DueDiligenceDocumentRequestStep.CompanyBackground,
        ) || []
      const completedDocumentRequestCount = documentRequests.filter(
        ({ files }) => files.length > 0,
      ).length
      const documentRequestCount = documentRequests.length

      setProgress(
        Math.round(
          ((completedColumnsCount + completedDocumentRequestCount) /
            (columnsCount + documentRequestCount)) *
            100,
        ),
      )
    },
    [dueDiligenceDocumentRequests],
  )

  const handleSetStep = useCallback(
    async (nextStep) => {
      if (nextStep === step) {
        return
      }

      if (step === COMPANY_BACKGROUND_STEP) {
        await formRef.current.submit()
      }

      setStep(nextStep)
    },
    [step],
  )

  const handleCloseModal = useCallback(async () => {
    if (step === COMPANY_BACKGROUND_STEP) {
      await formRef.current.submit()
    }

    onCloseModal()
  }, [step, onCloseModal])

  const handleSaveActive = useCallback(
    async (data) => {
      const {
        address,
        billingAddress,
        establishedDate,
        federalTaxId,
        isWithAllTaxes,
        isPendingJudgement,
        isAssetsPledged,
        ...rest
      } = data

      setIsLoading(true)
      await updateCompanyInfo({
        establishedDate: establishedDate
          ? moment(establishedDate).startOf('year').format(DATE_DATABASE_FORMAT)
          : null,
        billingCountry: billingAddress?.country || '',
        billingState: billingAddress?.state || '',
        billingCity: billingAddress?.city || '',
        billingPostalCode: billingAddress?.postalCode || '',
        billingStreet: billingAddress?.street || '',
        federalTaxId: federalTaxId ? federalTaxId.replaceAll('-', '') : null,
        isWithAllTaxes: isWithAllTaxes === 'true',
        isPendingJudgement: isPendingJudgement === 'true',
        isAssetsPledged: isAssetsPledged === 'true',
        ...rest,
      })
      setIsLoading(false)

      return
    },
    [updateCompanyInfo],
  )

  const initialValues = useMemo(
    () => ({
      fullLegalName: dueDiligenceCompanyInfo.fullLegalName || '',
      employeeCount: dueDiligenceCompanyInfo.employeeCount || null,
      address: dueDiligenceCompanyInfo.billingCountry
        ? {
            label: `${dueDiligenceCompanyInfo.billingStreet}, ${dueDiligenceCompanyInfo.billingCity}, ${dueDiligenceCompanyInfo.billingState} ${dueDiligenceCompanyInfo.billingPostalCode}, ${dueDiligenceCompanyInfo.billingCountry}`,
          }
        : null,
      billingAddress: {
        country: dueDiligenceCompanyInfo.billingCountry,
        state: dueDiligenceCompanyInfo.billingState,
        city: dueDiligenceCompanyInfo.billingCity,
        street: dueDiligenceCompanyInfo.billingStreet,
        postalCode: dueDiligenceCompanyInfo.billingPostalCode,
      },
      establishedDate: dueDiligenceCompanyInfo.establishedDate || null,
      phone: dueDiligenceCompanyInfo.phone || null,
      stateOfFormation: dueDiligenceCompanyInfo.stateOfFormation || null,
      federalTaxId: dueDiligenceCompanyInfo.federalTaxId || null,
      isWithAllTaxes: (dueDiligenceCompanyInfo.isWithAllTaxes || false).toString(),
      isWithAllTaxesComment: dueDiligenceCompanyInfo.isWithAllTaxesComment || null,
      isPendingJudgement: (dueDiligenceCompanyInfo.isPendingJudgement || false).toString(),
      isPendingJudgementComment: dueDiligenceCompanyInfo.isPendingJudgementComment || null,
      isAssetsPledged: (dueDiligenceCompanyInfo.isAssetsPledged || false).toString(),
      isAssetsPledgedComment: dueDiligenceCompanyInfo.isAssetsPledgedComment || null,
    }),
    [dueDiligenceCompanyInfo],
  )

  const onBack = useCallback(() => {
    filesNavigationRef.current(() => {
      setStep(COMPANY_BACKGROUND_STEP)
    }, 'back')
  }, [])

  const onNext = useCallback(async () => {
    if (step === COMPANY_BACKGROUND_STEP) {
      await formRef.current?.submit()
      setStep(DOCUMENTATION_STEP)
    } else {
      filesNavigationRef.current(handleCloseModal)
    }
  }, [step, handleCloseModal])

  const header = useMemo(
    () =>
      step === COMPANY_BACKGROUND_STEP
        ? 'Tell us more about your company'
        : 'Upload docs to help us with diligence',
    [step],
  )

  const steps = useMemo(() => {
    const documentRequests =
      dueDiligenceDocumentRequests?.data?.data?.filter(
        ({ type }) => type.step === DueDiligenceDocumentRequestStep.CompanyBackground,
      ) || []

    return [
      {
        title: 'Company Background',
        isActive:
          !!dueDiligenceCompanyInfo.fullLegalName ||
          !!dueDiligenceCompanyInfo.employeeCount?.toString() ||
          !!dueDiligenceCompanyInfo.establishedDate ||
          !!dueDiligenceCompanyInfo.phone ||
          !!dueDiligenceCompanyInfo.stateOfFormation ||
          dueDiligenceCompanyInfo.isWithAllTaxes ||
          !!dueDiligenceCompanyInfo.isWithAllTaxesComment ||
          dueDiligenceCompanyInfo.isPendingJudgement ||
          !!dueDiligenceCompanyInfo.isPendingJudgementComment ||
          dueDiligenceCompanyInfo.isAssetsPledged ||
          !!dueDiligenceCompanyInfo.isAssetsPledgedComment ||
          !!dueDiligenceCompanyInfo.federalTaxId ||
          !!dueDiligenceCompanyInfo.billingPostalCode ||
          !!dueDiligenceCompanyInfo.billingState ||
          !!dueDiligenceCompanyInfo.billingCity ||
          !!dueDiligenceCompanyInfo.billingCountry ||
          !!dueDiligenceCompanyInfo.billingStreet,
        isCompleted:
          !!dueDiligenceCompanyInfo.fullLegalName &&
          !!dueDiligenceCompanyInfo.employeeCount?.toString() &&
          !!dueDiligenceCompanyInfo.establishedDate &&
          !!dueDiligenceCompanyInfo.phone &&
          !!dueDiligenceCompanyInfo.stateOfFormation &&
          (dueDiligenceCompanyInfo.isWithAllTaxes === true ||
            (dueDiligenceCompanyInfo.isWithAllTaxes === false &&
              dueDiligenceCompanyInfo.isWithAllTaxesComment)) &&
          (dueDiligenceCompanyInfo.isPendingJudgement === false ||
            (dueDiligenceCompanyInfo.isPendingJudgement === true &&
              dueDiligenceCompanyInfo.isPendingJudgementComment)) &&
          (dueDiligenceCompanyInfo.isAssetsPledged === false ||
            (dueDiligenceCompanyInfo.isAssetsPledged === true &&
              dueDiligenceCompanyInfo.isAssetsPledgedComment)) &&
          !!dueDiligenceCompanyInfo.federalTaxId &&
          (!!dueDiligenceCompanyInfo.billingPostalCode ||
            !!dueDiligenceCompanyInfo.billingState ||
            !!dueDiligenceCompanyInfo.billingCity ||
            !!dueDiligenceCompanyInfo.billingCountry ||
            !!dueDiligenceCompanyInfo.billingStreet),
      },
      {
        title: 'Documentation',
        isActive:
          documentRequests.length > 0 && documentRequests.some(({ files }) => files.length > 0),
        isCompleted:
          documentRequests.length > 0 && documentRequests.every(({ files }) => files.length > 0),
      },
    ]
  }, [dueDiligenceCompanyInfo, dueDiligenceDocumentRequests])

  return (
    <DueDiligenceFullscreenModal
      step={step}
      setStep={handleSetStep}
      steps={steps}
      progress={progress}
      header={header}
      onCloseModal={handleCloseModal}
      onNext={onNext}
      onBack={onBack}
      isDisabled={step === COMPANY_BACKGROUND_STEP && isFormInvalid}
      isLoading={isLoading}
      isSaving={dueDiligenceDocumentRequests.isSaving}
      isSaved={dueDiligenceDocumentRequests.isSaved}
    >
      {step === COMPANY_BACKGROUND_STEP && (
        <Form
          initialValues={initialValues}
          onSubmit={handleSaveActive}
          validate={validate}
          mutators={mutators}
          render={(props) => (
            <DueDiligenceCompanyBackgroundFormRender
              formRef={formRef}
              onChange={handleFormChange}
              setIsFormInvalid={setIsFormInvalid}
              {...props}
            />
          )}
        />
      )}

      {step === DOCUMENTATION_STEP && (
        <DueDiligenceClientStepFiles
          step={DueDiligenceDocumentRequestStep.CompanyBackground}
          navigationRef={filesNavigationRef}
        />
      )}
    </DueDiligenceFullscreenModal>
  )
}

export default DueDiligenceCompanyBackgroundModal
