import React, { useCallback } from 'react'
import Tooltip from '@mui/material/Tooltip'
import cn from 'classnames'

import genericSs from '@styles/generic.module.scss'

import { ICheckAccount } from '@common/interfaces/collection'
import TableRow from '../Common/TableRow'
import TableCell from '../Common/TableCell'
import CreatableSelectField from '../Common/CreatableSelectField'
import { formatDate, formatDateTime } from '../../helpers/helpers'

interface IProps {
  index: number
  account: ICheckAccount
  isActiveRow: boolean
  isCurrentActiveRow: boolean
  onSelectRow: (event: any, index: number) => void
  loadEntitiesOptions: (inputValue: string) => Promise<any>
  handleAddEntityOption: (mapping: string | string[]) => Promise<void>
  onLinkedNameChange: (index: number, value: any) => void
}

const EntityAccountMappingFormRow = ({
  index,
  account,
  isActiveRow,
  isCurrentActiveRow,
  onSelectRow,
  loadEntitiesOptions,
  handleAddEntityOption,
  onLinkedNameChange,
}: IProps) => {
  const handleSelectRow = useCallback((event) => onSelectRow(event, index), [index, onSelectRow])

  const handleLinkedNameChange = useCallback(
    (event, newValue: any) => {
      onLinkedNameChange(index, newValue?.value || null)
    },
    [index, onLinkedNameChange],
  )

  if (!account) {
    return null
  }

  return (
    <TableRow
      id={`mapping-table-row-${index}`}
      data-index={index}
      className={cn('activableRow', {
        activeRow: isActiveRow,
        currentActiveRow: isCurrentActiveRow,
      })}
      onClick={handleSelectRow}
    >
      <TableCell className={genericSs.tableTextLeft}>
        <Tooltip title={account.accountNumber} placement="top" disableTouchListener>
          <span>{account.accountNumber}</span>
        </Tooltip>
      </TableCell>
      <TableCell className={genericSs.tableTextRight}>
        {account.lastSeenDate && (
          <Tooltip
            title={formatDateTime(account.lastSeenDate)}
            placement="top"
            disableTouchListener
          >
            <span>{formatDate(account.lastSeenDate)}</span>
          </Tooltip>
        )}
      </TableCell>
      <TableCell className={genericSs.tableTextRight}>
        <Tooltip
          title={formatDateTime(account.lastMappedDate)}
          placement="top"
          disableTouchListener
        >
          <span>{formatDate(account.lastMappedDate)}</span>
        </Tooltip>
      </TableCell>
      <TableCell>
        <CreatableSelectField
          label=""
          className="focusableInput"
          tabIndex={index}
          name={`accounts[${index}].linkedName`}
          placeholder="Select entity"
          onAddValue={handleAddEntityOption}
          options={[]}
          isAsync
          loadOptions={loadEntitiesOptions}
          onChangeCustom={handleLinkedNameChange}
          getOptionValue={(option) => option.value}
          clearOnBlur
        />
      </TableCell>
    </TableRow>
  )
}

export default EntityAccountMappingFormRow
