import { connect } from 'react-redux'
import { createSelector, createStructuredSelector } from 'reselect'

import BBCFileSelect from './BBCFileSelect'

import { isLoading as isBBCLoading, bbc } from '../../redux/bbc/selectors'
import { isLoading as isLoadingFile } from '../../redux/file/selectors'
import { deleteFile } from '../../redux/file/actions'
import {
  getBBCFiles,
  uploadBBCFiles,
  submitBBCFiles,
  downloadTemplate,
} from '../../redux/bbc/actions'

const selector = createStructuredSelector({
  isLoading: createSelector(
    isBBCLoading,
    isLoadingFile,
    (bbcLoading, fileLoading) => bbcLoading || fileLoading,
  ),
  bbc,
})
const actions = {
  getBBCFiles,
  uploadBBCFiles,
  submitBBCFiles,
  downloadTemplate,
  deleteFile,
}

export default connect(selector, actions)(BBCFileSelect)
