import React, { useMemo, useState, useCallback } from 'react'
import Box from '@mui/material/Box'
import Card from '../../components/Common/Card'
import EntitySettingsHeader from '../../components/EntitySettingsHeader'
import { IEntityInfo } from '@common/interfaces/entityInfo'
import Grid from '@mui/material/Grid'
import TableContainer from '../../components/Common/TableContainer/TableContainer'
import Table from '../../components/Common/Table/Table'
import TableHead from '../../components/Common/TableHead/TableHead'
import TableRow from '../../components/Common/TableRow/TableRow'
import TableCell from '../../components/Common/TableCell/TableCell'
import TableBody from '../../components/Common/TableBody/TableBody'
import IconButton from '../../components/Common/IconButton/IconButton'
import { ReactComponent as DeleteIcon } from '../../assets/images/delete-icon.svg'
import genericSs from '@styles/generic.module.scss'
import styles from './EnititySettingsRelatedEntitiesPage.module.scss'
import { useParams } from 'react-router'
import { generatePath } from 'react-router-dom'
import { ROUTES } from '../../constants/routes'
import { Link } from 'react-router-dom'
import TableLoader from '../../components/Common/TableLoader/TableLoader'
import { Skeleton } from '@mui/material'
import AddButton from '../../components/Client/AddButton/AddButton'
import Modal from '../../components/Common/Modal'
import Autocomplete from '../../components/Common/Autocomplete'
import { Form } from 'react-final-form'
import Button from '../../components/Common/Button'
import WarningModal from '../../components/WarningModal'
import SaveState from '../../components/Common/SaveState'
import { ReactComponent as EditIcon } from '@assets/images/edit-outlined-icon.svg'

interface IListEntityProps {
  entity: IEntityInfo
  listEntityInfo: (data?: object) => Promise<{ data: IEntityInfo[] }>
  onSubmit: (data: object) => Promise<any>
  handleCancelEdit: () => void
  handleDeleteParent?: (id: string) => void
}

const ListEntityModal = ({
  entity,
  listEntityInfo,
  onSubmit,
  handleCancelEdit,
  handleDeleteParent,
}: IListEntityProps) => {
  const subsidiaryIds = useMemo(() => {
    return entity.subsidiaries?.map(({ id }) => id) || []
  }, [entity.subsidiaries])

  const isParentModal = useMemo(() => !!handleDeleteParent, [handleDeleteParent])

  const title = useMemo(() => {
    return isParentModal ? 'Edit parent' : 'Add subsidiary'
  }, [isParentModal])

  const loadEntities = useCallback(
    async (inputValue: string) => {
      const res = await listEntityInfo({
        name: inputValue,
      })
      const entities = res.data.map(({ name, id }) => ({
        value: id,
        label: name,
      }))

      const filteredEntities = entities.filter(
        ({ value }) =>
          !subsidiaryIds.includes(value) && value !== entity.id && value !== entity.parent?.id,
      )
      return filteredEntities
    },
    [listEntityInfo, subsidiaryIds, entity],
  )

  const initialValues = useMemo(
    () =>
      isParentModal && entity.parent
        ? {
            entity: {
              value: entity.parent?.id,
              label: entity.parent?.name,
            },
          }
        : {},
    [isParentModal, entity],
  )

  const handleSubmit = useCallback(
    async (data) => {
      if (isParentModal && !data.entity?.value) {
        if (entity?.parent?.id) {
          await handleDeleteParent(entity?.parent?.id)
          handleCancelEdit()
        } else {
          handleCancelEdit()
        }
      } else {
        await onSubmit(data)
      }
    },
    [onSubmit, isParentModal, entity, handleDeleteParent, handleCancelEdit],
  )

  return (
    <Modal
      title={
        <div className={styles.modalTitle}>
          <div className={styles.modalTitleText}>{title}</div>
        </div>
      }
      open
      size="small"
      classes={{ body: styles.editEntityNameBody }}
      onCancel={handleCancelEdit}
    >
      <Form
        onSubmit={handleSubmit}
        initialValues={initialValues}
        render={({ handleSubmit }) => (
          <form>
            <Grid container spacing={2} justifyContent={'start'}>
              <Grid item xs={12} lg={12} justifyContent={'start'}>
                <h4 className={styles.fieldName}>Name</h4>
                <Autocomplete
                  label=""
                  name="entity"
                  className={styles.autocompleteField}
                  isAsync
                  loadOptions={loadEntities}
                  options={[]}
                  autoSelect
                  placeholder="Select entity"
                />
              </Grid>
              <Grid item xs={12} lg={12}>
                <Button
                  className={styles.editButton}
                  small={false}
                  key="submit"
                  color="primary"
                  variant="contained"
                  fullWidth
                  onClick={(data) => handleSubmit(data)}
                >
                  Save
                </Button>
              </Grid>
            </Grid>
          </form>
        )}
      />
    </Modal>
  )
}

interface IProps {
  entity: IEntityInfo
  listEntityInfo: (data?: object) => Promise<{ data: IEntityInfo[] }>
  addEntityRelationship: (id: string, parentId: string) => Promise<any>
  deleteEntityRelationship: (id: string, parentId: string) => Promise<any>
}

const EnititySettingsRelatedEntities = ({
  entity,
  listEntityInfo,
  addEntityRelationship,
  deleteEntityRelationship,
}: IProps) => {
  const { id: entityId } = useParams<{ id?: string }>()
  const [isAddSubsidiaryModalOpen, setIsAddSubsidiaryModalOpen] = useState(false)
  const [isAddParentModalOpen, setIsAddParentModalOpen] = useState(false)
  const [isDeleteSubsidiaryModalOpen, setIsDeleteSubsidiaryModalOpen] = useState(false)
  const [currentSubsidiaryId, setCurrentSubsidiaryId] = useState<string | null>(null)
  const [isDeleteParentModalOpen, setIsDeleteParentModalOpen] = useState(false)
  const [currentParentId, setCurrentParentId] = useState<string | null>(null)
  const [isSaving, setIsSaving] = useState(false)
  const [isSaved, setIsSaved] = useState(false)

  const parent = useMemo(() => entity?.parent, [entity])
  const subsidiaries = useMemo(() => entity?.subsidiaries, [entity])

  const isLoading = useMemo(
    () => !entityId || !entity || entity.id !== entityId,
    [entityId, entity],
  )

  const handleCloseAddSubsidiaryModal = useCallback(() => {
    setIsAddSubsidiaryModalOpen(false)
  }, [])

  const handleOpenSubsidiaryModal = useCallback(() => {
    setIsAddSubsidiaryModalOpen(true)
  }, [])

  const handleAddSubsidiary = useCallback(
    async (data) => {
      setIsSaving(true)
      await addEntityRelationship(data.entity.value, entityId)
      handleCloseAddSubsidiaryModal()
      setIsSaving(false)
      setIsSaved(true)
      setTimeout(() => {
        setIsSaved(false)
      }, 5000)
    },
    [entityId, addEntityRelationship, handleCloseAddSubsidiaryModal],
  )
  const handleOpenParentModal = useCallback(() => {
    setIsAddParentModalOpen(true)
  }, [])

  const handleCloseAddParentModal = useCallback(() => {
    setIsAddParentModalOpen(false)
  }, [])

  const handleAddParent = useCallback(
    async (data) => {
      setIsSaving(true)
      await addEntityRelationship(entityId, data.entity.value)
      handleCloseAddParentModal()
      setIsSaving(false)
      setIsSaved(true)
      setTimeout(() => {
        setIsSaved(false)
      }, 5000)
    },
    [entityId, addEntityRelationship, handleCloseAddParentModal],
  )

  const handleDeleteSubsidiary = useCallback((id: string) => {
    setCurrentSubsidiaryId(id)
    setIsDeleteSubsidiaryModalOpen(true)
  }, [])

  const handleCloseDeleteSubsidiaryModal = useCallback(() => {
    setIsDeleteSubsidiaryModalOpen(false)
    setCurrentSubsidiaryId(null)
  }, [])

  const handleConfirmDeleteSubsidiary = useCallback(async () => {
    setIsSaving(true)
    await deleteEntityRelationship(currentSubsidiaryId, entityId)
    setCurrentSubsidiaryId(null)
    handleCloseDeleteSubsidiaryModal()
    setIsSaving(false)
    setIsSaved(true)
    setTimeout(() => {
      setIsSaved(false)
    }, 50000)
  }, [entityId, deleteEntityRelationship, currentSubsidiaryId, handleCloseDeleteSubsidiaryModal])

  const handleDeleteParent = useCallback((id: string) => {
    setCurrentParentId(id)
    setIsDeleteParentModalOpen(true)
  }, [])

  const handleCloseDeleteParentModal = useCallback(() => {
    setIsDeleteParentModalOpen(false)
    setCurrentParentId(null)
  }, [])

  const handleConfirmDeleteParent = useCallback(async () => {
    setIsSaving(true)
    await deleteEntityRelationship(entityId, currentParentId)
    setCurrentParentId(null)
    handleCloseDeleteParentModal()
    setIsSaving(false)
    setIsSaved(true)
    setTimeout(() => {
      setIsSaved(false)
    }, 50000)
  }, [entityId, deleteEntityRelationship, currentParentId, handleCloseDeleteParentModal])

  return (
    <Box py={1} pr={2}>
      <EntitySettingsHeader />
      <Grid container spacing={6}>
        <Grid item xs={6} lg={4}>
          <Card
            title={
              <Grid container justifyContent={'space-between'} alignItems={'center'}>
                <Grid item>Related entities</Grid>
                <Grid item>
                  <AddButton variant="outlined" onClick={handleOpenSubsidiaryModal}></AddButton>
                </Grid>
              </Grid>
            }
          >
            <Grid container spacing={2} rowSpacing={2}>
              <Grid item container xs={12} alignItems={'center'} justifyContent={'space-between'}>
                <div className={styles.parentTitle}>Parent:</div>
                <div className={styles.parentName}>
                  {isLoading ? (
                    <Skeleton width={100} />
                  ) : parent ? (
                    <>
                      <Link
                        to={generatePath(ROUTES.ENTITY_PAGE, { id: parent?.id })}
                        className={styles.link}
                      >
                        {' '}
                        {parent?.name}
                      </Link>
                      <IconButton
                        disableFocusRipple
                        disableRipple
                        className={styles.editIcon}
                        onClick={handleOpenParentModal}
                      >
                        <EditIcon />
                      </IconButton>
                    </>
                  ) : (
                    <div className={styles.addParent} onClick={handleOpenParentModal}>
                      Add parent
                    </div>
                  )}
                </div>
              </Grid>
              <Grid item xs={12}>
                <TableContainer className={styles.table}>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell className={genericSs.tableTextLeft}>Subsidiary</TableCell>
                        <TableCell className={genericSs.tableTextLeft}>Action</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {isLoading ? (
                        <TableLoader columnsCount={2} rowsCount={5} />
                      ) : (
                        subsidiaries?.map((subsidiary, index) => (
                          <TableRow key={index}>
                            <TableCell className={genericSs.tableTextLeft}>
                              <Link
                                to={generatePath(ROUTES.ENTITY_PAGE, { id: subsidiary?.id })}
                                className={styles.link}
                              >
                                {subsidiary?.name}
                              </Link>
                            </TableCell>
                            <TableCell className={genericSs.tableTextCenter}>
                              <IconButton
                                onClick={() => {
                                  handleDeleteSubsidiary(subsidiary?.id)
                                }}
                              >
                                <DeleteIcon className={styles.iconRoot} />
                              </IconButton>
                            </TableCell>
                          </TableRow>
                        ))
                      )}
                    </TableBody>
                  </Table>
                  <Box display="flex" alignItems="center" justifyContent="right">
                    <SaveState isSaving={isSaving} isSaved={isSaved} />
                  </Box>
                </TableContainer>
                {isAddSubsidiaryModalOpen && (
                  <ListEntityModal
                    entity={entity}
                    listEntityInfo={listEntityInfo}
                    onSubmit={handleAddSubsidiary}
                    handleCancelEdit={handleCloseAddSubsidiaryModal}
                  />
                )}
                {isAddParentModalOpen && (
                  <ListEntityModal
                    entity={entity}
                    listEntityInfo={listEntityInfo}
                    onSubmit={handleAddParent}
                    handleCancelEdit={handleCloseAddParentModal}
                    handleDeleteParent={handleDeleteParent}
                  />
                )}
                {isDeleteSubsidiaryModalOpen && (
                  <WarningModal
                    title="Delete relationship"
                    warningMessage="Are you sure you want to remove this subsidiary?"
                    onCancel={() => setIsDeleteSubsidiaryModalOpen(false)}
                    onConfirm={handleConfirmDeleteSubsidiary}
                    confirmText="Delete"
                    cancelText="Cancel"
                  />
                )}
                {isDeleteParentModalOpen && (
                  <WarningModal
                    title="Delete relationship"
                    warningMessage="Are you sure you want to remove this parent?"
                    onCancel={() => setIsDeleteParentModalOpen(false)}
                    onConfirm={handleConfirmDeleteParent}
                    confirmText="Delete"
                    cancelText="Cancel"
                  />
                )}
              </Grid>
            </Grid>
          </Card>
        </Grid>
      </Grid>
    </Box>
  )
}

export default EnititySettingsRelatedEntities
