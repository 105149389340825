import React, { Dispatch, SetStateAction, useCallback, useMemo, useState } from 'react'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import moment from 'moment'

import styles from './../ClientLoanLedger.module.scss'

import { IParticipation, IParticipationWire } from '@common/interfaces/participant'
import WarningModal from '../../../WarningModal'
import { MenuIcon } from '../../../Common/Icons'
import CreateAndEditParticipationWireModal from '../CreateParticipationWireModal'

interface IParticipationActivityMenuProps {
  clientId: string
  participationWire: IParticipationWire
  deleteParticipationWire: (
    id: string,
    participationId: string,
    participationWireId: string,
  ) => void
  updateParticipationWire: (
    id: string,
    participationId: string,
    participationWireId: string,
    data: object,
  ) => void
  loanBalanceStartDate: string
  effectiveParticipations: IParticipation[]
  setRefreshCounter?: Dispatch<SetStateAction<number>>
  withEdit?: boolean
}

export const ParticipationActivityMenu = ({
  clientId,
  participationWire,
  deleteParticipationWire,
  updateParticipationWire,
  loanBalanceStartDate,
  effectiveParticipations,
  setRefreshCounter,
  withEdit = true,
}: IParticipationActivityMenuProps) => {
  const [anchorEl, setAnchorEl] = useState(null)
  const [isMenuOpen, setIsMenuOpen] = useState(false)
  const [isEditModalOpen, setIsEditModalOpen] = useState(false)
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false)

  const handleClickMenu = useCallback((event: React.MouseEvent) => {
    setAnchorEl(event.currentTarget)
    setIsMenuOpen(true)
  }, [])

  const handleCloseMenu = useCallback(() => {
    setAnchorEl(null)
    setIsMenuOpen(false)
  }, [])

  const handleUpdate = useCallback(() => {
    handleCloseMenu()
    setIsEditModalOpen(true)
  }, [handleCloseMenu])

  const handleDelete = useCallback(() => {
    handleCloseMenu()
    setIsDeleteModalOpen(true)
  }, [handleCloseMenu])

  const handleDeleteConfirm = useCallback(async () => {
    setIsDeleteModalOpen(false)
    await deleteParticipationWire(clientId, participationWire.participationId, participationWire.id)
    setRefreshCounter && setRefreshCounter((prev) => prev + 1)
  }, [deleteParticipationWire, clientId, participationWire, setRefreshCounter])

  const handleCancel = useCallback(() => {
    setIsDeleteModalOpen(false)
    setIsEditModalOpen(false)
  }, [])

  const wireInLockedMonth = useMemo(
    () => moment(participationWire.recordDate).isBefore(loanBalanceStartDate),
    [participationWire, loanBalanceStartDate],
  )

  if (wireInLockedMonth) {
    return null
  }

  return (
    <>
      <MenuIcon isActive={isMenuOpen} onClick={handleClickMenu} size="small" />
      <Menu
        classes={{ paper: styles.iconMenu }}
        open={isMenuOpen}
        onClose={handleCloseMenu}
        anchorEl={anchorEl}
      >
        {withEdit && (
          <MenuItem classes={{ root: styles.iconMenuItem }} onClick={handleUpdate}>
            Edit
          </MenuItem>
        )}
        <MenuItem classes={{ root: styles.iconMenuItem }} onClick={handleDelete}>
          Delete
        </MenuItem>
      </Menu>
      {isEditModalOpen && (
        <CreateAndEditParticipationWireModal
          clientId={clientId}
          participationWire={participationWire}
          handleCloseModal={handleCancel}
          updateParticipationWire={updateParticipationWire}
          loanBalanceStartDate={loanBalanceStartDate}
          effectiveParticipations={effectiveParticipations}
        />
      )}
      {isDeleteModalOpen && (
        <WarningModal
          warningMessage="Are you sure you want to delete this wire? This can not be undone."
          onConfirm={handleDeleteConfirm}
          onCancel={handleCancel}
          confirmText="Delete"
          cancelText="Cancel"
        />
      )}
    </>
  )
}

export default ParticipationActivityMenu
