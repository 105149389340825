import { useCallback, useState, useMemo, useEffect } from 'react'
import { useHistory, useLocation } from 'react-router'
import queryString from 'query-string'
import isDate from 'lodash/isDate'

import {
  dateToString,
  handleMultipleActivableSelect,
  handleMultipleCellSelect,
  handleMultipleSelect,
} from '../helpers/helpers'

interface IProps {
  tableId: string
  filtersDefaults?: any
  sortDefault?: {
    field: string
    direction: 'ASC' | 'DESC' | ''
  }
  quickFilterDefault?: string | null
}

const useTable = ({ tableId, filtersDefaults, sortDefault, quickFilterDefault }: IProps) => {
  const filterParamName = useMemo(() => `${tableId}Filters`, [tableId])
  const quickFilterParamName = useMemo(() => `${tableId}QuickFilter`, [tableId])
  const history = useHistory()
  const { search }: { search: string } = useLocation()
  const {
    searchFilters,
    searchQuickFilter,
    restSearch,
    isFilterParamNameInSearch,
    isQuickFilterParamNameInSearch,
  } = useMemo(() => {
    const {
      [filterParamName]: searchFilters,
      [quickFilterParamName]: searchQuickFilter,
      ...restSearch
    } = queryString.parse(search)

    const isFilterParamNameInSearch = searchFilters !== undefined
    const isQuickFilterParamNameInSearch = searchQuickFilter !== undefined

    return {
      searchFilters,
      searchQuickFilter,
      restSearch,
      isFilterParamNameInSearch,
      isQuickFilterParamNameInSearch,
    }
  }, [search, filterParamName, quickFilterParamName])

  const [orderBy, setOrderBy] = useState(sortDefault)

  const [activeItem, setActiveItem] = useState(null)
  const [activeItems, setActiveItems] = useState([])
  const handleSelectRow = useMemo(() => handleMultipleSelect(setActiveItems, setActiveItem), [])

  const [activeCells, setActiveCells] = useState<number[][]>([])
  const [activeRows, setActiveRows] = useState<number[]>([])
  const [activeColumns, setActiveColumns] = useState<number[]>([])

  const resetMultiCellsSelection = useCallback(() => {
    setActiveCells([])
  }, [])
  const resetMultiRowsSelection = useCallback(() => {
    setActiveRows([])
    setActiveColumns([])
  }, [])
  const handleSelectCell = useMemo(
    () => handleMultipleCellSelect(setActiveCells, resetMultiRowsSelection),
    [resetMultiRowsSelection],
  )
  const handleSelectActiveRow = useMemo(
    () => handleMultipleActivableSelect(setActiveRows, resetMultiCellsSelection),
    [resetMultiCellsSelection],
  )
  const handleSelectActiveColumn = useMemo(
    () =>
      handleMultipleActivableSelect(setActiveColumns, resetMultiCellsSelection, 'activableColumn'),
    [resetMultiCellsSelection],
  )

  const handleOrderChange = useCallback((field: string, direction?: string) => {
    setOrderBy((order) => ({
      field,
      direction:
        direction || order.field === field ? (order.direction === 'DESC' ? 'ASC' : 'DESC') : 'ASC',
    }))
  }, [])

  const handleFiltersChange = useCallback(
    (values: any) => {
      history.replace({
        search: queryString.stringify({
          ...restSearch,
          [filterParamName]: queryString.stringify(
            Object.fromEntries(
              Object.entries(values)
                .filter(([_, value]) =>
                  Array.isArray(value) ? value.length > 0 : value !== null && value !== undefined,
                )
                .map(([key, value]) => {
                  if (isDate(value)) {
                    return [key, dateToString(value)]
                  }

                  return [key, value]
                }),
            ),
            { arrayFormat: 'index' },
          ),
          [quickFilterParamName]: searchQuickFilter,
        }),
      })
    },
    [restSearch, history, filterParamName, quickFilterParamName, searchQuickFilter],
  )

  const handleQuickFilterChange = useCallback(
    (quickFilter: string | null) => {
      history.replace({
        search: queryString.stringify({
          ...restSearch,
          [filterParamName]: searchFilters,
          [quickFilterParamName]: quickFilter,
        }),
      })
    },
    [restSearch, history, filterParamName, quickFilterParamName, searchFilters],
  )

  const parsedSearchFilters = useMemo(
    () =>
      queryString.parse(searchFilters as string, {
        parseNumbers: true,
        arrayFormat: 'index',
      }),
    [searchFilters],
  )

  useEffect(() => {
    if (!!quickFilterDefault && !isQuickFilterParamNameInSearch) {
      handleQuickFilterChange(quickFilterDefault)
    }
    if (!!filtersDefaults && !isFilterParamNameInSearch) {
      handleFiltersChange(filtersDefaults)
    }
  }, [
    isQuickFilterParamNameInSearch,
    isFilterParamNameInSearch,
    handleQuickFilterChange,
    handleFiltersChange,
    quickFilterDefault,
    filtersDefaults,
  ])

  const resetActiveItems = useCallback(() => setActiveItems([]), [])
  const resetActiveCells = useCallback(() => {
    setActiveCells([])
    setActiveRows([])
    setActiveColumns([])
  }, [])

  return {
    filters: parsedSearchFilters as any,
    quickFilter: searchQuickFilter as string,
    handleQuickFilterChange,
    handleFiltersChange,
    handleOrderChange,
    orderBy,
    activeItem,
    activeItems,
    handleSelectRow,
    setActiveItem,
    setActiveItems,
    resetActiveItems,
    activeCells,
    activeRows,
    activeColumns,
    handleSelectCell,
    handleSelectActiveRow,
    handleSelectActiveColumn,
    resetActiveCells,
  }
}

export default useTable
