import Base from './base'
import { IUser } from '@common/interfaces/user'

export default class Users extends Base {
  list(query?: object) {
    return this.apiClient.get('users', query)
  }

  show(id: string) {
    return this.apiClient.get(`users/${id}`)
  }

  create(data: Partial<IUser & { clientId?: string }>) {
    return this.apiClient.post('users', data)
  }

  update(id: string, data: Partial<IUser & { clientId?: string }>) {
    return this.apiClient.put(`users/${id}`, data)
  }

  delete(id: string) {
    return this.apiClient.delete(`users/${id}`)
  }

  invite(id: string) {
    return this.apiClient.post(`users/${id}/invite`)
  }

  track_link(params?: object) {
    return this.apiClient.post('users/track_link', params)
  }
}
