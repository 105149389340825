import { IClientInfo } from './client'

export enum ClientEntityRelationshipType {
  LawFirm = 'lawFirm',
  OutsourcedCFO = 'outsourcedCFO',
  AccountingFirm = 'accountingFirm',
  DistributionServices3PL = 'distributionServices3PL',
}

export const SALESFORCE_ACCOUNT_TYPES_MAPPING = {
  [ClientEntityRelationshipType.LawFirm]: {
    type: 'Legal/Compliance',
    subType: 'Attorney (Growth)',
  },
  [ClientEntityRelationshipType.OutsourcedCFO]: {
    type: 'Consultant',
    subType: 'Outsourced CFO',
  },
  [ClientEntityRelationshipType.AccountingFirm]: {
    type: 'Consultant',
    subType: 'Accountant',
  },
  [ClientEntityRelationshipType.DistributionServices3PL]: {
    type: 'Distribution Services',
    subType: '3PL/Fulfillment',
  },
}

export interface ISalesforceAccount {
  id: string
  salesforceId: string
  name: string
  type: string
  subType: string
}

export interface IClientEntityRelationship {
  id: string
  clientName: string
  clientInfo: IClientInfo
  salesforceAccountId: string | null
  salesforceAccount: ISalesforceAccount | null
  newSalesforceAccount: string | null
  type: ClientEntityRelationshipType
  fullName: string | null
  phone: string | null
  email: string | null
  salesforceContactId: string | null
  isNewSalesforceContact: boolean
}
