import React, { useMemo } from 'react'
import { IFlags, IBBCFlagDetails } from '@common/interfaces/bbc'
import TableCell from '../Common/TableCell'
import genericSs from '@styles/generic.module.scss'
import { formatDate, formatPrice, formatNumber } from '../../helpers/helpers'
import Tooltip from '@mui/material/Tooltip'
import { DETAILED_BBC_FLAGS_MAPPING, BBC_FLAG_TYPES } from '@common/interfaces/bbc'
import { DETAILED_FLAG_FILTER_MAPPING } from './constants'
import cn from 'classnames'

const formatCell = (value: string, type: string) => {
  if (type === 'amount') {
    return `$${formatPrice(value)}`
  } else if (type === 'date') {
    return formatDate(value)
  } else if (type === 'number') {
    return formatNumber(value) || 0
  }
  return value
}

interface IPropsFlagDetailTable {
  flag: IBBCFlagDetails
  currentFlag: IFlags | null
  large?: boolean
}

const FlagDetailTable = ({ flag, currentFlag, large }: IPropsFlagDetailTable) => {
  const flagConfig = useMemo(() => {
    const flagMapping = DETAILED_BBC_FLAGS_MAPPING[currentFlag?.flagName]
    const flagReferences = BBC_FLAG_TYPES[flagMapping?.sourceData]
    const flagTable = flagReferences?.tablePrevious || flagReferences?.tableCurrent

    return {
      flagMapping,
      flagReferences,
      flagTable,
    }
  }, [currentFlag])

  const smallTableColumns = useMemo(() => {
    return large
      ? []
      : DETAILED_FLAG_FILTER_MAPPING[currentFlag?.flagName]?.filters.map(
          ({ field, type }: { field: string; type: string }) => ({ field, type }),
        )
  }, [currentFlag, large])

  return (
    <>
      {large ? (
        <>
          <TableCell className={genericSs.tableTextLeft}>
            {flag?.[flagConfig?.flagTable]?.[flagConfig?.flagReferences?.labelDbField]}
          </TableCell>
          <TableCell className={genericSs.tableTextLeft}>
            {flag?.[flagConfig?.flagTable]?.[flagConfig?.flagReferences?.referenceDbField]}
          </TableCell>
          <TableCell className={genericSs.tableTextRight}>
            {formatDate(
              flag?.[flagConfig?.flagReferences?.tablePrevious]?.[
                flagConfig?.flagReferences?.dateField
              ],
            )}
          </TableCell>
          <TableCell className={genericSs.tableTextRight}>
            {formatCell(
              flag?.[flagConfig?.flagReferences?.tablePrevious]?.[flagConfig?.flagMapping?.field],
              flagConfig?.flagMapping?.type,
            )}
          </TableCell>
          <TableCell className={genericSs.tableTextRight}>
            {formatCell(
              flag?.[flagConfig?.flagReferences?.tableCurrent]?.[flagConfig?.flagMapping?.field],
              flagConfig?.flagMapping?.type,
            )}
          </TableCell>
          <TableCell className={genericSs.tableTextRight}>
            ${formatPrice(flag?.impactToAvailability)}
          </TableCell>
          {flagConfig?.flagMapping?.showMessage && (
            <TableCell className={genericSs.tableTextLeft}>
              <Tooltip title={flag?.message} placement="top" disableTouchListener>
                <span> {flag?.message}</span>
              </Tooltip>
            </TableCell>
          )}
        </>
      ) : (
        <>
          <TableCell className={genericSs.tableTextLeft}>
            {formatCell(flag?.[smallTableColumns?.[0].field], smallTableColumns?.[0].type)}
          </TableCell>
          <TableCell className={genericSs.tableTextRight}>
            {formatCell(flag?.[smallTableColumns?.[1].field], smallTableColumns?.[1].type)}
          </TableCell>
          <TableCell
            className={cn(genericSs.tableTextLeft, {
              [genericSs.tableTextRight]: currentFlag?.flagName === 'pending_cash_dominion',
            })}
          >
            <Tooltip
              title={formatCell(flag?.[smallTableColumns?.[2].field], smallTableColumns?.[2].type)}
              placement="top"
              disableTouchListener
            >
              <span>
                {formatCell(flag?.[smallTableColumns?.[2].field], smallTableColumns?.[2].type)}
              </span>
            </Tooltip>
          </TableCell>
        </>
      )}
    </>
  )
}

export default FlagDetailTable
