import React, { useCallback } from 'react'
import styles from './ItemsToMonitor.module.scss'
import RichTextEditor from '../../Common/RichTextEditor'

interface IProps {
  clientInfo: { id: string; itemsToMonitor: string }
  updateClient: (id: string, data: string) => void
}

const ItemsToMonitor = ({ clientInfo, updateClient }: IProps) => {
  const handleChange = useCallback(
    (value: string) => {
      if (clientInfo) {
        updateClient(clientInfo.id, value)
      }
    },
    [updateClient, clientInfo],
  )

  return (
    <RichTextEditor
      value={clientInfo?.itemsToMonitor || ''}
      className={styles.textEditor}
      handleChange={handleChange}
    />
  )
}

export default ItemsToMonitor
