import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'

import BBCIneligibleARTable from './BBCIneligibleARTable'

import {
  clientCollaterals,
  arIneligibleStats,
  isLoading as isLoadingBBC,
} from '../../redux/bbc/selectors'
import { listARIneligibleStats } from '../../redux/bbc/actions'

const selector = createStructuredSelector({
  isLoadingBBC,
  clientCollaterals,
  data: arIneligibleStats,
})

const actions = {
  listARIneligibleStats,
}

export default connect(selector, actions)(BBCIneligibleARTable)
