import React from 'react'
import Skeleton from '@mui/material/Skeleton'

import styles from './BBCLogsItem.module.scss'

const BBCLogsItemLoader = () => {
  return (
    <div className={styles.item}>
      <div className={styles.itemHeading}>
        <Skeleton width={75} height={24} />
      </div>
      <div className={styles.itemTitle}>
        <Skeleton width={130} height={24} />
        <Skeleton width={70} height={24} />
      </div>
      <div className={styles.itemData}>
        <Skeleton height={20} />
        <Skeleton height={20} />
        <Skeleton height={20} width={90} />
      </div>
      <div className={styles.itemBottom}>
        <Skeleton width={90} height={12} />
      </div>
    </div>
  )
}

export default BBCLogsItemLoader
