import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'

import UWDashboard from './UWDashboard'

import {
  dueDiligenceStats,
  prospects,
  dueDiligenceAggregation,
} from '../../redux/dueDiligence/selectors'
import { getStats, listProspects, list } from '../../redux/dueDiligence/actions'

import { user, isUW } from '../../redux/profile/selectors'

const selector = createStructuredSelector({
  isUW,
  dueDiligenceStats,
  prospects,
  dueDiligenceAggregationData: dueDiligenceAggregation,
  user,
})
const actions = {
  getStats,
  listProspects,
  list,
}

export default connect(selector, actions)(UWDashboard)
