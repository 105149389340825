import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'

import { clientInfo } from '../../redux/clientInfo/selectors'
import { pathname } from '../../redux/router/selectors'
import ClientSetupPage from './ClientSetupPage'

const selector = createStructuredSelector({
  clientInfo,
  pathname,
})

const actions = {}

export default connect(selector, actions)(ClientSetupPage)
