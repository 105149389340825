import React, { ReactNode } from 'react'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import cn from 'classnames'

import styles from './WarningModal.module.scss'
import genericSs from '@styles/generic.module.scss'

import { ReactComponent as ActionPendingIcon } from '@assets/images/action-pending.svg'
import Modal from './../Common/Modal'
import Button from '../Common/Button'
import { handleStopPropagation } from '../../helpers/helpers'

interface Props {
  title?: string | ReactNode
  warningMessage: string
  onConfirm?: () => void
  confirmText?: string
  disabled?: boolean
  onClose?: () => void
  onCancel: () => void
  cancelText?: string
  isLoading?: boolean
  isLoadingCancel?: boolean
  inputComponent?: React.ReactNode
}

const WarningModal = ({
  title = 'Are you sure?',
  warningMessage,
  onConfirm,
  confirmText = 'Yes',
  disabled,
  onClose,
  onCancel,
  cancelText = 'No',
  isLoading,
  isLoadingCancel,
  inputComponent,
}: Props) => {
  return (
    <Modal open title="" onCancel={onClose || onCancel}>
      <Box
        className={cn(styles.modalBody, { [styles.hideButtons]: !onConfirm })}
        display="flex"
        justifyContent="center"
        alignItems="space-between"
        onClick={handleStopPropagation}
      >
        <Grid container spacing={1} justifyContent={'center'} alignItems={'center'}>
          <Grid
            item
            container
            spacing={1}
            xs={12}
            lg={12}
            justifyContent={'center'}
            alignItems={'center'}
          >
            <Grid item xs={12} lg={12} justifyContent={'center'} display={'flex'}>
              <ActionPendingIcon />
            </Grid>
          </Grid>
          <Grid
            item
            container
            spacing={1}
            xs={12}
            lg={12}
            justifyContent={'center'}
            alignItems={'center'}
          >
            <Grid item xs={12} lg={12}>
              <h2 className={genericSs.textCenter}>{title}</h2>
            </Grid>
            <Grid item xs={12} lg={12} justifyContent={'center'} display={'flex'}>
              <div className={cn(genericSs.textCenter, styles.descriptionTitle)}>
                {warningMessage}
              </div>
            </Grid>
            {inputComponent}
            {onConfirm && (
              <Grid item xs={12} lg={12} justifyContent={'center'} display={'flex'}>
                <Button
                  variant="contained"
                  color="primary"
                  className={styles.button}
                  onClick={onConfirm}
                  disabled={disabled}
                  isLoading={isLoading}
                >
                  {confirmText}
                </Button>
              </Grid>
            )}

            {onCancel && (
              <Grid item xs={12} lg={12} justifyContent={'center'} display={'flex'}>
                <Button
                  onClick={onCancel}
                  variant="outlined"
                  color="primary"
                  className={styles.button}
                  disabled={disabled}
                  isLoading={isLoadingCancel}
                >
                  {cancelText}
                </Button>
              </Grid>
            )}
          </Grid>
        </Grid>
      </Box>
    </Modal>
  )
}
export default WarningModal
