import { combineReducers } from 'redux'
import * as actions from './actions'
import { IUser } from '@common/interfaces/user'
import { IClientInfo } from '@common/interfaces/client'
import { UPDATE_LOAN_BALANCE } from '@common/constants/webSockets'
import { loadingArrayReducer } from '../../helpers/helpers'

export interface IProfileState {
  isInitialized: boolean
  loadingArray: string[]
  user: IUser
  isConfirmed: boolean
  isRecoveryRequested: boolean
  isPasswordSetup: boolean
  invitedUser: IUser
  error: object | null
  isChangedPwd: boolean
  clientInfo: IClientInfo
  isSidebarOpen: boolean
}

export default combineReducers<IProfileState>({
  isInitialized(state: boolean = false, action) {
    switch (action.type) {
      case actions.PROFILE_SUCCESS:
      case actions.PROFILE_FAILURE:
        return true
      default:
        return state
    }
  },
  loadingArray: loadingArrayReducer([actions.prefix]),
  user(state: IUser = null, action) {
    switch (action.type) {
      case actions.PROFILE_SUCCESS:
      case actions.UPDATE_PROFILE_SUCCESS:
      case actions.UPDATE_NOTIFICATION_SETTINGS_SUCCESS:
        return action.data
      case actions.LOGOUT_SUCCESS:
        return null
      default:
        return state
    }
  },
  isConfirmed(state: boolean = false, action) {
    switch (action.type) {
      case actions.CONFIRM_SUCCESS:
        return true
      default:
        return state
    }
  },
  isChangedPwd(state: boolean = false, action) {
    switch (action.type) {
      case actions.RESET_PASSWORD_REQUEST:
      case actions.RESET_PASSWORD_FAILURE:
        return false
      case actions.RESET_PASSWORD_SUCCESS:
        return true
      default:
        return state
    }
  },
  isRecoveryRequested(state: boolean = false, action) {
    switch (action.type) {
      case actions.PASSWORD_RECOVERY_REQUEST:
      case actions.PASSWORD_RECOVERY_FAILURE:
        return false
      case actions.PASSWORD_RECOVERY_SUCCESS:
        return true
      default:
        return state
    }
  },
  isPasswordSetup(state: boolean = false, action) {
    switch (action.type) {
      case actions.PROCESS_INVITATION_SUCCESS:
        return true
      default:
        return state
    }
  },
  invitedUser(state: IUser = null, action) {
    switch (action.type) {
      case actions.PROCESS_INVITATION_SUCCESS:
        return action.data
      default:
        return state
    }
  },
  error(state = null, action) {
    switch (action.type) {
      case actions.LOGIN_FAILURE:
      case actions.LOGOUT_FAILURE:
      case actions.PASSWORD_RECOVERY_FAILURE:
      case actions.RESET_PASSWORD_FAILURE:
        return action.error
      default:
        return state
    }
  },
  clientInfo(state: IClientInfo = null, action) {
    switch (action.type) {
      case actions.CLIENT_INFO_SUCCESS:
      case actions.CLIENT_INFO_RELOAD_SUCCESS:
        return action.data
      case UPDATE_LOAN_BALANCE:
        if (!state || state.id !== action.data.clientId) {
          return state
        }
        return {
          ...state,
          currentAmount: action.data.loanBalance,
        }
      default:
        return state
    }
  },

  isSidebarOpen(state: boolean = false, action) {
    switch (action.type) {
      case actions.TOGGLE_SIDEBAR:
        return !state
      case actions.OPEN_SIDEBAR:
        return true
      default:
        return state
    }
  },
})
