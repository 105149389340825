import { ITreasuryData } from '../../../../common/interfaces/treasury'
import { combineReducers } from 'redux'
import * as actions from './actions'
import { ILoadingData } from '../types'
import { createLoadingDataReducer } from '../../helpers/redux'

export interface ITreasuryState {
  treasuryData: ILoadingData<ITreasuryData>
}

export default combineReducers<ITreasuryState>({
  treasuryData: createLoadingDataReducer<ITreasuryData>(
    [actions.LIST_TREASURY_REQUEST],
    [actions.UPDATE_TREASURY_REQUEST],
  ),
})
