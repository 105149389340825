import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'

import DueDiligenceProcessDocumentsPage from './DueDiligenceProcessDocumentsPage'

import {
  isLoading,
  dueDiligenceInfo,
  dueDiligenceReporting,
} from '../../redux/dueDiligence/selectors'
import { show, showReporting, updateReporting } from '../../redux/dueDiligence/actions'

const selector = createStructuredSelector({
  isLoadingInfo: isLoading,
  dueDiligenceInfo,
  dueDiligenceReportingData: dueDiligenceReporting,
})
const actions = {
  show,
  showReporting,
  updateReporting,
}

export { DueDiligenceProcessDocumentsPage }

export default connect(selector, actions)(DueDiligenceProcessDocumentsPage)
