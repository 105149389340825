import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'

import DueDiligenceProcessDocumentsGeneralLedgerPage from './DueDiligenceProcessDocumentsGeneralLedgerPage'

import { dueDiligenceInfo, aliasMapping } from '../../redux/dueDiligence/selectors'
import { show, listAliasMapping, hideAliasMapping } from '../../redux/dueDiligence/actions'
import { listTypeMapping } from '../../redux/generalLedger/actions'
import { addEntityInfo, listEntityInfo } from '../../redux/entityInfo/actions'
import { typeMapping } from '../../redux/generalLedger/selectors'
import { deleteAlias, updateAlias } from '../../redux/arapmapping/actions'

const selector = createStructuredSelector({
  dueDiligenceInfo,
  aliasesData: aliasMapping,
  typeMapping,
})
const actions = {
  show,
  listTypeMapping,
  listAliasMapping,
  hideAliasMapping,
  listEntityInfo,
  addEntityInfo,
  updateAlias,
  deleteAlias,
}

export default connect(selector, actions)(DueDiligenceProcessDocumentsGeneralLedgerPage)
