import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { generatePath, Link } from 'react-router-dom'
import LinkButton from '@mui/material/Link'
import cn from 'classnames'

import styles from './GlobalSearchPage.module.scss'
import genericSs from '@styles/generic.module.scss'

import { INoteMapping } from '@common/interfaces/notes'
import { REPORTING_DOCUMENT_TYPES } from '@common/constants/client'
import { ROUTES } from '../../constants/routes'
import { formatDate, handleStopPropagation } from '../../helpers/helpers'
import { replaceJSX } from './GlobalSearchPage'

const GlobalSearchPageNote = ({
  note,
  searchString,
}: {
  note: INoteMapping
  searchString: string
}) => {
  const noteTextRef = useRef(null)
  const [isNoteTexEllipsisActive, setIsNoteTexEllipsisActive] = useState(false)
  const [isNoteTextExpanded, setIsNoteTextExpanded] = useState(false)

  const ongoingReportingLink = useMemo(() => {
    if (!note.ongoingReportingId) {
      return null
    }

    if (
      [REPORTING_DOCUMENT_TYPES.financials, REPORTING_DOCUMENT_TYPES.financialProjections].includes(
        note.ongoingReportingType,
      )
    ) {
      return generatePath(ROUTES.ONGOING_REPORTING, { id: note.ongoingReportingId })
    } else if (REPORTING_DOCUMENT_TYPES.salesBySKU === note.ongoingReportingType) {
      return generatePath(ROUTES.ONGOING_REPORTING_SALES_BY_SKU, { id: note.ongoingReportingId })
    } else if (REPORTING_DOCUMENT_TYPES.arGeneralLedger === note.ongoingReportingType) {
      return generatePath(ROUTES.ONGOING_REPORTING_AR_GENERAL_LEDGER, {
        id: note.ongoingReportingId,
      })
    } else if (REPORTING_DOCUMENT_TYPES.bankTransactions === note.ongoingReportingType) {
      return generatePath(ROUTES.BANK_TRANSACTIONS_UPLOAD, { id: note.ongoingReportingId })
    } else if (REPORTING_DOCUMENT_TYPES.capTable === note.ongoingReportingType) {
      return generatePath(ROUTES.CAP_TABLE_UPLOAD, { id: note.ongoingReportingId })
    }

    return null
  }, [note])

  useEffect(() => {
    if (noteTextRef.current) {
      setIsNoteTexEllipsisActive(
        noteTextRef.current.offsetHeight < noteTextRef.current.scrollHeight,
      )
    } else {
      setIsNoteTexEllipsisActive(false)
    }
  }, [note])
  const toggleIsNoteTextExpanded = useCallback((event) => {
    handleStopPropagation(event)
    setIsNoteTextExpanded((isExpanded) => !isExpanded)
  }, [])

  return (
    <div className={styles.noteContainer}>
      <div className={styles.noteTitle}>
        {replaceJSX(note.userFirstName, searchString) || 'System'}
      </div>
      <div className={styles.noteTextWrapper}>
        <div
          className={cn(styles.noteText, { [styles.noteTextExpanded]: isNoteTextExpanded })}
          ref={noteTextRef}
        >
          {replaceJSX(note.noteText, searchString)}
        </div>
      </div>
      <div className={styles.noteFooter}>
        <div className={styles.noteRelations}>
          {note.clientName && (
            <LinkButton
              component={Link}
              to={generatePath(ROUTES.CLIENT_PAGE, { id: note.clientId })}
              className={styles.noteRelation}
              onClick={handleStopPropagation}
            >
              {replaceJSX(note.clientName, searchString)}
            </LinkButton>
          )}
          {note.ongoingReportingId && (
            <LinkButton
              component={Link}
              to={ongoingReportingLink}
              className={styles.noteRelation}
              onClick={handleStopPropagation}
            >
              {replaceJSX(note.ongoingReportingType, searchString)}{' '}
              {replaceJSX(formatDate(note.ongoingReportingRecordDate), searchString)}
            </LinkButton>
          )}
          {note.borrowingBaseId && (
            <LinkButton
              component={Link}
              to={generatePath(ROUTES.BBC_SUMMARY, { id: note.borrowingBaseRecordDate })}
              className={styles.noteRelation}
              onClick={handleStopPropagation}
            >
              BBC {replaceJSX(formatDate(note.borrowingBaseRecordDate), searchString)}
            </LinkButton>
          )}
        </div>
        <div className={styles.noteDate}>
          {replaceJSX(formatDate(note.createdAt), searchString)}
        </div>
      </div>
      {isNoteTexEllipsisActive && (
        <div className={styles.noteTextExpandButton}>
          <div
            className={cn(genericSs.grayCard, styles.noteTextExpandButton)}
            onClick={toggleIsNoteTextExpanded}
          >
            {isNoteTextExpanded ? 'Show less' : 'Show more'}
          </div>
        </div>
      )}
    </div>
  )
}

export default GlobalSearchPageNote
