import React from 'react'
import { ReactComponent as EmptyIcon } from '../../../assets/images/empty-page-icon.svg'
import { Box, Grid } from '@mui/material'

const Empty = ({ message, height = '70vh' }: { message: string; height?: string }) => {
  return (
    <Box display="flex" justifyContent="center" alignItems="center" height={height}>
      <Grid container spacing={2} justifyContent={'center'}>
        <Grid container item xs={12} justifyContent={'center'}>
          <EmptyIcon />
        </Grid>
        <Grid container item xs={12} justifyContent={'center'}>
          <h3>{message}</h3>
        </Grid>
      </Grid>
    </Box>
  )
}

export default Empty
