import React from 'react'
import { generatePath, Link } from 'react-router-dom'
import LinkButton from '@mui/material/Link'

import genericSs from '@styles/generic.module.scss'

import TableRow from '../../components/Common/TableRow'
import TableCell from '../../components/Common/TableCell'
import { ROUTES } from '../../constants/routes'
import { formatPrice } from '../../helpers/helpers'
import { replaceJSX } from './GlobalSearchPage'
import { IParticipant } from '@common/interfaces/participant'

const GlobalSearchPageTableRowParticipant = ({
  participant,
  searchString,
}: {
  participant: IParticipant
  searchString: string
}) => {
  return (
    <TableRow>
      <TableCell className={genericSs.tableTextLeft}>
        <LinkButton
          component={Link}
          to={generatePath(ROUTES.PARTICIPANTS_PAGE, { id: participant.id })}
        >
          {replaceJSX(participant.participant, searchString)}
        </LinkButton>
      </TableCell>
      <TableCell className={genericSs.tableTextRight}>
        <span className={genericSs.pricePrefix}>$</span>
        {replaceJSX(formatPrice(participant.totalLoanExposure), searchString)}
      </TableCell>
      <TableCell className={genericSs.tableTextRight}>
        {participant.totalClients ? replaceJSX(participant.totalClients, searchString) : '-'}
      </TableCell>
    </TableRow>
  )
}

export default GlobalSearchPageTableRowParticipant
