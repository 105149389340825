import React from 'react'
import { DateTimePicker, DateTimePickerProps } from '@mui/x-date-pickers/DateTimePicker'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import TextField from '@mui/material/TextField'
import { Field, FieldProps, FieldRenderProps } from 'react-final-form'
import { showErrorOnChange, ShowErrorFunc } from 'mui-rff'
import cn from 'classnames'
import { parseISO } from 'date-fns'

import styles from './KeyboardDateTimePicker.module.scss'

import { ReactComponent as CalendarIcon } from '../../../assets/images/calendar-icon.svg'

interface KeyboardDateTimePickerProps
  extends Partial<Omit<DateTimePickerProps<any, any>, 'onChange'>> {
  name: string
  placeholder?: string
  fieldProps?: Partial<FieldProps<any, any>>
  showError?: ShowErrorFunc
  isFilterMenu?: boolean
}

interface DatePickerWrapperProps
  extends FieldRenderProps<DateTimePickerProps<any, any>, HTMLElement> {}

const KeyboardDateTimePickerWrapper = (props: DatePickerWrapperProps) => {
  const {
    className,
    placeholder = '',
    input: { name, onChange, value, ...restInput },
    isFilterMenu = false,
    meta,
    showError = showErrorOnChange,
    ...rest
  } = props

  const { error, submitError } = meta
  const isError = showError({ meta })

  const { helperText, ...lessRest } = rest
  const rootClass = isFilterMenu ? styles.filterMenuRoot : styles.root

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <DateTimePicker
        components={{
          OpenPickerIcon: CalendarIcon,
        }}
        renderInput={({ InputProps, ...renderProps }) => (
          <TextField
            name={name}
            className={cn(className, rootClass)}
            {...renderProps}
            {...restInput}
            error={isError}
            InputProps={{
              ...InputProps,
              placeholder,
            }}
            helperText={isError ? error || submitError : helperText}
            variant="standard"
          />
        )}
        onChange={onChange}
        value={
          (value as any) === '' ? null : typeof value === 'string' ? parseISO(value as any) : value
        }
        InputAdornmentProps={{
          position: 'start',
        }}
        {...lessRest}
      />
    </LocalizationProvider>
  )
}

const KeyboardDateTimePicker = (props: KeyboardDateTimePickerProps) => {
  const { className = '', name, fieldProps, ...rest } = props

  return (
    <Field
      name={name}
      render={({ input, ...fieldRenderProps }) => {
        const handleOpen = () => input.onFocus()
        const handleClose = () => input.onBlur()

        return (
          <KeyboardDateTimePickerWrapper
            {...fieldRenderProps}
            {...rest}
            input={input}
            className={className}
            onOpen={handleOpen}
            onClose={handleClose}
          />
        )
      }}
      {...fieldProps}
    />
  )
}

export default KeyboardDateTimePicker
