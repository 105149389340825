import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'

import DeletedCheckTable from './DeletedCheckTable'

import { deletedItems } from '../../redux/collections/selectors'
import {
  listDeletedItems,
  sentDeletedItems,
  createDeletedItem,
  deleteDeletedItems,
} from '../../redux/collections/actions'
import { loanBalanceStartDate } from '../../redux/loanBalance/selectors'

const selector = createStructuredSelector({
  deletedItems,
  loanBalanceStartDate,
})
const actions = {
  listDeletedItems,
  sentDeletedItems,
  createDeletedItem,
  deleteDeletedItems,
}

export default connect(selector, actions)(DeletedCheckTable)
