import React, { useState, useCallback, useMemo, useEffect, useRef } from 'react'
import { useParams } from 'react-router'
import { Link as RouterLink, generatePath } from 'react-router-dom'
import { Form } from 'react-final-form'
import InfiniteScroll from 'react-infinite-scroll-component'
import { addMonths, subMonths, format, parseISO, min, max } from 'date-fns'
import Box from '@mui/material/Box'
import CircularProgress from '@mui/material/CircularProgress'
import LinkButton from '@mui/material/Link'
import Tooltip from '@mui/material/Tooltip'
import cn from 'classnames'
import styles from './BBCARSummaryTable.module.scss'
import genericSs from '@styles/generic.module.scss'
import Card from '../Common/Card'
import TableRow from '../Common/TableRow'
import TableCell from '../Common/TableCell'
import Table from '../Common/Table'
import TableHead from '../Common/TableHead'
import TableContainer from '../Common/TableContainer'
import TableBody from '../Common/TableBody'
import TableFooter from '../Common/TableFooter'
import { IClientInfo } from '@common/interfaces/client'
import {
  IArSummaryStatsData,
  IBorrowingBase,
  BBC_AGING_REPORT,
  BBC_RECEIVABLES_GROUP_BY_OPTIONS,
  BBCReceivablesGroupBy,
  IDebtorIneligibleCategories,
  IARAPChart,
  IneligibleCategory,
  IPreviousCollateralDateOptions,
  ReportingFlow,
  OngoingReportingType,
  IARAPChartDropdownOptions,
} from '@common/interfaces/bbc'
import { debounceEventHandler, formatDate, formatPrice } from '../../helpers/helpers'
import TableFiltersRow from '../Common/TableFiltersRow'
import FullscreenModal from '../Common/FullscreenModal'
import SelectField from '../Common/SelectField'
import ChangedValue from '../ChangedValue'
import { buildFiltersDefaults, buildFiltersValidateSchema } from '../../helpers/filters'
import { BBC_AR_SUMMARY_LIST_FILTERS_CONFIG, PER_PAGE } from '@common/constants/filters'
import { ROUTES } from '../../constants/routes'
import BBCARDetailRow from '../BBCARDetailRow'
import { maxOutAt99WithTooltip } from '../Common/Helpers/helpers'
import FilterContainer from '../Filters/FilterContainer'
import { ExpandAndMinimize, ExpandDetailIcon } from '../Common/Icons'
import TableLoader from '../Common/TableLoader'
import DatePicker from '../Common/DatePicker'
import { ILoadingData } from '../../redux/types'
import ARAPSummaryOverTime from '../ARAPSummaryOverTime'
import Empty from '../Common/Empty'
import useTrackVisualizationsTable from '../../hooks/useTrackVisualizationsTable'
import { CATEGORIES } from '@common/constants/tracking'
import useGraphToggle, { GraphType } from '../../hooks/useGraphTogggle'
import { usePermissions } from '../../helpers/permissionContext'
import EntityPreview from '../EntityPreview'
import { WorkflowTypes } from '@common/interfaces/notes'
import MultiCellTooltip, { IMultiCellTotal } from '../MultiCellTooltip'
import useTable from '../../hooks/useTable'
import useTrackVisualizationsTableChartSelection from '../../hooks/useTrackVisualizationsTableChartSelection'
import { IDueDiligence } from '@common/interfaces/dueDiligence'
import { useReportingFlow } from '../../hooks/useReportingFlow'
const metricOptions = [
  { value: 'value', label: '$ Value' },
  { value: 'percent', label: '% of Total' },
]

const CELL_INDEXES_MAP = {
  2: 'ar_1To_30Days',
  3: 'ar_31To_60Days',
  4: 'ar_61To_90Days',
  5: 'ar_91PlusDays',
  6: 'ineligible',
  7: 'eligible',
  8: 'total',
  9: 'percent',
}

const CELL_CLIENT_USER_INDEXES_MAP = {
  2: 'ar_1To_30Days',
  3: 'ar_31To_60Days',
  4: 'ar_61To_90Days',
  5: 'ar_91PlusDays',
  8: 'total',
  9: 'percent',
}

const PERCENT_CELL_INDEXES = [9]
const TOTALS_CELL_INDEXES = [6, 7, 8, 9]

const BBCARSummaryCell = ({
  type,
  data,
  changedValue,
  dataTotal,
  rowIndex,
  cellIndex,
  onSelectCell,
  isSelected,
  isActive,
  isTotals = false,
  withChangedValue = true,
}: {
  type: 'currency' | 'percent'
  data: number
  changedValue: number
  dataTotal?: IMultiCellTotal
  rowIndex: number
  cellIndex: number
  onSelectCell: (event: any, rowIndex: number, columnIndex: number) => void
  isSelected: boolean
  isActive: boolean
  isTotals?: boolean
  withChangedValue?: boolean
}) => {
  const handleSelectCell = useCallback(
    (event) => onSelectCell(event, rowIndex, cellIndex),
    [rowIndex, cellIndex, onSelectCell],
  )

  return (
    <TableCell
      className={cn(genericSs.tableTextRight, 'activableCell', {
        [styles.totals]: isTotals,
        activeCell: isSelected,
      })}
      onClick={handleSelectCell}
      data-index={cellIndex}
    >
      <MultiCellTooltip isActive={isActive} data={dataTotal}>
        <div>
          {withChangedValue && (
            <ChangedValue
              className={styles.changedValue}
              isChip
              type={type}
              changedValue={changedValue}
            />
          )}
          {type === 'percent' ? (
            <>{(data * 100 || 0).toFixed(2)}%</>
          ) : (
            <>
              <span className={genericSs.pricePrefix}>$</span>
              {formatPrice(data)}
            </>
          )}
        </div>
      </MultiCellTooltip>
    </TableCell>
  )
}

interface IProps {
  isLoadingBBC: boolean
  bbc: IBorrowingBase
  arSummaryStatsData: ILoadingData<IArSummaryStatsData>
  previousClientCollaterals: IPreviousCollateralDateOptions[]
  getPreviousBBCClientCollateral: (id: string, params?: object) => void
  getBBCARSummaryStats: (id: string, data: object) => void
  getARAPChartDropdownOptions: (id: string, data: object) => void
  arapChartDataDropdownOptions: ILoadingData<IARAPChartDropdownOptions>
  refreshCount: number
  isFilesSaving: boolean
  title?: string
  reportingFlow: ReportingFlow
  clientInfo?: IClientInfo
  dueDiligenceInfo?: IDueDiligence
  debtorIneligibleCategories: ILoadingData<IDebtorIneligibleCategories>
  arapChartData: ILoadingData<IARAPChart>
  editLink?: string
  fixedType?: GraphType
}

const BBCARSummaryTable = ({
  isLoadingBBC,
  bbc,
  arSummaryStatsData,
  previousClientCollaterals,
  getPreviousBBCClientCollateral,
  getBBCARSummaryStats,
  getARAPChartDropdownOptions,
  arapChartDataDropdownOptions,
  refreshCount,
  isFilesSaving,
  title = 'AR Summary',
  reportingFlow,
  clientInfo,
  dueDiligenceInfo,
  debtorIneligibleCategories,
  arapChartData,
  editLink,
  fixedType,
}: IProps) => {
  const { id } = useParams<{ id: string }>()
  const wrapperRef = useRef(null)
  const { isParticipant } = usePermissions()

  const [expanded, setExpanded] = useState([])
  const [recordDate, setRecordDate] = useState<string>('')
  const [compareDate, setCompareDate] = useState<string>('')
  const [currentMetric, setCurrentMetric] = useState<string>('value')
  const [groupBy, setGroupBy] = useState<BBCReceivablesGroupBy>(BBCReceivablesGroupBy.Customer)

  const [currentDateRange, setCurrentDateRange] = useState<{ startDate: string; endDate: string }>({
    startDate: '',
    endDate: '',
  })
  const [datesBoundary, setDatesBoundary] = useState<{ minDate: string; maxDate: string }>({
    minDate: '',
    maxDate: '',
  })

  const { isLoading, data: arSummaryStats } = useMemo(
    () => arSummaryStatsData,
    [arSummaryStatsData],
  )

  const [isModalShown, setIsModalShown] = useState(false)

  const { isClientPage, isClientUserPage, isLCRPage, isBBC } = useReportingFlow({ reportingFlow })

  const { TabsComponent, isGraphShown } = useGraphToggle({
    defaultTab: fixedType ? fixedType : isClientUserPage ? GraphType.Chart : GraphType.Table,
  })

  const previousClientCollateralsDatesOptions = useMemo(() => {
    return [bbc, ...(previousClientCollaterals || [])].filter(Boolean).map((clientCollateral) => ({
      value: clientCollateral.recordDate,
      label: formatDate(clientCollateral.recordDate),
    }))
  }, [bbc, previousClientCollaterals])

  const compareableClientCollaterals = useMemo(() => {
    if (isBBC) {
      return previousClientCollateralsDatesOptions?.slice(1)
    } else {
      return previousClientCollateralsDatesOptions?.filter((option) => option.value !== recordDate)
    }
  }, [previousClientCollateralsDatesOptions, recordDate, isBBC])

  const { minBBCDate, maxBBCDate } = useMemo(() => {
    const dateObjects = previousClientCollateralsDatesOptions.map((dateString) =>
      parseISO(dateString.value),
    )
    const maxBBCDate = new Date(max(dateObjects))
    maxBBCDate.setDate(maxBBCDate.getDate() + 1)
    const minBBCDate = new Date(min(dateObjects))
    minBBCDate.setDate(minBBCDate.getDate() - 1)
    return {
      minBBCDate,
      maxBBCDate,
    }
  }, [previousClientCollateralsDatesOptions])

  const handleDateChange = useCallback(
    (values: { startDate: string; endDate: string }) => {
      if (minBBCDate && maxBBCDate) {
        setCurrentDateRange(values)
        let minDate = subMonths(new Date(values.endDate), 24)
        let maxDate = addMonths(new Date(values.startDate), 24)

        minDate = minDate < minBBCDate ? minBBCDate : minDate
        maxDate = maxBBCDate > maxDate ? maxDate : maxBBCDate

        const formattedMinDate = format(minDate, 'yyyy-MM-dd')
        const formattedMaxDate = format(maxDate, 'yyyy-MM-dd')

        setDatesBoundary({
          minDate: formattedMinDate,
          maxDate: formattedMaxDate,
        })
      }
    },
    [setCurrentDateRange, minBBCDate, maxBBCDate],
  )

  useEffect(() => {
    if (bbc?.recordDate) {
      handleDateChange({
        startDate: format(subMonths(new Date(bbc.recordDate), 12), 'yyyy-MM-dd'),
        endDate: bbc.recordDate,
      })
    }
  }, [bbc, handleDateChange, isClientPage])

  useEffect(() => {
    if (!isBBC && previousClientCollaterals?.[0]?.recordDate) {
      setRecordDate(previousClientCollaterals?.[0]?.recordDate)

      handleDateChange({
        startDate: format(
          subMonths(new Date(previousClientCollaterals?.[0]?.recordDate), 12),
          'yyyy-MM-dd',
        ),
        endDate: previousClientCollaterals?.[0]?.recordDate,
      })
    } else if (!isBBC) {
      setRecordDate('')
    }
  }, [previousClientCollaterals, handleDateChange, isBBC])

  const shouldLoad = useMemo(() => {
    return (
      (isClientPage && clientInfo?.id === id) ||
      (isClientUserPage && clientInfo?.id) ||
      (isLCRPage && dueDiligenceInfo?.id === id)
    )
  }, [isClientPage, clientInfo, isClientUserPage, isLCRPage, dueDiligenceInfo, id])

  useEffect(() => {
    if (shouldLoad) {
      getPreviousBBCClientCollateral(id, {
        reportingFlow,
        clientName: clientInfo?.clientName,
        reportType: OngoingReportingType.AR,
      })
    }
  }, [id, shouldLoad, getPreviousBBCClientCollateral, reportingFlow, clientInfo])

  const ineligibleCategories = useMemo(
    () => debtorIneligibleCategories.data?.ineligibleCategories || [],
    [debtorIneligibleCategories],
  )
  const debtorIneligibleCategoriesOptions = useMemo(
    () =>
      ineligibleCategories.map((ineligibleCategory) => ({
        label: ineligibleCategory,
        value: ineligibleCategory,
      })),
    [ineligibleCategories],
  )

  useEffect(() => {
    ;(id || clientInfo?.id) &&
      reportingFlow &&
      getARAPChartDropdownOptions(id || clientInfo?.id, {
        agingReport: BBC_AGING_REPORT.AR,
        reportingFlow,
      })
  }, [id, getARAPChartDropdownOptions, reportingFlow, clientInfo?.id])

  const entitiesOptions = useMemo(
    () =>
      arapChartDataDropdownOptions?.data?.entities?.map((entity) => ({
        label: entity,
        value: entity,
      })) || [],
    [arapChartDataDropdownOptions],
  )

  const top5Entities = useMemo(
    () => entitiesOptions.slice(0, 5)?.map((option) => option.value),
    [entitiesOptions],
  )

  const filtersConfig = useMemo(() => {
    // First filter out configs based on page type
    const filteredConfigs = BBC_AR_SUMMARY_LIST_FILTERS_CONFIG.filter((config) => {
      if (isBBC) return true
      if (isClientPage) return !['invoice', 'daysPastInvoice'].includes(config.field)
      if (isClientUserPage || isLCRPage) {
        return !['daysPastInvoice', 'invoice', 'ineligible', 'eligible'].includes(config.field)
      }
      return true
    })

    // Add ineligible category options
    const withIneligibleCategories = filteredConfigs.map((item) => {
      if (item.field === 'ineligible_category') {
        return {
          ...item,
          options: debtorIneligibleCategoriesOptions,
        }
      }
      return item
    })

    // Handle client user page specific logic
    const withClientUserLogic = withIneligibleCategories.map((item) => {
      if ((isClientUserPage || isLCRPage) && item.field === 'ineligible_category') {
        return {
          ...item,
          type: 'empty' as const,
        }
      }
      if (item.field === 'debtor') {
        return {
          ...item,
          options: entitiesOptions,
        }
      }
      return item
    })

    // Add quick filters except for client user page
    const withQuickFilters = withClientUserLogic.map((filter) => {
      if (filter.type !== 'quickFilter') return filter
      if (isClientUserPage) return null

      return {
        ...filter,
        quickFilters: [
          {
            title: 'Top 5 Customers',
            filters: {
              debtor: top5Entities,
            },
          },
          {
            title: 'Eligible Customers',
            filters: {
              ineligible_category: [
                IneligibleCategory.Eligible,
                IneligibleCategory.Foreign,
                IneligibleCategory.Government,
                IneligibleCategory.Intercompany,
                IneligibleCategory.Other,
                IneligibleCategory.Distressed,
                IneligibleCategory.Affiliate,
              ].filter((category) => ineligibleCategories.includes(category)),
            },
          },
        ].filter(({ title }) => title !== 'Eligible Customers' || (!isLCRPage && !isGraphShown)),
      }
    })

    return withQuickFilters.filter(Boolean)
  }, [
    isBBC,
    isClientPage,
    isClientUserPage,
    isLCRPage,
    entitiesOptions,
    debtorIneligibleCategoriesOptions,
    ineligibleCategories,
    top5Entities,
    isGraphShown,
  ])

  const filtersValidate = useMemo(() => buildFiltersValidateSchema(filtersConfig), [filtersConfig])
  const filtersDefaults = useMemo(() => buildFiltersDefaults(filtersConfig), [filtersConfig])

  const {
    filters,
    handleFiltersChange,
    handleOrderChange,
    orderBy,
    activeCells,
    activeRows,
    handleSelectCell,
    handleSelectActiveRow,
    resetActiveCells,
    quickFilter,
    handleQuickFilterChange,
  } = useTable({
    tableId: `arSummary${fixedType || ''}`,
    filtersDefaults,
    sortDefault: { field: 'total', direction: 'DESC' },
  })

  const companyValues = useMemo(() => {
    return filters?.debtor?.map((company: string) => company) || []
  }, [filters?.debtor])

  const debounceListArSummary = useMemo(
    () =>
      debounceEventHandler(
        (data: any) =>
          getBBCARSummaryStats(isClientUserPage ? clientInfo?.id : id, {
            ...data,
            reportingFlow,
            clientName: clientInfo?.clientName,
          }),
        500,
      ),
    [
      id,
      getBBCARSummaryStats,
      isClientUserPage,
      reportingFlow,
      clientInfo?.id,
      clientInfo?.clientName,
    ],
  )

  const loadMore = useCallback(() => {
    debounceListArSummary({
      loadMore: true,
      orderBy: orderBy.field,
      orderDirection: orderBy.direction,
      filters,
      recordDate,
      compareDate,
      groupBy,
      page: Math.ceil(arSummaryStats?.data.length / PER_PAGE),
    })
  }, [orderBy, filters, recordDate, compareDate, groupBy, debounceListArSummary, arSummaryStats])

  const handleRecordDateChange = useCallback(({ target: { value } }) => {
    setRecordDate(value)
  }, [])

  const handleCompareDateChange = useCallback(({ target: { value } }) => {
    setCompareDate(value)
  }, [])

  const handleGroupByChange = useCallback(({ target: { value } }) => {
    setExpanded([])
    setGroupBy(value)
  }, [])

  const handleMetricChange = useCallback(({ target: { value } }) => {
    setCurrentMetric(value)
  }, [])

  const readyToLoad = useMemo(() => {
    if (isBBC) {
      return !!id
    }
    return !!recordDate
  }, [id, recordDate, isBBC])

  useEffect(() => {
    refreshCount &&
      readyToLoad &&
      !isGraphShown &&
      debounceListArSummary({
        orderBy: orderBy.field,
        orderDirection: orderBy.direction,
        filters,
        page: 0,
        recordDate,
        compareDate,
        groupBy,
      })
  }, [
    orderBy,
    filters,
    debounceListArSummary,
    refreshCount,
    recordDate,
    groupBy,
    compareDate,
    readyToLoad,
    isGraphShown,
  ])

  const summaryRow = useMemo(
    () =>
      arSummaryStats?.data
        ?.filter((_, index) => activeRows.includes(index))
        .reduce(
          (totalRowResult, row) => {
            totalRowResult.invoiceCount += +row.invoiceCount || 0
            totalRowResult.daysPastInvoice += row.daysPastInvoice / activeRows?.length || 0
            totalRowResult.ar_1To_30Days += row.ar_1To_30Days || 0
            totalRowResult.ar_31To_60Days += row.ar_31To_60Days || 0
            totalRowResult.ar_61To_90Days += row.ar_61To_90Days || 0
            totalRowResult.ar_91PlusDays += row.ar_91PlusDays || 0
            totalRowResult.ineligible += row.ineligible || 0
            totalRowResult.eligible += row.eligible || 0
            totalRowResult.total += row.total || 0
            totalRowResult.percent = arSummaryStats?.totals?.total
              ? +((totalRowResult.total / arSummaryStats?.totals?.total) * 100).toFixed(2)
              : 0

            return totalRowResult
          },
          {
            invoiceCount: 0,
            daysPastInvoice: 0,
            ar_1To_30Days: 0,
            ar_31To_60Days: 0,
            ar_61To_90Days: 0,
            ar_91PlusDays: 0,
            ineligible: 0,
            eligible: 0,
            total: 0,
            percent: 0,
          },
        ),
    [activeRows, arSummaryStats],
  )

  const handleExpand = useCallback(
    (label: string) => {
      if (expanded.length === 0) {
        setIsModalShown(true)
      }
      setExpanded((values) =>
        values.includes(label) ? values.filter((item) => item !== label) : [...values, label],
      )
    },
    [expanded],
  )

  const toggleModal = useCallback(() => {
    setIsModalShown((prev) => !prev)
    resetActiveCells()
  }, [resetActiveCells])

  const totalCell: IMultiCellTotal = useMemo(() => {
    const rowIndexesWithActiveCells = activeCells
      .map((indexes, index) => (indexes?.length ? index : null))
      .filter((index) => index !== null)

    const cellIndexesWithActiveCells = [...new Set(activeCells.flat().filter(Boolean))]
    const filteredData = {
      fields: cellIndexesWithActiveCells.map((cellIndex) => CELL_INDEXES_MAP[cellIndex]),
      amounts: [] as number[],
      values: [] as number[],
    }

    const isSameType =
      cellIndexesWithActiveCells.every((cellIndex) => PERCENT_CELL_INDEXES.includes(cellIndex)) ||
      cellIndexesWithActiveCells.every((cellIndex) => !PERCENT_CELL_INDEXES.includes(cellIndex))
    rowIndexesWithActiveCells.forEach((rowIndex) => {
      activeCells[rowIndex].forEach((cellIndex) => {
        filteredData.values.push(arSummaryStats?.data[rowIndex][CELL_INDEXES_MAP[cellIndex]])
        if (!PERCENT_CELL_INDEXES.includes(cellIndex)) {
          filteredData.amounts.push(arSummaryStats?.data[rowIndex][CELL_INDEXES_MAP[cellIndex]])
        }
      })
    })
    const amountSum = filteredData.amounts.reduce(
      (result: number, amount: number) => result + amount,
      0,
    )
    const sum = filteredData.values.reduce((result: number, amount: number) => result + amount, 0)

    return {
      type: cellIndexesWithActiveCells.every((cellIndex) =>
        PERCENT_CELL_INDEXES.includes(cellIndex),
      )
        ? 'percent'
        : 'currency',
      count: filteredData.values.length,
      sum,
      avg: isSameType
        ? filteredData.values.length
          ? sum / filteredData.values.length
          : 0
        : filteredData.amounts.length
        ? amountSum / filteredData.amounts.length
        : 0,
    }
  }, [activeCells, arSummaryStats])

  const { isLoading: isChartDataLoading } = useMemo(() => arapChartData, [arapChartData])

  const handlClearCompareDate = useCallback(() => {
    setCompareDate('')
  }, [setCompareDate])

  useEffect(() => {
    if (recordDate === compareDate) {
      handlClearCompareDate()
    }
  }, [recordDate, compareDate, handlClearCompareDate])

  const isInitialized = useMemo(() => !!arSummaryStatsData?.data?.data, [arSummaryStatsData])
  const visualizationsParams = useMemo(
    () => ({
      [isBBC ? 'borrowingBaseId' : 'clientId']: id,
    }),
    [isBBC, id],
  )
  const visualizationsFilters = useMemo(
    () => ({
      ...filters,
      recordDate,
      compareDate,
      groupBy,
      currentMetric,
    }),
    [filters, recordDate, compareDate, groupBy, currentMetric],
  )

  const visualizationsCategory = useMemo(
    () => (isGraphShown ? CATEGORIES.arSummaryChart : CATEGORIES.arSummary),
    [isGraphShown],
  )

  useTrackVisualizationsTable({
    isInitialized,
    category: visualizationsCategory,
    params: visualizationsParams,
    filtersConfig: BBC_AR_SUMMARY_LIST_FILTERS_CONFIG,
    filters: visualizationsFilters,
    orderBy,
  })

  useTrackVisualizationsTableChartSelection({
    isInitialized,
    category: visualizationsCategory,
    params: visualizationsParams,
    isChart: !!isGraphShown,
  })

  const isNoDataAvailable = useMemo(
    () => !previousClientCollaterals.length && !bbc && !isLoading,
    [previousClientCollaterals.length, bbc, isLoading],
  )

  const graphFiltersConfig = useMemo(() => {
    return filtersConfig.filter(
      (filter) => filter.field === 'debtor' || filter.field === 'quickFilter',
    )
  }, [filtersConfig])

  return (
    <FullscreenModal
      isOpen={isModalShown}
      setIsOpen={setIsModalShown}
      classes={{ body: styles.fullScreenModal }}
    >
      <Card
        id={`ar-summary${fixedType || ''}`}
        className={styles.cardContainer}
        noHeaderMargin
        ref={wrapperRef}
      >
        <TableContainer
          className={cn({
            [styles.table]: isBBC,
            [styles.tableClientPage]: isClientPage,
            [styles.tableClientUserPage]: isClientUserPage || isLCRPage,
          })}
          hasFooter
        >
          <Form
            validate={filtersValidate}
            onSubmit={handleFiltersChange}
            initialValues={filters}
            mutators={{
              setFieldData: ([field, value], state, { changeValue }) => {
                changeValue(state, field, () => value)
              },
            }}
            render={({ values, handleSubmit, form: { mutators } }) => (
              <FilterContainer
                filters={isGraphShown ? graphFiltersConfig : filtersConfig}
                handleSubmit={handleSubmit}
                mutators={mutators}
                values={values}
                appliedFilters={filters}
                appliedQuickFilter={quickFilter}
                handleAppliedQuickFilterChange={handleQuickFilterChange}
                withFullSearch={!isGraphShown}
                actionsSize={!isGraphShown && isClientUserPage ? 6 : 7}
                title={
                  <Box
                    mr={2}
                    className={cn({
                      [styles.clientUserTitle]: isClientUserPage && !isGraphShown,
                    })}
                  >
                    <h2>
                      {(isClientPage || isLCRPage) && isGraphShown ? title + ' Over Time' : title}
                      {editLink && (
                        <LinkButton
                          component={RouterLink}
                          to={editLink}
                          className={styles.editLink}
                        >
                          Edit
                        </LinkButton>
                      )}
                      {isGraphShown && isChartDataLoading && (
                        <CircularProgress className={styles.loadingIndicator} size={18} />
                      )}
                    </h2>
                    {isClientUserPage && !isGraphShown && recordDate && (
                      <span className={styles.asOfLabel}>as of {formatDate(recordDate)}</span>
                    )}
                  </Box>
                }
                actions={
                  <Box display="flex" justifyContent="space-between" alignItems="center" gap={1}>
                    {!isGraphShown ? (
                      <>
                        {!isBBC && (
                          <div className={styles.recordDateWrapper}>
                            <SelectField
                              key={recordDate}
                              label="Date"
                              variant="outlined"
                              className={styles.selectField}
                              useFinalForm={false}
                              name="recordDate"
                              options={previousClientCollateralsDatesOptions}
                              onChange={handleRecordDateChange}
                              value={recordDate}
                              defaultValue=""
                              withTopLabel
                            />
                          </div>
                        )}
                        {!isClientUserPage && (
                          <div className={styles.recordDateWrapper}>
                            <SelectField
                              key={compareDate}
                              label="Compare"
                              variant="outlined"
                              className={styles.selectField}
                              useFinalForm={false}
                              name="compareDate"
                              options={compareableClientCollaterals}
                              onChange={handleCompareDateChange}
                              value={compareDate}
                              defaultValue=""
                              withTopLabel
                              handleClear={handlClearCompareDate}
                            />
                          </div>
                        )}
                        {!isClientUserPage && (
                          <div className={styles.recordDateWrapper}>
                            <SelectField
                              key={groupBy}
                              label="Group by"
                              variant="outlined"
                              className={styles.selectField}
                              useFinalForm={false}
                              name="groupBy"
                              options={BBC_RECEIVABLES_GROUP_BY_OPTIONS}
                              onChange={handleGroupByChange}
                              value={groupBy}
                              defaultValue=""
                              withTopLabel
                            />
                          </div>
                        )}
                      </>
                    ) : (
                      <>
                        <div className={styles.recordDateWrapper}>
                          <SelectField
                            key={currentMetric}
                            label="Mertic"
                            variant="outlined"
                            className={styles.selectField}
                            useFinalForm={false}
                            name="recordDate"
                            options={metricOptions}
                            onChange={handleMetricChange}
                            value={currentMetric}
                            defaultValue=""
                            withTopLabel
                          />
                        </div>
                        <div className={styles.recordDateWrapper}>
                          <DatePicker
                            currentDateRange={currentDateRange}
                            datesBoundary={datesBoundary}
                            onChange={handleDateChange}
                          />
                        </div>
                      </>
                    )}
                    {!fixedType && <div className={styles.recordDateWrapper}>{TabsComponent}</div>}
                    <ExpandAndMinimize action={toggleModal} isExpanded={isModalShown} />
                  </Box>
                }
              />
            )}
          />
          {isNoDataAvailable ? (
            <Empty
              message={
                isClientUserPage ? 'No data is available' : 'No recievables exist for this client'
              }
            />
          ) : !isGraphShown ? (
            <>
              <Table>
                <TableHead>
                  <TableFiltersRow
                    filters={filtersConfig}
                    orderBy={orderBy}
                    handleOrderChange={handleOrderChange}
                    isChildrenAtStart
                  >
                    <TableCell />
                  </TableFiltersRow>
                </TableHead>
                <TableBody id="scrollableARSummaryTable">
                  {isFilesSaving || isLoadingBBC || isLoading ? (
                    <TableLoader columnsCount={11} />
                  ) : (
                    arSummaryStats?.data.length > 0 && (
                      <InfiniteScroll
                        dataLength={arSummaryStats?.data.length}
                        next={loadMore}
                        hasMore={
                          arSummaryStats?.data.length < arSummaryStats?.data?.[0]?.totalCount
                        }
                        loader={<TableLoader columnsCount={10} rowsCount={1} />}
                        scrollableTarget="scrollableARSummaryTable"
                      >
                        {arSummaryStats?.data.map((item, index) => {
                          const isExpanded = expanded.includes(item.debtor)

                          return (
                            <React.Fragment key={item.debtor}>
                              <TableRow data-index={index} className="activableRow">
                                <TableCell className={genericSs.tableTextCenter}>
                                  {item?.invoiceCount > 0 && (
                                    <ExpandDetailIcon
                                      onClick={() => handleExpand(item.debtor)}
                                      isExpanded={isExpanded}
                                    />
                                  )}
                                </TableCell>
                                <TableCell
                                  className={genericSs.tableTextLeft}
                                  onClick={(event) => handleSelectActiveRow(event, index)}
                                >
                                  <Tooltip
                                    title={item.debtor || ''}
                                    placement="top"
                                    disableHoverListener={
                                      (item.debtor?.length < 20 && !item.ineligibleCategory) ||
                                      !item.debtor
                                    }
                                    disableTouchListener
                                  >
                                    {item?.entityId && !isParticipant && !isClientUserPage ? (
                                      <LinkButton
                                        component={RouterLink}
                                        to={generatePath(ROUTES.ENTITY_PAGE, {
                                          id: item.entityId,
                                        })}
                                        className={cn(styles.entityLink, {
                                          [styles.shortDebtorLabel]: !!item.ineligibleCategory,
                                        })}
                                      >
                                        {item.debtor}
                                      </LinkButton>
                                    ) : (
                                      <span
                                        className={cn({
                                          [styles.shortDebtorLabel]:
                                            !!item.ineligibleCategory && !isClientUserPage,
                                        })}
                                      >
                                        {item.debtor}
                                      </span>
                                    )}
                                  </Tooltip>
                                  {item?.entityId && !isParticipant && (
                                    <EntityPreview
                                      id={item.entityId}
                                      workflow={
                                        isBBC ? WorkflowTypes.bbc : WorkflowTypes.clientPage
                                      }
                                      className={styles.entityPreviewIcon}
                                      isClientUserPage={isClientUserPage}
                                      itemId={id || clientInfo?.id}
                                    />
                                  )}
                                  {item.ineligibleCategory && !isClientUserPage && (
                                    <Tooltip placement="top" title="Ineligible category">
                                      <div className={genericSs.grayCard}>
                                        {item.ineligibleCategory}
                                      </div>
                                    </Tooltip>
                                  )}
                                </TableCell>
                                {isBBC && (
                                  <>
                                    <TableCell className={genericSs.tableTextRight}>
                                      <ChangedValue
                                        isChip
                                        className={styles.changedValue}
                                        changedValue={item.invoiceCountChanged}
                                      />
                                      {maxOutAt99WithTooltip(+item.invoiceCount) || '-'}
                                    </TableCell>
                                    <TableCell className={genericSs.tableTextRight}>
                                      <ChangedValue
                                        isChip
                                        className={styles.changedValue}
                                        changedValue={item.daysPastInvoiceChanged}
                                      />
                                      {Math.round(+item?.daysPastInvoice)}
                                    </TableCell>
                                  </>
                                )}
                                {Object.keys(
                                  isClientUserPage || isLCRPage
                                    ? CELL_CLIENT_USER_INDEXES_MAP
                                    : CELL_INDEXES_MAP,
                                ).map((cellIndex) => (
                                  <BBCARSummaryCell
                                    key={cellIndex}
                                    type={
                                      PERCENT_CELL_INDEXES.includes(+cellIndex)
                                        ? 'percent'
                                        : 'currency'
                                    }
                                    data={item[CELL_INDEXES_MAP[cellIndex]]}
                                    changedValue={item[`${[CELL_INDEXES_MAP[cellIndex]]}Changed`]}
                                    rowIndex={index}
                                    cellIndex={+cellIndex}
                                    onSelectCell={handleSelectCell}
                                    isSelected={
                                      activeRows.includes(index) ||
                                      activeCells[index]?.includes(+cellIndex)
                                    }
                                    isActive={activeCells[index]?.includes(+cellIndex)}
                                    dataTotal={totalCell}
                                    isTotals={TOTALS_CELL_INDEXES.includes(+cellIndex)}
                                    withChangedValue={!isClientUserPage}
                                  />
                                ))}
                              </TableRow>
                              {isExpanded && (
                                <TableRow>
                                  <TableCell className={genericSs.nestedRowColumn} colSpan={11}>
                                    <BBCARDetailRow
                                      arDetailStats={item.rows}
                                      filters={filters}
                                      isModalShown={isModalShown}
                                      recordDate={recordDate}
                                      compareDate={compareDate}
                                      debtor={item.debtor}
                                      reportingFlow={reportingFlow}
                                      clientName={clientInfo?.clientName}
                                      groupBy={groupBy}
                                    />
                                  </TableCell>
                                </TableRow>
                              )}
                            </React.Fragment>
                          )
                        })}
                      </InfiniteScroll>
                    )
                  )}
                </TableBody>
                <TableFooter>
                  {isFilesSaving || isLoadingBBC || !arSummaryStats ? (
                    <TableLoader columnsCount={11} rowsCount={2} />
                  ) : (
                    <>
                      <TableRow>
                        <TableCell colSpan={4} className={genericSs.tableTextLeft}>
                          Total
                        </TableCell>
                        <TableCell className={genericSs.tableTextRight}>
                          <span className={genericSs.pricePrefix}>$</span>
                          {formatPrice(arSummaryStats?.totals?.ar_1To_30Days)}
                        </TableCell>
                        <TableCell className={genericSs.tableTextRight}>
                          <span className={genericSs.pricePrefix}>$</span>
                          {formatPrice(arSummaryStats?.totals?.ar_31To_60Days)}
                        </TableCell>
                        <TableCell className={genericSs.tableTextRight}>
                          <span className={genericSs.pricePrefix}>$</span>
                          {formatPrice(arSummaryStats?.totals?.ar_61To_90Days)}
                        </TableCell>
                        <TableCell className={genericSs.tableTextRight}>
                          <span className={genericSs.pricePrefix}>$</span>
                          {formatPrice(arSummaryStats?.totals?.ar_91PlusDays)}
                        </TableCell>
                        {!isClientUserPage && !isLCRPage && (
                          <TableCell className={cn(genericSs.tableTextRight, styles.totals)}>
                            <span className={genericSs.pricePrefix}>$</span>
                            {formatPrice(arSummaryStats?.totals?.ineligible)}
                          </TableCell>
                        )}
                        {!isClientUserPage && !isLCRPage && (
                          <TableCell className={cn(genericSs.tableTextRight, styles.totals)}>
                            <span className={genericSs.pricePrefix}>$</span>
                            {formatPrice(arSummaryStats?.totals?.eligible)}
                          </TableCell>
                        )}
                        <TableCell className={cn(genericSs.tableTextRight, styles.totals)}>
                          <span className={genericSs.pricePrefix}>$</span>
                          {formatPrice(arSummaryStats?.totals?.total)}
                        </TableCell>
                        <TableCell className={cn(genericSs.tableTextRight, styles.totals)}>
                          100%
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell colSpan={4} className={genericSs.tableTextLeft}>
                          % of Total
                        </TableCell>
                        <TableCell className={genericSs.tableTextRight}>
                          {arSummaryStats?.totals?.ar_1To_30DaysPercent?.toFixed(2)}%
                        </TableCell>
                        <TableCell className={genericSs.tableTextRight}>
                          {arSummaryStats?.totals?.ar_31To_60DaysPercent?.toFixed(2)}%
                        </TableCell>
                        <TableCell className={genericSs.tableTextRight}>
                          {arSummaryStats?.totals?.ar_61To_90DaysPercent?.toFixed(2)}%
                        </TableCell>
                        <TableCell className={genericSs.tableTextRight}>
                          {arSummaryStats?.totals?.ar_91PlusDaysPercent?.toFixed(2)}%
                        </TableCell>
                        {!isClientUserPage && !isLCRPage && (
                          <TableCell className={cn(genericSs.tableTextRight, styles.totals)}>
                            {arSummaryStats?.totals?.ineligiblePercent?.toFixed(2)}%
                          </TableCell>
                        )}
                        {!isClientUserPage && !isLCRPage && (
                          <TableCell className={cn(genericSs.tableTextRight, styles.totals)}>
                            {arSummaryStats?.totals?.eligiblePercent?.toFixed(2)}%
                          </TableCell>
                        )}
                        <TableCell className={cn(genericSs.tableTextRight, styles.totals)}>
                          100%
                        </TableCell>
                        <TableCell className={cn(genericSs.tableTextRight, styles.totals)}>
                          100%
                        </TableCell>
                      </TableRow>
                    </>
                  )}
                  {activeRows.length > 1 && (
                    <TableRow className="summaryRow">
                      <TableCell className={genericSs.tableTextLeft} colSpan={4}>
                        {activeRows.length} rows selected
                      </TableCell>

                      <TableCell className={genericSs.tableTextRight}>
                        {formatPrice(summaryRow?.ar_1To_30Days)}
                      </TableCell>
                      <TableCell className={genericSs.tableTextRight}>
                        {formatPrice(summaryRow?.ar_31To_60Days)}
                      </TableCell>
                      <TableCell className={genericSs.tableTextRight}>
                        {formatPrice(summaryRow?.ar_61To_90Days)}
                      </TableCell>
                      <TableCell className={genericSs.tableTextRight}>
                        {formatPrice(summaryRow?.ar_91PlusDays)}
                      </TableCell>
                      {!isClientUserPage && !isLCRPage && (
                        <TableCell className={cn(genericSs.tableTextRight, styles.totals)}>
                          {formatPrice(summaryRow?.ineligible)}
                        </TableCell>
                      )}
                      {!isClientUserPage && !isLCRPage && (
                        <TableCell className={cn(genericSs.tableTextRight, styles.totals)}>
                          {formatPrice(summaryRow?.eligible)}
                        </TableCell>
                      )}
                      <TableCell className={cn(genericSs.tableTextRight, styles.totals)}>
                        {formatPrice(summaryRow?.total)}
                      </TableCell>
                      <TableCell className={cn(genericSs.tableTextRight, styles.totals)}>
                        {summaryRow?.percent}%
                      </TableCell>
                    </TableRow>
                  )}
                </TableFooter>
              </Table>
            </>
          ) : (
            <div className={styles.chartContainer}>
              <ARAPSummaryOverTime
                reportingFlow={reportingFlow}
                clientId={clientInfo?.id}
                companyValues={companyValues}
                currentDateRange={currentDateRange}
                currentMetric={currentMetric}
                agingReport={BBC_AGING_REPORT.AR}
                isFullscreen={isModalShown}
              />
            </div>
          )}
        </TableContainer>
      </Card>
    </FullscreenModal>
  )
}

export default BBCARSummaryTable
