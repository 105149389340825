import React, { useEffect, useCallback, useMemo } from 'react'
import { generatePath, Link as RouterLink } from 'react-router-dom'
import { Form } from 'react-final-form'
import Grid from '@mui/material/Grid'
import Link from '@mui/material/Link'
import Tooltip from '@mui/material/Tooltip'
import InfiniteScroll from 'react-infinite-scroll-component'
import cn from 'classnames'

import styles from './ProspectAnalysisQueueTable.module.scss'
import genericSs from '@styles/generic.module.scss'

import { ReactComponent as WarningIcon } from '@assets/images/warning-polygon-icon.svg'
import { BoxLink } from '../Common/Icons'
import {
  debounceEventHandler,
  formatDateCalendarNoTime,
  formatDateTime,
  formatDate,
} from '../../helpers/helpers'
import ProspectAnalysisQueueActions from './ProspectAnalysisQueueActions'
import ProspectAnalysisQueueUncategorizedActions from './ProspectAnalysisQueueUncategorizedActions'
import Card from '../Common/Card'
import TableContainer from '../Common/TableContainer'
import Table from '../Common/Table'
import TableHead from '../Common/TableHead'
import TableBody from '../Common/TableBody'
import TableRow from '../Common/TableRow'
import TableCell from '../Common/TableCell'
import TableFiltersRow from '../Common/TableFiltersRow'
import { ROUTES } from '../../constants/routes'
import {
  IProspectReportingAggregation,
  OPSReportingFlowStatus,
  PROSPECT_FILES_FOLDERS_MAP,
  PROSPECT_REPORTING_MAP,
} from '@common/interfaces/prospects'
import { OngoingReportingStatus, OngoingReportingType } from '@common/interfaces/bbc'
import {
  buildFiltersDefaults,
  buildFiltersValidateSchema,
  updateDateFilters,
} from '../../helpers/filters'
import {
  PROSPECT_REPORTING_LIST_FILTERS_CONFIG,
  PER_PAGE,
  IFilter,
} from '@common/constants/filters'
import FilterContainer from '../Filters/FilterContainer'
import { ILoadingData } from '../../redux/types'
import TableLoader from '../Common/TableLoader/TableLoader'
import SaveState from '../Common/SaveState'
import { IUser } from '@common/interfaces/user'
import useTable from '../../hooks/useTable'
import { usePermissions } from '../../helpers/permissionContext'

const filtersValidate = buildFiltersValidateSchema(PROSPECT_REPORTING_LIST_FILTERS_CONFIG)

const STATUSES_OPTIONS = [
  ...Object.values(OPSReportingFlowStatus).map((value) => ({
    value,
    label: value,
  })),
]

const PROSPECT_REPORTING_ROUTES = {
  [OngoingReportingType.IncomeStatement]: ROUTES.PROSPECT_REPORTING_INCOME_STATEMENT,
  [OngoingReportingType.BalanceSheet]: ROUTES.PROSPECT_REPORTING_BALANCE_SHEET,
  [OngoingReportingType.IncomeStatementProjections]:
    ROUTES.PROSPECT_REPORTING_INCOME_STATEMENT_PROJECTIONS,
  [OngoingReportingType.BalanceSheetProjections]:
    ROUTES.PROSPECT_REPORTING_BALANCE_SHEET_PROJECTIONS,
  [OngoingReportingType.AR]: ROUTES.PROSPECT_REPORTING_RECEIVABLES,
  [OngoingReportingType.AP]: ROUTES.PROSPECT_REPORTING_PAYABLES,
  [OngoingReportingType.Inventory]: ROUTES.PROSPECT_REPORTING_INVENTORY,
}

interface IProps {
  opsReportingId?: string
  refreshCounter?: number
  readOnly?: boolean
  prospectReporting: ILoadingData<IProspectReportingAggregation>
  listProspectReporting: (data: object) => void
  hideProspectReporting: () => void
  updateReporting: (id: string, data: object) => Promise<any>
  handleManageUncategorizedFiles?: () => void
  handleDeleteUncategorizedFiles?: () => void
  isBDO: boolean
  user: IUser
}

const ProspectAnalysisQueueTable = ({
  opsReportingId,
  refreshCounter,
  readOnly = false,
  prospectReporting,
  listProspectReporting,
  hideProspectReporting,
  updateReporting,
  handleManageUncategorizedFiles,
  handleDeleteUncategorizedFiles,
  isBDO,
  user,
}: IProps) => {
  const sortDefault = useMemo(
    () => ({
      field: opsReportingId
        ? 'status'
        : PROSPECT_REPORTING_LIST_FILTERS_CONFIG.find((item) => item.field === 'createdAt').dbField,
      direction: 'DESC' as const,
    }),
    [opsReportingId],
  )
  const filtersDefaults = useMemo(
    () =>
      buildFiltersDefaults(
        PROSPECT_REPORTING_LIST_FILTERS_CONFIG,
        opsReportingId
          ? {}
          : isBDO
          ? {
              salesforceOwner: [user?.id],
            }
          : {
              statuses: [OPSReportingFlowStatus.FileSelect, OPSReportingFlowStatus.Mapping],
            },
      ),
    [isBDO, opsReportingId, user],
  )

  const {
    filters,
    handleFiltersChange,
    handleOrderChange,
    orderBy,
    quickFilter,
    handleQuickFilterChange,
  } = useTable({
    tableId: 'prospectAnalysis',
    filtersDefaults,
    sortDefault,
    quickFilterDefault: isBDO ? 'Assigned to Me' : null,
  })

  const { isUW } = usePermissions()

  const {
    data: prospectReportingData,
    isLoading,
    isSaved,
    isSaving,
  } = useMemo(() => prospectReporting, [prospectReporting])

  const itemsCount = useMemo(() => prospectReportingData?.totalCount, [prospectReportingData])

  const clientsNames: string[] = useMemo(() => {
    const clientNamesArray = prospectReportingData?.data?.map(({ clientName }) => clientName)
    return [...new Set(clientNamesArray)]
  }, [prospectReportingData])

  const fetchProspectReporting = useCallback(
    (data: any) => {
      const filters = updateDateFilters(data.filters, PROSPECT_REPORTING_LIST_FILTERS_CONFIG)
      const params = {
        ...data,
        opsReportingId,
        filters,
      }
      listProspectReporting(params)
    },
    [listProspectReporting, opsReportingId],
  )
  const debounceListOngoingReporting = useMemo(
    () => debounceEventHandler(fetchProspectReporting, 500),
    [fetchProspectReporting],
  )

  useEffect(() => {
    refreshCounter &&
      debounceListOngoingReporting({
        page: 0,
        perPage: PER_PAGE,
        filters,
        orderBy: orderBy.field,
        orderDirection: orderBy.direction,
      })
  }, [filters, orderBy, debounceListOngoingReporting, refreshCounter])

  const refetchOngoingReportingList = useCallback(
    (skipLoader: boolean = true) =>
      debounceListOngoingReporting({
        page: 0,
        perPage: PER_PAGE,
        filters,
        orderBy: orderBy.field,
        orderDirection: orderBy.direction,
        skipLoader,
      }),
    [filters, orderBy, debounceListOngoingReporting],
  )

  const filtersConfig = useMemo(() => {
    const config: IFilter[] = PROSPECT_REPORTING_LIST_FILTERS_CONFIG.filter(
      ({ field }) => !opsReportingId || field !== 'prospect',
    )
      .filter(({ field }) => !readOnly || field !== 'boxLink')
      .filter(({ field }) => !readOnly || field !== 'action')
      .filter(({ field }) => !!opsReportingId || field !== 'completedAt')
      .map((item) => ({
        ...item,

        options:
          item.field === 'prospect'
            ? clientsNames?.map((item) => ({
                value: item,
                label: item,
              }))
            : item.field === 'statuses'
            ? STATUSES_OPTIONS
            : item.options,
      }))
      .map((filter) =>
        isBDO && !opsReportingId && filter.type === 'quickFilter'
          ? {
              ...filter,
              quickFilters: [
                {
                  title: 'Assigned to Me',
                  isHidden: true,
                  filters: {
                    salesforceOwner: [user?.id],
                  },
                },
              ],
            }
          : filter,
      )

    return config
  }, [clientsNames, opsReportingId, readOnly, isBDO, user])

  const loadMore = useCallback(() => {
    fetchProspectReporting({
      loadMore: true,
      page: Math.ceil(prospectReportingData?.data.length / PER_PAGE),
      perPage: PER_PAGE,
      orderBy: orderBy.field,
      orderDirection: orderBy.direction,
      filters,
    })
  }, [prospectReportingData, orderBy, filters, fetchProspectReporting])

  const handleUpdateReportingStatus = useCallback(
    async (id, status, type) => {
      await updateReporting(id, {
        status: status,
        type: type,
      })
      await refetchOngoingReportingList()
    },
    [updateReporting, refetchOngoingReportingList],
  )

  const getRowStyle = useCallback((status: OPSReportingFlowStatus | OngoingReportingStatus) => {
    switch (status) {
      case OngoingReportingStatus.NotStarted:
      case OPSReportingFlowStatus.FileSelect:
        return genericSs.notStarted
      case OPSReportingFlowStatus.Mapping:
        return genericSs.inProgress
      case OPSReportingFlowStatus.Complete:
      case OPSReportingFlowStatus.Waived:
        return genericSs.complete
      default:
        return ''
    }
  }, [])

  return (
    <Card noHeaderMargin withBorder={false} noPadding>
      <TableContainer className={styles.table}>
        {!opsReportingId && (
          <Form
            validate={filtersValidate}
            onSubmit={handleFiltersChange}
            initialValues={filters}
            mutators={{
              setFieldData: ([field, value], state, { changeValue }) => {
                changeValue(state, field, () => value)
              },
            }}
            render={({ values, handleSubmit, form: { mutators } }) => (
              <FilterContainer
                filters={filtersConfig}
                handleSubmit={handleSubmit}
                mutators={mutators}
                values={values}
                appliedFilters={filters}
                appliedQuickFilter={quickFilter}
                handleAppliedQuickFilterChange={handleQuickFilterChange}
              />
            )}
          />
        )}
        <Table>
          <TableHead>
            <TableFiltersRow
              filters={filtersConfig}
              orderBy={orderBy}
              handleOrderChange={handleOrderChange}
            />
          </TableHead>
          <TableBody id="scrollableTableReporting">
            {isLoading ? (
              <TableLoader
                columnsCount={filtersConfig.length}
                height={24}
                rowsCount={
                  opsReportingId ? Object.keys(PROSPECT_FILES_FOLDERS_MAP).length : PER_PAGE
                }
              />
            ) : (
              prospectReportingData?.data &&
              prospectReportingData.data.length > 0 && (
                <InfiniteScroll
                  dataLength={prospectReportingData?.data.length}
                  next={loadMore}
                  hasMore={prospectReportingData?.data.length < itemsCount}
                  loader={
                    <TableLoader columnsCount={filtersConfig.length} height={24} rowsCount={1} />
                  }
                  scrollableTarget="scrollableTableReporting"
                >
                  {prospectReportingData.data.map((item) => (
                    <TableRow key={item.id}>
                      {!opsReportingId && (
                        <TableCell className={genericSs.tableTextLeft}>
                          <Link
                            component={RouterLink}
                            to={generatePath(ROUTES.PROSPECT_PAGE, { id: item.opsReportingId })}
                          >
                            {item.clientName}
                          </Link>
                        </TableCell>
                      )}
                      <TableCell className={genericSs.tableTextLeft}>
                        {item.type === OngoingReportingType.Uncategorized && (
                          <WarningIcon className={styles.warningIcon} />
                        )}
                        {[
                          OngoingReportingType.CapTable,
                          OngoingReportingType.OrgChart,
                          OngoingReportingType.Uncategorized,
                        ].includes(item.type as OngoingReportingType) ? (
                          PROSPECT_REPORTING_MAP[item.type]
                        ) : (
                          <Link
                            component={RouterLink}
                            to={generatePath(PROSPECT_REPORTING_ROUTES[item.type], {
                              id: item.opsReportingId,
                            })}
                          >
                            {PROSPECT_REPORTING_MAP[item.type]}
                          </Link>
                        )}
                      </TableCell>
                      {!readOnly && (
                        <TableCell className={genericSs.tableTextLeft}>
                          {item?.boxLink && <BoxLink link={item.boxLink} size="small" />}
                        </TableCell>
                      )}
                      <TableCell className={genericSs.tableTextRight}>
                        <Tooltip
                          title={item.submittedDate ? formatDateTime(item.submittedDate) : '-'}
                        >
                          <span>
                            {item.submittedDate
                              ? formatDateCalendarNoTime(item.submittedDate)
                              : '-'}
                          </span>
                        </Tooltip>
                      </TableCell>
                      {opsReportingId && (
                        <TableCell className={genericSs.tableTextRight}>
                          <Tooltip
                            title={item.completedAt ? formatDateTime(item.completedAt) : '-'}
                          >
                            <span>
                              {item.completedAt ? formatDateCalendarNoTime(item.completedAt) : '-'}
                            </span>
                          </Tooltip>
                        </TableCell>
                      )}
                      <TableCell className={genericSs.tableTextRight}>
                        {item.type === OngoingReportingType.Uncategorized
                          ? '-'
                          : item.monthsProcessed
                          ? formatDate(item.monthsProcessed)
                          : '-'}
                      </TableCell>
                      <TableCell
                        className={cn(
                          genericSs.tableTextLeft,
                          styles.reportingStatus,
                          getRowStyle(item.status),
                        )}
                      >
                        {item.type === OngoingReportingType.Uncategorized
                          ? 'Report select'
                          : item.status}
                      </TableCell>
                      {!readOnly && (
                        <TableCell className={genericSs.tableTextLeft}>
                          {item.type === OngoingReportingType.Uncategorized ? (
                            <ProspectAnalysisQueueUncategorizedActions
                              handleManage={handleManageUncategorizedFiles}
                              handleDelete={handleDeleteUncategorizedFiles}
                            />
                          ) : ![
                              OngoingReportingType.CapTable,
                              OngoingReportingType.OrgChart,
                            ].includes(item.type as OngoingReportingType) &&
                            (!isUW || (isUW && item.status === OPSReportingFlowStatus.Complete)) ? (
                            <ProspectAnalysisQueueActions
                              item={item}
                              updateOngoingReportingStatus={handleUpdateReportingStatus}
                              reportingRoutes={PROSPECT_REPORTING_ROUTES}
                              opsReportingId={item.opsReportingId}
                              type={item.type}
                            />
                          ) : null}
                        </TableCell>
                      )}
                    </TableRow>
                  ))}
                </InfiniteScroll>
              )
            )}
          </TableBody>
        </Table>
        <Grid container justifyContent={'flex-end'}>
          <SaveState isSaving={isSaving} isSaved={isSaved} />
        </Grid>
      </TableContainer>
    </Card>
  )
}

export default ProspectAnalysisQueueTable
