import { IApi } from '../../api'
import { CommonPromiseAction } from '../types'
import { createRequestTypes } from '../../helpers/helpers'
export const prefix = 'entityInfo'

export const LIST_ENTITY_INFO_REQUEST = `${prefix}/LIST_ENTITY_INFO_REQUEST`
export const LIST_ENTITY_INFO_SUCCESS = `${prefix}/LIST_ENTITY_INFO_SUCCESS`
export const LIST_ENTITY_INFO_FAILURE = `${prefix}/LIST_ENTITY_INFO_FAILURE`

export const listEntityInfo: CommonPromiseAction = (data: object = {}) => ({
  type: [LIST_ENTITY_INFO_REQUEST, LIST_ENTITY_INFO_SUCCESS, LIST_ENTITY_INFO_FAILURE],
  promise: (api: IApi) => api.entityInfo.listEntityInfo(data),
})

export const GET_CUSTOMER_AGGREGATION_REQUEST = `${prefix}/GET_CUSTOMER_AGGREGATION_REQUEST`
export const GET_CUSTOMER_AGGREGATION_SUCCESS = `${prefix}/GET_CUSTOMER_AGGREGATION_SUCCESS`
export const GET_CUSTOMER_AGGREGATION_FAILURE = `${prefix}/GET_CUSTOMER_AGGREGATION_FAILURE`

export const listCustomers: CommonPromiseAction = (data: object = {}) => ({
  type: [
    GET_CUSTOMER_AGGREGATION_REQUEST,
    GET_CUSTOMER_AGGREGATION_SUCCESS,
    GET_CUSTOMER_AGGREGATION_FAILURE,
  ],
  promise: (api: IApi) => api.entityInfo.listCustomers(data),
  params: data,
})

export const GET_VENDOR_AGGREGATION_REQUEST = `${prefix}/GET_VENDOR_AGGREGATION_REQUEST`
export const GET_VENDOR_AGGREGATION_SUCCESS = `${prefix}/GET_VENDOR_AGGREGATION_SUCCESS`
export const GET_VENDOR_AGGREGATION_FAILURE = `${prefix}/GET_VENDOR_AGGREGATION_FAILURE`

export const listVendors: CommonPromiseAction = (data: object = {}) => ({
  type: [
    GET_VENDOR_AGGREGATION_REQUEST,
    GET_VENDOR_AGGREGATION_SUCCESS,
    GET_VENDOR_AGGREGATION_FAILURE,
  ],
  promise: (api: IApi) => api.entityInfo.listVendors(data),
  params: data,
})

const LIST_INVESTORS = createRequestTypes(prefix, 'LIST_INVESTORS')
export const [LIST_INVESTORS_REQUEST, LIST_INVESTORS_SUCCESS, LIST_INVESTORS_FAILURE] =
  LIST_INVESTORS

export const listInvestors: CommonPromiseAction = (data: object = {}) => ({
  type: [LIST_INVESTORS_REQUEST, LIST_INVESTORS_SUCCESS, LIST_INVESTORS_FAILURE],
  promise: (api: IApi) => api.entityInfo.listInvestors(data),
  params: data,
})

const EXPORT_INVESTORS = createRequestTypes(prefix, 'EXPORT_INVESTORS')
export const [EXPORT_INVESTORS_REQUEST, EXPORT_INVESTORS_SUCCESS, EXPORT_INVESTORS_FAILURE] =
  EXPORT_INVESTORS

export const exportInvestors: CommonPromiseAction = (params: object) => ({
  type: [EXPORT_INVESTORS_REQUEST, EXPORT_INVESTORS_SUCCESS, EXPORT_INVESTORS_FAILURE],
  promise: (api: IApi) => api.entityInfo.listInvestors({ ...params, isFile: true }),
  params,
})

const LIST_SUMMARY_INVESTORS = createRequestTypes(prefix, 'LIST_SUMMARY_INVESTORS')
export const [
  LIST_SUMMARY_INVESTORS_REQUEST,
  LIST_SUMMARY_INVESTORS_SUCCESS,
  LIST_SUMMARY_INVESTORS_FAILURE,
] = LIST_SUMMARY_INVESTORS

export const listInvestorSummary: CommonPromiseAction = (id: string, params: object) => ({
  type: [
    LIST_SUMMARY_INVESTORS_REQUEST,
    LIST_SUMMARY_INVESTORS_SUCCESS,
    LIST_SUMMARY_INVESTORS_FAILURE,
  ],
  promise: (api: IApi) => api.entityInfo.listInvestorSummary(id, params),
  params,
})

const GET_INVESTMENT_ROW_DETAIL = createRequestTypes(prefix, 'GET_INVESTMENT_ROW_DETAIL')
export const [
  GET_INVESTMENT_ROW_DETAIL_REQUEST,
  GET_INVESTMENT_ROW_DETAIL_SUCCESS,
  GET_INVESTMENT_ROW_DETAIL_FAILURE,
] = GET_INVESTMENT_ROW_DETAIL

export const getInvestmentDetailRow: CommonPromiseAction = (id: string, params: object) => ({
  type: [
    GET_INVESTMENT_ROW_DETAIL_REQUEST,
    GET_INVESTMENT_ROW_DETAIL_SUCCESS,
    GET_INVESTMENT_ROW_DETAIL_FAILURE,
  ],
  promise: (api: IApi) => api.entityInfo.getInvestmentDetailRow(id, params),
  params,
})

export const EXPORT_CUSTOMERS_REQUEST = `${prefix}/EXPORT_CUSTOMERS_REQUEST`
export const EXPORT_CUSTOMERS_SUCCESS = `${prefix}/EXPORT_CUSTOMERS_SUCCESS`
export const EXPORT_CUSTOMERS_FAILURE = `${prefix}/EXPORT_CUSTOMERS_FAILURE`

export const exportCustomers: CommonPromiseAction = (params: object) => ({
  type: [EXPORT_CUSTOMERS_REQUEST, EXPORT_CUSTOMERS_SUCCESS, EXPORT_CUSTOMERS_FAILURE],
  promise: (api: IApi) =>
    api.entityInfo.exportEntities({
      ...params,
      isExport: true,
      entityType: 'customer',
    }),
  params,
})

export const EXPORT_VENDORS_REQUEST = `${prefix}/EXPORT_VENDORS_REQUEST`
export const EXPORT_VENDORS_SUCCESS = `${prefix}/EXPORT_VENDORS_SUCCESS`
export const EXPORT_VENDORS_FAILURE = `${prefix}/EXPORT_VENDORS_FAILURE`

export const exportVendors: CommonPromiseAction = (params?: {
  page?: number
  perPage?: number
  filters?: object
  orderBy?: string
  orderDirection?: string
}) => ({
  type: [EXPORT_VENDORS_REQUEST, EXPORT_VENDORS_SUCCESS, EXPORT_VENDORS_FAILURE],
  promise: (api: IApi) =>
    api.entityInfo.exportEntities({
      ...params,
      isExport: true,
      entityType: 'vendor',
    }),
  params,
})

export const ADD_ENTITY_INFO_REQUEST = `${prefix}/ADD_ENTITY_INFO_REQUEST`
export const ADD_ENTITY_INFO_SUCCESS = `${prefix}/ADD_ENTITY_INFO_SUCCESS`
export const ADD_ENTITY_INFO_FAILURE = `${prefix}/ADD_ENTITY_INFO_FAILURE`

export const addEntityInfo: CommonPromiseAction = (data: object) => ({
  type: [ADD_ENTITY_INFO_REQUEST, ADD_ENTITY_INFO_SUCCESS, ADD_ENTITY_INFO_FAILURE],
  promise: (api: IApi) => api.entityInfo.addEntityInfo(data),
})

export const GET_ENTITY_INFO_REQUEST = `${prefix}/GET_ENTITY_INFO_REQUEST`
export const GET_ENTITY_INFO_SUCCESS = `${prefix}/GET_ENTITY_INFO_SUCCESS`
export const GET_ENTITY_INFO_FAILURE = `${prefix}/GET_ENTITY_INFO_FAILURE`

export const getEntityInfo: CommonPromiseAction = (id: string) => ({
  type: [GET_ENTITY_INFO_REQUEST, GET_ENTITY_INFO_SUCCESS, GET_ENTITY_INFO_FAILURE],
  promise: (api: IApi) => api.entityInfo.getEntityInfo(id),
})
export const HIDE_ENTITY_INFO = `${prefix}/HIDE_ENTITY_INFO`

export const hideEntityInfo: CommonPromiseAction = () => ({
  type: HIDE_ENTITY_INFO,
})

export const GET_ENTITY_AR_SUMMARY_REQUEST = `${prefix}/GET_ENTITY_AR_SUMMARY_REQUEST`
export const GET_ENTITY_AR_SUMMARY_SUCCESS = `${prefix}/GET_ENTITY_AR_SUMMARY_SUCCESS`
export const GET_ENTITY_AR_SUMMARY_FAILURE = `${prefix}/GET_ENTITY_AR_SUMMARY_FAILURE`

export const getEntityArSummary: CommonPromiseAction = (id: string, params: object) => ({
  type: [
    GET_ENTITY_AR_SUMMARY_REQUEST,
    GET_ENTITY_AR_SUMMARY_SUCCESS,
    GET_ENTITY_AR_SUMMARY_FAILURE,
  ],
  promise: (api: IApi) => api.entityInfo.getArSummary(id, params),
  params,
})

export const GET_ENTITY_AR_DETAILS_REQUEST = `${prefix}/GET_ENTITY_AR_DETAILS_REQUEST`
export const GET_ENTITY_AR_DETAILS_SUCCESS = `${prefix}/GET_ENTITY_AR_DETAILS_SUCCESS`
export const GET_ENTITY_AR_DETAILS_FAILURE = `${prefix}/GET_ENTITY_AR_DETAILS_FAILURE`

export const getEntityArDetails: CommonPromiseAction = (id: string, params: object) => ({
  type: [
    GET_ENTITY_AR_DETAILS_REQUEST,
    GET_ENTITY_AR_DETAILS_SUCCESS,
    GET_ENTITY_AR_DETAILS_FAILURE,
  ],
  promise: (api: IApi) => api.entityInfo.getArDetails(id, params),
  params,
})

export const GET_ENTITY_AP_SUMMARY_REQUEST = `${prefix}/GET_ENTITY_AP_SUMMARY_REQUEST`
export const GET_ENTITY_AP_SUMMARY_SUCCESS = `${prefix}/GET_ENTITY_AP_SUMMARY_SUCCESS`
export const GET_ENTITY_AP_SUMMARY_FAILURE = `${prefix}/GET_ENTITY_AP_SUMMARY_FAILURE`

export const getEntityApSummary: CommonPromiseAction = (id: string, params: object) => ({
  type: [
    GET_ENTITY_AP_SUMMARY_REQUEST,
    GET_ENTITY_AP_SUMMARY_SUCCESS,
    GET_ENTITY_AP_SUMMARY_FAILURE,
  ],
  promise: (api: IApi) => api.entityInfo.getApSummary(id, params),
  params,
})

export const GET_ENTITY_AP_DETAILS_REQUEST = `${prefix}/GET_ENTITY_AP_DETAILS_REQUEST`
export const GET_ENTITY_AP_DETAILS_SUCCESS = `${prefix}/GET_ENTITY_AP_DETAILS_SUCCESS`
export const GET_ENTITY_AP_DETAILS_FAILURE = `${prefix}/GET_ENTITY_AP_DETAILS_FAILURE`

export const getEntityApDetails: CommonPromiseAction = (id: string, params: object) => ({
  type: [
    GET_ENTITY_AP_DETAILS_REQUEST,
    GET_ENTITY_AP_DETAILS_SUCCESS,
    GET_ENTITY_AP_DETAILS_FAILURE,
  ],
  promise: (api: IApi) => api.entityInfo.getApDetails(id, params),
  params,
})

export const GET_ENTITY_DILUTION_REQUEST = `${prefix}/GET_ENTITY_DILUTION_REQUEST`
export const GET_ENTITY_DILUTION_SUCCESS = `${prefix}/GET_ENTITY_DILUTION_SUCCESS`
export const GET_ENTITY_DILUTION_FAILURE = `${prefix}/GET_ENTITY_DILUTION_FAILURE`

export const getEntityDilution: CommonPromiseAction = (id: string, params: object) => ({
  type: [GET_ENTITY_DILUTION_REQUEST, GET_ENTITY_DILUTION_SUCCESS, GET_ENTITY_DILUTION_FAILURE],
  promise: (api: IApi) => api.entityInfo.getDilution(id, params),
  params,
})

export const GET_ENTITY_ACTIVITY_REQUEST = `entity/GET_ENTITY_ACTIVITY_REQUEST`
export const GET_ENTITY_ACTIVITY_SUCCESS = `entity/GET_ENTITY_ACTIVITY_SUCCESS`
export const GET_ENTITY_ACTIVITY_FAILURE = `entity/GET_ENTITY_ACTIVITY_FAILURE`

export const getEntityActivity: CommonPromiseAction = (id: string, params: any) => ({
  type: [GET_ENTITY_ACTIVITY_REQUEST, GET_ENTITY_ACTIVITY_SUCCESS, GET_ENTITY_ACTIVITY_FAILURE],
  promise: (api: IApi) => api.entityInfo.getActivity(id, params),
  params,
})

export const GET_ALIASES_REQUEST = `${prefix}/GET_ALIASES_REQUEST`
export const GET_ALIASES_SUCCESS = `${prefix}/GET_ALIASES_SUCCESS`
export const GET_ALIASES_FAILURE = `${prefix}/GET_ALIASES_FAILURE`

export const listAliasMapping: CommonPromiseAction = (id: string, data: any) => ({
  type: [GET_ALIASES_REQUEST, GET_ALIASES_SUCCESS, GET_ALIASES_FAILURE],
  promise: (api: IApi) => api.entityInfo.listAliasMapping(id, data),
  params: data,
})

export const LIST_ALIAS_REQUEST = `${prefix}/LIST_ALIAS_REQUEST`
export const LIST_ALIAS_SUCCESS = `${prefix}/LIST_ALIAS_SUCCESS`
export const LIST_ALIAS_FAILURE = `${prefix}/LIST_ALIAS_FAILURE`

export const listEntityAliases: CommonPromiseAction = (id: string, data: any) => ({
  type: [LIST_ALIAS_REQUEST, LIST_ALIAS_SUCCESS, LIST_ALIAS_FAILURE],
  promise: (api: IApi) => api.entityInfo.listEntityAliases(id, data),
  params: data,
})

export const UPDATE_ENTITY_INFO_REQUEST = `${prefix}/UPDATE_ENTITY_INFO_REQUEST`
export const UPDATE_ENTITY_INFO_SUCCESS = `${prefix}/UPDATE_ENTITY_INFO_SUCCESS`
export const UPDATE_ENTITY_INFO_FAILURE = `${prefix}/UPDATE_ENTITY_INFO_FAILURE`

export const updateEntityInfo: CommonPromiseAction = (id: string, data: object) => ({
  type: [UPDATE_ENTITY_INFO_REQUEST, UPDATE_ENTITY_INFO_SUCCESS, UPDATE_ENTITY_INFO_FAILURE],
  promise: (api: IApi) => api.entityInfo.updateEntityInfo(id, data),
})

export const GET_ENTITY_DUPLICATE_ENTITIES_REQUEST = `${prefix}/GET_ENTITY_DUPLICATE_ENTITIES_REQUEST`
export const GET_ENTITY_DUPLICATE_ENTITIES_SUCCESS = `${prefix}/GET_ENTITY_DUPLICATE_ENTITIES_SUCCESS`
export const GET_ENTITY_DUPLICATE_ENTITIES_FAILURE = `${prefix}/GET_ENTITY_DUPLICATE_ENTITIES_FAILURE`

export const getEntityInfoDuplicates: CommonPromiseAction = (id: string) => ({
  type: [
    GET_ENTITY_DUPLICATE_ENTITIES_REQUEST,
    GET_ENTITY_DUPLICATE_ENTITIES_SUCCESS,
    GET_ENTITY_DUPLICATE_ENTITIES_FAILURE,
  ],
  promise: (api: IApi) => api.entityInfo.getEntityInfoDuplicates(id),
})

export const UPDATE_DUPLICATE_ENTITY_REQUEST = `${prefix}/UPDATE_DUPLICATE_ENTITY_REQUEST`
export const UPDATE_DUPLICATE_ENTITY_SUCCESS = `${prefix}/UPDATE_DUPLICATE_ENTITY_SUCCESS`
export const UPDATE_DUPLICATE_ENTITY_FAILURE = `${prefix}/UPDATE_DUPLICATE_ENTITY_FAILURE`

export const updateDuplicateEntity: CommonPromiseAction = (id: string, data: object) => ({
  type: [
    UPDATE_DUPLICATE_ENTITY_REQUEST,
    UPDATE_DUPLICATE_ENTITY_SUCCESS,
    UPDATE_DUPLICATE_ENTITY_FAILURE,
  ],
  promise: (api: IApi) => api.entityInfo.updateDuplicateEntity(id, data),
})

export const HIDE_ALIASES = `${prefix}/HIDE_ALIASES`

export const hideAliases: CommonPromiseAction = () => ({
  type: HIDE_ALIASES,
})

export const LIST_ENTITY_AR_REQUEST = `${prefix}/LIST_ENTITY_AR_REQUEST`
export const LIST_ENTITY_AR_SUCCESS = `${prefix}/LIST_ENTITY_AR_SUCCESS`
export const LIST_ENTITY_AR_FAILURE = `${prefix}/LIST_ENTITY_AR_FAILURE`

export const listEntityAr: CommonPromiseAction = (id: string, data: object) => ({
  type: [LIST_ENTITY_AR_REQUEST, LIST_ENTITY_AR_SUCCESS, LIST_ENTITY_AR_FAILURE],
  promise: (api: IApi) => api.entityInfo.listEntityAr(id, data),
  params: data,
})

export const UPDATE_ENTITY_AR_REQUEST = `${prefix}/UPDATE_ENTITY_AR_REQUEST`
export const UPDATE_ENTITY_AR_SUCCESS = `${prefix}/UPDATE_ENTITY_AR_SUCCESS`
export const UPDATE_ENTITY_AR_FAILURE = `${prefix}/UPDATE_ENTITY_AR_FAILURE`

export const updateEntityAr: CommonPromiseAction = (id: string, itemId: string, data: object) => ({
  type: [UPDATE_ENTITY_AR_REQUEST, UPDATE_ENTITY_AR_SUCCESS, UPDATE_ENTITY_AR_FAILURE],
  promise: (api: IApi) => api.entityInfo.updateEntityAr(id, itemId, data),
})

export const LIST_ENTITY_AP_REQUEST = `${prefix}/LIST_ENTITY_AP_REQUEST`
export const LIST_ENTITY_AP_SUCCESS = `${prefix}/LIST_ENTITY_AP_SUCCESS`
export const LIST_ENTITY_AP_FAILURE = `${prefix}/LIST_ENTITY_AP_FAILURE`

export const listEntityAp: CommonPromiseAction = (id: string, data: object) => ({
  type: [LIST_ENTITY_AP_REQUEST, LIST_ENTITY_AP_SUCCESS, LIST_ENTITY_AP_FAILURE],
  promise: (api: IApi) => api.entityInfo.listEntityAp(id, data),
  params: data,
})

export const UPDATE_ENTITY_AP_REQUEST = `${prefix}/UPDATE_ENTITY_AP_REQUEST`
export const UPDATE_ENTITY_AP_SUCCESS = `${prefix}/UPDATE_ENTITY_AP_SUCCESS`
export const UPDATE_ENTITY_AP_FAILURE = `${prefix}/UPDATE_ENTITY_AP_FAILURE`

export const updateEntityAp: CommonPromiseAction = (id: string, itemId: string, data: object) => ({
  type: [UPDATE_ENTITY_AP_REQUEST, UPDATE_ENTITY_AP_SUCCESS, UPDATE_ENTITY_AP_FAILURE],
  promise: (api: IApi) => api.entityInfo.updateEntityAp(id, itemId, data),
})

export const ADD_ENTITY_RELATIONSHIP_REQUEST = `${prefix}/ADD_ENTITY_RELATIONSHIP_REQUEST`
export const ADD_ENTITY_RELATIONSHIP_SUCCESS = `${prefix}/ADD_ENTITY_RELATIONSHIP_SUCCESS`
export const ADD_ENTITY_RELATIONSHIP_FAILURE = `${prefix}/ADD_ENTITY_RELATIONSHIP_FAILURE`

export const addEntityRelationship: CommonPromiseAction = (id: string, parentId: string) => ({
  type: [
    ADD_ENTITY_RELATIONSHIP_REQUEST,
    ADD_ENTITY_RELATIONSHIP_SUCCESS,
    ADD_ENTITY_RELATIONSHIP_FAILURE,
  ],
  promise: (api: IApi) => api.entityInfo.addRelationship(id, parentId),
})

export const DELETE_ENTITY_RELATIONSHIP_REQUEST = `${prefix}/DELETE_ENTITY_RELATIONSHIP_REQUEST`
export const DELETE_ENTITY_RELATIONSHIP_SUCCESS = `${prefix}/DELETE_ENTITY_RELATIONSHIP_SUCCESS`
export const DELETE_ENTITY_RELATIONSHIP_FAILURE = `${prefix}/DELETE_ENTITY_RELATIONSHIP_FAILURE`

export const deleteEntityRelationship: CommonPromiseAction = (id: string, parentId: string) => ({
  type: [
    DELETE_ENTITY_RELATIONSHIP_REQUEST,
    DELETE_ENTITY_RELATIONSHIP_SUCCESS,
    DELETE_ENTITY_RELATIONSHIP_FAILURE,
  ],
  promise: (api: IApi) => api.entityInfo.deleteRelationship(id, parentId),
})

export const LIST_ENTITY_ACCOUNTS = createRequestTypes(prefix, 'LIST_ENTITY_ACCOUNTS')
export const [LIST_ENTITY_ACCOUNTS_REQUEST] = LIST_ENTITY_ACCOUNTS

export const listEntityAccounts: CommonPromiseAction = (id: string, data: any) => ({
  type: LIST_ENTITY_ACCOUNTS,
  promise: (api: IApi) => api.entityInfo.listEntityAccounts(id, data),
  params: data,
})

export const HIDE_ENTITY_ACCOUNTS = `${prefix}/HIDE_ENTITY_ACCOUNTS`

export const hideEntityAccounts: CommonPromiseAction = () => ({
  type: HIDE_ENTITY_ACCOUNTS,
})

export const UPDATE_ENTITY_ACCOUNTS = createRequestTypes(prefix, 'UPDATE_ENTITY_ACCOUNTS')
export const [UPDATE_ENTITY_ACCOUNTS_REQUEST] = UPDATE_ENTITY_ACCOUNTS

export const updateEntityAccounts: CommonPromiseAction = (id: string, data: object) => ({
  type: UPDATE_ENTITY_ACCOUNTS,
  promise: (api: IApi) => api.entityInfo.updateEntityAccounts(id, data),
})

const GET_ENTITY_PREVIEW = createRequestTypes(prefix, 'GET_ENTITY_PREVIEW')

export const getEntityPreview: CommonPromiseAction = (id: string, data: object = {}) => ({
  type: GET_ENTITY_PREVIEW,
  promise: (api: IApi) => api.entityInfo.getEntityPreview(id, data),
})
