import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'

import InvestorSummaryTable from './InvestorSummaryTable'

import { investorAggregation } from '../../redux/entityInfo/selectors'
import { listInvestors, exportInvestors } from '../../redux/entityInfo/actions'

const selector = createStructuredSelector({
  investorAggregation,
})
const actions = {
  listInvestors,
  exportInvestors,
}

export default connect(selector, actions)(InvestorSummaryTable)
