import React, { useCallback, useState } from 'react'

import { IAccountActivity } from '@common/interfaces/client'
import WarningModal from '../../WarningModal'

interface IProps {
  restoreFee: (id: string) => Promise<void>
  activeItems: IAccountActivity[]
  handleCancel: () => void
  handleConfirm: () => void
}

const ClientAccountActivityRestoreModal = ({
  restoreFee,
  activeItems,
  handleCancel,
  handleConfirm,
}: IProps) => {
  const [isLoading, setIsLoading] = useState(false)
  const handleRestoreConfirm = useCallback(async () => {
    setIsLoading(true)
    const fee = activeItems.find((row) => row.isDeletable && row.isDeleted)
    if (fee) {
      await restoreFee(fee.id)
    }
    handleConfirm()
    setIsLoading(false)
  }, [activeItems, restoreFee, handleConfirm])

  return (
    <WarningModal
      warningMessage="Account activity will be restored and current amount will be recalculated."
      onConfirm={handleRestoreConfirm}
      onCancel={handleCancel}
      confirmText="Restore"
      cancelText="Cancel"
      isLoading={isLoading}
    />
  )
}

export default ClientAccountActivityRestoreModal
