import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'

import { entity } from '../../redux/entityInfo/selectors'
import { getEntityInfo } from '../../redux/entityInfo/actions'

import EntitySettingsHeader from './EntitySettingsHeader'

const selector = createStructuredSelector({
  entity,
})

const actions = {
  getEntityInfo,
}

export default connect(selector, actions)(EntitySettingsHeader)
