import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'

import EntitySettingsApTable from './EntitySettingsApTable'

import { entityAp } from '../../redux/entityInfo/selectors'

import { listEntityAp, updateEntityAp } from '../../redux/entityInfo/actions'

const selector = createStructuredSelector({
  entityAp,
})

const actions = {
  listEntityAp,
  updateEntityAp,
}

export default connect(selector, actions)(EntitySettingsApTable)
