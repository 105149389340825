import React, { useEffect, useMemo, useState } from 'react'
import Skeleton from '@mui/material/Skeleton'
import { Chart } from 'react-chartjs-2'
import cn from 'classnames'

import styles from './DilutionOverTime.module.scss'

import { ReportingPeriods } from '@common/interfaces/bbc'
import { formatPrice, formatPercent } from '../../helpers/helpers'
import { BORDER_RADIUS, FONT_SIZE, TOOLTIP } from '../../constants/graphs'
import {} from '@common/constants/client'
import {
  CLIENT_GENERAL_LEDGER_DILUTION_FIELDS_LABELS,
  CLIENT_GENERAL_LEDGER_DILUTION_GRAPH_FIELDS,
  CLIENT_GENERAL_LEDGER_DILUTION_GRAPH_FIELDS_COLORS,
  CLIENT_GENERAL_LEDGER_DILUTION_GRAPH_LINE_FIELDS,
  IClientGeneralLedgerDilutionOverTime,
} from '@common/interfaces/clientGeneralLedger'

interface IProps {
  isModalShown: boolean
  dilutionOverTimeHeaders: string[]
  dilutionOverTimeData: IClientGeneralLedgerDilutionOverTime[]
  reportingPeriod: ReportingPeriods
  options: string[]
}

const DilutionOverTimeGraph = ({
  isModalShown,
  dilutionOverTimeHeaders,
  dilutionOverTimeData,
  reportingPeriod,
  options,
}: IProps) => {
  const [isInitialLoading, setIsInitialLoading] = useState(true)

  useEffect(() => {
    if (dilutionOverTimeData) {
      setIsInitialLoading(false)
    }
  }, [dilutionOverTimeData])

  const dilutionOverTimeNormalizedData = useMemo(
    () =>
      dilutionOverTimeHeaders
        .map((_, index) =>
          dilutionOverTimeData.reduce(
            (result, row) => {
              result.invoices += row.invoices[index]
              result.payments += row.payments[index]
              result.dilutiveCredits += row.dilutiveCredits[index]
              result.other += row.other[index]

              return result
            },
            {
              invoices: 0,
              payments: 0,
              dilutiveCredits: 0,
              other: 0,
            },
          ),
        )
        .map((item) => ({
          ...item,
          dilution:
            item.invoices && item.dilutiveCredits
              ? Math.abs(item.dilutiveCredits / item.invoices)
              : 0,
        })),
    [dilutionOverTimeData, dilutionOverTimeHeaders],
  )

  const chartData = useMemo(() => {
    if (dilutionOverTimeNormalizedData) {
      return {
        labels: dilutionOverTimeHeaders.map((header: string) =>
          reportingPeriod === ReportingPeriods.Monthly
            ? header.replace(/20\d{2}/g, (year) => year.slice(2))
            : header,
        ),
        stepped: true,
        datasets: [
          ...CLIENT_GENERAL_LEDGER_DILUTION_GRAPH_FIELDS.filter((field) =>
            options.includes(field),
          ).map((field) => ({
            label: CLIENT_GENERAL_LEDGER_DILUTION_FIELDS_LABELS[field],
            field,
            data: dilutionOverTimeHeaders.map(
              (_, index) => dilutionOverTimeNormalizedData[index][field],
            ),
            backgroundColor: CLIENT_GENERAL_LEDGER_DILUTION_GRAPH_FIELDS_COLORS[field],
            borderRadius: BORDER_RADIUS,
            type: CLIENT_GENERAL_LEDGER_DILUTION_GRAPH_LINE_FIELDS.includes(field)
              ? ('line' as const)
              : ('bar' as const),
            order: CLIENT_GENERAL_LEDGER_DILUTION_GRAPH_LINE_FIELDS.includes(field) ? 0 : 1,
            pointStyle: 'line',
            pointRadius: 20,
            pointHoverRadius: 20,
            pointHoverBorderWidth: 3,
            pointBorderWidth: 3,
            pointBorderColor: CLIENT_GENERAL_LEDGER_DILUTION_GRAPH_FIELDS_COLORS[field],
            showLine: false,
            yAxisID: CLIENT_GENERAL_LEDGER_DILUTION_GRAPH_LINE_FIELDS.includes(field) ? 'y1' : 'y',
          })),
        ],
      }
    } else {
      return null
    }
  }, [dilutionOverTimeHeaders, dilutionOverTimeNormalizedData, reportingPeriod, options])

  const chartOptions = useMemo(() => {
    return {
      maintainAspectRatio: false,
      scales: {
        x: {
          stacked: false,
          grid: {
            display: false,
          },
          ticks: {
            font: {
              size: FONT_SIZE,
            },
          },
        },
        y: {
          stacked: false,
          grid: {
            display: false,
          },
          ticks: {
            font: {
              size: FONT_SIZE,
            },
            callback: (value: any) => {
              return formatPrice(value) ? `$${formatPrice(value)}` : `$0`
            },
          },
        },
        y1: {
          min: 0,
          max: 1,
          grid: {
            display: false,
          },
          ticks: {
            count: 6,
            font: {
              size: FONT_SIZE,
            },
            callback: (value: any) => {
              return formatPercent(value || 0)
            },
          },
          beforeSetDimensions: (axis: any) => {
            if (!axis.chart.scales.y.max) {
              axis.chart.scales.y.max = Math.abs(axis.chart.scales.y.min)
            }
            if (axis.chart.scales.y.min < 0) {
              const ratio = axis.chart.scales.y.max / Math.abs(axis.chart.scales.y.min)
              axis.min = (axis.max / ratio) * -1
            }
          },
          position: 'right' as const,
        },
      },
      plugins: {
        legend: {
          position: 'top' as const,
          labels: {
            font: {
              size: FONT_SIZE,
              weight: '500',
            },
            color: 'black',
            boxWidth: 20,
            boxHeight: 20,
            sort: (a: any, b: any) => {
              return a.datasetIndex - b.datasetIndex
            },
          },
        },
        tooltip: {
          ...TOOLTIP,
          itemSort: (a: any, b: any) => {
            return a.datasetIndex - b.datasetIndex
          },
          callbacks: {
            label: (tooltipItem: any) => {
              const datasetIndex = tooltipItem.datasetIndex
              const dataIndex = tooltipItem.dataIndex
              const yAxisID = chartData.datasets[datasetIndex].yAxisID
              const originalValue = chartData.datasets[datasetIndex].data[dataIndex]
              const title = chartData.datasets[datasetIndex].label

              if (yAxisID === 'y1') {
                return `${title}: ${formatPercent(originalValue || 0)}`
              } else {
                return `${title}: $${formatPrice(originalValue || 0)}`
              }
            },
          },
        },
      },
    }
  }, [chartData])

  return (
    <div
      className={cn(styles.chartContainer, {
        [styles.fullScreen]: isModalShown,
      })}
    >
      {isInitialLoading ? (
        <Skeleton variant="rectangular" width="100%" height="100%" />
      ) : (
        chartData && <Chart type="bar" data={chartData} options={chartOptions} />
      )}
    </div>
  )
}

export default DilutionOverTimeGraph
