export type TimelineYear = {
  year: string
  label: string
}

export type MetricId =
  | 'changeInGrossRevenue'
  | 'discountsPercentage'
  | 'returnsPercentage'
  | 'grossMargin'
  | 'changeInPayroll'
  | 'changeInSalesAndMarketing'
  | 'changeInOtherExpenses'

export type FinancialMetric = {
  id: MetricId
  label: string
  type: 'percent' | 'currency' | 'number'
}
export type MetricValue = {
  value: number
  isEditable: boolean
  yearId: string
  metricId: MetricId
  assumptionId?: string
  type?: 'percent' | 'currency' | 'number'
}

export type StressCaseData = {
  timeline: TimelineYear[]
  values: MetricValue[]
}

// Predefined metrics for the stress case table
export const STRESS_CASE_METRICS: FinancialMetric[] = [
  {
    id: 'changeInGrossRevenue',
    label: 'Change in Gross Revenue',
    type: 'percent',
  },
  {
    id: 'discountsPercentage',
    label: 'Trade Discounts as % of Gross Revenue',
    type: 'percent',
  },
  {
    id: 'returnsPercentage',
    label: 'Returns as % of Gross Revenue',
    type: 'percent',
  },
  {
    id: 'grossMargin',
    label: 'Gross Margin',
    type: 'percent',
  },
  {
    id: 'changeInPayroll',
    label: 'Change in Payroll',
    type: 'percent',
  },
  {
    id: 'changeInSalesAndMarketing',
    label: 'Change in Sales & Marketing',
    type: 'percent',
  },
  {
    id: 'changeInOtherExpenses',
    label: 'Change in Other Expenses',
    type: 'percent',
  },
]

export interface IFinancialSummary {
  year: number
  grossRevenue: number
  tradeDiscounts: number
  returnsRefunds: number
  netRevenue: number
  grossProfit: number
  payroll: number
  salesAndMarketing: number
  otherOperatingExpenses: number
}

export interface IFormattedFinancialSummary extends IFinancialSummary {
  tradeDiscountsPercentage: number
  returnsPercentage: number
  grossMargin: number
}

export interface IStressCaseAssumption {
  id: string
  year: number
  changeInGrossRevenue?: number
  discountsPercentage?: number
  returnsPercentage?: number
  grossMargin?: number
  changeInPayroll?: number
  changeInSalesAndMarketing?: number
  changeInOtherExpenses?: number
}
