import { IApi } from '../../api'
import { CommonPromiseAction } from '../types'
import { createRequestTypes } from '../../helpers/helpers'

export const prefix = 'options'

const GET_DEBTOR_INELIGIBLE_CATEGORIES = createRequestTypes(
  prefix,
  'GET_DEBTOR_INELIGIBLE_CATEGORIES',
)
export const [GET_DEBTOR_INELIGIBLE_CATEGORIES_REQUEST] = GET_DEBTOR_INELIGIBLE_CATEGORIES

export const getDebtorIneligibleCategories: CommonPromiseAction = (params: object) => ({
  type: GET_DEBTOR_INELIGIBLE_CATEGORIES,
  promise: (api: IApi) => api.option.getDebtorIneligibleCategories(params),
  params,
})
