import React, { useCallback, useMemo } from 'react'
import Card from '../../Common/Card'
import Grid from '@mui/material/Grid'
import {
  IClientInfo,
  allClientTermsInfo,
  clientTermsInfo,
  ClientSetupSections,
} from '@common/interfaces/client'
import { ClientABLField } from '../ClientHelpers'
import { Form, FormSpy } from 'react-final-form'
import { dateToString } from '../../../helpers/helpers'
const FIELDS_MAP = allClientTermsInfo.filter(
  (term) => term.section === ClientSetupSections.Reporting,
)

interface IProps {
  clientInfo: IClientInfo
  updateClient: (id: string, values: any) => void
}

const InsuranceInformation = ({ clientInfo, updateClient }: IProps) => {
  const handleClientUpdate = useCallback(
    (values) => {
      const { id } = clientInfo

      clientTermsInfo.forEach((item) => {
        if (Object.keys(values).includes(item.value)) {
          if (item.type === 'date') {
            values[item.value] = dateToString(values[item.value]) || null
          }
        }
      })

      updateClient(id, { ...values })
    },
    [updateClient, clientInfo],
  )

  const initialValues = useMemo(
    () =>
      clientInfo && {
        coiExpirationDate: clientInfo?.coiExpirationDate,
        eopExpirationDate: clientInfo?.eopExpirationDate,
        additionallyInsured: clientInfo?.additionallyInsured,
        lenderLossPayee: clientInfo.lenderLossPayee,
      },
    [clientInfo],
  )

  return (
    <Form
      initialValues={initialValues}
      onSubmit={handleClientUpdate}
      render={({ handleSubmit }) => {
        return (
          <form>
            <Card title="Insurance information">
              <Grid
                container
                paddingBottom={'10px'}
                spacing={6}
                justifyContent={'start'}
                fontSize={'14px'}
              >
                {FIELDS_MAP.map(({ label, value, type }) => (
                  <Grid item xs={3} key={label}>
                    <ClientABLField label={label} name={value} type={type} />
                  </Grid>
                ))}
                <FormSpy
                  subscription={{ dirty: true }}
                  onChange={(props) => {
                    props.dirty && handleSubmit(props.values)
                  }}
                />
              </Grid>
            </Card>
          </form>
        )
      }}
    />
  )
}

export default InsuranceInformation
