import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useParams } from 'react-router'
import InfiniteScroll from 'react-infinite-scroll-component'
import Popover from '@mui/material/Popover'
import MenuItem from '@mui/material/MenuItem'
import { Form } from 'react-final-form'
import cn from 'classnames'
import { ContentState, convertToRaw, EditorState } from 'draft-js'

import styles from './BBCLogsContainer.module.scss'

import { ReactComponent as EmptyIcon } from '@assets/images/no-notes-icon.svg'
import { ReactComponent as SortIcon } from '@assets/images/sort-icon.svg'
import { WorkflowTypes } from '@common/interfaces/notes'
import { buildFiltersDefaults } from '../../helpers/filters'
import { BBC_LOGS_LIST_FILTERS_CONFIG, PER_PAGE } from '@common/constants/filters'
import { debounceEventHandler } from '../../helpers/helpers'
import { ILoadingData } from '../../redux/types'
import { IBBCLog, IBBCLogData } from '@common/interfaces/bbcLog'
import BBCLogsItem, { BBCLogsItemLoader } from './BBCLogsItem'
import { IUser, USER_ROLE_LABEL_SHORT, UserRole } from '@common/interfaces/user'
import BBCLogsDetailsModal from './BBCLogsDetailsModal'
import FilterContainer from '../Filters/FilterContainer'
import Avatar from '../Common/Avatar'

const SORT_OPTIONS = [
  { value: 'created_at DESC', label: 'Created (Newest)' },
  { value: 'created_at ASC', label: 'Created (Oldest)' },
]

interface IProps {
  workflow: string
  data: ILoadingData<IBBCLogData>
  users: ILoadingData<{ data: IUser[] }>
  listBBCLog: (data: object) => Promise<ILoadingData<IBBCLogData>>
  hideBBCLog: () => void
  listUsers: ({ roles }: { withCurrent: boolean; roles: string[] }) => void
  addNote: (params: object) => void
}

const filtersDefaults = buildFiltersDefaults(BBC_LOGS_LIST_FILTERS_CONFIG, {
  type: [],
  userId: [],
})

const BBCLogsContainer = ({
  workflow,
  data: bbcLogData,
  users,
  listBBCLog,
  hideBBCLog,
  listUsers,
  addNote,
}: IProps) => {
  const { id } = useParams<{ id: string }>()
  const [filters, setFilters] = useState(filtersDefaults)
  const [expandedLog, setExpandedLog] = useState<IBBCLog>(null)
  const [sortAnchorEl, setSortAnchorEl] = useState<SVGSVGElement | null>(null)
  const isSortOpen = useMemo(() => Boolean(sortAnchorEl), [sortAnchorEl])

  const { isLoading, bbcLogs, itemsCount, totalItems } = useMemo(
    () => ({
      isLoading: bbcLogData.isLoading,
      bbcLogs: bbcLogData.data?.data || [],
      itemsCount: bbcLogData.data?.data?.length || 0,
      totalItems: bbcLogData.data?.totals?.totalItems || 0,
    }),
    [bbcLogData],
  )

  const [orderBy, setOrderBy] = useState({
    field: 'created_at',
    direction: 'DESC',
  })

  const debounceListLogs = useMemo(() => debounceEventHandler(listBBCLog, 500), [listBBCLog])

  useEffect(() => {
    if (id && workflow) {
      debounceListLogs({
        [workflow === WorkflowTypes.clientPage ? 'clientId' : 'borrowingBaseId']: id,
        filters,
        orderBy: orderBy.field,
        orderDirection: orderBy.direction,
        page: 0,
        perPage: PER_PAGE,
      })
    } else {
      hideBBCLog()
    }
  }, [id, workflow, filters, orderBy, debounceListLogs, hideBBCLog])

  useEffect(() => {
    return () => {
      hideBBCLog()
    }
  }, [hideBBCLog])

  useEffect(() => {
    listUsers({
      withCurrent: true,
      roles: [UserRole.ADMIN, UserRole.PORTFOLIO_ADMIN, UserRole.PORTFOLIO_USER, UserRole.UW_USER],
    })
  }, [listUsers])

  const loadMore = useCallback(() => {
    if (id && workflow) {
      listBBCLog({
        loadMore: true,
        [workflow === WorkflowTypes.clientPage ? 'clientId' : 'borrowingBaseId']: id,
        filters,
        orderBy: orderBy.field,
        orderDirection: orderBy.direction,
        page: Math.ceil(itemsCount / PER_PAGE),
        perPage: PER_PAGE,
      })
    }
  }, [id, workflow, filters, orderBy, itemsCount, listBBCLog])

  const handleChangeOrderBy = useCallback(
    (value) => {
      const [field, direction] = value.split(' ')
      setOrderBy((order) => ({
        field,
        direction,
      }))
      setSortAnchorEl(null)
    },
    [setOrderBy],
  )

  const openSort = useCallback((event: React.MouseEvent<SVGSVGElement>) => {
    setSortAnchorEl((anchorEl: SVGSVGElement) => (anchorEl ? null : event.currentTarget))
  }, [])

  const closeSort = useCallback(() => {
    setSortAnchorEl(null)
  }, [])

  const handlePushToNotes = useCallback(
    async (data) => {
      const draftJSData = EditorState.createWithContent(ContentState.createFromText(data.text))
      const draftJSDataJSON = convertToRaw(draftJSData.getCurrentContent())
      await addNote({ workflow, note: draftJSDataJSON, id, bbcLogId: data.id })
    },
    [addNote, workflow, id],
  )

  const handleShowDetails = useCallback((bbcLog: IBBCLog) => {
    setExpandedLog((selectedBBCLog) => (selectedBBCLog?.id === bbcLog.id ? null : bbcLog))
  }, [])

  const handleCloseDetails = useCallback(() => {
    setExpandedLog(null)
  }, [])

  const usersList = useMemo(() => users?.data?.data || [], [users])

  useEffect(() => {
    setExpandedLog((bbcLog) =>
      bbcLog ? bbcLogs?.find(({ id }) => bbcLog.id === id) || null : null,
    )
  }, [bbcLogs])

  const handleFiltersChange = useCallback((data: any) => {
    setFilters(data)
  }, [])

  const filtersConfig = useMemo(
    () =>
      BBC_LOGS_LIST_FILTERS_CONFIG.map((item) => ({
        ...item,
        options:
          item.field === 'userId'
            ? usersList.map((user) => ({
                value: user.id,
                label: `${user.firstName} - ${USER_ROLE_LABEL_SHORT[user.role]}`,
                icon: <Avatar user={user} size="small" />,
              }))
            : item.options,
      })),
    [usersList],
  )

  return (
    <div className={styles.container}>
      <div className={styles.actionsContainer}>
        <div
          className={cn(styles.actionButtonContainer, styles.actionButtonContainerFilterContainer)}
        >
          <Form
            onSubmit={handleFiltersChange}
            initialValues={filters}
            mutators={{
              setFieldData: ([field, value], state, { changeValue }) => {
                changeValue(state, field, () => value)
              },
            }}
            render={({ values, handleSubmit, form: { mutators } }) => (
              <FilterContainer
                mutators={mutators}
                filters={filtersConfig}
                handleSubmit={handleSubmit}
                values={values}
                appliedFilters={filters}
                withFilterItems={false}
                actionsSize={0}
                actionsInsideFilters
                actions={
                  <div className={styles.actionButtonContainer}>
                    <Popover
                      open={isSortOpen}
                      anchorEl={sortAnchorEl}
                      onClose={closeSort}
                      anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                      }}
                      classes={{ paper: styles.sortMenuPopoverPaper }}
                    >
                      <div className={styles.sortMenu}>
                        {SORT_OPTIONS.map((option) => (
                          <MenuItem
                            key={option.value}
                            classes={{
                              root: styles.sortMenuItem,
                              selected: styles.sortMenuItemSelected,
                            }}
                            onClick={() => handleChangeOrderBy(option.value)}
                            selected={`${orderBy.field} ${orderBy.direction}` === option.value}
                          >
                            {option.label}
                          </MenuItem>
                        ))}
                      </div>
                    </Popover>

                    <SortIcon
                      className={cn(styles.button, styles.sortButton, {
                        [styles.buttonActive]: isSortOpen,
                      })}
                      onClick={openSort}
                    />
                  </div>
                }
                withFullSearch={false}
              />
            )}
          />
        </div>
      </div>
      <div id="scrollableLogs" className={styles.logsContainer}>
        {isLoading ? (
          <>
            <BBCLogsItemLoader />
            <BBCLogsItemLoader />
            <BBCLogsItemLoader />
          </>
        ) : !bbcLogs.length ? (
          <div className={styles.emptyContainer}>
            <EmptyIcon />
            <div className={styles.emptyContainerText}>There are no logs at this time.</div>
          </div>
        ) : (
          <InfiniteScroll
            className={styles.infiniteScroll}
            dataLength={itemsCount}
            next={loadMore}
            hasMore={itemsCount < totalItems}
            loader={<BBCLogsItemLoader />}
            scrollableTarget="scrollableLogs"
          >
            {bbcLogs.map((item) => (
              <BBCLogsItem
                key={item.id}
                bbcLog={item}
                isExpanded={expandedLog?.id === item.id}
                pushToNotes={handlePushToNotes}
                showDetails={handleShowDetails}
              />
            ))}
          </InfiniteScroll>
        )}
      </div>

      {!!expandedLog && (
        <BBCLogsDetailsModal bbcLog={expandedLog} handleClose={handleCloseDetails} />
      )}
    </div>
  )
}

export default BBCLogsContainer
