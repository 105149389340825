import { combineReducers } from 'redux'
import * as actions from './actions'
import { IPassThroughData } from '@common/interfaces/passThrough'
import { ILoadingData } from '../types'
import { createLoadingDataReducer } from '../..//helpers/redux'

export interface IPassThroughState {
  passThroughData: ILoadingData<IPassThroughData>
}

export default combineReducers<IPassThroughState>({
  passThroughData: createLoadingDataReducer([actions.LIST_PASS_THROUGH_REQUEST]),
})
