import { put, select, takeEvery } from 'redux-saga/effects'
import { matchPath } from 'react-router'

import { CREATE_ADJUSTMENT } from './actions'

import { UserRole } from '@common/interfaces/user'
import { loadClientInfo } from '../profile/actions'
import { show as showClient } from '../clientInfo/actions'
import { role } from '../profile/selectors'
import { pathname } from '../router/selectors'
import { ROUTES } from '../../constants/routes'

export function* updateAdjustmentSuccess() {
  yield takeEvery(CREATE_ADJUSTMENT, function* () {
    const path: string = yield select(pathname)
    const userRole: UserRole = yield select(role)

    if (
      [UserRole.PORTFOLIO_USER, UserRole.PORTFOLIO_ADMIN].includes(userRole) &&
      matchPath(path, { path: ROUTES.CLIENT_PAGE })
    ) {
      const {
        params: { id },
      } = matchPath<{ id: string }>(path, { path: ROUTES.CLIENT_PAGE })
      yield put(showClient(id))
    } else if (userRole === UserRole.CLIENT_USER && matchPath(path, { path: ROUTES.HOMEPAGE })) {
      yield put(loadClientInfo())
    }
  })
}
