import React, { useState, useCallback, useMemo } from 'react'
import { useParams, matchPath, generatePath } from 'react-router'
import { useLocation, Link as RouterLink } from 'react-router-dom'
import Link from '@mui/material/Link'
import Tooltip from '@mui/material/Tooltip'
import { EditorState, convertToRaw, ContentState } from 'draft-js'
import cn from 'classnames'
import styles from './Flag.module.scss'
import { ReactComponent as CheckIcon } from '@assets/images/check-circle-icon-alt.svg'
import { ReactComponent as CheckIconDefault } from '@assets/images/alert-success-snackbar.svg'
import { REPORTING_DOCUMENT_TYPES } from '@common/constants/client'
import Card from '../../Common/Card'
import Note from '../../Notes/Note'
import AddComment from '../../Notes/AddComment'
import { formatDate, handleStopPropagation } from '../../../helpers/helpers'
import { ReactComponent as NextIcon } from '@assets/images/next-icon.svg'
import { ReactComponent as ReplyIcon } from '@assets/images/reply-icon.svg'
import { ReactComponent as EmptyIcon } from '@assets/images/no-notes-icon.svg'
import { currentWorkFlow } from '../../../helpers/helpers'
import { ROUTES } from '../../../constants/routes'
import { IFlags } from '@common/interfaces/bbc'
import FlagTitle from '../FlagTitle/FlagTitle'
import { WorkflowTypes } from '@common/interfaces/notes'

const Breadcrumbs = ({ flag, workflow }: { flag: IFlags; workflow?: WorkflowTypes }) => {
  const location = useLocation()
  const { id } = useParams<{ id: string }>()
  const { borrowingBase, clientInfo, ongoingReporting, entityInfo, opsReporting } = flag

  const ongoingReportingLink = useMemo(() => {
    if (ongoingReporting?.id) {
      const { id } = ongoingReporting
      switch (ongoingReporting?.type) {
        case REPORTING_DOCUMENT_TYPES.financials:
          return generatePath(ROUTES.ONGOING_REPORTING_SUMMARY, { id })
        case REPORTING_DOCUMENT_TYPES.financialProjections:
          return generatePath(ROUTES.ONGOING_REPORTING_SUMMARY, { id })
        case REPORTING_DOCUMENT_TYPES.bankTransactions:
          return generatePath(ROUTES.BANK_TRANSACTIONS_UPLOAD, { id })
        case REPORTING_DOCUMENT_TYPES.salesBySKU:
          return generatePath(ROUTES.ONGOING_REPORTING_SALES_BY_SKU, { id })
        case REPORTING_DOCUMENT_TYPES.arGeneralLedger:
          return generatePath(ROUTES.ONGOING_REPORTING_AR_GENERAL_LEDGER, { id })
        default:
          break
      }
    }
  }, [ongoingReporting])

  const clientPageLink = useMemo(() => {
    if (
      matchPath(location.pathname, { path: ROUTES.CLIENT_PAGE }) ||
      matchPath(location.pathname, { path: ROUTES.PROSPECT_PAGE })
    ) {
      return null
    }

    if (clientInfo) {
      return generatePath(ROUTES.CLIENT_PAGE, { id: clientInfo?.id })
    }
  }, [clientInfo, location])

  const breadcrumbs = useMemo(() => {
    const result: { name: string; link: string; isCurrentWorkflow: boolean }[] = []
    const breadcrumbId = borrowingBase?.id || ongoingReporting?.id
    const isCurrentWorkflow = id && breadcrumbId === id

    if (clientPageLink) {
      result.push({
        name: `${clientInfo?.clientName} ${formatDate(flag.recordDate)}`,
        link: clientPageLink,
        isCurrentWorkflow: false,
      })
    }

    if (opsReporting) {
      result.push({
        name: `OPS ${formatDate(opsReporting.recordDate)}`,
        link: generatePath(ROUTES.PROSPECT_SUMMARY_PAGE, { id: opsReporting?.id }),
        isCurrentWorkflow,
      })
    }

    if (borrowingBase) {
      result.push({
        name: `${borrowingBase.isTest ? 'Test ' : ''}BBC ${formatDate(borrowingBase.recordDate)}`,
        link: generatePath(ROUTES.BBC_SUMMARY, { id: borrowingBase.id }),
        isCurrentWorkflow,
      })
    }

    if (ongoingReporting) {
      result.push({
        name: `${ongoingReporting.type} ${formatDate(ongoingReporting.recordDate)}`,
        link: ongoingReportingLink,
        isCurrentWorkflow,
      })
    } else if (entityInfo) {
      result.push({
        name: `${entityInfo.name} ${formatDate(flag.recordDate)}`,
        link: generatePath(ROUTES.ENTITY_PAGE, { id: entityInfo.id }),
        isCurrentWorkflow,
      })
    }

    return result
  }, [
    clientPageLink,
    borrowingBase,
    clientInfo,
    ongoingReporting,
    ongoingReportingLink,
    id,
    entityInfo,
    flag,
    opsReporting,
  ])

  if (!breadcrumbs.length) {
    return <div />
  }

  return (
    <div className={styles.flagBreadcrumbs}>
      {breadcrumbs.map(({ name, link, isCurrentWorkflow }, index) => (
        <React.Fragment key={index}>
          <Link
            component={RouterLink}
            to={link}
            className={cn(styles.flagBreadcrumbItem, {
              [styles.isCurrentWorkflow]: isCurrentWorkflow,
            })}
            onClick={handleStopPropagation}
          >
            {name}
          </Link>
          {index !== breadcrumbs.length - 1 && (
            <NextIcon className={styles.flagBreadcrumbSeparator} />
          )}
        </React.Fragment>
      ))}
    </div>
  )
}

export const boldFlagDescription = (flagDescription: string) => {
  const regex = /(\$[^\s()]+)|([^\s()]+%)|~([^\s()]+)/g
  const parts = []
  let match
  let lastIndex = 0

  while ((match = regex.exec(flagDescription)) !== null) {
    if (match.index > lastIndex) {
      parts.push(flagDescription.slice(lastIndex, match.index))
    }
    const boldText = match[3] ? match[3] : match[0]
    parts.push(<strong key={match.index}>{boldText}</strong>)
    lastIndex = regex.lastIndex
  }

  if (lastIndex < flagDescription.length) {
    parts.push(flagDescription.slice(lastIndex))
  }
  return parts
}

interface IProps {
  flag: IFlags
  pathname: string
  addNote: (params: object) => void
  updateFlag: (id: string, params: object) => void
  isExpandModal?: boolean
  handleCloseExpandModal?: () => void
  onClickExpandModal?: (flag: IFlags) => void
  isSelected?: boolean
  index: number
}

const Flag = ({
  flag,
  pathname,
  addNote,
  updateFlag,
  index,
  isExpandModal = false,
  onClickExpandModal,
  isSelected = false,
}: IProps) => {
  const [isReplyShown, setIsReplyShown] = useState(false)
  const [isExpanded, setIsExpanded] = useState(false)
  const { id } = useParams<{ id: string }>()
  const workflow = useMemo(() => currentWorkFlow(pathname), [pathname])

  const createFlagNote = useCallback(async () => {
    const note = flag?.message
    const draftJSData = EditorState.createWithContent(ContentState.createFromText(note))
    const draftJSDataJSON = convertToRaw(draftJSData.getCurrentContent())
    await addNote({
      workflow,
      note: draftJSDataJSON,
      id,
      flagId: flag.id,
    })
  }, [addNote, id, flag, workflow])

  const toggleReplyVisibility = useCallback(
    async (event?: React.MouseEvent) => {
      event?.stopPropagation()
      if (!flag?.notes?.id) {
        await createFlagNote()
      }
      setIsReplyShown((isShown) => !isShown)
    },
    [createFlagNote, flag?.notes?.id],
  )

  const toggleExpand = useCallback((event: React.MouseEvent) => {
    event.stopPropagation()
    setIsExpanded((isExpanded) => !isExpanded)
  }, [])

  const expandText = useMemo(() => (isExpanded ? 'Hide all' : 'See all replies'), [isExpanded])

  const handleNoteClick = useCallback(async () => {
    if (!flag?.notes?.id) {
      await createFlagNote()
    }
    onClickExpandModal(flag)
  }, [flag, onClickExpandModal, createFlagNote])

  const { flagTitle, flagDescriptionSegments } = useMemo(() => {
    const flagTitle = flag?.message?.split(':')[0]
    const flagDescription = flag?.message?.split(':')[flag?.message?.split(':').length - 1]
    if (!flagDescription) {
      return { flagTitle, flagDescriptionSegments: [] }
    }

    const parts = boldFlagDescription(flagDescription)

    return { flagTitle, flagDescriptionSegments: parts }
  }, [flag])

  const handleResolve = useCallback(
    (event) => {
      event?.stopPropagation()
      updateFlag(flag.id, { resolved: !flag.resolved })
    },
    [flag, updateFlag],
  )

  return (
    <Card
      noHeaderMargin
      withBorder
      className={cn(styles.flag, {
        [styles.isExpandModal]: isExpandModal,
        [styles.selected]: isSelected,
      })}
      onClick={handleNoteClick}
      classes={{
        content: styles.flagCardContent,
      }}
    >
      <div className={styles.flagTopRow}>
        <Breadcrumbs flag={flag} workflow={workflow} />
        <div className={styles.flagTopRowActions}>
          <Tooltip
            key={`resolvetooltip-${flag?.id}-${index}`}
            title={flag?.resolved ? 'Unresolve' : 'Resolve'}
          >
            <div
              className={cn(styles.flagTopRowAction, {
                [styles.flagTopRowActionResolved]: flag?.resolved,
              })}
              onClick={handleResolve}
            >
              {flag?.resolved ? <CheckIconDefault /> : <CheckIcon />}
            </div>
          </Tooltip>
          {!isExpandModal && !isSelected && (
            <Tooltip title={'Comment'}>
              <div className={styles.flagTopRowAction} onClick={toggleReplyVisibility}>
                <ReplyIcon />
              </div>
            </Tooltip>
          )}
        </div>
      </div>

      <div className={cn(styles.noteWrapper, styles.noteWrapperParent)}>
        <div className={styles.flagContainer}>
          <div className={styles.flagHeading}>
            <FlagTitle
              flag={flag}
              pathname={pathname}
              updateFlag={updateFlag}
              flagTitle={flagTitle}
            />
          </div>

          <div
            className={styles.flagBody}
            onClick={isExpandModal ? handleStopPropagation : undefined}
          >
            {flagDescriptionSegments}
          </div>
        </div>
      </div>

      <Card
        noHeaderMargin
        withBorder={false}
        className={styles.childrenContainer}
        classes={{
          content: styles.childrenContainerContent,
        }}
      >
        {!isExpandModal && flag.notes?.children?.length > 1 && (
          <div className={styles.expandTextContainer}>
            <Link className={styles.expandText} onClick={toggleExpand}>
              {expandText}
            </Link>
          </div>
        )}

        {isExpandModal && <div className={styles.flagRepliesLabel}>Comments</div>}

        <div className={styles.childrenContainerList}>
          {isExpandModal && !flag.notes?.children?.length && (
            <div className={styles.emptyContainer}>
              <EmptyIcon />
              <div className={styles.emptyContainerText}>No comments</div>
            </div>
          )}
          {flag.notes?.children?.length > 0 &&
            flag.notes?.children?.map((child, index) => {
              if (index === flag.notes?.children?.length - 1 || isExpanded || isExpandModal) {
                return <Note key={child.id} note={child} isChild />
              }
              return null
            })}
        </div>

        {(isExpandModal || isReplyShown) && (
          <AddComment
            parent={flag?.notes}
            hideReply={isExpandModal ? undefined : toggleReplyVisibility}
          />
        )}
      </Card>
    </Card>
  )
}

export default Flag
