import React, { useCallback, useMemo, useState } from 'react'
import Grid from '@mui/material/Grid'
import Box from '@mui/material/Box'
import Tooltip from '@mui/material/Tooltip'
import Skeleton from '@mui/material/Skeleton'
import { Info } from '@mui/icons-material'
import cn from 'classnames'
import styles from './BBCAvailabilityTable.module.scss'
import {
  FUNDING_REQUEST_AMOUNT_TYPE_LABEL,
  FundingRequestAmountType,
  FundingRequestStatus,
  IBorrowingBase,
} from '@common/interfaces/bbc'
import { formatPrice } from '../../helpers/helpers'
import { LOAN_TYPES } from '@common/constants/client'
import ChangedValue from '../ChangedValue'
import { DEFAULT_EMPTY_ARRAY_PROP } from '../../constants/common'
import { ReactComponent as ActionSuccessIcon } from '../../assets/images/action-success.svg'
import Icon from '@mui/material/Icon'
import { ReactComponent as WarningIcon } from '../../assets/images/warning-icon-with-halo.svg'

interface IProps {
  bbc: IBorrowingBase
  isLoading: boolean
}

const AvailabilityItem = ({
  open,
  title,
  value,
  previousValue = null,
  popover = DEFAULT_EMPTY_ARRAY_PROP,
  handleClick,
  isHeader = false,
  stringValue = null,
  isLastCalc = false,
  isAfterCalc = false,
  isLoading = false,
}: {
  open?: boolean
  title: string
  value: number
  previousValue?: number
  stringValue?: string
  popover?: Array<{ title: string; value: number }>
  handleClick?: () => void
  isHeader?: Boolean
  isAfterCalc?: Boolean
  isLastCalc?: Boolean
  isLoading?: boolean
}) => {
  const changedValue = useMemo(() => {
    if (!previousValue && previousValue !== 0) {
      return null
    }

    return value - previousValue
  }, [value, previousValue])
  return (
    <div
      key={title}
      className={cn(styles.availabilitySummaryItem, {
        [styles.availabilitySummaryItemHeader]: isHeader,
        [styles.availabilitySummaryItemBorder]: isLastCalc,
        [styles.availabilitySummaryItemTotal]: isAfterCalc,
      })}
    >
      <h4
        className={cn(styles.availabilitySummaryItemTitle, {
          [styles.availabilitySummaryItemTitleHeader]: isHeader,
        })}
      >
        <div>{title}</div>
        {popover.length > 0 && (
          <Tooltip
            placement="right"
            title={
              <>
                {popover.map((popoverItem) => (
                  <div key={popoverItem.title} className={styles.availabilitySummaryItem}>
                    <h4 className={styles.availabilitySummaryItemTitlePopover}>
                      {popoverItem.title}
                    </h4>
                    <div className={styles.availabilitySummaryItemValue}>
                      $ {formatPrice(popoverItem.value)}
                    </div>
                  </div>
                ))}
              </>
            }
            open={open}
            classes={{
              popper: styles.popover,
              tooltip: styles.tooltip,
            }}
          >
            <div className={styles.infoIcon} onClick={handleClick}>
              {' '}
              <Info className={styles.infoIconSize} />{' '}
            </div>
          </Tooltip>
        )}
      </h4>
      <Grid container className={styles.numbersContainer}>
        {isLoading ? (
          <>
            <Box display="flex" alignItems="center" justifyContent="flex-end">
              <Skeleton height={15} width={100} />
            </Box>
            <Box display="flex" alignItems="center" justifyContent="flex-end">
              <Skeleton height={15} width={100} />
            </Box>
          </>
        ) : (
          <>
            <Grid
              item
              className={cn(styles.availabilitySummaryItemValue, {
                [styles.availabilitySummaryItemeValueHeader]: isHeader,
              })}
            >
              {stringValue ? stringValue : '$' + formatPrice(value)}
            </Grid>

            <Grid item>
              <ChangedValue
                className={cn(styles.availabilityValue, {
                  [styles.availabilityHeader]: isHeader,
                })}
                changedValue={changedValue}
              />
            </Grid>
          </>
        )}
      </Grid>
    </div>
  )
}

const BBCAvailabilityTable = ({ bbc, isLoading }: IProps) => {
  const [open, setOpen] = useState(false)
  const handleClick = useCallback(() => {
    setOpen((previousOpen) => !previousOpen)
  }, [])

  const isSent = useMemo(() => bbc?.status === FundingRequestStatus.Sent, [bbc])
  const clientCollateral = useMemo(() => bbc?.clientCollateral, [bbc])
  const previousClientCollateral = useMemo(() => bbc?.previousBBC?.clientCollateral, [bbc])

  const arAvailabilityItems = useMemo(
    () => [
      {
        title: 'Gross AR',
        value: clientCollateral?.totalGrossAr,
        previousValue: previousClientCollateral?.totalGrossAr ?? null,
        isHeader: true,
      },
      {
        title: 'AR (Positive balances)',
        value: clientCollateral?.totalReceivables,
        previousValue: previousClientCollateral?.totalReceivables ?? null,
      },
      {
        title: '- Ineligible AR',
        value: clientCollateral?.totalIneligibleAr,
        previousValue: previousClientCollateral?.totalIneligibleAr ?? null,
        isLastCalc: true,
      },
      {
        title: 'Eligible AR',
        value: clientCollateral?.eligibleReceivables,
        previousValue: previousClientCollateral?.eligibleReceivables ?? null,
        isHeader: true,
        isAfterCalc: true,
      },
      {
        title: `AR Availability @ ${(clientCollateral?.arAdvanceRate * 100).toFixed(1)}%`,
        value: clientCollateral?.arAvailability,
        previousValue: previousClientCollateral?.arAvailability ?? null,
        isHeader: true,
      },
    ],
    [clientCollateral, previousClientCollateral],
  )

  const inventoryAvailabilityItems = useMemo(
    () =>
      [
        {
          title: 'Gross Inventory',
          value: clientCollateral?.totalGrossInventory,
          previousValue: previousClientCollateral?.totalGrossInventory ?? null,
        },
        {
          title: '- Ineligible Inventory',
          value: clientCollateral?.totalIneligibleInventory,
          previousValue: previousClientCollateral?.totalIneligibleInventory ?? null,
          isLastCalc: true,
        },
        {
          title: 'Eligible Inventory @ Cost',
          value: clientCollateral?.eligibleInventoryCost,
          previousValue: previousClientCollateral?.eligibleInventoryCost ?? null,
          isHeader: true,
          isAfterCalc: true,
        },
        {
          title: '- Priority Payables Reserve',
          value: clientCollateral?.over_90Payables,
          previousValue: previousClientCollateral?.over_90Payables ?? null,
        },
        {
          title: 'Inv. Availability (Pre Sublimit)',
          value: clientCollateral?.inventoryAvailabilityPreSubLimit,
          previousValue: previousClientCollateral?.inventoryAvailabilityPreSubLimit ?? null,
          popover: [
            !clientCollateral?.costValueOfRawMaterialsInventory && {
              title: `Cost Value of Inventory @ ${(
                clientCollateral?.inventoryAdvanceRate * 100
              ).toFixed(1)}%`,
              value: clientCollateral?.costValueOfInventory,
            },
            !!clientCollateral?.costValueOfRawMaterialsInventory && {
              title: `Cost Value of Inventory @ ${(
                clientCollateral?.inventoryAdvanceRate * 100
              ).toFixed(1)}% (Finished Goods)`,
              value: clientCollateral?.costValueOfFinishedGoodsInventory,
            },
            !!clientCollateral?.costValueOfRawMaterialsInventory && {
              title: `Cost Value of Inventory @ ${(
                clientCollateral?.rawMaterialsAdvanceRate * 100
              ).toFixed(1)}% (Raw Materials)`,
              value: clientCollateral?.costValueOfRawMaterialsInventory,
            },
            !clientCollateral?.costValueOfRawMaterialsInventory && {
              title: `NOLV Calculation (${(clientCollateral?.nolvPercentOfCost * 100).toFixed(
                1,
              )}% of Cost)`,
              value: clientCollateral?.nolvCalculation,
            },
            !!clientCollateral?.costValueOfRawMaterialsInventory && {
              title: `NOLV Calculation (${(clientCollateral?.nolvPercentOfCost * 100).toFixed(
                1,
              )}% of Cost) (Finished Goods)`,
              value: clientCollateral?.nolvCalculationFinishedGoods,
            },
            !!clientCollateral?.costValueOfRawMaterialsInventory && {
              title: `NOLV Calculation (${(clientCollateral?.rmNolv * 100).toFixed(
                1,
              )}% of Cost) (Raw Materials)`,
              value: clientCollateral?.nolvCalculationRawMaterials,
            },
            !clientCollateral?.costValueOfRawMaterialsInventory && {
              title: `NOLV Advance @ ${(clientCollateral?.nolvAdvanceRate * 100).toFixed(1)}%`,
              value: clientCollateral?.nolvAdvance,
            },
            !!clientCollateral?.costValueOfRawMaterialsInventory && {
              title: `NOLV Advance @ ${(clientCollateral?.nolvAdvanceRate * 100).toFixed(
                1,
              )}% (Finished Goods)`,
              value: clientCollateral?.nolvAdvanceFinishedGoods,
            },
            !!clientCollateral?.costValueOfRawMaterialsInventory && {
              title: `NOLV Advance @ ${(clientCollateral?.rmInventoryAdvanceRateNolv * 100).toFixed(
                1,
              )}% (Raw Materials)`,
              value: clientCollateral?.nolvAdvanceRawMaterials,
            },
          ].filter(Boolean),
          isHeader: true,
        },
        {
          title: 'Inv. Availability (Post Sublimit)',
          value: clientCollateral?.inventoryAvailabilityPostSubLimit,
          previousValue: previousClientCollateral?.inventoryAvailabilityPostSubLimit ?? null,
          isHeader: true,
          isAfterCalc: true,
        },
      ].filter(Boolean),
    [clientCollateral, previousClientCollateral],
  )
  const hasTermLoan = useMemo(() => clientCollateral?.termLoanBalance > 0, [clientCollateral])

  const ablPrefix = useMemo(() => (hasTermLoan ? 'ABL ' : ''), [hasTermLoan])

  const availabilitySummaryItems = useMemo(
    () => [
      {
        title: 'Max Line Amount',
        value: clientCollateral?.maximumLine,
        previousValue: previousClientCollateral?.maximumLine ?? null,
        isHeader: true,
      },
      {
        title: 'Net Availability',
        value: clientCollateral?.netAvailability,
        previousValue: previousClientCollateral?.netAvailability ?? null,
        isHeader: !clientCollateral?.overadvances.length,
        isAfterCalc: !clientCollateral?.overadvances.length,
      },
      {
        title: '+ Overadvance',
        overadvances: clientCollateral?.overadvances,
        isOveradvance: true,
        ignore: !clientCollateral?.overadvances.length,
      },
      {
        title: 'Net Availability with Overadvance',
        value: clientCollateral?.netAvailabilityWithOveradvances,
        previousValue: previousClientCollateral?.netAvailabilityWithOveradvances ?? null,
        isHeader: true,
        isAfterCalc: true,
        ignore: !clientCollateral?.overadvances.length,
      },
      {
        title: 'Final Net Availability',
        value: clientCollateral?.finalNetAvailability,
        previousValue: previousClientCollateral?.finalNetAvailability ?? null,
        ignore: clientCollateral?.finalNetAvailability === clientCollateral?.netAvailability,
      },
      {
        title: `- Current ${ablPrefix}Loan Balance`,
        value: clientCollateral?.currentLoanAmount,
      },
      {
        title: '+ Wire Cost',
        value: clientCollateral?.wireCosts,
        isLastCalc: true,
      },
      {
        title: 'Excess Loan Availability',
        value: clientCollateral?.excessLoanAvailability,
        isHeader: true,
        isAfterCalc: true,
      },
      {
        title: '+ Requested Amount',
        value:
          bbc?.requestedAmountType === FundingRequestAmountType.Specific
            ? bbc?.requestedAmount
            : null,
        stringValue:
          bbc?.requestedAmountType !== FundingRequestAmountType.Specific
            ? FUNDING_REQUEST_AMOUNT_TYPE_LABEL[bbc?.requestedAmountType]
            : null,
        isLastCalc: true,
      },
      {
        title: !isSent ? `Projected New ${ablPrefix}Loan Amount` : `New ${ablPrefix}Loan Amount`,
        value: bbc?.projectedNewLoanAmount,
        stringValue:
          bbc?.requestedAmountType === FundingRequestAmountType.TBD && !isSent ? 'TBD' : null,

        isHeader: true,
        isAfterCalc: true,
      },
    ],
    [bbc, ablPrefix, isSent, clientCollateral, previousClientCollateral],
  )

  const accountActivitySummaryItems = useMemo(() => {
    const termLoanPrincipal = clientCollateral?.termLoanPrincipal > 0 && {
      title: '+ Term Loan Principal & Amortization',
      value: clientCollateral?.termLoanPrincipal,
      isLastCalc: true,
    }
    const termLoanBalances = hasTermLoan
      ? [
          {
            title: '+ Term Loan Balance',
            value: clientCollateral?.termLoanBalance,
            isHeader: true,
            isLastCalc: true,
          },
          {
            title: 'Total Current Loan Balance',
            value: clientCollateral?.totalCurrentLoanBalance,
            isHeader: true,
            isAfterCalc: true,
          },
        ]
      : []

    return [
      {
        title: `Prior ${ablPrefix}Loan Balance`,
        value: clientCollateral?.priorLoanAmount,
        isHeader: true,
      },
      {
        title: '+ Realized Interest Fees & Misc. Fees',
        value: clientCollateral?.realizedInterest + clientCollateral?.miscFees,
        isHeader: false,
      },
      {
        title: '- Collections',
        value: clientCollateral?.collections,
        previousValue: previousClientCollateral?.collections ?? null,
      },
      {
        title: '+ Adjustments & Returned Collections',
        value: clientCollateral?.adjustments + clientCollateral?.returnedCollections,
        isLastCalc: !clientCollateral?.termLoanPrincipal,
      },
      termLoanPrincipal,
      {
        title: `Current ${ablPrefix}Loan Balance`,
        value: clientCollateral?.currentLoanAmount,
        isHeader: true,
        isAfterCalc: true,
      },
      ...termLoanBalances,
    ].filter(Boolean)
  }, [ablPrefix, hasTermLoan, clientCollateral, previousClientCollateral])

  const accountSummaryDiscrepency = useMemo(
    () =>
      +(
        clientCollateral?.priorLoanAmount +
        clientCollateral?.realizedInterest +
        clientCollateral?.miscFees -
        clientCollateral?.collections +
        clientCollateral?.adjustments +
        clientCollateral?.returnedCollections +
        (clientCollateral?.termLoanPrincipal ?? 0) -
        clientCollateral?.currentLoanAmount +
        // abocve checks if regular activity adds up to current loan balance
        // below checks if current loan balance and termloan adds up to total current loan balance
        clientCollateral?.totalCurrentLoanBalance -
        clientCollateral?.currentLoanAmount -
        (clientCollateral?.termLoanBalance ?? 0)
      ).toFixed(2),
    [clientCollateral],
  )

  return (
    <Grid container alignItems="baseline" spacing={1}>
      <Grid className={styles.summaryColumnLeft} item xs={5} lg={5}>
        {bbc?.clientInfo.loanType !== LOAN_TYPES.inventory && (
          <div>
            <h3 className={styles.availabilitySummaryTitle}>AR Availability</h3>
            {arAvailabilityItems.map((data) => {
              return (
                <AvailabilityItem
                  key={data.title}
                  open={open}
                  title={data.title}
                  value={data.value}
                  previousValue={data.previousValue}
                  handleClick={handleClick}
                  isHeader={data.isHeader}
                  isLastCalc={data.isLastCalc}
                  isAfterCalc={data.isAfterCalc}
                  isLoading={isLoading}
                />
              )
            })}
          </div>
        )}
        {bbc?.clientInfo.loanType === LOAN_TYPES.arAndInventory && <br />}
        {bbc?.clientInfo.loanType !== LOAN_TYPES.ar && (
          <div>
            <h3
              className={`${styles.availabilitySummaryTitle} ${styles.inventoryAvailabilityTitle}`}
            >
              Inventory Availability
            </h3>
            {inventoryAvailabilityItems.map((data) => {
              return (
                <AvailabilityItem
                  key={data.title}
                  open={open}
                  title={data.title}
                  value={data.value}
                  previousValue={data.previousValue}
                  handleClick={handleClick}
                  isHeader={data.isHeader}
                  isLastCalc={data.isLastCalc}
                  isAfterCalc={data.isAfterCalc}
                  popover={data.popover}
                  isLoading={isLoading}
                />
              )
            })}
          </div>
        )}
      </Grid>

      <Grid item xs={5} lg={5}>
        {!bbc?.isTest && (
          <>
            <Grid container justifyContent={'start'} alignContent={'center'} gap={2}>
              <h3 className={styles.availabilitySummaryTitle}>Account Activity</h3>
              <Tooltip title={`Discrepency: $${formatPrice(accountSummaryDiscrepency)}`}>
                <Icon
                  component={accountSummaryDiscrepency === 0 ? ActionSuccessIcon : WarningIcon}
                  sx={{ fontSize: 20, color: 'green' }}
                />
              </Tooltip>
            </Grid>
            {accountActivitySummaryItems.map(
              ({ title, value, previousValue, isHeader, isLastCalc, isAfterCalc }) => (
                <AvailabilityItem
                  key={title}
                  title={title}
                  value={value}
                  previousValue={previousValue}
                  isHeader={isHeader}
                  isLastCalc={isLastCalc}
                  isLoading={isLoading}
                  isAfterCalc={isAfterCalc}
                />
              ),
            )}
            <br />
          </>
        )}

        <h3 className={styles.availabilitySummaryTitle}>Availability Summary</h3>
        {availabilitySummaryItems
          .filter((item) => !item.ignore)
          .map(
            ({
              title,
              value,
              previousValue,
              isOveradvance,
              overadvances,
              isHeader,
              isLastCalc,
              isAfterCalc,
              stringValue,
            }) => {
              if (isOveradvance) {
                return overadvances.map((overadvance, index) => {
                  const isLastCalc = index === overadvances.length - 1
                  return (
                    <AvailabilityItem
                      key={index}
                      title={title}
                      value={overadvance}
                      previousValue={previousValue}
                      isLastCalc={isLastCalc}
                      isLoading={isLoading}
                    />
                  )
                })
              }
              return (
                <AvailabilityItem
                  key={title}
                  title={title}
                  value={value}
                  previousValue={previousValue}
                  isHeader={isHeader}
                  isLastCalc={isLastCalc}
                  isAfterCalc={isAfterCalc}
                  isLoading={isLoading}
                  stringValue={stringValue}
                />
              )
            },
          )}
      </Grid>
    </Grid>
  )
}

export default BBCAvailabilityTable
