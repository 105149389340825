import { useState, useEffect, MutableRefObject } from 'react'

export const useIsVisible = (element: MutableRefObject<HTMLDivElement>) => {
  const [isVisible, setIsVisible] = useState(false)

  useEffect(() => {
    const { current } = element
    const observer = new IntersectionObserver(([entry]) => {
      setIsVisible(entry.isIntersecting)
    })

    current && observer.observe(current)

    return () => observer.unobserve(current)
  }, [element])

  return isVisible
}
