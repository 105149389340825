import React from 'react'
import MuiInputLabel, { InputLabelProps } from '@mui/material/InputLabel'
import cn from 'classnames'

import styles from './InputLabel.module.scss'

interface IProps extends InputLabelProps {
  size: 'small' | 'normal'
  position: 'vertical' | 'horizontal'
}

const InputLabel = ({ size, position, ...props }: IProps) => {
  return (
    <MuiInputLabel
      {...props}
      className={cn(styles.inputLabel, props.className, {
        [styles.inputLabelMedium]: size === 'normal',
        [styles.inputLabelHorizontal]: position === 'horizontal',
      })}
    />
  )
}

InputLabel.defaultProps = {
  size: 'small',
  position: 'vertical',
}

export default InputLabel
