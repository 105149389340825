import React, { useCallback } from 'react'
import { Form, FormRenderProps } from 'react-final-form'
import { FieldArray } from 'react-final-form-arrays'
import InfiniteScroll from 'react-infinite-scroll-component'
import Box from '@mui/material/Box'

import styles from './EntityAccountMapping.module.scss'

import { ICheckAccount } from '@common/interfaces/collection'
import { ENTITY_ACCOUNTS_LIST_FILTERS_CONFIG } from '@common/constants/filters'
import TableContainer from '../Common/TableContainer'
import FilterContainer from '../Filters/FilterContainer'
import Table from '../Common/Table'
import TableHead from '../Common/TableHead'
import TableFiltersRow from '../Common/TableFiltersRow'
import TableBody from '../Common/TableBody'
import TableLoader from '../Common/TableLoader'
import SaveState from '../Common/SaveState'
import { buildFiltersValidateSchema } from '../../helpers/filters'
import EntityAccountMappingFormRow from './EntityAccountMappingFormRow'

const filtersValidate = buildFiltersValidateSchema(ENTITY_ACCOUNTS_LIST_FILTERS_CONFIG)

interface IProps extends FormRenderProps<any> {
  isLoading?: boolean
  isSaving?: boolean
  isSaved?: boolean
  filters: any
  onFiltersChange: (data: any) => void
  orderBy: any
  onOrderChange: (field: string) => void
  itemsCount: number
  totalItems: number
  loadMore: () => void
  loadEntitiesOptions: (inputValue: string) => Promise<any>
  handleAddEntityOption: (mapping: string | string[]) => Promise<void>
  activeItem: number
  activeItems: number[]
  setActiveItem: (indexes: number) => void
  setActiveItems: (index: number[]) => void
  handleSelectRow: (event: any, index: number) => void
}

const EntityAccountMappingForm = ({
  isLoading,
  isSaving,
  isSaved,
  filters,
  onFiltersChange,
  orderBy,
  onOrderChange,
  itemsCount,
  totalItems,
  loadMore,
  loadEntitiesOptions,
  handleAddEntityOption,
  form,
  values,
  initialValues,
  handleSubmit,
  activeItem,
  activeItems,
  setActiveItem,
  setActiveItems,
  handleSelectRow,
}: IProps) => {
  const handleLinkedNameChange = useCallback(
    (index, value) => {
      if (activeItems.length > 1 && activeItems.includes(index)) {
        form.batch(() => {
          ;(values.accounts as ICheckAccount[]).forEach((_, index) => {
            if (activeItems.includes(index)) {
              form.change(`accounts[${index}].linkedName`, value)
            }
          })
        })
      } else {
        form.change(`accounts[${index}].linkedName`, value)
      }
      handleSubmit()
    },
    [form, activeItems, values, handleSubmit],
  )

  return (
    <TableContainer
      className={styles.table}
      isActivable
      onActiveRowsChange={setActiveItems}
      onActiveRowChange={setActiveItem}
    >
      <div className={styles.tableHeaderContainer}>
        <Form
          validate={filtersValidate}
          onSubmit={onFiltersChange}
          initialValues={filters}
          mutators={{
            setFieldData: ([field, value], state, { changeValue }) => {
              changeValue(state, field, () => value)
            },
          }}
          render={({ values: filterValues, handleSubmit, form: { mutators: filterMutators } }) => (
            <FilterContainer
              filters={ENTITY_ACCOUNTS_LIST_FILTERS_CONFIG}
              handleSubmit={handleSubmit}
              mutators={filterMutators}
              values={filterValues}
              appliedFilters={filters}
            />
          )}
        />
      </div>
      <Table>
        <TableHead>
          <TableFiltersRow
            filters={ENTITY_ACCOUNTS_LIST_FILTERS_CONFIG}
            orderBy={orderBy}
            handleOrderChange={onOrderChange}
          />
        </TableHead>
        <TableBody id="scrollableTable">
          {isLoading ? (
            <TableLoader columnsCount={ENTITY_ACCOUNTS_LIST_FILTERS_CONFIG.length} height={26} />
          ) : (
            values.accounts && (
              <InfiniteScroll
                dataLength={itemsCount}
                next={loadMore}
                hasMore={itemsCount < totalItems}
                loader={
                  <TableLoader
                    columnsCount={ENTITY_ACCOUNTS_LIST_FILTERS_CONFIG.length}
                    rowsCount={1}
                    height={26}
                  />
                }
                scrollableTarget="scrollableTable"
              >
                <FieldArray name="accounts">
                  {({ fields }) =>
                    fields.map((name, index) => (
                      <EntityAccountMappingFormRow
                        key={name}
                        index={index}
                        account={values.accounts[index]}
                        isActiveRow={activeItems.includes(index)}
                        isCurrentActiveRow={activeItem === index}
                        onSelectRow={handleSelectRow}
                        loadEntitiesOptions={loadEntitiesOptions}
                        handleAddEntityOption={handleAddEntityOption}
                        onLinkedNameChange={handleLinkedNameChange}
                      />
                    ))
                  }
                </FieldArray>
              </InfiniteScroll>
            )
          )}
        </TableBody>
      </Table>
      <Box display="flex" alignItems="center" justifyContent="flex-end">
        <SaveState isSaving={isSaving} isSaved={isSaved} />
      </Box>
    </TableContainer>
  )
}

export default EntityAccountMappingForm
