import React from 'react'
import Grid from '@mui/material/Grid'
import styles from './DashboardHeader.module.scss'
import { formatAmount, formatDate, formatNumber, formatPrice } from '../../helpers/helpers'
import cn from 'classnames'
import { Tooltip } from '@mui/material'
export interface IHeaderItem {
  title: string
  type: 'number' | 'currency' | 'percentage' | 'amount' | 'date' | 'string'
  value: string | number
  percentage?: number
  goal?: number | string
  hoverText?: string | React.ReactNode
}

interface IProps {
  headerItems: IHeaderItem[]
}

const DashboardHeader = ({ headerItems }: IProps) => {
  return (
    <div className={styles.dashboardHeaderWrapper}>
      <Grid
        container
        alignItems="center"
        justifyContent="space-evenly"
        columnSpacing={{
          xs: 1,
          sm: 1,
          md: 2,
          lg: 3,
          xl: 3,
        }}
      >
        {headerItems.map((item, index) => (
          <Grid item xs={12 / headerItems.length} key={index}>
            <div className={styles.dashboardCard}>
              <div className={styles.cardHeader}>{item.title}</div>
              <Tooltip title={item.hoverText || ''} placement={'bottom-end'}>
                <div className={styles.cardValue}>
                  {item.type === 'number'
                    ? formatNumber(item.value)
                    : item.type === 'amount'
                    ? formatAmount(item.value, '$')
                    : item.type === 'currency'
                    ? `$${formatPrice(item.value)}`
                    : item.type === 'date' && typeof item.value === 'string'
                    ? formatDate(item.value)
                    : item.value}
                  {item.percentage ? (
                    <div
                      className={cn({
                        [styles.positivePct]: item.percentage > 0,
                        [styles.negativePct]: item.percentage < 0,
                      })}
                    >
                      {item.percentage} %
                    </div>
                  ) : null}
                </div>
              </Tooltip>

              {item.goal !== undefined && item.goal !== null && (
                <div className={styles.cardFooter}>Goal: {item.goal}</div>
              )}
            </div>
          </Grid>
        ))}
      </Grid>
    </div>
  )
}

export default DashboardHeader
