import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'

import OngoingReportingMapping from './OngoingReportingMapping'

import { dataConsolidated } from '../../redux/ongoingReporting/selectors'
import { isNotesShown } from '../../redux/notes/selectors'
import {
  listConsolidatedOngoingReporting,
  updateConsolidatedOngoingReporting,
} from '../../redux/ongoingReporting/actions'

const selector = createStructuredSelector({
  dataConsolidated,
  isNotesShown,
})
const actions = {
  listConsolidatedOngoingReporting,
  updateConsolidatedOngoingReporting,
}

export default connect(selector, actions)(OngoingReportingMapping)
