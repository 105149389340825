import React, { useMemo } from 'react'
import cn from 'classnames'

import styles from './ChangedValue.module.scss'
import { formatAmount } from '../../helpers/helpers'
import Tooltip from '@mui/material/Tooltip'
import { formatPrice } from '../../helpers/helpers'

interface IProps {
  className?: string
  changedValue?: number
  isChip?: boolean
  type?: 'currency' | 'percent'
  showZero?: boolean
}

const ChangedValue = ({
  className,
  changedValue,
  type = 'currency',
  isChip,
  showZero = false,
}: IProps) => {
  const roundedValue = useMemo(() => {
    return changedValue && Math.round(changedValue * 100) / 100
  }, [changedValue])

  const { isCurrency, isPercent } = useMemo(() => {
    return {
      isCurrency: type === 'currency',
      isPercent: type === 'percent',
    }
  }, [type])

  const { value, fullValue } = useMemo(() => {
    const absValue = Math.abs(roundedValue)
    const prefix = isCurrency ? '$ ' : ''
    const suffix = isPercent ? '%' : ''
    const formattedValue = isCurrency
      ? isChip
        ? formatAmount(absValue)
        : formatPrice(absValue, 0)
      : absValue
    const fullValue = formatPrice(absValue, 0)

    return {
      value: `${prefix}${formattedValue}${suffix}`,
      fullValue: `${prefix}${fullValue}${suffix}`,
    }
  }, [isCurrency, roundedValue, isChip, isPercent])

  if (!roundedValue && !showZero) {
    return null
  }

  return isChip ? (
    <Tooltip title={fullValue} placement="top" disableTouchListener>
      <span
        className={cn(styles.rootChip, className, {
          [styles.up]: roundedValue > 0,
          [styles.down]: !roundedValue || roundedValue < 0,
        })}
      >
        {value}
      </span>
    </Tooltip>
  ) : (
    <span
      className={cn(styles.root, className, {
        [styles.up]: roundedValue > 0,
        [styles.down]: !roundedValue || roundedValue < 0,
      })}
    >
      {value}
    </span>
  )
}

export default ChangedValue
