import { connect } from 'react-redux'
import LoanCommitteeReportPayablesPage from './LoanCommitteeReportPayablesPage'
import { dueDiligenceInfo } from '../../redux/dueDiligence/selectors'
import { createStructuredSelector } from 'reselect'

const selectors = createStructuredSelector({
  dueDiligenceInfo,
})

export default connect(selectors, null)(LoanCommitteeReportPayablesPage)
