import { exportMasterInventory, bulkUpdateMasterInventory } from './../../redux/clientInfo/actions'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'

import BBCInventoryIneligibleSKUTable from './BBCInventoryIneligibleSKUTable'

import { listMasterInventory, updateMasterInventory } from '../../redux/bbc/actions'
import { bbc, inventoryIneligibilityData } from '../../redux/bbc/selectors'
import { listMasterInventory as clientListMasterInventory } from '../../redux/clientInfo/actions'
import { inventoryEligibilityData } from '../../redux/clientInfo/selectors'

const selector = createStructuredSelector({
  bbc,
  inventoryIneligibilityData,
  inventoryEligibilityData,
})

const actions = {
  listMasterInventory,
  updateMasterInventory,
  clientListMasterInventory,
  exportMasterInventory,
  bulkUpdateMasterInventory,
}

export default connect(selector, actions)(BBCInventoryIneligibleSKUTable)
