import React from 'react'
import Tooltip from '@mui/material/Tooltip'
import cn from 'classnames'

import styles from './CodatSyncChip.module.scss'

import { ReactComponent as CheckIcon } from '@assets/images/check-circle-icon.svg'
import { CodatSyncStatus, IClientInfo } from '@common/interfaces/client'
import { formatDateTime } from '../../../helpers/helpers'

interface IProps {
  clientInfo: IClientInfo
}

const CodatSyncChip = ({ clientInfo }: IProps) => {
  if (!clientInfo || !clientInfo.isErpConnected) {
    return null
  }

  return (
    <Tooltip title={clientInfo.codatSyncedAt ? formatDateTime(clientInfo.codatSyncedAt) : ''}>
      <div
        className={cn(styles.autoReportingChip, {
          [styles.autoReportingFailedChip]: clientInfo.codatSyncStatus === CodatSyncStatus.Failed,
        })}
      >
        <CheckIcon className={styles.autoReportingChipIcon} />
        Codat
      </div>
    </Tooltip>
  )
}

export default CodatSyncChip
