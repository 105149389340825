import React, { useEffect, useMemo } from 'react'
import { useParams } from 'react-router'
import { generatePath, Link as RouterLink } from 'react-router-dom'
import Skeleton from '@mui/material/Skeleton'
import Link from '@mui/material/Link'
import cn from 'classnames'

import genericSs from '@styles/generic.module.scss'
import styles from './ProspectReportingSummaryTermStructure.module.scss'

import { ILoadingData } from '../../redux/types'
import { IProspectTerms } from '@common/interfaces/prospects'
import { formatDate, formatPercent, formatPriceNoDecimal } from '../../helpers/helpers'
import { ROUTES } from '../../constants/routes'
import { LOAN_TYPES } from '@common/constants/client'
import { All_MANAGE_TERMS_FIELDS_CONFIG } from '@common/constants/prospects'

const TERM_STRUCTURE_COLUMNS = [
  'maxLineAmount',
  'minimumLineAmount',
  'interestRateBase',
  'interestRateAbovePrime',
  'loanType',
  'arAdvance',
  'inventoryAdvanceRateCost',
  'inventoryAdvanceRateNolv',
  'terminationYear1',
]

interface IProps {
  termStructureSummaryInfo: ILoadingData<{ data: IProspectTerms }>
  showTermStructureSummaryInfo: (id: string) => void
}

const ProspectReportingSummaryTermStructure = ({
  termStructureSummaryInfo,
  showTermStructureSummaryInfo,
}: IProps) => {
  const { id } = useParams<{ id: string }>()
  const { isLoading, data } = useMemo(
    () => ({
      isLoading: termStructureSummaryInfo.isLoading,
      data: termStructureSummaryInfo.data?.data,
    }),
    [termStructureSummaryInfo],
  )

  useEffect(() => {
    showTermStructureSummaryInfo(id)
  }, [showTermStructureSummaryInfo, id])

  const columns = useMemo(
    () =>
      TERM_STRUCTURE_COLUMNS.filter(
        (column) =>
          column !== 'arAdvance' || (data?.loanType && data.loanType !== LOAN_TYPES.inventory),
      )
        .filter(
          (column) =>
            !['inventoryAdvanceRateCost', 'inventoryAdvanceRateNolv'].includes(column) ||
            (data?.loanType && data.loanType !== LOAN_TYPES.ar),
        )
        .map((column) => All_MANAGE_TERMS_FIELDS_CONFIG.find(({ name }) => name === column))
        .map((column) =>
          column.name === 'terminationYear1'
            ? {
                ...column,
                label: 'Termination fee (year 1 / ongoing)',
              }
            : column,
        ),
    [data],
  )

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <div className={styles.titleContainer}>
          <div className={styles.title}>Term Structure</div>
          <div className={cn(styles.subTitle, genericSs.textCapitalize)}>
            {isLoading ? (
              <Skeleton className={styles.subTitleLoader} width={85} height={20} />
            ) : data ? (
              `${data.status} ${formatDate(data.recordDate)}`
            ) : (
              '-'
            )}
          </div>
        </div>

        <Link
          component={RouterLink}
          to={generatePath(ROUTES.PROSPECT_MANAGE_TERMS_PAGE, { id })}
          className={styles.editLink}
        >
          Edit
        </Link>
      </div>
      <div className={styles.data}>
        {columns.map(({ name, label, type }) => (
          <div key={name} className={styles.column}>
            <div className={styles.label}>{label}</div>
            <div className={styles.value}>
              {isLoading ? (
                <Skeleton width={100} height={15} />
              ) : name === 'terminationYear1' ? (
                `${data?.terminationYear1 ? formatPercent(data.terminationYear1) : '-'} / ${
                  data?.terminationYear2 ? formatPercent(data.terminationYear2) : '-'
                }`
              ) : data?.[name] ? (
                type === 'currency' ? (
                  <>
                    <span className={genericSs.pricePrefix}>$</span>
                    {formatPriceNoDecimal(data[name])}
                  </>
                ) : type === 'percent' ? (
                  formatPercent(data[name])
                ) : (
                  data[name]
                )
              ) : (
                '-'
              )}
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}

export default ProspectReportingSummaryTermStructure
