import Base from './base'

export default class Adjustment extends Base {
  create(data: object) {
    return this.apiClient.post('adjustments', data)
  }

  update(id: string, data: object) {
    return this.apiClient.put(`adjustments/${id}`, data)
  }

  delete(id: string) {
    return this.apiClient.delete(`adjustments/${id}`)
  }
}
