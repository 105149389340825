import React, { useMemo } from 'react'
import { IBrand } from '@common/interfaces/newsfeed'
import LinkUrl from '@mui/material/Link'

import { Link, generatePath } from 'react-router-dom'
import LinkButton from '@mui/material/Link'
import { ROUTES } from '../../constants/routes'
import { usePermissions } from '../../helpers/permissionContext'
import { ClientInfoStatus } from '@common/interfaces/client'

interface IProps {
  brandInfo: IBrand
  salesforceBaseUrl: string
  type: string
}

const LinkedAccountItem = ({ brandInfo, salesforceBaseUrl, type }: IProps) => {
  const { isAdmin, isBDO, isUW, isPortfolio } = usePermissions()

  const salesforceLink = useMemo(() => {
    if (!brandInfo?.salesforceType || !brandInfo?.salesforceId) {
      return null
    } else {
      return `${salesforceBaseUrl}${brandInfo.salesforceType}/${brandInfo.salesforceId}/view`
    }
  }, [brandInfo?.salesforceType, brandInfo?.salesforceId, salesforceBaseUrl])

  const opsId = useMemo(() => {
    return brandInfo?.salesforceProspectInfo?.opsReporting?.id
  }, [brandInfo])

  const atlasRoute = useMemo(() => {
    if (!brandInfo) {
      return null
    }

    if (isAdmin) {
      if (
        [ClientInfoStatus.Prospect, ClientInfoStatus.Archived].includes(
          brandInfo.clientInfo?.clientStatus,
        ) &&
        opsId
      ) {
        return { path: ROUTES.PROSPECT_PAGE, id: opsId }
      } else if (brandInfo.clientInfo?.clientStatus === ClientInfoStatus.DueDiligence) {
        return { path: ROUTES.DUE_DILIGENCE, id: brandInfo.clientInfo.id }
      } else if (brandInfo.clientInfo?.id) {
        return { path: ROUTES.CLIENT_PAGE, id: brandInfo.clientInfo.id }
      }
    } else if (isUW) {
      if (brandInfo.clientInfo?.clientStatus === ClientInfoStatus.DueDiligence) {
        return { path: ROUTES.DUE_DILIGENCE, id: brandInfo.clientInfo.id }
      } else if (
        brandInfo.clientInfo?.id &&
        [ClientInfoStatus.Current, ClientInfoStatus.Past].includes(
          brandInfo.clientInfo?.clientStatus,
        )
      ) {
        return { path: ROUTES.CLIENT_PAGE, id: brandInfo.clientInfo.id }
      }
    }

    if (isPortfolio) {
      if (
        brandInfo.clientInfo?.id &&
        [ClientInfoStatus.Current, ClientInfoStatus.Past].includes(
          brandInfo.clientInfo?.clientStatus,
        )
      ) {
        return { path: ROUTES.CLIENT_PAGE, id: brandInfo.clientInfo.id }
      }
    }

    return null
  }, [brandInfo, isAdmin, isUW, isPortfolio, opsId])

  const salesforceLinkComponent = useMemo(
    () => (
      <LinkUrl color="primary" href={salesforceLink} target="_blank" rel="noopener noreferrer">
        {brandInfo?.brandName}
      </LinkUrl>
    ),
    [salesforceLink, brandInfo?.brandName],
  )

  const atlasLinkComponent = useMemo(
    () =>
      atlasRoute && (
        <LinkButton component={Link} to={generatePath(atlasRoute.path, { id: atlasRoute.id })}>
          {brandInfo?.brandName}
        </LinkButton>
      ),
    [atlasRoute, brandInfo?.brandName],
  )

  if (!brandInfo) {
    return null
  }

  if (isBDO) {
    return salesforceLinkComponent
  } else if (atlasRoute) {
    return atlasLinkComponent
  } else {
    return salesforceLinkComponent
  }
}

export default LinkedAccountItem
