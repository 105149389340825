import React, { useCallback, useEffect, useMemo, useState } from 'react'
import Box from '@mui/material/Box'
import MenuItem from '@mui/material/MenuItem'
import Menu from '@mui/material/Menu'
import Tooltip from '@mui/material/Tooltip'
import cn from 'classnames'

import styles from './DueDiligenceTable.module.scss'
import genericSs from '@styles/generic.module.scss'

import { ReactComponent as WarningIcon } from '@assets/images/warning-polygon-icon.svg'
import {
  DueDiligenceTableItemType,
  IDueDiligenceBankAccount,
  IDueDiligenceBoardMember,
  IDueDiligenceContact,
  IDueDiligenceExecutive,
  IDueDiligenceInventoryLocation,
  IDueDiligenceReference,
  IDueDiligenceTableItem,
} from '@common/interfaces/dueDiligence'
import TableContainer from '../Common/TableContainer'
import Table from '../Common/Table'
import TableRow from '../Common/TableRow'
import TableHead from '../Common/TableHead'
import TableBody from '../Common/TableBody'
import TableCell from '../Common/TableCell'
import TableFiltersRow from '../Common/TableFiltersRow'
import { ILoadingData } from '../../redux/types'
import {
  DUE_DILIGENCE_FINANCIALS_BANK_ACCOUNTS_LIST_FILTERS_CONFIG,
  DUE_DILIGENCE_FINANCIALS_INVENTORY_LOCATIONS_LIST_FILTERS_CONFIG,
  DUE_DILIGENCE_TEAM_BOARD_MEMBERS_LIST_FILTERS_CONFIG,
  DUE_DILIGENCE_TEAM_CONTACTS_LIST_FILTERS_CONFIG,
  DUE_DILIGENCE_TEAM_EXECUTIVES_LIST_FILTERS_CONFIG,
  DUE_DILIGENCE_TEAM_REFERENCES_LIST_FILTERS_CONFIG,
} from '@common/constants/filters'
import TableLoader from '../Common/TableLoader'
import { debounceEventHandler, formatDate } from '../../helpers/helpers'
import WarningModal from '../WarningModal'
import DueDiligenceSaveItemModal from './DueDiligenceSaveItemModal'
import DueDiligenceViewItemModal from './DueDiligenceViewItemModal'
import { IBank } from '@common/interfaces/bank'
import { getDueDiligenceTableItemMissingFields } from '@common/helpers/helpers'
import { MenuIcon } from '../Common/Icons'
import AddButton from '../Client/AddButton'

const TITLE = {
  [DueDiligenceTableItemType.Contact]: 'Contacts',
  [DueDiligenceTableItemType.Executive]: 'Executives',
  [DueDiligenceTableItemType.BoardMember]: 'Board members',
  [DueDiligenceTableItemType.Reference]: 'References',
  [DueDiligenceTableItemType.InventoryLocation]: 'Inventory locations',
  [DueDiligenceTableItemType.BankAccount]: 'Bank Accounts',
}

const LABEL = {
  [DueDiligenceTableItemType.Contact]: 'contact',
  [DueDiligenceTableItemType.Executive]: 'executive',
  [DueDiligenceTableItemType.BoardMember]: 'board member',
  [DueDiligenceTableItemType.Reference]: 'reference',
  [DueDiligenceTableItemType.InventoryLocation]: 'inventory location',
  [DueDiligenceTableItemType.BankAccount]: 'bank account',
}

const VIEW_ACTION_LABEL = {
  [DueDiligenceTableItemType.Contact]: 'View profile',
  [DueDiligenceTableItemType.Executive]: 'View profile',
  [DueDiligenceTableItemType.BoardMember]: 'View profile',
  [DueDiligenceTableItemType.Reference]: 'View profile',
  [DueDiligenceTableItemType.InventoryLocation]: 'View details',
  [DueDiligenceTableItemType.BankAccount]: 'View details',
}

const FILTERS_CONFIG = {
  [DueDiligenceTableItemType.Contact]: DUE_DILIGENCE_TEAM_CONTACTS_LIST_FILTERS_CONFIG,
  [DueDiligenceTableItemType.Executive]: DUE_DILIGENCE_TEAM_EXECUTIVES_LIST_FILTERS_CONFIG,
  [DueDiligenceTableItemType.BoardMember]: DUE_DILIGENCE_TEAM_BOARD_MEMBERS_LIST_FILTERS_CONFIG,
  [DueDiligenceTableItemType.Reference]: DUE_DILIGENCE_TEAM_REFERENCES_LIST_FILTERS_CONFIG,
  [DueDiligenceTableItemType.InventoryLocation]:
    DUE_DILIGENCE_FINANCIALS_INVENTORY_LOCATIONS_LIST_FILTERS_CONFIG,
  [DueDiligenceTableItemType.BankAccount]:
    DUE_DILIGENCE_FINANCIALS_BANK_ACCOUNTS_LIST_FILTERS_CONFIG,
}

const MissingFieldsIcon = ({ missingFields }: { missingFields: string[] }) => {
  const title = useMemo(() => `Missing fields: ${missingFields.join(', ')}`, [missingFields])

  if (!missingFields.length) {
    return null
  }

  return (
    <Tooltip title={title} placement="bottom-start" disableTouchListener>
      <WarningIcon className={styles.warningIcon} />
    </Tooltip>
  )
}

const DueDiligenceTableRowActions = ({
  item,
  type,
  handleView,
  handleEdit,
  handleDelete,
}: {
  item: IDueDiligenceTableItem
  type: DueDiligenceTableItemType
  handleView: (item: IDueDiligenceTableItem) => void
  handleEdit: (item: IDueDiligenceTableItem) => void
  handleDelete: (item: IDueDiligenceTableItem) => void
}) => {
  const [anchorEl, setAnchorEl] = useState(null)
  const [actionsMenuOpen, setActionsMenuOpen] = useState(false)

  const handleClickMenu = useCallback((event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
    setActionsMenuOpen(true)
  }, [])

  const handleCloseMenu = useCallback(() => {
    setActionsMenuOpen(false)
    setAnchorEl(null)
  }, [])

  const handleViewItem = useCallback(() => {
    handleCloseMenu()
    handleView(item)
  }, [item, handleView, handleCloseMenu])

  const handleEditItem = useCallback(() => {
    handleCloseMenu()
    handleEdit(item)
  }, [item, handleEdit, handleCloseMenu])

  const handleDeleteItem = useCallback(() => {
    handleCloseMenu()
    handleDelete(item)
  }, [item, handleDelete, handleCloseMenu])

  return (
    <TableCell className={genericSs.tableTextRight}>
      <MenuIcon isActive={actionsMenuOpen} onClick={handleClickMenu} size="small" />

      <Menu open={actionsMenuOpen} anchorEl={anchorEl} onClose={handleCloseMenu}>
        <MenuItem onClick={handleViewItem}>{VIEW_ACTION_LABEL[type]}</MenuItem>
        <MenuItem onClick={handleEditItem}>Edit</MenuItem>
        <MenuItem onClick={handleDeleteItem}>Delete</MenuItem>
      </Menu>
    </TableCell>
  )
}

const DueDiligenceTableTeamContactRow = ({
  contact,
  handleView,
  handleEdit,
  handleDelete,
}: {
  contact: IDueDiligenceContact
  handleView: (item: IDueDiligenceTableItem) => void
  handleEdit: (item: IDueDiligenceTableItem) => void
  handleDelete: (item: IDueDiligenceTableItem) => void
}) => {
  const missingFields = useMemo(
    () => getDueDiligenceTableItemMissingFields(DueDiligenceTableItemType.Contact, contact),
    [contact],
  )

  return (
    <TableRow>
      <TableCell className={genericSs.tableTextLeft}>
        <MissingFieldsIcon missingFields={missingFields} />
        {contact.firstName} {contact.lastName}
      </TableCell>
      <TableCell className={genericSs.tableTextLeft}>{contact.phone}</TableCell>
      <TableCell className={genericSs.tableTextLeft}>{contact.email}</TableCell>
      <DueDiligenceTableRowActions
        item={contact}
        type={DueDiligenceTableItemType.Contact}
        handleView={handleView}
        handleEdit={handleEdit}
        handleDelete={handleDelete}
      />
    </TableRow>
  )
}

const DueDiligenceTableTeamExecutiveRow = ({
  executive,
  handleView,
  handleEdit,
  handleDelete,
}: {
  executive: IDueDiligenceExecutive
  handleView: (item: IDueDiligenceTableItem) => void
  handleEdit: (item: IDueDiligenceTableItem) => void
  handleDelete: (item: IDueDiligenceTableItem) => void
}) => {
  const missingFields = useMemo(
    () => getDueDiligenceTableItemMissingFields(DueDiligenceTableItemType.Executive, executive),
    [executive],
  )

  return (
    <TableRow>
      <TableCell className={genericSs.tableTextLeft}>
        <MissingFieldsIcon missingFields={missingFields} />
        {executive.title}
      </TableCell>
      <TableCell className={genericSs.tableTextLeft}>
        {executive.firstName} {executive.lastName}
      </TableCell>
      <TableCell className={genericSs.tableTextLeft}>{executive.email}</TableCell>
      <TableCell className={genericSs.tableTextRight}>
        {executive.birthday ? formatDate(executive.birthday) : '-'}
      </TableCell>
      <DueDiligenceTableRowActions
        item={executive}
        type={DueDiligenceTableItemType.Executive}
        handleView={handleView}
        handleEdit={handleEdit}
        handleDelete={handleDelete}
      />
    </TableRow>
  )
}

const DueDiligenceTableTeamBoardMemberRow = ({
  boardMember,
  handleView,
  handleEdit,
  handleDelete,
}: {
  boardMember: IDueDiligenceBoardMember
  handleView: (item: IDueDiligenceTableItem) => void
  handleEdit: (item: IDueDiligenceTableItem) => void
  handleDelete: (item: IDueDiligenceTableItem) => void
}) => {
  const missingFields = useMemo(
    () => getDueDiligenceTableItemMissingFields(DueDiligenceTableItemType.BoardMember, boardMember),
    [boardMember],
  )

  return (
    <TableRow>
      <TableCell className={genericSs.tableTextLeft}>
        <MissingFieldsIcon missingFields={missingFields} />
        {boardMember.firstName} {boardMember.lastName}
      </TableCell>
      <TableCell className={genericSs.tableTextLeft}>{boardMember.email}</TableCell>
      <TableCell className={genericSs.tableTextLeft}>{boardMember.company}</TableCell>
      <TableCell className={genericSs.tableTextLeft}>{boardMember.relationshipToCompany}</TableCell>
      <DueDiligenceTableRowActions
        item={boardMember}
        type={DueDiligenceTableItemType.BoardMember}
        handleView={handleView}
        handleEdit={handleEdit}
        handleDelete={handleDelete}
      />
    </TableRow>
  )
}

const DueDiligenceTableTeamReferenceRow = ({
  reference,
  handleView,
  handleEdit,
  handleDelete,
}: {
  reference: IDueDiligenceReference
  handleView: (item: IDueDiligenceTableItem) => void
  handleEdit: (item: IDueDiligenceTableItem) => void
  handleDelete: (item: IDueDiligenceTableItem) => void
}) => {
  const missingFields = useMemo(
    () => getDueDiligenceTableItemMissingFields(DueDiligenceTableItemType.Reference, reference),
    [reference],
  )

  return (
    <TableRow>
      <TableCell className={genericSs.tableTextLeft}>
        <MissingFieldsIcon missingFields={missingFields} />
        {reference.firstName} {reference.lastName}
      </TableCell>
      <TableCell className={genericSs.tableTextLeft}>{reference.businessName}</TableCell>
      <TableCell className={genericSs.tableTextLeft}>{reference.email}</TableCell>
      <TableCell className={genericSs.tableTextLeft}>{reference.phone}</TableCell>
      <TableCell className={genericSs.tableTextLeft}>{reference.relationshipToCompany}</TableCell>
      <DueDiligenceTableRowActions
        item={reference}
        type={DueDiligenceTableItemType.Reference}
        handleView={handleView}
        handleEdit={handleEdit}
        handleDelete={handleDelete}
      />
    </TableRow>
  )
}

const DueDiligenceTableFinancialsInventoryLocationRow = ({
  inventoryLocation,
  handleView,
  handleEdit,
  handleDelete,
}: {
  inventoryLocation: IDueDiligenceInventoryLocation
  handleView: (item: IDueDiligenceTableItem) => void
  handleEdit: (item: IDueDiligenceTableItem) => void
  handleDelete: (item: IDueDiligenceTableItem) => void
}) => {
  const missingFields = useMemo(
    () =>
      getDueDiligenceTableItemMissingFields(
        DueDiligenceTableItemType.InventoryLocation,
        inventoryLocation,
      ),
    [inventoryLocation],
  )

  return (
    <TableRow>
      <TableCell className={genericSs.tableTextLeft}>
        <MissingFieldsIcon missingFields={missingFields} />
        {inventoryLocation.name}
      </TableCell>
      <TableCell className={genericSs.tableTextLeft}>{inventoryLocation.type}</TableCell>
      <TableCell className={genericSs.tableTextLeft}>{inventoryLocation.landlord}</TableCell>
      <TableCell className={genericSs.tableTextLeft}>
        {[
          inventoryLocation.street,
          inventoryLocation.city,
          inventoryLocation.state,
          inventoryLocation.postalCode,
          inventoryLocation.country,
        ]
          .filter(Boolean)
          .join(', ')}
      </TableCell>
      <DueDiligenceTableRowActions
        item={inventoryLocation}
        type={DueDiligenceTableItemType.InventoryLocation}
        handleView={handleView}
        handleEdit={handleEdit}
        handleDelete={handleDelete}
      />
    </TableRow>
  )
}

const DueDiligenceTableFinancialsBankAccountRow = ({
  bankAccount,
  handleView,
  handleEdit,
  handleDelete,
}: {
  bankAccount: IDueDiligenceBankAccount
  handleView: (item: IDueDiligenceTableItem) => void
  handleEdit: (item: IDueDiligenceTableItem) => void
  handleDelete: (item: IDueDiligenceTableItem) => void
}) => {
  return (
    <TableRow>
      <TableCell className={genericSs.tableTextLeft}>{bankAccount.bankName}</TableCell>
      <TableCell className={genericSs.tableTextLeft}>
        <Tooltip
          title={bankAccount.bankAccountNumber}
          arrow
          placement="top"
          classes={{
            tooltip: styles.bankAccountNumberTooltip,
            arrow: styles.bankAccountNumberTooltipArrow,
          }}
          leaveDelay={1000}
        >
          <span>{`XXXXX${bankAccount.bankAccountNumber.slice(-4)}`}</span>
        </Tooltip>
      </TableCell>
      <TableCell className={genericSs.tableTextLeft}>{bankAccount.abaRoutingNumber}</TableCell>
      <TableCell className={genericSs.tableTextLeft}>
        <Tooltip
          title={bankAccount.purpose}
          arrow
          placement="top-start"
          classes={{
            tooltip: styles.bankAccountNumberTooltip,
            arrow: styles.bankAccountNumberTooltipArrow,
          }}
          leaveDelay={1000}
        >
          <span>{bankAccount.purpose}</span>
        </Tooltip>
      </TableCell>
      <DueDiligenceTableRowActions
        item={bankAccount}
        type={DueDiligenceTableItemType.BankAccount}
        handleView={handleView}
        handleEdit={handleEdit}
        handleDelete={handleDelete}
      />
    </TableRow>
  )
}

const DueDiligenceTableRow = ({
  type,
  item,
  handleView,
  handleEdit,
  handleDelete,
}: {
  type: DueDiligenceTableItemType
  item: IDueDiligenceTableItem
  handleView: (item: IDueDiligenceTableItem) => void
  handleEdit: (item: IDueDiligenceTableItem) => void
  handleDelete: (item: IDueDiligenceTableItem) => void
}) => {
  if (type === DueDiligenceTableItemType.Contact) {
    return (
      <DueDiligenceTableTeamContactRow
        contact={item as IDueDiligenceContact}
        handleView={handleView}
        handleEdit={handleEdit}
        handleDelete={handleDelete}
      />
    )
  }

  if (type === DueDiligenceTableItemType.Executive) {
    return (
      <DueDiligenceTableTeamExecutiveRow
        executive={item as IDueDiligenceExecutive}
        handleView={handleView}
        handleEdit={handleEdit}
        handleDelete={handleDelete}
      />
    )
  }

  if (type === DueDiligenceTableItemType.BoardMember) {
    return (
      <DueDiligenceTableTeamBoardMemberRow
        boardMember={item as IDueDiligenceBoardMember}
        handleView={handleView}
        handleEdit={handleEdit}
        handleDelete={handleDelete}
      />
    )
  }

  if (type === DueDiligenceTableItemType.Reference) {
    return (
      <DueDiligenceTableTeamReferenceRow
        reference={item as IDueDiligenceReference}
        handleView={handleView}
        handleEdit={handleEdit}
        handleDelete={handleDelete}
      />
    )
  }

  if (type === DueDiligenceTableItemType.InventoryLocation) {
    return (
      <DueDiligenceTableFinancialsInventoryLocationRow
        inventoryLocation={item as IDueDiligenceInventoryLocation}
        handleView={handleView}
        handleEdit={handleEdit}
        handleDelete={handleDelete}
      />
    )
  }

  if (type === DueDiligenceTableItemType.BankAccount) {
    return (
      <DueDiligenceTableFinancialsBankAccountRow
        bankAccount={item as IDueDiligenceBankAccount}
        handleView={handleView}
        handleEdit={handleEdit}
        handleDelete={handleDelete}
      />
    )
  }

  return null
}

interface IProps {
  type: DueDiligenceTableItemType
  data: ILoadingData<{
    data: IDueDiligenceTableItem[]
  }>
  handleList: (params: object) => void
  handleHide: () => void
  handleAdd: (data: object) => Promise<any>
  handleUpdate: (id: string, data: object) => Promise<any>
  handleDelete: (id: string) => Promise<void>
  banks?: IBank[]
  handleAddBank?: (name: string) => void
}

const DueDiligenceTable = ({
  type,
  data,
  handleList,
  handleHide,
  handleAdd,
  handleUpdate,
  handleDelete,
  banks,
  handleAddBank,
}: IProps) => {
  const [orderBy, setOrderBy] = useState({
    field: null,
    direction: 'ASC',
  })
  const [isSaving, setIsSaving] = useState(false)
  const [selectedItem, setSelectedItem] = useState<IDueDiligenceTableItem>(null)
  const [isSaveModalShown, setIsSaveModalShown] = useState(false)
  const [isViewModalShown, setIsViewModalShown] = useState(false)
  const [isDeleteModalShown, setIsDeleteModalShown] = useState(false)

  const handleOrderChange = useCallback((field: string) => {
    setOrderBy((order) => ({
      field,
      direction: order.field === field ? (order.direction === 'DESC' ? 'ASC' : 'DESC') : 'ASC',
    }))
  }, [])

  const debounceListItems = useMemo(
    () =>
      debounceEventHandler((params: any) => {
        handleList(params)
      }, 500),
    [handleList],
  )

  useEffect(() => {
    debounceListItems({
      orderBy: orderBy.field,
      orderDirection: orderBy.direction,
    })
  }, [orderBy, debounceListItems])

  useEffect(() => {
    return () => {
      handleHide()
    }
  }, [handleHide])

  const refetchItemsList = useCallback(
    (skipLoader: boolean = false) => {
      handleList({
        orderBy: orderBy.field,
        orderDirection: orderBy.direction,
        skipLoader,
      })
    },
    [orderBy, handleList],
  )

  const filtersConfig = useMemo(() => FILTERS_CONFIG[type], [type])

  const onAddItem = useCallback(() => {
    setIsSaveModalShown(true)
  }, [])

  const onEditItem = useCallback((item: IDueDiligenceTableItem) => {
    setIsViewModalShown(false)
    setSelectedItem(item)
    setIsSaveModalShown(true)
  }, [])

  const handleSaveConfirm = useCallback(
    async (data: Partial<IDueDiligenceTableItem>) => {
      const result = selectedItem
        ? await handleUpdate(selectedItem.id, data)
        : await handleAdd(data)
      if (!result.error) {
        await refetchItemsList(true)
        setSelectedItem(null)
      }
      return result
    },
    [selectedItem, handleAdd, handleUpdate, refetchItemsList],
  )

  const handleSaveCancel = useCallback(() => {
    setSelectedItem(null)
    setIsSaveModalShown(false)
  }, [])

  const onViewItem = useCallback((item: IDueDiligenceTableItem) => {
    setSelectedItem(item)
    setIsViewModalShown(true)
  }, [])

  const handleViewCancel = useCallback(() => {
    setSelectedItem(null)
    setIsViewModalShown(false)
  }, [])

  const onDeleteItem = useCallback((item: IDueDiligenceTableItem) => {
    setIsViewModalShown(false)
    setSelectedItem(item)
    setIsDeleteModalShown(true)
  }, [])

  const handleDeleteConfirm = useCallback(async () => {
    setIsSaving(true)
    await handleDelete(selectedItem.id)
    await refetchItemsList(true)
    setIsSaving(false)
    setSelectedItem(null)
    setIsDeleteModalShown(false)
  }, [selectedItem, handleDelete, refetchItemsList])

  const handleDeleteCancel = useCallback(() => {
    setSelectedItem(null)
    setIsDeleteModalShown(false)
  }, [])

  return (
    <TableContainer
      className={cn(styles.tableContainer, {
        [styles.tableContainerContacts]: type === DueDiligenceTableItemType.Contact,
        [styles.tableContainerExecutives]: type === DueDiligenceTableItemType.Executive,
        [styles.tableContainerBoardMembers]: type === DueDiligenceTableItemType.BoardMember,
        [styles.tableContainerReferences]: type === DueDiligenceTableItemType.Reference,
        [styles.tableContainerInventoryLocations]:
          type === DueDiligenceTableItemType.InventoryLocation,
        [styles.tableContainerBankAccounts]: type === DueDiligenceTableItemType.BankAccount,
      })}
    >
      <Box display="flex" justifyContent="space-between" alignItems="center" mb={3}>
        <h3 className={styles.title}>{TITLE[type]}</h3>
        <AddButton onClick={onAddItem} />
      </Box>

      <Table>
        <TableHead>
          <TableFiltersRow
            filters={filtersConfig}
            orderBy={orderBy}
            handleOrderChange={handleOrderChange}
          />
        </TableHead>
        <TableBody>
          {data.isLoading ? (
            <TableLoader columnsCount={filtersConfig.length} rowsCount={3} height={38} />
          ) : !data.data?.data?.length ? (
            <TableRow className={styles.emptyRow}>
              <TableCell colSpan={filtersConfig.length}>
                <div className={styles.emptyRowContainer}>
                  <div className={styles.emptyRowTitle}>Add {LABEL[type]}</div>
                  <div className={styles.emptyRowText}>
                    You have not added any {LABEL[type]} yet
                  </div>
                </div>
              </TableCell>
            </TableRow>
          ) : null}
          {data.data?.data.map((item) => (
            <DueDiligenceTableRow
              key={item.id}
              type={type}
              item={item}
              handleView={onViewItem}
              handleEdit={onEditItem}
              handleDelete={onDeleteItem}
            />
          ))}
        </TableBody>
      </Table>

      {isSaveModalShown && (
        <DueDiligenceSaveItemModal
          type={type}
          item={selectedItem}
          handleSaveItem={handleSaveConfirm}
          onCloseModal={handleSaveCancel}
          banks={banks}
          handleAddBank={handleAddBank}
        />
      )}

      {isViewModalShown && selectedItem && (
        <DueDiligenceViewItemModal
          type={type}
          item={selectedItem}
          handleEditItem={onEditItem}
          handleDeleteItem={onDeleteItem}
          onCloseModal={handleViewCancel}
        />
      )}

      {isDeleteModalShown && selectedItem && (
        <WarningModal
          warningMessage="You will not be able to recover this info"
          onConfirm={handleDeleteConfirm}
          onCancel={handleDeleteCancel}
          confirmText="Delete"
          cancelText="Cancel"
          isLoading={isSaving}
          disabled={isSaving}
        />
      )}
    </TableContainer>
  )
}

export default DueDiligenceTable
