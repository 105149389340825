import React, { useCallback } from 'react'
import MuiStepper from '@mui/material/Stepper'
import MuiStep from '@mui/material/Step'
import StepLabel from '@mui/material/StepLabel'
import StepConnector from '@mui/material/StepConnector'

import styles from './Stepper.module.scss'

import { CLIENT_INTAKE_STEPS_LIST, CLIENT_INTAKE_HEADINGS } from '@common/constants/client'

interface IProps {
  activeStep: number
  handleStepChange: (step: number) => void
}

const Step = ({
  step,
  isCompleted,
  handleStepChange,
}: {
  step: number
  isCompleted: boolean
  handleStepChange: (step: number) => void
}) => {
  const onClick = useCallback(() => {
    if (isCompleted) {
      handleStepChange(step)
    }
  }, [step, isCompleted, handleStepChange])

  return (
    <MuiStep
      key={step}
      completed={isCompleted}
      classes={{
        completed: styles.stepCompleted,
      }}
      onClick={onClick}
      index={step - 1}
    >
      <StepLabel
        classes={{
          active: styles.stepLabelActive,
          completed: styles.stepLabelActive,
        }}
        StepIconProps={{
          classes: {
            root: styles.stepIcon,
            text: styles.stepIconText,
            active: styles.stepIconActive,
            completed: styles.stepIconActive,
          },
        }}
      >
        {CLIENT_INTAKE_HEADINGS[step].title}
      </StepLabel>
    </MuiStep>
  )
}

const Stepper = ({ activeStep, handleStepChange }: IProps) => {
  return (
    <div className={styles.wrapper}>
      <MuiStepper
        activeStep={activeStep}
        nonLinear
        connector={
          <StepConnector
            classes={{
              line: styles.stepConnectorLine,
              active: styles.activeStepConnector,
              completed: styles.completedStepConnector,
            }}
          />
        }
      >
        {CLIENT_INTAKE_STEPS_LIST.map((step) => (
          <Step
            key={step}
            step={step}
            isCompleted={activeStep > step - 1}
            handleStepChange={handleStepChange}
          />
        ))}
      </MuiStepper>
    </div>
  )
}

export default Stepper
