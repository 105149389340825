import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'

import DueDiligenceProcessDocumentsInventoryPage from './DueDiligenceProcessDocumentsInventoryPage'

import { dueDiligenceInfo, dueDiligenceReportingFlows } from '../../redux/dueDiligence/selectors'
import { processDocuments, show, showDocuments } from '../../redux/dueDiligence/actions'

const selector = createStructuredSelector({
  dueDiligenceInfo,
  dueDiligenceReportingFlowsData: dueDiligenceReportingFlows,
})
const actions = {
  show,
  showDocuments,
  processDocuments,
}

export default connect(selector, actions)(DueDiligenceProcessDocumentsInventoryPage)
