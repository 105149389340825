import { connect } from 'react-redux'

import EntityPreview from './EntityPreview'

import { getEntityPreview } from '../../redux/entityInfo/actions'

const actions = {
  getEntityPreview,
}

export default connect(null, actions)(EntityPreview)
