import React, { useCallback } from 'react'
import { useHistory } from 'react-router'
import Tooltip from '@mui/material/Tooltip'

import styles from './ClientBanners.module.scss'

import { IClientBanner } from '@common/interfaces/client'
import { CloseIcon } from '../Common/Icons'

const images = require['context']('../../assets/images', true)

interface IProps {
  banners: IClientBanner[]
  closeBanner: (id: string) => Promise<void>
}

const ClientBanner = ({
  banner,
  closeBanner,
}: {
  banner: IClientBanner
  closeBanner: (id: string) => Promise<void>
}) => {
  const history = useHistory()

  const onLinkClick = useCallback(() => {
    if (!banner?.link) {
      return null
    }

    closeBanner(banner.id)
    if (banner.link.startsWith('/')) {
      history.push(banner.link)
    } else {
      window.open(banner.link)
    }
  }, [banner, history, closeBanner])

  const onClose = useCallback(() => {
    if (!banner) {
      return null
    }

    closeBanner(banner.id)
  }, [closeBanner, banner])

  const loadImage = useCallback(() => {
    if (banner.icon) {
      return images(`./${banner.icon}`)
    }

    return null
  }, [banner])

  if (!banner) {
    return null
  }

  return (
    <div className={styles.clientBanner} style={{ background: banner.color }}>
      {banner.icon && <img src={loadImage()} alt="Icon" />}
      {banner.holidayId ? (
        <span className={styles.clientBannerContent}>
          <span className={styles.title}>{banner.title}</span>&nbsp;
          <span
            dangerouslySetInnerHTML={{
              __html: banner.description,
            }}
          />
        </span>
      ) : (
        <Tooltip title={`${banner.title} ${banner.description}`} placement="bottom">
          <span className={styles.clientBannerContent}>
            <span className={styles.title}>{banner.title}</span>&nbsp;
            {banner.description}
          </span>
        </Tooltip>
      )}
      {banner.link && (
        <div onClick={onLinkClick} className={styles.link}>
          Take me there
        </div>
      )}
      <CloseIcon action={onClose} />
    </div>
  )
}

const ClientBanners = ({ banners, closeBanner }: IProps) => {
  return (
    <div className={styles.clientBannersContainer}>
      {banners.map((banner) => (
        <ClientBanner key={banner.id} banner={banner} closeBanner={closeBanner} />
      ))}
    </div>
  )
}

export default ClientBanners
