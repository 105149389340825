import { EXPORT_CLIENT_FEE_STATEMENT_REQUEST } from './../clientInfo/actions'
import { combineReducers } from 'redux'

import * as actions from './actions'
import { IEndOfMonthFlow } from '@common/interfaces/loanServicing'
import { loadingArrayReducer } from '../../helpers/helpers'

export interface ILoanBalanceState {
  loadingArray: string[]
  loanBalanceStartDate: string
  endOfMonthFlow: IEndOfMonthFlow
}

export default combineReducers<ILoanBalanceState>({
  loadingArray: loadingArrayReducer([
    actions.prefix,
    EXPORT_CLIENT_FEE_STATEMENT_REQUEST.replace('_REQUEST', ''),
  ]),
  loanBalanceStartDate(state: string = null, action) {
    switch (action.type) {
      case actions.GET_LOAN_BALANCE_START_DATE_SUCCESS:
        return action.data
      default:
        return state
    }
  },
  endOfMonthFlow(state: IEndOfMonthFlow = null, action) {
    switch (action.type) {
      case actions.GET_END_OF_MONTH_FLOW_SUCCESS:
        return action.data
      default:
        return state
    }
  },
})
