import { prefix } from './actions'
import { IRootState } from '../reducer'

export const isLoading = (state: IRootState) => state[prefix].loadingArray.length > 0

export const capTable = (state: IRootState) => state[prefix].capTable

export const capTableMapping = (state: IRootState) => state[prefix].capTableMapping

export const capTableSummary = (state: IRootState) => state[prefix].capTableSummary
