import Base from './base'

export default class ClientIntake extends Base {
  start(salesforceId: string) {
    return this.apiClient.post('client-intake', { salesforceId })
  }

  loadVendors(salesforceId: string, data: object) {
    return this.apiClient.get(`client-intake/${salesforceId}/vendors`, data)
  }

  getCodatLink(salesforceId: string) {
    return this.apiClient.get(`client-intake/${salesforceId}/codat-link`)
  }

  saveContact(salesforceId: string, data: object) {
    return this.apiClient.post(`client-intake/${salesforceId}/contact`, data)
  }

  saveCompanyInfo(salesforceId: string, data: object) {
    return this.apiClient.post(`client-intake/${salesforceId}/company-info`, data)
  }

  savePeopleAndTools(salesforceId: string, data: object) {
    return this.apiClient.post(`client-intake/${salesforceId}/people-and-tools`, data)
  }

  uploadDocument(salesforceId: string, data: FormData) {
    return this.apiClient.post(`client-intake/${salesforceId}/documentation`, data)
  }

  deleteDocument(salesforceId: string, fileId: string) {
    return this.apiClient.delete(`client-intake/${salesforceId}/documentation/${fileId}`)
  }

  submit(salesforceId: string) {
    return this.apiClient.post(`client-intake/${salesforceId}`)
  }
}
