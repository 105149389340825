import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'

import BasicInformation from './BasicInformation'
import {
  listAccounts,
  updateAccount,
  describeAccount,
  showAccount,
  hideAccount,
} from '../../../redux/salesforce/actions'
import {
  accounts,
  account,
  accountIndustries,
  accountSalesChannels,
} from '../../../redux/salesforce/selectors'
import { updateClient } from '../../../redux/clientInfo/actions'
import { clientInfo } from '../../../redux/clientInfo/selectors'

const selector = createStructuredSelector({
  accounts,
  account,
  accountIndustries,
  accountSalesChannels,
  clientInfo,
})

const actions = {
  listAccounts,
  updateAccount,
  describeAccount,
  showAccount,
  hideAccount,
  updateClient,
}

export default connect(selector, actions)(BasicInformation)
