import { IApi } from '../../api'
import { CommonPromiseAction } from '../types'

export const prefix = 'clientIntake'

export const START_REQUEST = `${prefix}/START_REQUEST`
export const START_SUCCESS = `${prefix}/START_SUCCESS`
export const START_FAILURE = `${prefix}/START_FAILURE`

export const start: CommonPromiseAction = (salesforceId: string) => ({
  type: [START_REQUEST, START_SUCCESS, START_FAILURE],
  promise: (api: IApi) => api.clientIntake.start(salesforceId),
})

export const LOAD_VENDORS_REQUEST = `${prefix}/LOAD_VENDORS_REQUEST`
export const LOAD_VENDORS_SUCCESS = `${prefix}/LOAD_VENDORS_SUCCESS`
export const LOAD_VENDORS_FAILURE = `${prefix}/LOAD_VENDORS_FAILURE`

export const loadVendors: CommonPromiseAction = (id: string, data: object) => ({
  type: [LOAD_VENDORS_REQUEST, LOAD_VENDORS_SUCCESS, LOAD_VENDORS_FAILURE],
  promise: (api: IApi) => api.clientIntake.loadVendors(id, data),
})

export const GET_CODAT_LINK_REQUEST = `${prefix}/GET_CODAT_LINK_REQUEST`
export const GET_CODAT_LINK_SUCCESS = `${prefix}/GET_CODAT_LINK_SUCCESS`
export const GET_CODAT_LINK_FAILURE = `${prefix}/GET_CODAT_LINK_FAILURE`

export const getCodatLink: CommonPromiseAction = (salesforceId: string) => ({
  type: [GET_CODAT_LINK_REQUEST, GET_CODAT_LINK_SUCCESS, GET_CODAT_LINK_FAILURE],
  promise: (api: IApi) => api.clientIntake.getCodatLink(salesforceId),
})

export const SAVE_CONTACT_REQUEST = `${prefix}/SAVE_CONTACT_REQUEST`
export const SAVE_CONTACT_SUCCESS = `${prefix}/SAVE_CONTACT_SUCCESS`
export const SAVE_CONTACT_FAILURE = `${prefix}/SAVE_CONTACT_FAILURE`

export const saveContact: CommonPromiseAction = (salesforceId: string, data: object) => ({
  type: [SAVE_CONTACT_REQUEST, SAVE_CONTACT_SUCCESS, SAVE_CONTACT_FAILURE],
  promise: (api: IApi) => api.clientIntake.saveContact(salesforceId, data),
})

export const SAVE_COMPANY_INFO_REQUEST = `${prefix}/SAVE_COMPANY_INFO_REQUEST`
export const SAVE_COMPANY_INFO_SUCCESS = `${prefix}/SAVE_COMPANY_INFO_SUCCESS`
export const SAVE_COMPANY_INFO_FAILURE = `${prefix}/SAVE_COMPANY_INFO_FAILURE`

export const saveCompanyInfo: CommonPromiseAction = (salesforceId: string, data: object) => ({
  type: [SAVE_COMPANY_INFO_REQUEST, SAVE_COMPANY_INFO_SUCCESS, SAVE_COMPANY_INFO_FAILURE],
  promise: (api: IApi) => api.clientIntake.saveCompanyInfo(salesforceId, data),
})

export const SAVE_PEOPLE_AND_TOOLS_REQUEST = `${prefix}/SAVE_PEOPLE_AND_TOOLS_REQUEST`
export const SAVE_PEOPLE_AND_TOOLS_SUCCESS = `${prefix}/SAVE_PEOPLE_AND_TOOLS_SUCCESS`
export const SAVE_PEOPLE_AND_TOOLS_FAILURE = `${prefix}/SAVE_PEOPLE_AND_TOOLS_FAILURE`

export const savePeopleAndTools: CommonPromiseAction = (salesforceId: string, data: object) => ({
  type: [
    SAVE_PEOPLE_AND_TOOLS_REQUEST,
    SAVE_PEOPLE_AND_TOOLS_SUCCESS,
    SAVE_PEOPLE_AND_TOOLS_FAILURE,
  ],
  promise: (api: IApi) => api.clientIntake.savePeopleAndTools(salesforceId, data),
})

export const UPLOAD_DOCUMENT_REQUEST = `${prefix}/UPLOAD_DOCUMENT_REQUEST`
export const UPLOAD_DOCUMENT_SUCCESS = `${prefix}/UPLOAD_DOCUMENT_SUCCESS`
export const UPLOAD_DOCUMENT_FAILURE = `${prefix}/UPLOAD_DOCUMENT_FAILURE`

export const uploadDocument: CommonPromiseAction = (salesforceId: string, data: FormData) => ({
  type: [UPLOAD_DOCUMENT_REQUEST, UPLOAD_DOCUMENT_SUCCESS, UPLOAD_DOCUMENT_FAILURE],
  promise: (api: IApi) => api.clientIntake.uploadDocument(salesforceId, data),
})

export const DELETE_DOCUMENT_REQUEST = `${prefix}/DELETE_DOCUMENT_REQUEST`
export const DELETE_DOCUMENT_SUCCESS = `${prefix}/DELETE_DOCUMENT_SUCCESS`
export const DELETE_DOCUMENT_FAILURE = `${prefix}/DELETE_DOCUMENT_FAILURE`

export const deleteDocument: CommonPromiseAction = (salesforceId: string, fileId: string) => ({
  type: [DELETE_DOCUMENT_REQUEST, DELETE_DOCUMENT_SUCCESS, DELETE_DOCUMENT_FAILURE],
  promise: (api: IApi) => api.clientIntake.deleteDocument(salesforceId, fileId),
})

export const SUBMIT_REQUEST = `${prefix}/SUBMIT_REQUEST`
export const SUBMIT_SUCCESS = `${prefix}/SUBMIT_SUCCESS`
export const SUBMIT_FAILURE = `${prefix}/SUBMIT_FAILURE`

export const submit: CommonPromiseAction = (salesforceId: string) => ({
  type: [SUBMIT_REQUEST, SUBMIT_SUCCESS, SUBMIT_FAILURE],
  promise: (api: IApi) => api.clientIntake.submit(salesforceId),
})
