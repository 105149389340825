import { combineReducers } from 'redux'
import * as actions from './actions'
import { IAuditLogData, IAuditLogDictionary } from '@common/interfaces/auditLog'

export interface IAuditLogState {
  loadingArray: string[]
  data: IAuditLogData
  dictionary: IAuditLogDictionary
}

export default combineReducers<IAuditLogState>({
  loadingArray(state: string[] = [], action) {
    switch (action.type) {
      case actions.LIST_AUDIT_LOG_REQUEST:
      case actions.EXPORT_AUDIT_LOG_REQUEST:
        return [...state, action.type]
      case actions.LIST_AUDIT_LOG_SUCCESS:
      case actions.LIST_AUDIT_LOG_FAILURE:
      case actions.EXPORT_AUDIT_LOG_SUCCESS:
      case actions.EXPORT_AUDIT_LOG_FAILURE:
        return state.filter(
          (item) =>
            item !== action.type.replace('SUCCESS', 'REQUEST').replace('FAILURE', 'REQUEST'),
        )
      default:
        return state
    }
  },
  data(state: IAuditLogData = null, action) {
    switch (action.type) {
      case actions.LIST_AUDIT_LOG_SUCCESS:
        if (!action.loadMore) {
          return action.data
        } else {
          return {
            ...state,
            data: [...state.data, ...action.data.data],
          }
        }
      default:
        return state
    }
  },
  dictionary(state: IAuditLogDictionary = null, action) {
    switch (action.type) {
      case actions.GET_AUDIT_LOG_DICTIONARY_SUCCESS:
        return action.data
      default:
        return state
    }
  },
})
