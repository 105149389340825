import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'

import ProspectReportingSummaryTermStructure from './ProspectReportingSummaryTermStructure'

import { termStructureSummaryInfo } from '../../redux/prospect/selectors'
import { showTermStructureSummaryInfo } from '../../redux/prospect/actions'

const selector = createStructuredSelector({
  termStructureSummaryInfo,
})

const actions = {
  showTermStructureSummaryInfo,
}

export default connect(selector, actions)(ProspectReportingSummaryTermStructure)
