import React from 'react'
import { Link } from 'react-router-dom'

import styles from './OverdueReportingBanner.module.scss'

import { ReactComponent as WarningIcon } from '@assets/images/warning-icon-outline.svg'
import { IUser } from '@common/interfaces/user'
import { ROUTES } from '../../constants/routes'
import { ClientInfoStatus, IClientInfo } from '@common/interfaces/client'

interface IProps {
  user: IUser
  clientInfo: IClientInfo
}

const OverdueReportingBanner = ({ user, clientInfo }: IProps) => {
  if (!clientInfo?.isReportingOverdue || clientInfo?.clientStatus !== ClientInfoStatus.Current) {
    return null
  }

  return (
    <div className={styles.overdueReportingBanner}>
      <WarningIcon />
      <span className={styles.overdueContent}>
        <span className={styles.title}>Overdue reporting: </span>
        {user?.firstName}, there are overdue reports on your account. Resolving this prevents
        interruption in funding
      </span>
      <Link to={ROUTES.SUBMIT_REPORTING} className={styles.link}>
        Upload now
      </Link>
    </div>
  )
}

export default OverdueReportingBanner
