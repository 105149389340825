import { RouteComponentProps, withRouter } from 'react-router-dom'
import { connect, ConnectedComponent } from 'react-redux'
import { createStructuredSelector } from 'reselect'

import PrivateRoute from './PrivateRoute'
import { isAuthenticated, isInitialized } from '../../redux/profile/selectors'

export interface OwnProps {
  path: string
  exact?: boolean
  component: ConnectedComponent<any, any>
}

const selector = createStructuredSelector({
  isAuthenticated,
  isInitialized,
})

export default withRouter<RouteComponentProps & OwnProps, any>(
  connect(selector, null, null, { pure: false })(PrivateRoute),
)
