import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'

import EntityDilutionTable from './EntityDilutionTable'

import { entityDilution } from '../../redux/entityInfo/selectors'
import { getEntityDilution } from '../../redux/entityInfo/actions'
import { debtorIneligibleCategories } from '../../redux/options/selectors'

const selector = createStructuredSelector({
  entityDilutionData: entityDilution,
  debtorIneligibleCategories,
})

const actions = {
  getEntityDilution,
}

export default connect(selector, actions)(EntityDilutionTable)
