import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'

import PasswordSetUpPage from './PasswordSetUpPage'

import { processInvitation, setupPassword } from '../../redux/profile/actions'
import { isLoading, isPasswordSetup, invitedUser } from '../../redux/profile/selectors'

const selector = createStructuredSelector({
  isLoading,
  isPasswordSetup,
  invitedUser,
})

const actions = {
  processInvitation,
  setupPassword,
}

export default connect(selector, actions)(PasswordSetUpPage)
