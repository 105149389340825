import { combineReducers } from 'redux'
import * as actions from './actions'
import { IReportingDocument } from '@common/interfaces/reportingDocument'

export interface IReportingDocumentState {
  loadingArray: string[]
  list: IReportingDocument[]
}

export default combineReducers<IReportingDocumentState>({
  loadingArray(state: string[] = [], action) {
    switch (action.type) {
      case actions.CREATE_REQUEST:
      case actions.LIST_REQUEST:
        return [...state, action.type]
      case actions.CREATE_SUCCESS:
      case actions.CREATE_FAILURE:
      case actions.LIST_SUCCESS:
      case actions.LIST_FAILURE:
        return state.filter(
          (item) =>
            item !== action.type.replace('SUCCESS', 'REQUEST').replace('FAILURE', 'REQUEST'),
        )
      default:
        return state
    }
  },
  list(state: IReportingDocument[] = [], action) {
    switch (action.type) {
      case actions.CREATE_SUCCESS:
        return [...state, action.data]
      case actions.LIST_SUCCESS:
        return action.data
      default:
        return state
    }
  },
})
