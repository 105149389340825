import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useParams } from 'react-router'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import moment from 'moment'
import cn from 'classnames'

import styles from './ClientSetupTerminationPage.module.scss'
import genericSs from '@styles/generic.module.scss'

import Card from '../../components/Common/Card'
import Button from '../../components/Common/Button'
import { ClientInfoStatus, IClientInfo } from '@common/interfaces/client'
import { dateToString, debounceEventHandler, formatDate, formatter } from '../../helpers/helpers'
import TerminationForm from './TerminationForm'
import AdditionalFees from './AdditionalFees'
import { FeeType } from '@common/interfaces/loanServicing'
import ClientSetupHeader from '../../components/ClientSetupHeader'
import WarningModal from '../../components/WarningModal'

const today = moment().startOf('day').toDate()
interface IProps {
  clientInfo: IClientInfo
  isAdmin: boolean
  calculateTerminateClient: (id: string) => void
  generateTerminationLetter: (id: string) => void
  terminateClient: (id: string, data: object) => void
}

const ClientSetupTerminationPage = ({
  clientInfo,
  isAdmin,
  calculateTerminateClient,
  generateTerminationLetter,
  terminateClient,
}: IProps) => {
  const { id: clientId } = useParams<{ id?: string }>()
  const { id: clientInfoId } = clientInfo || {}
  const [isConfirmModalShown, setIsConfirmModalShown] = useState(false)
  const [isButtonLoading, setIsButtonLoading] = useState({ type: null, value: false })

  useEffect(() => {
    if (clientInfoId) {
      calculateTerminateClient(clientInfoId)
    }
  }, [clientInfoId, calculateTerminateClient])

  const disabled = useMemo(() => clientInfo?.clientStatus === ClientInfoStatus.Past, [clientInfo])

  const interestAmount = useMemo(() => {
    if (clientInfo?.overridePayoffInterest) {
      return Math.round(clientInfo.manualPayoffInterestAmount * 100) / 100
    }
    return (
      Math.round(
        ((clientInfo?.payoffInterestTotal || 0) + (clientInfo?.payoffFloatBalance || 0)) * 100,
      ) / 100
    )
  }, [clientInfo])

  const handleGenerateTerminationLetter = useCallback(async () => {
    if (clientId) {
      setIsButtonLoading({ type: 'terminationLetter', value: true })
      await generateTerminationLetter(clientId)
      setIsButtonLoading({ type: null, value: false })
    }
  }, [generateTerminationLetter, clientId])

  const handleTerminate = useCallback(() => {
    setIsConfirmModalShown(true)
  }, [])

  const handleTerminateConfirm = useCallback(async () => {
    if (clientInfo.id) {
      setIsButtonLoading({ type: 'terminate', value: true })
      await terminateClient(clientInfo.id, {
        payoffAmount: clientInfo.payoffAmount,
        payoffDate: clientInfo.payoffDate || moment().format('YYYY-MM-DD'),
      })
      setIsConfirmModalShown(false)
      setIsButtonLoading({ type: null, value: false })
    }
  }, [terminateClient, clientInfo])

  const handleTerminateCancel = useCallback(() => {
    setIsConfirmModalShown(false)
  }, [])

  const calculateManualInterest = useMemo(
    () =>
      clientInfo?.payoffFees
        ? clientInfo.payoffFees
            .filter(({ type }) => type === FeeType.ManualInterest)
            .reduce((sum, { amount }) => sum + +amount, 0) || 0
        : 0,
    [clientInfo],
  )

  const handleSaveTerminateData = useMemo(
    () =>
      debounceEventHandler((values: any) => {
        if (clientId) {
          terminateClient(clientId, {
            ...values,
            payoffAmount: null,
            payoffDate: dateToString(values.payoffDate),
          })
        }
      }, 500),
    [terminateClient, clientId],
  )

  const handleSaveAdditionalFees = useCallback(
    async (values) => {
      if (clientId) {
        setIsButtonLoading({ type: 'saveFees', value: true })
        await terminateClient(clientId, {
          payoffAmount: null,
          payoffDate: clientInfo?.payoffDate || moment().format('YYYY-MM-DD'),
          payoffFees: [
            ...clientInfo.payoffFees,
            { type: values.feeType, amount: values.amount, title: values.title },
          ],
        })
        setIsButtonLoading({ type: null, value: false })
      }
    },
    [terminateClient, clientInfo, clientId],
  )

  const handleDeleteFees = useCallback(
    async (feeIndex) => {
      if (clientId) {
        setIsButtonLoading({ type: 'deleteFee', value: true })
        await terminateClient(clientId, {
          payoffAmount: null,
          payoffDate: clientInfo?.payoffDate || moment().format('YYYY-MM-DD'),
          payoffFees: clientInfo.payoffFees.filter((_, index) => index !== feeIndex),
        })
        setIsButtonLoading({ type: null, value: false })
      }
    },
    [terminateClient, clientInfo, clientId],
  )

  const initialValues = useMemo(() => {
    if (!clientInfo) {
      return null
    }
    const totalFees = clientInfo.payoffFees.filter(({ amount }) => Number(amount) !== 0)
    const isCurrentClient = clientInfo.clientStatus === ClientInfoStatus.Current
    return {
      payoffDate:
        (moment(clientInfo.payoffDate).isBefore(today, 'day') || !clientInfo.payoffDate) &&
        isCurrentClient
          ? today
          : moment(clientInfo.payoffDate).startOf('day').toDate(),
      overridePayoffInterest: clientInfo.overridePayoffInterest,
      manualPayoffInterestAmount: interestAmount,
      payoffAmount: clientInfo.payoffAmount,
      applyLockboxFee: clientInfo.applyLockboxFee,
      overrideTerminationFee: clientInfo.overrideTerminationFee,
      applyServicingFee: clientInfo.applyServicingFee,
      terminationFee: clientInfo.terminationFee,
      servicingFee: clientInfo.servicingFee,
      totalFees,
      gracePeriod: clientInfo.gracePeriod,
    }
  }, [clientInfo, interestAmount])

  const { effectivePayoffAmount, payoffTitle } = useMemo(() => {
    if (!clientInfo) {
      return {
        effectivePayoffAmount: 0,
        payoffTitle: '',
      }
    }
    if (clientInfo?.payoffAmount < -(clientInfo?.wireFee || 0)) {
      return {
        payoffTitle: 'Pass-Through Amount (Wire fee applied)',
        effectivePayoffAmount: Math.abs(clientInfo.payoffAmount) - (clientInfo.wireFee || 0),
      }
    }
    return {
      payoffTitle: clientInfo.payoffAmount > 0 ? 'Payoff Amount' : 'Remaining Balance',
      effectivePayoffAmount: clientInfo.payoffAmount,
    }
  }, [clientInfo])

  return (
    <Box py={1} pr={2}>
      <ClientSetupHeader />
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        mb={3}
        marginBottom={'12px'}
      >
        <Box justifyContent="flex-start" className={styles.pageHeader}>
          {clientInfo?.clientName} Termination
        </Box>
        {isAdmin && !disabled && (
          <Box justifyContent="flex-end">
            <Button
              variant="contained"
              onClick={handleGenerateTerminationLetter}
              secondary
              className={styles.buttonMarginRight}
              isLoading={isButtonLoading?.type === 'terminationLetter' && isButtonLoading?.value}
            >
              Generate Letter
            </Button>

            <Button
              error
              variant="contained"
              onClick={handleTerminate}
              isLoading={isButtonLoading?.type === 'terminate' && isButtonLoading?.value}
            >
              Terminate
            </Button>
          </Box>
        )}
      </Box>

      {clientInfo && (
        <Grid container justifyContent={'space-between'} flexDirection={'row'} spacing={1}>
          <Grid item xs={7} lg={7}>
            <Grid container flexDirection={'column'}>
              <Grid item>
                <TerminationForm
                  clientInfo={clientInfo}
                  handleSaveTerminateData={handleSaveTerminateData}
                  initialValues={initialValues}
                />
              </Grid>
              <Grid item marginTop={'16px'}>
                <AdditionalFees
                  handleSaveAdditionalFees={handleSaveAdditionalFees}
                  handleDeleteFees={handleDeleteFees}
                  isButtonLoading={isButtonLoading}
                  totalFees={initialValues.totalFees}
                  disabled={disabled}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid marginTop={'18px'} item xs={4} lg={4}>
            <Card className={styles.card}>
              <div className={genericSs.cardTitle}>Payoff summary</div>

              <div className={styles.summaryList}>
                <div className={cn(styles.summaryListItem, styles.headerRow)}>
                  <div className={styles.summaryListData}>
                    <div className={styles.rowTitleBold}>Payoff date</div>
                    <div className={styles.rowValueBold}>
                      {formatDate(clientInfo.payoffDate || undefined)}
                    </div>
                  </div>
                </div>
                <div className={cn(styles.summaryListItem, styles.headerRow)}>
                  <div className={styles.summaryListData}>
                    <div className={styles.rowTitleBold}>Grace period</div>
                    <div className={styles.rowValueBold}>{clientInfo.gracePeriod} days</div>
                  </div>
                </div>
                <div className={styles.summaryListItem}>
                  <div className={styles.summaryListData}>
                    <div className={styles.rowTitleBold}>Servicing fee</div>
                    <div className={styles.rowValueBold}>
                      {formatter.format(clientInfo.applyServicingFee ? clientInfo.servicingFee : 0)}
                    </div>
                  </div>
                </div>
                <div className={cn(styles.summaryListItem, styles.headerRow)}>
                  <div className={styles.summaryListData}>
                    <div className={styles.rowTitleBold}>Principal</div>
                    <div className={styles.rowValueBold}>
                      {formatter.format(clientInfo.payoffLoanBalance || 0)}
                    </div>
                  </div>
                </div>
                <div className={styles.summaryListItem}>
                  <div className={styles.summaryListData}>
                    <div className={styles.rowTitle}>+ Interest through payoff date</div>
                    <div className={styles.rowValue}>{formatter.format(interestAmount)}</div>
                  </div>
                </div>
                <div className={styles.summaryListItem}>
                  <div className={styles.summaryListData}>
                    <div className={styles.rowTitle}>+ Lockbox fee</div>
                    <div className={styles.rowValue}>
                      {formatter.format(clientInfo.applyLockboxFee ? clientInfo.lockboxFee : 0)}
                    </div>
                  </div>
                </div>
                <div className={styles.summaryListItem}>
                  <div className={styles.summaryListData}>
                    <div className={styles.rowTitle}>+ Termination fee</div>
                    <div className={styles.rowValue}>
                      {formatter.format(clientInfo.terminationFee)}
                    </div>
                  </div>
                </div>
                <div className={styles.summaryListItem}>
                  <div className={styles.summaryListData}>
                    <div className={styles.rowTitle}>+ Legal fee</div>
                    <div className={styles.rowValue}>
                      {formatter.format(
                        clientInfo.payoffFees
                          .filter(
                            ({ type, title }) => type === FeeType.PassThroughs && title === 'Legal',
                          )
                          .reduce((sum, { amount }) => sum + +amount, 0) || 0,
                      )}
                    </div>
                  </div>
                </div>
                <div className={styles.summaryListItem}>
                  <div className={styles.summaryListData}>
                    <div className={styles.rowTitle}>+ Pass-through fee</div>
                    <div className={styles.rowValue}>
                      {formatter.format(
                        clientInfo.payoffFees
                          .filter(
                            ({ type, title }) => type === FeeType.PassThroughs && title !== 'Legal',
                          )
                          .reduce((sum, { amount }) => sum + +amount, 0) || 0,
                      )}
                    </div>
                  </div>
                </div>
                <div className={styles.summaryListItem}>
                  <div className={styles.summaryListData}>
                    <div className={styles.rowTitle}>+ Misc. fee</div>
                    <div className={styles.rowValue}>
                      {formatter.format(
                        clientInfo.payoffFees
                          .filter(({ type, title }) => type === FeeType.Miscellaneous)
                          .reduce((sum, { amount }) => sum + +amount, 0) || 0,
                      )}
                    </div>
                  </div>
                </div>
                <div className={cn(styles.summaryListItem, styles.summaryListItemBorder)}>
                  <div className={styles.summaryListData}>
                    <div className={styles.rowTitle}>+ Manual interest</div>
                    <div className={styles.rowValue}>
                      {formatter.format(calculateManualInterest)}
                    </div>
                  </div>
                </div>
                <div className={styles.summaryListItem}>
                  <div className={styles.summaryListData}>
                    <div className={styles.rowTitleBold}>{payoffTitle}</div>
                    <div className={styles.rowValueBold}>
                      {formatter.format(effectivePayoffAmount)}
                    </div>
                  </div>
                </div>
              </div>
            </Card>
          </Grid>
        </Grid>
      )}

      {isConfirmModalShown && (
        <WarningModal
          onCancel={handleTerminateCancel}
          onConfirm={handleTerminateConfirm}
          warningMessage={`Are you sure you want to terminate this client? This cannot be undone.`}
          isLoading={isButtonLoading?.type === 'terminate' && isButtonLoading?.value}
          disabled={isButtonLoading?.type === 'terminate' && isButtonLoading?.value}
        />
      )}
    </Box>
  )
}

export default ClientSetupTerminationPage
