import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'

import ActivityQueuePage from './ActivityQueuePage'
import { activities } from '../../redux/activity/selectors'
import { isAdmin, isPortfolioAdmin, userId, isUW } from '../../redux/profile/selectors'
import { list as users } from '../../redux/user/selectors'
import { clients } from '../../redux/clientInfo/selectors'
import { list as listClients } from '../../redux/clientInfo/actions'
import {
  listActivities,
  createActivity,
  updateActivity,
  deleteActivity,
} from '../../redux/activity/actions'
import { listUsers } from '../../redux/user/actions'
import {
  hide as hideBBC,
  sendSignReminder as sendBBCSignReminder,
  toggleSignReminder as toggleBBCSignReminder,
} from '../../redux/bbc/actions'

const selector = createStructuredSelector({
  isAdmin,
  isPortfolioAdmin,
  currentUserId: userId,
  clients,
  fullActivities: activities,
  users,
  isUW,
})

const actions = {
  listClients,
  listActivities,
  createActivity,
  updateActivity,
  deleteActivity,
  listUsers,
  hideBBC,
  sendBBCSignReminder,
  toggleBBCSignReminder,
}

export default connect(selector, actions)(ActivityQueuePage)
