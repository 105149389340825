import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'

import DueDiligenceApplicationTableBankAccounts from './DueDiligenceApplicationTableBankAccounts'

import { dueDiligenceFinancialsBankAccounts } from '../../redux/dueDiligence/selectors'
import {
  listFinancialsBankAccounts,
  hideFinancialsBankAccounts,
} from '../../redux/dueDiligence/actions'

const selector = createStructuredSelector({
  dueDiligenceFinancialsBankAccounts,
})
const actions = {
  listFinancialsBankAccounts,
  hideFinancialsBankAccounts,
}

export default connect(selector, actions)(DueDiligenceApplicationTableBankAccounts)
