import React, { useCallback, useEffect, useState, useRef } from 'react'
import cn from 'classnames'
import Box from '@mui/material/Box'
import ExpandMore from '@mui/material/SvgIcon/SvgIcon'
import Popover from '@mui/material/Popover'
import TreeView from '@mui/lab/TreeView/TreeView'
import TreeItem from '@mui/lab/TreeItem/TreeItem'
import Breadcrumbs from '@mui/material/Breadcrumbs'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import NavigateNextIcon from '@mui/icons-material/NavigateNext'
import Card from '../../Common/Card'
import Document from '../../Common/Document'
import { IClientInfo } from '@common/interfaces/client'
import { IFile, IFolder } from '@common/interfaces/box'

import styles from './Documents.module.scss'
import DropDownArrow from '../../Common/DropDownArrow'

import { ReactComponent as XLSIcon } from '../../../assets/images/xls-icon.svg'

interface IProps {
  clientId: string
  updateClient: (id: string, data: object) => void
  listFolders: () => void
  clientInfo: IClientInfo
  folders: IFolder[]
  listDocuments: (id: string) => void
  documents: {
    amendments: IFile[]
    lcr: IFile[]
    lsa: IFile[]
    ucc: IFile[]
    warehouseWaivers: IFile[]
    insurance: IFile[]
    payoff: IFile[]
  }
}

const DOCUMENT_TYPES = {
  amendments: 'Amendments',
  lcr: 'LCR',
  lsa: 'LSA',
  ucc: 'UCC',
  warehouseWaivers: 'Warehouse Waivers',
  insurance: 'Insurance',
  payoff: 'Payoff Letter',
}

const Documents = ({
  clientId,
  clientInfo,
  folders,
  updateClient,
  listFolders,
  listDocuments,
  documents,
}: IProps) => {
  const [folderPath, setFolderPath] = useState<string[] | null>(null)
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)

  useEffect(() => {
    listFolders()
  }, [listFolders])

  const prevFolderIdRef = useRef(clientInfo?.contractDocumentsFolderId)
  useEffect(() => {
    const contractDocumentsFolderId = clientInfo?.contractDocumentsFolderId
    if (prevFolderIdRef.current !== contractDocumentsFolderId) {
      listDocuments(clientInfo?.id)
      prevFolderIdRef.current = contractDocumentsFolderId
    }
  }, [clientInfo, listDocuments, prevFolderIdRef])

  useEffect(() => {
    if (clientInfo?.contractDocumentsFolderId && folders.length && !folderPath) {
      const parentFolder = folders.find(({ items }) =>
        items.some(({ id }) => clientInfo?.contractDocumentsFolderId === id),
      )
      if (parentFolder) {
        const selectedFolder = parentFolder.items.find(
          ({ id }) => clientInfo?.contractDocumentsFolderId === id,
        )
        setFolderPath([parentFolder.name, selectedFolder.name])
      }
      listDocuments(clientInfo?.id)
    }
  }, [clientInfo, listDocuments, folders, folderPath])

  const handleOpen = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      setAnchorEl(event.currentTarget)
    },
    [setAnchorEl],
  )

  const handleClose = useCallback(() => {
    setAnchorEl(null)
  }, [setAnchorEl])

  const handleSelect = useCallback(
    (event, nodeId) => {
      if (clientId) {
        const topFolders = folders.map(({ id: folderId }) => folderId)
        if (!topFolders.includes(nodeId)) {
          handleClose()
          updateClient(clientId, { contractDocumentsFolderId: nodeId })
          const parentFolder = folders.find(({ items }) => items.some(({ id }) => nodeId === id))
          const selectedFolder = parentFolder.items.find(({ id }) => nodeId === id)
          setFolderPath([parentFolder.name, selectedFolder.name])
        }
      }
    },
    [clientId, updateClient, folders, handleClose],
  )

  const renderDocuments = (files: IFile[], documentType: string) => (
    <Box display="flex" alignItems="center" className={styles.documentRow}>
      <Box className={styles.sectionLabel}>
        <Typography variant="body2">{documentType}</Typography>
      </Box>
      <Box display="flex" flexWrap="wrap" className={styles.documentItems}>
        {files?.length > 0 ? (
          files.map(({ name, size, link }) => (
            <Box key={link}>
              <Document name={name} size={size} link={link} isProvided icon={<XLSIcon />} />
            </Box>
          ))
        ) : (
          <Box>
            <Document icon={<XLSIcon />} />
          </Box>
        )}
      </Box>
    </Box>
  )

  return (
    <Card title="Documents" className={styles.card}>
      <Box display="flex" mb={1.5}>
        <Button
          disabled={!clientId}
          type="button"
          color="primary"
          size="small"
          className={styles.documentFoldersTitle}
          onClick={handleOpen}
          endIcon={<ExpandMore />}
        >
          Underwriting and Closing
          <DropDownArrow
            className={cn(styles.documentFoldersTitleIcon, { [styles.rotate]: anchorEl })}
          />
        </Button>
        {folderPath && (
          <Breadcrumbs
            separator={<NavigateNextIcon fontSize="small" />}
            aria-label="breadcrumb"
            className={styles.breadcrumbs}
          >
            {folderPath.map((folderName, index) => (
              <Typography
                variant="body2"
                className={cn(styles.documentFoldersPath, {
                  [styles.documentFoldersPathBold]: index === 1,
                })}
                key={folderName}
              >
                {index === 0 && <NavigateNextIcon fontSize="small" />}
                {folderName}
              </Typography>
            ))}
          </Breadcrumbs>
        )}
        <Popover
          open={Boolean(anchorEl)}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          classes={{ paper: styles.popover }}
        >
          <TreeView
            defaultCollapseIcon={<ExpandMoreIcon />}
            defaultExpandIcon={<ChevronRightIcon />}
            multiSelect={false}
            onNodeSelect={handleSelect}
            defaultSelected={clientInfo?.contractDocumentsFolderId}
            className={styles.documentFolders}
          >
            {folders.map(({ id: folderId, name: folderName, items }) => (
              <TreeItem
                key={folderId}
                nodeId={folderId}
                className={styles.menuItem}
                classes={{
                  content: styles.content,
                  label: styles.label,
                  selected: styles.selected,
                }}
                label={
                  <>
                    {folderName}
                    <span className={styles.documentsCountLabel}>{items.length}</span>
                  </>
                }
              >
                {items.map(({ id: itemId, name: itemName }) => (
                  <TreeItem
                    key={itemId}
                    nodeId={itemId}
                    label={itemName}
                    classes={{
                      content: styles.content,
                    }}
                  />
                ))}
              </TreeItem>
            ))}
          </TreeView>
        </Popover>
      </Box>
      {renderDocuments(documents?.lcr, DOCUMENT_TYPES.lcr)}
      {renderDocuments(documents?.lsa, DOCUMENT_TYPES.lsa)}
      {renderDocuments(documents?.ucc, DOCUMENT_TYPES.ucc)}
      {renderDocuments(documents?.warehouseWaivers, DOCUMENT_TYPES.warehouseWaivers)}
      {renderDocuments(documents?.insurance, DOCUMENT_TYPES.insurance)}
      {renderDocuments(documents?.amendments, DOCUMENT_TYPES.amendments)}
      {documents?.payoff?.length > 0 && renderDocuments(documents?.payoff, DOCUMENT_TYPES.payoff)}
    </Card>
  )
}

export default Documents
