import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'

import ARAPSummaryOverTime from './ARAPSummaryOverTime'

import { arapChartData } from '../../redux/bbc/selectors'
import { getARAPChartData } from '../../redux/bbc/actions'

const selector = createStructuredSelector({
  arapChartData,
})

const actions = {
  getARAPChartData,
}

export default connect(selector, actions)(ARAPSummaryOverTime)
