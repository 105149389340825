import { connect } from 'react-redux'

import UpdateBrandImageModal from './UpdateBrandImageModal'
import { updateEntityInfo } from '../../redux/entityInfo/actions'
import { updateClient } from '../../redux/clientInfo/actions'
import { showNewsfeed, updateBrand } from '../../redux/newsfeed/actions'

const actions = {
  updateEntityInfo,
  updateClient,
  showNewsfeed,
  updateBrand,
}

export default connect(null, actions)(UpdateBrandImageModal)
