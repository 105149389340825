import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'

import ClientVisualizationsPage from './ClientVisualizationsPage'

import { clientInfo } from '../../redux/profile/selectors'

const selector = createStructuredSelector({
  clientInfo,
})

const actions = {}

export default connect(selector, actions)(ClientVisualizationsPage)
