import React from 'react'

import styles from './DueDiligenceFinancialsIntegrations.module.scss'

import Button from '../Common/Button'
import { ReactComponent as AppIntegrationsImage } from '@assets/images/app-integrations.svg'

interface IProps {
  handleIntegrations: () => void
}

const DueDiligenceFinancialsIntegrations = ({ handleIntegrations }: IProps) => {
  return (
    <div className={styles.integrationsWrapper}>
      <div className={styles.title}>App integrations</div>
      <div className={styles.description}>
        Shorten your request list by authorizing Dwight to pull reports on your behalf
      </div>
      <AppIntegrationsImage />

      <Button
        className={styles.connectButton}
        variant="contained"
        onClick={handleIntegrations}
        small={false}
      >
        Connect to auto-reporting
      </Button>
    </div>
  )
}

export default DueDiligenceFinancialsIntegrations
