import React from 'react'
import { generatePath } from 'react-router-dom'
import { useParams } from 'react-router'

import { IProspectAPSummary } from '@common/interfaces/prospects'
import { ILoadingData } from '../../redux/types'
import { ROUTES } from '../../constants/routes'
import SummaryTable from '../ProspectArApSummary'

interface IProps {
  listAPSummary: (id: string, params: object) => void
  apSummary: ILoadingData<IProspectAPSummary>
}

const ProspectReportingSummaryPayables = ({ apSummary, listAPSummary }: IProps) => {
  const { id } = useParams<{ id: string }>()

  return (
    <SummaryTable
      reportType="AP"
      isSummary
      listSummary={listAPSummary}
      isDataLimited
      summaryData={apSummary}
      readOnly
      editLink={generatePath(ROUTES.PROSPECT_REPORTING_PAYABLES, { id })}
    />
  )
}

export default ProspectReportingSummaryPayables
