import {
  listEntityInfo,
  addEntityRelationship,
  deleteEntityRelationship,
} from './../../redux/entityInfo/actions'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'

import EnititySettingsRelatedEntitiesPage from './EnititySettingsRelatedEntitiesPage'
import { entity } from '../../redux/entityInfo/selectors'

const selector = createStructuredSelector({
  entity,
})
const actions = {
  listEntityInfo,
  addEntityRelationship,
  deleteEntityRelationship,
}
export default connect(selector, actions)(EnititySettingsRelatedEntitiesPage)
