import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'

import NotificationSettings from './NotificationSettings'

import { user, role } from '../../redux/profile/selectors'
import { updateNotificationSettings } from '../../redux/profile/actions'

const selector = createStructuredSelector({
  user,
  role,
})

const actions = { updateNotificationSettings }

export default connect(selector, actions)(NotificationSettings)
