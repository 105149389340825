import { connect } from 'react-redux'
import { createStructuredSelector, createSelector } from 'reselect'

import RequiredReporting from './RequiredReporting'

import { list as frequencies } from '../../../redux/frequency/selectors'
import { listFrequencies } from '../../../redux/frequency/actions'
import { list as reportingDocuments } from '../../../redux/reportingDocument/selectors'
import {
  listReportingDocuments,
  createReportingDocument,
} from '../../../redux/reportingDocument/actions'
import { isLoading, list as requiredReports } from '../../../redux/requiredReport/selectors'
import {
  listRequiredReports,
  createRequiredReport,
  updateRequiredReport,
  deleteRequiredReport,
} from '../../../redux/requiredReport/actions'
import { clientId } from '../../../redux/clientInfo/selectors'

import { REPORTING_DOCUMENT_TYPES } from '@common/constants/client'

const mappedRequiredReports = createSelector(requiredReports, (reports) =>
  reports.map((requiredReport) => ({
    ...requiredReport,
    canBeDeleted: ![
      REPORTING_DOCUMENT_TYPES.apAging,
      REPORTING_DOCUMENT_TYPES.arAging,
      REPORTING_DOCUMENT_TYPES.inventoryDetail,
    ].includes(requiredReport.documentName),
    isConsistentFrequency: ![
      REPORTING_DOCUMENT_TYPES.apAging,
      REPORTING_DOCUMENT_TYPES.arAging,
      REPORTING_DOCUMENT_TYPES.inventoryDetail,
      REPORTING_DOCUMENT_TYPES.financials,
      REPORTING_DOCUMENT_TYPES.financialProjections,
    ].includes(requiredReport.documentName),
  })),
)

const mappedReportingDocuments = createSelector(
  reportingDocuments,
  requiredReports,
  (documents, reports) =>
    documents.filter(
      (document) => !reports.some(({ documentName }) => document.name === documentName),
    ),
)

const selector = createStructuredSelector({
  frequencies,
  reportingDocuments: mappedReportingDocuments,
  requiredReports: mappedRequiredReports,
  clientId,
  isLoading,
})

const actions = {
  listFrequencies,
  listReportingDocuments,
  createReportingDocument,
  listRequiredReports,
  createRequiredReport,
  updateRequiredReport,
  deleteRequiredReport,
}

export default connect(selector, actions)(RequiredReporting)
