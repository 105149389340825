import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'

import ProspectReportingSummaryFinancials from './ProspectReportingSummaryFinancials'

import { reporting } from '../../redux/prospect/selectors'

const selector = createStructuredSelector({
  reporting,
})

export default connect(selector)(ProspectReportingSummaryFinancials)
