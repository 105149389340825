import React from 'react'

import styles from './ClientLoanLedger.module.scss'
import genericSs from '@styles/generic.module.scss'

import Table from '../../Common/Table'
import TableHead from '../../Common/TableHead'
import TableRow from '../../Common/TableRow'
import TableContainer from '../../Common/TableContainer'
import TableBody from '../../Common/TableBody'
import TableCell from '../../Common/TableCell'
import { IAccountActivity, IClientLoanLedgerWire } from '@common/interfaces/client'
import { IParticipation, IParticipationWire } from '@common/interfaces/participant'
import { LoanLedgerWiresTableParticipationRow } from './LoanLedgerWiresTableParticipationRow'
import { LoanLedgerWiresTableAccountActivityRow } from './LoanLedgerWiresTableAccountActivityRow'

interface LoanLedgerWiresTableProps {
  clientId: string
  wires: IClientLoanLedgerWire[]
  effectiveParticipations: IParticipation[]
}

export const LoanLedgerWiresTable = ({
  clientId,
  wires,
  effectiveParticipations,
}: LoanLedgerWiresTableProps) => {
  return (
    <TableContainer className={styles.loanLedgerWiresTable}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell className={genericSs.tableTextLeft}>Type</TableCell>
            <TableCell className={genericSs.tableTextLeft}>Description</TableCell>
            <TableCell className={genericSs.tableTextRight}>Amount</TableCell>
            <TableCell className={genericSs.tableTextRight}>Action</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {wires.map((wire) =>
            wire.isParticipantWire ? (
              <LoanLedgerWiresTableParticipationRow
                key={wire.item.id}
                clientId={clientId}
                participationWire={wire.item as IParticipationWire}
                effectiveParticipations={effectiveParticipations}
              />
            ) : (
              <LoanLedgerWiresTableAccountActivityRow
                key={wire.item.id}
                clientId={clientId}
                accountActivity={wire.item as IAccountActivity}
              />
            ),
          )}
        </TableBody>
      </Table>
    </TableContainer>
  )
}
