import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'

import WiresUploads from './WiresUploads'
import { wiresFiles } from '../../redux/collections/selectors'
import { deleteFile } from '../../redux/file/actions'

const selector = createStructuredSelector({
  wiresFiles,
})

const actions = {
  deleteFile,
}

export default connect(selector, actions)(WiresUploads)
