import { combineReducers } from 'redux'
import { RouterState } from 'connected-react-router'
import { History } from 'history'

import router from './router/reducer'
import box, { IBoxState } from './box/reducer'
import collection, { ICollectionState } from './collections/reducer'
import clientInfo, { IClientInfoState } from './clientInfo/reducer'
import profile, { IProfileState } from './profile/reducer'
import salesforce, { ISalesforceState } from './salesforce/reducer'
import user, { IUserState } from './user/reducer'
import bank, { IBankState } from './bank/reducer'
import bankAccount, { IBankAccountState } from './bankAccount/reducer'
import requiredReport, { IRequiredReportState } from './requiredReport/reducer'
import reportingDocument, { IReportingDocumentState } from './reportingDocument/reducer'
import frequency, { IFrequencyState } from './frequency/reducer'
import loanBalance, { ILoanBalanceState } from './loanBalance/reducer'
import passThrough, { IPassThroughState } from './passThrough/reducer'
import outgoingWire, { IOutgoingWireState } from './outgoingWire/reducer'
import activity, { IActivityState } from './activity/reducer'
import bbc, { IBBCState } from './bbc/reducer'
import auditLog, { IAuditLogState } from './auditLog/reducer'
import file, { IFileState } from './file/reducer'
import ongoingReporting, { IOngoingReportingState } from './ongoingReporting/reducer'
import ui, { IUiState } from './ui/reducer'
import arapmapping, { AliasMapping } from './arapmapping/reducer'
import bankTransactions, { IBankTransactionsState } from './bankTransactions/reducer'
import capTable, { ICapTableState } from './capTable/reducer'
import prospect, { IProspectState } from './prospect/reducer'
import generalLedger, { IGeneralLedgerState } from './generalLedger/reducer'
import search, { ISearchState } from './search/reducer'
import bdo, { IBDOState } from './bdo/reducer'
import entityInfo, { IEntityInfoState } from './entityInfo/reducer'
import notes, { INotesState } from './notes/reducer'
import dueDiligence, { IDueDiligenceState } from './dueDiligence/reducer'
import clientIntake, { IClientIntake } from './clientIntake/reducer'
import notification, { INotificationState } from './notification/reducer'
import newsfeed, { INewsfeedState } from './newsfeed/reducer'
import bbcLog, { IBBCLogState } from './bbcLog/reducer'
import { LOGOUT_SUCCESS } from './profile/actions'
import participant, { IParticipantState } from './participant/reducer'
import flag, { IFlagState } from './flag/reducer'
import options, { IOptionsState } from './options/reducer'
import treasury, { ITreasuryState } from './treasury/reducer'

export interface IRootState {
  router: RouterState
  box: IBoxState
  collection: ICollectionState
  clientInfo: IClientInfoState
  profile: IProfileState
  salesforce: ISalesforceState
  user: IUserState
  bank: IBankState
  bankAccount: IBankAccountState
  requiredReport: IRequiredReportState
  reportingDocument: IReportingDocumentState
  frequency: IFrequencyState
  loanBalance: ILoanBalanceState
  passThrough: IPassThroughState
  outgoingWire: IOutgoingWireState
  activity: IActivityState
  bbc: IBBCState
  auditLog: IAuditLogState
  file: IFileState
  ongoingReporting: IOngoingReportingState
  ui: IUiState
  arapmapping: AliasMapping
  bankTransactions: IBankTransactionsState
  capTable: ICapTableState
  prospect: IProspectState
  dueDiligence: IDueDiligenceState
  generalLedger: IGeneralLedgerState
  search: ISearchState
  bdo: IBDOState
  entityInfo: IEntityInfoState
  notes: INotesState
  clientIntake: IClientIntake
  notification: INotificationState
  newsfeed: INewsfeedState
  participant: IParticipantState
  bbcLog: IBBCLogState
  flag: IFlagState
  options: IOptionsState
  treasury: ITreasuryState
}

const appReducer = (history: History) =>
  combineReducers<IRootState>({
    router: router(history),
    box,
    collection,
    clientInfo,
    profile,
    salesforce,
    user,
    bank,
    bankAccount,
    requiredReport,
    reportingDocument,
    frequency,
    loanBalance,
    passThrough,
    outgoingWire,
    activity,
    bbc,
    auditLog,
    file,
    ongoingReporting,
    ui,
    arapmapping,
    bankTransactions,
    capTable,
    prospect,
    dueDiligence,
    generalLedger,
    search,
    bdo,
    entityInfo,
    notes,
    clientIntake,
    notification,
    newsfeed,
    participant,
    bbcLog,
    flag,
    options,
    treasury,
  })

const reducer = (history: History) => (state: IRootState, action: any) => {
  if (action.type === LOGOUT_SUCCESS) {
    state = undefined
  }
  return appReducer(history)(state, action)
}

export default reducer
