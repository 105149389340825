import React from 'react'
import Card from '../../Common/Card'
import { ClientABLField } from '../ClientHelpers'
import Grid from '@mui/material/Grid'
import styles from '../DealStructure/DealStructure.module.scss'
import { allClientTermsInfo, ClientSetupSections } from '@common/interfaces/client'

const FIELDS_MAP = allClientTermsInfo.filter(
  (term) => term.section === ClientSetupSections.InterestRate,
)

const InterestRate = () => {
  return (
    <Card
      title="Interest Rate"
      classes={{
        root: styles.card,
      }}
    >
      <Grid container spacing={2} justifyContent={'start'}>
        {FIELDS_MAP.map(({ label, value, type, disabled }) => {
          return (
            <Grid item xs={3} xl={2} key={label}>
              <ClientABLField label={label} name={value} type={type} disabled={disabled} />
            </Grid>
          )
        })}
      </Grid>
    </Card>
  )
}

export default InterestRate
