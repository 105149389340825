import React, { useCallback, useState } from 'react'
import Grid from '@mui/material/Grid'
import Link from '@mui/material/Link'
import Tooltip from '@mui/material/Tooltip'
import cn from 'classnames'

import styles from '../OngoingReportingFiles/OngoingReportingFiles.module.scss'

import { IFile } from '@common/interfaces/box'
import { ReactComponent as ExcelIcon } from '@assets/images/excel-icon.svg'
import { EditIcon, RegenerateIcon, ClearIcon } from '../Common/Icons'
import { excelOnlineLink } from '../../constants/common'
import genericSs from '@assets/styles/generic.module.scss'

interface IProps {
  selectedDocuments: Array<IFile & { fileSheetId?: string }>
  handleSelectFile?: (id: string, sheetName: string, isReselect?: boolean) => void
  handleSelectMultipleFiles?: (
    selectedDocuments: { id?: string; fileId: string; sheetName: string }[],
    isReselect?: boolean,
  ) => void
  fileReselecting?: { id?: string; fileId: string; sheetName: string }
  isMultipleReSelecting?: boolean
  disabled?: boolean
}

const SelectedFiles = ({
  selectedDocuments,
  handleSelectFile,
  handleSelectMultipleFiles,
  fileReselecting,
  isMultipleReSelecting = false,
  disabled = false,
}: IProps) => {
  const [fileLoading, setFileLoading] = useState({
    id: '',
    sheetName: '',
  })

  const [fileClearing, setFileClearing] = useState({
    id: '',
    sheetName: '',
  })

  const handleReselectFile = useCallback(
    async (id: string, sheetName: string) => {
      if (disabled) {
        return
      }
      setFileLoading({ id, sheetName })
      if (handleSelectMultipleFiles) {
        const updatedSelectedDocuments = selectedDocuments.map(
          ({ id: fileId, sheetName: fileSheetName, fileSheetId }) => ({
            id: fileSheetId,
            fileId,
            sheetName: fileSheetName,
            isReselect: fileId === id && fileSheetName === sheetName,
          }),
        )
        await handleSelectMultipleFiles(updatedSelectedDocuments, true)
      } else {
        await handleSelectFile(id, sheetName, true)
      }
      setFileLoading({ id: '', sheetName: '' })
    },
    [handleSelectFile, selectedDocuments, handleSelectMultipleFiles, disabled],
  )

  const handleClearFile = useCallback(
    async (id: string, sheetName: string) => {
      if (disabled) {
        return
      }
      setFileClearing({ id, sheetName })
      if (handleSelectMultipleFiles) {
        const updatedSelectedDocuments = selectedDocuments
          .map((selectedFile) => ({
            id: selectedFile.fileSheetId,
            fileId: selectedFile.id,
            sheetName: selectedFile.sheetName,
          }))
          .filter((file) => file.fileId !== id || file.sheetName !== sheetName)

        await handleSelectMultipleFiles(updatedSelectedDocuments)
      } else {
        await handleSelectFile(id, '')
      }
      setFileClearing({ id: '', sheetName: '' })
    },
    [handleSelectFile, selectedDocuments, handleSelectMultipleFiles, disabled],
  )

  if (!(selectedDocuments?.length > 0)) {
    return null
  }

  return (
    <>
      <h3 className={styles.filesListItemTitle}>Selected files</h3>
      {selectedDocuments?.map(
        ({ id, fileSheetId, fileName, name, sheetName, link, fileId, error }) => {
          const newError = error ? (
            <div>
              <h3 className={styles.errorTitle}>Unable to read file</h3>
              <span>{error}</span>
            </div>
          ) : (
            ''
          )
          return (
            <Tooltip
              title={newError}
              placement="left"
              key={`${id}-${fileSheetId || ''}`}
              arrow
              classes={{ tooltip: styles.tooltipError, arrow: styles.tooltipArrow }}
            >
              <Grid
                container
                item
                xs={12}
                justifyContent={'flex-start'}
                alignContent={'center'}
                className={cn(styles.selectedFile, {
                  [styles.error]: error,
                })}
                key={id}
              >
                <Grid item xs={2} container alignContent={'center'} justifyContent={'center'}>
                  <Link href={link} target="_blank" className={styles.link}>
                    <ExcelIcon className={styles.fileNameIcon} />
                  </Link>
                </Grid>
                <Grid item xs={6} className={styles.nameContainer}>
                  <Tooltip title={fileName || name} placement="top">
                    <div className={styles.fileName}>{fileName || name}</div>
                  </Tooltip>
                  <span className={styles.sheetName}>{sheetName}</span>
                </Grid>
                <Grid
                  item
                  xs={4}
                  container
                  alignContent={'center'}
                  justifyContent={'flex-end'}
                  paddingRight={'10px'}
                  className={cn({
                    [genericSs.disabled]: disabled,
                  })}
                >
                  <RegenerateIcon
                    title="Reselect file"
                    action={() => {
                      handleReselectFile(id, sheetName)
                    }}
                    isLoading={
                      (fileLoading.id === id && fileLoading.sheetName === sheetName) ||
                      (fileReselecting?.fileId === id &&
                        fileReselecting?.sheetName === sheetName) ||
                      isMultipleReSelecting
                    }
                    disabled={disabled}
                  />
                  <EditIcon
                    disabled={disabled}
                    title="Edit (excel online)"
                    onClick={() => window.open(excelOnlineLink(fileId), '_blank')}
                  />
                  <ClearIcon
                    disabled={disabled}
                    action={() => {
                      handleClearFile(id, sheetName)
                    }}
                    title="Clear file"
                    isLoading={fileClearing.id === id && fileClearing.sheetName === sheetName}
                  />
                </Grid>
              </Grid>
            </Tooltip>
          )
        },
      )}
    </>
  )
}

export default SelectedFiles
