import { connect } from 'react-redux'
import { createSelector, createStructuredSelector } from 'reselect'
import UploadFileManagement from './UploadFileManagement'
import { role } from '../../../redux/profile/selectors'

import { UserRole } from '@common/interfaces/user'

const selector = createStructuredSelector({
  isExternalUser: createSelector(role, (userRole) => [UserRole.CLIENT_USER].includes(userRole)),
})

export default connect(selector, null)(UploadFileManagement)
