import React from 'react'
import Table from '../../Common/Table'
import TableContainer from '../../Common/TableContainer'
import TableHead from '../../Common/TableHead'
import TableBody from '../../Common/TableBody'
import TableRow from '../../Common/TableRow'
import TableCell from '../../Common/TableCell'
import genericSs from '@styles/generic.module.scss'
import classNames from 'classnames'
import FormField from '../../Common/FormField'
import { FEE_TYPES } from './Participations'
import styles from './Participations.module.scss'

const HEADERS = ['Fee', 'Override (%)', 'Share (%)']

const CreateParticipationFees = ({ disabled = false }: { disabled?: boolean }) => {
  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            {HEADERS.map((item, index) => (
              <TableCell
                key={index}
                className={classNames({
                  [genericSs.tableTextLeft]: index === 0,
                  [genericSs.tableTextRight]: index !== 0,
                })}
              >
                {item}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody id="scrollableTable">
          {FEE_TYPES.map((item, index) => {
            const isInterest = item.value === 'interest'
            const override = `${item.value}Override`
            const share = isInterest ? 'loanExposure' : `${item.value}Share`

            return (
              <TableRow key={`create-fees-${item.value}`}>
                <TableCell className={genericSs.tableTextLeft}>{item.label}</TableCell>
                <TableCell
                  key={`create-fees-${item.value}-override`}
                  className={classNames(genericSs.tableTextRight, styles.participationCreateCell)}
                >
                  <FormField disabled={disabled} name={override} type="percent" size="small" />
                </TableCell>
                <TableCell
                  key={`create-fees-${item.value}-share`}
                  className={classNames(genericSs.tableTextRight, styles.participationCreateCell)}
                >
                  <FormField
                    name={share}
                    type="percent"
                    size="small"
                    disabled={isInterest || disabled}
                  />
                </TableCell>
              </TableRow>
            )
          })}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export default CreateParticipationFees
