import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'

import DueDiligenceCompanyBackgroundPage from './DueDiligenceCompanyBackgroundPage'

import { dueDiligenceInfo } from '../../redux/dueDiligence/selectors'
import { show } from '../../redux/dueDiligence/actions'

const selector = createStructuredSelector({
  dueDiligenceInfo,
})
const actions = {
  show,
}

export { DueDiligenceCompanyBackgroundPage }

export default connect(selector, actions)(DueDiligenceCompanyBackgroundPage)
