import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'

import ManageTermsActionMenu from './ManageTermsActionMenu'

import {
  createProspectTerms,
  deleteProspectTerms,
  generateTermSheet,
} from '../../../redux/prospect/actions'
import { reporting } from '../../../redux/prospect/selectors'
import { userId } from '../../../redux/profile/selectors'

const selector = createStructuredSelector({
  reporting,
  userId,
})

const actions = {
  createProspectTerms,
  deleteProspectTerms,
  generateTermSheet,
}

export default connect(selector, actions)(ManageTermsActionMenu)
