import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'

import CollectionsDashboardPage from './CollectionsDashboardPage'
import { isLoading as isLoadingCollections, aggregation } from '../../redux/collections/selectors'
import { clients } from '../../redux/clientInfo/selectors'
import {
  listAggregation,
  exportAggregation,
  updateAggregationRow,
  deleteAggregationRow,
  listAggregationRow,
} from '../../redux/collections/actions'
import { list as listClients } from '../../redux/clientInfo/actions'
import { isLoading as isLoadingEntityInfo } from '../../redux/entityInfo/selectors'
import { listEntityInfo, addEntityInfo } from '../../redux/entityInfo/actions'

const selector = createStructuredSelector({
  isLoadingCollections,
  isLoadingEntityInfo,
  aggregation,
  clients,
})

const actions = {
  listAggregation,
  listAggregationRow,
  exportAggregation,
  updateAggregationRow,
  deleteAggregationRow,
  listClients,
  listEntityInfo,
  addEntityInfo,
}

export default connect(selector, actions)(CollectionsDashboardPage)
