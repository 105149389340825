import { createRequestTypes } from '../../helpers/helpers'
import { IApi } from '../../api'
import { CommonPromiseAction } from '../types'

export const prefix = 'treasury'

const LIST_TREASURY = createRequestTypes(prefix, 'LIST_TREASURY')

export const [LIST_TREASURY_REQUEST, LIST_TREASURY_SUCCESS, LIST_TREASURY_FAILURE] = LIST_TREASURY

export const listTreasury: CommonPromiseAction = (params?: object) => ({
  type: LIST_TREASURY,
  promise: (api: IApi) => api.treasury.listTreasury(params),
  params,
})

const UPDATE_TREASURY = createRequestTypes(prefix, 'UPDATE_TREASURY')

export const [UPDATE_TREASURY_REQUEST, UPDATE_TREASURY_SUCCESS, UPDATE_TREASURY_FAILURE] =
  UPDATE_TREASURY

export const updateTreasury: CommonPromiseAction = (params: object) => ({
  type: UPDATE_TREASURY,
  promise: (api: IApi) => api.treasury.updateTreasury(params),
  params,
})

const CREATE_TREASURY = createRequestTypes(prefix, 'CREATE_TREASURY')

export const [CREATE_TREASURY_REQUEST, CREATE_TREASURY_SUCCESS, CREATE_TREASURY_FAILURE] =
  CREATE_TREASURY

export const createTreasury: CommonPromiseAction = (params: object) => ({
  type: CREATE_TREASURY,
  promise: (api: IApi) => api.treasury.createTreasury(params),
  params,
})
