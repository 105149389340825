import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'

import DueDiligenceTeamBoardMembers from './DueDiligenceTeamBoardMembers'

import { dueDiligenceTeamBoardMembers } from '../../redux/dueDiligence/selectors'
import {
  listTeamBoardMembers,
  hideTeamBoardMembers,
  addTeamBoardMember,
  updateTeamBoardMember,
  deleteTeamBoardMember,
} from '../../redux/dueDiligence/actions'

const selector = createStructuredSelector({
  dueDiligenceTeamBoardMembers,
})
const actions = {
  listTeamBoardMembers,
  hideTeamBoardMembers,
  addTeamBoardMember,
  updateTeamBoardMember,
  deleteTeamBoardMember,
}

export default connect(selector, actions)(DueDiligenceTeamBoardMembers)
