import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { overadvanceFieldConfig } from '../../../ClientOveradvances/ClientOveradvances'
import { formatDate, formatPrice } from '../../../../helpers/helpers'
import styles from './../ClientAmendments.module.scss'
import { IOveradvance, IOveradvancePayment } from '@common/interfaces/bbc'
import { Grid } from '@mui/material'
import Modal from '../../../Common/Modal/Modal'
import KeyboardDatePicker from '../../../Common/KeyboardDatePicker/KeyboardDatePicker'
import CurrencyField from '../../../Common/CurrencyField/CurrencyField'
import * as Yup from 'yup'
import { Form } from 'react-final-form'
import { makeValidate } from 'mui-rff'
import moment from 'moment'
import IconButton from '../../../Common/IconButton'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import WarningModal from '../../../WarningModal'
import Button from '../../../Common/Button'
import Table from '../../../Common/Table'
import TableHead from '../../../Common/TableHead'
import TableRow from '../../../Common/TableRow'
import TableCell from '../../../Common/TableCell'
import TableContainer from '../../../Common/TableContainer'
import TableBody from '../../../Common/TableBody'
import genericSs from '@styles/generic.module.scss'
import { ReactComponent as DeleteIcon } from '@assets/images/delete-icon.svg'
import { getFieldValue } from '../../ClientHelpers'
import { ExternalLink, MenuIcon } from '../../../Common/Icons'

interface IOveradvanceMenuProps {
  clientId: string
  overadvance: IOveradvance
  listOveradvancePayments: (clientId: string, overadvanceId: string) => void
  createOveradvancePayment: (
    clientId: string,
    overadvanceId: string,
    payment: IOveradvancePayment,
  ) => void
  handleListAmendments: () => void
  deleteOveradvancePayment: (clientId: string, overadvanceId: string, paymentId: string) => void
}

interface IOveradvancePaymentsProps {
  clientId: string
  overadvance: IOveradvance
  deleteOveradvancePayment: (clientId: string, overadvanceId: string, paymentId: string) => void
  listOveradvancePayments: (clientId: string, overadvanceId: string) => void
  handleListAmendments: () => void
}

const OveradvancePayments = ({
  clientId,
  overadvance,
  deleteOveradvancePayment,
  listOveradvancePayments,
  handleListAmendments,
}: IOveradvancePaymentsProps) => {
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false)
  const [payment, setPayment] = useState(null)

  const overadvancePayments = useMemo(() => {
    return overadvance?.overadvancePayments?.sort((a, b) => {
      return moment(a.recordDate).diff(moment(b.recordDate))
    })
  }, [overadvance])

  const overadvanceId = useMemo(() => {
    return overadvance?.id
  }, [overadvance])

  const handleDelete = useCallback((payment: IOveradvancePayment) => {
    setPayment(payment)
    setIsConfirmModalOpen(true)
  }, [])

  const handleLoadOveradvancePayments = useCallback(async () => {
    overadvanceId && (await listOveradvancePayments(clientId, overadvanceId))
  }, [clientId, overadvanceId, listOveradvancePayments])

  const handleDeleteConfirm = useCallback(async () => {
    setIsConfirmModalOpen(false)
    await deleteOveradvancePayment(clientId, overadvanceId, payment.id)
    await handleListAmendments()
    await handleLoadOveradvancePayments()
  }, [
    clientId,
    overadvanceId,
    payment,
    deleteOveradvancePayment,
    handleLoadOveradvancePayments,
    handleListAmendments,
  ])

  const handleCancel = useCallback(() => {
    setIsConfirmModalOpen(false)
  }, [])

  useEffect(() => {
    handleLoadOveradvancePayments()
  }, [handleLoadOveradvancePayments])

  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell className={genericSs.tableTextRight}>Date</TableCell>
            <TableCell className={genericSs.tableTextLeft}>Amount</TableCell>
            <TableCell className={genericSs.tableTextLeft}>Balance</TableCell>
            <TableCell className={genericSs.tableTextRight}>Action</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {overadvancePayments?.map((payment) => (
            <TableRow key={payment.id}>
              <TableCell className={genericSs.tableTextRight}>
                {formatDate(payment.recordDate)}
              </TableCell>
              <TableCell className={genericSs.tableTextLeft}>
                ${formatPrice(payment.amount)}
              </TableCell>
              <TableCell className={genericSs.tableTextLeft}>
                ${formatPrice(payment.outstandingBalance)}
              </TableCell>
              <TableCell className={genericSs.tableTextRight}>
                <IconButton onClick={() => handleDelete(payment)}>
                  <DeleteIcon />
                </IconButton>
              </TableCell>
            </TableRow>
          ))}
          {isConfirmModalOpen && (
            <WarningModal
              warningMessage={`Are you sure you want to delete this payment? The payment will be removed from the overadvance and the overadvance balance will be updated to 
                ${formatPrice(overadvance?.currentBalance + payment.amount)}.`}
              onCancel={handleCancel}
              onConfirm={handleDeleteConfirm}
            />
          )}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export const OveradvanceMenu = ({
  clientId,
  overadvance,
  createOveradvancePayment,
  listOveradvancePayments,
  deleteOveradvancePayment,
  handleListAmendments,
}: IOveradvanceMenuProps) => {
  const [anchorEl, setAnchorEl] = useState(null)
  const [isMenuOpen, setIsMenuOpen] = useState(false)
  const [isAddPaymentModalOpen, setIsAddPaymentModalOpen] = useState(false)
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false)
  const [isOveradvancePaymentsOpen, setIsOveradvancePaymentsOpen] = useState(false)
  const [isSubmitting, setIsSubmitting] = useState(false)

  const handleClickMenu = (event: React.MouseEvent) => {
    setAnchorEl(event.currentTarget)
    setIsMenuOpen(true)
  }

  const handleCloseMenu = useCallback(() => {
    setAnchorEl(null)
    setIsMenuOpen(false)
  }, [])

  const handleAddPayment = useCallback(() => {
    handleCloseMenu()
    setIsAddPaymentModalOpen(true)
  }, [handleCloseMenu])

  const handleCancelPayment = useCallback(() => {
    setIsConfirmModalOpen(false)
    setIsAddPaymentModalOpen(false)
  }, [])

  const handleCreatePayment = useCallback(() => {
    setIsConfirmModalOpen(true)
  }, [])

  const handleViewPayments = useCallback(() => {
    setIsOveradvancePaymentsOpen(true)
  }, [])

  const handleCancelViewPayments = useCallback(() => {
    setIsOveradvancePaymentsOpen(false)
  }, [])

  const handleConfirmPayment = useCallback(
    async (values: any) => {
      setIsSubmitting(true)
      await createOveradvancePayment(clientId, overadvance.id, values)
      await handleListAmendments()
      setIsAddPaymentModalOpen(false)
      handleViewPayments()
      setIsConfirmModalOpen(false)
      setIsSubmitting(false)
    },
    [clientId, overadvance, createOveradvancePayment, handleListAmendments, handleViewPayments],
  )

  const validate = useMemo(
    () =>
      makeValidate(
        Yup.object().shape({
          recordDate: Yup.date()
            .typeError('Please type date in MM/DD/YY format')
            // date must be the same or after first day of the month
            .min(
              moment().startOf('month').toDate(),
              `Date must be the same or after ${moment().startOf('month').format('MM/DD/YYYY')}`,
            )
            .required('Required'),
          amount: Yup.number()
            .typeError('Invalid amount')
            .min(0, 'Amount must be greater than 0')
            .max(
              overadvance.currentBalance,
              `Payment must be less than current balance $${formatPrice(
                overadvance.currentBalance,
              )}`,
            )
            .required('Required'),
        }),
      ),
    [overadvance],
  )

  return (
    <>
      <MenuIcon isActive={isMenuOpen} onClick={handleClickMenu} size="small" />
      <Menu open={isMenuOpen} onClose={handleCloseMenu} anchorEl={anchorEl}>
        {overadvance?.currentBalance > 0 && (
          <MenuItem onClick={handleAddPayment}>Add payment</MenuItem>
        )}
        <MenuItem onClick={handleViewPayments}>View payments</MenuItem>
      </Menu>
      {isAddPaymentModalOpen && (
        <Modal open onCancel={handleCancelPayment} title={'Add payment'} size="small">
          <Form
            onSubmit={handleCreatePayment}
            initialValues={{
              recordDate: moment().format('YYYY-MM-DD'),
              amount: '',
            }}
            validate={validate}
            render={({
              handleSubmit,
              submitting,
              invalid,
              values,
            }: {
              handleSubmit: any
              submitting: boolean
              invalid: boolean
              values: any
            }) => (
              <form onSubmit={handleSubmit}>
                <Grid container rowSpacing={2} justifyContent={'start'}>
                  <Grid item xs={12}>
                    <h4 className={styles.value}>Date</h4>
                    <KeyboardDatePicker
                      name={'recordDate'}
                      placeholder={'Select date'}
                      size="large"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <h4 className={styles.value}>Amount</h4>
                    <CurrencyField name={'amount'} placeholder={'Enter amount'} size="large" />
                  </Grid>
                  <Grid item xs={12}>
                    <Button
                      type={'submit'}
                      fullWidth
                      disabled={isSubmitting || invalid}
                      className={styles.button}
                      variant={'contained'}
                      color={'primary'}
                      small={false}
                    >
                      Add payment
                    </Button>
                  </Grid>
                </Grid>
                {isConfirmModalOpen && (
                  <WarningModal
                    warningMessage={`Are you sure you want to add this payment? The resulting overadvance balance will be $${formatPrice(
                      overadvance.currentBalance - values.amount,
                    )}`}
                    onCancel={handleCancelPayment}
                    onConfirm={() => handleConfirmPayment(values)}
                    confirmText="Add payment"
                    cancelText="Cancel"
                    isLoading={isSubmitting}
                  />
                )}
              </form>
            )}
          />
        </Modal>
      )}
      {isOveradvancePaymentsOpen && (
        <Modal open onCancel={handleCancelViewPayments} title={'Overadvance payments'} size="small">
          <OveradvancePayments
            listOveradvancePayments={listOveradvancePayments}
            clientId={clientId}
            overadvance={overadvance}
            deleteOveradvancePayment={deleteOveradvancePayment}
            handleListAmendments={handleListAmendments}
          />
        </Modal>
      )}
    </>
  )
}

const ClientOveradvanceDetails = ({
  overadvanceDetail,
  boxLink,
}: {
  overadvanceDetail: IOveradvance
  boxLink: string
}) => {
  return (
    <Grid container rowSpacing={3} justifyContent={'start'}>
      {overadvanceFieldConfig.map((field) => {
        const value = getFieldValue(overadvanceDetail, field.name, field.type)
        if (field.name === 'boxLink') {
          return (
            <Grid item md={3} lg={2}>
              <h3>{field.label}</h3>
              {boxLink ? <ExternalLink link={boxLink} /> : <span className={styles.value}>-</span>}
            </Grid>
          )
        }

        return (
          <Grid item md={3} lg={2}>
            <h3>{field.label}</h3>
            <span className={styles.value}>{value}</span>
          </Grid>
        )
      })}
    </Grid>
  )
}

export default ClientOveradvanceDetails
