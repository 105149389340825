import React from 'react'
import cn from 'classnames'
import styles from './Attachments.module.scss'
import { IAttachment } from '@common/interfaces/notes'
import AttachmentItem from './AttachmentItem'

interface IProps {
  attachments: IAttachment[]
  readOnly: boolean
  removeAttachment: (index: number) => void
  handleOpenImageModal: (attachment: any) => void
}

const Attachments = ({ attachments, readOnly, removeAttachment, handleOpenImageModal }: IProps) => {
  return (
    <div
      className={cn({
        [styles.attachmentsContainerReadOnly]: readOnly,
        [styles.attachmentsContainer]: !readOnly,
      })}
    >
      {!!attachments &&
        attachments?.map((attachment: any, index: number) => (
          <AttachmentItem
            attachment={attachment}
            readOnly={readOnly}
            index={index}
            removeAttachment={removeAttachment}
            handleOpenImageModal={handleOpenImageModal}
          />
        ))}
    </div>
  )
}

export default Attachments
