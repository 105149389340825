import React, { useEffect, useState, useCallback, useMemo } from 'react'
import Box from '@mui/material/Box'
import Popover from '@mui/material/Popover'
import TreeView from '@mui/lab/TreeView/TreeView'
import TreeItem from '@mui/lab/TreeItem/TreeItem'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import cn from 'classnames'
import { ReactComponent as ExcelIcon } from '@assets/images/excel-icon.svg'
import styles from './FileSelect.module.scss'
import Button from '../../components/Common/Button'
import DropDownArrow from '../../components/Common/DropDownArrow'
import { IFile } from '@common/interfaces/box'
import Loader from '../Loader'
import Tooltip from '@mui/material/Tooltip'
import Link from '@mui/material/Link'
interface IProps {
  isLoading?: boolean
  files: IFile[]
  isSheetSelectable?: boolean
  isAutoSelectable?: boolean
  updateFile?: (id: string, sheetName: string) => void
  className?: string
}

const FileSelect = ({
  isLoading = false,
  files,
  isSheetSelectable = true,
  isAutoSelectable = true,
  updateFile,
  className,
}: IProps) => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)
  const [selectedDocument, setSelectedDocument] = useState<IFile | null>(null)
  const [expandedNode, setExpandedNode] = useState([])

  const handleNodeToggle = useCallback(
    (event: React.ChangeEvent<{}>, nodeIds: string[]) => {
      setExpandedNode([nodeIds[0]])
    },
    [setExpandedNode],
  )

  useEffect(() => {
    setSelectedDocument(files.find(({ sheetName }) => Boolean(sheetName)))
  }, [files])

  const selectedNodeId = useMemo(
    () =>
      selectedDocument
        ? isSheetSelectable
          ? `${selectedDocument.id}/${selectedDocument.sheetName}`
          : selectedDocument.id
        : null,
    [selectedDocument, isSheetSelectable],
  )

  const handleOpen = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      setAnchorEl(event.currentTarget)
    },
    [setAnchorEl],
  )

  const handleClose = useCallback(() => {
    setAnchorEl(null)
  }, [setAnchorEl])

  const handleSelect = useCallback(
    async (event, nodeId) => {
      if (!nodeId) {
        return
      }

      if (!isSheetSelectable) {
        setSelectedDocument(files.find(({ id: fileId }) => fileId === nodeId))
        updateFile && updateFile(nodeId, '')
        handleClose()
      } else {
        const filesId = files.map(({ id: fileId }) => fileId)
        if (!filesId.includes(nodeId)) {
          const [fileId, sheetName] = nodeId.split('/')
          updateFile(fileId, sheetName)
          handleClose()
        }
      }
    },
    [isSheetSelectable, files, handleClose, updateFile],
  )

  useEffect(() => {
    if (isAutoSelectable && files.length === 1 && !files[0].sheetName && !files[0].isSkipped) {
      if (isSheetSelectable) {
        if (files[0].sheetNames?.length === 1) {
          files[0].sheetName = files[0].sheetNames[0]
          setSelectedDocument(files[0])
        }
      } else {
        setSelectedDocument(files[0])
      }
    }
  }, [files, isSheetSelectable, isAutoSelectable])

  const fileSelectLabel = useMemo(() => {
    if (isSheetSelectable && selectedDocument?.sheetName) {
      return `${selectedDocument.fileName || selectedDocument.name} - ${selectedDocument.sheetName}`
    }
    if (selectedDocument?.fileName) {
      return selectedDocument.fileName
    }
    if (selectedDocument?.name) {
      return selectedDocument.name
    }
    return 'Select file and worksheet'
  }, [selectedDocument, isSheetSelectable])
  return (
    <Box display="flex" className={cn(styles.wrapper, className)}>
      {isLoading && <Loader />}

      <Button
        type="button"
        color="primary"
        size="small"
        className={cn(styles.title, {
          [styles.open]: Boolean(anchorEl),
        })}
        onClick={handleOpen}
      >
        <Tooltip title={fileSelectLabel} placement="top">
          <span>{fileSelectLabel}</span>
        </Tooltip>
        <DropDownArrow className={cn(styles.titleIcon, { [styles.rotate]: !!anchorEl })} />
      </Button>

      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        classes={{ paper: styles.popover }}
      >
        <TreeView
          defaultCollapseIcon={<ExpandMoreIcon />}
          defaultExpandIcon={<ChevronRightIcon />}
          multiSelect={false}
          onNodeSelect={handleSelect}
          selected={selectedNodeId}
          className={styles.filesList}
          expanded={expandedNode}
          onNodeToggle={handleNodeToggle}
        >
          {files.map(
            ({ id, fileName, name, sheetNames, link }) =>
              id && (
                <TreeItem
                  key={id}
                  nodeId={id}
                  classes={{
                    content: styles.content,
                    label: styles.label,
                    selected: styles.selected,
                    group: styles.group,
                  }}
                  label={
                    <Box display="flex" alignItems="center">
                      <Box className={styles.fileNameWrapper}>
                        <Link href={link} target="_blank">
                          <ExcelIcon className={styles.fileNameIcon} />
                        </Link>
                        <span className={styles.fileName}>{fileName || name}</span>
                      </Box>

                      {isSheetSelectable && <DropDownArrow className={cn(styles.titleIcon)} />}
                    </Box>
                  }
                >
                  {sheetNames &&
                    sheetNames.map((sheetName) => (
                      <TreeItem
                        key={sheetName}
                        nodeId={`${id}/${sheetName}`}
                        label={sheetName}
                        classes={{
                          content: styles.content,
                          selected: styles.selected,
                        }}
                      />
                    ))}
                </TreeItem>
              ),
          )}
          {!files.length && (
            <TreeItem
              nodeId={'no-items'}
              classes={{
                content: styles.content,
                label: styles.label,
                selected: styles.selected,
              }}
              label="No items available"
            />
          )}
        </TreeView>
      </Popover>
    </Box>
  )
}

export default FileSelect
