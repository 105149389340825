import SalesByDistributionGraph from './SalesByDistributionGraph'
import { listSalesByDistributionGraph } from '../../redux/ongoingReporting/actions'
import { createStructuredSelector } from 'reselect'
import { salesByDistributionGraph } from '../../redux/ongoingReporting/selectors'
import { connect } from 'react-redux'

const selector = createStructuredSelector({
  salesByDistributionGraph,
})

const actions = {
  listSalesByDistributionGraph,
}

export default connect(selector, actions)(SalesByDistributionGraph)
