import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'

import ProspectPage from './ProspectPage'

import { reporting } from '../../redux/prospect/selectors'
import { isBDO, isAdmin } from '../../redux/profile/selectors'

const selector = createStructuredSelector({
  reporting,
  isBDO,
  isAdmin,
})

export default connect(selector)(ProspectPage)
