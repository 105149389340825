import { connect } from 'react-redux'
import { createStructuredSelector, createSelector } from 'reselect'
import { matchPath } from 'react-router'

import ProspectReportingFinancialsPage from './ProspectReportingFinancialsPage'

import { dataConsolidated, dataSummary } from '../../redux/ongoingReporting/selectors'
import { reporting } from '../../redux/prospect/selectors'
import { pathname } from '../../redux/router/selectors'
import { startReporting, show } from '../../redux/prospect/actions'
import { ROUTES } from '../../constants/routes'
import { OngoingReportingType } from '@common/interfaces/bbc'
import { submitOngoingReporting } from '../../redux/ongoingReporting/actions'
import { isSidebarOpen } from '../../redux/profile/selectors'
import { isNotesShown } from '../../redux/notes/selectors'

const selector = createStructuredSelector({
  reporting,
  statementType: createSelector(pathname, (path) =>
    matchPath(path, { path: ROUTES.PROSPECT_REPORTING_BALANCE_SHEET })
      ? OngoingReportingType.BalanceSheet
      : matchPath(path, { path: ROUTES.PROSPECT_REPORTING_INCOME_STATEMENT })
      ? OngoingReportingType.IncomeStatement
      : matchPath(path, { path: ROUTES.PROSPECT_REPORTING_BALANCE_SHEET_PROJECTIONS })
      ? OngoingReportingType.BalanceSheetProjections
      : matchPath(path, { path: ROUTES.PROSPECT_REPORTING_INCOME_STATEMENT_PROJECTIONS })
      ? OngoingReportingType.IncomeStatementProjections
      : null,
  ),
  dataConsolidated,
  dataSummary,
  isSidebarOpen,
  isNotesShown,
})

const actions = {
  show,
  startReporting,
  submitOngoingReporting,
}

export default connect(selector, actions)(ProspectReportingFinancialsPage)
