import React from 'react'
import MuiMenuItem from '@mui/material/MenuItem'

import styles from './MenuItem.module.scss'

const MenuItem = React.forwardRef((props: any, ref) => {
  return <MuiMenuItem {...props} ref={ref} classes={{ root: styles.root }} />
})

export default MenuItem
