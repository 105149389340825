import React from 'react'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import ClientSetupHeader from '../../components/ClientSetupHeader'
import DealStructure from '../../components/Client/DealStructure'

const ClientSetupLoanStructureAssetBasedPage = () => {
  return (
    <Box py={1} pr={2}>
      <ClientSetupHeader />

      <Box>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <DealStructure />
          </Grid>
        </Grid>
      </Box>
    </Box>
  )
}

export default ClientSetupLoanStructureAssetBasedPage
