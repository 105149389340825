import React from 'react'

import {
  DueDiligenceTableItemType,
  IDueDiligenceInventoryLocation,
} from '@common/interfaces/dueDiligence'
import DueDiligenceApplicationTable from '../DueDiligenceApplicationTable'
import { ILoadingData } from '../../redux/types'

interface IProps {
  dueDiligenceFinancialsInventoryLocations: ILoadingData<{ data: IDueDiligenceInventoryLocation[] }>
  listFinancialsInventoryLocations: (params: object) => void
  hideFinancialsInventoryLocations: () => void
}

const DueDiligenceApplicationTableInventoryLocations = ({
  dueDiligenceFinancialsInventoryLocations,
  listFinancialsInventoryLocations,
  hideFinancialsInventoryLocations,
}: IProps) => {
  return (
    <DueDiligenceApplicationTable
      type={DueDiligenceTableItemType.InventoryLocation}
      data={dueDiligenceFinancialsInventoryLocations}
      handleList={listFinancialsInventoryLocations}
      handleHide={hideFinancialsInventoryLocations}
    />
  )
}

export default DueDiligenceApplicationTableInventoryLocations
