import React, { useCallback, useMemo, useState } from 'react'
import { useHistory, useLocation, useParams } from 'react-router'
import { generatePath } from 'react-router-dom'
import Grid from '@mui/material/Grid'
import Box from '@mui/material/Box'
import Tooltip from '@mui/material/Tooltip'
import genericSs from '@styles/generic.module.scss'
import { useLoadInfo } from '../../hooks/useLoadInfo'
import { ReactComponent as HomeIcon } from '@assets/images/home-icon.svg'
import { IDueDiligence } from '@common/interfaces/dueDiligence'
import { ROUTES } from '../../constants/routes'
import Breadcrumbs from '../../components/Common/Breadcrumbs'
import { useSetPageTitle } from '../../hooks/useSetPageTitle'
import Button from '../../components/Common/Button'
import { ClientInfoStatus } from '@common/interfaces/client'
import CapTableMappingTable from '../../components/CapTableMappingTable'
import { ReportingFlow } from '@common/interfaces/bbc'

interface IProps {
  dueDiligenceInfo: IDueDiligence
  show: (id: string) => void
}

const DueDiligenceProcessDocumentsMappingPage = ({
  dueDiligenceInfo,

  show,
}: IProps) => {
  const { id, type: routeType } = useParams<{ id: string; type: string }>()
  const { pathname } = useLocation()
  const history = useHistory()
  const readOnly = useMemo(
    () => dueDiligenceInfo?.clientStatus !== ClientInfoStatus.DueDiligence,
    [dueDiligenceInfo],
  )

  const [mappingComplete, setMappingComplete] = useState(false)

  useLoadInfo({ id, info: dueDiligenceInfo, show })

  useSetPageTitle(dueDiligenceInfo?.clientName || '')

  const breadcrumbs = useMemo(() => {
    return [
      {
        link: ROUTES.DUE_DILIGENCE_HOME,
        Icon: HomeIcon,
      },
      {
        link: generatePath(ROUTES.DUE_DILIGENCE, { id }),
        title: dueDiligenceInfo?.clientName,
      },
      {
        link: generatePath(ROUTES.DUE_DILIGENCE_ANALYSIS_QUEUE, { id }),
        title: 'Process Documents',
      },
      {
        link: generatePath(ROUTES.DUE_DILIGENCE_PROCESS_DOCUMENTS_LIST, { id, type: routeType }),
        title: `Cap Table Reports`,
      },
      {
        link: pathname,
        title: `Map Cap Table Investors`,
      },
    ]
  }, [dueDiligenceInfo, id, routeType, pathname])

  const handleGoNext = useCallback(async () => {
    if (readOnly) {
      history.push(generatePath(ROUTES.DUE_DILIGENCE_ANALYSIS_QUEUE, { id }))
      return
    }

    history.push(generatePath(ROUTES.DUE_DILIGENCE_ANALYSIS_QUEUE, { id }))
  }, [id, history, readOnly])

  return (
    <Grid container py={3} pr={2} alignItems="flex-start" justifyContent="center" rowSpacing={2}>
      <Grid item container xs={12} justifyContent="space-between">
        <Grid item container xs={6} justifyContent="flex-start" alignItems="center">
          <Breadcrumbs breadcrumbs={breadcrumbs} />
        </Grid>
      </Grid>

      <Grid
        item
        xs={12}
        alignItems="flex-start"
        justifyContent="flex-start"
        ml={3}
        paddingBottom={'16px'}
      >
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <h2 className={genericSs.dueDiligenceHeader}>Map Cap Table</h2>
          <Tooltip
            title={!mappingComplete ? 'Map all investors with at least 5% ownership' : ''}
            placement="top"
          >
            <Box display="flex" alignItems="center" gap={2}>
              <Button
                className={genericSs.dueDiligenceSubmitButton}
                variant="contained"
                onClick={handleGoNext}
                disabled={!mappingComplete}
              >
                {readOnly ? 'Next' : 'Continue'}
              </Button>
            </Box>
          </Tooltip>
        </Box>
      </Grid>
      <CapTableMappingTable
        reportingFlow={ReportingFlow.DueDiligence}
        clientName={dueDiligenceInfo?.clientName}
        setMappingComplete={setMappingComplete}
      />
    </Grid>
  )
}

export default DueDiligenceProcessDocumentsMappingPage
