import React, { useCallback, useMemo } from 'react'
import Grid from '@mui/material/Grid'
import Popover from '@mui/material/Popover'
import { CalendarPickerView } from '@mui/x-date-pickers/CalendarPicker'
import moment from 'moment'

import styles from './DatePicker.module.scss'

import { ReactComponent as CalendarIcon } from '@assets/images/calendar-icon.svg'
import {
  formatDate,
  formatDateMonthYearShort,
  formatDateQuarterYearShort,
  formatDateYear,
} from '../../../helpers/helpers'
import { NonReportingPeriods, ReportingPeriods, CALENDAR_VIEWS_MAP } from '@common/interfaces/bbc'
import TextField from '../TextField'
import InputLabel from '../InputLabel'
import KeyboardDatePicker from '../KeyboardDatePicker'
import { DATE_FORMAT } from '../../../constants/common'

const DatePicker = ({
  reportingPeriod = ReportingPeriods.Monthly || NonReportingPeriods.Daily,
  currentDateRange,
  datesBoundary,
  onChange,
  label = 'Date Range',
  disabled,
}: {
  reportingPeriod?: ReportingPeriods | NonReportingPeriods.Daily
  currentDateRange: { startDate: string; endDate: string }
  datesBoundary: { minDate: string; maxDate: string }
  onChange: (values: { startDate: string; endDate: string }) => void
  label?: string
  disabled?: boolean
}) => {
  const [anchorEl, setAnchorEl] = React.useState(null)
  const open = Boolean(anchorEl)

  const handleClose = useCallback(() => {
    setAnchorEl(null)
  }, [])

  const handleClick = useCallback((event: React.MouseEvent<any>) => {
    setAnchorEl(event.currentTarget)
  }, [])

  const value = useMemo(() => {
    if (!currentDateRange) {
      return ''
    }

    const formatDateFunction =
      reportingPeriod === ReportingPeriods.Annually
        ? formatDateYear
        : reportingPeriod === ReportingPeriods.Quarterly
        ? formatDateQuarterYearShort
        : reportingPeriod === ReportingPeriods.Monthly
        ? formatDateMonthYearShort
        : formatDate

    return `${currentDateRange.startDate ? formatDateFunction(currentDateRange.startDate) : ''} - ${
      currentDateRange.endDate ? formatDateFunction(currentDateRange.endDate) : ''
    }`
  }, [currentDateRange, reportingPeriod])

  const handleStartDateChange = useCallback(
    (value) => {
      if (!isNaN(value?.getTime()) || value === null) {
        onChange({
          startDate: value ? moment(value).format('YYYY-MM-DD') : null,
          endDate: currentDateRange.endDate,
        })
      }
    },
    [onChange, currentDateRange],
  )

  const handleEndDateChange = useCallback(
    (value) => {
      if (!isNaN(value?.getTime()) || value === null) {
        onChange({
          startDate: currentDateRange.startDate,
          endDate: value ? moment(value).format('YYYY-MM-DD') : null,
        })
      }
    },
    [onChange, currentDateRange],
  )

  const calendarViews: CalendarPickerView[] = useMemo(
    () => CALENDAR_VIEWS_MAP[reportingPeriod],
    [reportingPeriod],
  )

  return (
    <Grid item>
      <TextField
        useFinalForm={false}
        variant="outlined"
        className={styles.dateRangeField}
        name="dateRange"
        label={label}
        value={value}
        InputProps={{
          endAdornment: <CalendarIcon className={styles.dateRangeFieldIcon} />,
        }}
        onClick={handleClick}
        disabled={disabled}
      />
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        slotProps={{
          paper: {
            style: { width: anchorEl?.offsetWidth },
          },
        }}
      >
        <div className={styles.dateRangeGrid}>
          <Grid container spacing={3} direction="column" justifyContent="center">
            <Grid item xs={4}>
              <InputLabel>From</InputLabel>
              <KeyboardDatePicker
                useFinalForm={false}
                name="startDate"
                value={currentDateRange.startDate}
                onChange={handleStartDateChange}
                views={calendarViews}
                placeholder="Start date"
                inputFormat={DATE_FORMAT}
                minDate={datesBoundary?.minDate}
                maxDate={currentDateRange.endDate || datesBoundary?.maxDate}
              />
            </Grid>

            <Grid item xs={4}>
              <InputLabel>To</InputLabel>
              <KeyboardDatePicker
                useFinalForm={false}
                name="endDate"
                value={currentDateRange.endDate}
                onChange={handleEndDateChange}
                views={calendarViews}
                placeholder="End date"
                inputFormat={DATE_FORMAT}
                minDate={currentDateRange.startDate || datesBoundary?.minDate}
                maxDate={datesBoundary?.maxDate}
              />
            </Grid>
          </Grid>
        </div>
      </Popover>
    </Grid>
  )
}

export default DatePicker
