import React, { useCallback, useEffect, useMemo, useState } from 'react'
import SelectField from '../../../components/Common/SelectField'
import { IProspectTerms } from '@common/interfaces/prospects'
import cn from 'classnames'
import styles from './../ProspectManageTermsPage.module.scss'
import { PROSPECT_TERMS_STATUSES } from '@common/constants/prospects'

interface IProps {
  updateProspectTerms: (id: string, prospectTermId: string, term: Partial<IProspectTerms>) => void
  term: IProspectTerms
  opsReportingId: string
}

const statusOptions = [
  { value: '', label: '-' },
  { value: PROSPECT_TERMS_STATUSES.submission, label: 'Submission', className: styles.gray },
  { value: PROSPECT_TERMS_STATUSES.approved, label: 'Approved', className: styles.blue },
  { value: PROSPECT_TERMS_STATUSES.proposed, label: 'Proposed', className: styles.yellow },
  { value: PROSPECT_TERMS_STATUSES.signed, label: 'Signed', className: styles.green },
]

const Status = ({ status }: { status: string }) => {
  const statusOption = statusOptions.find((option) => option.value === status)
  return <div className={cn(styles.status, statusOption?.className)}>{statusOption?.label}</div>
}

const ManageTermsStatusSelect = ({ updateProspectTerms, term, opsReportingId }: IProps) => {
  const { id: prospectTermId, status: initialStatus } = useMemo(() => term, [term])

  const [status, setStatus] = useState(initialStatus)

  const handleChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setStatus(event.target.value)
    },
    [setStatus],
  )

  useEffect(() => {
    if (initialStatus !== status && status) {
      updateProspectTerms(opsReportingId, prospectTermId, {
        status,
      })
    }
  }, [status, updateProspectTerms, prospectTermId, opsReportingId, initialStatus])

  return (
    <SelectField
      label="Status"
      useFinalForm={false}
      name="status"
      options={statusOptions}
      value={status}
      onChange={handleChange}
      className={styles.statusSelect}
      optionClassName={styles.statusOption}
      renderValue={() => <Status status={status} />}
    />
  )
}

export default ManageTermsStatusSelect
