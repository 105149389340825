import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'

import { clientInfo } from '../../redux/clientInfo/selectors'
import { show as showClient } from '../../redux/clientInfo/actions'
import { isAdmin } from '../../redux/profile/selectors'

import ClientSetupHeader from './ClientSetupHeader'

const selector = createStructuredSelector({
  clientInfo,
  isAdmin,
})

const actions = {
  showClient,
}

export default connect(selector, actions)(ClientSetupHeader)
