import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import TreasuryTable from './TreasuryTable'
import { treasuryData } from '../../redux/treasury/selectors'
import { listTreasury, updateTreasury, createTreasury } from '../../redux/treasury/actions'

const selector = createStructuredSelector({
  treasuryData,
})

const actions = {
  listTreasury,
  updateTreasury,
  createTreasury,
}

export default connect(selector, actions)(TreasuryTable)
