import React, { useCallback, useEffect, useMemo, useState } from 'react'
import genericSs from '@styles/generic.module.scss'
import { debounceEventHandler, formatPrice, voidHandler } from '../../helpers/helpers'
import Table from '../Common/Table'
import TableHead from '../Common/TableHead'
import TableRow from '../Common/TableRow'
import TableCell from '../Common/TableCell'
import TableContainer from '../Common/TableContainer'
import TableBody from '../Common/TableBody'
import TableFooter from '../Common/TableFooter'
import { ILoadingData } from '../../redux/types'
import { TREASURY_FILTERS_CONFIG, EMPTY_ORDER_BY } from '@common/constants/filters'
import styles from './TreasuryTable.module.scss'
import TableFiltersRow from '../Common/TableFiltersRow'
import TableLoader from '../Common/TableLoader'
import LinkButton from '@mui/material/Link'
import { Link, generatePath } from 'react-router-dom'
import { ROUTES } from '../../constants/routes'
import { ITreasuryData, TreasuryAmountTypes } from '@common/interfaces/treasury'
import Checkbox from '../Common/Checkbox'
import { FundingRequestStatus } from '@common/interfaces/bbc'
import cn from 'classnames'
import CurrencyField from '../Common/CurrencyField'
import { DeleteIcon } from '../Common/Icons'
import AddButton from '../Client/AddButton'
import Modal from '../Common/Modal'
import Button from '../Common/Button'
import SelectField from '../Common/SelectField'
import { Box } from '@mui/material'
import InputLabel from '../Common/InputLabel'
import TextField from '../Common/TextField'
import Autocomplete from '../Common/Autocomplete'
import { Field, Form } from 'react-final-form'
import WarningModal from '../WarningModal'
import { ReactComponent as EmptyIcon } from '@assets/images/empty-page-icon.svg'

interface IProps {
  listTreasury: (params?: object) => void
  treasuryData: ILoadingData<ITreasuryData>
  updateTreasury: (params: object) => void
  createTreasury: (params: object) => void
}

const TreasuryTable = ({ listTreasury, treasuryData, updateTreasury, createTreasury }: IProps) => {
  const [isCreateModalShown, setIsCreateModalShown] = useState(false)
  const [isSaving, setIsSaving] = useState(false)
  const [isDeleteModalShown, setIsDeleteModalShown] = useState(false)
  const [selectedWire, setSelectedWire] = useState(null)

  const {
    data: treasurySummaryData,
    isLoading,
    fundingWires,
    passthroughWires,
    participationWires,
    otherWires,
    clientList,
  } = useMemo(() => {
    return {
      data: treasuryData?.data?.data,
      isLoading: treasuryData?.isLoading,
      fundingWires: treasuryData?.data?.data?.fundingWires,
      passthroughWires: treasuryData?.data?.data?.passthroughWires,
      participationWires: treasuryData?.data?.data?.participationWires,
      otherWires: treasuryData?.data?.data?.otherWires,
      clientList: treasuryData?.data?.data?.clientList,
    }
  }, [treasuryData])

  const newWireTypes = useMemo(() => {
    return [TreasuryAmountTypes.Funding, TreasuryAmountTypes.Other].map((type) => ({
      value: type,
      label: type,
    }))
  }, [])

  useEffect(() => {
    listTreasury()
  }, [listTreasury])

  const handelCreateWire = useCallback(
    async ({ newWireType, newWireAmount, newWireClient, newWireDescription }) => {
      setIsSaving(true)

      await createTreasury({
        type: newWireType,
        amount: newWireAmount,
        description: newWireDescription,
        clientName: newWireClient?.value,
      })
      await listTreasury({ skipLoader: true })
      setIsSaving(false)
      setIsCreateModalShown(false)
    },
    [listTreasury, createTreasury],
  )

  const selectedWiresTotal = useMemo(() => {
    return (
      fundingWires
        ?.filter((item) => item.isChecked)
        ?.reduce((acc, item) => acc + Number(item.amount), 0) +
      passthroughWires
        ?.filter((item) => item.isChecked)
        ?.reduce((acc, item) => acc + Number(item.amount), 0) +
      participationWires
        ?.filter((item) => item.isChecked)
        ?.reduce((acc, item) => acc + Number(item.amount), 0) +
      otherWires
        ?.filter((item) => item.isChecked)
        ?.reduce((acc, item) => acc + Number(item.amount), 0)
    )
  }, [fundingWires, passthroughWires, participationWires, otherWires])

  const availableCash = useMemo(() => {
    return treasurySummaryData?.startingCash - selectedWiresTotal
  }, [treasurySummaryData, selectedWiresTotal])

  const estimatedDraw = useMemo(() => {
    return Math.max(
      -treasurySummaryData?.startingCash + selectedWiresTotal + treasurySummaryData?.buffer,
      0,
    )
  }, [treasurySummaryData, selectedWiresTotal])

  const endingCash = useMemo(() => {
    return treasurySummaryData?.startingCash + treasurySummaryData?.finalDraw - selectedWiresTotal
  }, [treasurySummaryData, selectedWiresTotal])

  const handleUpdateTreasuryData = useCallback(
    async (data: object) => {
      await updateTreasury(data)
      await listTreasury({ skipLoader: true })
    },
    [updateTreasury, listTreasury],
  )

  const toggleModal = useCallback(() => {
    setIsCreateModalShown(!isCreateModalShown)
  }, [isCreateModalShown])

  const handleUpdateDebounce = useMemo(
    () =>
      debounceEventHandler(async (data: object) => {
        await handleUpdateTreasuryData(data)
      }, 1000),
    [handleUpdateTreasuryData],
  )

  const isAllFundingsChecked = useMemo(() => {
    return fundingWires?.every((item) => item.isChecked)
  }, [fundingWires])

  const isAllPassthroughsChecked = useMemo(() => {
    return passthroughWires?.every((item) => item.isChecked)
  }, [passthroughWires])

  const isAllPassthroughsDisabled = useMemo(() => {
    return passthroughWires?.every((item) => item.passThrough?.id)
  }, [passthroughWires])

  const isAllParticipationsChecked = useMemo(() => {
    return participationWires?.every((item) => item.isChecked)
  }, [participationWires])

  const isAllParticipationsDisabled = useMemo(() => {
    return participationWires?.every((item) => item.participationWires?.id)
  }, [participationWires])

  const isAllOthersChecked = useMemo(() => {
    return otherWires?.every((item) => item.isChecked)
  }, [otherWires])

  const handleCurrencyFieldChange = useCallback(
    ({ target }) => {
      handleUpdateDebounce({
        id: target.name,
        amount: target.value || 0,
      })
    },
    [handleUpdateDebounce],
  )

  const handleDeleteWire = useCallback(async (id) => {
    setSelectedWire(id)
    setIsDeleteModalShown(true)
  }, [])

  const handleDeleteTreasuryConfirm = useCallback(async () => {
    await handleUpdateTreasuryData({ id: selectedWire, isDisplayed: false })
    setIsDeleteModalShown(false)
    setSelectedWire(null)
  }, [selectedWire, handleUpdateTreasuryData])

  const handleDeleteTreasuryCancel = useCallback(() => {
    setIsDeleteModalShown(false)
    setSelectedWire(null)
  }, [])

  const handleUpdateStartingCash = useCallback(
    ({ target }) => {
      handleUpdateDebounce({
        startingCash: target.value || 0,
      })
    },
    [handleUpdateDebounce],
  )

  const handleUpdateBuffer = useCallback(
    ({ target }) => {
      handleUpdateDebounce({
        buffer: target.value || 0,
      })
    },
    [handleUpdateDebounce],
  )

  const handleUpdateFinalDraw = useCallback(
    ({ target }) => {
      handleUpdateDebounce({
        finalDraw: target.value || 0,
      })
    },
    [handleUpdateDebounce],
  )

  const handleUpdatedChecked = useCallback(
    ({ id, wireType, isChecked }) => {
      if (id) {
        handleUpdateTreasuryData({ id, isChecked })
      } else if (wireType) {
        handleUpdateTreasuryData({ wireType, isChecked })
      }
    },
    [handleUpdateTreasuryData],
  )

  const onFormSubmit = useCallback(
    (formValues) => {
      const { newWireType, newWireDescription, newWireClient, newWireAmount } = formValues
      handelCreateWire({ newWireType, newWireDescription, newWireClient, newWireAmount })
    },
    [handelCreateWire],
  )

  return (
    <>
      {treasurySummaryData?.id ? (
        <div>
          <TableContainer className={styles.table}>
            <Table>
              <div className={styles.titleContainer}>
                <div className={styles.title}>Treasury</div>
                <AddButton className={styles.addButton} onClick={toggleModal} />
              </div>
              <TableHead>
                <TableFiltersRow
                  filters={TREASURY_FILTERS_CONFIG}
                  hideFilters
                  isChildrenAtStart
                  orderBy={EMPTY_ORDER_BY}
                  handleOrderChange={voidHandler}
                ></TableFiltersRow>
              </TableHead>
              <TableBody id="scrollableTableDetails">
                {isLoading ? (
                  <TableLoader columnsCount={TREASURY_FILTERS_CONFIG.length} />
                ) : (
                  <div>
                    {fundingWires?.length ? (
                      <div className={styles.fundingWires}>
                        <TableHead className={styles.headerContainer}>
                          <TableRow>
                            <TableCell className={genericSs.tableTextLeft}>
                              <Checkbox
                                className={styles.checkBox}
                                checked={isAllFundingsChecked}
                                onChange={(event, checked) =>
                                  handleUpdatedChecked({
                                    wireType: TreasuryAmountTypes.Funding,
                                    isChecked: checked,
                                  })
                                }
                              />
                            </TableCell>

                            <TableCell className={genericSs.tableTextLeft}>Fundings</TableCell>
                          </TableRow>
                        </TableHead>
                        {fundingWires?.map((item) => (
                          <TableRow key={item.id}>
                            <TableCell className={genericSs.tableTextLeft}>
                              <Checkbox
                                className={styles.checkBox}
                                checked={item.isChecked}
                                onChange={(event, checked) =>
                                  handleUpdatedChecked({
                                    id: item.id,
                                    isChecked: checked,
                                  })
                                }
                              />
                            </TableCell>

                            <TableCell className={genericSs.tableTextLeft}>
                              {item?.clientInfo?.id ? (
                                <LinkButton
                                  component={Link}
                                  to={generatePath(ROUTES.CLIENT_PAGE, {
                                    id: item?.clientInfo?.id,
                                  })}
                                >
                                  {item?.clientInfo?.clientName}
                                </LinkButton>
                              ) : (
                                item?.clientInfo?.clientName
                              )}
                            </TableCell>
                            <TableCell className={cn(genericSs.tableTextLeft)}>
                              <div
                                className={cn(genericSs.tableTextLeft, styles.status, {
                                  [genericSs.complete]:
                                    item?.borrowingBase?.status === FundingRequestStatus.Sent,
                                  [genericSs.inProgress]:
                                    item?.borrowingBase &&
                                    item?.borrowingBase?.status !== FundingRequestStatus.Sent,
                                  [genericSs.notStarted]: !item?.borrowingBase,
                                })}
                              >
                                {item?.borrowingBase
                                  ? item?.borrowingBase?.status === FundingRequestStatus.Sent
                                    ? 'Completed'
                                    : item?.borrowingBase.requestedAmountType
                                  : item.isPrediction
                                  ? 'Atlas Predicted'
                                  : 'User Predicted'}
                              </div>
                            </TableCell>
                            <TableCell className={genericSs.tableTextRight}>
                              {item?.borrowingBase?.status === FundingRequestStatus.Sent ? (
                                `$${formatPrice(item.amount)}`
                              ) : (
                                <CurrencyField
                                  className={styles.amountField}
                                  name={item.id}
                                  value={formatPrice(item.amount)}
                                  onChange={handleCurrencyFieldChange}
                                  useFinalForm={false}
                                />
                              )}
                            </TableCell>
                            <TableCell className={genericSs.tableTextLeft}>
                              {!item?.borrowingBase?.status && (
                                <DeleteIcon
                                  className={styles.iconButton}
                                  onClick={() => handleDeleteWire(item.id)}
                                  size="small"
                                />
                              )}
                            </TableCell>
                          </TableRow>
                        ))}
                      </div>
                    ) : (
                      <></>
                    )}
                    {passthroughWires?.length ? (
                      <div className={styles.passThroughs}>
                        <TableHead className={styles.headerContainer}>
                          <TableRow>
                            <TableCell className={genericSs.tableTextLeft}>
                              <Checkbox
                                className={styles.checkBox}
                                checked={isAllPassthroughsChecked}
                                disabled={isAllPassthroughsDisabled}
                                onChange={(event, checked) =>
                                  handleUpdatedChecked({
                                    wireType: TreasuryAmountTypes.Passthrough,
                                    isChecked: checked,
                                  })
                                }
                              />
                            </TableCell>
                            <TableCell className={genericSs.tableTextLeft}>Passthroughs</TableCell>
                          </TableRow>
                        </TableHead>
                        {passthroughWires?.map((item) => (
                          <TableRow key={item.id}>
                            <TableCell className={genericSs.tableTextLeft}>
                              <Checkbox
                                className={styles.checkBox}
                                disabled={item?.passThrough?.id ? true : false}
                                checked={item.isChecked}
                                onChange={(event, checked) =>
                                  handleUpdatedChecked({
                                    id: item.id,
                                    isChecked: checked,
                                  })
                                }
                              />
                            </TableCell>
                            <TableCell className={genericSs.tableTextLeft}>
                              {item?.clientInfo?.id ? (
                                <LinkButton
                                  component={Link}
                                  to={generatePath(ROUTES.CLIENT_PAGE, {
                                    id: item?.clientInfo?.id,
                                  })}
                                >
                                  {item?.clientInfo?.clientName}
                                </LinkButton>
                              ) : (
                                item?.clientInfo?.clientName
                              )}
                            </TableCell>
                            <TableCell className={cn(genericSs.tableTextLeft)}>
                              <div
                                className={cn(
                                  genericSs.tableTextLeft,
                                  styles.status,
                                  genericSs.complete,
                                )}
                              >
                                Sent
                              </div>
                            </TableCell>
                            <TableCell className={genericSs.tableTextRight}>
                              {item?.passThrough ? (
                                `$${formatPrice(item.amount)}`
                              ) : (
                                <CurrencyField
                                  className={styles.amountField}
                                  name={item.id}
                                  value={formatPrice(item.amount)}
                                  onChange={handleCurrencyFieldChange}
                                  useFinalForm={false}
                                />
                              )}
                            </TableCell>
                            <TableCell className={genericSs.tableTextRight}></TableCell>
                          </TableRow>
                        ))}
                      </div>
                    ) : (
                      <></>
                    )}
                    {participationWires?.length ? (
                      <div className={styles.participationWires}>
                        <TableHead className={styles.headerContainer}>
                          <TableRow>
                            <TableCell className={genericSs.tableTextLeft}>
                              <Checkbox
                                className={styles.checkBox}
                                checked={isAllParticipationsChecked}
                                disabled={isAllParticipationsDisabled}
                                onChange={(event, checked) =>
                                  handleUpdatedChecked({
                                    wireType: TreasuryAmountTypes.Participation,
                                    isChecked: checked,
                                  })
                                }
                              />
                            </TableCell>
                            <TableCell className={genericSs.tableTextLeft}>
                              Participations
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        {participationWires?.map((item) => (
                          <TableRow key={item.id}>
                            <TableCell className={genericSs.tableTextLeft}>
                              <Checkbox
                                className={styles.checkBox}
                                disabled={item?.participationWires?.id ? true : false}
                                checked={item.isChecked}
                                onChange={(event, checked) =>
                                  handleUpdatedChecked({
                                    id: item.id,
                                    isChecked: checked,
                                  })
                                }
                              />
                            </TableCell>

                            <TableCell className={genericSs.tableTextLeft}>
                              {item?.clientInfo?.clientName} {item?.participant}{' '}
                              {item?.participationWireType}
                            </TableCell>
                            <TableCell className={cn(genericSs.tableTextLeft)}>
                              <div
                                className={cn(
                                  genericSs.tableTextLeft,
                                  styles.status,
                                  genericSs.complete,
                                )}
                              >
                                Sent
                              </div>
                            </TableCell>
                            <TableCell className={genericSs.tableTextRight}>
                              {item?.participationWires ? (
                                `$${formatPrice(item.amount)}`
                              ) : (
                                <CurrencyField
                                  className={styles.amountField}
                                  name={item.id}
                                  value={formatPrice(item.amount)}
                                  onChange={handleCurrencyFieldChange}
                                  useFinalForm={false}
                                />
                              )}
                            </TableCell>
                            <TableCell className={genericSs.tableTextRight}></TableCell>
                          </TableRow>
                        ))}
                      </div>
                    ) : (
                      <></>
                    )}
                    {otherWires?.length ? (
                      <div className={styles.otherWires}>
                        <TableHead className={styles.headerContainer}>
                          <TableRow>
                            <TableCell className={genericSs.tableTextLeft}>
                              <Checkbox
                                className={styles.checkBox}
                                checked={isAllOthersChecked}
                                onChange={(event, checked) =>
                                  handleUpdatedChecked({
                                    wireType: TreasuryAmountTypes.Other,
                                    isChecked: checked,
                                  })
                                }
                              />
                            </TableCell>
                            <TableCell className={genericSs.tableTextLeft}>Other</TableCell>
                          </TableRow>
                        </TableHead>
                        {otherWires?.map((item) => (
                          <TableRow key={item.id}>
                            <TableCell className={genericSs.tableTextLeft}>
                              <Checkbox
                                className={styles.checkBox}
                                checked={item.isChecked}
                                onChange={(event, checked) =>
                                  handleUpdatedChecked({
                                    id: item.id,
                                    isChecked: checked,
                                  })
                                }
                              />
                            </TableCell>
                            <TableCell className={genericSs.tableTextLeft}>
                              {item?.description}
                            </TableCell>
                            <TableCell></TableCell>
                            <TableCell className={genericSs.tableTextRight}>
                              <CurrencyField
                                className={styles.amountField}
                                name={item.id}
                                value={formatPrice(item.amount)}
                                onChange={handleCurrencyFieldChange}
                                useFinalForm={false}
                              />
                            </TableCell>
                            <TableCell className={genericSs.tableTextLeft}>
                              <DeleteIcon
                                className={styles.iconButton}
                                onClick={() => handleDeleteWire(item.id)}
                                size="small"
                              />
                            </TableCell>
                          </TableRow>
                        ))}
                      </div>
                    ) : (
                      <></>
                    )}
                  </div>
                )}
              </TableBody>
              <TableFooter>
                <>
                  <TableRow>
                    <TableCell />
                    <TableCell className={genericSs.tableTextLeft}>Starting Cash</TableCell>
                    <TableCell />

                    <TableCell className={genericSs.tableTextRight}>
                      <CurrencyField
                        size="small"
                        className={styles.amountField}
                        name="startingCash"
                        value={formatPrice(treasurySummaryData?.startingCash)}
                        onChange={handleUpdateStartingCash}
                        useFinalForm={false}
                      />
                    </TableCell>
                    <TableCell />
                  </TableRow>

                  <TableRow className={styles.noBottomBorder}>
                    <TableCell />
                    <TableCell className={genericSs.tableTextLeft}>Estimated Cash Out</TableCell>
                    <TableCell />

                    <TableCell className={genericSs.tableTextRight}>
                      ${formatPrice(selectedWiresTotal)}
                    </TableCell>
                    <TableCell />
                  </TableRow>

                  <TableRow className={styles.noBottomBorder}>
                    <TableCell />
                    <TableCell className={genericSs.tableTextLeft}>Available</TableCell>
                    <TableCell />

                    <TableCell className={genericSs.tableTextRight}>
                      ${formatPrice(availableCash)}
                    </TableCell>
                    <TableCell />
                  </TableRow>

                  <TableRow className={styles.noBottomBorder}>
                    <TableCell />
                    <TableCell className={cn(genericSs.tableTextLeft, genericSs.tabSpacing)}>
                      Buffer
                    </TableCell>
                    <TableCell />

                    <TableCell className={genericSs.tableTextRight}>
                      <CurrencyField
                        size="small"
                        className={styles.amountField}
                        name="buffer"
                        value={formatPrice(treasurySummaryData?.buffer)}
                        onChange={handleUpdateBuffer}
                        useFinalForm={false}
                      />
                    </TableCell>
                    <TableCell />
                  </TableRow>

                  <TableRow className={styles.noBottomBorder}>
                    <TableCell />
                    <TableCell className={genericSs.tableTextLeft}>Estimated Draw</TableCell>
                    <TableCell />

                    <TableCell className={genericSs.tableTextRight}>
                      ${formatPrice(estimatedDraw)}
                    </TableCell>
                    <TableCell />
                  </TableRow>

                  <TableRow>
                    <TableCell />
                    <TableCell className={genericSs.tableTextLeft}>Final Draw</TableCell>
                    <TableCell />

                    <TableCell className={genericSs.tableTextRight}>
                      <CurrencyField
                        size="small"
                        className={styles.amountField}
                        name="buffer"
                        value={formatPrice(treasurySummaryData?.finalDraw)}
                        onChange={handleUpdateFinalDraw}
                        useFinalForm={false}
                      />
                    </TableCell>
                    <TableCell />
                  </TableRow>

                  <TableRow>
                    <TableCell />
                    <TableCell className={genericSs.tableTextLeft}>Ending Cash</TableCell>
                    <TableCell />

                    <TableCell className={genericSs.tableTextRight}>
                      ${formatPrice(endingCash)}
                    </TableCell>
                    <TableCell />
                  </TableRow>
                </>
              </TableFooter>
            </Table>
          </TableContainer>

          {isCreateModalShown && (
            <Modal
              open={isCreateModalShown}
              onCancel={toggleModal}
              classes={{ root: styles.modalRoot }}
              title="Add Wire"
            >
              <Form
                onSubmit={onFormSubmit}
                render={({ handleSubmit, values }) => {
                  const isSaveDisabled =
                    !values.newWireType ||
                    (!values.newWireAmount && values.newWireType === TreasuryAmountTypes.Other) ||
                    (!values.newWireDescription &&
                      values.newWireType === TreasuryAmountTypes.Other) ||
                    (!values.newWireClient && values.newWireType === TreasuryAmountTypes.Funding)

                  return (
                    <form onSubmit={handleSubmit}>
                      <Box className={styles.fieldsContainer}>
                        <InputLabel htmlFor="newWireType" className={genericSs.textLeft}>
                          Type
                        </InputLabel>
                        <Field
                          name="newWireType"
                          render={({ input }) => (
                            <SelectField
                              {...input}
                              options={newWireTypes}
                              className={styles.selectFormField}
                              variant="outlined"
                              useFinalForm={false}
                              fullWidth
                            />
                          )}
                        />

                        {values.newWireType === TreasuryAmountTypes.Other && (
                          <>
                            <InputLabel htmlFor="newWireDescription" className={genericSs.textLeft}>
                              Recipient
                            </InputLabel>
                            <Field
                              name="newWireDescription"
                              render={({ input }) => (
                                <TextField {...input} className={styles.textFormField} />
                              )}
                            />

                            <InputLabel htmlFor="newWireAmount" className={genericSs.textLeft}>
                              Amount
                            </InputLabel>
                            <CurrencyField className={styles.textFormField} name="newWireAmount" />
                          </>
                        )}

                        {values.newWireType === TreasuryAmountTypes.Funding && (
                          <>
                            <InputLabel htmlFor="newWireClient" className={genericSs.textLeft}>
                              Client
                            </InputLabel>
                            <Autocomplete
                              name="newWireClient"
                              options={clientList}
                              className={styles.autocompleteField}
                            />
                          </>
                        )}
                      </Box>
                      <Button
                        disabled={isSaveDisabled}
                        isLoading={isSaving}
                        color="primary"
                        variant="contained"
                        fullWidth
                        small={false}
                        type="submit"
                      >
                        Save
                      </Button>
                    </form>
                  )
                }}
              />
            </Modal>
          )}

          {isDeleteModalShown && selectedWire && (
            <WarningModal
              warningMessage=""
              onConfirm={handleDeleteTreasuryConfirm}
              onCancel={handleDeleteTreasuryCancel}
              confirmText="Yes, proceed"
              cancelText="Cancel"
              disabled={isLoading}
            />
          )}
        </div>
      ) : (
        <div className={styles.treasuryNotReady}>
          <EmptyIcon />
          <div className={styles.treasuryNotReadyTitle}>Treasury will be available soon</div>
        </div>
      )}
    </>
  )
}

export default TreasuryTable
