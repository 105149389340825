import React from 'react'
import Grid from '@mui/material/Grid'
import CircularProgress from '@mui/material/CircularProgress'
import Skeleton from '@mui/material/Skeleton'
import Box from '@mui/material/Box'

import styles from './DueDiligenceClientDashboard.module.scss'

import { DueDiligenceProgressStep } from '@common/interfaces/dueDiligence'

const DueDiligenceClientDashboardProgressCardLoader = () => {
  return (
    <div className={styles.diligenceProgressCardItem}>
      <div className={styles.diligenceProgressCardItemProgressContainer}>
        <CircularProgress
          classes={{
            root: styles.diligenceProgressCardItemProgress,
            circle: styles.diligenceProgressCardItemProgressCircleBackground,
          }}
          variant="determinate"
          value={100}
          size={92}
        />
        <CircularProgress
          classes={{
            root: styles.diligenceProgressCardItemProgress,
            circle: styles.diligenceProgressCardItemProgressCircle,
          }}
          size={92}
        />
      </div>
      <div className={styles.diligenceProgressCardItemTextContainer}>
        <div className={styles.diligenceProgressCardItemTextLabel}>Progress of</div>
        <div className={styles.diligenceProgressCardItemTextTitle}>
          <Skeleton width={140} height={24} />
        </div>
      </div>
      <div className={styles.diligenceProgressCardItemLink}>
        <Skeleton width={80} height={24} />
      </div>
    </div>
  )
}

const DueDiligenceClientDashboardLoader = () => {
  return (
    <Grid container py={3} pr={2} alignItems="flex-start" justifyContent="center" rowSpacing={4}>
      <Grid container item xs={12} alignItems="center" justifyContent="center" gap={3} mt={4}>
        <div className={styles.diligenceProgress}>
          <div className={styles.diligenceProgressTitle}>Diligence Progress</div>

          <div className={styles.diligenceProgressSteps}>
            <div className={styles.diligenceProgressStepItemRoot}>
              {Object.values(DueDiligenceProgressStep).map((step) => (
                <Box key={step} display="flex" gap={1} mt={0.75} alignItems="center">
                  <Skeleton width={32} height={54} />
                  <Skeleton width={150} height={24} />
                </Box>
              ))}
            </div>
          </div>

          <div className={styles.diligenceProgressDateContainer}>
            <div className={styles.diligenceProgressDateTextContainer}>
              <div className={styles.diligenceProgressDateLabel}>Target funding date</div>
              <div className={styles.diligenceProgressDate}>
                <Skeleton height={28} width={100} />
              </div>
            </div>
          </div>
        </div>
        <div className={styles.diligenceContainer}>
          <div className={styles.diligenceProgressCards}>
            <DueDiligenceClientDashboardProgressCardLoader />
            <DueDiligenceClientDashboardProgressCardLoader />
            <DueDiligenceClientDashboardProgressCardLoader />
          </div>
          <div className={styles.diligenceQuestions}>
            <div className={styles.diligenceQuestionsText}>
              <div className={styles.diligenceQuestionsTextTitle}>Question ?</div>
              <div className={styles.diligenceQuestionsTextDescription}>
                <Skeleton width={400} />
              </div>
            </div>
            <div className={styles.diligenceQuestionsManagers}>
              <div className={styles.diligenceQuestionsManagerItem}>
                <Skeleton variant="circular" width={48} height={48} />
                <div className={styles.diligenceQuestionsManagerItemContacts}>
                  <div className={styles.diligenceQuestionsManagerItemContactsName}>
                    <Skeleton width={50} height={24} />
                  </div>
                  <div className={styles.diligenceQuestionsManagerItemContactsData}>
                    <Skeleton width={200} height={20} />
                  </div>
                  <div className={styles.diligenceQuestionsManagerItemContactsData}>
                    <Skeleton width={150} height={20} />
                  </div>
                </div>
              </div>
              <div className={styles.diligenceQuestionsManagerItem}>
                <Skeleton variant="circular" width={48} height={48} />
                <div className={styles.diligenceQuestionsManagerItemContacts}>
                  <div className={styles.diligenceQuestionsManagerItemContactsName}>
                    <Skeleton width={50} height={24} />
                  </div>
                  <div className={styles.diligenceQuestionsManagerItemContactsData}>
                    <Skeleton width={200} height={20} />
                  </div>
                  <div className={styles.diligenceQuestionsManagerItemContactsData}>
                    <Skeleton width={150} height={20} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Grid>
    </Grid>
  )
}

export default DueDiligenceClientDashboardLoader
