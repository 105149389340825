import { updateClient } from './../../../redux/clientInfo/actions'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import InsuranceInformation from './InsuranceInformation'
import { clientInfo } from '../../../redux/clientInfo/selectors'

const selector = createStructuredSelector({
  clientInfo,
})
const actions = {
  updateClient,
}
export default connect(selector, actions)(InsuranceInformation)
