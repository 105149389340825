import React, { useCallback, useMemo } from 'react'
import AttachmentDeleteButton from './AttachmentDeleteButton'
import NonImageAttachment from './NonImageAttachment'
import { ReactComponent as PDFDocumentIcon } from '@assets/images/pdf-logo-icon.svg'
import { ReactComponent as ExcelIcon } from '@assets/images/excel-icon.svg'
import { ReactComponent as WordIcon } from '@assets/images/word-icon.svg'

import styles from './Attachments.module.scss'
import { IAttachment } from '@common/interfaces/notes'

interface IProps {
  attachment: IAttachment
  readOnly: boolean
  index: number
  removeAttachment: (index: number) => void
  handleOpenImageModal: (attachment: any) => void
}

const AttachmentItem = ({
  attachment,
  readOnly,
  index,
  removeAttachment,
  handleOpenImageModal,
}: IProps) => {
  const handleClickOpenImageModal = useCallback(() => {
    handleOpenImageModal(attachment)
  }, [handleOpenImageModal, attachment])

  const fileType = useMemo(() => {
    const contentSplitSlash = attachment?.contentType?.split('/')
    const contentSplitPeriod = attachment?.contentType?.split('.')
    const extension =
      contentSplitSlash[0] === 'image'
        ? 'image'
        : contentSplitSlash[1] === 'pdf'
        ? 'pdf'
        : contentSplitPeriod[contentSplitPeriod.length - 1] === 'document'
        ? 'document'
        : 'excel'

    return extension
  }, [attachment?.contentType])

  return (
    <div key={`${attachment.fileName}-${index}`}>
      {fileType === 'image' ? (
        <div className={styles.attachmentUnit}>
          <img
            className={styles.attachmentImage}
            src={attachment.attachmentSrc}
            alt={attachment.fileName}
            onClick={handleClickOpenImageModal}
          />
          {!readOnly && (
            <AttachmentDeleteButton index={index} removeAttachment={removeAttachment} />
          )}
        </div>
      ) : fileType === 'pdf' ? (
        <NonImageAttachment
          attachment={attachment}
          readOnly={readOnly}
          index={index}
          removeAttachment={removeAttachment}
          attachmentIconComponent={<PDFDocumentIcon className={styles.attachmentNonImage} />}
        />
      ) : fileType === 'document' ? (
        <NonImageAttachment
          attachment={attachment}
          readOnly={readOnly}
          index={index}
          removeAttachment={removeAttachment}
          attachmentIconComponent={<WordIcon className={styles.attachmentNonImage} />}
        />
      ) : (
        <NonImageAttachment
          attachment={attachment}
          readOnly={readOnly}
          index={index}
          removeAttachment={removeAttachment}
          attachmentIconComponent={<ExcelIcon className={styles.attachmentNonImage} />}
        />
      )}
    </div>
  )
}

export default AttachmentItem
