import React, { useState, useCallback, useMemo, useEffect } from 'react'
import { useParams } from 'react-router'
import { Link, generatePath } from 'react-router-dom'
import { Form } from 'react-final-form'
import Tooltip from '@mui/material/Tooltip'
import LinkButton from '@mui/material/Link'
import cn from 'classnames'

import styles from './EntityDilutionTable.module.scss'
import genericSs from '@styles/generic.module.scss'

import ActiveToolbar from '../ActiveToolbar'
import TableFooter from '../Common/TableFooter'
import Card from '../Common/Card'
import TableRow from '../Common/TableRow'
import TableCell from '../Common/TableCell'
import Table from '../Common/Table'
import TableHead from '../Common/TableHead'
import TableContainer from '../Common/TableContainer'
import TableBody from '../Common/TableBody'
import { ROUTES } from '../../constants/routes'
import { IEntityDilutionData } from '@common/interfaces/entityInfo'
import { debounceEventHandler, formatPrice, formatPercent } from '../../helpers/helpers'
import TableFiltersRow from '../Common/TableFiltersRow'
import FullscreenModal from '../Common/FullscreenModal'
import { buildFiltersDefaults, buildFiltersValidateSchema } from '../../helpers/filters'
import { ENTITY_DILUTION_LIST_FILTERS_CONFIG, PER_PAGE } from '@common/constants/filters'
import FilterContainer from '../Filters/FilterContainer'
import { ILoadingData } from '../../redux/types'
import TableLoader from '../Common/TableLoader'
import { IDebtorIneligibleCategories } from '@common/interfaces/bbc'
import useTable from '../../hooks/useTable'

interface IProps {
  getEntityDilution: (id: string, params: any) => void
  entityDilutionData: ILoadingData<IEntityDilutionData>
  debtorIneligibleCategories: ILoadingData<IDebtorIneligibleCategories>
}

const filtersValidate = buildFiltersValidateSchema(ENTITY_DILUTION_LIST_FILTERS_CONFIG)
const filtersDefaults = buildFiltersDefaults(ENTITY_DILUTION_LIST_FILTERS_CONFIG)

const EntityDilutionTable = ({
  getEntityDilution,
  entityDilutionData,
  debtorIneligibleCategories,
}: IProps) => {
  const { id } = useParams<{ id: string }>()

  const [isModalShown, setIsModalShown] = useState(false)

  const {
    filters,
    handleFiltersChange,
    handleOrderChange,
    orderBy,
    activeItems,
    activeItem,
    handleSelectRow,
    setActiveItems,
    setActiveItem,
  } = useTable({
    tableId: 'entityDilution',
    filtersDefaults,
    sortDefault: {
      field: 'totalAr',
      direction: 'DESC',
    },
  })

  const { isLoading, entityDilution } = useMemo(
    () => ({
      isLoading: entityDilutionData.isLoading,
      entityDilution: entityDilutionData.data,
    }),
    [entityDilutionData],
  )

  const fetchGetEntityDilution = useCallback(
    (data: any) => {
      const params = {
        ...data,
        filters: {
          ...data.filters,
          id: id,
        },
      }
      getEntityDilution(id, params)
    },
    [getEntityDilution, id],
  )

  const debounceGetEntityDilution = useMemo(
    () => debounceEventHandler(fetchGetEntityDilution, 500),
    [fetchGetEntityDilution],
  )

  useEffect(() => {
    debounceGetEntityDilution({
      page: 0,
      perPage: PER_PAGE,
      filters,
      orderBy: orderBy.field,
      orderDirection: orderBy.direction,
      id: id,
    })
  }, [getEntityDilution, id, orderBy, filters, debounceGetEntityDilution])

  const debtorIneligibleCategoriesOptions = useMemo(
    () =>
      (debtorIneligibleCategories.data?.ineligibleCategories || []).map((ineligibleCategory) => ({
        label: ineligibleCategory,
        value: ineligibleCategory,
      })),
    [debtorIneligibleCategories],
  )

  const filtersConfig = useMemo(
    () =>
      ENTITY_DILUTION_LIST_FILTERS_CONFIG.map((item) => ({
        ...item,
        options:
          item.field === 'clientName'
            ? entityDilution?.data?.map((item) => ({
                label: item.clientName,
                value: item.clientName,
              }))
            : item.field === 'ineligible_category'
            ? debtorIneligibleCategoriesOptions
            : item.options,
      })),
    [entityDilution?.data, debtorIneligibleCategoriesOptions],
  )

  const totalRow = useMemo(
    () =>
      entityDilution?.data
        .filter((_, index) => activeItems.includes(index))
        .reduce(
          (result, row) => {
            result.priorAr += +(row.priorAr || 0)
            result.totalAr += +(row.totalAr || 0)
            result.newInvoices += +(row.newInvoices || 0)
            result.impliedCollections += +(row.impliedCollections || 0)
            result.totalCollections += +(row.totalCollections || 0)

            result.dilution = +(
              result.impliedCollections
                ? (result.totalCollections - result.impliedCollections) / result.totalCollections
                : 0
            ).toFixed(2)

            return result
          },
          {
            totalAr: 0,
            priorAr: 0,
            newInvoices: 0,
            totalCollections: 0,
            impliedCollections: 0,
            dilution: 0,
          },
        ),
    [entityDilution, activeItems],
  )

  return (
    <FullscreenModal
      isOpen={isModalShown}
      setIsOpen={setIsModalShown}
      classes={{ body: styles.fullScreenModal }}
    >
      <Card noHeaderMargin>
        <TableContainer
          className={styles.table}
          onActiveRowsChange={setActiveItems}
          onActiveRowChange={setActiveItem}
          hasFooter
        >
          <Form
            validate={filtersValidate}
            onSubmit={handleFiltersChange}
            initialValues={filters}
            mutators={{
              setFieldData: ([field, value], state, { changeValue }) => {
                changeValue(state, field, () => value)
              },
            }}
            render={({ values, handleSubmit, form: { mutators } }) => (
              <FilterContainer
                filters={filtersConfig}
                handleSubmit={handleSubmit}
                mutators={mutators}
                values={values}
                appliedFilters={filters}
                title="Dilution Analysis"
              />
            )}
          />
          <Table>
            <TableHead>
              <TableFiltersRow
                filters={filtersConfig}
                orderBy={orderBy}
                handleOrderChange={handleOrderChange}
              />
            </TableHead>
            <TableBody id="scrollablePerformanceTable">
              {isLoading ? (
                <TableLoader columnsCount={7} />
              ) : (
                entityDilution?.data.map((item, index) => (
                  <TableRow
                    key={`${item.clientName}`}
                    data-index={index}
                    className={cn('activableRow', {
                      activeRow: activeItems.includes(index),
                      currentActiveRow: activeItem === index,
                    })}
                    onClick={(event) => handleSelectRow(event, index)}
                  >
                    <TableCell className={genericSs.tableTextLeft}>
                      <Tooltip
                        title={item.clientName || ''}
                        placement="top"
                        disableHoverListener={item.clientName?.length < 20 || !item.clientName}
                        disableTouchListener
                      >
                        <LinkButton
                          component={Link}
                          to={generatePath(ROUTES.CLIENT_PAGE, {
                            id: item.id,
                          })}
                        >
                          {item.clientName}
                        </LinkButton>
                      </Tooltip>
                    </TableCell>

                    <TableCell className={genericSs.tableTextRight}>
                      <span className={genericSs.pricePrefix}>$</span>
                      {formatPrice(item.priorAr)}
                    </TableCell>

                    <TableCell className={genericSs.tableTextRight}>
                      <span className={genericSs.pricePrefix}>$</span>
                      {formatPrice(item.totalAr)}
                    </TableCell>
                    <TableCell className={genericSs.tableTextRight}>
                      <span className={genericSs.pricePrefix}>$</span>
                      {formatPrice(item.newInvoices)}
                    </TableCell>

                    <TableCell className={genericSs.tableTextRight}>
                      <span className={genericSs.pricePrefix}>$</span>
                      {formatPrice(item.impliedCollections)}
                    </TableCell>
                    <TableCell className={genericSs.tableTextRight}>
                      <span className={genericSs.pricePrefix}>$</span>
                      {formatPrice(item.totalCollections)}
                    </TableCell>

                    <TableCell className={genericSs.tableTextRight}>
                      {item.dilution && formatPercent(item.dilution)}
                    </TableCell>
                  </TableRow>
                ))
              )}
            </TableBody>
            {isLoading ? (
              <TableFooter>
                <TableLoader columnsCount={7} rowsCount={1} />
              </TableFooter>
            ) : (
              entityDilution?.totals && (
                <TableFooter>
                  <TableRow>
                    <TableCell colSpan={2} className={genericSs.tableTextLeft}>
                      Total
                    </TableCell>

                    <TableCell className={genericSs.tableTextRight}>
                      <span className={genericSs.pricePrefix}>$</span>
                      {formatPrice(entityDilution.totals.priorAr)}
                    </TableCell>
                    <TableCell className={genericSs.tableTextRight}>
                      <span className={genericSs.pricePrefix}>$</span>
                      {formatPrice(entityDilution.totals.totalAr)}
                    </TableCell>
                    <TableCell className={genericSs.tableTextRight}>
                      <span className={genericSs.pricePrefix}>$</span>
                      {formatPrice(entityDilution.totals.newInvoices)}
                    </TableCell>
                    <TableCell className={genericSs.tableTextRight}>
                      <span className={genericSs.pricePrefix}>$</span>
                      {formatPrice(entityDilution.totals.impliedCollections)}
                    </TableCell>
                    <TableCell className={genericSs.tableTextRight}>
                      <span className={genericSs.pricePrefix}>$</span>
                      {formatPrice(entityDilution.totals.totalCollections)}
                    </TableCell>
                    <TableCell className={genericSs.tableTextRight}>
                      {entityDilution.totals.dilution > 0 &&
                        formatPercent(entityDilution.totals.dilution)}
                    </TableCell>
                  </TableRow>
                </TableFooter>
              )
            )}
          </Table>

          <ActiveToolbar
            activeItems={activeItems}
            className={styles.activeToolbar}
            isFullscreen={isModalShown}
          >
            <div className={genericSs.tableTextRight}>{formatPrice(totalRow?.priorAr) || '-'}</div>
            <div className={genericSs.tableTextRight}>{formatPrice(totalRow?.totalAr) || '-'}</div>
            <div className={genericSs.tableTextRight}>
              {formatPrice(totalRow?.newInvoices) || '-'}
            </div>
            <div className={genericSs.tableTextRight}>
              {formatPrice(totalRow?.impliedCollections) || '-'}
            </div>
            <div className={genericSs.tableTextRight}>
              {formatPrice(totalRow?.totalCollections) || '-'}
            </div>
            <div className={genericSs.tableTextRight}>
              {formatPercent(totalRow?.dilution) || '-'}
            </div>
          </ActiveToolbar>
        </TableContainer>
      </Card>
    </FullscreenModal>
  )
}

export default EntityDilutionTable
