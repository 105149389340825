import React, { useCallback, useMemo } from 'react'
import Link from '@mui/material/Link'
import Tooltip from '@mui/material/Tooltip'
import AutoFixHighIcon from '@mui/icons-material/AutoFixHigh'
import cn from 'classnames'
import { Link as RouterLink } from 'react-router-dom'
import styles from './Icons.module.scss'
import genericSs from '@styles/generic.module.scss'
import IconButton from '../IconButton'
import { ReactComponent as SalesforceIcon } from '@assets/images/salesforce-icon.svg'
import { ReactComponent as BoxIcon } from '@assets/images/box-icon.svg'
import { ReactComponent as TableauIcon } from '@assets/images/tableau-icon.svg'
import { ReactComponent as EditMappingIcon } from '@assets/images/edit-mapping-icon.svg'
import { ReactComponent as ExpandIcon } from '@assets/images/modal-expand-icon.svg'
import { ReactComponent as MinimizeIcon } from '@assets/images/modal-minimize-icon.svg'
import { ReactComponent as LinkIcon } from '@assets/images/link-out-icon.svg'
import { ReactComponent as CachedIcon } from '@assets/images/cached-icon.svg'
import { ReactComponent as SourceDataIcon } from '@assets/images/source-data-icon.svg'
import { ReactComponent as PDFDocumentIcon } from '@assets/images/pdf-link-out.svg'
import { ReactComponent as ArrowDownIcon } from '@assets/images/drop-down-icon.svg'
import { ReactComponent as PencilIcon } from '@assets/images/pencil-icon.svg'
import { ReactComponent as Visibility } from '@assets/images/text-visibility-on.svg'
import { ReactComponent as VisibilityOff } from '@assets/images/text-visibility-off.svg'
import { ReactComponent as MinusRectangleIcon } from '@assets/images/minus-rectangle-icon.svg'
import { ReactComponent as PlusCircleIcon } from '@assets/images/plus-circle-icon.svg'
import { handleStopPropagation } from '../../../helpers/helpers'
import { ReactComponent as ClearXIcon } from '@assets/images/close-outline-icon.svg'
import { ReactComponent as MoreIcon } from '@assets/images/more-icon.svg'
import { ReactComponent as GearIcon } from '@assets/images/settings-icon2.svg'
import { ReactComponent as ArrowRightIcon } from '@assets/images/arrow-right-icon.svg'
import { ReactComponent as ReceivedIcon } from '@assets/images/received-icon.svg'
import { ReactComponent as PlusIcon } from '@assets/images/plus-simple-icon.svg'
import { ReactComponent as LoadingIcon } from '@assets/images/loading-icon.svg'
import { ReactComponent as TrashIcon } from '@assets/images/delete-icon.svg'

interface LinkProps {
  link: string
  title?: string
  size?: 'small' | 'large'
  className?: string
  disabled?: boolean
}

export const SalesforceLink = ({ link, title = 'Salesforce', size = 'large' }: LinkProps) => {
  return (
    <Tooltip title={title} placement="top">
      <Link
        className={styles.salesforceLinkIcon}
        href={link}
        target="_blank"
        rel="noopener noreferrer"
      >
        <div
          className={cn(styles.iconWrapper, {
            [styles.iconWrapperSmall]: size === 'small',
          })}
        >
          <SalesforceIcon className={styles.salesforceLinkIcon} />
        </div>
      </Link>
    </Tooltip>
  )
}

export const BoxLink = ({
  link,
  title = '',
  className = '',
  size = 'large',
  disabled = false,
}: LinkProps) => {
  return (
    <Tooltip title={title} placement="top">
      <Link
        className={cn(styles.boxLinkIcon, className, {
          [genericSs.disabled]: disabled,
        })}
        href={link}
        onClick={handleStopPropagation}
        target="_blank"
        rel="noopener noreferrer"
      >
        <div
          className={cn(styles.iconWrapper, {
            [styles.iconWrapperSmall]: size === 'small',
            [genericSs.disabled]: disabled,
          })}
        >
          <BoxIcon className={styles.boxIcon} />
        </div>
      </Link>
    </Tooltip>
  )
}

export const TableauLink = ({ link, title = '', size = 'large' }: LinkProps) => {
  return (
    <Tooltip title={title} placement="top">
      <Link
        className={styles.tableauLinkIcon}
        href={link}
        target="_blank"
        rel="noopener noreferrer"
      >
        <div
          className={cn(styles.iconWrapper, {
            [styles.iconWrapperSmall]: size === 'small',
          })}
        >
          <TableauIcon />
        </div>
      </Link>
    </Tooltip>
  )
}

export const EditMapping = ({
  action,
  title = 'Mapping',
  mappingRequired = false,
  mappingDisabled = false,
  size = 'large',
}: {
  action: () => void
  title?: string
  mappingRequired?: boolean
  mappingDisabled?: boolean
  size?: 'small' | 'large'
}) => {
  const onClick = useCallback(() => {
    if (mappingDisabled) {
      return
    }

    action()
  }, [action, mappingDisabled])

  return (
    <Tooltip title={title} placement="top">
      <div
        className={cn(styles.iconWrapper, {
          [styles.mappingRequired]: mappingRequired && !mappingDisabled,
          [styles.mappingDisabled]: mappingDisabled,
          [styles.iconWrapperSmall]: size === 'small',
        })}
        onClick={onClick}
      >
        <EditMappingIcon />
      </div>
    </Tooltip>
  )
}

export const ExpandAndMinimize = ({
  action,
  isExpanded,
  className,
  size = 'large',
}: {
  action: (event?: any) => void
  isExpanded: boolean
  className?: string
  size?: 'small' | 'large'
}) => {
  const title = useMemo(() => (isExpanded ? 'Minimize' : 'Expand'), [isExpanded])
  return (
    <Tooltip title={title} placement="top">
      <div
        className={cn(styles.iconWrapper, className, {
          [styles.iconWrapperSmall]: size === 'small',
        })}
        onClick={action}
      >
        {isExpanded ? <MinimizeIcon /> : <ExpandIcon />}
      </div>
    </Tooltip>
  )
}

// type where either link or onClick is required
type ExternalLinkProps = {
  link?: string
  onClick?: () => void
  title?: string
  className?: string
  useOnClick?: boolean
  size?: 'small' | 'large'
}

export const ExternalLink = ({
  link,
  title,
  className,
  useOnClick = false,
  size = 'large',
  onClick,
}: ExternalLinkProps) => {
  const handleOnClick = useCallback(
    (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
      if ((link && useOnClick) || onClick) {
        event.stopPropagation()
        event.preventDefault()
        if (onClick) {
          onClick()
        } else {
          window.open(link, '_blank')
        }
      }
    },
    [link, useOnClick, onClick],
  )

  return (
    <Link
      color="primary"
      href={link}
      target="_blank"
      rel="noopener noreferrer"
      className={cn(styles.link, className)}
      onClick={handleOnClick}
    >
      <Tooltip title={title} placement="top">
        <div
          className={cn(styles.iconWrapper, {
            [styles.iconWrapperSmall]: size === 'small',
          })}
        >
          <LinkIcon className={styles.linkIcon} />
        </div>
      </Tooltip>
    </Link>
  )
}

export const RegenerateIcon = ({
  action,
  title = 'Refresh reports',
  isLoading = false,
  size = 'large',
  disabled = false,
}: {
  action: () => void
  title?: string
  isLoading?: boolean
  size?: 'small' | 'large'
  disabled?: boolean
}) => {
  return (
    <div
      className={cn(styles.iconWrapper, {
        [styles.iconWrapperActive]: isLoading,
        [styles.iconWrapperSmall]: size === 'small',
        [genericSs.disabled]: disabled,
      })}
      onClick={action}
    >
      <Tooltip title={title} placement="top">
        <CachedIcon className={cn({ [styles.rotating]: isLoading })} />
      </Tooltip>
    </div>
  )
}

export const EditSourceData = ({
  action,
  error = false,
  size = 'large',
}: {
  action: () => void
  error?: boolean
  size?: 'small' | 'large'
}) => {
  return (
    <Tooltip title="Source data" placement="top">
      <div
        className={cn(styles.iconWrapper, styles.link, {
          [styles.error]: error,
          [styles.iconWrapperSmall]: size === 'small',
        })}
        onClick={action}
      >
        <SourceDataIcon />
      </div>
    </Tooltip>
  )
}

export const PDFIcon = ({
  title = 'View PDF',
  size = 'large',
}: {
  title?: string
  size?: 'small' | 'large'
}) => {
  return (
    <div
      className={cn(styles.iconWrapper, {
        [styles.iconWrapperSmall]: size === 'small',
      })}
    >
      <Tooltip title={title} placement="top">
        <PDFDocumentIcon className={styles.pdfIcon} />
      </Tooltip>
    </div>
  )
}

export const IconWrapper = ({
  title,
  action,
  children,
  size = 'large',
}: {
  title: string
  action: () => void
  children: React.ReactNode
  size?: 'small' | 'large'
}) => {
  return (
    <Tooltip title={title} placement="top">
      <div
        className={cn(styles.iconWrapper, {
          [styles.iconWrapperSmall]: size === 'small',
        })}
        onClick={action}
      >
        {children}
      </div>
    </Tooltip>
  )
}

export const ExpandDetailIcon = ({
  onClick,
  isExpanded,
}: {
  onClick: () => void
  isExpanded: boolean
}) => {
  return (
    <IconButton
      color="primary"
      className={cn(genericSs.expandIcon, {
        [genericSs.expandIconOpen]: isExpanded,
      })}
      onClick={onClick}
      disableFocusRipple
      disableRipple
    >
      <ArrowDownIcon />
    </IconButton>
  )
}

export const EditIcon = ({
  title = 'Edit',
  onClick,
  className = '',
  size = 'large',
  disabled,
}: {
  title?: string
  onClick: () => void
  className?: string
  size?: 'small' | 'large'
  disabled?: boolean
}) => {
  return (
    <div
      className={cn(styles.iconWrapper, className, {
        [styles.iconWrapperSmall]: size === 'small',
        [genericSs.disabled]: disabled,
      })}
      onClick={onClick}
    >
      <Tooltip title={title} placement="top">
        <PencilIcon className={styles.pencilIcon} />
      </Tooltip>
    </div>
  )
}

export const SummarizeButton = ({
  action,
  title = 'Summarize Notes',
  isLoading = false,
  className,
  size = 'large',
}: {
  action: () => void
  title?: string
  isLoading?: boolean
  className?: string
  size?: 'small' | 'large'
}) => {
  return (
    <Tooltip title={title} placement="top">
      <div
        className={cn(styles.iconWrapper, className, {
          [styles.iconWrapperSmall]: size === 'small',
        })}
        onClick={action}
      >
        <AutoFixHighIcon
          className={cn({
            [styles.shaking]: isLoading,
          })}
        />
      </div>
    </Tooltip>
  )
}

export const VisibilityIcon = ({
  action,
  isVisible,
  size = 'large',
}: {
  action: () => void
  isVisible: boolean
  size?: 'small' | 'large'
}) => {
  const title = useMemo(() => (isVisible ? 'Hide' : 'Show'), [isVisible])
  return (
    <Tooltip title={title} placement="top">
      <div
        className={cn(styles.iconWrapper, styles.visibilityIcon, {
          [styles.iconWrapperSmall]: size === 'small',
        })}
        onClick={action}
      >
        {isVisible ? <Visibility /> : <VisibilityOff />}
      </div>
    </Tooltip>
  )
}

export const ShowAndHide = ({
  action,
  isExpanded,
  className,
}: {
  action: (event?: any) => void
  isExpanded: boolean
  className?: string
}) => {
  return (
    <div className={className} onClick={action}>
      {isExpanded ? <MinusRectangleIcon /> : <PlusCircleIcon />}
    </div>
  )
}

export const ClearIcon = ({
  action,
  title = 'Clear',
  isLoading = false,
  size = 'large',
  disabled = false,
}: {
  action: () => void
  title?: string
  isLoading?: boolean
  size?: 'small' | 'large'
  disabled?: boolean
}) => {
  return (
    <Tooltip title={title} placement="top">
      <div
        onClick={action}
        className={cn(styles.iconWrapper, {
          [styles.iconWrapperActive]: isLoading,
          [styles.iconWrapperSmall]: size === 'small',
          [genericSs.disabled]: disabled,
        })}
      >
        <div
          className={cn(styles.clearIcon, {
            [styles.clearIconLoading]: isLoading,
          })}
        >
          <ClearXIcon />
        </div>
      </div>
    </Tooltip>
  )
}

export const CloseIcon = ({
  action,
  title = 'Close',
  isLoading = false,
  size = 'large',
}: {
  action: () => void
  title?: string
  isLoading?: boolean
  size?: 'small' | 'large'
}) => {
  return (
    <Tooltip title={title} placement="top">
      <div
        onClick={action}
        className={cn(styles.iconWrapper, {
          [styles.iconWrapperActive]: isLoading,
          [styles.clearIconLoading]: isLoading,
          [styles.iconWrapperSmall]: size === 'small',
        })}
      >
        <ClearXIcon />
      </div>
    </Tooltip>
  )
}

export const MenuIcon = ({
  className,
  onClick,
  isActive,
  isLoading,
  size = 'large',
  title = 'Action',
}: {
  className?: string
  onClick: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
  isActive?: boolean
  isLoading?: boolean
  size?: 'small' | 'large'
  title?: string
}) => {
  return (
    <IconButton
      className={cn(styles.iconWrapper, styles.iconButtonWrapper, className, {
        [styles.iconWrapperActive]: isActive || isLoading,
        [styles.iconWrapperSmall]: size === 'small',
      })}
      disableRipple
      onClick={onClick}
    >
      <Tooltip title={title} placement="top">
        <MoreIcon />
      </Tooltip>
    </IconButton>
  )
}

export const SettingsIcon = ({
  className,
  link,
  title = 'Settings',
  size = 'large',
}: {
  className?: string
  link?: string
  title?: string
  size?: 'small' | 'large'
}) => {
  return (
    <Link
      className={cn(styles.iconWrapper, styles.iconButtonWrapper, className, {
        [styles.iconWrapperSmall]: size === 'small',
      })}
      component={RouterLink}
      to={link}
    >
      <Tooltip title={title} placement="top">
        <GearIcon className={styles.gearIcon} />
      </Tooltip>
    </Link>
  )
}

export const SendIcon = ({
  isLoading,
  disabled,
  className,
  onClick,
  title = 'Send',
  size = 'large',
}: {
  isLoading?: boolean
  disabled?: boolean
  className?: string
  onClick: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
  title?: string
  size?: 'small' | 'large'
}) => {
  return (
    <IconButton
      disableRipple
      className={cn(styles.iconWrapper, styles.iconButtonWrapper, className, {
        [styles.iconWrapperActive]: isLoading,
        [genericSs.disabled]: disabled,
        [styles.iconWrapperSmall]: size === 'small',
      })}
      onClick={onClick}
      disabled={disabled}
    >
      <Tooltip title={title} placement="top">
        {isLoading ? <LoadingIcon className={styles.rotating} /> : <ArrowRightIcon />}
      </Tooltip>
    </IconButton>
  )
}

export const ReceiveIcon = ({
  isLoading,
  disabled,
  className,
  onClick,
  title = 'Receive',
  size = 'large',
}: {
  isLoading?: boolean
  disabled?: boolean
  className?: string
  onClick: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
  title?: string
  size?: 'small' | 'large'
}) => {
  return (
    <IconButton
      disableRipple
      className={cn(styles.iconWrapper, styles.iconButtonWrapper, className, {
        [styles.iconWrapperActive]: isLoading,
        [genericSs.disabled]: disabled,
        [styles.iconWrapperSmall]: size === 'small',
      })}
      onClick={onClick}
    >
      <Tooltip title={title} placement="top">
        {isLoading ? <LoadingIcon className={styles.rotating} /> : <ReceivedIcon />}
      </Tooltip>
    </IconButton>
  )
}

export const AddIcon = ({
  isLoading,
  disabled,
  className,
  onClick,
  title = 'Add',
  size = 'large',
}: {
  isLoading?: boolean
  disabled?: boolean
  className?: string
  onClick: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
  title?: string
  size?: 'small' | 'large'
}) => {
  return (
    <IconButton
      disableRipple
      className={cn(styles.iconWrapper, styles.iconButtonWrapper, styles.plusIcon, className, {
        [styles.iconWrapperActive]: isLoading,
        [genericSs.disabled]: disabled,
        [styles.iconWrapperSmall]: size === 'small',
      })}
      onClick={onClick}
    >
      <Tooltip title={title} placement="top">
        {isLoading ? <LoadingIcon className={styles.rotating} /> : <PlusIcon />}
      </Tooltip>
    </IconButton>
  )
}

export const DeleteIcon = ({
  isLoading,
  disabled,
  className,
  onClick,
  title = 'Delete',
  size = 'large',
}: {
  isLoading?: boolean
  disabled?: boolean
  className?: string
  onClick: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
  title?: string
  size?: 'small' | 'large'
}) => {
  return (
    <IconButton
      disableRipple
      className={cn(styles.iconWrapper, styles.iconButtonWrapper, className, {
        [styles.iconWrapperActive]: isLoading,
        [genericSs.disabled]: disabled,
        [styles.iconWrapperSmall]: size === 'small',
      })}
      onClick={onClick}
    >
      <Tooltip title={title} placement="top">
        {isLoading ? <LoadingIcon className={styles.rotating} /> : <TrashIcon />}
      </Tooltip>
    </IconButton>
  )
}
