import React, { useMemo } from 'react'
import Skeleton from '@mui/material/Skeleton'

import styles from './TableLoader.module.scss'
import genericSs from '@styles/generic.module.scss'

import { PER_PAGE } from '@common/constants/filters'
import TableCell from '../TableCell'
import TableRow from '../TableRow'

interface IProps {
  columnsCount: number
  rowsCount?: number
  height?: number
}

const TableLoader = ({ columnsCount, rowsCount = PER_PAGE, height = 20 }: IProps) => {
  const columns = useMemo(() => [...Array(columnsCount).keys()], [columnsCount])
  const rows = useMemo(() => [...Array(rowsCount).keys()], [rowsCount])

  return (
    <>
      {rows.map((key) => (
        <TableRow key={key}>
          {columns.map((key) => (
            <TableCell key={key} className={genericSs.tableTextLeft}>
              <Skeleton className={styles.loader} height={height} />
            </TableCell>
          ))}
        </TableRow>
      ))}
    </>
  )
}

export default TableLoader
