import React, { createContext, useContext, useEffect, useState, useCallback } from 'react'

interface TableCellManagerContextType {
  activeCell: HTMLTableCellElement | null
  setActiveCell: (cell: HTMLTableCellElement | null) => void
}

const TableCellManagerContext = createContext<TableCellManagerContextType | undefined>(undefined)

export const TableCellManagerProvider: React.FC = ({ children }) => {
  const [activeCell, setActiveCellState] = useState<HTMLTableCellElement | null>(null)

  const setActiveCell = useCallback((cell: HTMLTableCellElement | null) => {
    setActiveCellState(cell)
  }, [])

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (activeCell) {
        const target = event.target as Element

        // Check if the clicked element is the active cell or its descendant
        if (activeCell.contains(target)) {
          return
        }

        // Check if the clicked element is part of a popper, menu, autocomplete dropdown, or their items
        const isPartOfPopper = target.closest(
          '.MuiAutocomplete-popper, .MuiSelect-select, .MuiPopover-root, [role="listbox"], [role="menu"], .MuiAutocomplete-root, .MuiAutocomplete-option, .MuiMenuItem-root, [role="option"], .commonFilter, .MuiAutocomplete-listbox',
        )
        const isBackdrop = target.classList.contains('MuiBackdrop-root')

        // Check if the clicked element is an input field (for CreatableSelectField)
        const isInputField = target instanceof HTMLInputElement

        if ((!isPartOfPopper && !isInputField) || isBackdrop) {
          setActiveCell(null)
        }
      }
    }

    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [activeCell, setActiveCell])

  return (
    <TableCellManagerContext.Provider value={{ activeCell, setActiveCell }}>
      {children}
    </TableCellManagerContext.Provider>
  )
}

export const useTableCellManager = () => {
  const context = useContext(TableCellManagerContext)

  return (
    context || {
      activeCell: null,
      setActiveCell: () => {},
    }
  )
}
