import React from 'react'

import genericSs from '@styles/generic.module.scss'

import TableRow from '../../Common/TableRow'
import { IParticipation, IParticipationWire } from '@common/interfaces/participant'
import FormattedTableCell from '../../Common/FormattedTableCell'
import TableCell from '../../Common/TableCell'
import ParticipationActivityMenu from './ParticipationActivityMenu'
import { usePermissions } from '../../../helpers/permissionContext'

interface ILoanLedgerWiresTableParticipationRowProps {
  clientId: string
  participationWire: IParticipationWire
  effectiveParticipations: IParticipation[]
}

export const LoanLedgerWiresTableParticipationRow = ({
  participationWire,
  clientId,
  effectiveParticipations,
}: ILoanLedgerWiresTableParticipationRowProps) => {
  const { isParticipant } = usePermissions()

  return (
    <TableRow key={participationWire.id}>
      <FormattedTableCell value={`Participant Wire - ${participationWire.type}`} fieldType="text" />
      <FormattedTableCell
        value={`${participationWire.participant} - ${participationWire.direction}`}
        fieldType="text"
      />
      <FormattedTableCell value={participationWire.amount} fieldType="amount" />
      {!isParticipant ? (
        <TableCell className={genericSs.tableTextRight}>
          <ParticipationActivityMenu
            clientId={clientId}
            participationWire={participationWire}
            effectiveParticipations={effectiveParticipations}
          />
        </TableCell>
      ) : (
        <TableCell />
      )}
    </TableRow>
  )
}
