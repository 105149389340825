import Base from './base'

export default class OutgoingWire extends Base {
  list(data: object) {
    return this.apiClient.get('outgoing-wire', data)
  }

  sent(data: object) {
    return this.apiClient.post(`outgoing-wire`, data)
  }

  delete(id: string) {
    return this.apiClient.delete(`outgoing-wire/${id}`)
  }
}
