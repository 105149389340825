import { combineReducers } from 'redux'
import * as actions from './actions'
import { ADD_OR_REMOVE_MANAGER_REQUEST } from '../clientInfo/actions'
import { IUser } from '@common/interfaces/user'
import { loadingArrayReducer } from '../../helpers/helpers'
import { ILoadingData } from '../../redux/types'
import { createLoadingDataReducer } from '../../helpers/redux'

export interface IUserState {
  loadingArray: string[]
  list: ILoadingData<{ data: IUser[] }>
}

export default combineReducers<IUserState>({
  loadingArray: loadingArrayReducer([actions.prefix]),
  list: createLoadingDataReducer<{ data: IUser[] }>(
    [actions.LIST_REQUEST],
    [
      actions.CREATE_REQUEST,
      actions.UPDATE_REQUEST,
      actions.DELETE_REQUEST,
      ADD_OR_REMOVE_MANAGER_REQUEST,
      actions.INVITE_REQUEST,
    ],
    true,
  ),
})
