import FlagDetailSidePanel from './FlagDetailSidePanel'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import { flagDetails } from '../../../redux/flag/selectors'
import {
  getFlagDetailsDuplicateInvoices,
  getFlagDetailPaidPriorityInvoices,
  getFlagDetailsPendingCashDominion,
  getFlagDetails,
} from '../../../redux/flag/actions'

const selector = createStructuredSelector({
  flagDetailsData: flagDetails,
})
const actions = {
  getFlagDetailsDuplicateInvoices,
  getFlagDetailPaidPriorityInvoices,
  getFlagDetailsPendingCashDominion,
  getFlagDetails,
}

export default connect(selector, actions)(FlagDetailSidePanel)
