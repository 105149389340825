import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'

import BDOHomepage from './BDOHomepage'

import { isBDO } from '../../redux/profile/selectors'

const selector = createStructuredSelector({
  isBDO,
})

export default connect(selector)(BDOHomepage)
