import React from 'react'

import { DueDiligenceTableItemType, IDueDiligenceExecutive } from '@common/interfaces/dueDiligence'
import DueDiligenceApplicationTable from '../DueDiligenceApplicationTable'
import { ILoadingData } from '../../redux/types'

interface IProps {
  dueDiligenceTeamExecutives: ILoadingData<{ data: IDueDiligenceExecutive[] }>
  listTeamExecutives: (params: object) => void
  hideTeamExecutives: () => void
}

const DueDiligenceApplicationTableExecutives = ({
  dueDiligenceTeamExecutives,
  listTeamExecutives,
  hideTeamExecutives,
}: IProps) => {
  return (
    <DueDiligenceApplicationTable
      type={DueDiligenceTableItemType.Executive}
      data={dueDiligenceTeamExecutives}
      handleList={listTeamExecutives}
      handleHide={hideTeamExecutives}
    />
  )
}

export default DueDiligenceApplicationTableExecutives
