import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'

import { listInventoryMapping, updateInventoryMapping } from '../../redux/ongoingReporting/actions'
import { skuDetails } from '../../redux/ongoingReporting/selectors'
import InvetoryMappingTable from './InvetoryMappingTable'

const selector = createStructuredSelector({
  skuDetails,
})

const actions = {
  listInventoryMapping,
  updateInventoryMapping,
}

export default connect(selector, actions)(InvetoryMappingTable)
