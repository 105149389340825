import React from 'react'

import { UserRole } from '@common/interfaces/user'
import RequestFundingPage from '../RequestFundingPage'
import PortfolioManagerDashboard from '../../components/PortfolioManagerDashboard'
import BDOHomepage from '../BDOHomepage'
import UWDashboard from '../../components/UWDashboard'
import DueDiligenceClientDashboard from '../../components/DueDiligenceClientDashboard'
import ClientDashboard from '../../components/ClientDashboard'

interface IProps {
  role: string
  isPastClient?: boolean
  isDueDiligenceClient?: boolean
}

const DashboardPage: React.FC<IProps> = ({ role, isPastClient, isDueDiligenceClient }) => {
  let Component
  switch (role) {
    case UserRole.CLIENT_USER:
      if (isPastClient) {
        Component = ClientDashboard
      } else if (isDueDiligenceClient) {
        Component = DueDiligenceClientDashboard
      } else {
        Component = RequestFundingPage
      }
      break
    case UserRole.ADMIN:
    case UserRole.PARTICIPANT_USER:
    case UserRole.PORTFOLIO_ADMIN:
    case UserRole.PORTFOLIO_USER:
      Component = PortfolioManagerDashboard
      break
    case UserRole.BDO:
      Component = BDOHomepage
      break
    case UserRole.UW_USER:
      Component = UWDashboard

      break
    default:
      return null
  }

  return <Component />
}

export default DashboardPage
