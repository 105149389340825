import { connect } from 'react-redux'
import LoanCommitteeReportInventoryPage from './LoanCommitteeReportInventoryPage'
import { dueDiligenceInfo } from '../../redux/dueDiligence/selectors'
import { createStructuredSelector } from 'reselect'

const selectors = createStructuredSelector({
  dueDiligenceInfo,
})

export default connect(selectors, null)(LoanCommitteeReportInventoryPage)
