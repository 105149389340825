import React, { useCallback, useMemo, useRef } from 'react'
import Box from '@mui/material/Box'
import { Form, FormRenderProps } from 'react-final-form'
import * as Yup from 'yup'
import moment from 'moment'
import { FormApi } from 'final-form'
import { makeValidate } from 'mui-rff'
import cn from 'classnames'

import styles from './../ClientAmendments.module.scss'
import genericSs from '@styles/generic.module.scss'

import Button from '../../../Common/Button'
import InputLabel from '../../../Common/InputLabel'
import KeyboardDatePicker from '../../../Common/KeyboardDatePicker'
import Modal from '../../../Common/Modal'
import TextField from '../../../Common/TextField'
import { DATE_FORMAT } from '../../../../constants/common'
import { IAmendment } from '@common/interfaces/client'
import Checkbox from '../../../Common/Checkbox'

const today = moment().startOf('day').toDate()

const INITIAL_VALUES: any = {
  summaryOfChanges: '',
  effectiveDate: today,
  restartTerm: false,
  boxLink: null,
}

const ClientAmendmentCreateForm = ({
  pristine,
  invalid,
  submitting,
  handleSubmit,
  form,
  values,
  isLoading,
  loanBalanceStartDate,
  formRef,
}: FormRenderProps<any> & {
  isLoading: boolean
  loanBalanceStartDate: string
  formRef?: React.MutableRefObject<FormApi<any, Partial<any>>>
}) => {
  formRef.current = form

  const minDate = useMemo(
    () => moment(loanBalanceStartDate || undefined).toDate(),
    [loanBalanceStartDate],
  )

  const handleRestartTermChange = useCallback(
    (event) => {
      form.change('restartTerm', event.target.checked)
    },
    [form],
  )

  return (
    <form onSubmit={handleSubmit}>
      <Box display="flex" flexDirection="column" gap={3}>
        <Box flex={1}>
          <InputLabel htmlFor="summaryOfChanges" className={genericSs.textLeft}>
            Summary of Changes
          </InputLabel>
          <TextField
            className={styles.textFormField}
            id="summaryOfChanges"
            name="summaryOfChanges"
            placeholder="Summary of Changes"
          />
        </Box>

        <Box flex={1}>
          <InputLabel htmlFor="effectiveDate" className={genericSs.textLeft}>
            Effective Date
          </InputLabel>
          <KeyboardDatePicker
            className={styles.dateFormField}
            name="effectiveDate"
            inputFormat={DATE_FORMAT}
            placeholder="Select date"
            minDate={minDate}
          />
        </Box>

        <Box flex={1}>
          <InputLabel htmlFor="restartTerm" className={genericSs.textLeft}>
            Restart term?
          </InputLabel>
          <Box className={genericSs.textLeft}>
            <Checkbox
              className={styles.restartTerm}
              name="restartTerm"
              checked={values.restartTerm}
              color="primary"
              onChange={handleRestartTermChange}
            />
          </Box>
        </Box>

        <Box flex={1}>
          <Box mb={1}>
            <InputLabel htmlFor="boxLink" className={genericSs.textLeft}>
              Is this a legal amendment?
            </InputLabel>
          </Box>
          <InputLabel htmlFor="boxLink" className={cn(genericSs.textLeft, genericSs.textRegular)}>
            Amendment Box Link
          </InputLabel>
          <TextField
            className={styles.textFormField}
            id="boxLink"
            name="boxLink"
            placeholder="Amendment box link"
            InputProps={{
              type: 'url',
            }}
          />
        </Box>
      </Box>

      <Box mt={5}>
        <Button
          type="submit"
          fullWidth
          small={false}
          key="submit"
          color="primary"
          variant="contained"
          onClick={handleSubmit}
          disabled={pristine || invalid || submitting}
          isLoading={isLoading || submitting}
        >
          Apply
        </Button>
      </Box>
    </form>
  )
}

interface IProps {
  isLoading: boolean
  handleClose: () => void
  createAmendment: (data: IAmendment) => void
  loanBalanceStartDate: string
}

const ClientAmendmentCreateModal = ({
  isLoading,
  handleClose,
  createAmendment,
  loanBalanceStartDate,
}: IProps) => {
  const formRef: React.MutableRefObject<FormApi<any, Partial<any>>> = useRef(null)

  const handleSubmit = useCallback(
    (values: any) => {
      createAmendment(values)
    },
    [createAmendment],
  )

  const validate = useMemo(() => {
    const minDate = moment(loanBalanceStartDate || undefined).toDate()

    const schema = Yup.object().shape({
      summaryOfChanges: Yup.string().required('Required'),
      effectiveDate: Yup.date()
        .typeError('Please type date in MM/DD/YY format')
        .required('Required')
        .min(minDate, 'Cannot set effective date for a locked month'),
      boxLink: Yup.string().nullable(),
    })
    return makeValidate(schema)
  }, [loanBalanceStartDate])

  return (
    <Modal open onCancel={handleClose} title="Add Amendment" size="small">
      <Form
        validate={validate}
        initialValues={INITIAL_VALUES}
        onSubmit={handleSubmit}
        render={(formProps) => (
          <ClientAmendmentCreateForm
            {...formProps}
            isLoading={isLoading}
            loanBalanceStartDate={loanBalanceStartDate}
            formRef={formRef}
          />
        )}
      />
    </Modal>
  )
}

export default ClientAmendmentCreateModal
