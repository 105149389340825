import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import LoanCommitteeReportReceivablesPage from './LoanCommitteeReportReceivablesPage'
import { dueDiligenceInfo } from '../../redux/dueDiligence/selectors'
import { listDilutionDropdowns } from '../../redux/generalLedger/actions'
const selector = createStructuredSelector({
  dueDiligenceInfo,
})

const actions = {
  listDilutionDropdowns,
}

export default connect(selector, actions)(LoanCommitteeReportReceivablesPage)
