import { useMemo } from 'react'

import {
  IInventoryIneligibilityCustomRule,
  IInventoryIneligibilityField,
} from '@common/interfaces/bbc'
import { IClientInfo } from '@common/interfaces/client'
import { ILoadingData } from '../../../redux/types'
import InventoryEligibleCustomRuleTable from './InventoryEligibleCustomRuleTable'

interface IProps {
  clientInfo: IClientInfo
  fields: ILoadingData<{ data: IInventoryIneligibilityField[] }>
  customRules: ILoadingData<IInventoryIneligibilityCustomRule[]>
  customRulesValues: { [key: string]: string[] }
  listCustomRules: (clientId: string, data?: object) => void
  createCustomRules: (data: object) => void
  updateCustomRule: (id: string, data: object) => void
  deleteCustomRule: (id: string) => void
  listCustomRulesLabels: () => void
  createCustomRulesLabel: (data: { label: string }) => void
  listCustomRulesValues: (data: { clientName: string; field: string }) => void
}

const InventoryEligibleCustomRules = ({
  clientInfo,
  fields,
  customRules,
  customRulesValues,
  listCustomRules,
  createCustomRules,
  updateCustomRule,
  deleteCustomRule,
  listCustomRulesLabels,
  createCustomRulesLabel,
  listCustomRulesValues,
}: IProps) => {
  const clientName = useMemo(() => clientInfo?.clientName, [clientInfo])
  const clientId = useMemo(() => clientInfo?.id, [clientInfo])

  return InventoryEligibleCustomRuleTable({
    clientId,
    clientName,
    fields,
    customRules,
    customRulesValues,
    listCustomRules,
    createCustomRules,
    updateCustomRule,
    deleteCustomRule,
    listCustomRulesLabels,
    createCustomRulesLabel,
    listCustomRulesValues,
  })
}

export default InventoryEligibleCustomRules
