import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'

import DueDiligenceApplicationTableExecutives from './DueDiligenceApplicationTableExecutives'

import { dueDiligenceTeamExecutives } from '../../redux/dueDiligence/selectors'
import { listTeamExecutives, hideTeamExecutives } from '../../redux/dueDiligence/actions'

const selector = createStructuredSelector({
  dueDiligenceTeamExecutives,
})
const actions = {
  listTeamExecutives,
  hideTeamExecutives,
}

export default connect(selector, actions)(DueDiligenceApplicationTableExecutives)
