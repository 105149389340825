import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'

import DueDiligenceProcessDocumentsFinancialsPage from './DueDiligenceProcessDocumentsFinancialsPage'

import { dueDiligenceInfo, dueDiligenceReportingFlows } from '../../redux/dueDiligence/selectors'
import {
  show,
  showDocuments,
  processDocuments,
  updateDocument,
} from '../../redux/dueDiligence/actions'
import { dataConsolidated, dataSummary } from '../../redux/ongoingReporting/selectors'
import { isSidebarOpen } from '../../redux/profile/selectors'
import { isNotesShown } from '../../redux/notes/selectors'

const selector = createStructuredSelector({
  isSidebarOpen,
  dueDiligenceInfo,
  dueDiligenceReportingFlowsData: dueDiligenceReportingFlows,
  dataConsolidated,
  dataSummary,
  isNotesShown,
})
const actions = {
  show,
  showDocuments,
  processDocuments,
  updateDocument,
}

export default connect(selector, actions)(DueDiligenceProcessDocumentsFinancialsPage)
