import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'

import OverdueReportingBanner from './OverdueReportingBanner'
import { user } from '../../redux/profile/selectors'
import { clientInfo } from '../../redux/profile/selectors'

const selector = createStructuredSelector({
  user,
  clientInfo,
})

export default connect(selector)(OverdueReportingBanner)
