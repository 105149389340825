import React from 'react'
import cn from 'classnames'

import styles from './TableFields.module.scss'

interface IProps {
  rows: any[][]
  classes?: { [key: string]: string }
}

const TableFields = ({ rows, classes = {} }: IProps) => {
  return (
    <table className={cn(styles.table, classes.root)}>
      {rows?.map((row, rowIndex) => (
        <tr key={rowIndex}>
          {row?.map((column, columnIndex) => (
            <td key={columnIndex} className={styles.column}>
              {column}
            </td>
          ))}
        </tr>
      ))}
    </table>
  )
}

export default TableFields
