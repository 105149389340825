import React, { useContext, useEffect, useRef, useMemo } from 'react'
import { TextFieldProps } from 'mui-rff'
import { NumericFormat } from 'react-number-format'
import styles from './CurrencyField.module.scss'
import { ReactComponent as DollarIcon } from '@assets/images/dollar-icon.svg'
import TextField from '../TextField'
import cn from 'classnames'
import InputAdornment from '@mui/material/InputAdornment'
import { TableCellContext } from '../TableCell/TableCell'

function CurrencyFieldComponent(props: any) {
  const { onChange, ...other } = props
  const inputRef = useRef<HTMLInputElement>(null)

  const context = useContext(TableCellContext)
  const isActive = useMemo(() => context?.isActive, [context])
  useEffect(() => {
    if (isActive) {
      inputRef.current?.focus()
    }
  }, [isActive, inputRef])

  return (
    <NumericFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        })
      }}
      thousandSeparator
      valueIsNumericString
    />
  )
}

interface IProps extends Omit<TextFieldProps, 'size'> {
  size?: 'small' | 'medium' | 'large'
  useFinalForm?: boolean
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void
  value?: any
  inTable?: boolean
}

const CurrencyField = ({ InputProps, ...props }: IProps) => {
  return (
    <TextField
      size={props.size || 'small'}
      type="amount"
      {...props}
      InputProps={{
        ...InputProps,
        className: styles.input,
        startAdornment: (
          <InputAdornment position="start">
            <DollarIcon
              className={cn(styles.adornment, {
                [styles.adornmentSmallSize]: props.size === 'small',
              })}
            />
          </InputAdornment>
        ),
        inputComponent: CurrencyFieldComponent,
      }}
    />
  )
}

export default CurrencyField
