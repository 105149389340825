import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'

import DueDiligenceTeamReferences from './DueDiligenceTeamReferences'

import { dueDiligenceTeamReferences } from '../../redux/dueDiligence/selectors'
import {
  listTeamReferences,
  hideTeamReferences,
  addTeamReference,
  updateTeamReference,
  deleteTeamReference,
} from '../../redux/dueDiligence/actions'

const selector = createStructuredSelector({
  dueDiligenceTeamReferences,
})
const actions = {
  listTeamReferences,
  hideTeamReferences,
  addTeamReference,
  updateTeamReference,
  deleteTeamReference,
}

export default connect(selector, actions)(DueDiligenceTeamReferences)
