import React, { useCallback, useMemo, useState } from 'react'
import { generatePath, Link as RouterLink, useParams } from 'react-router-dom'
import Link from '@mui/material/Link'
import MenuItem from '@mui/material/MenuItem'
import Menu from '@mui/material/Menu'
import CircularProgress from '@mui/material/CircularProgress'
import {
  IDueDiligence,
  OPPORTUNITY_SALESFORCE_ARCHIVE_REASONS,
  DueDiligenceHeaderMenuPages,
} from '@common/interfaces/dueDiligence'
import { ROUTES } from '../../constants/routes'
import styles from './DueDiligenceHeaderMenu.module.scss'
import { checkIsLoanStructureComplete } from '@common/helpers/helpers'
import { BoxLink, MenuIcon, SalesforceLink } from '../Common/Icons'
import ArchivedChip from '../Client/ArchivedChip'
import CodatSyncChip from '../Client/CodatSyncChip'
import WarningModal from '../WarningModal'
import TextField from '../Common/TextField'
import SelectField from '../Common/SelectField'
import Modal from '../Common/Modal'
import Button from '../Common/Button'
import Box from '@mui/material/Box'
import { ClientInfoStatus } from '@common/interfaces/client'

interface DiligenceHeaderMenuProps {
  isAdminRightsRole: boolean
  dueDiligenceInfo: IDueDiligence
  runBBC: (id: string) => Promise<void>
  loginAsClient: (clientId: string) => void
  update: (id: string, data: object) => void
  page: DueDiligenceHeaderMenuPages
  currentProcessDocumentsLink: string
}

const DueDiligenceHeaderMenu = ({
  isAdminRightsRole,
  dueDiligenceInfo,
  runBBC,
  loginAsClient,
  update,
  page,
  currentProcessDocumentsLink,
}: DiligenceHeaderMenuProps) => {
  const { id } = useParams<{ id: string }>()

  const [isRunningBBC, setIsRunningBBC] = useState(false)
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null)
  const [isConvertToArchivedModalShown, setIsConvertToArchivedModalShown] = useState(false)
  const [isArchiveReasonModalShown, setIsArchiveReasonModalShown] = useState(false)
  const [archiveReason, setArchiveReason] = useState(null)
  const [reasonLost, setReasonLost] = useState(null)
  const [isSaving, setIsSaving] = useState(false)

  const isActionsMenuOpen = useMemo(() => Boolean(anchorEl), [anchorEl])
  const readOnly = useMemo(
    () => dueDiligenceInfo?.clientStatus !== ClientInfoStatus.DueDiligence,
    [dueDiligenceInfo],
  )
  const isArchived = useMemo(
    () => dueDiligenceInfo?.clientStatus === ClientInfoStatus.Archived,
    [dueDiligenceInfo],
  )

  const isLoanStructureComplete = useMemo(() => {
    return checkIsLoanStructureComplete(dueDiligenceInfo)
  }, [dueDiligenceInfo])

  const handleClickMenu = useCallback((event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }, [])
  const handleCloseMenu = useCallback(() => {
    if (isRunningBBC) {
      return
    }
    setAnchorEl(null)
  }, [isRunningBBC])

  const handleRunBBC = useCallback(async () => {
    setIsRunningBBC(true)
    await runBBC(id)
    setIsRunningBBC(false)
    handleCloseMenu()
  }, [id, runBBC, handleCloseMenu])

  const handleUpdateToArchived = useCallback(
    async (data: any) => {
      setIsSaving(true)
      await update(id, data)
      setIsSaving(false)
    },
    [update, id],
  )

  const handleLoginAsClient = useCallback(() => {
    if (isAdminRightsRole) {
      loginAsClient(id)
    }
  }, [id, loginAsClient, isAdminRightsRole])

  const toggleArchiveReasonModalShown = useCallback(() => {
    setIsArchiveReasonModalShown(true)
    handleCloseMenu()
  }, [handleCloseMenu, setIsArchiveReasonModalShown])

  const toggleArchiveReasonModalCancel = useCallback(() => {
    setArchiveReason(null)
    setIsArchiveReasonModalShown(false)
  }, [setIsArchiveReasonModalShown])

  const toggleConvertToArchiveModalShown = useCallback(() => {
    setIsConvertToArchivedModalShown(true)
    setIsArchiveReasonModalShown(false)
    handleCloseMenu()
  }, [handleCloseMenu, setIsConvertToArchivedModalShown])

  const handleConvertToArchiveCancel = useCallback(() => {
    setIsConvertToArchivedModalShown(false)
    toggleArchiveReasonModalCancel()
  }, [setIsConvertToArchivedModalShown, toggleArchiveReasonModalCancel])

  const handleChangeArchiveReason = useCallback(
    ({ target: { value } }) => {
      setArchiveReason(value)
    },
    [setArchiveReason],
  )

  const handleChangeReasonLostText = useCallback(
    ({ target: { value } }) => {
      setReasonLost(value)
    },
    [setReasonLost],
  )

  const handleArchive = useCallback(() => {
    toggleArchiveReasonModalShown()
    handleCloseMenu()
  }, [handleCloseMenu, toggleArchiveReasonModalShown])

  const handleRestore = useCallback(() => {
    setIsConvertToArchivedModalShown(true)
    setIsArchiveReasonModalShown(false)
    handleCloseMenu()
  }, [handleCloseMenu, setIsConvertToArchivedModalShown])

  const handleConvertToArchivedConfirm = useCallback(async () => {
    await handleUpdateToArchived({
      status: isArchived ? ClientInfoStatus.DueDiligence : ClientInfoStatus.Archived,
      archiveReason,
      reasonLost,
    })
    toggleArchiveReasonModalCancel()
    setReasonLost(null)
    setIsConvertToArchivedModalShown(false)
  }, [
    handleUpdateToArchived,
    isArchived,
    toggleArchiveReasonModalCancel,
    archiveReason,
    reasonLost,
  ])

  const { lcrLink, clientSetupLink, opsReportingLink } = useMemo(() => {
    return {
      lcrLink: generatePath(ROUTES.DUE_DILIGENCE_LOAN_COMMITTEE_REPORT_PAGE, { id }),
      clientSetupLink: generatePath(ROUTES.CLIENT_SETUP, { id }),
      opsReportingLink: dueDiligenceInfo?.opsReportings?.[0]?.id
        ? generatePath(ROUTES.PROSPECT_PAGE, {
            id: dueDiligenceInfo?.opsReportings?.[0]?.id,
          })
        : null,
    }
  }, [id, dueDiligenceInfo])
  const showRestoreOptions = useMemo(
    () => page === DueDiligenceHeaderMenuPages.DiligenceProfile,
    [page],
  )

  const boxLink = useMemo(() => {
    if (page === DueDiligenceHeaderMenuPages.ProcessDocuments) {
      return currentProcessDocumentsLink
    }
    return dueDiligenceInfo?.salesforceDDInfo?.boxLink
  }, [page, currentProcessDocumentsLink, dueDiligenceInfo])

  return (
    <>
      <ArchivedChip clientInfo={dueDiligenceInfo} />
      <CodatSyncChip clientInfo={dueDiligenceInfo} />
      <BoxLink disabled={!boxLink} link={boxLink} />
      <SalesforceLink link={dueDiligenceInfo?.salesforceDDInfo?.salesforceLink} />
      <MenuIcon isActive={isActionsMenuOpen} onClick={handleClickMenu} />
      <Menu open={isActionsMenuOpen} anchorEl={anchorEl} onClose={handleCloseMenu}>
        {page !== DueDiligenceHeaderMenuPages.LCR && (
          <Link className={styles.menuItemLink} component={RouterLink} to={lcrLink}>
            <MenuItem onClick={handleCloseMenu}>LCR</MenuItem>
          </Link>
        )}
        {isAdminRightsRole && <MenuItem onClick={handleLoginAsClient}>Prospect View</MenuItem>}
        <Link className={styles.menuItemLink} component={RouterLink} to={clientSetupLink}>
          <MenuItem onClick={handleCloseMenu}>Client setup</MenuItem>
        </Link>
        {opsReportingLink && (
          <Link className={styles.menuItemLink} component={RouterLink} to={opsReportingLink}>
            <MenuItem onClick={handleCloseMenu}>View OPS</MenuItem>
          </Link>
        )}
        {showRestoreOptions && (
          <>
            <MenuItem disabled={readOnly || !isLoanStructureComplete} onClick={handleRunBBC}>
              {isRunningBBC ? <CircularProgress size={20} /> : 'Run BBC'}
            </MenuItem>
            {!readOnly && <MenuItem onClick={handleArchive}>Archive</MenuItem>}
            {isArchived && <MenuItem onClick={handleRestore}>Restore</MenuItem>}
          </>
        )}
      </Menu>
      {isConvertToArchivedModalShown && (
        <WarningModal
          onCancel={handleConvertToArchiveCancel}
          warningMessage={`Are you sure you want to ${
            isArchived ? 'restore' : 'archive'
          } this prospect?`}
          onConfirm={handleConvertToArchivedConfirm}
          isLoading={isSaving}
        />
      )}
      {isArchiveReasonModalShown && (
        <Modal
          open={isArchiveReasonModalShown}
          onCancel={toggleArchiveReasonModalCancel}
          classes={{ root: styles.modalRoot }}
          title="Archive Prospect"
          footer={[
            <Button
              key="cancel"
              color="primary"
              variant="contained"
              small={false}
              onClick={toggleArchiveReasonModalCancel}
              secondary
            >
              Cancel
            </Button>,
            <Button
              isLoading={isSaving}
              key="submit"
              color="primary"
              variant="contained"
              small={false}
              onClick={toggleConvertToArchiveModalShown}
              disabled={!archiveReason || !reasonLost}
            >
              Continue
            </Button>,
          ]}
        >
          <Box className={styles.fieldsContainer}>
            <SelectField
              name="archiveReason"
              useFinalForm={false}
              options={OPPORTUNITY_SALESFORCE_ARCHIVE_REASONS}
              onChange={handleChangeArchiveReason}
              value={archiveReason}
              disabled={readOnly}
              selectSize="large"
              border
              placeholder="Select a reason"
            />
            <TextField
              name="reasonLost"
              className={styles.textFormField}
              useFinalForm={false}
              onChange={handleChangeReasonLostText}
              value={reasonLost}
              disabled={readOnly}
              multiline
              rows={3}
              placeholder="Please explain why this opportunity was lost"
            />
          </Box>
        </Modal>
      )}
    </>
  )
}

export default DueDiligenceHeaderMenu
