import React, { useMemo, useState, useEffect, useCallback } from 'react'
import { NavLink, Link as RouterLink } from 'react-router-dom'
import { AppBar } from '@mui/material'
import Grid from '@mui/material/Grid'
import Link from '@mui/material/Link'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import classnames from 'classnames'

import styles from './Header.module.scss'

import { ROUTES } from '../../constants/routes'
import { ReactComponent as NotesIcon } from '@assets/images/notes-icon.svg'
import { ReactComponent as ArrowDropDownIcon } from '@assets/images/drop-down-icon.svg'
import GlobalSearch from '../../components/GlobalSearch'
import { IUser } from '@common/interfaces/user'
import { UserRole } from '@common/interfaces/user'
import { titles } from './titles'
import NotificationPane from '../NotificationPane'
import Avatar from '../Common/Avatar'
import logo from '../../assets/images/logo2.png'
import { IClientInfo } from '@common/interfaces/client'
import { usePermissions } from '../../helpers/permissionContext'

interface IProps {
  toggleNotes: () => void
  user: IUser
  isBlankLayout: boolean
  workflow: string
  role: string
  pathname: string
  isSidebarOpen: boolean
  title: string
  onLogout: () => void
  isDueDiligenceClient: boolean
  isAdminLoggedAsClient: boolean
  clientInfo?: IClientInfo
}

const HeaderUserActions = ({
  isOpen,
  anchorEl,
  handleCloseMenu,
  handleLogout,
}: {
  isOpen: boolean
  anchorEl: any
  handleCloseMenu: () => void
  handleLogout: () => void
}) => {
  const handleNavigateToSettings = useCallback(() => {
    handleCloseMenu()
  }, [handleCloseMenu])

  return (
    <Menu
      classes={{
        paper: styles.actionsMenu,
      }}
      open={isOpen}
      anchorEl={anchorEl}
      onClose={handleCloseMenu}
      transformOrigin={{
        vertical: -10,
        horizontal: 'left',
      }}
    >
      <Link className={styles.actionsMenuItemLink} component={RouterLink} to={ROUTES.SETTINGS}>
        <MenuItem classes={{ root: styles.actionsMenuItem }} onClick={handleNavigateToSettings}>
          Settings
        </MenuItem>
      </Link>
      <MenuItem classes={{ root: styles.actionsMenuItem }} onClick={handleLogout}>
        Log out
      </MenuItem>
    </Menu>
  )
}

const Header = ({
  toggleNotes,
  user,
  isBlankLayout,
  workflow,
  role,
  pathname,
  isSidebarOpen,
  title,
  onLogout,
  isDueDiligenceClient,
  isAdminLoggedAsClient,
  clientInfo,
}: IProps) => {
  const [pageTitle, setPageTitle] = useState('')
  const [anchorEl, setAnchorEl] = useState(null)
  const isActionsMenuOpen = useMemo(() => Boolean(anchorEl), [anchorEl])

  const { isParticipant } = usePermissions()

  useEffect(() => {
    if (titles?.[pathname]) {
      setPageTitle(titles?.[pathname])
      document.title = titles?.[pathname]
    } else if (titles?.[pathname.split('/')[1]]) {
      setPageTitle(titles?.[pathname.split('/')[1]])
      document.title = titles?.[pathname.split('/')[1]]
    } else {
      setPageTitle(title)
    }
  }, [pathname, title])

  const isNotesAndSearchUser = useMemo(
    () => ![UserRole.CLIENT_USER].includes(role as UserRole),
    [role],
  )

  const isNotificationsAvailable = useMemo(
    () => ![UserRole.CLIENT_USER].includes(role as UserRole),
    [role],
  )

  const handleLogout = useCallback(() => {
    onLogout()
  }, [onLogout])

  const handleClickMenu = useCallback((event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }, [])
  const handleCloseMenu = useCallback(() => {
    setAnchorEl(null)
  }, [])

  const userName = useMemo(
    () => (isAdminLoggedAsClient ? clientInfo?.clientName || '' : user?.firstName),
    [isAdminLoggedAsClient, user, clientInfo],
  )

  if (!user) {
    return null
  }

  if (role === UserRole.CLIENT_USER && isDueDiligenceClient) {
    return (
      <AppBar
        className={classnames(styles.appBar, {
          [styles.loggedAsClientAppBar]: isAdminLoggedAsClient,
        })}
      >
        <Grid
          container
          item
          xs={12}
          className={styles.appBarContainer}
          alignItems={'center'}
          justifyContent={'center'}
        >
          <Grid className={styles.prospectTopBar} item xs={8} display="flex" alignItems="center">
            <img className={styles.prospectTopBarLogo} src={logo} alt="Dwight Funding" />
            <div className={styles.prospectTopBarDivider} />
            <div className={styles.prospectTopBarMenu}>
              <NavLink
                to={ROUTES.HOMEPAGE}
                className={styles.prospectTopBarMenuItem}
                activeClassName={styles.prospectTopBarMenuItemActive}
                exact
              >
                Status & progress
              </NavLink>
              <NavLink
                to={ROUTES.DOCUMENT_REQUESTS}
                className={styles.prospectTopBarMenuItem}
                activeClassName={styles.prospectTopBarMenuItemActive}
                exact
              >
                Document requests
              </NavLink>
            </div>
          </Grid>

          <Grid item xs={4} display="flex" alignItems="center" justifyContent={'flex-end'}>
            {!isAdminLoggedAsClient && <NotificationPane />}
            <div className={styles.user} onClick={handleClickMenu}>
              <Avatar user={user} clientInfo={isAdminLoggedAsClient ? clientInfo : null} />
              <span className={styles.userName}>Hello, {userName}</span>
              {!isAdminLoggedAsClient && (
                <ArrowDropDownIcon
                  className={classnames({
                    [styles.rotate]: anchorEl,
                  })}
                />
              )}
            </div>

            {!isAdminLoggedAsClient && (
              <HeaderUserActions
                isOpen={isActionsMenuOpen}
                anchorEl={anchorEl}
                handleCloseMenu={handleCloseMenu}
                handleLogout={handleLogout}
              />
            )}
          </Grid>
        </Grid>
      </AppBar>
    )
  }

  return (
    <AppBar
      className={classnames(styles.appBar, {
        [styles.loggedAsClientAppBar]: isAdminLoggedAsClient,
      })}
    >
      <Grid
        container
        item
        xs={12}
        className={styles.appBarContainer}
        alignItems={'center'}
        justifyContent={'center'}
      >
        <Grid item xs={4}>
          <div
            className={classnames(styles.title, {
              [styles.titleExpanded]: isSidebarOpen,
            })}
            id="pageTitle"
          >
            {pageTitle}
          </div>
        </Grid>
        <Grid item xs={4} justifyContent={'center'}>
          {!isBlankLayout && isNotesAndSearchUser && (
            <div className={styles.searchBar}>
              <GlobalSearch />
            </div>
          )}
        </Grid>

        <Grid item xs={4} display="flex" alignItems="center" justifyContent={'flex-end'}>
          {isNotificationsAvailable && <NotificationPane />}
          {user && (
            <React.Fragment>
              <div className={styles.user} onClick={handleClickMenu}>
                <Avatar user={user} clientInfo={isAdminLoggedAsClient ? clientInfo : null} />
                <span className={styles.userName}>Hello, {userName}</span>
                {!isAdminLoggedAsClient && (
                  <ArrowDropDownIcon
                    className={classnames({
                      [styles.rotate]: anchorEl,
                    })}
                  />
                )}
              </div>

              {!isAdminLoggedAsClient && (
                <HeaderUserActions
                  isOpen={isActionsMenuOpen}
                  anchorEl={anchorEl}
                  handleCloseMenu={handleCloseMenu}
                  handleLogout={handleLogout}
                />
              )}
            </React.Fragment>
          )}
          {workflow && isNotesAndSearchUser && !isParticipant && (
            <div className={classnames(styles.toggleNotes, 'mui-fixed')}>
              <NotesIcon onClick={toggleNotes} />
            </div>
          )}
        </Grid>
      </Grid>
    </AppBar>
  )
}

export default Header
