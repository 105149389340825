import React, { useEffect } from 'react'
import { useLocation } from 'react-router'
import queryString from 'query-string'
import Box from '@mui/material/Box'

import Loader from '../../components/Loader'
import { textToTitleCase } from '@common/helpers/helpers'

interface IProps {
  isLoading: boolean
  createProspect: (data: object) => void
}

const ProspectSetupPage = ({ isLoading, createProspect }: IProps) => {
  const { search }: { search: string } = useLocation()
  const { record_type: recordType, salesforce_id: salesforceId } = queryString.parse(search)

  useEffect(() => {
    if (recordType && salesforceId) {
      createProspect({
        recordType: textToTitleCase(recordType as string),
        salesforceId,
      })
    }
  }, [createProspect, recordType, salesforceId])

  return (
    <Box py={1} pr={2}>
      {isLoading && <Loader />}
    </Box>
  )
}

export default ProspectSetupPage
