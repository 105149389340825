import { createRequestTypes } from '../../helpers/helpers'
import { IApi } from '../../api'
import { CommonPromiseAction } from '../types'

export const prefix = 'ongoingReporting'

export const [
  LIST_ONGOING_REPORTING_REQUEST,
  LIST_ONGOING_REPORTING_SUCCESS,
  LIST_ONGOING_REPORTING_FAILURE,
] = createRequestTypes(prefix, 'LIST_ONGOING_REPORTING')

export const listOngoingReporting: CommonPromiseAction = (data: object) => ({
  type: [
    LIST_ONGOING_REPORTING_REQUEST,
    LIST_ONGOING_REPORTING_SUCCESS,
    LIST_ONGOING_REPORTING_FAILURE,
  ],
  promise: (api: IApi) => api.ongoingReporting.list(data),
  params: data,
})

export const [
  SHOW_ONGOING_REPORTING_REQUEST,
  SHOW_ONGOING_REPORTING_SUCCESS,
  SHOW_ONGOING_REPORTING_FAILURE,
] = createRequestTypes(prefix, 'SHOW_ONGOING_REPORTING')

export const showOngoingReporting: CommonPromiseAction = (id: string) => ({
  type: [
    SHOW_ONGOING_REPORTING_REQUEST,
    SHOW_ONGOING_REPORTING_SUCCESS,
    SHOW_ONGOING_REPORTING_FAILURE,
  ],
  promise: (api: IApi) => api.ongoingReporting.show(id),
})

export const HIDE_ONGOING_REPORTING = `${prefix}/HIDE_ONGOING_REPORTING`

export const hideOngoingReporting: CommonPromiseAction = () => ({
  type: HIDE_ONGOING_REPORTING,
})

export const [
  UPDATE_ONGOING_REPORTING_REQUEST,
  UPDATE_ONGOING_REPORTING_SUCCESS,
  UPDATE_ONGOING_REPORTING_FAILURE,
] = createRequestTypes(prefix, 'UPDATE_ONGOING_REPORTING')

export const updateOngoingReporting: CommonPromiseAction = (
  id: string,
  data: object | FormData,
) => ({
  type: [
    UPDATE_ONGOING_REPORTING_REQUEST,
    UPDATE_ONGOING_REPORTING_SUCCESS,
    UPDATE_ONGOING_REPORTING_FAILURE,
  ],
  promise: (api: IApi) => api.ongoingReporting.update(id, data),
})

export const [
  UPDATE_ONGOING_REPORTING_STATUS_REQUEST,
  UPDATE_ONGOING_REPORTING_STATUS_SUCCESS,
  UPDATE_ONGOING_REPORTING_STATUS_FAILURE,
] = createRequestTypes(prefix, 'UPDATE_ONGOING_REPORTING_STATUS')

export const updateOngoingReportingStatus: CommonPromiseAction = (data: object) => ({
  type: [
    UPDATE_ONGOING_REPORTING_STATUS_REQUEST,
    UPDATE_ONGOING_REPORTING_STATUS_SUCCESS,
    UPDATE_ONGOING_REPORTING_STATUS_FAILURE,
  ],
  promise: (api: IApi) => api.ongoingReporting.updateReportingStatus(data),
})

export const [
  SUBMIT_ONGOING_REPORTING_REQUEST,
  SUBMIT_ONGOING_REPORTING_SUCCESS,
  SUBMIT_ONGOING_REPORTING_FAILURE,
] = createRequestTypes(prefix, 'SUBMIT_ONGOING_REPORTING')

export const submitOngoingReporting: CommonPromiseAction = (id: string, data: object) => ({
  type: [
    SUBMIT_ONGOING_REPORTING_REQUEST,
    SUBMIT_ONGOING_REPORTING_SUCCESS,
    SUBMIT_ONGOING_REPORTING_FAILURE,
  ],
  promise: (api: IApi) => api.ongoingReporting.submit(id, data),
  id,
})

export const [
  DELETE_ONGOING_REPORTING_REQUEST,
  DELETE_ONGOING_REPORTING_SUCCESS,
  DELETE_ONGOING_REPORTING_FAILURE,
] = createRequestTypes(prefix, 'DELETE_ONGOING_REPORTING')

export const deleteOngoingReporting: CommonPromiseAction = (id: string) => ({
  type: [
    DELETE_ONGOING_REPORTING_REQUEST,
    DELETE_ONGOING_REPORTING_SUCCESS,
    DELETE_ONGOING_REPORTING_FAILURE,
  ],
  promise: (api: IApi) => api.ongoingReporting.delete(id),
  id,
})

export const [
  LIST_CONSOLIDATED_ONGOING_REPORTING_REQUEST,
  LIST_CONSOLIDATED_ONGOING_REPORTING_SUCCESS,
  LIST_CONSOLIDATED_ONGOING_REPORTING_FAILURE,
] = createRequestTypes(prefix, 'LIST_CONSOLIDATED_ONGOING_REPORTING')

export const listConsolidatedOngoingReporting: CommonPromiseAction = (
  id: string,
  data: object,
) => ({
  type: [
    LIST_CONSOLIDATED_ONGOING_REPORTING_REQUEST,
    LIST_CONSOLIDATED_ONGOING_REPORTING_SUCCESS,
    LIST_CONSOLIDATED_ONGOING_REPORTING_FAILURE,
  ],
  promise: (api: IApi) => api.ongoingReporting.listConsolidated(id, data),
  // @ts-ignore
  loadMore: data.loadMore,
})

export const [
  UPDATE_CONSOLIDATED_ONGOING_REPORTING_REQUEST,
  UPDATE_CONSOLIDATED_ONGOING_REPORTING_SUCCESS,
  UPDATE_CONSOLIDATED_ONGOING_REPORTING_FAILURE,
] = createRequestTypes(prefix, 'UPDATE_CONSOLIDATED_ONGOING_REPORTING')

export const updateConsolidatedOngoingReporting: CommonPromiseAction = (
  id: string,
  data: object,
) => ({
  type: [
    UPDATE_CONSOLIDATED_ONGOING_REPORTING_REQUEST,
    UPDATE_CONSOLIDATED_ONGOING_REPORTING_SUCCESS,
    UPDATE_CONSOLIDATED_ONGOING_REPORTING_FAILURE,
  ],
  promise: (api: IApi) => api.ongoingReporting.updateConsolidated(id, data),
})

export const [
  LIST_SUMMARY_ONGOING_REPORTING_REQUEST,
  LIST_SUMMARY_ONGOING_REPORTING_SUCCESS,
  LIST_SUMMARY_ONGOING_REPORTING_FAILURE,
] = createRequestTypes(prefix, 'LIST_SUMMARY_ONGOING_REPORTING')

export const listSummaryOngoingReporting: CommonPromiseAction = (id: string, data: object) => ({
  type: [
    LIST_SUMMARY_ONGOING_REPORTING_REQUEST,
    LIST_SUMMARY_ONGOING_REPORTING_SUCCESS,
    LIST_SUMMARY_ONGOING_REPORTING_FAILURE,
  ],
  promise: (api: IApi) => api.ongoingReporting.listSummary(id, data),
})

export const [
  LIST_INVENTORY_MAPPING_REQUEST,
  LIST_INVENTORY_MAPPING_SUCCESS,
  LIST_INVENTORY_MAPPING_FAILURE,
] = createRequestTypes(prefix, 'LIST_INVENTORY_MAPPING')

export const listInventoryMapping: CommonPromiseAction = (id: string, params: object) => ({
  type: [
    LIST_INVENTORY_MAPPING_REQUEST,
    LIST_INVENTORY_MAPPING_SUCCESS,
    LIST_INVENTORY_MAPPING_FAILURE,
  ],
  promise: (api: IApi) => api.ongoingReporting.listInventoryMapping(id, params),
  params,
})

export const [
  LIST_SALES_BY_SKU_VISUALIZATION_REQUEST,
  LIST_SALES_BY_SKU_VISUALIZATION_SUCCESS,
  LIST_SALES_BY_SKU_VISUALIZATION_FAILURE,
] = createRequestTypes(prefix, 'LIST_SALES_BY_SKU_VISUALIZATION')

export const listSalesBySkuVisualization: CommonPromiseAction = (id: string, params: object) => ({
  type: [
    LIST_SALES_BY_SKU_VISUALIZATION_REQUEST,
    LIST_SALES_BY_SKU_VISUALIZATION_SUCCESS,
    LIST_SALES_BY_SKU_VISUALIZATION_FAILURE,
  ],
  promise: (api: IApi) => api.ongoingReporting.listSalesBySkuVisualization(id, params),
  params,
})

export const [
  EXPORT_SALES_BY_SKU_VISUALIZATION_REQUEST,
  EXPORT_SALES_BY_SKU_VISUALIZATION_SUCCESS,
  EXPORT_SALES_BY_SKU_VISUALIZATION_FAILURE,
] = createRequestTypes(prefix, 'EXPORT_SALES_BY_SKU_VISUALIZATION')

export const exportSalesBySkuVisualization: CommonPromiseAction = (id: string, params: object) => ({
  type: [
    EXPORT_SALES_BY_SKU_VISUALIZATION_REQUEST,
    EXPORT_SALES_BY_SKU_VISUALIZATION_SUCCESS,
    EXPORT_SALES_BY_SKU_VISUALIZATION_FAILURE,
  ],
  promise: (api: IApi) =>
    api.ongoingReporting.listSalesBySkuVisualization(id, {
      ...params,
      isFile: true,
    }),
  params,
})

export const [
  UPDATE_INVENTORY_MAPPING_REQUEST,
  UPDATE_INVENTORY_MAPPING_SUCCESS,
  UPDATE_INVENTORY_MAPPING_FAILURE,
] = createRequestTypes(prefix, 'UPDATE_INVENTORY_MAPPING')

export const updateInventoryMapping: CommonPromiseAction = (
  id: string,
  itemId: string,
  data: object,
) => ({
  type: [
    UPDATE_INVENTORY_MAPPING_REQUEST,
    UPDATE_INVENTORY_MAPPING_SUCCESS,
    UPDATE_INVENTORY_MAPPING_FAILURE,
  ],
  promise: (api: IApi) => api.ongoingReporting.updateInventoryMapping(id, itemId, data),
})

const LIST_SALES_BY_SKU_FILTERS_TYPES = createRequestTypes(prefix, 'LIST_SALES_BY_SKU_FILTERS')
export const [
  LIST_SALES_BY_SKU_FILTERS_REQUEST,
  LIST_SALES_BY_SKU_FILTERS_SUCCESS,
  LIST_SALES_BY_SKU_FILTERS_FAILURE,
] = LIST_SALES_BY_SKU_FILTERS_TYPES

export const listSalesBySkuFilters: CommonPromiseAction = (id: string, params: object) => ({
  type: LIST_SALES_BY_SKU_FILTERS_TYPES,
  promise: (api: IApi) => api.ongoingReporting.listSalesBySkuFilters(id, params),
  params,
})

const LIST_SALES_BY_DISTRIBUTION_GRAPH_TYPES = createRequestTypes(
  prefix,
  'LIST_SALES_BY_DISTRIBUTION_GRAPH',
)
export const [
  LIST_SALES_BY_DISTRIBUTION_GRAPH_REQUEST,
  LIST_SALES_BY_DISTRIBUTION_GRAPH_SUCCESS,
  LIST_SALES_BY_DISTRIBUTION_GRAPH_FAILURE,
] = LIST_SALES_BY_DISTRIBUTION_GRAPH_TYPES

export const listSalesByDistributionGraph: CommonPromiseAction = (id: string, params: object) => ({
  type: LIST_SALES_BY_DISTRIBUTION_GRAPH_TYPES,
  promise: (api: IApi) => api.ongoingReporting.listSalesByDistributionGraph(id, params),
  params,
})

export const HIDE_SALES_DATA = `${prefix}/HIDE_SALES_DATA`

export const hideSalesData: CommonPromiseAction = () => ({
  type: HIDE_SALES_DATA,
})
