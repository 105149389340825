import React, { useCallback, useState, useMemo } from 'react'
import { NavLink, matchPath } from 'react-router-dom'
import cn from 'classnames'
import { Location } from 'history'
import Drawer from '@mui/material/Drawer'
import List from '@mui/material/List'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemText from '@mui/material/ListItemText'
import ListItemIcon from '@mui/material/ListItemIcon'
import Link from '@mui/material/Link'
import { Link as RouterLink } from 'react-router-dom'
import {
  SIDE_MENU,
  IMenuData,
  SIDE_MENU_PAST_CLIENT_USER,
  SIDE_MENU_CLIENT_USER,
} from './SideMenuConfig'
import { UserRole } from '@common/interfaces/user'
import styles from './SideMenu.module.scss'
import ExpandableMenuItem from './components/ExpandableNavItem'
import { getInitialExpandableListItemsState, updateExpandableListItemsState } from './utils'
import { ROUTES } from '../../constants/routes'
import { ReactComponent as LogoutIcon } from '../../assets/images/logout-icon.svg'
import { ReactComponent as BurgerMenuIcon } from '../../assets/images/burger-icon.svg'
import logo from '../../assets/images/logo2.png'
import smalllogo from '../../assets/images/logo-d-small.png'
import Collapse from '@mui/material/Collapse'
import { Grid, Skeleton } from '@mui/material'

interface IProps {
  role: string
  pathname: string
  location: Location
  onLogout: () => void
  isSidebarOpen: boolean
  toggleSidebar: () => void
  isPastClient: boolean
  isDueDiligenceClient: boolean
  isAdminLoggedAsClient: boolean
  isClientUser: boolean
}

const SideMenu = ({
  role = UserRole.CLIENT_USER,
  location,
  onLogout,
  toggleSidebar,
  isSidebarOpen,
  isPastClient,
  isDueDiligenceClient,
  isAdminLoggedAsClient,
  isClientUser,
}: IProps) => {
  const [expandableListItemsState, setExpandableListItemsState] = useState(
    getInitialExpandableListItemsState(role, location),
  )

  const handleItemExpand = useCallback(
    (itemName: string) => {
      const newState = updateExpandableListItemsState(expandableListItemsState, itemName)

      setExpandableListItemsState(newState)
    },
    [expandableListItemsState],
  )

  const handleLogout = useCallback(() => {
    onLogout()
  }, [onLogout])

  const sideMenuItems = useMemo(() => {
    if (role) {
      if (role === UserRole.CLIENT_USER) {
        if (isPastClient) {
          return SIDE_MENU_PAST_CLIENT_USER.filter(
            (item) => !isAdminLoggedAsClient || item.link !== ROUTES.SETTINGS,
          )
        }
        if (isPastClient !== null) {
          return SIDE_MENU_CLIENT_USER.filter(
            (item) => !isAdminLoggedAsClient || item.link !== ROUTES.SETTINGS,
          )
        }
      }

      return SIDE_MENU[role]
    }
  }, [role, isPastClient, isAdminLoggedAsClient])

  const isCurrentPage = useCallback(
    (link: string) => {
      return matchPath(link, {
        path: location.pathname,
        exact: true,
      })
    },
    [location],
  )

  if (role === UserRole.CLIENT_USER && isDueDiligenceClient) {
    return null
  }

  return (
    <Drawer
      className={cn(styles.drawer, {
        [styles.expanded]: isSidebarOpen,
        [styles.collapsed]: !isSidebarOpen,
        [styles.loggedAsClientDrawer]: isAdminLoggedAsClient,
      })}
      variant="permanent"
      classes={{
        paper: styles.drawerPaper,
      }}
      anchor="left"
      open={isSidebarOpen}
    >
      <BurgerMenuIcon className={styles.burgerMenuIcon} onClick={toggleSidebar} />
      <List component="nav" classes={{ root: styles.wrapper }}>
        <Link component={RouterLink} to={ROUTES.HOMEPAGE} className={styles.logoWrapper}>
          {isSidebarOpen ? (
            <Collapse orientation="horizontal" in={isSidebarOpen}>
              <img className={styles.logo} src={logo} alt="Dwight Funding" />
            </Collapse>
          ) : (
            <Collapse orientation="horizontal" in={!isSidebarOpen}>
              <img
                className={cn(styles.logo, styles.smallLogo)}
                src={smalllogo}
                alt="Dwight Funding"
              />
            </Collapse>
          )}
        </Link>
        <div className={styles.sideMenuItems}>
          {!sideMenuItems.length ? (
            <>
              <Grid container spacing={1} rowGap={1}>
                {[...Array(5)].map((_, key) => (
                  <>
                    <Grid item xs={isSidebarOpen ? 3 : 12} key={key}>
                      <Skeleton
                        variant="rectangular"
                        width={30}
                        height={30}
                        className={styles.skeleton}
                      />
                    </Grid>
                    {isSidebarOpen && (
                      <Grid item xs={9} key={key}>
                        <Skeleton
                          variant="rectangular"
                          width={150}
                          height={30}
                          className={styles.skeleton}
                        />
                      </Grid>
                    )}
                  </>
                ))}
              </Grid>
            </>
          ) : (
            (sideMenuItems || []).map(
              ({ icon, label, subItems, link, activeIcon, divider }: IMenuData) => (
                <div key={link} className={styles.sideMenuItem}>
                  {subItems ? (
                    <ExpandableMenuItem
                      icon={icon}
                      label={label}
                      subItems={subItems}
                      handleItemExpand={handleItemExpand}
                      itemExpanded={expandableListItemsState[label]}
                      sidebarExpanded={isSidebarOpen}
                    />
                  ) : (
                    <>
                      {divider && <div className={styles.divider} />}
                      <NavLink to={link} exact activeClassName={styles.active}>
                        <ListItemButton className={styles.navItem} disableRipple>
                          <div className={styles.collapsedIcon}>
                            <ListItemIcon
                              className={cn(styles.icon, {
                                [styles.colorIcon]: !activeIcon,
                              })}
                            >
                              {activeIcon && isCurrentPage(link) ? activeIcon : icon}
                            </ListItemIcon>
                          </div>

                          {isSidebarOpen && (
                            <ListItemText classes={{ primary: styles.label }} primary={label} />
                          )}
                        </ListItemButton>
                      </NavLink>
                    </>
                  )}
                </div>
              ),
            )
          )}
        </div>
        {isClientUser && !isAdminLoggedAsClient && (
          <div className={styles.profileWrapper}>
            <div onClick={handleLogout} className={styles.navItem}>
              <div className={styles.icon}>
                <LogoutIcon />
              </div>
              {isSidebarOpen && <span className={styles.label}>Log out</span>}
            </div>
          </div>
        )}
      </List>
    </Drawer>
  )
}

export default SideMenu
