import { connect } from 'react-redux'

import BBCRollforwardDetailsTable from './BBCRollforwardDetailsTable'

import { getBBCARRollforwardDetails, getBBCAPRollforwardDetails } from '../../redux/bbc/actions'

const actions = {
  getBBCARRollforwardDetails,
  getBBCAPRollforwardDetails,
}

export default connect(null, actions)(BBCRollforwardDetailsTable)
