import { IUser } from './user'
import {
  EligibilityStatus,
  IArIneligibility,
  IBorrowingBase,
  IExtraReserve,
  IInventoryIneligibilityCustomRule,
  IReserve,
} from './bbc'
import { INote } from './notes'
import { IClientInfo } from './client'

export enum BBCLogType {
  Reserve = 'Reserve',
  AREligibility = 'AR Eligibility',
  CustomRule = 'Custom Rule',
  InventoryEligibility = 'Inventory Eligibility',
  InventoryMapping = 'Inventory Mapping',
  APEligibility = 'AP Eligibility',
}

export enum BBCLogAction {
  add = 'added',
  update = 'updated',
  delete = 'deleted',
}

export interface IBBCLogCustomRule {
  id: string
  action: BBCLogAction
  oldRuleId: string | null
  oldRule?: IInventoryIneligibilityCustomRule
  newRuleId: string | null
  newRule?: IInventoryIneligibilityCustomRule
}

export interface IBBCLogDebtorClientEligibility {
  id: string
  oldId: string | null
  oldItem?: IArIneligibility
  newId: string | null
  newItem?: IArIneligibility
  impactToAvailability: number
}

export interface IBBCLogInventoryIneligibilityCategories {
  id: string
  oldId: string | null
  oldItem?: {
    id: string
    category: string
    value: string
    eligibility: string
  }
  newId: string | null
  newItem?: {
    id: string
    category: string
    value: string
    eligibility: string
  }
  impactToAvailability: number
}

export interface IBBCLogMasterInventory {
  id: string
  oldId: string | null
  oldItem?: {
    id: string
    sku: string
    type: string
    brandCategory: string
    eligibility: string
  }
  newId: string | null
  newItem?: {
    id: string
    sku: string
    type: string
    brandCategory: string
    eligibility: string
  }
  impactToAvailability: number
}

export interface IBBCLogReserve {
  id: string
  action: BBCLogAction
  oldReserveId: string | null
  oldReserve?: IReserve
  newReserveId: string | null
  newReserve?: IReserve
}

export interface IBBCLogCreditorClient {
  id: string
  oldId: string | null
  oldItem?: IExtraReserve
  newId: string | null
  newItem?: IExtraReserve
  impactToAvailability: number
}

export interface IBBCLogUser {
  id: string
  userId: string
  user?: IUser
}

export interface IBBCLog {
  id: string
  clientName: string
  clientInfo?: IClientInfo
  borrowingBaseId: string | null
  borrowingBase?: IBorrowingBase
  type: BBCLogType
  impactToAvailability: number
  users?: IBBCLogUser[]
  notes?: INote[]
  customRules: IBBCLogCustomRule[]
  debtorClientEligibility: IBBCLogDebtorClientEligibility[]
  inventoryIneligibilityCategories: IBBCLogInventoryIneligibilityCategories[]
  masterInventory: IBBCLogMasterInventory[]
  reserves: IBBCLogReserve[]
  creditorClients: IBBCLogCreditorClient[]
  createdAt: string
  updatedAt: string
}

export interface IBBCLogData {
  data: IBBCLog[]
  params: {
    clientName: string
  }
  totals: {
    totalItems: number
  }
}

export const BBC_LOG_FIELDS_TO_COMPARE = {
  [BBCLogType.CustomRule]: [
    'field',
    'label',
    'operator',
    'value',
    'eligibility',
    'overrides',
    'values',
    'ineligibleReason',
  ],
  [BBCLogType.AREligibility]: ['creditLimits', 'concentrationLimit', 'ineligibleCategory'],
  [BBCLogType.Reserve]: ['category', 'amount', 'kind', 'percent', 'field'],
  [BBCLogType.InventoryEligibility]: ['eligibility', 'isCanadaMexicoLocation'],
  [BBCLogType.InventoryMapping]: ['type', 'brandCategory', 'eligibility'],
  [BBCLogType.APEligibility]: ['priorityPayable', 'notes'],
}

export const BBC_LOG_ITEM_DEFAULT = {
  [BBCLogType.AREligibility]: {
    ineligibleCategory: EligibilityStatus.New,
    creditLimits: 0,
    concentrationLimit: 0.3,
  },
  [BBCLogType.InventoryEligibility]: {
    eligibility: EligibilityStatus.Eligible,
    isCanadaMexicoLocation: false,
  },
  [BBCLogType.InventoryMapping]: {
    type: null,
    brandCategory: null,
    eligibility: EligibilityStatus.Eligible,
  },
  [BBCLogType.AREligibility]: {
    priorityPayable: null,
    notes: null,
  },
}
