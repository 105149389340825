import { trackActivity } from './../../redux/activity/actions'
import { connect } from 'react-redux'
import { createStructuredSelector, createSelector } from 'reselect'

import CollectionsMidDayWiresPage from './CollectionsMidDayWiresPage'
import {
  isLoading as isLoadingCollections,
  wiresData,
  wiresDataNegative,
  wiresDataDeleted,
  wiresDataTotals,
  collectionStatus,
} from '../../redux/collections/selectors'
import {
  uploadMidDayWires,
  updateMidDayWire,
  updateMidDayWires,
  deleteMidDayWire,
  restoreMidDayWire,
  saveMidDayWires,
  listMidDayWiresNew,
  listMidDayWiresNegative,
  listMidDayWiresDeleted,
  listWireFilesAndStatus,
} from '../../redux/collections/actions'
import { isLoading as isLoadingEntityInfo } from '../../redux/entityInfo/selectors'
import { isLoading as isLoadingFiles } from '../../redux/file/selectors'

const selector = createStructuredSelector({
  isLoading: createSelector(
    isLoadingCollections,
    isLoadingFiles,
    isLoadingEntityInfo,
    (collectionsLoading, loadingFiles, loadingEntityInfo) =>
      collectionsLoading || loadingFiles || loadingEntityInfo,
  ),
  wiresData,
  wiresDataNegative,
  wiresDataDeleted,
  wiresDataTotals,
  collectionStatus,
})

const actions = {
  uploadWires: uploadMidDayWires,
  listNewWires: listMidDayWiresNew,
  listNegativeWires: listMidDayWiresNegative,
  listDeletedWires: listMidDayWiresDeleted,
  updateWire: updateMidDayWire,
  updateWires: updateMidDayWires,
  deleteWire: deleteMidDayWire,
  restoreWire: restoreMidDayWire,
  saveWires: saveMidDayWires,
  listWireFilesAndStatus,
  trackActivity,
}

export default connect(selector, actions)(CollectionsMidDayWiresPage)
