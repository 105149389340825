import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'

import NotificationPage from './NotificationPage'

import { isLoading, notifications } from '../../redux/notification/selectors'
import { clients } from '../../redux/clientInfo/selectors'
import { list as users } from '../../redux/user/selectors'
import {
  list as listNotifications,
  markAsRead as markNotificationsAsRead,
} from '../../redux/notification/actions'
import { list as listClients } from '../../redux/clientInfo/actions'
import { listUsers } from '../../redux/user/actions'
import { userId } from '../../redux/profile/selectors'

const selector = createStructuredSelector({
  isLoading,
  notifications,
  clients,
  users,
  currentUserId: userId,
})
const actions = {
  listNotifications,
  markNotificationsAsRead,
  listUsers,
  listClients,
}

export { NotificationPage }

export default connect(selector, actions)(NotificationPage)
