import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'

import BankTransactions from './BankTransactions'

import { bankTransactionsRaw, bankTransactionsGraph } from '../../redux/bankTransactions/selectors'
import {
  listBankTransactionsRaw,
  listBankTransactionsGraph,
  hideBankTransactionsData,
  listBankTransactionDetail,
  exportBankTransactions,
} from '../../redux/bankTransactions/actions'
import { listEntityInfo } from '../../redux/entityInfo/actions'

const selector = createStructuredSelector({
  bankTransactions: bankTransactionsRaw,
  bankTransactionsGraph,
})
const actions = {
  listBankTransactions: listBankTransactionsRaw,
  listBankTransactionsGraph: listBankTransactionsGraph,
  exportBankTransactions,
  hideBankTransactionsData,
  listEntityInfo,
  listBankTransactionDetail,
}

export default connect(selector, actions)(BankTransactions)
