import React, { useCallback } from 'react'
import AttachmentCancelIcon from '@mui/icons-material/Cancel'
import IconButton from '@mui/material/IconButton'
import styles from './Attachments.module.scss'

interface IProps {
  index: number
  removeAttachment: (index: number) => void
}

const AttachmentDeleteButton = ({ index, removeAttachment }: IProps) => {
  const handleRemoveAttachment = useCallback(() => {
    removeAttachment(index)
  }, [index, removeAttachment])

  return (
    <IconButton className={styles.closeButton} disableRipple onClick={handleRemoveAttachment}>
      <AttachmentCancelIcon />
    </IconButton>
  )
}

export default AttachmentDeleteButton
