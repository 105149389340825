import React, { useEffect, useCallback, useMemo, useState } from 'react'
import { useHistory } from 'react-router'
import { useParams } from 'react-router'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import { IOngoingReporting, OngoingReportingStatus, WorkflowPage } from '@common/interfaces/bbc'
import Button from '../../components/Common/Button'
import { ROUTES } from '../../constants/routes'
import Card from '../../components/Common/Card'
import { EditSourceData, ExternalLink } from '../../components/Common/Icons/Icons'
import Breadcrumbs from '../../components/Common/Breadcrumbs'
import { ReactComponent as HomeIcon } from '@assets/images/home-icon.svg'
import { generatePath } from 'react-router-dom'
import OngoingReportingFiles from '../../components/OngoingReportingFiles'
import { REPORTING_DOCUMENT_TYPES } from '@common/constants/client'
import InvetoryMappingTable from '../../components/InvetoryMappingTable'
import SalesBySKUVisualizationTable from '../../components/SalesBySKUVisualizationTable'
import { EditMapping } from '../../components/Common/Icons/Icons'
import FullscreenModal from '../../components/Common/FullscreenModal'
import styles from './OngoingReportingSalesBySKUPage.module.scss'
import WarningModal from '../../components/WarningModal'
import useOngoingReportingFileSheet from '../../hooks/useOngoingReportingFileSheet'

interface IProps {
  isLoading: boolean
  data: IOngoingReporting
  showOngoingReporting: (id: string) => void
  updateOngoingReporting: (id: string, data: object) => Promise<any>
  submitOngoingReporting: (id: string, data: object) => void
  deleteFile: (id: string) => Promise<void>
  downloadTemplate: (id: string) => Promise<void>
  updateOngoingReportingStatus: (data: object) => Promise<void>
}

const OngoingReportingSalesBySKUPage = ({
  isLoading,
  data,
  showOngoingReporting,
  updateOngoingReporting,
  updateOngoingReportingStatus,
}: IProps) => {
  const { id } = useParams<{ id: string }>()

  const [isFileSelectShown, setIsFileSelectShown] = useState(false)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [isFilesSaving, setIsFilesSaving] = useState(false)
  const [refreshCount, setRefreshCount] = useState(1)
  const [isDuplicateModalOpen, setIsDuplicateModalOpen] = useState(false)
  const [totalDuplicateSkus, setTotalDuplicateSkus] = useState(0)
  const [totalUniqueDuplicateSkus, setTotalUniqueDuplicateSkus] = useState(0)

  useEffect(() => {
    if (!data || data.id !== id) {
      showOngoingReporting(id)
    }
  }, [showOngoingReporting, data, id])

  const status = useMemo(() => data?.status, [data])
  const history = useHistory()

  const isDisabled = useMemo(
    () => [OngoingReportingStatus.Verified, OngoingReportingStatus.Archived].includes(status),
    [status],
  )

  const handleCloseModal = useCallback(() => {
    setIsModalOpen(false)
    setRefreshCount((prev) => prev + 1)
  }, [])

  const handleCloseSelectFile = useCallback(() => {
    setIsFileSelectShown(false)
  }, [setIsFileSelectShown])

  const handleOpenSelectFile = useCallback(() => {
    setIsFileSelectShown(true)
  }, [setIsFileSelectShown])

  const breadcrumbs = useMemo(() => {
    return [
      {
        link: ROUTES.HOMEPAGE,
        Icon: HomeIcon,
      },
      {
        link: ROUTES.ANALYSIS_QUEUE,
        title: 'Analysis queue',
      },
      {
        title: data?.clientName,
        link: data?.clientInfo?.id
          ? generatePath(ROUTES.CLIENT_PAGE, { id: data?.clientInfo?.id })
          : '',
      },
      {
        link: generatePath(ROUTES.ONGOING_REPORTING_SALES_BY_SKU, { id }),
        title: `Sales by SKU reporting`,
      },
    ]
  }, [id, data])

  const closeWarningModal = useCallback(() => {
    setIsDuplicateModalOpen(false)
  }, [])

  const handleSelectFiles = useCallback(
    async (fileSheets: { fileId: string; sheetName: string }[]) => {
      const result = await updateOngoingReporting(id, {
        fileSheets: fileSheets.map((item) => ({
          ...item,
          type: REPORTING_DOCUMENT_TYPES.salesBySKU,
        })),
      })
      if (result?.data?.totalDuplicateSkus > 0) {
        setTotalDuplicateSkus(result.data.totalDuplicateSkus)
        setTotalUniqueDuplicateSkus(result.data.totalUniqueDuplicateSkus)
        setIsDuplicateModalOpen(true)
      }
      setRefreshCount((prev) => prev + 1)
      return result
    },
    [id, updateOngoingReporting, setRefreshCount],
  )

  const { fileSheets, currentSelectedFile } = useOngoingReportingFileSheet({
    reporting: data,
    type: REPORTING_DOCUMENT_TYPES.salesBySKU,
  })

  const handleClick = useCallback(() => {
    if (data?.id === id && status === OngoingReportingStatus.Process) {
      updateOngoingReportingStatus({ id, status: OngoingReportingStatus.Verified })
    }
    history.push(ROUTES.ANALYSIS_QUEUE)
  }, [id, data, updateOngoingReportingStatus, history, status])

  return (
    <Box py={1} pr={2}>
      <Breadcrumbs breadcrumbs={breadcrumbs} isLoading={isLoading} />

      <Card
        withBorder={false}
        title={
          <Box display="flex" justifyContent="flex-end" alignItems="center">
            <Box display="flex" justifyContent="space-between" alignItems="center" gap={2}>
              <Box display="flex" justifyContent="space-between" alignItems="center">
                <ExternalLink link={currentSelectedFile?.link} className={styles.externalLink} />
              </Box>
              <Box display="flex" justifyContent="space-between" alignItems="center">
                <EditSourceData
                  action={() => setIsFileSelectShown(!isFileSelectShown)}
                  error={!!fileSheets?.filter(({ file }) => file && file.error).length}
                />
              </Box>
              <Box display="flex" justifyContent="space-between" alignItems="center">
                <EditMapping action={() => setIsModalOpen(true)} title="Mapping" />
              </Box>

              {isDisabled ? (
                <Button
                  type="submit"
                  color="primary"
                  variant="contained"
                  size="small"
                  href={ROUTES.ANALYSIS_QUEUE}
                >
                  Return to Analysis queue
                </Button>
              ) : (
                <Button
                  type="submit"
                  color="primary"
                  variant="contained"
                  size="small"
                  disabled={!data}
                  onClick={handleClick}
                >
                  Submit
                </Button>
              )}
            </Box>
          </Box>
        }
      >
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <OngoingReportingFiles
              handleSelectFiles={handleSelectFiles}
              fileSheets={fileSheets}
              files={data?.files}
              isDisabled={isDisabled}
              handleCloseModal={handleCloseSelectFile}
              handleOpenModal={handleOpenSelectFile}
              isOpen={isFileSelectShown}
              setIsFilesSaving={setIsFilesSaving}
            />
          </Grid>
        </Grid>
      </Card>
      <SalesBySKUVisualizationTable
        currentId={data?.id}
        type={WorkflowPage.ongoingReporting}
        refreshCount={refreshCount}
      />
      {isModalOpen && (
        <FullscreenModal
          isOpen
          setIsOpen={handleCloseModal}
          disableEnforceFocus
          classes={{ body: styles.fullScreenModal }}
          showCloseIcon
        >
          <Card noHeaderMargin className={styles.mappingContainer}>
            <InvetoryMappingTable isFilesSaving={isFilesSaving} />
          </Card>
        </FullscreenModal>
      )}

      {isDuplicateModalOpen && totalDuplicateSkus > 0 && (
        <WarningModal
          warningMessage={`${totalUniqueDuplicateSkus} unique duplicate SKU - Date(s) appeared ${totalDuplicateSkus} times in this report.`}
          title="Warning"
          onCancel={closeWarningModal}
          cancelText="Close"
        />
      )}
    </Box>
  )
}

export default OngoingReportingSalesBySKUPage
