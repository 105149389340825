import React, { useCallback, useEffect } from 'react'
import cn from 'classnames'

import styles from './DueDiligenceFinancialsBankAccounts.module.scss'
import genericSs from '@styles/generic.module.scss'

import {
  DueDiligenceTableItemType,
  IDueDiligenceBankAccount,
} from '@common/interfaces/dueDiligence'
import DueDiligenceTable from '../DueDiligenceTable'
import { ILoadingData } from '../../redux/types'
import { IBank } from '@common/interfaces/bank'

interface IProps {
  dueDiligenceFinancialsBankAccounts: ILoadingData<{ data: IDueDiligenceBankAccount[] }>
  banks: IBank[]
  createBank: (data: { name: string }) => void
  listBanks: () => void
  listFinancialsBankAccounts: (params: object) => void
  hideFinancialsBankAccounts: () => void
  addFinancialsBankAccount: (data: object) => Promise<void>
  updateFinancialsBankAccount: (id: string, data: object) => Promise<void>
  deleteFinancialsBankAccount: (id: string) => Promise<void>
}

const DueDiligenceFinancialsBankAccounts = ({
  dueDiligenceFinancialsBankAccounts,
  banks,
  createBank,
  listBanks,
  listFinancialsBankAccounts,
  hideFinancialsBankAccounts,
  addFinancialsBankAccount,
  updateFinancialsBankAccount,
  deleteFinancialsBankAccount,
}: IProps) => {
  useEffect(() => {
    listBanks()
  }, [listBanks])

  const handleAddBankAccount = useCallback(
    async (data: any) => {
      const { bankName, ...rest } = data

      return addFinancialsBankAccount({
        bankName: bankName?.value || '',
        ...rest,
      })
    },
    [addFinancialsBankAccount],
  )
  const handleUpdateBankAccount = useCallback(
    async (id: string, data: any) => {
      const { bankName, ...rest } = data

      return updateFinancialsBankAccount(id, {
        bankName: bankName?.value || '',
        ...rest,
      })
    },
    [updateFinancialsBankAccount],
  )

  const handleAddBank = useCallback((name: string) => createBank({ name }), [createBank])

  return (
    <div className={styles.container}>
      <DueDiligenceTable
        type={DueDiligenceTableItemType.BankAccount}
        data={dueDiligenceFinancialsBankAccounts}
        handleList={listFinancialsBankAccounts}
        handleHide={hideFinancialsBankAccounts}
        handleAdd={handleAddBankAccount}
        handleUpdate={handleUpdateBankAccount}
        handleDelete={deleteFinancialsBankAccount}
        banks={banks}
        handleAddBank={handleAddBank}
      />

      <div className={styles.warning}>
        <div className={cn(genericSs.warningMessage, genericSs.textLeft)}>
          <div>
            Please provide read-only bank account access to{' '}
            <a target="_blank" rel="noopener noreferrer" href="mailto:ops@dwightfund.com">
              ops@dwightfund.com
            </a>{' '}
            for each of your business bank accounts.
          </div>
          <div>
            Please e-mail the details to{' '}
            <a target="_blank" rel="noopener noreferrer" href="mailto:ops@dwightfund.com">
              ops@dwightfund.com
            </a>{' '}
            and reach out to our team should you have any questions or concerns!
          </div>
        </div>
      </div>
    </div>
  )
}

export default DueDiligenceFinancialsBankAccounts
