import React from 'react'
import { useParams } from 'react-router'
import Box from '@mui/material/Box'

import ClientSetupHeader from '../../components/ClientSetupHeader'
import ClientTermLoans from '../../components/ClientTermLoans'

const ClientSetupLoanStructureTermPage = () => {
  const { id: clientId } = useParams<{ id?: string }>()

  return (
    <Box py={1} pr={2}>
      <ClientSetupHeader />

      <Box>
        <ClientTermLoans clientId={clientId} />
      </Box>
    </Box>
  )
}

export default ClientSetupLoanStructureTermPage
