import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'

import BankTransactionsMapping from './BankTransactionsMapping'

import { bankTransactions } from '../../redux/bankTransactions/selectors'
import { listEntityInfo, addEntityInfo } from '../../redux/entityInfo/actions'
import {
  listBankTransactionDetail,
  listBankTransactions,
  updateTransactions,
} from '../../redux/bankTransactions/actions'

const selector = createStructuredSelector({
  bankTransactionsData: bankTransactions,
})

const actions = {
  updateTransactions,
  listEntityInfo,
  addEntityInfo,
  listBankTransactions,
  listBankTransactionDetail,
}

export default connect(selector, actions)(BankTransactionsMapping)
