import React from 'react'
import Grid from '@mui/material/Grid'
import Box from '@mui/material/Box'
import Skeleton from '@mui/material/Skeleton'

import genericSs from '@styles/generic.module.scss'
import styles from './DueDiligenceProcessDocumentsPage.module.scss'

import TableContainer from '../../components/Common/TableContainer'
import Table from '../../components/Common/Table'
import TableHead from '../../components/Common/TableHead'
import TableBody from '../../components/Common/TableBody'
import TableRow from '../../components/Common/TableRow'
import TableCell from '../../components/Common/TableCell'
import TableLoader from '../../components/Common/TableLoader'

const DueDiligenceProcessDocumentsPageLoader = () => {
  return (
    <Grid container py={3} pr={2} alignItems="flex-start" justifyContent="center" rowSpacing={2}>
      <Grid item container xs={12} justifyContent="space-between">
        <Grid item container xs={6} justifyContent="flex-start" alignItems="center">
          <Box mt={1} ml={3} display="flex" gap={2.5}>
            <Skeleton width={12} height={25} />
            <Skeleton width={140} height={25} />
            <Skeleton width={140} height={25} />
          </Box>
        </Grid>
      </Grid>
      <Grid container item xs={12} alignItems="flex-start" justifyContent="flex-start" ml={3}>
        <Skeleton width={230} height={34} />

        <TableContainer className={styles.documentTable}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell className={genericSs.tableTextLeft}>Report type</TableCell>
                <TableCell className={genericSs.tableTextLeft}>Folder</TableCell>
                <TableCell className={genericSs.tableTextLeft}>Submitted date</TableCell>
                <TableCell className={genericSs.tableTextLeft}>Months processed</TableCell>
                <TableCell className={genericSs.tableTextLeft}>Status</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableLoader columnsCount={5} rowsCount={10} height={36} />
            </TableBody>
          </Table>
          <Box display="flex" alignItems="center" justifyContent="flex-end">
            <div className={genericSs.savingContainer} />
          </Box>
        </TableContainer>
      </Grid>
    </Grid>
  )
}

export default DueDiligenceProcessDocumentsPageLoader
