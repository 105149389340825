import React, { createContext, useContext, ReactNode } from 'react'
import { useSelector } from 'react-redux'
import { IRootState } from '../redux/reducer'
import { UserRole } from '@common/interfaces/user'

interface PermissionsContextProps {
  isParticipant: boolean
  isAdmin: boolean
  isUW: boolean
  isBDO: boolean
  isExtendedRightsRole: boolean
  isAdminRightsRole: boolean
  isClientUser: boolean
  isProspectUser: boolean
  isPortfolio: boolean
}

const PermissionsContext = createContext<PermissionsContextProps | undefined>(undefined)

export const PermissionsProvider = ({ children }: { children: ReactNode }) => {
  const userType = useSelector((state: IRootState) => state.profile?.user?.role)
  const isProspectUser = useSelector((state: IRootState) => state.profile?.user?.isProspectUser)

  const permissions = {
    isParticipant: userType === UserRole.PARTICIPANT_USER,
    isAdmin: userType === UserRole.ADMIN,
    isUW: userType === UserRole.UW_USER,
    isBDO: userType === UserRole.BDO,
    isExtendedRightsRole: [
      UserRole.ADMIN,
      UserRole.PORTFOLIO_ADMIN,
      UserRole.PORTFOLIO_USER,
      UserRole.UW_USER,
    ].includes(userType),
    isAdminRightsRole: [UserRole.ADMIN, UserRole.PORTFOLIO_ADMIN, UserRole.UW_USER].includes(
      userType,
    ),
    isPortfolio: [UserRole.PORTFOLIO_ADMIN, UserRole.PORTFOLIO_USER].includes(userType),
    isClientUser: userType === UserRole.CLIENT_USER,
    isProspectUser: userType === UserRole.CLIENT_USER && isProspectUser,
  }

  return <PermissionsContext.Provider value={permissions}>{children}</PermissionsContext.Provider>
}

export const usePermissions = () => {
  const context = useContext(PermissionsContext)
  if (context === undefined) {
    throw new Error('usePermissions must be used within a PermissionsProvider')
  }
  return context
}
