import { IApi } from '../../api'
import { createRequestTypes } from '../../helpers/helpers'

export const prefix = 'logger'

export const LOG_ERROR = createRequestTypes(prefix, 'LOG_ERROR')

export const logError = (data: object) => ({
  type: LOG_ERROR,
  promise: (api: IApi) => api.logger.logError(data),
})
