import React from 'react'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import Card from '../../components/Common/Card'

import EntitySettingsHeader from '../../components/EntitySettingsHeader'
import EntitySettingsApTable from '../../components/EntitySettingsApTable'

const EntitySettingsPayablesPage = () => {
  return (
    <Box py={1} pr={2}>
      <EntitySettingsHeader />

      <Box>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Card noHeaderMargin>
              <EntitySettingsApTable />
            </Card>
          </Grid>
        </Grid>
      </Box>
    </Box>
  )
}

export default EntitySettingsPayablesPage
