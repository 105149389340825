import React, { useCallback, useMemo } from 'react'
import { Form } from 'react-final-form'
import Box from '@mui/material/Box'
import styles from './RelationshipManager.module.scss'
import SelectField from '../../Common/SelectField'
import Button from '../../Common/Button'
import { IUser } from '@common/interfaces/user'
import Modal from '../../Common/Modal'
import InputLabel from '../../Common/InputLabel'
import TableFields from '../../TableFields'
import { makeValidate } from 'mui-rff'
import * as Yup from 'yup'

const schema = Yup.object().shape({
  managerId: Yup.string().required('Required'),
})

const validate = makeValidate(schema)

interface IProps {
  isEditModalShown: boolean
  setIsEditModalShown: (val: boolean) => void
  handleAddManager: (data: any & { clientId: string } & { id?: string }) => void
  relationshipManagers: IUser[]
  isButtonLoading: boolean
}

const AddRelationshipManager = ({
  isEditModalShown,
  setIsEditModalShown,
  handleAddManager,
  relationshipManagers,
  isButtonLoading,
}: IProps) => {
  const handleSubmitForm = useCallback(
    (data, form, handleSubmit) => {
      handleSubmit(data).then(() => {
        form.restart()
        setIsEditModalShown(false)
      })
    },
    [setIsEditModalShown],
  )

  const formOptions = useMemo(() => {
    return relationshipManagers
      ? relationshipManagers.map(({ id, firstName, lastName }) => ({
          value: id,
          label: `${firstName} ${lastName}`,
        }))
      : []
  }, [relationshipManagers])

  return (
    <Form
      onSubmit={handleAddManager}
      validate={validate}
      render={({ dirty, invalid, form, handleSubmit }) => (
        <Modal
          open={isEditModalShown}
          onCancel={() => setIsEditModalShown(false)}
          title="Add manager"
          classes={{
            root: styles.editModal,
            footer: styles.editModalFooter,
            title: styles.editModalTitle,
          }}
          footer={[
            <Button
              key="submit"
              color="primary"
              variant="contained"
              className={styles.submitModalButton}
              disabled={!dirty || invalid}
              isLoading={isButtonLoading}
              onClick={(data) => {
                handleSubmitForm(data, form, handleSubmit)
              }}
            >
              Add manager
            </Button>,
          ]}
        >
          <Box className={styles.fieldsContainer}>
            <TableFields
              rows={[
                [
                  <InputLabel
                    key="managerIdLabel"
                    htmlFor="managerId"
                    size="normal"
                    position="horizontal"
                  >
                    Relationship Manager
                  </InputLabel>,
                ],
                [
                  <SelectField
                    border
                    selectSize="medium"
                    key="managerIdInput"
                    className={styles.managerSelect}
                    name="managerId"
                    options={formOptions}
                    placeholder="Select manager"
                  />,
                ],
              ]}
            />
          </Box>
        </Modal>
      )}
    />
  )
}

export default AddRelationshipManager
