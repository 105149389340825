import React, { useState, useCallback } from 'react'
import Link from '@mui/material/Link'
import styles from './ProspectAnalysisQueueTable.module.scss'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import { OPSReportingFlowStatus, IProspectReporting } from '@common/interfaces/prospects'
import { buildFiltersDefaults } from '../../helpers/filters'
import { ONGOING_REPORTING_LIST_FILTERS_CONFIG } from '@common/constants/filters'
import { generatePath, Link as RouterLink } from 'react-router-dom'
import { CircularProgress } from '@mui/material'
import { MenuIcon } from '../Common/Icons'
import { usePermissions } from '../../helpers/permissionContext'

export const filtersDefaults = buildFiltersDefaults(ONGOING_REPORTING_LIST_FILTERS_CONFIG, {
  statuses: [
    OPSReportingFlowStatus.FileSelect,
    OPSReportingFlowStatus.Mapping,
    OPSReportingFlowStatus.Complete,
  ],
})

const ACTION_LABEL = {
  [OPSReportingFlowStatus.FileSelect]: 'Select file',
  [OPSReportingFlowStatus.Mapping]: 'Map',
  [OPSReportingFlowStatus.Complete]: 'View',
}

interface IProps {
  item: IProspectReporting
  updateOngoingReportingStatus: (
    opsReportingId: string,
    status: string,
    type: string,
  ) => Promise<void>
  reportingRoutes: any
  opsReportingId: string
  type: string
}

const ProspectAnalysisQueueActions = ({
  item,
  updateOngoingReportingStatus,
  opsReportingId,
  reportingRoutes,
  type,
}: IProps) => {
  const [anchorEl, setAnchorEl] = useState(null)
  const [menuOpen, setMenuOpen] = useState(false)
  const [isWaiveSaving, setIsWaiveSaving] = useState(false)

  const handleClickMenu = useCallback((event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
    setMenuOpen(true)
  }, [])

  const handleCloseMenu = useCallback(() => {
    setAnchorEl(null)
    setMenuOpen(false)
  }, [])

  const handleWaive = useCallback(async () => {
    setIsWaiveSaving(true)
    await updateOngoingReportingStatus(opsReportingId, OPSReportingFlowStatus.Waived, type)
    setIsWaiveSaving(false)
    handleCloseMenu()
  }, [opsReportingId, updateOngoingReportingStatus, type, handleCloseMenu])

  const handleUnWaive = useCallback(async () => {
    setIsWaiveSaving(true)
    await updateOngoingReportingStatus(opsReportingId, OPSReportingFlowStatus.FileSelect, type)
    setIsWaiveSaving(false)
    handleCloseMenu()
  }, [opsReportingId, updateOngoingReportingStatus, type, handleCloseMenu])

  const { isUW } = usePermissions()

  return (
    <>
      <MenuIcon isActive={menuOpen} onClick={handleClickMenu} />
      <Menu
        classes={{ paper: styles.iconMenu }}
        open={menuOpen}
        onClose={handleCloseMenu}
        anchorEl={anchorEl}
      >
        {((!isUW && ACTION_LABEL[item.status]) ||
          (isUW && item.status === OPSReportingFlowStatus.Complete)) && (
          <MenuItem classes={{ root: styles.iconMenuItem }}>
            <Link
              className={styles.linkBtn}
              component={RouterLink}
              to={generatePath(reportingRoutes[item.type], {
                id: item.opsReportingId,
              })}
            >
              {ACTION_LABEL[item.status]}
            </Link>
          </MenuItem>
        )}

        {!isUW &&
          (item.status === OPSReportingFlowStatus.Waived ? (
            <MenuItem classes={{ root: styles.iconMenuItem }} onClick={handleUnWaive}>
              {isWaiveSaving ? <CircularProgress size={20} /> : 'Add to queue'}
            </MenuItem>
          ) : item.status !== OPSReportingFlowStatus.Complete ? (
            <MenuItem classes={{ root: styles.iconMenuItem }} onClick={handleWaive}>
              {isWaiveSaving ? <CircularProgress size={20} /> : 'Waive'}
            </MenuItem>
          ) : null)}
      </Menu>
    </>
  )
}

export default ProspectAnalysisQueueActions
