import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'

import BBCLogsContainer from './BBCLogsContainer'

import { listBBCLog, hideBBCLog } from '../../redux/bbcLog/actions'
import { data } from '../../redux/bbcLog/selectors'
import { listUsers } from '../../redux/user/actions'
import { list as users } from '../../redux/user/selectors'
import { addNote } from '../../redux/notes/actions'

const selector = createStructuredSelector({
  data,
  users,
})

const actions = {
  listBBCLog,
  hideBBCLog,
  listUsers,
  addNote,
}

export default connect(selector, actions)(BBCLogsContainer)
