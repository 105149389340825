import React, { useCallback, useEffect } from 'react'
import { useHistory, useLocation } from 'react-router'
import queryString from 'query-string'
import Stepper from '@mui/material/Stepper'
import Step from '@mui/material/Step'
import StepLabel from '@mui/material/StepLabel'
import StepConnector from '@mui/material/StepConnector'
import { StepIconProps } from '@mui/material/StepIcon'
import CircularProgress from '@mui/material/CircularProgress'
import cn from 'classnames'

import styles from './DueDiligenceFullscreenModal.module.scss'

import { ReactComponent as ArrowLeft } from '@assets/images/left-point.svg'
import { ReactComponent as CompletedStepIcon } from '@assets/images/dd-step-completed.svg'
import { ReactComponent as ActiveStepIcon } from '@assets/images/dd-step-active.svg'
import Modal from '../Common/Modal'
import Button from '../Common/Button/Button'
import SaveState from '../Common/SaveState'

interface IStep {
  title: string
  isActive: boolean
  isCompleted: boolean
}

const DueDiligenceFullscreenModalStepperStepIcon = (props: StepIconProps) => {
  const { active, completed, icon } = props
  if (completed) {
    return (
      <CompletedStepIcon
        className={cn(
          styles.ddModalStepperStepItemIcon,
          styles.ddModalStepperStepItemIconCompleted,
        )}
      />
    )
  }
  if (active) {
    return (
      <ActiveStepIcon
        className={cn(styles.ddModalStepperStepItemIcon, styles.ddModalStepperStepItemIconActive)}
      />
    )
  }

  return (
    <div
      className={cn(styles.ddModalStepperStepItemIcon, styles.ddModalStepperStepItemIconText, {
        [styles.ddModalStepperStepItemIconActive]: active,
        [styles.ddModalStepperStepItemIconInactive]: !active,
      })}
    >
      {icon}
    </div>
  )
}

const DueDiligenceFullscreenModalStepperStepItem = ({
  index,
  step,
  current,
  active,
  completed,
  onClick,
}: {
  index: number
  step: string
  current: boolean
  active: boolean
  completed: boolean
  onClick: (step: number) => void
}) => {
  const handleClick = useCallback(() => {
    onClick(index)
  }, [onClick, index])

  return (
    <Step index={index} active={active} completed={completed} onClick={handleClick}>
      <StepLabel
        className={cn({
          [styles.ddModalStepperStepItemRootActive]: current,
        })}
        classes={{
          root: styles.ddModalStepperStepItemRoot,
          iconContainer: cn(styles.ddModalStepperStepItemIconContainer, {
            [styles.ddModalStepperStepItemIconContainerCurrent]: current,
          }),
          label: cn(styles.ddModalStepperStepItemLabel, {
            [styles.ddModalStepperStepItemLabelCurrent]: current,
          }),
          // active: styles.ddModalStepperStepItemLabelActive,
          completed: styles.ddModalStepperStepItemLabelCompleted,
        }}
        StepIconComponent={DueDiligenceFullscreenModalStepperStepIcon}
      >
        {step}
      </StepLabel>
    </Step>
  )
}

const DueDiligenceFullscreenModalStepper = ({
  steps,
  activeStep,
  onStepChange,
}: {
  steps: IStep[]
  activeStep: number
  onStepChange: (step: number) => void
}) => {
  return (
    <Stepper
      nonLinear
      orientation="vertical"
      connector={
        <StepConnector
          classes={{
            root: styles.ddModalStepperStepConnectorRoot,
            line: styles.ddModalStepperStepConnectorLine,
          }}
        />
      }
    >
      {steps.map(({ title, isActive, isCompleted }, index) => (
        <DueDiligenceFullscreenModalStepperStepItem
          key={title}
          index={index}
          step={title}
          current={index === activeStep}
          active={isActive}
          completed={isCompleted}
          onClick={onStepChange}
        />
      ))}
    </Stepper>
  )
}

const DueDiligenceFullscreenModal = ({
  children,
  step,
  setStep,
  steps,
  progress,
  header,
  actions,
  onCloseModal,
  onBack,
  showBackButtonOnFirstStep = false,
  onNext,
  nextButtonText = 'Next',
  nextButtonClassName,
  isDisabled = false,
  isLoading = false,
  isSaving = false,
  isSaved = false,
}: {
  children: React.ReactNode
  step: number
  setStep: (step: number) => void
  steps: IStep[]
  progress: number
  header: string
  actions?: React.ReactNode
  onCloseModal: () => void
  onBack?: () => void
  showBackButtonOnFirstStep?: boolean
  onNext: () => void
  nextButtonText?: string
  nextButtonClassName?: string
  isDisabled?: boolean
  isLoading?: boolean
  isSaving?: boolean
  isSaved?: boolean
}) => {
  const history = useHistory()
  const { search }: { search: string } = useLocation()

  useEffect(() => {
    const { modal } = queryString.parse(search)

    if (modal) {
      history.replace({
        search: queryString.stringify({
          modal,
          step,
        }),
      })
    }
  }, [step, search, history])

  const handleStepChange = useCallback(
    (step: number) => {
      setStep(step)
    },
    [setStep],
  )

  return (
    <Modal
      classes={{ root: styles.ddModal, close: styles.ddModalClose, body: styles.ddModalBody }}
      open
      onCancel={onCloseModal}
      disableEnforceFocus
    >
      <div className={styles.ddModalContainer}>
        <div className={styles.ddModalStepperWrapper}>
          {onBack && step !== 0 && (
            <ArrowLeft className={styles.ddModalGoBackIcon} onClick={onBack} />
          )}

          <div className={styles.ddModalStepperProgressContainer}>
            <CircularProgress
              classes={{
                root: styles.ddModalStepperProgress,
                circle: styles.ddModalStepperProgressCircleBackground,
              }}
              variant="determinate"
              value={100}
              size={96}
            />
            <CircularProgress
              classes={{
                root: styles.ddModalStepperProgress,
                circle: styles.ddModalStepperProgressCircle,
              }}
              variant="determinate"
              value={progress}
              size={96}
            />
            <div className={styles.ddModalStepperProgressText}>
              <span>{progress}%</span>
            </div>
          </div>

          <div className={styles.ddModalStepper}>
            <DueDiligenceFullscreenModalStepper
              activeStep={step}
              steps={steps}
              onStepChange={handleStepChange}
            />
          </div>
        </div>

        <div className={styles.ddModalContentWrapper}>
          <div className={styles.ddModalHeaderWrapper}>
            <div className={styles.ddModalHeader}>
              {header}
              {actions}
            </div>
            <SaveState className={styles.ddModalSaveState} isSaving={isSaving} isSaved={isSaved} />
          </div>

          <div className={styles.ddModalContentContainer}>
            <div className={styles.ddModalContent}>{children}</div>

            <div className={styles.ddModalContentButtonWrapper}>
              {onBack && (showBackButtonOnFirstStep || step !== 0) ? (
                <Button
                  className={styles.ddModalContentButton}
                  small={false}
                  variant="outlined"
                  disabled={isDisabled}
                  onClick={onBack}
                >
                  Back
                </Button>
              ) : (
                <div />
              )}

              <Button
                className={cn(
                  styles.ddModalContentButton,
                  styles.ddModalContentButtonNext,
                  nextButtonClassName,
                )}
                small={false}
                variant="contained"
                disabled={isDisabled}
                isLoading={isLoading}
                onClick={onNext}
              >
                {nextButtonText}
              </Button>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  )
}

export default DueDiligenceFullscreenModal
