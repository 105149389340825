import React, { ReactNode, useCallback, useState } from 'react'
import Backdrop from '@mui/material/Backdrop'
import Slide from '@mui/material/Slide'
import MuiModal from '@mui/material/Modal'
import cn from 'classnames'

import styles from './NoteExpandModal.module.scss'

interface IProps {
  handleClose: () => void
  children: ReactNode
  bodyClassName?: string
}

const NoteExpandModal = ({ handleClose, children, bodyClassName }: IProps) => {
  const [isOpened, setIsOpened] = useState(false)
  const toggleIsOpened = useCallback(() => {
    setIsOpened((isOpened) => !isOpened)
  }, [])

  return (
    <MuiModal
      open
      onClose={handleClose}
      disableEnforceFocus
      slots={{ backdrop: Backdrop }}
      slotProps={{
        backdrop: {
          timeout: 300,
          className: styles.modalBackdrop,
        },
      }}
      className={styles.modalWrapper}
    >
      <Slide
        direction="left"
        in
        timeout={300}
        mountOnEnter
        unmountOnExit
        onEnter={toggleIsOpened}
        onExit={toggleIsOpened}
      >
        <div
          className={cn(styles.modal, {
            [styles.modalOpened]: isOpened,
          })}
        >
          <div className={cn(styles.modalBody, bodyClassName)}>{children}</div>
        </div>
      </Slide>
    </MuiModal>
  )
}

export default NoteExpandModal
