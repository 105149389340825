import Base from './base'
import { IRequiredReport } from '@common/interfaces/requiredReport'

export default class RequiredReport extends Base {
  create(data: Partial<IRequiredReport> & { clientId: string }) {
    return this.apiClient.post('required-reports', data)
  }

  list(clientId: string) {
    return this.apiClient.get('required-reports', { clientId })
  }

  update(id: string, data: Partial<IRequiredReport>) {
    return this.apiClient.put(`required-reports/${id}`, data)
  }

  delete(id: string) {
    return this.apiClient.delete(`required-reports/${id}`)
  }
}
