import React, { useMemo } from 'react'
import { generatePath, Link } from 'react-router-dom'
import Box from '@mui/material/Box'
import MuiAvatar from '@mui/material/Avatar'
import LinkButton from '@mui/material/Link'

import styles from './GlobalSearchPage.module.scss'
import genericSs from '@styles/generic.module.scss'

import { IBorrowingBaseIndex } from '@common/interfaces/bbc'
import TableRow from '../../components/Common/TableRow'
import TableCell from '../../components/Common/TableCell'
import { ROUTES } from '../../constants/routes'
import { formatDate, formatPrice } from '../../helpers/helpers'
import { BoxLink } from '../../components/Common/Icons'
import { replaceJSX } from './GlobalSearchPage'

const GlobalSearchPageTableRowFunding = ({
  bbc,
  searchString,
}: {
  bbc: IBorrowingBaseIndex
  searchString: string
}) => {
  const title = useMemo(
    () => [bbc.clientName, bbc.isTest ? 'Test BBC' : '', formatDate(bbc.recordDate)].join(' '),
    [bbc],
  )

  return (
    <TableRow>
      <TableCell className={genericSs.tableTextLeft}>
        <LinkButton component={Link} to={generatePath(ROUTES.BBC_SUMMARY, { id: bbc.id })}>
          {replaceJSX(title, searchString)}
        </LinkButton>
      </TableCell>
      <TableCell className={genericSs.tableTextLeft}>
        <Box display="flex" alignItems="center" gap={2}>
          {bbc.clientIconUrl && (
            <MuiAvatar
              src={bbc.clientIconUrl}
              alt={bbc.clientName}
              className={styles.clientAvatar}
            />
          )}
          <LinkButton component={Link} to={generatePath(ROUTES.CLIENT_PAGE, { id: bbc.clientId })}>
            {replaceJSX(bbc.clientName, searchString)}
          </LinkButton>
        </Box>
      </TableCell>
      <TableCell className={genericSs.tableTextRight}>
        <span className={genericSs.pricePrefix}>$</span>
        {replaceJSX(formatPrice(bbc.amount), searchString)}
      </TableCell>
      <TableCell className={genericSs.tableTextRight}>
        <span className={genericSs.pricePrefix}>$</span>
        {replaceJSX(formatPrice(bbc.arAvailability), searchString)}
      </TableCell>
      <TableCell className={genericSs.tableTextRight}>
        <span className={genericSs.pricePrefix}>$</span>
        {replaceJSX(formatPrice(bbc.inventoryAvailabilityPostSubLimit), searchString)}
      </TableCell>
      <TableCell className={genericSs.tableTextLeft}>
        {bbc.boxLink && <BoxLink link={bbc.boxLink} />}
      </TableCell>
    </TableRow>
  )
}

export default GlobalSearchPageTableRowFunding
