import React, { useCallback, useMemo, useState } from 'react'
import Popover from '@mui/material/Popover'
import cn from 'classnames'

import styles from './EntityPreview.module.scss'

import { ReactComponent as InfoIcon } from '@assets/images/info-icon.svg'
import { IEntityPreviewInfo } from '@common/interfaces/entityInfo'
import { WorkflowTypes } from '@common/interfaces/notes'
import EntityPreviewInfo from './EntityPreviewInfo'
import EntityPreviewClientInfo from './EntityPreviewClientInfo'

interface IProps {
  id: string
  workflow: WorkflowTypes
  getEntityPreview: (id: string, data: object) => Promise<any>
  isEligiblePage?: boolean
  isClientUserPage?: boolean
  className?: string
  itemId?: string
}

const EntityPreview = ({
  id,
  workflow,
  getEntityPreview,
  isEligiblePage,
  isClientUserPage,
  className,
  itemId,
}: IProps) => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)
  const [isLoading, setIsLoading] = useState(false)
  const [entityInfo, setEntityInfo] = useState<IEntityPreviewInfo | null>(null)
  const isOpen = useMemo(() => Boolean(anchorEl), [anchorEl])

  const handleLoadEntityInfo = useCallback(
    async (event) => {
      event.stopPropagation()
      event.preventDefault()

      setAnchorEl(event.currentTarget)

      if (!id || entityInfo?.id === id) {
        return
      }

      setIsLoading(true)
      const result = await getEntityPreview(id, {
        workflow,
        isEligiblePage,
        isClientUserPage,
        itemId,
      })
      setIsLoading(false)
      if (!result?.error) {
        setEntityInfo(result.data)
      }
    },
    [id, getEntityPreview, entityInfo, workflow, isEligiblePage, isClientUserPage, itemId],
  )

  const handleClose = useCallback(
    (event) => {
      event.stopPropagation()
      setAnchorEl(null)
    },
    [setAnchorEl],
  )

  return (
    <div
      className={cn(styles.wrapper, {
        [styles.isOpen]: isOpen,
      })}
    >
      <InfoIcon className={cn(styles.icon, className)} onClick={handleLoadEntityInfo} />

      <Popover
        open={isOpen}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        classes={{
          paper: cn(styles.popover, {
            [styles.popoverSmall]: isClientUserPage,
          }),
        }}
      >
        {isClientUserPage ? (
          <EntityPreviewClientInfo isLoading={isLoading} entityInfo={entityInfo} />
        ) : (
          <EntityPreviewInfo
            isLoading={isLoading}
            entityInfo={entityInfo}
            workflow={workflow}
            isEligiblePage={isEligiblePage}
          />
        )}
      </Popover>
    </div>
  )
}

export default EntityPreview
