import { IClientInfo } from './client'
import { IUser } from './user'
import { RawDraftContentState } from 'draft-js'
import { IBorrowingBase, IOngoingReporting, IFlags } from './bbc'
import { IOPSReporting } from './prospects'
import { IEntityInfo } from './entityInfo'
import { IDueDiligence } from './dueDiligence'
import { IBBCLog } from './bbcLog'

export enum WorkflowTypes {
  bbc = 'Borrowing Base',
  ongoingReporting = 'Ongoing Reporting',
  opsFlow = 'Ops Flow',
  entityPage = 'Entity Page',
  clientPage = 'Client Page',
  dueDiligencePage = 'Due Diligence Page',
  homePage = 'Home Page',
  clientManagement = 'Client Management',
}

export const Pages = {
  bbc: 'BBC',
  financials: 'Financials',
  salesBySKU: 'Sales By SKU',
  projections: 'Projections',
  arGeneralLedger: 'AR General Ledger',
  clientPage: 'Client Page',
  ops: 'OPS',
  dueDiligence: 'Due Diligence',
  entity: 'Entity',
}

export enum NotesFilterOptions {
  Pinned = 'Pinned',
  Open = 'Open',
  CallPrep = 'Call Prep',
  Resolved = 'Resolved',
  CallAgenda = 'Call Agenda',
  Archived = 'Waived',
  All = 'All',
}

export const GENERAL_NOTES_FILTER_OPTIONS = [
  NotesFilterOptions.All,
  NotesFilterOptions.Pinned,
  NotesFilterOptions.Open,
  NotesFilterOptions.Resolved,
]

export const DD_NOTES_FILTER_OPTIONS = [
  NotesFilterOptions.Open,
  NotesFilterOptions.CallAgenda,
  NotesFilterOptions.All,
  NotesFilterOptions.Resolved,
  NotesFilterOptions.Archived,
].filter((option) => option !== NotesFilterOptions.Pinned)

export const workflowMappings = {
  bbc: WorkflowTypes.bbc,
  'ongoing-reporting': WorkflowTypes.ongoingReporting,
  prospects: WorkflowTypes.opsFlow,
  entity: WorkflowTypes.entityPage,
  clients: WorkflowTypes.clientPage,
  'client-setup': WorkflowTypes.clientPage,
  'due-diligence': WorkflowTypes.dueDiligencePage,
  'client-management': WorkflowTypes.clientManagement,
}

export const opsWorkflowToSalesforceFieldMapping = {
  receivables: 'Latest_AR_Comments__c',
  payables: 'Latest_AP_Comments__c',
  inventory: 'Latest_Inventory_Comments__c',
  'income-statement': 'Latest_Financial_Comments__c',
  'balance-sheet': 'Latest_Financial_Comments__c',
  'income-statement-projections': 'Latest_Financial_Comments__c',
  'balance-sheet-projections': 'Latest_Financial_Comments__c',
  summary: 'General_Comments__c',
  committee: 'Questions_for_Committee__c',
}
export interface INoteMention {
  id: string
  entityId: string
  noteId: string
  entity?: IEntityInfo
}
export interface IAttachment {
  fileName: string
  contentType: string
  attachmentSrc: string
}

export interface ITranscript {
  id: string
  date: Date
  transcriptUrl: string
}

export interface IFirefliesSentence {
  id: string
  startTime: number
  transcript: ITranscript
}

export interface ITag {
  id: string
  tag: string
  firefliesSentence: IFirefliesSentence
}

export interface INote {
  id: string
  workflow: string
  note: RawDraftContentState
  noteText: string
  flagId: string
  flags: IFlags
  bbcLog: IBBCLog[]
  entityFlagId: string
  clientName: string
  createdAt: Date
  resolved: boolean
  pinned: boolean
  user: IUser
  children: INote[]
  noteMentions: INoteMention[]
  borrowingBase: IBorrowingBase
  opsReporting: IOPSReporting
  ongoingReporting: IOngoingReporting
  ddInfo: IDueDiligence
  clientInfo: IClientInfo
  attachments: IAttachment[]
  updatedAt?: Date
  isEdited?: boolean
  isNoteTextEdited?: boolean
  noteTextUpdatedAt?: string
  isArchived?: boolean
  isCallAgenda?: boolean
  tags: ITag[]
  dragOrder: number
}

export interface INotesData {
  data: INote[]
  total: number
  distinctTags: string[]
  dataByTags: Record<NotesTags, INote[]>
}

export interface INoteSuggestion {
  id: string
  name: string
  type: string | null
  clientId: string | null
}

export interface INoteMapping {
  id: string
  clientName: string
  clientId: string
  clientIconUrl: string
  noteText: string
  createdAt: string
  workflow: string
  entities: string[]
  userFirstName: string
  userLastName: string
  borrowingBaseId: string
  borrowingBaseRecordDate: string
  opsReportingId: string
  ongoingReportingId: string
  ongoingReportingType: string
  ongoingReportingRecordDate: string
}

export enum NotesTags {
  CorporateStructure = 'Corporate Structure',
  HistoricalIncomeStatement = 'Historical Income Statement',
  HistoricalBalanceSheet = 'Historical Balance Sheet',
  Forecast = 'Forecast',
  AccountsReceivable = 'Accounts Receivable',
  AccountsPayable = 'Accounts Payable',
  Inventory = 'Inventory',
  GeneralCompany = 'General Company',
  EquityRaises = 'Equity Financing & Investors',
  DebtFinancing = 'Current & Prior Debt',
  AccountingLegal = 'Accounting & Legal',
}

export const TAG_ORDER = [
  NotesTags.HistoricalIncomeStatement,
  NotesTags.HistoricalBalanceSheet,
  NotesTags.Forecast,
  NotesTags.AccountsReceivable,
  NotesTags.AccountsPayable,
  NotesTags.Inventory,
  NotesTags.EquityRaises,
  NotesTags.DebtFinancing,
  NotesTags.CorporateStructure,
  NotesTags.AccountingLegal,
  NotesTags.GeneralCompany,
]

export const TAG_COLOR_MAPPING = {
  [NotesTags.CorporateStructure]: { backgroundColor: '#FBECC6', color: '#7C6C42' },
  [NotesTags.HistoricalIncomeStatement]: { backgroundColor: '#FFFAE5', color: '#78630C' },
  [NotesTags.HistoricalBalanceSheet]: { backgroundColor: '#DBE6DB', color: '#578357' },
  [NotesTags.Forecast]: { backgroundColor: '#F0ECEC', color: '#734545' },
  [NotesTags.AccountsReceivable]: { backgroundColor: '#BEC6EB', color: '#535E91' },
  [NotesTags.AccountsPayable]: { backgroundColor: '#CDDEEF', color: '#476C91' },
  [NotesTags.Inventory]: { backgroundColor: '#FCF2F3', color: '#7C393F' },
  [NotesTags.GeneralCompany]: { backgroundColor: '#DFE1E0', color: '#3B6D54' },
  [NotesTags.EquityRaises]: { backgroundColor: '#D5DEFD', color: '#455EB2' },
  [NotesTags.DebtFinancing]: { backgroundColor: '#DDD3AC', color: '#655519' },
  [NotesTags.AccountingLegal]: { backgroundColor: '#EF8A84', color: '#761818' },
}
