import React, { useEffect, useCallback, useState, useMemo } from 'react'

import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import { useLocation, useHistory, generatePath } from 'react-router-dom'
import queryString from 'query-string'
import styles from './CollectionsWiresPage.module.scss'
import { ReactComponent as HomeIcon } from '@assets/images/home-icon.svg'
import { useSetPageTitle } from '../../hooks/useSetPageTitle'
import WiresUploads from '../../components/WiresUploads'
import Card from '../../components/Common/Card'
import { IWireDataAggregation, WireDataStatus } from '@common/interfaces/collection'
import Button from '../../components/Common/Button'
import { ActivityStatus } from '@common/interfaces/activity'
import CollectionsWiresReconciliationTable from '../../components/CollectionsWiresMapping/CollectionsWiresReconciliationTable'
import CollectionsWiresMapping from '../../components/CollectionsWiresMapping'
import moment from 'moment'
import { ROUTES } from '../../constants/routes'
import { ActivityType } from '@common/interfaces/activity'
import {
  historyChangeEventHandler,
  visibilityChangeEventHandler,
  formatDate,
  unFormatPrice,
} from '../../helpers/helpers'
import Breadcrumbs from '../../components/Common/Breadcrumbs'
import { ILoadingData } from '../../redux/types'
import Skeleton from '@mui/material/Skeleton'
interface IProps {
  wiresData: ILoadingData<IWireDataAggregation>
  wiresDataNegative: ILoadingData<IWireDataAggregation>
  wiresDataDeleted: ILoadingData<IWireDataAggregation>
  collectionStatus?: string
  uploadWires: (data: FormData) => void
  listNewWires: (params: object) => void
  listDeletedWires: (params: object) => void
  listNegativeWires: (params: object) => void
  saveWires: (data: object) => void
  listWireFilesAndStatus: (id: string) => void
  updateWire: (id: string, data: object) => void
  updateWires: (data: object) => void
  restoreWire: (id: string, data: object) => void
  deleteWire: (id: string, data: object) => void
  trackActivity: (data: object, beacon?: boolean) => void
}

const CollectionsWiresPage = ({
  wiresData,
  wiresDataNegative,
  wiresDataDeleted,
  collectionStatus,
  uploadWires,
  saveWires,
  listNewWires,
  listDeletedWires,
  listNegativeWires,
  listWireFilesAndStatus,
  updateWire,
  updateWires,
  restoreWire,
  deleteWire,
  trackActivity,
}: IProps) => {
  const {
    wiresDataLoaded,
    wiresDataNegativeLoaded,
    wiresDataDeletedLoaded,
    isWiresLoading,
    allDataLoading,
  } = useMemo(
    () => ({
      wiresDataLoaded: wiresData?.data,
      wiresDataNegativeLoaded: wiresDataNegative?.data,
      wiresDataDeletedLoaded: wiresDataDeleted?.data,
      isWiresLoading: wiresData?.isLoading,
      allDataLoading:
        wiresData?.isLoading && wiresDataNegative?.isLoading && wiresDataDeleted?.isLoading,
    }),
    [wiresData, wiresDataNegative, wiresDataDeleted],
  )

  const totalAmount = useMemo(
    () =>
      wiresDataLoaded?.totalAmount +
      wiresDataNegativeLoaded?.totalAmount +
      wiresDataDeletedLoaded?.totalAmount,
    [wiresDataLoaded, wiresDataNegativeLoaded, wiresDataDeletedLoaded],
  )
  const [accounts, setAccounts] = useState({
    bank: 0,
    operating: 0,
    concentration: 0,
    discrepancy: +totalAmount || 0,
  })

  const wireDate = useMemo(
    () =>
      wiresDataLoaded?.data?.length
        ? formatDate(wiresDataLoaded.data?.[0].recordDate)
        : wiresDataNegativeLoaded?.data?.length
        ? formatDate(wiresDataNegativeLoaded.data?.[0].recordDate)
        : wiresDataDeletedLoaded?.data?.length
        ? formatDate(wiresDataDeletedLoaded.data?.[0].recordDate)
        : '',
    [wiresDataLoaded, wiresDataNegativeLoaded, wiresDataDeletedLoaded],
  )

  useSetPageTitle('Wires ' + wireDate)

  const { search, pathname }: { search: string; pathname: string } = useLocation()
  const qs = useMemo(() => queryString.parse(search), [search])
  const history = useHistory()

  const mappingProgress = useMemo(
    () =>
      ((wiresDataLoaded?.mappedCount + wiresDataNegativeLoaded?.mappedCount) * 100) /
      (wiresDataLoaded?.totalCount + wiresDataNegativeLoaded?.totalCount),
    [wiresDataLoaded, wiresDataNegativeLoaded],
  )

  const refetchWireFilesAndStatus = useCallback(() => {
    if (qs?.activityId && typeof qs?.activityId === 'string') {
      listWireFilesAndStatus(qs?.activityId)
    }
  }, [qs, listWireFilesAndStatus])

  useEffect(() => {
    refetchWireFilesAndStatus()
  }, [refetchWireFilesAndStatus])

  useEffect(() => {
    setAccounts((accountsData) => ({
      ...accountsData,
      discrepancy:
        Math.round(
          (+(totalAmount || 0) -
            unFormatPrice(accountsData.bank) +
            unFormatPrice(accountsData.operating) +
            unFormatPrice(accountsData.concentration)) *
            100,
        ) / 100,
    }))
  }, [wiresDataLoaded, totalAmount])

  const [startTime, setStartTime] = useState(moment().toISOString())

  const [isSavingButton, setIsSavingButton] = useState(false)

  const breadcrumbs = useMemo(() => {
    return [
      {
        link: ROUTES.HOMEPAGE,
        Icon: HomeIcon,
      },
      {
        link: ROUTES.ACTIVITY_QUEUE,
        title: 'Operations',
      },
      {
        link: generatePath(ROUTES.COLLECTIONS_WIRES + search),
        title: `Wires ${wireDate}`,
      },
    ]
  }, [search, wireDate])

  const logActivity = useCallback(
    (beacon: boolean = false) => {
      if (qs?.activityId) {
        const endTime = moment().toISOString()
        const data = {
          activityId: qs?.activityId,
          startedAt: startTime,
          finishedAt: endTime,
          workflow: ActivityType.Wire,
          step: 'Mapping',
        }
        trackActivity(data, beacon)
        setStartTime(endTime)
      }
    },
    [qs, startTime, trackActivity],
  )
  useEffect(() => {
    const unlisten = historyChangeEventHandler(logActivity, history, pathname)
    return unlisten
  }, [history, pathname, logActivity])

  useEffect(() => {
    const unlisten = visibilityChangeEventHandler(logActivity)
    return unlisten
  }, [logActivity])

  const handleSubmit = useCallback(async () => {
    setIsSavingButton(true)
    logActivity()
    await saveWires({ activityId: qs.activityId })
    setIsSavingButton(false)
  }, [saveWires, qs, logActivity, setIsSavingButton])

  const handleListWires = useCallback(
    (params: any, type?: WireDataStatus) => {
      switch (type) {
        case WireDataStatus.Negative:
          listNegativeWires(params)
          break
        case WireDataStatus.New:
          listNewWires(params)
          break
        case WireDataStatus.Deleted:
          listDeletedWires(params)
          break
        default:
          break
      }
    },
    [listNegativeWires, listNewWires, listDeletedWires],
  )

  return (
    <Box py={1} pr={2}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Box mb={2}>
            {!isWiresLoading ? (
              <Breadcrumbs breadcrumbs={breadcrumbs} noMarginLeft />
            ) : (
              <Skeleton height={15} width={300} />
            )}
          </Box>
          <Box
            display="flex"
            justifyContent="space-between"
            pt={2}
            paddingTop={'15'}
            alignItems="center"
            mb={3}
          >
            <WiresUploads uploadWires={uploadWires} />
            <div className={styles.submitButtonContainer}>
              <Button
                type="button"
                disabled={!!accounts.discrepancy || mappingProgress !== 100}
                color="primary"
                variant="contained"
                size="small"
                onClick={handleSubmit}
                isLoading={isSavingButton}
              >
                {collectionStatus === ActivityStatus.Review ? 'Submit' : 'Submit for review'}
              </Button>
            </div>
          </Box>
        </Grid>
        <CollectionsWiresMapping
          handleListWires={handleListWires}
          updateWire={updateWire}
          updateWires={updateWires}
          restoreWire={restoreWire}
          deleteWire={deleteWire}
        />

        <Grid item xs={6} marginBottom={'24px'} paddingTop={'0 !important'}>
          <Card title="Bank account reconciliation">
            <CollectionsWiresReconciliationTable
              accounts={accounts}
              setAccounts={setAccounts}
              wiresDataTotal={totalAmount}
              allDataLoading={allDataLoading}
            />
          </Card>
        </Grid>
        <Grid
          container
          xs={12}
          marginBottom={'24px'}
          justifyContent={'flex-end'}
          paddingLeft={'24px'}
        >
          <div className={styles.submitButtonContainer}>
            <Button
              type="button"
              disabled={!!accounts.discrepancy || mappingProgress !== 100}
              color="primary"
              variant="contained"
              size="small"
              onClick={handleSubmit}
              isLoading={isSavingButton}
            >
              {collectionStatus === ActivityStatus.Review ? 'Submit' : 'Submit for review'}
            </Button>
          </div>
        </Grid>
      </Grid>
    </Box>
  )
}

export default CollectionsWiresPage
