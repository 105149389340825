import React, { useCallback } from 'react'

import { DueDiligenceTableItemType, IDueDiligenceContact } from '@common/interfaces/dueDiligence'
import DueDiligenceTable from '../DueDiligenceTable'
import { ILoadingData } from '../../redux/types'

interface IProps {
  dueDiligenceTeamContacts: ILoadingData<{ data: IDueDiligenceContact[] }>
  listTeamContacts: (params: object) => void
  hideTeamContacts: () => void
  addTeamContact: (data: object) => Promise<void>
  updateTeamContact: (id: string, data: object) => Promise<void>
  deleteTeamContact: (id: string) => Promise<void>
}

const DueDiligenceTeamContacts = ({
  dueDiligenceTeamContacts,
  listTeamContacts,
  hideTeamContacts,
  addTeamContact,
  updateTeamContact,
  deleteTeamContact,
}: IProps) => {
  const handleAddContact = useCallback(
    async (data: any) => {
      const { isResponsibleRequestingFunding, isAuthorizedSignature, ...rest } = data

      return addTeamContact({
        isResponsibleRequestingFunding: isResponsibleRequestingFunding
          ? isResponsibleRequestingFunding === 'true'
          : null,
        isAuthorizedSignature: isAuthorizedSignature ? isAuthorizedSignature === 'true' : null,
        ...rest,
      })
    },
    [addTeamContact],
  )
  const handleUpdateContact = useCallback(
    async (id: string, data: any) => {
      const {
        firstName,
        lastName,
        email,
        phone,
        isResponsibleRequestingFunding,
        isAuthorizedSignature,
        ...rest
      } = data

      return updateTeamContact(id, {
        firstName: firstName || '',
        lastName: lastName || '',
        email: email || '',
        phone: phone || null,
        isResponsibleRequestingFunding: isResponsibleRequestingFunding
          ? isResponsibleRequestingFunding === 'true'
          : null,
        isAuthorizedSignature: isAuthorizedSignature ? isAuthorizedSignature === 'true' : null,
        ...rest,
      })
    },
    [updateTeamContact],
  )

  return (
    <DueDiligenceTable
      type={DueDiligenceTableItemType.Contact}
      data={dueDiligenceTeamContacts}
      handleList={listTeamContacts}
      handleHide={hideTeamContacts}
      handleAdd={handleAddContact}
      handleUpdate={handleUpdateContact}
      handleDelete={deleteTeamContact}
    />
  )
}

export default DueDiligenceTeamContacts
