import React from 'react'
import { generatePath } from 'react-router-dom'
import { useParams } from 'react-router'
import { IProspectARSummary } from '@common/interfaces/prospects'
import { ILoadingData } from '../../redux/types'
import { ROUTES } from '../../constants/routes'
import SummaryTable from '../ProspectArApSummary'

interface IProps {
  listARSummary: (id: string, params: object) => void
  arSummary: ILoadingData<IProspectARSummary>
}

const ProspectReportingSummaryReceivables = ({ arSummary, listARSummary }: IProps) => {
  const { id } = useParams<{ id: string }>()

  return (
    <SummaryTable
      reportType="AR"
      isSummary
      listSummary={listARSummary}
      isDataLimited
      summaryData={arSummary}
      readOnly
      editLink={generatePath(ROUTES.PROSPECT_REPORTING_RECEIVABLES, { id })}
    />
  )
}

export default ProspectReportingSummaryReceivables
