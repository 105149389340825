import React, { useCallback, useState, useMemo, useEffect } from 'react'
import { useHistory, useLocation } from 'react-router'
import queryString from 'query-string'
import Skeleton from '@mui/material/Skeleton'
import Grid from '@mui/material/Grid'
import cn from 'classnames'

import styles from './DueDiligenceClientDocumentRequestsPage.module.scss'
import genericSs from '@styles/generic.module.scss'

import { ReactComponent as PriorityIcon } from '@assets/images/priority-icon.svg'
import {
  DUE_DILIGENCE_DOCUMENTS_TYPES_WITHOUT_UPLOAD,
  DueDiligenceDocumentRequestStatus,
  IDueDiligence,
  IDueDiligenceDocumentRequest,
} from '@common/interfaces/dueDiligence'
import Tabs from '../../components/Common/Tabs'
import TableContainer from '../../components/Common/TableContainer'
import Table from '../../components/Common/Table'
import TableHead from '../../components/Common/TableHead'
import TableBody from '../../components/Common/TableBody'
import TableRow from '../../components/Common/TableRow'
import TableCell from '../../components/Common/TableCell'
import { ExpandDetailIcon } from '../../components/Common/Icons'
import TableLoader from '../../components/Common/TableLoader'
import Button from '../../components/Common/Button'
import { ILoadingData } from '../../redux/types'
import DueDiligenceClientDocumentRequestModal from '../../components/DueDiligenceClientDocumentRequestModal'
import { sortAndGroupDueDiligenceDocumentsRequests } from '../../helpers/helpers'

const TABS = ['Incomplete documents', 'Completed documents']

const DueDiligenceClientDocumentRequestsRow = ({
  documentRequest,
  onSelectDocumentRequest,
}: {
  documentRequest: IDueDiligenceDocumentRequest
  onSelectDocumentRequest: (documentRequest: IDueDiligenceDocumentRequest) => void
}) => {
  const handleSelectDocumentRequest = useCallback(() => {
    onSelectDocumentRequest(documentRequest)
  }, [documentRequest, onSelectDocumentRequest])

  const withoutFiles = useMemo(
    () => DUE_DILIGENCE_DOCUMENTS_TYPES_WITHOUT_UPLOAD.includes(documentRequest.type.type),
    [documentRequest],
  )

  return (
    <TableRow>
      <TableCell className={genericSs.tableTextLeft}>
        <div className={styles.nameWrapper}>
          {documentRequest.type.isPriority && <PriorityIcon />}
          <div className={styles.name}>{documentRequest.type.name}</div>
          {documentRequest.files.length > 0 && !withoutFiles && (
            <div className={styles.count}>{documentRequest.files.length}</div>
          )}
        </div>
      </TableCell>
      <TableCell className={genericSs.tableTextLeft}>
        <span
          className={cn(styles.status, {
            [styles.statusNotStarted]:
              documentRequest.status === DueDiligenceDocumentRequestStatus.NotStarted,
            [styles.statusInReview]:
              documentRequest.status === DueDiligenceDocumentRequestStatus.InReview,
            [styles.statusActionRequired]:
              documentRequest.status === DueDiligenceDocumentRequestStatus.ActionRequired,
            [styles.statusCompleted]:
              documentRequest.status === DueDiligenceDocumentRequestStatus.Completed ||
              documentRequest.status === DueDiligenceDocumentRequestStatus.Waived,
          })}
        >
          {documentRequest.status}
        </span>
      </TableCell>
      <TableCell className={genericSs.tableTextLeft}>
        <Button className={styles.button} variant="outlined" onClick={handleSelectDocumentRequest}>
          {documentRequest.status === DueDiligenceDocumentRequestStatus.ActionRequired ||
          withoutFiles
            ? 'View details'
            : 'Upload'}
        </Button>
      </TableCell>
    </TableRow>
  )
}

const DueDiligenceClientDocumentRequestsSection = ({
  name,
  data,
  handleSelectDocumentRequest,
}: {
  name: string
  data: IDueDiligenceDocumentRequest[]
  handleSelectDocumentRequest: (documentRequest: IDueDiligenceDocumentRequest) => void
}) => {
  const [isExpanded, setIsExpanded] = useState(true)

  const toggleIsExpanded = useCallback(() => {
    setIsExpanded((isExpanded) => !isExpanded)
  }, [])

  return (
    <React.Fragment>
      {data.length > 0 && (
        <TableRow>
          <TableCell className={cn(genericSs.tableTextLeft, styles.headingRow)} colSpan={3}>
            <ExpandDetailIcon onClick={toggleIsExpanded} isExpanded={isExpanded} />
            {name} ({data.length})
          </TableCell>
        </TableRow>
      )}
      {isExpanded &&
        data.map((item) => (
          <DueDiligenceClientDocumentRequestsRow
            key={item.id}
            documentRequest={item}
            onSelectDocumentRequest={handleSelectDocumentRequest}
          />
        ))}
    </React.Fragment>
  )
}

const DueDiligenceClientDocumentRequestsTable = ({
  isLoading,
  dueDiligenceInfo,
  data,
  handleSelectDocumentRequest,
}: {
  isLoading: boolean
  dueDiligenceInfo: IDueDiligence
  data: {
    companyBackground: IDueDiligenceDocumentRequest[]
    team: IDueDiligenceDocumentRequest[]
    financials: IDueDiligenceDocumentRequest[]
    sample: IDueDiligenceDocumentRequest[]
  }
  handleSelectDocumentRequest: (documentRequest: IDueDiligenceDocumentRequest) => void
}) => {
  return (
    <TableContainer className={styles.table}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell className={genericSs.tableTextLeft}>Documents</TableCell>
            <TableCell className={genericSs.tableTextLeft}>Status</TableCell>
            <TableCell className={genericSs.tableTextLeft}>Action</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {isLoading && <TableLoader columnsCount={3} rowsCount={10} height={36} />}
          <DueDiligenceClientDocumentRequestsSection
            name="Company Background"
            data={data.companyBackground}
            handleSelectDocumentRequest={handleSelectDocumentRequest}
          />
          <DueDiligenceClientDocumentRequestsSection
            name={`${dueDiligenceInfo.clientName} Team`}
            data={data.team}
            handleSelectDocumentRequest={handleSelectDocumentRequest}
          />
          <DueDiligenceClientDocumentRequestsSection
            name="Financials and Collateral"
            data={data.financials}
            handleSelectDocumentRequest={handleSelectDocumentRequest}
          />
          <DueDiligenceClientDocumentRequestsSection
            name="Third Party Testing - Samples"
            data={data.sample}
            handleSelectDocumentRequest={handleSelectDocumentRequest}
          />
        </TableBody>
      </Table>
    </TableContainer>
  )
}

interface IProps {
  dueDiligenceInfo: IDueDiligence
  dueDiligenceDocumentRequests: ILoadingData<{ data: IDueDiligenceDocumentRequest[] }>
  showDocumentRequests: (params?: any) => void
  uploadDocumentRequestsFiles: (data: FormData) => void
  getDocumentRequestsSharedLink: (documentRequestId: string) => void
  deleteFile: (ids: string[]) => Promise<void>
  createDocumentRequestComment: (
    documentRequestId: string,
    data: object,
    id?: string,
  ) => Promise<any>
  updateDocumentRequestComment: (
    documentRequestId: string,
    commentId: string,
    data: object,
    id?: string,
  ) => Promise<any>
  deleteDocumentRequestComment: (
    documentRequestId: string,
    commentId: string,
    id?: string,
  ) => Promise<any>
}

const DueDiligenceClientDocumentRequestsPage = ({
  dueDiligenceInfo,
  dueDiligenceDocumentRequests,
  showDocumentRequests,
  uploadDocumentRequestsFiles,
  getDocumentRequestsSharedLink,
  createDocumentRequestComment,
  updateDocumentRequestComment,
  deleteDocumentRequestComment,
  deleteFile,
}: IProps) => {
  const history = useHistory()
  const { search }: { search: string } = useLocation()
  const { documentRequestId } = queryString.parse(search) as { documentRequestId: string }

  const [selectedDocumentRequest, setSelectedDocumentRequest] =
    useState<IDueDiligenceDocumentRequest>(null)
  const [currentTab, setCurrentTab] = useState(TABS[0])

  useEffect(() => {
    if (dueDiligenceDocumentRequests?.data?.data) {
      setSelectedDocumentRequest((selectedDocumentRequest) =>
        selectedDocumentRequest
          ? dueDiligenceDocumentRequests.data.data.find(
              ({ id }) => id === selectedDocumentRequest.id,
            )
          : null,
      )
    }
  }, [dueDiligenceDocumentRequests.data])

  const filterByStatus = useMemo(
    () => (items: IDueDiligenceDocumentRequest[], completed: boolean) => {
      return items.filter(({ status }) =>
        completed
          ? status === DueDiligenceDocumentRequestStatus.Completed
          : status !== DueDiligenceDocumentRequestStatus.Completed &&
            status !== DueDiligenceDocumentRequestStatus.Waived,
      )
    },
    [],
  )

  const { isLoading, isSaving, incompleted, completed } = useMemo(() => {
    const { companyBackground, team, financials, samples } =
      sortAndGroupDueDiligenceDocumentsRequests(dueDiligenceDocumentRequests?.data?.data || [])

    return {
      isLoading: dueDiligenceDocumentRequests.isLoading,
      isSaving: dueDiligenceDocumentRequests.isSaving,
      completed: {
        companyBackground: filterByStatus(companyBackground, true),
        team: filterByStatus(team, true),
        financials: filterByStatus(financials, true),
        sample: filterByStatus(samples, true),
      },
      incompleted: {
        companyBackground: filterByStatus(companyBackground, false),
        team: filterByStatus(team, false),
        financials: filterByStatus(financials, false),
        sample: filterByStatus(samples, false),
      },
    }
  }, [dueDiligenceDocumentRequests, filterByStatus])

  const handleSelectDocumentRequest = useCallback(
    (documentRequest: IDueDiligenceDocumentRequest) => {
      history.replace({
        search: queryString.stringify({
          documentRequestId: documentRequest.id,
        }),
      })
      setSelectedDocumentRequest(documentRequest)
    },
    [history],
  )

  const handleCloseDocumentRequestModal = useCallback(() => {
    history.replace({
      search: null,
    })
    setSelectedDocumentRequest(null)
  }, [history])

  useEffect(() => {
    if (documentRequestId && !selectedDocumentRequest && dueDiligenceDocumentRequests.data?.data) {
      const documentRequest = dueDiligenceDocumentRequests.data.data.find(
        ({ id }) => id === documentRequestId,
      )
      if (documentRequest) {
        setSelectedDocumentRequest(documentRequest)
      }
    }
  }, [documentRequestId, selectedDocumentRequest, dueDiligenceDocumentRequests])

  const handleFileUpload = useCallback(
    async (loadedFiles: File[]) => {
      const formData = new FormData()

      setSelectedDocumentRequest({
        ...selectedDocumentRequest,
        files: [
          ...selectedDocumentRequest.files,
          ...loadedFiles.map((file) => ({
            ...file,
            id: null,
            name: file.name,
            fileName: file.name,
            isUploading: true,
          })),
        ],
      })

      for (const file of loadedFiles) {
        formData.append('files[]', file, file.name)
      }
      formData.append('documentRequestId', selectedDocumentRequest.id)

      await uploadDocumentRequestsFiles(formData)
    },
    [selectedDocumentRequest, uploadDocumentRequestsFiles],
  )

  const handleFilesDelete = useCallback(
    async (ids: string[]) => {
      await deleteFile(ids)
      await showDocumentRequests({ skipLoading: true })
    },
    [deleteFile, showDocumentRequests],
  )

  const handleSaveDocumentRequestComment = useCallback(
    async (data: any) => {
      if (data.id) {
        return updateDocumentRequestComment(selectedDocumentRequest.id, data.id, {
          comment: data.comment,
        })
      } else {
        return createDocumentRequestComment(selectedDocumentRequest.id, data)
      }
    },
    [selectedDocumentRequest, createDocumentRequestComment, updateDocumentRequestComment],
  )

  const handleDeleteDocumentRequestComment = useCallback(
    async (commentId: string) => {
      return deleteDocumentRequestComment(selectedDocumentRequest.id, commentId)
    },
    [selectedDocumentRequest, deleteDocumentRequestComment],
  )

  if (!dueDiligenceInfo) {
    return (
      <div className={styles.container}>
        <Skeleton height={68} width={420} />
        <Skeleton height={54} width={230} />
        <Skeleton height={24} width={350} />
      </div>
    )
  }

  return (
    <Grid container py={1} pr={2} alignItems="flex-start" justifyContent="center" rowSpacing={4}>
      <Grid
        item
        xs={12}
        alignItems="center"
        justifyContent="center"
        gap={3}
        mt={4}
        className={styles.container}
      >
        <div className={styles.tabContainer}>
          <Tabs tabs={TABS} selected={currentTab} handleChange={setCurrentTab} />
        </div>
        <h1 className={cn(genericSs.dueDiligenceHeader, styles.header)}>Document requests</h1>
        <div className={styles.description}>
          {currentTab === TABS[0]
            ? `Review previously submitted and outstanding reporting`
            : `See the status of uploaded documents and any related comments`}
        </div>

        <DueDiligenceClientDocumentRequestsTable
          isLoading={isLoading}
          dueDiligenceInfo={dueDiligenceInfo}
          data={currentTab === TABS[0] ? incompleted : completed}
          handleSelectDocumentRequest={handleSelectDocumentRequest}
        />

        {selectedDocumentRequest && (
          <DueDiligenceClientDocumentRequestModal
            isSaving={isSaving}
            documentRequest={selectedDocumentRequest}
            handleUploadFiles={handleFileUpload}
            handleDeleteFiles={handleFilesDelete}
            handleViewSample={getDocumentRequestsSharedLink}
            handleClose={handleCloseDocumentRequestModal}
            handleSaveComment={handleSaveDocumentRequestComment}
            handleDeleteComment={handleDeleteDocumentRequestComment}
          />
        )}
      </Grid>
    </Grid>
  )
}

export default DueDiligenceClientDocumentRequestsPage
