import React, { useCallback, useState } from 'react'

export interface OptionType {
  label?: string
  value?: string
  inputValue?: string
  type?: string
  isNew?: boolean
}

interface IProps {
  isAsync: boolean
  onInputChangeDefault?: (
    event: React.SyntheticEvent,
    inputValue: string,
    reason: 'input' | 'reset' | 'clear',
  ) => void
  loadOptions?: (inputValue: string) => Promise<OptionType[]>
}

const useLoadedOptions = ({ isAsync, onInputChangeDefault, loadOptions }: IProps) => {
  const [asyncOptions, setAsyncOptions] = useState<OptionType[]>([])

  const handleInputChange = useCallback(
    async (
      event: React.SyntheticEvent,
      inputValue: string,
      reason: 'input' | 'reset' | 'clear',
    ) => {
      if (!isAsync && onInputChangeDefault) {
        onInputChangeDefault(event, inputValue, reason)
      }
      if (reason === 'input' && inputValue.length > 1 && loadOptions) {
        const loadedOptions = await loadOptions(inputValue)
        setAsyncOptions(loadedOptions)
      } else {
        setAsyncOptions([])
      }
    },
    [isAsync, loadOptions, onInputChangeDefault],
  )

  return {
    asyncOptions,
    handleInputChange,
  }
}

export default useLoadedOptions
