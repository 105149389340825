import React, { useState, useCallback, useMemo, useEffect, useRef } from 'react'
import { useParams } from 'react-router'
import cn from 'classnames'
import Tooltip from '@mui/material/Tooltip'
import InfiniteScroll from 'react-infinite-scroll-component'

import styles from './BBCARDetailRow.module.scss'
import genericSs from '@styles/generic.module.scss'

import ActiveToolbar from '../ActiveToolbar'
import TableContainer from '../Common/TableContainer'
import Table from '../Common/Table'
import TableHead from '../Common/TableHead'
import TableBody from '../Common/TableBody'
import TableRow from '../Common/TableRow'
import TableCell from '../Common/TableCell'
import TableFiltersRow from '../Common/TableFiltersRow'
import { BBCReceivablesGroupBy, IArSummaryStats, ReportingFlow } from '@common/interfaces/bbc'
import { debounceEventHandler, formatPrice, handleMultipleSelect } from '../../helpers/helpers'
import ChangedValue from '../ChangedValue'
import { BBC_AR_SUMMARY_DETAILS_LIST_FILTERS_CONFIG, PER_PAGE } from '@common/constants/filters'
import TableLoader from '../Common/TableLoader'
import { useReportingFlow } from '../../hooks/useReportingFlow'

interface IProps {
  arDetailStats: IArSummaryStats[]
  getBBCARDetailStats: (id: string, data: object) => void
  filters: any
  recordDate: string
  isModalShown: boolean
  debtor: string
  reportingFlow: ReportingFlow
  clientName?: string
  compareDate?: string
  groupBy?: BBCReceivablesGroupBy
}

const BBCARDetailRow = ({
  arDetailStats,
  getBBCARDetailStats,
  filters,
  isModalShown,
  recordDate,
  debtor,
  clientName,
  compareDate,
  groupBy,
  reportingFlow,
}: IProps) => {
  const { id } = useParams<{ id: string }>()
  const wrapperRef = useRef(null)

  const [isLoading, setIsLoading] = useState(true)
  const [activeItem, setActiveItem] = useState<number>()
  const [activeItems, setActiveItems] = useState([])
  const [orderBy, setOrderBy] = useState({
    field: 'invoice',
    direction: 'ASC',
  })

  const { isClientPage, isClientUserPage, isLCRPage, isBBC } = useReportingFlow({ reportingFlow })

  const filtersConfig = useMemo(
    () =>
      BBC_AR_SUMMARY_DETAILS_LIST_FILTERS_CONFIG.filter(
        (config) =>
          isBBC ||
          isClientPage ||
          (isLCRPage && !['ineligible', 'eligible'].includes(config.field)) ||
          (isClientUserPage &&
            !['daysPastInvoice', 'ineligible', 'eligible'].includes(config.field)),
      ).filter((config) => {
        if (groupBy === BBCReceivablesGroupBy.Parent) {
          return config.field !== 'memo'
        } else {
          return config.field !== 'debtor'
        }
      }),
    [isBBC, groupBy, isClientPage, isClientUserPage, isLCRPage],
  )

  const handleSelectRow = useMemo(() => handleMultipleSelect(setActiveItems, setActiveItem), [])
  const resetActiveItems = useCallback(() => setActiveItems([]), [])

  const handleOrderChange = useCallback((field: string) => {
    setOrderBy((order) => ({
      field,
      direction: order.field === field ? (order.direction === 'DESC' ? 'ASC' : 'DESC') : 'ASC',
    }))
  }, [])

  const debounceListArSummary = useMemo(
    () =>
      debounceEventHandler(async (data: any) => {
        !data.loadMore && setIsLoading(true)
        await getBBCARDetailStats(id, {
          ...data,
          recordDate,
          debtor,
          reportingFlow,
          compareDate,
          groupBy,
          clientName,
          nestedRows: {
            keys: ['debtor'],
          },
        })
        setIsLoading(false)
      }, 500),
    [id, getBBCARDetailStats, recordDate, compareDate, groupBy, debtor, reportingFlow, clientName],
  )

  const loadMore = useCallback(() => {
    debounceListArSummary({
      loadMore: true,
      orderBy: orderBy.field,
      orderDirection: orderBy.direction,
      filters,
      recordDate,
      page: Math.ceil(arDetailStats?.length / PER_PAGE),
    })
  }, [orderBy, filters, recordDate, debounceListArSummary, arDetailStats])

  const totalCount = useMemo(() => arDetailStats?.[0]?.totalCount, [arDetailStats])

  useEffect(() => {
    !arDetailStats &&
      debounceListArSummary({
        orderBy: orderBy.field,
        orderDirection: orderBy.direction,
        filters,
        page: 0,
      })
  }, [orderBy, filters, debounceListArSummary, arDetailStats])

  useEffect(() => {
    debounceListArSummary({
      orderBy: orderBy.field,
      orderDirection: orderBy.direction,
      filters,
      page: 0,
    })
  }, [orderBy, filters, debounceListArSummary])

  const totalRow = useMemo(
    () =>
      arDetailStats
        ?.filter((_, index) => activeItems.includes(index))
        .reduce(
          (totalRowResult, row) => {
            totalRowResult.daysPastInvoice += row.daysPastInvoice / activeItems?.length || 0
            totalRowResult.ar_1To_30Days += row.ar_1To_30Days || 0
            totalRowResult.ar_31To_60Days += row.ar_31To_60Days || 0
            totalRowResult.ar_61To_90Days += row.ar_61To_90Days || 0
            totalRowResult.ar_91PlusDays += row.ar_91PlusDays || 0
            totalRowResult.eligible += row.eligible || 0
            totalRowResult.ineligible += row.ineligible || 0
            totalRowResult.total += row.total || 0
            totalRowResult.percent += row.percent || 0
            return totalRowResult
          },
          {
            daysPastInvoice: 0,
            ar_1To_30Days: 0,
            ar_31To_60Days: 0,
            ar_61To_90Days: 0,
            ar_91PlusDays: 0,
            eligible: 0,
            ineligible: 0,
            total: 0,
            percent: 0,
          },
        ),
    [activeItems, arDetailStats],
  )

  return (
    <TableContainer
      className={cn(styles.table, {
        [styles.tableGroupedByParent]: groupBy === BBCReceivablesGroupBy.Parent,
      })}
      onActiveRowsChange={setActiveItems}
      onActiveRowChange={setActiveItem}
      hasFooter
      size="small"
    >
      <Table ref={wrapperRef}>
        <TableHead>
          <TableFiltersRow
            filters={filtersConfig}
            orderBy={orderBy}
            handleOrderChange={handleOrderChange}
          />
        </TableHead>
        <TableBody id={debtor} className={styles.scrollableDiv}>
          {isLoading ? (
            <TableLoader columnsCount={filtersConfig?.length} />
          ) : (
            <InfiniteScroll
              dataLength={arDetailStats?.length || 0}
              next={loadMore}
              hasMore={arDetailStats?.length < totalCount}
              loader={<TableLoader columnsCount={filtersConfig?.length} rowsCount={1} />}
              scrollableTarget={debtor}
            >
              {arDetailStats?.map((item, index) => {
                const customer = groupBy === BBCReceivablesGroupBy.Parent ? item.debtor : item.memo

                return (
                  <TableRow
                    key={item.invoice}
                    data-index={index}
                    className={cn('activableRow', {
                      activeRow: activeItems.includes(index),
                      currentActiveRow: activeItem === index,
                    })}
                    onClick={(event) => handleSelectRow(event, index)}
                  >
                    <TableCell className={genericSs.tableTextLeft}>
                      <Tooltip
                        title={customer || ''}
                        placement="top"
                        disableHoverListener={customer?.length < 10 || !customer}
                        disableTouchListener
                      >
                        <span>{customer}</span>
                      </Tooltip>
                    </TableCell>

                    <TableCell className={genericSs.tableTextLeft}>
                      <Tooltip
                        title={item.invoice || ''}
                        placement="top"
                        disableHoverListener={item.invoice?.length < 10 || !item.invoice}
                        disableTouchListener
                      >
                        <span>{item.invoice}</span>
                      </Tooltip>
                    </TableCell>

                    {!isClientUserPage && (
                      <TableCell className={genericSs.tableTextRight}>
                        <ChangedValue
                          isChip
                          className={styles.changedValue}
                          changedValue={item.daysPastInvoiceChanged}
                        />
                        {Math.round(+item?.daysPastInvoice)}
                      </TableCell>
                    )}
                    <TableCell className={genericSs.tableTextRight}>
                      {!isClientUserPage && (
                        <ChangedValue
                          isChip
                          className={styles.changedValue}
                          changedValue={item.ar_1To_30DaysChanged}
                        />
                      )}
                      <span className={genericSs.pricePrefix}>$</span>
                      {formatPrice(item.ar_1To_30Days)}
                    </TableCell>
                    <TableCell className={genericSs.tableTextRight}>
                      {!isClientUserPage && (
                        <ChangedValue
                          isChip
                          className={styles.changedValue}
                          changedValue={item.ar_31To_60DaysChanged}
                        />
                      )}
                      <span className={genericSs.pricePrefix}>$</span>
                      {formatPrice(item.ar_31To_60Days)}
                    </TableCell>
                    <TableCell className={genericSs.tableTextRight}>
                      {!isClientUserPage && (
                        <ChangedValue
                          isChip
                          className={styles.changedValue}
                          changedValue={item.ar_61To_90DaysChanged}
                        />
                      )}
                      <span className={genericSs.pricePrefix}>$</span>
                      {formatPrice(item.ar_61To_90Days)}
                    </TableCell>
                    <TableCell className={genericSs.tableTextRight}>
                      {!isClientUserPage && (
                        <ChangedValue
                          isChip
                          className={styles.changedValue}
                          changedValue={item.ar_91PlusDaysChanged}
                        />
                      )}
                      <span className={genericSs.pricePrefix}>$</span>
                      {formatPrice(item.ar_91PlusDays)}
                    </TableCell>
                    {!isClientUserPage && !isLCRPage && (
                      <TableCell className={cn(genericSs.tableTextRight, styles.totals)}>
                        {!isClientUserPage && (
                          <ChangedValue
                            isChip
                            className={styles.changedValue}
                            changedValue={item.ineligibleChanged}
                          />
                        )}
                        <span className={genericSs.pricePrefix}>$</span>
                        {formatPrice(item.ineligible)}
                      </TableCell>
                    )}
                    {!isClientUserPage && !isLCRPage && (
                      <TableCell className={cn(genericSs.tableTextRight, styles.totals)}>
                        <ChangedValue
                          isChip
                          className={styles.changedValue}
                          changedValue={item.eligibleChanged}
                        />
                        <span className={genericSs.pricePrefix}>$</span>
                        {formatPrice(item.eligible)}
                      </TableCell>
                    )}
                    <TableCell className={cn(genericSs.tableTextRight, styles.totals)}>
                      {!isClientUserPage && (
                        <ChangedValue
                          isChip
                          className={styles.changedValue}
                          changedValue={item.totalChanged}
                        />
                      )}
                      <span className={genericSs.pricePrefix}>$</span>
                      {formatPrice(item.total)}
                    </TableCell>
                    <TableCell className={cn(genericSs.tableTextRight, styles.totals)}>
                      {!isClientUserPage && (
                        <ChangedValue
                          className={styles.changedValue}
                          isChip
                          type="percent"
                          changedValue={item.percentChanged}
                        />
                      )}
                      {(item.percent || 0).toFixed(2)}%
                    </TableCell>
                  </TableRow>
                )
              })}
            </InfiniteScroll>
          )}
        </TableBody>
      </Table>
      <ActiveToolbar
        activeItems={activeItems}
        className={styles.activeToolbar}
        isFullscreen={isModalShown}
        containerRef={wrapperRef}
        resetActiveItems={resetActiveItems}
      >
        {!isClientUserPage && (
          <div className={genericSs.tableTextRight}>{Math.round(totalRow?.daysPastInvoice)}</div>
        )}
        <div className={genericSs.tableTextRight}>
          <span className={genericSs.pricePrefix}>$</span>
          {formatPrice(totalRow?.ar_1To_30Days)}
        </div>
        <div className={genericSs.tableTextRight}>
          <span className={genericSs.pricePrefix}>$</span>
          {formatPrice(totalRow?.ar_31To_60Days)}
        </div>
        <div className={genericSs.tableTextRight}>
          <span className={genericSs.pricePrefix}>$</span>
          {formatPrice(totalRow?.ar_61To_90Days)}
        </div>
        <div className={genericSs.tableTextRight}>
          <span className={genericSs.pricePrefix}>$</span>
          {formatPrice(totalRow?.ar_91PlusDays)}
        </div>
        {!isClientUserPage && !isLCRPage && (
          <div className={cn(genericSs.tableTextRight, styles.totals)}>
            <span className={genericSs.pricePrefix}>$</span>
            {formatPrice(totalRow?.ineligible)}
          </div>
        )}
        {!isClientUserPage && !isLCRPage && (
          <div className={cn(genericSs.tableTextRight, styles.totals)}>
            <span className={genericSs.pricePrefix}>$</span>
            {formatPrice(totalRow?.eligible)}
          </div>
        )}
        <div className={cn(genericSs.tableTextRight, styles.totals)}>
          <span className={genericSs.pricePrefix}>$</span>
          {formatPrice(totalRow?.total)}
        </div>
        <div className={cn(genericSs.tableTextRight, styles.totals)}>
          {(totalRow?.percent || 0).toFixed(2)}%
        </div>
      </ActiveToolbar>
    </TableContainer>
  )
}

export default BBCARDetailRow
