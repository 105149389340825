import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'

import ClientNewsfeedContainer from './ClientNewsfeedContainer'

import { clientNewsfeedData } from '../../redux/newsfeed/selectors'
import { listClientNewsfeed } from '../../redux/newsfeed/actions'

const selector = createStructuredSelector({
  clientNewsfeedData,
})
const actions = {
  listClientNewsfeed,
}

export default connect(selector, actions)(ClientNewsfeedContainer)
