import { combineReducers } from 'redux'
import * as actions from './actions'
import { IRequiredReport } from '@common/interfaces/requiredReport'
import { loadingArrayReducer } from '../../helpers/helpers'

export interface IRequiredReportState {
  loadingArray: string[]
  list: IRequiredReport[]
}

export default combineReducers<IRequiredReportState>({
  loadingArray: loadingArrayReducer([actions.prefix]),
  list(state: IRequiredReport[] = [], action) {
    switch (action.type) {
      case actions.CREATE_SUCCESS:
        return [...state, action.data]
      case actions.DELETE_SUCCESS:
        return state.filter(({ id }) => action.data.id !== id)
      case actions.UPDATE_SUCCESS:
        return state.map((report) =>
          report.id === action.data.id ? { ...report, ...action.data } : report,
        )
      case actions.LIST_SUCCESS:
        return action.data
      default:
        return state
    }
  },
})
