import { IApi } from '../../api'
import { CommonPromiseAction } from '../types'

export const prefix = 'arapmapping'

export const UPDATE_ALIAS_REQUEST = `${prefix}/UPDATE_ALIAS_REQUEST`
export const UPDATE_ALIAS_SUCCESS = `${prefix}/UPDATE_ALIAS_SUCCESS`
export const UPDATE_ALIAS_FAILURE = `${prefix}/UPDATE_ALIAS_FAILURE`

export const updateAlias: CommonPromiseAction = (data: any) => ({
  type: [UPDATE_ALIAS_REQUEST, UPDATE_ALIAS_SUCCESS, UPDATE_ALIAS_FAILURE],
  promise: (api: IApi) => api.bbc.updateAlias(data),
})

export const DELETE_ALIAS_REQUEST = `${prefix}/DELETE_ALIAS_REQUEST`
export const DELETE_ALIAS_SUCCESS = `${prefix}/DELETE_ALIAS_SUCCESS`
export const DELETE_ALIAS_FAILURE = `${prefix}/DELETE_ALIAS_FAILURE`

export const deleteAlias: CommonPromiseAction = (data: any) => ({
  type: [DELETE_ALIAS_REQUEST, DELETE_ALIAS_SUCCESS, DELETE_ALIAS_FAILURE],
  promise: (api: IApi) => api.bbc.deleteAlias(data),
})
