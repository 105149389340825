import React, { useState, useEffect, useCallback, useMemo, useRef } from 'react'
import { useParams } from 'react-router-dom'
import { Form } from 'react-final-form'
import Box from '@mui/material/Box'
import moment from 'moment'
import styles from './DilutionOverTime.module.scss'
import { DILUTION_OVER_TIME_LIST_FILTERS_CONFIG } from '@common/constants/filters'
import {
  CLIENT_GENERAL_LEDGER_DILUTION_FIELDS_LABELS,
  CLIENT_GENERAL_LEDGER_DILUTION_GRAPH_FIELDS,
  IClientGeneralLedgerDilutionOverTimeData,
  IDilutionDropwdowns,
} from '@common/interfaces/clientGeneralLedger'
import {
  IDebtorIneligibleCategories,
  IneligibleCategory,
  REPORTING_PERIOD_OPTIONS,
  ReportingFlow,
  ReportingPeriods,
} from '@common/interfaces/bbc'
import { debounceEventHandler } from '../../helpers/helpers'
import { buildFiltersDefaults, buildFiltersValidateSchema } from '../../helpers/filters'
import FilterContainer from '../Filters/FilterContainer'
import { ILoadingData } from '../../redux/types'
import { ExpandAndMinimize } from '../Common/Icons'
import FullscreenModal from '../Common/FullscreenModal'
import DatePicker from '../Common/DatePicker'
import SelectField from '../Common/SelectField'
import DilutionOverTimeTable from './DilutionOverTimeTable'
import DilutionOverTimeGraph from './DilutionOverTimeGraph'
import useTable from '../../hooks/useTable'
import useGraphToggle from '../../hooks/useGraphTogggle'
import { CATEGORIES } from '@common/constants/tracking'
import useTrackVisualizationsTable from '../../hooks/useTrackVisualizationsTable'
import useTrackVisualizationsTableChartSelection from '../../hooks/useTrackVisualizationsTableChartSelection'
import LinkButton from '@mui/material/Link'
import { Link as RouterLink } from 'react-router-dom'
import Empty from '../Common/Empty/Empty'

const filtersValidate = buildFiltersValidateSchema(DILUTION_OVER_TIME_LIST_FILTERS_CONFIG)
const filtersDefaults = buildFiltersDefaults(DILUTION_OVER_TIME_LIST_FILTERS_CONFIG)

const OPTIONS = CLIENT_GENERAL_LEDGER_DILUTION_GRAPH_FIELDS.map((field) => ({
  value: field,
  label: CLIENT_GENERAL_LEDGER_DILUTION_FIELDS_LABELS[field],
}))

interface IProps {
  reportingFlow: ReportingFlow
  dilutionOverTime: ILoadingData<IClientGeneralLedgerDilutionOverTimeData>
  listDilutionOverTime: (
    id: string,
    params?: {
      filters?: object
    },
  ) => void
  hideDilutionOverTime: () => void
  refreshCounter?: number
  debtorIneligibleCategories: ILoadingData<IDebtorIneligibleCategories>
  dilutionDropdowns: ILoadingData<IDilutionDropwdowns>
  editLink?: string
}

const DilutionOverTime = ({
  reportingFlow,
  dilutionOverTime,
  listDilutionOverTime,
  hideDilutionOverTime,
  refreshCounter,
  debtorIneligibleCategories,
  dilutionDropdowns,
  editLink,
}: IProps) => {
  const { id } = useParams<{ id: string }>()
  const wrapperRef = useRef(null)
  const [isModalShown, setIsModalShown] = useState(false)
  const [currentDateRange, setCurrentDateRange] = useState<{ startDate: string; endDate: string }>({
    startDate: '',
    endDate: '',
  })
  const [reportingPeriod, setReportingPeriod] = useState<ReportingPeriods>(ReportingPeriods.Monthly)
  const [option, setOption] = useState(CLIENT_GENERAL_LEDGER_DILUTION_GRAPH_FIELDS[0])
  const [options, setOptions] = useState(CLIENT_GENERAL_LEDGER_DILUTION_GRAPH_FIELDS)
  const [isInitialized, setIsInitialized] = useState(false)
  const { TabsComponent, isGraphShown } = useGraphToggle({})

  const isSummaryView = useMemo(
    () => [ReportingFlow.ClientPage, ReportingFlow.LoanCommitteeReport].includes(reportingFlow),
    [reportingFlow],
  )

  const {
    filters,
    handleFiltersChange,
    activeItem,
    activeItems,
    setActiveItem,
    setActiveItems,
    handleSelectRow,
    resetActiveItems,
    quickFilter,
    handleQuickFilterChange,
  } = useTable({
    tableId: 'dilutionOverTime',
    filtersDefaults,
  })

  const { isLoading, dilutionOverTimeData, dilutionOverTimeHeaders } = useMemo(
    () => ({
      isLoading: dilutionOverTime.isLoading,
      dilutionOverTimeData: dilutionOverTime?.data?.data || [],
      dilutionOverTimeHeaders: dilutionOverTime?.data?.headers || [],
    }),
    [dilutionOverTime],
  )

  const { debtors, minDate, maxDate, isDropdownsLoading } = useMemo(
    () => ({
      debtors: dilutionDropdowns?.data?.debtors || [],
      minDate: dilutionDropdowns?.data?.minDate || '',
      maxDate: dilutionDropdowns?.data?.maxDate || '',
      isDropdownsLoading: dilutionDropdowns?.isLoading,
    }),
    [dilutionDropdowns],
  )

  useEffect(() => {
    if (!isInitialized && minDate && maxDate) {
      if (isSummaryView) {
        setCurrentDateRange({
          startDate: moment
            .max([moment(minDate), moment(maxDate).subtract(1, 'year')])
            .format('YYYY-MM-DD'),
          endDate: maxDate,
        })
      } else {
        setCurrentDateRange({
          startDate: minDate || '',
          endDate: maxDate || '',
        })
      }
      setIsInitialized(true)
    }
  }, [isInitialized, dilutionOverTime, isSummaryView, minDate, maxDate, isDropdownsLoading])

  useEffect(() => {
    return () => {
      hideDilutionOverTime()
    }
  }, [hideDilutionOverTime])

  const datesBoundary = useMemo(
    () => ({
      minDate: minDate || null,
      maxDate: maxDate || null,
    }),
    [minDate, maxDate],
  )

  const fetchDilutionOverTime = useCallback(
    (data: any) => {
      const params = {
        ...data,
        filters: {
          ...data.filters,
        },
        startDate: currentDateRange.startDate,
        endDate: currentDateRange.endDate,
        reportingPeriod,
        reportingFlow,
      }
      currentDateRange.startDate && listDilutionOverTime(id, params)
    },
    [listDilutionOverTime, id, currentDateRange, reportingPeriod, reportingFlow],
  )

  const debounceListDilutionOverTime = useMemo(
    () => debounceEventHandler(fetchDilutionOverTime, 500),
    [fetchDilutionOverTime],
  )

  useEffect(() => {
    debounceListDilutionOverTime({
      filters,
    })
  }, [filters, debounceListDilutionOverTime])

  const refetchDilutionOverTime = useCallback(() => {
    fetchDilutionOverTime({
      filters,
    })
  }, [filters, fetchDilutionOverTime])

  useEffect(() => {
    if (refreshCounter) {
      refetchDilutionOverTime()
    }
  }, [refreshCounter, refetchDilutionOverTime])

  const handleModalToggle = useCallback(() => {
    setIsModalShown((prev) => !prev)
  }, [])

  const handleDateChange = useCallback(
    (values: { startDate: string; endDate: string }) => {
      setCurrentDateRange(values)
    },
    [setCurrentDateRange],
  )

  const ineligibleCategories = useMemo(
    () => debtorIneligibleCategories.data?.ineligibleCategories || [],
    [debtorIneligibleCategories],
  )

  const filtersConfig = useMemo(
    () =>
      DILUTION_OVER_TIME_LIST_FILTERS_CONFIG.map((item) => ({
        ...item,
        options:
          item.field === 'debtor'
            ? debtors.map((item) => ({
                label: item,
                value: item,
              }))
            : item.options,
      })).map((filter) =>
        filter.type === 'quickFilter'
          ? {
              ...filter,
              quickFilters: [
                {
                  title: 'Eligible Customers',
                  isHidden: true,
                  filters: {
                    ineligible_category: [
                      IneligibleCategory.Eligible,
                      IneligibleCategory.Foreign,
                      IneligibleCategory.Government,
                      IneligibleCategory.Intercompany,
                      IneligibleCategory.Other,
                      IneligibleCategory.Distressed,
                      IneligibleCategory.Affiliate,
                    ].filter((category) => ineligibleCategories.includes(category)),
                  },
                },
              ],
            }
          : filter,
      ),
    [debtors, ineligibleCategories],
  )

  const handleReportingPeriodChange = useCallback(({ target: { value } }) => {
    setReportingPeriod(value)
  }, [])

  const handleOptionChange = useCallback(({ target: { value } }) => {
    setOption(value)
  }, [])

  const handleOptionsChange = useCallback(({ target: { value } }) => {
    setOptions(value)
  }, [])

  const visualizationsParams = useMemo(
    () => ({
      [isSummaryView ? 'clientId' : 'ongoingReportingId']: id,
    }),
    [isSummaryView, id],
  )

  const visualizationsCategory = useMemo(
    () => (isGraphShown ? CATEGORIES.dilutionOverTimeChart : CATEGORIES.dilutionOverTimeTable),
    [isGraphShown],
  )

  const visualizationsFilters = useMemo(
    () => ({
      ...filters,
      latestSalesDate: currentDateRange.endDate,
      earliestSalesDate: currentDateRange.startDate,
      reportingPeriod: reportingPeriod,
      type: isGraphShown ? options : option,
    }),
    [filters, currentDateRange, reportingPeriod, isGraphShown, option, options],
  )

  useTrackVisualizationsTable({
    isInitialized,
    category: visualizationsCategory,
    params: visualizationsParams,
    filtersConfig: filtersConfig,
    filters: visualizationsFilters,
  })

  useTrackVisualizationsTableChartSelection({
    isInitialized,
    category: visualizationsCategory,
    params: visualizationsParams,
    isChart: !!isGraphShown,
  })

  const noDataAvailable = useMemo(
    () => !maxDate && !minDate && !isDropdownsLoading,
    [maxDate, minDate, isDropdownsLoading],
  )

  return (
    <FullscreenModal
      isOpen={isModalShown}
      setIsOpen={setIsModalShown}
      classes={{ body: styles.fullScreenModal }}
    >
      <>
        <Form
          validate={filtersValidate}
          onSubmit={handleFiltersChange}
          initialValues={filters}
          mutators={{
            setFieldData: ([field, value], state, { changeValue }) => {
              changeValue(state, field, () => value)
            },
          }}
          render={({ values, handleSubmit, form: { mutators } }) => (
            <FilterContainer
              filters={filtersConfig}
              handleSubmit={handleSubmit}
              mutators={mutators}
              values={values}
              appliedFilters={filters}
              appliedQuickFilter={quickFilter}
              handleAppliedQuickFilterChange={handleQuickFilterChange}
              title={
                <Box mr={2}>
                  <h2>
                    {isSummaryView ? 'Dilution Over Time' : ''}
                    {editLink && (
                      <LinkButton component={RouterLink} to={editLink} className={styles.editLink}>
                        Edit
                      </LinkButton>
                    )}
                  </h2>
                </Box>
              }
              actions={
                <Box display="flex" alignItems="center" gap={1}>
                  {!isGraphShown ? (
                    <SelectField
                      useFinalForm={false}
                      name="option"
                      value={option}
                      onChange={handleOptionChange}
                      className={styles.selectField}
                      label="Select"
                      variant="outlined"
                      options={OPTIONS}
                      disabled={isLoading}
                      fullWidth={false}
                    />
                  ) : (
                    <SelectField
                      useFinalForm={false}
                      name="option"
                      value={options}
                      onChange={handleOptionsChange}
                      className={styles.selectField}
                      label="Select"
                      variant="outlined"
                      options={OPTIONS}
                      disabled={isLoading}
                      fullWidth={false}
                      multiple
                    />
                  )}

                  <SelectField
                    useFinalForm={false}
                    name="reportingPeriod"
                    value={reportingPeriod}
                    onChange={handleReportingPeriodChange}
                    className={styles.selectField}
                    label="Period"
                    variant="outlined"
                    options={REPORTING_PERIOD_OPTIONS}
                    disabled={isLoading}
                    fullWidth={false}
                  />

                  <DatePicker
                    reportingPeriod={ReportingPeriods.Monthly}
                    currentDateRange={currentDateRange}
                    datesBoundary={datesBoundary}
                    onChange={handleDateChange}
                    disabled={isLoading}
                  />

                  {TabsComponent}

                  <ExpandAndMinimize action={handleModalToggle} isExpanded={isModalShown} />
                </Box>
              }
            />
          )}
        />
        {noDataAvailable ? (
          <Empty message="No dilution data is available" />
        ) : !isGraphShown ? (
          <DilutionOverTimeTable
            isLoading={isLoading}
            option={option}
            dilutionOverTimeHeaders={dilutionOverTimeHeaders}
            dilutionOverTimeData={dilutionOverTimeData}
            activeItem={activeItem}
            activeItems={activeItems}
            setActiveItem={setActiveItem}
            setActiveItems={setActiveItems}
            handleSelectRow={handleSelectRow}
            resetActiveItems={resetActiveItems}
            wrapperRef={wrapperRef}
            isModalShown={isModalShown}
          />
        ) : (
          <DilutionOverTimeGraph
            isModalShown={isModalShown}
            dilutionOverTimeHeaders={dilutionOverTimeHeaders}
            dilutionOverTimeData={dilutionOverTimeData}
            reportingPeriod={reportingPeriod}
            options={options}
          />
        )}
      </>
    </FullscreenModal>
  )
}

export default DilutionOverTime
