import { Location } from 'history'
import React, { useEffect, useState, useCallback, useMemo } from 'react'
import { Prompt } from 'react-router-dom'
import Modal from './../Modal'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import styles from './RouteLeavingGuard.module.scss'
import genericSs from '@styles/generic.module.scss'
import cn from 'classnames'
import { ReactComponent as ActionPendingIcon } from '../../../assets/images/action-pending.svg'
import Button from '../Button'
import { useBeforeUnload } from '../../../hooks/useBeforeUnload'

interface Props {
  when?: boolean | undefined
  navigate: (path: string) => void
  shouldBlockNavigation: (location: Location) => boolean
  helperText?: string
  buttonText?: string
  alternateSubmit?: (data: any) => void
  isAlternateSubmitInvalid?: boolean
}

const RouteLeavingGuard = ({
  when,
  navigate,
  shouldBlockNavigation,
  helperText = ` You haven't submitted your funding request. If you leave this page, all changes
  will be lost.`,
  buttonText = 'Submit Request',
  alternateSubmit,
  isAlternateSubmitInvalid,
}: Props) => {
  const [modalVisible, setModalVisible] = useState(false)
  const [lastLocation, setLastLocation] = useState<Location | null>(null)
  const [confirmedNavigation, setConfirmedNavigation] = useState(false)
  useBeforeUnload(when)

  const closeModal = useCallback(() => {
    setModalVisible(false)
  }, [setModalVisible])

  const handleBlockedNavigation = useCallback(
    (nextLocation: Location): boolean => {
      if (!confirmedNavigation && shouldBlockNavigation(nextLocation)) {
        setModalVisible(true)
        setLastLocation(nextLocation)
        return false
      }
      return true
    },
    [confirmedNavigation, shouldBlockNavigation, setModalVisible, setLastLocation],
  )

  const handleConfirmNavigationClick = useCallback(() => {
    setModalVisible(false)
    setConfirmedNavigation(true)
  }, [setModalVisible, setConfirmedNavigation])

  const handleAlternateSubmit = useCallback(
    (data) => {
      alternateSubmit && alternateSubmit(data)
      setModalVisible(false)
    },
    [alternateSubmit],
  )

  useEffect(() => {
    if (confirmedNavigation && lastLocation) {
      navigate(lastLocation.pathname)
    }
  }, [confirmedNavigation, lastLocation, navigate])

  const secondaryButtonType = useMemo(
    () => (isAlternateSubmitInvalid ? 'contained' : 'outlined'),
    [isAlternateSubmitInvalid],
  )

  return (
    <>
      <Prompt when={when} message={handleBlockedNavigation} />
      <Modal open={modalVisible} title="" onCancel={closeModal}>
        <Box
          className={styles.modalBody}
          display="flex"
          justifyContent="center"
          alignItems="space-between"
        >
          <Grid container spacing={1} justifyContent={'center'} alignItems={'center'}>
            <Grid
              item
              container
              spacing={1}
              xs={12}
              lg={12}
              justifyContent={'center'}
              alignItems={'center'}
            >
              <Grid item xs={12} lg={12} justifyContent={'center'} display={'flex'}>
                <ActionPendingIcon />
              </Grid>
            </Grid>
            <Grid
              item
              container
              spacing={1}
              xs={12}
              lg={12}
              justifyContent={'center'}
              alignItems={'center'}
            >
              <Grid item xs={12} lg={12}>
                <h2 className={genericSs.textCenter}>Are you sure?</h2>
              </Grid>
              <Grid item xs={12} lg={12} justifyContent={'center'} display={'flex'}>
                <div className={cn(genericSs.textCenter, styles.descriptionTitle)}>
                  {helperText}
                </div>
              </Grid>
              {!isAlternateSubmitInvalid && (
                <Grid item xs={12} lg={12} justifyContent={'center'} display={'flex'}>
                  <Button
                    variant="contained"
                    color="primary"
                    className={styles.button}
                    onClick={handleAlternateSubmit}
                    disabled={isAlternateSubmitInvalid}
                  >
                    {buttonText}
                  </Button>
                </Grid>
              )}
              <Grid item xs={12} lg={12} justifyContent={'center'} display={'flex'}>
                <Button
                  onClick={closeModal}
                  variant={secondaryButtonType}
                  color="primary"
                  className={styles.button}
                >
                  Take me back
                </Button>
              </Grid>
              <Grid item xs={12} lg={12} justifyContent={'center'} display={'flex'}>
                <span onClick={handleConfirmNavigationClick} className={styles.exit}>
                  Exit without saving
                </span>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Modal>
    </>
  )
}
export default RouteLeavingGuard
