import React, { useMemo } from 'react'
import { useParams } from 'react-router'
import { generatePath } from 'react-router-dom'
import Grid from '@mui/material/Grid'

import styles from './ParticipantDashboardHeader.module.scss'

import { ReactComponent as HomeIcon } from '@assets/images/home-icon.svg'
import { ROUTES } from '../../constants/routes'
import Breadcrumbs from '../Common/Breadcrumbs'
import { IParticipant } from '@common/interfaces/participant'
import { ILoadingData } from '../../redux/types'
import { SettingsIcon } from '../Common/Icons'
import { formatPercent, formatPrice } from '../../helpers/helpers'
import { ReactComponent as MetricSummary } from '../../assets/images/chart-line.svg'

interface ISummaryProps {
  participant?: IParticipant
}

const ParticipantSummaryMetrics = ({ participant }: ISummaryProps) => {
  return (
    <Grid item className={styles.headerContainer} xs={12}>
      {participant && (
        <div className={styles.dashboardBlock}>
          <div className={styles.dashboardBlockTitle}>
            <MetricSummary className={styles.dashboardBlockContentIcon} />
            Summary Metrics
          </div>
          <Grid container spacing={3} className={styles.gridContainer}>
            <Grid item xs={12} lg={12} className={styles.blockContainer}>
              <div className={styles.gridContainerWrapper}>
                <div className={styles.dashboardBlockContent}>
                  <div className={styles.dashboardBlockContentLabel}>Total Loan Balance</div>
                  <div className={styles.dashboardBlockContentValue}>
                    ${formatPrice(participant.totalLoanBalance)}
                  </div>
                </div>
                <div className={styles.dashboardBlockContent}>
                  <div className={styles.dashboardBlockContentLabel}>Exposure ($)</div>
                  <div className={styles.dashboardBlockContentValue}>
                    ${formatPrice(participant.totalLoanExposure)}
                  </div>
                </div>
                <div className={styles.dashboardBlockContent}>
                  <div className={styles.dashboardBlockContentLabel}>Exposure (%)</div>
                  <div className={styles.dashboardBlockContentValue}>
                    {formatPercent(participant.percentExposure)}
                  </div>
                </div>
                <div className={styles.dashboardBlockContent}>
                  <div className={styles.dashboardBlockContentLabel}>Total Clients</div>
                  <div className={styles.dashboardBlockContentValue}>
                    {participant.totalClients}
                  </div>
                </div>
              </div>
            </Grid>
          </Grid>
        </div>
      )}
    </Grid>
  )
}

interface IProps {
  participantInfo: ILoadingData<IParticipant>
}

const ParticipantDashboardHeader = ({ participantInfo }: IProps) => {
  const { id } = useParams<{ id: string }>()

  const { data: participantInfoData } = useMemo(() => participantInfo, [participantInfo])

  const breadcrumbs = useMemo(() => {
    return [
      {
        link: ROUTES.CLIENT_MANAGEMENT,
        Icon: HomeIcon,
      },
      {
        title: 'Participants',
        link: ROUTES.PARTICIPANTS_MANAGEMENT,
      },
      id && {
        link: generatePath(ROUTES.PARTICIPANTS_PAGE, { id }),
        title: participantInfoData?.participant,
      },
    ].filter(Boolean)
  }, [participantInfoData?.participant, id])

  const settingsLink = useMemo(() => {
    return generatePath(ROUTES.PARTICIPANT_SETTINGS, { id: participantInfoData?.id })
  }, [participantInfoData])

  return (
    <Grid container item xs={12} rowGap={2}>
      <Grid item xs={6} justifyContent={'flex-start'}>
        <Breadcrumbs breadcrumbs={breadcrumbs} />
      </Grid>
      <Grid
        className={styles.infoItems}
        item
        rowSpacing={1}
        columnSpacing={2}
        container
        xs={6}
        justifyContent={'flex-end'}
      >
        <Grid item>{participantInfoData?.id && <SettingsIcon link={settingsLink} />}</Grid>
      </Grid>
      <ParticipantSummaryMetrics participant={participantInfoData} />
    </Grid>
  )
}

export default ParticipantDashboardHeader
