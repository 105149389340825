import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import InfiniteScroll from 'react-infinite-scroll-component'
import useTable from '../../hooks/useTable'
import styles from './MarketNewsTable.module.scss'
import TableLoader from '../Common/TableLoader'
import { INewsfeedData, INewsfeed, NewsletterSections } from '@common/interfaces/newsfeed'
import { ILoadingData } from '../../redux/types'
import MarketNewsItem from './MarketNewsItem'
import TableContainer from '../Common/TableContainer'
import FilterContainer from '../Filters/FilterContainer'
import { Field, Form } from 'react-final-form'
import Table from '../Common/Table'
import TableBody from '../Common/TableBody'
import { NEWS_FEED_LIST_FILTERS_CONFIG, PER_PAGE } from '@common/constants/filters'
import { buildFiltersDefaults, buildFiltersValidateSchema } from '../../helpers/filters'
import cn from 'classnames'
import { IClientInfo } from '@common/interfaces/client'
import { SummarizeButton } from '../Common/Icons'
import Modal from '../Common/Modal'
import Button from '../../components/Common/Button'
import DatePicker from '../Common/DatePicker'
import moment from 'moment'
import genericSs from '@styles/generic.module.scss'
import * as Yup from 'yup'
import { makeValidate } from 'mui-rff'
import { NonReportingPeriods } from '@common/interfaces/bbc'

interface IProps {
  newsfeedData: ILoadingData<INewsfeedData>
  listNewsfeed: (data: object) => void
  setCurrentNewsItem: (item: INewsfeed | null) => void
  currentNewsItem: INewsfeed
  listBrandOptions: (data: object) => Promise<{ data: any[] }>
  exportNewsfeed: (data: object) => void
  setClientIndustries: (industries: Record<string, IClientInfo[]>) => void
  selectNewsletterHeadlines: (data: object) => void
}

const filtersValidate = buildFiltersValidateSchema(NEWS_FEED_LIST_FILTERS_CONFIG)
const filtersDefaults = buildFiltersDefaults(NEWS_FEED_LIST_FILTERS_CONFIG)

interface NewsletterFormValues {
  dateRange: {
    startDate: string
    endDate: string
  }
  [key: string]: any
}

const newsletterSchema: Yup.ObjectSchema<NewsletterFormValues> = Yup.object().shape({
  dateRange: Yup.object()
    .shape({
      startDate: Yup.string().required('Start date is required'),
      endDate: Yup.string().required('End date is required'),
    })
    .required('Date range is required'),
})

const validateNewsletter = makeValidate(newsletterSchema)

const MarketNewsTable = ({
  newsfeedData,
  listNewsfeed,
  setCurrentNewsItem,
  currentNewsItem,
  listBrandOptions,
  exportNewsfeed,
  setClientIndustries,
  selectNewsletterHeadlines,
}: IProps) => {
  const containerRef = useRef(null)

  const [isNewsletterModalOpen, setIsNewsletterModalOpen] = useState(false)
  const [isSaving, setIsSaving] = useState(false)

  const {
    data: newsfeed,
    totalItems,
    clientIndustries,
  } = useMemo(
    () => ({
      data: newsfeedData?.data?.data,
      isLoading: newsfeedData?.isLoading,
      totalItems: newsfeedData?.data?.totals?.totalItems,
      clientIndustries: newsfeedData?.data?.clientIndustries,
    }),
    [newsfeedData],
  )

  const { filters, handleFiltersChange, quickFilter, handleQuickFilterChange } = useTable({
    tableId: 'newsfeedTable',
    filtersDefaults,
    sortDefault: {
      field: 'processedAt',
      direction: 'DESC',
    },
  })

  const handleListNewsfeed = useCallback(
    (params?: object) => {
      listNewsfeed({
        perPage: PER_PAGE,
        filters,
        ...params,
      })
    },
    [listNewsfeed, filters],
  )

  useEffect(() => {
    handleListNewsfeed()
  }, [handleListNewsfeed])

  useEffect(() => {
    setClientIndustries(clientIndustries)
  }, [clientIndustries, setClientIndustries])

  const handleToggleNewsletterModal = useCallback(() => {
    setIsNewsletterModalOpen((prev) => !prev)
  }, [])

  const exportRiskRating = useCallback(async () => {
    await exportNewsfeed({
      isExport: true,
      filters,
    })
  }, [exportNewsfeed, filters])

  const loadMore = useCallback(() => {
    handleListNewsfeed({
      loadMore: true,
      page: Math.ceil(newsfeedData.data.data.length / PER_PAGE),
    })
  }, [handleListNewsfeed, newsfeedData])

  const handleNewsHeadlineClick = useCallback(
    (item: INewsfeed) => {
      setCurrentNewsItem(item)
    },
    [setCurrentNewsItem],
  )

  const handleSelectNewsletterHeadlines = useCallback(
    async ({ dateRange }: { dateRange: { startDate: string; endDate: string } }) => {
      setIsSaving(true)

      await selectNewsletterHeadlines({
        startDate: dateRange.startDate,
        endDate: dateRange.endDate,
      })
      setIsSaving(false)

      const newFilters = buildFiltersDefaults(NEWS_FEED_LIST_FILTERS_CONFIG, {
        processedAtFrom: dateRange.startDate,
        processedAtTo: dateRange.endDate,
        newsletterSection: [
          NewsletterSections.RecentlyInFundraiseNews,
          NewsletterSections.RecentlyInDistributionNews,
        ],
      })

      await handleFiltersChange(newFilters)
      handleToggleNewsletterModal()
    },
    [selectNewsletterHeadlines, handleToggleNewsletterModal, handleFiltersChange],
  )

  useEffect(() => {
    const isCurrentItemInNewsfeed = newsfeed?.some((item) => item.id === currentNewsItem?.id)

    if (!newsfeed?.length) {
      setCurrentNewsItem(null)
    } else if (!isCurrentItemInNewsfeed) {
      setCurrentNewsItem(newsfeed[0])
      containerRef.current?.scrollTo({ top: 0, behavior: 'smooth' })
    }
  }, [newsfeed, setCurrentNewsItem, currentNewsItem?.id])

  const loadOptions = useCallback(
    async (search: string) => {
      const res = await listBrandOptions({ search })
      return res.data.map(({ label }) => ({
        value: label,
        label,
      }))
    },
    [listBrandOptions],
  )

  const filtersConfig = useMemo(
    () =>
      NEWS_FEED_LIST_FILTERS_CONFIG.map((item) => ({
        ...item,
        options: !['brand', 'referral_source'].includes(item.field) ? item.options : undefined,
        loadOptions: ['brand', 'referral_source'].includes(item.field) ? loadOptions : undefined,
      })),
    [loadOptions],
  )

  const isFilterMenuOpen = useMemo(() => Object.keys(filters).length, [filters])

  return (
    <div className={styles.newsContainer}>
      <TableContainer className={styles.table}>
        <Form
          validate={filtersValidate}
          onSubmit={handleFiltersChange}
          initialValues={filters}
          mutators={{
            setFieldData: ([field, value], state, { changeValue }) => {
              changeValue(state, field, () => value)
            },
          }}
          render={({ values, handleSubmit, form: { mutators } }) => (
            <div className={styles.filterContainer}>
              <FilterContainer
                filters={filtersConfig}
                handleSubmit={handleSubmit}
                mutators={mutators}
                values={values}
                appliedFilters={filters}
                appliedQuickFilter={quickFilter}
                handleExportAggregation={exportRiskRating}
                handleAppliedQuickFilterChange={handleQuickFilterChange}
                actions={
                  <SummarizeButton
                    className={styles.summarizeButton}
                    title="Select Newsletter Headlines"
                    action={handleToggleNewsletterModal}
                    isLoading={false}
                  />
                }
              />
            </div>
          )}
        />
        <Table
          classes={{
            root: styles.tableRoot,
          }}
        >
          {newsfeed?.length > 0 ? (
            <TableBody
              ref={containerRef}
              className={cn(styles.container, { [styles.containerWithFilter]: isFilterMenuOpen })}
              id="marketNewsTable"
            >
              <InfiniteScroll
                dataLength={newsfeed?.length}
                className={styles.infiniteScroll}
                next={loadMore}
                hasMore={newsfeed?.length < totalItems}
                loader={<TableLoader columnsCount={1} rowsCount={1} />}
                scrollableTarget="marketNewsTable"
              >
                {newsfeed?.map((item) => (
                  <MarketNewsItem
                    isCurrentItem={currentNewsItem?.id === item.id}
                    key={item.id}
                    item={item}
                    handleNewsHeadlineClick={handleNewsHeadlineClick}
                  />
                ))}
              </InfiniteScroll>
            </TableBody>
          ) : null}
        </Table>
        {isNewsletterModalOpen && (
          <Form
            onSubmit={handleSelectNewsletterHeadlines}
            validate={validateNewsletter}
            render={({ handleSubmit, hasValidationErrors }) => (
              <form className={genericSs.textLeft} onSubmit={handleSubmit}>
                <Modal
                  open
                  onCancel={handleToggleNewsletterModal}
                  title="Newsletter"
                  size="small"
                  footer={[
                    <Button
                      key="submit"
                      isLoading={isSaving}
                      color="primary"
                      variant="contained"
                      onClick={handleSubmit}
                      className={styles.submitButton}
                      disabled={hasValidationErrors}
                    >
                      Continue
                    </Button>,
                  ]}
                >
                  <div className={styles.datePickerContainer}>
                    <Field name="dateRange">
                      {({ input }) => (
                        <DatePicker
                          reportingPeriod={NonReportingPeriods.Daily}
                          datesBoundary={{
                            minDate: moment().subtract(3, 'months').toDate().toISOString(),
                            maxDate: moment().toDate().toISOString(),
                          }}
                          currentDateRange={input.value}
                          onChange={input.onChange}
                        />
                      )}
                    </Field>
                  </div>
                </Modal>
              </form>
            )}
          />
        )}
      </TableContainer>
    </div>
  )
}

export default MarketNewsTable
