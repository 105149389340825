import React, { useMemo } from 'react'
import Grid from '@mui/material/Grid'
import Skeleton from '@mui/material/Skeleton'

import styles from '../ProspectArApSummary/ProspectArApSummary.module.scss'

import Card from '../../components/Common/Card'
import TableContainer from '../Common/TableContainer'
import Table from '../Common/Table'
import TableHead from '../Common/TableHead'
import TableFooter from '../Common/TableFooter'
import TableBody from '../Common/TableBody'
import TableFiltersRow from '../Common/TableFiltersRow'
import {
  PROSPECTS_RECEIVABLES_SUMMARY_LIST_FILTERS_CONFIG,
  PROSPECTS_PAYABLES_SUMMARY_LIST_FILTERS_CONFIG,
  EMPTY_ORDER_BY,
} from '@common/constants/filters'
import TableLoader from '../Common/TableLoader'
import { voidHandler } from '../../helpers/helpers'

interface IProps {
  reportType: string
}

const ProspectReportingLoader = ({ reportType }: IProps) => {
  const filters = useMemo(
    () =>
      reportType === 'AR'
        ? PROSPECTS_RECEIVABLES_SUMMARY_LIST_FILTERS_CONFIG
        : reportType === 'AP'
        ? PROSPECTS_PAYABLES_SUMMARY_LIST_FILTERS_CONFIG
        : null,
    [reportType],
  )

  return (
    <Card noHeaderMargin withBorder={false}>
      <TableContainer className={styles.table} hasFooter>
        <Grid container direction="row" alignItems="center" justifyContent="flex-start" pt={1}>
          <Grid item container xs={12} pb={2}>
            <Grid container item xs={8} alignItems="center" justifyContent="start" gap={2}>
              <Skeleton width={120} height={40} />
              <Skeleton width={40} height={60} />
              <Skeleton width={90} height={60} />
            </Grid>
            <Grid container item xs={4} alignItems="center" justifyContent="end" gap={2}>
              <Skeleton width={36} height={60} />
              <Skeleton width={36} height={60} />
              <Skeleton width={36} height={60} />
              <Skeleton width={140} height={60} />
              <Skeleton width={36} height={60} />
            </Grid>
          </Grid>
        </Grid>
        <Table>
          <TableHead>
            <TableFiltersRow
              filters={filters}
              orderBy={EMPTY_ORDER_BY}
              handleOrderChange={voidHandler}
            />
          </TableHead>
          <TableBody>
            <TableLoader columnsCount={filters.length} rowsCount={10} />
          </TableBody>
          <TableFooter>
            <TableLoader columnsCount={filters.length} rowsCount={2} />
          </TableFooter>
        </Table>
      </TableContainer>
    </Card>
  )
}

export default ProspectReportingLoader
