import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'

import InventoryEligibleCustomRules from './InventoryEligibleCustomRules'

import { listCustomRules } from '../../../redux/clientInfo/actions'
import { eligibleFields, customRules, clientInfo } from '../../../redux/clientInfo/selectors'
import {
  listCustomRulesLabels,
  createCustomRules,
  updateCustomRule,
  deleteCustomRule,
  createCustomRulesLabel,
  listCustomRulesValues,
} from '../../../redux/bbc/actions'
import { customRulesLabels, customRulesValues } from '../../../redux/bbc/selectors'

const selector = createStructuredSelector({
  clientInfo,
  fields: eligibleFields,
  customRules,
  customRulesLabels,
  customRulesValues,
})

const actions = {
  listCustomRules,
  listCustomRulesLabels,
  listCustomRulesValues,
  createCustomRulesLabel,
  createCustomRules,
  updateCustomRule,
  deleteCustomRule,
}

export default connect(selector, actions)(InventoryEligibleCustomRules)
