import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'

import DueDiligenceSalesBySKUPage from './DueDiligenceSalesBySKUPage'

import { dueDiligenceInfo } from '../../redux/dueDiligence/selectors'
import { listInventoryMapping } from '../../redux/ongoingReporting/actions'
import { show } from '../../redux/dueDiligence/actions'

const selector = createStructuredSelector({
  dueDiligenceInfo,
})

const actions = {
  listInventoryMapping,
  show,
}

export default connect(selector, actions)(DueDiligenceSalesBySKUPage)
