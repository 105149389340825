import { combineReducers } from 'redux'

import * as actions from './actions'
import {
  IBankTransactionData,
  IBankTransactionGraphData,
  IBankTransactionRawData,
} from '@common/interfaces/bankTransactions'
import { ILoadingData } from '../types'
import { createLoadingDataReducer } from '../../helpers/redux'

export interface IBankTransactionsState {
  bankTransactions: ILoadingData<IBankTransactionData>
  bankTransactionsRaw: ILoadingData<IBankTransactionRawData>
  bankTransactionsGraph: ILoadingData<IBankTransactionGraphData>
}

export default combineReducers<IBankTransactionsState>({
  bankTransactions: createLoadingDataReducer<IBankTransactionData>(
    [actions.LIST_TRANSACTIONS_REQUEST, actions.LIST_TRANSACTION_DETAIL_REQUEST],
    [actions.UPDATE_TRANSACTIONS_REQUEST],
  ),
  bankTransactionsRaw: createLoadingDataReducer<IBankTransactionRawData>(
    [actions.LIST_TRANSACTION_RAW_REQUEST],
    [],
    false,
    [actions.HIDE_TRANSACTIONS],
  ),
  bankTransactionsGraph: createLoadingDataReducer<IBankTransactionGraphData>(
    [actions.LIST_TRANSACTION_GRAPH_REQUEST],
    [],
    false,
    [actions.HIDE_TRANSACTIONS],
  ),
})
