import { useEffect } from 'react'
import { useDispatch } from 'react-redux'

import { changePageTitle } from '../redux/ui/actions'

export function useSetPageTitle(title: string): void {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(changePageTitle(title))
    document.title = title

    return () => {
      dispatch(changePageTitle(''))
      document.title = 'Dwight Funding - Atlas'
    }
  }, [title, dispatch])
}
