import { combineReducers } from 'redux'
import * as actions from './actions'
import { IFundingWiresData } from '@common/interfaces/fundingWire'
import { ILoadingData } from '../types'
import { createLoadingDataReducer } from '../../helpers/redux'

export interface IOutgoingWireState {
  fundingWiresData: ILoadingData<IFundingWiresData>
}

export default combineReducers<IOutgoingWireState>({
  fundingWiresData: createLoadingDataReducer([actions.LIST_OUTGOING_WIRE_REQUEST]),
})
