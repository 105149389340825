import { Dispatch } from 'redux'

import { connectToWS, WS_SEND_MESSAGE } from '@common/constants/webSockets'
import { IAction } from './apiMiddleware'
import { LOGOUT_SUCCESS, PROFILE_SUCCESS } from './profile/actions'

export default function webSocketMiddleware() {
  return ({ dispatch }: { dispatch: Dispatch<IAction> }) => {
    let ws: WebSocket

    return (next: Dispatch<IAction>) => (action: IAction) => {
      if (action.type === PROFILE_SUCCESS && !ws) {
        const token = window.localStorage.getItem('token') || null
        if (token) {
          const handleMessage = ({ type, data }: any) => {
            if (type && data) {
              dispatch({ type, data })
            }
          }
          ws = connectToWS(token, handleMessage)
        }
      }

      if (action.type === LOGOUT_SUCCESS && ws) {
        ws && ws.close()
        ws = undefined
      }

      if (
        action.type &&
        action.payload &&
        action.type === WS_SEND_MESSAGE &&
        ws &&
        ws.readyState === WebSocket.OPEN
      ) {
        ws.send(JSON.stringify(action.payload))
      }

      return next(action)
    }
  }
}
