import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'

import ItemsToMonitor from './ItemsToMonitor'
import { clientInfoItemsMonitor } from '../../../redux/clientInfo/selectors'
import { updateClientItemsToMonitor } from '../../../redux/clientInfo/actions'

const selector = createStructuredSelector({
  clientInfo: clientInfoItemsMonitor,
})

const actions = {
  updateClient: updateClientItemsToMonitor,
}

export default connect(selector, actions)(ItemsToMonitor)
