import { Action } from 'redux'
import { put, takeEvery } from 'redux-saga/effects'
import {
  EXPORT_TRANSACTIONS_SUCCESS,
  EXPORT_END_OF_MONTH_FLOW_SUCCESS,
  EXPORT_END_OF_MONTH_FLOW_FAILURE,
  APPLY_END_OF_MONTH_FLOW_SUCCESS,
  GET_AVERAGE_LOAN_BALANCE_SUCCESS,
  getLoanBalanceStartDate,
  getLatestEndOfMonthFlow,
  GET_AVERAGE_LOAN_BALANCE_FAILURE,
  EXPORT_TRANSACTIONS_FAILURE,
} from './actions'

import { IResponse } from '@common/interfaces/request'
import { download } from '../../helpers/helpers'

export function* exportLoanBalanceTransactions() {
  yield takeEvery(EXPORT_TRANSACTIONS_SUCCESS, function* (action: IResponse & Action) {
    try {
      yield download(action.data, action.data.name)
    } catch (error) {
      yield put({ type: EXPORT_TRANSACTIONS_FAILURE, error })
    }
  })
}

export function* downloadAverageLoanBalanceResult() {
  yield takeEvery(GET_AVERAGE_LOAN_BALANCE_SUCCESS, function* (action: IResponse & Action) {
    try {
      yield download(action.data, action.data.name)
    } catch (error) {
      yield put({ type: GET_AVERAGE_LOAN_BALANCE_FAILURE, error })
    }
  })
}

export function* exportEndOfMonthFlow() {
  yield takeEvery(EXPORT_END_OF_MONTH_FLOW_SUCCESS, function* (action: IResponse & Action) {
    try {
      yield download(action.data, action.data.name)
    } catch (error) {
      yield put({ type: EXPORT_END_OF_MONTH_FLOW_FAILURE, error })
    }
  })
}

export function* reloadLatestEndOfMonthFlow() {
  yield takeEvery(APPLY_END_OF_MONTH_FLOW_SUCCESS, function* () {
    yield put(getLoanBalanceStartDate())
    yield put(getLatestEndOfMonthFlow())
  })
}
