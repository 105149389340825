import { combineReducers } from 'redux'
import { loadingArrayReducer } from '../../helpers/helpers'
import * as actions from './actions'
import { ILoadingData } from '../types'
import { createLoadingDataReducer } from '../../helpers/redux'
import {
  IOPSReporting,
  IProspectTerms,
  IProspectsAggregation,
  IProspectReportingAggregation,
  IProspectSummaryInventory,
  IProspectSummaryCashFlow,
  IProspectARSummary,
  IProspectAPSummary,
} from '@common/interfaces/prospects'
import { SUBMIT_ONGOING_REPORTING_SUCCESS } from '../ongoingReporting/actions'
import { UPDATE_OPS_REPORTING } from '@common/constants/webSockets'

export interface IProspectState {
  loadingArray: string[]
  reporting: IOPSReporting
  prospectsAggregation: ILoadingData<IProspectsAggregation>
  prospectReporting: ILoadingData<IProspectReportingAggregation>
  prospectTerms: ILoadingData<{ data: IProspectTerms[] }>
  inventorySummaryInfo: ILoadingData<IProspectSummaryInventory>
  cashFlowSummaryInfo: ILoadingData<IProspectSummaryCashFlow>
  termStructureSummaryInfo: ILoadingData<{ data: IProspectTerms }>
  arSummary: ILoadingData<IProspectARSummary>
  apSummary: ILoadingData<IProspectAPSummary>
  date?: { AR: string; AP: string }
  refreshCount: number
}

export default combineReducers<IProspectState>({
  loadingArray: loadingArrayReducer(
    [actions.prefix],
    [
      actions.UPDATE_REPORTING_REQUEST,
      actions.LIST_REQUEST,
      actions.SHOW_REQUEST,
      actions.SHOW_FOLDERS_REQUEST,
      actions.UPDATE_REQUEST,
      actions.UPDATE_REPORTING_FLOW_ADDITIONAL_REQUEST,
    ],
  ),
  reporting(state: IOPSReporting = null, action) {
    switch (action.type) {
      case actions.SHOW_SUCCESS:
      case actions.START_REPORTING_SUCCESS:
        if (!state) {
          return action.data
        }

        return {
          ...state,
          ...action.data,
          clientInfo: {
            ...(state.clientInfo || {}),
            ...(action.data.clientInfo || {}),
          },
          salesforceProspectInfo: {
            ...(state.salesforceProspectInfo || {}),
            ...(action.data.salesforceProspectInfo || {}),
          },
        }
      case actions.UPDATE_REPORTING_SUCCESS:
      case actions.PULL_REPORTS_SUCCESS:
      case actions.UPDATE_SUCCESS:
      case actions.SHOW_FOLDERS_SUCCESS:
      case SUBMIT_ONGOING_REPORTING_SUCCESS:
        if (!state || state?.id !== action.data.id) {
          return null
        }

        return {
          ...state,
          ...action.data,
          clientInfo: {
            ...(state.clientInfo || {}),
            ...(action.data.clientInfo || {}),
          },
          salesforceProspectInfo: {
            ...(state.salesforceProspectInfo || {}),
            ...(action.data.salesforceProspectInfo || {}),
          },
        }
      case actions.UPDATE_FINANCIALS_DATES_SUCCESS:
        return {
          ...state,
          opsReportingFlows: (state.opsReportingFlows || []).map((item) =>
            item.id === action.data.id
              ? {
                  ...item,
                  ...action.data,
                }
              : item,
          ),
        }
      case actions.UPDATE_REPORTING_FLOW_ADDITIONAL_SUCCESS:
        if (state.opsReportingFlows) {
          return {
            ...state,
            opsReportingFlows: state.opsReportingFlows.map((flow) =>
              flow.id === action.data.id ? action.data : flow,
            ),
          }
        }
        return state
      case UPDATE_OPS_REPORTING:
        if (!state || state?.id !== action.data.id) {
          return state
        }

        return {
          ...state,
          ...action.data,
          clientInfo: {
            ...(state.clientInfo || {}),
            ...(action.data.clientInfo || {}),
          },
          salesforceProspectInfo: {
            ...(state.salesforceProspectInfo || {}),
            ...(action.data.salesforceProspectInfo || {}),
          },
        }
      case actions.HIDE:
      case actions.LIST_SUCCESS:
        return null
      default:
        return state
    }
  },
  prospectsAggregation: createLoadingDataReducer<IProspectsAggregation>([actions.LIST_REQUEST]),
  prospectReporting: createLoadingDataReducer<IProspectReportingAggregation>(
    [actions.LIST_PROSPECT_REPORTING_REQUEST],
    [],
    false,
    [actions.HIDE_PROSPECT_REPORTING],
  ),
  prospectTerms: createLoadingDataReducer<{ data: IProspectTerms[] }>(
    [actions.LIST_PROSPECT_TERMS_REQUEST],
    [
      actions.DELETE_PROSPECT_TERMS_REQUEST,
      actions.UPDATE_PROSPECT_TERMS_REQUEST,
      actions.CREATE_PROSPECT_TERMS_REQUEST,
    ],
    true,
  ),
  inventorySummaryInfo: createLoadingDataReducer<IProspectSummaryInventory>([
    actions.SHOW_INVENTORY_SUMMARY_INFO_REQUEST,
  ]),
  cashFlowSummaryInfo: createLoadingDataReducer<IProspectSummaryCashFlow>([
    actions.SHOW_CASH_FLOW_SUMMARY_INFO_REQUEST,
  ]),
  termStructureSummaryInfo: createLoadingDataReducer<{ data: IProspectTerms }>([
    actions.SHOW_TERM_STRUCTURE_SUMMARY_INFO_REQUEST,
  ]),
  arSummary: createLoadingDataReducer<IProspectARSummary>(
    [actions.LIST_AR_SUMMARY_REQUEST, actions.LIST_AR_DETAIL_REQUEST],
    [actions.UPDATE_AR_SUMMARY_REQUEST, actions.UPDATE_REPORTING_FLOW_ADDITIONAL_REQUEST],
  ),
  apSummary: createLoadingDataReducer<IProspectAPSummary>([
    actions.LIST_AP_SUMMARY_REQUEST,
    actions.LIST_AP_DETAIL_REQUEST,
  ]),
  date(state: { AR: string; AP: string } = { AR: '', AP: '' }, action) {
    switch (action.type) {
      case actions.GET_DATE_AP_SUCCESS:
      case actions.UPDATE_DATE_AP_SUCCESS:
        return { ...state, AP: action.data }
      case actions.GET_DATE_AR_SUCCESS:
      case actions.UPDATE_DATE_AR_SUCCESS:
        return { ...state, AR: action.data }
      default:
        return state
    }
  },
  refreshCount: (state = 0, action) => {
    switch (action.type) {
      case actions.PROSPECT_REFRESH_COUNT:
        return state + 1
    }

    return state
  },
})
