import React, { useEffect, useState, useCallback, useMemo, useRef } from 'react'
import { useParams } from 'react-router'
import { Form } from 'react-final-form'
import CreatableSelect from 'react-select/creatable'
import cn from 'classnames'
import Tooltip from '@mui/material/Tooltip'
import Box from '@mui/material/Box'
import InfiniteScroll from 'react-infinite-scroll-component'
import Button from '../Common/Button'

import styles from './InvetoryMappingTable.module.scss'
import genericSs from '@styles/generic.module.scss'

import TableRow from '../Common/TableRow'
import TableCell from '../Common/TableCell'
import Table from '../Common/Table'
import TableHead from '../Common/TableHead'
import TableContainer from '../Common/TableContainer'
import TableBody from '../Common/TableBody'

import { debounceEventHandler, handleMultipleSelect } from '../../helpers/helpers'
import {
  IInventoryMappingFieldsData,
  IInventoryMappingFields,
  EligibilityStatus,
  SKU_TYPES_LIST,
} from '@common/interfaces/bbc'
import TableFiltersRow from '../Common/TableFiltersRow'
import Checkbox from '../Common/Checkbox'
import TextField from '../Common/TextField'

import {
  LIST_INVENTORY_MAPPING_FILTERS_CONFIG,
  ELIGIBILITY_NOTES_FILTERS_CONFIG,
  PER_PAGE,
} from '@common/constants/filters'

import { buildFiltersDefaults, buildFiltersValidateSchema } from '../../helpers/filters'
import FilterContainer from '../Filters/FilterContainer'
import TableLoader from '../Common/TableLoader'
import SaveState from '../Common/SaveState'
import { ILoadingData } from '../../redux/types'

interface IProps {
  listInventoryMapping: (id: string, params?: object) => Promise<void>
  updateInventoryMapping: (id: string, itemId: string, data: object) => Promise<void>
  skuDetails: ILoadingData<IInventoryMappingFieldsData>
  isDueDiligence?: boolean
  handleGoNext?: () => void
  isProcessing?: boolean
  readOnly?: boolean
  isFilesSaving?: boolean
  isInventory?: boolean
}

const InvetoryMappingTableRow = ({
  index,
  item,
  isActiveRow,
  isCurrentActiveRow,
  onSelectRow,
  typeFields,
  typeFieldOptions,
  onDistributionChange,
  isDueDiligence,
}: {
  index: number
  item: IInventoryMappingFields
  isActiveRow: boolean
  isCurrentActiveRow: boolean
  onSelectRow: (event: any, index: number) => void
  typeFields: string[]
  typeFieldOptions: any
  onDistributionChange: (index: number, value: string, field: string) => void
  isDueDiligence: boolean
}) => {
  const [notesValues, setNotesValues] = useState(item?.notes)

  useEffect(() => {
    setNotesValues(item?.notes)
  }, [item?.notes])

  const handleSelectRow = useCallback((event) => onSelectRow(event, index), [index, onSelectRow])
  const onChange = useCallback(
    (option, input) => {
      onDistributionChange(index, option?.value || null, input.name.split('.')[1])
    },
    [index, onDistributionChange],
  )

  const handleCheckbox = useCallback(
    (event) => {
      onDistributionChange(index, event.target.checked, 'eligibility')
    },
    [index, onDistributionChange],
  )

  const handleDistributionChange = useCallback(
    (value) => {
      onDistributionChange(index, value, 'notes')
    },
    [index, onDistributionChange],
  )

  const debounceTextChange = useMemo(
    () => debounceEventHandler(handleDistributionChange, 500),
    [handleDistributionChange],
  )

  const handleTextChange = useCallback(
    (event) => {
      setNotesValues(event.target.value)
      debounceTextChange(event.target.value)
    },
    [debounceTextChange],
  )

  return (
    <TableRow
      id={`mapping-table-row-${index}`}
      key={`mapping-table-row-${item.id}`}
      data-index={index}
      className={cn('activableRow', {
        activeRow: isActiveRow,
        currentActiveRow: isCurrentActiveRow,
        [styles.newRow]: ![EligibilityStatus.Eligible, EligibilityStatus.Ineligible].includes(
          item?.eligibility,
        ),
      })}
      onClick={handleSelectRow}
    >
      <TableCell className={genericSs.tableTextLeft}>
        <Tooltip title={item.sku} placement="top" disableTouchListener>
          <span>{item.sku}</span>
        </Tooltip>
      </TableCell>
      <TableCell className={genericSs.tableTextLeft}>
        <Tooltip title={item.description} placement="top" disableTouchListener>
          <span>{item.description}</span>
        </Tooltip>
      </TableCell>
      {typeFields.map((typeField) => {
        return (
          <TableCell key={typeField} className={styles.selectColumn}>
            <CreatableSelect
              className={cn('react-select', styles.reactSelectField)}
              classNamePrefix="react-select"
              name={`inventoryDetails[${index}].${typeField}`}
              options={typeField === 'type' ? SKU_TYPES_LIST : typeFieldOptions[typeField]}
              value={
                item && item[typeField]
                  ? {
                      value: item[typeField],
                      label: item[typeField],
                    }
                  : null
              }
              placeholder=""
              onChange={onChange}
              isClearable
              isValidNewOption={() => (typeField === 'type' ? false : true)}
            />
          </TableCell>
        )
      })}
      {isDueDiligence && (
        <>
          <TableCell className={genericSs.tableTextCenter}>
            <Checkbox
              className="focusableInput"
              tabIndex={3 * index + 1}
              name={`inventoryDetails[${index}].eligibility`}
              color="primary"
              onChange={handleCheckbox}
              checked={item?.eligibility === EligibilityStatus.Eligible}
            />
          </TableCell>
          <TableCell className={genericSs.tableTextCenter}>
            <TextField
              className="focusableInput"
              tabIndex={3 * index + 2}
              name={`inventoryDetails[${index}].notes`}
              fullWidth={false}
              useFinalForm={false}
              value={notesValues ? notesValues : ''}
              onChange={handleTextChange}
            />
          </TableCell>
        </>
      )}
    </TableRow>
  )
}

const excludedSKUFields = ['sku', 'description', 'eligibility', 'notes']

const InvetoryMappingTable = ({
  updateInventoryMapping,
  listInventoryMapping,
  skuDetails,
  isDueDiligence,
  handleGoNext,
  isProcessing,
  readOnly,
  isFilesSaving,
  isInventory,
}: IProps) => {
  const { id } = useParams<{ id: string }>()
  const wrapperRef = useRef(null)

  const tableFilters = useMemo(() => {
    return isDueDiligence
      ? [...LIST_INVENTORY_MAPPING_FILTERS_CONFIG, ...ELIGIBILITY_NOTES_FILTERS_CONFIG]
      : LIST_INVENTORY_MAPPING_FILTERS_CONFIG
  }, [isDueDiligence])

  const filtersValidate = buildFiltersValidateSchema(tableFilters)
  const filtersDefaults = buildFiltersDefaults(tableFilters)

  const [orderBy, setOrderBy] = useState({
    field: 'eligibility',
    direction: 'DESC',
  })
  const [filters, setFilters] = useState(filtersDefaults)
  const [activeItem, setActiveItem] = useState<number>()
  const [activeItems, setActiveItems] = useState([])

  const {
    isLoading,
    isSaving,
    isSaved,
    data: resData,
    itemsCount,
  } = useMemo(() => {
    return {
      isLoading: skuDetails.isLoading,
      isSaving: skuDetails.isSaving,
      isSaved: skuDetails.isSaved,
      data: skuDetails?.data?.data,
      itemsCount: skuDetails?.data?.totalItems,
    }
  }, [skuDetails])

  const handleSelectRow = useMemo(
    () => handleMultipleSelect(setActiveItems, setActiveItem, activeItems),
    [activeItems],
  )
  const resetActiveItems = useCallback(() => setActiveItems([]), [])

  const handleFiltersChange = useCallback((data: any) => {
    setFilters(data)
  }, [])

  const handleOrderChange = useCallback((field: string) => {
    setOrderBy((order) => ({
      field,
      direction: order.field === field ? (order.direction === 'DESC' ? 'ASC' : 'DESC') : 'ASC',
    }))
  }, [])

  const fetchInventoryMapping = useCallback(
    async (data) => {
      const params = {
        ...data,
        isInventory,
        filters: {
          field: 'sku',
          ...data.filters,
        },
        perPage: data.perPage || PER_PAGE,
      }
      await listInventoryMapping(id, params)
      if (!data.loadMore) {
        resetActiveItems()
      }
    },
    [id, listInventoryMapping, resetActiveItems, isInventory],
  )

  const refetchInventoryMapping = useCallback(() => {
    fetchInventoryMapping({
      page: 0,
      perPage: resData.length,
      filters,
      orderBy: orderBy.field,
      orderDirection: orderBy.direction,
      skipLoader: true,
    })
  }, [filters, orderBy, resData, fetchInventoryMapping])

  const debounceInventoryMappingList = useMemo(
    () => debounceEventHandler(fetchInventoryMapping, 500),
    [fetchInventoryMapping],
  )

  useEffect(() => {
    debounceInventoryMappingList({
      page: 0,
      filters,
      orderBy: orderBy.field,
      orderDirection: orderBy.direction,
    })
  }, [orderBy, filters, debounceInventoryMappingList])

  const activeItemsIds = useMemo(
    () => resData?.filter((_, index) => activeItems.includes(index)).map((item) => item.id),
    [activeItems, resData],
  )

  const typeFields = useMemo(() => {
    return tableFilters
      .filter((filter) => !excludedSKUFields.includes(filter.field))
      .map((filter) => filter.field)
  }, [tableFilters])

  const typeFieldOptions = useMemo(() => {
    const typeFieldOptions = {}

    typeFields.forEach((typeField) => {
      typeFieldOptions[typeField] = []
    })

    resData?.forEach((item: IInventoryMappingFields) => {
      typeFields.forEach((typeField) => {
        if (
          item[typeField] &&
          //@ts-ignore
          !typeFieldOptions[typeField].some((option) => option.value === item[typeField])
        ) {
          typeFieldOptions[typeField].push({ value: item[typeField], label: item[typeField] })
        }
      })
    })

    return typeFieldOptions
  }, [resData, typeFields])

  const handleUpdateSkuDetails = useCallback(
    async (itemId: string, data: any, activeItemsIds: string[], updatedRowIndex: number) => {
      const [typeField, value] = Object.entries(data)[0]

      if (activeItemsIds?.length > 1 && activeItemsIds?.includes(itemId)) {
        await updateInventoryMapping(id, activeItemsIds[0], {
          [typeField]: value,
          itemId: activeItemsIds,
        })
      } else {
        await updateInventoryMapping(id, itemId, { [typeField]: value })
      }
      await refetchInventoryMapping()
    },
    [id, updateInventoryMapping, refetchInventoryMapping],
  )

  const handleUpdateSkuDetailsDebounce = useMemo(
    () =>
      debounceEventHandler(
        async (
          itemId: string,
          data: Partial<IInventoryMappingFields>,
          activeItemsIds: string[],
          updatedRowIndex: number,
        ) => {
          await handleUpdateSkuDetails(itemId, data, activeItemsIds, updatedRowIndex)
        },
        100,
      ),
    [handleUpdateSkuDetails],
  )

  const handleUpdate = useCallback(
    (index, value, field) => {
      if (field === 'eligibility') {
        handleUpdateSkuDetailsDebounce(
          resData[index].id,
          { eligibility: value ? EligibilityStatus.Eligible : EligibilityStatus.Ineligible },
          activeItemsIds,
          index,
        )
      } else {
        handleUpdateSkuDetailsDebounce(resData[index].id, { [field]: value }, activeItemsIds, index)
      }
    },
    [activeItemsIds, handleUpdateSkuDetailsDebounce, resData],
  )

  const loadMore = useCallback(() => {
    fetchInventoryMapping({
      loadMore: true,
      page: Math.ceil(resData?.length / PER_PAGE),
      orderBy: orderBy.field,
      orderDirection: orderBy.direction,
      filters,
    })
  }, [resData, orderBy, filters, fetchInventoryMapping])

  return (
    <TableContainer
      className={styles.table}
      isActivable
      onActiveRowsChange={setActiveItems}
      onActiveRowChange={setActiveItem}
    >
      <Form
        onSubmit={handleFiltersChange}
        initialValues={filters}
        validate={filtersValidate}
        mutators={{
          setFieldData: ([field, value], state, { changeValue }) => {
            changeValue(state, field, () => value)
          },
        }}
        render={({ values, handleSubmit, form: { mutators } }) => (
          <FilterContainer
            filters={tableFilters}
            handleSubmit={handleSubmit}
            mutators={mutators}
            values={values}
            appliedFilters={filters}
            title={
              <Box mr={2}>
                <h2>Inventory mapping</h2>
              </Box>
            }
            actions={
              isDueDiligence && (
                <Button
                  isLoading={isProcessing}
                  className={genericSs.dueDiligenceSubmitButton}
                  variant="contained"
                  onClick={handleGoNext}
                >
                  {readOnly ? 'Next' : 'Submit'}
                </Button>
              )
            }
          />
        )}
      />
      <Table ref={wrapperRef}>
        <TableHead>
          <TableFiltersRow
            filters={tableFilters}
            orderBy={orderBy}
            handleOrderChange={handleOrderChange}
          />
        </TableHead>
        <TableBody id="scrollableTableMappingTable">
          {isFilesSaving || isLoading ? (
            <TableLoader columnsCount={tableFilters.length} height={24} rowsCount={30} />
          ) : (
            <InfiniteScroll
              dataLength={resData?.length}
              next={loadMore}
              hasMore={resData?.length < itemsCount}
              loader={<TableLoader columnsCount={tableFilters.length} height={24} rowsCount={1} />}
              scrollableTarget="scrollableTableMappingTable"
            >
              {resData.map((value, index) => (
                <InvetoryMappingTableRow
                  key={resData[index].id}
                  index={index}
                  item={resData[index]}
                  isActiveRow={activeItems.includes(index)}
                  isCurrentActiveRow={activeItem === index}
                  onSelectRow={handleSelectRow}
                  typeFields={typeFields}
                  typeFieldOptions={typeFieldOptions}
                  onDistributionChange={handleUpdate}
                  isDueDiligence={isDueDiligence}
                />
              ))}
            </InfiniteScroll>
          )}
        </TableBody>
      </Table>
      <Box display="flex" alignItems="center" justifyContent="space-between">
        {itemsCount > 0 && (
          <div className={genericSs.itemsCount}>
            {resData?.length} / {itemsCount}
          </div>
        )}
        <SaveState isSaving={isSaving} isSaved={isSaved} />
      </Box>
    </TableContainer>
  )
}

export default InvetoryMappingTable
