import React, { useCallback } from 'react'
import { NewsTags } from '@common/interfaces/newsfeed'
import Chip from '@mui/material/Chip'
import styles from './MarketNewsDetails.module.scss'
import { IOptionType } from '@common/interfaces/entityInfo'
import cn from 'classnames'
import { Tooltip } from '@mui/material'

interface IProps {
  option: IOptionType
  handleDeleteLinkedAccount: (value: string) => void
  isFundraise?: boolean
}

const LinkedAccountTooltip = ({ option, handleDeleteLinkedAccount, isFundraise }: IProps) => {
  const handleDelete = useCallback(() => {
    handleDeleteLinkedAccount(option.value)
  }, [handleDeleteLinkedAccount, option.value])

  return (
    <Tooltip key={option.label} title={option.label} placement={'top'}>
      <Chip
        className={cn(styles.chip)}
        label={option.label}
        size="small"
        onDelete={handleDelete}
        disabled={option.type === NewsTags.Fundraise && !isFundraise}
      />
    </Tooltip>
  )
}

export default LinkedAccountTooltip
