import React, { useCallback, useState } from 'react'
import { useLocation } from 'react-router-dom'
import queryString from 'query-string'

import styles from './WiresUploads.module.scss'

import { IFile } from '@common/interfaces/box'
import { UploadFile } from '../Common/UploadFile'
import WarningModal from '../WarningModal'

interface IProps {
  wiresFiles: IFile[]
  uploadWires: (data: FormData) => void
  deleteFile: (ids: string[]) => Promise<void>
}

const WiresUploads = ({ wiresFiles, uploadWires, deleteFile }: IProps) => {
  const [filesForDelete, setFilesForDelete] = useState<IFile[]>([])
  const [isDeleteModalShown, setIsDeleteModalShown] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  const [isWiresUploading, setIsWiresUploading] = useState(false)

  const { search }: { search: string } = useLocation()

  const [openModal, setOpenModal] = useState<boolean>(false)

  const handleToggleModal = useCallback(() => {
    setOpenModal((isOpen) => !isOpen)
  }, [])

  const handleUploadWires = useCallback(
    async (loadedFiles: File[]) => {
      const qs = queryString.parse(search)
      const formData = new FormData()

      loadedFiles.forEach((file) => {
        formData.append('files[]', file, file.name)
      })
      qs?.activityId && formData.append('activityId', qs?.activityId.toString())

      setIsWiresUploading(true)
      await uploadWires(formData)
      setIsWiresUploading(false)
    },
    [uploadWires, search],
  )
  const handleUpload = useCallback(
    (loadedFiles: File[]) => {
      if (loadedFiles.length) {
        handleUploadWires(loadedFiles)
      }
    },
    [handleUploadWires],
  )

  const handleDeleteFiles = useCallback(
    (indexes: number[]) => {
      setIsDeleteModalShown(true)
      setFilesForDelete(
        indexes
          .sort((a, b) => b - a)
          .map((number, index) => wiresFiles[number === undefined ? index : number]),
      )
    },
    [wiresFiles],
  )

  const handleDeleteCancel = useCallback(() => {
    setIsDeleteModalShown(false)
    setFilesForDelete([])
  }, [])

  const handleDeleteConfirm = useCallback(async () => {
    setIsLoading(true)
    await deleteFile(filesForDelete.map((file) => file.id))
    setIsLoading(false)
    setIsDeleteModalShown(false)
    setFilesForDelete([])
  }, [deleteFile, filesForDelete])

  return (
    <div>
      <div className={styles.container}>
        <UploadFile
          key={wiresFiles.length}
          isLoading={isWiresUploading || (!isDeleteModalShown && isLoading)}
          size={'lg'}
          files={wiresFiles}
          handleToggleModal={handleToggleModal}
          isInModal={true}
          isModalOpen={openModal}
          title="Wires file"
          frequency="From Dwight bank account"
          onDelete={handleDeleteFiles}
          onDropAccepted={handleUpload}
        />
      </div>

      {isDeleteModalShown && filesForDelete.length > 0 && (
        <WarningModal
          onCancel={handleDeleteCancel}
          warningMessage="Are you sure you want to delete this wire file?"
          onConfirm={handleDeleteConfirm}
          isLoading={isLoading}
        />
      )}
    </div>
  )
}

export default WiresUploads
