import React, { useMemo, useEffect, useCallback } from 'react'
import { Field, Form, FormRenderProps } from 'react-final-form'
import { FormApi } from 'final-form'
import * as Yup from 'yup'
import { makeValidate } from 'mui-rff'
import Box from '@mui/material/Box'
import cn from 'classnames'

import styles from './DueDiligenceFinancialsVendors.module.scss'

import InputLabel from '../Common/InputLabel'
import { IDueDiligenceCompanyInfo } from '@common/interfaces/dueDiligence'
import { ClientEntityRelationshipType } from '@common/interfaces/integration'
import Autocomplete from '../Common/Autocomplete'
import { ClientERPSystem } from '@common/interfaces/client'
import TextField from '../Common/TextField'

const mutators = {
  setFieldData: ([field, value]: any, state: any, { changeValue }: any) => {
    changeValue(state, field, () => value)
  },
}

const schema = Yup.object().shape({
  erpSystemOther: Yup.string()
    .nullable()
    .when('erpSystem', (erpSystem: ClientERPSystem[], validation: any) =>
      erpSystem.includes(ClientERPSystem.Other) ? validation.required('Required') : validation,
    ),
})
const validate = makeValidate(schema)

const DueDiligenceFinancialsVendorsFormRender = ({
  form,
  values,
  invalid,
  errors,
  submitErrors,
  submitting,
  formRef,
  setIsFormInvalid,
  handleLoadVendors,
}: FormRenderProps<any> & {
  formRef: React.MutableRefObject<FormApi<any, Partial<any>>>
  setIsFormInvalid: (state: boolean) => void
  handleLoadVendors: (type: string, search: string) => Promise<any>
}) => {
  formRef.current = form
  const {
    mutators: { setFieldData },
  } = form

  useEffect(() => {
    setIsFormInvalid(submitting || invalid)
  }, [invalid, submitting, setIsFormInvalid])

  const loadDistributionServices3PLOptions = useCallback(
    async (search: string) =>
      handleLoadVendors(ClientEntityRelationshipType.DistributionServices3PL, search),
    [handleLoadVendors],
  )

  const handleSelectERPSystem = useCallback(
    (event: React.SyntheticEvent<HTMLDivElement>) => {
      const { value } = event.currentTarget.dataset
      const { erpSystem = [] } = values
      setFieldData(
        'erpSystem',
        erpSystem.includes(value)
          ? erpSystem.filter((system: string) => system !== value)
          : [...erpSystem, value],
      )
    },
    [values, setFieldData],
  )

  return (
    <form className={styles.formWrapper}>
      <div className={styles.formContent}>
        <Box className={cn(styles.fullWidthRow, styles.formHeading)}>Tools</Box>

        <Box className={styles.fullWidthRow}>
          <InputLabel className={styles.inputLabel}>
            What ERPs and/or Inventory Management Systems do you use?
          </InputLabel>

          <Field name="erpSystem" render={() => null} />
          <div className={styles.erpSelectorWrapper}>
            {Object.values(ClientERPSystem).map((value) => (
              <div
                key={value}
                data-value={value}
                onClick={handleSelectERPSystem}
                className={cn(styles.erpSelector, {
                  [styles.erpSelectorSelected]: values.erpSystem.includes(value),
                })}
              >
                {value}
              </div>
            ))}
          </div>
        </Box>

        {values.erpSystem.includes(ClientERPSystem.Other) && (
          <Box className={styles.fullWidthRow}>
            <InputLabel className={styles.inputLabel}>
              What other ERPs and/or Inventory Management Systems do you use?
            </InputLabel>
            <TextField
              name="erpSystemOther"
              placeholder="List them here"
              size="large"
              className={styles.inputField}
            />
          </Box>
        )}

        <Box className={cn(styles.fullWidthRow, styles.formHeading, styles.withTopMargin)}>
          Inventory
        </Box>

        <Box>
          <InputLabel className={styles.inputLabel}>What 3PL do you use?</InputLabel>
          <Autocomplete
            label=""
            name="distributionServices3PL"
            className={styles.autocompleteField}
            isAsync
            loadOptions={loadDistributionServices3PLOptions}
            options={[]}
            freeSolo
            autoSelect
            placeholder="Select 3PL"
          />
        </Box>
      </div>
    </form>
  )
}

interface IProps {
  dueDiligenceCompanyInfo: IDueDiligenceCompanyInfo
  formRef: React.MutableRefObject<FormApi<any, Partial<any>>>
  onSubmit: (values: any) => void
  setIsFormInvalid: (state: boolean) => void
  loadVendors: (data: { type: string; search: string }) => Promise<any>
}

const DueDiligenceFinancialsVendors = ({
  dueDiligenceCompanyInfo,
  formRef,
  onSubmit,
  setIsFormInvalid,
  loadVendors,
}: IProps) => {
  const initialValues = useMemo(() => {
    const distributionServices3PL = dueDiligenceCompanyInfo.clientEntityRelationship.find(
      ({ type }) => type === ClientEntityRelationshipType.DistributionServices3PL,
    )

    return {
      distributionServices3PL: distributionServices3PL
        ? distributionServices3PL.salesforceAccount?.name ||
          distributionServices3PL.newSalesforceAccount
        : undefined,
      erpSystem: dueDiligenceCompanyInfo.erpSystem,
      erpSystemOther: dueDiligenceCompanyInfo.erpSystemOther,
    }
  }, [dueDiligenceCompanyInfo])

  const handleLoadVendors = useCallback(
    async (type, search: string) => {
      const res = await loadVendors({ type, search })
      return res.data.map((vendor: any) => ({
        value: vendor.id,
        label: vendor.name,
      }))
    },
    [loadVendors],
  )

  return (
    <Form
      initialValues={initialValues}
      mutators={mutators}
      validate={validate}
      onSubmit={onSubmit}
      render={(props) => (
        <DueDiligenceFinancialsVendorsFormRender
          formRef={formRef}
          setIsFormInvalid={setIsFormInvalid}
          handleLoadVendors={handleLoadVendors}
          {...props}
        />
      )}
    />
  )
}

export default DueDiligenceFinancialsVendors
