import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import { trackActivity } from './../../redux/activity/actions'

import CollectionsChecksPage from './CollectionsChecksPage'
import { checkData, checksSummaryInfo, collectionStatus } from '../../redux/collections/selectors'
import {
  listChecks,
  saveChecks,
  updateCheck,
  updateChecks,
  restoreCheck,
  deleteCheck,
  loadCheckImage,
  getChecksSummaryInfo,
} from '../../redux/collections/actions'
import { list as listClients } from '../../redux/clientInfo/actions'
import { clients } from '../../redux/clientInfo/selectors'
import { listEntityInfo, addEntityInfo } from '../../redux/entityInfo/actions'

const selector = createStructuredSelector({
  checkData,
  checksSummaryInfo,
  clients,
  collectionStatus,
})

const actions = {
  listChecks,
  saveChecks,
  updateCheck,
  updateChecks,
  restoreCheck,
  deleteCheck,
  listClients,
  listEntityInfo,
  addEntityInfo,
  trackActivity,
  loadCheckImage,
  getChecksSummaryInfo,
}

export default connect(selector, actions)(CollectionsChecksPage)
