import { IApi } from '../../api'
import { CommonPromiseAction } from '../types'
import { createRequestTypes } from '../../helpers/helpers'

export const prefix = 'capTable'

const PROCESS_CAPTABLE = createRequestTypes(prefix, 'PROCESS_CAPTABLE')
export const [PROCESS_CAPTABLE_REQUEST, PROCESS_CAPTABLE_SUCCESS, PROCESS_CAPTABLE_FAILURE] =
  PROCESS_CAPTABLE

export const processCapTable: CommonPromiseAction = (id: string, data: object) => ({
  type: [PROCESS_CAPTABLE_REQUEST, PROCESS_CAPTABLE_SUCCESS, PROCESS_CAPTABLE_FAILURE],
  promise: (api: IApi) => api.capTable.process(id, data),
})

const LIST_CAPTABLE = createRequestTypes(prefix, 'LIST_CAPTABLE')
export const [LIST_CAPTABLE_REQUEST, LIST_CAPTABLE_SUCCESS, LIST_CAPTABLE_FAILURE] = LIST_CAPTABLE

export const listCapTable: CommonPromiseAction = (params: object) => ({
  type: [LIST_CAPTABLE_REQUEST, LIST_CAPTABLE_SUCCESS, LIST_CAPTABLE_FAILURE],
  promise: (api: IApi) => api.capTable.list(params),
  params,
})

const LIST_MAPPING = createRequestTypes(prefix, 'LIST_MAPPING')
export const [LIST_MAPPING_REQUEST, LIST_MAPPING_SUCCESS, LIST_MAPPING_FAILURE] = LIST_MAPPING

export const listMapping: CommonPromiseAction = (id: string, params: object) => ({
  type: [LIST_MAPPING_REQUEST, LIST_MAPPING_SUCCESS, LIST_MAPPING_FAILURE],
  promise: (api: IApi) =>
    api.capTable.listMapping(id, {
      ...params,
    }),
  params,
})

const LIST_BOARD_SEATS = createRequestTypes(prefix, 'LIST_BOARD_SEATS')
export const [LIST_BOARD_SEATS_REQUEST, LIST_BOARD_SEATS_SUCCESS, LIST_BOARD_SEATS_FAILURE] =
  LIST_BOARD_SEATS

export const listBoardSeats: CommonPromiseAction = (id: string, params: object) => ({
  type: [LIST_BOARD_SEATS_REQUEST, LIST_BOARD_SEATS_SUCCESS, LIST_BOARD_SEATS_FAILURE],
  promise: (api: IApi) => api.capTable.listBoardSeats(id, params),
})

const UPDATE_CAP_TABLE_DETAILS = createRequestTypes(prefix, 'UPDATE_CAP_TABLE_DETAILS')

export const [
  UPDATE_CAP_TABLE_DETAILS_REQUEST,
  UPDATE_CAP_TABLE_DETAILS_SUCCESS,
  UPDATE_CAP_TABLE_DETAILS_FAILURE,
] = UPDATE_CAP_TABLE_DETAILS

export const updateCapTableDetails: CommonPromiseAction = (data: object) => ({
  type: UPDATE_CAP_TABLE_DETAILS,
  promise: (api: IApi) => api.capTable.updateCapTableDetails(data),
})

export const UPLOAD_CAPTABLE_REQUEST = `${prefix}/UPLOAD_CAPTABLE_REQUEST`
export const UPLOAD_CAPTABLE_SUCCESS = `${prefix}/UPLOAD_CAPTABLE_SUCCESS`
export const UPLOAD_CAPTABLE_FAILURE = `${prefix}/UPLOAD_CAPTABLE_FAILURE`

export const uploadCapTable: CommonPromiseAction = (id: string) => ({
  type: [UPLOAD_CAPTABLE_REQUEST, UPLOAD_CAPTABLE_SUCCESS, UPLOAD_CAPTABLE_FAILURE],
  promise: (api: IApi) => api.capTable.upload(id),
})

export const UPDATE_CAPTABLE_REQUEST = `${prefix}/UPDATE_CAPTABLE_REQUEST`
export const UPDATE_CAPTABLE_SUCCESS = `${prefix}/UPDATE_CAPTABLE_SUCCESS`
export const UPDATE_CAPTABLE_FAILURE = `${prefix}/UPDATE_CAPTABLE_FAILURE`

export const updateCapTable: CommonPromiseAction = (id: string, data: object) => ({
  type: [UPDATE_CAPTABLE_REQUEST, UPDATE_CAPTABLE_SUCCESS, UPDATE_CAPTABLE_FAILURE],
  promise: (api: IApi) => api.capTable.update(id, data),
})
