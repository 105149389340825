import React from 'react'
import Box from '@mui/material/Box'
import Skeleton from '@mui/material/Skeleton'

import styles from './ClientIntakePage.module.scss'

import Card from '../../components/Common/Card'

const ClientIntakePageLoader = () => {
  return (
    <div className={styles.clientIntakeWrapper}>
      <Card
        noHeaderMargin
        className={styles.clientIntakeCard}
        classes={{ content: styles.clientIntakeCardContent }}
      >
        <Box mb={2.5} display="flex" flexDirection="column" alignItems="center">
          <Skeleton width={260} height={54} />
          <Skeleton width={480} height={25} />
        </Box>
        <div className={styles.formWrapper}>
          <div className={styles.formContent}>
            <Skeleton width={100} height={30} />
            <Skeleton width="100%" height={64} />
            <Skeleton width={100} height={30} />
            <Skeleton width="100%" height={64} />
            <Skeleton width={100} height={30} />
            <Skeleton width="100%" height={64} />
            <Skeleton width={100} height={30} />
            <Skeleton width="100%" height={64} />
          </div>

          <div className={styles.buttonDivider} />

          <div className={styles.buttonWrapper}>
            <div />
            <Skeleton width={140} height={56} />
          </div>
        </div>
      </Card>
    </div>
  )
}

export default ClientIntakePageLoader
