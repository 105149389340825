import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useParams } from 'react-router'
import { makeValidate } from 'mui-rff'
import * as Yup from 'yup'
import { Form, FormSpy } from 'react-final-form'
import Skeleton from '@mui/material/Skeleton'

import genericSs from '@styles/generic.module.scss'
import styles from './ProspectReportingSummaryCashFlow.module.scss'

import TextField from '../Common/TextField'
import SelectField from '../Common/SelectField'
import { FinancialsType } from '@common/interfaces/bbc'
import { ILoadingData } from '../../redux/types'
import { IProspectSummaryCashFlow } from '@common/interfaces/prospects'
import {
  debounceEventHandler,
  formatDateMonthYear,
  formatPrice,
  voidHandler,
} from '../../helpers/helpers'

const options = [
  {
    value: FinancialsType.Actuals,
    label: 'Last',
  },
  {
    value: FinancialsType.Projections,
    label: 'Next',
  },
]

interface IProps {
  showCashFlowSummaryInfo: (id: string, params: object) => void
  cashFlowSummaryInfo: ILoadingData<IProspectSummaryCashFlow>
}

const ProspectReportingSummaryCashFlow = ({
  cashFlowSummaryInfo,
  showCashFlowSummaryInfo,
}: IProps) => {
  const { id } = useParams<{ id: string }>()
  const { isLoading, data, asOfDate } = useMemo(
    () => ({
      isLoading: cashFlowSummaryInfo.isLoading,
      data: cashFlowSummaryInfo.data,
      asOfDate: cashFlowSummaryInfo.data?.asOfDate,
    }),
    [cashFlowSummaryInfo],
  )
  const [params, setParams] = useState({
    option: FinancialsType.Actuals,
    numberOfMonths: 3,
  })

  const debounceShowCashFlowSummaryInfo = useMemo(
    () =>
      debounceEventHandler((params: any) => {
        showCashFlowSummaryInfo(id, params)
      }, 500),
    [id, showCashFlowSummaryInfo],
  )

  useEffect(() => {
    debounceShowCashFlowSummaryInfo(params)
  }, [debounceShowCashFlowSummaryInfo, params])

  const validate = useMemo(
    () =>
      makeValidate(
        Yup.object().shape({
          numberOfMonths: Yup.number()
            .typeError('Invalid number')
            .when('option', (option: FinancialsType, validation: any) => {
              const maxNumberOfMonth =
                option === FinancialsType.Actuals ? data.numberOfMonthLast : data.numberOfMonthNext
              return validation.max(
                maxNumberOfMonth,
                `Max number of month should be less than or equal ${maxNumberOfMonth}`,
              )
            }),
        }),
      ),
    [data],
  )

  const handleUpdateParams = useCallback(
    ({ values }) => {
      if (!data) {
        return
      }
      const maxNumberOfMonth =
        values.option === FinancialsType.Actuals ? data.numberOfMonthLast : data.numberOfMonthNext

      if (+values.numberOfMonths && values.numberOfMonths <= maxNumberOfMonth) {
        setParams(values)
      }
    },
    [data],
  )

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <div className={styles.titleContainer}>
          <div className={styles.title}>Cash Flow</div>
          <div className={styles.subTitle}>
            {asOfDate ? (
              <span>
                as of <span className={styles.subTitleMonth}>{formatDateMonthYear(asOfDate)}</span>
              </span>
            ) : (
              <Skeleton className={styles.subTitleMonthLoader} width={85} height={20} />
            )}
          </div>
        </div>
        <Form
          validate={validate}
          onSubmit={voidHandler}
          initialValues={params}
          render={() => (
            <div className={styles.inputContainer}>
              <div className={styles.selectFieldWrapper}>
                <SelectField
                  name="option"
                  className={styles.selectField}
                  label="Option"
                  variant="outlined"
                  options={options}
                  disabled={isLoading}
                  fullWidth={false}
                />
              </div>
              <TextField
                className={styles.textField}
                name="numberOfMonths"
                label="Number of Months"
                InputProps={{
                  type: 'number',
                }}
                disabled={isLoading}
              />
              <FormSpy subscription={{ values: true }} onChange={handleUpdateParams} />
            </div>
          )}
        />
      </div>
      <div className={styles.data}>
        <div className={styles.row}>
          <span>Most Recent Cash</span>
          {isLoading ? (
            <Skeleton width={100} height={15} />
          ) : (
            <span>
              <span className={genericSs.pricePrefix}>$</span>
              {formatPrice(data?.mostRecentCash)}
            </span>
          )}
        </div>
        <div className={styles.row}>
          <span>Average Operating Cash Flow</span>
          {isLoading ? (
            <Skeleton width={100} height={15} />
          ) : (
            <span>
              <span className={genericSs.pricePrefix}>$</span>
              {formatPrice(data?.avgOperatingCashFlow)}
            </span>
          )}
        </div>
        <div className={styles.row}>
          <span>Months of Cash On-hand (Operating Cash Flow)</span>
          {isLoading ? (
            <Skeleton width={100} height={15} />
          ) : (
            <span>{data?.monthsOfCashOnHandOperatingCashFlow || 0}</span>
          )}
        </div>
        <div className={styles.row}>
          <span>Average EBITDA</span>
          {isLoading ? (
            <Skeleton width={100} height={15} />
          ) : (
            <span>
              <span className={genericSs.pricePrefix}>$</span>
              {formatPrice(data?.avgEBITDA)}
            </span>
          )}
        </div>
        <div className={styles.row}>
          <span>Months of Cash On-hand (EBITDA)</span>
          {isLoading ? (
            <Skeleton width={100} height={15} />
          ) : (
            <span>{data?.monthsOfCashOnHandEBITDA || 0}</span>
          )}
        </div>
        <div className={styles.row}>
          <span>Average Net Income</span>
          {isLoading ? (
            <Skeleton width={100} height={15} />
          ) : (
            <span>
              <span className={genericSs.pricePrefix}>$</span>
              {formatPrice(data?.avgNetIncome)}
            </span>
          )}
        </div>
      </div>
    </div>
  )
}

export default ProspectReportingSummaryCashFlow
