import React, { useCallback } from 'react'
import CircularProgress from '@mui/material/CircularProgress'
import Tooltip from '@mui/material/Tooltip'

import styles from './DueDiligenceClientDashboard.module.scss'
import { ReactComponent as ArrowRightIcon } from '@assets/images/arrow-right-circle-icon.svg'

interface IProps {
  progress: number
  title: string
  onClick: () => void
}
const DueDiligenceClientDashboardProgressCard = ({ progress, title, onClick }: IProps) => {
  const handleClick = useCallback(() => {
    onClick()
  }, [onClick])

  return (
    <div className={styles.diligenceProgressCardItem}>
      <div className={styles.diligenceProgressCardItemProgressContainer}>
        <CircularProgress
          classes={{
            root: styles.diligenceProgressCardItemProgress,
            circle: styles.diligenceProgressCardItemProgressCircleBackground,
          }}
          variant="determinate"
          value={100}
          size={92}
        />
        <CircularProgress
          classes={{
            root: styles.diligenceProgressCardItemProgress,
            circle: styles.diligenceProgressCardItemProgressCircle,
          }}
          variant="determinate"
          value={progress}
          size={92}
        />
        <div className={styles.diligenceProgressCardItemProgressText}>
          <span>{progress}%</span>
        </div>
      </div>
      <div className={styles.diligenceProgressCardItemTextContainer}>
        <div className={styles.diligenceProgressCardItemTextLabel}>Progress of</div>
        <div className={styles.diligenceProgressCardItemTextTitle}>
          <Tooltip title={title} placement="top">
            <span>{title}</span>
          </Tooltip>
        </div>
      </div>
      <div className={styles.diligenceProgressCardItemLink} onClick={handleClick}>
        Resume <ArrowRightIcon />
      </div>
    </div>
  )
}

export default DueDiligenceClientDashboardProgressCard
