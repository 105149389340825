import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'

import ProspectReportingSummaryPayables from './ProspectReportingSummaryPayables'

import { apSummary } from '../../redux/prospect/selectors'
import { listAPSummary } from '../../redux/prospect/actions'

const selector = createStructuredSelector({
  apSummary,
})

const actions = {
  listAPSummary,
}

export default connect(selector, actions)(ProspectReportingSummaryPayables)
