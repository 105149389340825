import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'

import AddNote from './AddNote'

import { addNote } from '../../../redux/notes/actions'
import { pathname } from '../../../redux/router/selectors'

import { isLoading } from '../../../redux/notes/selectors'
const selector = createStructuredSelector({
  pathname,
  isLoading,
})

const actions = {
  addNote,
}

export default connect(selector, actions)(AddNote)
