import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'

import EntitySettingsGeneralPage from './EntitySettingsGeneralPage'

import { getEntityInfo, updateEntityInfo, listEntityInfo } from '../../redux/entityInfo/actions'
import { entity, entityMergeConflicts } from '../../redux/entityInfo/selectors'

const selector = createStructuredSelector({
  entity,
  entityMergeConflicts,
})
const actions = {
  getEntityInfo,
  updateEntityInfo,
  listEntityInfo,
}

export default connect(selector, actions)(EntitySettingsGeneralPage)
