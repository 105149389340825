import { createTheme } from '@mui/material/styles'

const jakartaSans = {
  fontFamily: 'Plus Jakarta Sans',
  fontStyle: 'normal',
  fontWeight: 400,
  src: `
    local('Jakarta Sans'),
    local('JakartaSans-Regular'),
    url('../assets/fonts/JakartaSans-Regular.ttf') format('truetype')
  `,
  unicodeRange:
    'U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF',
}

export default createTheme({
  palette: {
    primary: {
      main: '#0066F5',
    },
    secondary: {
      main: '#1A202C',
    },
  },
  spacing: 8,
  typography: {
    fontFamily: 'Plus Jakarta Sans',
    fontSize: 14,
    body1: {
      fontSize: 12,
    },
    body2: {
      fontSize: 14,
    },
    subtitle1: {
      fontSize: 18,
      fontWeight: 700,
    },
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        '@global': {
          '@font-face': [jakartaSans],
        },
      },
    },
  },
})
