import React from 'react'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import Skeleton from '@mui/material/Skeleton'

import Card from '../Common/Card'
import TableLoader from '../Common/TableLoader'
import TableContainer from '../Common/TableContainer'
import Table from '../Common/Table'
import TableHead from '../Common/TableHead'
import TableBody from '../Common/TableBody'
import TableFooter from '../Common/TableFooter'

const BBCAvailabilityItemLoader = () => {
  return (
    <Box display="flex" alignItems="center" mt={1}>
      <Box flex={3}>
        <Skeleton height={15} width={120} />
      </Box>
      <Box flex={1} display="flex" alignItems="center" justifyContent="flex-end">
        <Skeleton height={15} width={100} />
      </Box>
      <Box flex={1} display="flex" alignItems="center" justifyContent="flex-end">
        <Skeleton height={15} width={100} />
      </Box>
    </Box>
  )
}
const BBCLoader = () => {
  return (
    <>
      <Box display="flex" justifyContent="space-between" mb={3}>
        <Box mt={1.25}>
          <Skeleton width={450} height={21} />
        </Box>
        <Box display="flex" gap={4}>
          <Skeleton width={90} height={32} />
          <Skeleton width={100} height={32} />
        </Box>
      </Box>

      <Grid container alignItems="baseline" spacing={1}>
        <Grid mr={10} item xs={5} lg={5} rowSpacing={2}>
          <Skeleton width={120} height={20} />
          <br />

          <BBCAvailabilityItemLoader />

          <BBCAvailabilityItemLoader />

          <BBCAvailabilityItemLoader />

          <BBCAvailabilityItemLoader />

          <BBCAvailabilityItemLoader />

          <br />

          <Skeleton width={120} height={20} />
          <br />

          <BBCAvailabilityItemLoader />

          <BBCAvailabilityItemLoader />

          <BBCAvailabilityItemLoader />

          <BBCAvailabilityItemLoader />

          <BBCAvailabilityItemLoader />
        </Grid>

        <Grid mr={10} item xs={5} lg={5} rowSpacing={2}>
          <Skeleton width={120} height={20} />
          <br />

          <BBCAvailabilityItemLoader />

          <BBCAvailabilityItemLoader />

          <BBCAvailabilityItemLoader />

          <BBCAvailabilityItemLoader />

          <BBCAvailabilityItemLoader />

          <br />

          <Skeleton width={120} height={20} />
          <br />

          <BBCAvailabilityItemLoader />

          <BBCAvailabilityItemLoader />

          <BBCAvailabilityItemLoader />

          <BBCAvailabilityItemLoader />

          <BBCAvailabilityItemLoader />
        </Grid>
      </Grid>
      <Box display="flex" alignItems="center" justifyContent="space-between" mt={4} mb={3}>
        <Skeleton width={300} height={60} />
        <Box display="flex" gap={3}>
          <Skeleton width={36} height={60} />
          <Skeleton width={36} height={60} />
          <Skeleton width={36} height={60} />
        </Box>
      </Box>

      <Card>
        <Skeleton width={120} height={24} />
        <br />

        <TableContainer>
          <Table>
            <TableHead>
              <TableLoader columnsCount={11} rowsCount={1} />
            </TableHead>
            <TableBody>
              <TableLoader columnsCount={11} rowsCount={10} />
            </TableBody>
            <TableFooter>
              <TableLoader columnsCount={11} rowsCount={2} />
            </TableFooter>
          </Table>
        </TableContainer>
        <br />
      </Card>
    </>
  )
}

export default BBCLoader
