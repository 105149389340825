import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'

import ComplianceByClientTable from './ComplianceByClientTable'

import { clients, complianceData, refreshCount } from '../../redux/clientInfo/selectors'
import { listCompliance, refreshAutoReporting } from '../../redux/clientInfo/actions'

import { updateClient } from '../../redux/clientInfo/actions'
import { isAdminRightsRole, user, userId } from '../../redux/profile/selectors'

const selector = createStructuredSelector({
  clients,
  fullComplianceData: complianceData,
  user,
  isAdminRightsRole,
  refreshCount,
  userId,
})
const actions = {
  listCompliance,
  updateClient,
  refreshAutoReporting,
}

export default connect(selector, actions)(ComplianceByClientTable)
