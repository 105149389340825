import Base from './base'

export default class EntityInfo extends Base {
  addEntityInfo(data: object) {
    return this.apiClient.post('entity-info', data)
  }

  listEntityInfo(data: object = {}) {
    return this.apiClient.get('entity-info', data)
  }

  getEntityInfo(id: string) {
    return this.apiClient.get(`entity-info/${id}`)
  }

  getEntityPreview(id: string, data: object = {}) {
    return this.apiClient.get(`entity-info/${id}/preview`, data)
  }

  calculateEntityFlags(id: string) {
    return this.apiClient.get(`entity-info/${id}/flags-calculate`)
  }

  getArSummary(id: string, params: object) {
    return this.apiClient.get(`entity-info/${id}/ar-summary`, params)
  }

  getArDetails(id: string, params: object) {
    return this.apiClient.get(`entity-info/${id}/ar-details`, params)
  }

  getApSummary(id: string, params: object) {
    return this.apiClient.get(`entity-info/${id}/ap-summary`, params)
  }

  getApDetails(id: string, params: object) {
    return this.apiClient.get(`entity-info/${id}/ap-details`, params)
  }

  getDilution(id: string, params: object) {
    return this.apiClient.get(`entity-info/${id}/entity-dilution`, params)
  }

  getActivity(id: string, params: object) {
    return this.apiClient.get(`entity-info/${id}/activity`, params)
  }

  listAliasMapping(id: string, params: object) {
    return this.apiClient.get(`aliases/mapping/${id}`, params)
  }

  listEntityAliases(id: string, data: object) {
    return this.apiClient.get(`aliases/${id}`, data)
  }
  updateEntityInfo(id: string, data: object | FormData) {
    return this.apiClient.put(`entity-info/${id}`, data)
  }

  getEntityInfoDuplicates(id: string) {
    return this.apiClient.get(`entity-info/${id}/duplicate`)
  }

  updateDuplicateEntity(id: string, data: object) {
    return this.apiClient.put(`entity-info/${id}/duplicate`, data)
  }

  listEntityAr(id: string, data: object) {
    return this.apiClient.get(`entity-ar/${id}`, data)
  }
  updateEntityAr(id: string, itemId: string, data: object) {
    return this.apiClient.put(`entity-ar/${id}/update/${itemId}`, data)
  }

  listEntityAp(id: string, data: object) {
    return this.apiClient.get(`entity-ap/${id}`, data)
  }
  updateEntityAp(id: string, itemId: string, data: object) {
    return this.apiClient.put(`entity-ap/${id}/update/${itemId}`, data)
  }

  listCustomers(data: object) {
    return this.apiClient.get('customers', data)
  }

  listVendors(data: object) {
    return this.apiClient.get('vendors', data)
  }

  listInvestors(data: object) {
    return this.apiClient.get('investors', data)
  }

  listInvestorSummary(id: string, data: object) {
    return this.apiClient.get(`investor-summary/${id}`, data)
  }

  getInvestmentDetailRow(id: string, data: object) {
    return this.apiClient.get(`investors/${id}/details`, data)
  }

  exportEntities(params: object) {
    return this.apiClient.post('entities/export', params, { isFile: true })
  }
  addRelationship(id: string, parentId: string) {
    return this.apiClient.post(`entity-info/${id}/relationship/${parentId}`)
  }
  deleteRelationship(id: string, parentId: string) {
    return this.apiClient.delete(`entity-info/${id}/relationship/${parentId}`)
  }

  listEntityAccounts(id: string, data: object) {
    return this.apiClient.get(`entity-info/${id}/accounts`, data)
  }

  updateEntityAccounts(id: string, data: object) {
    return this.apiClient.post(`entity-info/${id}/accounts`, data)
  }
}
