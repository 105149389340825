import React, { useCallback, useMemo } from 'react'
import { generatePath, Link as RouterLink } from 'react-router-dom'
import moment from 'moment'
import Link from '@mui/material/Link'
import cn from 'classnames'
import { ReactComponent as ExpandChevron } from '@assets/images/chevron-forward-icon.svg'
import { INewsfeed, ClientNewsIndustries } from '@common/interfaces/newsfeed'
import { ROUTES } from '../../constants/routes'
import { DATE_FORMAT } from '../../constants/common'
import styles from './MarketNewsDetails.module.scss'
import KeyboardDatePicker from '../Common/KeyboardDatePicker'
import Checkbox from '../Common/Checkbox'
import { OpenIndustriesState } from './MarketNewsDetails'
import { IClientInfo } from '@common/interfaces/client'

interface IProps {
  newsItem: INewsfeed
  industry: ClientNewsIndustries
  handleIndustryToggle: (industry: ClientNewsIndustries) => void
  handleUpdateIndustry: (industry: ClientNewsIndustries) => void
  handleUpdateDisplayUntil: (date: Date, id: string) => void
  openIndustries: OpenIndustriesState
  clientIndustries: Record<ClientNewsIndustries, IClientInfo[]>
}

const ClientDistributionIndustries = ({
  newsItem,
  industry,
  handleIndustryToggle,
  handleUpdateIndustry,
  handleUpdateDisplayUntil,
  openIndustries,
  clientIndustries,
}: IProps) => {
  const clientNewsItem = useMemo(
    () => newsItem.clientNewsfeedHeadlines.find((item) => item.type === industry),
    [newsItem, industry],
  )
  const handleIndustryClick = useCallback(() => {
    handleIndustryToggle(industry as ClientNewsIndustries)
  }, [handleIndustryToggle, industry])

  const handleCheckboxChange = useCallback(() => {
    handleUpdateIndustry(industry)
  }, [handleUpdateIndustry, industry])

  const handleDisplayUntilChange = useCallback(
    (date: Date) => {
      handleUpdateDisplayUntil(date, clientNewsItem?.id)
    },
    [handleUpdateDisplayUntil, clientNewsItem?.id],
  )

  const displayUntilDate = useMemo(() => {
    return clientNewsItem?.displayUntil ? moment(clientNewsItem?.displayUntil) : null
  }, [clientNewsItem?.displayUntil])

  return (
    <div key={industry} className={styles.industryRow}>
      <div className={styles.industryHeader} onClick={handleIndustryClick}>
        <div className={styles.industryCheckboxTitle}>
          <Checkbox
            name={industry}
            checked={!!clientNewsItem}
            color="primary"
            onChange={handleCheckboxChange}
          />
          <div className={styles.industryName}>{industry}</div>
        </div>
        <ExpandChevron
          className={cn(styles.expandChevron, {
            [styles.chevronOpen]: openIndustries[industry as ClientNewsIndustries],
          })}
        />
      </div>
      {openIndustries[industry as ClientNewsIndustries] && (
        <div className={styles.clientListContainer}>
          <KeyboardDatePicker
            name="recordDate"
            inputFormat={DATE_FORMAT}
            withTopLabel={!!clientNewsItem}
            label="Display Until"
            placeholder="Display Until"
            disabled={!clientNewsItem}
            calendarSelectOnly
            value={displayUntilDate}
            className={styles.recordDate}
            useFinalForm={false}
            maxDate={moment().add(14, 'days').toDate()}
            minDate={moment().toDate()}
            onChange={handleDisplayUntilChange}
          />
          <div className={styles.clientList}>
            {clientIndustries[industry as ClientNewsIndustries].map((client) => (
              <div key={client.id} className={styles.clientName}>
                <Link
                  component={RouterLink}
                  to={generatePath(ROUTES.CLIENT_PAGE, {
                    id: client.id,
                  })}
                >
                  {client.clientName}
                </Link>
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  )
}

export default ClientDistributionIndustries
