import React, { useMemo } from 'react'
import { IClientInfo } from '@common/interfaces/client'
import { formatPrice } from '../../helpers/helpers'
import DashboardHeader from '../DashboardHeader'
import { IHeaderItem } from '../DashboardHeader/DashboardHeader'

interface IProps {
  clientInfo: IClientInfo
}

const ClientDashboardHeader = ({ clientInfo }: IProps) => {
  const allBalances = useMemo(
    () =>
      [
        {
          label: 'ABL',
          value: clientInfo?.currentAmount,
        },
      ].concat(
        clientInfo?.termLoans?.map((termLoan) => ({
          label: termLoan?.collateralName,
          value: termLoan?.loanBalance,
        })),
      ),
    [clientInfo],
  )
  const totalBalance = useMemo(() => {
    return allBalances.reduce((sum, step) => sum + (step?.value || 0), 0)
  }, [allBalances])

  const clientDashboardHeaderItems: IHeaderItem[] = useMemo(
    () => [
      {
        title: 'Total Loan Balance',
        type: 'currency',
        value: totalBalance,
      },
      {
        title: 'Last Excess Availability',
        type: 'string',
        value: `$${formatPrice(clientInfo?.excessLoanAvailability, 0)}`,
      },
      {
        title: 'Last Funding',
        type: 'date',
        value: clientInfo?.lastBBC,
      },
      {
        title: 'Max Line Amount',
        type: 'string',
        value: `$${formatPrice(clientInfo?.maxLineAmount, 0)}`,
      },
    ],
    [clientInfo, totalBalance],
  )

  return <DashboardHeader headerItems={clientDashboardHeaderItems} />
}

export default ClientDashboardHeader
