import React from 'react'
import { NavLink } from 'react-router-dom'
import cn from 'classnames'
import ListItem from '@mui/material/ListItem'

import styles from '../SideMenu.module.scss'
import { ISubMenuData } from '../SideMenuConfig'

interface IProps extends ISubMenuData {
  sidebarExpanded: boolean
}

const NestedNavItem = ({ link, label, icon, sidebarExpanded }: IProps) => {
  return (
    <NavLink exact key={link} to={link} activeClassName={styles.active}>
      <ListItem className={styles.nestedItem}>
        <div className={cn(styles.icon, styles.nestedIcon)}>{icon}</div>
        {sidebarExpanded && <p className={styles.expandableLabel}>{label}</p>}
      </ListItem>
    </NavLink>
  )
}

export default NestedNavItem
