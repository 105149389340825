import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Editor } from 'react-draft-wysiwyg'
import { EditorState, convertToRaw, ContentState } from 'draft-js'
import draftToHtml from 'draftjs-to-html'
import htmlToDraft from 'html-to-draftjs'
import cn from 'classnames'
import styles from './RichTextEditor.module.scss'
import { debounceEventHandler } from '../../../helpers/helpers'
import boldIcon from './../../../assets/images/bold.svg'
import italicIcon from './../../../assets/images/italic.svg'
import underlineIcon from './../../../assets/images/underline.svg'
import smileIcon from './../../../assets/images/smile.svg'
import alignCenterIcon from './../../../assets/images/alignCenter.svg'
import alignLeftIcon from './../../../assets/images/alignLeft.svg'

const toolbar = {
  options: ['fontSize', 'inline', 'textAlign', 'list', 'link', 'image', 'emoji'],
  inline: {
    options: ['bold', 'italic', 'underline'],
    bold: { icon: boldIcon },
    italic: { icon: italicIcon },
    underline: { icon: underlineIcon },
  },
  link: {
    options: ['link'],
  },
  textAlign: {
    options: ['left', 'center'],
    left: { icon: alignLeftIcon },
    center: { icon: alignCenterIcon },
  },
  list: {
    options: ['ordered'],
  },
  emoji: {
    icon: smileIcon,
  },
}

interface IProps extends React.ComponentProps<typeof Editor> {
  className?: string
  readOnly?: boolean
  value: string
  handleChange?: (value: string) => void
}

const RichTextEditor = ({
  className,
  value,
  handleChange = null,
  readOnly = false,
  ...props
}: IProps) => {
  const [editorState, setEditorState] = useState(EditorState.createEmpty())

  useEffect(() => {
    const contentBlock = htmlToDraft(value)
    if (contentBlock) {
      setEditorState(
        EditorState.createWithContent(
          ContentState.createFromBlockArray(contentBlock.contentBlocks, contentBlock.entityMap),
        ),
      )
    }
  }, [value])

  const handleSave = useCallback(
    (state: ContentState) => {
      const html = draftToHtml(convertToRaw(state)).replace(/(\r\n|\n|\r)/gm, '')
      if (html !== value) {
        handleChange && handleChange(html)
      }
    },
    [handleChange, value],
  )
  const handleValueChanges = useMemo(
    () =>
      debounceEventHandler((state: ContentState) => {
        handleSave(state)
      }, 2000),
    [handleSave],
  )
  const handleEditorStateChange = useCallback(
    (state: EditorState) => {
      setEditorState(state)
      handleValueChanges(state.getCurrentContent())
    },
    [handleValueChanges],
  )

  return (
    <div className={cn(styles.editorContainer, className)}>
      <Editor
        toolbar={toolbar}
        toolbarClassName={styles.editorToolbar}
        wrapperClassName={styles.editorWrapper}
        editorClassName={styles.editor}
        spellCheck
        editorState={editorState}
        // @ts-ignore
        onBlur={(event, state) => handleSave(state.getCurrentContent())}
        onEditorStateChange={handleEditorStateChange}
        readOnly={readOnly}
        {...props}
      />
    </div>
  )
}

export default RichTextEditor
