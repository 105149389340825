import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'

import DueDiligenceApplicationTableReferences from './DueDiligenceApplicationTableReferences'

import { dueDiligenceTeamReferences } from '../../redux/dueDiligence/selectors'
import { listTeamReferences, hideTeamReferences } from '../../redux/dueDiligence/actions'

const selector = createStructuredSelector({
  dueDiligenceTeamReferences,
})
const actions = {
  listTeamReferences,
  hideTeamReferences,
}

export default connect(selector, actions)(DueDiligenceApplicationTableReferences)
