import { connect } from 'react-redux'

import BBCInventoryIneligibleTable from './BBCInventoryIneligibleTable'
import BBCInventoryIneligibleTableLoader from './BBCInventoryIneligibleTableLoader'

import {
  listInventoryIneligibleCategories,
  updateInventoryIneligibleCategories,
  updateFields,
} from '../../redux/bbc/actions'
import { listInventoryCategories } from '../../redux/clientInfo/actions'

const actions = {
  listInventoryCategories,
  listInventoryIneligibleCategories,
  updateInventoryIneligibleCategories,
  updateFields,
}

export { BBCInventoryIneligibleTableLoader }

export default connect(null, actions)(BBCInventoryIneligibleTable)
