import { ActionPattern } from '@redux-saga/types'
import { IApi } from '../../api'
import { CommonPromiseAction } from '../types'

export const prefix = 'reportingDocument'

export const CREATE_REQUEST = `${prefix}/CREATE_REQUEST`
export const CREATE_SUCCESS = `${prefix}/CREATE_SUCCESS`
export const CREATE_FAILURE = `${prefix}/CREATE_FAILURE`

export const createReportingDocument: CommonPromiseAction = (data: { name: string }) => ({
  type: [CREATE_REQUEST, CREATE_SUCCESS, CREATE_FAILURE],
  promise: (api: IApi) => api.reportingDocument.create(data),
})

export const LIST_REQUEST = `${prefix}/LIST_REQUEST`
export const LIST_SUCCESS = `${prefix}/LIST_SUCCESS`
export const LIST_FAILURE = `${prefix}/LIST_FAILURE` as ActionPattern

export const listReportingDocuments: CommonPromiseAction = () => ({
  type: [LIST_REQUEST, LIST_SUCCESS, LIST_FAILURE],
  promise: (api: IApi) => api.reportingDocument.list(),
})
