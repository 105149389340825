import React, { useMemo, useEffect } from 'react'
import { useParams } from 'react-router'
import { Bar } from 'react-chartjs-2'
import Skeleton from '@mui/material/Skeleton'
import cn from 'classnames'

import styles from './ARAPSummaryOverTime.module.scss'

import { IARAPChart, BBC_AGING_REPORT, ReportingFlow } from '@common/interfaces/bbc'
import { formatPrice, formatAmount } from '../../helpers/helpers'
import { ILoadingData } from '../../redux/types'
import { BORDER_RADIUS, FONT_SIZE, TOOLTIP } from '../../constants/graphs'

interface IProps {
  getARAPChartData: (id: string, data: object) => void
  arapChartData: ILoadingData<IARAPChart>
  companyValues: string[]
  currentDateRange: { startDate: string; endDate: string }
  currentMetric: string
  agingReport: BBC_AGING_REPORT
  reportingFlow: ReportingFlow
  clientId?: string
  isFullscreen?: boolean
}

const ARAPSummaryOverTime = ({
  reportingFlow,
  arapChartData,
  getARAPChartData,
  companyValues,
  currentDateRange,
  currentMetric,
  agingReport,
  clientId,
  isFullscreen,
}: IProps) => {
  const { id } = useParams<{ id: string }>()

  const { data: arapChart } = useMemo(() => arapChartData, [arapChartData])

  const [initialLoading, setInitialLoading] = React.useState(true)

  useEffect(() => {
    if (currentDateRange.startDate !== '' && currentDateRange.endDate !== '' && agingReport) {
      getARAPChartData(id || clientId, {
        startDate: currentDateRange.startDate,
        endDate: currentDateRange.endDate,
        agingReport,
        entities: [...companyValues],
        reportingFlow,
      })
    }
  }, [id, getARAPChartData, currentDateRange, companyValues, agingReport, reportingFlow, clientId])

  useEffect(() => {
    if (arapChart?.data) {
      setInitialLoading(false)
    }
  }, [arapChart?.data])

  const percentSuffix = useMemo(
    () => (currentMetric === 'percent' ? 'Percent' : ''),
    [currentMetric],
  )

  const chartData = useMemo(() => {
    if (arapChart?.id === (id || clientId) && arapChart?.data) {
      const chartRawData = arapChart.data
      return {
        labels: chartRawData?.map((data) => data.recordDate),
        datasets: [
          {
            label: '1 - 30 Days',
            data: chartRawData?.map((data) => data[`0to30Days${percentSuffix}`]),
            backgroundColor: '#54936D',
            borderRadius: BORDER_RADIUS,
          },
          {
            label: '31 - 60 Days',
            data: chartRawData?.map((data) => data[`31to60Days${percentSuffix}`]),
            backgroundColor: '#66B485',
            borderRadius: BORDER_RADIUS,
          },
          {
            label: '61 - 90 Days',
            data: chartRawData?.map((data) => data[`61to90Days${percentSuffix}`]),
            backgroundColor: '#E48C65',
            borderRadius: BORDER_RADIUS,
          },
          {
            label: '91+ Days',
            data: chartRawData?.map((data) => data[`91plusDays${percentSuffix}`]),
            backgroundColor: '#CC5858',
            borderRadius: BORDER_RADIUS,
          },
        ],
      }
    } else {
      return null
    }
  }, [arapChart, percentSuffix, id, clientId])

  const chartOptions = useMemo(() => {
    return {
      maintainAspectRatio: false,
      scales: {
        x: {
          stacked: true,
          grid: {
            display: false,
          },
          ticks: {
            font: {
              size: FONT_SIZE,
            },
          },
        },
        y: {
          stacked: true,
          grid: {
            display: false,
          },
          ticks: {
            font: {
              size: FONT_SIZE,
            },
            callback: (value: any) => {
              if (percentSuffix === 'Percent') {
                return value ? `${value * 100}%` : `0%`
              } else {
                return formatAmount(value) ? `$${formatAmount(value)}` : `$0`
              }
            },
          },
        },
      },
      plugins: {
        legend: {
          position: 'top' as const,
          labels: {
            font: {
              size: FONT_SIZE,
              weight: '500',
            },
            color: 'black',
            boxWidth: 20,
            boxHeight: 20,
          },
        },
        tooltip: {
          ...TOOLTIP,
          callbacks: {
            title: (tooltipItems: any) => {
              // Assuming 'recordDate' is the x-axis label
              return `${tooltipItems[0].label}`
            },
            label: (tooltipItem: any) => {
              const chartRawData = arapChart.data // Ensure arapChart.data is accessible here
              const dataIndex = tooltipItem.dataIndex
              const datasetLabel = tooltipItem.dataset.label

              // Find the key for value and percentage based on the dataset label
              let valueKey, percentKey
              switch (datasetLabel) {
                case '1 - 30 Days':
                  valueKey = '0to30Days'
                  percentKey = '0to30DaysPercent'
                  break
                case '31 - 60 Days':
                  valueKey = '31to60Days'
                  percentKey = '31to60DaysPercent'
                  break
                case '61 - 90 Days':
                  valueKey = '61to90Days'
                  percentKey = '61to90DaysPercent'
                  break
                case '91+ Days':
                  valueKey = '91plusDays'
                  percentKey = '91plusDaysPercent'
                  break
              }

              const value = chartRawData[dataIndex][valueKey]
              const percent = chartRawData[dataIndex][percentKey] * 100

              // Format the value and percent
              const formattedValue = formatPrice(value)
              const formattedPercent = percent.toFixed(2) + '%'

              return [`$${formattedValue}`, `${formattedPercent}`]
            },
            footer: (tooltipItem: any) => {
              return '$' + formatPrice(tooltipItem.reduce((a: any, b: any) => a + b.parsed.y, 0))
            },
          },
        },
      },
    }
  }, [arapChart?.data, percentSuffix])

  return (
    <div
      className={cn(styles.chartContainer, {
        [styles.chartContainerFullScreen]: isFullscreen,
      })}
    >
      {initialLoading ? (
        <Skeleton variant="rectangular" width="100%" height="100%" />
      ) : (
        chartData && <Bar data={chartData} options={chartOptions} />
      )}
    </div>
  )
}

export default ARAPSummaryOverTime
