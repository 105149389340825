import { createSelector } from 'reselect'
import { prefix } from './actions'
import { IRootState } from '../reducer'
import { BLANK_LAYOUT_ROUTES } from '../../constants/routes'

export const location = (state: IRootState) => state[prefix].location

export const pathname = createSelector(location, ({ pathname }) => pathname)

export const isBlankLayout = createSelector(pathname, (pathname) =>
  BLANK_LAYOUT_ROUTES.some((route) => pathname.includes(route)),
)
