import React, { useContext, useEffect, useMemo, useRef } from 'react'
import { TextField as MuiRffTextField, showErrorOnBlur } from 'mui-rff'
import {
  default as MuiCoreTextField,
  TextFieldProps as MuiTextFieldProps,
} from '@mui/material/TextField'
import styles from './TextField.module.scss'
import cn from 'classnames'
import genericSs from '@styles/generic.module.scss'
import { formatValue } from '../../../helpers/helpers'

import { TableCellContext } from '../TableCell/TableCell'
const ExtraInputProps = {
  classes: {
    input: styles.input,
    error: styles.inputError,
  },
  disableUnderline: true,
}

interface TextFieldProps extends Omit<MuiTextFieldProps, 'size'> {
  size?: 'small' | 'large' | 'filterInput' | 'medium'
  useFinalForm?: boolean
  showInputAdornmentBorder?: boolean
  showStartInputAdornmentBorder?: boolean
  showEndInputAdornmentBorder?: boolean
  inTable?: boolean
}

const TextField: React.FC<TextFieldProps> = ({
  inTable = false,
  size = 'small',
  useFinalForm = true,
  showInputAdornmentBorder = true,
  showStartInputAdornmentBorder = true,
  showEndInputAdornmentBorder = true,
  onChange,
  value,
  InputProps,
  ...props
}) => {
  const Component = useFinalForm ? MuiRffTextField : MuiCoreTextField
  const extraProps = useFinalForm ? {} : { onChange, value }
  const context = useContext(TableCellContext)
  const isActive = useMemo(() => context?.isActive, [context])
  const inputRef = useRef<HTMLInputElement>(null)

  const evaluatedType = useMemo(() => {
    if (['percent', 'amount'].includes(props.type)) {
      return props.type
    }
    return null
  }, [props.type])

  useEffect(() => {
    if (inputRef.current && isActive && inTable) {
      inputRef.current.focus()
    }
  }, [isActive, inputRef, inTable])

  return (
    <>
      {!isActive && inTable && (
        <div
          className={cn({
            [genericSs.inactiveFormField]: !value,
            syntheticInputField: inTable,
          })}
        >
          <span>
            {value
              ? formatValue({ value: value, type: evaluatedType, scaleValue: 1 })
              : props.placeholder}
          </span>
        </div>
      )}
      {isActive || !inTable ? (
        <>
          <Component
            {...(props as any)}
            {...extraProps}
            classes={{
              root: cn(styles.root, {
                tableFormField: inTable,
                [styles.inputSmallSize]: size === 'small',
                [styles.inputBigSize]: size === 'large',
                [styles.inputFilterMenu]: size === 'filterInput',

                [styles.inputHideStartAdornmentBorder]: !(
                  showStartInputAdornmentBorder && showInputAdornmentBorder
                ),
                [styles.inputHideEndAdornmentBorder]: !(
                  showEndInputAdornmentBorder && showInputAdornmentBorder
                ),
                [styles.textRight]: !!InputProps?.endAdornment && size !== 'filterInput',
                [styles.inputMultiline]: props.multiline,
              }),
              error: styles.error,
            }}
            inputRef={props.inputRef || inputRef}
            subscription={{ value: true, focus: true, error: true, touched: true }}
            InputProps={{ ...InputProps, ...ExtraInputProps }}
            FormHelperTextProps={{ classes: { root: styles.formHelperTextRoot } }}
            showError={showErrorOnBlur}
            variant="standard"
          />
        </>
      ) : null}
    </>
  )
}

export default TextField
