import React from 'react'

import styles from './GlobalSearchPage.module.scss'

import { ISearchResultItem } from '@common/interfaces/search'
import { INoteMapping } from '@common/interfaces/notes'
import GlobalSearchPageNote from './GlobalSearchPageNote'

const GlobalSearchPageNotes = ({
  items,
  searchString,
}: {
  items: ISearchResultItem[]
  searchString: string
}) => {
  return (
    <div className={styles.notesContainer}>
      {items.map((item) => (
        <GlobalSearchPageNote
          key={item.id}
          note={item.item as INoteMapping}
          searchString={searchString}
        />
      ))}
    </div>
  )
}

export default GlobalSearchPageNotes
