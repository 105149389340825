import { IApi } from '../../api'
import { CommonPromiseAction } from '../types'

export const prefix = 'bdo'

export const LIST_DEBTORS_REQUEST = `${prefix}/LIST_DEBTORS_REQUEST`
export const LIST_DEBTORS_SUCCESS = `${prefix}/LIST_DEBTORS_SUCCESS`
export const LIST_DEBTORS_FAILURE = `${prefix}/LIST_DEBTORS_FAILURE`

export const listDebtors: CommonPromiseAction = () => ({
  type: [LIST_DEBTORS_REQUEST, LIST_DEBTORS_SUCCESS, LIST_DEBTORS_FAILURE],
  promise: (api: IApi) => api.bdo.listDebtors(),
})

export const ADD_DEBTOR_REQUEST = `${prefix}/ADD_DEBTOR_REQUEST`
export const ADD_DEBTOR_SUCCESS = `${prefix}/ADD_DEBTOR_SUCCESS`
export const ADD_DEBTOR_FAILURE = `${prefix}/ADD_DEBTOR_FAILURE`

export const addDebtor: CommonPromiseAction = (data: object) => ({
  type: [ADD_DEBTOR_REQUEST, ADD_DEBTOR_SUCCESS, ADD_DEBTOR_FAILURE],
  promise: (api: IApi) => api.bdo.addDebtor(data),
})

export const LIST_CREDITORS_REQUEST = `${prefix}/LIST_CREDITORS_REQUEST`
export const LIST_CREDITORS_SUCCESS = `${prefix}/LIST_CREDITORS_SUCCESS`
export const LIST_CREDITORS_FAILURE = `${prefix}/LIST_CREDITORS_FAILURE`

export const listCreditors: CommonPromiseAction = () => ({
  type: [LIST_CREDITORS_REQUEST, LIST_CREDITORS_SUCCESS, LIST_CREDITORS_FAILURE],
  promise: (api: IApi) => api.bdo.listCreditors(),
})

export const ADD_CREDITOR_REQUEST = `${prefix}/ADD_CREDITOR_REQUEST`
export const ADD_CREDITOR_SUCCESS = `${prefix}/ADD_CREDITOR_SUCCESS`
export const ADD_CREDITOR_FAILURE = `${prefix}/ADD_CREDITOR_FAILURE`

export const addCreditor: CommonPromiseAction = (data: object) => ({
  type: [ADD_CREDITOR_REQUEST, ADD_CREDITOR_SUCCESS, ADD_CREDITOR_FAILURE],
  promise: (api: IApi) => api.bdo.addCreditor(data),
})
