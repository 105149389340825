import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'

import ChecksUploads from './ChecksUploads'
import { checksFiles } from '../../redux/collections/selectors'
import { uploadChecks } from '../../redux/collections/actions'
import { deleteFile } from '../../redux/file/actions'

const selector = createStructuredSelector({
  checksFiles,
})

const actions = {
  uploadChecks,
  deleteFile,
}

export default connect(selector, actions)(ChecksUploads)
