import { openDB, DBSchema } from 'idb'

const DB_NAME = 'dwight-funding-portal'
const IMAGE_STORE_NAME = 'images'

interface MyDB extends DBSchema {
  images: {
    value: any
    key: string
  }
}

const dbPromise = openDB<MyDB>(DB_NAME, 1, {
  upgrade(db) {
    db.createObjectStore(IMAGE_STORE_NAME)
  },
})

const imageStore = {
  get: async (key: string) => (await dbPromise).get(IMAGE_STORE_NAME, key),
  set: async (key: string, val: any) => (await dbPromise).put(IMAGE_STORE_NAME, val, key),
  del: async (key: string) => (await dbPromise).delete(IMAGE_STORE_NAME, key),
  clear: async () => (await dbPromise).clear(IMAGE_STORE_NAME),
  keys: async () => (await dbPromise).getAllKeys(IMAGE_STORE_NAME),
}

export default imageStore
