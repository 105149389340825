import React from 'react'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import styles from './RequestFundingPage.module.scss'
import Card from '../../components/Common/Card'
import ClientDashboardHeader from '../../components/ClientDashboardHeader'
import RequestFunding from '../../components/RequestFunding'
import ClientBanners from '../../components/ClientBanners'
import OverdueReportingBanner from '../../components/OverdueReportingBanner'
import DashboardManager from '../../components/DashboardManager'
import { IClientInfo } from '@common/interfaces/client'
import RequestFundingPageLoader from './RequestFundingPageLoader'
import ClientNewsfeedContainer from '../../components/ClientNewsfeedContainer'
import cn from 'classnames'
interface IProps {
  clientInfo: IClientInfo
}

const RequestFundingPage = ({ clientInfo }: IProps) => {
  return (
    <Box pt={1}>
      {!clientInfo && <RequestFundingPageLoader />}
      {clientInfo && (
        <>
          <ClientBanners />
          <OverdueReportingBanner />
          <ClientDashboardHeader />

          <Grid container spacing={2} alignItems="start" justifyContent={'space-between'}>
            <Grid item xs={9}>
              <Card
                withBorder
                className={cn(styles.mainCard, {
                  [styles.mainCardWitouthManager]: !clientInfo.primaryManager,
                })}
                classes={{ content: styles.mainCardContent, title: styles.mainCardTitle }}
                title="Request Funding"
              >
                <RequestFunding />
              </Card>
              {clientInfo.primaryManager && <DashboardManager />}
            </Grid>
            <Grid item xs={3}>
              <div className={styles.rightPanelContainer}>
                <div className={styles.newsContainer}>
                  <ClientNewsfeedContainer />
                </div>
              </div>
            </Grid>
          </Grid>
        </>
      )}
    </Box>
  )
}

export default RequestFundingPage
