import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import ClientProfilePictureUpload from './ClientProfilePictureUpload'

import { clientInfo } from '../../redux/clientInfo/selectors'
import { updateClient, show } from '../../redux/clientInfo/actions'

const selector = createStructuredSelector({
  clientInfo,
})
const actions = { updateClient, showClient: show }

export default connect(selector, actions)(ClientProfilePictureUpload)
