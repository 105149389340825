import React from 'react'
import Grid from '@mui/material/Grid'
import Card from '../../Common/Card'
import { ClientABLField } from '../ClientHelpers'
import styles from '../DealStructure/DealStructure.module.scss'
import { ClientSetupSections, allClientTermsInfo } from '@common/interfaces/client'

const FIELDS_MAP = allClientTermsInfo.filter(
  (term) => term.section === ClientSetupSections.AdministrationInfo,
)
const AdministrationInfo = () => {
  return (
    <Card
      title="Administration info"
      classes={{
        root: styles.card,
      }}
    >
      <Grid container spacing={2} justifyContent={'start'}>
        {FIELDS_MAP.map(({ label, value, type, options, disabled }) => (
          <Grid item xs={3} xl={2} key={label}>
            <ClientABLField
              label={label}
              name={value}
              type={type}
              options={options}
              disabled={disabled}
            />
          </Grid>
        ))}
      </Grid>
    </Card>
  )
}

export default AdministrationInfo
