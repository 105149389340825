import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'

import { confirm } from '../../redux/profile/actions'
import { isConfirmed } from '../../redux/profile/selectors'

import ConfirmEmail from './ConfirmEmail'

const selector = createStructuredSelector({
  isConfirmed,
})

const actions = {
  onConfirm: confirm,
}

export { ConfirmEmail }

export default connect(selector, actions)(ConfirmEmail)
