import { IApi } from '../../api'
import { CommonPromiseAction } from '../types'

export const prefix = 'activity'

export const LIST_ACTIVITY_REQUEST = `${prefix}/LIST_ACTIVITY_REQUEST`
export const LIST_ACTIVITY_SUCCESS = `${prefix}/LIST_ACTIVITY_SUCCESS`
export const LIST_ACTIVITY_FAILURE = `${prefix}/LIST_ACTIVITY_FAILURE`

export const listActivities: CommonPromiseAction = (data: object) => ({
  type: [LIST_ACTIVITY_REQUEST, LIST_ACTIVITY_SUCCESS, LIST_ACTIVITY_FAILURE],
  promise: (api: IApi) => api.activity.list(data),
  params: data,
})

export const CREATE_ACTIVITY_REQUEST = `${prefix}/CREATE_ACTIVITY_REQUEST`
export const CREATE_ACTIVITY_SUCCESS = `${prefix}/CREATE_ACTIVITY_SUCCESS`
export const CREATE_ACTIVITY_FAILURE = `${prefix}/CREATE_ACTIVITY_FAILURE`

export const createActivity: CommonPromiseAction = (data: object) => ({
  type: [CREATE_ACTIVITY_REQUEST, CREATE_ACTIVITY_SUCCESS, CREATE_ACTIVITY_FAILURE],
  promise: (api: IApi) => api.activity.create(data),
})

export const UPDATE_ACTIVITY_REQUEST = `${prefix}/UPDATE_ACTIVITY_REQUEST`
export const UPDATE_ACTIVITY_SUCCESS = `${prefix}/UPDATE_ACTIVITY_SUCCESS`
export const UPDATE_ACTIVITY_FAILURE = `${prefix}/UPDATE_ACTIVITY_FAILURE`

export const updateActivity: CommonPromiseAction = (id: string, data: object) => ({
  type: [UPDATE_ACTIVITY_REQUEST, UPDATE_ACTIVITY_SUCCESS, UPDATE_ACTIVITY_FAILURE],
  promise: (api: IApi) => api.activity.update(id, data),
})

export const DELETE_ACTIVITY_REQUEST = `${prefix}/DELETE_ACTIVITY_REQUEST`
export const DELETE_ACTIVITY_SUCCESS = `${prefix}/DELETE_ACTIVITY_SUCCESS`
export const DELETE_ACTIVITY_FAILURE = `${prefix}/DELETE_ACTIVITY_FAILURE`

export const deleteActivity: CommonPromiseAction = (id: string) => ({
  type: [DELETE_ACTIVITY_REQUEST, DELETE_ACTIVITY_SUCCESS, DELETE_ACTIVITY_FAILURE],
  promise: (api: IApi) => api.activity.delete(id),
})

export const TRACK_ACTIVITY_REQUEST = `${prefix}/TRACK_ACTIVITY_REQUEST`
export const TRACK_ACTIVITY_SUCCESS = `${prefix}/TRACK_ACTIVITY_SUCCESS`
export const TRACK_ACTIVITY_FAILURE = `${prefix}/TRACK_ACTIVITY_FAILURE`

export const trackActivity: CommonPromiseAction = (data: object, beacon: boolean = false) => ({
  type: [TRACK_ACTIVITY_REQUEST, TRACK_ACTIVITY_SUCCESS, TRACK_ACTIVITY_FAILURE],
  promise: (api: IApi) => api.activity.track(data, beacon),
})
