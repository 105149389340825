import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useParams } from 'react-router'
import {
  CategoryScale,
  Chart as ChartJS,
  Chart,
  CoreChartOptions,
  DatasetChartOptions,
  ElementChartOptions,
  Legend,
  LinearScale,
  LineControllerChartOptions,
  LineElement,
  PluginChartOptions,
  PointElement,
  ScaleChartOptions,
  Title,
  Tooltip,
  TooltipModel,
} from 'chart.js'
import { DeepPartial } from 'chart.js/dist/types/utils'
import { Line } from 'react-chartjs-2'
import Grid from '@mui/material/Grid'
import Box from '@mui/material/Box'
import moment from 'moment'

import styles from './InventoryHealthVisualization.module.scss'
import Card from './../../Common/Card'
import {
  IInventoryHealthData,
  INVENTORY_HEALTH_METRIC_OPTIONS,
  INVENTORY_HEALTH_METRIC_UNDERLYING_DATA,
} from '@common/interfaces/client'
import { ILoadingData } from '../../../redux/types'
import SelectField from '../../Common/SelectField'
import DatePicker from '../../Common/DatePicker'
import { ReportingPeriods } from '@common/interfaces/bbc'
import { getFieldValue } from '../ClientHelpers'
import { formatAmount, formatDate } from '../../../helpers/helpers'
import {
  buildTooltip,
  buildTooltipMetricScoreValueHead,
  buildTooltipMetricScoreValueRow,
  buildTooltipUnderlyingMetricValueHead,
  buildTooltipUnderlyingMetricValueRow,
  FONT_SIZE,
  GRAPH_COLOR_PRIMARY,
  GRAPH_COLOR_SLATE_GRAY,
  METRIC_TYPE_OPTIONS,
  MetricType,
} from '../../../constants/graphs'
import useTrackVisualizationsTable from '../../../hooks/useTrackVisualizationsTable'
import { CATEGORIES } from '@common/constants/tracking'

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend)

const CHART_OPTIONS: DeepPartial<
  CoreChartOptions<'line'> &
    ElementChartOptions<'line'> &
    PluginChartOptions<'line'> &
    DatasetChartOptions<'line'> &
    ScaleChartOptions<'line'> &
    LineControllerChartOptions
> = {
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
    legend: {
      position: 'top' as const,
      labels: {
        boxWidth: 20,
        boxHeight: 20,
        usePointStyle: true,
        pointStyle: 'rect',
        padding: 10,
        color: 'black',
        font: {
          size: FONT_SIZE,
          weight: '500',
        },
      },
    },
  },
  scales: {
    x: {
      grid: {
        display: false,
      },
      ticks: {
        font: {
          size: FONT_SIZE,
        },
      },
    },
    y: {
      grid: {
        display: false,
      },
      ticks: {
        count: 7,
        font: {
          size: FONT_SIZE,
        },
        callback: (value) => formatAmount(value),
      },
    },
  },
}

interface IProps {
  listInventoryHealth: (clientId: string) => void
  inventoryHealth: ILoadingData<IInventoryHealthData>
}

const InventoryHealthVisualization = ({ listInventoryHealth, inventoryHealth }: IProps) => {
  const { id } = useParams<{ id: string }>()
  const [metricType, setMetricType] = useState(MetricType.Score)
  const [selectedMetric, setSelectedMetric] = useState({
    label: INVENTORY_HEALTH_METRIC_OPTIONS[0].label,
    score: INVENTORY_HEALTH_METRIC_OPTIONS[0].score,
    value: INVENTORY_HEALTH_METRIC_OPTIONS[0].value,
    type: INVENTORY_HEALTH_METRIC_OPTIONS[0].type,
  })
  const [currentDateRange, setCurrentDateRange] = useState({
    startDate: null,
    endDate: null,
  })

  const [clickedIndex, setClickedIndex] = useState(null)

  useEffect(() => {
    const handleClickOutside = (event: Event) => {
      const tooltipEl = document.getElementById('chartjs-tooltip')
      const chartEl = document.getElementById('chartjs')

      if (
        tooltipEl &&
        chartEl &&
        !tooltipEl.contains(event.target as Node) &&
        !chartEl.contains(event.target as Node)
      ) {
        tooltipEl.remove()
        setClickedIndex(null)
      }
    }
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [])

  useEffect(() => {
    listInventoryHealth(id)
  }, [listInventoryHealth, id])

  const { inventoryHealthData } = useMemo(
    () => ({
      inventoryHealthData: inventoryHealth.data?.data,
    }),
    [inventoryHealth],
  )

  const dates = useMemo(() => {
    if (!inventoryHealthData?.length) {
      return []
    }

    return inventoryHealthData?.map(({ recordDate }) => recordDate).filter(Boolean)
  }, [inventoryHealthData])

  useEffect(() => {
    if (!dates) {
      return
    }
    if (dates.length > 12) {
      setCurrentDateRange({
        startDate: dates[dates.length - 12] || null,
        endDate: dates[dates.length - 1] || null,
      })
    } else {
      setCurrentDateRange({
        startDate: dates[0] || null,
        endDate: dates[dates.length - 1] || null,
      })
    }
  }, [dates])

  const datesBoundary = useMemo(
    () => ({
      minDate: dates[0],
      maxDate: dates[dates.length - 1],
    }),
    [dates],
  )

  const handleMetricChange = useCallback(
    ({ target }) => {
      const { value } = target
      const mewMetric = INVENTORY_HEALTH_METRIC_OPTIONS.find(
        ({ value: metricValue }) => metricValue === value,
      )
      setSelectedMetric(mewMetric)
    },
    [setSelectedMetric],
  )

  const handleDateChange = useCallback(
    (values: { startDate: string; endDate: string }) => {
      setCurrentDateRange(values)
    },
    [setCurrentDateRange],
  )

  const filteredData = useMemo(
    () =>
      (inventoryHealthData || [])?.filter(
        ({ recordDate }) =>
          !recordDate ||
          (moment(recordDate).isSameOrAfter(currentDateRange.startDate) &&
            moment(recordDate).isSameOrBefore(currentDateRange.endDate)),
      ),
    [inventoryHealthData, currentDateRange],
  )

  const chartData = useMemo(
    () => ({
      labels: filteredData?.map(({ recordDate }) => formatDate(recordDate)),
      datasets: [
        {
          label: selectedMetric.label,
          data: filteredData?.map(
            (item) =>
              item.inventoryHealth[
                metricType === MetricType.Score ? selectedMetric.score : selectedMetric.value
              ],
          ),
          borderColor: GRAPH_COLOR_PRIMARY,
          backgroundColor: GRAPH_COLOR_PRIMARY,
          lineTension: 0.4,
          pointRadius: (context: any) => {
            return clickedIndex === context.dataIndex ? 7 : 2
          },
          pointHoverRadius: 7,
          yAxisID: 'y',
        },
        {
          label: 'Portfolio average',
          data: filteredData?.map(
            (item) =>
              item.portfolioAverages?.[
                metricType === MetricType.Score ? selectedMetric.score : selectedMetric.value
              ],
          ),
          borderColor: GRAPH_COLOR_SLATE_GRAY,
          backgroundColor: GRAPH_COLOR_SLATE_GRAY,
          lineTension: 0.4,
          pointRadius: 0,
          yAxisID: 'y',
        },
      ],
    }),
    [filteredData, selectedMetric, metricType, clickedIndex],
  )

  const inventoryHealthTooltip = useCallback(
    (context: { chart: Chart<'line'>; tooltip: TooltipModel<'line'> }) =>
      buildTooltip((tooltip) => {
        const titleLines = tooltip.title || []
        const date = titleLines[0]

        const dataPoint = tooltip?.dataPoints?.[0]

        const lineLabel = dataPoint?.dataset?.label

        if (lineLabel === 'Portfolio average') {
          return
        }

        const inventoryHealthItemData = inventoryHealthData?.find(
          ({ recordDate }) => formatDate(recordDate) === date,
        )?.inventoryHealth

        if (!inventoryHealthItemData) {
          return
        }

        const value =
          metricType === MetricType.Value
            ? getFieldValue(inventoryHealthItemData, selectedMetric.value, selectedMetric.type)
            : dataPoint?.parsed?.y?.toFixed(2)
        let innerHtml: string
        if (lineLabel === 'Inventory Health Rating') {
          innerHtml = buildTooltipMetricScoreValueHead(lineLabel, `${value} (${date})`)

          INVENTORY_HEALTH_METRIC_OPTIONS.forEach((metric) => {
            if (metric.value === selectedMetric.value) {
              return
            }
            const label = metric.label
            const score = inventoryHealthItemData[metric.score]
              ? (+inventoryHealthItemData[metric.score]).toFixed(1)
              : '-'
            const value = getFieldValue(inventoryHealthItemData, metric.value, metric.type)

            innerHtml += buildTooltipMetricScoreValueRow(label, score, value)
          })
        } else {
          innerHtml = buildTooltipUnderlyingMetricValueHead(
            selectedMetric.label,
            `${value} (${date})`,
          )

          let supplementalData = INVENTORY_HEALTH_METRIC_UNDERLYING_DATA.filter(
            ({ originalValue }) => originalValue === selectedMetric.value,
          ).map(({ label, type, value }) => ({
            label,
            value: getFieldValue(inventoryHealthItemData, value, type),
          }))

          supplementalData = [
            {
              label:
                metricType === MetricType.Value
                  ? 'Score'
                  : selectedMetric.label + (selectedMetric.type === 'percent' ? ' (%)' : ''),
              value:
                metricType === MetricType.Value
                  ? inventoryHealthItemData[selectedMetric.score]
                    ? (+inventoryHealthItemData[selectedMetric.score]).toFixed(2)
                    : '-'
                  : getFieldValue(
                      inventoryHealthItemData,
                      selectedMetric.value,
                      selectedMetric.type,
                    ),
            },
            ...supplementalData,
          ]

          supplementalData.forEach(({ label, value }) => {
            innerHtml += buildTooltipUnderlyingMetricValueRow(label, value)
          })
        }
        innerHtml += '</tbody>'

        return innerHtml
      })(context),
    [inventoryHealthData, selectedMetric, metricType],
  )

  const chartOptions = useMemo(
    () => ({
      ...CHART_OPTIONS,
      plugins: {
        ...CHART_OPTIONS.plugins,
        tooltip: {
          enabled: false,
          external: inventoryHealthTooltip,
          events: ['click'],
          position: 'nearest' as const,
        },
      },
      tooltips: {
        yAlign: 'bottom',
      },
      scales: {
        ...CHART_OPTIONS.scales,
        y: {
          ...CHART_OPTIONS.scales.y,
          ...(metricType === MetricType.Score && {
            min: 1,
            max: 7,
          }),
        },
      },
      onHover: (event: any, elements: any) => {
        // @ts-ignore
        event.native.target.style.cursor = elements[0] ? 'pointer' : 'default'
      },
      onClick: (event: any, elements: any) => {
        setClickedIndex(elements[0] ? elements[0].index : null)
      },
    }),
    [inventoryHealthTooltip, metricType],
  )

  const handleSetMetricType = useCallback(
    ({ target }) => {
      const { value } = target
      setMetricType(value)
    },
    [setMetricType],
  )

  useEffect(() => {
    if (selectedMetric.value === 'inventoryRating') {
      setMetricType(MetricType.Score)
    }
  }, [selectedMetric])

  const isInitialized = useMemo(() => !!inventoryHealth?.data?.data, [inventoryHealth])
  const visualizationsParams = useMemo(
    () => ({
      clientId: id,
    }),
    [id],
  )
  const visualizationsFilters = useMemo(
    () => ({
      selectedMetric: selectedMetric.value,
      metricType,
      startDate: currentDateRange.startDate,
      endDate: currentDateRange.endDate,
    }),
    [metricType, currentDateRange.startDate, currentDateRange.endDate, selectedMetric],
  )
  useTrackVisualizationsTable({
    isInitialized,
    category: CATEGORIES.inventoryHealthVisualization,
    params: visualizationsParams,
    filters: visualizationsFilters,
  })

  return (
    <Card
      className={styles.card}
      title={
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <span>Inventory Rating Over Time</span>
          <Box display="flex" alignItems="center" justifyContent="space-between" gap={2}>
            <DatePicker
              reportingPeriod={ReportingPeriods.Monthly}
              currentDateRange={currentDateRange}
              datesBoundary={datesBoundary}
              onChange={handleDateChange}
            />
            <SelectField
              key={selectedMetric.value}
              label="Select metric"
              variant="outlined"
              useFinalForm={false}
              options={INVENTORY_HEALTH_METRIC_OPTIONS}
              onChange={handleMetricChange}
              value={selectedMetric.value}
              name="selectedMetric"
              defaultValue=""
              className={styles.selectField}
            />
            <SelectField
              key={metricType}
              label="Select view"
              variant="outlined"
              useFinalForm={false}
              options={METRIC_TYPE_OPTIONS}
              onChange={handleSetMetricType}
              value={metricType}
              name="metricType"
              defaultValue=""
              className={styles.selectField}
              disabled={selectedMetric.value === 'inventoryRating'}
            />
          </Box>
        </Box>
      }
    >
      <Grid container xs={12} justifyContent="center">
        <Grid item xs={12} className={styles.chartContainer} container>
          <Line id="chartjs" data={chartData} options={chartOptions} />
        </Grid>
      </Grid>
    </Card>
  )
}

export default InventoryHealthVisualization
