import { combineReducers } from 'redux'
import * as actions from './actions'
import { ISearchResult } from '@common/interfaces/search'

export interface ISearchState {
  loadingArray: string[]
  searchString: string
  searchResults: ISearchResult[]
}

export default combineReducers<ISearchState>({
  loadingArray(state: string[] = [], action) {
    switch (action.type) {
      case actions.SEARCH_REQUEST:
        return [...state, action.type]
      case actions.SEARCH_SUCCESS:
      case actions.SEARCH_FAILURE:
        return state.filter(
          (item) =>
            item !== action.type.replace('SUCCESS', 'REQUEST').replace('FAILURE', 'REQUEST'),
        )
      default:
        return state
    }
  },
  searchString(state: string = '', action) {
    switch (action.type) {
      case actions.SEARCH_REQUEST:
        return action.params?.search || ''
      default:
        return state
    }
  },
  searchResults(state: ISearchResult[] = [], action) {
    switch (action.type) {
      case actions.SEARCH_SUCCESS:
        return action.data
      default:
        return state
    }
  },
})
