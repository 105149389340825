import { connect } from 'react-redux'

import { updatePassword } from '../../redux/profile/actions'

import UpdatePasswordForm from './UpdatePasswordForm'

const actions = { onUpdatePassword: updatePassword }

export { UpdatePasswordForm }

export default connect(null, actions)(UpdatePasswordForm)
