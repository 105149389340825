import { prefix } from './actions'
import { IRootState } from '../reducer'

export const isLoading = (state: IRootState) => state[prefix].loadingArray.length > 0

export const accounts = (state: IRootState) => state[prefix].accounts

export const investors = (state: IRootState) => state[prefix].investors

export const account = (state: IRootState) => state[prefix].account

export const accountDependencies = (state: IRootState) => state[prefix].accountDependencies

export const accountIndustries = (state: IRootState) =>
  state[prefix].accountDependencies?.industries

export const accountSubIndustries = (state: IRootState) =>
  state[prefix].accountDependencies?.subIndustries

export const accountSalesChannels = (state: IRootState) =>
  state[prefix].accountDependencies?.salesChannels
