import React from 'react'
import Box from '@mui/material/Box'

import styles from './BankTransactionsMappingLegend.module.scss'

const BankTransactionsMappingLegend = ({ className }: { className?: string }) => {
  return (
    <Box
      display="flex"
      gap={1}
      justifyContent="space-evenly"
      alignItems="center"
      className={className}
    >
      <div className={styles.keyLabel}>Key:</div>
      <div className={styles.predictionCardVeryLikely}>Very Likely</div>
      <div className={styles.predictionCardLikely}>Likely</div>
      <div className={styles.predictionCardPossible}>Possible</div>
      <div className={styles.predictionCardEntity}>Sender / Receiver</div>
      <div className={styles.predictionCardIntermediary}>Intermediary</div>
      <div className={styles.predictionCardDebtor}>No Confidence Level</div>
    </Box>
  )
}

export default BankTransactionsMappingLegend
