import React from 'react'

import styles from './FlatCard.module.scss'

interface IProps {
  title?: React.ReactNode
  children: React.ReactNode
  action?: React.ReactNode
}

const FlatCard = ({ title, children, action }: IProps) => (
  <div className={styles.container}>
    <div className={styles.title}>{title}</div>
    <div className={styles.content}>{children}</div>
    <div className={styles.action}>{action}</div>
  </div>
)

export default FlatCard
