import React, { useCallback, useMemo, useState } from 'react'
import { Field, Form, FormRenderProps, FormSpy } from 'react-final-form'
import Box from '@mui/material/Box'
import cn from 'classnames'

import styles from './NotificationSettings.module.scss'

import InputLabel from '../../components/Common/InputLabel'
import {
  IUser,
  IUserNotificationSettings,
  NOTIFICATION_SETTING_LABEL,
  NotificationSettingType,
  USER_ROLE_NOTIFICATION_SETTINGS,
  UserRole,
} from '@common/interfaces/user'
import Checkbox from '../Common/Checkbox'
import SaveState from '../Common/SaveState'

const mutators = {
  setFieldData: ([field, value]: any, state: any, { changeValue, ...rest }: any) => {
    changeValue(state, field, () => value)
  },
}

interface IProps {
  user: IUser
  role: UserRole
  updateNotificationSettings: (data: Partial<IUser>) => Promise<any>
}

const NotificationSettingsFormRow = ({
  notificationSettingType,
  isEmailChecked,
  isPushChecked,
  onChangeEmail,
  onChangePush,
}: {
  notificationSettingType: NotificationSettingType
  isEmailChecked: boolean
  isPushChecked: boolean
  onChangeEmail: (notificationSettingType: NotificationSettingType) => void
  onChangePush: (notificationSettingType: NotificationSettingType) => void
}) => {
  const handleEmailCheckboxChange = useCallback(() => {
    onChangeEmail(notificationSettingType)
  }, [onChangeEmail, notificationSettingType])

  const handlePushCheckboxChange = useCallback(() => {
    onChangePush(notificationSettingType)
  }, [onChangePush, notificationSettingType])

  return (
    <Box className={styles.settingsRow}>
      <Box>{NOTIFICATION_SETTING_LABEL[notificationSettingType]}</Box>
      <Box>
        <Checkbox
          className={styles.checkbox}
          name={`${notificationSettingType}Email`}
          checked={isEmailChecked}
          onChange={handleEmailCheckboxChange}
          color="primary"
        />
      </Box>
      <Box>
        <Checkbox
          className={styles.checkbox}
          name={`${notificationSettingType}Push`}
          checked={isPushChecked}
          onChange={handlePushCheckboxChange}
          color="primary"
        />
      </Box>
    </Box>
  )
}

const NotificationSettingsFormRender = ({
  form,
  handleSubmit,
  values,
  isSaving,
  isSaved,
  notificationConfiguration,
}: FormRenderProps<IUserNotificationSettings> & {
  isSaving: boolean
  isSaved: boolean
  notificationConfiguration: NotificationSettingType[]
}) => {
  const isAllEmailChecked = useMemo(
    () => notificationConfiguration.every((setting) => values.email.includes(setting)),
    [values.email, notificationConfiguration],
  )

  const isAllPushChecked = useMemo(
    () => notificationConfiguration.every((setting) => values.push.includes(setting)),
    [values.push, notificationConfiguration],
  )

  const handleAllEmailCheckboxChange = useCallback(() => {
    form.mutators.setFieldData('email', isAllEmailChecked ? [] : notificationConfiguration)
  }, [form, isAllEmailChecked, notificationConfiguration])

  const handleAllPushCheckboxChange = useCallback(() => {
    form.mutators.setFieldData('push', isAllPushChecked ? [] : notificationConfiguration)
  }, [form, isAllPushChecked, notificationConfiguration])

  const handleCheckboxChange = useCallback(
    (field: 'email' | 'push', notificationSettingType: NotificationSettingType) => {
      form.mutators.setFieldData(
        field,
        values[field].includes(notificationSettingType)
          ? values[field].filter((setting) => setting !== notificationSettingType)
          : [...values[field], notificationSettingType],
      )
    },
    [form, values],
  )

  const handleEmailCheckboxChange = useCallback(
    (notificationSettingType: NotificationSettingType) => {
      handleCheckboxChange('email', notificationSettingType)
    },
    [handleCheckboxChange],
  )

  const handlePushCheckboxChange = useCallback(
    (notificationSettingType: NotificationSettingType) => {
      handleCheckboxChange('push', notificationSettingType)
    },
    [handleCheckboxChange],
  )

  return (
    <form onSubmit={handleSubmit}>
      <Field name="email" type="hidden" render={() => null} />
      <Field name="push" type="hidden" render={() => null} />
      <Box className={styles.settingsWrapper}>
        <Box className={styles.settingsRow}>
          <h3 className={styles.heading}>Notification preference</h3>
          <Box className={styles.allEnabledWrapper} display="flex" alignItems="center">
            <Checkbox
              className={styles.checkbox}
              name="isAllEmail"
              color="primary"
              checked={isAllEmailChecked}
              onChange={handleAllEmailCheckboxChange}
            />
            <InputLabel htmlFor="isAllEmail">All</InputLabel>
          </Box>
          <Box className={styles.allEnabledWrapper} display="flex" alignItems="center">
            <Checkbox
              className={styles.checkbox}
              name="isAllPush"
              color="primary"
              checked={isAllPushChecked}
              onChange={handleAllPushCheckboxChange}
            />
            <InputLabel htmlFor="isAllPush">All</InputLabel>
          </Box>
        </Box>
        <Box className={cn(styles.settingsRow, styles.settingsRowHeader)}>
          <Box>Category</Box>
          <Box>Email</Box>
          <Box>Push</Box>
        </Box>
        {notificationConfiguration.map((item) => (
          <NotificationSettingsFormRow
            key={item}
            notificationSettingType={item}
            isEmailChecked={values.email.includes(item)}
            isPushChecked={values.push.includes(item)}
            onChangeEmail={handleEmailCheckboxChange}
            onChangePush={handlePushCheckboxChange}
          />
        ))}
      </Box>
      <Box mt={2} display="flex" alignItems="center" justifyContent="flex-end">
        <SaveState isSaving={isSaving} isSaved={isSaved} />
      </Box>
      <FormSpy
        subscription={{ dirty: true, values: true, valid: true }}
        onChange={(props) => {
          props.dirty && props.valid && handleSubmit({ ...props.values })
        }}
      />
    </form>
  )
}

const NotificationSettings = ({
  user: { notificationSettings },
  role,
  updateNotificationSettings,
}: IProps) => {
  const [isSaving, setIsSaving] = useState(false)
  const [isSaved, setIsSaved] = useState(false)

  const handleSaveSettings = useCallback(
    async (data: any) => {
      setIsSaving(true)
      setIsSaved(false)
      const result = await updateNotificationSettings({ ...data })
      !result?.error && setIsSaved(true)
      setIsSaving(false)
    },
    [updateNotificationSettings],
  )

  const notificationConfiguration = useMemo<NotificationSettingType[]>(
    () => USER_ROLE_NOTIFICATION_SETTINGS[role] || [],
    [role],
  )

  return (
    <Box className={styles.wrapper}>
      <Form<IUserNotificationSettings>
        initialValues={notificationSettings}
        onSubmit={handleSaveSettings}
        mutators={mutators}
        render={(props) => (
          <NotificationSettingsFormRender
            isSaving={isSaving}
            isSaved={isSaved}
            notificationConfiguration={notificationConfiguration}
            {...props}
          />
        )}
      />
    </Box>
  )
}

export default NotificationSettings
