import React from 'react'
import PasswordRecoveryForm from '../../components/PasswordRecoveryForm'
import Card from '../../components/Common/Card'

import styles from '../../components/AuthContainer/AuthContainer.module.scss'
import LeftPoint from '../../assets/images/left-point.svg'
import SuccessLogo from '../../assets/images/success-logo.svg'
import { ROUTES } from '../../constants/routes'
import { Link as RouterLink } from 'react-router-dom'
import Link from '@mui/material/Link'

interface IProps {
  isRecoveryRequested: boolean
}

const PasswordRecoveryPage = ({ isRecoveryRequested }: IProps) => {
  return (
    <div>
      <div className={styles.backToLogin}>
        <Link component={RouterLink} to={ROUTES.LOGIN}>
          <img className={styles.leftPoint} src={LeftPoint} alt="Back to login" />
          Back to login
        </Link>
      </div>

      <Card noHeaderMargin className={styles.resetFormWrapper}>
        <div className={styles.resetFormContainer}>
          {isRecoveryRequested && (
            <img className={styles.successLogo} src={SuccessLogo} alt="Success" />
          )}
          <div className={styles.headingWrapper}>
            <div className={styles.heading}>
              {isRecoveryRequested ? (
                <div className={styles.emailSent}>Email sent</div>
              ) : (
                <div>
                  <div className={styles.forgotPwd}>Forgot password?</div>

                  <div className={styles.forgotPwd}>No worries.</div>
                </div>
              )}
            </div>
          </div>
          {isRecoveryRequested ? (
            <div>
              <p className={styles.successInfo}>
                Check your email for a link to reset your password
              </p>
            </div>
          ) : (
            <div className={styles.formContainer}>
              <p className={styles.info}>
                Enter the email address associated with your account so that we can send you a link
                to reset your password
              </p>
              <div>
                <PasswordRecoveryForm />
              </div>
            </div>
          )}
        </div>
      </Card>
    </div>
  )
}

export default PasswordRecoveryPage
