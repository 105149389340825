import React, { useState } from 'react'
import { Field } from 'react-final-form'
import cn from 'classnames'
import isEqual from 'lodash/isEqual'

import FormControl from '../FormControl'
import Select from '../Select'
import MenuItem from '../MenuItem'
import Checkbox from '../Checkbox'
import FormHelperText from '../FormHelperText'

import styles from './SelectWithCheckboxesField.module.scss'
import DropDownArrow from '../DropDownArrow'

interface IOption {
  value: string
  label: string
  disabled?: boolean
}

const MenuProps = {
  classes: { paper: styles.menuPaper, list: styles.menuList },
}

interface IProps {
  name: string
  className?: string
  style?: object
  placeholder?: string
  options: IOption[]
  disabled?: boolean
  selectAll?: boolean
}

const getDisplayValue = (values: { [key: string]: string } | string) =>
  typeof values === 'string'
    ? [values]
    : Object.entries(values).reduce(
        (acc: string[], [key, value]) => (value ? [...acc, key] : acc),
        [],
      )

const SelectWithCheckboxesField = ({
  name,
  className,
  placeholder,
  options,
  style,
  disabled,
  selectAll,
}: IProps) => {
  const [isOpen, setIsOpen] = useState(false)

  return (
    <Field
      name={name}
      render={({ input, meta }) => {
        const inputValue = input.value || {}
        const { error, touched } = meta || {}
        const isError = !!error && touched

        const handleOpen = () => {
          setIsOpen(true)
          input.onFocus()
        }
        const handleClose = () => {
          setIsOpen(false)
          input.onBlur()
        }

        return (
          <FormControl error={isError} className={className}>
            <Select
              style={style}
              value={getDisplayValue(inputValue)}
              placeholder={placeholder}
              multiple
              renderValue={(selected) =>
                Object.values(selected)
                  .filter((value) => value !== 'all')
                  .map((value) => options.find((option) => option.value === value)?.label || value)
                  .join(', ')
              }
              onOpen={handleOpen}
              onClose={handleClose}
              className={styles.select}
              IconComponent={(props) => (
                <DropDownArrow
                  {...props}
                  className={cn('MuiSelect-icon', styles.icon, { [styles.open]: isOpen })}
                />
              )}
              MenuProps={MenuProps}
              disabled={disabled}
            >
              {selectAll && (
                <MenuItem value="all" className={styles.listItemRoot}>
                  <label>
                    <Checkbox
                      key={inputValue.all}
                      checked={inputValue.all}
                      color="primary"
                      onChange={(event, checked) => {
                        let newValue = { all: checked }
                        if (checked) {
                          newValue = options
                            .map(({ value }) => value)
                            .reduce(
                              (acc, item: string) => ({
                                ...acc,
                                [item]: true,
                              }),
                              newValue,
                            )
                        }
                        input.onChange(newValue)
                      }}
                      disabled={disabled}
                    />
                    All
                  </label>
                </MenuItem>
              )}
              {options.map(({ value, label, disabled: optionDisabled }) => {
                return (
                  <MenuItem
                    key={value}
                    value={value}
                    className={cn(styles.listItemRoot, {
                      [styles.listItemRootDisabled]: optionDisabled,
                    })}
                  >
                    <label>
                      <Checkbox
                        key={inputValue[value]}
                        checked={inputValue[value]}
                        color="primary"
                        onChange={(event, checked) => {
                          const newValue = { ...inputValue, [value]: checked }
                          if (selectAll) {
                            if (!checked) {
                              newValue.all = false
                            } else {
                              delete newValue.all
                              const allOptions = options
                                .map((option) => option.value)
                                .reduce(
                                  (acc, item: string) => ({
                                    ...acc,
                                    [item]: true,
                                  }),
                                  newValue,
                                )
                              if (isEqual(newValue, allOptions)) {
                                newValue.all = true
                              }
                            }
                          }
                          input.onChange(newValue)
                        }}
                        disabled={optionDisabled}
                      />
                      {label}
                    </label>
                  </MenuItem>
                )
              })}
            </Select>
            {isError && <FormHelperText>{error}</FormHelperText>}
          </FormControl>
        )
      }}
    />
  )
}

export default SelectWithCheckboxesField
