import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'

import { clientInfo } from '../../redux/clientInfo/selectors'
import { isAdmin } from '../../redux/profile/selectors'
import {
  terminateClient,
  calculateTerminateClient,
  generateTerminationLetter,
} from '../../redux/clientInfo/actions'
import ClientSetupTerminationPage from './ClientSetupTerminationPage'

const selector = createStructuredSelector({
  clientInfo,
  isAdmin,
})

const actions = {
  calculateTerminateClient,
  generateTerminationLetter,
  terminateClient,
}

export default connect(selector, actions)(ClientSetupTerminationPage)
