import { Chart, TooltipModel } from 'chart.js'

export const BORDER_RADIUS = 5
export const FONT_SIZE = 13

export const GRAPH_COLOR_PRIMARY = '#0066F5'
export const GRAPH_COLOR_SLATE_GRAY = '#718096'
export const GRAPH_COLOR_1 = '#395580'
export const GRAPH_COLOR_2 = '#CBD5E0'
export const GRAPH_COLOR_3 = '#A6CAFF'
export const GRAPH_COLOR_4 = '#08295C'
export const GRAPH_COLOR_OTHER = '#E5E5E5'

export const COLORS = [
  GRAPH_COLOR_1,
  GRAPH_COLOR_2,
  GRAPH_COLOR_SLATE_GRAY,
  GRAPH_COLOR_PRIMARY,
  GRAPH_COLOR_3,
]

export const TOOLTIP = {
  backgroundColor: 'rgba(255, 255, 255, 1)',
  titleColor: '#000000',
  bodyColor: '#000000',
  footerColor: '#000000',
  titleFont: { size: FONT_SIZE, weight: 'bold' },
  bodyFont: { size: 13 },
  footerFont: { size: 13, weight: 'bold' },
  bodySpacing: 4,
  boxWidth: 18,
  boxHeight: 18,
  cornerRadius: 6,
  boxPadding: 10,
  borderColor: 'rgba(0,0,0,0.1)',
  borderWidth: 1,
  padding: 20,
  titleMarginBottom: 18,
  footerMarginTop: 18,
  displayColors: true,
  usePointStyle: false,
  interaction: {
    mode: 'index',
    intersect: true,
  },
  itemSort: (a: any, b: any) => {
    return b.datasetIndex - a.datasetIndex
  },
}

export enum MetricType {
  Score = 'score',
  Value = 'value',
}

export const METRIC_TYPE_OPTIONS = [
  { label: 'Score', value: MetricType.Score },
  { label: 'Value', value: MetricType.Value },
]

export const buildTooltip =
  (buildInnerHtml: (tooltip: any) => any) =>
  (context: { chart: Chart<'line'>; tooltip: TooltipModel<'line'> }) => {
    const { chart, tooltip } = context
    let tooltipEl = document.getElementById('chartjs-tooltip')
    // Create element on first render
    if (!tooltipEl) {
      tooltipEl = document.createElement('div')
      tooltipEl.id = 'chartjs-tooltip'
      tooltipEl.style.backgroundColor = '#FFFFFF'
      tooltipEl.style.border = '1px solid #E2E8F0'
      tooltipEl.style.borderRadius = '5px'
      tooltipEl.style.boxShadow = '0px 0px 10px rgba(0, 0, 0, 0.25)'
      tooltipEl.style.zIndex = '9999'

      tooltipEl.innerHTML = `<table
        style="border-collapse: collapse"
        ></table>`
      document.body.appendChild(tooltipEl)
    }

    const tooltipModel = tooltip
    if (tooltipModel.opacity === 0) {
      tooltipEl.style.opacity = '0'
      //remove tooltip if not visible
      tooltipEl.style.position = 'absolute'
      tooltipEl.style.left = '0px'
      tooltipEl.style.top = '0px'
      tooltipEl.style.pointerEvents = 'none'

      return
    }

    if (tooltip.body) {
      const innerHTML = buildInnerHtml(tooltip)
      if (!innerHTML) {
        return
      }

      const tableRoot = tooltipEl.querySelector('table')
      tableRoot.innerHTML = innerHTML
    }

    const position = chart.canvas.getBoundingClientRect()

    tooltipEl.style.opacity = '1'
    tooltipEl.style.position = 'absolute'
    tooltipEl.style.pointerEvents = 'auto'

    // Adjust tooltip position to prevent rolloff
    const leftPosition = position.left + tooltip.caretX + 5
    const topPosition = position.top + tooltip.caretY + 5

    // Get the dimensions of the tooltip element
    const tooltipWidth = tooltipEl.offsetWidth
    const tooltipHeight = tooltipEl.offsetHeight

    // Check if the tooltip exceeds the right edge of the visible area
    if (leftPosition + tooltipWidth > window.innerWidth + window.scrollX) {
      tooltipEl.style.left = window.innerWidth - tooltipWidth + window.scrollX + 'px'
    } else {
      tooltipEl.style.left = leftPosition + window.scrollX + 'px'
    }

    // Check if the tooltip exceeds the bottom edge of the visible area
    if (topPosition + tooltipHeight > window.innerHeight + window.scrollY) {
      tooltipEl.style.top = window.innerHeight - tooltipHeight + window.scrollY + 'px'
    } else {
      tooltipEl.style.top = topPosition + window.scrollY + 'px'
    }
  }

export const buildTooltipMetricScoreValueHead = (label: string, value: string) =>
  `<thead style="background-color: #0066f5; color: white; border: none">
    <tr>
      <th style="text-align: left; font-size: 16px; font-weight: 600; padding: 4px 10px">${label}</th>
      <th colspan="2" style="text-align: right; font-size: 16px; font-weight: 600; padding: 4px 10px">${value}</th>
    </tr>
    <tr>
      <th style="text-align: left; font-size: 16px; font-weight: 600; padding: 4px 10px">Metric</th>
      <th style="text-align: right; font-size: 16px; font-weight: 600; padding: 4px 10px">Score</th>
      <th style="text-align: right; font-size: 16px; font-weight: 600; padding: 4px 10px">Value</th>
    </tr>
  </thead>
  <tbody>`

export const buildTooltipMetricScoreValueRow = (label: string, score: string, value: string) =>
  `<tr style="border: 1px solid #EBEBEB; font-size: 13px">
    <td style="padding: 4px 10px">${label}</td>
    <td style="text-align: right; padding:4px 10px">${score}</td>
    <td style="text-align: right; padding: 4px 10px">${value}</td>
  </tr>`

export const buildTooltipUnderlyingMetricValueHead = (label: string, value: string) =>
  `<thead style="background-color: #0066f5; color: white; border: none">
    <tr>
      <th style="text-align: left; font-size: 16px; font-weight: 600; padding: 4px 10px">${label}</th>
      <th colspan="2" style="text-align: right; font-size: 16px; font-weight: 600; padding: 4px 10px">${value}</th>
    </tr>
    <tr>
      <th style="text-align: left; font-size: 16px; font-weight: 600; padding: 4px 10px">Underlying Metric</th>
      <th style="text-align: right; font-size: 16px; font-weight: 600; padding: 4px 10px">Value</th>
    </tr>
    </thead>
    <tbody>`

export const buildTooltipUnderlyingMetricValueRow = (label: string, value: string) =>
  `<tr style="border: 1px solid #EBEBEB; font-size: 13px">
    <td style="padding: 4px 10px">${label}</td>
    <td style="text-align: right; padding: 4px 10px">${value}</td>
  </tr>`
