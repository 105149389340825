import React from 'react'
import { ReactComponent as FilterIconWhite } from '@assets/images/filter-symbol-black.svg'
import { ReactComponent as FilterIconBlack } from '@assets/images/filter-symbol-white.svg'
import styles from './FilterButton.module.scss'
import Button from '../../Common/Button'
import cn from 'classnames'
interface filterCountProps {
  isFilterMenuOpen: boolean
  numFilters?: number
}

const FilterCount = ({ isFilterMenuOpen, numFilters }: filterCountProps) => {
  if (numFilters > 0) {
    return (
      <div
        className={cn(styles.filterCount, {
          [styles.filterCountActive]: isFilterMenuOpen,
          [styles.filterCount]: !isFilterMenuOpen,
        })}
      >
        {numFilters || ''}
      </div>
    )
  }

  return null
}

interface IProps {
  className?: string
  isFilterMenuOpen: boolean
  toggleIsFilterMenuOpen: (event: React.MouseEvent<HTMLButtonElement>) => void
  values?: any
  numFilters?: number
  size?: 'small' | 'large'
  smallFilter?: boolean
}

const FilterButton = ({
  isFilterMenuOpen,
  toggleIsFilterMenuOpen,
  numFilters,
  size = 'large',
  smallFilter = false,
}: IProps) => {
  return (
    <Button
      className={cn(styles.filterButton, {
        [styles.filterButtonActive]: isFilterMenuOpen,
        [styles.filterButtonSmall]: size === 'small',
        [styles.smallFilter]: smallFilter,
      })}
      classes={{
        startIcon: styles.filterButtonIcon,
        endIcon: numFilters === 0 && styles.filterCountHidden,
      }}
      onClick={toggleIsFilterMenuOpen}
      startIcon={
        !isFilterMenuOpen ? (
          <FilterIconWhite className={styles.filterButtonIcon} />
        ) : (
          <FilterIconBlack className={styles.filterButtonIcon} />
        )
      }
      endIcon={<FilterCount isFilterMenuOpen={isFilterMenuOpen} numFilters={numFilters} />}
      data-cy="filter"
    />
  )
}

export default FilterButton
