import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'

import DueDiligenceTeamModal from './DueDiligenceTeamModal'

import {
  dueDiligenceInfo,
  dueDiligenceCompanyInfo,
  dueDiligenceDocumentRequests,
} from '../../redux/dueDiligence/selectors'
import { updateTeamAdvisorsInfo } from '../../redux/dueDiligence/actions'

const selector = createStructuredSelector({
  dueDiligenceInfo,
  dueDiligenceCompanyInfo,
  dueDiligenceDocumentRequests,
})
const actions = {
  updateTeamAdvisorsInfo,
}

export default connect(selector, actions)(DueDiligenceTeamModal)
