import React, { useState, useCallback, useEffect, useMemo } from 'react'
import Box from '@mui/material/Box'
import Button from '../Common/Button'
import Grid from '@mui/material/Grid'
import { Form } from 'react-final-form'
import { FormSpy } from 'react-final-form'
import { makeValidate } from 'mui-rff'
import * as Yup from 'yup'
import moment from 'moment'
import { useSetPageTitle } from '../../hooks/useSetPageTitle'
import styles from './ExportsDashboard.module.scss'
import FlatCard from '../Common/FlatCard'
import KeyboardDatePicker from '../Common/KeyboardDatePicker'
import { DATE_FORMAT } from '../../constants/common'
import { dateToString } from '../../helpers/helpers'
import { IClientInfo } from '@common/interfaces/client'
import Autocomplete from '../Common/Autocomplete'
import { EndOfMonthFlowOperationType, IEndOfMonthFlow } from '@common/interfaces/loanServicing'
import WarningModal from '../WarningModal'
import { ILoadingData } from '../../redux/types'
import { TransactionsOutputType } from '@common/interfaces/loanServicing'
import Card from '../Common/Card'
const schema = Yup.object().shape({
  recordDateFrom: Yup.date().typeError('Please type date in MM/DD/YY format').nullable(true),
  recordDateTo: Yup.date()
    .typeError('Please type date in MM/DD/YY format')
    .nullable(true)
    .when('recordDateFrom', (recordDateFrom: any, validation: any) =>
      recordDateFrom && recordDateFrom instanceof Date && !isNaN(+recordDateFrom)
        ? validation.min(recordDateFrom, 'To date should be prior to the from date')
        : validation,
    ),
})
const validateQuickbook = makeValidate(schema)
const validateClientFeeStatement = makeValidate(schema)
const validateBankOutput = makeValidate(schema)

const DEFAULT_FILTERS_QUICKBOOK = {
  recordDateFrom: moment().subtract(1, 'month').startOf('month').startOf('day').toDate(),
  recordDateTo: moment().subtract(1, 'month').endOf('month').endOf('day').toDate(),
} as any

const DEFAULT_FILTERS_AVG_LOAN_BALANCE = {
  recordDateFrom: moment().subtract(1, 'month').startOf('month').startOf('day').toDate(),
  recordDateTo: moment().subtract(1, 'month').endOf('month').endOf('day').toDate(),
} as any

const DEFAULT_FILTERS_BANK_OUTPUT = {
  recordDateFrom: moment().subtract(1, 'day').endOf('day').toDate(),
  recordDateTo: moment().subtract(1, 'day').endOf('day').toDate(),
  clients: [],
} as any

const DEFAULT_FILTERS_CLIENT_FEE_STATEMENT = {
  recordDateFrom: moment().startOf('month').startOf('day').toDate(),
  recordDateTo: moment().endOf('month').endOf('day').toDate(),
  clients: [],
} as any

const recalculationSchema = Yup.object().shape({
  recordDate: Yup.date()
    .typeError('Please type date in MM/DD/YY format')
    .required('Start date is required'),
})
const validateRecalculation = makeValidate(recalculationSchema)
const DEFAULT_FILTERS_RECALCULATION = {
  clients: [],
  recordDate: null,
} as any

interface IProps {
  isLoading: boolean
  loanBalanceStartDate: string
  endOfMonthFlow: IEndOfMonthFlow
  clients: ILoadingData<{ data: IClientInfo[] }>
  exportTransactions: (params?: { filters?: object; format: string }) => void
  exportClientFeeStatement: (params: object) => void
  getLatestEndOfMonthFlow: () => void
  exportEndOfMonthFlow: () => void
  applyEndOfMonthFlow: (params: object) => void
  recalculateLoanBalance: (params?: { filters?: object }) => void
  listClients: () => void
  getAverageLoanBalance: (params: object) => void
}

const ExportsDashboard = ({
  isLoading,
  loanBalanceStartDate,
  endOfMonthFlow,
  clients,
  exportTransactions,
  exportClientFeeStatement,
  getLatestEndOfMonthFlow,
  exportEndOfMonthFlow,
  applyEndOfMonthFlow,
  recalculateLoanBalance,
  listClients,
  getAverageLoanBalance,
}: IProps) => {
  const [isConfirmModalShown, setIsConfirmModalShown] = useState(false)
  const [endOfMonthFlowType, setEndOfMonthFlowType] = useState(null)
  const [buttonLoading, setButtonLoading] = useState({ type: null, value: false })
  const [quickbookFilters, setQuickbookFilters] = useState(DEFAULT_FILTERS_QUICKBOOK)
  const [recalculationFilters, setRecalculationFilters] = useState(DEFAULT_FILTERS_RECALCULATION)
  const [avgLoanBalanceFilters, setAvgLoanBalanceFilters] = useState(
    DEFAULT_FILTERS_AVG_LOAN_BALANCE,
  )
  const [clientFeeStatementFilters, setClientFeeStatementFilters] = useState(
    DEFAULT_FILTERS_CLIENT_FEE_STATEMENT,
  )

  const { clientsData } = useMemo(
    () => ({
      clientsData: clients?.data?.data,
    }),
    [clients],
  )

  useEffect(() => {
    listClients()
  }, [listClients])

  const [bankFilters, setBankFilters] = useState<{
    recordDateFrom: Date | string
    recordDateTo: Date | string
    clients: string[]
  }>(DEFAULT_FILTERS_BANK_OUTPUT)

  useEffect(() => {
    getLatestEndOfMonthFlow()
  }, [getLatestEndOfMonthFlow])

  const isMonthClosedAndLatest = useMemo(() => {
    if (
      endOfMonthFlow?.isLocked &&
      endOfMonthFlow?.recordDate ===
        moment().startOf('month').subtract(1, 'day').format('YYYY-MM-DD')
    ) {
      return true
    }
    return false
  }, [endOfMonthFlow])

  const isMonthApplied = useMemo(() => {
    if (!isMonthClosedAndLatest && !endOfMonthFlow?.isLocked) {
      return true
    }
    return false
  }, [endOfMonthFlow, isMonthClosedAndLatest])

  useSetPageTitle('Exports')

  const endOfMonthLabel = useMemo(
    () =>
      isMonthClosedAndLatest
        ? `Month of ${moment(endOfMonthFlow?.recordDate).format('MMMM')} Closed at ${moment(
            endOfMonthFlow?.updatedAt,
          ).format('M/D/YY hh:mmA')}`
        : isMonthApplied
        ? `Month of ${moment(endOfMonthFlow?.recordDate).format('MMMM')} Applied at ${moment(
            endOfMonthFlow?.updatedAt,
          ).format('M/D/YY hh:mmA')}`
        : `Close Month of ${moment(endOfMonthFlow?.recordDate)
            .endOf('month')
            .add(1, 'day')
            .format('MMMM')}`,
    [endOfMonthFlow, isMonthApplied, isMonthClosedAndLatest],
  )

  const handleQuickbookExportTransactions = useCallback(async () => {
    const params = {
      ...quickbookFilters,
    }
    if (params?.recordDateFrom && typeof params.recordDateFrom !== 'string') {
      params.recordDateFrom = dateToString(params.recordDateFrom)
    }
    if (params?.recordDateTo && typeof params.recordDateTo !== 'string') {
      params.recordDateTo = dateToString(params.recordDateTo)
    }
    setButtonLoading({ type: 'quickbooks', value: true })
    await exportTransactions({
      filters: params,
      format: TransactionsOutputType.QuickBooks,
    })
    setButtonLoading({ type: null, value: false })
  }, [quickbookFilters, exportTransactions])

  const handleQuickbookFiltersChange = useCallback((data: any) => {
    setQuickbookFilters(data)
  }, [])

  const handleExportAverageLoanBalance = useCallback(async () => {
    const params = {
      ...avgLoanBalanceFilters,
    }
    if (params?.recordDateFrom && typeof params.recordDateFrom !== 'string') {
      params.recordDateFrom = dateToString(params.recordDateFrom)
    }
    if (params?.recordDateTo && typeof params.recordDateTo !== 'string') {
      params.recordDateTo = dateToString(params.recordDateTo)
    }
    setButtonLoading({ type: 'loanBalance', value: true })
    await getAverageLoanBalance(params)
    setButtonLoading({ type: null, value: false })
  }, [avgLoanBalanceFilters, getAverageLoanBalance])

  const handleAvgLoanBalanceFiltersChange = useCallback((data: any) => {
    setAvgLoanBalanceFilters(data)
  }, [])

  const handleBankExportTransactions = useCallback(async () => {
    const params = {
      ...bankFilters,
    }
    if (params?.recordDateFrom && typeof params.recordDateFrom !== 'string') {
      params.recordDateFrom = dateToString(params.recordDateFrom)
    }
    if (params?.recordDateTo && typeof params.recordDateTo !== 'string') {
      params.recordDateTo = dateToString(params.recordDateTo)
    }
    setButtonLoading({ type: 'bankAccount', value: true })
    await exportTransactions({
      filters: params,
      format: TransactionsOutputType.Bank,
    })
    setButtonLoading({ type: null, value: false })
  }, [bankFilters, exportTransactions])

  const handleBankFiltersChange = useCallback((data: any) => {
    setBankFilters(data)
  }, [])

  const handleRecalculation = useCallback(async () => {
    const params = {
      ...recalculationFilters,
    }
    if (params?.startDate && typeof params.startDate !== 'string') {
      params.startDate = dateToString(params.startDate)
    }
    if (params?.endDate && typeof params.endDate !== 'string') {
      params.endDate = dateToString(params.endDate)
    }
    setButtonLoading({ type: 'recalculation', value: true })
    await recalculateLoanBalance({
      filters: params,
    })
    setButtonLoading({ type: null, value: false })
  }, [recalculationFilters, recalculateLoanBalance])

  const handleRecalculationFiltersChange = useCallback((data: any) => {
    setRecalculationFilters(data)
  }, [])

  const handleExportClientFeeStatement = useCallback(async () => {
    const params = {
      ...clientFeeStatementFilters,
    }
    if (params?.recordDateFrom && typeof params.recordDateFrom !== 'string') {
      params.recordDateFrom = dateToString(params.recordDateFrom)
    }
    if (params?.recordDateTo && typeof params.recordDateTo !== 'string') {
      params.recordDateTo = dateToString(params.recordDateTo)
    }
    setButtonLoading({ type: 'clientFee', value: true })
    await exportClientFeeStatement(params)
    setButtonLoading({ type: null, value: false })
  }, [exportClientFeeStatement, clientFeeStatementFilters])

  const handleClientFeeStatementFiltersChange = useCallback((data: any) => {
    setClientFeeStatementFilters(data)
  }, [])

  const handleExportEndOfMonthFlow = useCallback(async () => {
    setButtonLoading({ type: 'closeOfMonth', value: true })
    await exportEndOfMonthFlow()
    setButtonLoading({ type: null, value: false })
  }, [exportEndOfMonthFlow])

  const handleApplyEndOfMonthCancel = useCallback(() => {
    setIsConfirmModalShown(false)
    setEndOfMonthFlowType(null)
  }, [])

  const handleApplyEndOfMonthConfirm = useCallback(async () => {
    setButtonLoading({ type: null, value: true })
    await applyEndOfMonthFlow({ type: endOfMonthFlowType })
    setIsConfirmModalShown(false)
    setButtonLoading({ type: null, value: false })
    setEndOfMonthFlowType(null)
  }, [applyEndOfMonthFlow, endOfMonthFlowType])

  const handleApplyEndOfMonthFlow = useCallback(() => {
    setIsConfirmModalShown(true)
    setEndOfMonthFlowType(EndOfMonthFlowOperationType.Apply)
  }, [])

  const handleRevertEndOfMonthFlow = useCallback(() => {
    setIsConfirmModalShown(true)
    setEndOfMonthFlowType(EndOfMonthFlowOperationType.Revert)
  }, [])

  const handleLockEndOfMonthFlow = useCallback(() => {
    setIsConfirmModalShown(true)
    setEndOfMonthFlowType(EndOfMonthFlowOperationType.Lock)
  }, [])

  const clientOptions = useMemo(
    () =>
      clientsData?.map(({ clientName }) => ({
        value: clientName,
        label: clientName,
      })),
    [clientsData],
  )

  return (
    <Box pt={1} pr={2}>
      <Grid item xs={12}>
        <Card withBorder={false} noHeaderMargin>
          <div className={styles.gridTitle}>Daily</div>
          <Grid container spacing={6} justifyContent={'start'}>
            <Grid className={styles.gridItem} item xs={3}>
              <FlatCard
                title="Bank Output"
                action={
                  <Button
                    className={styles.button}
                    variant="contained"
                    onClick={handleBankExportTransactions}
                    isLoading={buttonLoading.type === 'bankAccount' && buttonLoading.value}
                  >
                    Export
                  </Button>
                }
              >
                <Form
                  validate={validateBankOutput}
                  onSubmit={handleBankFiltersChange}
                  initialValues={bankFilters}
                  render={({ handleSubmit }) => (
                    <>
                      <Autocomplete
                        // @ts-ignore
                        multiple
                        className={styles.autocomplete}
                        label=""
                        name="clients"
                        placeholder="Search clients"
                        options={clientOptions}
                        getOptionValue={(option) => option.value}
                      />
                      <KeyboardDatePicker
                        className={styles.datePicker}
                        name="recordDateFrom"
                        placeholder="Start date"
                        inputFormat={DATE_FORMAT}
                      />
                      <KeyboardDatePicker
                        className={styles.datePicker}
                        name="recordDateTo"
                        placeholder="End date"
                        inputFormat={DATE_FORMAT}
                      />
                      <FormSpy
                        subscription={{ dirty: true, values: true, valid: true }}
                        onChange={(props) => {
                          props.dirty && props.valid && handleSubmit({ ...props.values })
                        }}
                      />
                    </>
                  )}
                />
              </FlatCard>
            </Grid>
          </Grid>

          <div className={styles.gridTitle}>Ongoing</div>
          <Grid container spacing={6} justifyContent={'start'}>
            <Grid className={styles.gridItem} item xs={3}>
              <FlatCard
                title="Client Fee Statement"
                action={
                  <Button
                    className={styles.button}
                    variant="contained"
                    onClick={handleExportClientFeeStatement}
                    isLoading={buttonLoading.type === 'clientFee' && buttonLoading.value}
                  >
                    Export
                  </Button>
                }
              >
                <Form
                  validate={validateClientFeeStatement}
                  onSubmit={handleClientFeeStatementFiltersChange}
                  initialValues={clientFeeStatementFilters}
                  render={({ handleSubmit }) => (
                    <>
                      <Autocomplete
                        // @ts-ignore
                        multiple
                        className={styles.autocomplete}
                        label=""
                        name="clients"
                        placeholder="Search clients"
                        options={clientOptions}
                        getOptionValue={(option) => option.value}
                      />
                      <KeyboardDatePicker
                        className={styles.datePicker}
                        name="recordDateFrom"
                        placeholder="Start date"
                        inputFormat={DATE_FORMAT}
                      />
                      <KeyboardDatePicker
                        className={styles.datePicker}
                        name="recordDateTo"
                        placeholder="End date"
                        inputFormat={DATE_FORMAT}
                      />
                      <FormSpy
                        subscription={{ dirty: true, values: true, valid: true }}
                        onChange={(props) => {
                          props.dirty && props.valid && handleSubmit({ ...props.values })
                        }}
                      />
                    </>
                  )}
                />
              </FlatCard>
            </Grid>

            <Grid className={styles.gridItem} item xs={3}>
              <FlatCard
                title="Quickbooks"
                action={
                  <Button
                    className={styles.button}
                    variant="contained"
                    onClick={handleQuickbookExportTransactions}
                    isLoading={buttonLoading.type === 'quickbooks' && buttonLoading.value}
                  >
                    Export
                  </Button>
                }
              >
                <Form
                  validate={validateQuickbook}
                  onSubmit={handleQuickbookFiltersChange}
                  initialValues={quickbookFilters}
                  render={({ handleSubmit }) => (
                    <>
                      <div className={styles.blank} />

                      <KeyboardDatePicker
                        className={styles.datePicker}
                        name="recordDateFrom"
                        placeholder="Start date"
                        inputFormat={DATE_FORMAT}
                      />
                      <KeyboardDatePicker
                        className={styles.datePicker}
                        name="recordDateTo"
                        placeholder="End date"
                        inputFormat={DATE_FORMAT}
                      />
                      <FormSpy
                        subscription={{ dirty: true, values: true, valid: true }}
                        onChange={(props) => {
                          props.dirty && props.valid && handleSubmit({ ...props.values })
                        }}
                      />
                    </>
                  )}
                />
              </FlatCard>
            </Grid>

            <Grid className={styles.gridItem} item xs={3}>
              <FlatCard
                title="Average Loan Balance"
                action={
                  <Button
                    className={styles.button}
                    onClick={handleExportAverageLoanBalance}
                    variant="contained"
                    isLoading={buttonLoading.type === 'loanBalance' && buttonLoading.value}
                  >
                    Export
                  </Button>
                }
              >
                <Form
                  validate={validateBankOutput}
                  onSubmit={handleAvgLoanBalanceFiltersChange}
                  initialValues={avgLoanBalanceFilters}
                  render={({ handleSubmit }) => (
                    <>
                      <div className={styles.blank} />

                      <KeyboardDatePicker
                        className={styles.datePicker}
                        name="recordDateFrom"
                        placeholder="Start date"
                        inputFormat={DATE_FORMAT}
                      />
                      <KeyboardDatePicker
                        className={styles.datePicker}
                        name="recordDateTo"
                        placeholder="End date"
                        inputFormat={DATE_FORMAT}
                      />

                      <FormSpy
                        subscription={{ dirty: true, values: true, valid: true }}
                        onChange={(props) => {
                          props.dirty && props.valid && handleSubmit({ ...props.values })
                        }}
                      />
                    </>
                  )}
                />
              </FlatCard>
            </Grid>

            <Grid className={styles.gridItem} item xs={3}>
              <FlatCard
                title={'Recalculate Loan Balance'}
                action={
                  <Button
                    className={styles.button}
                    variant="contained"
                    onClick={handleRecalculation}
                    isLoading={buttonLoading.type === 'recalculate' && buttonLoading.value}
                  >
                    Recalculate
                  </Button>
                }
              >
                <Form
                  validate={validateRecalculation}
                  onSubmit={handleRecalculationFiltersChange}
                  initialValues={recalculationFilters}
                  render={({ handleSubmit }) => (
                    <>
                      <div className={styles.blank} />
                      <Autocomplete
                        // @ts-ignore
                        multiple
                        className={styles.autocomplete}
                        label=""
                        name="clients"
                        placeholder="Search clients"
                        options={clientOptions}
                        getOptionValue={(option) => option.value}
                      />
                      <KeyboardDatePicker
                        className={styles.datePicker}
                        name="recordDate"
                        placeholder="Start date"
                        inputFormat={DATE_FORMAT}
                        minDate={moment(loanBalanceStartDate || undefined).toDate()}
                      />
                      <FormSpy
                        subscription={{ dirty: true, values: true, valid: true }}
                        onChange={(props) => {
                          props.dirty && props.valid && handleSubmit({ ...props.values })
                        }}
                      />
                    </>
                  )}
                />
              </FlatCard>
            </Grid>
          </Grid>

          <div className={styles.gridTitleCloseMonth}>{endOfMonthLabel}</div>

          <Grid container spacing={6} justifyContent={'start'}>
            <Grid className={styles.gridItem} item xs={1.75}>
              <FlatCard>
                {!isMonthClosedAndLatest && (
                  <Box display="flex" justifyContent="space-between" flex="1">
                    <Button
                      className={styles.button}
                      variant="contained"
                      onClick={handleExportEndOfMonthFlow}
                      isLoading={buttonLoading.type === 'closeOfMonth' && buttonLoading.value}
                    >
                      Export
                    </Button>
                  </Box>
                )}
              </FlatCard>
            </Grid>
            <Grid className={styles.gridItem} item xs={1.75}>
              <FlatCard>
                {!isMonthClosedAndLatest && (
                  <Box display="flex" justifyContent="space-between" flex="1">
                    {!!endOfMonthFlow?.id && isMonthApplied ? (
                      <Button
                        className={styles.button}
                        variant="outlined"
                        onClick={handleRevertEndOfMonthFlow}
                      >
                        Revert
                      </Button>
                    ) : (
                      <Button
                        className={styles.button}
                        variant="outlined"
                        onClick={handleApplyEndOfMonthFlow}
                      >
                        Apply
                      </Button>
                    )}
                  </Box>
                )}
              </FlatCard>
            </Grid>
            <Grid className={styles.gridItem} item xs={1.75}>
              <FlatCard>
                {!isMonthClosedAndLatest && (
                  <Box display="flex" justifyContent="space-between" flex="1">
                    <Button
                      className={styles.button}
                      variant="outlined"
                      onClick={handleLockEndOfMonthFlow}
                      disabled={!endOfMonthFlow?.id || !isMonthApplied}
                    >
                      Lock
                    </Button>
                  </Box>
                )}
              </FlatCard>
            </Grid>
          </Grid>

          {isConfirmModalShown && endOfMonthFlowType && (
            <WarningModal
              warningMessage={
                endOfMonthFlowType === EndOfMonthFlowOperationType.Lock
                  ? 'Locking will disable any modification or addition of data in that month'
                  : endOfMonthFlowType === EndOfMonthFlowOperationType.Revert
                  ? 'Reverting will delete any manual overrides of fees applied'
                  : 'Applying will apply all fees from the export'
              }
              onConfirm={handleApplyEndOfMonthConfirm}
              onCancel={handleApplyEndOfMonthCancel}
              confirmText="Yes, proceed"
              cancelText="Cancel"
              isLoading={buttonLoading.value}
            />
          )}
        </Card>
      </Grid>
    </Box>
  )
}

export default ExportsDashboard
