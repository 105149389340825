import React, { ReactNode, useCallback } from 'react'
import MuiModal from '@mui/material/Modal'
import cn from 'classnames'
import genericSs from '@styles/generic.module.scss'
import { ReactComponent as CloseIcon } from '@assets/images/modal-close-icon.svg'
import styles from './FullscreenModal.module.scss'

interface IProps {
  isOpen?: boolean
  children?: ReactNode
  setIsOpen?: (value: boolean) => void
  classes?: any
  onCloseCustom?: () => void
  disableEnforceFocus?: boolean
  showCloseIcon?: boolean
}

const FullscreenModal = ({
  classes = {},
  isOpen,
  setIsOpen,
  children,
  onCloseCustom,
  disableEnforceFocus,
  showCloseIcon = false,
}: IProps) => {
  const onClose = useCallback(() => {
    setIsOpen(false)
    onCloseCustom && onCloseCustom()
  }, [setIsOpen, onCloseCustom])

  if (isOpen) {
    return (
      <MuiModal open onClose={onClose} disableEnforceFocus={disableEnforceFocus}>
        <div className={cn(styles.modal, classes.root)}>
          <div className={classes.header}>
            {showCloseIcon && (
              <CloseIcon className={cn(styles.modalClose, classes.close)} onClick={onClose} />
            )}
          </div>
          <div className={cn(styles.modalBody, classes.body)}>{children}</div>
          <div id="secondModalOpen" style={{ marginTop: 65 }} />
          <div
            id="activeToolbarsFullscreenWrapper"
            className={genericSs.activeToolbarsWrapperFullscreen}
          />
        </div>
      </MuiModal>
    )
  }
  return <>{children}</>
}

export default FullscreenModal
