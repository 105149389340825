import React, { useCallback, useMemo, useState } from 'react'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'

import styles from './GlobalSearchPage.module.scss'

import { GLOBAL_SEARCH_TYPE_TITLES, ISearchResult, SearchType } from '@common/interfaces/search'
import { highlightsMatches } from '../../helpers/helpers'
import FullscreenModal from '../../components/Common/FullscreenModal'
import GlobalSearchPageFullscreenSection from './GlobalSearchPageFullscreenSection'
import GlobalSearchPageSection from './GlobalSearchPageSection'
import { IFilter } from '@common/constants/filters'

export const GLOBAL_SEARCH_TYPE_COLUMNS: { [key: string]: IFilter[] } = {
  [SearchType.Client]: [
    {
      field: 'clientName',
      title: 'Client Name',
      type: 'text',
      isSortable: true,
    },
    {
      field: 'currentAmount',
      title: 'Loan Balance',
      type: 'amount',
      isSortable: true,
    },
    {
      field: 'risk',
      title: 'Risk Rating',
      type: 'number',
      isSortable: true,
    },
    {
      field: 'lineTurn',
      title: 'Line turn',
      type: 'number',
      isSortable: true,
    },
    {
      field: 'clientStatus',
      title: 'Status',
      type: 'text',
      isSortable: true,
    },
  ],
  [SearchType.DueDiligence]: [
    {
      field: 'diligencePage',
      title: 'Diligence Page',
      type: 'text',
      isSortable: false,
    },
    {
      field: 'clientName',
      title: 'Client Page',
      type: 'text',
      isSortable: true,
    },
    {
      field: 'currentAmount',
      title: 'Loan Balance',
      type: 'amount',
      isSortable: true,
    },
    {
      field: 'risk',
      title: 'Risk Rating',
      type: 'number',
      isSortable: true,
    },
    {
      field: 'lineTurn',
      title: 'Line turn',
      type: 'number',
      isSortable: true,
    },
    {
      field: 'clientStatus',
      title: 'Status',
      type: 'text',
      isSortable: true,
    },
  ],
  [SearchType.Prospect]: [
    {
      field: 'prospectPage',
      title: 'Prospect Page',
      type: 'text',
      isSortable: false,
    },
    {
      field: 'clientName',
      title: 'Client Page',
      type: 'text',
      isSortable: true,
    },
    {
      field: 'currentAmount',
      title: 'Loan Balance',
      type: 'amount',
      isSortable: true,
    },
    {
      field: 'risk',
      title: 'Risk Rating',
      type: 'number',
      isSortable: true,
    },
    {
      field: 'lineTurn',
      title: 'Line turn',
      type: 'number',
      isSortable: true,
    },
    {
      field: 'clientStatus',
      title: 'Status',
      type: 'text',
      isSortable: true,
    },
  ],
  [SearchType.Funding]: [
    {
      field: 'recordDate',
      title: 'Borrowing Base',
      type: 'text',
      isSortable: true,
    },
    {
      field: 'clientName',
      title: 'Client Name',
      type: 'text',
      isSortable: true,
    },
    {
      field: 'amount',
      title: 'Amount',
      type: 'amount',
      isSortable: true,
    },
    {
      field: 'arAvailability',
      title: 'AR Availability',
      type: 'amount',
      isSortable: true,
    },
    {
      field: 'inventoryAvailabilityPostSubLimit',
      title: 'Inv. Availability',
      type: 'amount',
      isSortable: true,
    },
    {
      field: 'box',
      title: 'Box',
      type: 'text',
      isSortable: false,
    },
  ],
  [SearchType.Collection]: [
    {
      field: 'clientName',
      title: 'Client Name',
      type: 'text',
      isSortable: true,
    },
    {
      field: 'paymentAmount',
      title: 'Amount',
      type: 'amount',
      isSortable: true,
    },
    {
      field: 'recordDate',
      title: 'Date',
      type: 'date',
      isSortable: true,
    },
    {
      field: 'debtor',
      title: 'Customer',
      type: 'text',
      isSortable: true,
    },
    {
      field: 'box',
      title: 'Box',
      type: 'text',
      isSortable: false,
    },
  ],
  [SearchType.Fee]: [
    {
      field: 'clientName',
      title: 'Client Name',
      type: 'text',
      isSortable: true,
    },
    {
      field: 'recordDate',
      title: 'Date',
      type: 'date',
      isSortable: true,
    },
    {
      field: 'amount',
      title: 'Amount',
      type: 'amount',
      isSortable: true,
    },
    {
      field: 'type',
      title: 'Type',
      type: 'text',
      isSortable: true,
    },
    {
      field: 'title',
      title: 'Description',
      type: 'text',
      isSortable: true,
    },
  ],
  [SearchType.OngoingReporting]: [
    {
      field: 'recordDate',
      title: 'Reporting',
      type: 'text',
      isSortable: true,
    },
    {
      field: 'clientName',
      title: 'Client Name',
      type: 'text',
      isSortable: true,
    },
    {
      field: 'type',
      title: 'Type',
      type: 'text',
      isSortable: true,
    },
    {
      field: 'statusLabel',
      title: 'Status',
      type: 'text',
      isSortable: true,
    },
  ],
  [SearchType.User]: [
    {
      field: 'fullName',
      title: 'Name',
      type: 'text',
      isSortable: true,
    },
    {
      field: 'email',
      title: 'Email',
      type: 'text',
      isSortable: true,
    },
    {
      field: 'clientName',
      title: 'Client Name',
      type: 'text',
      isSortable: true,
    },
    {
      field: 'roleLabel',
      title: 'Role',
      type: 'text',
      isSortable: true,
    },
  ],
  [SearchType.Entity]: [
    {
      field: 'name',
      title: 'Name',
      type: 'text',
      isSortable: true,
    },
    {
      field: 'type',
      title: 'Type',
      type: 'text',
      isSortable: true,
    },
    {
      field: 'totalExposure',
      title: 'Total AR Exposure',
      type: 'amount',
      isSortable: true,
    },
    {
      field: 'totalAp',
      title: 'Total AP',
      type: 'amount',
      isSortable: true,
    },
    {
      field: 'totalCollections',
      title: 'Collections',
      type: 'amount',
      isSortable: true,
    },
    {
      field: 'totalClients',
      title: 'Total Clients',
      type: 'number',
      isSortable: true,
    },
  ],
  [SearchType.Participant]: [
    {
      field: 'participant',
      title: 'Participant',
      type: 'text',
      isSortable: true,
    },
    {
      field: 'totalExposure',
      title: 'Total Exposure ($)',
      type: 'amount',
      isSortable: true,
    },
    {
      field: 'totalClients',
      title: 'Total Clients',
      type: 'number',
      isSortable: true,
    },
  ],
}

export const replaceJSX = (str: any, find: string) => {
  if (!find) {
    return str
  }

  const replacedStr = highlightsMatches(str?.toString() || '', find)

  return (
    <span
      dangerouslySetInnerHTML={{
        __html: replacedStr,
      }}
    />
  )
}

interface IProps {
  isLoading: boolean
  searchString: string
  searchResults: ISearchResult[]
  searchByType: (data: object) => Promise<{ data: ISearchResult[] }>
}

const GlobalSearchPage = ({ isLoading, searchString, searchResults, searchByType }: IProps) => {
  const [viewMoreResult, setViewMoreResult] = useState<SearchType>(null)
  const searchResultCount = useMemo(
    () => searchResults?.map(({ items }) => items).flat().length || 0,
    [searchResults],
  )
  const toggleViewMoreResult = useCallback(() => {
    setViewMoreResult(null)
  }, [])

  return (
    <Box py={3} px={2}>
      <Grid container>
        {searchString && (
          <Grid item xs={12}>
            <div className={styles.title}>
              Showing {searchResultCount} results for
              <span className={styles.titleSearchString}>“{searchString}”</span>
            </div>
          </Grid>
        )}
        {searchResults.map(
          (searchResult) =>
            searchResult.items.length > 0 && (
              <GlobalSearchPageSection
                key={searchResult.type}
                isLoading={isLoading}
                searchResult={searchResult}
                searchString={searchString}
                setViewMoreResult={setViewMoreResult}
              />
            ),
        )}
      </Grid>

      {viewMoreResult && (
        <FullscreenModal
          isOpen={!!viewMoreResult}
          setIsOpen={toggleViewMoreResult}
          classes={{ body: styles.fullScreenModal }}
        >
          <div className={styles.fullScreenModalTitleWrapper}>
            <h1 className={styles.fullScreenModalTitle}>
              {GLOBAL_SEARCH_TYPE_TITLES[viewMoreResult]}
            </h1>

            <span className={styles.title}>
              Showing results for
              <span className={styles.titleSearchString}>“{searchString}”</span>
            </span>
          </div>

          <GlobalSearchPageFullscreenSection
            type={viewMoreResult}
            searchString={searchString}
            search={searchByType}
          />
        </FullscreenModal>
      )}
    </Box>
  )
}

export default GlobalSearchPage
