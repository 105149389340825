import Base from './base'

export default class ReportingDocument extends Base {
  create(data: { name: string }) {
    return this.apiClient.post('reporting-documents', data)
  }

  list() {
    return this.apiClient.get('reporting-documents')
  }
}
