import React from 'react'
import Box from '@mui/material/Box'

import ClientSetupHeader from '../../components/ClientSetupHeader'
import ItemsToMonitor from '../../components/Client/ItemsToMonitor'

const ClientSetupItemsToMonitorPage = () => {
  return (
    <Box py={1} pr={2}>
      <ClientSetupHeader />

      <Box>
        <ItemsToMonitor />
      </Box>
    </Box>
  )
}

export default ClientSetupItemsToMonitorPage
