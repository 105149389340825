import React, { useCallback } from 'react'
import moment from 'moment'

import { DueDiligenceTableItemType, IDueDiligenceExecutive } from '@common/interfaces/dueDiligence'
import DueDiligenceTable from '../DueDiligenceTable'
import { ILoadingData } from '../../redux/types'
import { DATE_DATABASE_FORMAT } from '../../constants/common'

interface IProps {
  dueDiligenceTeamExecutives: ILoadingData<{ data: IDueDiligenceExecutive[] }>
  listTeamExecutives: (params: object) => void
  hideTeamExecutives: () => void
  addTeamExecutive: (data: object) => Promise<void>
  updateTeamExecutive: (id: string, data: object) => Promise<void>
  deleteTeamExecutive: (id: string) => Promise<void>
}

const DueDiligenceTeamExecutives = ({
  dueDiligenceTeamExecutives,
  listTeamExecutives,
  hideTeamExecutives,
  addTeamExecutive,
  updateTeamExecutive,
  deleteTeamExecutive,
}: IProps) => {
  const handleAddExecutive = useCallback(
    async (data: any) => {
      const {
        address,
        socialSecurityNumber,
        birthday,
        homeAddress,
        isConvictedFelony,
        isDefaultedFinancialObligation,
        isPersonalBankruptcy,
        ...rest
      } = data

      return addTeamExecutive({
        birthday: birthday ? moment(birthday).format(DATE_DATABASE_FORMAT) : null,
        socialSecurityNumber: socialSecurityNumber
          ? socialSecurityNumber.replaceAll('-', '')
          : null,
        country: homeAddress?.country || '',
        state: homeAddress?.state || '',
        city: homeAddress?.city || '',
        postalCode: homeAddress?.postalCode || '',
        street: homeAddress?.street || '',
        isConvictedFelony: isConvictedFelony ? isConvictedFelony === 'true' : null,
        isDefaultedFinancialObligation: isDefaultedFinancialObligation
          ? isDefaultedFinancialObligation === 'true'
          : null,
        isPersonalBankruptcy: isPersonalBankruptcy ? isPersonalBankruptcy === 'true' : null,
        ...rest,
      })
    },
    [addTeamExecutive],
  )
  const handleUpdateExecutive = useCallback(
    async (id: string, data: any) => {
      const {
        address,
        socialSecurityNumber,
        birthday,
        homeAddress,
        isConvictedFelony,
        isDefaultedFinancialObligation,
        isPersonalBankruptcy,
        ...rest
      } = data

      return updateTeamExecutive(id, {
        firstName: data.firstName || '',
        lastName: data.lastName || '',
        title: data.title || '',
        email: data.email || '',
        phone: data.phone || null,
        birthday: birthday ? moment(birthday).format(DATE_DATABASE_FORMAT) : null,
        socialSecurityNumber: socialSecurityNumber
          ? socialSecurityNumber.replaceAll('-', '')
          : null,
        country: homeAddress?.country || '',
        state: homeAddress?.state || '',
        city: homeAddress?.city || '',
        postalCode: homeAddress?.postalCode || '',
        street: homeAddress?.street || '',
        isConvictedFelony: isConvictedFelony ? isConvictedFelony === 'true' : null,
        isConvictedFelonyComment: data.isConvictedFelonyComment || null,
        isDefaultedFinancialObligation: isDefaultedFinancialObligation
          ? isDefaultedFinancialObligation === 'true'
          : null,
        isDefaultedFinancialObligationComment: data.isDefaultedFinancialObligationComment || null,
        isPersonalBankruptcy: isPersonalBankruptcy ? isPersonalBankruptcy === 'true' : null,
        isPersonalBankruptcyComment: data.isPersonalBankruptcyComment || null,
        ...rest,
      })
    },
    [updateTeamExecutive],
  )

  return (
    <DueDiligenceTable
      type={DueDiligenceTableItemType.Executive}
      data={dueDiligenceTeamExecutives}
      handleList={listTeamExecutives}
      handleHide={hideTeamExecutives}
      handleAdd={handleAddExecutive}
      handleUpdate={handleUpdateExecutive}
      handleDelete={deleteTeamExecutive}
    />
  )
}

export default DueDiligenceTeamExecutives
