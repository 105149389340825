import { IApi } from '../../api'
import { CommonPromiseAction } from '../types'
import { createRequestTypes } from '../../helpers/helpers'

export const prefix = 'adjustment'

export const CREATE_ADJUSTMENT = createRequestTypes(prefix, 'CREATE_ADJUSTMENT')

export const [CREATE_ADJUSTMENT_REQUEST, CREATE_ADJUSTMENT_SUCCESS, CREATE_ADJUSTMENT_FAILURE] =
  CREATE_ADJUSTMENT

export const createAdjustment: CommonPromiseAction = (data: object) => ({
  type: CREATE_ADJUSTMENT,
  promise: (api: IApi) => api.adjustment.create(data),
})

const UPDATE_ADJUSTMENT = createRequestTypes(prefix, 'UPDATE_ADJUSTMENT')

export const [UPDATE_ADJUSTMENT_REQUEST, UPDATE_ADJUSTMENT_SUCCESS, UPDATE_ADJUSTMENT_FAILURE] =
  UPDATE_ADJUSTMENT

export const updateAdjustment: CommonPromiseAction = (id: string, data: object) => ({
  type: UPDATE_ADJUSTMENT,
  promise: (api: IApi) => api.adjustment.update(id, data),
})

export const DELETE_ADJUSTMENT = createRequestTypes(prefix, 'DELETE_ADJUSTMENT')

export const [DELETE_ADJUSTMENT_REQUEST, DELETE_ADJUSTMENT_SUCCESS, DELETE_ADJUSTMENT_FAILURE] =
  DELETE_ADJUSTMENT

export const deleteAdjustment: CommonPromiseAction = (id: string) => ({
  type: DELETE_ADJUSTMENT,
  promise: (api: IApi) => api.adjustment.delete(id),
})
