import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'

import CollectionsWiresMapping from './CollectionsWiresMapping'
import { wiresData, wiresDataNegative, wiresDataDeleted } from '../../redux/collections/selectors'
import { listEntityInfo, addEntityInfo } from '../../redux/entityInfo/actions'
import { clients } from '../../redux/clientInfo/selectors'
import { list as listClients } from '../../redux/clientInfo/actions'

const selector = createStructuredSelector({
  wiresData,
  wiresDataNegative,
  wiresDataDeleted,
  clients,
})

const actions = {
  listEntityInfo,
  addEntityInfo,
  listClients,
}

export default connect(selector, actions)(CollectionsWiresMapping)
