import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'

import DueDiligenceValidateDocumentsPage from './DueDiligenceValidateDocumentsPage'

import {
  dueDiligenceInfo,
  dueDiligenceDocumentRequests,
  dueDiligenceDocumentRequestsTypes,
  refreshCount,
} from '../../redux/dueDiligence/selectors'
import {
  show,
  showDocumentRequestsTypes,
  hideDocumentRequestsTypes,
  showDocumentRequests,
  hideDocumentRequests,
  createDocumentRequest,
  updateDocumentRequest,
  updateDocumentsRequests,
  deleteDocumentRequest,
  deleteDocumentsRequests,
  createDocumentRequestComment,
  updateDocumentRequestComment,
  deleteDocumentRequestComment,
  uploadDocumentRequestsFiles,
  getDocumentRequestsSharedLink,
} from '../../redux/dueDiligence/actions'
import { userId } from '../../redux/profile/selectors'
import { deleteFile } from '../../redux/file/actions'
import { putNotification } from '../../redux/ui/actions'

const selector = createStructuredSelector({
  dueDiligenceInfo,
  dueDiligenceDocumentRequests,
  dueDiligenceDocumentRequestsTypes,
  currentUserId: userId,
  refreshCount,
})

const actions = {
  show,
  showDocumentRequestsTypes,
  hideDocumentRequestsTypes,
  showDocumentRequests,
  hideDocumentRequests,
  createDocumentRequest,
  updateDocumentRequest,
  updateDocumentsRequests,
  deleteDocumentRequest,
  deleteDocumentsRequests,
  createDocumentRequestComment,
  updateDocumentRequestComment,
  deleteDocumentRequestComment,
  uploadDocumentRequestsFiles,
  getDocumentRequestsSharedLink,
  deleteFile,
  putNotification,
}

export default connect(selector, actions)(DueDiligenceValidateDocumentsPage)
