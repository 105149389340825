import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'

import EntityMergeConflictModal from './EntityMergeConflictModal'

import { entity, entityMergeConflicts } from '../../redux/entityInfo/selectors'

const selector = createStructuredSelector({
  entity,
  entityMergeConflicts,
})

export default connect(selector, null)(EntityMergeConflictModal)
