import { useMemo } from 'react'
import { IOngoingReporting } from '@common/interfaces/bbc'

interface IProps {
  reporting: IOngoingReporting
  type: string
}

const useOngoingReportingFileSheet = ({ reporting, type }: IProps) => {
  const fileSheets = useMemo(
    () => (reporting?.fileSheets || []).filter((fileSheet) => fileSheet.type === type),
    [reporting, type],
  )

  const currentSelectedFile = useMemo(
    () =>
      fileSheets?.length ? reporting?.files?.find(({ id }) => id === fileSheets[0].fileId) : null,
    [reporting, fileSheets],
  )

  return {
    fileSheets,
    currentSelectedFile,
  }
}

export default useOngoingReportingFileSheet
