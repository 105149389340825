import React from 'react'
import Box from '@mui/material/Box'
import Skeleton from '@mui/material/Skeleton'
import Card from '../../components/Common//Card'
import TableLoader from '../../components/Common//TableLoader'
import TableContainer from '../../components/Common//TableContainer'
import Table from '../../components/Common//Table'
import TableHead from '../../components/Common//TableHead'
import TableBody from '../../components/Common//TableBody'
import TableFooter from '../../components/Common/TableFooter'
import { Grid } from '@mui/material'
import styles from './EntityPage.module.scss'

const EntityLoader = () => {
  return (
    <>
      <Box display="flex" justifyContent="space-between" mb={3}>
        <Box mt={1.25}>
          <Skeleton width={450} height={21} />
        </Box>
      </Box>

      <Box display="flex" alignItems="center" justifyContent="space-between" mt={4} mb={3}>
        <Skeleton width={300} height={60} />
        <Box display="flex" gap={3}>
          <Skeleton width={100} height={40} />
        </Box>
      </Box>
      <Grid container xs={12} spacing={3}>
        <Grid item xs={6}>
          <Skeleton height={300} />
        </Grid>
        <Grid item xs={6}>
          <Skeleton className={styles.headerLoader} height={300} />
        </Grid>
      </Grid>

      <Card>
        <Skeleton width={120} height={24} />
        <br />

        <TableContainer>
          <Table>
            <TableHead>
              <TableLoader columnsCount={11} rowsCount={1} />
            </TableHead>
            <TableBody>
              <TableLoader columnsCount={11} rowsCount={10} />
            </TableBody>
            <TableFooter>
              <TableLoader columnsCount={11} rowsCount={2} />
            </TableFooter>
          </Table>
        </TableContainer>
        <br />
      </Card>
    </>
  )
}

export default EntityLoader
