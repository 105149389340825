import React, {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import { Link as RouterLink } from 'react-router-dom'
import { useParams } from 'react-router'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import Skeleton from '@mui/material/Skeleton'
import Link from '@mui/material/Link'
import Tooltip from '@mui/material/Tooltip'
import cn from 'classnames'
import { IFile } from '@common/interfaces/box'

import styles from './OngoingReportingSummary.module.scss'
import genericSs from '@styles/generic.module.scss'

import {
  formatDate,
  formatDateMonthYear,
  formatDateRange,
  formatPriceConditionalDecimal,
} from '../../helpers/helpers'
import TableContainer from '../Common/TableContainer'
import Table from '../Common/Table'
import TableHead from '../Common/TableHead'
import TableBody from '../Common/TableBody'
import TableRow from '../Common/TableRow'
import TableCell from '../Common/TableCell'
import {
  ANNUALLY_REPORTING_PERIOD_OPTIONS,
  AnnuallyReportingPeriods,
  CLIENT_FINANCIALS_BOTTOM_LINE_FIELDS,
  CLIENT_FINANCIALS_DETAILS_FIELDS_EXPANDERS,
  CLIENT_FINANCIALS_FIELDS,
  CLIENT_FINANCIALS_FIELDS_MAPPING,
  CLIENT_FINANCIALS_HEADER_FIELDS,
  CLIENT_FINANCIALS_HEADER_FIELDS_WITH_EXPAND,
  CLIENT_FINANCIALS_HEADER_FIELDS_WITH_EXPAND_BY_TYPE,
  CLIENT_FINANCIALS_PERCENT_FIELDS,
  CLIENT_FINANCIALS_RATIO_FIELDS,
  DisplayGrowthPeriods,
  FinancialKeys,
  FinancialsType,
  IOngoingReportingFileSheet,
  IOngoingReportingSummaryData,
  IOngoingReportingSummaryHeaderData,
  IOngoingReportingSummaryHeaders,
  IOngoingReportingSummaryHeadersData,
  ONGOING_REPORTING_TYPE_LABEL,
  OngoingReportingType,
  REPORTING_PERIOD_OPTIONS,
  ReportingFlow,
  ReportingPeriods,
} from '@common/interfaces/bbc'
import Card from '../Common/Card'
import {
  EditMapping,
  EditSourceData,
  ExpandAndMinimize,
  ExternalLink,
  ShowAndHide,
} from '../Common/Icons'
import FullscreenModal from '../Common/FullscreenModal'
import FinancialEditDatesModal from '../FinancialEditDatesModal'
import SelectField, { IOption } from '../Common/SelectField'
import TableLoader from '../Common/TableLoader'
import DatePicker from '../Common/DatePicker'
import MultiCellTooltip, { IMultiCellTotal } from '../MultiCellTooltip'
import FinancialTrendsGraph from '../FinancialTrendsGraph'
import { IOPSReporting } from '@common/interfaces/prospects'
import useTrackVisualizationsTableChartSelection from '../../hooks/useTrackVisualizationsTableChartSelection'
import { CATEGORIES } from '@common/constants/tracking'
import useTrackVisualizationsTable from '../../hooks/useTrackVisualizationsTable'
import { DEFAULT_EMPTY_ARRAY_PROP } from '../../constants/common'
import moment from 'moment'
import useGraphToggle from '../../hooks/useGraphTogggle'
import useTable from '../../hooks/useTable'
import Empty from '../Common/Empty/Empty'

const today = moment().endOf('year').format('YYYY-MM-DD')
const twoYearsAgo = moment().subtract(2, 'years').startOf('year').format('YYYY-MM-DD')

const INCOME_STATEMENT_FIELD_MAPPING = CLIENT_FINANCIALS_FIELDS[
  OngoingReportingType.IncomeStatement
].map((field) => {
  return {
    value: field,
    label: CLIENT_FINANCIALS_FIELDS_MAPPING[field],
  }
})

const BALANCE_SHEET_FIELD_MAPPING = CLIENT_FINANCIALS_FIELDS[OngoingReportingType.BalanceSheet].map(
  (field) => {
    return {
      value: field,
      label: CLIENT_FINANCIALS_FIELDS_MAPPING[field],
    }
  },
)

const IS_DEFAULT_FIELDS: FinancialKeys[] = ['gross_revenue', 'net_revenue', 'gross_profit']

const BS_DEFAULT_FIELDS: FinancialKeys[] = [
  'cash_and_cash_equivalents',
  'accounts_receivable_net',
  'inventory',
]

const financialsTypeOptions = Object.values(FinancialsType).map((value) => ({
  value,
  label: value,
}))

enum QuickFilter {
  ActualsVsProjections = 'Act. vs. Proj.',
  YearOverYear = 'Year-over-Year',
}

interface IRowProps {
  field: FinancialKeys
  dataSummaryKey: OngoingReportingType | FinancialsType.StressCase
  activeCells: number[][]
  activeRows: number[]
  activeColumns: number[]
  rowIndex: number
  onSelectRow: (event: any, rowIndex: number) => void
  onSelectCell: (event: any, rowIndex: number, columnIndex: number) => void
  expandedFields: {
    [key: string]: boolean
  }
  toggleExpand: (field: string) => void
  missedColumns: number[]
  dataSummary: Partial<Record<OngoingReportingType, IOngoingReportingSummaryData>>
  dataSummaryGrowth?: Partial<Record<OngoingReportingType, IOngoingReportingSummaryData>>
  dataSummaryHeaders: string[]
  totalCell?: IMultiCellTotal
  summaryColumn?: number
}

const OngoingReportingSummaryGrowthDisplay = ({ dataGrowth }: { dataGrowth?: number }) => {
  const isPositive = useMemo(() => dataGrowth > 0, [dataGrowth])

  const displayValue = useMemo(() => {
    const value = Math.abs(dataGrowth * 100)

    return value >= 10000
      ? '9999+'
      : value >= 100
      ? Math.round(value)
      : value >= 10
      ? value.toFixed(1)
      : value.toFixed(2)
  }, [dataGrowth])

  if (!dataGrowth) {
    return null
  }

  return (
    <span
      className={cn(styles.dataGrowth, {
        [styles.dataGrowthPositive]: isPositive,
      })}
    >
      {displayValue}%
    </span>
  )
}

const OngoingReportingSummaryCell = ({
  field,
  data,
  dataTotal,
  dataGrowth,
  rowIndex,
  cellIndex,
  onSelectCell,
  isSelected,
  isActive,
}: {
  field: FinancialKeys
  data: number
  dataTotal?: IMultiCellTotal
  dataGrowth?: number
  rowIndex: number
  cellIndex: number
  onSelectCell: (event: any, rowIndex: number, columnIndex: number) => void
  isSelected: boolean
  isActive: boolean
}) => {
  const handleSelectCell = useCallback(
    (event) => onSelectCell(event, rowIndex, cellIndex),
    [rowIndex, cellIndex, onSelectCell],
  )

  return (
    <TableCell
      className={cn(genericSs.tableTextRight, 'activableCell', {
        activeCell: isSelected,
      })}
      data-index={cellIndex}
      onClick={handleSelectCell}
    >
      <MultiCellTooltip isActive={isActive} data={dataTotal}>
        <div
          className={cn({
            [styles.amountHolder]: !CLIENT_FINANCIALS_BOTTOM_LINE_FIELDS.includes(field),
            [styles.bottomLineAmount]: CLIENT_FINANCIALS_BOTTOM_LINE_FIELDS.includes(field),
          })}
        >
          <OngoingReportingSummaryGrowthDisplay dataGrowth={dataGrowth} />

          {CLIENT_FINANCIALS_PERCENT_FIELDS.includes(field) ? (
            <>
              {(data * 100 || 0)?.toFixed(2)}
              <span>%</span>
            </>
          ) : CLIENT_FINANCIALS_RATIO_FIELDS.includes(field) ? (
            <>
              {(+data)?.toFixed(2)}
              <span>x</span>
            </>
          ) : (
            <>
              <span>$</span>
              {formatPriceConditionalDecimal(data)}
            </>
          )}
        </div>
      </MultiCellTooltip>
    </TableCell>
  )
}

const OngoingReportingSummaryRow = ({
  field,
  dataSummaryKey,
  activeCells,
  activeRows,
  activeColumns,
  rowIndex,
  onSelectRow,
  onSelectCell,
  expandedFields,
  toggleExpand,
  missedColumns,
  dataSummary,
  dataSummaryGrowth,
  dataSummaryHeaders,
  totalCell,
  summaryColumn,
}: IRowProps) => {
  const handleSelectRow = useCallback(
    (event) => onSelectRow(event, rowIndex),
    [rowIndex, onSelectRow],
  )

  const handleToggleExpand = useCallback(() => toggleExpand(field), [field, toggleExpand])

  if (
    !CLIENT_FINANCIALS_HEADER_FIELDS.includes(field) &&
    !expandedFields[CLIENT_FINANCIALS_DETAILS_FIELDS_EXPANDERS[field]]
  ) {
    return null
  }

  return (
    <TableRow
      className={cn('activableRow', {
        [styles.headerRow]:
          CLIENT_FINANCIALS_HEADER_FIELDS.includes(field) &&
          !CLIENT_FINANCIALS_BOTTOM_LINE_FIELDS.includes(field),
        [styles.headerRowThinBorder]:
          CLIENT_FINANCIALS_HEADER_FIELDS.includes(field) &&
          !CLIENT_FINANCIALS_HEADER_FIELDS_WITH_EXPAND.includes(field),
        [styles.bottomRow]:
          CLIENT_FINANCIALS_HEADER_FIELDS.includes(field) &&
          CLIENT_FINANCIALS_BOTTOM_LINE_FIELDS.includes(field),
        [styles.keyRow]: CLIENT_FINANCIALS_HEADER_FIELDS.includes(field),
      })}
      data-index={rowIndex}
    >
      <TableCell
        style={{
          zIndex: CLIENT_FINANCIALS_HEADER_FIELDS_WITH_EXPAND.includes(field) ? 50 - rowIndex : 1,
        }}
        className={cn({
          [styles.withExpandIcon]: CLIENT_FINANCIALS_HEADER_FIELDS_WITH_EXPAND.includes(field),
        })}
      >
        {CLIENT_FINANCIALS_HEADER_FIELDS_WITH_EXPAND.includes(field) && (
          <ShowAndHide
            isExpanded={expandedFields[field]}
            action={handleToggleExpand}
            className={styles.expandIcon}
          />
        )}
        <div className={styles.expandOverflow} />
      </TableCell>
      <TableCell
        className={cn({
          activeCell: activeRows.includes(rowIndex),
        })}
        onClick={handleSelectRow}
      >
        <div
          className={cn({
            [styles.financialHeaders]: !CLIENT_FINANCIALS_BOTTOM_LINE_FIELDS.includes(field),
            [styles.financialBottomLine]: CLIENT_FINANCIALS_BOTTOM_LINE_FIELDS.includes(field),
            [styles.financialHeadersActive]: !CLIENT_FINANCIALS_BOTTOM_LINE_FIELDS.includes(field),
          })}
        >
          {CLIENT_FINANCIALS_FIELDS_MAPPING[field]}
        </div>
      </TableCell>

      {dataSummary[dataSummaryKey] ? (
        dataSummaryHeaders.map((header, index) =>
          dataSummary[dataSummaryKey][field] ? (
            <OngoingReportingSummaryCell
              key={header}
              field={field}
              data={dataSummary[dataSummaryKey][field][index]}
              dataTotal={totalCell}
              dataGrowth={dataSummaryGrowth[dataSummaryKey]?.[field]?.[index]}
              rowIndex={rowIndex}
              cellIndex={index}
              onSelectCell={onSelectCell}
              isSelected={
                activeRows.includes(rowIndex) ||
                activeCells[rowIndex]?.includes(index) ||
                activeColumns.includes(index)
              }
              isActive={activeCells[rowIndex]?.includes(index)}
            />
          ) : (
            <TableCell key={header} />
          ),
        )
      ) : (
        <TableCell>
          {!dataSummary[dataSummaryKey] && <Skeleton width={100} height={16} />}
        </TableCell>
      )}
      {missedColumns.map((index) => (
        <TableCell key={index}>
          {!dataSummary[dataSummaryKey] && <Skeleton width={100} height={16} />}
        </TableCell>
      ))}
      {activeColumns.length > 1 && (
        <TableCell
          className={cn(genericSs.tableTextRight, 'summaryColumn', {
            [styles.blueColumn]:
              CLIENT_FINANCIALS_HEADER_FIELDS.includes(field) &&
              CLIENT_FINANCIALS_BOTTOM_LINE_FIELDS.includes(field),
          })}
        >
          <div className={styles.amountHolder}>
            <span>$</span>
            {formatPriceConditionalDecimal(summaryColumn)}
          </div>
        </TableCell>
      )}
    </TableRow>
  )
}

const OngoingReportingSummaryHeaderTooltip = ({
  header,
  data,
}: {
  header: string
  data: IOngoingReportingSummaryHeaderData
}) => {
  const actuals = useMemo(
    () =>
      (data.periods?.[FinancialsType.Actuals] || []).map((recordDate) => ({
        startDate: recordDate,
        endDate: recordDate,
      })),
    [data],
  )
  const projections = useMemo(
    () =>
      (data.periods?.[FinancialsType.Projections] || []).map((recordDate) => ({
        startDate: recordDate,
        endDate: recordDate,
      })),
    [data],
  )

  return (
    <Tooltip
      title={
        <div className={styles.headerTooltipContent}>
          <div className={styles.headerTooltipContentHeader}>{header}</div>
          {actuals.length > 0 && (
            <>
              <div className={styles.headerTooltipContentHeader}>Actuals</div>
              <div className={styles.headerTooltipContentValue}>
                {formatDateRange(actuals, 'MMM YYYY', false, true)}
              </div>
            </>
          )}

          {projections.length > 0 && (
            <>
              <div className={styles.headerTooltipContentHeader}>
                Projections {formatDate(data.asOfDate)}
              </div>
              <div className={styles.headerTooltipContentValue}>
                {formatDateRange(projections, 'MMM YYYY', false, true)}
              </div>
            </>
          )}
        </div>
      }
      placement="right"
      enterDelay={600}
      arrow
      classes={{
        arrow: styles.headerTooltipArrow,
        tooltip: styles.headerTooltip,
      }}
    >
      <div className={styles.accountHeader}>{header}</div>
    </Tooltip>
  )
}

interface IProps {
  statementType: OngoingReportingType
  dataSummary: Partial<Record<OngoingReportingType, IOngoingReportingSummaryData>>
  dataSummaryHeaders: IOngoingReportingSummaryHeaders
  dataSummaryHeadersColors: IOngoingReportingSummaryHeaders
  dataSummaryHeadersData: IOngoingReportingSummaryHeadersData
  dataSummaryGrowth: Partial<Record<OngoingReportingType, IOngoingReportingSummaryData>>
  listSummaryOngoingReporting: (
    id: string,
    params?: {
      statementType: string
      reportingFlow: string
      reportingPeriod?: string
    },
  ) => void
  listClientFinancialsSummary: (
    id: string,
    params?: {
      statementType: OngoingReportingType
      financialsTypes?: FinancialsType[]
      ongoingReporting?: string[]
      reportingFlow?: ReportingFlow
      opsReportingId?: string
      reportingPeriod?: ReportingPeriods
      annuallyReportingPeriods?: AnnuallyReportingPeriods[]
      displayGrowth?: DisplayGrowthPeriods
      startDate?: string
      endDate?: string
    },
  ) => void
  refreshCounter?: number
  setIsMappingModalShown?: Dispatch<SetStateAction<boolean>>
  handleOpenSelectFile?: () => void
  selectedFile?: IFile | null
  updateFinancialsDates?: (data: object) => Promise<void>
  showEditFinancialsDate?: boolean
  isModalShown?: boolean
  setIsModalShown?: Dispatch<SetStateAction<boolean>>
  additionalParams?: object
  dates?: { startDate: string; endDate: string }
  datesBoundary?: { minDate: string; maxDate: string } | null
  reportingFlow: ReportingFlow
  isMappingRequired?: boolean
  isMappingDisabled?: boolean
  isLoading?: boolean
  asOfDates?: string[]
  currentReportingPeriod: ReportingPeriods
  currentAnnuallyReportingPeriods?: AnnuallyReportingPeriods[]
  currentDisplayGrowth?: DisplayGrowthPeriods
  currentFinancialsTypes?: FinancialsType[]
  currentOngoingReporting?: string[]
  currentDateRange?: { startDate: string; endDate: string }
  setCurrentReportingPeriod: Dispatch<SetStateAction<ReportingPeriods>>
  setCurrentAnnuallyReportingPeriods?: Dispatch<SetStateAction<AnnuallyReportingPeriods[]>>
  setCurrentDisplayGrowth?: Dispatch<SetStateAction<DisplayGrowthPeriods>>
  setCurrentFinancialsTypes?: Dispatch<SetStateAction<FinancialsType[]>>
  setCurrentOngoingReporting?: Dispatch<SetStateAction<string[]>>
  setCurrentDateRange?: Dispatch<SetStateAction<{ startDate: string; endDate: string }>>
  defaultExpandState?: boolean
  title?: string
  editLink?: string | (() => void)
  cardWithBorder?: boolean
  isSummary?: boolean
  reporting: IOPSReporting
  fileSheets?: IOngoingReportingFileSheet[]
  isStressCase?: boolean
}

const OngoingReportingSummary = ({
  dataSummary,
  dataSummaryHeaders,
  dataSummaryHeadersColors,
  dataSummaryHeadersData,
  dataSummaryGrowth,
  listSummaryOngoingReporting,
  listClientFinancialsSummary,
  refreshCounter = 1,
  setIsMappingModalShown,
  statementType,
  handleOpenSelectFile,
  selectedFile,
  showEditFinancialsDate = false,
  updateFinancialsDates,
  isModalShown,
  setIsModalShown,
  additionalParams,
  dates,
  datesBoundary,
  reportingFlow,
  isMappingRequired = false,
  isMappingDisabled = false,
  isLoading = false,
  asOfDates,
  currentReportingPeriod,
  currentAnnuallyReportingPeriods = DEFAULT_EMPTY_ARRAY_PROP,
  currentDisplayGrowth = DisplayGrowthPeriods.Hide,
  currentFinancialsTypes,
  currentOngoingReporting,
  currentDateRange,
  setCurrentReportingPeriod,
  setCurrentAnnuallyReportingPeriods,
  setCurrentDisplayGrowth,
  setCurrentFinancialsTypes,
  setCurrentOngoingReporting,
  setCurrentDateRange,
  defaultExpandState = true,
  title,
  editLink,
  cardWithBorder = true,
  isSummary = false,
  reporting,
  fileSheets,
  isStressCase = false,
}: IProps) => {
  const wrapperRef = useRef(null)
  const { id } = useParams<{ id: string }>()
  const [isRefresh, setIsRefresh] = useState(false)
  const [currentLineItems, setCurrentLineItems] = useState<FinancialKeys[]>([])

  const clientId = useMemo(
    () => (reportingFlow === ReportingFlow.OPS ? reporting?.clientInfo?.id : id),
    [reportingFlow, reporting, id],
  )
  const opsReportingId = useMemo(
    () => (reportingFlow === ReportingFlow.OPS ? reporting?.id : null),
    [reportingFlow, reporting],
  )

  const isPortfolio = useMemo(() => !!asOfDates, [asOfDates])

  const isDD = useMemo(() => reportingFlow === ReportingFlow.DueDiligence, [reportingFlow])

  const dataSummaryKey = useMemo(() => {
    return isStressCase ? FinancialsType.StressCase : statementType
  }, [isStressCase, statementType])

  const lineItems = useMemo(() => {
    if (statementType === OngoingReportingType.BalanceSheet) {
      return BALANCE_SHEET_FIELD_MAPPING
    } else if (isPortfolio) {
      return [
        ...INCOME_STATEMENT_FIELD_MAPPING,
        {
          value: 'collections',
          label: 'Collections',
        },
      ]
    }
    return INCOME_STATEMENT_FIELD_MAPPING
  }, [isPortfolio, statementType])

  useEffect(() => {
    if (statementType === OngoingReportingType.IncomeStatement) {
      if (isPortfolio) {
        setCurrentLineItems([...IS_DEFAULT_FIELDS, 'collections'])
      } else {
        setCurrentLineItems(IS_DEFAULT_FIELDS)
      }
    } else {
      setCurrentLineItems(BS_DEFAULT_FIELDS)
    }
  }, [statementType, isPortfolio])

  const {
    activeCells,
    activeColumns,
    activeRows,
    handleSelectCell,
    handleSelectActiveRow: handleSelectRow,
    handleSelectActiveColumn: handleSelectColumn,
    resetActiveCells,
  } = useTable({
    tableId: 'ongoingReportingSummary',
  })

  const coupledTables = useMemo(
    () => [
      `ongoingReportingSummary${statementType}`,
      'ongoingReportingReconciliation',
      `ongoingActiveToolbar${statementType}`,
    ],
    [statementType],
  )

  const displayGrowthOptions = useMemo(
    () =>
      Object.values(DisplayGrowthPeriods)
        .filter(
          (period) =>
            period !== DisplayGrowthPeriods.Month ||
            currentReportingPeriod === ReportingPeriods.Monthly,
        )
        .filter(
          (period) =>
            period !== DisplayGrowthPeriods.Quarter ||
            currentReportingPeriod === ReportingPeriods.Quarterly,
        )
        .filter(
          (period) =>
            period !== DisplayGrowthPeriods.ActualsProjections ||
            ((currentFinancialsTypes?.length === 2 ||
              (currentOngoingReporting?.length >= 2 &&
                currentOngoingReporting.includes(FinancialsType.Actuals))) &&
              !isStressCase),
        )
        .filter((period) => period !== DisplayGrowthPeriods.StressBase || isStressCase)
        .map((value) => ({
          value,
          label: value,
        })),
    [currentReportingPeriod, currentFinancialsTypes, currentOngoingReporting, isStressCase],
  )

  const financialTypeOptions: IOption[] = useMemo(() => {
    return [
      {
        value: FinancialsType.Actuals,
        label: FinancialsType.Actuals,
      },
      ...(asOfDates || []).map((date) => ({
        value: date,
        label: `Projections ${formatDate(date)}`,
      })),
    ]
  }, [asOfDates])

  useEffect(() => {
    !isSummary &&
      refreshCounter &&
      listSummaryOngoingReporting(id, {
        statementType,
        reportingFlow,
        reportingPeriod: currentReportingPeriod,
        ...(additionalParams || {}),
      })
  }, [
    isSummary,
    refreshCounter,
    id,
    statementType,
    listSummaryOngoingReporting,
    additionalParams,
    currentReportingPeriod,
    reportingFlow,
  ])

  const { startDate, endDate } = useMemo(() => {
    return {
      startDate: currentDateRange?.startDate,
      endDate: currentDateRange?.endDate,
    }
  }, [currentDateRange])

  useEffect(() => {
    const listClientFinancialsSummaryData = async () => {
      setIsRefresh(true)
      await listClientFinancialsSummary(clientId, {
        statementType,
        reportingFlow,
        opsReportingId,
        reportingPeriod: currentReportingPeriod,
        annuallyReportingPeriods: currentAnnuallyReportingPeriods,
        displayGrowth: currentDisplayGrowth,
        financialsTypes: currentFinancialsTypes,
        ongoingReporting: currentOngoingReporting,
        startDate,
        endDate,
      })
      setIsRefresh(false)
    }

    isSummary && refreshCounter && clientId && startDate && listClientFinancialsSummaryData()
  }, [
    isSummary,
    refreshCounter,
    listClientFinancialsSummary,
    clientId,
    statementType,
    reportingFlow,
    currentAnnuallyReportingPeriods,
    currentReportingPeriod,
    currentDisplayGrowth,
    startDate,
    endDate,
    currentFinancialsTypes,
    currentOngoingReporting,
    opsReportingId,
  ])

  const financialStatementType = useMemo(
    () => statementType?.replace('Projections', ''),
    [statementType],
  )

  const [expandedFields, setExpandedFields] = useState<{
    [key: string]: boolean
  }>({})

  useEffect(() => {
    setExpandedFields(
      (CLIENT_FINANCIALS_HEADER_FIELDS_WITH_EXPAND_BY_TYPE[financialStatementType] || []).reduce(
        (state: any, field: string) => ({
          ...state,
          [field]: defaultExpandState,
        }),
        {},
      ),
    )
  }, [financialStatementType, defaultExpandState])

  const isAllExpanded = useMemo(
    () =>
      Object.keys(expandedFields).length > 0 &&
      Object.values(expandedFields).every((isExpanded) => isExpanded),
    [expandedFields],
  )
  const toggleExpandAll = useCallback(() => {
    setExpandedFields((fields) =>
      Object.keys(fields).reduce(
        (result, field) => ({
          ...result,
          [field]: !isAllExpanded,
        }),
        {},
      ),
    )
    resetActiveCells()
  }, [isAllExpanded, resetActiveCells])

  const toggleExpand = useCallback(
    (field: string) => {
      setExpandedFields((fields) => ({
        ...fields,
        [field]: !fields[field],
      }))
      resetActiveCells()
    },
    [resetActiveCells],
  )

  const headers = useMemo(
    () => (dataSummaryHeaders?.[dataSummaryKey] || []) as string[],
    [dataSummaryHeaders, dataSummaryKey],
  )

  const headersColors = useMemo(
    () => (dataSummaryHeadersColors?.[dataSummaryKey] || []) as string[],
    [dataSummaryHeadersColors, dataSummaryKey],
  )

  const headersData = useMemo(
    () => (dataSummaryHeadersData?.[dataSummaryKey] || []) as IOngoingReportingSummaryHeaderData[],
    [dataSummaryHeadersData, dataSummaryKey],
  )

  const missedColumns = useMemo(
    () => Array.from(Array(Math.max(6 - (headers.length || 0), 0)).keys()),
    [headers],
  )

  const handleReportingPeriodChange = useCallback(
    ({ target: { value } }) => {
      setCurrentReportingPeriod(value)
    },
    [setCurrentReportingPeriod],
  )

  const handleAnnuallyReportingPeriodsChange = useCallback(
    ({ target: { value } }) => {
      if (!value.length) {
        setCurrentAnnuallyReportingPeriods([AnnuallyReportingPeriods.FY])
      } else {
        setCurrentAnnuallyReportingPeriods(value)
      }
    },
    [setCurrentAnnuallyReportingPeriods],
  )

  const handleDisplayGrowthChange = useCallback(
    ({ target: { value } }) => {
      setCurrentDisplayGrowth(value)
    },
    [setCurrentDisplayGrowth],
  )

  const handleFinancialsTypesChange = useCallback(
    ({ target: { value } }) => {
      if (!value.length) {
        setCurrentFinancialsTypes([FinancialsType.Actuals])
      } else {
        setCurrentFinancialsTypes(value)
      }
    },
    [setCurrentFinancialsTypes],
  )

  const handleLineItemsChane = useCallback(({ target: { value } }) => {
    if (!value.length) {
      setCurrentLineItems([])
    } else {
      setCurrentLineItems(value)
    }
  }, [])

  const handleOngoingReportingChange = useCallback(
    ({ target: { value } }) => {
      if (!value.length && setCurrentOngoingReporting) {
        setCurrentOngoingReporting([FinancialsType.Actuals])
      } else {
        setCurrentOngoingReporting(value)
      }
    },
    [setCurrentOngoingReporting],
  )

  const handleDateRangeChange = useCallback(
    (values) => {
      setCurrentDateRange(values)
    },
    [setCurrentDateRange],
  )

  const handleIsMapping = useCallback(() => {
    setIsMappingModalShown((prev) => !prev)
  }, [setIsMappingModalShown])

  useEffect(() => {
    if (setCurrentDisplayGrowth) {
      if (
        (currentReportingPeriod !== ReportingPeriods.Monthly &&
          currentDisplayGrowth === DisplayGrowthPeriods.Month) ||
        (currentReportingPeriod !== ReportingPeriods.Quarterly &&
          currentDisplayGrowth === DisplayGrowthPeriods.Quarter)
      ) {
        setCurrentDisplayGrowth(DisplayGrowthPeriods.Hide)
      }
    }
  }, [setCurrentDisplayGrowth, currentDisplayGrowth, currentReportingPeriod])

  useEffect(() => {
    if (setCurrentDisplayGrowth) {
      if (
        !displayGrowthOptions.find(
          ({ value }) => value === DisplayGrowthPeriods.ActualsProjections,
        ) &&
        currentDisplayGrowth === DisplayGrowthPeriods.ActualsProjections
      ) {
        setCurrentDisplayGrowth(DisplayGrowthPeriods.Hide)
      }
    }
  }, [setCurrentDisplayGrowth, currentDisplayGrowth, displayGrowthOptions])

  const handleChangeDates = useCallback(
    async (dates: any) => {
      if (id) {
        await updateFinancialsDates({ id, statementType, ...dates, ...(additionalParams || {}) })
      }
    },
    [updateFinancialsDates, id, statementType, additionalParams],
  )

  const totalCell: IMultiCellTotal = useMemo(() => {
    const rowIndexesWithActiveCells = activeCells
      .map((indexes, index) => (indexes?.length ? index : null))
      .filter((index) => index !== null)

    const filteredData: { fields: FinancialKeys[]; amounts: number[] } = (
      CLIENT_FINANCIALS_FIELDS[financialStatementType] || []
    )
      .map((field: string, rowIndex: number) => ({
        field,
        rowIndex,
      }))
      .filter(({ rowIndex }: { rowIndex: number }) => rowIndexesWithActiveCells.includes(rowIndex))
      .map(({ field, rowIndex }: { field: string; rowIndex: number }) => ({
        field,
        amounts:
          dataSummary[dataSummaryKey]?.[field]?.filter((_: number, columnIndex: number) =>
            activeCells[rowIndex].includes(columnIndex),
          ) || [],
      }))
      .reduce(
        (result: { fields: string; amounts: [] }, item: { field: string; amounts: [] }) => ({
          fields: [...result.fields, item.field],
          amounts: [...result.amounts, ...item.amounts],
        }),
        { fields: [], amounts: [] },
      )
    const sum = filteredData.amounts.reduce((result: number, amount: number) => result + amount, 0)

    return {
      type: filteredData.fields.every(
        (field) =>
          CLIENT_FINANCIALS_PERCENT_FIELDS.includes(field) ||
          CLIENT_FINANCIALS_RATIO_FIELDS.includes(field),
      )
        ? 'percent'
        : 'currency',
      count: filteredData.amounts.length,
      sum,
      avg: filteredData.amounts.length ? sum / filteredData.amounts.length : 0,
    }
  }, [dataSummary, dataSummaryKey, activeCells, financialStatementType])

  const summaryColumn: Record<FinancialKeys, number> = useMemo(
    () =>
      (CLIENT_FINANCIALS_FIELDS[financialStatementType] || [])
        .filter((field: FinancialKeys) => !CLIENT_FINANCIALS_PERCENT_FIELDS.includes(field))
        .filter((field: FinancialKeys) => !CLIENT_FINANCIALS_RATIO_FIELDS.includes(field))
        .map((field: FinancialKeys) => ({
          field,
          amounts: (dataSummary[dataSummaryKey]?.[field] || [])
            .filter((_: any, index: number) => activeColumns.includes(index))
            .reduce((sum: number, amount: number) => sum + amount, 0),
        }))
        .reduce(
          (
            result: { [key: string]: number },
            { field, amounts }: { field: string; amounts: number },
          ) => ({
            ...result,
            [field]: amounts,
          }),
          {},
        ),
    [dataSummary, dataSummaryKey, activeColumns, financialStatementType],
  )

  const summaryRow = useMemo(
    () =>
      (CLIENT_FINANCIALS_FIELDS[financialStatementType] || [])
        .filter((_: string, index: number) => activeRows.includes(index))
        .filter((field: FinancialKeys) => !CLIENT_FINANCIALS_PERCENT_FIELDS.includes(field))
        .filter((field: FinancialKeys) => !CLIENT_FINANCIALS_RATIO_FIELDS.includes(field))
        .map((field: string) => ({ field, amounts: dataSummary[dataSummaryKey]?.[field] || [] }))
        .reduce((result: number[], row: { field: string; amounts: number[] }) => {
          if (!result.length) {
            headers.forEach((header, columnIndex) => {
              result[columnIndex] = 0
            })
          }
          headers.forEach((header, columnIndex) => {
            result[columnIndex] += row.amounts[columnIndex] || 0
          })

          return result
        }, []),
    [dataSummary, headers, dataSummaryKey, activeRows, financialStatementType],
  )

  const handleToggleExpandExtra = useCallback(
    () => toggleExpand('total_current_assets_above'),
    [toggleExpand],
  )

  const toggleExpandAndMinimize = useCallback(() => {
    setExpandedFields((fields) =>
      Object.keys(fields).reduce(
        (result, field) => ({
          ...result,
          [field]: isModalShown ? defaultExpandState : true,
        }),
        {},
      ),
    )

    setIsModalShown((prev) => !prev)
  }, [isModalShown, defaultExpandState, setIsModalShown])

  const onCloseModal = useCallback(() => {
    setExpandedFields((fields) =>
      Object.keys(fields).reduce(
        (result, field) => ({
          ...result,
          [field]: defaultExpandState,
        }),
        {},
      ),
    )
    setIsModalShown(false)
  }, [setIsModalShown, defaultExpandState])

  const { TabsComponent, isGraphShown } = useGraphToggle({})

  const isInitialized = useMemo(() => !!dataSummary[dataSummaryKey], [dataSummary, dataSummaryKey])
  const visualizationsCategory = useMemo(
    () =>
      isGraphShown
        ? statementType === OngoingReportingType.BalanceSheet
          ? CATEGORIES.financialsBSChart
          : CATEGORIES.financialsISChart
        : statementType === OngoingReportingType.BalanceSheet
        ? CATEGORIES.financialsBSTable
        : CATEGORIES.financialsISTable,
    [isGraphShown, statementType],
  )

  const visualizationsParams = useMemo(
    () =>
      reportingFlow === ReportingFlow.OPS
        ? {
            opsReportingId,
          }
        : {
            clientId: id,
          },
    [reportingFlow, opsReportingId, id],
  )

  const visualizationsFilters = useMemo(
    () => ({
      reportingPeriod: currentReportingPeriod,
      annuallyReportingPeriods: currentAnnuallyReportingPeriods,
      displayGrowth: currentDisplayGrowth,
      financialsTypes: currentFinancialsTypes,
      ongoingReporting: currentOngoingReporting,
      startDate: startDate,
      endDate: endDate,
    }),
    [
      currentReportingPeriod,
      currentAnnuallyReportingPeriods,
      currentDisplayGrowth,
      currentFinancialsTypes,
      currentOngoingReporting,
      startDate,
      endDate,
    ],
  )

  useTrackVisualizationsTableChartSelection({
    isInitialized,
    category: visualizationsCategory,
    params: visualizationsParams,
    isChart: isGraphShown,
  })
  useTrackVisualizationsTable({
    isInitialized,
    category: visualizationsCategory,
    params: visualizationsParams,
    filters: visualizationsFilters,
  })

  const currentQuickFilter = useMemo(() => {
    if (
      currentReportingPeriod === ReportingPeriods.Annually &&
      currentAnnuallyReportingPeriods.length === 2 &&
      (currentFinancialsTypes?.[0] === FinancialsType.Actuals ||
        currentOngoingReporting?.[0] === FinancialsType.Actuals)
    ) {
      if (
        currentDisplayGrowth === DisplayGrowthPeriods.ActualsProjections &&
        (currentFinancialsTypes?.length === 2 || currentOngoingReporting?.length === 2)
      ) {
        return QuickFilter.ActualsVsProjections
      } else if (
        currentDisplayGrowth === DisplayGrowthPeriods.Year &&
        (currentOngoingReporting?.length === 1 || currentFinancialsTypes?.length === 1)
      ) {
        return QuickFilter.YearOverYear
      }
    }
    return null
  }, [
    currentFinancialsTypes,
    currentDisplayGrowth,
    currentReportingPeriod,
    currentAnnuallyReportingPeriods,
    currentOngoingReporting,
  ])

  const resetDefaultFields = useCallback(() => {
    setCurrentFinancialsTypes && setCurrentFinancialsTypes([FinancialsType.Actuals])
    setCurrentOngoingReporting && setCurrentOngoingReporting([FinancialsType.Actuals])
    setCurrentDisplayGrowth(DisplayGrowthPeriods.Hide)
    setCurrentReportingPeriod(ReportingPeriods.Monthly)
    handleDateRangeChange({
      startDate: '',
      endDate: '',
    })
  }, [
    setCurrentFinancialsTypes,
    setCurrentDisplayGrowth,
    setCurrentReportingPeriod,
    setCurrentOngoingReporting,
    handleDateRangeChange,
  ])

  const handleYoYFilterClick = useCallback(() => {
    if (currentQuickFilter === QuickFilter.YearOverYear) {
      resetDefaultFields()
    } else {
      setCurrentReportingPeriod(ReportingPeriods.Annually)
      setCurrentFinancialsTypes && setCurrentFinancialsTypes([FinancialsType.Actuals])
      setCurrentOngoingReporting && setCurrentOngoingReporting([FinancialsType.Actuals])
      setCurrentDisplayGrowth(DisplayGrowthPeriods.Year)
      setCurrentAnnuallyReportingPeriods([
        AnnuallyReportingPeriods.FY,
        AnnuallyReportingPeriods.YTD,
      ])
      handleDateRangeChange({
        startDate: twoYearsAgo,
        endDate: today,
      })
    }
  }, [
    setCurrentReportingPeriod,
    setCurrentDisplayGrowth,
    setCurrentAnnuallyReportingPeriods,
    currentQuickFilter,
    resetDefaultFields,
    setCurrentFinancialsTypes,
    setCurrentOngoingReporting,
    handleDateRangeChange,
  ])

  const handleActualsProjectionsFilterClick = useCallback(() => {
    if (currentQuickFilter === QuickFilter.ActualsVsProjections) {
      resetDefaultFields()
    } else {
      setCurrentFinancialsTypes &&
        setCurrentFinancialsTypes([FinancialsType.Actuals, FinancialsType.Projections])
      setCurrentOngoingReporting &&
        setCurrentOngoingReporting(financialTypeOptions?.map(({ value }) => value).slice(0, 2))
      setCurrentReportingPeriod(ReportingPeriods.Annually)
      setCurrentDisplayGrowth(DisplayGrowthPeriods.ActualsProjections)
      setCurrentAnnuallyReportingPeriods([
        AnnuallyReportingPeriods.FY,
        AnnuallyReportingPeriods.YTD,
      ])
      handleDateRangeChange({
        startDate: twoYearsAgo,
        endDate: '',
      })
    }
  }, [
    setCurrentFinancialsTypes,
    setCurrentDisplayGrowth,
    currentQuickFilter,
    resetDefaultFields,
    setCurrentReportingPeriod,
    setCurrentAnnuallyReportingPeriods,
    setCurrentOngoingReporting,
    financialTypeOptions,
    handleDateRangeChange,
  ])

  const isDataEmpty = useMemo(() => {
    return !isLoading && !isRefresh && !headers.length
  }, [isLoading, isRefresh, headers])

  return (
    <FullscreenModal
      isOpen={isModalShown}
      setIsOpen={setIsModalShown}
      classes={{ body: styles.fullScreenModal }}
      onCloseCustom={onCloseModal}
    >
      <Card
        ref={wrapperRef}
        title={
          <Box display="flex" justifyContent="space-between" alignItems="center">
            <Box display="flex" alignItems="center">
              <Box display="flex" flexDirection={'column'} gap={1}>
                <Box display="flex" alignItems="center" gap={1}>
                  {title ? title : `Consolidated ${ONGOING_REPORTING_TYPE_LABEL[statementType]}`}{' '}
                  {dates
                    ? `from ${formatDateMonthYear(dates.startDate)} to ${formatDateMonthYear(
                        dates.endDate,
                      )}`
                    : ''}
                  {dates && showEditFinancialsDate && (
                    <FinancialEditDatesModal
                      startDate={dates.startDate}
                      endDate={dates.endDate}
                      handleSave={handleChangeDates}
                    />
                  )}
                  {typeof editLink === 'string' && (
                    <Link component={RouterLink} to={editLink} className={styles.editLink}>
                      Edit
                    </Link>
                  )}
                  {typeof editLink === 'function' && editLink ? (
                    <span onClick={editLink} className={styles.editLink}>
                      Edit
                    </span>
                  ) : null}
                </Box>
                {isSummary && !isDD && (
                  <span className={styles.quickFilters}>
                    Quick filters
                    <span
                      className={cn(genericSs.grayCard, styles.quickFilterButton, {
                        [styles.quickFilterButtonActive]:
                          currentQuickFilter === QuickFilter.ActualsVsProjections,
                      })}
                      onClick={handleActualsProjectionsFilterClick}
                    >
                      {QuickFilter.ActualsVsProjections}
                    </span>
                    <span
                      className={cn(genericSs.grayCard, styles.quickFilterButton, {
                        [styles.quickFilterButtonActive]:
                          currentQuickFilter === QuickFilter.YearOverYear,
                      })}
                      onClick={handleYoYFilterClick}
                    >
                      {QuickFilter.YearOverYear}
                    </span>
                  </span>
                )}
              </Box>
            </Box>
            <Grid
              item
              xs={dates ? 5 : 9}
              container
              justifyContent="flex-end"
              columnSpacing={1}
              spacing={2}
            >
              {handleOpenSelectFile && (
                <Grid item>
                  <EditSourceData
                    action={handleOpenSelectFile}
                    error={
                      fileSheets
                        ? !!fileSheets?.filter(({ file }) => file && file.error).length
                        : !!selectedFile?.error
                    }
                  />
                </Grid>
              )}
              <Grid item>
                {selectedFile?.link && (
                  <ExternalLink
                    link={selectedFile?.link}
                    title={ONGOING_REPORTING_TYPE_LABEL[statementType]}
                  />
                )}
              </Grid>
              <Grid item>
                {setIsMappingModalShown && reportingFlow !== ReportingFlow.DueDiligence && (
                  <EditMapping
                    mappingRequired={isMappingRequired}
                    mappingDisabled={isMappingDisabled}
                    action={handleIsMapping}
                  />
                )}
              </Grid>
              {isSummary && setCurrentDisplayGrowth && !isGraphShown && (
                <Grid item>
                  <SelectField
                    key={currentDisplayGrowth}
                    label="Display Growth"
                    variant="outlined"
                    useFinalForm={false}
                    name="displayGrowth"
                    options={displayGrowthOptions}
                    onChange={handleDisplayGrowthChange}
                    value={currentDisplayGrowth}
                    defaultValue=""
                    className={cn(styles.selectField, styles.selectFieldDisplayGrowth)}
                  />
                </Grid>
              )}
              {isSummary && isGraphShown && (
                <Grid item>
                  <SelectField
                    label="Line Items"
                    variant="outlined"
                    useFinalForm={false}
                    name="lineItems"
                    options={lineItems}
                    onChange={handleLineItemsChane}
                    value={currentLineItems}
                    defaultValue=""
                    className={styles.lineItemsField}
                    multiple
                    maxMultipleValues={5}
                  />
                </Grid>
              )}
              {isSummary && !isDD && setCurrentFinancialsTypes && (
                <Grid item>
                  <SelectField
                    label="Statement Type"
                    variant="outlined"
                    useFinalForm={false}
                    name="financialsType"
                    options={financialsTypeOptions}
                    onChange={handleFinancialsTypesChange}
                    value={currentFinancialsTypes}
                    defaultValue=""
                    className={styles.selectField}
                    multiple
                  />
                </Grid>
              )}
              {isSummary && setCurrentOngoingReporting && (
                <Grid item>
                  <SelectField
                    label="Statement Type"
                    variant="outlined"
                    useFinalForm={false}
                    name="ongoingReporting"
                    options={financialTypeOptions}
                    onChange={handleOngoingReportingChange}
                    value={currentOngoingReporting}
                    className={styles.selectField}
                    multiple
                  />
                </Grid>
              )}
              <Grid item>
                <SelectField
                  key={currentReportingPeriod}
                  label="Period"
                  variant="outlined"
                  useFinalForm={false}
                  name="reportingPeriod"
                  options={REPORTING_PERIOD_OPTIONS}
                  onChange={handleReportingPeriodChange}
                  value={currentReportingPeriod}
                  defaultValue=""
                  className={styles.selectField}
                />
              </Grid>
              {currentReportingPeriod === ReportingPeriods.Annually &&
                setCurrentAnnuallyReportingPeriods && (
                  <Grid item>
                    <SelectField
                      label="YTD/FY"
                      variant="outlined"
                      useFinalForm={false}
                      name="annuallyReportingPeriod"
                      options={ANNUALLY_REPORTING_PERIOD_OPTIONS}
                      onChange={handleAnnuallyReportingPeriodsChange}
                      value={currentAnnuallyReportingPeriods}
                      className={styles.selectField}
                      multiple
                    />
                  </Grid>
                )}
              {isSummary && setCurrentDateRange && (
                <DatePicker
                  reportingPeriod={currentReportingPeriod}
                  currentDateRange={currentDateRange}
                  datesBoundary={datesBoundary}
                  onChange={handleDateRangeChange}
                />
              )}
              <Grid item>{TabsComponent}</Grid>
              <Grid item>
                <ExpandAndMinimize action={toggleExpandAndMinimize} isExpanded={isModalShown} />
              </Grid>
            </Grid>
          </Box>
        }
        withBorder={cardWithBorder}
        withHeaderBorder={!cardWithBorder}
        classes={{
          content: cn({
            [styles.contentWithBorder]: !cardWithBorder,
          }),
        }}
      >
        {isDataEmpty ? (
          <Empty
            message={`No ${ONGOING_REPORTING_TYPE_LABEL[
              statementType
            ]?.toLowerCase()} exists for this client`}
            height="40vh"
          />
        ) : !isGraphShown ? (
          <TableContainer
            className={cn(styles.table, { [styles.tableCellAutoWidth]: headers.length <= 6 })}
            scrollLeft
            id={`ongoingReportingSummary${statementType}`}
            coupledTables={coupledTables}
          >
            <Table>
              <TableHead className={cn(styles.tableHead)}>
                <TableRow>
                  <TableCell>
                    <ShowAndHide
                      isExpanded={isAllExpanded}
                      action={toggleExpandAll}
                      className={styles.expandIcon}
                    />
                    {financialStatementType === OngoingReportingType.BalanceSheet && (
                      <ShowAndHide
                        isExpanded={expandedFields.total_current_assets_above}
                        action={handleToggleExpandExtra}
                        className={cn(styles.expandIcon, styles.expandIconCustom)}
                      />
                    )}
                  </TableCell>
                  <TableCell>
                    <div className={styles.accountHeader}>Account</div>
                  </TableCell>
                  {isRefresh
                    ? headers.map((header) => (
                        <TableCell key={header} className={genericSs.tableTextRight}>
                          <Skeleton className={styles.loader} />
                        </TableCell>
                      ))
                    : headers.map((header, index) => (
                        <TableCell
                          key={header}
                          className={cn(genericSs.tableTextRight, 'activableColumn', {
                            activeCell: activeColumns.includes(index),
                          })}
                          data-index={index}
                          onClick={(event) => handleSelectColumn(event, index)}
                          style={{
                            backgroundColor: headersColors?.[index] || undefined,
                          }}
                        >
                          {statementType === OngoingReportingType.IncomeStatement &&
                          currentReportingPeriod !== ReportingPeriods.Monthly ? (
                            <OngoingReportingSummaryHeaderTooltip
                              header={header}
                              data={headersData[index]}
                            />
                          ) : (
                            <div className={styles.accountHeader}>{header}</div>
                          )}
                        </TableCell>
                      ))}
                  {!isRefresh && missedColumns.map((index) => <TableCell key={index} />)}
                  {activeColumns.length > 1 && (
                    <TableCell className={cn(genericSs.tableTextLeft, 'summaryColumn')}>
                      {activeColumns.length} columns selected
                    </TableCell>
                  )}
                </TableRow>
              </TableHead>
              <TableBody>
                {isLoading || isRefresh ? (
                  <TableLoader
                    columnsCount={(headers.length || 6) + 2}
                    rowsCount={CLIENT_FINANCIALS_FIELDS[financialStatementType]?.length}
                  />
                ) : (
                  dataSummary[dataSummaryKey] && (
                    <>
                      {CLIENT_FINANCIALS_FIELDS[financialStatementType].map(
                        (field: FinancialKeys, rowIndex: number) => (
                          <OngoingReportingSummaryRow
                            key={field}
                            field={field}
                            dataSummaryKey={dataSummaryKey}
                            activeCells={activeCells}
                            activeRows={activeRows}
                            activeColumns={activeColumns}
                            rowIndex={rowIndex}
                            onSelectRow={handleSelectRow}
                            onSelectCell={handleSelectCell}
                            expandedFields={expandedFields}
                            toggleExpand={toggleExpand}
                            missedColumns={missedColumns}
                            dataSummary={dataSummary}
                            dataSummaryGrowth={dataSummaryGrowth}
                            dataSummaryHeaders={headers}
                            totalCell={totalCell}
                            summaryColumn={summaryColumn[field]}
                          />
                        ),
                      )}
                    </>
                  )
                )}
                {activeRows.length > 1 && (
                  <TableRow className="summaryRow">
                    <TableCell />
                    <TableCell className={genericSs.tableTextLeft}>
                      {activeRows.length} rows selected
                    </TableCell>
                    {dataSummary[dataSummaryKey] &&
                      headers.map((header, index) => (
                        <TableCell key={header} className={genericSs.tableTextRight}>
                          <div className={styles.amountHolder}>
                            <span>$</span>
                            {formatPriceConditionalDecimal(summaryRow[index])}
                          </div>
                        </TableCell>
                      ))}
                    {missedColumns.map((index) => (
                      <TableCell key={index} />
                    ))}
                    {activeColumns.length > 1 && (
                      <TableCell className={cn(genericSs.tableTextLeft, 'summaryColumn')} />
                    )}
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        ) : (
          <FinancialTrendsGraph
            financialData={dataSummary}
            dataSummaryHeaders={dataSummaryHeaders}
            dataSummaryKey={dataSummaryKey}
            fieldsToDisplay={currentLineItems}
            fullScreen={isModalShown}
            reportingPeriod={currentReportingPeriod}
          />
        )}
      </Card>
    </FullscreenModal>
  )
}

export default OngoingReportingSummary
