import React, { useCallback, useMemo, useState } from 'react'
import Box from '@mui/material/Box'
import Tooltip from '@mui/material/Tooltip'

import styles from './DueDiligenceTable.module.scss'

import {
  DueDiligenceTableItemType,
  IDueDiligenceBankAccount,
  IDueDiligenceBoardMember,
  IDueDiligenceContact,
  IDueDiligenceExecutive,
  IDueDiligenceInventoryLocation,
  IDueDiligenceReference,
  IDueDiligenceTableItem,
} from '@common/interfaces/dueDiligence'
import Modal from '../Common/Modal'
import Button from '../Common/Button'
import { VisibilityIcon } from '../Common/Icons'
import { formatPrice, formatTextDate } from '../../helpers/helpers'

const DueDiligenceViewContact = ({ contact }: { contact: IDueDiligenceContact }) => {
  return (
    <form className={styles.formWrapper}>
      <div className={styles.profileContent}>
        <Box>
          <div className={styles.profileLabel}>First name</div>
          <div className={styles.profileValue}>{contact.firstName || '-'}</div>
        </Box>

        <Box>
          <div className={styles.profileLabel}>Last name</div>
          <div className={styles.profileValue}>{contact.lastName || '-'}</div>
        </Box>

        <Box>
          <div className={styles.profileLabel}>Phone number</div>
          <div className={styles.profileValue}>{contact.phone || '-'}</div>
        </Box>

        <Box>
          <div className={styles.profileLabel}>Email</div>
          <div className={styles.profileValue}>
            <Tooltip title={contact.email} placement="top" disableTouchListener>
              <span>{contact.email}</span>
            </Tooltip>
          </div>
        </Box>

        <Box className={styles.fullWidthRow}>
          <div className={styles.profileLabel}>
            Is this person responsible for requesting funding?
          </div>

          <div className={styles.profileValue}>
            {contact.isResponsibleRequestingFunding
              ? 'Yes'
              : contact.isResponsibleRequestingFunding === false
              ? 'No'
              : '-'}
          </div>
        </Box>

        <Box className={styles.fullWidthRow}>
          <div className={styles.profileLabel}>Is this person authorized to sign for funding?</div>

          <div className={styles.profileValue}>
            {contact.isAuthorizedSignature
              ? 'Yes'
              : contact.isAuthorizedSignature === false
              ? 'No'
              : '-'}
          </div>
        </Box>
      </div>
    </form>
  )
}

const DueDiligenceViewExecutive = ({ executive }: { executive: IDueDiligenceExecutive }) => {
  const [isSocialSecurityNumberVisible, setIsSocialSecurityNumberVisible] = useState(false)
  const toggleSocialSecurityNumberVisibility = useCallback(() => {
    setIsSocialSecurityNumberVisible((visible) => !visible)
  }, [])

  return (
    <form className={styles.formWrapper}>
      <div className={styles.profileContent}>
        <Box>
          <div className={styles.profileLabel}>First name</div>
          <div className={styles.profileValue}>{executive.firstName || '-'}</div>
        </Box>

        <Box>
          <div className={styles.profileLabel}>Last name</div>
          <div className={styles.profileValue}>{executive.lastName || '-'}</div>
        </Box>

        <Box>
          <div className={styles.profileLabel}>Title</div>
          <div className={styles.profileValue}>{executive.title || '-'}</div>
        </Box>

        <Box>
          <div className={styles.profileLabel}>Phone number</div>
          <div className={styles.profileValue}>{executive.phone || '-'}</div>
        </Box>

        <Box>
          <div className={styles.profileLabel}>Email</div>
          <div className={styles.profileValue}>
            <Tooltip title={executive.email} placement="top" disableTouchListener>
              <span>{executive.email || '-'}</span>
            </Tooltip>
          </div>
        </Box>

        <Box>
          <div className={styles.profileLabel}>Social security number</div>
          <div className={styles.socialSecurityNumberWrapper}>
            <div className={styles.profileValue}>
              {isSocialSecurityNumberVisible
                ? (executive.socialSecurityNumber || '').replace(
                    /(\d{3})(\d{2})(.*)/,
                    '$1-$2-$3',
                  ) || '-'
                : '*********'}
            </div>
            <VisibilityIcon
              isVisible={isSocialSecurityNumberVisible}
              action={toggleSocialSecurityNumberVisibility}
            />
          </div>
        </Box>

        <Box>
          <div className={styles.profileLabel}>Date of birth</div>
          <div className={styles.profileValue}>
            {executive.birthday ? formatTextDate(executive.birthday) : '-'}
          </div>
        </Box>

        <Box>
          <div className={styles.profileLabel}>Home address (Street, City, State, Zip)</div>
          <div className={styles.profileValue}>
            {[
              executive.street,
              executive.city,
              executive.state,
              executive.postalCode,
              executive.country,
            ]
              .filter(Boolean)
              .join(', ')}
          </div>
        </Box>

        <Box className={styles.fullWidthRow}>
          <div className={styles.profileLabel}>
            Has this individual ever been convicted of a felony?
          </div>

          <div className={styles.profileValue}>
            {executive.isConvictedFelony
              ? 'Yes'
              : executive.isConvictedFelony === false
              ? 'No'
              : '-'}
          </div>
        </Box>

        <Box className={styles.fullWidthRow}>
          <div className={styles.profileLabel}>
            Has this individual ever defaulted on any financial obligation?
          </div>

          <div className={styles.profileValue}>
            {executive.isDefaultedFinancialObligation
              ? 'Yes'
              : executive.isDefaultedFinancialObligation === false
              ? 'No'
              : '-'}
          </div>
        </Box>

        <Box className={styles.fullWidthRow}>
          <div className={styles.profileLabel}>
            Has this individual ever filed for personal bankruptcy?
          </div>

          <div className={styles.profileValue}>
            {executive.isPersonalBankruptcy
              ? 'Yes'
              : executive.isPersonalBankruptcy === false
              ? 'No'
              : '-'}
          </div>
        </Box>
      </div>
    </form>
  )
}

const DueDiligenceViewBoardMember = ({
  boardMember,
}: {
  boardMember: IDueDiligenceBoardMember
}) => {
  return (
    <form className={styles.formWrapper}>
      <div className={styles.profileContent}>
        <Box>
          <div className={styles.profileLabel}>First name</div>
          <div className={styles.profileValue}>{boardMember.firstName || '-'}</div>
        </Box>

        <Box>
          <div className={styles.profileLabel}>Last name</div>
          <div className={styles.profileValue}>{boardMember.lastName || '-'}</div>
        </Box>

        <Box>
          <div className={styles.profileLabel}>Company</div>
          <div className={styles.profileValue}>{boardMember.company || '-'}</div>
        </Box>

        <Box>
          <div className={styles.profileLabel}>Relationship to company</div>
          <div className={styles.profileValue}>{boardMember.relationshipToCompany || '-'}</div>
        </Box>
      </div>
    </form>
  )
}

const DueDiligenceViewReference = ({ reference }: { reference: IDueDiligenceReference }) => {
  return (
    <form className={styles.formWrapper}>
      <div className={styles.profileContent}>
        <Box>
          <div className={styles.profileLabel}>First name</div>
          <div className={styles.profileValue}>{reference.firstName || '-'}</div>
        </Box>

        <Box>
          <div className={styles.profileLabel}>Last name</div>
          <div className={styles.profileValue}>{reference.lastName || '-'}</div>
        </Box>

        <Box>
          <div className={styles.profileLabel}>Phone number</div>
          <div className={styles.profileValue}>{reference.phone || '-'}</div>
        </Box>

        <Box>
          <div className={styles.profileLabel}>Email</div>
          <div className={styles.profileValue}>
            <Tooltip title={reference.email} placement="top" disableTouchListener>
              <span>{reference.email}</span>
            </Tooltip>
          </div>
        </Box>

        <Box>
          <div className={styles.profileLabel}>Business name</div>
          <div className={styles.profileValue}>{reference.businessName || '-'}</div>
        </Box>

        <Box>
          <div className={styles.profileLabel}>Relationship to company</div>
          <div className={styles.profileValue}>{reference.relationshipToCompany || '-'}</div>
        </Box>
      </div>
    </form>
  )
}

const DueDiligenceViewInventoryLocation = ({
  inventoryLocation,
}: {
  inventoryLocation: IDueDiligenceInventoryLocation
}) => {
  return (
    <form className={styles.formWrapper}>
      <div className={styles.profileContent}>
        <Box>
          <div className={styles.profileLabel}>Name</div>
          <div className={styles.profileValue}>{inventoryLocation.name || '-'}</div>
        </Box>

        <Box>
          <div className={styles.profileLabel}>Location type</div>
          <div className={styles.profileValue}>{inventoryLocation.type || '-'}</div>
        </Box>

        <Box>
          <div className={styles.profileLabel}>Landlord</div>
          <div className={styles.profileValue}>{inventoryLocation.landlord || '-'}</div>
        </Box>

        <Box>
          <div className={styles.profileLabel}>Finished goods value</div>
          <div className={styles.profileValue}>
            ${formatPrice(inventoryLocation.finishedGoodsAmount)}
          </div>
        </Box>

        <Box>
          <div className={styles.profileLabel}>Total Inventory value</div>
          <div className={styles.profileValue}>
            ${formatPrice(inventoryLocation.totalInventoryAmount)}
          </div>
        </Box>

        <Box>
          <div className={styles.profileLabel}>Inventory location address</div>
          <div className={styles.profileValue}>
            {[
              inventoryLocation.street,
              inventoryLocation.city,
              inventoryLocation.state,
              inventoryLocation.postalCode,
              inventoryLocation.country,
            ]
              .filter(Boolean)
              .join(', ')}
          </div>
        </Box>
      </div>
    </form>
  )
}

const DueDiligenceViewBankAccount = ({
  bankAccount,
}: {
  bankAccount: IDueDiligenceBankAccount
}) => {
  return (
    <form className={styles.formWrapper}>
      <div className={styles.profileContent}>
        <Box>
          <div className={styles.profileLabel}>Bank name</div>
          <div className={styles.profileValue}>{bankAccount.bankName || '-'}</div>
        </Box>

        <Box>
          <div className={styles.profileLabel}>Bank account #</div>
          <div className={styles.profileValue}>
            <Tooltip
              title={bankAccount.bankAccountNumber}
              arrow
              placement="top"
              classes={{
                tooltip: styles.bankAccountNumberTooltip,
                arrow: styles.bankAccountNumberTooltipArrow,
              }}
              leaveDelay={1000}
            >
              <span>{`XXXXX${bankAccount.bankAccountNumber.slice(-4)}`}</span>
            </Tooltip>
          </div>
        </Box>

        <Box>
          <div className={styles.profileLabel}>Wire Routing #</div>
          <div className={styles.profileValue}>
            <div className={styles.profileValue}>{bankAccount.abaRoutingNumber || '-'}</div>
          </div>
        </Box>

        <Box>
          <div className={styles.profileLabel}>Purpose</div>
          <div className={styles.profileValue}>{bankAccount.purpose || '-'}</div>
        </Box>
      </div>
    </form>
  )
}

const LABEL = {
  [DueDiligenceTableItemType.Contact]: 'Contact profile',
  [DueDiligenceTableItemType.Executive]: 'Executive profile',
  [DueDiligenceTableItemType.BoardMember]: 'Board member profile',
  [DueDiligenceTableItemType.Reference]: 'Reference profile',
  [DueDiligenceTableItemType.InventoryLocation]: 'Inventory details',
  [DueDiligenceTableItemType.BankAccount]: 'Bank account details',
}

interface IProps {
  type: DueDiligenceTableItemType
  item: IDueDiligenceTableItem
  handleEditItem: (item: IDueDiligenceTableItem) => void
  handleDeleteItem: (item: IDueDiligenceTableItem) => void
  onCloseModal: () => void
}

const DueDiligenceSaveTeamMemberModal = ({
  type,
  item,
  handleEditItem,
  handleDeleteItem,
  onCloseModal,
}: IProps) => {
  const handleEdit = useCallback(() => {
    handleEditItem(item)
  }, [item, handleEditItem])

  const handleDelete = useCallback(() => {
    handleDeleteItem(item)
  }, [item, handleDeleteItem])

  const title = useMemo(() => LABEL[type], [type])

  return (
    <Modal title={title} open disableEnforceFocus onCancel={onCloseModal}>
      {type === DueDiligenceTableItemType.Contact && (
        <DueDiligenceViewContact contact={item as IDueDiligenceContact} />
      )}

      {type === DueDiligenceTableItemType.Executive && (
        <DueDiligenceViewExecutive executive={item as IDueDiligenceExecutive} />
      )}

      {type === DueDiligenceTableItemType.BoardMember && (
        <DueDiligenceViewBoardMember boardMember={item as IDueDiligenceBoardMember} />
      )}

      {type === DueDiligenceTableItemType.Reference && (
        <DueDiligenceViewReference reference={item as IDueDiligenceReference} />
      )}

      {type === DueDiligenceTableItemType.InventoryLocation && (
        <DueDiligenceViewInventoryLocation
          inventoryLocation={item as IDueDiligenceInventoryLocation}
        />
      )}

      {type === DueDiligenceTableItemType.BankAccount && (
        <DueDiligenceViewBankAccount bankAccount={item as IDueDiligenceBankAccount} />
      )}

      <div className={styles.modalButtonsWrapper}>
        <Button small={false} variant="outlined" onClick={handleEdit}>
          Edit
        </Button>

        <Button small={false} error variant="contained" onClick={handleDelete}>
          Delete
        </Button>
      </div>
    </Modal>
  )
}

export default DueDiligenceSaveTeamMemberModal
