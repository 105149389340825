import React, { useMemo } from 'react'
import styles from './ReservesTable.module.scss'
import Button from '../../components/Common/Button'
import Modal from '../Common/Modal'
import { ReserveKind } from '@common/interfaces/bbc'
import { CATEGORIES_OPTIONS, FIELDS_OPTIONS, TYPES_OPTIONS } from './constants'
import { CollateralTypes, OVERPAYABLE_INTERVAL_VALUES } from '@common/constants/client'

import Radios from '../Common/Radios'
import CurrencyField from '../Common/CurrencyField'
import TextField from '../../components/Common/TextField'
import SelectField from '../Common/SelectField'
import { InputAdornment } from '@mui/material'
import Autocomplete from '../Common/Autocomplete'

import { Form } from 'react-final-form'
import arrayMutators from 'final-form-arrays'

const mutators = {
  ...arrayMutators,
}

interface IProps {
  adjustmentType: string
  type: CollateralTypes
  item: any
  handleSubmit: (data: any) => void
  isModalShowing: boolean
  setIsModalShowing: (isModalShowing: boolean) => void
  creditors?: { label: string; value: string }[]
  extraReserveList?: { extraReserves: any[] }
  isSaving?: boolean
}

const AdjustmentsModal = ({
  adjustmentType,
  type,
  item,
  handleSubmit,
  isModalShowing,
  setIsModalShowing,
  creditors,
  extraReserveList,
  isSaving = false,
}: IProps) => {
  const name = useMemo(() => {
    if (type === CollateralTypes.inventory) {
      return 'Inv.'
    }
    return type
  }, [type])

  if ([CollateralTypes.ar, CollateralTypes.inventory].includes(type)) {
    return (
      <Form
        initialValues={item}
        onSubmit={handleSubmit}
        mutators={mutators}
        render={({ values, handleSubmit }) => (
          <form>
            <Modal
              open={isModalShowing}
              onCancel={() => setIsModalShowing(false)}
              title={`${name} reserves & adjustments`}
              size="small"
              footer={[
                <Button
                  isLoading={isSaving}
                  className={styles.saveButton}
                  small={false}
                  color="primary"
                  variant="contained"
                  onClick={(data) => handleSubmit(data)}
                >
                  {adjustmentType === 'edit' ? 'Save' : 'Add'}
                </Button>,
              ]}
            >
              <div className={styles.adjustmentTitle}> Type </div>
              <div className={styles.adjustmentRadio}>
                <Radios name="kind" data={TYPES_OPTIONS} />
              </div>
              {values.kind === ReserveKind.Absolute && (
                <>
                  <div className={styles.adjustmentTitle}> Amount </div>
                  <div className={styles.adjustmentAmt}>
                    <CurrencyField name={`amount`} placeholder="Amount" size="large" />
                  </div>
                </>
              )}
              {values.kind === ReserveKind.Percent && (
                <>
                  <div className={styles.adjustmentTitle}> Amount </div>
                  <div className={styles.adjustmentAmt}>
                    {' '}
                    <TextField
                      name={`percent`}
                      placeholder="Percent"
                      size="large"
                      InputProps={{
                        endAdornment: <InputAdornment position="end"> %</InputAdornment>,
                      }}
                    />
                  </div>

                  <div className={styles.adjustmentTitle}> Of </div>
                  <div className={styles.adjustmentRadio}>
                    <Radios name={`field`} data={FIELDS_OPTIONS[type]} />
                  </div>
                </>
              )}
              <div className={styles.adjustmentTitle}> Category </div>
              <div>
                <SelectField
                  selectSize="large"
                  name={`category`}
                  options={CATEGORIES_OPTIONS[type]}
                  placeholder="Select category"
                />
              </div>
              <div className={styles.adjustmentTitle}> Reason </div>
              <TextField size="large" key="reason" name={`reason`} placeholder="Enter reason" />
            </Modal>
          </form>
        )}
      />
    )
  }

  if ([CollateralTypes.ap].includes(type)) {
    return (
      <Form
        onSubmit={handleSubmit}
        mutators={mutators}
        initialValues={item}
        render={({ values, handleSubmit }) => (
          <form>
            <Modal
              open={isModalShowing}
              onCancel={() => setIsModalShowing(false)}
              title={`${type} reserves & adjustments`}
              size="small"
              footer={[
                <Button
                  isLoading={isSaving}
                  className={styles.saveButton}
                  small={false}
                  color="primary"
                  variant="contained"
                  onClick={(data) => handleSubmit(data)}
                >
                  {adjustmentType === 'edit' ? 'Save' : 'Add'}
                </Button>,
              ]}
            >
              {adjustmentType !== 'edit' && (
                <>
                  <div className={styles.adjustmentTitle}> Vendor </div>
                  <div className={styles.categorySelect}>
                    <Autocomplete
                      label=""
                      key={extraReserveList.extraReserves.length}
                      name={`creditor`}
                      options={creditors}
                      getOptionValue={(option) => option.value}
                      style={{ width: '100%' }}
                      clearIcon={null}
                      size="small"
                      autocompleteSize="large"
                    />
                  </div>
                </>
              )}
              <div className={styles.adjustmentTitle}> Priority payables threshold </div>
              <div>
                <SelectField
                  name={`priorityPayable`}
                  options={OVERPAYABLE_INTERVAL_VALUES}
                  align="left"
                  selectSize="large"
                />
              </div>

              <div className={styles.adjustmentTitle}> Notes </div>
              <TextField name={`notes`} size="large" placeholder="Notes" />
            </Modal>
          </form>
        )}
      />
    )
  } else {
    return null
  }
}

export default AdjustmentsModal
