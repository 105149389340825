import React from 'react'
import Box from '@mui/material/Box'
import LinearProgress from '@mui/material/LinearProgress'
import cn from 'classnames'

import styles from './MappingProgress.module.scss'

interface IProps {
  value: number
  className?: string
}

const MappingProgress = ({ value, className }: IProps) => {
  return (
    <Box className={cn(styles.wrapper, className)}>
      <span className={styles.label}>Mapping Progress</span>
      <LinearProgress className={styles.progress} variant="determinate" value={value} />
      <span className={styles.percent}>{value.toFixed(2)}%</span>
    </Box>
  )
}

export default MappingProgress
