import React, { useEffect, useMemo, useCallback, useState, useRef } from 'react'
import { useLocation, useParams, useHistory } from 'react-router'
import InfiniteScroll from 'react-infinite-scroll-component'
import Box from '@mui/material/Box'
import CircularProgress from '@mui/material/CircularProgress'
import Grid from '@mui/material/Grid'
import Popover from '@mui/material/Popover'
import MenuItem from '@mui/material/MenuItem'
import { Form } from 'react-final-form'
import queryString from 'query-string'
import cn from 'classnames'

import styles from './NotesContainer.module.scss'

import { ReactComponent as EmptyIcon } from '@assets/images/no-notes-icon.svg'
import { ReactComponent as SortIcon } from '@assets/images/sort-icon.svg'
import Card from '../Common/Card/Card'
import AddNote from './AddNote'
import Note from './Note'
import {
  INote,
  INotesData,
  Pages,
  WorkflowTypes,
  DD_NOTES_FILTER_OPTIONS,
  NotesFilterOptions,
} from '@common/interfaces/notes'
import Loader from '../Loader'
import { buildFiltersDefaults } from '../../helpers/filters'
import {
  NOTES_LIST_FILTERS_CONFIG,
  NOTES_LIST_PER_PAGE,
  NOTES_LIST_SORT_OPTIONS,
} from '@common/constants/filters'
import { currentWorkFlow, debounceEventHandler } from '../../helpers/helpers'
import { ExpandAndMinimize, RegenerateIcon, SummarizeButton } from '../Common/Icons'
import Modal from '../Common/Modal/Modal'
import NoteExpandModal from './NoteExpandModal'
import NotesFullScreenModal from '../Notes/NotesFullScreenModal'
import FilterContainer from '../Filters/FilterContainer'

interface IProps {
  isNotesShown: boolean
  listNotes: (id: string, data: object) => void
  notesData: INotesData
  isLoading: boolean
  hideNotes: () => void
  workflow: string
  summarizeNotes: (id: string, data: object) => any
  summary: string
  isBDO: boolean
}

const NotesContainer = ({
  listNotes,
  notesData,
  isLoading,
  hideNotes,
  summarizeNotes,
  summary,
  isBDO,
}: IProps) => {
  const { id, clientId } = useParams<{ id: string; clientId: string }>()
  const { pathname, search }: { search: string; pathname: string } = useLocation()
  const workflow = useMemo(() => currentWorkFlow(pathname), [pathname])
  const [isSummaryOpen, setIsSummaryOpen] = useState(false)
  const [isExpandNewNoteModalOpen, setIsExpandNewNoteModalOpen] = useState(false)
  const [isSummarizeLoading, setIsSummarizeLoading] = useState(false)
  const totalCount = useMemo(() => notesData?.total, [notesData])
  const itemsCount = useMemo(() => notesData?.data?.length, [notesData])
  const [isScrolled, setIsScrolled] = useState(false)
  const notesListRef = useRef(null)
  const [addNoteEditorState, setAddNoteEditorState] = useState(null)
  const [expandedNote, setExpandedNote] = useState<INote>(null)
  const [isAutoRefresh, setIsAutoRefresh] = useState(true)
  const [isFullScreen, setIsFullScreen] = useState(false)
  const [sortAnchorEl, setSortAnchorEl] = useState<SVGSVGElement | null>(null)
  const isSortOpen = useMemo(() => Boolean(sortAnchorEl), [sortAnchorEl])
  const { noteId } = queryString.parse(search) as { noteId: string }
  const history = useHistory()

  const isDueDiligence = useMemo(() => workflow === WorkflowTypes.dueDiligencePage, [workflow])

  useEffect(() => {
    if (noteId && notesData?.data) {
      const noteToSelect = notesData.data.find(({ id }) => id === noteId)
      if (noteToSelect) {
        setExpandedNote(noteToSelect)
      }

      const { noteId: _, ...rest } = queryString.parse(search)
      history.replace({
        search: queryString.stringify(rest),
      })
    }
  }, [noteId, notesData, history, search])

  const filtersDefaults = useMemo(
    () =>
      buildFiltersDefaults(
        NOTES_LIST_FILTERS_CONFIG,
        isDueDiligence
          ? {
              dueDiligenceFilters: DD_NOTES_FILTER_OPTIONS.filter(
                (filter) => filter !== NotesFilterOptions.Archived,
              ),
            }
          : {
              pages: isBDO
                ? [Pages.ops]
                : [
                    Pages.bbc,
                    Pages.financials,
                    Pages.salesBySKU,
                    Pages.projections,
                    Pages.arGeneralLedger,
                    Pages.clientPage,
                    Pages.ops,
                    Pages.entity,
                  ],
            },
      ),
    [isDueDiligence, isBDO],
  )
  const filtersConfig = useMemo(
    () => [
      isDueDiligence
        ? {
            field: 'dueDiligenceFilters',
            type: 'list' as const,
            title: '',
            isMultiple: true,
            options: DD_NOTES_FILTER_OPTIONS.map((value) => ({
              value,
              label: value,
            })),
          }
        : {
            field: 'pages',
            type: 'list' as const,
            title: '',
            isMultiple: true,
            options: (isBDO
              ? [Pages.ops]
              : [
                  Pages.bbc,
                  Pages.financials,
                  Pages.salesBySKU,
                  Pages.projections,
                  Pages.arGeneralLedger,
                  Pages.clientPage,
                  Pages.dueDiligence,
                  Pages.entity,
                  Pages.ops,
                ]
            ).map((value) => ({
              value,
              label: value,
            })),
          },
    ],
    [isDueDiligence, isBDO],
  )

  const [filters, setFilters] = useState(filtersDefaults)

  const [orderBy, setOrderBy] = useState({
    field: 'created_at',
    direction: 'DESC',
  })

  const debounceListNotes = useMemo(
    () =>
      debounceEventHandler((data: any) => {
        if (data?.isDueDiligence) {
          listNotes(clientId || id, {
            filters,
            orderBy: orderBy.field,
            orderDirection: orderBy.direction,
            workflow,
            page: 0,
            perPage: NOTES_LIST_PER_PAGE,
            skipLoader: true,
          })
        } else {
          listNotes(clientId || id, data)
        }
      }, 500),
    [id, clientId, listNotes, filters, orderBy, workflow],
  )

  useEffect(() => {
    if (id && workflow) {
      debounceListNotes({
        filters,
        orderBy: orderBy.field,
        orderDirection: orderBy.direction,
        workflow,
        page: 0,
        perPage: NOTES_LIST_PER_PAGE,
      })
    } else {
      hideNotes()
    }
  }, [filters, debounceListNotes, id, orderBy, workflow, hideNotes])

  const loadMore = useCallback(() => {
    const data = {
      loadMore: true,
      workflow,
      orderBy: orderBy.field,
      orderDirection: orderBy.direction,
      page: Math.ceil(itemsCount / NOTES_LIST_PER_PAGE),
      perPage: NOTES_LIST_PER_PAGE,
      filters,
    }
    workflow && listNotes(clientId || id, data)
  }, [id, clientId, listNotes, filters, orderBy, workflow, itemsCount])

  const handleChangeOrderBy = useCallback((value) => {
    const [field, direction] = value.split(' ')
    setOrderBy({
      field,
      direction,
    })
    setSortAnchorEl(null)
  }, [])

  const handleSummarizeNotes = useCallback(async () => {
    setIsSummarizeLoading(true)
    const result = await summarizeNotes(id, {
      workflow,
    })
    if (!result.error) {
      setIsSummaryOpen(true)
    }
    setIsSummarizeLoading(false)
  }, [id, summarizeNotes, workflow])

  const handleCloseFullScreenModal = useCallback(() => {
    setFilters({ ...filters, noteText: '' })
    setIsFullScreen(false)
  }, [setIsFullScreen, filters])

  const handleOpenFullScreenModal = useCallback(() => {
    setIsFullScreen(true)
  }, [setIsFullScreen])

  const handleRefreshNotesSummary = useCallback(async () => {
    setIsSummarizeLoading(true)
    const result = await summarizeNotes(id, {
      workflow,
      refresh: true,
    })
    if (!result.error) {
      setIsSummaryOpen(true)
    }
    setIsSummarizeLoading(false)
  }, [id, summarizeNotes, workflow])

  const toggleIsSummaryOpen = useCallback(() => {
    setIsSummaryOpen((isOpen) => !isOpen)
  }, [])

  const cleanedHTMLSummary = useMemo(() => {
    const regex = /(<([^>]+)>)/gi
    let newSummary = summary?.replace(/<br>/g, '\n')
    newSummary = newSummary?.replace(regex, '')
    //replace words surround by ** with bold
    newSummary = newSummary?.replace(/\*\*(.*?)\*\*/g, '<b>$1</b>')
    //replace - at the beginning of the line with bullet and add an indent
    newSummary = newSummary?.replace(/^-/gm, '&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;•')
    newSummary = newSummary
      ?.replace(
        /#-#/g,
        '&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;○',
      )
      ?.replace(
        /#@#/g,
        '&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;•',
      )
      ?.replace(
        /#@@#/g,
        '&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;•',
      )

    return newSummary
  }, [summary])

  const isSummarizeButtonDisabled = useMemo(() => workflow === WorkflowTypes.entityPage, [workflow])

  const openSort = useCallback((event: React.MouseEvent<SVGSVGElement>) => {
    setSortAnchorEl((anchorEl: SVGSVGElement) => (anchorEl ? null : event.currentTarget))
  }, [])

  const closeSort = useCallback(() => {
    setSortAnchorEl(null)
  }, [])

  useEffect(() => {
    const element = notesListRef.current

    const handleScroll = (event: React.UIEvent<HTMLElement>) => {
      setIsScrolled((event.target as Element).scrollTop > 0)
    }

    if (element) {
      element.addEventListener('scroll', handleScroll)
    }

    return () => {
      if (element) {
        element.removeEventListener('scroll', handleScroll)
      }
    }
  }, [])

  const handleExpandNote = useCallback((note: INote) => {
    setExpandedNote((selectedNote) => (selectedNote?.id === note.id ? null : note))
    setIsExpandNewNoteModalOpen(false)
  }, [])

  const closeExpandModal = useCallback(() => {
    setExpandedNote(null)
  }, [])

  const toggleExpandNewNoteModal = useCallback(() => {
    setIsExpandNewNoteModalOpen((isOpen) => !isOpen)
    setExpandedNote(null)
  }, [])

  const closeExpandNewNoteModal = useCallback(() => {
    setIsExpandNewNoteModalOpen(false)
  }, [])

  useEffect(() => {
    if (!isAutoRefresh) {
      return
    }

    setExpandedNote((note) =>
      note ? notesData.data?.find(({ id }) => note.id === id) || null : null,
    )
  }, [notesData, isAutoRefresh])

  const handleKeyChange = useCallback(
    (e) => {
      if (!notesData?.data || !expandedNote) {
        return
      }
      if (e.key === 'Escape') {
        setExpandedNote(null)
      } else if (e.key === 'ArrowDown') {
        const currentIndex = notesData.data.findIndex(({ id }) => expandedNote.id === id)
        if (currentIndex < notesData.data.length - 1) {
          setExpandedNote(notesData.data[currentIndex + 1])
        }
      } else if (e.key === 'ArrowUp') {
        const currentIndex = notesData.data.findIndex(({ id }) => expandedNote.id === id)
        if (currentIndex > 0) {
          setExpandedNote(notesData.data[currentIndex - 1])
        }
      }
    },
    [expandedNote, notesData, setExpandedNote],
  )

  useEffect(() => {
    window.addEventListener('keydown', handleKeyChange)

    return () => {
      window.removeEventListener('keydown', handleKeyChange)
    }
  }, [handleKeyChange])

  const handleFiltersChange = useCallback((data: any) => {
    setFilters(data)
  }, [])

  const sortOptions = useMemo(() => {
    return [
      ...NOTES_LIST_SORT_OPTIONS,
      ...(isDueDiligence
        ? [
            {
              value: 'tag DESC',
              label: 'Tags (Standard)',
            },
            {
              value: 'tag ASC',
              label: 'Tags (Reverse)',
            },
          ]
        : []),
    ]
  }, [isDueDiligence])

  return (
    <>
      {isLoading && <Loader />}
      <Card
        className={styles.notesContainer}
        classes={{
          content: styles.notesCardContainer,
        }}
        withBorder={false}
        noHeaderMargin
      >
        <div
          className={cn(styles.addNoteContainer, {
            [styles.addNoteContainerSmall]: isScrolled || isExpandNewNoteModalOpen,
            [styles.addNoteContainerDisabled]: isExpandNewNoteModalOpen,
          })}
        >
          <ExpandAndMinimize
            className={styles.addNoteContainerExpandIcon}
            action={toggleExpandNewNoteModal}
            isExpanded={isExpandNewNoteModalOpen}
          />

          <AddNote
            placeholder="What’s happening?"
            editorState={addNoteEditorState}
            onEditorStateChange={setAddNoteEditorState}
            disabled={isExpandNewNoteModalOpen}
            hideExpandModal={closeExpandNewNoteModal}
          />
        </div>
        <Box
          display="flex"
          justifyContent="space-between"
          alignContent="center"
          gap={0.5}
          className={styles.actionsContainer}
        >
          <Box display="flex" flex={1} justifyContent="flex-start" alignItems="center" gap={0.5}>
            <div
              className={cn(
                styles.actionButtonContainer,
                styles.actionButtonContainerFilterContainer,
              )}
            >
              <Form
                onSubmit={handleFiltersChange}
                initialValues={filters}
                mutators={{
                  setFieldData: ([field, value], state, { changeValue }) => {
                    changeValue(state, field, () => value)
                  },
                }}
                render={({ values, handleSubmit, form: { mutators } }) => (
                  <FilterContainer
                    mutators={mutators}
                    filters={filtersConfig}
                    handleSubmit={handleSubmit}
                    values={values}
                    appliedFilters={filters}
                    withFilterItems={false}
                    filtersSize={12}
                    actionsSize={0}
                    actionsInsideFilters
                    filterMenuClassName={styles.filterMenuPopoverPaper}
                    actions={
                      <div className={styles.actionButtonContainer}>
                        <Popover
                          open={isSortOpen}
                          anchorEl={sortAnchorEl}
                          onClose={closeSort}
                          anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'left',
                          }}
                          classes={{ paper: styles.sortMenuPopoverPaper }}
                        >
                          <div className={styles.sortMenu}>
                            {sortOptions.map((option) => (
                              <MenuItem
                                key={option.value}
                                classes={{
                                  root: styles.sortMenuItem,
                                  selected: styles.sortMenuItemSelected,
                                }}
                                onClick={() => handleChangeOrderBy(option.value)}
                                selected={`${orderBy.field} ${orderBy.direction}` === option.value}
                              >
                                {option.label}
                              </MenuItem>
                            ))}
                          </div>
                        </Popover>

                        <SortIcon
                          className={cn(styles.button, styles.sortButton, {
                            [styles.buttonActive]: isSortOpen,
                          })}
                          onClick={openSort}
                        />
                      </div>
                    }
                    additionalActions={
                      <Box display="flex" justifyContent="flex-start" alignItems="center" gap={0.5}>
                        {!isSummarizeButtonDisabled && (
                          <SummarizeButton
                            className={styles.button}
                            action={handleSummarizeNotes}
                            isLoading={isSummarizeLoading}
                          />
                        )}
                        <ExpandAndMinimize
                          className={styles.button}
                          action={handleOpenFullScreenModal}
                          isExpanded={isFullScreen}
                        />
                      </Box>
                    }
                  />
                )}
              />
            </div>
          </Box>
        </Box>
        <div id="scrollableDiv" className={styles.notesList} ref={notesListRef}>
          {!isLoading && !notesData?.data?.length && (
            <div className={styles.emptyContainer}>
              <EmptyIcon />
              <div className={styles.emptyContainerText}>There are no notes at this time.</div>
            </div>
          )}
          <InfiniteScroll
            className={styles.infiniteScroll}
            dataLength={itemsCount || 0}
            next={loadMore}
            hasMore={itemsCount < totalCount}
            loader={
              <Box className={styles.circularLoader}>
                <CircularProgress color="primary" />
              </Box>
            }
            scrollableTarget="scrollableDiv"
          >
            {notesData?.data?.map((note) => (
              <Note
                key={note.id}
                note={note}
                isSelected={expandedNote?.id === note.id}
                onClick={handleExpandNote}
                handleCloseExpandModal={expandedNote?.id === note.id ? closeExpandModal : undefined}
                setIsAutoRefresh={setIsAutoRefresh}
                listNotes={debounceListNotes}
              />
            ))}
          </InfiniteScroll>
        </div>
      </Card>
      {isSummaryOpen && (
        <Modal
          classes={{
            title: styles.summaryTitle,
          }}
          title={
            <Grid container xs={12} justifyContent={'space-between'} alignItems={'center'}>
              <Grid item xs={6}>
                <h1>Summary</h1>
              </Grid>
              <Grid item xs={6} pt={1}>
                <RegenerateIcon
                  action={handleRefreshNotesSummary}
                  isLoading={isSummarizeLoading}
                  title="Refresh"
                />
              </Grid>
            </Grid>
          }
          onCancel={toggleIsSummaryOpen}
          open
        >
          <div
            className={styles.summary}
            dangerouslySetInnerHTML={{ __html: cleanedHTMLSummary }}
          />
        </Modal>
      )}
      {(isExpandNewNoteModalOpen || !!expandedNote) && (
        <NoteExpandModal
          handleClose={isExpandNewNoteModalOpen ? toggleExpandNewNoteModal : closeExpandModal}
        >
          <div className={styles.notesRepliesContainerList}>
            {isExpandNewNoteModalOpen ? (
              <div className={styles.addNewNoteExpandedContainer}>
                <AddNote
                  placeholder="What’s happening?"
                  editorState={addNoteEditorState}
                  onEditorStateChange={setAddNoteEditorState}
                  hideExpandModal={closeExpandNewNoteModal}
                  isExpandModal
                />
              </div>
            ) : (
              <div className={styles.noteExpandedContainer}>
                <Note
                  note={expandedNote}
                  isExpandModal
                  handleCloseExpandModal={closeExpandModal}
                  setIsAutoRefresh={setIsAutoRefresh}
                  listNotes={debounceListNotes}
                />
              </div>
            )}
          </div>
        </NoteExpandModal>
      )}
      {isFullScreen && <NotesFullScreenModal handleClose={handleCloseFullScreenModal} />}
    </>
  )
}

export default NotesContainer
