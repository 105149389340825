import React, { useMemo } from 'react'
import cn from 'classnames'

import styles from './ClientNewsfeedContainer.module.scss'
import { ReactComponent as RectangleItem } from '@assets/images/rectangle.svg'

import { IClientNewsfeed } from '@common/interfaces/newsfeed'
import { formatDateCalendarNoTime } from '../../helpers/helpers'
import ExternalLink from '../Common/ExternalLink'

interface IProps {
  item: IClientNewsfeed
}

const ClientNewsItem = ({ item }: IProps) => {
  const newsType = useMemo(() => {
    if (item.newsfeedHeadlines?.type && item.newsfeedHeadlines?.type !== 'Other') {
      return item.newsfeedHeadlines?.type
    } else {
      return item.newsfeedHeadlines?.industry || item.type
    }
  }, [item])

  return (
    <div className={styles.item}>
      <div className={styles.titleContainer}>
        <div className={styles.titleRectangle}>
          <RectangleItem />
          <div className={styles.itemDescription}>{newsType}</div>
        </div>
        <div className={styles.itemDate}>
          {formatDateCalendarNoTime(item.newsfeedHeadlines?.processedAt)}
        </div>
      </div>

      {item.newsfeedHeadlines?.link && item.newsfeedHeadlines?.isUrlTransformed ? (
        <div className={styles.itemSubtitles}>
          <ExternalLink
            className={styles.itemLink}
            href={item.newsfeedHeadlines?.link}
            newsfeedHeadlineId={item.newsfeedHeadlines?.id}
          >
            {item.newsfeedHeadlines?.headline || ''}
          </ExternalLink>
        </div>
      ) : (
        <div className={cn(styles.itemSubtitles, styles.itemSubtitlesNoLink, styles.itemLink)}>
          {item.newsfeedHeadlines?.headline || ''}
        </div>
      )}
    </div>
  )
}

export default ClientNewsItem
