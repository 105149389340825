import { Location } from 'history'
import { IMenuData, ISubMenuData, SIDE_MENU } from './SideMenuConfig'

export const isItemActive = (items: ISubMenuData[], location: Location) =>
  items.map(({ link }) => link).includes(location.pathname)

export const getInitialExpandableListItemsState = (role: string, location: Location) =>
  (SIDE_MENU[role] || []).reduce(
    (acc: { [key: string]: boolean }, { label, subItems }: IMenuData) => {
      if (subItems) {
        // if the expanded menu page is open via URL or page is refreshed -> show expanded menu
        acc[label] = isItemActive(subItems, location)
      }

      return acc
    },
    {},
  )

export const updateExpandableListItemsState = (
  initialState: { [key: string]: boolean },
  itemToExpand: string,
) => {
  const newExpandableListItemsState = {}

  for (const key of Object.keys(initialState)) {
    const isListItemExpanded = initialState[itemToExpand]

    if (key === itemToExpand) {
      newExpandableListItemsState[key] = !isListItemExpanded
    } else {
      newExpandableListItemsState[key] = false
    }
  }

  return newExpandableListItemsState
}
