import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'

import VendorsSummaryTable from './VendorsSummaryTable'

import { vendorsAggregation } from '../../redux/entityInfo/selectors'
import { listVendors, exportVendors, listEntityInfo } from '../../redux/entityInfo/actions'

const selector = createStructuredSelector({
  fullVendorsAggregation: vendorsAggregation,
})
const actions = {
  listVendors,
  exportVendors,
  listEntityInfo,
}

export default connect(selector, actions)(VendorsSummaryTable)
