import React, { useEffect, useMemo, useState, useCallback } from 'react'
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  CoreChartOptions,
  ElementChartOptions,
  PluginChartOptions,
  DatasetChartOptions,
  ScaleChartOptions,
  LineControllerChartOptions,
  TooltipModel,
  Chart,
} from 'chart.js'
import { Line } from 'react-chartjs-2'
import styles from './RiskRatingVisualization.module.scss'
import genericSs from '@styles/generic.module.scss'

import Card from './../../Common/Card'
import { DeepPartial } from 'chart.js/dist/types/utils'
import {
  IRiskRatingData,
  RISK_METRIC_OPTIONS,
  RISK_METRIC_UNDERLYING_DATA,
} from '@common/interfaces/client'
import { ILoadingData } from '../../../redux/types'
import { useParams } from 'react-router'
import SelectField from '../../Common/SelectField'
import DatePicker from '../../Common/DatePicker'
import Grid from '@mui/material/Grid'
import Box from '@mui/material/Box'
import { ReportingPeriods } from '@common/interfaces/bbc'
import moment from 'moment'
import { getFieldValue } from '../ClientHelpers'
import { formatAmount } from '../../../helpers/helpers'
import {
  buildTooltip,
  buildTooltipMetricScoreValueHead,
  buildTooltipMetricScoreValueRow,
  GRAPH_COLOR_SLATE_GRAY,
  GRAPH_COLOR_PRIMARY,
  GRAPH_COLOR_4,
  MetricType,
  METRIC_TYPE_OPTIONS,
  buildTooltipUnderlyingMetricValueHead,
  buildTooltipUnderlyingMetricValueRow,
} from '../../../constants/graphs'
import useTrackVisualizationsTable from '../../../hooks/useTrackVisualizationsTable'
import { CATEGORIES } from '@common/constants/tracking'
import cn from 'classnames'

const FONT_SIZE = 13

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend)

const options: DeepPartial<
  CoreChartOptions<'line'> &
    ElementChartOptions<'line'> &
    PluginChartOptions<'line'> &
    DatasetChartOptions<'line'> &
    ScaleChartOptions<'line'> &
    LineControllerChartOptions
> = {
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
    legend: {
      position: 'top' as const,
      labels: {
        boxWidth: 20,
        boxHeight: 20,
        usePointStyle: true,
        pointStyle: 'rect',
        padding: 10,
        color: 'black',
        font: {
          size: FONT_SIZE,
          weight: '500',
        },
      },
    },
  },
  scales: {
    x: {
      grid: {
        display: false,
      },
      ticks: {
        font: {
          size: FONT_SIZE,
        },
      },
    },
    y1: {
      grid: {
        display: false,
      },
      ticks: {
        count: 7,
        font: {
          size: FONT_SIZE,
        },
        callback: (value) => formatAmount(value),
      },
      position: 'right',
    },
  },
}

interface IProps {
  listRiskRating: (clientId: string) => void
  riskRatings: ILoadingData<IRiskRatingData>
}

const RiskRatingVisualization = ({ listRiskRating, riskRatings }: IProps) => {
  const { id } = useParams<{ id: string }>()
  const [selectedMetric, setSelectedMetric] = useState({
    label: RISK_METRIC_OPTIONS[0].label,
    value: RISK_METRIC_OPTIONS[0].value,
    type: RISK_METRIC_OPTIONS[0].type,
  })
  const [currentDateRange, setCurrentDateRange] = useState({
    startDate: null,
    endDate: null,
  })

  const [metricType, setMetricType] = useState(MetricType.Score)
  const [clickedIndex, setClickedIndex] = useState(null)

  useEffect(() => {
    const handleClickOutside = (event: Event) => {
      const tooltipEl = document.getElementById('chartjs-tooltip')
      const chartEl = document.getElementById('chartjs')

      if (
        tooltipEl &&
        chartEl &&
        !tooltipEl.contains(event.target as Node) &&
        !chartEl.contains(event.target as Node)
      ) {
        tooltipEl.remove()
        setClickedIndex(null)
      }
    }
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [])

  useEffect(() => {
    listRiskRating(id)
  }, [listRiskRating, id])

  const { risk } = useMemo(
    () => ({
      risk: riskRatings.data?.data,
    }),
    [riskRatings],
  )

  const dates = useMemo(() => {
    if (!risk) {
      return []
    }
    return risk?.map(({ date }) => date).filter(Boolean)
  }, [risk])

  useEffect(() => {
    if (!dates) {
      return
    }
    if (dates.length > 12) {
      setCurrentDateRange({
        startDate: dates[dates.length - 12] || null,
        endDate: dates[dates.length - 1] || null,
      })
    } else {
      setCurrentDateRange({
        startDate: dates[0] || null,
        endDate: dates[dates.length - 1] || null,
      })
    }
  }, [dates])

  const datesBoundary = useMemo(
    () => ({
      minDate: dates[0],
      maxDate: dates[dates.length - 1],
    }),
    [dates],
  )

  const handleMetricChange = useCallback(
    ({ target }) => {
      const { value } = target
      const mewMetric = RISK_METRIC_OPTIONS.find(({ value: metricValue }) => metricValue === value)
      setSelectedMetric(mewMetric)
    },
    [setSelectedMetric],
  )

  const handleSetMetricType = useCallback(
    ({ target }) => {
      const { value } = target
      setMetricType(value)
    },
    [setMetricType],
  )

  const handleDateChange = useCallback(
    (values: { startDate: string; endDate: string }) => {
      setCurrentDateRange(values)
    },
    [setCurrentDateRange],
  )

  const filteredRisk = useMemo(() => {
    if (!risk) {
      return []
    }
    const filteredRisk = risk?.filter(({ date }) => {
      if (!date) {
        return true
      }
      if (
        moment(date).isSameOrAfter(currentDateRange.startDate) &&
        moment(date).isSameOrBefore(currentDateRange.endDate)
      ) {
        return true
      }
      return false
    })
    return filteredRisk
  }, [risk, currentDateRange])

  const labels = useMemo(
    () => filteredRisk?.map(({ date }) => (date ? moment(date).format('MMM YY') : 'Live')),
    [filteredRisk],
  )

  const lineData = useMemo(
    () =>
      filteredRisk?.map(({ scores, data }) =>
        metricType === MetricType.Score ? scores[selectedMetric.value] : data[selectedMetric.value],
      ),

    [selectedMetric, filteredRisk, metricType],
  )

  const portfolioData = useMemo(
    () =>
      filteredRisk?.map(({ portfolioScores, portfolioData }) =>
        metricType === MetricType.Score
          ? portfolioScores?.[selectedMetric.value]
          : portfolioData?.[selectedMetric.value],
      ),
    [filteredRisk, selectedMetric, metricType],
  )

  const loanBalances = useMemo(
    () => filteredRisk?.map(({ loanBalance }) => loanBalance),
    [filteredRisk],
  )

  const data = useMemo(
    () => ({
      labels,
      datasets: [
        {
          label: selectedMetric.label,
          data: lineData,
          borderColor: GRAPH_COLOR_PRIMARY,
          backgroundColor: '#0066F5',
          lineTension: 0.4,
          pointRadius: (context: any) => {
            return clickedIndex === context.dataIndex ? 7 : 2
          },
          pointHoverRadius: 7,
          yAxisID: 'y',
        },
        {
          label: 'Portfolio average',
          data: portfolioData,
          borderColor: GRAPH_COLOR_SLATE_GRAY,
          backgroundColor: GRAPH_COLOR_SLATE_GRAY,
          lineTension: 0.4,
          pointRadius: 0,
          yAxisID: 'y',
        },
        {
          label: 'Loan balance',
          data: loanBalances,
          borderColor: GRAPH_COLOR_4,
          backgroundColor: GRAPH_COLOR_4,
          lineTension: 0.4,
          pointRadius: 0,
          yAxisID: 'y1',
        },
      ],
    }),
    [labels, lineData, selectedMetric, portfolioData, clickedIndex, loanBalances],
  )

  useEffect(() => {
    if (selectedMetric.value === 'riskRating') {
      setMetricType(MetricType.Score)
    }
  }, [selectedMetric])

  const riskRatingTooltip = useCallback(
    (context: { chart: Chart<'line'>; tooltip: TooltipModel<'line'> }) =>
      buildTooltip((tooltip) => {
        const titleLines = tooltip.title || []
        const date = titleLines[0]

        const dataPoint = tooltip?.dataPoints?.[0]

        const lineLabel = dataPoint?.dataset?.label
        const currentScore = dataPoint?.parsed?.y

        if (lineLabel === 'Portfolio average' || lineLabel === 'Loan balance') {
          return
        }

        const riskRatingData = risk?.find(
          ({ date: riskDate }) =>
            (date === 'Live' && !riskDate) || moment(riskDate).format('MMM YY') === date,
        )
        const scores = riskRatingData?.scores
        const rawData = riskRatingData?.data
        const riskRating = scores?.riskRating?.toFixed(2)

        const currentScoreFormatted =
          metricType === MetricType.Score
            ? currentScore?.toFixed(2)
            : getFieldValue(rawData, selectedMetric.value, selectedMetric.type)

        if (!riskRatingData) {
          return
        }

        let innerHtml: string

        if (lineLabel === 'Risk rating') {
          innerHtml = buildTooltipMetricScoreValueHead('Risk rating', `${riskRating} (${date})`)

          RISK_METRIC_OPTIONS.forEach((metric) => {
            const key = metric.value
            if (key === 'riskRating') {
              return
            }
            const label = metric.label
            const score = scores[key]?.toFixed(1) || '-'
            const value = getFieldValue(rawData, key, metric.type)

            innerHtml += buildTooltipMetricScoreValueRow(label, score, value)
          })
        } else {
          let supplementalData = RISK_METRIC_UNDERLYING_DATA.filter(
            ({ originalValue }) => originalValue === selectedMetric.value,
          ).map(({ label, type, value }) => ({
            label,
            value: getFieldValue(rawData, value, type),
          }))

          const currentMetric = {
            label: selectedMetric.label,
            value: getFieldValue(rawData, selectedMetric.value, selectedMetric.type),
          }

          if (metricType === MetricType.Score) {
            supplementalData = [currentMetric, ...supplementalData]
          }

          innerHtml = buildTooltipUnderlyingMetricValueHead(
            currentMetric.label,
            `${currentScoreFormatted} (${date})`,
          )

          supplementalData.forEach(({ label, value }) => {
            innerHtml += buildTooltipUnderlyingMetricValueRow(label, value)
          })
        }
        innerHtml += '</tbody>'

        return innerHtml
      })(context),
    [risk, selectedMetric, metricType],
  )

  const updateOptions = useMemo(() => {
    let newOptions = {
      ...options,
      plugins: {
        ...options.plugins,
        tooltip: {
          enabled: false,
          external: riskRatingTooltip,
          events: ['click'],
        },
      },
      onHover: (event: any, elements: any) => {
        // @ts-ignore
        event.native.target.style.cursor = elements[0] ? 'pointer' : 'default'
      },
      onClick: (event: any, elements: any) => {
        setClickedIndex(elements[0] ? elements[0].index : null)
      },
    }

    if (metricType === MetricType.Score) {
      newOptions = {
        ...newOptions,
        scales: {
          ...newOptions.scales,
          y: {
            min: 1.0,
            max: 7.0,
            ticks: {
              font: {
                size: FONT_SIZE,
              },
              callback: function (value) {
                return (Number(value) || 0)?.toFixed(2)
              },
            },
          },
        },
      }
    }
    return newOptions
  }, [riskRatingTooltip, metricType])

  const isInitialized = useMemo(() => !!riskRatings?.data?.data, [riskRatings])
  const visualizationsParams = useMemo(
    () => ({
      clientId: id,
    }),
    [id],
  )
  const visualizationsFilters = useMemo(
    () => ({
      selectedMetric: selectedMetric.value,
      metricType,
      startDate: currentDateRange.startDate,
      endDate: currentDateRange.endDate,
    }),
    [metricType, currentDateRange.startDate, currentDateRange.endDate, selectedMetric],
  )
  useTrackVisualizationsTable({
    isInitialized,
    category: CATEGORIES.riskRatingVisualization,
    params: visualizationsParams,
    filters: visualizationsFilters,
  })

  return (
    <Card
      className={styles.card}
      title={
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <span className={cn(styles.subTitle, genericSs.textCapitalize)}>
            {selectedMetric.label} {metricType === MetricType.Score && metricType} Over Time
          </span>
          <Box display="flex" alignItems="center" justifyContent={'space-between'} gap={2}>
            <DatePicker
              reportingPeriod={ReportingPeriods.Monthly}
              currentDateRange={currentDateRange}
              datesBoundary={datesBoundary}
              onChange={handleDateChange}
            />
            <SelectField
              key={selectedMetric.value}
              label="Select metric"
              variant="outlined"
              useFinalForm={false}
              options={RISK_METRIC_OPTIONS}
              onChange={handleMetricChange}
              value={selectedMetric.value}
              name="selectedMetric"
              defaultValue=""
              className={styles.selectField}
            />
            <SelectField
              key={metricType}
              label="Select view"
              variant="outlined"
              useFinalForm={false}
              options={METRIC_TYPE_OPTIONS}
              onChange={handleSetMetricType}
              value={metricType}
              name="metricType"
              defaultValue=""
              className={styles.selectField}
              disabled={selectedMetric.value === 'riskRating'}
            />
          </Box>
        </Box>
      }
    >
      <Grid container xs={12} justifyContent={'center'}>
        <Grid item xs={12} className={styles.chartContainer} container>
          <Line id="chartjs" data={data} options={updateOptions} />
        </Grid>
      </Grid>
    </Card>
  )
}

export default RiskRatingVisualization
