import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'

import BBCAvailabilityTable from './BBCAvailabilityTable'

import { bbc, isLoading } from '../../redux/bbc/selectors'

const selector = createStructuredSelector({
  bbc,
  isLoading,
})

const actions = {}

export default connect(selector, actions)(BBCAvailabilityTable)
