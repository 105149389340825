import AccountActivityGraph from './AccountActivityGraph'

import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'

import { getAccountActivityGraph } from '../../redux/clientInfo/actions'
import { accountActivityGraph } from '../../redux/clientInfo/selectors'

const selector = createStructuredSelector({
  accountActivityGraph,
})

const actions = {
  getAccountActivityGraph,
}

export default connect(selector, actions)(AccountActivityGraph)
