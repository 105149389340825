import { Action } from 'redux'
import { takeEvery, put } from 'redux-saga/effects'
import { PROFILE_FAILURE } from '../profile/actions'
import { putNotification } from './actions'
import { IResponse } from '@common/interfaces/request'

function* notifyAction(action: IResponse & Action) {
  if (action.data && action.data.success) {
    const { message, code } = action.data.success
    yield put(putNotification({ code, message, type: 'success' }))
  }

  if (action.data && action.data.info) {
    const { message, code } = action.data.info
    yield put(putNotification({ code, message, type: 'info' }))
  }

  if (action.data && action.data.error) {
    const { message, code, messagesList } = action.data.error
    const noShowMessage = messagesList ? true : false

    yield put(putNotification({ code, message, type: 'error', messagesList, noShowMessage }))
  }

  if (action.error && action.type !== PROFILE_FAILURE && action.error.code !== 'CUSTOM_ERROR') {
    const { message, code, status, messagesList, data } = action.error
    if (status === 503) {
      yield put(
        putNotification({
          code: '',
          message:
            'Atlas is undergoing scheduled maintenance at this time. Please check back again soon!',
          type: 'error',
        }),
      )
      return
    }
    if (data?.isSilent) {
      return
    }
    const noShowMessage = messagesList ? true : false

    yield put(putNotification({ code, message, messagesList, type: 'error', noShowMessage }))
  }
}

export function* notify() {
  yield takeEvery('*', notifyAction)
}
