import React, { useState, useEffect, useCallback, useMemo, useRef } from 'react'
import { useParams } from 'react-router-dom'
import { Form } from 'react-final-form'
import Box from '@mui/material/Box'
import InfiniteScroll from 'react-infinite-scroll-component'
import cn from 'classnames'

import styles from './SalesChannelDistribution.module.scss'
import genericSs from '@styles/generic.module.scss'

import { SALES_CHANNEL_DISTRIBUTION_LIST_FILTERS_CONFIG, PER_PAGE } from '@common/constants/filters'
import {
  ISalesChannelDistribution,
  ISalesChannelDistributionData,
  ISalesChannelDistributionOptions,
} from '@common/interfaces/client'
import { ReportingFlow } from '@common/interfaces/bbc'
import { debounceEventHandler, formatValue } from '../../helpers/helpers'
import { buildFiltersDefaults, buildFiltersValidateSchema } from '../../helpers/filters'
import FilterContainer from '../Filters/FilterContainer'
import { ILoadingData } from '../../redux/types'
import { ExpandAndMinimize, ExternalLink } from '../Common/Icons'
import FullscreenModal from '../Common/FullscreenModal'
import useTable from '../../hooks/useTable'
import { CATEGORIES } from '@common/constants/tracking'
import useTrackVisualizationsTable from '../../hooks/useTrackVisualizationsTable'
import TableContainer from '../Common/TableContainer'
import Table from '../Common/Table'
import TableHead from '../Common/TableHead'
import TableBody from '../Common/TableBody'
import TableRow from '../Common/TableRow'
import TableCell from '../Common/TableCell'
import TableFiltersRow from '../Common/TableFiltersRow'
import TableLoader from '../Common/TableLoader'
import FormattedTableCell from '../Common/FormattedTableCell'
import TableFooter from '../Common/TableFooter'
import ActiveToolbar from '../ActiveToolbar'

const filtersValidate = buildFiltersValidateSchema(SALES_CHANNEL_DISTRIBUTION_LIST_FILTERS_CONFIG)
const filtersDefaults = buildFiltersDefaults(SALES_CHANNEL_DISTRIBUTION_LIST_FILTERS_CONFIG)

interface IProps {
  reportingFlow: ReportingFlow
  salesChannelDistribution: ILoadingData<ISalesChannelDistributionData>
  salesChannelDistributionOptions: ILoadingData<ISalesChannelDistributionOptions>
  listSalesChannelDistribution: (
    id: string,
    params?: {
      filters?: object
    },
  ) => void
  listSalesChannelDistributionOptions: (id: string, params?: object) => void
  hideSalesChannelDistribution: () => void
}

const SalesChannelDistribution = ({
  reportingFlow,
  salesChannelDistribution,
  salesChannelDistributionOptions,
  listSalesChannelDistribution,
  listSalesChannelDistributionOptions,
  hideSalesChannelDistribution,
}: IProps) => {
  const { id } = useParams<{ id: string }>()
  const wrapperRef = useRef(null)
  const [isModalShown, setIsModalShown] = useState(false)

  const {
    filters,
    handleFiltersChange,
    handleOrderChange,
    orderBy,
    activeItem,
    activeItems,
    setActiveItem,
    setActiveItems,
    handleSelectRow,
    resetActiveItems,
    quickFilter,
    handleQuickFilterChange,
  } = useTable({
    tableId: 'salesChannelDistribution',
    filtersDefaults,
    sortDefault: { field: 'label', direction: 'ASC' },
  })

  const { isLoading, salesChannelDistributionData, salesChannelDistributionTotals, totalItems } =
    useMemo(
      () => ({
        isLoading: salesChannelDistribution.isLoading,
        salesChannelDistributionData: salesChannelDistribution?.data?.data || [],
        salesChannelDistributionTotals: salesChannelDistribution?.data?.totals || [],
        totalItems: salesChannelDistribution?.data?.totalItems || 0,
      }),
      [salesChannelDistribution],
    )

  const { maxDate, minDate, boxLink } = useMemo(
    () => ({
      maxDate: salesChannelDistributionOptions?.data?.maxDate,
      minDate: salesChannelDistributionOptions?.data?.minDate,
      boxLink: salesChannelDistributionOptions?.data?.boxLink || null,
    }),
    [salesChannelDistributionOptions],
  )

  useEffect(() => {
    listSalesChannelDistributionOptions(id, {
      reportingFlow,
    })
  }, [listSalesChannelDistributionOptions, id, reportingFlow])

  useEffect(() => {
    return () => {
      hideSalesChannelDistribution()
    }
  }, [hideSalesChannelDistribution])

  const fetchSalesChannelDistribution = useCallback(
    (data: any) => {
      const params = {
        ...data,
        filters: {
          ...data.filters,
        },
        reportingFlow,
        maxDate,
        minDate,
      }
      minDate && listSalesChannelDistribution(id, params)
    },
    [listSalesChannelDistribution, id, reportingFlow, maxDate, minDate],
  )

  const debounceListSalesChannelDistribution = useMemo(
    () => debounceEventHandler(fetchSalesChannelDistribution, 500),
    [fetchSalesChannelDistribution],
  )

  const loadMore = useCallback(() => {
    debounceListSalesChannelDistribution({
      loadMore: true,
      page: Math.ceil(salesChannelDistributionData.length / PER_PAGE),
      orderBy: orderBy.field,
      orderDirection: orderBy.direction,
      filters,
    })
  }, [salesChannelDistributionData, orderBy, filters, debounceListSalesChannelDistribution])

  useEffect(() => {
    debounceListSalesChannelDistribution({
      page: 0,
      orderBy: orderBy.field,
      orderDirection: orderBy.direction,
      filters,
    })
  }, [filters, orderBy, debounceListSalesChannelDistribution])

  const handleModalToggle = useCallback(() => {
    setIsModalShown((prev) => !prev)
  }, [])

  const visualizationsParams = useMemo(
    () => ({
      clientId: id,
    }),
    [id],
  )

  const visualizationsFilters = useMemo(() => filters, [filters])

  useTrackVisualizationsTable({
    category: CATEGORIES.salesChannelDistribution,
    params: visualizationsParams,
    filtersConfig: SALES_CHANNEL_DISTRIBUTION_LIST_FILTERS_CONFIG,
    filters: visualizationsFilters,
    orderBy,
  })

  const listFieldsConfig = useMemo(
    () =>
      SALES_CHANNEL_DISTRIBUTION_LIST_FILTERS_CONFIG.filter((filter) =>
        ['amount', 'percent'].includes(filter.type),
      ).map((filter) => ({
        field: filter.field,
        fieldType: filter.type,
      })),

    [],
  )

  const totalRow = useMemo(() => {
    const totalRow = salesChannelDistributionData
      .filter((_, index) => activeItems.includes(index))
      .reduce(
        (totalRowResult, row) => {
          listFieldsConfig.forEach(({ field }) => {
            totalRowResult[field] += Number(row[field]) || 0
          })

          return totalRowResult
        },
        {
          ...listFieldsConfig
            .map(({ field }) => field)
            .reduce((totalRowResult: Partial<ISalesChannelDistribution>, field: string) => {
              totalRowResult[field] = 0

              return totalRowResult
            }, {}),
        },
      )

    totalRow.dilution = totalRow.ar_gl_invoices
      ? 1 - totalRow.ar_gl_dilutive_credits / totalRow.ar_gl_invoices
      : 0

    return totalRow
  }, [salesChannelDistributionData, activeItems, listFieldsConfig])

  return (
    <FullscreenModal
      isOpen={isModalShown}
      setIsOpen={setIsModalShown}
      classes={{ body: styles.fullScreenModal }}
    >
      <>
        <Form
          validate={filtersValidate}
          onSubmit={handleFiltersChange}
          initialValues={filters}
          mutators={{
            setFieldData: ([field, value], state, { changeValue }) => {
              changeValue(state, field, () => value)
            },
          }}
          render={({ values, handleSubmit, form: { mutators } }) => (
            <FilterContainer
              filters={SALES_CHANNEL_DISTRIBUTION_LIST_FILTERS_CONFIG}
              handleSubmit={handleSubmit}
              mutators={mutators}
              values={values}
              appliedFilters={filters}
              appliedQuickFilter={quickFilter}
              handleAppliedQuickFilterChange={handleQuickFilterChange}
              title={
                <Box mr={2}>
                  <h2>Sales Channel Distribution</h2>
                </Box>
              }
              actions={
                <Box display="flex" alignItems="center" gap={1}>
                  {reportingFlow === ReportingFlow.LoanCommitteeReport && boxLink && (
                    <ExternalLink link={boxLink} />
                  )}

                  <ExpandAndMinimize action={handleModalToggle} isExpanded={isModalShown} />
                </Box>
              }
            />
          )}
        />

        <TableContainer
          ref={wrapperRef}
          className={styles.table}
          onActiveRowsChange={setActiveItems}
          onActiveRowChange={setActiveItem}
          hasFooter
        >
          <Table>
            <TableHead>
              <TableFiltersRow
                filters={SALES_CHANNEL_DISTRIBUTION_LIST_FILTERS_CONFIG}
                orderBy={orderBy}
                handleOrderChange={handleOrderChange}
                isChildrenAtStart
              />
            </TableHead>

            <TableBody id="sales-channel-distribution">
              {isLoading ? (
                <TableLoader columnsCount={SALES_CHANNEL_DISTRIBUTION_LIST_FILTERS_CONFIG.length} />
              ) : (
                <InfiniteScroll
                  dataLength={salesChannelDistributionData.length}
                  next={loadMore}
                  hasMore={salesChannelDistributionData.length < totalItems}
                  loader={
                    <TableLoader
                      columnsCount={SALES_CHANNEL_DISTRIBUTION_LIST_FILTERS_CONFIG.length}
                      rowsCount={1}
                    />
                  }
                  scrollableTarget="sales-channel-distribution"
                >
                  {salesChannelDistributionData.map((item, index) => (
                    <TableRow
                      key={item.label}
                      data-index={index}
                      className={cn('activableRow', {
                        activeRow: activeItems.includes(index),
                        currentActiveRow: activeItem === index,
                      })}
                      onClick={(event) => handleSelectRow(event, index)}
                    >
                      <TableCell className={genericSs.tableTextLeft}>
                        <span>{item.label}</span>
                      </TableCell>

                      {listFieldsConfig.map(({ field, fieldType }) => (
                        <FormattedTableCell key={field} value={item[field]} fieldType={fieldType} />
                      ))}
                    </TableRow>
                  ))}
                </InfiniteScroll>
              )}
            </TableBody>

            <TableFooter>
              {!salesChannelDistributionTotals ? (
                <TableLoader
                  columnsCount={SALES_CHANNEL_DISTRIBUTION_LIST_FILTERS_CONFIG.length}
                  rowsCount={1}
                />
              ) : (
                salesChannelDistributionTotals.map((item) => (
                  <TableRow key={item.label}>
                    <TableCell className={genericSs.tableTextLeft}>{item.label}</TableCell>

                    {listFieldsConfig.map(({ field, fieldType }) => (
                      <FormattedTableCell key={field} value={item[field]} fieldType={fieldType} />
                    ))}
                  </TableRow>
                ))
              )}
            </TableFooter>
          </Table>

          <ActiveToolbar
            activeItems={activeItems}
            className={cn(styles.activeToolbar)}
            isFullscreen={isModalShown}
            containerRef={wrapperRef}
            resetActiveItems={resetActiveItems}
          >
            {listFieldsConfig.map(({ field, fieldType }) => (
              <div key={field} className={genericSs.tableTextRight}>
                {formatValue({
                  value: totalRow?.[field],
                  type: fieldType,
                })}
              </div>
            ))}
          </ActiveToolbar>
        </TableContainer>
      </>
    </FullscreenModal>
  )
}

export default SalesChannelDistribution
