import React, { useCallback } from 'react'

import {
  DueDiligenceTableItemType,
  IDueDiligenceBoardMember,
} from '@common/interfaces/dueDiligence'
import DueDiligenceTable from '../DueDiligenceTable'
import { ILoadingData } from '../../redux/types'

interface IProps {
  dueDiligenceTeamBoardMembers: ILoadingData<{ data: IDueDiligenceBoardMember[] }>
  listTeamBoardMembers: (params: object) => void
  hideTeamBoardMembers: () => void
  addTeamBoardMember: (data: object) => Promise<void>
  updateTeamBoardMember: (id: string, data: object) => Promise<void>
  deleteTeamBoardMember: (id: string) => Promise<void>
}

const DueDiligenceTeamBoardMembers = ({
  dueDiligenceTeamBoardMembers,
  listTeamBoardMembers,
  hideTeamBoardMembers,
  addTeamBoardMember,
  updateTeamBoardMember,
  deleteTeamBoardMember,
}: IProps) => {
  const handleUpdateBoardMember = useCallback(
    async (id: string, data: any) => {
      const { email, firstName, lastName, company, relationshipToCompany, ...rest } = data

      return updateTeamBoardMember(id, {
        email: email || '',
        firstName: firstName || '',
        lastName: lastName || '',
        company: company || '',
        relationshipToCompany: relationshipToCompany || '',
        ...rest,
      })
    },
    [updateTeamBoardMember],
  )

  return (
    <DueDiligenceTable
      type={DueDiligenceTableItemType.BoardMember}
      data={dueDiligenceTeamBoardMembers}
      handleList={listTeamBoardMembers}
      handleHide={hideTeamBoardMembers}
      handleAdd={addTeamBoardMember}
      handleUpdate={handleUpdateBoardMember}
      handleDelete={deleteTeamBoardMember}
    />
  )
}

export default DueDiligenceTeamBoardMembers
