import React, { useState, useCallback, useMemo } from 'react'
import { generatePath, Link as RouterLink } from 'react-router-dom'
import Link from '@mui/material/Link'
import MenuItem from '@mui/material/MenuItem'
import Paper from '@mui/material/Paper'
import Popper from '@mui/material/Popper'
import cn from 'classnames'
import Tooltip from '@mui/material/Tooltip'
import styles from './FlagTitle.module.scss'

import { ReactComponent as AlertIcon } from '@assets/images/warning-circle-icon.svg'
import { ReactComponent as NextIcon } from '@assets/images/next-icon.svg'
import { ReactComponent as ThumbsUpIcon } from '@assets/images/thumbs-up-icon.svg'
import { ReactComponent as WarningOutlined } from '@assets/images/small-warning-icon.svg'
import { Feedback, IFlagFeedback } from '@common/interfaces/prospects'
import { currentWorkFlow, formatDate, handleStopPropagation } from '../../../helpers/helpers'
import { WorkflowTypes } from '@common/interfaces/notes'
import { FlagColors, IFlags } from '@common/interfaces/bbc'
import Avatar from '../../Common/Avatar'
import { ROUTES } from '../../../constants/routes'
import { ReactComponent as CheckIcon } from '@assets/images/check-circle-icon-alt.svg'
import { ReactComponent as CheckIconDefault } from '@assets/images/alert-success-snackbar.svg'

interface IPropsFlagTitle {
  flag: IFlags
  pathname: string
  updateFlag: (id: string, params: object) => void
  flagTitle: string
  isSidePanel?: boolean
}

const FlagTitle = ({ flag, pathname, updateFlag, flagTitle, isSidePanel }: IPropsFlagTitle) => {
  const [likedMenuOpen, setLikedMenuOpen] = useState(false)
  const [likedMenuAnchorEl, setLikedMenuAnchorEl] = useState(null)
  const [dislikedMenuOpen, setDislikedMenuOpen] = useState(false)
  const [dislikedMenuAnchorEl, setDislikedMenuAnchorEl] = useState(null)

  const reportLink = useMemo(() => {
    let path
    if (flag?.borrowingBase?.id) {
      path = generatePath(ROUTES.BBC_SUMMARY, { id: flag?.borrowingBase?.id })
    } else if (flag?.ongoingReportingId) {
      path = generatePath(ROUTES.ONGOING_REPORTING_SUMMARY, { id: flag.ongoingReportingId })
    }

    return path
  }, [flag])
  const breadcrumbText = useMemo(() => {
    if (flag?.borrowingBase?.id) {
      return `BBC ${formatDate(flag.borrowingBase?.recordDate)}`
    } else if (flag?.ongoingReportingId) {
      return `Financials ${formatDate(flag.ongoingReporting?.recordDate)}`
    }
  }, [flag])

  const clientLink = useMemo(
    () => (flag.clientInfo ? generatePath(ROUTES.CLIENT_PAGE, { id: flag.clientInfo.id }) : null),
    [flag],
  )

  const workflow = useMemo(() => currentWorkFlow(pathname), [pathname])

  const handleCloseLikedMenu = useCallback(() => {
    setLikedMenuAnchorEl(null)
    setLikedMenuOpen(false)
  }, [])

  const handleOpenLikedMenu = useCallback((event: React.MouseEvent<HTMLDivElement>) => {
    setLikedMenuAnchorEl(event.currentTarget)
    setDislikedMenuOpen(false)
    setLikedMenuOpen(true)
  }, [])

  const handleCloseDislikedMenu = useCallback(() => {
    setDislikedMenuAnchorEl(null)
    setDislikedMenuOpen(false)
  }, [])

  const handleOpenDislikedMenu = useCallback((event: React.MouseEvent<HTMLDivElement>) => {
    setDislikedMenuAnchorEl(event.currentTarget)
    setLikedMenuOpen(false)
    setDislikedMenuOpen(true)
  }, [])

  const tooltipMessage = useMemo(() => {
    if (flag?.masterFlag?.tooltipMessage.includes('[x]')) {
      if (flag.flagColor === FlagColors.Yellow) {
        return flag?.masterFlag?.tooltipMessage.replace(
          '[x]',
          String(flag?.masterFlag?.yellowThreshold || ''),
        )
      } else {
        return flag?.masterFlag?.tooltipMessage.replace(
          '[x]',
          String(flag?.masterFlag?.redThreshold || ''),
        )
      }
    } else {
      return flag?.masterFlag?.tooltipMessage
    }
  }, [flag])

  const handleFeedback = useCallback(
    (feedback: Feedback) => {
      updateFlag(flag.id, { feedback })
    },
    [flag, updateFlag],
  )

  const handleFeedbackLiked = useCallback(
    (e) => {
      e.preventDefault()
      e.stopPropagation()
      handleFeedback(flag?.feedback === Feedback.LIKED ? Feedback.CLEAR : Feedback.LIKED)
    },
    [handleFeedback, flag],
  )

  const handleFeedbackDisliked = useCallback(
    (e) => {
      e.preventDefault()
      e.stopPropagation()
      handleFeedback(flag?.feedback === Feedback.DISLIKED ? Feedback.CLEAR : Feedback.DISLIKED)
    },
    [handleFeedback, flag],
  )

  const flagLiked = useMemo(
    () => (flag.feedbacks as IFlagFeedback[]).filter(({ feedback }) => feedback === Feedback.LIKED),
    [flag],
  )

  const flagDisliked = useMemo(
    () =>
      (flag.feedbacks as IFlagFeedback[]).filter(({ feedback }) => feedback === Feedback.DISLIKED),
    [flag],
  )

  const isRedFlag = useMemo(() => flag?.flagColor === FlagColors.Red, [flag])

  const handleResolve = useCallback(
    (event) => {
      event?.stopPropagation()
      updateFlag(flag.id, { resolved: !flag.resolved })
    },
    [updateFlag, flag],
  )

  return (
    <div className={cn(styles.titleContainer, { [styles.titleContainerSidePanel]: isSidePanel })}>
      <div
        className={cn(styles.alertWarningContainer, {
          [styles.warningIconBackground]: !isRedFlag,
          [styles.alertIconBakcground]: isRedFlag,
          [styles.alertWarningContainerSidePanel]: isSidePanel,
        })}
      >
        {isRedFlag ? <AlertIcon /> : <WarningOutlined />}
      </div>

      <div className={cn(styles.flagTitle, { [styles.flagTitleSidePanel]: isSidePanel })}>
        <Tooltip title={tooltipMessage}>
          <div className={styles.flagTitleText}>{flagTitle}</div>
        </Tooltip>
        {isSidePanel && workflow !== WorkflowTypes.entityPage && (
          <div className={styles.modalBreadcrumbs}>
            <Link
              component={RouterLink}
              to={clientLink}
              className={styles.modalBreadcrumbItem}
              onClick={handleStopPropagation}
            >
              {flag.clientInfo?.clientName}
            </Link>

            <React.Fragment>
              <NextIcon className={styles.modalBreadcrumbItemSeparator} />
              <Link
                component={RouterLink}
                to={reportLink}
                className={styles.modalBreadcrumbItem}
                onClick={handleStopPropagation}
              >
                {breadcrumbText}
              </Link>
            </React.Fragment>
          </div>
        )}
      </div>

      <div className={styles.likeMenu}>
        <div
          className={styles.feedbackButton}
          onClick={handleFeedbackLiked}
          onMouseEnter={flagLiked.length > 0 ? handleOpenLikedMenu : null}
          onMouseLeave={flagLiked.length > 0 ? handleCloseLikedMenu : null}
        >
          <div className={styles.likeCount}>{flagLiked.length > 0 ? flagLiked.length : ''}</div>
          <ThumbsUpIcon
            className={cn({
              [styles.liked]: flag?.feedback === Feedback.LIKED,
            })}
          />

          {flagLiked.length > 0 && (
            <Popper
              open={likedMenuOpen}
              anchorEl={likedMenuAnchorEl}
              className={styles.likeMenuPopper}
            >
              <Paper elevation={24}>
                {flagLiked.map((flag) => (
                  <MenuItem key={flag.id} className={styles.feedMenuPopper} disableTouchRipple>
                    <Avatar user={flag.user} size="small" />
                    {flag.user?.firstName} {flag.user?.lastName}
                  </MenuItem>
                ))}
              </Paper>
            </Popper>
          )}
        </div>
        <div
          className={styles.feedbackButton}
          onClick={handleFeedbackDisliked}
          onMouseEnter={flagDisliked.length > 0 ? handleOpenDislikedMenu : null}
          onMouseLeave={flagDisliked.length > 0 ? handleCloseDislikedMenu : null}
        >
          <div className={styles.likeCount}>
            {flagDisliked.length > 0 ? flagDisliked.length : ''}
          </div>
          <ThumbsUpIcon
            className={cn(styles.dislikeButton, {
              [styles.disliked]: flag?.feedback === Feedback.DISLIKED,
            })}
          />

          {flagDisliked.length > 0 && (
            <Popper
              open={dislikedMenuOpen}
              anchorEl={dislikedMenuAnchorEl}
              className={styles.likeMenuPopper}
            >
              <Paper elevation={24}>
                {flagDisliked.map((flag) => (
                  <MenuItem key={flag.id} className={styles.feedMenuPopper} disableTouchRipple>
                    <Avatar user={flag.user} size="small" />
                    {flag.user?.firstName} {flag.user?.lastName}
                  </MenuItem>
                ))}
              </Paper>
            </Popper>
          )}
        </div>
        {isSidePanel && (
          <div
            className={cn(styles.resolveInDetail, {
              [styles.flagTopRowActionResolved]: flag?.resolved,
            })}
            onClick={handleResolve}
          >
            {flag?.resolved ? <CheckIconDefault /> : <CheckIcon />}
          </div>
        )}
      </div>
    </div>
  )
}

export default FlagTitle
