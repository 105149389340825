import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'

import CapTableMappingTable from './CapTableMappingTable'
import { capTableMapping } from '../../redux/capTable/selectors'
import { listMapping, updateCapTableDetails, listBoardSeats } from '../../redux/capTable/actions'
import { listEntityInfo, addEntityInfo } from '../../redux/entityInfo/actions'

import { updateAlias } from '../../redux/arapmapping/actions'

const selector = createStructuredSelector({
  capTableMapping,
})
const actions = {
  updateAlias,
  listMapping,
  listEntityInfo,
  addEntityInfo,
  updateCapTableDetails,
  listBoardSeats,
}

export default connect(selector, actions)(CapTableMappingTable)
