import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'

import DueDiligenceTeamContacts from './DueDiligenceTeamContacts'

import { dueDiligenceTeamContacts } from '../../redux/dueDiligence/selectors'
import {
  listTeamContacts,
  hideTeamContacts,
  addTeamContact,
  updateTeamContact,
  deleteTeamContact,
} from '../../redux/dueDiligence/actions'

const selector = createStructuredSelector({
  dueDiligenceTeamContacts,
})
const actions = {
  listTeamContacts,
  hideTeamContacts,
  addTeamContact,
  updateTeamContact,
  deleteTeamContact,
}

export default connect(selector, actions)(DueDiligenceTeamContacts)
