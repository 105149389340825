import React, { useMemo, useEffect, useState } from 'react'
import styles from './AccountActivityGraph.module.scss'
import { Chart } from 'react-chartjs-2'

import { formatAmount, formatPrice, getRGBFFromHex } from '../../helpers/helpers'
import Skeleton from '@mui/material/Skeleton'
import classNames from 'classnames'
import { BORDER_RADIUS, COLORS, FONT_SIZE, TOOLTIP } from '../../constants/graphs'
import { ILoadingData } from 'src/redux/types'
import { AccountActivityPeriods, IAccountActivityGraphData } from '@common/interfaces/client'
import { useParams } from 'react-router'

const LINE_FIELDS = ['Loan Balance']

interface IProps {
  accountActivityGraph: ILoadingData<IAccountActivityGraphData>
  getAccountActivityGraph: (id: string, data: object) => void
  filters: any
  startDate: string
  endDate: string
  period: AccountActivityPeriods
  isClientUser: boolean
}

const AccountActivityGraph = ({
  accountActivityGraph,
  getAccountActivityGraph,
  filters,
  startDate,
  endDate,
  period,
  isClientUser,
}: IProps) => {
  const { id } = useParams<{ id?: string }>()

  const [initialLoading, setInitialLoading] = useState(true)

  const { labels, datasets } = useMemo(
    () => ({
      labels: accountActivityGraph?.data?.labels,
      datasets: accountActivityGraph?.data?.datasets,
      isLoading: accountActivityGraph?.isLoading,
    }),
    [accountActivityGraph],
  )

  const graphFilters = useMemo(() => {
    if (isClientUser) {
      return {
        description: filters.description,
      }
    } else {
      return {
        description: filters.description,
        type: filters.type,
      }
    }
  }, [filters, isClientUser])

  useEffect(() => {
    if (startDate && endDate) {
      const data = {
        id: id || 'me',
        startDate,
        endDate,
        period,
        filters: graphFilters,
      }
      getAccountActivityGraph(id, data)
    }
  }, [id, getAccountActivityGraph, startDate, endDate, period, graphFilters])

  useEffect(() => {
    if (accountActivityGraph) {
      setInitialLoading(false)
    }
  }, [accountActivityGraph])

  const chartData = useMemo(() => {
    if (datasets && labels) {
      return {
        labels,
        stepped: true,
        datasets: [
          ...datasets.map((dataset, index) => ({
            label: dataset.label,
            data: dataset.data,
            backgroundColor: getRGBFFromHex(COLORS[index], 1),
            borderRadius: BORDER_RADIUS,
            type:
              LINE_FIELDS.includes(dataset.label) || isClientUser
                ? ('line' as const)
                : ('bar' as const),
            order: LINE_FIELDS.includes(dataset.label) || isClientUser ? 0 : 1,
            pointRadius: 2,
            pointHoverRadius: 4,
            pointHoverBorderWidth: 3,
            pointBorderWidth: 3,
            showLine: true,
            pointBorderColor: COLORS[index],
            borderColor: COLORS[index],
            tension: 0.4,
          })),
        ],
      }
    } else {
      return null
    }
  }, [labels, datasets, isClientUser])

  const chartOptions = useMemo(() => {
    return {
      maintainAspectRatio: false,
      scales: {
        x: {
          stacked: false,
          grid: {
            display: false,
          },
          ticks: {
            font: {
              size: FONT_SIZE,
            },
          },
        },
        y: {
          stacked: false,
          grid: {
            display: false,
          },
          ticks: {
            font: {
              size: FONT_SIZE,
            },
            callback: (value: any) => {
              return formatAmount(value) ? `$${formatAmount(value)}` : `$0`
            },
          },
        },
      },
      plugins: {
        legend: {
          position: 'top' as const,
          labels: {
            font: {
              size: FONT_SIZE,
              weight: '500',
            },
            color: 'black',
            boxWidth: 20,
            boxHeight: 20,
          },
        },
        tooltip: {
          ...TOOLTIP,
          itemSort: (a: any, b: any) => {
            return a.datasetIndex - b.datasetIndex
          },
          callbacks: {
            label: (tooltipItem: any) => {
              const value = tooltipItem.parsed.y

              const title = tooltipItem.dataset.label
              return `${title}: $${formatPrice(value, 0)}`
            },
          },
        },
      },
    }
  }, [])

  return (
    <div className={classNames(styles.chartContainer)}>
      {initialLoading ? (
        <Skeleton variant="rectangular" width="100%" height="100%" />
      ) : (
        chartData && (
          <Chart type="bar" className={styles.chart} data={chartData} options={chartOptions} />
        )
      )}
    </div>
  )
}

export default AccountActivityGraph
