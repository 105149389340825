import { isAdminLoggedAsClient } from './../../redux/profile/selectors'
import { boxViewLink } from './../../redux/box/selectors'
import { setBoxViewerLink } from './../../redux/box/actions'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import BoxViewer from './BoxViewer'

const selector = createStructuredSelector({
  boxViewLink,
  isAdminLoggedAsClient,
})

const actions = {
  setBoxViewerLink,
}

export default connect(selector, actions)(BoxViewer)
