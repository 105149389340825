import { combineReducers } from 'redux'
import * as actions from './actions'
import { IFileHeaderData } from '@common/interfaces/file'
import { createLoadingDataReducer } from '../../helpers/redux'
import { ILoadingData } from '../types'

export interface IFileState {
  loadingArray: string[]
  headerMappings: ILoadingData<IFileHeaderData>
}

export default combineReducers<IFileState>({
  loadingArray(state: string[] = [], action) {
    switch (action.type) {
      case actions.UPLOAD_REQUEST:
      case actions.UPDATE_REQUEST:
      case actions.DELETE_REQUEST:
        return [...state, action.type]
      case actions.UPLOAD_SUCCESS:
      case actions.UPLOAD_FAILURE:
      case actions.UPDATE_SUCCESS:
      case actions.UPDATE_FAILURE:
      case actions.DELETE_SUCCESS:
      case actions.DELETE_FAILURE:
        return state.filter(
          (item) =>
            item !== action.type.replace('SUCCESS', 'REQUEST').replace('FAILURE', 'REQUEST'),
        )
      default:
        return state
    }
  },
  headerMappings: createLoadingDataReducer<IFileHeaderData>(
    [actions.LIST_HEADER_MAPPING_REQUEST],
    [actions.UPDATE_HEADER_MAPPING_REQUEST, actions.CREATE_HEADER_MAPPING_SUCCESS],
    true,
  ),
})
