import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'

import LoanCommitteeReportTransactionPage from './LoanCommitteeReportTransactionPage'

import { dueDiligenceInfo } from '../../redux/dueDiligence/selectors'
import { listDilutionDropdowns } from '../../redux/generalLedger/actions'

const selectors = createStructuredSelector({
  dueDiligenceInfo,
})

const actions = {
  listDilutionDropdowns,
}

export default connect(selectors, actions)(LoanCommitteeReportTransactionPage)
