import Base from './base'

export default class File extends Base {
  upload(data: FormData) {
    return this.apiClient.post('files', data)
  }

  update(id: string, data: object) {
    return this.apiClient.put(`files/${id}`, data)
  }

  delete(id: string | string[]) {
    if (Array.isArray(id)) {
      return this.apiClient.delete('files', { ids: id })
    }
    return this.apiClient.delete(`files/${id}`)
  }

  listHeaderMapping(params: object) {
    return this.apiClient.get(`files/header-mapping`, params)
  }

  createHeaderMapping(data: object) {
    return this.apiClient.post(`files/header-mapping`, data)
  }

  updateHeaderMapping(id: string, data: object) {
    return this.apiClient.put(`files/header-mapping/${id}`, data)
  }
}
