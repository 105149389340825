import { IClientInfo } from './client'
import { IBorrowingBaseIndex, IOngoingReporting } from './bbc'
import { IFee } from './loanServicing'
import { IUser } from './user'
import { IMasterCollection } from './collection'
import { INoteMapping } from './notes'
import { IEntityInfo } from './entityInfo'
import { IParticipant } from './participant'

export enum SearchType {
  Client = 'Client',
  Entity = 'Entity',
  Funding = 'Borrowing Base',
  Prospect = 'Prospect',
  DueDiligence = 'Due Diligence',
  Collection = 'Collection',
  Fee = 'Fee',
  Note = 'Note',
  User = 'User',
  OngoingReporting = 'Ongoing Reporting',
  Participant = 'Participant',
}

export const GLOBAL_SEARCH_TYPE_TITLES = {
  [SearchType.Client]: 'Clients',
  [SearchType.DueDiligence]: 'Due Diligence',
  [SearchType.Prospect]: 'Prospects',
  [SearchType.Entity]: 'Entities',
  [SearchType.Funding]: 'Borrowing Bases',
  [SearchType.Collection]: 'Collections',
  [SearchType.Fee]: 'Fees',
  [SearchType.Note]: 'Notes',
  [SearchType.User]: 'Users',
  [SearchType.OngoingReporting]: 'Ongoing Reporting',
  [SearchType.Participant]: 'Participants',
}

export interface ISearchResultItem {
  id: string
  title: string
  type: SearchType
  link: string
  status: string
  item:
    | IClientInfo
    | IBorrowingBaseIndex
    | IMasterCollection
    | IFee
    | INoteMapping
    | IOngoingReporting
    | IUser
    | IEntityInfo
    | IParticipant
}

export interface ISearchResult {
  type: SearchType
  total: number
  maxScore: number
  items: ISearchResultItem[]
}

export const SEARCH_LIMIT = 3
