import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'

import DueDiligenceApplicationTableBoardMembers from './DueDiligenceApplicationTableBoardMembers'

import { dueDiligenceTeamBoardMembers } from '../../redux/dueDiligence/selectors'
import { listTeamBoardMembers, hideTeamBoardMembers } from '../../redux/dueDiligence/actions'

const selector = createStructuredSelector({
  dueDiligenceTeamBoardMembers,
})
const actions = {
  listTeamBoardMembers,
  hideTeamBoardMembers,
}

export default connect(selector, actions)(DueDiligenceApplicationTableBoardMembers)
