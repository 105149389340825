import React, { useCallback, useMemo, useState } from 'react'
import { Link as RouterLink } from 'react-router-dom'
import Popover from '@mui/material/Popover'
import Link from '@mui/material/Link'

import styles from './NotificationPane.module.scss'

import { ReactComponent as NotificationIcon } from '@assets/images/notification-light-icon.svg'
import { INotification, INotificationData } from '@common/interfaces/notification'
import { ROUTES } from '../../constants/routes'
import {
  NotificationItemAvatar,
  NotificationItemDate,
  NotificationItemContent,
  NotificationItemUnreadIcon,
  generateNotificationLocation,
} from '../NotificationItem'
import { UserRole } from '@common/interfaces/user'
import { usePermissions } from '../../helpers/permissionContext'

interface IProps {
  isLoading: boolean
  notifications: INotificationData
  markAsRead: (params: { notificationIds: string[]; all?: boolean }) => void
}

const NotificationItem = ({
  notification,
  generateNotificationLink,
  handleClick,
}: {
  notification: INotification
  generateNotificationLink: (notification: INotification) => string
  handleClick: () => void
}) => {
  const { user, isRead, createdAt } = notification
  const link = useMemo(
    () => generateNotificationLink(notification),
    [notification, generateNotificationLink],
  )
  const isClientUser = useMemo(() => user?.role === UserRole.CLIENT_USER || !user, [user])

  return (
    <Link
      component={RouterLink}
      to={link}
      className={styles.notificationItemLink}
      onClick={handleClick}
    >
      <div className={styles.notificationItem}>
        <NotificationItemAvatar
          className={styles.notificationItemAvatar}
          user={user}
          clientInfo={isClientUser ? notification.clientInfo : null}
        />
        <div className={styles.notificationItemContent}>
          <NotificationItemContent
            className={styles.notificationItemText}
            notification={notification}
            isFull={false}
          />
          <div className={styles.notificationItemDate}>
            <NotificationItemDate createdAt={createdAt} />
          </div>
        </div>
        {!isRead && <NotificationItemUnreadIcon />}
      </div>
    </Link>
  )
}

const NotificationPane = ({ notifications, markAsRead }: IProps) => {
  const [anchorEl, setAnchorEl] = useState(null)
  const { isAdmin, isBDO, isUW, isClientUser } = usePermissions()

  const unreadNotificationIds = useMemo(
    () => notifications?.data.filter(({ isRead }) => !isRead).map(({ id }) => id) || [],
    [notifications],
  )

  const markNotificationAsRead = useCallback(() => {
    if (unreadNotificationIds.length) {
      markAsRead({
        notificationIds: unreadNotificationIds,
      })
    }
  }, [unreadNotificationIds, markAsRead])

  const handleOpen = useCallback((event: React.MouseEvent<HTMLDivElement>) => {
    setAnchorEl(event.currentTarget)
  }, [])

  const handleClose = useCallback(() => {
    markNotificationAsRead()
    setAnchorEl(null)
  }, [markNotificationAsRead])

  const generateNotificationLink = useCallback(
    (notification: INotification) =>
      generateNotificationLocation(notification, isAdmin, isBDO, isUW, isClientUser),
    [isAdmin, isBDO, isUW, isClientUser],
  )

  if (!notifications) {
    return null
  }

  return (
    <>
      <div className={styles.wrapper} onClick={handleOpen}>
        <NotificationIcon />
        {notifications.totalUnread > 0 && (
          <span className={styles.countBadge}>{notifications.totalUnread}</span>
        )}
      </div>

      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 52,
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        classes={{ paper: styles.popover }}
      >
        {notifications.total ? (
          <>
            <div className={styles.header}>
              Notifications {notifications.totalUnread > 0 && `(${notifications.totalUnread})`}
              <Link
                component={RouterLink}
                to={ROUTES.NOTIFICATIONS}
                className={styles.linkButton}
                onClick={handleClose}
              >
                See all
              </Link>
            </div>

            <div className={styles.notificationsList}>
              {notifications.data.map((notification) => (
                <NotificationItem
                  key={notification.id}
                  notification={notification}
                  generateNotificationLink={generateNotificationLink}
                  handleClick={handleClose}
                />
              ))}
            </div>
          </>
        ) : (
          <div className={styles.notificationsEmpty}>
            You don't have any notification at the moment
          </div>
        )}
      </Popover>
    </>
  )
}

export default NotificationPane
