import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'

import ClientAliasMapping from './ClientAliasMapping'

import { updateAlias, deleteAlias } from '../../redux/arapmapping/actions'
import {
  listEntityInfo,
  addEntityInfo,
  listEntityAliases,
  listAliasMapping,
  hideAliases,
} from '../../redux/entityInfo/actions'
import { entities, aliases } from '../../redux/entityInfo/selectors'

const selector = createStructuredSelector({
  entities,
  aliases,
})

const actions = {
  updateAlias,
  deleteAlias,
  listEntityInfo,
  addEntityInfo,
  listAliasMapping,
  listEntityAliases,
  hideAliases,
}

export default connect(selector, actions)(ClientAliasMapping)
