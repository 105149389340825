import React, { useCallback, useEffect, useState, useMemo } from 'react'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import Tooltip from '@mui/material/Tooltip'
import { Form } from 'react-final-form'
import moment from 'moment'
import InfiniteScroll from 'react-infinite-scroll-component'
import styles from './AuditPage.module.scss'
import genericSs from '@styles/generic.module.scss'
import Loader from '../../components/Loader'
import { dateTimeToString, debounceEventHandler, formatDateTime } from '../../helpers/helpers'
import Card from '../../components/Common/Card'
import { IUser } from '@common/interfaces/user'
import { IAuditLogData, IAuditLogDictionary } from '@common/interfaces/auditLog'
import Table from '../../components/Common/Table'
import TableHead from '../../components/Common/TableHead'
import TableRow from '../../components/Common/TableRow'
import TableCell from '../../components/Common/TableCell'
import TableContainer from '../../components/Common/TableContainer'
import TableBody from '../../components/Common/TableBody'
import TableFiltersRow from '../../components/Common/TableFiltersRow'
import { buildFiltersDefaults, buildFiltersValidateSchema } from '../../helpers/filters'
import { AUDIT_LOG_LIST_FILTERS_CONFIG, PER_PAGE } from '@common/constants/filters'
import FilterContainer from '../../components/Filters/FilterContainer'
import { ILoadingData } from '../../redux/types'
import { useSetPageTitle } from '../../hooks/useSetPageTitle'

const filtersValidate = buildFiltersValidateSchema(AUDIT_LOG_LIST_FILTERS_CONFIG)
const filtersDefaults = buildFiltersDefaults(AUDIT_LOG_LIST_FILTERS_CONFIG)

interface IProps {
  isLoading: boolean
  data: IAuditLogData
  dictionary: IAuditLogDictionary
  users: ILoadingData<{ data: IUser[] }>
  listAuditLog: (params?: {
    page?: number
    perPage?: number
    filters?: object
    orderBy?: string
    orderDirection?: string
  }) => void
  exportAuditLog: (params?: {
    page?: number
    perPage?: number
    filters?: object
    orderBy?: string
    orderDirection?: string
  }) => void
  listUsers: (params: object) => void
  getAuditLogDictionary: () => void
}

const ActivityQueuePage = ({
  isLoading,
  data,
  dictionary,
  users,
  listAuditLog,
  exportAuditLog,
  listUsers,
  getAuditLogDictionary,
}: IProps) => {
  const [filters, setFilters] = useState(filtersDefaults)
  const [orderBy, setOrderBy] = useState({
    field: 'created_at',
    direction: 'DESC',
  })

  const { usersData } = useMemo(
    () => ({
      usersData: users?.data?.data,
    }),
    [users],
  )

  useSetPageTitle('Audit Log')

  useEffect(() => {
    listUsers({ withCurrent: true })
  }, [listUsers])

  useEffect(() => {
    getAuditLogDictionary()
  }, [getAuditLogDictionary])

  const fetchAuditLogList = useCallback(
    async (values: any) => {
      const params = {
        ...values,
        filters: {
          ...values.filters,
        },
      }
      if (params?.filters.createdAtFrom && typeof params.filters.createdAtFrom !== 'string') {
        params.filters.createdAtFrom = dateTimeToString(params.filters.createdAtFrom)
      }
      if (params?.filters.createdAtTo && typeof params.filters.createdAtTo !== 'string') {
        params.filters.createdAtTo = dateTimeToString(params.filters.createdAtTo)
      }
      listAuditLog(params)
    },
    [listAuditLog],
  )

  const debounceListAuditLog = useMemo(
    () => debounceEventHandler(fetchAuditLogList, 500),
    [fetchAuditLogList],
  )

  useEffect(() => {
    debounceListAuditLog({
      page: 0,
      perPage: PER_PAGE,
      filters,
      orderBy: orderBy.field,
      orderDirection: orderBy.direction,
    })
  }, [filters, orderBy, debounceListAuditLog])

  const handleFiltersChange = useCallback((params: any) => {
    setFilters(params)
  }, [])

  const handleOrderChange = useCallback((field: string) => {
    setOrderBy((order) => ({
      field,
      direction: order.field === field ? (order.direction === 'DESC' ? 'ASC' : 'DESC') : 'ASC',
    }))
  }, [])

  const tablesOptions = useMemo(
    () =>
      dictionary && dictionary.tables
        ? dictionary.tables.map((activity) => ({
            value: activity,
            label: activity,
          }))
        : [],
    [dictionary],
  )

  const clients = useMemo(
    () =>
      dictionary && dictionary.clientNames
        ? dictionary.clientNames.filter(Boolean).map((clientName) => ({
            value: clientName,
            label: clientName,
          }))
        : [],
    [dictionary],
  )

  const isValidRange = useMemo(
    () =>
      filters.createdAtFrom &&
      filters.createdAtTo &&
      moment(filters.createdAtFrom).add(7, 'days').isSameOrAfter(moment(filters.createdAtTo)),
    [filters],
  )

  const handleExport = useCallback(async () => {
    if (!isValidRange) {
      return
    }

    const params = {
      page: 0,
      perPage: 0,
      filters: {
        ...filters,
      },
      orderBy: orderBy.field,
      orderDirection: orderBy.direction,
    }
    if (params?.filters.createdAtFrom && typeof params.filters.createdAtFrom !== 'string') {
      params.filters.createdAtFrom = dateTimeToString(params.filters.createdAtFrom)
    }
    if (params?.filters.createdAtTo && typeof params.filters.createdAtTo !== 'string') {
      params.filters.createdAtTo = dateTimeToString(params.filters.createdAtTo)
    }

    await exportAuditLog(params)
  }, [exportAuditLog, isValidRange, filters, orderBy])

  const filtersConfig = useMemo(
    () =>
      AUDIT_LOG_LIST_FILTERS_CONFIG.map((item) => ({
        ...item,
        options:
          item.field === 'clients'
            ? clients
            : item.field === 'users' && usersData
            ? [{ id: 'system', firstName: 'System', lastName: '' }]
                .concat(usersData)
                .map(({ id, firstName, lastName }) => ({
                  value: id,
                  label: `${firstName} ${lastName}`,
                }))
            : item.field === 'tables'
            ? tablesOptions
            : undefined,
      })),
    [clients, usersData, tablesOptions],
  )

  const loadMore = useCallback(() => {
    fetchAuditLogList({
      loadMore: true,
      page: Math.ceil(data?.data.length / PER_PAGE),
      perPage: PER_PAGE,
      orderBy: orderBy.field,
      orderDirection: orderBy.direction,
      filters,
    })
  }, [data, orderBy, filters, fetchAuditLogList])

  return (
    <Box py={1} pr={2}>
      {isLoading && <Loader />}

      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Card withBorder={false} noHeaderMargin>
            <TableContainer className={styles.table}>
              <Form
                validate={filtersValidate}
                onSubmit={handleFiltersChange}
                initialValues={filters}
                mutators={{
                  setFieldData: ([field, value], state, { changeValue }) => {
                    changeValue(state, field, () => value)
                  },
                }}
                render={({ values, handleSubmit, form: { mutators } }) => (
                  <FilterContainer
                    filters={filtersConfig}
                    handleSubmit={handleSubmit}
                    mutators={mutators}
                    values={values}
                    appliedFilters={filters}
                    handleExportAggregation={handleExport}
                  />
                )}
              />
              <Table>
                <TableHead>
                  <TableFiltersRow
                    filters={filtersConfig}
                    orderBy={orderBy}
                    handleOrderChange={handleOrderChange}
                  />
                </TableHead>
                <TableBody id="scrollableTable">
                  {data?.data && (
                    <InfiniteScroll
                      dataLength={data?.data.length}
                      next={loadMore}
                      hasMore
                      loader={
                        <TableRow>
                          <TableCell colSpan={9}>Loading...</TableCell>
                        </TableRow>
                      }
                      scrollableTarget="scrollableTable"
                    >
                      {data.data.map((item) => (
                        <TableRow key={item.id}>
                          <TableCell className={genericSs.tableTextLeft}>
                            <Tooltip title={item.id} placement="top">
                              <span>{item.id}</span>
                            </Tooltip>
                          </TableCell>
                          <TableCell className={genericSs.tableTextLeft}>
                            {item.clientName}
                          </TableCell>
                          <TableCell className={genericSs.tableTextLeft}>{item.activity}</TableCell>
                          <TableCell className={genericSs.tableTextLeft}>
                            {item.user ? `${item.user.firstName} ${item.user.lastName}` : 'System'}
                          </TableCell>
                          <TableCell className={genericSs.tableTextLeft}>{item.table}</TableCell>
                          <TableCell className={genericSs.tableTextLeft}>{item.column}</TableCell>
                          <TableCell className={genericSs.tableTextLeft}>
                            <Tooltip title={item.oldValue} placement="top">
                              <span>{item.oldValue}</span>
                            </Tooltip>
                          </TableCell>
                          <TableCell className={genericSs.tableTextLeft}>
                            <Tooltip title={item.newValue} placement="top">
                              <span>{item.newValue}</span>
                            </Tooltip>
                          </TableCell>
                          <TableCell className={genericSs.tableTextRight}>
                            {formatDateTime(item.createdAt)}
                          </TableCell>
                        </TableRow>
                      ))}
                    </InfiniteScroll>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Card>
        </Grid>
      </Grid>
    </Box>
  )
}

export default ActivityQueuePage
