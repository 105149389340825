import React from 'react'
import { Field, Form } from 'react-final-form'
import Box from '@mui/material/Box'
import styles from './UserManagement.module.scss'
import Checkbox from '../../Common/Checkbox'
import TextField from '../../Common/TextField'
import Button from '../../Common/Button'
import { IUser, UserStatus } from '@common/interfaces/user'
import Modal from '../../Common/Modal'
import InputLabel from '../../Common/InputLabel'
import TableFields from '../../TableFields'
import { makeValidate } from 'mui-rff'
import * as Yup from 'yup'
import SelectField from '../../Common/SelectField'

const schema = Yup.object().shape({
  firstName: Yup.string().required('Required'),
  lastName: Yup.string().required('Required'),
  email: Yup.string().email().required('Required'),
})

const validate = makeValidate(schema)

interface IProps {
  isEditModalShown: boolean
  isParticipant?: boolean
  participantCompanies?: string[]
  setIsEditModalShown: (val: boolean) => void
  handleCreateAddUser: (data: any & { clientId: string } & { id?: string }) => void
  user?: IUser
  handleCloseMenu?: () => void
  isButtonLoading: boolean
}

const AddEditUser = ({
  isEditModalShown,
  isParticipant,
  participantCompanies,
  setIsEditModalShown,
  handleCreateAddUser,
  user,
  handleCloseMenu,
  isButtonLoading,
}: IProps) => {
  return (
    <Form
      onSubmit={handleCreateAddUser}
      initialValues={{
        firstName: user?.firstName,
        lastName: user?.lastName,
        email: user?.email,
        isAuthorizedSignature: user?.isAuthorizedSignature,
        isViewer: user?.isViewer,
        isReportingReminderReceiver: user?.isReportingReminderReceiver,
        id: user?.id,
        companies: user
          ? user?.participantInfoUser?.participations?.map(({ clientName }) => clientName)
          : participantCompanies,
      }}
      validate={validate}
      render={({ dirty, invalid, form, handleSubmit }) => (
        <form>
          <Modal
            open={isEditModalShown}
            onCancel={() => {
              if (user) {
                handleCloseMenu()
              }
              setIsEditModalShown(false)
            }}
            title={user ? 'Edit user' : 'Add user'}
            classes={{
              root: styles.editModal,
              footer: styles.editModalFooter,
              title: styles.editModalTitle,
            }}
            footer={[
              <Button
                key="submit"
                color="primary"
                variant="contained"
                className={styles.submitModalButton}
                disabled={!dirty || invalid}
                isLoading={isButtonLoading}
                onClick={(data) => {
                  handleSubmit(data).then(() => {
                    form.restart()
                    if (user) {
                      handleCloseMenu()
                    }
                  })
                }}
              >
                {user ? 'Save & update' : 'Add user'}
              </Button>,
            ]}
          >
            <Box className={styles.fieldsContainer}>
              <TableFields
                rows={[
                  [
                    <InputLabel
                      key="firstNameLabel"
                      htmlFor="firstName"
                      size="normal"
                      position="horizontal"
                    >
                      First Name
                    </InputLabel>,
                  ],
                  [<TextField key="firstNameInput" required name="firstName" />],
                  [
                    <InputLabel
                      key="lastNameLabel"
                      htmlFor="lastName"
                      size="normal"
                      position="horizontal"
                    >
                      Last Name
                    </InputLabel>,
                  ],
                  [<TextField key="lastNameInput" required name="lastName" />],
                  [
                    <InputLabel
                      key="emailLabel"
                      htmlFor="email"
                      size="normal"
                      position="horizontal"
                    >
                      Email
                    </InputLabel>,
                  ],
                  [<TextField key="emailInput" required name="email" />],
                  isParticipant
                    ? [
                        <InputLabel
                          key="companiesLabel"
                          htmlFor="companies"
                          size="normal"
                          position="horizontal"
                        >
                          Client Access
                        </InputLabel>,
                      ]
                    : [],
                  isParticipant
                    ? [
                        <SelectField
                          multiple
                          name="companies"
                          options={participantCompanies?.map((item: string) => ({
                            value: item,
                            label: item,
                          }))}
                        />,
                      ]
                    : [],
                ]}
              />
            </Box>
            <Box className={styles.checkboxContainer}>
              <TableFields
                rows={[
                  user
                    ? []
                    : [
                        <Field
                          key="sendInviteInput"
                          name="sendInvite"
                          type="checkbox"
                          component={Checkbox}
                        />,
                        <InputLabel
                          key="sendInviteLabel"
                          htmlFor="sendInvite"
                          size="normal"
                          position="horizontal"
                        >
                          Send invite
                        </InputLabel>,
                      ],
                  isParticipant
                    ? []
                    : [
                        <Field
                          key="isAuthorizedSignatureInput"
                          name="isAuthorizedSignature"
                          type="checkbox"
                          component={Checkbox}
                        />,
                        <InputLabel
                          key="isAuthorizedSignatureLabel"
                          htmlFor="isAuthorizedSignature"
                          size="normal"
                          position="horizontal"
                        >
                          Authorized signor
                        </InputLabel>,
                      ],
                  user?.status === UserStatus.Active && !isParticipant
                    ? [
                        <Field
                          key="isReportingReminderReceiverInput"
                          name="isReportingReminderReceiver"
                          type="checkbox"
                          component={Checkbox}
                        />,
                        <InputLabel
                          key="isReportingReminderReceiverLabel"
                          htmlFor="isReportingReminderReceiver"
                          size="normal"
                          position="horizontal"
                        >
                          Reporting Reminders
                        </InputLabel>,
                      ]
                    : [],
                  isParticipant
                    ? []
                    : [
                        <Field
                          key="isViewerInput"
                          name="isViewer"
                          type="checkbox"
                          component={Checkbox}
                        />,
                        <InputLabel
                          key="isViewerLabel"
                          htmlFor="isViewer"
                          size="normal"
                          position="horizontal"
                        >
                          BBC Viewer
                        </InputLabel>,
                      ],
                ]}
              />
            </Box>
          </Modal>
        </form>
      )}
    />
  )
}

export default AddEditUser
