import React from 'react'
import { generatePath, Link } from 'react-router-dom'
import Box from '@mui/material/Box'
import MuiAvatar from '@mui/material/Avatar'
import LinkButton from '@mui/material/Link'

import styles from './GlobalSearchPage.module.scss'
import genericSs from '@styles/generic.module.scss'

import { IMasterCollection } from '@common/interfaces/collection'
import TableRow from '../../components/Common/TableRow'
import TableCell from '../../components/Common/TableCell'
import { ROUTES } from '../../constants/routes'
import { formatDate, formatPrice } from '../../helpers/helpers'
import { BoxLink } from '../../components/Common/Icons'
import { replaceJSX } from './GlobalSearchPage'

const GlobalSearchPageTableRowCollection = ({
  collection,
  searchString,
}: {
  collection: IMasterCollection
  searchString: string
}) => {
  return (
    <TableRow>
      <TableCell className={genericSs.tableTextLeft}>
        <Box display="flex" alignItems="center" gap={2}>
          {collection.clientIconUrl && (
            <MuiAvatar
              src={collection.clientIconUrl}
              alt={collection.clientName}
              className={styles.clientAvatar}
            />
          )}
          <LinkButton
            component={Link}
            to={generatePath(ROUTES.CLIENT_PAGE, { id: collection.clientId })}
          >
            {replaceJSX(collection.clientName, searchString)}
          </LinkButton>
        </Box>
      </TableCell>
      <TableCell className={genericSs.tableTextRight}>
        <span className={genericSs.pricePrefix}>$</span>
        {replaceJSX(formatPrice(collection.paymentAmount), searchString)}
      </TableCell>
      <TableCell className={genericSs.tableTextRight}>
        {replaceJSX(formatDate(collection.recordDate), searchString)}
      </TableCell>
      <TableCell className={genericSs.tableTextLeft}>
        {collection.entityId ? (
          <LinkButton
            component={Link}
            to={generatePath(ROUTES.ENTITY_PAGE, { id: collection.entityId })}
          >
            {replaceJSX(collection.debtor, searchString)}
          </LinkButton>
        ) : (
          replaceJSX(collection.debtor, searchString)
        )}
      </TableCell>
      <TableCell className={genericSs.tableTextLeft}>
        {collection.boxLink && <BoxLink link={collection.boxLink} />}
      </TableCell>
    </TableRow>
  )
}

export default GlobalSearchPageTableRowCollection
