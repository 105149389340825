import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'

import GlobalSearch from './GlobalSearch'

import { isLoading } from '../../redux/search/selectors'
import { search } from '../../redux/search/actions'
import { isBDO } from '../../redux/profile/selectors'

const selector = createStructuredSelector({
  isLoading,
  isBDO,
})
const actions = {
  search,
}

export default connect(selector, actions)(GlobalSearch)
