import { Action } from 'redux'
import { takeEvery, put } from 'redux-saga/effects'
import { EXPORT_BANK_TRANSACTIONS_SUCCESS, EXPORT_BANK_TRANSACTIONS_FAILURE } from './actions'
import { IResponse } from '@common/interfaces/request'
import { download } from '../../helpers/helpers'

export function* exportBankTransactions() {
  yield takeEvery(EXPORT_BANK_TRANSACTIONS_SUCCESS, function* (action: IResponse & Action) {
    try {
      const fileName = action.data.name
      yield download(action.data, fileName)
    } catch (error) {
      yield put({ type: EXPORT_BANK_TRANSACTIONS_FAILURE, error })
    }
  })
}
