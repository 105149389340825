import { connect } from 'react-redux'
import LoanCommitteeReportOverviewPage from './LoanCommitteeReportOverviewPage'
import { dueDiligenceInfo, isLoading } from './../../redux/dueDiligence/selectors'
import { createStructuredSelector } from 'reselect'

const structuredSelector = createStructuredSelector({
  dueDiligenceInfo,
  isLoading,
})

export default connect(structuredSelector, null)(LoanCommitteeReportOverviewPage)
