import React, { useMemo } from 'react'
import Tooltip from '@mui/material/Tooltip'

import styles from './ArchivedChip.module.scss'

import { ReactComponent as ArchivedIcon } from '@assets/images/archived-icon.svg'
import { ClientInfoStatus, IClientInfo } from '@common/interfaces/client'
import { formatDateTime } from '../../../helpers/helpers'

interface IProps {
  clientInfo: IClientInfo
  isArchived?: boolean
}

const ArchivedChip = ({ clientInfo, isArchived }: IProps) => {
  const isPastOrArchived = useMemo(() => {
    return (
      [ClientInfoStatus.Past, ClientInfoStatus.Archived, ClientInfoStatus.PastArchived].includes(
        clientInfo?.clientStatus,
      ) || isArchived
    )
  }, [clientInfo, isArchived])

  if (!clientInfo || !isPastOrArchived) {
    return null
  }

  return (
    <Tooltip title={clientInfo.archivedAt ? formatDateTime(clientInfo.archivedAt) : ''}>
      <div className={styles.archivedChip}>
        <ArchivedIcon className={styles.archivedChipIcon} />
        {clientInfo.clientStatus === ClientInfoStatus.Past ? 'Past' : 'Archived'}
      </div>
    </Tooltip>
  )
}

export default ArchivedChip
