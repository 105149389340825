import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import ProspectReportingPayablesPage from './ProspectReportingPayablesPage'

import { reporting, apSummary, date } from '../../redux/prospect/selectors'
import {
  show,
  startReporting,
  listAPSummary,
  updateAPDate,
  getAPDate,
} from '../../redux/prospect/actions'

const selector = createStructuredSelector({
  reporting,
  apSummaryData: apSummary,
  date,
})
const actions = {
  show,
  listAPSummary,
  startReporting,
  updateDate: updateAPDate,
  getDate: getAPDate,
}

export { ProspectReportingPayablesPage }

export default connect(selector, actions)(ProspectReportingPayablesPage)
