import React from 'react'
import { useParams } from 'react-router'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'

import ClientSetupHeader from '../../components/ClientSetupHeader'
import UserManagement from '../../components/Client/UserManagement'

const ClientSetupUsersPage = () => {
  const { id: clientId } = useParams<{ id?: string }>()

  return (
    <Box py={1} pr={2}>
      <ClientSetupHeader />

      <Box>
        <Grid container spacing={1}>
          <Grid item xs={12} lg={12}>
            <UserManagement clientId={clientId} />
          </Grid>
        </Grid>
      </Box>
    </Box>
  )
}

export default ClientSetupUsersPage
