import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'

import ClientSetupRelationshipManagerPage from './ClientSetupRelationshipManagerPage'
import { clientInfo } from '../../redux/clientInfo/selectors'

const selector = createStructuredSelector({
  clientInfo,
})

export default connect(selector, null)(ClientSetupRelationshipManagerPage)
