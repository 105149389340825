import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'

import Documents from './Documents'
import { listFolders } from '../../../redux/box/actions'
import { folders } from '../../../redux/box/selectors'
import { updateClient, listDocuments } from '../../../redux/clientInfo/actions'
import { clientInfo, documents } from '../../../redux/clientInfo/selectors'

const selector = createStructuredSelector({
  folders,
  clientInfo,
  documents,
})

const actions = {
  listFolders,
  updateClient,
  listDocuments,
}

export default connect(selector, actions)(Documents)
