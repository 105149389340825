import React, { useCallback, useMemo, useState } from 'react'
import { useHistory, useLocation } from 'react-router'
import Tooltip from '@mui/material/Tooltip'
import queryString from 'query-string'

import { ReactComponent as ChartIcon } from '@assets/images/chart-icon.svg'
import { ReactComponent as TableIcon } from '@assets/images/table-icon.svg'
import { clarity } from 'react-microsoft-clarity'
import Tabs from '../components/Common/Tabs'

export enum GraphType {
  Table = 'Table',
  Chart = 'Chart',
}

const TABS = [GraphType.Table, GraphType.Chart]

const TAB_ICONS = {
  Table: (
    <Tooltip title="Table" placement="top">
      <TableIcon />
    </Tooltip>
  ),
  Chart: (
    <Tooltip title="Chart" placement="top">
      <ChartIcon
        style={{
          height: '20px',
          width: '20px',
        }}
      />
    </Tooltip>
  ),
}

interface IProps {
  defaultTab?: string
}

const useGraphToggle = ({ defaultTab = TABS[0] }: IProps | undefined) => {
  const [tabValue, setTabValue] = useState(defaultTab)

  const history = useHistory()
  const location = useLocation()
  const { search } = location

  const handleTabChange = useCallback(
    (tab: string) => {
      clarity.setTag('Graph Type', tab)
      setTabValue(tab)

      const currentSearch = queryString.parse(search, {
        parseNumbers: true,
        parseBooleans: true,
      })
      history.replace({
        search: queryString.stringify({
          ...currentSearch,
          view: tab,
        }),
      })
    },
    [history, search],
  )

  const isGraphShown = useMemo(() => tabValue === TABS[1], [tabValue])

  return {
    isGraphShown,
    TabsComponent: (
      <Tabs tabs={TABS} tabsIcon={TAB_ICONS} selected={tabValue} handleChange={handleTabChange} />
    ),
  }
}

export default useGraphToggle
