import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'

import TypeMapping from './TypeMapping'

import { updateTypeMapping, listTypes } from '../../redux/generalLedger/actions'
import { typeOptions } from '../../redux/generalLedger/selectors'

const selector = createStructuredSelector({
  typeOptions,
})

const actions = {
  updateTypeMapping,
  listTypes,
}

export default connect(selector, actions)(TypeMapping)
