import { ClientInfoStatus } from '@common/interfaces/client'
import { createSelector } from 'reselect'
import { prefix } from './actions'
import { IRootState } from '../reducer'
import { UserRole, USER_ROLE_LABEL } from '@common/interfaces/user'

const { ADMIN, PORTFOLIO_ADMIN, CLIENT_USER, PORTFOLIO_USER, UW_USER, BDO, PARTICIPANT_USER } =
  UserRole

const profile = (state: IRootState) => state[prefix]

export const isInitialized = createSelector(profile, ({ isInitialized }) => isInitialized)

export const isLoading = (state: IRootState) => state[prefix].loadingArray.length > 0

export const error = createSelector(profile, ({ error }) => error)

export const isChangedPwd = createSelector(profile, ({ isChangedPwd }) => isChangedPwd)

export const isRecoveryRequested = createSelector(
  profile,
  ({ isRecoveryRequested }) => isRecoveryRequested,
)

export const isConfirmed = createSelector(profile, ({ isConfirmed }) => isConfirmed)

export const isPasswordSetup = createSelector(profile, ({ isPasswordSetup }) => isPasswordSetup)

export const invitedUser = createSelector(profile, ({ invitedUser }) => invitedUser)

export const user = createSelector(profile, ({ user }) => user)

export const userId = createSelector(user, (user) => user?.id)

export const role = createSelector(user, (user) => user?.role)

export const isAdmin = createSelector(user, ({ role }) => role === ADMIN)

export const isBDO = createSelector(user, ({ role }) => role === BDO)

export const isUW = createSelector(user, ({ role }) => role === UW_USER)

export const isParticipant = createSelector(user, ({ role }) => role === PARTICIPANT_USER)

export const isProspectUser = createSelector(
  profile,
  ({ user }) => user?.role === CLIENT_USER && user?.isProspectUser,
)

export const isClientUser = createSelector(user, ({ role }) => role === CLIENT_USER)

export const isAdminLoggedAsClient = createSelector(
  profile,
  ({ user }) => user?.role === CLIENT_USER && user?.isAdminLoggedAsClient,
)

export const isExtendedRightsRole = createSelector(user, ({ role }) =>
  [ADMIN, PORTFOLIO_ADMIN, PORTFOLIO_USER, UW_USER].includes(role),
)

export const isAdminRightsRole = createSelector(
  user,
  ({ role, isAdminLoggedAsClient }) =>
    [ADMIN, PORTFOLIO_ADMIN, UW_USER].includes(role) && !isAdminLoggedAsClient,
)

export const isPortfolioAdmin = createSelector(
  user,
  ({ role }) => role === PORTFOLIO_ADMIN || role === UW_USER,
)

export const isAuthenticated = createSelector(user, (user) => !!user)

export const fullName = createSelector(profile, ({ user }) =>
  user ? `${user.firstName} ${user.lastName}` : null,
)

export const avatar = createSelector(profile, ({ user }) => user?.avatar)

export const userRoleLabel = createSelector(role, (role) => USER_ROLE_LABEL[role])

export const shouldShowRoleLabel = createSelector(role, (role) => role !== CLIENT_USER)

export const clientInfo = (state: IRootState) => state[prefix].clientInfo

export const clientName = createSelector(clientInfo, (client) => client?.clientName)

export const isSidebarOpen = createSelector(profile, ({ isSidebarOpen }) => isSidebarOpen)

export const isPastClient = createSelector(
  clientInfo,
  (client) =>
    client && [ClientInfoStatus.Archived, ClientInfoStatus.Past].includes(client?.clientStatus),
)

export const isDueDiligenceClient = createSelector(
  clientInfo,
  (client) => client && client.clientStatus === ClientInfoStatus.DueDiligence,
)
