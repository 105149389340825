import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'

import BBCApIneligibilityTable from './BBCApIneligibilityTable'

import { listApEligibility, updateApEligibility } from '../../redux/clientInfo/actions'
import { apEligibilityData } from '../../redux/clientInfo/selectors'

const selector = createStructuredSelector({
  apEligibilityData,
})
const actions = {
  listApEligibility,
  updateApEligibility,
}

export default connect(selector, actions)(BBCApIneligibilityTable)
