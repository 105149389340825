import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'

import BBCInventoryIneligibleCustomRules from './BBCInventoryIneligibleCustomRules'

import {
  listCustomRules,
  createCustomRules,
  updateCustomRule,
  deleteCustomRule,
  listCustomRulesLabels,
  createCustomRulesLabel,
  listCustomRulesValues,
} from '../../redux/bbc/actions'
import {
  bbc,
  fields,
  customRules,
  customRulesLabels,
  customRulesValues,
} from '../../redux/bbc/selectors'
import { putNotification } from '../../redux/ui/actions'

const selector = createStructuredSelector({
  bbc,
  fields,
  customRules,
  customRulesLabels,
  customRulesValues,
})

const actions = {
  listCustomRules,
  createCustomRules,
  updateCustomRule,
  deleteCustomRule,
  listCustomRulesLabels,
  listCustomRulesValues,
  createCustomRulesLabel,
  putNotification,
}

export default connect(selector, actions)(BBCInventoryIneligibleCustomRules)
