import React from 'react'
import Box from '@mui/material/Box'

import styles from './EnvironmentBanner.module.scss'

const EnvironmentBanner = () => {
  const environment = process.env.REACT_APP_ENVIRONMENT

  if (!environment || environment.toLowerCase() === 'production') {
    return null
  }

  return (
    <Box display="flex" className={styles.wrapper}>
      <Box className={styles.label}>{environment}</Box>
    </Box>
  )
}

export default EnvironmentBanner
