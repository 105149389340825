import React from 'react'
import cn from 'classnames'
import MuiButton, { ButtonProps } from '@mui/material/Button'
import MuiLoadingButton from '@mui/lab/LoadingButton'

import styles from './Button.module.scss'

interface IProps extends ButtonProps {
  secondary?: boolean
  error?: boolean
  small?: boolean
  isLoading?: boolean
  withFieldset?: boolean
  isOpen?: boolean
  label?: string
  selected?: boolean
}

const Button = ({
  disabled,
  className,
  secondary,
  error,
  small = true,
  classes,
  isLoading = false,
  withFieldset = false,
  isOpen,
  label,
  selected,
  ...props
}: IProps) => {
  const Component = isLoading ? MuiLoadingButton : MuiButton
  const otherProps: any = {}
  if (isLoading) {
    otherProps.loading = true
  }

  return (
    <Component
      {...props}
      startIcon={!isLoading && props.startIcon}
      endIcon={!isLoading && props.endIcon}
      classes={{
        root: styles.root,
        loading: styles.loading,
        loadingIndicator: styles.loadingIndicator,
        endIcon: styles.endIcon,
        ...classes,
      }}
      className={cn(className, {
        [styles.disabled]: disabled,
        [styles.secondary]: secondary || props.variant === 'outlined',
        [styles.error]: error,
        [styles.small]: small,
      })}
      {...otherProps}
    >
      {withFieldset && (isOpen || selected) && (
        <fieldset
          className={cn(styles.fieldset, {
            [styles.activeBorder]: isOpen,
            [styles.borderSelected]: selected,
          })}
        >
          <legend className={styles.legend}>
            <span>{label}</span>
          </legend>
        </fieldset>
      )}
      {props.children}
    </Component>
  )
}

export default Button
