import React, { useState, useEffect, useCallback, useMemo } from 'react'
import { generatePath, Link as RouterLink } from 'react-router-dom'
import { Form } from 'react-final-form'
import { FieldArray } from 'react-final-form-arrays'
import arrayMutators from 'final-form-arrays'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import Link from '@mui/material/Link'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import SpeakerNotesIcon from '@mui/icons-material/SpeakerNotes'
import Tooltip from '@mui/material/Tooltip'
import InfiniteScroll from 'react-infinite-scroll-component'
import cn from 'classnames'
import { MenuIcon } from '../Common/Icons'
import styles from './ComplianceByClientTable.module.scss'
import genericSs from '@styles/generic.module.scss'

import { ReactComponent as PendingActionsIcon } from '@assets/images/pending-actions-icon.svg'
import { ReactComponent as ConnectedIcon } from '@assets/images/connected-icon.svg'
import { ReactComponent as NotConnectedIcon } from '@assets/images/not-connected-icon.svg'
import { ReactComponent as LoadingIcon } from '@assets/images/loading-icon.svg'
import {
  dateToString,
  debounceEventHandler,
  formatDate,
  formatDateShort,
  formatDateTime,
  handleMultipleSelect,
} from '../../helpers/helpers'
import Card from '../Common/Card'
import TableContainer from '../Common/TableContainer'
import Table from '../Common/Table'
import TableHead from '../Common/TableHead'
import TableBody from '../Common/TableBody'
import TableRow from '../Common/TableRow'
import TableCell from '../Common/TableCell'
import TableLoader from '../Common/TableLoader'
import TableFiltersRow from '../../components/Common/TableFiltersRow'
import { IClientInfo, IClientComplianceData, IClientCompliance } from '@common/interfaces/client'
import { ROUTES } from '../../constants/routes'
import {
  COMPLIANCE_BY_CLIENT_LIST_FILTERS_CONFIG,
  COMPLIANCE_BY_CLIENT_LIST_FILTERS_EDITABLE_CONFIG,
  getApplicableFilters,
  PER_PAGE,
} from '@common/constants/filters'
import {
  buildFiltersDefaults,
  buildFiltersValidateSchema,
  updateDateFilters,
} from '../../helpers/filters'
import Modal from '../Common/Modal'
import { DATE_FORMAT } from '../../constants/common'
import KeyboardDatePicker from '../Common/KeyboardDatePicker'
import TextField from '../Common/TextField'
import FilterContainer from '../Filters/FilterContainer'
import { ILoadingData } from '../../redux/types'
import { IUser } from '@common/interfaces/user'
import Button from '../Common/Button'
import SaveState from '../Common/SaveState/SaveState'
import { OngoingReportingType } from '@common/interfaces/bbc'
import ActiveToolbar from '../ActiveToolbar'
import { UploadFile } from '../Common/UploadFile'
import { ReportingDocumentStatuses } from '@common/constants/client'
import { usePermissions } from '../../helpers/permissionContext'
import useTable from '../../hooks/useTable'

const ReportingDate = ({
  status,
  label,
  date,
  link,
  formatFunc,
}: {
  status: string
  label: string
  date: string
  link: string
  formatFunc: (date: string) => string
}) => {
  const { isParticipant } = usePermissions()

  const handleLinkClick = useCallback(
    (e: React.MouseEvent<HTMLDivElement>) => {
      if (!link) return
      e.stopPropagation()
      e.preventDefault()
      window.open(link, '_blank')
    },
    [link],
  )

  return (
    <Tooltip title={label} placement="top">
      <div
        className={cn(styles.reportingStatusCell, {
          [styles.green]: !status && date,
          [styles.red]: status === ReportingDocumentStatuses.Overdue,
          [styles.yellow]: status === ReportingDocumentStatuses.Due,
        })}
        onClick={!isParticipant && handleLinkClick}
      >
        {date ? (
          <Link target="_blank" href={link}>
            {formatFunc(date)}
          </Link>
        ) : status ? (
          '-'
        ) : (
          ''
        )}
      </div>
    </Tooltip>
  )
}

const PendingReporting = ({
  id,
  date,
  type,
}: {
  id: string
  date: string
  type: OngoingReportingType
}) => {
  const url = useMemo(() => {
    switch (type) {
      case OngoingReportingType.SalesBySKU:
        return ROUTES.ONGOING_REPORTING_SALES_BY_SKU
      case OngoingReportingType.ARGeneralLedger:
        return ROUTES.ONGOING_REPORTING_AR_GENERAL_LEDGER
      case OngoingReportingType.BankTransactions:
        return ROUTES.BANK_TRANSACTIONS_UPLOAD
      case OngoingReportingType.CapTable:
        return ROUTES.CAP_TABLE_UPLOAD
      default:
        return ROUTES.ONGOING_REPORTING
    }
  }, [type])

  return (
    <>
      {id && (
        <Link
          component={RouterLink}
          to={generatePath(url, {
            id,
          })}
        >
          <Tooltip title={formatDate(date)} placement="top">
            <PendingActionsIcon className={styles.pendingIcon} />
          </Tooltip>
        </Link>
      )}
    </>
  )
}

const ActionMenu = ({
  item,
  isAdminRightsRole,
  handleClickNotes,
  user,
  handleEditClient,
  handleRefreshClientAutoReporting,
}: {
  item: IClientCompliance
  isAdminRightsRole: boolean
  handleClickNotes: (item: IClientCompliance) => void
  user: IUser
  handleEditClient: (item: IClientCompliance) => void
  handleRefreshClientAutoReporting: (item: IClientCompliance) => void
}) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const [menuOpen, setMenuOpen] = useState(false)

  const handleClick = useCallback((event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
    setMenuOpen(true)
  }, [])

  const handleClose = useCallback(() => {
    setAnchorEl(null)
    setMenuOpen(false)
  }, [])

  const emailBody = useMemo(() => {
    const { listOfClientOverdueReports, primaryUserFirstName } = item

    if (!listOfClientOverdueReports?.length) {
      return ''
    }
    let body = `Hi ${primaryUserFirstName},\n\nThe following reports are overdue on your account:\n\n`
    listOfClientOverdueReports?.forEach((report) => {
      // add bullet point
      body += `• ${report}\n`
    })
    body += `\nPlease submit these reports as soon as possible.\n\nThank you,\n${user.firstName}`

    return encodeURIComponent(body)
  }, [item, user])

  const onClientRefresh = useCallback(() => {
    if (!!item.refreshedAt) {
      return
    }
    handleClose()
    handleRefreshClientAutoReporting(item)
  }, [handleClose, handleRefreshClientAutoReporting, item])

  if (!item) {
    return null
  }

  return (
    <div className={styles.menuIcon}>
      <MenuIcon isActive={menuOpen} onClick={handleClick} />
      <Menu open={menuOpen} onClose={handleClose} anchorEl={anchorEl}>
        {item.primaryUserEmail && (
          <MenuItem>
            {' '}
            <Link
              className={styles.linkBtn}
              target="_blank"
              href={`https://mail.google.com/mail/?view=cm&source=mailto&to=${encodeURIComponent(
                item.primaryUserEmail,
              )}
              &su=${encodeURIComponent('Dwight - Overdue reporting')}&body=${emailBody}
              `}
            >
              <span className={styles.menuItem}>Email client</span>
            </Link>
          </MenuItem>
        )}
        {item.clientId && (
          <MenuItem>
            {' '}
            <Link
              className={styles.linkBtn}
              component={RouterLink}
              to={generatePath(ROUTES.CLIENT_SUBMIT_REPORTING_PAGE, {
                id: item.clientId,
              })}
            >
              <span className={styles.menuItem}>Submit reporting</span>
            </Link>
          </MenuItem>
        )}
        <MenuItem
          onClick={() => {
            handleClose()
            handleClickNotes(item)
          }}
        >
          {item.complianceNote ? 'Edit' : 'Add'} note
        </MenuItem>
        <MenuItem
          onClick={() => {
            handleClose()
            handleEditClient(item)
          }}
        >
          Update insurance
        </MenuItem>
        {item.isErpConnected && isAdminRightsRole && (
          <Tooltip
            title={item.refreshedAt ? `Requested at ${formatDateTime(item.refreshedAt)}` : ''}
            placement="top"
          >
            <div>
              <MenuItem onClick={onClientRefresh} disabled={!!item.refreshedAt}>
                Refresh Codat
              </MenuItem>
            </div>
          </Tooltip>
        )}
      </Menu>
    </div>
  )
}

const ComplianceNote = ({
  item,
  open,
  handleClose,
  handleSave,
  isSaving,
  noteValue,
}: {
  item: IClientCompliance
  open?: boolean
  handleClose: () => void
  handleSave: (note: string) => void
  isSaving: boolean
  noteValue: string
}) => {
  return (
    <Modal open={open} onCancel={handleClose} title={`${item.clientName} note`}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TextField name={`[${item.clientId}]complianceNote`} rows={8} multiline fullWidth />
        </Grid>
        <Grid item xs={12}>
          <Button
            variant="contained"
            color="primary"
            onClick={() => handleSave(noteValue)}
            small={false}
            isLoading={isSaving}
            fullWidth
          >
            Save
          </Button>
        </Grid>
      </Grid>
    </Modal>
  )
}

interface IProps {
  clients: ILoadingData<{ data: IClientInfo[] }>
  fullComplianceData: ILoadingData<IClientComplianceData>
  listCompliance: (params?: {
    page?: number
    perPage?: number
    filters?: object
    orderBy?: string
    orderDirection?: string
  }) => void
  updateClient: (clientId: string, data: any) => void
  user?: IUser
  isAdminRightsRole: boolean
  refreshAutoReporting: (data?: object) => void
  refreshCount: number
  userId: string
}

const ComplianceByClientTable = ({
  clients,
  fullComplianceData,
  listCompliance,
  updateClient,
  user,
  isAdminRightsRole,
  refreshAutoReporting,
  refreshCount,
  userId,
}: IProps) => {
  const [isEditClientModalShown, setIsEditClientModalShown] = useState(false)
  const [currentEditClient, setCurrentEditClient] = useState<Partial<IClientCompliance>>(null)
  const [isButtonLoading, setIsButtonLoading] = useState(false)
  const [isEOPDocumentsModalShown, setIsEOPDocumentsModalShown] = useState(false)
  const [isCOIDocumentsModalShown, setIsCOIDocumentsModalShown] = useState(false)

  const handleToggleIsEOPDocumentsModalShown = useCallback(() => {
    setIsEOPDocumentsModalShown((prev) => !prev)
  }, [])

  const handleToggleIsCOIDocumentsModalShown = useCallback(() => {
    setIsCOIDocumentsModalShown((prev) => !prev)
  }, [])

  const { isParticipant } = usePermissions()

  const filterConfig = useMemo(
    () =>
      isParticipant
        ? COMPLIANCE_BY_CLIENT_LIST_FILTERS_CONFIG.filter((item) => item.field !== 'isErpConnected')
        : [
            ...COMPLIANCE_BY_CLIENT_LIST_FILTERS_CONFIG,
            ...COMPLIANCE_BY_CLIENT_LIST_FILTERS_EDITABLE_CONFIG,
          ].map((filter) =>
            filter.type === 'quickFilter'
              ? {
                  ...filter,
                  quickFilters: filter.quickFilters.map((quickFilter) =>
                    quickFilter.title === 'My Clients'
                      ? {
                          ...quickFilter,
                          filters: {
                            ...quickFilter.filters,
                            userId,
                          },
                        }
                      : quickFilter,
                  ),
                }
              : filter,
          ),
    [isParticipant, userId],
  )
  const columnsCount = useMemo(() => getApplicableFilters(filterConfig).length, [filterConfig])

  const filtersValidate = buildFiltersValidateSchema(filterConfig)

  const {
    isLoading,
    data: complianceData,
    isSaving,
    isSaved,
  } = useMemo(
    () => fullComplianceData,

    [fullComplianceData],
  )

  const filtersDefaults = useMemo(() => buildFiltersDefaults(filterConfig, {}), [filterConfig])

  const { clientsData } = useMemo(
    () => ({
      clientsData: clients?.data?.data,
    }),
    [clients],
  )

  const [addNoteOpen, setAddNoteOpen] = useState(false)
  const [currentItem, setCurrentItem] = useState<IClientCompliance>(null)
  const handleCloseNote = () => {
    setAddNoteOpen(false)
  }

  const [activeItem, setActiveItem] = useState<number>()
  const [activeItems, setActiveItems] = useState([])

  const {
    filters,
    orderBy,
    handleFiltersChange,
    handleOrderChange,
    quickFilter,
    handleQuickFilterChange,
  } = useTable({
    tableId: 'complianceByClient',
    filtersDefaults,
    sortDefault: {
      field: 'clientNameSort',
      direction: 'ASC',
    },
  })

  const fetchComplianceList = useCallback(
    (data: any) => {
      const filters = updateDateFilters(data.filters, filterConfig)
      const params = {
        ...data,
        filters,
      }

      listCompliance(params)
    },
    [listCompliance, filterConfig],
  )

  const refetchComplianceList = useCallback(
    () =>
      fetchComplianceList({
        orderBy: orderBy.field,
        orderDirection: orderBy.direction,
        filters,
        skipLoader: true,
        page: 0,
        perPage: complianceData?.data?.length,
      }),
    [fetchComplianceList, orderBy, filters, complianceData?.data?.length],
  )

  useEffect(() => {
    if (refreshCount) {
      refetchComplianceList()
    }
  }, [refreshCount, refetchComplianceList])

  const handleSaveNote = useCallback(
    async (note: string) => {
      setIsButtonLoading(true)
      await updateClient(currentItem.clientId, { complianceNote: note ? note : '' })
      refetchComplianceList()
      setIsButtonLoading(false)
      setAddNoteOpen(false)
    },
    [currentItem, updateClient, setAddNoteOpen, refetchComplianceList],
  )

  const debounceListCompliance = useMemo(
    () => debounceEventHandler(fetchComplianceList, 500),
    [fetchComplianceList],
  )

  useEffect(() => {
    debounceListCompliance({
      page: 0,
      perPage: PER_PAGE,
      filters,
      orderBy: orderBy.field,
      orderDirection: orderBy.direction,
    })
  }, [filters, orderBy, debounceListCompliance])

  const filtersConfig = useMemo(
    () =>
      filterConfig.map((item) => ({
        ...item,
        options:
          item.field === 'clientName'
            ? clientsData?.map(({ clientName }) => ({
                value: clientName,
                label: clientName,
              }))
            : item.options,
      })),
    [clientsData, filterConfig],
  )

  const loadMore = useCallback(() => {
    fetchComplianceList({
      loadMore: true,
      page: Math.ceil(complianceData?.data.length / PER_PAGE),
      perPage: PER_PAGE,
      orderBy: orderBy.field,
      orderDirection: orderBy.direction,
      filters,
    })
  }, [complianceData, orderBy, filters, fetchComplianceList])

  const handleEditClient = (client: Partial<IClientCompliance>) => {
    setIsEditClientModalShown(true)
    setCurrentEditClient(client)
  }
  const handleEditClientModalClose = () => {
    setIsEditClientModalShown(false)
    setCurrentEditClient(null)
  }

  useEffect(() => {
    if (isSaved) {
      handleEditClientModalClose()
    }
  }, [isSaved])

  const handleClickNotes = useCallback(
    (item: IClientCompliance) => {
      setAddNoteOpen(true)
      setCurrentItem(item)
    },
    [setAddNoteOpen, setCurrentItem],
  )

  const handleSelectRow = useMemo(() => handleMultipleSelect(setActiveItems, setActiveItem), [])

  const handleSave = useCallback(
    async (data: {
      clientId: string
      eopExpirationDate?: string
      coiExpirationDate?: string
      complianceNote?: string
      coiExpirationFiles?: File[]
      eopExpirationFiles?: File[]
    }) => {
      const clientId = data.clientId
      const formData = new FormData()
      data?.coiExpirationFiles?.forEach((file, index) => {
        file instanceof File && formData.append(`files[coiExpiration][${index}]`, file, file.name)
      })
      data?.eopExpirationFiles?.forEach((file, index) => {
        file instanceof File && formData.append(`files[eopExpiration][${index}]`, file, file.name)
      })
      data.coiExpirationDate &&
        formData.append('coiExpirationDate', dateToString(data.coiExpirationDate))
      data.eopExpirationDate &&
        formData.append('eopExpirationDate', dateToString(data.eopExpirationDate))
      data.complianceNote && formData.append('complianceNote', data.complianceNote)

      await updateClient(clientId, formData)
      setCurrentEditClient(null)
    },
    [updateClient],
  )
  const initialValues = useMemo(
    () =>
      complianceData?.data.reduce((acc, item) => {
        acc[item.clientId] = item
        return acc
      }, {}),
    [complianceData],
  )

  const handleRefreshAutoReporting = useCallback(async () => {
    await refreshAutoReporting()
    refetchComplianceList()
  }, [refreshAutoReporting, refetchComplianceList])

  const handleRefreshClientAutoReporting = useCallback(
    async (client?: Partial<IClientCompliance>) => {
      await refreshAutoReporting({
        clientName: client?.clientName,
      })
      refetchComplianceList()
    },
    [refreshAutoReporting, refetchComplianceList],
  )

  return (
    <Card withBorder={false} noHeaderMargin noPadding>
      <Form
        onSubmit={handleSave}
        initialValues={initialValues}
        mutators={{ ...arrayMutators }}
        render={({ values: mainValues, dirty, form }) => (
          <form>
            <TableContainer
              className={cn({
                [styles.table]: !isParticipant,
                [styles.tableParticipant]: isParticipant,
              })}
              onActiveRowsChange={setActiveItems}
              onActiveRowChange={setActiveItem}
            >
              {addNoteOpen && (
                <ComplianceNote
                  open={addNoteOpen}
                  item={currentItem}
                  handleSave={handleSaveNote}
                  handleClose={handleCloseNote}
                  noteValue={mainValues[currentItem.clientId]?.complianceNote}
                  isSaving={isButtonLoading}
                />
              )}
              <Form
                validate={filtersValidate}
                onSubmit={handleFiltersChange}
                initialValues={filters}
                mutators={{
                  setFieldData: ([field, value], state, { changeValue }) => {
                    changeValue(state, field, () => value)
                  },
                }}
                render={({ values, handleSubmit, form: { mutators } }) => (
                  <FilterContainer
                    filters={filtersConfig}
                    handleSubmit={handleSubmit}
                    mutators={mutators}
                    values={values}
                    appliedFilters={filters}
                    appliedQuickFilter={quickFilter}
                    handleAppliedQuickFilterChange={handleQuickFilterChange}
                    actions={
                      isAdminRightsRole && (
                        <Box display="flex" alignItems="center" gap={1}>
                          <Tooltip
                            title={
                              complianceData?.totals?.refreshedAt
                                ? `Requested at ${formatDateTime(
                                    complianceData.totals.refreshedAt,
                                  )}`
                                : ''
                            }
                            placement="top"
                          >
                            <div>
                              <Button
                                color="primary"
                                variant="outlined"
                                onClick={handleRefreshAutoReporting}
                                disabled={!!complianceData?.totals?.refreshedAt}
                              >
                                Refresh Codat
                              </Button>
                            </div>
                          </Tooltip>
                        </Box>
                      )
                    }
                  />
                )}
              />
              <Table>
                <TableHead>
                  <TableFiltersRow
                    filters={filtersConfig}
                    orderBy={orderBy}
                    handleOrderChange={handleOrderChange}
                  />
                </TableHead>
                <TableBody id="scrollableTable">
                  {isLoading ? (
                    <TableLoader columnsCount={columnsCount} height={24} />
                  ) : (
                    complianceData?.data && (
                      <InfiniteScroll
                        dataLength={complianceData?.data.length}
                        next={loadMore}
                        hasMore={complianceData?.data.length < complianceData?.totals.totalItems}
                        loader={
                          <TableLoader columnsCount={columnsCount} height={24} rowsCount={1} />
                        }
                        scrollableTarget="scrollableTable"
                      >
                        {complianceData.data.map((item, index) => (
                          <TableRow
                            key={index}
                            className={cn('activableRow')}
                            isActiveRow={activeItems.includes(index)}
                            isCurrentActiveRow={activeItem === index}
                            index={index}
                            onSelectRow={handleSelectRow}
                          >
                            <TableCell className={genericSs.tableTextLeft}>
                              <div className={styles.clientName}>
                                <Link
                                  component={RouterLink}
                                  to={generatePath(ROUTES.CLIENT_PAGE, { id: item.clientId })}
                                >
                                  {item.clientName}
                                </Link>
                                {!isParticipant &&
                                  item.complianceNote &&
                                  item.complianceNote !== '' && (
                                    <Tooltip title={item.complianceNote} placement="top">
                                      <SpeakerNotesIcon
                                        color="primary"
                                        fontSize="small"
                                        className={styles.speakerNotesIcon}
                                        onClick={() => handleClickNotes(item)}
                                      />
                                    </Tooltip>
                                  )}
                              </div>
                            </TableCell>
                            <TableCell className={genericSs.tableTextLeft}>
                              {item.loanType}
                            </TableCell>
                            {!isParticipant && (
                              <TableCell className={genericSs.tableTextCenter}>
                                {item.isErpConnected ? (
                                  <Tooltip title={item.erpName || 'Auto reporting'} placement="top">
                                    {!!item.refreshedAt ? (
                                      <LoadingIcon className={styles.loadingIcon} />
                                    ) : (
                                      <ConnectedIcon />
                                    )}
                                  </Tooltip>
                                ) : (
                                  <Tooltip title="Not connected" placement="top">
                                    <NotConnectedIcon />
                                  </Tooltip>
                                )}
                              </TableCell>
                            )}
                            <TableCell>
                              <div className={styles.tableCellWithIcon}>
                                <ReportingDate
                                  date={item.clientBalanceSheet}
                                  status={item.clientBalanceSheetStatus}
                                  label={item.clientBalanceSheetStatusLabel}
                                  formatFunc={formatDateShort}
                                  link={
                                    item.latestClientFinancialsReportingId
                                      ? generatePath(ROUTES.ONGOING_REPORTING_SUMMARY, {
                                          id: item.latestClientFinancialsReportingId,
                                        })
                                      : null
                                  }
                                />
                              </div>
                            </TableCell>
                            <TableCell>
                              <div className={styles.tableCellWithIcon}>
                                <ReportingDate
                                  date={item.clientIncomeStatement}
                                  status={item.clientIncomeStatementStatus}
                                  label={item.clientIncomeStatementStatusLabel}
                                  formatFunc={formatDateShort}
                                  link={
                                    item.latestClientFinancialsReportingId
                                      ? generatePath(ROUTES.ONGOING_REPORTING_SUMMARY, {
                                          id: item.latestClientFinancialsReportingId,
                                        })
                                      : null
                                  }
                                />
                                {!isParticipant && (
                                  <PendingReporting
                                    id={item.clientFinancialsReporting}
                                    date={item.clientFinancialsReportingAsOfDate}
                                    type={OngoingReportingType.IncomeStatement}
                                  />
                                )}
                              </div>
                            </TableCell>
                            <TableCell>
                              <div className={styles.tableCellWithIcon}>
                                <ReportingDate
                                  date={item.projections}
                                  status={item.projectionsStatus}
                                  label={item.projectionsStatusLabel}
                                  formatFunc={formatDateShort}
                                  link={
                                    item.latestProjectionsReportingId
                                      ? generatePath(ROUTES.ONGOING_REPORTING_SUMMARY, {
                                          id: item.latestProjectionsReportingId,
                                        })
                                      : null
                                  }
                                />

                                {!isParticipant && (
                                  <PendingReporting
                                    id={item.projectionsReporting}
                                    date={item.projectionsReportingAsOfDate}
                                    type={OngoingReportingType.IncomeStatementProjections}
                                  />
                                )}
                              </div>
                            </TableCell>
                            <TableCell>
                              <div className={styles.tableCellWithIcon}>
                                <ReportingDate
                                  date={item.salesBySKU}
                                  status={item.salesBySKUStatus}
                                  label={item.salesBySKUStatusLabel}
                                  formatFunc={formatDateShort}
                                  link={
                                    item.latestSalesBySKUReportingId
                                      ? generatePath(ROUTES.ONGOING_REPORTING_SALES_BY_SKU, {
                                          id: item.latestSalesBySKUReportingId,
                                        })
                                      : null
                                  }
                                />

                                {!isParticipant && (
                                  <PendingReporting
                                    id={item.salesBySKUReporting}
                                    date={item.salesBySKUReportingAsOfDate}
                                    type={OngoingReportingType.SalesBySKU}
                                  />
                                )}
                              </div>
                            </TableCell>
                            <TableCell>
                              <div className={styles.tableCellWithIcon}>
                                <ReportingDate
                                  date={item.arLedger}
                                  status={item.arLedgerStatus}
                                  label={item.arLedgerStatusLabel}
                                  formatFunc={formatDate}
                                  link={
                                    item.arLedgerReporting
                                      ? generatePath(ROUTES.ONGOING_REPORTING_AR_GENERAL_LEDGER, {
                                          id: item.arLedgerReporting,
                                        })
                                      : null
                                  }
                                />
                                {!isParticipant && (
                                  <PendingReporting
                                    id={item.arLedgerReporting}
                                    date={item.arLedgerReportingAsOfDate}
                                    type={OngoingReportingType.ARGeneralLedger}
                                  />
                                )}
                              </div>
                            </TableCell>
                            <TableCell>
                              <div className={styles.tableCellWithIcon}>
                                <ReportingDate
                                  date={item.bankTransactions}
                                  status={item.bankTransactionsStatus}
                                  label={item.bankTransactionsStatusLabel}
                                  formatFunc={formatDate}
                                  link={
                                    item.latestBankTransactionsReportingId
                                      ? generatePath(ROUTES.BANK_TRANSACTIONS_UPLOAD, {
                                          id: item.latestBankTransactionsReportingId,
                                        })
                                      : null
                                  }
                                />
                                {!isParticipant && (
                                  <PendingReporting
                                    id={item.bankTransactionsReporting}
                                    date={item.bankTransactionsReportingAsOfDate}
                                    type={OngoingReportingType.BankTransactions}
                                  />
                                )}
                              </div>
                            </TableCell>

                            <TableCell>
                              <div className={styles.tableCellWithIcon}>
                                <ReportingDate
                                  date={
                                    mainValues?.[item?.clientId]?.eopExpiration ||
                                    item.eopExpiration
                                  }
                                  status={item.eopExpirationStatus}
                                  label={item.eopExpirationStatusLabel}
                                  formatFunc={formatDate}
                                  link={item.eopExpirationBoxLink}
                                />
                              </div>
                            </TableCell>
                            <TableCell>
                              <div className={styles.tableCellWithIcon}>
                                <ReportingDate
                                  date={
                                    mainValues?.[item?.clientId]?.coiExpiration ||
                                    item.coiExpiration
                                  }
                                  status={item.coiExpirationStatus}
                                  label={item.coiExpirationStatusLabel}
                                  formatFunc={formatDate}
                                  link={item.coiExpirationBoxLink}
                                />
                              </div>
                            </TableCell>

                            <TableCell>
                              <div className={styles.tableCellWithIcon}>
                                <ReportingDate
                                  date={item.borrowingBase}
                                  status={item.borrowingBaseStatus}
                                  label={item.borrowingBaseStatusLabel}
                                  formatFunc={formatDate}
                                  link={null}
                                />
                              </div>
                            </TableCell>
                            {!isParticipant && (
                              <TableCell>
                                <div className={styles.tableCellWithIcon}>
                                  <ReportingDate
                                    date={item.capTable}
                                    status={item.capTableStatus}
                                    label={item.capTableStatusLabel}
                                    formatFunc={formatDate}
                                    link={
                                      item.latestCapTableReportingId
                                        ? generatePath(ROUTES.CAP_TABLE_UPLOAD, {
                                            id: item.latestCapTableReportingId,
                                          })
                                        : null
                                    }
                                  />
                                  <PendingReporting
                                    id={item.capTableReporting}
                                    date={item.capTableReportingAsOfDate}
                                    type={OngoingReportingType.CapTable}
                                  />
                                </div>
                              </TableCell>
                            )}
                            {!isParticipant && (
                              <TableCell>{item.totalOverdueReports || ''}</TableCell>
                            )}

                            {!isParticipant && (
                              <TableCell className={genericSs.tableTextRight}>
                                <ActionMenu
                                  item={item}
                                  isAdminRightsRole={isAdminRightsRole}
                                  handleClickNotes={handleClickNotes}
                                  user={user}
                                  handleEditClient={handleEditClient}
                                  handleRefreshClientAutoReporting={
                                    handleRefreshClientAutoReporting
                                  }
                                />
                              </TableCell>
                            )}
                          </TableRow>
                        ))}
                      </InfiniteScroll>
                    )
                  )}
                </TableBody>
              </Table>
              <Grid justifyContent={'flex-end'} container>
                <SaveState isSaved={isSaved} isSaving={isSaving} />
              </Grid>
              <ActiveToolbar
                activeItems={activeItems}
                className={styles.activeToolbar}
              ></ActiveToolbar>
            </TableContainer>
            {isEditClientModalShown && (
              <Modal
                open
                onCancel={() => {
                  handleEditClientModalClose()
                  form.reset()
                }}
                title={`Edit ${currentEditClient.clientName}'s insurance`}
                size="small"
              >
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <h4 className={styles.formLabel}>EOP expiration</h4>
                    <KeyboardDatePicker
                      name={`[${currentEditClient.clientId}]eopExpiration`}
                      inputFormat={DATE_FORMAT}
                      size="large"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <FieldArray name={`[${currentEditClient.clientId}]eopExpirationFiles`}>
                      {({ fields }) => (
                        <UploadFile
                          disabled={isLoading}
                          title={'EOP Expiration'}
                          size="lg"
                          onDropAccepted={(loadedFiles: File[]) => {
                            loadedFiles.forEach((file) => fields.push(file))
                          }}
                          acceptedFileTypes={['pdf']}
                          files={mainValues[currentEditClient.clientId]['eopExpirationFiles']}
                          onDelete={(index) => {
                            index.sort((a, b) => b - a).forEach((i) => fields.remove(i))
                          }}
                          isModalOpen={isEOPDocumentsModalShown}
                          handleToggleModal={handleToggleIsEOPDocumentsModalShown}
                        />
                      )}
                    </FieldArray>
                  </Grid>

                  <Grid item xs={12}>
                    <h4 className={styles.formLabel}>COI expiration</h4>
                    <KeyboardDatePicker
                      name={`[${currentEditClient.clientId}]coiExpiration`}
                      inputFormat={DATE_FORMAT}
                      size="large"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <FieldArray name={`[${currentEditClient.clientId}]coiExpirationFiles`}>
                      {({ fields }) => (
                        <UploadFile
                          disabled={isLoading}
                          title={'COI Expiration'}
                          size="lg"
                          onDropAccepted={(loadedFiles: File[]) => {
                            loadedFiles.forEach((file) => fields.push(file))
                          }}
                          acceptedFileTypes={['pdf']}
                          files={mainValues[currentEditClient.clientId]['coiExpirationFiles']}
                          onDelete={(index) => {
                            index.sort((a, b) => b - a).forEach((i) => fields.remove(i))
                          }}
                          isModalOpen={isCOIDocumentsModalShown}
                          handleToggleModal={handleToggleIsCOIDocumentsModalShown}
                        />
                      )}
                    </FieldArray>
                  </Grid>
                  <Grid item xs={12}>
                    <Button
                      key="submit"
                      color="primary"
                      variant="contained"
                      onClick={() => {
                        handleSave({
                          clientId: currentEditClient.clientId,
                          eopExpirationDate: mainValues[currentEditClient.clientId].eopExpiration,
                          coiExpirationDate: mainValues[currentEditClient.clientId].coiExpiration,
                          coiExpirationFiles:
                            mainValues[currentEditClient.clientId].coiExpirationFiles,
                          eopExpirationFiles:
                            mainValues[currentEditClient.clientId].eopExpirationFiles,
                        })
                      }}
                      fullWidth
                      small={false}
                      isLoading={isSaving}
                    >
                      Update
                    </Button>
                  </Grid>
                </Grid>
              </Modal>
            )}
          </form>
        )}
      />
    </Card>
  )
}
export default ComplianceByClientTable
