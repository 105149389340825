import { connect } from 'react-redux'
import {
  listEntityInfo,
  updateEntityInfo,
  updateDuplicateEntity,
  listInvestors,
} from '../../redux/entityInfo/actions'

import EntityDashboardHeader from './EntityDashboardHeader'
import { createStructuredSelector } from 'reselect'

import {
  entity as entityInfo,
  investorAggregation,
  entityMergeConflicts,
} from '../../redux/entityInfo/selectors'

const selector = createStructuredSelector({
  entityInfo,
  investorAggregation,
  entityMergeConflicts,
})

const actions = {
  listEntityInfo,
  updateEntityInfo,
  updateDuplicateEntity,
  listInvestors,
}

export default connect(selector, actions)(EntityDashboardHeader)
