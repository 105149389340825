import { IFlagData } from '@common/interfaces/bbc'
import { orderBy } from 'lodash'

export const addNoteSuccess = (state: IFlagData, action: any) => {
  const data = state?.data
  if (!data) return state
  let newData
  if (data && action.data.parentId) {
    newData = data.map((item) =>
      item.notes?.id === action.data.parentId
        ? {
            ...item,
            notes: { ...item.notes, children: [...item.notes.children, action.data] },
          }
        : item,
    )
  } else if (data && action.data.flagId) {
    newData = data.map((item) =>
      item.id === action.data.flagId
        ? {
            ...item,
            notes: { ...action.data, children: [] },
          }
        : item,
    )
  } else if (data && action.data.entityFlagId) {
    newData = data.map((item) =>
      item.id === action.data.entityFlagId
        ? {
            ...item,
            notes: { ...action.data, children: [] },
          }
        : item,
    )
  }
  return {
    ...state,
    data: newData,
  }
}

export const deleteNoteSuccess = (state: IFlagData, action: any) => {
  const data = state?.data
  if (!data) return state
  let newData
  if (action.data?.parentId) {
    newData = data.map((item) => {
      if (item.notes?.id === action.data.parentId) {
        return {
          ...item,
          notes: {
            ...item.notes,
            children: item.notes.children.filter((child) => child.id !== action.data.id),
          },
        }
      } else {
        return item
      }
    })
  } else {
    newData = data.filter((item) => item.id !== action.data.id)
  }
  return {
    ...state,
    data: newData,
  }
}

export const updateNoteSuccess = (state: IFlagData, action: any) => {
  const data = state?.data
  if (!data) return state
  let newData
  if (action.data?.parentId) {
    newData = data.map((item) => {
      if (item.notes?.id === action.data.parentId) {
        return {
          ...item,
          notes: {
            ...item.notes,
            children: orderBy(
              item.notes.children.map((child) => {
                return child.id === action.data.id ? action.data : child
              }),
              ['pinned', 'resolved', 'createdAt'],
              ['asc', 'desc', 'asc'],
            ),
          },
        }
      } else {
        return item
      }
    })
  } else {
    newData = orderBy(
      data.map((item) => {
        if (item.id === action.data.id) {
          return action.data
        }
        return item
      }),
      ['pinned', 'resolved', 'createdAt'],
      ['desc', 'asc', 'desc'],
    )
  }
  return {
    ...state,
    data: newData,
  }
}
