import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'

import OngoingReportingReconciliation from './OngoingReportingReconciliation'

import { dataConsolidated } from '../../redux/ongoingReporting/selectors'

const selector = createStructuredSelector({
  dataConsolidated,
})
const actions = {}

export default connect(selector, actions)(OngoingReportingReconciliation)
