import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'

import BBCARSummaryTable from './BBCARSummaryTable'

import {
  isLoading as isLoadingBBC,
  bbc,
  arSummaryStats,
  previousClientCollaterals,
  arapChartData,
  arapChartDataDropdownOptions,
} from '../../redux/bbc/selectors'
import {
  getBBCARSummaryStats,
  getPreviousBBCClientCollateral,
  show as showBBC,
  getARAPChartDropdownOptions,
} from '../../redux/bbc/actions'
import { debtorIneligibleCategories } from '../../redux/options/selectors'

const selector = createStructuredSelector({
  isLoadingBBC,
  bbc,
  arSummaryStatsData: arSummaryStats,
  previousClientCollaterals,
  debtorIneligibleCategories,
  arapChartData,
  arapChartDataDropdownOptions,
})

const actions = {
  getBBCARSummaryStats,
  showBBC,
  getPreviousBBCClientCollateral,
  getARAPChartDropdownOptions,
}

export default connect(selector, actions)(BBCARSummaryTable)
