import { Action } from 'redux'
import { takeEvery, put } from 'redux-saga/effects'
import { EXPORT_NEWSFEED_SUCCESS, EXPORT_NEWSFEED_FAILURE } from './actions'
import { IResponse } from '@common/interfaces/request'
import { download } from '../../helpers/helpers'

export function* exportNewsfeed() {
  yield takeEvery(EXPORT_NEWSFEED_SUCCESS, function* (action: IResponse & Action) {
    try {
      yield download(action.data, action.data.name)
    } catch (error) {
      yield put({ type: EXPORT_NEWSFEED_FAILURE, error })
    }
  })
}
