import Base from './base'

export default class Dashboard extends Base {
  listDashboards(params?: object) {
    return this.apiClient.get('dashboards', params)
  }

  createDashboard(data: object) {
    return this.apiClient.post('dashboards', data)
  }

  deleteDashboard(id: string) {
    return this.apiClient.delete(`dashboards/${id}`)
  }

  updateDashboard(id: string, data: object) {
    return this.apiClient.put(`dashboards/${id}`, data)
  }
}
