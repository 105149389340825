import { ActionPattern } from '@redux-saga/types'
import { IApi } from '../../api'
import { CommonPromiseAction } from '../types'

export const prefix = 'salesforce'

export const LIST_ACCOUNTS_REQUEST = `${prefix}/LIST_ACCOUNTS_REQUEST`
export const LIST_ACCOUNTS_SUCCESS = `${prefix}/LIST_ACCOUNTS_SUCCESS`
export const LIST_ACCOUNTS_FAILURE = `${prefix}/LIST_ACCOUNTS_FAILURE` as ActionPattern

export const listAccounts: CommonPromiseAction = () => ({
  type: [LIST_ACCOUNTS_REQUEST, LIST_ACCOUNTS_SUCCESS, LIST_ACCOUNTS_FAILURE],
  promise: (api: IApi) => api.salesforce.listAccounts(),
})

export const LIST_INVESTORS_REQUEST = `${prefix}/LIST_INVESTORS_REQUEST`
export const LIST_INVESTORS_SUCCESS = `${prefix}/LIST_INVESTORS_SUCCESS`
export const LIST_INVESTORS_FAILURE = `${prefix}/LIST_INVESTORS_FAILURE` as ActionPattern

export const listInvestors: CommonPromiseAction = () => ({
  type: [LIST_INVESTORS_REQUEST, LIST_INVESTORS_SUCCESS, LIST_INVESTORS_FAILURE],
  promise: (api: IApi) => api.salesforce.listInvestors(),
})

export const DESCRIBE_ACCOUNTS_REQUEST = `${prefix}/DESCRIBE_ACCOUNTS_REQUEST`
export const DESCRIBE_ACCOUNTS_SUCCESS = `${prefix}/DESCRIBE_ACCOUNTS_SUCCESS`
export const DESCRIBE_ACCOUNTS_FAILURE = `${prefix}/DESCRIBE_ACCOUNTS_FAILURE` as ActionPattern

export const describeAccount: CommonPromiseAction = () => ({
  type: [DESCRIBE_ACCOUNTS_REQUEST, DESCRIBE_ACCOUNTS_SUCCESS, DESCRIBE_ACCOUNTS_FAILURE],
  promise: (api: IApi) => api.salesforce.describeAccount(),
})

export const SHOW_ACCOUNT_REQUEST = `${prefix}/SHOW_ACCOUNT_REQUEST`
export const SHOW_ACCOUNT_SUCCESS = `${prefix}/SHOW_ACCOUNT_SUCCESS`
export const SHOW_ACCOUNT_FAILURE = `${prefix}/SHOW_ACCOUNT_FAILURE`

export const showAccount: CommonPromiseAction = (uid: string) => ({
  type: [SHOW_ACCOUNT_REQUEST, SHOW_ACCOUNT_SUCCESS, SHOW_ACCOUNT_FAILURE],
  promise: (api: IApi) => api.salesforce.showAccount(uid),
  uid,
})

export const HIDE_ACCOUNT = `${prefix}/HIDE_ACCOUNT`

export const hideAccount = () => ({
  type: HIDE_ACCOUNT,
})

export const UPDATE_ACCOUNT_REQUEST = `${prefix}/UPDATE_ACCOUNT_REQUEST`
export const UPDATE_ACCOUNT_SUCCESS = `${prefix}/UPDATE_ACCOUNT_SUCCESS`
export const UPDATE_ACCOUNT_FAILURE = `${prefix}/UPDATE_ACCOUNT_FAILURE`

export const updateAccount: CommonPromiseAction = (uid: string, data: object) => ({
  type: [UPDATE_ACCOUNT_REQUEST, UPDATE_ACCOUNT_SUCCESS, UPDATE_ACCOUNT_FAILURE],
  promise: (api: IApi) => api.salesforce.updateAccount(uid, data),
  uid,
})
