import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { FormApi } from 'final-form'

import styles from './DueDiligenceFinancialsModal.module.scss'
import {
  DUE_DILIGENCE_DOCUMENTS_TYPES_WITHOUT_UPLOAD,
  DueDiligenceDocumentRequestStatus,
  DueDiligenceDocumentRequestStep,
  IDueDiligence,
  IDueDiligenceCompanyInfo,
  IDueDiligenceDocumentRequest,
} from '@common/interfaces/dueDiligence'
import { ILoadingData } from '../../redux/types'
import DueDiligenceFullscreenModal from '../DueDiligenceFullscreenModal'
import DueDiligenceFinancialsVendors from '../DueDiligenceFinancialsVendors'
import DueDiligenceFinancialsIntegration from '../DueDiligenceFinancialsIntegrations'
import DueDiligenceFinancialsInventoryLocations from '../DueDiligenceFinancialsInventoryLocations'
import DueDiligenceFinancialsBankAccounts from '../DueDiligenceFinancialsBankAccounts'
import DueDiligenceClientStepFiles from '../DueDiligenceClientStepFiles'
import { ClientEntityRelationshipType } from '@common/interfaces/integration'
import { ClientERPSystem } from '@common/interfaces/client'

const VENDORS_STEP = 0
const INVENTORY_LOCATION_STEP = 1
const BANK_ACCOUNTS_STEP = 2
const DOCUMENTATION_STEP = 3

interface IProps {
  dueDiligenceInfo: IDueDiligence
  dueDiligenceCompanyInfo: IDueDiligenceCompanyInfo
  dueDiligenceDocumentRequests: ILoadingData<{ data: IDueDiligenceDocumentRequest[] }>
  updateFinancialsVendorsInfo: (data: object) => Promise<void>
  getFinancialsCodatLink: () => void
  reloadClientInfo: () => void
  showDocumentRequests: (id?: string, params?: object) => void
  onCloseModal: () => void
  startStep?: string
}

const DueDiligenceFinancialsModal = ({
  dueDiligenceInfo,
  dueDiligenceCompanyInfo,
  dueDiligenceDocumentRequests,
  updateFinancialsVendorsInfo,
  getFinancialsCodatLink,
  reloadClientInfo,
  showDocumentRequests,
  onCloseModal,
  startStep,
}: IProps) => {
  const filesNavigationRef: React.MutableRefObject<any> = useRef(null)
  const vendorsFormRef: React.MutableRefObject<FormApi<any, Partial<any>>> = useRef(null)
  const [step, setStep] = useState(startStep ? parseInt(startStep) : VENDORS_STEP)
  const [isLoading, setIsLoading] = useState(false)
  const [isVendorsFormInvalid, setIsVendorsFormInvalid] = useState(false)
  const containsAppIntegrationsERPSystemsRef: React.MutableRefObject<boolean> = useRef(false)
  const codatWindow = useRef(null)
  const [isAppIntegrations, setIsAppIntegrations] = useState(false)
  const [isAppIntegrationsSkipped, setIsAppIntegrationsSkipped] = useState(false)

  const handleCloseModal = useCallback(async () => {
    if (step === VENDORS_STEP && !isAppIntegrations) {
      await vendorsFormRef.current.submit()
    }

    onCloseModal()
  }, [onCloseModal, step, isAppIntegrations])

  const onBack = useCallback(async () => {
    if (step === VENDORS_STEP) {
      if (isAppIntegrations) {
        setIsAppIntegrations(false)
        return
      } else {
        await vendorsFormRef.current.submit()
      }
    }

    if (step === DOCUMENTATION_STEP) {
      filesNavigationRef.current(() => {
        setStep(BANK_ACCOUNTS_STEP)
      }, 'back')
      return
    }

    setStep((step) => step - 1)
  }, [step, isAppIntegrations])

  const onNext = useCallback(async () => {
    if (step === DOCUMENTATION_STEP) {
      filesNavigationRef.current(handleCloseModal)
      return
    }

    if (step === VENDORS_STEP) {
      if (isAppIntegrations) {
        setIsAppIntegrations(false)
        setIsAppIntegrationsSkipped(true)
        codatWindow.current = null
      } else {
        await vendorsFormRef.current.submit()
        if (
          !dueDiligenceInfo.isErpConnected &&
          containsAppIntegrationsERPSystemsRef.current &&
          !isAppIntegrationsSkipped
        ) {
          setIsAppIntegrations(true)
          return
        }
      }
    }

    setStep((step) => step + 1)
  }, [step, handleCloseModal, dueDiligenceInfo, isAppIntegrationsSkipped, isAppIntegrations])

  const handleSetStep = useCallback(
    async (nextStep) => {
      if (nextStep === step) {
        return
      }

      if (step === VENDORS_STEP) {
        await vendorsFormRef.current.submit()
      }

      setStep(nextStep)
    },
    [step],
  )

  const steps = useMemo(() => {
    const documentRequests =
      dueDiligenceDocumentRequests?.data?.data?.filter(
        ({ type }) => type.step === DueDiligenceDocumentRequestStep.Financials,
      ) || []

    const distributionServices3PL = dueDiligenceCompanyInfo.clientEntityRelationship.find(
      ({ type }) => type === ClientEntityRelationshipType.DistributionServices3PL,
    )

    return [
      {
        title: 'Vendors',
        isActive: dueDiligenceCompanyInfo.erpSystem.length > 0 || !!distributionServices3PL,
        isCompleted: dueDiligenceCompanyInfo.erpSystem.length > 0 && !!distributionServices3PL,
      },
      {
        title: 'Inventory Location',
        isActive: dueDiligenceCompanyInfo.hasInventoryLocations,
        isCompleted: dueDiligenceCompanyInfo.isAllInventoryLocationsValid,
      },
      {
        title: 'Bank Accounts',
        isActive: dueDiligenceCompanyInfo.hasBankAccounts,
        isCompleted: dueDiligenceCompanyInfo.hasBankAccounts,
      },
      {
        title: 'Documentation',
        isActive:
          documentRequests.length > 0 &&
          documentRequests.some(({ type, status, files }) =>
            DUE_DILIGENCE_DOCUMENTS_TYPES_WITHOUT_UPLOAD.includes(type.type)
              ? status === DueDiligenceDocumentRequestStatus.Completed
              : files.length > 0,
          ),
        isCompleted:
          documentRequests.length > 0 &&
          documentRequests.every(({ type, status, files }) =>
            DUE_DILIGENCE_DOCUMENTS_TYPES_WITHOUT_UPLOAD.includes(type.type)
              ? status === DueDiligenceDocumentRequestStatus.Completed
              : files.length > 0,
          ),
      },
    ]
  }, [dueDiligenceCompanyInfo, dueDiligenceDocumentRequests])

  const header = useMemo(() => {
    switch (step) {
      case VENDORS_STEP:
        return 'Tell us about vendors that help you manage inventory and finance'
      case INVENTORY_LOCATION_STEP:
        return 'Let us know where your inventory is located'
      case BANK_ACCOUNTS_STEP:
        return 'Share your banking information'
      case DOCUMENTATION_STEP:
        return 'Upload docs to help us with diligence'
      default:
        return ''
    }
  }, [step])

  const handleAdvisorsFormSave = useCallback(
    async (data) => {
      const { distributionServices3PL, erpSystem, ...rest } = data

      setIsLoading(true)
      await updateFinancialsVendorsInfo({
        clientEntityRelationship: [
          distributionServices3PL
            ? {
                type: ClientEntityRelationshipType.DistributionServices3PL,
                salesforceAccount: distributionServices3PL?.label || distributionServices3PL,
              }
            : null,
        ].filter(Boolean),
        erpSystem: erpSystem || [],
        ...rest,
      })
      setIsLoading(false)

      containsAppIntegrationsERPSystemsRef.current =
        !!erpSystem?.includes(ClientERPSystem.Netsuite) ||
        !!erpSystem?.includes(ClientERPSystem.QuickbooksOnline)

      return
    },
    [updateFinancialsVendorsInfo],
  )

  const handleIntegrations = useCallback(async () => {
    if (dueDiligenceInfo?.codatLink) {
      sessionStorage.setItem('previousPage', window.location.href)
      codatWindow.current = window.open(dueDiligenceInfo.codatLink, '_self')
    }
  }, [dueDiligenceInfo])

  useEffect(() => {
    if (step === VENDORS_STEP) {
      if (dueDiligenceInfo.isErpConnected && isAppIntegrations) {
        setIsAppIntegrations(false)
        setStep((step) => step + 1)
        codatWindow.current = null
      } else if (
        !dueDiligenceInfo.codatLink &&
        (dueDiligenceCompanyInfo.erpSystem.includes(ClientERPSystem.Netsuite) ||
          dueDiligenceCompanyInfo.erpSystem.includes(ClientERPSystem.QuickbooksOnline))
      ) {
        getFinancialsCodatLink()
      }
    }
    if (step === DOCUMENTATION_STEP) {
      if (dueDiligenceInfo.isErpConnected) {
        codatWindow.current = null
      } else if (
        !dueDiligenceInfo.codatLink &&
        (dueDiligenceCompanyInfo.erpSystem.includes(ClientERPSystem.Netsuite) ||
          dueDiligenceCompanyInfo.erpSystem.includes(ClientERPSystem.QuickbooksOnline))
      ) {
        getFinancialsCodatLink()
      }
    }
  }, [
    step,
    isAppIntegrations,
    getFinancialsCodatLink,
    dueDiligenceInfo.isErpConnected,
    dueDiligenceInfo.codatLink,
    dueDiligenceCompanyInfo.erpSystem,
  ])

  useEffect(() => {
    const onFocus = () => {
      if (
        ((step === VENDORS_STEP && isAppIntegrations) ||
          (step === DOCUMENTATION_STEP && !dueDiligenceInfo.isErpConnected)) &&
        codatWindow.current
      ) {
        reloadClientInfo()
        showDocumentRequests(null, { skipLoader: true })
      }
    }

    window.addEventListener('focus', onFocus)

    return () => {
      window.removeEventListener('focus', onFocus)
    }
  }, [step, isAppIntegrations, reloadClientInfo, showDocumentRequests, dueDiligenceInfo])

  return (
    <DueDiligenceFullscreenModal
      step={step}
      setStep={handleSetStep}
      steps={steps}
      progress={dueDiligenceCompanyInfo.financialCollateralProgress}
      header={header}
      onCloseModal={handleCloseModal}
      onNext={onNext}
      nextButtonText={step === VENDORS_STEP && isAppIntegrations ? 'Skip' : 'Next'}
      nextButtonClassName={step === VENDORS_STEP && isAppIntegrations ? styles.skipButton : null}
      onBack={onBack}
      showBackButtonOnFirstStep={step === VENDORS_STEP && isAppIntegrations}
      isDisabled={step === VENDORS_STEP && isVendorsFormInvalid}
      isLoading={isLoading}
      isSaving={dueDiligenceDocumentRequests.isSaving}
      isSaved={dueDiligenceDocumentRequests.isSaved}
    >
      {step === VENDORS_STEP && !isAppIntegrations && (
        <DueDiligenceFinancialsVendors
          formRef={vendorsFormRef}
          onSubmit={handleAdvisorsFormSave}
          setIsFormInvalid={setIsVendorsFormInvalid}
        />
      )}
      {step === VENDORS_STEP && isAppIntegrations && (
        <DueDiligenceFinancialsIntegration handleIntegrations={handleIntegrations} />
      )}
      {step === INVENTORY_LOCATION_STEP && <DueDiligenceFinancialsInventoryLocations />}
      {step === BANK_ACCOUNTS_STEP && <DueDiligenceFinancialsBankAccounts />}
      {step === DOCUMENTATION_STEP && (
        <DueDiligenceClientStepFiles
          step={DueDiligenceDocumentRequestStep.Financials}
          navigationRef={filesNavigationRef}
          handleIntegrations={handleIntegrations}
        />
      )}
    </DueDiligenceFullscreenModal>
  )
}

export default DueDiligenceFinancialsModal
