import React, { useEffect, useState, useCallback, useMemo } from 'react'
import { Form } from 'react-final-form'
import Grid from '@mui/material/Grid'
import Box from '@mui/material/Box'
import TableLoader from '../../Common/TableLoader'
import { Tooltip } from '@mui/material'
import styles from './BankAccountInformation.module.scss'
import genericSs from '@styles/generic.module.scss'
import Card from '../../Common/Card'
import { IBankAccount } from '@common/interfaces/bankAccount'
import Table from '../../Common/Table'
import TableBody from '../../Common/TableBody'
import TableCell from '../../Common/TableCell'
import TableContainer from '../../Common/TableContainer'
import TableHead from '../../Common/TableHead'
import TableRow from '../../Common/TableRow'
import { IBank } from '@common/interfaces/bank'
import { ReactComponent as MoneyIcon } from '../../../assets/images/money-icon-oulined.svg'
import MenuItem from '@mui/material/MenuItem'
import Menu from '@mui/material/Menu'
import AddButton from '../AddButton'
import TableFiltersRow from '../../Common/TableFiltersRow'
import { BANK_ACCOUNT_FILTERS_CONFIG } from '@common/constants/filters'
import FilterContainer from '../../Filters/FilterContainer'
import { buildFiltersDefaults, buildFiltersValidateSchema } from '../../../helpers/filters'
import { debounceEventHandler } from '../../../helpers/helpers'
import AddEditBankAccount from './AddEditBankAccount'
import { ILoadingData } from '../../../redux/types'
import SaveState from '../../Common/SaveState'
import { MenuIcon } from '../../Common/Icons'
import useTable from '../../../hooks/useTable'
import { InternalBanks } from './AddEditBankAccount'
import cn from 'classnames'
import WarningModal from '../../WarningModal'

const INTERNAL_BANK_TO_ROUTING = {
  [InternalBanks.IDB]: '026009768',
  [InternalBanks.Webster]: '221970443',
}

const filtersDefaults = buildFiltersDefaults(BANK_ACCOUNT_FILTERS_CONFIG)
const filtersValidate = buildFiltersValidateSchema(BANK_ACCOUNT_FILTERS_CONFIG)

interface IProps {
  isLoading: boolean
  banks: IBank[]
  bankAccounts: ILoadingData<{ data: IBankAccount[] }>
  createBank: (data: { name: string }) => void
  listBanks: () => void
  createBankAccount: (data: Partial<IBankAccount> & { clientId: string }) => void
  updateBankAccount: (id: string, data: Partial<IBankAccount>) => void
  deleteBankAccount: (id: string) => void
  listBankAccounts: (clientId: string, data: object) => void
  clientId: string
}

const BankAccountInformation = ({
  banks,
  listBanks,
  createBank,
  createBankAccount,
  updateBankAccount,
  deleteBankAccount,
  clientId,
  bankAccounts,
  listBankAccounts,
}: IProps) => {
  const [isEditModalShown, setIsEditModalShown] = useState(false)
  const [isDeleteModalShown, setIsDeleteModalShown] = useState(false)
  const [anchorEl, setAnchorEl] = useState(null)
  const [actionsMenuOpen, setActionsMenuOpen] = useState(false)
  const [selectedRow, setSelectedRow] = useState(null)

  const { filters, handleFiltersChange, orderBy, handleOrderChange } = useTable({
    tableId: 'bankAccountInformation',
    filtersDefaults,
    sortDefault: {
      field: 'is_external',
      direction: 'ASC',
    },
  })

  const handleClickMenu = useCallback((bankAccount, event: React.MouseEvent<HTMLElement>) => {
    setSelectedRow(bankAccount)
    setAnchorEl(event.currentTarget)
    setActionsMenuOpen(true)
  }, [])
  const handleCloseMenu = useCallback(() => {
    setSelectedRow(null)
    setAnchorEl(null)
    setActionsMenuOpen(false)
    setIsEditModalShown(false)
  }, [])

  const { isLoading, bankAccountsData, isSaving, isSaved } = useMemo(
    () => ({
      isLoading: bankAccounts.isLoading,
      isSaving: bankAccounts.isSaving,
      isSaved: bankAccounts.isSaved,
      bankAccountsData: bankAccounts?.data?.data || [],
    }),
    [bankAccounts],
  )

  useEffect(() => {
    listBanks()
  }, [listBanks])

  const handleAddBank = useCallback((name: string) => createBank({ name }), [createBank])

  const debounceListSummary = useMemo(
    () =>
      debounceEventHandler((data: any) => {
        clientId && listBankAccounts(clientId, data)
      }, 500),
    [clientId, listBankAccounts],
  )

  useEffect(() => {
    debounceListSummary({
      filters,
      orderBy: orderBy.field,
      orderDirection: orderBy.direction,
    })
  }, [filters, orderBy, debounceListSummary])

  const openEditModal = useCallback(() => {
    setIsEditModalShown(true)
  }, [])

  const openDeleteModal = useCallback(() => {
    setIsDeleteModalShown(true)
  }, [])
  const cancelDelete = useCallback(() => {
    setIsDeleteModalShown(false)
  }, [])

  const handleDeleteBankAccount = useCallback(() => {
    deleteBankAccount(selectedRow.id)
    setIsDeleteModalShown(false)
    handleCloseMenu()
  }, [deleteBankAccount, handleCloseMenu, selectedRow])

  return (
    <Card noHeaderMargin>
      <Grid container>
        <Box flex={1}>
          <Card withBorder={false} noPadding noHeaderMargin className={styles.card}>
            <AddEditBankAccount
              isEditModalShown={isEditModalShown}
              setIsEditModalShown={setIsEditModalShown}
              handleAddEditBankAccount={!selectedRow ? createBankAccount : updateBankAccount}
              handleAddBank={handleAddBank}
              isAddModal={!selectedRow}
              selectedRow={selectedRow}
              handleCloseMenu={handleCloseMenu}
              banks={banks}
              clientId={clientId}
              inClientSettings
            />
            <Card withBorder={false} noPadding noHeaderMargin>
              <TableContainer className={styles.table}>
                <Form
                  onSubmit={handleFiltersChange}
                  validate={filtersValidate}
                  initialValues={filters}
                  mutators={{
                    setFieldData: ([field, value], state, { changeValue }) => {
                      changeValue(state, field, () => value)
                    },
                  }}
                  render={({ values, handleSubmit, form: { mutators } }) => (
                    <div className={styles.filtersHeader}>
                      <FilterContainer
                        filters={BANK_ACCOUNT_FILTERS_CONFIG}
                        handleSubmit={handleSubmit}
                        mutators={mutators}
                        values={values}
                        appliedFilters={filters}
                        title={`Bank Accounts`}
                        actions={
                          <AddButton
                            variant="outlined"
                            disabled={!clientId}
                            onClick={() => setIsEditModalShown(true)}
                          ></AddButton>
                        }
                      />
                    </div>
                  )}
                />

                <Table>
                  <TableHead>
                    <TableFiltersRow
                      filters={BANK_ACCOUNT_FILTERS_CONFIG}
                      orderBy={orderBy}
                      handleOrderChange={handleOrderChange}
                    />
                  </TableHead>
                  <TableBody>
                    {isLoading && <TableLoader columnsCount={6} />}
                    {bankAccountsData?.length > 0 &&
                      bankAccountsData.map((bankAccount: IBankAccount) => {
                        return (
                          <TableRow key={bankAccount.id}>
                            <TableCell className={genericSs.tableTextLeft}>
                              {bankAccount.bankName}
                              {bankAccount.isFunding && <MoneyIcon />}
                            </TableCell>
                            <TableCell className={genericSs.tableTextLeft}>
                              {bankAccount.isExternal ? bankAccount.accountHolderName : '-'}
                            </TableCell>
                            <TableCell className={genericSs.tableTextRight}>
                              <Tooltip
                                title={bankAccount.bankAccountNumber}
                                arrow
                                placement="top"
                                classes={{
                                  tooltip: styles.bankAccountNumberTooltip,
                                  arrow: styles.bankAccountNumberTooltipArrow,
                                }}
                                leaveDelay={100}
                              >
                                <span>{`XXXXX${bankAccount.bankAccountNumber.slice(-4)}`}</span>
                              </Tooltip>
                            </TableCell>
                            <TableCell className={genericSs.tableTextRight}>
                              {bankAccount.isExternal
                                ? bankAccount.abaRoutingNumber
                                : INTERNAL_BANK_TO_ROUTING[bankAccount.bankName as InternalBanks]}
                            </TableCell>
                            <TableCell className={genericSs.tableTextLeft}>
                              {
                                <Tooltip
                                  title={bankAccount.purpose}
                                  arrow
                                  placement="top"
                                  classes={{
                                    tooltip: styles.bankAccountNumberTooltip,
                                    arrow: styles.bankAccountNumberTooltipArrow,
                                  }}
                                  leaveDelay={100}
                                >
                                  <span>{bankAccount.purpose}</span>
                                </Tooltip>
                              }
                            </TableCell>
                            <TableCell className={genericSs.tableTextLeft}>
                              {bankAccount.isExternal ? (
                                <span className={cn(genericSs.grayCard, genericSs.noMargin)}>
                                  External
                                </span>
                              ) : (
                                <span className={cn(genericSs.greenTag, genericSs.noMargin)}>
                                  Internal
                                </span>
                              )}
                            </TableCell>
                            <TableCell className={genericSs.tableTextLeft}>
                              <Box display="inline-box" ml={1}>
                                <MenuIcon
                                  onClick={(event) => handleClickMenu(bankAccount, event)}
                                  size="small"
                                />
                              </Box>
                            </TableCell>
                          </TableRow>
                        )
                      })}
                    <Menu
                      open={actionsMenuOpen}
                      anchorEl={anchorEl}
                      onClose={handleCloseMenu}
                      className={styles.actionsMenu}
                    >
                      <MenuItem onClick={openEditModal}>Edit</MenuItem>
                      <MenuItem onClick={openDeleteModal}>Delete</MenuItem>
                    </Menu>
                  </TableBody>
                </Table>
                <SaveState isSaving={isSaving} isSaved={isSaved} />
              </TableContainer>
            </Card>
          </Card>
        </Box>
      </Grid>
      {isDeleteModalShown && (
        <WarningModal
          title="Are you sure?"
          warningMessage="This action will delete the bank account"
          onConfirm={handleDeleteBankAccount}
          onCancel={cancelDelete}
          isLoading={isSaving}
          confirmText="Delete"
          cancelText="Cancel"
        />
      )}
    </Card>
  )
}

export default BankAccountInformation
