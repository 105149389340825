import React, { useEffect, useCallback, useMemo, useState } from 'react'
import { useParams } from 'react-router'

import Box from '@mui/material/Box'

import styles from './ReservesTable.module.scss'
import genericSs from '@styles/generic.module.scss'
import LinkButton from '@mui/material/Link'

import Card from '../../components/Common/Card'
import TableContainer from '../Common/TableContainer'
import Table from '../Common/Table'
import TableHead from '../Common/TableHead'
import TableBody from '../Common/TableBody'
import TableRow from '../Common/TableRow'
import TableCell from '../Common/TableCell'
import TableLoader from '../Common/TableLoader'
import SaveState from '../Common/SaveState'
import { ROUTES } from '../../constants/routes'
import { formatPrice } from '../../helpers/helpers'
import {
  FundingRequestStatus,
  IBorrowingBase,
  IReserve,
  ReserveKind,
  IExtraReserveData,
} from '@common/interfaces/bbc'
import { formatter } from '../../helpers/helpers'
import AdjustmentsModal from './AdjustmentsModal'
import ReserveActions from './ReserveActions'
import ExtraReserveActions from './ExtraReserveActions'
import { FIELDS_LABELS } from './constants'
import { CollateralTypes } from '@common/constants/client'
import { usePermissions } from '../../helpers/permissionContext'

import { Link, generatePath } from 'react-router-dom'
import { ILoadingData } from '../../redux/types'
import AddButton from '../Client/AddButton'

interface IProps {
  isLoadingBBC: boolean
  type: any
  bbc: IBorrowingBase
  reservesData: ILoadingData<{ data: IReserve[] }>
  listBBCReserves: (id: string, data: object) => void
  listClientReserves: (id: string, data: object) => void
  createBBCReserve: (id: string, data: object) => Promise<void>
  createClientReserve: (id: string, data: object) => Promise<void>
  updateBBCReserve: (id: string, itemId: string, data: object) => Promise<void>
  updateClientReserve: (id: string, itemId: string, data: object) => Promise<void>
  deleteBBCReserve: (id: string, itemId: string) => Promise<void>
  deleteClientReserve: (id: string, itemId: string) => Promise<void>
  putNotification: (notification: object) => void
  isClientPage?: boolean
  apReservesData: ILoadingData<IExtraReserveData>
  listExtraReserves: (id: string, params?: object) => void
  listClientExtraReserves: (id: string, params?: object) => void
  updateExtraReserves: (id: string, data: object) => Promise<void>
  updateClientExtraReserves: (id: string, data: object) => Promise<void>
  refreshCount?: number
}

const ReservesTable = ({
  isLoadingBBC,
  type,
  bbc,
  reservesData,
  listBBCReserves,
  listClientReserves,
  createBBCReserve,
  createClientReserve,
  updateBBCReserve,
  updateClientReserve,
  deleteBBCReserve,
  deleteClientReserve,
  putNotification,
  isClientPage = false,
  apReservesData,
  listExtraReserves,
  listClientExtraReserves,
  updateExtraReserves,
  updateClientExtraReserves,
  refreshCount,
}: IProps) => {
  const { id } = useParams<{ id: string }>()
  const { isParticipant } = usePermissions()

  const { isLoadingReserves, isSavingReserves, isSavedReserves, reserves } = useMemo(
    () => ({
      isLoadingReserves: reservesData.isLoading,
      isSavingReserves: reservesData.isSaving,
      isSavedReserves: reservesData.isSaved,
      reserves: reservesData?.data?.data || [],
    }),
    [reservesData],
  )
  const {
    isLoading,
    isSaving,
    isSaved,
    data: apReserves,
  } = useMemo(() => apReservesData, [apReservesData])
  const [isAddModalShown, setIsAddModalShown] = useState(false)
  const [isAddExtraModalShown, setIsAddExtraModalShown] = useState(false)

  const isHistorical = useMemo(
    () => [FundingRequestStatus.Completed, FundingRequestStatus.Sent].includes(bbc?.status),
    [bbc],
  )

  useEffect(() => {
    if ([CollateralTypes.ar, CollateralTypes.inventory].includes(type)) {
      if (isClientPage) {
        listClientReserves(id, { type })
      } else {
        listBBCReserves(id, { type })
      }
    }
    if ([CollateralTypes.ap].includes(type)) {
      if (isClientPage) {
        listClientExtraReserves(id)
      } else {
        listExtraReserves(id)
      }
    }
  }, [
    id,
    type,
    listBBCReserves,
    listClientReserves,
    isClientPage,
    listExtraReserves,
    listClientExtraReserves,
  ])

  const refetchReservesList = useCallback(
    (skipLoader: boolean = false) => {
      if ([CollateralTypes.ar, CollateralTypes.inventory].includes(type)) {
        if (isClientPage) {
          listClientReserves(id, { type, skipLoader })
        } else {
          listBBCReserves(id, { type, skipLoader })
        }
      }
      if ([CollateralTypes.ap].includes(type)) {
        if (isClientPage) {
          listClientExtraReserves(id, { skipLoader })
        } else {
          listExtraReserves(id, { skipLoader })
        }
      }
    },
    [
      id,
      listBBCReserves,
      listClientReserves,
      isClientPage,
      type,
      listExtraReserves,
      listClientExtraReserves,
    ],
  )

  const handleCreateReserve = useCallback(
    (values) => {
      const { kind, percent, field, amount, category, reason } = values
      if (
        (kind === ReserveKind.Absolute && !amount) ||
        (kind === ReserveKind.Percent && (!percent || !field)) ||
        !category ||
        !reason
      ) {
        putNotification({ code: 'FORMAT_ERROR', message: 'Please fill all data', type: 'error' })
        return
      }

      const createReserve = isClientPage ? createClientReserve : createBBCReserve
      createReserve(id, {
        kind,
        amount: amount || 0,
        percent: kind === ReserveKind.Percent ? percent : 0,
        field: kind === ReserveKind.Percent ? field : null,
        type,
        category,
        reason,
      }).then(() => {
        refetchReservesList(true)
        setIsAddModalShown(false)
      })
    },
    [
      id,
      isClientPage,
      createBBCReserve,
      createClientReserve,
      putNotification,
      type,
      refetchReservesList,
    ],
  )

  const handleCreateExtraReserve = useCallback(
    (values) => {
      const { creditor, priorityPayable, notes } = values
      if (!creditor || !priorityPayable) {
        return
      }

      const update = isClientPage ? updateClientExtraReserves : updateExtraReserves
      update(id, {
        creditor,
        priorityPayable,
        notes,
      }).then(() => {
        refetchReservesList(true)
        setIsAddExtraModalShown(false)
      })
    },
    [id, isClientPage, updateExtraReserves, updateClientExtraReserves, refetchReservesList],
  )

  const creditors = useMemo(
    () =>
      apReserves?.dictionary?.creditors.map((creditor) => ({
        value: creditor,
        label: creditor,
      })) || [],
    [apReserves],
  )

  const extraReserveList = useMemo(
    () => ({
      extraReserves: apReserves?.data.filter(({ priorityPayable }) => !!priorityPayable) || [],
    }),
    [apReserves],
  )
  if ([CollateralTypes.ar, CollateralTypes.inventory].includes(type)) {
    return (
      <Card noHeaderMargin>
        <TableContainer className={styles.table}>
          <Box display="flex" alignItems="center" justifyContent="space-between" mb={2}>
            <h2>{`${type} Reserves & Adjustments`}</h2>

            {!isHistorical && !isParticipant && (
              <AddButton variant="outlined" onClick={() => setIsAddModalShown(true)} />
            )}
          </Box>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell className={genericSs.tableTextLeft}>Type</TableCell>
                <TableCell className={genericSs.tableTextRight}>Amount</TableCell>
                <TableCell className={genericSs.tableTextLeft}>Category</TableCell>
                <TableCell className={genericSs.tableTextLeft}>Reason</TableCell>
                {!isHistorical && (
                  <TableCell className={genericSs.tableTextRight}>Actions</TableCell>
                )}
              </TableRow>
            </TableHead>
            <TableBody>
              {(isLoadingBBC || isLoadingReserves) && (
                <TableLoader columnsCount={isHistorical ? 4 : 5} rowsCount={3} />
              )}
              {reserves.map((item) => {
                return (
                  <TableRow key={item.id}>
                    <TableCell className={genericSs.tableTextLeft}>
                      {FIELDS_LABELS[item.kind]}
                    </TableCell>
                    <TableCell className={genericSs.tableTextRight}>
                      {item.kind === ReserveKind.Absolute
                        ? formatter.format(item.amount)
                        : `${item.percent}% of ${FIELDS_LABELS[item.field]}`}
                    </TableCell>
                    <TableCell className={genericSs.tableTextLeft}>
                      {FIELDS_LABELS[item.category]}
                    </TableCell>
                    <TableCell className={genericSs.tableTextLeft}>{item.reason}</TableCell>

                    <ReserveActions
                      item={item}
                      isHistorical={isHistorical}
                      isClientPage={isClientPage}
                      deleteClientReserve={deleteClientReserve}
                      deleteBBCReserve={deleteBBCReserve}
                      updateClientReserve={updateClientReserve}
                      updateBBCReserve={updateBBCReserve}
                      type={type}
                      putNotification={putNotification}
                      refetchReservesList={refetchReservesList}
                      isSaving={isSavingReserves}
                    />
                  </TableRow>
                )
              })}
            </TableBody>
          </Table>

          <Box display="flex" alignItems="center" justifyContent="flex-end">
            <SaveState isSaving={isSavingReserves} isSaved={isSavedReserves} />
          </Box>
        </TableContainer>
        {isAddModalShown && (
          <AdjustmentsModal
            type={type}
            adjustmentType="add"
            item={{ kind: ReserveKind.Absolute }}
            handleSubmit={handleCreateReserve}
            isModalShowing={isAddModalShown}
            setIsModalShowing={setIsAddModalShown}
            isSaving={isSavingReserves}
          />
        )}
      </Card>
    )
  }
  if ([CollateralTypes.ap].includes(type)) {
    return (
      <Card noHeaderMargin>
        <TableContainer className={styles.table}>
          <Box display="flex" alignItems="center" justifyContent="space-between" mb={2}>
            <h2>{`${type} Reserves & Adjustments`}</h2>

            {!isHistorical && (
              <AddButton variant="outlined" onClick={() => setIsAddExtraModalShown(true)} />
            )}
          </Box>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell className={genericSs.tableTextLeft}>Vendor</TableCell>
                <TableCell className={genericSs.tableTextRight}>
                  Priority Payables Threshold
                </TableCell>
                {!isClientPage && (
                  <TableCell className={genericSs.tableTextRight}>Payables Reserve</TableCell>
                )}
                <TableCell className={genericSs.tableTextLeft}>Notes</TableCell>
                {!isHistorical && (
                  <TableCell className={genericSs.tableTextRight}>Actions</TableCell>
                )}
              </TableRow>
            </TableHead>
            <TableBody>
              {(isLoadingBBC || isLoading) && (
                <TableLoader
                  columnsCount={isHistorical ? (isClientPage ? 3 : 4) : isClientPage ? 4 : 5}
                  rowsCount={3}
                />
              )}
              {extraReserveList?.extraReserves.map((item, index) => {
                return (
                  <TableRow key={index}>
                    <TableCell className={genericSs.tableTextLeft}>
                      {!isParticipant ? (
                        <LinkButton
                          component={Link}
                          to={generatePath(ROUTES.ENTITY_PAGE, {
                            id: item?.entityId,
                          })}
                        >
                          {item.creditor}
                        </LinkButton>
                      ) : (
                        item.creditor
                      )}
                    </TableCell>
                    <TableCell className={genericSs.tableTextRight}>
                      {item.priorityPayable === '0' ? 'All' : item.priorityPayable}
                    </TableCell>
                    {!isClientPage && (
                      <TableCell className={genericSs.tableTextRight}>
                        $&nbsp;
                        {formatPrice(item[`over${item.priorityPayable}Payables`])}
                      </TableCell>
                    )}
                    <TableCell className={genericSs.tableTextLeft}>{item.notes}</TableCell>
                    {!isParticipant && (
                      <ExtraReserveActions
                        item={item}
                        isHistorical={isHistorical}
                        isClientPage={isClientPage}
                        updateExtraReserves={updateExtraReserves}
                        updateClientExtraReserves={updateClientExtraReserves}
                        refetchReservesList={refetchReservesList}
                        isSaving={isSaving}
                      />
                    )}
                  </TableRow>
                )
              })}
            </TableBody>
          </Table>

          <Box display="flex" alignItems="center" justifyContent="flex-end">
            <SaveState isSaving={isSaving} isSaved={isSaved} />
          </Box>
        </TableContainer>
        {isAddExtraModalShown && (
          <AdjustmentsModal
            type={type}
            adjustmentType="add"
            item={null}
            handleSubmit={handleCreateExtraReserve}
            isModalShowing={isAddExtraModalShown}
            setIsModalShowing={setIsAddExtraModalShown}
            creditors={creditors}
            extraReserveList={extraReserveList}
            isSaving={isSaving}
          />
        )}
      </Card>
    )
  } else {
    return null
  }
}

export default ReservesTable
