import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'

import ProspectAddTerms from './ProspectAddTermsPage'
import { reporting, prospectTerms } from '../../redux/prospect/selectors'
import {
  createProspectTerms,
  updateProspectTerms,
  show,
  listProspectTerms,
} from '../../redux/prospect/actions'

const selector = createStructuredSelector({
  reporting,
  prospectTerms,
})

const actions = {
  createProspectTerms,
  updateProspectTerms,
  listProspectTerms,
  show,
}

export default connect(selector, actions)(ProspectAddTerms)
