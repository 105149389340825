import Base from './base'
import { IBankAccount } from '@common/interfaces/bankAccount'

export default class BankAccount extends Base {
  create(data: Partial<IBankAccount> & { clientId: string }) {
    return this.apiClient.post('bank-accounts', data)
  }

  list(data: object) {
    return this.apiClient.get('bank-accounts', data)
  }

  update(id: string, data: Partial<IBankAccount>) {
    return this.apiClient.put(`bank-accounts/${id}`, data)
  }

  delete(id: string) {
    return this.apiClient.delete(`bank-accounts/${id}`)
  }

  listBalances(ongoingReportingId: string) {
    return this.apiClient.get(`bank-accounts/${ongoingReportingId}/balances`)
  }
}
