import React from 'react'

import {
  DueDiligenceTableItemType,
  IDueDiligenceBankAccount,
} from '@common/interfaces/dueDiligence'
import DueDiligenceApplicationTable from '../DueDiligenceApplicationTable'
import { ILoadingData } from '../../redux/types'

interface IProps {
  dueDiligenceFinancialsBankAccounts: ILoadingData<{ data: IDueDiligenceBankAccount[] }>
  listFinancialsBankAccounts: (params: object) => void
  hideFinancialsBankAccounts: () => void
}

const DueDiligenceApplicationTableBankAccounts = ({
  dueDiligenceFinancialsBankAccounts,
  listFinancialsBankAccounts,
  hideFinancialsBankAccounts,
}: IProps) => {
  return (
    <DueDiligenceApplicationTable
      type={DueDiligenceTableItemType.BankAccount}
      data={dueDiligenceFinancialsBankAccounts}
      handleList={listFinancialsBankAccounts}
      handleHide={hideFinancialsBankAccounts}
    />
  )
}

export default DueDiligenceApplicationTableBankAccounts
