import React, { useEffect, useCallback, useState, useMemo } from 'react'
import { useParams, useHistory, generatePath, useLocation } from 'react-router'
import queryString from 'query-string'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import moment from 'moment'
import { ReactComponent as HomeIcon } from '@assets/images/home-icon.svg'
import { ActivityType } from '@common/interfaces/activity'
import Loader from '../../components/Loader'
import Card from '../../components/Common/Card'
import { IBorrowingBase, FundingRequestStatus, ReportingFlow } from '@common/interfaces/bbc'
import BBCInventoryIneligibleSKUTable from '../../components/BBCInventoryIneligibleSKUTable'
import BBCInventoryIneligibleFields from '../../components/BBCInventoryIneligibleFields'
import BBCInventoryIneligibleCustomRules from '../../components/BBCInventoryIneligibleCustomRules'
import { ROUTES } from '../../constants/routes'
import { LOAN_TYPES } from '@common/constants/client'
import Tabs from '../../components/Common/Tabs'
import BBCNotes from '../../components/BBCNotes'
import {
  formatDate,
  historyChangeEventHandler,
  visibilityChangeEventHandler,
} from '../../helpers/helpers'
import { useSetPageTitle } from '../../hooks/useSetPageTitle'
import Breadcrumbs from '../../components/Common/Breadcrumbs'
import { useLoadInfo } from '../../hooks/useLoadInfo'

const TABS = ['Receivables', 'Inventory']

const TABS_ROUTES = {
  Receivables: ROUTES.BBC_COLLATERAL_ELIGIBILITY_RECEIVABLES,
  Inventory: ROUTES.BBC_COLLATERAL_ELIGIBILITY_INVENTORY,
}

interface IProps {
  isLoading: boolean
  bbc: IBorrowingBase
  show: (id: string) => void
  trackActivity: (data: object, beacon?: boolean) => void
}

const BBCCollateralEligibilityInventoryPage = ({ isLoading, bbc, show, trackActivity }: IProps) => {
  const { id } = useParams<{ id: string }>()
  const history = useHistory()
  const [refreshCounter, setRefreshCounter] = useState(0)

  const { search, pathname }: { search: string; pathname: string } = useLocation()
  const { bbcStep } = queryString.parse(search)

  useLoadInfo({ id, info: bbc, show })
  useSetPageTitle(
    bbc
      ? `${bbc.clientName} ${bbc?.isTest ? 'Test ' : ''}BBC ${formatDate(bbc.recordDate)}`
      : 'BBC',
  )

  useEffect(() => {
    if (bbc?.clientInfo?.loanType === LOAN_TYPES.ar) {
      history.push(
        `${generatePath(ROUTES.BBC_COLLATERAL_ELIGIBILITY_RECEIVABLES, { id })}?bbcStep=${bbcStep}`,
      )
    }
  }, [bbc, history, id, bbcStep])

  const [startTime, setStartTime] = useState(moment().toISOString())

  const logActivity = useCallback(
    (beacon: boolean = false) => {
      if (bbc && bbc?.status !== FundingRequestStatus.Completed) {
        const endTime = moment().toISOString()
        const data = {
          borrowingBaseId: bbc.id,
          startedAt: startTime,
          finishedAt: endTime,
          workflow: ActivityType.Funding,
          step: 'Inventory Eligibility',
        }
        trackActivity(data, beacon)
        setStartTime(endTime)
      }
    },
    [bbc, startTime, trackActivity],
  )

  useEffect(() => {
    const unlisten = historyChangeEventHandler(logActivity, history, pathname)
    return unlisten
  }, [history, pathname, logActivity])

  useEffect(() => {
    const unlisten = visibilityChangeEventHandler(logActivity)
    return unlisten
  }, [logActivity])

  const onTabChange = useCallback(
    (tab) => {
      history.push(`${generatePath(TABS_ROUTES[tab], { id })}?bbcStep=${bbcStep}`)
    },
    [history, id, bbcStep],
  )

  const breadcrumbs = useMemo(() => {
    return [
      {
        link: ROUTES.HOMEPAGE,
        Icon: HomeIcon,
      },
      {
        link: ROUTES.ACTIVITY_QUEUE,
        title: 'Operations',
      },
      {
        title: bbc?.clientName,
        link: bbc?.clientInfo?.id
          ? generatePath(ROUTES.CLIENT_PAGE, { id: bbc?.clientInfo?.id })
          : '',
      },
      {
        link: generatePath(ROUTES[bbcStep as string] || ROUTES.BBC_SUMMARY_INVENTORY, { id }),
        title: bbc
          ? `${bbc?.isTest ? 'Test ' : ''}BBC ${formatDate(bbc.recordDate)}`
          : `${bbc?.isTest ? 'Test ' : ''}BBC`,
      },
      {
        link: `${generatePath(ROUTES.BBC_COLLATERAL_ELIGIBILITY_INVENTORY, {
          id,
        })}?bbcStep=${bbcStep}`,
        title: 'Collateral eligibility',
      },
    ]
  }, [bbc, id, bbcStep])

  return (
    <Box py={1} pr={2}>
      {isLoading && <Loader />}

      <BBCNotes />

      <Breadcrumbs breadcrumbs={breadcrumbs} noMarginLeft />

      <Grid container rowSpacing={3} mt={0}>
        <Grid item xs={12}>
          {bbc?.clientInfo.loanType === LOAN_TYPES.arAndInventory && (
            <Box display="flex" justifyContent="space-between" alignItems="center">
              <Tabs tabs={TABS} selected="Inventory" handleChange={onTabChange} />
            </Box>
          )}
        </Grid>

        <Grid item xs={12}>
          <Card noHeaderMargin>
            <BBCInventoryIneligibleCustomRules />
          </Card>
        </Grid>

        <Grid item xs={12}>
          <Card noHeaderMargin>
            <BBCInventoryIneligibleSKUTable
              reportingFlow={ReportingFlow.BorrowingBase}
              setRefreshCounter={setRefreshCounter}
            />
          </Card>
        </Grid>

        <Grid item xs={12}>
          <BBCInventoryIneligibleFields
            refreshCounter={refreshCounter}
            reportingFlow={ReportingFlow.BorrowingBase}
          />
        </Grid>
      </Grid>
    </Box>
  )
}

export default BBCCollateralEligibilityInventoryPage
