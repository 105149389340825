import React, { useEffect, useMemo } from 'react'
import { generatePath, Link as RouterLink } from 'react-router-dom'
import Skeleton from '@mui/material/Skeleton'
import Link from '@mui/material/Link'

import genericSs from '@styles/generic.module.scss'
import styles from './ProspectReportingSummaryInventory.module.scss'

import { ILoadingData } from '../../redux/types'
import { useParams } from 'react-router'
import { IProspectSummaryInventory } from '@common/interfaces/prospects'
import { formatNumber, formatPriceNoDecimal } from '../../helpers/helpers'
import { ROUTES } from '../../constants/routes'

interface IProps {
  showInventorySummaryInfo: (id: string) => void
  inventorySummaryInfo: ILoadingData<IProspectSummaryInventory>
}

const ProspectReportingSummaryInventory = ({
  inventorySummaryInfo,
  showInventorySummaryInfo,
}: IProps) => {
  const { id } = useParams<{ id: string }>()
  const { isLoading, data } = useMemo(
    () => ({
      isLoading: inventorySummaryInfo.isLoading,
      data: inventorySummaryInfo.data,
    }),
    [inventorySummaryInfo],
  )

  useEffect(() => {
    showInventorySummaryInfo(id)
  }, [id, showInventorySummaryInfo])

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <div className={styles.title}>Inventory</div>

        <Link
          component={RouterLink}
          to={generatePath(ROUTES.PROSPECT_REPORTING_INVENTORY, { id })}
          className={styles.editLink}
        >
          Edit
        </Link>
      </div>
      <div className={styles.data}>
        <div className={styles.row}>
          <span>Number of SKUs</span>
          {isLoading ? (
            <Skeleton width={100} height={15} />
          ) : (
            <span>{formatNumber(data?.numberOfSKUs)}</span>
          )}
        </div>
        <div className={styles.row}>
          <span>Inventory Value</span>
          {isLoading ? (
            <Skeleton width={100} height={15} />
          ) : (
            <span>
              <span className={genericSs.pricePrefix}>$</span>
              {formatPriceNoDecimal(data?.inventoryValue)}
            </span>
          )}
        </div>
        <div className={styles.row}>
          <span>Average COGS</span>
          {isLoading ? (
            <Skeleton width={100} height={15} />
          ) : (
            <span>
              <span className={genericSs.pricePrefix}>$</span>
              {formatPriceNoDecimal(data?.averageCOGS)}
            </span>
          )}
        </div>
        <div className={styles.row}>
          <span>DIO (Inventory Report)</span>
          {isLoading ? (
            <Skeleton width={100} height={15} />
          ) : (
            <span>{formatNumber(data?.dioInventory)}</span>
          )}
        </div>
        <div className={styles.row}>
          <span>Average Inventory (BS)</span>
          {isLoading ? (
            <Skeleton width={100} height={15} />
          ) : (
            <span>
              <span className={genericSs.pricePrefix}>$</span>
              {formatPriceNoDecimal(data?.averageInventoryBS)}
            </span>
          )}
        </div>
        <div className={styles.row}>
          <span>DIO (Inventory from BS)</span>

          {isLoading ? (
            <Skeleton width={100} height={15} />
          ) : (
            <span>{formatNumber(data?.dioBS)}</span>
          )}
        </div>
      </div>
    </div>
  )
}

export default ProspectReportingSummaryInventory
