import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'

import ProspectReportingSummaryReceivables from './ProspectReportingSummaryReceivables'

import { arSummary } from '../../redux/prospect/selectors'
import { listARSummary } from '../../redux/prospect/actions'

const selector = createStructuredSelector({
  arSummary,
})

const actions = {
  listARSummary,
}

export default connect(selector, actions)(ProspectReportingSummaryReceivables)
