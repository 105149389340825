import Base from './base'

export default class Selaseforce extends Base {
  listAccounts() {
    return this.apiClient.get('salesforce/accounts')
  }

  listInvestors() {
    return this.apiClient.get('salesforce/investors')
  }

  showAccount(id: string) {
    return this.apiClient.get(`salesforce/accounts/${id}`)
  }

  updateAccount(id: string, data: object) {
    return this.apiClient.put(`salesforce/accounts/${id}`, data)
  }

  describeAccount() {
    return this.apiClient.get('salesforce/accounts/describe')
  }
}
