import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'

import ClientLoanLedger from './ClientLoanLedger'

import { listLoanLedger } from '../../../redux/clientInfo/actions'
import {
  listClientParticipations,
  createParticipationWire,
  exportParticipationActivity,
} from '../../../redux/participant/actions'
import { loanLedger } from '../../../redux/clientInfo/selectors'
import { clientParticipations, refreshCount } from '../../../redux/participant/selectors'
import { loanBalanceStartDate } from '../../../redux/loanBalance/selectors'

const selector = createStructuredSelector({
  loanLedger,
  clientParticipations,
  loanBalanceStartDate,
  refreshCount,
})

const actions = {
  listLoanLedger,
  listClientParticipations,
  createParticipationWire,
  exportParticipationActivity,
}

export default connect(selector, actions)(ClientLoanLedger)
