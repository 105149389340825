import React from 'react'
import { generatePath, Link } from 'react-router-dom'
import LinkButton from '@mui/material/Link'

import Button from '../Common/Button'
import { IEntityInfo, IEntityMergeConflicts } from '@common/interfaces/entityInfo'
import Modal from '../Common/Modal'
import { ROUTES } from '../../constants/routes'

interface IProps {
  entity: IEntityInfo
  entityMergeConflicts?: IEntityMergeConflicts[]
  handleCloseConfirmWithConflictModal: () => void
  handleConfirmWithConflicts: () => void
}

const EntitySettingsGeneralPage = ({
  entity,
  entityMergeConflicts,
  handleCloseConfirmWithConflictModal,
  handleConfirmWithConflicts,
}: IProps) => {
  return (
    <Modal
      open
      title="Are you sure?"
      onCancel={handleCloseConfirmWithConflictModal}
      footer={[
        <Button
          key="cancel"
          variant="contained"
          color="secondary"
          secondary
          onClick={handleCloseConfirmWithConflictModal}
        >
          Cancel
        </Button>,
        <Button
          key="submit"
          variant="contained"
          color="primary"
          onClick={handleConfirmWithConflicts}
        >
          Confirm
        </Button>,
      ]}
    >
      Eligibility rules will be overwritten for the following clients:
      <div>
        {entityMergeConflicts.map((item: IEntityMergeConflicts) => (
          <div key={item.id}>
            <LinkButton
              component={Link}
              to={generatePath(
                entity.debtor
                  ? ROUTES.CLIENT_SETUP_COLLATERAL_RECEIVABLE
                  : ROUTES.CLIENT_SETUP_COLLATERAL_PAYABLE,
                {
                  id: item.id,
                },
              )}
            >
              {item.clientName}
            </LinkButton>
          </div>
        ))}
      </div>
    </Modal>
  )
}

export default EntitySettingsGeneralPage
