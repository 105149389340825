import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'

import ClientSettingsPage from './ClientSettingsPage'

import { isAdminLoggedAsClient } from '../../redux/profile/selectors'

const selector = createStructuredSelector({
  isAdminLoggedAsClient,
})

export default connect(selector)(ClientSettingsPage)
