import React, { useState, useEffect, useCallback, useMemo, useRef } from 'react'
import { Link, generatePath, useParams } from 'react-router-dom'
import { Form } from 'react-final-form'
import Box from '@mui/material/Box'
import LinkButton from '@mui/material/Link'
import moment from 'moment'
import InfiniteScroll from 'react-infinite-scroll-component'
import cn from 'classnames'

import styles from './CollectionsReconciliation.module.scss'
import genericSs from '@styles/generic.module.scss'

import { COLLECTIONS_RECONCILIATION_LIST_FILTERS_CONFIG, PER_PAGE } from '@common/constants/filters'
import {
  CollectionsReconciliationGroupBy,
  ICollectionsReconciliation,
  ICollectionsReconciliationData,
  ICollectionsReconciliationOptions,
} from '@common/interfaces/client'
import { ReportingFlow, ReportingPeriods } from '@common/interfaces/bbc'
import { debounceEventHandler, formatValue } from '../../helpers/helpers'
import { buildFiltersDefaults, buildFiltersValidateSchema } from '../../helpers/filters'
import FilterContainer from '../Filters/FilterContainer'
import { ILoadingData } from '../../redux/types'
import { ExpandAndMinimize } from '../Common/Icons'
import FullscreenModal from '../Common/FullscreenModal'
import DatePicker from '../Common/DatePicker'
import SelectField from '../Common/SelectField'
import useTable from '../../hooks/useTable'
import { CATEGORIES } from '@common/constants/tracking'
import useTrackVisualizationsTable from '../../hooks/useTrackVisualizationsTable'
import TableContainer from '../Common/TableContainer'
import Table from '../Common/Table'
import TableHead from '../Common/TableHead'
import TableBody from '../Common/TableBody'
import TableRow from '../Common/TableRow'
import TableCell from '../Common/TableCell'
import TableFiltersRow from '../Common/TableFiltersRow'
import TableLoader from '../Common/TableLoader'
import { ROUTES } from '../../constants/routes'
import FormattedTableCell from '../Common/FormattedTableCell'
import TableFooter from '../Common/TableFooter'
import ActiveToolbar from '../ActiveToolbar'
import ChangedValue from '../ChangedValue'

const COLLECTIONS_RECONCILIATION_GROUP_BY_OPTIONS = [
  {
    value: CollectionsReconciliationGroupBy.Month,
    label: 'Month',
  },
  {
    value: CollectionsReconciliationGroupBy.Parent,
    label: 'Parent entity',
  },
  {
    value: CollectionsReconciliationGroupBy.Customer,
    label: 'Customer',
  },
]

const filtersValidate = buildFiltersValidateSchema(COLLECTIONS_RECONCILIATION_LIST_FILTERS_CONFIG)
const filtersDefaults = buildFiltersDefaults(COLLECTIONS_RECONCILIATION_LIST_FILTERS_CONFIG)

const isDilutionChangeField = (field: string): boolean => field === 'dilution_change'

interface IProps {
  reportingFlow: ReportingFlow
  collectionsReconciliation: ILoadingData<ICollectionsReconciliationData>
  collectionsReconciliationOptions: ILoadingData<ICollectionsReconciliationOptions>
  listCollectionsReconciliation: (
    id: string,
    params?: {
      filters?: object
    },
  ) => void
  listCollectionsReconciliationOptions: (id: string, params?: object) => void
  hideCollectionsReconciliation: () => void
}

const CollectionsReconciliation = ({
  reportingFlow,
  collectionsReconciliation,
  collectionsReconciliationOptions,
  listCollectionsReconciliation,
  listCollectionsReconciliationOptions,
  hideCollectionsReconciliation,
}: IProps) => {
  const { id } = useParams<{ id: string }>()
  const wrapperRef = useRef(null)
  const [isModalShown, setIsModalShown] = useState(false)
  const [currentDateRange, setCurrentDateRange] = useState<{ startDate: string; endDate: string }>({
    startDate: '',
    endDate: '',
  })
  const [groupBy, setGroupBy] = useState<CollectionsReconciliationGroupBy>(
    CollectionsReconciliationGroupBy.Customer,
  )
  const [isInitialized, setIsInitialized] = useState(false)

  const {
    filters,
    handleFiltersChange,
    handleOrderChange,
    orderBy,
    activeItem,
    activeItems,
    setActiveItem,
    setActiveItems,
    handleSelectRow,
    resetActiveItems,
    quickFilter,
    handleQuickFilterChange,
  } = useTable({
    tableId: 'collectionsReconciliation',
    filtersDefaults,
    sortDefault: { field: 'ar_gl_dilution', direction: 'DESC' },
  })

  const { isLoading, collectionsReconciliationData, collectionsReconciliationTotals, totalItems } =
    useMemo(
      () => ({
        isLoading: collectionsReconciliation.isLoading,
        collectionsReconciliationData: collectionsReconciliation?.data?.data || [],
        collectionsReconciliationTotals: collectionsReconciliation?.data?.totals || [],
        totalItems: collectionsReconciliation?.data?.totalItems || 0,
      }),
      [collectionsReconciliation],
    )

  const { maxDate, minDate } = useMemo(
    () => ({
      maxDate: collectionsReconciliationOptions?.data?.maxDate,
      minDate: collectionsReconciliationOptions?.data?.minDate,
    }),
    [collectionsReconciliationOptions],
  )

  useEffect(() => {
    if (!isInitialized && minDate && maxDate) {
      setCurrentDateRange({
        startDate: moment
          .max([moment(minDate), moment(maxDate).startOf('month').subtract(1, 'year')])
          .format('YYYY-MM-DD'),
        endDate: moment(maxDate).endOf('month').format('YYYY-MM-DD'),
      })
      setIsInitialized(true)
    }
  }, [isInitialized, minDate, maxDate])

  useEffect(() => {
    listCollectionsReconciliationOptions(id, {
      reportingFlow,
    })
  }, [listCollectionsReconciliationOptions, id, reportingFlow])

  useEffect(() => {
    return () => {
      hideCollectionsReconciliation()
    }
  }, [hideCollectionsReconciliation])

  const datesBoundary = useMemo(
    () => ({
      minDate: minDate || null,
      maxDate: maxDate || null,
    }),
    [minDate, maxDate],
  )

  const fetchCollectionsReconciliation = useCallback(
    (data: any) => {
      const params = {
        ...data,
        filters: {
          ...data.filters,
        },
        groupBy,
        startDate: currentDateRange.startDate,
        endDate: currentDateRange.endDate,
        reportingFlow,
        maxDate,
        minDate,
      }
      minDate && currentDateRange.startDate && listCollectionsReconciliation(id, params)
    },
    [listCollectionsReconciliation, id, currentDateRange, reportingFlow, groupBy, maxDate, minDate],
  )

  const debounceListCollectionsReconciliation = useMemo(
    () => debounceEventHandler(fetchCollectionsReconciliation, 500),
    [fetchCollectionsReconciliation],
  )

  const loadMore = useCallback(() => {
    debounceListCollectionsReconciliation({
      loadMore: true,
      page: Math.ceil(collectionsReconciliationData.length / PER_PAGE),
      orderBy: orderBy.field,
      orderDirection: orderBy.direction,
      filters,
    })
  }, [collectionsReconciliationData, orderBy, filters, debounceListCollectionsReconciliation])

  useEffect(() => {
    debounceListCollectionsReconciliation({
      page: 0,
      orderBy: orderBy.field,
      orderDirection: orderBy.direction,
      filters,
    })
  }, [filters, orderBy, debounceListCollectionsReconciliation])

  const handleModalToggle = useCallback(() => {
    setIsModalShown((prev) => !prev)
  }, [])

  const handleDateChange = useCallback(
    (values: { startDate: string; endDate: string }) => {
      setCurrentDateRange(values)
    },
    [setCurrentDateRange],
  )

  const handleGroupByChange = useCallback(
    ({ target: { value } }) => {
      if (value === CollectionsReconciliationGroupBy.Month) {
        handleOrderChange('label', 'ASC')
      } else if (groupBy === CollectionsReconciliationGroupBy.Month) {
        handleOrderChange('ar_gl_dilution', 'DESC')
      }
      setGroupBy(value)
    },
    [groupBy, handleOrderChange],
  )

  const filtersConfig = useMemo(
    () =>
      COLLECTIONS_RECONCILIATION_LIST_FILTERS_CONFIG.map((item) => ({
        ...item,
        title:
          item.field === 'label'
            ? groupBy === CollectionsReconciliationGroupBy.Month
              ? 'Month'
              : 'Customer'
            : item.title,
      })),
    [groupBy],
  )

  const visualizationsParams = useMemo(
    () => ({
      clientId: id,
    }),
    [id],
  )

  const visualizationsFilters = useMemo(
    () => ({
      ...filters,
      groupBy,
      endDate: currentDateRange.endDate,
      startDate: currentDateRange.startDate,
    }),
    [filters, currentDateRange, groupBy],
  )

  useTrackVisualizationsTable({
    isInitialized,
    category: CATEGORIES.collectionsReconciliation,
    params: visualizationsParams,
    filtersConfig: filtersConfig,
    filters: visualizationsFilters,
    orderBy,
  })

  const listFieldsConfig = useMemo(
    () =>
      filtersConfig
        .filter((filter) => ['amount', 'percent'].includes(filter.type))
        .map((filter) => ({
          field: filter.field,
          fieldType: filter.type,
        })),

    [filtersConfig],
  )

  const totalRow = useMemo(() => {
    const totalRow = collectionsReconciliationData
      .filter((_, index) => activeItems.includes(index))
      .reduce(
        (totalRowResult, row) => {
          listFieldsConfig.forEach(({ field }) => {
            totalRowResult[field] += Number(row[field]) || 0
          })

          return totalRowResult
        },
        {
          ...listFieldsConfig
            .map(({ field }) => field)
            .reduce((totalRowResult: Partial<ICollectionsReconciliation>, field: string) => {
              totalRowResult[field] = 0

              return totalRowResult
            }, {}),
        },
      )

    totalRow.ar_gl_dilution = totalRow.ar_gl_invoices
      ? 1 - totalRow.ar_gl_payments / totalRow.ar_gl_invoices
      : 0
    totalRow.actual_dilution = totalRow.ar_gl_invoices
      ? 1 - totalRow.actual_collections / totalRow.ar_gl_invoices
      : 0
    totalRow.dilution_change = totalRow.ar_gl_dilution - totalRow.actual_dilution

    return totalRow
  }, [collectionsReconciliationData, activeItems, listFieldsConfig])

  return (
    <FullscreenModal
      isOpen={isModalShown}
      setIsOpen={setIsModalShown}
      classes={{ body: styles.fullScreenModal }}
    >
      <>
        <Form
          validate={filtersValidate}
          onSubmit={handleFiltersChange}
          initialValues={filters}
          mutators={{
            setFieldData: ([field, value], state, { changeValue }) => {
              changeValue(state, field, () => value)
            },
          }}
          render={({ values, handleSubmit, form: { mutators } }) => (
            <FilterContainer
              filters={filtersConfig}
              handleSubmit={handleSubmit}
              mutators={mutators}
              values={values}
              appliedFilters={filters}
              appliedQuickFilter={quickFilter}
              handleAppliedQuickFilterChange={handleQuickFilterChange}
              title={
                <Box mr={2}>
                  <h2>Collections & Dilution Reconciliation</h2>
                </Box>
              }
              actions={
                <Box display="flex" alignItems="center" gap={1}>
                  <SelectField
                    key={groupBy}
                    label="Group by"
                    variant="outlined"
                    className={styles.selectField}
                    useFinalForm={false}
                    name="groupBy"
                    options={COLLECTIONS_RECONCILIATION_GROUP_BY_OPTIONS}
                    onChange={handleGroupByChange}
                    value={groupBy}
                    defaultValue=""
                    withTopLabel
                  />

                  <DatePicker
                    reportingPeriod={ReportingPeriods.Monthly}
                    currentDateRange={currentDateRange}
                    datesBoundary={datesBoundary}
                    onChange={handleDateChange}
                    disabled={isLoading}
                  />

                  <ExpandAndMinimize action={handleModalToggle} isExpanded={isModalShown} />
                </Box>
              }
            />
          )}
        />

        <TableContainer
          ref={wrapperRef}
          className={styles.table}
          onActiveRowsChange={setActiveItems}
          onActiveRowChange={setActiveItem}
          hasFooter
        >
          <Table>
            <TableHead>
              <TableFiltersRow
                filters={filtersConfig}
                orderBy={orderBy}
                handleOrderChange={handleOrderChange}
                isChildrenAtStart
              />
            </TableHead>

            <TableBody id="collections-reconciliation">
              {isLoading ? (
                <TableLoader columnsCount={filtersConfig.length} />
              ) : (
                <InfiniteScroll
                  dataLength={collectionsReconciliationData.length}
                  next={loadMore}
                  hasMore={collectionsReconciliationData.length < totalItems}
                  loader={<TableLoader columnsCount={filtersConfig.length} rowsCount={1} />}
                  scrollableTarget={`collections-reconciliation`}
                >
                  {collectionsReconciliationData.map((item, index) => (
                    <TableRow
                      key={item.label}
                      data-index={index}
                      className={cn('activableRow', {
                        activeRow: activeItems.includes(index),
                        currentActiveRow: activeItem === index,
                      })}
                      onClick={(event) => handleSelectRow(event, index)}
                    >
                      <TableCell className={genericSs.tableTextLeft}>
                        {item.entity_id ? (
                          <LinkButton
                            component={Link}
                            to={generatePath(ROUTES.ENTITY_PAGE, {
                              id: item.entity_id,
                            })}
                          >
                            {item.label}
                          </LinkButton>
                        ) : (
                          <span>{item.label}</span>
                        )}
                      </TableCell>

                      {listFieldsConfig.map(({ field, fieldType }) => (
                        <FormattedTableCell
                          key={field}
                          value={isDilutionChangeField(field) ? item[field] * 100 : item[field]}
                          fieldType={fieldType}
                          isChip={isDilutionChangeField(field)}
                          showZeroChip={isDilutionChangeField(field)}
                        />
                      ))}
                    </TableRow>
                  ))}
                </InfiniteScroll>
              )}
            </TableBody>

            <TableFooter>
              {!collectionsReconciliationTotals ? (
                <TableLoader columnsCount={filtersConfig.length} rowsCount={3} />
              ) : (
                collectionsReconciliationTotals.map((item) => (
                  <TableRow key={item.label}>
                    <TableCell className={genericSs.tableTextLeft}>{item.label}</TableCell>

                    {listFieldsConfig.map(({ field, fieldType }) => (
                      <FormattedTableCell
                        key={field}
                        value={isDilutionChangeField(field) ? item[field] * 100 : item[field]}
                        fieldType={fieldType}
                        isChip={isDilutionChangeField(field)}
                        showZeroChip={isDilutionChangeField(field)}
                      />
                    ))}
                  </TableRow>
                ))
              )}
            </TableFooter>
          </Table>

          <ActiveToolbar
            activeItems={activeItems}
            className={cn(styles.activeToolbar)}
            isFullscreen={isModalShown}
            containerRef={wrapperRef}
            resetActiveItems={resetActiveItems}
          >
            {listFieldsConfig
              .filter(({ field }) => !isDilutionChangeField(field))
              .map(({ field, fieldType }) => (
                <div key={field} className={genericSs.tableTextRight}>
                  {formatValue({
                    value: totalRow?.[field],
                    type: fieldType,
                  })}
                </div>
              ))}

            <div className={genericSs.tableTextRight}>
              <ChangedValue
                isChip
                changedValue={totalRow?.dilution_change * 100}
                type="percent"
                showZero
              />
            </div>
          </ActiveToolbar>
        </TableContainer>
      </>
    </FullscreenModal>
  )
}

export default CollectionsReconciliation
