export interface IBankBalance {
  id: string
  bankAccountId: string
  balance: number
  recordDate: string
  clientName: string
  fileId: string | null
  ongoingReportingId: string | null
  bankAccountInfo: IBankAccount
}

export interface IBankAccount {
  id: string
  bankName: string
  bankAccountNumber: string
  abaRoutingNumber: string
  bankAccountLink: string
  accountHolderName?: string
  isExternal: boolean
  isFunding: boolean
  purpose: string | null
  bankBalances: IBankBalance[]
}

export enum BankAccountType {
  External = 'External',
  Internal = 'Internal',
}
