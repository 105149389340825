import React, { Dispatch, SetStateAction, useCallback, useState } from 'react'
import { useLocation } from 'react-router-dom'
import queryString from 'query-string'
import Modal from '../../components/Common/Modal'

import styles from './ChecksUploads.module.scss'

import { IFile } from '@common/interfaces/box'
import Button from '../../components/Common/Button'
import { UploadFile } from '../Common/UploadFile'

interface IProps {
  checksFiles: IFile[]
  uploadChecks: (data: FormData) => void
  deleteFile: (ids: string[]) => Promise<void>
  setRefreshCounter: Dispatch<SetStateAction<number>>
}

const ChecksUploads = ({ checksFiles, uploadChecks, deleteFile, setRefreshCounter }: IProps) => {
  const [filesForDelete, setFilesForDelete] = useState<IFile[]>([])
  const [isDeleteModalShown, setIsDeleteModalShown] = useState(false)
  const [isUploading, setIsUploading] = useState(false)
  const [isDeleting, setIsDeleting] = useState(false)

  const { search }: { search: string } = useLocation()

  const [openModal, setOpenModal] = useState<boolean>(false)

  const handleToggleModal = useCallback(() => {
    setOpenModal((isOpen) => !isOpen)
  }, [])

  const handleUploadChecks = useCallback(
    async (loadedFiles: File[]) => {
      setIsUploading(true)
      const qs = queryString.parse(search)
      const formData = new FormData()

      loadedFiles.forEach((file) => {
        formData.append('files[]', file, file.name)
      })
      qs?.activityId && formData.append('activityId', qs?.activityId.toString())

      await uploadChecks(formData)
      setRefreshCounter((prev) => prev + 1)
      setIsUploading(false)
    },
    [uploadChecks, search, setRefreshCounter],
  )
  const handleUpload = useCallback(
    (loadedFiles: File[]) => {
      if (loadedFiles.length) {
        handleUploadChecks(loadedFiles)
      }
    },
    [handleUploadChecks],
  )

  const handleDeleteFiles = useCallback(
    (indexes: number[]) => {
      setIsDeleteModalShown(true)
      setFilesForDelete(
        indexes
          .sort((a, b) => b - a)
          .map((number, index) => checksFiles[number === undefined ? index : number]),
      )
    },
    [checksFiles],
  )

  const handleDeleteCancel = useCallback(() => {
    setIsDeleteModalShown(false)
    setFilesForDelete([])
  }, [])

  const handleDeleteConfirm = useCallback(async () => {
    setIsDeleting(true)
    await deleteFile(filesForDelete.map((file) => file.id))
    setIsDeleting(false)
    setIsDeleteModalShown(false)
    setFilesForDelete([])
    setRefreshCounter((prev) => prev + 1)
  }, [deleteFile, filesForDelete, setRefreshCounter])

  return (
    <div>
      <div className={styles.container}>
        <UploadFile
          key={checksFiles.length}
          size={'lg'}
          files={checksFiles}
          handleToggleModal={handleToggleModal}
          isInModal={true}
          isModalOpen={openModal}
          title="Check file"
          frequency="From Dwight bank account"
          onDelete={handleDeleteFiles}
          onDropAccepted={handleUpload}
          isLoading={isUploading}
        />
      </div>

      {isDeleteModalShown && filesForDelete.length > 0 && (
        <Modal
          open={isDeleteModalShown}
          onCancel={handleDeleteCancel}
          title="Are you sure you want to delete this check file?"
          footer={[
            <Button key="cancel" color="primary" variant="outlined" onClick={handleDeleteCancel}>
              Cancel
            </Button>,
            <Button
              key="submit"
              isLoading={isDeleting}
              color="primary"
              variant="contained"
              onClick={handleDeleteConfirm}
            >
              Delete
            </Button>,
          ]}
        >
          All check and file data will be permanently deleted.
        </Modal>
      )}
    </div>
  )
}

export default ChecksUploads
