import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'

import ProspectInventoryAnalysis from './ProspectInventoryAnalysis'

import { reporting } from '../../redux/prospect/selectors'
import { listInventory, updateReportingFlowAdditionalData } from '../../redux/prospect/actions'

const selector = createStructuredSelector({
  reporting,
})

const actions = {
  listInventory,
  updateReportingFlowAdditionalData,
}

export default connect(selector, actions)(ProspectInventoryAnalysis)
