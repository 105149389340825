import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'

import DueDiligenceFinancialsBankAccounts from './DueDiligenceFinancialsBankAccounts'

import { list as banks } from '../../redux/bank/selectors'
import { createBank, listBanks } from '../../redux/bank/actions'
import { dueDiligenceFinancialsBankAccounts } from '../../redux/dueDiligence/selectors'
import {
  listFinancialsBankAccounts,
  hideFinancialsBankAccounts,
  addFinancialsBankAccount,
  updateFinancialsBankAccount,
  deleteFinancialsBankAccount,
} from '../../redux/dueDiligence/actions'

const selector = createStructuredSelector({
  dueDiligenceFinancialsBankAccounts,
  banks,
})
const actions = {
  listFinancialsBankAccounts,
  hideFinancialsBankAccounts,
  addFinancialsBankAccount,
  updateFinancialsBankAccount,
  deleteFinancialsBankAccount,
  createBank,
  listBanks,
}

export default connect(selector, actions)(DueDiligenceFinancialsBankAccounts)
