import React, { useState, useCallback, useMemo } from 'react'
import cn from 'classnames'

import styles from './BBCNotes.module.scss'

import { FundingRequestStatus, IBorrowingBase } from '@common/interfaces/bbc'
import BBCEditorModal from '../BBCEditorModal'
import { ReactComponent as NoteEditIcon } from '../../assets/images/note-edit-icon.svg'

interface IProps {
  bbc: IBorrowingBase
  showBBC: (id: string) => void
  updateClientItemsToMonitor: (id: string, data: string) => void
}

const BBCNotes = ({ bbc, showBBC, updateClientItemsToMonitor }: IProps) => {
  const [isItemsToMonitorModalShown, setIsItemsToMonitorModalShown] = useState(false)

  const isHistorical = useMemo(
    () => [FundingRequestStatus.Completed, FundingRequestStatus.Sent].includes(bbc?.status),
    [bbc],
  )

  const toggleItemsToMonitorModal = useCallback(() => {
    setIsItemsToMonitorModalShown((isShown) => !isShown)
    showBBC(bbc.id)
  }, [showBBC, bbc])

  const handleChangeItemsToMonitor = useCallback(
    (value: string) => {
      if (bbc?.clientInfo) {
        updateClientItemsToMonitor(bbc.clientInfo.id, value)
      }
    },
    [updateClientItemsToMonitor, bbc],
  )

  if (!bbc?.id) {
    return null
  }

  return (
    <>
      <div
        className={cn(styles.iconWrapper, styles.iconItemsToMonitorWrapper)}
        onClick={toggleItemsToMonitorModal}
      >
        <NoteEditIcon />
      </div>

      <BBCEditorModal
        isShown={isItemsToMonitorModalShown}
        onCancel={toggleItemsToMonitorModal}
        className={styles.modalRoot}
        title="Items To Monitor"
        Icon={NoteEditIcon}
        bbc={bbc}
        value={bbc.clientInfo?.itemsToMonitor || ''}
        handleChange={handleChangeItemsToMonitor}
        readOnly={isHistorical}
      />
    </>
  )
}

export default BBCNotes
