import React, { useEffect } from 'react'
import styles from './CodatRedirectPage.module.scss'
import DwightLogo from '../../assets/images/dwight-text.svg'

const RedirectPage = () => {
  useEffect(() => {
    const previousPage = sessionStorage.getItem('previousPage') || '/'
    window.location.href = previousPage
  })

  return (
    <div className={styles.redirectContainer}>
      <div className={styles.topLogoContainer}>
        <img className={styles.dwightLogo} src={DwightLogo} alt="Dwight Funding" />
      </div>
      <div className={styles.redirectText}>Redirecting back to Atlas...</div>
    </div>
  )
}

export default RedirectPage
