import React from 'react'
import { useParams } from 'react-router'
import Box from '@mui/material/Box'

import ClientSetupHeader from '../../components/ClientSetupHeader'
import Participations from '../../components/Client/Participations'

const ClientSetupLoanStructureParticipantsPage = () => {
  const { id: clientId } = useParams<{ id?: string }>()

  return (
    <Box py={1} pr={2}>
      <ClientSetupHeader />

      <Box>
        <Participations clientId={clientId} />
      </Box>
    </Box>
  )
}

export default ClientSetupLoanStructureParticipantsPage
