import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'

import BBCIneligibleARDetailsInvoices from './BBCIneligibleARDetailsInvoices'

import { clientCollaterals } from '../../redux/bbc/selectors'
import { listArIneligibleStatsDetailsInvoices } from '../../redux/bbc/actions'

const selector = createStructuredSelector({
  clientCollaterals,
})

const actions = {
  listArIneligibleStatsDetailsInvoices,
}

export default connect(selector, actions)(BBCIneligibleARDetailsInvoices)
