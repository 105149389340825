import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'

import ClientAccountActivityDeleteModal from './ClientAccountActivityDeleteModal'

import { clientInfo } from '../../../redux/clientInfo/selectors'
import { deleteAggregation } from '../../../redux/clientInfo/actions'

const selector = createStructuredSelector({
  clientInfo,
})

const actions = {
  deleteAggregation,
}

export default connect(selector, actions)(ClientAccountActivityDeleteModal)
