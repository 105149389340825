import React, { useCallback, useMemo, useState } from 'react'
import { useHistory, matchPath } from 'react-router'
import { default as MuiTabs } from '@mui/material/Tabs'
import { default as MuiTab } from '@mui/material/Tab'
import MenuItem from '@mui/material/MenuItem'
import Menu from '@mui/material/Menu'
import styles from './Tabs.module.scss'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'

interface IProps {
  tabs: string[]
  tabsIcon?: any
  selected: string
  handleChange: (tab: string) => void
  menu?: { [key: string]: { label: string; route: string }[] }
  handleClickMenuItem?: (tab: string, menuItem: string) => void
  actionsRequired?: { [key: string]: boolean | boolean[] }
}

const Tabs = ({
  tabs,
  tabsIcon,
  selected,
  handleChange,
  menu,
  handleClickMenuItem,
  actionsRequired,
}: IProps) => {
  const onChange = useCallback(
    (event: any, tab: number) => handleChange(tabs[tab]),
    [handleChange, tabs],
  )

  const history = useHistory()
  const value = useMemo(() => tabs.findIndex((tab) => tab === selected), [selected, tabs])

  const [anchorEl, setAnchorEl] = useState(null)
  const [menuOptions, setMenuOptions] = useState([])
  const [menuTab, setMenuTab] = useState(null)

  const handleCloseMenu = useCallback(() => {
    setAnchorEl(null)
    setMenuTab(null)
  }, [])

  const handleOpenMenu = useCallback(
    (event: any, tab: string) => {
      setMenuTab(tab)
      setMenuOptions(menu[tab])
      setAnchorEl(event.currentTarget)
    },
    [setAnchorEl, setMenuTab, setMenuOptions, menu],
  )

  const selectedMenuItem = useMemo(() => {
    const currentTab = menuOptions.find((item) => {
      const exactPath = `${history.location.pathname}${history.location.search}`
      const match = matchPath(exactPath, {
        path: item.route,
      })?.isExact
      return !!match
    })
    return currentTab
  }, [history.location.pathname, menuOptions, history.location.search])

  const handleClick = useCallback(
    (menuItem: string) => {
      handleClickMenuItem(menuTab, menuItem)
      handleCloseMenu()
    },
    [handleClickMenuItem, menuTab, handleCloseMenu],
  )

  return (
    <MuiTabs
      value={value}
      onChange={onChange}
      classes={{
        root: tabsIcon ? styles.rootIcon : styles.root,
        indicator: styles.indicator,
        flexContainer: styles.flexContainer,
      }}
    >
      {tabs.map((tab) => {
        return (
          <MuiTab
            key={tab}
            icon={tabsIcon?.[tab]}
            disableRipple
            label={
              !tabsIcon?.[tab] && (
                <span>
                  {tab}
                  {(Array.isArray(actionsRequired?.[tab])
                    ? (actionsRequired?.[tab] as Boolean[]).some(Boolean)
                    : !!actionsRequired?.[tab]) && <span className={styles.actionRequired} />}
                  {menu?.[tab] && <KeyboardArrowDownIcon className={styles.downArrow} />}
                </span>
              )
            }
            classes={{
              root: tabsIcon ? styles.tabRootIcon : styles.tabRoot,
              selected: tabsIcon ? styles.tabSelectedIcon : styles.tabSelected,
            }}
            onClick={menu?.[tab] ? (event) => handleOpenMenu(event, tab) : undefined}
          />
        )
      })}

      {menuOptions && (
        <Menu
          open={menuTab || false}
          onClose={handleCloseMenu}
          anchorEl={anchorEl}
          classes={{
            paper: styles.menuPaper,
          }}
        >
          {menuOptions.map((item, index) => (
            <MenuItem
              key={item.label}
              selected={selectedMenuItem?.label === item.label}
              onClick={() => handleClick(item.label)}
              classes={{
                root: styles.menuRoot,
                selected: styles.tabSelected,
              }}
            >
              <span>
                {item.icon}
                {item.label}
                {(actionsRequired?.[menuTab] as Boolean[])?.[index] && (
                  <span className={styles.actionRequired} />
                )}
              </span>
            </MenuItem>
          ))}
        </Menu>
      )}
    </MuiTabs>
  )
}

export default Tabs
