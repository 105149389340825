import { createRequestTypes } from '../../helpers/helpers'
import { IApi } from '../../api'
import { CommonPromiseAction } from '../types'

export const prefix = 'passThrough'

const LIST_PASS_THROUGH = createRequestTypes(prefix, 'LIST_PASS_THROUGH')

export const [LIST_PASS_THROUGH_REQUEST, LIST_PASS_THROUGH_SUCCESS, LIST_PASS_THROUGH_FAILURE] =
  LIST_PASS_THROUGH

export const listPassThroughs: CommonPromiseAction = (data: object) => ({
  type: LIST_PASS_THROUGH,
  promise: (api: IApi) => api.passThroughs.list(data),
  params: data,
})

const SUBMIT_PASS_THROUGH = createRequestTypes(prefix, 'SUBMIT_PASS_THROUGH')

export const [
  SUBMIT_PASS_THROUGH_REQUEST,
  SUBMIT_PASS_THROUGH_SUCCESS,
  SUBMIT_PASS_THROUGH_FAILURE,
] = SUBMIT_PASS_THROUGH

export const submitPassThroughs: CommonPromiseAction = (data: object) => ({
  type: SUBMIT_PASS_THROUGH,
  promise: (api: IApi) => api.passThroughs.submit(data),
})

const DELETE_PASS_THROUGH = createRequestTypes(prefix, 'DELETE_PASS_THROUGH')

export const [
  DELETE_PASS_THROUGH_REQUEST,
  DELETE_PASS_THROUGH_SUCCESS,
  DELETE_PASS_THROUGH_FAILURE,
] = DELETE_PASS_THROUGH

export const deletePassthrough: CommonPromiseAction = (id: string) => ({
  type: DELETE_PASS_THROUGH,
  promise: (api: IApi) => api.passThroughs.delete(id),
})
