import { show } from '../../redux/dueDiligence/actions'
import { dueDiligenceInfo } from './../../redux/dueDiligence/selectors'
import { createStructuredSelector } from 'reselect'
import LoanCommitteeReportHeader from './LoanCommitteeReportHeader'
import { connect } from 'react-redux'

const selector = createStructuredSelector({
  dueDiligenceInfo,
})

const actions = {
  show,
}

export default connect(selector, actions)(LoanCommitteeReportHeader)
