import { connect } from 'react-redux'
import { createSelector, createStructuredSelector } from 'reselect'

import UserManagement from './UserManagement'

import { isLoading, list as users } from '../../../redux/user/selectors'
import { clientInfo } from '../../../redux/clientInfo/selectors'
import {
  listUsers,
  createUser,
  updateUser,
  deleteUser,
  inviteUser,
} from '../../../redux/user/actions'

const primaryUserId = createSelector(
  users,
  (users) => users?.data?.data?.find(({ isPrimary }) => Boolean(isPrimary))?.id,
)

const selector = createStructuredSelector({
  isLoading,
  primaryUserId,
  users,
  clientInfo,
})

const actions = {
  listUsers,
  createUser,
  updateUser,
  deleteUser,
  inviteUser,
}

export default connect(selector, actions)(UserManagement)
