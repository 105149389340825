import React from 'react'
import Tooltip from '@mui/material/Tooltip'

import styles from './MultiCellTooltip.module.scss'

import { formatPercent, formatPriceConditionalDecimal } from '../../helpers/helpers'

export interface IMultiCellTotal {
  type: 'currency' | 'percent'
  count: number
  sum: number
  avg: number
}

const formatValue = (type: string, data: string | number) => {
  switch (type) {
    case 'currency':
      return `$${formatPriceConditionalDecimal(data)}`
    case 'percent':
      return formatPercent(+data, 2)
    default:
      return data
  }
}
const MultiCellTooltip = ({
  isActive,
  data,
  children,
}: {
  isActive: boolean
  data?: IMultiCellTotal
  children: React.ReactElement
}) => {
  if (!isActive || !data) {
    return children
  }

  return (
    <Tooltip
      title={
        <div className={styles.tooltipContent}>
          <div className={styles.tooltipContentValue}>{data.count} selected</div>
          <div className={styles.tooltipContentHeader}>Sum</div>
          <div className={styles.tooltipContentValue}>{formatValue(data.type, data.sum)}</div>
          <div className={styles.tooltipContentHeader}>Average</div>
          <div className={styles.tooltipContentValue}>{formatValue(data.type, data.avg)}</div>
        </div>
      }
      placement="right"
      enterDelay={600}
      arrow
      classes={{
        arrow: styles.arrow,
        tooltip: styles.tooltip,
      }}
    >
      {children}
    </Tooltip>
  )
}

export default MultiCellTooltip
