import React from 'react'
import AttachmentDeleteButton from './AttachmentDeleteButton'
import Link from '@mui/material/Link'
import styles from './Attachments.module.scss'
import Tooltip from '@mui/material/Tooltip'
import { IAttachment } from '@common/interfaces/notes'

interface IProps {
  attachment: IAttachment
  readOnly: boolean
  index: number
  removeAttachment: (index: number) => void
  attachmentIconComponent: any
}

const NonImageAttachment = ({
  attachment,
  readOnly,
  index,
  removeAttachment,
  attachmentIconComponent,
}: IProps) => {
  return (
    <div className={styles.attachmentUnit}>
      <Link
        href={attachment.attachmentSrc}
        target="_blank"
        rel="noopener noreferrer"
        download={attachment.fileName}
      >
        <Tooltip title={attachment?.fileName} placement="top">
          {attachmentIconComponent}
        </Tooltip>
      </Link>
      {!readOnly && <AttachmentDeleteButton index={index} removeAttachment={removeAttachment} />}
    </div>
  )
}

export default NonImageAttachment
