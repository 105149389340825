import { Box, Grid } from '@mui/material'
import React, { useMemo } from 'react'
import LoanCommitteeReportHeader from '../../components/LoanCommitteeReportHeader'
import BBCAPSummaryTable from '../../components/BBCAPSummaryTable'
import { DUE_DILIGENCE_ROUTES_MAP, IDueDiligence } from '@common/interfaces/dueDiligence'
import { OngoingReportingType, ReportingFlow } from '@common/interfaces/bbc'
import { generatePath } from 'react-router-dom'
import { useParams } from 'react-router-dom'
import { ROUTES } from '../../constants/routes'
import { GraphType } from '../../hooks/useGraphTogggle'
interface IProps {
  dueDiligenceInfo: IDueDiligence
}

const LoanCommitteeReportPayablesPage = ({ dueDiligenceInfo }: IProps) => {
  const { id } = useParams<{ id: string }>()

  const editLink = useMemo(
    () =>
      generatePath(ROUTES.DUE_DILIGENCE_PROCESS_DOCUMENTS_LIST, {
        id,
        type: DUE_DILIGENCE_ROUTES_MAP[OngoingReportingType.AP],
      }),
    [id],
  )

  return (
    <Box py={1} pr={2}>
      <LoanCommitteeReportHeader />
      <Grid container spacing={2} mt={1}>
        <Grid item xs={12}>
          <BBCAPSummaryTable
            refreshCount={1}
            isFilesSaving={false}
            title={'Payables'}
            reportingFlow={ReportingFlow.LoanCommitteeReport}
            clientInfo={dueDiligenceInfo}
            editLink={editLink}
            fixedType={GraphType.Chart}
          />
        </Grid>
        <Grid item xs={12}>
          <BBCAPSummaryTable
            refreshCount={1}
            isFilesSaving={false}
            title={'Payables'}
            reportingFlow={ReportingFlow.LoanCommitteeReport}
            clientInfo={dueDiligenceInfo}
            editLink={editLink}
            fixedType={GraphType.Table}
          />
        </Grid>
      </Grid>
    </Box>
  )
}

export default LoanCommitteeReportPayablesPage
