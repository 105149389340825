import React, { useMemo } from 'react'
import { generatePath, Link } from 'react-router-dom'
import Box from '@mui/material/Box'
import LinkButton from '@mui/material/Link'
import MuiAvatar from '@mui/material/Avatar'

import styles from './GlobalSearchPage.module.scss'
import genericSs from '@styles/generic.module.scss'

import { IUser, USER_ROLE_LABEL, UserRole } from '@common/interfaces/user'
import { ROUTES } from '../../constants/routes'
import TableRow from '../../components/Common/TableRow'
import TableCell from '../../components/Common/TableCell'
import Avatar from '../../components/Common/Avatar'
import { replaceJSX } from './GlobalSearchPage'

const GlobalSearchPageTableRowUser = ({
  user,
  searchString,
}: {
  user: IUser
  searchString: string
}) => {
  const link = useMemo(
    () =>
      user.role === UserRole.CLIENT_USER
        ? generatePath(ROUTES.CLIENT_SETUP_USERS, { id: user.clientId })
        : ROUTES.USER_MANAGEMENT,
    [user],
  )

  return (
    <TableRow>
      <TableCell className={genericSs.tableTextLeft}>
        <Box display="flex" alignItems="center" gap={2}>
          <Avatar user={user} />
          <LinkButton component={Link} to={link}>
            {replaceJSX(user.firstName, searchString)} {replaceJSX(user.lastName, searchString)}
          </LinkButton>
        </Box>
      </TableCell>
      <TableCell className={genericSs.tableTextLeft}>
        {replaceJSX(user.email, searchString)}
      </TableCell>
      <TableCell className={genericSs.tableTextLeft}>
        {user.clientName && (
          <Box display="flex" alignItems="center" gap={2}>
            {user.clientIconUrl && (
              <MuiAvatar
                src={user.clientIconUrl}
                alt={user.clientName}
                className={styles.clientAvatar}
              />
            )}
            <LinkButton
              component={Link}
              to={generatePath(ROUTES.CLIENT_PAGE, { id: user.clientId })}
            >
              {replaceJSX(user.clientName, searchString)}
            </LinkButton>
          </Box>
        )}
      </TableCell>
      <TableCell className={genericSs.tableTextLeft}>
        {replaceJSX(USER_ROLE_LABEL[user.role], searchString)}
      </TableCell>
    </TableRow>
  )
}

export default GlobalSearchPageTableRowUser
