import { connect } from 'react-redux'
import ParticipantDashboardHeader from './ParticipantDashboardHeader'
import { participantInfo } from './../../redux/participant/selectors'
import { createStructuredSelector } from 'reselect'

const selector = createStructuredSelector({
  participantInfo,
})

export default connect(selector, null)(ParticipantDashboardHeader)
