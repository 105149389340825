import React, { useEffect, useCallback, useState, useMemo } from 'react'
import { OnChange } from 'react-final-form-listeners'
import { Form } from 'react-final-form'
import Box from '@mui/material/Box'
import styles from './FileHeaderMapping.module.scss'
import genericSs from '@styles/generic.module.scss'
import Card from '../Common/Card'
import TableContainer from '../Common/TableContainer/TableContainer'
import Table from '../Common/Table/Table'
import TableHead from '../Common/TableHead/TableHead'
import TableRow from '../Common/TableRow/TableRow'
import TableCell from '../Common/TableCell/TableCell'
import TableBody from '../Common/TableBody/TableBody'
import Modal from '../Common/Modal'
import Button from '../Common/Button'
import AddButton from '../Client/AddButton'
import { IFileHeaderData } from '@common/interfaces/file'
import { INVENTORY_INELIGIBILITY_FIELDS, WorkflowPage } from '@common/interfaces/bbc'
import { ILoadingData } from '../../redux/types'
import FormField from '../Common/FormField'
import SaveState from '../Common/SaveState'
import Grid from '@mui/material/Grid'
import * as Yup from 'yup'
import { makeValidate } from 'mui-rff'
import { textToTitleCase } from '@common/helpers/helpers'
import classNames from 'classnames'

const options = [
  {
    value: 'notApplicable',
    label: 'N/A',
  },
  {
    value: 'description',
    label: 'Description',
  },
  ...Object.keys(INVENTORY_INELIGIBILITY_FIELDS).map((key) => ({
    value: key,
    label: INVENTORY_INELIGIBILITY_FIELDS[key].reason,
  })),
]

const WithModal = ({ children, onClose }: { children: React.ReactNode; onClose: () => void }) => {
  return (
    <Modal open onCancel={onClose}>
      {children}
    </Modal>
  )
}

const ConditionalModal = ({
  children,
  onClose,
  inModal,
}: {
  children: React.ReactNode
  inModal: boolean
  onClose: () => void
}) => {
  return inModal ? <WithModal onClose={onClose}>{children}</WithModal> : <>{children}</>
}

interface IProps {
  id: string
  listHeaderMapping: (params: object) => void
  headerMappings: ILoadingData<IFileHeaderData>
  updateHeaderMapping: (id: string, data: object) => void
  createHeaderMapping: (data: object) => void
  fileType: string
  onClose: () => void
  workflow?: WorkflowPage
  inModal?: boolean
  onSave?: () => void
}

const FileHeaderMapping = ({
  id,
  headerMappings,
  fileType,
  listHeaderMapping,
  updateHeaderMapping,
  createHeaderMapping,
  onClose,
  workflow = WorkflowPage.client,
  inModal = true,
  onSave,
}: IProps) => {
  const [isCreateModalShown, setIsCreateModalShown] = useState(false)

  useEffect(() => {
    id && listHeaderMapping({ id, fileType, workflow })
  }, [id, listHeaderMapping, fileType, workflow])

  const handleCreateHeaderMapping = useCallback(
    async (values) => {
      const data = {
        ...values,
        fileType,
        workflow,
        id,
      }
      await createHeaderMapping(data)
      setIsCreateModalShown(false)
    },
    [createHeaderMapping, id, fileType, workflow],
  )

  const handleUpdateHeaderMapping = useCallback(
    (values) => {
      updateHeaderMapping(values.id, {
        ...values,
        fileType,
      })
    },
    [updateHeaderMapping, fileType],
  )

  const { headers, isSaving, isSaved } = useMemo(
    () => ({
      headers: headerMappings?.data?.data || [],
      isSaving: headerMappings?.isSaving,
      isSaved: headerMappings?.isSaved,
    }),

    [headerMappings],
  )

  const initialValues = useMemo(() => {
    return headers?.reduce((acc, header) => {
      acc[header.id] = {
        id: header.id,
        mappedColumnName: header.mappedColumnName,
      }
      return acc
    }, {})
  }, [headers])

  const existingHeaders = useMemo(() => {
    return headers?.map((header) => header.fileHeaderName)
  }, [headers])

  const mappedColumnNames = useMemo(() => {
    return headers?.map((header) => header.mappedColumnName)
  }, [headers])

  const validate = useMemo(() => {
    return makeValidate(
      Yup.object().shape({
        fileHeaderName: Yup.string()
          .required('Required')
          .trim()
          .lowercase()
          .notOneOf(existingHeaders, 'Header already exists'),
        mappedColumnName: Yup.string().required('Required'),
      }),
    )
  }, [existingHeaders])

  const handleOpenCreateModal = useCallback(() => {
    setIsCreateModalShown(true)
  }, [])

  const handleCloseCreateModal = useCallback(() => {
    setIsCreateModalShown(false)
  }, [])

  const updatedOptions = useMemo(() => {
    return options.map((option) => {
      if (mappedColumnNames.includes(option.value) && option.value !== 'notApplicable') {
        return {
          ...option,
          disabled: true,
        }
      }
      return option
    })
  }, [mappedColumnNames])

  return (
    <ConditionalModal onClose={onClose} inModal={inModal}>
      <Card
        noHeaderMargin
        noPadding
        withBorder={false}
        title={
          <Box
            display="flex"
            justifyContent={inModal ? 'space-between' : 'flex-end'}
            alignItems="center"
          >
            {inModal && <Box>Column Mapping</Box>}
            <AddButton
              className={classNames({
                [styles.addButton]: inModal,
              })}
              variant="outlined"
              disabled={!id}
              onClick={handleOpenCreateModal}
            ></AddButton>
          </Box>
        }
      >
        <Box flex={1}>
          <Card withBorder={false} noPadding noHeaderMargin>
            <Form
              onSubmit={handleCreateHeaderMapping}
              validate={validate}
              render={({ dirty, submitting, invalid, form, handleSubmit }) => (
                <form>
                  <Modal
                    open={isCreateModalShown}
                    onCancel={handleCloseCreateModal}
                    size="small"
                    title="Add Column Mapping"
                    footer={[
                      <Button
                        key="submit"
                        color="primary"
                        small={false}
                        variant="contained"
                        disabled={!dirty || invalid || submitting}
                        onClick={async () => {
                          await handleSubmit()
                          form.reset()
                        }}
                        fullWidth
                      >
                        Add
                      </Button>,
                    ]}
                  >
                    <Box>
                      <FormField
                        name="fileHeaderName"
                        label="Column Name"
                        type="text"
                        labelSize="small"
                      />
                      <FormField
                        name="mappedColumnName"
                        label="Mapped Column Name"
                        options={options}
                        type="select"
                      />
                    </Box>
                  </Modal>
                </form>
              )}
            />
          </Card>

          {headers?.length > 0 && (
            <>
              <Card withBorder={false} noPadding noHeaderMargin>
                <TableContainer className={styles.table}>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell className={genericSs.tableTextLeft}>File Column</TableCell>
                        <TableCell className={genericSs.tableTextLeft}>Mapped Column</TableCell>
                        <TableCell className={genericSs.tableTextLeft}>Sample Data</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {headers.map((header) => {
                        return (
                          <Form
                            key={header.id}
                            initialValues={initialValues[header.id]}
                            onSubmit={handleUpdateHeaderMapping}
                            render={({ dirty, handleSubmit }) => (
                              <TableRow>
                                <TableCell className={genericSs.tableTextLeft}>
                                  {textToTitleCase(header.fileHeaderName)}
                                </TableCell>
                                <TableCell className={genericSs.tableTextLeft}>
                                  <FormField
                                    name="mappedColumnName"
                                    label=""
                                    options={updatedOptions}
                                    type="select"
                                    size="small"
                                  />
                                </TableCell>
                                <TableCell className={genericSs.tableTextLeft}>
                                  {header.sampleData}
                                </TableCell>

                                <OnChange name="mappedColumnName">
                                  {() => dirty && handleSubmit()}
                                </OnChange>
                              </TableRow>
                            )}
                          />
                        )
                      })}
                    </TableBody>
                  </Table>
                  <Grid container justifyContent={'space-between'} alignItems={'center'}>
                    <SaveState isSaving={isSaving} isSaved={isSaved} />
                    {onSave && (
                      <Button onClick={onSave} className={styles.saveButton}>
                        Save
                      </Button>
                    )}
                  </Grid>
                </TableContainer>
              </Card>
            </>
          )}
        </Box>
      </Card>
    </ConditionalModal>
  )
}

export default FileHeaderMapping
