import React, { useMemo } from 'react'
import { useLocation, matchPath } from 'react-router'
import ClientSettingsMenu from '../ClientSettingsMenu'
import Box from '@mui/material/Box'
import { ROUTES } from '../../constants/routes'

const SecondaryMenu = () => {
  const { pathname } = useLocation()

  const currentTab = useMemo(
    () =>
      matchPath(pathname, ROUTES.SETTINGS_SECURITY)
        ? 'Security'
        : matchPath(pathname, ROUTES.CLIENT_SETTINGS_INTEGRATIONS)
        ? 'App integrations'
        : matchPath(pathname, ROUTES.CLIENT_SETTINGS_NOTIFICATIONS)
        ? 'Notification settings'
        : matchPath(pathname, ROUTES.SETTINGS_PROFILE) || matchPath(pathname, ROUTES.SETTINGS)
        ? 'Profile details'
        : null,
    [pathname],
  )

  return (
    currentTab && (
      <Box width={350} py={1} pr={2}>
        <ClientSettingsMenu currentTab={currentTab} />
      </Box>
    )
  )
}

export default SecondaryMenu
