import Base from './base'

export default class Flag extends Base {
  list(params: any) {
    return this.apiClient.get(`flags`, params)
  }

  update(id: string, params: any) {
    return this.apiClient.put(`flags/${id}`, params)
  }

  getFlagDetails(id: string, data: object) {
    return this.apiClient.get(`flags/${id}/details`, data)
  }
  getFlagDetailsDuplicateInvoices(id: string, data: object) {
    return this.apiClient.get(`flags/${id}/details-duplicate-invoices`, data)
  }
  getFlagDetailPaidPriorityInvoices(id: string, data: object) {
    return this.apiClient.get(`flags/${id}/details-paid-priority-invoices`, data)
  }
  getFlagDetailsPendingCashDominion(id: string, data: object) {
    return this.apiClient.get(`flags/${id}/details-pending-cash-dominion`, data)
  }
  calculateEntityFlags(params: object) {
    return this.apiClient.get(`flags/calculate-entity`, params)
  }
  calculateBBCFlags(params: object) {
    return this.apiClient.get(`flags/calculate-bbc`, params)
  }
  calculateOPSFlags(params: object) {
    return this.apiClient.get(`flags/calculate-ops`, params)
  }
  calculateOngoingReportingFlags(params: object) {
    return this.apiClient.get(`flags/calculate-ongoing-reporting`, params)
  }
  getNewFlagsCount(params: object) {
    return this.apiClient.get(`flags/count`, params)
  }

  calculateBankTransactionFlags(id: string) {
    return this.apiClient.post(`flags/calculate-bank-transactions`, { id })
  }
}
