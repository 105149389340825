import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'

import UserManagementPage from './UserManagementPage'

import { isLoading, list as users } from '../../redux/user/selectors'
import { listUsers, createUser, updateUser, deleteUser, inviteUser } from '../../redux/user/actions'
import {
  list as listClients,
  addOrRemoveManager,
  removeManager,
} from '../../redux/clientInfo/actions'
import { clients } from '../../redux/clientInfo/selectors'

const selector = createStructuredSelector({
  isLoading,
  users,
  clients,
})

const actions = {
  listUsers,
  createUser,
  updateUser,
  deleteUser,
  inviteUser,
  addOrRemoveManager,
  removeManager,
  listClients,
}

export { UserManagementPage }

export default connect(selector, actions)(UserManagementPage)
