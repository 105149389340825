import React, { useCallback, useMemo, useState } from 'react'
import cn from 'classnames'
import { withRouter, RouteComponentProps } from 'react-router'
import { Location } from 'history'
import ListItem from '@mui/material/ListItem'
import Collapse from '@mui/material/Collapse'
import List from '@mui/material/List'

import NestedNavItem from './NestedNavItem'
import { IMenuData, ISubMenuData } from '../SideMenuConfig'
import { isItemActive } from '../utils'
import { ReactComponent as ArrowDropUp } from '../../../assets/images/drop-up-icon.svg'
import { ReactComponent as ArrowDropDown } from '../../../assets/images/drop-down-icon.svg'

import styles from '../SideMenu.module.scss'

interface IProps extends Partial<IMenuData> {
  handleItemExpand: (itemName: string) => void
  itemExpanded: boolean
  sidebarExpanded: boolean
  location: Location
}

const ExpandableNavItem = ({
  icon,
  label,
  subItems,
  handleItemExpand,
  itemExpanded,
  sidebarExpanded,
  location,
}: IProps) => {
  const [isHovered, setIsHovered] = useState(false)

  const handleExpandClick = useCallback(() => handleItemExpand(label), [handleItemExpand, label])

  const isActive = useMemo(() => isItemActive(subItems, location), [location, subItems])

  const handleMouseEnter = () => setIsHovered(true)
  const handleMouseLeave = () => setIsHovered(false)

  return (
    <>
      <div className={cn({ [styles.active]: isActive })}>
        <ListItem
          className={cn(styles.navItem, {
            [styles.navItemExpanded]: itemExpanded,
            [styles.isHovered]: isHovered,
          })}
          button
          onClick={handleExpandClick}
          disableRipple
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        >
          <div className={styles.icon}>
            {icon}
            <span className={styles.expandableIcon}>
              {itemExpanded ? (
                <ArrowDropUp className={styles.dropdownIcon} />
              ) : (
                <ArrowDropDown className={styles.dropdownIcon} />
              )}
            </span>
          </div>
          {sidebarExpanded && <div className={styles.label}>{label}</div>}
        </ListItem>
      </div>
      <Collapse in={itemExpanded} timeout={0} unmountOnExit>
        <List
          className={cn(styles.nestedNav, { [styles.isHovered]: isHovered })}
          component="div"
          disablePadding
        >
          {subItems.map(({ label: itemLabel, link, icon: subMenuIcon }: ISubMenuData) => (
            <NestedNavItem
              link={link}
              label={itemLabel}
              sidebarExpanded={sidebarExpanded}
              key={link}
              icon={subMenuIcon}
            />
          ))}
        </List>
      </Collapse>
    </>
  )
}

export default withRouter<RouteComponentProps & IProps, any>(ExpandableNavItem)
