import React, { useEffect, useCallback, useState, useMemo } from 'react'
import Box from '@mui/material/Box'
import { Link as RouterLink } from 'react-router-dom'
import Link from '@mui/material/Link'
import { Form } from 'react-final-form'
import { makeValidate } from 'mui-rff'
import * as Yup from 'yup'
import { useParams } from 'react-router'
import InputAdornment from '@mui/material/InputAdornment'
import IconButton from '@mui/material/IconButton'

import Visibility from '../../assets/images/text-visibility-on.svg'
import VisibilityOff from '../../assets/images/text-visibility-off.svg'

import styles from '../../components/AuthContainer/AuthContainer.module.scss'

import Card from '../../components/Common/Card'
import TextField from '../../components/Common/TextField'
import InputLabel from '../../components/Common/InputLabel'
import Button from '../../components/Common/Button'
import { PhoneMaskInput } from '../../components/Common/MaskInput'

import { IUser } from '@common/interfaces/user'
import { ROUTES } from '../../constants/routes'
import StrongPasswordCheck from '../../components/StrongPasswordCheck'
import { PHONE_VALIDATE_REGEXP } from '../../constants/common'

const schema = Yup.object().shape({
  firstName: Yup.string().required('First name is required'),
  lastName: Yup.string().required('Last name is required'),
  password: Yup.string()
    .required('')
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[^A-Za-z0-9]).{8,}/,
      'Password is not strong enough',
    ),
  confirmPassword: Yup.string()
    .required('')
    .oneOf([Yup.ref('password'), null], 'Password does not match'),
})

const prospectSchema = Yup.object().shape({
  email: Yup.string().required('Email is required').email(),
  phone: Yup.string()
    .required('Phone is required')
    .matches(PHONE_VALIDATE_REGEXP, 'Not a valid phone number'),
})

const validate = makeValidate(schema)
const prospectValidate = makeValidate(schema.concat(prospectSchema))

interface IProps {
  isLoading: boolean
  isPasswordSetup: boolean
  invitedUser: IUser
  processInvitation: (hash: string) => Promise<void>
  setupPassword: (hash: string, data: any) => void
}

const PasswordSetUpPage = ({
  isLoading,
  isPasswordSetup,
  invitedUser,
  processInvitation,
  setupPassword,
}: IProps) => {
  const { hash } = useParams<{ hash: string }>()

  if (invitedUser && !invitedUser.password) {
    invitedUser.password = ''
  }
  if (invitedUser && !invitedUser.confirmPassword) {
    invitedUser.confirmPassword = ''
  }
  const [passwordVisibility, setPasswordVisibility] = useState(false)
  const handleClickShowPassword = useCallback(() => {
    setPasswordVisibility((visibility) => !visibility)
  }, [])

  const [confirmPasswordVisibility, setConfirmPasswordVisibility] = useState(false)
  const handleClickShowConfirmPassword = useCallback(() => {
    setConfirmPasswordVisibility((visibility) => !visibility)
  }, [])

  useEffect(() => {
    !isPasswordSetup && processInvitation(hash)
  }, [processInvitation, hash, isPasswordSetup])

  const handleSetupPassword = useCallback(
    (data: any) => {
      setupPassword(hash, data)
    },
    [setupPassword, hash],
  )
  const maskProps = useMemo(() => ({ inputComponent: PhoneMaskInput() }), [])
  return (
    <Card
      className={styles.formWrapper}
      classes={{ content: styles.centeredContent }}
      withBorder={false}
    >
      <div className={styles.formContainer}>
        <div className={styles.infoContainer}>
          <div>
            <div className={styles.setupHeading}>Setup your account</div>
            <br />
            <div className={styles.setupHeadingInfo}>Transparent, every step of the way.</div>
            <br />
          </div>
        </div>
        {isPasswordSetup ? (
          <Form
            onSubmit={handleSetupPassword}
            validate={invitedUser.isProspectUser ? prospectValidate : validate}
            initialValues={invitedUser}
            render={(props) => (
              <form>
                <Box mb={2}>
                  <InputLabel className={styles.inputField}>First name</InputLabel>
                  <TextField name="firstName" />
                </Box>
                <Box mb={2}>
                  <InputLabel className={styles.inputField}>Last name</InputLabel>
                  <TextField name="lastName" />
                </Box>
                {invitedUser.isProspectUser && (
                  <>
                    <Box mb={2}>
                      <InputLabel className={styles.inputField}>Email</InputLabel>
                      <TextField name="email" />
                    </Box>
                    <Box mb={2}>
                      <InputLabel className={styles.inputField}>Phone</InputLabel>
                      <TextField name="phone" InputProps={maskProps} />
                    </Box>
                  </>
                )}
                <Box mb={2}>
                  <InputLabel className={styles.inputField}>Password</InputLabel>
                  <TextField
                    name="password"
                    type={passwordVisibility ? 'text' : 'password'}
                    showInputAdornmentBorder={false}
                    InputProps={{
                      autoComplete: 'off',
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            className={styles.iconButtonVisibility}
                            disableRipple
                            onClick={handleClickShowPassword}
                          >
                            {passwordVisibility ? (
                              <img src={Visibility} alt="toggle password visibility" />
                            ) : (
                              <img src={VisibilityOff} alt="toggle password visibility" />
                            )}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Box>
                <Box mb={2}>
                  <InputLabel className={styles.inputField}>Confirm Password</InputLabel>
                  <TextField
                    name="confirmPassword"
                    type={confirmPasswordVisibility ? 'text' : 'password'}
                    showInputAdornmentBorder={false}
                    InputProps={{
                      autoComplete: 'off',
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            className={styles.iconButtonVisibility}
                            disableRipple
                            onClick={handleClickShowConfirmPassword}
                          >
                            {confirmPasswordVisibility ? (
                              <img src={Visibility} alt="toggle password visibility" />
                            ) : (
                              <img src={VisibilityOff} alt="toggle password visibility" />
                            )}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Box>
                <StrongPasswordCheck password={props.values.password} />
                <Box>
                  <Button
                    className={styles.resetButton}
                    variant="contained"
                    disabled={isLoading || props.submitting || props.pristine || props.invalid}
                    onClick={props.handleSubmit}
                  >
                    Next
                  </Button>
                </Box>
              </form>
            )}
          />
        ) : (
          <div>
            <Link component={RouterLink} to={ROUTES.LOGIN}>
              Go to login page
            </Link>
          </div>
        )}
      </div>
    </Card>
  )
}

export default PasswordSetUpPage
