import React, { useCallback, useMemo, useState, useRef } from 'react'
import cn from 'classnames'
import groupBy from 'lodash/groupBy'
import Slide from '@mui/material/Slide'

import styles from './Documents.module.scss'
import genericSs from '@styles/generic.module.scss'

import { ReactComponent as DeleteIcon } from '@assets/images/delete-icon.svg'
import { ReactComponent as LoadingIcon } from '@assets/images/loading-icon.svg'
import { ReactComponent as ArrowRightIcon } from '@assets/images/direction-right-icon.svg'
import { IFile } from '@common/interfaces/box'
import {
  IOPSReporting,
  IProspectReportDocumentType,
  PROSPECT_REPORT_DOCUMENTS_TYPES_LIST,
} from '@common/interfaces/prospects'
import Button from '../../Common/Button'
import { UploadFile } from '../../Common/UploadFile'
import { formatTextDate } from '../../../helpers/helpers'
import { OngoingReportingType } from '@common/interfaces/bbc'
import LinearProgressBar from '../../Common/LinearProgressBar'
import { Grid } from '@mui/material'

const DocumentType = ({
  documentType,
  documentCount,
  selectedDocumentType,
  setSelectedDocumentType,
}: {
  documentType: IProspectReportDocumentType
  documentCount: number
  selectedDocumentType: string
  setSelectedDocumentType: (type: string) => void
}) => {
  const onSelect = useCallback(
    () => setSelectedDocumentType(documentType.type),
    [documentType, setSelectedDocumentType],
  )

  return (
    <div
      className={cn(styles.documentTypeItem, {
        [styles.documentTypeItemSelected]: documentType.type === selectedDocumentType,
      })}
      onClick={onSelect}
    >
      <div className={styles.documentTypeItemContainer}>
        <div className={styles.documentTypeItemTitle}>{documentType.title}</div>
      </div>
      <div className={styles.documentTypeItemIcons}>
        {documentCount > 0 && <div className={styles.documentTypeItemCount}>{documentCount}</div>}
        <ArrowRightIcon />
      </div>
    </div>
  )
}

const DocumentItem = ({
  file,
  handleDelete,
  isLoading,
}: {
  file: IFile
  handleDelete?: (fileId: string) => void
  isLoading?: boolean
}) => {
  const [isDeleting, setIsDeleting] = useState(false)
  const onDelete = useCallback(async () => {
    setIsDeleting(true)
    await handleDelete(file.id)
    setIsDeleting(false)
  }, [file, handleDelete])

  return (
    <div className={styles.documentsListItem}>
      <div className={styles.documentsListItemTextContainer}>
        <span className={styles.documentsListItemFileName}>{file.fileName}</span>
        {isLoading ? (
          <LinearProgressBar isFullWidth isWithLabel={false} />
        ) : (
          <div className={styles.documentsListItemLine} />
        )}
        {file.createdAt && (
          <span className={styles.documentsListItemDate}>{formatTextDate(file.createdAt)}</span>
        )}
      </div>
      {file.id && (
        <div className={styles.documentsListItemDeleteIcon}>
          {isDeleting ? (
            <LoadingIcon className={styles.documentsListItemDeleteLoadingIcon} />
          ) : (
            <DeleteIcon onClick={onDelete} />
          )}
        </div>
      )}
    </div>
  )
}

interface IProps {
  isSaving: boolean
  opsReporting: IOPSReporting
  handleGoBack: (data: any) => void
  handleSubmit: (data: any) => void
  handleUploadDocument: (type: string, files: File[]) => void
  handleDeleteDocument: (fileId: string) => void
  handleAppIntegrations: () => void
  showConnectToAutoReporting: boolean
}

const Documents = ({
  isSaving,
  opsReporting,
  handleGoBack,
  handleSubmit,
  handleUploadDocument,
  handleDeleteDocument,
  handleAppIntegrations,
  showConnectToAutoReporting,
}: IProps) => {
  const uploadContainerRef = useRef(null)
  const [savingFiles, setSavingFiles] = useState<{ [key: string]: IFile[] }>({})
  const [selectedDocumentType, setSelectedDocumentType] = useState<string>(
    opsReporting?.clientInfo?.isErpConnected
      ? OngoingReportingType.BalanceSheetProjections
      : OngoingReportingType.BalanceSheet,
  )

  const documentsTypesList = useMemo(
    () =>
      PROSPECT_REPORT_DOCUMENTS_TYPES_LIST.filter(
        ({ isShortList }) => !opsReporting?.clientInfo?.isErpConnected || isShortList,
      ),
    [opsReporting],
  )

  const handleUpload = useCallback(
    async (loadedFiles: File[]) => {
      setSavingFiles((savingFiles) => ({
        ...savingFiles,
        [selectedDocumentType]: loadedFiles.map((item) => ({
          ...item,
          id: null,
          fileName: item.name,
        })),
      }))
      await handleUploadDocument(selectedDocumentType, loadedFiles)
      setSavingFiles((savingFiles) => ({
        ...savingFiles,
        [selectedDocumentType]: [],
      }))
    },
    [selectedDocumentType, handleUploadDocument],
  )

  const handleDelete = useCallback(
    (fileId: string) => handleDeleteDocument(fileId),
    [handleDeleteDocument],
  )

  const files = useMemo(
    () => opsReporting?.files.filter(({ type }) => type === selectedDocumentType) || [],
    [opsReporting, selectedDocumentType],
  )
  const filesByTypes = useMemo(() => groupBy(opsReporting?.files, 'type'), [opsReporting])

  const isLastDocumentSelected = useMemo(
    () =>
      documentsTypesList.findIndex(({ type }) => type === selectedDocumentType) >=
      documentsTypesList.length - 1,
    [documentsTypesList, selectedDocumentType],
  )

  const handleBack = useCallback(() => {
    const currentIndex = documentsTypesList.findIndex(({ type }) => type === selectedDocumentType)
    if (currentIndex === 0) {
      handleGoBack(null)
    } else {
      setSelectedDocumentType(documentsTypesList[currentIndex - 1].type)
    }
  }, [documentsTypesList, selectedDocumentType, handleGoBack])
  const handleNext = useCallback(() => {
    const currentIndex = documentsTypesList.findIndex(({ type }) => type === selectedDocumentType)
    if (currentIndex >= documentsTypesList.length - 1) {
      handleSubmit(null)
    } else {
      setSelectedDocumentType(documentsTypesList[currentIndex + 1].type)
    }
  }, [documentsTypesList, selectedDocumentType, handleSubmit])

  return (
    <div className={styles.formWrapper}>
      <div className={styles.formContent}>
        <div className={styles.documentsTypesList}>
          {documentsTypesList.map((documentType) => (
            <DocumentType
              key={documentType.type}
              documentType={documentType}
              documentCount={filesByTypes[documentType.type]?.length}
              selectedDocumentType={selectedDocumentType}
              setSelectedDocumentType={setSelectedDocumentType}
            />
          ))}
        </div>

        <Grid className={styles.uploadContainer} container xs={12} ref={uploadContainerRef}>
          <Slide
            key={selectedDocumentType}
            direction="right"
            in
            timeout={700}
            mountOnEnter
            unmountOnExit
            container={uploadContainerRef.current}
          >
            <Grid
              item
              container
              xs={12}
              justifyContent="space-between"
              direction="row"
              className={styles.uploadWrapper}
            >
              <Grid item xs={12}>
                <div className={styles.documentsListWrapper}>
                  {selectedDocumentType !== OngoingReportingType.OrgChart && (
                    <div className={genericSs.warningMessage}>
                      Excel format is required for this document
                    </div>
                  )}
                  <UploadFile onDropAccepted={handleUpload} size="xl" />

                  <div className={styles.documentsList}>
                    {files.map((file: IFile) => (
                      <DocumentItem key={file.id} file={file} handleDelete={handleDelete} />
                    ))}
                    {savingFiles?.[selectedDocumentType]?.map((file: IFile) => (
                      <DocumentItem key={file.name} file={file} isLoading />
                    ))}
                  </div>
                </div>
              </Grid>
              <Grid container item xs={12} alignItems={'flex-end'}>
                {showConnectToAutoReporting && (
                  <div className={styles.autoReporting}>
                    Shorten your documents request list and bypass certain AR, AP, and historical
                    reports by authorizing Dwight to pull reports on your behalf.{' '}
                    <span className={styles.autoReportingLink} onClick={handleAppIntegrations}>
                      Connect to auto-reporting.
                    </span>
                  </div>
                )}
              </Grid>
            </Grid>
          </Slide>
        </Grid>
      </div>

      <div className={styles.buttonDivider} />

      <div className={styles.buttonWrapper}>
        <Button className={styles.button} variant="outlined" onClick={handleBack}>
          Back
        </Button>

        <Button
          className={styles.button}
          variant="contained"
          onClick={handleNext}
          isLoading={isSaving}
        >
          {isLastDocumentSelected ? 'Submit' : 'Next'}
        </Button>
      </div>
    </div>
  )
}

export default Documents
