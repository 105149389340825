import React, { useCallback, useMemo } from 'react'
import { generatePath } from 'react-router-dom'
import Link from '@mui/material/Link'
import Skeleton from '@mui/material/Skeleton'
import Box from '@mui/material/Box'
import cn from 'classnames'

import styles from './EntityPreview.module.scss'

import { IEntityPreviewInfo } from '@common/interfaces/entityInfo'
import { WorkflowTypes } from '@common/interfaces/notes'
import { ExternalLink } from '../Common/Icons'
import { addHttp } from '@common/helpers/helpers'
import { formatAmount, formatPercent } from '../../helpers/helpers'
import { ROUTES } from '../../constants/routes'

interface IProps {
  isLoading: boolean
  entityInfo: IEntityPreviewInfo | null
  workflow: WorkflowTypes
  isEligiblePage?: boolean
}

const CLIENTS_COUNT = 10

const EntityPreviewInfo = ({ isLoading, entityInfo, workflow, isEligiblePage }: IProps) => {
  const clientsList = useMemo(() => entityInfo?.clients.slice(0, CLIENTS_COUNT) || [], [entityInfo])
  const prospectsList = useMemo(
    () => entityInfo?.prospects.slice(0, CLIENTS_COUNT) || [],
    [entityInfo],
  )

  const handleOnClientClick = useCallback(
    (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
      event.stopPropagation()
      event.preventDefault()
      window.open(event.currentTarget.href, '_blank')
    },
    [],
  )

  if (isLoading || !entityInfo) {
    return (
      <>
        <div className={styles.row}>
          <Skeleton height={75} width={75} variant="rectangular" />
          <Box flex={1}>
            <Skeleton height={15} width={350} />
            <Skeleton height={15} width={350} />
            <Skeleton height={15} width={150} />
          </Box>
          <Skeleton height={16} width={16} variant="rectangular" />
        </div>
        <div className={cn(styles.row, styles.dataRow)}>
          <div className={styles.row}>
            <Skeleton height={25} width={80} />
            <Skeleton height={25} width={50} />
          </div>
          <div className={styles.row}>
            <Skeleton height={25} width={80} />
            <Skeleton height={25} width={50} />
          </div>
          <div className={styles.row}>
            <Skeleton height={25} width={80} />
            <Skeleton height={25} width={50} />
          </div>
        </div>
        <div className={cn(styles.row, styles.dataRow)}>
          <div className={styles.row}>
            <Skeleton height={25} width={80} />
            <Skeleton height={25} width={50} />
          </div>
          <div className={styles.row}>
            <Skeleton height={25} width={80} />
            <Skeleton height={25} width={50} />
          </div>
          <div className={styles.row}>
            <Skeleton height={25} width={80} />
            <Skeleton height={25} width={50} />
          </div>
        </div>
      </>
    )
  }

  return (
    <>
      <div className={styles.row}>
        {entityInfo.iconUrl ? (
          <div className={styles.avatarWrapper}>
            <img className={styles.avatarImg} src={entityInfo.iconUrl} alt={entityInfo.name} />
          </div>
        ) : (
          <span className={styles.name}>{entityInfo.name}</span>
        )}
        {entityInfo.description && (
          <span className={styles.description}>{entityInfo.description}</span>
        )}
        {entityInfo.website && (
          <ExternalLink
            title={entityInfo.website}
            link={addHttp(entityInfo.website)}
            useOnClick
            size="small"
            className={styles.websiteLink}
          />
        )}
      </div>
      {entityInfo.debtor && workflow !== WorkflowTypes.opsFlow && (
        <div className={cn(styles.row, styles.dataRow)}>
          <div className={styles.dataBlock}>
            <div className={styles.dataLabel}>Total Exposure:</div>
            <div className={styles.dataValue}>{formatAmount(entityInfo.totalExposure, '$')}</div>
          </div>
          <div className={styles.dataBlock}>
            <div className={styles.dataLabel}>Ineligible AR:</div>
            <div className={styles.dataValue}>{formatAmount(entityInfo.ineligibleAr, '$')}</div>
          </div>
          <div className={styles.dataBlock}>
            <div className={styles.dataLabel}>Avg. Dilution:</div>
            <div className={styles.dataValue}>{formatPercent(entityInfo.dilution)}</div>
          </div>
        </div>
      )}
      {entityInfo.debtor &&
        (workflow === WorkflowTypes.bbc ||
          (workflow === WorkflowTypes.clientPage && isEligiblePage)) && (
          <div className={cn(styles.row, styles.dataRow)}>
            <div className={styles.dataBlock}>
              <div className={styles.dataLabel}>Avg. Credit Limit:</div>
              <div className={styles.dataValue}>{formatAmount(entityInfo.avgCreditLimit, '$')}</div>
            </div>
            <div className={styles.dataBlock}>
              <div className={styles.dataLabel}>Avg. Conc. Limit:</div>
              <div className={styles.dataValue}>
                {formatAmount(entityInfo.avgConcentrationLimit)}
              </div>
            </div>
            <div className={styles.dataBlock}>
              <div className={styles.dataLabel}>Eligible Clients:</div>
              <div className={styles.dataValue}>
                {entityInfo.eligibleClientsCount} / {entityInfo.totalClientsAr}
              </div>
            </div>
          </div>
        )}
      {entityInfo.creditor && workflow !== WorkflowTypes.opsFlow && (
        <div className={cn(styles.row, styles.dataRow)}>
          <div className={styles.dataBlock}>
            <div className={styles.dataLabel}>Total AP:</div>
            <div className={styles.dataValue}>{formatAmount(entityInfo.totalAp, '$')}</div>
          </div>
          <div className={styles.dataBlock}>
            <div className={styles.dataLabel}>Priority Payable:</div>
            <div className={styles.dataValue}>
              {entityInfo.priorityClientsCount} / {entityInfo.totalClientsAp}
            </div>
          </div>
          {entityInfo.priorityClientsCount > 0 ? (
            <div className={styles.dataBlock}>
              <div className={styles.dataLabel}>Reserve:</div>
              <div className={styles.dataValue}>{formatAmount(entityInfo.avgReserveAp, '$')}</div>
            </div>
          ) : (
            <div className={styles.dataBlock} />
          )}
        </div>
      )}
      <div className={styles.clientContainer}>
        <div className={cn(styles.dataLabel, styles.dataLabelLong)}>Clients:</div>
        <div className={styles.dataValue}>
          {clientsList.length > 0 ? (
            <div className={styles.dataValue}>
              {clientsList.map((client, index) => (
                <React.Fragment key={client.clientId}>
                  {workflow === WorkflowTypes.opsFlow ? (
                    <span>{client.clientName}</span>
                  ) : (
                    <Link
                      onClick={handleOnClientClick}
                      className={styles.clientLink}
                      href={generatePath(ROUTES.CLIENT_PAGE, { id: client.clientId })}
                      target="_blank"
                    >
                      {client.clientName}
                    </Link>
                  )}
                  {index === clientsList.length - 1
                    ? ''
                    : index === clientsList.length - 2 && entityInfo.clients.length <= CLIENTS_COUNT
                    ? ' & '
                    : ', '}
                </React.Fragment>
              ))}
              {entityInfo.clients.length > CLIENTS_COUNT && (
                <span> & {entityInfo.clients.length - CLIENTS_COUNT} more</span>
              )}
            </div>
          ) : (
            ''
          )}
        </div>
      </div>
      {workflow === WorkflowTypes.opsFlow && (
        <div className={styles.clientContainer}>
          <div className={cn(styles.dataLabel, styles.dataLabelLong)}>Prospects:</div>
          <div className={styles.dataValue}>
            <div className={styles.dataValue}>
              {prospectsList
                .map((client) => client.clientName)
                .join(', ')
                .replace(/, ([^,]*)$/, ' & $1')}
            </div>
          </div>
        </div>
      )}
    </>
  )
}

export default EntityPreviewInfo
