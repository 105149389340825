import { trackActivity } from './../../redux/activity/actions'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'

import BBCCollateralEligibilityReceivablesPage from './BBCCollateralEligibilityReceivablesPage'
import { isLoading, bbc } from '../../redux/bbc/selectors'
import { show } from '../../redux/bbc/actions'
import { changePageTitle } from '../../redux/ui/actions'

const selector = createStructuredSelector({
  isLoading,
  bbc,
})

const actions = {
  show,
  trackActivity,
  changePageTitle,
}

export default connect(selector, actions)(BBCCollateralEligibilityReceivablesPage)
