import React, { useCallback, useMemo } from 'react'
import { diff as deepDiff } from 'deep-object-diff'
import pick from 'lodash/pick'

import styles from './BBCLogsItem.module.scss'

import {
  BBC_LOG_FIELDS_TO_COMPARE,
  BBC_LOG_ITEM_DEFAULT,
  BBCLogType,
  IBBCLog,
  IBBCLogCreditorClient,
} from '@common/interfaces/bbcLog'
import BBCLogsItemContentUsers from './BBCLogsItemContentUsers'

const BBC_LOGS_AP_ELIGIBILITY_FIELD_LABEL_MAP = {
  priorityPayable: 'priority payables threshold',
  notes: 'notes',
}

const BBCLogsItemContentAPEligibilitySingle = ({
  bbcLog,
  creditorClient,
}: {
  bbcLog: IBBCLog
  creditorClient: IBBCLogCreditorClient
}) => {
  const renderFirstItemChanges = useCallback(() => {
    const diff = pick(
      deepDiff(
        creditorClient.oldItem || BBC_LOG_ITEM_DEFAULT[BBCLogType.APEligibility],
        creditorClient.newItem || {},
      ),
      BBC_LOG_FIELDS_TO_COMPARE[BBCLogType.APEligibility],
    )

    const renderValue = (field: string, value: any) => {
      if (!value) {
        return '-'
      }

      return value
    }

    return Object.keys(diff).map((field, index) => (
      <React.Fragment key={field}>
        <b>{BBC_LOGS_AP_ELIGIBILITY_FIELD_LABEL_MAP[field]}</b>&nbsp;
        {creditorClient.oldItem && `from ${renderValue(field, creditorClient?.oldItem?.[field])} `}
        to&nbsp;<b>{renderValue(field, creditorClient?.newItem?.[field])}</b>
        {index !== Object.keys(diff).length - 1 && ', '}
      </React.Fragment>
    ))
  }, [creditorClient])

  return (
    <div className={styles.itemData}>
      <BBCLogsItemContentUsers bbcLog={bbcLog} />
      &nbsp;
      <b>updated</b>&nbsp;
      <b>{bbcLog.creditorClients?.[0]?.newItem?.entityInfo?.name}</b>&nbsp;
      {renderFirstItemChanges()}
    </div>
  )
}

const BBCLogsItemContentAPEligibilityMultiple = ({ bbcLog }: { bbcLog: IBBCLog }) => {
  const updates = useMemo(() => {
    const diffs = bbcLog.creditorClients.map(({ oldItem, newItem }) =>
      Object.keys(
        pick(
          deepDiff(oldItem || BBC_LOG_ITEM_DEFAULT[BBCLogType.APEligibility], newItem || {}),
          BBC_LOG_FIELDS_TO_COMPARE[BBCLogType.APEligibility],
        ),
      ),
    )

    return diffs.reduce((result, updatedFields) => {
      updatedFields.forEach((field) => {
        if (!result[field]) {
          result[field] = 0
        }

        result[field]++
      })

      return result
    }, {})
  }, [bbcLog])

  return (
    <div className={styles.itemData}>
      <BBCLogsItemContentUsers bbcLog={bbcLog} />
      &nbsp;
      <b>updated</b>&nbsp;
      {Object.keys(updates).map((field, index) => (
        <React.Fragment key={field}>
          <b>{BBC_LOGS_AP_ELIGIBILITY_FIELD_LABEL_MAP[field]}</b>&nbsp; for <b>{updates[field]}</b>
          &nbsp;customer{updates[field] > 1 ? 's' : ''}
          {index === Object.keys(updates).length - 2
            ? ' and '
            : index !== Object.keys(updates).length - 1
            ? ', '
            : ''}
        </React.Fragment>
      ))}
    </div>
  )
}

const BBCLogsItemContentAPEligibility = ({ bbcLog }: { bbcLog: IBBCLog }) => {
  if (bbcLog.creditorClients.length > 1) {
    return <BBCLogsItemContentAPEligibilityMultiple bbcLog={bbcLog} />
  }

  return (
    <BBCLogsItemContentAPEligibilitySingle
      bbcLog={bbcLog}
      creditorClient={bbcLog.creditorClients?.[0]}
    />
  )
}

export default BBCLogsItemContentAPEligibility
