import { IApi } from '../../api'
import { CommonPromiseAction } from '../types'

export const prefix = 'profile'

export const PROFILE_REQUEST = `${prefix}/PROFILE_REQUEST`
export const PROFILE_SUCCESS = `${prefix}/PROFILE_SUCCESS`
export const PROFILE_FAILURE = `${prefix}/PROFILE_FAILURE`

export const loadProfile: CommonPromiseAction = () => ({
  type: [PROFILE_REQUEST, PROFILE_SUCCESS, PROFILE_FAILURE],
  promise: (api: IApi): Promise<any> => api.auth.profile(),
})

export const LOGIN_REQUEST = `${prefix}/LOGIN_REQUEST`
export const LOGIN_SUCCESS = `${prefix}/LOGIN_SUCCESS`
export const LOGIN_FAILURE = `${prefix}/LOGIN_FAILURE`

export const login: CommonPromiseAction = ({
  email,
  password,
}: {
  email: string
  password: string
}) => ({
  type: [LOGIN_REQUEST, LOGIN_SUCCESS, LOGIN_FAILURE],
  promise: (api: IApi) => api.auth.login(email, password),
})

export const LOGOUT_REQUEST = `${prefix}/LOGOUT_REQUEST`
export const LOGOUT_SUCCESS = `${prefix}/LOGOUT_SUCCESS`
export const LOGOUT_FAILURE = `${prefix}/LOGOUT_FAILURE`

export const logout: CommonPromiseAction = () => ({
  type: [LOGOUT_REQUEST, LOGOUT_SUCCESS, LOGOUT_FAILURE],
  promise: (api: IApi) => api.auth.logout(window.localStorage.getItem('token')),
})

export const CONFIRM_REQUEST = `${prefix}/CONFIRM_REQUEST`
export const CONFIRM_SUCCESS = `${prefix}/CONFIRM_SUCCESS`
export const CONFIRM_FAILURE = `${prefix}/CONFIRM_FAILURE`

export const confirm: CommonPromiseAction = (hash: string) => ({
  type: [CONFIRM_REQUEST, CONFIRM_SUCCESS, CONFIRM_FAILURE],
  promise: (api: IApi) => api.auth.confirm(hash),
})

export const PASSWORD_RECOVERY_REQUEST = `${prefix}/PASSWORD_RECOVERY_REQUEST`
export const PASSWORD_RECOVERY_SUCCESS = `${prefix}/PASSWORD_RECOVERY_SUCCESS`
export const PASSWORD_RECOVERY_FAILURE = `${prefix}/PASSWORD_RECOVERY_FAILURE`

export const passwordRecovery: CommonPromiseAction = ({ email }: { email: string }) => ({
  type: [PASSWORD_RECOVERY_REQUEST, PASSWORD_RECOVERY_SUCCESS, PASSWORD_RECOVERY_FAILURE],
  promise: (api: IApi) => api.auth.resetPassword(email),
})

export const RESET_PASSWORD_REQUEST = `${prefix}/RESET_PASSWORD_REQUEST`
export const RESET_PASSWORD_SUCCESS = `${prefix}/RESET_PASSWORD_SUCCESS`
export const RESET_PASSWORD_FAILURE = `${prefix}/RESET_PASSWORD_FAILURE`

export const resetPassword = ({
  hash,
  password,
  confirmPassword,
}: {
  hash: string
  password: string
  confirmPassword: string
}): CommonPromiseAction => ({
  type: [RESET_PASSWORD_REQUEST, RESET_PASSWORD_SUCCESS, RESET_PASSWORD_FAILURE],
  promise: (api: IApi) => api.auth.setPassword(hash, password, confirmPassword),
})

export const UPDATE_PASSWORD_REQUEST = `${prefix}/UPDATE_PASSWORD_REQUEST`
export const UPDATE_PASSWORD_SUCCESS = `${prefix}/UPDATE_PASSWORD_SUCCESS`
export const UPDATE_PASSWORD_FAILURE = `${prefix}/UPDATE_PASSWORD_FAILURE`

export const updatePassword = ({
  password,
  newPassword,
}: {
  password: string
  newPassword: string
}): CommonPromiseAction => ({
  type: [UPDATE_PASSWORD_REQUEST, UPDATE_PASSWORD_SUCCESS, UPDATE_PASSWORD_FAILURE],
  promise: (api: IApi) => api.auth.updatePassword(password, newPassword),
})

export const UPDATE_PROFILE_REQUEST = `${prefix}/UPDATE_PROFILE_REQUEST`
export const UPDATE_PROFILE_SUCCESS = `${prefix}/UPDATE_PROFILE_SUCCESS`
export const UPDATE_PROFILE_FAILURE = `${prefix}/UPDATE_PROFILE_FAILURE`

export const updateProfile: CommonPromiseAction = (data: {
  firstName: string
  lastName: string
  email: string
}) => ({
  type: [UPDATE_PROFILE_REQUEST, UPDATE_PROFILE_SUCCESS, UPDATE_PROFILE_FAILURE],
  promise: (api: IApi) => api.auth.updateProfile(data),
})

export const PROCESS_INVITATION_REQUEST = `dontShow/PROCESS_INVITATION_REQUEST`
export const PROCESS_INVITATION_SUCCESS = `dontShow/PROCESS_INVITATION_SUCCESS`
export const PROCESS_INVITATION_FAILURE = `dontShow/PROCESS_INVITATION_FAILURE`

export const processInvitation: CommonPromiseAction = (hash: string) => ({
  type: [PROCESS_INVITATION_REQUEST, PROCESS_INVITATION_SUCCESS, PROCESS_INVITATION_FAILURE],
  promise: (api: IApi) => api.auth.processInvitation(hash),
})

export const CHECK_SETUP_PASSWORD_REQUEST = `dontShow/CHECK_SETUP_PASSWORD_REQUEST`
export const CHECK_SETUP_PASSWORD_SUCCESS = `dontShow/CHECK_SETUP_PASSWORD_SUCCESS`
export const CHECK_SETUP_PASSWORD_FAILURE = `dontShow/CHECK_SETUP_PASSWORD_FAILURE`

export const checkPasswordSetup: CommonPromiseAction = (hash: string) => ({
  type: [CHECK_SETUP_PASSWORD_REQUEST, CHECK_SETUP_PASSWORD_SUCCESS, CHECK_SETUP_PASSWORD_FAILURE],
  promise: (api: IApi) => api.auth.checkPasswordSetup(hash),
})

export const SETUP_PASSWORD_REQUEST = `${prefix}/SETUP_PASSWORD_REQUEST`
export const SETUP_PASSWORD_SUCCESS = `${prefix}/SETUP_PASSWORD_SUCCESS`
export const SETUP_PASSWORD_FAILURE = `${prefix}/SETUP_PASSWORD_FAILURE`

export const setupPassword = (
  hash: string,
  {
    firstName,
    lastName,
    email,
    phone,
    password,
    confirmPassword,
  }: {
    firstName: string
    lastName: string
    email: string
    phone: string
    password: string
    confirmPassword: string
  },
): CommonPromiseAction => ({
  type: [SETUP_PASSWORD_REQUEST, SETUP_PASSWORD_SUCCESS, SETUP_PASSWORD_FAILURE],
  promise: (api: IApi) =>
    api.auth.setupPassword(hash, { firstName, lastName, email, phone, password, confirmPassword }),
})

export const CLIENT_INFO_REQUEST = `${prefix}/CLIENT_INFO_REQUEST`
export const CLIENT_INFO_SUCCESS = `${prefix}/CLIENT_INFO_SUCCESS`
export const CLIENT_INFO_FAILURE = `${prefix}/CLIENT_INFO_FAILURE`

export const loadClientInfo: CommonPromiseAction = () => ({
  type: [CLIENT_INFO_REQUEST, CLIENT_INFO_SUCCESS, CLIENT_INFO_FAILURE],
  promise: (api: IApi) => api.clientInfo.clientInfo(),
})

export const CLIENT_INFO_RELOAD_REQUEST = `${prefix}/CLIENT_INFO_RELOAD_REQUEST`
export const CLIENT_INFO_RELOAD_SUCCESS = `${prefix}/CLIENT_INFO_RELOAD_SUCCESS`
export const CLIENT_INFO_RELOAD_FAILURE = `${prefix}/CLIENT_INFO_RELOAD_FAILURE`

export const reloadClientInfo: CommonPromiseAction = () => ({
  type: [CLIENT_INFO_RELOAD_REQUEST, CLIENT_INFO_RELOAD_SUCCESS, CLIENT_INFO_RELOAD_FAILURE],
  promise: (api: IApi) => api.clientInfo.clientInfo(),
})

export const PERMISSION_DENIED = `${prefix}/PERMISSION_DENIED`

export const permissionDenied = () => ({
  type: PERMISSION_DENIED,
})

export const TOGGLE_SIDEBAR = `${prefix}/TOGGLE_SIDEBAR`

export const toggleSidebar = () => ({
  type: TOGGLE_SIDEBAR,
})

export const OPEN_SIDEBAR = `${prefix}/OPEN_SIDEBAR`

export const openSidebar = () => ({
  type: OPEN_SIDEBAR,
})

export const UPDATE_NOTIFICATION_SETTINGS_REQUEST = `${prefix}/UPDATE_NOTIFICATION_SETTINGS_REQUEST`
export const UPDATE_NOTIFICATION_SETTINGS_SUCCESS = `${prefix}/UPDATE_NOTIFICATION_SETTINGS_SUCCESS`
export const UPDATE_NOTIFICATION_SETTINGS_FAILURE = `${prefix}/UPDATE_NOTIFICATION_SETTINGS_FAILURE`

export const updateNotificationSettings: CommonPromiseAction = (data: {
  firstName: string
  lastName: string
  email: string
}) => ({
  type: [
    UPDATE_NOTIFICATION_SETTINGS_REQUEST,
    UPDATE_NOTIFICATION_SETTINGS_SUCCESS,
    UPDATE_NOTIFICATION_SETTINGS_FAILURE,
  ],
  promise: (api: IApi) => api.auth.updateNotificationSettings(data),
})

export const LOGIN_AS_CLIENT_REQUEST = `${prefix}/LOGIN_AS_CLIENT_REQUEST`
export const LOGIN_AS_CLIENT_SUCCESS = `${prefix}/LOGIN_AS_CLIENT_SUCCESS`
export const LOGIN_AS_CLIENT_FAILURE = `${prefix}/LOGIN_AS_CLIENT_FAILURE`

export const loginAsClient: CommonPromiseAction = (clientId: string) => ({
  type: [LOGIN_AS_CLIENT_REQUEST, LOGIN_AS_CLIENT_SUCCESS, LOGIN_AS_CLIENT_FAILURE],
  promise: (api: IApi) => api.auth.loginAsClient(clientId),
})

export const LOGOUT_AS_CLIENT_REQUEST = `${prefix}/LOGOUT_AS_CLIENT_REQUEST`
export const LOGOUT_AS_CLIENT_SUCCESS = `${prefix}/LOGOUT_AS_CLIENT_SUCCESS`
export const LOGOUT_AS_CLIENT_FAILURE = `${prefix}/LOGOUT_AS_CLIENT_FAILURE`

export const logoutAsClient: CommonPromiseAction = () => ({
  type: [LOGOUT_AS_CLIENT_REQUEST, LOGOUT_AS_CLIENT_SUCCESS, LOGOUT_AS_CLIENT_FAILURE],
  promise: (api: IApi) => api.auth.logoutAsClient(),
})
