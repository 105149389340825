import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'

import DueDiligenceTeamPage from './DueDiligenceTeamPage'

import { dueDiligenceInfo } from '../../redux/dueDiligence/selectors'
import { show } from '../../redux/dueDiligence/actions'

const selector = createStructuredSelector({
  dueDiligenceInfo,
})
const actions = {
  show,
}

export { DueDiligenceTeamPage }

export default connect(selector, actions)(DueDiligenceTeamPage)
