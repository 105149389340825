import React, { useMemo } from 'react'
import Box from '@mui/material/Box'
import { Form, FormSpy } from 'react-final-form'
import arrayMutators from 'final-form-arrays'
import { makeValidate } from 'mui-rff'
import * as Yup from 'yup'
import moment from 'moment'
import Grid from '@mui/material/Grid'

import styles from './ClientSetupTerminationPage.module.scss'
import cn from 'classnames'
import { ClientInfoStatus, IClientInfo } from '@common/interfaces/client'
import InputLabel from '../../components/Common/InputLabel'
import Checkbox from '../../components/Common/Checkbox'
import FormField from '../../components/Common/FormField'
import CurrencyField from '../../components/Common/CurrencyField'

const schema = Yup.object().shape({
  payoffDate: Yup.date()
    .typeError('Please type date in MM/DD/YY format')
    .test({
      name: 'minDate',
      exclusive: false,
      message: 'Payoff Date cannot be in the past',
      test(value) {
        return !value || moment(value).isSameOrAfter(moment(), 'day')
      },
    }),
  manualPayoffInterestAmount: Yup.number()
    .typeError('Invalid number')
    .min(0, 'Must be positive number'),
  terminationFee: Yup.number().typeError('Invalid number').min(0, 'Must be positive number'),
  servicingFee: Yup.number().typeError('Invalid number').min(0, 'Must be positive number'),
  gracePeriod: Yup.number().typeError('Invalid number').min(0, 'Must be positive number'),
})
const validate = makeValidate(schema)

const mutators = {
  ...arrayMutators,
}

interface IProps {
  clientInfo: IClientInfo
  handleSaveTerminateData: () => void
  initialValues: object
}

const TerminationForm = ({ clientInfo, handleSaveTerminateData, initialValues }: IProps) => {
  const disabled = useMemo(() => clientInfo?.clientStatus === ClientInfoStatus.Past, [clientInfo])

  return (
    <Form
      initialValues={initialValues}
      onSubmit={handleSaveTerminateData}
      validate={validate}
      mutators={mutators}
      render={({ form, values, handleSubmit }: { form: any; values: any; handleSubmit: any }) => (
        <form className={styles.form}>
          <Grid container justifyContent={'space-between'}>
            <Grid xs={5.5}>
              <Box display="flex" alignItems="center" className={styles.box}>
                <FormField type="date" label="Payoff date" name="payoffDate" disabled={disabled} />
              </Box>
              <Box display="flex" alignItems="center" className={styles.box}>
                <Box display="flex" alignItems="center" flexDirection={'row'}>
                  <Checkbox
                    key={+values?.overrideTerminationFee || 0}
                    checked={values?.overrideTerminationFee}
                    disabled={disabled}
                    onChange={(event) => {
                      form.change('overrideTerminationFee', event.target.checked)
                      form.submit()
                    }}
                  />
                  <h4>Override termination fee</h4>
                </Box>

                <CurrencyField
                  size="large"
                  name="terminationFee"
                  disabled={disabled || !values?.overrideTerminationFee}
                />
              </Box>
              <Box display="flex" alignItems="center" className={styles.box}>
                <FormField
                  type="text"
                  label="Grace period"
                  name="gracePeriod"
                  disabled={disabled}
                />
              </Box>
            </Grid>
            <Grid item xs={5.5}>
              <Box display="flex" alignItems="center" className={styles.box}>
                <Box display="flex" alignItems="center" flexDirection={'row'}>
                  <Checkbox
                    key={+values?.overridePayoffInterest || 0}
                    checked={values.overridePayoffInterest}
                    disabled={disabled}
                    onChange={(event) => {
                      form.change('overridePayoffInterest', event.target.checked)
                      form.submit()
                    }}
                  />
                  <h4>Override interest</h4>
                </Box>

                <CurrencyField
                  size="large"
                  name="manualPayoffInterestAmount"
                  disabled={disabled || !values.overridePayoffInterest}
                />
              </Box>
              <Box display="flex" alignItems="center" className={styles.box}>
                <Box display="flex" alignItems="center" flexDirection={'row'}>
                  <Checkbox
                    key={+values?.applyServicingFee || 0}
                    checked={values?.applyServicingFee}
                    disabled={disabled}
                    onChange={(event) => {
                      form.change('applyServicingFee', event.target.checked)
                      form.submit()
                    }}
                  />
                  <h4>Servicing fee</h4>
                </Box>

                <CurrencyField
                  size="large"
                  name="servicingFee"
                  disabled={disabled || !values.applyServicingFee}
                />
              </Box>
              <Box display="flex" alignItems="center" className={cn(styles.box, styles.checkBox)}>
                <div>
                  <Checkbox
                    key={+values?.applyLockboxFee || 0}
                    checked={values.applyLockboxFee}
                    disabled={disabled}
                    onChange={(event) => {
                      form.change('applyLockboxFee', event.target.checked)
                      form.submit()
                    }}
                  />
                </div>
                <InputLabel htmlFor="applyLockboxFee">Lockbox fee</InputLabel>
              </Box>
            </Grid>

            <FormSpy
              subscription={{ dirty: true, values: true, invalid: true }}
              onChange={(props) => {
                !props.invalid && props.dirty && handleSubmit(props.values)
              }}
            />
          </Grid>
        </form>
      )}
    />
  )
}

export default TerminationForm
