import React, { useCallback } from 'react'
import Grid from '@mui/material/Grid'
import styles from './Integrations.module.scss'
import { ReactComponent as SmallCheckIcon } from '@assets/images/small-check-icon.svg'
import Button from '../Common/Button'

interface IProps {
  integration: {
    name: string
    description: string
    icon: React.ReactNode
    isConnected: boolean
    manageLink: string
    connectLink: string
  }
}

const Integration = ({ integration }: IProps) => {
  const handleOpenLink = useCallback((link: string) => {
    sessionStorage.setItem('previousPage', window.location.href)
    window.open(link, '_self')
  }, [])

  if (!integration) return null
  return (
    <Grid
      container
      item
      xs={12}
      alignItems="center"
      justifyContent={'space-between'}
      className={styles.integration}
    >
      <Grid item xs={2} className={styles.integrationIcon}>
        {integration.icon}
      </Grid>
      <Grid item xs={6} container justifyContent={'space-around'}>
        <Grid item xs={12}>
          <h4 className={styles.integrationTitle}>{integration.name}</h4>
        </Grid>
        <Grid item xs={12}>
          <p className={styles.integrationDescription}>{integration.description}</p>
        </Grid>
      </Grid>
      <Grid item xs={4}>
        {!integration.isConnected ? (
          <Button
            small
            className={styles.integrationButton}
            onClick={() => handleOpenLink(integration.connectLink)}
          >
            Connect
          </Button>
        ) : (
          <Grid container item xs={12} alignItems="center" justifyContent={'space-between'}>
            <Grid container item xs={6} justifyContent={'space-around'} alignItems={'center'}>
              <Grid item xs={4}>
                <p className={styles.active}>Active</p>
              </Grid>
              <Grid item xs={8} alignItems={'center'} className={styles.activeIcon}>
                <SmallCheckIcon />
              </Grid>
            </Grid>
            <Grid item xs={6}>
              <Button
                variant={'outlined'}
                className={styles.integrationButton}
                small
                secondary
                onClick={() => handleOpenLink(integration.manageLink)}
              >
                Manage app
              </Button>
            </Grid>
          </Grid>
        )}
      </Grid>
    </Grid>
  )
}

export default Integration
