import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'

import ProspectApplicationPage from './ProspectApplicationPage'

import { reporting } from '../../redux/prospect/selectors'

const selector = createStructuredSelector({
  reporting,
})

export default connect(selector)(ProspectApplicationPage)
