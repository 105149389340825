import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'

import {
  user,
  role,
  userRoleLabel,
  shouldShowRoleLabel,
  clientName,
  isClientUser,
} from '../../redux/profile/selectors'

import { updateProfile } from '../../redux/profile/actions'

import ProfileForm from './ProfileForm'

const selector = createStructuredSelector({
  user,
  role,
  userRoleLabel,
  shouldShowRoleLabel,
  clientName,
  isClientUser,
})

const actions = { onUpdateProfile: updateProfile }

export { ProfileForm }

export default connect(selector, actions)(ProfileForm)
