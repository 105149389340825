import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'

import BBCARDetailRow from './BBCARDetailRow'

import { previousClientCollaterals } from '../../redux/bbc/selectors'
import { getBBCARDetailStats } from '../../redux/bbc/actions'

const selector = createStructuredSelector({
  previousClientCollaterals,
})

const actions = {
  getBBCARDetailStats,
}

export default connect(selector, actions)(BBCARDetailRow)
