import React, { useCallback, useMemo, useEffect } from 'react'
import { Form } from 'react-final-form'
import InfiniteScroll from 'react-infinite-scroll-component'
import { generatePath, Link as RouterLink } from 'react-router-dom'
import Link from '@mui/material/Link'
import Box from '@mui/material/Box'
import { ROUTES } from '../../constants/routes'
import { ICustomerAggregation } from '@common/interfaces/entityInfo'
import { CUSTOMER_SUMMARY_LIST_FILTERS_CONFIG, PER_PAGE } from '@common/constants/filters'
import { buildFiltersDefaults } from '../../helpers/filters'
import {
  debounceEventHandler,
  formatAmount,
  formatNumber,
  formatPercent,
} from '../../helpers/helpers'
import styles from './CustomersSummaryTable.module.scss'
import genericSs from '@styles/generic.module.scss'
import TableContainer from '../Common/TableContainer'
import Table from '../Common/Table'
import TableHead from '../Common/TableHead'
import TableBody from '../Common/TableBody'
import TableRow from '../Common/TableRow'
import TableCell from '../Common/TableCell'
import TableFiltersRow from '../Common/TableFiltersRow'
import FilterContainer from '../Filters/FilterContainer'
import { ILoadingData } from '../../redux/types'
import TableLoader from '../Common/TableLoader/TableLoader'
import useTable from '../../hooks/useTable'

interface IProps {
  fullCustomersAggregation: ILoadingData<ICustomerAggregation>
  listCustomers: (params: object) => void
  exportCustomers: (params?: {
    page?: number
    perPage?: number
    filters?: object
    orderBy?: string
    orderDirection?: string
  }) => void
  listEntityInfo: (data: object) => Promise<{ data: any }>
}

const filtersDefaults = buildFiltersDefaults(CUSTOMER_SUMMARY_LIST_FILTERS_CONFIG)

const CustomersSummaryTable = ({
  fullCustomersAggregation,
  listCustomers,
  listEntityInfo,
  exportCustomers,
}: IProps) => {
  const { filters, handleFiltersChange, handleOrderChange, orderBy } = useTable({
    tableId: 'customers',
    filtersDefaults,
    sortDefault: {
      field: 'totalExposure',
      direction: 'DESC',
    },
  })

  const { data: customersAggregation, isLoading } = useMemo(
    () => fullCustomersAggregation,
    [fullCustomersAggregation],
  )

  const fetchListCustomers = useCallback(
    (data: any) => {
      const params = {
        ...data,
        filters: {
          ...data.filters,
        },
      }
      listCustomers(params)
    },
    [listCustomers],
  )

  const debounceListCustomers = useMemo(
    () => debounceEventHandler(fetchListCustomers, 500),
    [fetchListCustomers],
  )

  const loadMappings = useCallback(
    async (inputValue: string) => {
      const res = await listEntityInfo({
        name: inputValue,
      })
      return res.data.map((mapping: any) => ({
        id: mapping.id,
        value: mapping.name,
        label: mapping.name,
      }))
    },
    [listEntityInfo],
  )

  const filtersConfig = useMemo(
    () =>
      CUSTOMER_SUMMARY_LIST_FILTERS_CONFIG.map((item) => ({
        ...item,
        loadOptions: loadMappings,
      })),
    [loadMappings],
  )

  useEffect(() => {
    debounceListCustomers({
      page: 0,
      perPage: PER_PAGE,
      filters,
      orderBy: orderBy.field,
      orderDirection: orderBy.direction,
    })
  }, [orderBy, filters, debounceListCustomers])

  const loadMore = useCallback(() => {
    fetchListCustomers({
      loadMore: true,
      page: Math.ceil(customersAggregation?.data.length / PER_PAGE),
      perPage: PER_PAGE,
      orderBy: orderBy.field,
      orderDirection: orderBy.direction,
      filters,
    })
  }, [customersAggregation, orderBy, filters, fetchListCustomers])

  const handleExportAggregation = useCallback(async () => {
    await exportCustomers({
      page: 0,
      perPage: 0,
      filters,
      orderBy: orderBy.field,
      orderDirection: orderBy.direction,
    })
  }, [filters, orderBy, exportCustomers])

  return (
    <Box py={1} pr={2}>
      <TableContainer className={styles.table}>
        <Form
          onSubmit={handleFiltersChange}
          initialValues={filters}
          mutators={{
            setFieldData: ([field, value], state, { changeValue }) => {
              changeValue(state, field, () => value)
            },
          }}
          render={({ values, handleSubmit, form: { mutators } }) => (
            <FilterContainer
              filters={filtersConfig}
              handleSubmit={handleSubmit}
              mutators={mutators}
              values={values}
              title={''}
              appliedFilters={filters}
              handleExportAggregation={handleExportAggregation}
            />
          )}
        />
        <Table>
          <TableHead>
            <TableFiltersRow
              filters={filtersConfig}
              orderBy={orderBy}
              handleOrderChange={handleOrderChange}
            />
          </TableHead>
          <TableBody id="scrollableTableReporting">
            {isLoading ? (
              <TableLoader columnsCount={6} height={24} />
            ) : (
              customersAggregation?.data &&
              customersAggregation.data.length > 0 && (
                <InfiniteScroll
                  dataLength={customersAggregation?.data.length}
                  next={loadMore}
                  hasMore={customersAggregation?.data.length < customersAggregation?.totalItems}
                  loader={<TableLoader columnsCount={6} rowsCount={1} />}
                  scrollableTarget="scrollableTableReporting"
                >
                  {customersAggregation?.data.map((item) => (
                    <TableRow key={item?.id}>
                      <TableCell className={genericSs.tableTextLeft}>
                        <Link
                          component={RouterLink}
                          to={generatePath(ROUTES.ENTITY_PAGE, { id: item.id })}
                        >
                          {item.name}
                        </Link>
                      </TableCell>
                      <TableCell className={genericSs.tableTextRight}>
                        ${formatAmount(item.totalExposure)}
                      </TableCell>
                      <TableCell className={genericSs.tableTextRight}>
                        ${formatAmount(item.totalAr)}
                      </TableCell>
                      <TableCell className={genericSs.tableTextRight}>
                        {item.totalCollections ? <>${formatAmount(item.totalCollections)}</> : '-'}
                      </TableCell>
                      <TableCell className={genericSs.tableTextRight}>
                        {item.dilution ? <>{formatPercent(item.dilution)}</> : '-'}
                      </TableCell>
                      <TableCell className={genericSs.tableTextRight}>
                        {formatNumber(item.dso)}
                      </TableCell>
                      <TableCell className={genericSs.tableTextRight}>
                        {formatNumber(item.totalClientsAr)}
                      </TableCell>
                    </TableRow>
                  ))}
                </InfiniteScroll>
              )
            )}
          </TableBody>
        </Table>
        <Box display="flex" alignItems="center" justifyContent="space-between">
          {customersAggregation?.totalItems > 0 && (
            <div className={genericSs.itemsCount}>
              {customersAggregation?.data?.length} / {customersAggregation?.totalItems}
            </div>
          )}
        </Box>
      </TableContainer>
    </Box>
  )
}

export default CustomersSummaryTable
