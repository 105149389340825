import React, { useCallback, useMemo, useState } from 'react'
import moment from 'moment'
import { Field, Form } from 'react-final-form'
import { makeValidate } from 'mui-rff'
import * as Yup from 'yup'
import Box from '@mui/material/Box'
import Radio from '@mui/material/Radio'
import FormControl from '@mui/material/FormControl'
import FormControlLabel from '@mui/material/FormControlLabel'

import genericSs from '@styles/generic.module.scss'
import styles from './BBCEditModal.module.scss'

import { FundingRequestAmountType, IBorrowingBase } from '@common/interfaces/bbc'
import Modal from '../Common/Modal'
import ModalButton from '../Common/Button/Button'
import KeyboardDatePicker from '../Common/KeyboardDatePicker'
import { DATE_FORMAT } from '../../constants/common'
import InputLabel from '../Common/InputLabel'
import CurrencyField from '../Common/CurrencyField'
import TextField from '../Common/TextField'
import { ClientInfoStatus } from '@common/interfaces/client'

interface IProps {
  bbc: IBorrowingBase
  updateBBC: (id: string, data: object) => Promise<any>
  handleCloseMenu?: () => void
  handleUpdateBBC?: () => Promise<void>
}

const BBCEditModal = ({ bbc, updateBBC, handleCloseMenu, handleUpdateBBC }: IProps) => {
  const { id } = bbc || {}
  const [isSaving, setIsSaving] = useState(false)
  const [isModalShown, setIsModalShown] = useState(false)

  const handleEdit = useCallback(() => {
    setIsModalShown(true)
  }, [])

  const handleCancel = useCallback(() => {
    setIsModalShown(false)
    handleCloseMenu()
  }, [handleCloseMenu])

  const handleSaveActive = useCallback(
    async (data) => {
      setIsSaving(true)
      const result = await updateBBC(id, {
        ...data,
        recordDate: moment(data.recordDate).format('YYYY-MM-DD'),
      })
      if (!!result?.error) {
        setIsSaving(false)
        return
      }
      handleUpdateBBC && (await handleUpdateBBC())
      setIsSaving(false)
      setIsModalShown(false)
      handleCloseMenu()
    },
    [id, updateBBC, handleCloseMenu, handleUpdateBBC],
  )

  const bbcMinDate = useMemo(
    () =>
      bbc?.clientInfo?.clientStatus === ClientInfoStatus.DueDiligence || bbc?.isTest
        ? null
        : moment().startOf('day').toDate(),
    [bbc],
  )

  const validate = useMemo(
    () =>
      bbc
        ? makeValidate(
            Yup.object().shape({
              requestedAmount: Yup.number()
                .typeError('Invalid number')
                .nullable(true)
                .when('requestedAmountType', (requestedAmountType: string, validation: any) =>
                  requestedAmountType === FundingRequestAmountType.Specific &&
                  bbc?.clientInfo?.maxLineAmount
                    ? validation
                        .required('Required')
                        .max(
                          Number(bbc?.clientInfo?.maxLineAmount),
                          `Max value is ${bbc?.clientInfo?.maxLineAmount || 0}`,
                        )
                    : validation,
                ),
              wireCount: Yup.number()
                .typeError('Invalid number')
                .min(1, 'Wires count must be greater than or equal 1'),
            }),
          )
        : undefined,
    [bbc],
  )
  const initialValues = useMemo(
    () => ({
      id: bbc?.id,
      recordDate: bbc?.recordDate || null,
      requestedAmount: bbc?.requestedAmount || null,
      requestedAmountType: bbc?.requestedAmountType || FundingRequestAmountType.TBD,
      wireCount: bbc?.wireCount || 1,
    }),
    [bbc],
  )

  return (
    <>
      <div onClick={handleEdit}>Edit Funding</div>

      {isModalShown && (
        <Modal open onCancel={handleCancel} title="Edit Funding">
          <Form
            initialValues={initialValues}
            onSubmit={handleSaveActive}
            validate={validate}
            render={({
              values,
              invalid,
              pristine,
              handleSubmit,
            }: {
              values: any
              invalid: boolean
              pristine: boolean
              handleSubmit: any
            }) => (
              <form className={genericSs.textLeft} onSubmit={handleSubmit}>
                <Box mt={2}>
                  <InputLabel htmlFor="link">Record Date</InputLabel>
                  <KeyboardDatePicker
                    name="recordDate"
                    inputFormat={DATE_FORMAT}
                    minDate={bbcMinDate}
                    className={styles.recordDate}
                  />
                </Box>
                {!bbc.isTest && (
                  <Box mt={2}>
                    <InputLabel htmlFor="link">Select Funding Amount</InputLabel>
                    <FormControl fullWidth>
                      <Field name="requestedAmountType" type="radio">
                        {({ input }) => (
                          <FormControlLabel
                            value={FundingRequestAmountType.Max}
                            classes={{ label: styles.radioLabel }}
                            control={
                              <Radio
                                {...input}
                                size="small"
                                value={FundingRequestAmountType.Max}
                                checked={
                                  values.requestedAmountType === FundingRequestAmountType.Max
                                }
                                onChange={() => input.onChange(FundingRequestAmountType.Max)}
                              />
                            }
                            label="Max amount"
                          />
                        )}
                      </Field>
                      <Field name="requestedAmountType" type="radio">
                        {({ input }) => (
                          <FormControlLabel
                            value={FundingRequestAmountType.Specific}
                            classes={{ label: styles.radioLabel }}
                            control={
                              <Radio
                                {...input}
                                size="small"
                                value={FundingRequestAmountType.Specific}
                                checked={
                                  values.requestedAmountType === FundingRequestAmountType.Specific
                                }
                                onChange={() => input.onChange(FundingRequestAmountType.Specific)}
                              />
                            }
                            label="Specific amount"
                          />
                        )}
                      </Field>
                      {values.requestedAmountType === FundingRequestAmountType.Specific && (
                        <Field name="requestedAmount">
                          {(props) => (
                            <CurrencyField
                              className={styles.amountField}
                              name={props.input.name}
                              value={props.input.value}
                              onChange={props.input.onChange}
                              placeholder="Enter amount"
                              disabled={
                                values.requestedAmountType !== FundingRequestAmountType.Specific
                              }
                              fullWidth
                            />
                          )}
                        </Field>
                      )}
                      <Field name="requestedAmountType" type="radio">
                        {({ input }) => (
                          <FormControlLabel
                            value={FundingRequestAmountType.TBD}
                            classes={{ label: styles.radioLabel }}
                            control={
                              <Radio
                                {...input}
                                size="small"
                                checked={
                                  values.requestedAmountType === FundingRequestAmountType.TBD
                                }
                                onChange={() => input.onChange(FundingRequestAmountType.TBD)}
                              />
                            }
                            label="Review borrowing base to specify amount"
                          />
                        )}
                      </Field>
                    </FormControl>
                  </Box>
                )}
                {!bbc.isTest && (
                  <Box mt={2}>
                    <InputLabel htmlFor="link">How many wires will be sent?</InputLabel>

                    <TextField name="wireCount" placeholder="Enter wires count" fullWidth />
                  </Box>
                )}

                <Box display="flex" alignItems="center" justifyContent="space-around" mt={2}>
                  <ModalButton color="primary" variant="outlined" onClick={handleCancel} secondary>
                    Cancel
                  </ModalButton>
                  <ModalButton
                    isLoading={isSaving}
                    color="primary"
                    variant="contained"
                    onClick={handleSubmit}
                    disabled={invalid || pristine}
                  >
                    Confirm
                  </ModalButton>
                </Box>
              </form>
            )}
          />
        </Modal>
      )}
    </>
  )
}

export default BBCEditModal
