import { ReportingFlow } from '@common/interfaces/bbc'

export const useReportingFlow = ({ reportingFlow }: { reportingFlow: ReportingFlow }) => {
  return {
    isClientPage: reportingFlow === ReportingFlow.ClientPage,
    isLCRPage: reportingFlow === ReportingFlow.LoanCommitteeReport,
    isBBC: reportingFlow === ReportingFlow.BorrowingBase,
    isClientUserPage: reportingFlow === ReportingFlow.ClientUserPage,
  }
}
