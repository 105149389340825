import React, { useCallback, useState, useEffect } from 'react'
import styles from './ClientProfilePictureUpload.module.scss'
import { IClientInfo } from '@common/interfaces/client'
import Box from '@mui/material/Box'
import { UploadFile } from '../Common/UploadFile'
import { useParams } from 'react-router'

interface IProps {
  clientInfo: IClientInfo
  updateClient: (id: string, data: object) => void
  showClient: (id: string) => void
}

const ClientProfilePictureUpload = ({ clientInfo, updateClient, showClient }: IProps) => {
  const { id } = useParams<{ id?: string }>()
  const [iconUrl, setIconUrl] = useState<string | null>(null)
  const [isImageManageModalOpen, setIsImageManageModalOpen] = useState(false)
  const [loadedFile, setLoadedFile] = useState<File[]>([])
  const handleToggleImageManageModal = useCallback(() => {
    setIsImageManageModalOpen((isOpen) => !isOpen)
  }, [])

  useEffect(() => {
    if (clientInfo) {
      setIconUrl(clientInfo?.iconUrl)
    }
  }, [clientInfo])

  const handleSetIconUrl = useCallback(
    (image) => {
      setIconUrl(image ? image : clientInfo?.iconUrl)
    },
    [clientInfo?.iconUrl],
  )

  const handleUpdateClientInfo = useCallback(
    async (file: any) => {
      if (id) {
        const formData = new FormData()
        formData.append('files[profilePicture]', file[0], file[0].name)
        await updateClient(id, formData)
      }
    },
    [id, updateClient],
  )

  const handleUploadFile = useCallback(
    async (loadedFiles: File[]) => {
      setLoadedFile(loadedFiles)
      const [image] = loadedFiles

      if (image) {
        const reader = new FileReader()
        reader.onload = () => {
          handleSetIconUrl(reader.result as string)
        }
        await handleUpdateClientInfo(loadedFiles)
        reader.readAsDataURL(image)
      }
    },
    [handleSetIconUrl, handleUpdateClientInfo],
  )

  const handleDeleteFile = useCallback(async () => {
    setLoadedFile([])
    handleSetIconUrl(null)
  }, [handleSetIconUrl])

  return (
    <Box mb={4} display="flex" gap={'40px'} alignItems="center">
      {iconUrl && (
        <Box className={styles.avatarWrapper}>
          <img className={styles.avatarImg} src={iconUrl} alt="logo" />
        </Box>
      )}
      <Box className={styles.imageUploadWrapper}>
        <UploadFile
          title="Profile Picture"
          size="lg"
          onDropAccepted={handleUploadFile}
          files={loadedFile}
          onDelete={handleDeleteFile}
          acceptedFileTypes={['image']}
          maxFiles={1}
          isModalOpen={isImageManageModalOpen}
          handleToggleModal={handleToggleImageManageModal}
        />
      </Box>
    </Box>
  )
}

export default ClientProfilePictureUpload
