import { IApi } from '../../api'
import { CommonPromiseAction } from '../types'

export const prefix = 'bbcLog'

export const LIST_BBC_LOG_REQUEST = `${prefix}/LIST_BBC_LOG_REQUEST`
export const LIST_BBC_LOG_SUCCESS = `${prefix}/LIST_BBC_LOG_SUCCESS`
export const LIST_BBC_LOG_FAILURE = `${prefix}/LIST_BBC_LOG_FAILURE`

export const listBBCLog: CommonPromiseAction = (params: object) => ({
  type: [LIST_BBC_LOG_REQUEST, LIST_BBC_LOG_SUCCESS, LIST_BBC_LOG_FAILURE],
  promise: (api: IApi) => api.bbcLog.list(params),
  params,
})

export const HIDE_BBC_LOG = `${prefix}/HIDE_BBC_LOG`

export const hideBBCLog = () => ({
  type: HIDE_BBC_LOG,
})
