import React, { useMemo } from 'react'
import Card from '../../Common/Card'
import { ClientABLField } from '../ClientHelpers'
import Grid from '@mui/material/Grid'
import { LOAN_TYPES } from '@common/constants/client'
import styles from '../DealStructure/DealStructure.module.scss'
import {
  ClientSetupSections,
  CollateralSubSection,
  allClientTermsInfo,
} from '@common/interfaces/client'

const arFields = allClientTermsInfo.filter(
  (term) =>
    (term.section === ClientSetupSections.Collateral &&
      term.subSection === CollateralSubSection.AR) ||
    term.value === 'loanType',
)

const inventoryFields = allClientTermsInfo.filter(
  (term) =>
    (term.section === ClientSetupSections.Collateral &&
      term.subSection === CollateralSubSection.Inventory) ||
    term.value === 'loanType',
)

const arAndInventoryFields = allClientTermsInfo.filter(
  (term) => term.section === ClientSetupSections.Collateral,
)

const justDealType = allClientTermsInfo.filter((term) => term.value === 'loanType')

interface IProps {
  dealType: string
}

const Collateral = ({ dealType }: IProps) => {
  const fieldsMap = useMemo(() => {
    let fieldsMapFinal: typeof allClientTermsInfo = []

    if (dealType === LOAN_TYPES.ar) {
      fieldsMapFinal = arFields
    }
    if (dealType === LOAN_TYPES.inventory) {
      fieldsMapFinal = inventoryFields
    }
    if (dealType === LOAN_TYPES.arAndInventory) {
      fieldsMapFinal = arAndInventoryFields
    }
    if (!dealType) {
      fieldsMapFinal = justDealType
    }

    return fieldsMapFinal
  }, [dealType])
  return (
    <Card
      title="Collateral"
      classes={{
        root: styles.card,
      }}
    >
      <Grid container spacing={2} justifyContent={'start'}>
        {fieldsMap.map(({ label, value, type, options }) => (
          <Grid item xs={3} xl={2} key={label}>
            <ClientABLField label={label} name={value} type={type} options={options} />
          </Grid>
        ))}
      </Grid>
    </Card>
  )
}

export default Collateral
