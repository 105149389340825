import React from 'react'
import { useState } from 'react'
import { Form } from 'react-final-form'
import { makeValidate } from 'mui-rff'
import * as Yup from 'yup'
import { Link as RouterLink } from 'react-router-dom'
import Box from '@mui/material/Box'
import Link from '@mui/material/Link'
import InputAdornment from '@mui/material/InputAdornment'
import IconButton from '@mui/material/IconButton'
import Visibility from '../../assets/images/text-visibility-on.svg'
import VisibilityOff from '../../assets/images/text-visibility-off.svg'

import TextField from '../Common/TextField'
import InputLabel from '../Common/InputLabel'
import Button from '../Common/Button'

import { ROUTES } from '../../constants/routes'

import styles from './LoginForm.module.scss'

const schema = Yup.object().shape({
  email: Yup.string()
    .required('Email address is required')
    .email('Email address is required')
    .trim(),
  password: Yup.string().required('Password is required').min(8, 'Password is required').trim(),
  rememberMe: Yup.boolean(),
})

const validate = makeValidate(schema, (error) => (
  <span className={styles.valError} key={`${error.type}-${error.path}`}>
    {error.message}
  </span>
))

interface IProps {
  onSubmit({
    email,
    password,
    rememberMe,
  }: {
    email: string
    password: string
    rememberMe: boolean
  }): void
}

const LoginForm = ({ onSubmit }: IProps) => {
  const [visibility, setVisibility] = useState(false)
  const [rememberMe, setRememberMe] = useState(true)

  const handleRememberMeChange = (event: any) => {
    setRememberMe(event.target.checked)
  }

  const handleClickShowPassword = React.useCallback(() => {
    setVisibility((prev) => !prev)
  }, [])

  return (
    <Form
      validate={validate}
      onSubmit={onSubmit}
      render={(props) => (
        <form onSubmit={props.handleSubmit}>
          <Box display="flex" flexDirection="column" width="100%">
            <Box mb={2}>
              <InputLabel htmlFor="email" className={styles.emailInput}>
                Email
              </InputLabel>
              <TextField
                name="email"
                type="text"
                placeholder="Enter your email address"
                fullWidth
                InputProps={{
                  autoComplete: 'off',
                }}
              />
            </Box>
            <Box mb={2}>
              <InputLabel htmlFor="password" className={styles.emailInput}>
                Password
              </InputLabel>

              <TextField
                name="password"
                type={visibility ? 'text' : 'password'}
                placeholder="Enter your password"
                fullWidth
                showInputAdornmentBorder={false}
                InputProps={{
                  autoComplete: 'off',
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        className={styles.iconButtonVisibility}
                        disableRipple
                        onClick={handleClickShowPassword}
                      >
                        {visibility ? (
                          <img src={Visibility} alt="toggle password visibility" />
                        ) : (
                          <img src={VisibilityOff} alt="toggle password visibility" />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Box>
            <div className={styles.floatContainer}>
              <Box>
                <div className={styles.rememberMe}>
                  <input
                    id="checkbox"
                    type="checkbox"
                    checked={rememberMe}
                    onChange={handleRememberMeChange}
                    className={styles.checkbox}
                  />
                  <label htmlFor="rememberMe">Remember me</label>
                </div>
              </Box>
              <Box>
                <div className={styles.forgotPassword}>
                  <Link component={RouterLink} to={ROUTES.PASSWORD_RECOVERY}>
                    Forgot password?
                  </Link>
                </div>
              </Box>
            </div>
          </Box>

          <Box mb={2}>
            <Button
              type="submit"
              disabled={props.submitting || props.pristine || props.invalid}
              color="primary"
              variant="contained"
              className={styles.loginButton}
            >
              Log in to your account
            </Button>
          </Box>
        </form>
      )}
    />
  )
}

export default LoginForm
