import React from 'react'
import Grid from '@mui/material/Grid'

import styles from '../../components/AuthContainer/AuthContainer.module.scss'

import LoginForm from '../../components/LoginForm'
import Card from '../../components/Common/Card'

const LoginPage = () => {
  return (
    <Card
      className={styles.formWrapper}
      classes={{ content: styles.centeredContent }}
      withBorder={false}
    >
      <Grid item xs={12} className={styles.formContainer}>
        <Grid item xs={12} className={styles.infoContainer}>
          <div>
            <div className={styles.headingLogin}>Sign in to Atlas</div>

            <div className={styles.headingInfo}>Transparent, every step of the way.</div>
          </div>
        </Grid>
        <LoginForm />
      </Grid>
    </Card>
  )
}

export default LoginPage
