import React, { useEffect } from 'react'
import { Provider } from 'react-redux'
import { ConnectedRouter } from 'connected-react-router'
import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles'
import CssBaseline from '@mui/material/CssBaseline'
import ReactGA from 'react-ga4'
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js'

import Snackbar from './components/Common/Snackbar'
import EnvironmentBanner from './components/EnvironmentBanner'
import theme from './helpers/theme'
import api from './api'
import configureStore, { history } from './redux/store'
import Routes from './routes'
import { PermissionsProvider } from './helpers/permissionContext'
import { clarity } from 'react-microsoft-clarity'

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend)

const store = configureStore(api, history)

const App = () => {
  useEffect(() => {
    ReactGA.initialize('G-X3G94M8HCE', {
      gaOptions: {
        siteSpeedSampleRate: 100,
      },
    })
    clarity.init('l90db3dijc')
  }, [])

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <Provider store={store}>
          <PermissionsProvider>
            <ConnectedRouter history={history}>
              <CssBaseline />
              <Snackbar />
              <EnvironmentBanner />
              <Routes />
            </ConnectedRouter>
          </PermissionsProvider>
          ,
        </Provider>
      </ThemeProvider>
    </StyledEngineProvider>
  )
}
export default App
