import React from 'react'
import Grid from '@mui/material/Grid'
import Box from '@mui/material/Box'
import Skeleton from '@mui/material/Skeleton'

import TableContainer from '../../components/Common/TableContainer'
import Table from '../../components/Common/Table'
import TableHead from '../../components/Common/TableHead'
import TableBody from '../../components/Common/TableBody'
import TableLoader from '../../components/Common/TableLoader'
import Card from '../Common/Card'

const DueDiligenceProcessDocumentsMappingLoader = () => {
  return (
    <Grid container py={3} pr={2} alignItems="flex-start" justifyContent="center" rowSpacing={2}>
      <Grid item container xs={12} justifyContent="space-between">
        <Grid item container xs={6} justifyContent="flex-start" alignItems="center">
          <Box mt={1} ml={3} display="flex" gap={2.5}>
            <Skeleton width={12} height={25} />
            <Skeleton width={140} height={25} />
            <Skeleton width={140} height={25} />
            <Skeleton width={140} height={25} />
            <Skeleton width={140} height={25} />
          </Box>
        </Grid>
      </Grid>

      <Grid item xs={12} alignItems="flex-start" justifyContent="flex-start" ml={3} mt={-1}>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Skeleton width={230} height={34} />
          <Skeleton width={64} height={60} />
        </Box>
      </Grid>

      <Grid item xs={12} mt={-1}>
        <Card noHeaderMargin>
          <Box mt={2} mb={3}>
            <Skeleton width={230} height={32} />
          </Box>

          <TableContainer>
            <Table>
              <TableHead>
                <TableLoader columnsCount={5} rowsCount={1} height={42} />
              </TableHead>
              <TableBody>
                <TableLoader columnsCount={5} rowsCount={10} height={26} />
              </TableBody>
            </Table>
          </TableContainer>
        </Card>
      </Grid>
    </Grid>
  )
}

export default DueDiligenceProcessDocumentsMappingLoader
