import React, { createContext, useCallback, useMemo, useRef } from 'react'
import MuiTableCell, { TableCellProps } from '@mui/material/TableCell'
import cn from 'classnames'

import styles from './TableCell.module.scss'

import { useTableCellManager } from './TableCellManager'

// Define the context type
interface TableCellContextType {
  isActive: boolean
  resetActiveCell: () => void
}

// Create the context
export const TableCellContext = createContext<TableCellContextType | undefined>(undefined)

interface IProps extends TableCellProps {
  isAutocompleteFilter?: boolean
  isAmountFilter?: boolean
  isDateFilter?: boolean
  isTextFilter?: boolean
}

const TableCell = React.memo(
  ({ isAutocompleteFilter, isAmountFilter, isDateFilter, isTextFilter, ...props }: IProps) => {
    const { activeCell, setActiveCell } = useTableCellManager()
    const cellRef = useRef<HTMLTableCellElement | null>(null)

    const handleClickInside = useCallback(
      (e: React.MouseEvent<HTMLTableCellElement>) => {
        const target = e.target as Element
        if (target?.closest?.('.MuiAutocomplete-option, .MuiMenuItem-root, .MuiSelect-select')) {
          return
        }
        setActiveCell(cellRef.current)
      },
      [setActiveCell],
    )

    const resetActiveCell = useCallback(() => {
      setActiveCell(null)
    }, [setActiveCell])

    const isActive = useMemo(() => {
      return cellRef.current && activeCell === cellRef.current
    }, [activeCell])

    const classNames = useMemo(() => {
      return cn(styles.root, {
        [styles.autocompleteFilter]: isAutocompleteFilter,
        [styles.amountFilter]: isAmountFilter,
        [styles.dateFilter]: isDateFilter,
        [styles.textFilter]: isTextFilter,
      })
    }, [isAutocompleteFilter, isAmountFilter, isDateFilter, isTextFilter])

    return (
      <TableCellContext.Provider value={{ isActive, resetActiveCell }}>
        <MuiTableCell
          ref={cellRef}
          onClick={handleClickInside}
          classes={{
            root: classNames,
            head: styles.headRow,
          }}
          {...props}
        />
      </TableCellContext.Provider>
    )
  },
)

export default TableCell
