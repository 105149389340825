import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'

import DueDiligenceFinancialsModal from './DueDiligenceFinancialsModal'

import {
  dueDiligenceInfo,
  dueDiligenceCompanyInfo,
  dueDiligenceDocumentRequests,
} from '../../redux/dueDiligence/selectors'
import {
  updateFinancialsVendorsInfo,
  getFinancialsCodatLink,
  reloadClientInfo,
  showDocumentRequests,
} from '../../redux/dueDiligence/actions'

const selector = createStructuredSelector({
  dueDiligenceInfo,
  dueDiligenceCompanyInfo,
  dueDiligenceDocumentRequests,
})
const actions = {
  reloadClientInfo,
  updateFinancialsVendorsInfo,
  getFinancialsCodatLink,
  showDocumentRequests,
}

export default connect(selector, actions)(DueDiligenceFinancialsModal)
