import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { Form, Field, FormRenderProps } from 'react-final-form'
import { FormApi } from 'final-form'
import { makeValidate } from 'mui-rff'
import * as Yup from 'yup'
import Avatar from '@mui/material/Avatar'
import Box from '@mui/material/Box'
import { useHistory } from 'react-router'

import styles from './ProfileForm.module.scss'

import { ReactComponent as DeleteIcon } from '@assets/images/delete-icon.svg'
import TextField from '../../components/Common/TextField'
import InputLabel from '../../components/Common/InputLabel'
import Button from '../../components/Common/Button'
import { IUser, UserRole } from '@common/interfaces/user'
import { UploadFile } from '../Common/UploadFile'
import RouteLeavingGuard from '../Common/RouteLeavingGuard'

interface IProps {
  user: IUser
  role: string
  userRoleLabel: string
  shouldShowRoleLabel: boolean
  clientName?: string
  onUpdateProfile: (data: object | FormData) => Promise<any>
  isClientUser: boolean
}

const schema = Yup.object().shape({
  firstName: Yup.string().required('First Name is required'),
  lastName: Yup.string().required('Last Name is required'),
  email: Yup.string().email().required('Email is required'),
  phone: Yup.string()
    .typeError('Phone number is invalid')
    .nullable(true)
    .matches(/^\+1\s\((\d{3})\)\s(\d{3})-(\d{4})$/, 'Phone number is invalid'),
  calendlyURL: Yup.string()
    .typeError('Calendly URL is invalid')
    .nullable(true)
    .url('Calendly URL is invalid'),
})
const validate = makeValidate(schema)

interface IFormProps extends FormRenderProps<any> {
  isSaving: boolean
  avatarUrl: string | null
  setAvatarUrl: (image: string | null) => void
  isClientUser: boolean
  role: UserRole
  shouldShowRoleLabel: boolean
  userRoleLabel: string
  clientName: string
  isDeleteAvatarAvailable: boolean
  handleDeleteAvatar: () => void
  formRef: React.MutableRefObject<FormApi<any, Partial<any>>>
}

const ProfileForm = ({
  isSaving,
  avatarUrl,
  setAvatarUrl,
  isClientUser,
  role,
  shouldShowRoleLabel,
  userRoleLabel,
  clientName,
  isDeleteAvatarAvailable,
  handleDeleteAvatar,
  form,
  values,
  dirty,
  invalid,
  handleSubmit,
  formRef,
}: IFormProps) => {
  formRef.current = form

  const history = useHistory()
  const [isImageManageModalOpen, setIsImageManageModalOpen] = useState(false)

  const handleToggleImageManageModal = useCallback(() => {
    setIsImageManageModalOpen((isOpen) => !isOpen)
  }, [])

  const handleNavigate = useCallback(
    (path) => {
      history.push(path)
    },
    [history],
  )

  const handleUploadFile = useCallback(
    (loadedFiles: File[]) => {
      form.change('files', loadedFiles)
      const [image] = loadedFiles
      if (image) {
        const reader = new FileReader()
        reader.onload = () => {
          setAvatarUrl(reader.result as string)
        }
        reader.readAsDataURL(image)
      }
    },
    [form, setAvatarUrl],
  )

  const handleDeleteFile = useCallback(() => {
    form.change('files', [])
    setAvatarUrl(null)
  }, [form, setAvatarUrl])

  const shouldBlockNavigation = useCallback(() => dirty, [dirty])

  return (
    <form onSubmit={handleSubmit}>
      <Box mt={3} display="flex" flexDirection="column" width="100%">
        <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
          {avatarUrl ? (
            <Box display="flex" alignItems="flex-end">
              <Avatar
                className={styles.avatar}
                alt={values.firstName + ' ' + values.lastName}
                src={avatarUrl}
              />
              {isDeleteAvatarAvailable && (
                <DeleteIcon className={styles.iconButton} onClick={handleDeleteAvatar} />
              )}
            </Box>
          ) : (
            <Avatar className={styles.avatar} alt={values.firstName + ' ' + values.lastName}>
              {values.firstName.charAt(0).toUpperCase() + values.lastName.charAt(0).toUpperCase()}
            </Avatar>
          )}
          {!isClientUser && (
            <Box className={styles.imageUploadWrapper}>
              <UploadFile
                title="Profile Picture"
                size="lg"
                onDropAccepted={handleUploadFile}
                files={values.files}
                onDelete={handleDeleteFile}
                acceptedFileTypes={['image']}
                maxFiles={1}
                isModalOpen={isImageManageModalOpen}
                handleToggleModal={handleToggleImageManageModal}
              />
              <Field name="files" type="hidden" render={() => null} />
            </Box>
          )}
        </Box>
        {shouldShowRoleLabel ? (
          <Box mb={2}>
            <InputLabel className={styles.companyRoleLabel}>Role:</InputLabel>{' '}
            <p className={styles.role}>{userRoleLabel}</p>
          </Box>
        ) : (
          <Box mb={2} className={styles.role}>
            <InputLabel className={styles.companyRoleLabel}>Company Name</InputLabel>{' '}
            <p className={styles.role}>{clientName}</p>
          </Box>
        )}
        <Box mb={2}>
          <InputLabel htmlFor="firstName">First Name</InputLabel>
          <TextField name="firstName" type="text" placeholder="First Name" required fullWidth />
        </Box>
        <Box mb={2}>
          <InputLabel htmlFor="lastName">Last Name</InputLabel>
          <TextField name="lastName" type="text" placeholder="Last Name" required fullWidth />
        </Box>
        <Box mb={2}>
          <InputLabel htmlFor="email">Email</InputLabel>
          <TextField disabled name="email" type="email" placeholder="Email" required fullWidth />
        </Box>
        {[UserRole.PORTFOLIO_USER, UserRole.PORTFOLIO_ADMIN].includes(role) && (
          <Box mb={2}>
            <InputLabel htmlFor="phone">Phone</InputLabel>
            <TextField name="phone" placeholder="Phone" fullWidth />
          </Box>
        )}
        {[UserRole.PORTFOLIO_USER, UserRole.PORTFOLIO_ADMIN].includes(role) && (
          <Box mb={2}>
            <InputLabel htmlFor="calendlyURL">Calendly</InputLabel>
            <TextField name="calendlyURL" type="url" placeholder="URL" fullWidth />
          </Box>
        )}
        <Box>
          <Button
            type="submit"
            disabled={invalid || !dirty}
            color="primary"
            variant="contained"
            size="small"
            className={styles.button}
            isLoading={isSaving}
          >
            Save
          </Button>
        </Box>
      </Box>

      <RouteLeavingGuard
        when={dirty}
        navigate={handleNavigate}
        shouldBlockNavigation={shouldBlockNavigation}
        helperText="You have unsaved changes. Are you sure you want to leave?"
        buttonText="Save changes"
        alternateSubmit={handleSubmit}
        isAlternateSubmitInvalid={invalid}
      />
    </form>
  )
}

const ProfileDetails = ({
  user: { firstName, lastName, email, phone, calendlyURL, avatar },
  role,
  userRoleLabel,
  shouldShowRoleLabel,
  onUpdateProfile,
  clientName,
  isClientUser,
}: IProps) => {
  const formRef: React.MutableRefObject<FormApi<any, Partial<any>>> = useRef(null)

  const [isLoading, setIsLoading] = useState(false)
  const [avatarUrl, setAvatarUrl] = useState<string | null>(null)

  const handleUpdateProfile = useCallback(
    async (data: any) => {
      const formData = new FormData()

      formData.append('email', data.email)
      formData.append('firstName', data.firstName)
      formData.append('lastName', data.lastName)

      if ([UserRole.PORTFOLIO_USER, UserRole.PORTFOLIO_ADMIN].includes(role as UserRole)) {
        formData.append('phone', data.phone)
        formData.append('calendlyURL', data.calendlyURL)
      }

      if (data.files?.length) {
        formData.append('files[]', data.files[0], data.files[0].name)
      }

      setIsLoading(true)
      const result = await onUpdateProfile(formData)
      setIsLoading(false)

      if (!result?.error) {
        formRef.current.change('files', [])
        formRef.current.resetFieldState('files')
        formRef.current.reset()
      }
    },
    [onUpdateProfile, role],
  )

  useEffect(() => {
    setAvatarUrl(avatar)
  }, [avatar])

  const initialValues = useMemo(
    () => ({
      firstName,
      lastName,
      email,
      phone,
      calendlyURL,
      files: [],
    }),
    [firstName, lastName, email, phone, calendlyURL],
  )

  const handleSetAvatarUrl = useCallback(
    (image) => {
      setAvatarUrl(image ? image : avatar)
    },
    [avatar],
  )

  const isDeleteAvatarAvailable = useMemo(() => avatarUrl?.startsWith('data:image'), [avatarUrl])

  const handleDeleteAvatar = useCallback(async () => {
    formRef.current.change('files', [])
    handleSetAvatarUrl(null)

    setIsLoading(true)
    const result = await onUpdateProfile({
      firstName,
      lastName,
      email,
      phone,
      calendlyURL,
      isAvatarDelete: true,
    })
    setIsLoading(false)

    if (!result?.error) {
      formRef.current.change('files', [])
      formRef.current.resetFieldState('files')
      formRef.current.reset()
    }
  }, [onUpdateProfile, firstName, lastName, email, phone, calendlyURL, handleSetAvatarUrl])

  return (
    <Box className={styles.wrapper}>
      <h3 className={styles.heading}>Personal Information</h3>
      <Form
        initialValues={initialValues}
        onSubmit={handleUpdateProfile}
        validate={validate}
        render={(formProps) => (
          <ProfileForm
            {...formProps}
            isSaving={isLoading}
            avatarUrl={avatarUrl}
            setAvatarUrl={handleSetAvatarUrl}
            isClientUser={isClientUser}
            role={role as UserRole}
            shouldShowRoleLabel={shouldShowRoleLabel}
            userRoleLabel={userRoleLabel}
            clientName={clientName}
            isDeleteAvatarAvailable={isDeleteAvatarAvailable}
            handleDeleteAvatar={handleDeleteAvatar}
            formRef={formRef}
          />
        )}
      />
    </Box>
  )
}

export default ProfileDetails
