import React, { useMemo } from 'react'
import { generatePath, Link } from 'react-router-dom'
import LinkButton from '@mui/material/Link'

import genericSs from '@styles/generic.module.scss'

import { IEntityInfo } from '@common/interfaces/entityInfo'
import TableRow from '../../components/Common/TableRow'
import TableCell from '../../components/Common/TableCell'
import { ROUTES } from '../../constants/routes'
import { formatPrice } from '../../helpers/helpers'
import { replaceJSX } from './GlobalSearchPage'

const GlobalSearchPageTableRowEntity = ({
  entityInfo,
  searchString,
}: {
  entityInfo: IEntityInfo
  searchString: string
}) => {
  const showAr = useMemo(() => entityInfo.type.includes('Customer'), [entityInfo.type])
  const showAp = useMemo(() => entityInfo.type.includes('Vendor'), [entityInfo.type])
  return (
    <TableRow>
      <TableCell className={genericSs.tableTextLeft}>
        <LinkButton component={Link} to={generatePath(ROUTES.ENTITY_PAGE, { id: entityInfo.id })}>
          {replaceJSX(entityInfo.name, searchString)}
        </LinkButton>
      </TableCell>
      <TableCell className={genericSs.tableTextLeft}>
        {replaceJSX(entityInfo.type, searchString)}
      </TableCell>
      <TableCell className={genericSs.tableTextRight}>
        {showAr ? (
          <>
            <span className={genericSs.pricePrefix}>$</span>
            {replaceJSX(formatPrice(entityInfo.totalExposure), searchString)}
          </>
        ) : (
          '-'
        )}
      </TableCell>
      <TableCell className={genericSs.tableTextRight}>
        {showAp ? (
          <>
            <span className={genericSs.pricePrefix}>$</span>
            {replaceJSX(formatPrice(entityInfo.totalAp), searchString)}
          </>
        ) : (
          '-'
        )}
      </TableCell>
      <TableCell className={genericSs.tableTextRight}>
        <span className={genericSs.pricePrefix}>$</span>
        {replaceJSX(formatPrice(entityInfo.totalCollections), searchString)}
      </TableCell>
      <TableCell className={genericSs.tableTextRight}>
        {entityInfo.totalClients ? replaceJSX(entityInfo.totalClients, searchString) : '-'}
      </TableCell>
    </TableRow>
  )
}

export default GlobalSearchPageTableRowEntity
