import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'

import BBCAPDetailRow from './BBCAPDetailRow'

import { previousClientCollaterals } from '../../redux/bbc/selectors'
import { getBBCAPDetailStats } from '../../redux/bbc/actions'

const selector = createStructuredSelector({
  previousClientCollaterals,
})

const actions = {
  getBBCAPDetailStats,
}

export default connect(selector, actions)(BBCAPDetailRow)
