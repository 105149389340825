import { connect } from 'react-redux'

import ClientAccountActivityRestoreModal from './ClientAccountActivityRestoreModal'

import { restoreFee } from '../../../redux/fee/actions'

const actions = {
  restoreFee,
}

export default connect(null, actions)(ClientAccountActivityRestoreModal)
