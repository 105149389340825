import { Action } from 'redux'
import { download } from '../../helpers/helpers'
import { takeEvery, select, put } from 'redux-saga/effects'
import { generatePath, matchPath } from 'react-router-dom'
import { History } from 'history'
import {
  ADD_ENTITY_INFO_SUCCESS,
  UPDATE_ENTITY_INFO_SUCCESS,
  EXPORT_CUSTOMERS_FAILURE,
  EXPORT_CUSTOMERS_SUCCESS,
  EXPORT_VENDORS_FAILURE,
  EXPORT_VENDORS_SUCCESS,
  EXPORT_INVESTORS_SUCCESS,
  EXPORT_INVESTORS_FAILURE,
} from './actions'
import { updateCheck } from '../collections/actions'
import { IResponse } from '@common/interfaces/request'
import { pathname } from '../router/selectors'
import { ROUTES } from '../../constants/routes'

export function* addEntitySuccess() {
  yield takeEvery([ADD_ENTITY_INFO_SUCCESS], function* (data: IResponse & Action) {
    const {
      // @ts-ignore
      checkId,
      // @ts-ignore
      accountNumber,
      data: { debtor },
    } = data
    if (checkId) {
      yield put(updateCheck(checkId, { debtor, accountNumber }))
    }
  })
}

export function* updateEntityInfoSuccess(history: History) {
  yield takeEvery([UPDATE_ENTITY_INFO_SUCCESS], function* (data: IResponse & Action) {
    const { id } = data.data.data
    const path: string = yield select(pathname)
    if (matchPath(path, { path: ROUTES.ENTITY_PAGE })) {
      yield history.push(generatePath(ROUTES.ENTITY_PAGE, { id }))
    }
    if (matchPath(path, { path: ROUTES.ENTITY_SETTINGS_GENERAL })) {
      yield history.push(generatePath(ROUTES.ENTITY_SETTINGS_GENERAL, { id }))
    }
  })
}

export function* exportCustomerAggregation() {
  yield takeEvery(EXPORT_CUSTOMERS_SUCCESS, function* (action: IResponse & Action) {
    try {
      yield download(action.data, action.data.name)
    } catch (error) {
      yield put({ type: EXPORT_CUSTOMERS_FAILURE, error })
    }
  })
}

export function* exportVendorAggregation() {
  yield takeEvery(EXPORT_VENDORS_SUCCESS, function* (action: IResponse & Action) {
    try {
      yield download(action.data, action.data.name)
    } catch (error) {
      yield put({ type: EXPORT_VENDORS_FAILURE, error })
    }
  })
}

export function* exportInvestorSummary() {
  yield takeEvery(EXPORT_INVESTORS_SUCCESS, function* (action: IResponse & Action) {
    try {
      yield download(action.data, action.data.name)
    } catch (error) {
      yield put({ type: EXPORT_INVESTORS_FAILURE, error })
    }
  })
}
