import { takeEvery, put } from 'redux-saga/effects'
import { Action } from 'redux'
import {
  EXPORT_PARTICIPATION_ACTIVITY_FAILURE,
  EXPORT_PARTICIPATION_ACTIVITY_SUCCESS,
} from './actions'
import { download } from '../../helpers/helpers'
import { IResponse } from '@common/interfaces/request'

export function* exportParticipationActivity() {
  yield takeEvery(EXPORT_PARTICIPATION_ACTIVITY_SUCCESS, function* (action: IResponse & Action) {
    try {
      yield download(action.data, action.data.name)
    } catch (error) {
      yield put({ type: EXPORT_PARTICIPATION_ACTIVITY_FAILURE, error })
    }
  })
}
