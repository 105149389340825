import React from 'react'
import { Form } from 'react-final-form'
import { makeValidate } from 'mui-rff'
import * as Yup from 'yup'
import Box from '@mui/material/Box'
import Button from '../Common/Button'
import TextField from '../Common/TextField'
import InputLabel from '../Common/InputLabel'
import { Link as RouterLink } from 'react-router-dom'
import Link from '@mui/material/Link'
import style from './PasswordRecoveryForm.module.scss'
import authContainerStyle from '../AuthContainer/AuthContainer.module.scss'
import { ROUTES } from '../../constants/routes'

const schema = Yup.object().shape({
  email: Yup.string().required('This field is required').email('Invalid email'),
})
const validate = makeValidate(schema)

interface IProps {
  onSubmit: ({ email }: { email: string }) => void
}

const PasswordRecoveryForm = ({ onSubmit }: IProps) => {
  return (
    <Form
      validate={validate}
      onSubmit={onSubmit}
      render={(props) => (
        <form onSubmit={props.handleSubmit}>
          <Box display="flex" flexDirection="column" width="100%">
            <Box mb={2}>
              <InputLabel htmlFor="email" className={authContainerStyle.emailInput}>
                Email
              </InputLabel>
              <TextField
                name="email"
                type="text"
                placeholder="Enter your email address"
                fullWidth
                InputProps={{
                  autoComplete: 'off',
                }}
              />
            </Box>
            <Box>
              <Button
                type="submit"
                disabled={props.submitting || props.pristine || props.invalid}
                color="primary"
                variant="contained"
                size="small"
                className={authContainerStyle.resetButton}
              >
                Send link
              </Button>
            </Box>

            <div className={style.forgotPassword}>
              <Link component={RouterLink} to={ROUTES.LOGIN}>
                I think I remember my password
              </Link>
            </div>
          </Box>
        </form>
      )}
    />
  )
}

export default PasswordRecoveryForm
