import React, { useEffect, useMemo, useState } from 'react'
import { useParams } from 'react-router'
import Grid from '@mui/material/Grid'

import Card from '../../Common/Card'
import { IClientInfo } from '@common/interfaces/client'
import OngoingReportingSummary from '../../OngoingReportingSummary'
import {
  AnnuallyReportingPeriods,
  DisplayGrowthPeriods,
  FinancialsType,
  OngoingReportingType,
  ReportingFlow,
  ReportingPeriods,
} from '@common/interfaces/bbc'
import { ILoadingData } from '../../../redux/types'
import ClientCashFlow from '../ClientCashFlow'

interface IProps {
  clientInfo: IClientInfo
  financialsReports: ILoadingData<{ asOfDates: string[]; startDate: string }>
  listFinancialsReports: (id: string) => void
  hideFinancialsReports: () => void
}

const ClientFinancials = ({
  clientInfo,
  financialsReports,
  listFinancialsReports,
  hideFinancialsReports,
}: IProps) => {
  const { id } = useParams<{ id: string }>()

  const startDate = useMemo(() => financialsReports.data?.startDate || null, [financialsReports])
  const [isISModalShown, setIsISModalShown] = useState(false)
  const [isBSModalShown, setIsBSModalShown] = useState(false)

  const [currentReportingPeriod, setCurrentReportingPeriod] = useState<ReportingPeriods>(
    ReportingPeriods.Monthly,
  )
  const [currentAnnuallyReportingPeriods, setCurrentAnnuallyReportingPeriods] = useState<
    AnnuallyReportingPeriods[]
  >([AnnuallyReportingPeriods.FY, AnnuallyReportingPeriods.YTD])
  const [currentDisplayGrowth, setCurrentDisplayGrowth] = useState<DisplayGrowthPeriods>(
    DisplayGrowthPeriods.Hide,
  )

  const [currentOngoingReporting, setCurrentOngoingReporting] = useState<string[]>([
    FinancialsType.Actuals,
  ])
  const [currentOptions, setCurrentOptions] = useState<FinancialsType[]>([FinancialsType.Actuals])
  const [dateRange, setDateRange] = useState({ startDate, endDate: null })

  useEffect(() => {
    if (startDate) {
      setDateRange((dateRange) => ({
        ...dateRange,
        startDate,
      }))
    }
  }, [startDate])

  useEffect(() => {
    if (id) {
      listFinancialsReports(id)
    }
  }, [id, listFinancialsReports])

  useEffect(() => {
    return () => {
      hideFinancialsReports()
    }
  }, [hideFinancialsReports])

  const asOfDates = useMemo(() => financialsReports.data?.asOfDates || [], [financialsReports])

  return (
    <Card noHeaderMargin noPadding withBorder={false}>
      <Grid container pr={1}>
        <Grid item xs={12}>
          <OngoingReportingSummary
            isModalShown={isISModalShown}
            setIsModalShown={setIsISModalShown}
            statementType={OngoingReportingType.IncomeStatement}
            reportingFlow={ReportingFlow.OngoingReporting}
            isLoading={!clientInfo}
            asOfDates={asOfDates}
            currentReportingPeriod={currentReportingPeriod}
            currentAnnuallyReportingPeriods={currentAnnuallyReportingPeriods}
            currentDisplayGrowth={currentDisplayGrowth}
            currentOngoingReporting={currentOngoingReporting}
            currentDateRange={dateRange}
            setCurrentReportingPeriod={setCurrentReportingPeriod}
            setCurrentAnnuallyReportingPeriods={setCurrentAnnuallyReportingPeriods}
            setCurrentDisplayGrowth={setCurrentDisplayGrowth}
            setCurrentOngoingReporting={setCurrentOngoingReporting}
            setCurrentDateRange={setDateRange}
            title="Income Statement"
            cardWithBorder={false}
            isSummary
          />
        </Grid>

        <Grid item xs={12}>
          <OngoingReportingSummary
            isModalShown={isBSModalShown}
            setIsModalShown={setIsBSModalShown}
            statementType={OngoingReportingType.BalanceSheet}
            reportingFlow={ReportingFlow.OngoingReporting}
            isLoading={!clientInfo}
            asOfDates={asOfDates}
            currentReportingPeriod={currentReportingPeriod}
            currentAnnuallyReportingPeriods={currentAnnuallyReportingPeriods}
            currentDisplayGrowth={currentDisplayGrowth}
            currentOngoingReporting={currentOngoingReporting}
            currentDateRange={dateRange}
            setCurrentReportingPeriod={setCurrentReportingPeriod}
            setCurrentAnnuallyReportingPeriods={setCurrentAnnuallyReportingPeriods}
            setCurrentDisplayGrowth={setCurrentDisplayGrowth}
            setCurrentOngoingReporting={setCurrentOngoingReporting}
            setCurrentDateRange={setDateRange}
            title="Balance Sheet"
            cardWithBorder={false}
            isSummary
          />
        </Grid>

        <Grid item xs={12}>
          <ClientCashFlow
            reportingFlow={ReportingFlow.OngoingReporting}
            currentOptions={currentOptions}
            currentReportingPeriod={currentReportingPeriod}
            currentDateRange={dateRange}
            setCurrentReportingPeriod={setCurrentReportingPeriod}
            setCurrentDateRange={setDateRange}
            setCurrentOptions={setCurrentOptions}
          />
        </Grid>
      </Grid>
    </Card>
  )
}

export default ClientFinancials
