import React, { useEffect, useCallback } from 'react'
import { useLocation, useHistory } from 'react-router'
import queryString from 'query-string'
import Grid from '@mui/material/Grid'
import Box from '@mui/material/Box'

import Card from '../../components/Common/Card'
import CustomersSummaryTable from '../../components/CustomersSummaryTable'
import VendorsSummaryTable from '../../components/VendorsSummaryTable'
import InvestorSummaryTable from '../../components/InvestorSummaryTable'
import Tabs from '../../components/Common/Tabs'
import { useSetPageTitle } from '../../hooks/useSetPageTitle'

const TABS = ['Customers', 'Vendors', 'Investors']

const EntityManagementPage = () => {
  const { search }: { search: string } = useLocation()
  const { tab = TABS[0] } = queryString.parse(search) as { tab: string }

  const history = useHistory()

  useSetPageTitle('Entities')

  const handleChangeTab = useCallback(
    (selectedTab: string) => {
      history.replace({
        search: queryString.stringify({ tab: selectedTab }),
      })
    },
    [history],
  )

  useEffect(() => {
    const currentSearch = queryString.parse(search, {
      parseNumbers: true,
      parseBooleans: true,
      arrayFormat: 'index',
    })

    if (!currentSearch.tab) {
      history.replace({
        search: queryString.stringify({ ...currentSearch, tab: TABS[0] }),
      })
    }
  }, [history, search])

  return (
    <Box py={1} pr={2}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Card
            withBorder={false}
            title={
              <Box display="flex" justifyContent="space-between" alignItems="center">
                <Tabs tabs={TABS} selected={tab} handleChange={handleChangeTab} />
              </Box>
            }
          >
            {tab === TABS[0] && <CustomersSummaryTable />}
            {tab === TABS[1] && <VendorsSummaryTable />}
            {tab === TABS[2] && <InvestorSummaryTable />}
          </Card>
        </Grid>
      </Grid>
    </Box>
  )
}

export default EntityManagementPage
