import React from 'react'
import Box from '@mui/material/Box'

import ClientSetupHeader from '../../components/ClientSetupHeader'
import BankAccountInformation from '../../components/Client/BankAccountInformation'

const ClientSetupBankAccountPage = () => {
  return (
    <Box py={1} pr={2}>
      <ClientSetupHeader />

      <Box>
        <BankAccountInformation />
      </Box>
    </Box>
  )
}

export default ClientSetupBankAccountPage
