import React, { useEffect, useMemo, useState } from 'react'
import { generatePath } from 'react-router-dom'
import { useParams } from 'react-router'
import Grid from '@mui/material/Grid'

import { IOPSReporting } from '@common/interfaces/prospects'
import { ROUTES } from '../../constants/routes'
import {
  AnnuallyReportingPeriods,
  DisplayGrowthPeriods,
  FinancialsType,
  OngoingReportingType,
  ReportingFlow,
  ReportingPeriods,
} from '@common/interfaces/bbc'
import OngoingReportingSummary from '../OngoingReportingSummary'

interface IProps {
  reporting: IOPSReporting
  isIncomeStatementShown?: boolean
  isBalanceSheetShown?: boolean
  defaultFinancialsType?: FinancialsType
}

const ProspectReportingSummaryFinancials = ({
  reporting,
  isIncomeStatementShown = true,
  isBalanceSheetShown = true,
  defaultFinancialsType = FinancialsType.Actuals,
}: IProps) => {
  const { id } = useParams<{ id: string }>()
  const [isISModalShown, setIsISModalShown] = useState(false)
  const [isBSModalShown, setIsBSModalShown] = useState(false)
  const [currentReportingPeriod, setCurrentReportingPeriod] = useState<ReportingPeriods>(
    ReportingPeriods.Monthly,
  )
  const [currentAnnuallyReportingPeriods, setCurrentAnnuallyReportingPeriods] = useState<
    AnnuallyReportingPeriods[]
  >([AnnuallyReportingPeriods.FY])
  const [currentDisplayGrowth, setCurrentDisplayGrowth] = useState<DisplayGrowthPeriods>(
    DisplayGrowthPeriods.Hide,
  )
  const [currentFinancialsTypes, setCurrentFinancialsTypes] = useState<FinancialsType[]>([
    defaultFinancialsType,
  ])
  const [dateRange, setDateRange] = useState({ startDate: null, endDate: null })
  const datesBoundary = useMemo(() => {
    const dates = (reporting?.opsReportingFlows || [])
      .filter(({ type }) =>
        (currentFinancialsTypes.length === 1
          ? currentFinancialsTypes[0] === FinancialsType.Actuals
            ? [OngoingReportingType.IncomeStatement, OngoingReportingType.BalanceSheet]
            : [
                OngoingReportingType.IncomeStatementProjections,
                OngoingReportingType.BalanceSheetProjections,
              ]
          : [
              OngoingReportingType.IncomeStatement,
              OngoingReportingType.BalanceSheet,
              OngoingReportingType.IncomeStatementProjections,
              OngoingReportingType.BalanceSheetProjections,
            ]
        ).includes(type as OngoingReportingType),
      )
      .map(({ files }) => files)
      .flat()
      .filter(({ data }) => data?.startDate && data?.endDate)
      .map(({ data }) => data)

    if (!dates.length) {
      return {
        minDate: null,
        maxDate: null,
      }
    }

    const minDate = [...new Set(dates.map(({ startDate }) => startDate))].sort().reverse().pop()
    const maxDate = [...new Set(dates.map(({ endDate }) => endDate))].sort().pop()

    return {
      minDate,
      maxDate,
    }
  }, [reporting, currentFinancialsTypes])

  useEffect(() => {
    if (datesBoundary && !dateRange.startDate && !dateRange.endDate) {
      setDateRange({ startDate: datesBoundary.minDate, endDate: datesBoundary.maxDate })
    }
  }, [datesBoundary, dateRange])

  if (!isIncomeStatementShown && !isBalanceSheetShown) {
    return null
  }

  return (
    <Grid container pr={1}>
      {isIncomeStatementShown && (
        <Grid item xs={12}>
          <OngoingReportingSummary
            isModalShown={isISModalShown}
            setIsModalShown={setIsISModalShown}
            datesBoundary={datesBoundary}
            statementType={OngoingReportingType.IncomeStatement}
            reportingFlow={ReportingFlow.OPS}
            isLoading={!reporting}
            currentReportingPeriod={currentReportingPeriod}
            currentAnnuallyReportingPeriods={currentAnnuallyReportingPeriods}
            currentDisplayGrowth={currentDisplayGrowth}
            currentFinancialsTypes={currentFinancialsTypes}
            currentDateRange={dateRange}
            setCurrentReportingPeriod={setCurrentReportingPeriod}
            setCurrentAnnuallyReportingPeriods={setCurrentAnnuallyReportingPeriods}
            setCurrentDisplayGrowth={setCurrentDisplayGrowth}
            setCurrentFinancialsTypes={setCurrentFinancialsTypes}
            setCurrentDateRange={setDateRange}
            defaultExpandState={false}
            title="Income Statement"
            editLink={generatePath(ROUTES.PROSPECT_REPORTING_INCOME_STATEMENT, { id })}
            cardWithBorder={false}
            isSummary
          />
        </Grid>
      )}

      {isBalanceSheetShown && (
        <Grid item xs={12}>
          <OngoingReportingSummary
            isModalShown={isBSModalShown}
            setIsModalShown={setIsBSModalShown}
            datesBoundary={datesBoundary}
            statementType={OngoingReportingType.BalanceSheet}
            reportingFlow={ReportingFlow.OPS}
            isLoading={!reporting}
            currentReportingPeriod={currentReportingPeriod}
            currentAnnuallyReportingPeriods={currentAnnuallyReportingPeriods}
            currentFinancialsTypes={currentFinancialsTypes}
            currentDisplayGrowth={currentDisplayGrowth}
            currentDateRange={dateRange}
            setCurrentReportingPeriod={setCurrentReportingPeriod}
            setCurrentAnnuallyReportingPeriods={setCurrentAnnuallyReportingPeriods}
            setCurrentDisplayGrowth={setCurrentDisplayGrowth}
            setCurrentFinancialsTypes={setCurrentFinancialsTypes}
            setCurrentDateRange={setDateRange}
            defaultExpandState={false}
            title="Balance Sheet"
            editLink={generatePath(ROUTES.PROSPECT_REPORTING_BALANCE_SHEET, { id })}
            cardWithBorder={false}
            isSummary
          />
        </Grid>
      )}
    </Grid>
  )
}

export default ProspectReportingSummaryFinancials
