import { prefix } from './actions'
import { IRootState } from '../reducer'

export const isLoading = (state: IRootState) => state[prefix].loadingArray.length > 0

export const folders = (state: IRootState) => state[prefix].folders

export const boxViewLink = (state: IRootState) => state[prefix].boxViewLink

export const isBoxViewerOpen = (state: IRootState) => !!state[prefix].boxViewLink
