import React, { useMemo } from 'react'
import Grid from '@mui/material/Grid'
import Skeleton from '@mui/material/Skeleton'
import chunk from 'lodash/chunk'
import cn from 'classnames'

import styles from './ProspectApplicationPage.module.scss'

import { IOPSReporting } from '@common/interfaces/prospects'
import { ClientEntityRelationshipType } from '@common/interfaces/integration'
import { ClientERPSystem } from '@common/interfaces/client'
import { formatDateYear } from '../../helpers/helpers'
import ProspectHeader from '../../components/ProspectHeader'
import { DEFAULT_EMPTY_ARRAY_PROP } from '../../constants/common'

interface IProps {
  reporting: IOPSReporting
}

interface IApplicationData {
  label: string
  value?: string | number
  values?: string[]
  size?: number
}

const ProspectApplicationLoaderDataItem = () => {
  return (
    <Grid item xs={4}>
      <div className={styles.label}>
        <Skeleton width={80} height={20} />
      </div>
      <div className={styles.value}>
        <Skeleton width={120} height={22} />
      </div>
    </Grid>
  )
}

const ProspectApplicationLoaderData = () => {
  return (
    <Grid container item xs={12}>
      <Grid item xs={12} mb={1.5}>
        <div className={styles.header}>
          <Skeleton width={140} height={30} />
        </div>

        <Grid container mx={2} py={2} className={styles.withBorder}>
          <ProspectApplicationLoaderDataItem />
          <ProspectApplicationLoaderDataItem />
          <ProspectApplicationLoaderDataItem />
        </Grid>

        <Grid container mx={2} py={2}>
          <ProspectApplicationLoaderDataItem />
        </Grid>
      </Grid>
    </Grid>
  )
}

const ProspectApplicationLoader = () => {
  return (
    <>
      <ProspectApplicationLoaderData />
      <ProspectApplicationLoaderData />
      <ProspectApplicationLoaderData />
    </>
  )
}

const ProspectApplicationDataItem = ({
  label,
  value = '',
  values = DEFAULT_EMPTY_ARRAY_PROP,
  size = 4,
}: IApplicationData) => {
  return (
    <Grid item xs={size}>
      <div className={styles.label}>{label}</div>
      {values?.length > 0 ? (
        <div className={styles.valuesContainer}>
          {values.map((item) => (
            <div key={item} className={styles.valuesItem}>
              {item}
            </div>
          ))}
        </div>
      ) : (
        <div className={styles.value}>{value || '-'}</div>
      )}
    </Grid>
  )
}

const ProspectApplicationData = ({ data }: { data: IApplicationData[] }) => {
  const rows = useMemo(() => chunk(data, 3), [data])

  return (
    <>
      {rows.map((columns, rowIndex) => (
        <Grid
          key={rowIndex}
          container
          mx={2}
          py={2}
          className={cn({ [styles.withBorder]: rowIndex !== rows.length - 1 })}
        >
          {columns.map((props, columnIndex) => (
            <ProspectApplicationDataItem key={columnIndex} {...props} />
          ))}
        </Grid>
      ))}
    </>
  )
}

const ProspectApplicationContactInfo = ({ reporting }: { reporting: IOPSReporting }) => {
  const data = useMemo(
    () =>
      reporting
        ? [
            { label: 'First Name', value: reporting.clientIntakeInfo?.primaryContactFirstName },
            { label: 'Last Name', value: reporting.clientIntakeInfo?.primaryContactLastName },
            { label: 'Email address', value: reporting.clientIntakeInfo?.primaryContactEmail },
            { label: 'Phone number', value: reporting.clientIntakeInfo?.primaryContactPhone },
          ]
        : [],
    [reporting],
  )

  if (!reporting) {
    return null
  }

  return (
    <Grid container item xs={12}>
      <Grid item xs={12} mb={1.5}>
        <div className={styles.header}>Contact Person</div>
      </Grid>
      <ProspectApplicationData data={data} />
    </Grid>
  )
}

const ProspectApplicationCompanyInfo = ({ reporting }: { reporting: IOPSReporting }) => {
  const data = useMemo(
    () =>
      reporting
        ? [
            {
              label: 'Company name',
              value: reporting.clientIntakeInfo?.clientName || reporting.clientName,
            },
            {
              label: 'Legal company name',
              value:
                reporting.clientIntakeInfo?.fullLegalName || reporting.clientInfo?.fullLegalName,
            },
            {
              label: 'HQ address',
              value: [
                reporting.clientIntakeInfo?.billingStreet || reporting.clientInfo?.billingStreet,
                reporting.clientIntakeInfo?.billingCity || reporting.clientInfo?.billingCity,
                reporting.clientIntakeInfo?.billingState || reporting.clientInfo?.billingState,
                reporting.clientIntakeInfo?.billingPostalCode ||
                  reporting.clientInfo?.billingPostalCode,
                reporting.clientIntakeInfo?.billingCountry || reporting.clientInfo?.billingCountry,
              ]
                .filter(Boolean)
                .join(', '),
            },
            {
              label: '# of employees',
              value:
                reporting.clientIntakeInfo?.employeeCount || reporting.clientInfo?.employeeCount,
            },
            {
              label: 'Year founded',
              value: reporting.clientIntakeInfo?.establishedDate
                ? formatDateYear(reporting.clientIntakeInfo?.establishedDate)
                : reporting.clientInfo?.establishedDate
                ? formatDateYear(reporting.clientInfo?.establishedDate)
                : '',
            },
            {
              label: 'Company phone number',
              value: reporting.clientIntakeInfo?.phone || reporting.clientInfo?.phone,
            },
            {
              label: 'State of formation',
              value:
                reporting.clientIntakeInfo?.stateOfFormation ||
                reporting.clientInfo?.stateOfFormation,
            },
            {
              label: 'Federal tax ID',
              value:
                reporting.clientIntakeInfo?.federalTaxId || reporting.clientInfo?.federalTaxId
                  ? (
                      reporting.clientIntakeInfo?.federalTaxId ||
                      reporting.clientInfo?.federalTaxId ||
                      ''
                    ).replace(/(\d{2})(.*)/, '$1-$2')
                  : '',
            },
          ]
        : [],
    [reporting],
  )

  if (!reporting) {
    return null
  }

  return (
    <Grid container item xs={12}>
      <Grid item xs={12} mb={1.5}>
        <div className={styles.header}>Company Information</div>
      </Grid>
      <ProspectApplicationData data={data} />
    </Grid>
  )
}

const ProspectApplicationPeopleAndTool = ({ reporting }: { reporting: IOPSReporting }) => {
  const data = useMemo(() => {
    if (!reporting?.clientInfo) {
      return []
    }

    const lawFirm = reporting.clientInfo.clientEntityRelationship.find(
      ({ type }) => type === ClientEntityRelationshipType.LawFirm,
    )
    const outsourcedCFO = reporting.clientInfo.clientEntityRelationship.find(
      ({ type }) => type === ClientEntityRelationshipType.OutsourcedCFO,
    )
    const accountingFirm = reporting.clientInfo.clientEntityRelationship.find(
      ({ type }) => type === ClientEntityRelationshipType.AccountingFirm,
    )
    const distributionServices3PL = reporting.clientInfo.clientEntityRelationship.find(
      ({ type }) => type === ClientEntityRelationshipType.DistributionServices3PL,
    )

    return [
      {
        label: 'Law firm',
        value: lawFirm ? lawFirm.salesforceAccount?.name || lawFirm.newSalesforceAccount : '',
      },
      {
        label: 'Outsourced CFO',
        value: outsourcedCFO
          ? outsourcedCFO.salesforceAccount?.name || outsourcedCFO.newSalesforceAccount
          : '',
      },
      {
        label: 'Accounting Firm',
        value: accountingFirm
          ? accountingFirm.salesforceAccount?.name || accountingFirm.newSalesforceAccount
          : '',
      },
      {
        label: 'What 3PL’s do you use?',
        value: distributionServices3PL
          ? distributionServices3PL.salesforceAccount?.name ||
            distributionServices3PL.newSalesforceAccount
          : '',
      },
      {
        label: 'What ERP’s and/or Inventory management systems do you use?',
        values: [
          ...(reporting.clientIntakeInfo?.erpSystem || reporting.clientInfo.erpSystem || []).filter(
            (system) => system !== ClientERPSystem.Other,
          ),
          ...((reporting.clientIntakeInfo?.erpSystemOther || reporting.clientInfo.erpSystemOther)
            ?.split(',')
            .map((system) => system.trim()) || []),
        ].filter(Boolean),
        size: 8,
      },
    ]
  }, [reporting])

  if (!reporting) {
    return null
  }

  return (
    <Grid container item xs={12}>
      <Grid item xs={12} mb={1.5}>
        <div className={styles.header}>People & Tools</div>
      </Grid>
      <ProspectApplicationData data={data} />
    </Grid>
  )
}

const ProspectApplicationPage = ({ reporting }: IProps) => {
  return (
    <Grid container py={3} pr={2} rowSpacing={3} alignItems="flex-start" justifyContent="center">
      <Grid item container xs={12}>
        <ProspectHeader />
      </Grid>

      <Grid
        item
        container
        rowSpacing={4}
        xs={12}
        direction="column"
        justifyContent="flex-start"
        alignItems="flex-start"
        ml={3.75}
      >
        {!reporting && <ProspectApplicationLoader />}
        <ProspectApplicationContactInfo reporting={reporting} />
        <ProspectApplicationCompanyInfo reporting={reporting} />
        <ProspectApplicationPeopleAndTool reporting={reporting} />
      </Grid>
    </Grid>
  )
}

export default ProspectApplicationPage
