import React, { useCallback, useState, useMemo, useEffect, useRef } from 'react'
import InfiniteScroll from 'react-infinite-scroll-component'
import { createFilterOptions } from '@mui/base'
import Chip from '@mui/material/Chip'
import Tooltip from '@mui/material/Tooltip'
import Box from '@mui/material/Box'
import { FieldArray } from 'react-final-form-arrays'
import { Form } from 'react-final-form'
import arrayMutators from 'final-form-arrays'
import setFieldData from 'final-form-set-field-data'
import cn from 'classnames'
import styles from './BankTransactionsMapping.module.scss'
import mappingLegendStyles from '../BankTransactionsMappingLegend/BankTransactionsMappingLegend.module.scss'
import genericSs from '@styles/generic.module.scss'
import ActiveToolbar from '../ActiveToolbar'
import Table from '../Common/Table'
import TableHead from '../Common/TableHead'
import TableRow from '../Common/TableRow'
import TableCell from '../Common/TableCell'
import TableContainer from '../Common/TableContainer'
import TableBody from '../Common/TableBody'
import CreatableSelectField from '../Common/CreatableSelectField'
import TaggingField from '../Common/TaggingField'
import TableFiltersRow from '../Common/TableFiltersRow'
import {
  IBankTransaction,
  IBankTransactionData,
  TYPE_OPTIONS_DEBIT,
  TYPE_OPTIONS_CREDIT,
  IBankTransactionDetail,
} from '@common/interfaces/bankTransactions'
import { IEntityInfo, IOptionType } from '@common/interfaces/entityInfo'
import {
  handleMultipleSelect,
  formatDate,
  formatPrice,
  debounceEventHandler,
  dateToString,
  voidHandler,
} from '../../helpers/helpers'
import { buildFiltersDefaults, buildFiltersValidateSchema } from '../../helpers/filters'
import {
  BANK_TRANSACTIONS_DETAIL_LIST_FILTERS_CONFIG,
  BANK_TRANSACTIONS_LIST_FILTERS_CONFIG,
  PER_PAGE,
} from '@common/constants/filters'
import FilterContainer from '../Filters/FilterContainer'
import { snakeToCamelCase } from '@common/helpers/helpers'
import Button from '../Common/Button'
import TableLoader from '../Common/TableLoader'
import { ILoadingData } from '../../redux/types'
import SaveState from '../Common/SaveState'
import { ExpandDetailIcon } from '../Common/Icons/Icons'
import { ReportingFlow } from '@common/interfaces/bbc'

const predictionCardLevel = {
  '1 - Possible': 'predictionCardPossible',
  '2 - Likely': 'predictionCardLikely',
  '3 - Very Likely': 'predictionCardVeryLikely',
  '1': 'predictionCardDebtor',
}

const mutators = {
  ...arrayMutators,
  setFieldData,
}

const getCategoryPrediction = (predictions: object) => {
  const updatedPredictions: { category: string; confidence: string }[] = []

  Object.entries(predictions).forEach(([key, value]) => {
    if (value != null) {
      updatedPredictions.push({
        category: key,
        confidence: value,
      })
    }
  })

  return updatedPredictions
}

const EMPTY_ORDER_BY: any = {
  field: null,
  direction: 'ASC',
}

const BankTransactionsMappingDetailRow = ({
  isModalShown,
  cleanedDescription,
  bankTransactionDetail,
  listBankTransactionDetail,
}: {
  isModalShown: boolean
  cleanedDescription: string
  bankTransactionDetail: IBankTransactionDetail[]
  listBankTransactionDetail: (params: object) => void
}) => {
  const wrapperRef = useRef(null)

  const [isLoading, setIsLoading] = useState(true)
  const [activeItem, setActiveItem] = useState<number>()
  const [activeItems, setActiveItems] = useState([])

  const handleSelectRow = useMemo(() => handleMultipleSelect(setActiveItems, setActiveItem), [])
  const resetActiveItems = useCallback(() => setActiveItems([]), [])

  const totalCount = useMemo(() => bankTransactionDetail?.[0]?.totalCount, [bankTransactionDetail])

  const debounceListDetail = useMemo(
    () =>
      debounceEventHandler(async (data: any) => {
        !data.loadMore && setIsLoading(true)
        await listBankTransactionDetail({
          ...data,
          cleanedDescription,
          nestedRows: {
            keys: ['cleanedDescription'],
          },
        })
        setIsLoading(false)
      }, 500),
    [listBankTransactionDetail, cleanedDescription],
  )

  const loadMore = useCallback(() => {
    debounceListDetail({
      loadMore: true,
      page: Math.ceil(bankTransactionDetail?.length / PER_PAGE),
    })
  }, [debounceListDetail, bankTransactionDetail])

  useEffect(() => {
    !bankTransactionDetail &&
      debounceListDetail({
        page: 0,
      })
  }, [debounceListDetail, bankTransactionDetail])

  const totalRow = useMemo(
    () =>
      bankTransactionDetail
        ?.filter((_, index) => activeItems.includes(index))
        .reduce(
          (totalRowResult, row) => {
            totalRowResult.amount += +row.amount || 0
            return totalRowResult
          },
          {
            amount: 0,
          },
        ),
    [activeItems, bankTransactionDetail],
  )

  return (
    <TableContainer
      className={styles.nestedTable}
      onActiveRowsChange={setActiveItems}
      onActiveRowChange={setActiveItem}
      hasFooter
    >
      <Table ref={wrapperRef}>
        <TableHead>
          <TableFiltersRow
            filters={BANK_TRANSACTIONS_DETAIL_LIST_FILTERS_CONFIG}
            orderBy={EMPTY_ORDER_BY}
            handleOrderChange={voidHandler}
          />
        </TableHead>
        <TableBody id={cleanedDescription} className={styles.scrollableDiv}>
          {isLoading ? (
            <TableLoader columnsCount={9} />
          ) : (
            <InfiniteScroll
              dataLength={bankTransactionDetail?.length || 0}
              next={loadMore}
              hasMore={bankTransactionDetail?.length < totalCount}
              loader={<TableLoader columnsCount={3} rowsCount={1} />}
              scrollableTarget={cleanedDescription}
            >
              {bankTransactionDetail?.map((item, index) => (
                <TableRow
                  key={item.id}
                  data-index={index}
                  className={cn('activableRow', {
                    activeRow: activeItems.includes(index),
                    currentActiveRow: activeItem === index,
                  })}
                  onClick={(event) => handleSelectRow(event, index)}
                >
                  <TableCell className={genericSs.tableTextLeft}>
                    <Tooltip
                      title={item.descriptionOriginal || ''}
                      placement="top"
                      disableTouchListener
                    >
                      <span>{item.descriptionOriginal}</span>
                    </Tooltip>
                  </TableCell>
                  <TableCell className={genericSs.tableTextRight}>
                    {formatDate(item.recordDate)}
                  </TableCell>
                  <TableCell className={genericSs.tableTextRight}>
                    <span className={genericSs.pricePrefix}>$</span>
                    {formatPrice(item.amount)}
                  </TableCell>
                </TableRow>
              ))}
            </InfiniteScroll>
          )}
        </TableBody>
      </Table>
      <ActiveToolbar
        activeItems={activeItems}
        className={styles.nestedActiveToolbar}
        isFullscreen={isModalShown}
        containerRef={wrapperRef}
        resetActiveItems={resetActiveItems}
      >
        <div className={cn(genericSs.tableTextRight, styles.totals)}>
          <span className={genericSs.pricePrefix}>$</span>
          {formatPrice(totalRow?.amount)}
        </div>
      </ActiveToolbar>
    </TableContainer>
  )
}

const filtersValidate = buildFiltersValidateSchema(BANK_TRANSACTIONS_LIST_FILTERS_CONFIG)

interface IProps {
  id: string
  transactionType: 'Debit' | 'Credit'
  currentClientName?: string
  isModalShown?: boolean
  bankTransactionsData: ILoadingData<IBankTransactionData>
  listBankTransactions: (params: object) => void
  listEntityInfo: (data: object) => Promise<{ data: IEntityInfo[] }>
  addEntityInfo: (data: object) => Promise<any>
  listBankTransactionDetail: (params: object) => Promise<any>
  updateTransactions: (data: object) => void
  reportingFlow: ReportingFlow
  readOnly?: boolean
  tableClassName?: string
}

const categoryMapping = {
  debtFlag: 'debt_flag',
  debtorPaymentFlag: 'debtor_payment_flag',
  diligenceDepositFlag: 'diligence_deposit_flag',
  intraCompanyTransferFlag: 'intra_company_transfer_flag',
  investmentFlag: 'investment_flag',
  vendorPaymentFlag: 'vendor_payment_flag',
}

const filtersDefaults = buildFiltersDefaults(BANK_TRANSACTIONS_LIST_FILTERS_CONFIG, {
  transactionType: 'Debit',
})

const BankTransactionsMapping = ({
  id,
  transactionType,
  currentClientName = '',
  isModalShown = false,
  bankTransactionsData,
  updateTransactions,
  listEntityInfo,
  addEntityInfo,
  listBankTransactions,
  listBankTransactionDetail,
  reportingFlow,
  readOnly = false,
  tableClassName = '',
}: IProps) => {
  const {
    isLoading,
    isSaving,
    isSaved,
    data: bankTransactions,
  } = useMemo(() => bankTransactionsData, [bankTransactionsData])
  const [filters, setFilters] = useState(filtersDefaults)
  const [orderBy, setOrderBy] = useState({
    field: 'transactionAmount',
    direction: 'ASC',
  })

  const [expanded, setExpanded] = useState([])
  const [activeItems, setActiveItems] = useState([])
  const [activeItem, setActiveItem] = useState<number>()
  const [entityOptions, setEntityOptions] = useState<IOptionType[]>([])

  const handleExpand = useCallback((label: string) => {
    setExpanded((values) =>
      values.includes(label) ? values.filter((item) => item !== label) : [...values, label],
    )
  }, [])

  const idParam = useMemo(() => {
    switch (reportingFlow) {
      case ReportingFlow.DueDiligence:
        return { ddReportingId: id }
      case ReportingFlow.OngoingReporting:
        return { ongoingReportingId: id }
      case ReportingFlow.ClientPage:
        return { clientId: id }
      default:
        return {}
    }
  }, [id, reportingFlow])

  const handleListBankTransactionDetail = useCallback(
    (data) => {
      listBankTransactionDetail({
        ...data,
        ...idParam,
      })
    },
    [listBankTransactionDetail, idParam],
  )

  const fetchBankTransactionsList = useCallback(
    (data) => {
      const params = {
        ...data,
        ...idParam,
        filters: {
          ...data.filters,
          transactionType,
        },
      }
      if (params?.filters.recordDateFrom && typeof params.filters.recordDateFrom !== 'string') {
        params.filters.recordDateFrom = dateToString(params.filters.recordDateFrom)
      }
      if (params?.filters.recordDateTo && typeof params.filters.recordDateTo !== 'string') {
        params.filters.recordDateTo = dateToString(params.filters.recordDateTo)
      }
      listBankTransactions(params)
      setActiveItems([])
    },
    [listBankTransactions, transactionType, idParam],
  )

  const debounceListBankTransactions = useMemo(
    () => debounceEventHandler(fetchBankTransactionsList, 500),
    [fetchBankTransactionsList],
  )

  const refetchBankTransactions = useCallback(
    (skipLoader: boolean = false) => {
      fetchBankTransactionsList({
        page: 0,
        perPage: bankTransactions.data.length,
        orderBy: orderBy.field,
        orderDirection: orderBy.direction,
        filters,
        skipLoader,
      })
    },
    [bankTransactions, filters, orderBy, fetchBankTransactionsList],
  )

  useEffect(() => {
    debounceListBankTransactions({
      page: 0,
      filters,
      orderBy: orderBy.field,
      orderDirection: orderBy.direction,
    })
  }, [filters, orderBy, debounceListBankTransactions])

  useEffect(() => {
    setOrderBy({
      direction: transactionType === 'Debit' ? 'ASC' : 'DESC',
      field: 'transactionAmount',
    })
  }, [transactionType])

  const handleSelectRow = useMemo(
    () => handleMultipleSelect(setActiveItems, setActiveItem, activeItems),
    [setActiveItems, activeItems],
  )

  const handleFiltersChange = useCallback(
    (data: any) => {
      setFilters(data)
    },
    [setFilters],
  )

  const filter = createFilterOptions<IOptionType>()

  const handleOrderChange = useCallback(
    (field: string) => {
      setOrderBy((order: { field: string; direction: string }) => ({
        field,
        direction: order.field === field ? (order.direction === 'DESC' ? 'ASC' : 'DESC') : 'ASC',
      }))
    },
    [setOrderBy],
  )

  const handleAddDebtor = useCallback(
    async (debtor: string) =>
      addEntityInfo({ name: debtor, type: 'debtor', clientName: currentClientName }),
    [addEntityInfo, currentClientName],
  )

  const activeTransactions = useMemo(
    () => [...new Set(bankTransactions?.data.filter((_, index) => activeItems.includes(index)))],
    [bankTransactions, activeItems],
  )

  const handleUpdateTransaction = useCallback(
    async (
      transactions: { cleanedDescription: string; category?: string; entityName?: string[] }[],
    ) => {
      if (readOnly) {
        return
      }
      if (transactions.length) {
        await updateTransactions({
          id,
          transactions,
          reportingFlow,
        })
      }
      refetchBankTransactions(true)
    },
    [updateTransactions, id, refetchBankTransactions, reportingFlow, readOnly],
  )

  const handleUpdateTransactionCategory = useCallback(
    (transaction: IBankTransaction, category: string) => {
      if (readOnly) {
        return
      }
      let transactionsToUpdate = []
      if (
        activeTransactions.length > 1 &&
        activeTransactions
          .map(({ cleanedDescription }) => cleanedDescription)
          .includes(transaction.cleanedDescription)
      ) {
        transactionsToUpdate = activeTransactions
      } else {
        transactionsToUpdate = [transaction]
      }
      handleUpdateTransaction(
        transactionsToUpdate.map((transaction) => ({
          cleanedDescription: transaction.cleanedDescription,
          category,
        })),
      )
    },
    [activeTransactions, handleUpdateTransaction, readOnly],
  )

  const handleSelectTransactionCategoryPrediction = useCallback(
    (transaction: IBankTransaction, category: string) => {
      if (readOnly) {
        return
      }
      handleUpdateTransaction([
        {
          cleanedDescription: transaction.cleanedDescription,
          category,
        },
      ])
    },
    [handleUpdateTransaction, readOnly],
  )

  const handleSelectTransactionCategoryPredictions = useCallback(async () => {
    if (readOnly) {
      return
    }
    handleUpdateTransaction(
      activeTransactions
        .map((transaction) => {
          const categoryPrediction = getCategoryPrediction(transaction.categoryPredictions)
          const category = categoryPrediction[0]?.category
          if (category && categoryMapping[category] !== transaction.category) {
            return {
              cleanedDescription: transaction.cleanedDescription,
              category: categoryMapping[category],
            }
          }
          return null
        })
        .filter(Boolean),
    )
  }, [activeTransactions, handleUpdateTransaction, readOnly])

  const handleUpdateTransactionEntityName = useCallback(
    async (
      transaction: IBankTransaction,
      entityName: { value: string; label: string; isNew: boolean }[],
    ) => {
      if (readOnly) {
        return
      }
      if (entityName[entityName.length - 1]?.isNew) {
        await handleAddDebtor(entityName[entityName.length - 1].value)
      }
      let transactionsToUpdate = []
      if (
        activeTransactions.length > 1 &&
        activeTransactions
          .map(({ cleanedDescription }) => cleanedDescription)
          .includes(transaction.cleanedDescription)
      ) {
        transactionsToUpdate = activeTransactions
      } else {
        transactionsToUpdate = [transaction]
      }
      handleUpdateTransaction(
        transactionsToUpdate.map((transaction) => ({
          cleanedDescription: transaction.cleanedDescription,
          entityName: [...new Set(entityName.map((entity) => entity.value))],
        })),
      )
      setEntityOptions([])
    },
    [handleAddDebtor, handleUpdateTransaction, activeTransactions, readOnly],
  )

  const handleSelectTransactionEntityNamePrediction = useCallback(
    async (transaction: IBankTransaction, entityName: string) => {
      if (readOnly) {
        return
      }
      await handleAddDebtor(entityName)
      const entityNameArr = Array(entityName)

      if (transaction.entityName) {
        entityNameArr.push(transaction.entityName)
      }
      if (transaction.intermediaryName) {
        entityNameArr.push(transaction.intermediaryName)
      }

      handleUpdateTransaction([
        {
          cleanedDescription: transaction.cleanedDescription,
          entityName: [...new Set(entityNameArr)],
        },
      ])
    },
    [handleUpdateTransaction, readOnly, handleAddDebtor],
  )

  const handleSelectTransactionEntityNamePredictions = useCallback(async () => {
    if (readOnly) {
      return
    }
    handleUpdateTransaction(
      activeTransactions
        .map((transaction) => {
          const entityNameArr = Array(transaction.entityPrediction)

          if (transaction.entityName) {
            entityNameArr.push(transaction.entityName)
          }
          if (transaction.intermediaryName) {
            entityNameArr.push(transaction.intermediaryName)
          }
          return {
            cleanedDescription: transaction.cleanedDescription,
            entityName: [...new Set(entityNameArr)],
          }
        })
        .filter(Boolean),
    )
  }, [activeTransactions, handleUpdateTransaction, readOnly])

  //@ts-ignore
  const typeOptionsSelect: { value: string; label: string }[] = useMemo(
    () =>
      (transactionType === 'Debit' ? TYPE_OPTIONS_DEBIT : TYPE_OPTIONS_CREDIT).filter(
        (option) => option?.label !== 'All',
      ),
    [transactionType],
  )
  //@ts-ignore
  const typeOptionsFilter: { value: string; label: string }[] = useMemo(
    () => (transactionType === 'Debit' ? TYPE_OPTIONS_DEBIT : TYPE_OPTIONS_CREDIT),
    [transactionType],
  )

  const initialValues = useMemo(
    () => ({
      bankTransactions: bankTransactions?.data?.map((transaction) => ({
        id: transaction.id,
        category: {
          label: typeOptionsSelect.filter(
            (typeOption) => typeOption.value === transaction.category,
          )[0]?.label,
          value:
            typeOptionsSelect.filter((typeOption) => typeOption.value === transaction.category)[0]
              ?.value || null,
        },
        entityName: transaction.entityName,
      })),
    }),
    [bankTransactions, typeOptionsSelect],
  )

  const totalRow = useMemo(
    () =>
      bankTransactions?.data
        ?.filter((_, index) => activeItems.includes(index))
        .reduce(
          (summary, row) => {
            summary.transactionAmount += +row.transactionAmount || 0

            return summary
          },
          {
            transactionAmount: 0,
          },
        ),
    [bankTransactions, activeItems],
  )

  const loadMore = useCallback(() => {
    fetchBankTransactionsList({
      loadMore: true,
      page: Math.ceil(bankTransactions?.data?.length / PER_PAGE),
      orderBy: orderBy.field,
      orderDirection: orderBy.direction,
      filters,
    })
  }, [bankTransactions, orderBy, filters, fetchBankTransactionsList])

  const loadDebtors = useCallback(
    async (inputValue: string) => {
      const res = await listEntityInfo({
        name: inputValue,
      })
      return res.data.map((entity) => ({
        id: entity.id,
        value: entity.name,
        label: entity.name,
        type: entity.type,
      }))
    },
    [listEntityInfo],
  )

  const loadEntities = useCallback(
    async (inputValue: string) => {
      const res = await listEntityInfo({
        name: inputValue,
      })
      return res.data.map(({ name }) => ({
        value: name,
        label: name,
      }))
    },
    [listEntityInfo],
  )

  const filtersConfig = useMemo(
    () =>
      BANK_TRANSACTIONS_LIST_FILTERS_CONFIG.map((item) => ({
        ...item,
        loadOptions: ['entityName', 'intermediarynName'].includes(item.field)
          ? loadEntities
          : undefined,
        options:
          item.field === 'bankAccountId'
            ? bankTransactions?.bankAccounts?.map((bank) => ({
                value: bank.id,
                label: `${bank.bankName} - ${bank.bankAccountNumber.slice(-4)}`,
              }))
            : item.field === 'category' || item.field === 'categoryPredictions'
            ? typeOptionsFilter
            : item.options,
      })),
    [bankTransactions, typeOptionsFilter, loadEntities],
  )

  const getEntityValues = (transaction: IBankTransaction) => {
    const entityValue = {
      id: transaction.id,
      label: transaction.entityName,
      value: transaction.entityName,
      type: 'entity',
    }
    const intermediaryValue = {
      id: transaction.id,
      label: transaction.intermediaryName,
      value: transaction.intermediaryName,
      type: 'intermediary',
    }
    return [entityValue, intermediaryValue].filter((entity) => entity?.value)
  }

  const handleInputChange = useCallback(
    async (
      event: React.ChangeEvent<{}>,
      inputValue: string,
      reason: 'input' | 'reset' | 'clear',
    ) => {
      if (reason === 'input') {
        if (inputValue.length > 1) {
          const loadedOptions = await loadDebtors(inputValue)
          setEntityOptions(loadedOptions)
        } else {
          setEntityOptions([])
        }
      }
    },
    [loadDebtors],
  )
  const debounceHandleInputChange = useMemo(
    () => debounceEventHandler(handleInputChange, 500),
    [handleInputChange],
  )

  return (
    <Form
      onSubmit={voidHandler}
      initialValues={initialValues}
      mutators={mutators}
      render={({ form }: { form: any }) => (
        <TableContainer
          className={cn(styles.table, tableClassName)}
          isActivable
          onActiveRowsChange={setActiveItems}
          onActiveRowChange={setActiveItem}
        >
          <Form
            mutators={{
              setFieldData: ([field, value], state, { changeValue }) => {
                changeValue(state, field, () => value)
              },
            }}
            validate={filtersValidate}
            onSubmit={handleFiltersChange}
            initialValues={filters}
            render={({
              values: filterValues,
              handleSubmit: handleFilterSubmit,
              form: { mutators: filterMutators },
            }) => (
              <FilterContainer
                filters={filtersConfig}
                handleSubmit={handleFilterSubmit}
                mutators={filterMutators}
                values={filterValues}
                appliedFilters={filters}
                actions={
                  !readOnly &&
                  activeItems.length > 1 && (
                    <Box display="flex" alignItems="center" gap={1}>
                      <Button
                        color="primary"
                        variant="outlined"
                        onClick={handleSelectTransactionCategoryPredictions}
                        small={false}
                      >
                        Category Prediction
                      </Button>
                      <Button
                        color="primary"
                        variant="outlined"
                        onClick={handleSelectTransactionEntityNamePredictions}
                        small={false}
                      >
                        Entity Prediction
                      </Button>
                    </Box>
                  )
                }
              />
            )}
          />
          <Table>
            <TableHead>
              <TableFiltersRow
                filters={filtersConfig}
                orderBy={orderBy}
                handleOrderChange={handleOrderChange}
                isChildrenAtStart
              >
                <TableCell />
              </TableFiltersRow>
            </TableHead>
            <TableBody
              id="scrollableMappingTable"
              className={cn(isModalShown ? styles.inModalTableBody : styles.fullWidthTableBody)}
            >
              {isLoading ? (
                <TableLoader columnsCount={7} />
              ) : (
                bankTransactions?.data && (
                  <InfiniteScroll
                    dataLength={bankTransactions?.data?.length}
                    next={loadMore}
                    hasMore={bankTransactions?.data?.length < bankTransactions?.totalCount}
                    loader={<TableLoader columnsCount={7} rowsCount={1} />}
                    scrollableTarget="scrollableMappingTable"
                  >
                    <FieldArray name="bankTransactions">
                      {({ fields }) => (
                        <>
                          {fields.map((name, index) => {
                            const transaction = bankTransactions.data[index]
                            if (!transaction) {
                              return null
                            }
                            const isExpanded = expanded.includes(transaction.cleanedDescription)
                            const entityTagValue = getEntityValues(transaction)
                            return (
                              <React.Fragment key={transaction.cleanedDescription}>
                                <TableRow
                                  id={`mapping-table-row-${index}`}
                                  data-index={index}
                                  className={cn('activableRow', {
                                    activeRow: activeItems.includes(index),
                                    currentActiveRow: activeItem === index,
                                  })}
                                  onClick={(event) => handleSelectRow(event, index)}
                                >
                                  <TableCell className={genericSs.tableTextCenter}>
                                    <ExpandDetailIcon
                                      onClick={() => handleExpand(transaction.cleanedDescription)}
                                      isExpanded={isExpanded}
                                    />
                                  </TableCell>
                                  <TableCell className={genericSs.tableTextLeft}>
                                    <Tooltip
                                      title={transaction.cleanedDescription}
                                      placement="top"
                                      disableTouchListener
                                    >
                                      <div>{transaction.cleanedDescription}</div>
                                    </Tooltip>
                                  </TableCell>
                                  <TableCell className={genericSs.tableTextRight}>
                                    ${formatPrice(transaction.transactionAmount)}
                                  </TableCell>
                                  <TableCell>
                                    <CreatableSelectField
                                      label=""
                                      className="focusableInput"
                                      tabIndex={index}
                                      name={`${name}.category`}
                                      placeholder="Start typing category"
                                      onAddValue={(e) => e}
                                      onChangeCustom={async (event, newValue: any) => {
                                        handleUpdateTransactionCategory(
                                          transaction,
                                          newValue ? newValue.value : 'DELETE',
                                        )
                                        form.reset()
                                      }}
                                      options={typeOptionsSelect}
                                      getOptionValue={(option) => option.value}
                                      getOptionLabel={(option) => option.label}
                                      disabled={readOnly}
                                    />
                                  </TableCell>
                                  <TableCell className={genericSs.tableTextLeft}>
                                    {!transaction.category &&
                                      getCategoryPrediction(transaction.categoryPredictions).map(
                                        (item, index) => {
                                          const categoryPrediction = typeOptionsSelect.filter(
                                            (predictionObj) =>
                                              snakeToCamelCase(predictionObj.value) ===
                                              item.category,
                                          )[0]
                                          if (!categoryPrediction) {
                                            return null
                                          }
                                          return (
                                            <div
                                              key={`${index}-${transaction.cleanedDescription}-${item.category}`}
                                              className={
                                                mappingLegendStyles[
                                                  `${predictionCardLevel[`${item.confidence}`]}`
                                                ]
                                              }
                                              onClick={() => {
                                                handleSelectTransactionCategoryPrediction(
                                                  transaction,
                                                  categoryPrediction?.value,
                                                )
                                                form.reset()
                                              }}
                                            >
                                              {categoryPrediction?.label}
                                            </div>
                                          )
                                        },
                                      )}
                                  </TableCell>
                                  <TableCell>
                                    <TaggingField
                                      disabled={readOnly}
                                      className={styles.taggingField}
                                      placeholder={
                                        entityTagValue?.length > 0 ? '' : 'Start typing entity'
                                      }
                                      renderTags={(tagValue, getTagProps) => {
                                        return tagValue.map((option, index) => (
                                          <Tooltip
                                            key={option.value}
                                            title={option.value}
                                            placement={'top'}
                                          >
                                            <Chip
                                              {...getTagProps({ index })}
                                              className={cn(
                                                styles.chip,
                                                option.type === 'intermediary'
                                                  ? mappingLegendStyles.predictionChipIntermediary
                                                  : mappingLegendStyles.predictionChipEntity,
                                              )}
                                              label={option.value}
                                              size="small"
                                            />
                                          </Tooltip>
                                        ))
                                      }}
                                      options={entityOptions}
                                      limitTags={2}
                                      onChange={(event, newValue) =>
                                        handleUpdateTransactionEntityName(
                                          transaction,
                                          // @ts-ignore
                                          newValue || null,
                                        )
                                      }
                                      isFreeSolo={true}
                                      // @ts-ignore
                                      value={entityTagValue}
                                      filterOptions={(currentOptions, params) => {
                                        // @ts-ignore
                                        const filtered = filter(currentOptions, params)
                                        if (
                                          params.inputValue !== '' &&
                                          filtered.findIndex(
                                            (option) => option.value === params.inputValue,
                                          ) === -1
                                        ) {
                                          filtered.push({
                                            value: params.inputValue,
                                            label: `Add "${params.inputValue}"`,
                                            isNew: true,
                                          })
                                        }

                                        return filtered
                                      }}
                                      // @ts-ignore
                                      onInputChange={debounceHandleInputChange}
                                    ></TaggingField>
                                  </TableCell>

                                  <TableCell className={genericSs.tableTextLeft}>
                                    {transaction.entityPrediction && !transaction.entityName ? (
                                      <div
                                        className={cn(mappingLegendStyles.predictionCardDebtor, {
                                          [mappingLegendStyles.predictionCardNewValue]:
                                            !transaction.entityPredictionId,
                                        })}
                                        onClick={() => {
                                          handleSelectTransactionEntityNamePrediction(
                                            transaction,
                                            transaction.entityPrediction,
                                          )
                                          form.reset()
                                        }}
                                      >
                                        {transaction.entityPredictionId ? (
                                          transaction.entityPrediction
                                        ) : (
                                          <Tooltip
                                            title="Create new entity"
                                            placement="top"
                                            disableTouchListener
                                          >
                                            <span>+ {transaction.entityPrediction}</span>
                                          </Tooltip>
                                        )}
                                      </div>
                                    ) : (
                                      ''
                                    )}
                                  </TableCell>
                                </TableRow>
                                {isExpanded && (
                                  <TableRow>
                                    <TableCell className={genericSs.nestedRowColumn} colSpan={7}>
                                      <BankTransactionsMappingDetailRow
                                        isModalShown={isModalShown}
                                        cleanedDescription={transaction.cleanedDescription}
                                        bankTransactionDetail={transaction.rows}
                                        listBankTransactionDetail={handleListBankTransactionDetail}
                                      />
                                    </TableCell>
                                  </TableRow>
                                )}
                              </React.Fragment>
                            )
                          })}
                        </>
                      )}
                    </FieldArray>
                  </InfiniteScroll>
                )
              )}
            </TableBody>
          </Table>

          <ActiveToolbar
            isFullscreen={isModalShown}
            activeItems={activeItems}
            className={styles.activeToolbar}
          >
            <div className={genericSs.tableTextRight}>
              ${formatPrice(totalRow?.transactionAmount)}
            </div>
          </ActiveToolbar>

          <Box mt={3} display="flex" justifyContent="space-between">
            {bankTransactions?.totalCount > 0 && (
              <div className={styles.itemsCount}>
                {bankTransactions.data.length} / {bankTransactions.totalCount}
              </div>
            )}

            <SaveState isSaving={isSaving} isSaved={isSaved} />
          </Box>
        </TableContainer>
      )}
    />
  )
}

export default BankTransactionsMapping
