import ProspectFileSelect from './ProspectFileSelect'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'

import { IRootState } from '../../redux/reducer'
import { isLoading as prospectIsLoading, reporting } from '../../redux/prospect/selectors'
import { updateReporting, startReporting } from '../../redux/prospect/actions'
import { deleteFile } from '../../redux/file/actions'
import { isLoading as fileIsLoading } from '../../redux/file/selectors'
import { downloadTemplate } from '../../redux/bbc/actions'

const isLoading = (state: IRootState) => prospectIsLoading(state) || fileIsLoading(state)

const selector = createStructuredSelector({
  reporting,
  isLoading,
})
const actions = {
  startReporting,
  updateReporting,
  deleteFile,
  downloadTemplate,
}

export default connect(selector, actions)(ProspectFileSelect)
