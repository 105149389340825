import React, { useState, useCallback, useMemo } from 'react'
import moment from 'moment'
import { Form } from 'react-final-form'
import { makeValidate } from 'mui-rff'
import * as Yup from 'yup'
import Box from '@mui/material/Box'

import genericSs from '@styles/generic.module.scss'
import styles from './FinancialEditDatesModal.module.scss'

import Modal from '../Common/Modal'
import ModalButton from '../Common/Button/Button'
import KeyboardDatePicker from '../Common/KeyboardDatePicker'
import { DATE_FORMAT } from '../../constants/common'
import InputLabel from '../Common/InputLabel'
import { EditIcon } from '../Common/Icons'

const schema = Yup.object().shape({
  startDate: Yup.date().typeError('Please type date in MM/DD/YY format').required('Required'),
  endDate: Yup.date()
    .typeError('Please type date in MM/DD/YY format')
    .when('startDate', (startDate: any, validation: any) =>
      startDate && startDate instanceof Date && !isNaN(+startDate)
        ? validation.min(startDate, 'End date should be prior to the from date')
        : validation,
    ),
})
const validate = makeValidate(schema)

interface IProps {
  startDate: string
  endDate: string
  handleSave: (dates: any) => Promise<void>
}

const FinancialEditDatesModal = ({ startDate, endDate, handleSave }: IProps) => {
  const [isSaving, setIsSaving] = useState(false)
  const [isModalShown, setIsModalShown] = useState(false)

  const handleEdit = useCallback(() => {
    setIsModalShown(true)
  }, [])

  const handleCancel = useCallback(() => {
    setIsModalShown(false)
  }, [])

  const handleSaveActive = useCallback(
    async (data) => {
      setIsSaving(true)
      await handleSave({
        startDate: moment(data.startDate).format('YYYY-MM-DD'),
        endDate: moment(data.endDate).format('YYYY-MM-DD'),
      })
      setIsSaving(false)
      setIsModalShown(false)
    },
    [handleSave],
  )

  const initialValues = useMemo(
    () => ({
      startDate,
      endDate,
    }),
    [startDate, endDate],
  )

  return (
    <>
      <EditIcon onClick={handleEdit} />

      {isModalShown && (
        <Modal open onCancel={handleCancel} title="Edit financials dates">
          <Form
            initialValues={initialValues}
            onSubmit={handleSaveActive}
            validate={validate}
            render={({
              invalid,
              pristine,
              handleSubmit,
            }: {
              values: any
              invalid: boolean
              pristine: boolean
              handleSubmit: any
            }) => (
              <form className={genericSs.textLeft} onSubmit={handleSubmit}>
                <Box mt={2}>
                  <InputLabel htmlFor="link">Start Date</InputLabel>
                  <KeyboardDatePicker
                    name="startDate"
                    inputFormat={DATE_FORMAT}
                    className={styles.dateField}
                  />
                </Box>

                <Box mt={2}>
                  <InputLabel htmlFor="link">End Date</InputLabel>
                  <KeyboardDatePicker
                    name="endDate"
                    inputFormat={DATE_FORMAT}
                    className={styles.dateField}
                  />
                </Box>

                <Box display="flex" alignItems="center" justifyContent="space-around" mt={2}>
                  <ModalButton color="primary" variant="outlined" onClick={handleCancel} secondary>
                    Cancel
                  </ModalButton>
                  <ModalButton
                    isLoading={isSaving}
                    color="primary"
                    variant="contained"
                    onClick={handleSubmit}
                    disabled={invalid || pristine}
                  >
                    Confirm
                  </ModalButton>
                </Box>
              </form>
            )}
          />
        </Modal>
      )}
    </>
  )
}

export default FinancialEditDatesModal
