import React from 'react'
import { Radios as MuiRadios, RadiosProps } from 'mui-rff'

import styles from './BooleanRadios.module.scss'

const DATA = [
  { label: 'Yes', value: 'true' },
  { label: 'No', value: 'false' },
]

const BooleanRadios = (props: Omit<RadiosProps, 'data'> & { className?: string }) => {
  return (
    <MuiRadios
      {...props}
      classes={{ root: styles.root, checked: styles.rootChecked }}
      radioGroupProps={{
        row: true,
        classes: { root: props.className },
      }}
      formControlLabelProps={{
        classes: {
          root: styles.label,
        },
      }}
      data={DATA}
    />
  )
}

export default BooleanRadios
