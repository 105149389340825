import React from 'react'

import styles from './Header.module.scss'

interface IProps {
  title: string
  description: string
}

const Header = ({ title, description }: IProps) => {
  return (
    <div className={styles.wrapper}>
      <div className={styles.title}>{title}</div>
      <div className={styles.description}>{description}</div>
      <div className={styles.divider} />
    </div>
  )
}

export default Header
