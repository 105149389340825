import { setBoxViewerLink } from './../../redux/box/actions'
import { user } from './../../redux/profile/selectors'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'

import RequestFunding from './RequestFunding'

import { clientInfo } from '../../redux/profile/selectors'
import {
  getRequestFunding,
  requestFunding,
  cancelFunding,
  modifyFunding,
} from '../../redux/clientInfo/actions'
import { deleteFile } from '../../redux/file/actions'
import { fundingRequest } from '../../redux/clientInfo/selectors'

const selector = createStructuredSelector({
  clientInfo,
  fundingRequest,
  user,
})
const actions = {
  getRequestFunding,
  requestFunding,
  cancelFunding,
  modifyFunding,
  deleteFile,
  setBoxViewerLink,
}

export default connect(selector, actions)(RequestFunding)
