import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'

import BBCIneligibleARDetails from './BBCIneligibleARDetails'

import { clientCollaterals } from '../../redux/bbc/selectors'
import { listArIneligibleStatsDetails } from '../../redux/bbc/actions'

const selector = createStructuredSelector({
  clientCollaterals,
})

const actions = {
  listArIneligibleStatsDetails,
}

export default connect(selector, actions)(BBCIneligibleARDetails)
