import { useEffect, useMemo, useRef } from 'react'
import { useDispatch } from 'react-redux'

import { trackVisualizations } from '../redux/visualizationsTracking/actions'
import { EVENTS } from '@common/constants/tracking'
import { debounceEventHandler } from '../helpers/helpers'

interface IProps {
  isInitialized?: boolean
  isChart: boolean
  category: string
  params: object
}

const useTrackVisualizationsTableChartSelection = ({
  isInitialized = true,
  isChart,
  category,
  params,
}: IProps) => {
  const isInitializedRef = useRef(isInitialized)
  const categoryRef = useRef(category)

  useEffect(() => {
    isInitializedRef.current = isInitialized
  }, [isInitialized])

  useEffect(() => {
    categoryRef.current = category
  }, [category])

  const dispatch = useDispatch()

  const trackVisualizationChange = useMemo(
    () =>
      debounceEventHandler((isGraph: boolean) => {
        dispatch(
          trackVisualizations(EVENTS.tableChart, categoryRef.current, {
            ...(params || {}),
            data: {
              visualization: isGraph ? 'Chart' : 'Table',
            },
          }),
        )
      }, 500),
    [dispatch, params],
  )

  useEffect(() => {
    if (isInitializedRef.current) {
      trackVisualizationChange(isChart)
    }
  }, [trackVisualizationChange, isChart])

  return {}
}

export default useTrackVisualizationsTableChartSelection
