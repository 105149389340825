import { connect } from 'react-redux'
import { trackLinkClick } from '../../../redux/user/actions'

import ExternalLink from './ExternalLink'

const actions = {
  trackLinkClick,
}

export default connect(null, actions)(ExternalLink)
