import React, { useCallback, useRef } from 'react'

import Autocomplete, { IProps } from '../Autocomplete'

const AutocompletePlace = (
  props: IProps & { onSelect: (address: any) => void; placePredictionsOptions?: any },
) => {
  const { onSelect, placePredictionsOptions, ...rest } = props
  const autocompleteService = useRef(null)
  const placeService = useRef(null)

  const loadOptions = useCallback(
    async (input: string) => {
      if (!autocompleteService.current && (window as any).google) {
        autocompleteService.current = new (window as any).google.maps.places.AutocompleteService()
      }

      const { predictions } = await autocompleteService.current.getPlacePredictions({
        input,
        componentRestrictions: { country: 'us' },
        ...(placePredictionsOptions || {}),
      })

      return predictions.map(
        ({
          place_id,
          structured_formatting,
        }: {
          place_id: string
          structured_formatting: { main_text: string; secondary_text: string }
        }) => ({
          value: place_id,
          label: `${structured_formatting.main_text}, ${structured_formatting.secondary_text}`,
        }),
      )
    },
    [placePredictionsOptions],
  )

  const handleChange = useCallback(
    async (event, data) => {
      if (!data) {
        onSelect(null)
        return
      }
      if (!placeService.current && (window as any).google) {
        const map = new (window as any).google.maps.Map(document.querySelector('#google-map'))
        placeService.current = new (window as any).google.maps.places.PlacesService(map)
      }
      placeService.current.getDetails(
        { placeId: data.value, fields: ['name', 'address_components'] },
        (place: {
          address_components: Array<{ long_name: string; short_name: string; types: string[] }>
        }) => {
          if (!place) {
            onSelect(null)
            return
          }
          const { address_components } = place
          onSelect({
            country: address_components.find(({ types }) => types.includes('country'))?.long_name,
            state: address_components.find(({ types }) =>
              types.includes('administrative_area_level_1'),
            )?.long_name,
            city:
              address_components.find(({ types }) => types.includes('locality'))?.long_name ||
              address_components.find(({ types }) => types.includes('sublocality'))?.long_name,
            postalCode: address_components.find(({ types }) => types.includes('postal_code'))
              ?.long_name,
            street: address_components
              .filter(({ types }) => types.includes('route') || types.includes('street_number'))
              .map(({ long_name }) => long_name)
              .filter(Boolean)
              .join(' '),
          })
        },
      )
    },
    [onSelect],
  )

  return (
    <Autocomplete
      isAsync
      loadOptions={loadOptions}
      onChange={handleChange}
      freeSolo
      data-cy="address"
      {...rest}
    />
  )
}

export default AutocompletePlace
