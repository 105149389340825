import FlagTitle from './FlagTitle'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import { addNote } from '../../../redux/notes/actions'
import { pathname } from '../../../redux/router/selectors'
import { updateFlag } from '../../../redux/flag/actions'

const selector = createStructuredSelector({
  pathname,
})
const actions = {
  addNote,
  updateFlag,
}

export default connect(selector, actions)(FlagTitle)
