import React from 'react'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'

import ClientSetupHeader from '../../components/ClientSetupHeader'
import BBCApIneligibilityTable from '../../components/BBCApIneligibilityTable'
const ClientSetupCollateralPayablePage = () => {
  return (
    <Box py={1} pr={2}>
      <ClientSetupHeader />

      <Box>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <BBCApIneligibilityTable />
          </Grid>
        </Grid>
      </Box>
    </Box>
  )
}

export default ClientSetupCollateralPayablePage
