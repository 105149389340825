import React, { useCallback, useState } from 'react'
import cn from 'classnames'

import styles from './ClientLoanLedger.module.scss'
import genericSs from '@styles/generic.module.scss'

import TableRow from '../../Common/TableRow'
import FormattedTableCell from '../../Common/FormattedTableCell'
import TableCell from '../../Common/TableCell'
import { IAccountActivity } from '@common/interfaces/client'
import ClientAccountActivityAction from '../ClientAccountActivity/ClientAccountActivityAction'
import { usePermissions } from '../../../helpers/permissionContext'
import ClientAccountActivityEditModal from '../ClientAccountActivityEditModal'
import ClientAccountActivityDeleteModal from '../ClientAccountActivityDeleteModal'
import ClientAccountActivityRestoreModal from '../ClientAccountActivityRestoreModal'

interface LoanLedgerWiresTableAccountActivityRowProps {
  clientId: string
  accountActivity: IAccountActivity
}

export const LoanLedgerWiresTableAccountActivityRow = ({
  clientId,
  accountActivity,
}: LoanLedgerWiresTableAccountActivityRowProps) => {
  const [isEditModalShown, setIsEditModalShown] = useState(false)
  const [isDeleteModalShown, setIsDeleteModalShown] = useState(false)
  const [isRestoreModalShown, setIsRestoreModalShown] = useState(false)

  const { isAdminRightsRole } = usePermissions()

  const handleEdit = useCallback((item: any) => {
    setIsEditModalShown(true)
  }, [])

  const handleDelete = useCallback(() => {
    setIsDeleteModalShown(true)
  }, [])

  const handleRestore = useCallback(() => {
    setIsRestoreModalShown(true)
  }, [])

  const handleModalConfirm = useCallback(() => {
    setIsEditModalShown(false)
    setIsDeleteModalShown(false)
    setIsRestoreModalShown(false)
  }, [])

  const handleModalCancel = useCallback(() => {
    setIsEditModalShown(false)
    setIsDeleteModalShown(false)
    setIsRestoreModalShown(false)
  }, [])

  return (
    <TableRow
      key={accountActivity.id}
      className={cn({
        [styles.deletedRow]: accountActivity.isDeleted,
      })}
    >
      <TableCell className={genericSs.tableTextLeft}>{accountActivity.type}</TableCell>
      <TableCell className={genericSs.tableTextLeft}>{accountActivity.description}</TableCell>
      <FormattedTableCell
        value={accountActivity.amount}
        fieldType="amount"
        nullCondition={!accountActivity.amount}
      />
      <TableCell className={genericSs.tableTextRight}>
        {isAdminRightsRole && (
          <ClientAccountActivityAction
            item={accountActivity}
            onEdit={handleEdit}
            onDelete={handleDelete}
            onRestore={handleRestore}
          />
        )}

        {isEditModalShown && (
          <ClientAccountActivityEditModal
            item={accountActivity}
            handleCancel={handleModalCancel}
            handleConfirm={handleModalConfirm}
          />
        )}

        {isDeleteModalShown && (
          <ClientAccountActivityDeleteModal
            activeItems={[accountActivity]}
            handleCancel={handleModalCancel}
            handleConfirm={handleModalConfirm}
          />
        )}

        {isRestoreModalShown && (
          <ClientAccountActivityRestoreModal
            activeItems={[accountActivity]}
            handleCancel={handleModalCancel}
            handleConfirm={handleModalConfirm}
          />
        )}
      </TableCell>
    </TableRow>
  )
}
