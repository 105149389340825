import React, { useState, useCallback } from 'react'
import { Form } from 'react-final-form'
import Box from '@mui/material/Box'
import { makeValidate } from 'mui-rff'
import * as Yup from 'yup'

import styles from './AdditionalFees.module.scss'
import genericSs from '@styles/generic.module.scss'
import {
  FeeType,
  FeeTypeCodeMapping,
  miscOptions,
  passthroughOptions,
} from '@common/interfaces/loanServicing'
import { IClientPayoffFee } from '@common/interfaces/client'

import CurrencyField from '../../components/Common/CurrencyField'
import Card from '../../components/Common/Card'
import TableContainer from '../../components/Common/TableContainer/TableContainer'
import Table from '../../components/Common/Table/Table'
import TableHead from '../../components/Common/TableHead/TableHead'
import TableRow from '../../components/Common/TableRow/TableRow'
import TableCell from '../../components/Common/TableCell/TableCell'
import TableBody from '../../components/Common/TableBody/TableBody'
import AddButton from '../../components/Client/AddButton'
import Modal from '../../components/Common/Modal'
import Button from '../../components/Common/Button'
import InputLabel from '../../components/Common/InputLabel'
import TableFields from '../../components/TableFields'
import SelectField from '../../components/Common/SelectField'
import TextField from '../../components/Common/TextField'
import MenuItem from '@mui/material/MenuItem'
import Menu from '@mui/material/Menu'
import WarningModal from '../../components/WarningModal'
import { MenuIcon } from '../../components/Common/Icons'

const schema = Yup.object().shape({
  amount: Yup.string().required('Required'),
  feeType: Yup.string().required('Required'),
})

const validate = makeValidate(schema)

const feeOptions = [
  {
    value: FeeType.PassThroughs,
    label: `${FeeType.PassThroughs} (${FeeTypeCodeMapping[FeeType.PassThroughs]})`,
  },
  {
    value: FeeType.Miscellaneous,
    label: `${FeeType.Miscellaneous} (${FeeTypeCodeMapping[FeeType.Miscellaneous]})`,
  },
  {
    value: FeeType.ManualInterest,
    label: `Interest (${FeeTypeCodeMapping[FeeType.ManualInterest]})`,
  },
]

interface IProps {
  handleSaveAdditionalFees: (values: object) => void
  handleDeleteFees: (feeIndex: number) => void
  isButtonLoading: { type: string; value: boolean }
  totalFees: IClientPayoffFee[]
  disabled?: boolean
}

const AdditionalFees = ({
  handleSaveAdditionalFees,
  handleDeleteFees,
  isButtonLoading,
  totalFees,
  disabled = false,
}: IProps) => {
  const [isAddModalShown, setIsAddModalShown] = useState(false)
  const [anchorEl, setAnchorEl] = useState(null)
  const [actionsMenuOpen, setActionsMenuOpen] = useState(false)
  const [selectedRow, setSelectedRow] = useState(null)
  const [isDeleteModalShown, setIsDeleteModalShown] = useState<boolean>(false)

  const handleClickMenu = useCallback((feeIndex, event: React.MouseEvent<HTMLElement>) => {
    setSelectedRow(feeIndex)
    setAnchorEl(event.currentTarget)
    setActionsMenuOpen(true)
  }, [])
  const handleCloseMenu = useCallback(() => {
    setSelectedRow(null)
    setAnchorEl(null)
    setActionsMenuOpen(false)
  }, [])

  const closeDeleteModal = useCallback(() => {
    setIsDeleteModalShown(false)
    handleCloseMenu()
  }, [handleCloseMenu])

  const handleClickAddButton = useCallback(() => {
    setIsAddModalShown(true)
  }, [setIsAddModalShown])

  const handleDeleteMenuClick = useCallback(() => {
    setIsDeleteModalShown(true)
  }, [setIsDeleteModalShown])

  const handleConfirmDelete = useCallback(async () => {
    await handleDeleteFees(selectedRow)
    handleCloseMenu()
    setIsDeleteModalShown(false)
  }, [handleDeleteFees, handleCloseMenu, setIsDeleteModalShown, selectedRow])

  return (
    <div className={styles.reportingContainer}>
      <div className={styles.headerContainer}></div>
      <Box
        className={styles.tableContainer}
        display="flex"
        justifyContent="space-between"
        alignItems="center"
      >
        <div className={genericSs.cardTitle}>Additional fees</div>
        <AddButton
          variant="outlined"
          onClick={handleClickAddButton}
          disabled={disabled}
        ></AddButton>
      </Box>
      <Box flex={1}>
        <Card withBorder={false} className={styles.card} noPadding noHeaderMargin>
          <Card noPadding withBorder={false} noHeaderMargin>
            <Form<any>
              onSubmit={handleSaveAdditionalFees}
              validate={validate}
              keepDirtyOnReinitialize
              render={({ dirty, submitting, invalid, form, handleSubmit }) => (
                <form>
                  <Modal
                    open={isAddModalShown}
                    onCancel={() => setIsAddModalShown(false)}
                    size="small"
                    title="Add fees"
                    classes={{
                      footer: styles.editModalFooter,
                      title: styles.editModalTitle,
                    }}
                    footer={[
                      <Button
                        key="submit"
                        color="primary"
                        small={false}
                        variant="contained"
                        disabled={!dirty || invalid || submitting}
                        className={styles.submitButton}
                        isLoading={isButtonLoading?.type === 'saveFees' && isButtonLoading?.value}
                        onClick={(data) => {
                          handleCloseMenu()
                          handleSubmit(data).then(() => {
                            form.change('feeType', null)
                            form.change('amount', null)
                            form.change('title', null)
                            setIsAddModalShown(false)
                          })
                        }}
                      >
                        Add fee
                      </Button>,
                    ]}
                  >
                    <Box className={styles.fieldsContainer}>
                      <TableFields
                        classes={{ root: styles.editFields }}
                        rows={[
                          [
                            <InputLabel
                              key="feeTypeLabel"
                              htmlFor="feeType"
                              size="normal"
                              position="horizontal"
                            >
                              Fee type
                            </InputLabel>,
                            <SelectField key="feeTypeInput" name="feeType" options={feeOptions} />,
                          ],
                          String(form?.getState().values?.feeType) === FeeType.PassThroughs ||
                          String(form?.getState().values?.feeType) === FeeType.Miscellaneous
                            ? [
                                <InputLabel
                                  key="titleLabel"
                                  htmlFor="titleType"
                                  size="normal"
                                  position="horizontal"
                                >
                                  Fee description
                                </InputLabel>,
                                <SelectField
                                  key="titleInput"
                                  name="title"
                                  options={
                                    String(form?.getState().values?.feeType) ===
                                    FeeType.PassThroughs
                                      ? passthroughOptions
                                      : miscOptions
                                  }
                                />,
                              ]
                            : String(form?.getState().values?.feeType) === FeeType.ManualInterest
                            ? [
                                <InputLabel
                                  key="titleLabel"
                                  htmlFor="titleType"
                                  size="normal"
                                  position="horizontal"
                                >
                                  Fee description
                                </InputLabel>,
                                <TextField key="titleInput" name={'title'} />,
                              ]
                            : [],
                          [
                            <InputLabel
                              key="amountLabel"
                              htmlFor="amount"
                              size="normal"
                              position="horizontal"
                            >
                              Amount
                            </InputLabel>,
                            <CurrencyField key="amountInput" name="amount" />,
                          ],
                        ]}
                      />
                    </Box>
                  </Modal>
                </form>
              )}
            />
          </Card>

          <Box>
            <Card withBorder={false} noPadding noHeaderMargin>
              <TableContainer className={styles.table}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell className={genericSs.tableTextLeft}>Type</TableCell>
                      <TableCell className={genericSs.tableTextLeft}>Description</TableCell>
                      <TableCell className={genericSs.tableTextLeft}>Amount</TableCell>
                      <TableCell className={genericSs.tableTextLeft}>Action</TableCell>
                    </TableRow>
                  </TableHead>
                  {totalFees.length > 0 && (
                    <TableBody>
                      {totalFees.map((fee: IClientPayoffFee, index) => (
                        <TableRow>
                          <TableCell className={genericSs.tableTextLeft}>
                            {fee.type === FeeType.ManualInterest ? 'Interest' : fee.type}
                          </TableCell>
                          <TableCell className={genericSs.tableTextLeft}>{fee.title}</TableCell>
                          <TableCell className={genericSs.tableTextLeft}>{fee.amount}</TableCell>
                          <TableCell className={genericSs.tableTextLeft}>
                            <Box display="inline-box" ml={1}>
                              {!disabled && (
                                <MenuIcon
                                  onClick={(event) => handleClickMenu(index, event)}
                                  size="small"
                                />
                              )}
                            </Box>
                          </TableCell>
                        </TableRow>
                      ))}
                      <Menu
                        open={actionsMenuOpen}
                        anchorEl={anchorEl}
                        onClose={handleCloseMenu}
                        className={styles.actionsMenu}
                      >
                        <MenuItem onClick={handleDeleteMenuClick}>Delete</MenuItem>
                      </Menu>
                    </TableBody>
                  )}
                  {isDeleteModalShown && (
                    <WarningModal
                      onCancel={closeDeleteModal}
                      onConfirm={handleConfirmDelete}
                      warningMessage={`Deleting this fee will be permanent`}
                      isLoading={isButtonLoading?.type === 'deleteFee' && isButtonLoading?.value}
                    />
                  )}
                </Table>
              </TableContainer>
            </Card>
          </Box>
        </Card>
      </Box>
    </div>
  )
}

export default AdditionalFees
