import Base from './base'

export default class Fee extends Base {
  create(data: object) {
    return this.apiClient.post('fees', data)
  }

  update(id: string, data: object) {
    return this.apiClient.put(`fees/${id}`, data)
  }

  restore(id: string) {
    return this.apiClient.patch(`fees/${id}`)
  }

  delete(id: string) {
    return this.apiClient.delete(`fees/${id}`)
  }
}
