import { ICheck } from '@common/interfaces/collection'
import { Paper, Skeleton } from '@mui/material'
import React, { useEffect, useMemo, useRef, useState } from 'react'
import styles from '../CollectionsChecksPage.module.scss'
import { ReactComponent as NavigateNext } from '../../../assets/images/next-arrow.svg'
import { ReactComponent as NavigateBefore } from '../../../assets/images/prev-arrow.svg'
import Carousel from 'react-material-ui-carousel'
import imageStore from '../../../helpers/imageStore'
import { retryPromise } from '@common/helpers/helpers'
import TableContainer from '../../../components/Common/TableContainer'
import Table from '../../../components/Common/Table'
import TableHead from '../../../components/Common/TableHead'
import TableRow from '../../../components/Common/TableRow'
import TableCell from '../../../components/Common/TableCell'
import TableBody from '../../../components/Common/TableBody'
import { EntitySubType } from '@common/interfaces/entityInfo'
import { formatPrice } from '../../../helpers/helpers'
import { IOptionType } from '../../../components/Common/CreatableSelectField'
import { ClientNameDropdown, EntityNameDropdown } from './CheckRow'
import cn from 'classnames'
import genericSs from '@styles/generic.module.scss'
const CheckImage = ({
  check,
  shouldLoad,
  handleLoadCheckImage,
  isActiveCheck,
  index,
  handleUpdateCheck,
  value,
  name,
  clientsOptions,
  loadDebtors,
  handleAddDebtor,
}: {
  check: ICheck
  shouldLoad?: boolean
  handleLoadCheckImage: (id: string) => Promise<string>
  index: number
  isActiveCheck: boolean
  handleUpdateCheck: (check: object) => void
  value: any
  name: string
  clientsOptions: IOptionType[]
  loadDebtors: (inputValue: string) => Promise<IOptionType[]>
  handleAddDebtor: (debtorName: string, clientName: string) => Promise<any>
}) => {
  const itemRef = useRef(null)
  const [isImageLoaded, setIsImageLoaded] = useState(false)

  useEffect(() => {
    const getSource = async () => {
      if (!itemRef.current || !check.id || !shouldLoad) {
        return
      }

      try {
        if (itemRef.current.firstChild?.tagName !== 'IMG') {
          let img = await imageStore.get(check.id)
          if (!img) {
            setIsImageLoaded(false)
            const response = await retryPromise(handleLoadCheckImage(check.id), 3)
            if (response.data) {
              img = response.data
              await imageStore.set(check.id, img)
            }
          }
          if (itemRef.current && itemRef.current.firstChild?.tagName !== 'IMG' && img) {
            const outputImg = document.createElement('img')
            outputImg.src = 'data:image/png;base64,' + img
            setIsImageLoaded(true)
            itemRef.current.insertBefore(outputImg, itemRef.current.firstChild)
          }
          setIsImageLoaded(true)
        }
      } catch (err) {}
    }

    getSource()
    // delete the images from the store when the component is unmounted
    return () => {
      imageStore.del(check.id)
    }
  }, [check, shouldLoad, handleLoadCheckImage])

  const isBillPaymentProvider = useMemo(
    () => check?.checkAccount?.entityInfo?.type === EntitySubType.BillPaymentProvider,
    [check],
  )

  return (
    <div
      className={cn({
        [styles.hidden]: !isActiveCheck,
      })}
    >
      <div className={styles.titleSection}>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell className={genericSs.tableTextLeft}>Client Name</TableCell>
                <TableCell className={genericSs.tableTextLeft}>Customer</TableCell>
                <TableCell className={genericSs.tableTextRight}>Amount</TableCell>
                {isBillPaymentProvider && (
                  <TableCell className={genericSs.tableTextLeft}>Original Entity</TableCell>
                )}
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow className={styles.checkImageCarouselRow}>
                <TableCell className={genericSs.tableTextLeft}>
                  <ClientNameDropdown
                    check={check}
                    handleUpdateCheck={handleUpdateCheck}
                    index={index}
                    name={name}
                    clientsOptions={clientsOptions}
                  />
                </TableCell>
                <TableCell className={genericSs.tableTextLeft}>
                  <EntityNameDropdown
                    check={check}
                    handleUpdateCheck={handleUpdateCheck}
                    index={index}
                    name={name}
                    loadDebtors={loadDebtors}
                    handleAddDebtor={handleAddDebtor}
                  />
                </TableCell>
                <TableCell className={genericSs.tableTextRight}>
                  ${formatPrice(check?.paymentAmount)}
                </TableCell>
                {isBillPaymentProvider && (
                  <TableCell className={genericSs.tableTextLeft}>
                    <EntityNameDropdown
                      check={check}
                      handleUpdateCheck={handleUpdateCheck}
                      index={index}
                      name={name}
                      entityType="originalEntity"
                      loadDebtors={loadDebtors}
                      handleAddDebtor={handleAddDebtor}
                    />
                  </TableCell>
                )}
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </div>
      <Paper ref={itemRef} className={styles.carouselItem} elevation={10}>
        {isImageLoaded ? null : (
          <Skeleton animation="wave" className={styles.skeleton} width={740} height={320} />
        )}
      </Paper>
    </div>
  )
}

interface IProps {
  checksToLoad: number[]
  activeItems: number[]
  setActiveItems: React.Dispatch<React.SetStateAction<number[]>>
  checks: ICheck[]
  handleLoadCheckImage: (id: string) => Promise<string>
  values: any
  handleUpdateCheck: (check: object) => void
  clientsOptions: IOptionType[]
  loadDebtors: (inputValue: string) => Promise<IOptionType[]>
  handleAddDebtor: (debtorName: string, clientName: string) => Promise<any>
}

const CheckImageCarousel = ({
  handleLoadCheckImage,
  checksToLoad,
  activeItems,
  setActiveItems,
  checks,
  values,
  handleUpdateCheck,
  clientsOptions,
  loadDebtors,
  handleAddDebtor,
}: IProps) => {
  const handleChangeIndex = (index: number) => {
    setActiveItems((indexes) => (indexes.includes(index) ? indexes : [index]))
  }

  return (
    <div className={styles.carouselWrapper}>
      <Carousel
        className={styles.carousel}
        autoPlay={false}
        duration={0}
        animation="slide"
        indicators
        navButtonsAlwaysVisible
        navButtonsWrapperProps={{ className: styles.carouselNavButton, style: {} }}
        indicatorContainerProps={{
          className: styles.carouselIndicators,
          style: {},
        }}
        indicatorIconButtonProps={{
          className: styles.carouselIndicatorsItem,
          style: {},
        }}
        activeIndicatorIconButtonProps={{
          className: styles.carouselIndicatorsItemActive,
          style: {},
        }}
        PrevIcon={<NavigateBefore />}
        NextIcon={<NavigateNext />}
        index={activeItems[0]}
        onChange={handleChangeIndex}
        changeOnFirstRender
      >
        {checks?.map((check, index) => (
          <CheckImage
            check={check}
            key={`check-img-${check.id}`}
            handleLoadCheckImage={handleLoadCheckImage}
            shouldLoad={checksToLoad.includes(index)}
            isActiveCheck={activeItems.includes(index)}
            index={index}
            handleUpdateCheck={handleUpdateCheck}
            value={values?.[index]}
            name={`checks[${index}]`}
            clientsOptions={clientsOptions}
            loadDebtors={loadDebtors}
            handleAddDebtor={handleAddDebtor}
          />
        ))}
      </Carousel>
    </div>
  )
}

export default CheckImageCarousel
