import { combineReducers } from 'redux'
import { loadingArrayReducer } from '../../helpers/helpers'
import * as actions from './actions'

export interface AliasMapping {
  loadingArray: string[]
}

export default combineReducers<AliasMapping>({
  loadingArray: loadingArrayReducer([actions.prefix]),
})
