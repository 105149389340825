import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useHistory, useLocation, useParams } from 'react-router'
import { generatePath } from 'react-router-dom'
import Grid from '@mui/material/Grid'
import Box from '@mui/material/Box'
import Link from '@mui/material/Link'
import Tooltip from '@mui/material/Tooltip'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import { ErrorCode, FileRejection, useDropzone } from 'react-dropzone'
import cn from 'classnames'
import moment from 'moment'

import genericSs from '@styles/generic.module.scss'
import styles from './DueDiligenceProcessDocumentsListPage.module.scss'

import { ReactComponent as DownChevron } from '@assets/images/down-chevron.svg'
import { ReactComponent as HomeIcon } from '@assets/images/home-icon.svg'
import { ReactComponent as ExcelIcon } from '@assets/images/excel-icon.svg'
import { ReactComponent as DropDownIcon } from '@assets/images/drop-down-icon.svg'
import { ReactComponent as CheckIcon } from '@assets/images/check-circle-icon.svg'
import { ReactComponent as WarningIcon } from '@assets/images/warning-icon.svg'
import { ReactComponent as DownloadIcon } from '@assets/images/download-arrow-icon.svg'
import { ReactComponent as DeleteIcon } from '@assets/images/delete-icon.svg'
import { ReactComponent as PlusIcon } from '@assets/images/add-select-field.svg'

import {
  DUE_DILIGENCE_ROUTES_MAP,
  DUE_DILIGENCE_DOCUMENTS_LIST_TITLE_MAP,
  DUE_DILIGENCE_DOCUMENTS_LIST_BREADCRUMBS_MAP,
  IDueDiligence,
  IDueDiligenceReportingFlow,
  DUE_DILIGENCE_TEMPLATES_MAP,
  DUE_DILIGENCE_DOCUMENTS_WITH_AUTO_REFRESH,
  DueDiligenceHeaderMenuPages,
  IDueDiligenceReportingFlowData,
} from '@common/interfaces/dueDiligence'
import { ROUTES } from '../../constants/routes'
import Breadcrumbs from '../../components/Common/Breadcrumbs'
import TableContainer from '../../components/Common/TableContainer'
import Table from '../../components/Common/Table'
import TableHead from '../../components/Common/TableHead'
import TableBody from '../../components/Common/TableBody'
import TableRow from '../../components/Common/TableRow'
import TableCell from '../../components/Common/TableCell'
import { useLoadInfo } from '../../hooks/useLoadInfo'
import { useSetPageTitle } from '../../hooks/useSetPageTitle'
import Button from '../../components/Common/Button'
import { OngoingReportingType, TemplateTypes } from '@common/interfaces/bbc'
import { databaseDateFormat, formatDateTime, unFormatPrice } from '../../helpers/helpers'
import { EditMapping, MenuIcon } from '../../components/Common/Icons'
import Checkbox from '../../components/Common/Checkbox'
import UploadFileManagement from '../../components/Common/UploadFileManagement/'
import Modal from '../../components/Common/Modal'
import {
  ACCEPTED_FILES_MAPPING,
  boxOnlineLink,
  DATE_FORMAT,
  DEFAULT_EMPTY_ARRAY_PROP,
  DEFAULT_MAX_FILE_SIZE,
} from '../../constants/common'
import SelectField, { IOption } from '../../components/Common/SelectField'
import KeyboardDatePicker from '../../components/Common/KeyboardDatePicker'
import CurrencyField from '../../components/Common/CurrencyField'
import { IBankAccount } from '@common/interfaces/bankAccount'
import { ILoadingData } from '../../redux/types'
import AddEditBankAccount from '../../components/Client/BankAccountInformation/AddEditBankAccount'
import { IBank } from '@common/interfaces/bank'
import SaveState from '../../components/Common/SaveState'
import TableLoader from '../../components/Common/TableLoader'
import DueDiligenceProcessDocumentsListPageLoader from './DueDiligenceProcessDocumentsListPageLoader'
import LinearProgressBar from '../../components/Common/LinearProgressBar'
import { ClientInfoStatus } from '@common/interfaces/client'
import { excelOnlineLink } from '../../constants/common'
import { humanReadableFileSize } from '@common/helpers/helpers'
import WarningModal from '../../components/WarningModal'
import DueDiligenceHeaderMenu from '../../components/DueDiligenceHeaderMenu'

const DueDiligenceDocumentsRow = ({
  reportingFlow,
  isSelected,
  onSelect,
  onChange,
  onClearData,
  hasRecordDate = false,
  hasBankInfo = false,
  clientBanksOptions = DEFAULT_EMPTY_ARRAY_PROP,
  handleAddBankAccount,
  readOnly,
  isProcessMultiple,
  deleteDocument,
  createDocument,
  otherIdsByLatestId,
  errorFiles,
}: {
  reportingFlow: IDueDiligenceReportingFlow
  isSelected: boolean
  onSelect: (id?: string, ids?: string[]) => void
  onChange: (id: string, data: object) => void
  onClearData: (id: string) => void
  hasRecordDate: boolean
  hasBankInfo: boolean
  clientBanksOptions: IOption[]
  handleAddBankAccount: () => void
  readOnly: boolean
  isProcessMultiple: boolean
  deleteDocument: (id: string) => void
  createDocument: (id: string) => void
  otherIdsByLatestId: object
  errorFiles: object
}) => {
  const sheetOptions = useMemo(
    () =>
      reportingFlow.file.sheetNames
        ? reportingFlow.file.sheetNames.map((sheetName) => ({
            label: sheetName,
            value: sheetName,
          }))
        : [],
    [reportingFlow],
  )
  const { isProcessed, isCompleted, data, file } = reportingFlow
  const isError = !!errorFiles[reportingFlow?.file?.fileName]
  const [recordDate, setRecordDate] = useState(data.recordDate || '')
  const [bankBalance, setBankBalance] = useState(data.bankBalance || null)
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const [menuOpen, setMenuOpen] = useState(false)

  const handleOpenMenu = useCallback((event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
    setMenuOpen(true)
  }, [])

  const handleCloseMenu = useCallback(() => {
    setAnchorEl(null)
    setMenuOpen(false)
  }, [])

  const handleSelect = useCallback(() => {
    if (isProcessMultiple) {
      const otherIds = otherIdsByLatestId[reportingFlow.id]
      onSelect(null, [...otherIds, reportingFlow.id])
    } else {
      onSelect(reportingFlow.id)
    }
  }, [onSelect, reportingFlow, otherIdsByLatestId, isProcessMultiple])

  const handleChangeSheetName = useCallback(
    ({ target: { value } }) => {
      onChange(reportingFlow.id, {
        sheetName: value,
      })
    },
    [onChange, reportingFlow],
  )

  const isRecordDateValid = useMemo(
    () => moment(recordDate, 'YYYY-MM-DD', true)?.isValid(),
    [recordDate],
  )

  const handleChangeRecordDate = useCallback((value) => {
    if (!isNaN(value?.getTime()) || value === null) {
      setRecordDate(databaseDateFormat(value))
    }
  }, [])

  const handleBlurRecordDate = useCallback(() => {
    if (recordDate !== data.recordDate && isRecordDateValid) {
      onChange(reportingFlow.id, {
        recordDate,
      })
    } else {
      setRecordDate(null)
    }
  }, [onChange, reportingFlow, recordDate, data, isRecordDateValid])

  const handleAcceptRecordDate = useCallback(
    (value) => {
      if (!isNaN(value?.getTime()) || value === null) {
        const recordDate = databaseDateFormat(value)
        setRecordDate(recordDate)
        onChange(reportingFlow.id, {
          recordDate,
        })
      }
    },
    [onChange, reportingFlow],
  )

  const handleChangeBankAccount = useCallback(
    ({ target: { value } }) => {
      onChange(reportingFlow.id, {
        bankAccount: value,
      })
    },
    [onChange, reportingFlow],
  )

  const handleChangeBankBalance = useCallback((event) => {
    setBankBalance(event.target.value)
  }, [])

  const handleDelete = useCallback(async () => {
    await deleteDocument(reportingFlow.id)
  }, [deleteDocument, reportingFlow.id])

  const handleAdd = useCallback(async () => {
    await createDocument(reportingFlow.id)
    if (isProcessMultiple) {
      // If the document is selected, we need to add it to the list of selected documents
      if (isSelected) {
        handleSelect()
      }
    }
  }, [createDocument, reportingFlow.id, isProcessMultiple, isSelected, handleSelect])

  const handleBlurBankBalance = useCallback(() => {
    if (unFormatPrice(bankBalance) !== unFormatPrice(data.bankBalance)) {
      onChange(reportingFlow.id, {
        bankBalance,
      })
    }
  }, [onChange, reportingFlow, bankBalance, data])

  const handleClearData = useCallback(() => {
    onClearData(reportingFlow.id)
    handleCloseMenu()
  }, [onClearData, reportingFlow, handleCloseMenu])

  const handleOpenFile = useCallback(() => {
    window.open(boxOnlineLink('file', file.fileId), '_blank')
  }, [file])

  return (
    <TableRow
      className={cn('activableRow', {
        activeRow: isSelected,
        [styles.isParentRow]: reportingFlow.isParent,
      })}
    >
      {!readOnly &&
        (!isProcessMultiple || (isProcessMultiple && reportingFlow.isParent) ? (
          <TableCell className={genericSs.tableTextCenter}>
            <Checkbox checked={isSelected} color="primary" onChange={handleSelect} />
          </TableCell>
        ) : (
          <TableCell />
        ))}
      {!isProcessMultiple || (isProcessMultiple && reportingFlow.isParent) ? (
        <TableCell className={cn(genericSs.tableTextLeft, styles.fileName)}>
          {isProcessed ? (
            (isError && isProcessMultiple) || (!isCompleted && !isProcessMultiple) ? (
              <Tooltip
                title={
                  isProcessMultiple
                    ? errorFiles[reportingFlow?.file?.fileName].map(
                        (error: string, index: number) => <div key={index}>{error}</div>,
                      )
                    : data.error
                }
              >
                <WarningIcon className={styles.dueDiligenceDocumentHasError} />
              </Tooltip>
            ) : (
              <CheckIcon className={styles.dueDiligenceDocumentSuccess} />
            )
          ) : (
            <ExcelIcon onClick={handleOpenFile} className={styles.fileNameIcon} />
          )}
          <Tooltip title={file.fileName}>
            <div className={styles.fileElement}>{file.fileName}</div>
          </Tooltip>
        </TableCell>
      ) : (
        <TableCell>
          <div className={styles.childBorders} />
        </TableCell>
      )}
      {hasRecordDate && (
        <TableCell className={genericSs.tableTextLeft}>
          <KeyboardDatePicker
            placeholder="Select date"
            name="recordDate"
            inputFormat={DATE_FORMAT}
            onChange={handleChangeRecordDate}
            onBlur={handleBlurRecordDate}
            onAccept={handleAcceptRecordDate}
            useFinalForm={false}
            value={recordDate}
            className={cn(styles.dueDiligenceDocumentDate, {
              [styles.dueDiligenceDocumentInvalid]: !recordDate && isSelected,
            })}
            disabled={readOnly}
          />
        </TableCell>
      )}
      <TableCell className={genericSs.tableTextLeft}>
        <SelectField
          placeholder="Select tab"
          fullWidth
          className={cn(styles.dueDiligenceDocumentSelect, {
            [styles.dueDiligenceDocumentInvalid]: !file.sheetName && isSelected,
          })}
          name="sheetName"
          useFinalForm={false}
          options={sheetOptions}
          onChange={handleChangeSheetName}
          value={file.sheetName || ''}
          disabled={readOnly}
        />
      </TableCell>
      {hasBankInfo && (
        <TableCell className={genericSs.tableTextLeft}>
          <SelectField
            placeholder="Select bank account"
            fullWidth
            className={cn(styles.dueDiligenceDocumentSelect, {
              [styles.dueDiligenceDocumentInvalid]: !data.bankAccount && isSelected,
            })}
            name="bankAccount"
            useFinalForm={false}
            options={clientBanksOptions}
            onChange={handleChangeBankAccount}
            value={data.bankAccount || ''}
            onAddValue={handleAddBankAccount}
            addValueLabel="Bank account"
            disabled={readOnly}
          />
        </TableCell>
      )}
      {hasBankInfo && (
        <TableCell className={genericSs.tableTextLeft}>
          <CurrencyField
            fullWidth
            className={cn(styles.dueDiligenceDocumentCurrency, {
              [styles.dueDiligenceDocumentInvalid]: !bankBalance && isSelected,
            })}
            placeholder="Bank balance"
            name="bankBalance"
            onChange={handleChangeBankBalance}
            onBlur={handleBlurBankBalance}
            useFinalForm={false}
            value={bankBalance}
            disabled={readOnly}
          />
        </TableCell>
      )}
      {!isProcessMultiple || (isProcessMultiple && reportingFlow.isParent) ? (
        <TableCell className={genericSs.tableTextLeft}>{formatDateTime(file.modifiedAt)}</TableCell>
      ) : (
        <TableCell />
      )}
      {!readOnly && (
        <TableCell className={genericSs.tableTextRight}>
          {isProcessMultiple && !reportingFlow.isParent && (
            <DeleteIcon className={styles.iconButtonDelete} onClick={handleDelete} />
          )}
          {isProcessMultiple &&
            reportingFlow.isParent &&
            reportingFlow.file.sheetNames?.length > 1 && (
              <PlusIcon className={styles.iconButton} onClick={handleAdd} />
            )}
          {!isProcessMultiple || (isProcessMultiple && reportingFlow.isParent) ? (
            <MenuIcon isActive={menuOpen} onClick={handleOpenMenu} size="small" />
          ) : (
            <div />
          )}
          <Menu open={menuOpen} onClose={handleCloseMenu} anchorEl={anchorEl}>
            <MenuItem>
              <Link
                className={styles.link}
                href={excelOnlineLink(file.fileId)}
                target="_blank"
                rel="noopener noreferrer"
                onClick={handleCloseMenu}
              >
                Format doc (Excel online)
              </Link>
            </MenuItem>
            {isProcessed && <MenuItem onClick={handleClearData}>Clear data</MenuItem>}
          </Menu>
        </TableCell>
      )}
    </TableRow>
  )
}

interface IProps {
  isLoadingInfo: boolean
  dueDiligenceInfo: IDueDiligence
  dueDiligenceReportingFlowsData: ILoadingData<IDueDiligenceReportingFlowData>
  banks: IBank[]
  banksAccountData: ILoadingData<{ data: IBankAccount[] }>
  show: (id: string) => void
  showDocuments: (id: string, type: string, params: object) => void
  hideDocuments: () => void
  processDocuments: (id: string, type: string, data: object) => any
  uploadDocuments: (id: string, type: string, data: FormData) => void
  updateDocument: (id: string, type: string, flowId: string, data: object) => any
  unprocessDocument: (id: string, type: string, flowId: string) => void
  listBankAccounts: (clientId: string, data: object) => void
  createBankAccount: (data: Partial<IBankAccount> & { clientId: string }) => void
  listBanks: () => void
  createBank: (data: { name: string }) => void
  downloadTemplate: (id: string) => Promise<void>
  putNotification: (notification: object) => void
  refreshAutoReporting: (id: string, data: object) => Promise<void>
  refreshCount: number
  deleteDocument: (id: string) => void
  createDocument: (id: string) => void
}

const DueDiligenceProcessDocumentsListPage = ({
  isLoadingInfo,
  dueDiligenceInfo,
  dueDiligenceReportingFlowsData,
  banksAccountData,
  banks,
  show,
  showDocuments,
  hideDocuments,
  processDocuments,
  uploadDocuments,
  updateDocument,
  unprocessDocument,
  listBankAccounts,
  createBankAccount,
  listBanks,
  createBank,
  downloadTemplate,
  putNotification,
  refreshAutoReporting,
  refreshCount,
  deleteDocument,
  createDocument,
}: IProps) => {
  const { id, type: routeType } = useParams<{ id: string; type: string }>()
  const { pathname } = useLocation()
  const history = useHistory()

  const [selected, setSelected] = useState<string[]>([])
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false)
  const [isAddBankAccountModalShown, setIsAddBankAccountModalShown] = useState<boolean>(false)
  const [files, setFiles] = useState<File[]>([])
  const [isDownloading, setIsDownloading] = useState(false)
  const [isMonthlyTemplateDownloading, setIsMonthlyTemplateDownloading] = useState(false)
  const [isUploading, setIsUploading] = useState(false)
  const [isRefreshing, setIsRefreshing] = useState(false)
  const [isProcessing, setIsProcessing] = useState(false)
  const [totalDuplicateSkus, setTotalDuplicateSkus] = useState(0)
  const [totalUniqueDuplicateSkus, setTotalUniqueDuplicateSkus] = useState(0)
  const [isDuplicateModalOpen, setIsDuplicateModalOpen] = useState(false)

  const [anchorElRefresh, setAnchorElRefresh] = useState(null)
  const isRefreshMenuOpen = useMemo(() => Boolean(anchorElRefresh), [anchorElRefresh])

  const handleClickRefreshMenu = useCallback((event: React.MouseEvent<HTMLElement>) => {
    setAnchorElRefresh(event.currentTarget)
  }, [])

  const handleCloseRefreshMenu = useCallback(() => {
    setAnchorElRefresh(null)
  }, [])

  const readOnly = useMemo(
    () => dueDiligenceInfo?.clientStatus !== ClientInfoStatus.DueDiligence,
    [dueDiligenceInfo],
  )
  const { isLoading, isSaving, isSaved, dueDiligenceReportingFlows } = useMemo(
    () => ({
      isLoading: dueDiligenceReportingFlowsData.isLoading,
      isSaving: dueDiligenceReportingFlowsData.isSaving,
      isSaved: dueDiligenceReportingFlowsData.isSaved,
      dueDiligenceReportingFlows: dueDiligenceReportingFlowsData.data?.data || [],
    }),
    [dueDiligenceReportingFlowsData],
  )

  const type = useMemo(
    () =>
      Object.keys(DUE_DILIGENCE_ROUTES_MAP).find(
        (key) => DUE_DILIGENCE_ROUTES_MAP[key] === routeType,
      ) || null,
    [routeType],
  )

  const isProcessMultiple = useMemo(
    () =>
      ![
        OngoingReportingType.CapTable,
        OngoingReportingType.Miscellaneous,
        OngoingReportingType.OrgChart,
        OngoingReportingType.Summary,
      ].includes(type as OngoingReportingType),
    [type],
  )

  const {
    dueDiligenceReportingFlowsTransformed,
    otherIdsByLatestId,
    errorFiles,
  }: {
    dueDiligenceReportingFlowsTransformed: IDueDiligenceReportingFlow[]
    otherIdsByLatestId: object
    errorFiles: object
  } = useMemo(() => {
    if (isProcessMultiple) {
      const idsByFileName = {}
      const sheetNamesByFileName = {}
      const errorFiles = {}
      dueDiligenceReportingFlows?.forEach((flow) => {
        const fileName = flow.file.fileName
        if (!idsByFileName?.[fileName]) {
          idsByFileName[fileName] = []
          sheetNamesByFileName[fileName] = []
        }
        idsByFileName[fileName].push(flow.id)
        sheetNamesByFileName[fileName].push(flow.file.sheetName)
        if (flow?.data?.error) {
          errorFiles[flow.file.fileName] = [
            ...(errorFiles[flow.file.fileName] || []),
            flow.data.error,
          ]
        }
      })

      const dueDiligenceReportingFlowsEarliest: IDueDiligenceReportingFlow[] = Object.keys(
        idsByFileName,
      ).map((fileName) => {
        return dueDiligenceReportingFlows
          ?.filter((flow) => flow.file.fileName === fileName)
          .reduce((earliest, current) => {
            return earliest.createdAt < current.createdAt ? earliest : current
          })
      })

      const otherIdsByLatestId = {}

      for (const flow of dueDiligenceReportingFlowsEarliest) {
        const allIds = idsByFileName[flow.file.fileName]
        const otherIds = allIds.filter((id: string) => id !== flow.id)
        otherIdsByLatestId[flow.id] = otherIds
      }
      return {
        dueDiligenceReportingFlowsTransformed: dueDiligenceReportingFlows
          ?.map((flow) => {
            const isParent = Object.keys(otherIdsByLatestId).includes(flow.id)
            const sheetNames = flow?.file?.sheetNames?.filter((sheetName) => {
              return !sheetNamesByFileName[flow.file.fileName].includes(sheetName)
            })
            return {
              ...flow,
              isParent,
              file: {
                ...flow.file,
                sheetNames: [...(sheetNames || []), flow.file.sheetName].filter(Boolean),
              },
            }
          })
          .sort((a, b) => {
            const nameComparison = a.file.fileName.localeCompare(b.file.fileName)
            if (nameComparison !== 0) return nameComparison
            if (a.isParent === b.isParent) return 0
            return a.isParent ? -1 : 1
          }),
        otherIdsByLatestId,
        errorFiles,
      }
    } else {
      return {
        dueDiligenceReportingFlowsTransformed: dueDiligenceReportingFlows,
        otherIdsByLatestId: {},
        errorFiles: {},
      }
    }
  }, [dueDiligenceReportingFlows, isProcessMultiple])

  const documentTypeFields = useMemo(
    () => ({
      hasRecordDate: [
        OngoingReportingType.AR,
        OngoingReportingType.AP,
        OngoingReportingType.BankTransactions,
        OngoingReportingType.Inventory,
        OngoingReportingType.IncomeStatementProjections,
        OngoingReportingType.BalanceSheetProjections,
      ].includes(type as OngoingReportingType),
      hasBankInfo: type === OngoingReportingType.BankTransactions,
    }),
    [type],
  )

  useLoadInfo({ id, info: dueDiligenceInfo, show })
  useSetPageTitle(dueDiligenceInfo?.clientName || '')

  useEffect(() => {
    showDocuments(id, type, {})

    return () => {
      hideDocuments()
    }
  }, [id, type, showDocuments, hideDocuments])

  useEffect(() => {
    if (type === OngoingReportingType.BankTransactions) {
      listBankAccounts(id, {
        filters: { isExternal: true },
      })
      listBanks()
    }
  }, [id, listBankAccounts, listBanks, type])

  useEffect(() => {
    if (refreshCount) {
      showDocuments(id, type, {})
    }
  }, [id, type, showDocuments, refreshCount])

  const completedDueDiligenceReportingFlows = useMemo(
    () => dueDiligenceReportingFlowsTransformed.filter(({ isCompleted }) => isCompleted),
    [dueDiligenceReportingFlowsTransformed],
  )
  const handleGoToMapping = useCallback(() => {
    let route
    if ([OngoingReportingType.AR, OngoingReportingType.AP].includes(type as OngoingReportingType)) {
      route = generatePath(ROUTES.DUE_DILIGENCE_PROCESS_DOCUMENTS_MAPPING, {
        id,
        type: routeType,
      })
    } else if (
      [
        OngoingReportingType.IncomeStatement,
        OngoingReportingType.BalanceSheet,
        OngoingReportingType.IncomeStatementProjections,
        OngoingReportingType.BalanceSheetProjections,
      ].includes(type as OngoingReportingType)
    ) {
      route = generatePath(ROUTES.DUE_DILIGENCE_PROCESS_DOCUMENTS_FINANCIALS, {
        clientId: id,
        type: routeType,
        id: completedDueDiligenceReportingFlows[0].id,
      })
    } else if (type === OngoingReportingType.BankTransactions) {
      route = generatePath(ROUTES.DUE_DILIGENCE_PROCESS_DOCUMENTS_BANK_TRANSACTIONS, {
        id,
        type: routeType,
      })
    } else if (type === OngoingReportingType.ARGeneralLedger) {
      route = generatePath(ROUTES.DUE_DILIGENCE_PROCESS_DOCUMENTS_GENERAL_LEDGER, {
        id,
        type: routeType,
      })
    } else if (type === OngoingReportingType.Inventory) {
      route = generatePath(ROUTES.DUE_DILIGENCE_PROCESS_DOCUMENTS_INVENTORY, {
        id,
        type: routeType,
      })
    } else if (type === OngoingReportingType.SalesBySKU) {
      route = generatePath(ROUTES.DUE_DILIGENCE_PROCESS_DOCUMENTS_SALES_BY_SKU, {
        id,
        type: routeType,
      })
    } else if (type === OngoingReportingType.CapTable) {
      route = generatePath(ROUTES.DUE_DILIGENCE_PROCESS_DOCUMENTS_CAP_TABLE, {
        id,
        type: routeType,
      })
    }

    if (route) {
      history.push(route)
    }
  }, [id, type, routeType, history, completedDueDiligenceReportingFlows])
  const handleRefreshDocuments = useCallback(
    (skipLoader: boolean = false) =>
      showDocuments(id, type, {
        isRefresh: true,
        skipLoader,
      }),
    [id, type, showDocuments],
  )
  const onRefresh = useCallback(() => {
    setAnchorElRefresh(null)
    handleRefreshDocuments()
  }, [handleRefreshDocuments])

  const breadcrumbs = useMemo(() => {
    return [
      {
        link: ROUTES.DUE_DILIGENCE_HOME,
        Icon: HomeIcon,
      },
      {
        link: generatePath(ROUTES.DUE_DILIGENCE, { id }),
        title: dueDiligenceInfo?.clientName,
      },
      {
        link: generatePath(ROUTES.DUE_DILIGENCE_ANALYSIS_QUEUE, { id }),
        title: 'Process Documents',
      },
      {
        link: pathname,
        title: DUE_DILIGENCE_DOCUMENTS_LIST_BREADCRUMBS_MAP[type],
      },
    ]
  }, [dueDiligenceInfo, id, pathname, type])

  const handleSelectDocument = useCallback(
    (itemId?: string, itemIds?: string[]) => {
      if (!isProcessMultiple) {
        setSelected((items) =>
          items.includes(itemId) ? items.filter((item) => item !== itemId) : [...items, itemId],
        )
      } else {
        setSelected((currentItems) => {
          const updatedItems = new Set(currentItems)
          itemIds.forEach((itemId) => {
            if (updatedItems.has(itemId)) {
              updatedItems.delete(itemId)
            } else {
              updatedItems.add(itemId)
            }
          })

          return Array.from(updatedItems)
        })
      }
    },
    [isProcessMultiple],
  )

  const isAllDocumentsSelected = useMemo(
    () => selected.length > 0 && selected.length === dueDiligenceReportingFlowsTransformed.length,
    [selected, dueDiligenceReportingFlowsTransformed],
  )

  const handleSelectAllDocuments = useCallback(() => {
    setSelected(
      isAllDocumentsSelected ? [] : dueDiligenceReportingFlowsTransformed.map((item) => item.id),
    )
  }, [isAllDocumentsSelected, dueDiligenceReportingFlowsTransformed])

  const handleAddDocument = useCallback(() => {
    setFiles([])
    setIsModalOpen(true)
  }, [])

  const handleToggleModal = useCallback(() => {
    if (!isUploading) {
      setIsModalOpen((isOpen) => !isOpen)
    }
  }, [isUploading])

  const handleToggleAddBankAccountModal = useCallback(() => {
    setIsAddBankAccountModalShown((isOpen) => !isOpen)
  }, [])

  const handleFileRejection = useCallback(
    (fileRejection: FileRejection[]) => {
      const firstError = fileRejection?.[0]?.errors?.[0]
      if (firstError) {
        putNotification({
          code: firstError.code,
          message:
            firstError.code === ErrorCode.FileTooLarge
              ? `File is too large, the maximum file size is ${humanReadableFileSize(
                  DEFAULT_MAX_FILE_SIZE,
                ).join(' ')}`
              : firstError.message,
          type: 'error',
        })
      }
    },
    [putNotification],
  )

  const handleFileUpload = useCallback((loadedFiles: File[]) => {
    setFiles((existsFiles) => [...existsFiles, ...loadedFiles])
  }, [])

  const handleFileDrop = useCallback(
    async (loadedFiles: File[]) => {
      const formData = new FormData()

      for (const file of loadedFiles) {
        formData.append('files[]', file, file.name)
      }

      setIsUploading(true)
      await uploadDocuments(id, type, formData)
      setIsUploading(false)
      setIsRefreshing(true)
      await handleRefreshDocuments(true)
      setIsRefreshing(false)
    },
    [id, type, uploadDocuments, handleRefreshDocuments],
  )

  const handleFileDelete = useCallback((indexes: number[]) => {
    setFiles((existsFiles) => {
      indexes.sort((a, b) => b - a).forEach((i) => existsFiles.splice(i, 1))
      return [...existsFiles]
    })
  }, [])

  const { getRootProps, getInputProps, open, isDragAccept, isDragReject } = useDropzone({
    noClick: true,
    maxSize: DEFAULT_MAX_FILE_SIZE,
    maxFiles: 99,
    accept: ACCEPTED_FILES_MAPPING.excel,
    multiple: true,
    noDragEventsBubbling: true,
    onDropAccepted: handleFileUpload,
    onDropRejected: handleFileRejection,
  })

  const {
    getRootProps: getContainerRootProps,
    getInputProps: getContainerInputProps,
    isDragActive,
  } = useDropzone({
    noClick: true,
    maxSize: DEFAULT_MAX_FILE_SIZE,
    maxFiles: 99,
    accept: ACCEPTED_FILES_MAPPING.excel,
    multiple: true,
    noDragEventsBubbling: true,
    onDropAccepted: handleFileDrop,
    onDropRejected: handleFileRejection,
  })

  const handleDocumentsUpload = useCallback(async () => {
    const formData = new FormData()

    for (const file of files) {
      formData.append('files[]', file, file.name)
    }

    setIsUploading(true)
    await uploadDocuments(id, type, formData)
    setIsUploading(false)
    setIsModalOpen(false)
    setIsRefreshing(true)
    await handleRefreshDocuments(true)
    setIsRefreshing(false)
    setFiles([])
  }, [id, type, uploadDocuments, files, handleRefreshDocuments])

  const handleChangeDocument = useCallback(
    async (itemId: string, data: object) => {
      const result = await updateDocument(id, type, itemId, data)
      if (result?.error?.code === 'FORMAT_ERROR') {
        await handleRefreshDocuments()
      }
    },
    [id, type, updateDocument, handleRefreshDocuments],
  )

  const handleClearDocumentData = useCallback(
    (itemId: string) => {
      unprocessDocument(id, type, itemId)
    },
    [id, type, unprocessDocument],
  )

  const closeWarningModal = useCallback(() => {
    setIsDuplicateModalOpen(false)
    handleGoToMapping()
  }, [handleGoToMapping])

  const disabledMessage = useMemo(() => {
    if (!selected.length) {
      return 'You must select at least one document'
    }
    const selectedFlows = dueDiligenceReportingFlowsTransformed.filter(({ id }) =>
      selected.includes(id),
    )

    const completedReports = dueDiligenceReportingFlowsTransformed.some(
      ({ isCompleted }) => isCompleted,
    )
    if (
      (type === OngoingReportingType.CapTable && selected.length > 1) ||
      (type === OngoingReportingType.CapTable && completedReports)
    ) {
      return 'You can only process one Cap Table'
    }
    if (selectedFlows.some(({ file }) => !file.sheetName)) {
      return 'All documents must have a sheet name'
    }
    if (documentTypeFields.hasRecordDate && selectedFlows.some(({ data }) => !data.recordDate)) {
      return 'All documents must have an as of date'
    }
    if (
      documentTypeFields.hasBankInfo &&
      selectedFlows.some(({ data }) => !data.bankBalance || !data.bankAccount)
    ) {
      return 'All documents must have a bank account and bank balance'
    }
    return ''
  }, [dueDiligenceReportingFlowsTransformed, selected, documentTypeFields, type])
  const handleProcessDocuments = useCallback(async () => {
    setIsProcessing(true)
    const result = await processDocuments(id, type, {
      flowIds: selected,
    })
    if (result?.error?.code === 'FORMAT_ERROR') {
      await handleRefreshDocuments()
    }
    if (type === OngoingReportingType.SalesBySKU && result?.data?.totalDuplicateSkus > 0) {
      setTotalDuplicateSkus(result.data.totalDuplicateSkus)
      setTotalUniqueDuplicateSkus(result.data.totalUniqueDuplicateSkus)
      setIsDuplicateModalOpen(true)
    }
    setIsProcessing(false)
    setSelected([])
  }, [id, type, processDocuments, selected, handleRefreshDocuments])

  const clientBanksOptions: IOption[] = useMemo(
    () =>
      banksAccountData?.data?.data.map(({ id, bankName, bankAccountNumber }) => ({
        value: id,
        label: `${bankName} - ${bankAccountNumber.trim().slice(-4)}`,
      })),
    [banksAccountData],
  )

  const handleAddBank = useCallback((name: string) => createBank({ name }), [createBank])
  const handleAddBankAccount = useCallback(() => {
    setIsAddBankAccountModalShown(true)
  }, [])

  const handleDownloadTemplate = useCallback(async () => {
    setIsDownloading(true)
    await downloadTemplate(DUE_DILIGENCE_TEMPLATES_MAP[type])
    setIsDownloading(false)
  }, [downloadTemplate, type])

  const handleDownloadTemplateMonthly = useCallback(async () => {
    setIsMonthlyTemplateDownloading(true)
    await downloadTemplate(TemplateTypes.SalesBySKUMonthlyHeader)
    setIsMonthlyTemplateDownloading(false)
  }, [downloadTemplate])

  const columnCount = useMemo(() => {
    let baseCount = 5
    if (documentTypeFields.hasRecordDate) {
      baseCount += 1
    }
    if (documentTypeFields.hasBankInfo) {
      baseCount += 2
    }
    if (readOnly) {
      baseCount -= 2
    }
    return baseCount
  }, [documentTypeFields, readOnly])

  const handleRefreshAutoReporting = useCallback(() => {
    setAnchorElRefresh(null)
    return refreshAutoReporting(id, { type })
  }, [id, refreshAutoReporting, type])

  const unprocessedDueDiligenceReportingFlows = useMemo(
    () => dueDiligenceReportingFlows?.filter(({ isProcessed }) => !isProcessed) || [],
    [dueDiligenceReportingFlows],
  )

  const handleDeleteUnprocessed = useCallback(
    async (itemId: string) => {
      setIsUploading(true)
      await deleteDocument(itemId)
      setIsUploading(false)
      setIsModalOpen(false)
    },
    [deleteDocument],
  )

  if (!dueDiligenceInfo || isLoadingInfo) {
    return <DueDiligenceProcessDocumentsListPageLoader />
  }

  return (
    <Grid container py={3} pr={2} alignItems="flex-start" justifyContent="center" rowSpacing={2}>
      <Grid item container xs={12} justifyContent="space-between">
        <Grid item container xs={6} justifyContent="flex-start" alignItems="center">
          <Breadcrumbs breadcrumbs={breadcrumbs} />
        </Grid>
        <Grid item container xs={6} justifyContent="flex-end" gap={1.5} alignItems="center">
          <DueDiligenceHeaderMenu page={DueDiligenceHeaderMenuPages.ProcessDocuments} />
        </Grid>
      </Grid>

      <Grid item xs={12} alignItems="flex-start" justifyContent="flex-start" ml={3}>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          className={styles.documentsTitleRow}
        >
          <h2 className={genericSs.dueDiligenceHeader}>
            Select {DUE_DILIGENCE_DOCUMENTS_LIST_TITLE_MAP[type]} reports to process
          </h2>
          {selected.length > 0 && (
            <Box display="flex" alignItems="center" gap={2}>
              {disabledMessage ? (
                <span className={styles.disabledMessage}>{disabledMessage}</span>
              ) : (
                <span className={styles.selectedItems}>{selected.length} sheet(s) selected</span>
              )}
              <Button
                className={genericSs.dueDiligenceSubmitButton}
                endIcon={<DropDownIcon className={styles.nextButtonIcon} />}
                variant="contained"
                onClick={handleProcessDocuments}
                disabled={!!disabledMessage}
                isLoading={isProcessing}
              >
                <span>Next</span>
              </Button>
            </Box>
          )}
        </Box>

        <Box display="flex" justifyContent="space-between" alignItems="center" my={3}>
          <Box display="flex" justifyContent="flex-start" alignItems="center">
            {!readOnly &&
              ![OngoingReportingType.BankTransactions].includes(type as OngoingReportingType) && (
                <Button
                  className={styles.downloadButton}
                  startIcon={<DownloadIcon />}
                  onClick={handleDownloadTemplate}
                  isLoading={isDownloading}
                  variant="outlined"
                >
                  Download template
                </Button>
              )}
            {!readOnly && type === OngoingReportingType.SalesBySKU && (
              <Button
                className={styles.downloadButton}
                startIcon={<DownloadIcon />}
                onClick={handleDownloadTemplateMonthly}
                isLoading={isMonthlyTemplateDownloading}
                variant="outlined"
              >
                Download template (monthly header)
              </Button>
            )}
          </Box>
          <Box display="flex" justifyContent="flex-end" alignItems="center" gap={2}>
            {completedDueDiligenceReportingFlows.length > 0 && (
              <EditMapping action={handleGoToMapping} title="Go to mapping" />
            )}

            {!readOnly && (
              <Tooltip
                title={
                  dueDiligenceInfo.refreshedAt
                    ? `Requested at ${formatDateTime(dueDiligenceInfo.refreshedAt)}`
                    : ''
                }
                placement="top"
              >
                <div>
                  <Button
                    variant="outlined"
                    onClick={handleClickRefreshMenu}
                    disabled={!!dueDiligenceInfo.refreshedAt}
                  >
                    Refresh
                    <DownChevron className={styles.downChevron} />
                  </Button>
                  <Menu
                    open={isRefreshMenuOpen}
                    anchorEl={anchorElRefresh}
                    onClose={handleCloseRefreshMenu}
                  >
                    {dueDiligenceInfo.isErpConnected &&
                      DUE_DILIGENCE_DOCUMENTS_WITH_AUTO_REFRESH.includes(
                        type as OngoingReportingType,
                      ) && (
                        <MenuItem
                          classes={{ root: styles.actionsMenuItem }}
                          onClick={handleRefreshAutoReporting}
                          disabled={!!dueDiligenceInfo.refreshedAt}
                        >
                          Codat
                        </MenuItem>
                      )}
                    <MenuItem classes={{ root: styles.actionsMenuItem }} onClick={onRefresh}>
                      Box
                    </MenuItem>
                  </Menu>
                </div>
              </Tooltip>
            )}

            {!readOnly && (
              <Button
                className={styles.addDocumentButton}
                variant="outlined"
                onClick={handleAddDocument}
              >
                Manage Files
              </Button>
            )}
          </Box>
        </Box>

        <div className={styles.dropWrapper} {...getContainerRootProps()}>
          <TableContainer
            className={cn(styles.documentTable, {
              [styles.documentTableWithRecordDate]: documentTypeFields.hasRecordDate,
              [styles.documentTableWithBankInfo]: documentTypeFields.hasBankInfo,
              [styles.documentTableReadOnly]: readOnly,
            })}
          >
            <Table>
              <TableHead>
                <TableRow>
                  {!readOnly && type !== OngoingReportingType.CapTable ? (
                    <TableCell className={genericSs.tableTextCenter}>
                      <Checkbox
                        checked={isAllDocumentsSelected}
                        color="primary"
                        onChange={handleSelectAllDocuments}
                      />
                    </TableCell>
                  ) : (
                    <TableCell />
                  )}
                  <TableCell className={genericSs.tableTextLeft}>Name</TableCell>
                  {documentTypeFields.hasRecordDate && (
                    <TableCell className={genericSs.tableTextLeft}>As of date</TableCell>
                  )}
                  <TableCell className={genericSs.tableTextLeft}>Tabs</TableCell>
                  {documentTypeFields.hasBankInfo && (
                    <TableCell className={genericSs.tableTextLeft}>Bank account</TableCell>
                  )}
                  {documentTypeFields.hasBankInfo && (
                    <TableCell className={genericSs.tableTextLeft}>Bank balance</TableCell>
                  )}
                  <TableCell className={genericSs.tableTextLeft}>Modified</TableCell>
                  {!readOnly && <TableCell className={genericSs.tableTexRight}>Action</TableCell>}
                </TableRow>
              </TableHead>
              <TableBody>
                {isLoading ? (
                  <TableLoader columnsCount={columnCount} rowsCount={5} height={44} />
                ) : (
                  dueDiligenceReportingFlowsTransformed.map((reportingFlow) => (
                    <DueDiligenceDocumentsRow
                      key={reportingFlow.id}
                      reportingFlow={reportingFlow}
                      isSelected={selected.includes(reportingFlow.id)}
                      onSelect={handleSelectDocument}
                      onChange={handleChangeDocument}
                      onClearData={handleClearDocumentData}
                      clientBanksOptions={clientBanksOptions}
                      handleAddBankAccount={handleAddBankAccount}
                      readOnly={readOnly}
                      isProcessMultiple={isProcessMultiple}
                      deleteDocument={deleteDocument}
                      createDocument={createDocument}
                      otherIdsByLatestId={otherIdsByLatestId}
                      errorFiles={errorFiles}
                      {...documentTypeFields}
                    />
                  ))
                )}
                {isRefreshing && (
                  <TableLoader columnsCount={columnCount} rowsCount={1} height={44} />
                )}
              </TableBody>
            </Table>
            <Box display="flex" alignItems="center" justifyContent="flex-end">
              <SaveState isSaving={isSaving} isSaved={isSaved} />
            </Box>
          </TableContainer>

          <input {...getContainerInputProps()} />

          {isDragActive && <div className={styles.dropOverlay}>Drop some files here</div>}
          {isUploading && !isModalOpen && (
            <div className={cn(styles.dropOverlay, styles.uploadOverlay)}>
              <LinearProgressBar />
            </div>
          )}
        </div>

        {isDuplicateModalOpen && totalDuplicateSkus > 0 && (
          <WarningModal
            warningMessage={`${totalUniqueDuplicateSkus} unique duplicate SKU - Date(s) appeared ${totalDuplicateSkus} times in this report.`}
            title="Warning"
            onCancel={closeWarningModal}
            cancelText="Close"
          />
        )}

        {isModalOpen && (
          <Modal
            open
            title="Manage Files"
            onCancel={handleToggleModal}
            size="small"
            footer={[
              <Button
                key="submit"
                color="primary"
                variant="contained"
                fullWidth
                small={false}
                isLoading={isUploading}
                onClick={handleDocumentsUpload}
                disabled={!files.length}
              >
                Save
              </Button>,
            ]}
          >
            <UploadFileManagement
              files={files}
              onDelete={handleFileDelete}
              setIsModalOpen={handleToggleModal}
              isDragAccept={isDragAccept}
              isDragReject={isDragReject}
              getRootProps={getRootProps}
              getInputProps={getInputProps}
              open={open}
              handleDelete={handleFileDelete}
              dropzoneText="Drop files here or "
            />

            <div className={styles.unprocessedFlowsList}>
              {unprocessedDueDiligenceReportingFlows.map((item) => (
                <div className={styles.unprocessedFlowItem} key={item.id}>
                  <div className={styles.unprocessedFlowItemNameContainer}>
                    <Tooltip title={item.file.fileName} placement="top">
                      <Link
                        href={item.file.link}
                        target="_blank"
                        rel="noopener noreferrer"
                        className={styles.unprocessedFlowItemName}
                      >
                        {item.file.fileName}
                      </Link>
                    </Tooltip>
                  </div>
                  <div className={styles.unprocessedFlowItemDeleteContainer}>
                    <DeleteIcon onClick={() => handleDeleteUnprocessed(item.id)} />
                  </div>
                </div>
              ))}
            </div>
          </Modal>
        )}
        <AddEditBankAccount
          isEditModalShown={isAddBankAccountModalShown}
          setIsEditModalShown={setIsAddBankAccountModalShown}
          handleAddEditBankAccount={createBankAccount}
          handleAddBank={handleAddBank}
          isAddModal
          selectedRow={null}
          handleCloseMenu={handleToggleAddBankAccountModal}
          banks={banks}
          clientId={id}
        />
      </Grid>
    </Grid>
  )
}

export default DueDiligenceProcessDocumentsListPage
