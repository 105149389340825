import React, { useCallback, useEffect, useState, useMemo } from 'react'
import { Editor } from 'react-draft-wysiwyg'
import { EditorState, convertToRaw, ContentState } from 'draft-js'
import draftToHtml from 'draftjs-to-html'
import htmlToDraft from 'html-to-draftjs'
import cn from 'classnames'
import styles from './DueDiligenceCommentsModal.module.scss'
import Button from '../Common/Button'
import boldIcon from '@assets/images/bold.svg'
import italicIcon from '@assets/images/italic.svg'
import underlineIcon from '@assets/images/underline.svg'

const TOOLBAR = {
  options: ['inline'],
  inline: {
    options: ['bold', 'italic', 'underline'],
    bold: { icon: boldIcon },
    italic: { icon: italicIcon },
    underline: { icon: underlineIcon },
  },
}

interface IProps extends React.ComponentProps<typeof Editor> {
  className?: string
  readOnly?: boolean
  value: any
  onAddComment?: (commentText: string) => Promise<any>
  handleUpdate?: (commentText: string) => Promise<any>
  isUpdate?: boolean
}

const CommentsTextEditor = ({
  className,
  value,
  onAddComment,
  readOnly = false,
  handleUpdate,
  isUpdate,
  ...props
}: IProps) => {
  const [editorState, setEditorState] = useState(() => {
    if (handleUpdate) {
      EditorState.createWithContent(ContentState.createFromText(value))
    }
    return EditorState.createEmpty()
  })
  const [isSaving, setIsSaving] = useState(false)

  const isDisabled = useMemo(() => {
    return !editorState.getCurrentContent().hasText()
  }, [editorState])

  useEffect(() => {
    const contentBlock = htmlToDraft(value)
    if (contentBlock) {
      setEditorState(
        EditorState.createWithContent(
          ContentState.createFromBlockArray(contentBlock.contentBlocks, contentBlock.entityMap),
        ),
      )
    }
  }, [value])

  const handleSave = useCallback(async () => {
    const html = draftToHtml(convertToRaw(editorState.getCurrentContent())).replace(
      /(\r\n|\n|\r)/gm,
      '',
    )
    if (html !== value) {
      setIsSaving(true)
      isUpdate ? await handleUpdate(html) : await onAddComment(html)
      setIsSaving(false)
      !isUpdate && setEditorState(EditorState.createEmpty())
    } else {
      isUpdate && handleUpdate(html)
    }
  }, [onAddComment, value, editorState, handleUpdate, isUpdate])

  const handleEditorStateChange = useCallback(
    (state: EditorState) => {
      setEditorState(state)
    },
    [setEditorState],
  )

  return (
    <div
      className={cn(className, {
        [styles.editorContainer]: !readOnly,
        [styles.readOnly]: readOnly,
      })}
    >
      {readOnly ? (
        <Editor toolbar={TOOLBAR} editorState={editorState} readOnly={readOnly} />
      ) : (
        <Editor
          toolbar={TOOLBAR}
          toolbarCustomButtons={[
            <Button
              color="primary"
              variant="contained"
              className={styles.commentButton}
              disabled={isDisabled || isSaving}
              isLoading={isSaving}
              onClick={handleSave}
            >
              {isUpdate ? 'Update' : 'Post'}
            </Button>,
          ]}
          toolbarClassName={styles.editorToolbar}
          wrapperClassName={styles.editorWrapper}
          editorClassName={styles.editor}
          spellCheck
          editorState={editorState}
          // @ts-ignore
          onEditorStateChange={handleEditorStateChange}
          placeholder="Type your comment here..."
          {...props}
        />
      )}
    </div>
  )
}

export default CommentsTextEditor
