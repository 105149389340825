import React, { useEffect, useMemo, useState } from 'react'
import Skeleton from '@mui/material/Skeleton'
import { Chart } from 'react-chartjs-2'
import cn from 'classnames'

import styles from './ClientCashFlow.module.scss'

import { IClientCashFlow } from '@common/interfaces/client'
import { ReportingPeriods } from '@common/interfaces/bbc'
import { formatPrice, getRGBFFromHex } from '../../../helpers/helpers'
import { BORDER_RADIUS, FONT_SIZE, TOOLTIP } from '../../../constants/graphs'
import {
  CLIENT_CASH_FLOW_FIELDS_LABELS,
  CLIENT_CASH_FLOW_NUMBER_FIELDS,
  CLIENT_CASH_FLOW_GRAPH_FIELDS,
  CLIENT_CASH_FLOW_GRAPH_FIELDS_COLORS,
  CLIENT_CASH_FLOW_GRAPH_LINE_FIELDS,
} from '@common/constants/client'

interface IProps {
  isModalShown: boolean
  headers: string[]
  data: IClientCashFlow[]
  reportingPeriod: ReportingPeriods
}

const ClientCashFlowGraph = ({ isModalShown, headers, data, reportingPeriod }: IProps) => {
  const [isInitialLoading, setIsInitialLoading] = useState(true)

  useEffect(() => {
    if (data) {
      setIsInitialLoading(false)
    }
  }, [data])

  const chartData = useMemo(() => {
    if (data) {
      return {
        labels: headers.map((header: string) =>
          reportingPeriod === ReportingPeriods.Monthly
            ? header.replace(/20\d{2}/g, (year) => year.slice(2))
            : header,
        ),
        stepped: true,
        datasets: [
          ...CLIENT_CASH_FLOW_GRAPH_FIELDS.map((field) => ({
            label: CLIENT_CASH_FLOW_FIELDS_LABELS[field],
            field,
            data: data
              .map((item) => item[field])
              .map((value) => (value === 'Profitable' ? 24 : value))
              .map((value) =>
                CLIENT_CASH_FLOW_NUMBER_FIELDS.includes(field) ? Math.abs(value) : value,
              )
              .map((value) =>
                CLIENT_CASH_FLOW_NUMBER_FIELDS.includes(field) && value > 24 ? 24 : value,
              ),
            originalData: data.map((item) => item[field]),
            backgroundColor: data
              .map((item) => item[field])
              .map((dataPoint, index) =>
                getRGBFFromHex(
                  CLIENT_CASH_FLOW_GRAPH_FIELDS_COLORS[field],
                  headers[index].includes('Proj') ? 0.7 : 1,
                ),
              ),
            borderRadius: BORDER_RADIUS,
            type: CLIENT_CASH_FLOW_GRAPH_LINE_FIELDS.includes(field)
              ? ('line' as const)
              : ('bar' as const),
            order: CLIENT_CASH_FLOW_GRAPH_LINE_FIELDS.includes(field) ? 0 : 1,
            pointStyle: 'line',
            pointRadius: 20,
            pointHoverRadius: 20,
            pointHoverBorderWidth: 3,
            pointBorderWidth: 3,
            pointBorderColor: CLIENT_CASH_FLOW_GRAPH_FIELDS_COLORS[field],
            showLine: false,
            yAxisID: CLIENT_CASH_FLOW_NUMBER_FIELDS.includes(field) ? 'y1' : 'y',
          })),
        ],
      }
    } else {
      return null
    }
  }, [headers, data, reportingPeriod])

  const chartOptions = useMemo(() => {
    return {
      maintainAspectRatio: false,
      scales: {
        x: {
          stacked: false,
          grid: {
            display: false,
          },
          ticks: {
            font: {
              size: FONT_SIZE,
            },
          },
        },
        y: {
          stacked: false,
          grid: {
            display: false,
          },
          ticks: {
            font: {
              size: FONT_SIZE,
            },
            callback: (value: any) => {
              return formatPrice(value) ? `$${formatPrice(value)}` : `$0`
            },
          },
        },
        y1: {
          max: 25,
          grid: {
            display: false,
          },
          ticks: {
            font: {
              size: FONT_SIZE,
            },
            callback: (value: any) => {
              return value || 0
            },
          },
          beforeSetDimensions: (axis: any) => {
            if (!axis.chart.scales.y.max) {
              axis.chart.scales.y.max = Math.abs(axis.chart.scales.y.min)
            }
            if (axis.chart.scales.y.min < 0) {
              const ratio = axis.chart.scales.y.max / Math.abs(axis.chart.scales.y.min)
              axis.min = (axis.max / ratio) * -1
            }
          },
          position: 'right' as const,
        },
      },
      plugins: {
        legend: {
          position: 'top' as const,
          labels: {
            font: {
              size: FONT_SIZE,
              weight: '500',
            },
            color: 'black',
            boxWidth: 20,
            boxHeight: 20,
            sort: (a: any, b: any) => {
              return a.datasetIndex - b.datasetIndex
            },
          },
        },
        tooltip: {
          ...TOOLTIP,
          itemSort: (a: any, b: any) => {
            return a.datasetIndex - b.datasetIndex
          },
          callbacks: {
            label: (tooltipItem: any) => {
              const datasetIndex = tooltipItem.datasetIndex
              const dataIndex = tooltipItem.dataIndex
              const yAxisID = chartData.datasets[datasetIndex].yAxisID
              const originalValue = chartData.datasets[datasetIndex].originalData[dataIndex]
              const title = chartData.datasets[datasetIndex].label

              if (yAxisID === 'y1') {
                return `${title}: ${originalValue || 0}`
              } else {
                return `${title}: $${formatPrice(originalValue || 0)}`
              }
            },
          },
        },
      },
    }
  }, [chartData])

  return (
    <div
      className={cn(styles.chartContainer, {
        [styles.fullScreen]: isModalShown,
      })}
    >
      {isInitialLoading ? (
        <Skeleton variant="rectangular" width="100%" height="100%" />
      ) : (
        chartData && <Chart type="bar" data={chartData} options={chartOptions} />
      )}
    </div>
  )
}

export default ClientCashFlowGraph
