import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react'
import MuiAutocomplete, { AutocompleteProps } from '@mui/material/Autocomplete'
import MuiTextField from '@mui/material/TextField'
import cn from 'classnames'

import styles from './AutocompleteField.module.scss'
import genericSs from '@styles/generic.module.scss'
import { debounceEventHandler } from '../../../helpers/helpers'
import { TableCellContext } from '../TableCell/TableCell'

interface OptionType {
  label?: string
  value: string
  inputValue?: string
}

interface IProps extends Partial<AutocompleteProps<OptionType, false, boolean, boolean>> {
  name: string
  style?: object
  placeholder?: string
  isAsync?: boolean
  loadOptions?: (value: string) => Promise<OptionType[]>
  freeSolo?: boolean
  inTable?: boolean
}

const AutocompleteField = ({
  value,
  options,
  placeholder,
  style,
  disabled,
  className,
  onFocus,
  onBlur,
  tabIndex,
  onChange,
  groupBy,
  isAsync = false,
  loadOptions,
  disableClearable = false,
  freeSolo = true,
  inTable = false,
}: IProps) => {
  const [asyncOptions, setAsyncOptions] = useState<OptionType[]>([])
  const inputRef = useRef<HTMLInputElement>(null)
  const context = useContext(TableCellContext)
  const isActive = useMemo(() => context?.isActive, [context])
  const [isOpen, setIsOpen] = useState(false)

  const handleInputChange = useCallback(
    async (
      event: React.ChangeEvent<{}>,
      inputValue: string,
      reason: 'input' | 'reset' | 'clear',
    ) => {
      if (!isAsync) {
        return
      }
      if (reason === 'input') {
        if (inputValue.length > 1) {
          const loadedOptions = await loadOptions(inputValue)
          setAsyncOptions(loadedOptions)
        } else {
          setAsyncOptions([])
        }
      }
    },
    [isAsync, loadOptions],
  )
  const debounceHandleInputChange = useMemo(
    () => debounceEventHandler(handleInputChange, 500),
    [handleInputChange],
  )

  const renderedValue = useMemo(() => {
    let returnValue = placeholder
    if (value && inTable) {
      if (Array.isArray(value) && value.length > 1) {
        returnValue = `${value.length} Selected`
      }
      if (typeof value === 'object' && value?.label) {
        returnValue = value?.label
      }
      if (typeof value === 'string') {
        returnValue = value
      }
    }
    return returnValue
  }, [value, placeholder, inTable])

  const handleOpen = useCallback(() => {
    setIsOpen(true)
  }, [])

  const handleClose = useCallback(() => {
    setIsOpen(false)
  }, [])

  useEffect(() => {
    if (isActive && inTable && inputRef.current) {
      inputRef.current.focus()
      setIsOpen(true)
    }
  }, [isActive, inTable, inputRef])

  return (
    <>
      {!isActive && inTable && (
        <div
          className={cn({
            [genericSs.inactiveFormField]: !value,
            syntheticInputField: inTable,
          })}
        >
          <span>{renderedValue}</span>
        </div>
      )}
      {isActive || !inTable ? (
        <MuiAutocomplete
          open={isOpen}
          onOpen={handleOpen}
          onClose={handleClose}
          forcePopupIcon={false}
          key={options.length}
          value={value}
          className={cn(styles.root, className, {
            tableFormField: inTable,
          })}
          onChange={onChange}
          selectOnFocus
          handleHomeEndKeys
          disableClearable={disableClearable}
          options={isAsync ? asyncOptions : options}
          // @ts-ignore
          getOptionLabel={(option) => option.label}
          // tslint:disable-next-line:no-shadowed-variable
          isOptionEqualToValue={(option: any, value: any) =>
            option?.value === (value && value?.value ? value.value : value)
          }
          // @ts-ignore
          renderOption={(props, option) => <li {...props}>{option.label}</li>}
          style={style}
          tabIndex={tabIndex}
          freeSolo={freeSolo}
          renderInput={(params) => (
            <MuiTextField
              {...params}
              placeholder={placeholder}
              FormHelperTextProps={{ classes: { root: styles.error } }}
              variant="standard"
              inputRef={inputRef}
            />
          )}
          disabled={disabled}
          classes={{
            inputRoot: styles.input,
            popper: styles.popper,
          }}
          onBlur={onBlur}
          onFocus={onFocus}
          groupBy={groupBy}
          onInputChange={debounceHandleInputChange}
        />
      ) : null}
    </>
  )
}

export default AutocompleteField
