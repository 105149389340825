import Base from './base'

export default class PassThroughs extends Base {
  list(data: object) {
    return this.apiClient.get('pass-throughs', data)
  }

  submit(data: object) {
    return this.apiClient.post('pass-throughs', data)
  }

  delete(id: string) {
    return this.apiClient.delete(`pass-throughs/${id}`)
  }
}
