import React, { useState, useEffect, useCallback, useMemo } from 'react'
import { generatePath, Link as RouterLink } from 'react-router-dom'
import { matchPath, useHistory, useLocation } from 'react-router'
import { Form } from 'react-final-form'
import InfiniteScroll from 'react-infinite-scroll-component'
import cn from 'classnames'

import Link from '@mui/material/Link'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'

import genericSs from '@styles/generic.module.scss'
import styles from './UWDashboard.module.scss'

import {
  debounceEventHandler,
  formatDate,
  formatNumber,
  formatPercent,
  formatPrice,
  formatTime,
} from '../../helpers/helpers'
import Card from '../Common/Card'
import TableContainer from '../Common/TableContainer'
import Table from '../Common/Table'
import TableHead from '../Common/TableHead'
import TableBody from '../Common/TableBody'
import TableRow from '../Common/TableRow'
import TableCell from '../Common/TableCell'
import TableFiltersRow from '../Common/TableFiltersRow'
import { IUser } from '@common/interfaces/user'
import { IDueDiligenceAggregation, IDueDiligenceStats } from '@common/interfaces/dueDiligence'
import { ROUTES } from '../../constants/routes'
import FullscreenModal from '../Common/FullscreenModal'
import { SalesforceLink, BoxLink, RegenerateIcon, ExpandAndMinimize } from '../Common/Icons'
import { buildFiltersDefaults } from '../../helpers/filters'
import { DD_LIST_FILTERS_CONFIG, PER_PAGE } from '@common/constants/filters'
import FilterContainer from '../Filters/FilterContainer'
import { IClientInfo, ClientInfoStatus } from '@common/interfaces/client'
import TableLoader from '../Common/TableLoader'
import { ILoadingData } from '../../redux/types'
import { Tooltip } from '@mui/material'
import { useSetPageTitle } from '../../hooks/useSetPageTitle'
import DashboardHeader, { IHeaderItem } from '../DashboardHeader/DashboardHeader'
import useTable from '../../hooks/useTable'

const filtersDefaults = buildFiltersDefaults(DD_LIST_FILTERS_CONFIG, {
  clientStatus: [ClientInfoStatus.DueDiligence],
})

interface IProps {
  isUW: boolean
  dueDiligenceStats: IDueDiligenceStats
  prospects: IClientInfo[]
  dueDiligenceAggregationData: ILoadingData<IDueDiligenceAggregation>
  user: IUser
  getStats: () => void
  listProspects: () => void
  list: (params: object) => void
}

const UWDashboard = ({
  isUW,
  dueDiligenceStats,
  prospects,
  dueDiligenceAggregationData,
  getStats,
  listProspects,
  list,
}: IProps) => {
  const { pathname } = useLocation()
  const history = useHistory()

  useSetPageTitle('Diligence')

  const { isLoading, dueDiligenceAggregation } = useMemo(
    () => ({
      isLoading: dueDiligenceAggregationData.isLoading,
      dueDiligenceAggregation: dueDiligenceAggregationData.data,
    }),
    [dueDiligenceAggregationData],
  )

  const [isModalShown, setIsModalShown] = useState(false)
  const toggleIsModalShown = useCallback(() => setIsModalShown((prev) => !prev), [])

  const {
    filters,
    handleFiltersChange,
    handleOrderChange,
    orderBy,
    quickFilter,
    handleQuickFilterChange,
  } = useTable({
    tableId: 'dueDiligenceTable',
    filtersDefaults,
    sortDefault: {
      field: 'age',
      direction: 'DESC',
    },
    quickFilterDefault: 'Due Diligence',
  })

  useEffect(() => {
    if (isUW && matchPath(pathname, ROUTES.DUE_DILIGENCE_HOME)) {
      history.replace(ROUTES.HOMEPAGE)
    }
  }, [pathname, isUW, history])

  const fetchListDdProspects = useCallback(
    (data: any) => {
      const params = {
        ...data,
        filters: {
          ...data.filters,
        },
        isRefresh: data.isRefresh ?? false,
      }

      list(params)
    },
    [list],
  )

  const debounceListDdProspects = useMemo(
    () => debounceEventHandler(fetchListDdProspects, 500),
    [fetchListDdProspects],
  )

  const filtersConfig = useMemo(
    () =>
      DD_LIST_FILTERS_CONFIG.map((item) => ({
        ...item,
        options:
          item.field === 'clients'
            ? prospects.map(({ clientName }) => ({
                value: clientName,
                label: clientName,
              }))
            : item.options,
      })),
    [prospects],
  )
  const lastUpdatedAt = useMemo(
    () =>
      `Last updated at ${
        dueDiligenceStats?.updatedAt ? formatTime(dueDiligenceStats.updatedAt) : ''
      } on ${dueDiligenceStats?.updatedAt ? formatDate(dueDiligenceStats.updatedAt) : ''}`,
    [dueDiligenceStats],
  )

  useEffect(() => {
    debounceListDdProspects({
      page: 0,
      perPage: PER_PAGE,
      filters,
      orderBy: orderBy.field,
      orderDirection: orderBy.direction,
    })
  }, [list, orderBy, filters, debounceListDdProspects])

  useEffect(() => {
    getStats()
    listProspects()
  }, [getStats, listProspects])

  const pctDiffDaysInDD = useMemo(() => {
    if (!dueDiligenceStats) {
      return ''
    }
    if (dueDiligenceStats.avgDaysInDD === 0 || dueDiligenceStats.avgDaysInDDGoal === 0) {
      return 0
    }
    return Number(
      (
        ((dueDiligenceStats.avgDaysInDD - dueDiligenceStats.avgDaysInDDGoal) /
          dueDiligenceStats.avgDaysInDDGoal) *
        100
      ).toFixed(0),
    )
  }, [dueDiligenceStats])

  const handleRefreshProspects = useCallback(() => {
    list({
      page: 0,
      perPage: PER_PAGE,
      filters,
      orderBy: orderBy.field,
      orderDirection: orderBy.direction,
      isRefresh: true,
    })
  }, [list, orderBy, filters])

  const loadMore = useCallback(() => {
    fetchListDdProspects({
      loadMore: true,
      page: Math.ceil(dueDiligenceAggregation?.data.length / PER_PAGE),
      perPage: PER_PAGE,
      orderBy: orderBy.field,
      orderDirection: orderBy.direction,
      filters,
    })
  }, [dueDiligenceAggregation, orderBy, filters, fetchListDdProspects])

  const entityDashboardItems: IHeaderItem[] = useMemo(
    () => [
      {
        title: 'Issued Term Sheets',
        type: 'number',
        value: dueDiligenceStats?.numTermSheets || 0,
      },
      {
        title: 'Deals in Diligence',
        type: 'number',
        value: dueDiligenceStats?.numDealsInDiligence || 0,
      },
      {
        title: 'Deals Closed (YTD)',
        type: 'number',
        value: dueDiligenceStats?.dealsClosed || 0,
      },
      {
        title: 'Pipeline Commitments',
        type: 'amount',
        value: dueDiligenceStats?.totalCommitments || 0,
      },
      {
        title: 'Avg. Days in Diligence',
        type: 'number',
        value: dueDiligenceStats?.avgDaysInDD || 0,
        percentage: pctDiffDaysInDD || 0,
        goal: dueDiligenceStats?.avgDaysInDDGoal || 0,
      },
    ],
    [dueDiligenceStats, pctDiffDaysInDD],
  )

  return (
    <Box py={0} pr={2}>
      <Grid item xs={12}>
        <Card withBorder={false} noHeaderMargin>
          <DashboardHeader headerItems={entityDashboardItems} />
          <Box py={1} pr={2}>
            <FullscreenModal
              isOpen={isModalShown}
              setIsOpen={setIsModalShown}
              classes={{ body: styles.fullScreenModal }}
            >
              <Card noHeaderMargin noPadding={!isModalShown} withBorder={false}>
                <TableContainer className={styles.table}>
                  <Form
                    onSubmit={handleFiltersChange}
                    initialValues={filters}
                    mutators={{
                      setFieldData: ([field, value], state, { changeValue }) => {
                        changeValue(state, field, () => value)
                      },
                    }}
                    render={({ values, handleSubmit, form: { mutators } }) => (
                      <FilterContainer
                        filters={filtersConfig}
                        handleSubmit={handleSubmit}
                        mutators={mutators}
                        values={values}
                        appliedQuickFilter={quickFilter}
                        handleAppliedQuickFilterChange={handleQuickFilterChange}
                        actions={
                          <Box display="flex" justifyContent="space-between" alignItems="center">
                            <Grid
                              container
                              columnSpacing={2}
                              justifyContent="space-between"
                              alignItems="center"
                            >
                              <Grid item>
                                <RegenerateIcon
                                  action={handleRefreshProspects}
                                  title={lastUpdatedAt}
                                />
                              </Grid>
                              <Grid item>
                                <ExpandAndMinimize
                                  action={toggleIsModalShown}
                                  isExpanded={isModalShown}
                                />
                              </Grid>
                            </Grid>
                          </Box>
                        }
                        appliedFilters={filters}
                      />
                    )}
                  />
                  <Table>
                    <TableHead>
                      <TableFiltersRow
                        filters={filtersConfig}
                        orderBy={orderBy}
                        handleOrderChange={handleOrderChange}
                      />
                    </TableHead>
                    <TableBody id="scrollableTableReporting">
                      {isLoading ? (
                        <TableLoader
                          columnsCount={filtersConfig.length}
                          rowsCount={10}
                          height={36}
                        />
                      ) : (
                        dueDiligenceAggregation?.data &&
                        dueDiligenceAggregation.data.length > 0 && (
                          <InfiniteScroll
                            dataLength={dueDiligenceAggregation?.data.length}
                            next={loadMore}
                            hasMore={
                              dueDiligenceAggregation?.data.length <
                              dueDiligenceAggregation?.totalItems
                            }
                            loader={
                              <TableLoader
                                columnsCount={filtersConfig.length}
                                rowsCount={1}
                                height={36}
                              />
                            }
                            scrollableTarget="scrollableTableReporting"
                          >
                            {dueDiligenceAggregation.data.map((item) => (
                              <TableRow key={item.id}>
                                <TableCell className={genericSs.tableTextLeft}>
                                  <Link
                                    component={RouterLink}
                                    to={generatePath(ROUTES.DUE_DILIGENCE, {
                                      id: item.clientInfo?.id,
                                    })}
                                  >
                                    {item.clientName}
                                  </Link>
                                  {item.clientInfo.clientStatus !==
                                    ClientInfoStatus.DueDiligence && (
                                    <span
                                      className={cn({
                                        [genericSs.grayCard]:
                                          item.clientInfo.clientStatus !== ClientInfoStatus.Current,
                                        [genericSs.greenTag]:
                                          item.clientInfo.clientStatus === ClientInfoStatus.Current,
                                      })}
                                    >
                                      {item.clientInfo.clientStatus === ClientInfoStatus.Archived
                                        ? 'Archived'
                                        : item.clientInfo.clientStatus}
                                    </span>
                                  )}
                                </TableCell>
                                <TableCell className={genericSs.tableTextRight}>
                                  {formatDate(item.closeDate)}
                                </TableCell>
                                <TableCell className={genericSs.tableTextRight}>
                                  {item.age}
                                </TableCell>
                                <TableCell className={genericSs.tableTextRight}>
                                  {formatPercent(item.progress, 0)}
                                </TableCell>
                                <TableCell className={genericSs.tableTextRight}>
                                  {formatNumber(item.riskRating, 2)}
                                </TableCell>
                                <TableCell className={genericSs.tableTextRight}>
                                  <Tooltip
                                    title={
                                      item.maxFinancialsDate
                                        ? `Financials through ${formatDate(item.maxFinancialsDate)}`
                                        : ''
                                    }
                                  >
                                    <span>
                                      {' '}
                                      {item.ebitda ? `$${formatPrice(item.ebitda, 0)}` : ''}
                                    </span>
                                  </Tooltip>
                                </TableCell>
                                <TableCell className={genericSs.tableTextRight}>
                                  <Tooltip
                                    title={
                                      item.maxFinancialsDate
                                        ? `Financials through ${formatDate(item.maxFinancialsDate)}`
                                        : ''
                                    }
                                  >
                                    <span>
                                      {' '}
                                      {item.netRevenue ? `$${formatPrice(item.netRevenue, 0)}` : ''}
                                    </span>
                                  </Tooltip>
                                </TableCell>
                                <TableCell className={genericSs.tableTextRight}>
                                  {item.commitmentAmount
                                    ? `$${formatPrice(item.commitmentAmount, 0)}`
                                    : '-'}
                                </TableCell>

                                <TableCell className={genericSs.tableTextRight}>
                                  <SalesforceLink link={item.salesforceLink} size="small" />
                                  <BoxLink link={item.boxLink} size="small" />
                                </TableCell>
                              </TableRow>
                            ))}
                          </InfiniteScroll>
                        )
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Card>
            </FullscreenModal>
          </Box>
        </Card>
      </Grid>
    </Box>
  )
}

export default UWDashboard
