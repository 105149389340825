import { createStructuredSelector } from 'reselect'
import { connect } from 'react-redux'

import ClientIntakePage from './ClientIntakePage'

import {
  start,
  loadVendors,
  getCodatLink,
  saveContact,
  saveCompanyInfo,
  savePeopleAndTools,
  uploadDocument,
  deleteDocument,
  submit,
} from '../../redux/clientIntake/actions'

import { isSaving, opsReporting } from '../../redux/clientIntake/selectors'

const selector = createStructuredSelector({
  isSaving,
  opsReporting,
})
const actions = {
  start,
  loadVendors,
  getCodatLink,
  saveContact,
  saveCompanyInfo,
  savePeopleAndTools,
  uploadDocument,
  deleteDocument,
  submit,
}

export { ClientIntakePage }

export default connect(selector, actions)(ClientIntakePage)
