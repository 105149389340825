import { ActionPattern } from '@redux-saga/types'
import { IApi } from '../../api'
import { IRequiredReport } from '@common/interfaces/requiredReport'
import { CommonPromiseAction } from '../types'

export const prefix = 'requiredReport'

export const CREATE_REQUEST = `${prefix}/CREATE_REQUEST`
export const CREATE_SUCCESS = `${prefix}/CREATE_SUCCESS`
export const CREATE_FAILURE = `${prefix}/CREATE_FAILURE`

export const createRequiredReport: CommonPromiseAction = (
  data: Partial<IRequiredReport> & { clientId: string },
) => ({
  type: [CREATE_REQUEST, CREATE_SUCCESS, CREATE_FAILURE],
  promise: (api: IApi) => api.requiredReport.create(data),
})

export const UPDATE_REQUEST = `${prefix}/UPDATE_REQUEST`
export const UPDATE_SUCCESS = `${prefix}/UPDATE_SUCCESS`
export const UPDATE_FAILURE = `${prefix}/UPDATE_FAILURE`

export const updateRequiredReport: CommonPromiseAction = (
  id: string,
  data: Partial<IRequiredReport>,
) => ({
  type: [UPDATE_REQUEST, UPDATE_SUCCESS, UPDATE_FAILURE],
  promise: (api: IApi) => api.requiredReport.update(id, data),
})

export const DELETE_REQUEST = `${prefix}/DELETE_REQUEST`
export const DELETE_SUCCESS = `${prefix}/DELETE_SUCCESS`
export const DELETE_FAILURE = `${prefix}/DELETE_FAILURE`

export const deleteRequiredReport: CommonPromiseAction = (id: string) => ({
  type: [DELETE_REQUEST, DELETE_SUCCESS, DELETE_FAILURE],
  promise: (api: IApi) => api.requiredReport.delete(id),
})

export const LIST_REQUEST = `${prefix}/LIST_REQUEST`
export const LIST_SUCCESS = `${prefix}/LIST_SUCCESS`
export const LIST_FAILURE = `${prefix}/LIST_FAILURE` as ActionPattern

export const listRequiredReports: CommonPromiseAction = (clientId: string) => ({
  type: [LIST_REQUEST, LIST_SUCCESS, LIST_FAILURE],
  promise: (api: IApi) => api.requiredReport.list(clientId),
})
