import React from 'react'

import { DueDiligenceTableItemType, IDueDiligenceReference } from '@common/interfaces/dueDiligence'
import DueDiligenceApplicationTable from '../DueDiligenceApplicationTable'
import { ILoadingData } from '../../redux/types'

interface IProps {
  dueDiligenceTeamReferences: ILoadingData<{ data: IDueDiligenceReference[] }>
  listTeamReferences: (params: object) => void
  hideTeamReferences: () => void
}

const DueDiligenceApplicationTableReferences = ({
  dueDiligenceTeamReferences,
  listTeamReferences,
  hideTeamReferences,
}: IProps) => {
  return (
    <DueDiligenceApplicationTable
      type={DueDiligenceTableItemType.Reference}
      data={dueDiligenceTeamReferences}
      handleList={listTeamReferences}
      handleHide={hideTeamReferences}
    />
  )
}

export default DueDiligenceApplicationTableReferences
