import React, { useMemo, useCallback } from 'react'
import { EntitySubType } from '@common/interfaces/entityInfo'
import Autocomplete from '../../../components/Common/Autocomplete'
import Box from '@mui/material/Box'
import cn from 'classnames'
import styles from '../CollectionsChecksPage.module.scss'
import genericSs from '@styles/generic.module.scss'
import { ICheck } from '@common/interfaces/collection'
import TableRow from '../../../components/Common/TableRow'
import TableCell from '../../../components/Common/TableCell'
import { formatPrice } from '../../../helpers/helpers'
import CreatableSelectField, { IOptionType } from '../../../components/Common/CreatableSelectField'
import { MenuIcon } from '../../../components/Common/Icons'

export const ClientNameDropdown = ({
  check,
  handleUpdateCheck,
  index,
  name,
  clientsOptions,
}: {
  check: ICheck
  handleUpdateCheck: (check: object) => void
  index: number
  name: string
  clientsOptions: IOptionType[]
}) => {
  const handleUpdateClientName = useCallback(
    (event, value: any) => {
      handleUpdateCheck({
        id: check.id,
        clientName: typeof value === 'string' ? value : value?.value,
      })
    },
    [check, handleUpdateCheck],
  )

  return (
    <Autocomplete
      clearIcon={null}
      label=""
      className={cn({
        focusableInput: true,
        [styles.validInput]: !!check.clientInfo,
        invalidInput: !check.clientInfo,
      })}
      name={`${name}.clientName`}
      placeholder="Select client"
      tabIndex={2 * index}
      value={check.clientName ? { value: check.clientName, label: check.clientName } : null}
      options={clientsOptions}
      getOptionValue={(option) => option.value}
      onChange={handleUpdateClientName}
      inTable
    />
  )
}

export const EntityNameDropdown = ({
  check,
  handleUpdateCheck,
  index,
  name,
  entityType = 'debtor',
  handleAddDebtor,
  loadDebtors,
}: {
  check: ICheck
  handleUpdateCheck: (check: object) => void
  index: number
  name: string
  entityType?: string
  handleAddDebtor: (debtorName: string, clientName: string) => Promise<any>
  loadDebtors: (inputValue: string) => Promise<IOptionType[]>
}) => {
  const handleChangeEntity = useCallback(
    async (event, newValue: any) => {
      if (!newValue) {
        handleUpdateCheck({
          id: check.id,
          [entityType]: '',
        })
      } else if (newValue.id) {
        handleUpdateCheck({
          id: check.id,
          [entityType]: newValue?.value,
        })
      } else if (newValue.value) {
        const result = await handleAddDebtor(newValue.value, check.clientName)
        if (!!result?.error) {
          return
        }
        handleUpdateCheck({
          id: check.id,
          [entityType]: newValue?.value,
        })
      }
    },
    [check, handleAddDebtor, handleUpdateCheck, entityType],
  )

  const formName = useMemo(
    () => (entityType === 'debtor' ? 'checkAccount' : 'originalEntity'),
    [entityType],
  )
  const value = useMemo(
    () =>
      formName === 'checkAccount'
        ? check.checkAccount?.linkedName
          ? { value: check.checkAccount.linkedName, label: check.checkAccount.linkedName }
          : null
        : formName === 'originalEntity'
        ? check.originalEntity
          ? { value: check.originalEntity, label: check.originalEntity }
          : null
        : null,
    [check, formName],
  ) as IOptionType | null

  return (
    <CreatableSelectField
      inTable
      label=""
      name={`${name}.${formName}`}
      tabIndex={2 * index + 1}
      className={cn({
        focusableInput: true,
        [styles.validInput]: !!check.accountNumber && !!value,
        invalidInput: !check.accountNumber || !value,
      })}
      value={value}
      placeholder="Select customer"
      onAddValue={(debtorName) => handleAddDebtor(debtorName, check.clientName)}
      options={[]}
      getOptionValue={(option) => option.value}
      isAsync
      loadOptions={loadDebtors}
      onChangeCustom={handleChangeEntity}
    />
  )
}

interface IProps {
  check: ICheck
  index: number
  activeItems: number[]
  activeItem: number
  handleSelectRow: (event: React.MouseEvent, index: number) => void
  handleUpdateCheck: (check: object) => void
  value: any
  name: string
  clientsOptions: IOptionType[]
  loadDebtors: (inputValue: string) => Promise<IOptionType[]>
  handleAddDebtor: (debtorName: string, clientName: string) => Promise<any>
  handleClickMenu: (check: ICheck, event: React.MouseEvent<HTMLElement>) => void
}

const CheckRow = ({
  check,
  index,
  activeItems,
  activeItem,
  handleSelectRow,
  handleUpdateCheck,
  value,
  name,
  clientsOptions,
  loadDebtors,
  handleAddDebtor,
  handleClickMenu,
}: IProps) => {
  const isBillPaymentProvider = useMemo(
    () => check?.checkAccount?.entityInfo?.type === EntitySubType.BillPaymentProvider,

    [check],
  )
  const isValidRow = useMemo(
    () =>
      !!check?.clientInfo &&
      !!check?.accountNumber &&
      !!check?.checkAccount?.linkedName &&
      (!isBillPaymentProvider || !!check?.originalEntity),
    [check, isBillPaymentProvider],
  )

  const isActiveRow = useMemo(() => activeItems.includes(index), [activeItems, index])

  if (!check) {
    return null
  }

  return (
    <TableRow
      id={`mapping-table-row-${index}`}
      key={`check-list-row-${check.id}`}
      data-index={index}
      className={cn('activableRow', {
        activeRow: isActiveRow,
        currentActiveRow: activeItem === index,
        [styles.validRow]: isValidRow,
      })}
      onClick={(event) => handleSelectRow(event, index)}
    >
      <TableCell className={genericSs.tableTextRight}>{check.checkNumber}</TableCell>
      <TableCell className={genericSs.tableTextLeft}>
        <ClientNameDropdown
          check={check}
          handleUpdateCheck={handleUpdateCheck}
          index={index}
          name={name}
          clientsOptions={clientsOptions}
        />
      </TableCell>
      <TableCell className={genericSs.tableTextLeft}>
        <EntityNameDropdown
          check={check}
          handleUpdateCheck={handleUpdateCheck}
          index={index}
          name={name}
          entityType="debtor"
          handleAddDebtor={handleAddDebtor}
          loadDebtors={loadDebtors}
        />
      </TableCell>
      <TableCell className={genericSs.tableTextLeft}>
        {isBillPaymentProvider ? (
          <EntityNameDropdown
            check={check}
            handleUpdateCheck={handleUpdateCheck}
            index={index}
            name={name}
            entityType="originalEntity"
            handleAddDebtor={handleAddDebtor}
            loadDebtors={loadDebtors}
          />
        ) : null}
      </TableCell>
      <TableCell className={genericSs.tableTextRight}>
        <span className={genericSs.pricePrefix}>$</span>
        {formatPrice(check.paymentAmount)}
      </TableCell>
      <TableCell className={genericSs.tableTextLeft}>
        <Box display="inline-box" ml={1}>
          <MenuIcon onClick={(event) => handleClickMenu(check, event)} size="small" />
        </Box>
      </TableCell>
    </TableRow>
  )
}

export default CheckRow
