import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'

import Loader from './Loader'

import { isSidebarOpen } from '../../redux/profile/selectors'

const selector = createStructuredSelector({
  isSidebarOpen,
})
export default connect(selector)(Loader)
