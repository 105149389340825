import React, { useCallback } from 'react'

import { DueDiligenceTableItemType, IDueDiligenceReference } from '@common/interfaces/dueDiligence'
import DueDiligenceTable from '../DueDiligenceTable'
import { ILoadingData } from '../../redux/types'

interface IProps {
  dueDiligenceTeamReferences: ILoadingData<{ data: IDueDiligenceReference[] }>
  listTeamReferences: (params: object) => void
  hideTeamReferences: () => void
  addTeamReference: (data: object) => Promise<void>
  updateTeamReference: (id: string, data: object) => Promise<void>
  deleteTeamReference: (id: string) => Promise<void>
}

const DueDiligenceTeamReferences = ({
  dueDiligenceTeamReferences,
  listTeamReferences,
  hideTeamReferences,
  addTeamReference,
  updateTeamReference,
  deleteTeamReference,
}: IProps) => {
  const handleUpdateReference = useCallback(
    async (id: string, data: any) => {
      const { firstName, lastName, email, phone, businessName, relationshipToCompany, ...rest } =
        data

      return updateTeamReference(id, {
        firstName: firstName || '',
        lastName: lastName || '',
        email: email || '',
        phone: phone || null,
        businessName: businessName || '',
        relationshipToCompany: relationshipToCompany || '',
        ...rest,
      })
    },
    [updateTeamReference],
  )

  return (
    <DueDiligenceTable
      type={DueDiligenceTableItemType.Reference}
      data={dueDiligenceTeamReferences}
      handleList={listTeamReferences}
      handleHide={hideTeamReferences}
      handleAdd={addTeamReference}
      handleUpdate={handleUpdateReference}
      handleDelete={deleteTeamReference}
    />
  )
}

export default DueDiligenceTeamReferences
