import React from 'react'
import MuiTableHead, { TableHeadProps } from '@mui/material/TableHead'

import styles from './TableHead.module.scss'

const TableHead = (props: TableHeadProps) => {
  return <MuiTableHead classes={{ root: styles.root }} {...props} />
}

export default TableHead
