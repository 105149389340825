import React from 'react'
import Box from '@mui/material/Box'
import Skeleton from '@mui/material/Skeleton'

import { Grid } from '@mui/material'

const Loader = () => {
  return (
    <>
      <Box display="flex" justifyContent="space-between" mb={3}>
        <Box mt={1.25}>
          <Skeleton width={450} height={21} />
        </Box>
      </Box>

      <Box display="flex" alignItems="center" justifyContent="space-between" mt={4} mb={3}>
        <Skeleton width={300} height={60} />
        <Box display="flex" gap={3}>
          <Skeleton width={100} height={40} />
        </Box>
      </Box>
      <Grid container xs={12} spacing={3}>
        <Grid item xs={12}>
          <Skeleton height={300} />
        </Grid>
      </Grid>
    </>
  )
}

export default Loader
