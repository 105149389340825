import { ActionPattern } from '@redux-saga/types'
import { IApi } from '../../api'
import { IUser } from '@common/interfaces/user'
import { CommonPromiseAction } from '../types'
import { createRequestTypes } from '../../helpers/helpers'

export const prefix = 'user'

export const LIST_REQUEST = `${prefix}/LIST_REQUEST`
export const LIST_SUCCESS = `${prefix}/LIST_SUCCESS`
export const LIST_FAILURE = `${prefix}/LIST_FAILURE` as ActionPattern

export const listUsers: CommonPromiseAction = (params: object) => ({
  type: [LIST_REQUEST, LIST_SUCCESS, LIST_FAILURE],
  promise: (api: IApi) => api.user.list(params),
})

export const SHOW_REQUEST = `${prefix}/SHOW_REQUEST`
export const SHOW_SUCCESS = `${prefix}/SHOW_SUCCESS`
export const SHOW_FAILURE = `${prefix}/SHOW_FAILURE`

export const showUser: CommonPromiseAction = (id: string) => ({
  type: [SHOW_REQUEST, SHOW_SUCCESS, SHOW_FAILURE],
  promise: (api: IApi) => api.user.show(id),
  id,
})

export const CREATE_REQUEST = `${prefix}/CREATE_REQUEST`
export const CREATE_SUCCESS = `${prefix}/CREATE_SUCCESS`
export const CREATE_FAILURE = `${prefix}/CREATE_FAILURE`

export const createUser: CommonPromiseAction = (data: Partial<IUser & { clientId?: string }>) => ({
  type: [CREATE_REQUEST, CREATE_SUCCESS, CREATE_FAILURE],
  promise: (api: IApi) => api.user.create(data),
})

export const UPDATE_REQUEST = `${prefix}/UPDATE_REQUEST`
export const UPDATE_SUCCESS = `${prefix}/UPDATE_SUCCESS`
export const UPDATE_FAILURE = `${prefix}/UPDATE_FAILURE`

export const updateUser: CommonPromiseAction = (
  id: string,
  data: Partial<IUser & { clientId?: string }>,
) => ({
  type: [UPDATE_REQUEST, UPDATE_SUCCESS, UPDATE_FAILURE],
  promise: (api: IApi) => api.user.update(id, data),
})

export const DELETE_REQUEST = `${prefix}/DELETE_REQUEST`
export const DELETE_SUCCESS = `${prefix}/DELETE_SUCCESS`
export const DELETE_FAILURE = `${prefix}/DELETE_FAILURE`

export const deleteUser: CommonPromiseAction = (id: string) => ({
  type: [DELETE_REQUEST, DELETE_SUCCESS, DELETE_FAILURE],
  promise: (api: IApi) => api.user.delete(id),
})

export const INVITE_REQUEST = `${prefix}/INVITE_REQUEST`
export const INVITE_SUCCESS = `${prefix}/INVITE_SUCCESS`
export const INVITE_FAILURE = `${prefix}/INVITE_FAILURE`

export const inviteUser: CommonPromiseAction = (id: string) => ({
  type: [INVITE_REQUEST, INVITE_SUCCESS, INVITE_FAILURE],
  promise: (api: IApi) => api.user.invite(id),
  id,
})

const TRACK_LINK_CLICK = createRequestTypes(prefix, 'TRACK_LINK_CLICK')

export const [TRACK_LINK_CLICK_REQUEST, TRACK_LINK_CLICK_SUCCESS, TRACK_LINK_CLICK_FAILURE] =
  TRACK_LINK_CLICK

export const trackLinkClick: CommonPromiseAction = (params: object) => ({
  type: TRACK_LINK_CLICK,
  promise: (api: IApi) => api.user.track_link(params),
})
