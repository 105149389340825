import React, { useCallback, useMemo } from 'react'

import styles from './MarketNewsTable.module.scss'

import { INewsfeed, NewsTags, TAG_COLOR_MAPPING } from '@common/interfaces/newsfeed'
import Link from '@mui/material/Link'
import { formatDateCalendarNoTime } from '../../helpers/helpers'
import Card from '../Common/Card'
import LinkedAccountItem from '../MarketNewsDetails/LinkedAccountItem'
import cn from 'classnames'
import { NotificationItemUnreadIcon } from '../NotificationItem'
import Tooltip from '@mui/material/Tooltip'

interface IProps {
  item: INewsfeed
  isCurrentItem: boolean
  handleNewsHeadlineClick: (item: INewsfeed) => void
}

const MarketNewsDetails = ({ item, isCurrentItem, handleNewsHeadlineClick }: IProps) => {
  const handleClick = useCallback(() => {
    handleNewsHeadlineClick(item)
  }, [handleNewsHeadlineClick, item])

  const newsIndustry = useMemo(
    () => item?.brandFromSalesforce?.industry || item?.industry || null,
    [item?.industry, item?.brandFromSalesforce?.industry],
  )

  return (
    <Card
      className={cn(styles.item, { [styles.isCurrentItem]: isCurrentItem })}
      onClick={handleClick}
      noHeaderMargin
    >
      <div className={styles.titleContainer}>
        <div className={styles.tagsContainer}>
          <div style={TAG_COLOR_MAPPING[item.type]} className={styles.itemDescription}>
            {item.type}
          </div>
          {item.isClient && (
            <div style={TAG_COLOR_MAPPING[NewsTags.Client]} className={styles.itemDescription}>
              {NewsTags.Client}
            </div>
          )}
          {item.isProspect && (
            <div style={TAG_COLOR_MAPPING[NewsTags.Prospect]} className={styles.itemDescription}>
              {NewsTags.Prospect}
            </div>
          )}
          {newsIndustry && (
            <div style={TAG_COLOR_MAPPING[NewsTags.Industry]} className={styles.itemDescription}>
              {newsIndustry}
            </div>
          )}
        </div>
        <div className={styles.itemDate}>{formatDateCalendarNoTime(item?.processedAt)}</div>
      </div>

      <div className={cn(styles.itemSubtitles, { [styles.itemSubtitlesHover]: item.link })}>
        {item.link ? (
          <Link
            className={cn(styles.itemHeadlineTitle, styles.itemLink)}
            color="primary"
            href={item.link}
            target="_blank"
            rel="noopener noreferrer"
          >
            {item.headline || ''}
          </Link>
        ) : (
          <div className={styles.itemHeadlineTitle}>{item.headline || ''}</div>
        )}
        {!item.salesforceSyncProcessed && (
          <Tooltip placement="top" title="Not synced with Salesforce">
            <div>
              <NotificationItemUnreadIcon />
            </div>
          </Tooltip>
        )}
      </div>

      <div className={styles.linkedAccounts}>
        <span className={styles.linkedAccountsTitle}>Linked Accounts:</span>

        {[
          ...(item.brandFromSalesforce?.salesforceId
            ? [
                <LinkedAccountItem
                  key="brand"
                  type="brand"
                  salesforceBaseUrl={item.salesforceBaseUrl}
                  brandInfo={item.brandFromSalesforce}
                />,
              ]
            : []),
          ...(item.linkedAccounts || []).map((linkedAccount, index) => (
            <LinkedAccountItem
              key={index}
              type="other"
              salesforceBaseUrl={item.salesforceBaseUrl}
              brandInfo={linkedAccount.brandInfo}
            />
          )),
        ]
          .filter(Boolean)
          .reduce(
            (acc, linkedAccount, index, arr) =>
              acc.concat(
                linkedAccount,
                index < arr.length - 1 ? <span key={`comma-${index}`}>, </span> : null,
              ),
            [],
          )}
      </div>
    </Card>
  )
}

export default MarketNewsDetails
