import { DELETE_ALIAS_SUCCESS } from './../arapmapping/actions'
import { UPDATE_GENERAL_LEDGER_SUCCESS, UPDATE_TYPE_MAPPING_SUCCESS } from './actions'
import { showOngoingReporting } from './../ongoingReporting/actions'
import { REPORTING_DOCUMENT_TYPES } from './../../../../common/constants/client'
import { put, select, takeEvery } from 'redux-saga/effects'
import { ongoingReporting } from '../ongoingReporting/selectors'
import { UPDATE_ALIAS_SUCCESS } from '../arapmapping/actions'
import { ROUTES } from '../../constants/routes'
import { matchPath } from 'react-router'

export function* refreshOngoingReporting() {
  yield takeEvery(
    [
      UPDATE_GENERAL_LEDGER_SUCCESS,
      UPDATE_ALIAS_SUCCESS,
      DELETE_ALIAS_SUCCESS,
      UPDATE_TYPE_MAPPING_SUCCESS,
    ],
    function* () {
      if (
        matchPath(window.location.pathname, {
          path: ROUTES.ONGOING_REPORTING_AR_GENERAL_LEDGER,
        })
      ) {
        const { id, type } = yield select(ongoingReporting)
        if (id && type && type === REPORTING_DOCUMENT_TYPES.arGeneralLedger) {
          yield put(showOngoingReporting(id))
        }
      }
    },
  )
}
