import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'

import DueDiligenceTeamAdvisors from './DueDiligenceTeamAdvisors'

import { dueDiligenceCompanyInfo } from '../../redux/dueDiligence/selectors'
import { loadVendors } from '../../redux/dueDiligence/actions'

const selector = createStructuredSelector({
  dueDiligenceCompanyInfo,
})

const actions = {
  loadVendors,
}

export default connect(selector, actions)(DueDiligenceTeamAdvisors)
