import { FieldType, IClientInfo } from './client'

export interface IParticipant {
  id: string
  participant: string
  email?: string
  allClients?: string[]
  participations?: IParticipation[]
  website?: string
  totalLoanExposure?: number
  totalLoanBalance?: number
  percentExposure?: number
  totalClients?: number
}

export interface IParticipantData {
  data: IParticipant[]
  totalCount: number
}

export enum ParticipationStatus {
  Active = 'Active',
  Terminated = 'Terminated',
  Pending = 'Pending',
}

export interface IParticipationFeeExposure {
  id: string
  clientName: string
  participant: string
  participantInfo?: IParticipant
  participation: IParticipation
  feeOverride: number
  participantExposureAmount: number
  participantExposurePercentage: number
}

export interface IParticipation {
  id?: string
  clientName: string
  participant: string
  participantInfo?: IParticipant
  loanExposure: number
  contractDate: string
  interestOverride: number
  miscFeeShare: number
  miscFeeOverride: number
  passThroughFeeShare: number
  passThroughFeeOverride: number
  facilityFeeShare: number
  facilityFeeOverride: number
  lockboxFeeShare: number
  lockboxFeeOverride: number
  wireFeeShare: number
  wireFeeOverride: number
  terminationDate: string
  status: ParticipationStatus
  createdAt: string
  clientId: string
  loanBalance: number
  nfe: number
}

export interface IParticipationPage extends IParticipation {
  participationLoanExposure: IParticipationLoanExposure
  clientInfo: IClientInfo
}

export interface IParticipationLoanExposure {
  id: string
  clientName: string
  participant: string
  participationId: string
  loanBalanceId: string
  loanBalance: number
  recordDate: string
  fundsIn: number
  fundsOut: number
  fees: number
  feesOut: number
  feeOverrides: number
  interestSent: number
  interestApplied: number
  participantExposureAmount: number
  participantExposurePercentage: number
  monthEndInterest: number
}

export interface IParticipationWire {
  id: string
  clientName: string
  recordDate: string
  participant: string
  participantInfo: IParticipant
  participationId: string
  participation: IParticipation
  amount: number
  type: ParticipationWireTypes
  direction: ParticipationWireDirections
  createdAt: string
  updatedAt: string
}

export enum ParticipationWireDirections {
  Outgoing = 'Outgoing',
  Incoming = 'Incoming',
}

export enum ParticipationWireTypes {
  Principal = 'Principal',
  Interest = 'Interest',
  OtherFee = 'Other Fee',
}

export interface IParticipationData {
  data: IParticipation[]
  totalCount: number
}

export interface IParticipationPageData {
  data: IParticipationPage[]
  totalCount: number
}

export type ParticipationField = {
  label: string
  value: keyof IParticipation
  dbName: string
  type: FieldType
}

export const PARTICIPATION_FIELDS: ParticipationField[] = [
  {
    value: 'loanExposure',
    label: 'Loan Exposure',
    dbName: 'loan_exposure',
    type: FieldType.Percent,
  },
  {
    value: 'contractDate',
    label: 'Contract Date',
    dbName: 'contract_date',
    type: FieldType.Date,
  },
  {
    value: 'interestOverride',
    label: 'Interest Override',
    dbName: 'interest_override',
    type: FieldType.Percent,
  },
  {
    value: 'miscFeeShare',
    label: 'Misc Fee Share',
    dbName: 'misc_fee_share',
    type: FieldType.Percent,
  },
  {
    value: 'miscFeeOverride',
    label: 'Misc Fee Override',
    dbName: 'misc_fee_override',
    type: FieldType.Percent,
  },
  {
    value: 'passThroughFeeShare',
    label: 'Pass Through Fee Share',
    dbName: 'pass_through_fee_share',
    type: FieldType.Percent,
  },
  {
    value: 'passThroughFeeOverride',
    label: 'Pass Through Fee Override',
    dbName: 'pass_through_fee_override',
    type: FieldType.Percent,
  },
  {
    value: 'facilityFeeShare',
    label: 'Facility Fee Share',
    dbName: 'facility_fee_share',
    type: FieldType.Percent,
  },
  {
    value: 'facilityFeeOverride',
    label: 'Facility Fee Override',
    dbName: 'facility_fee_override',
    type: FieldType.Percent,
  },
  {
    value: 'lockboxFeeShare',
    label: 'Lockbox Fee Share',
    dbName: 'lockbox_fee_share',
    type: FieldType.Percent,
  },
  {
    value: 'lockboxFeeOverride',
    label: 'Lockbox Fee Override',
    dbName: 'lockbox_fee_override',
    type: FieldType.Percent,
  },
  {
    value: 'wireFeeShare',
    label: 'Wire Fee Share',
    dbName: 'wire_fee_share',
    type: FieldType.Percent,
  },
  {
    value: 'wireFeeOverride',
    label: 'Wire Fee Override',
    dbName: 'wire_fee_override',
    type: FieldType.Percent,
  },
]

export const PARTICIPANT_PERCENT_FIELDS = PARTICIPATION_FIELDS.filter(
  (field) => field.type === FieldType.Percent,
).map((field) => field.value)

export interface IOutstandingParticipationWiresData {
  data: IOutstandingParticipationWire[]
  totalCount: number
}

export interface IOutstandingParticipationWire extends IParticipationWire {
  participationId: string
  participantId: string
  participantExposureAmount: number
  participantExposurePercentage: number
  confirmed: boolean
  clientId: string
}
