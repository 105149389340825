import React, { ReactNode, useCallback, useEffect, useMemo, useState, useRef } from 'react'
import { Link as RouterLink } from 'react-router-dom'
import Box from '@mui/material/Box'
import InfiniteScroll from 'react-infinite-scroll-component'
import Link from '@mui/material/Link'
import Timeline from '@mui/lab/Timeline'
import TimelineConnector from '@mui/lab/TimelineConnector'
import TimelineContent from '@mui/lab/TimelineContent'
import TimelineDot from '@mui/lab/TimelineDot'
import TimelineItem from '@mui/lab/TimelineItem'
import TimelineSeparator from '@mui/lab/TimelineSeparator'
import { Form } from 'react-final-form'

import styles from './NotificationPage.module.scss'

import { INotification, INotificationData } from '@common/interfaces/notification'
import { NOTIFICATIONS_LIST_FILTERS_CONFIG, PER_PAGE } from '@common/constants/filters'
import { IClientInfo } from '@common/interfaces/client'
import { IUser, UserRole } from '@common/interfaces/user'
import { dateTimeToString, debounceEventHandler } from '../../helpers/helpers'
import Loader from '../../components/Loader'
import FilterContainer from '../../components/Filters/FilterContainer'
import {
  generateNotificationLocation,
  NotificationItemAvatar,
  NotificationItemContent,
  NotificationItemDate,
  NotificationItemUnreadIcon,
} from '../../components/NotificationItem'
import { buildFiltersDefaults, buildFiltersValidateSchema } from '../../helpers/filters'
import { useIsVisible } from '../../hooks/useIsVisible'
import { ILoadingData } from '../../redux/types'
import Button from '../../components/Common/Button/Button'
import { usePermissions } from '../../helpers/permissionContext'

const filtersValidate = buildFiltersValidateSchema(NOTIFICATIONS_LIST_FILTERS_CONFIG)
const filtersDefaults = buildFiltersDefaults(NOTIFICATIONS_LIST_FILTERS_CONFIG)

interface IProps {
  isLoading: boolean
  notifications: INotificationData
  clients: ILoadingData<{ data: IClientInfo[] }>
  users: ILoadingData<{ data: IUser[] }>
  currentUserId: string
  listNotifications: (params: object) => void
  markNotificationsAsRead: (params: { notificationIds: string[]; all?: boolean }) => void
  listClients: () => void
  listUsers: (params: object) => void
}

const TimelineItemWrapper = ({
  isFirst = false,
  isLast = false,
  children,
}: {
  isFirst?: boolean
  isLast?: boolean
  children: ReactNode
}) => {
  return (
    <TimelineItem classes={{ root: styles.notificationItemRoot }}>
      <TimelineSeparator>
        <TimelineConnector
          className={
            isFirst ? styles.notificationItemConnectorTransparent : styles.notificationItemConnector
          }
        />
        <TimelineDot className={styles.notificationItemDot} />
        <TimelineConnector
          className={
            isLast ? styles.notificationItemConnectorTransparent : styles.notificationItemConnector
          }
        />
      </TimelineSeparator>
      <TimelineContent>{children}</TimelineContent>
    </TimelineItem>
  )
}

const NotificationItem = ({
  notification,
  generateNotificationLink,
  onRead,
  isAllRead,
}: {
  notification: INotification
  generateNotificationLink: (notification: INotification) => string
  onRead: (notification: INotification) => void
  isAllRead: boolean
}) => {
  const { user, createdAt } = useMemo(() => notification, [notification])

  const showNotificationIcon = useMemo(() => {
    if (isAllRead) {
      return false
    } else {
      return !notification?.isRead
    }
  }, [isAllRead, notification?.isRead])

  const ref = useRef<HTMLDivElement>()
  const isVisible = useIsVisible(ref)

  useEffect(() => {
    if (!notification.isRead && isVisible) {
      onRead(notification)
    }
  }, [notification, isVisible, onRead])

  const link = useMemo(
    () => generateNotificationLink(notification),
    [generateNotificationLink, notification],
  )

  const isClientUser = useMemo(() => user?.role === UserRole.CLIENT_USER || !user, [user])

  return (
    <Link component={RouterLink} to={link}>
      <div className={styles.notificationItemContent} ref={ref}>
        <div className={styles.notificationItemDate}>
          <NotificationItemDate createdAt={createdAt} />
        </div>
        <NotificationItemAvatar
          className={styles.notificationItemAvatar}
          user={user}
          clientInfo={isClientUser ? notification.clientInfo : null}
        />
        <div className={styles.notificationItemContainer}>
          <NotificationItemContent
            className={styles.notificationItemText}
            notification={notification}
          />
        </div>
        {showNotificationIcon && <NotificationItemUnreadIcon />}
      </div>
    </Link>
  )
}

const NotificationPage = ({
  isLoading,
  notifications,
  clients,
  users,
  currentUserId,
  listNotifications,
  markNotificationsAsRead,
  listUsers,
  listClients,
}: IProps) => {
  const { isAdmin, isBDO, isUW, isClientUser, isProspectUser } = usePermissions()

  const [filters, setFilters] = useState(filtersDefaults)
  const readNotificationIds = useRef<string[]>([])

  const { clientsData } = useMemo(
    () => ({
      clientsData: clients?.data?.data,
    }),
    [clients],
  )

  const { usersData } = useMemo(
    () => ({
      usersData: users?.data?.data,
    }),
    [users],
  )

  const isAllRead = useMemo(
    () => !Boolean(notifications?.totalUnread),
    [notifications?.totalUnread],
  )

  useEffect(() => {
    if (!isProspectUser) {
      listClients()
    }
  }, [currentUserId, listClients, isProspectUser])

  useEffect(() => {
    if (!isProspectUser) {
      listUsers({ withCurrent: true })
    }
  }, [listUsers, isProspectUser])

  useEffect(() => {
    return () => {
      if (readNotificationIds.current.length) {
        markNotificationsAsRead({
          notificationIds: readNotificationIds.current,
        })
      }
    }
  }, [markNotificationsAsRead])

  const fetchList = useCallback(
    (data: any) => {
      const params = {
        ...data,
        filters: {
          ...data.filters,
        },
      }
      if (params?.filters.createdAtFrom && typeof params.filters.createdAtFrom !== 'string') {
        params.filters.createdAtFrom = dateTimeToString(params.filters.createdAtFrom)
      }
      if (params?.filters.createdAtTo && typeof params.filters.createdAtTo !== 'string') {
        params.filters.createdAtTo = dateTimeToString(params.filters.createdAtTo)
      }
      listNotifications(params)
    },
    [listNotifications],
  )

  const debounceList = useMemo(() => debounceEventHandler(fetchList, 500), [fetchList])

  useEffect(() => {
    debounceList({
      page: 0,
      perPage: PER_PAGE,
      filters,
    })
  }, [filters, debounceList])

  const loadMore = useCallback(() => {
    fetchList({
      loadMore: true,
      page: Math.ceil(notifications?.data.length / PER_PAGE),
      perPage: PER_PAGE,
      filters: {},
    })
  }, [notifications, fetchList])

  const handleFiltersChange = useCallback((params: any) => {
    setFilters(params)
  }, [])

  const filtersConfig = useMemo(
    () =>
      NOTIFICATIONS_LIST_FILTERS_CONFIG.filter(({ field }) => {
        if (isBDO && ['activityType', 'feeType'].includes(field)) {
          return false
        }
        if (isProspectUser && !['createdAt', 'isRead'].includes(field)) {
          return false
        }

        return true
      }).map((item) => ({
        ...item,
        options:
          item.field === 'clientName'
            ? clientsData?.map(({ clientName }) => ({
                value: clientName,
                label: clientName,
              }))
            : item.field === 'user' && usersData
            ? [{ id: 'system', firstName: 'System', lastName: '' }]
                .concat(usersData)
                .map(({ id, firstName, lastName }) => ({
                  value: id,
                  label: `${firstName} ${lastName}`,
                }))
            : item.options,
      })),
    [clientsData, usersData, isBDO, isProspectUser],
  )

  const generateNotificationLink = useCallback(
    (notification: INotification) =>
      generateNotificationLocation(notification, isAdmin, isBDO, isUW, isClientUser),
    [isAdmin, isBDO, isUW, isClientUser],
  )

  const addReadNotification = useCallback((notification: INotification) => {
    readNotificationIds.current = [...new Set([...readNotificationIds.current, notification.id])]
  }, [])

  const handleMarkAllAsRead = useCallback(() => {
    markNotificationsAsRead({
      notificationIds: notifications?.data?.map(({ id }) => id),
      all: true,
    })
  }, [notifications, markNotificationsAsRead])

  if (!notifications) {
    return <Loader />
  }

  return (
    <Box py={3} px={2}>
      {isLoading && <Loader />}

      <Form
        validate={filtersValidate}
        onSubmit={handleFiltersChange}
        initialValues={filters}
        mutators={{
          setFieldData: ([field, value], state, { changeValue }) => {
            changeValue(state, field, () => value)
          },
        }}
        render={({ values, handleSubmit, form: { mutators } }) => (
          <FilterContainer
            filters={filtersConfig}
            handleSubmit={handleSubmit}
            mutators={mutators}
            values={values}
            appliedFilters={filters}
            actions={
              <Button
                variant="contained"
                color="primary"
                onClick={handleMarkAllAsRead}
                disabled={isLoading}
              >
                Mark all as read
              </Button>
            }
          />
        )}
      />

      <div className={styles.description}>
        {notifications.total
          ? `You have ${notifications.totalUnread} new notification(s)`
          : "You don't have any notification at the moment"}
      </div>

      <Timeline className={styles.notificationsList} id="notificationsList">
        {notifications?.data.length > 0 && (
          <InfiniteScroll
            dataLength={notifications.data.length}
            next={loadMore}
            hasMore={notifications.data.length < notifications.total}
            loader={
              <TimelineItemWrapper>
                <div className={styles.notificationItemLoader}>Loading...</div>
              </TimelineItemWrapper>
            }
            scrollableTarget="notificationsList"
          >
            {notifications.data.map((notification, index) => (
              <TimelineItemWrapper
                key={notification.id}
                isFirst={index === 0}
                isLast={index === notifications.total - 1}
              >
                <NotificationItem
                  notification={notification}
                  generateNotificationLink={generateNotificationLink}
                  onRead={addReadNotification}
                  isAllRead={isAllRead}
                />
              </TimelineItemWrapper>
            ))}
          </InfiniteScroll>
        )}
      </Timeline>
    </Box>
  )
}

export default NotificationPage
