import { connect } from 'react-redux'
import LoanCommitteeReportForecastPage from './LoanCommitteeReportForecastPage'
import { listFinancialsReports, hideFinancialsReports } from '../../redux/clientInfo/actions'
import { createStructuredSelector } from 'reselect'
import { financialsReports } from '../../redux/clientInfo/selectors'
const selector = createStructuredSelector({
  financialsReports,
})

const actions = {
  listFinancialsReports,
  hideFinancialsReports,
}

export default connect(selector, actions)(LoanCommitteeReportForecastPage)
