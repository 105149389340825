import React, { useMemo } from 'react'
import Table from '../../Common/Table'
import TableContainer from '../../Common/TableContainer'
import TableHead from '../../Common/TableHead'
import TableBody from '../../Common/TableBody'
import TableRow from '../../Common/TableRow'
import TableCell from '../../Common/TableCell'
import genericSs from '@styles/generic.module.scss'
import classNames from 'classnames'
import { FEE_TYPES } from './Participations'
import { IParticipation } from '@common/interfaces/participant'
import FormattedTableCell from '../../Common/FormattedTableCell'
import Card from '../../Common/Card'

const ParticipationFeesTable = ({
  participations,
  dwightsShare,
}: {
  participations: IParticipation[]
  dwightsShare: Partial<IParticipation>
}) => {
  const sortedParticipations = useMemo(() => {
    return participations?.sort((a, b) => {
      if (a.participant < b.participant) return -1
      if (a.participant > b.participant) return 1
      return 0
    })
  }, [participations])

  const headers = useMemo(() => {
    const newHeaders = ['Fee']
    sortedParticipations?.forEach((participation) => {
      newHeaders.push(`${participation.participant} Override (%)`)
      newHeaders.push(`${participation.participant} Share (%)`)
    })
    newHeaders.push("Dwight's Share (%)")
    return newHeaders
  }, [sortedParticipations])

  return (
    <Card title="Fee Structure">
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              {headers.map((item, index) => (
                <TableCell
                  key={index}
                  className={classNames({
                    [genericSs.tableTextLeft]: index === 0,
                    [genericSs.tableTextRight]: index !== 0,
                  })}
                >
                  {item}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody id="scrollableTable">
            {FEE_TYPES.map((item, index) => {
              const isInterest = item.value === 'interest'
              const override = `${item.value}Override`
              const share = isInterest ? 'loanExposure' : `${item.value}Share`

              return (
                <TableRow key={item.value}>
                  <TableCell className={genericSs.tableTextLeft}>{item.label}</TableCell>
                  {sortedParticipations?.map((participation, index) => (
                    <React.Fragment key={`${participation.id}-fee`}>
                      <FormattedTableCell value={participation[override]} fieldType="percent" />
                      <FormattedTableCell value={participation[share]} fieldType="percent" />
                    </React.Fragment>
                  ))}
                  <FormattedTableCell value={dwightsShare[share]} fieldType="percent" />
                </TableRow>
              )
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </Card>
  )
}

export default ParticipationFeesTable
