import React, { useCallback, useMemo } from 'react'
import { diff as deepDiff } from 'deep-object-diff'
import pick from 'lodash/pick'

import styles from './BBCLogsItem.module.scss'

import {
  BBC_LOG_FIELDS_TO_COMPARE,
  BBCLogAction,
  BBCLogType,
  IBBCLog,
  IBBCLogReserve,
} from '@common/interfaces/bbcLog'
import { ReserveKind } from '@common/interfaces/bbc'
import { formatPrice } from '../../../helpers/helpers'
import { FIELDS_LABELS } from '../../ReservesTable/constants'
import BBCLogsItemContentUsers from './BBCLogsItemContentUsers'

const BBCLogsItemContentReserveSingle = ({
  bbcLog,
  reserve,
}: {
  bbcLog: IBBCLog
  reserve: IBBCLogReserve
}) => {
  const renderChanges = useCallback(() => {
    const changedFields = Object.keys(
      pick(
        deepDiff(reserve.oldReserve || {}, reserve.newReserve || {}),
        BBC_LOG_FIELDS_TO_COMPARE[BBCLogType.Reserve],
      ),
    )

    const renderValue = (field: string, value: any) => {
      if (field === 'percent') {
        return value + '%'
      }
      if (field === 'amount') {
        return '$' + formatPrice(value)
      }
      if (['category', 'field'].includes(field)) {
        return FIELDS_LABELS[value]
      }

      return value
    }

    return (
      <>
        {changedFields.map((field, index) => (
          <React.Fragment key={field}>
            {reserve.oldReserve?.[field] && (
              <>from {renderValue(field, reserve.oldReserve?.[field])}&nbsp;</>
            )}
            to&nbsp;<b>{renderValue(field, reserve.newReserve?.[field])}</b>
            {index !== changedFields.length - 1 ? ', ' : ''}
          </React.Fragment>
        ))}
      </>
    )
  }, [reserve])

  switch (reserve.action) {
    case BBCLogAction.add:
      return (
        <div className={styles.itemData}>
          <BBCLogsItemContentUsers bbcLog={bbcLog} />
          &nbsp;
          <b>added</b> a new&nbsp;
          <b>
            {reserve.newReserve?.kind === ReserveKind.Absolute
              ? 'absolute $' + formatPrice(reserve.newReserve?.amount)
              : `${reserve.newReserve?.percent}% for ${FIELDS_LABELS[reserve.newReserve?.field]}`}
          </b>
          &nbsp;reserve
        </div>
      )
    case BBCLogAction.delete:
      return (
        <div className={styles.itemData}>
          <BBCLogsItemContentUsers bbcLog={bbcLog} />
          &nbsp;
          <b>removed</b> an&nbsp;
          <b>
            {reserve.oldReserve?.kind === ReserveKind.Absolute
              ? 'absolute $' + formatPrice(reserve.oldReserve?.amount)
              : `${reserve.oldReserve?.percent}% for ${FIELDS_LABELS[reserve.oldReserve?.field]}`}
          </b>
          &nbsp;reserve
        </div>
      )
    case BBCLogAction.update:
      return (
        <div className={styles.itemData}>
          <BBCLogsItemContentUsers bbcLog={bbcLog} />
          &nbsp;
          <b>updated</b> a <b>reserve</b>&nbsp;
          {renderChanges()}
        </div>
      )
    default:
      return null
  }
}

const BBCLogsItemContentReserveMultiple = ({ bbcLog }: { bbcLog: IBBCLog }) => {
  const actions = useMemo(
    () =>
      bbcLog.reserves.reduce((result, { action }) => {
        if (!result[action]) {
          result[action] = 0
        }

        result[action]++

        return result
      }, {}),
    [bbcLog],
  )

  return (
    <div className={styles.itemData}>
      <BBCLogsItemContentUsers bbcLog={bbcLog} />
      &nbsp;
      {Object.keys(actions).map((action, index) => (
        <React.Fragment key={action}>
          <b>{action}</b>&nbsp;
          {action === BBCLogAction.update && ' setting for '}
          <b>{actions[action]}</b>
          {action === BBCLogAction.add && ' new'} reserve{actions[action] > 1 ? 's' : ''}
          {index === Object.keys(actions).length - 2
            ? ' and '
            : index !== Object.keys(actions).length - 1
            ? ', '
            : ''}
        </React.Fragment>
      ))}
    </div>
  )
}

const BBCLogsItemContentReserve = ({ bbcLog }: { bbcLog: IBBCLog }) => {
  if (bbcLog.reserves.length > 1) {
    return <BBCLogsItemContentReserveMultiple bbcLog={bbcLog} />
  }

  return <BBCLogsItemContentReserveSingle bbcLog={bbcLog} reserve={bbcLog.reserves[0]} />
}

export default BBCLogsItemContentReserve
